import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { MessagesOfTheDayStore, IMessagesOfTheDayRow } from '@ad/messages-of-day/messages-of-day-store';
import { ListView } from '@/components/list-view/list-view';
import { DeprecatedSearchBarRestriction } from '@/ui-components/deprecated-search-bar/deprecated-search-bar';
import { TestIds } from '@/testIds';
import useStore from '@/hooks/useStore';

const AdminMessagesOfTheDayList = observer(() => {
  const { t } = useTranslation();

  const messagesOfTheDayStore = useStore(MessagesOfTheDayStore);

  return (
    <div>
      <ListView<IMessagesOfTheDayRow>
        isPageLoading={messagesOfTheDayStore.isDataLoading}
        title={t('menu.menuItems.administration.subMenuItems.messagesOfTheDay')}
        deprecatedSearchBar={{
          value: messagesOfTheDayStore.deprecatedSearchInputValue,
          onChange: (value) => messagesOfTheDayStore.setDeprecatedSearchInputValue(value),
          restriction: DeprecatedSearchBarRestriction.ONLY_ONE_OPTION_PER_GROUP,
          color: 'white',
          placeholder: t('general.search'),
          filterProps: {
            value: messagesOfTheDayStore.filterValues,
            filter: messagesOfTheDayStore.filter,
            onChange: messagesOfTheDayStore.handleFilterChange,
          },
        }}
        table={{
          columns: messagesOfTheDayStore.columns,
          rowData: messagesOfTheDayStore.filteredRows,
          rowSelection: {
            selectedRowKeys: messagesOfTheDayStore.selectedRowKeys,
            type: 'checkbox',
            onChange: (ids) => messagesOfTheDayStore.setSelectedRowKeys(ids),
          },
          shadow: true,
        }}
        selectedRowsActions={messagesOfTheDayStore.selectedRowsActions}
        actions={messagesOfTheDayStore.listViewActions}
        testId={TestIds.MESSAGES_OF_DAY_LIST}
      />
    </div>
  );
});

export default AdminMessagesOfTheDayList;
