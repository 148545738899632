import React, { useMemo, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ArcherElement } from 'react-archer';

import TodayLessonsStore from '@today/stores/today-lessons-store';
import { Scroller, Stack } from '@/ui-components';
import { LessonCard, SkeletonLessonCard } from '@/components/lesson-card';
import useStore from '@/hooks/useStore';
import { useMedia } from '@/hooks/useMedia';
import './lessons-list.less';
import AppStore from '@/stores/app-store';
import DeprecatedDropDown from '@/ui-components/deprecated-drop-down/drop-down';
import TodayStore from '@today/stores/today-store';

const LessonsList = observer(() => {
  const todayStore = useStore(TodayStore);
  return (todayStore.isCalendarEntriesAllowed && <DefaultLessonsList />) || <></>;
});

const DefaultLessonsList = observer(() => {
  // TODO: scroll to specific lesson based on these refs. (Most likely the Scroller component's
  //  automatic scrolling needs to be adapted.)
  const lessonRefs: Record<number, HTMLDivElement | null> = useMemo(() => ({}), []);
  const todayPageLessonRef = useRef(null);
  const { t } = useTranslation();

  const todayLessonsStore = useStore(TodayLessonsStore);

  useEffect(() => {
    todayLessonsStore.isReady && todayLessonsStore.getLessons();
  }, [todayLessonsStore.isReady]);

  return (
    <div>
      <div className="today-page-lessons-header">
        {/* TODO: This header should be replaced somehow by dynamic sticky header. */}
        <h2 className="today-page-section-heading">{t('general.todayWhatsNext')}</h2>
        {todayLessonsStore.isModeDropDownVisible && (
          <DeprecatedDropDown
            items={todayLessonsStore.modeDropDownItems}
            selectedKey={todayLessonsStore.modeDropDownKey}
            onSelect={(item) => todayLessonsStore.updateDropDownMode(item.key)}
            rightAlign={true}
          />
        )}
      </div>
      <div ref={todayPageLessonRef} className="today-page-lessons">
        {todayLessonsStore.needsToSelectAStudentFirst && <SelectAStudentFirstNotice />}
        {!todayLessonsStore.needsToSelectAStudentFirst && (
          <Scroller
            scrollableParentRef={todayPageLessonRef}
            disableScrollDown={todayLessonsStore.scrollDownDisabled}
            disableScrollUp={todayLessonsStore.scrollUpDisabled}
            hintDown={t('general.lessonsScrollDown')}
            hintUp={t('general.lessonsScrollUp')}
            disableScrollDownLabel={todayLessonsStore.scrollDownDisabledLabel}
            disableScrollUpLabel={todayLessonsStore.scrollUpDisabledLabel}
            onScrollToStart={todayLessonsStore.loadPreviousLessons}
            onScrollToEnd={todayLessonsStore.loadNextLessons}
          >
            <Stack y="md" className="today-page-lessons-stack">
              {!todayLessonsStore.lessonsEmpty &&
                Object.entries(todayLessonsStore.lessonsByDateWithLessonCardProps).map(([date, lessons]) => (
                  <Stack y="md" key={date}>
                    <div className="today-page-lessons-heading">
                      <h1 className="today-page-section-heading">{date}</h1>
                    </div>
                    {lessons.length <= 0 && (
                      <div className="today-lessons-empty">
                        <span className="today-info-text">{t('general.todayLessonEmpty')}</span>
                      </div>
                    )}
                    {lessons.map((lessonWithLessonCardProps) => (
                      <div
                        ref={(ref) => (lessonRefs[lessonWithLessonCardProps.id] = ref)}
                        id={`lesson-${lessonWithLessonCardProps.id}`}
                        key={lessonWithLessonCardProps.id}
                        className="today-page-lessons-lesson-card-wrapper"
                      >
                        <LessonCard {...lessonWithLessonCardProps.props} />
                      </div>
                    ))}
                  </Stack>
                ))}
              {todayLessonsStore.areLessonsLoading &&
                Array.from(Array(8).keys()).map((key) => <SkeletonLessonCard key={key} />)}

              {todayLessonsStore.hasLessonLoadingError && (
                <div className="today-lessons-empty">
                  <div className="today-info-text">{t('general.todayLessonLoadingError')}</div>
                </div>
              )}
            </Stack>
          </Scroller>
        )}
      </div>
    </div>
  );
});

const SelectAStudentFirstNotice = observer(() => {
  const appStore = useStore(AppStore);
  const showArrow = useMedia<boolean>(['(min-width: 1201px)'], [true], false);
  const { t } = useTranslation();

  return showArrow && !appStore.renderFrontChannelLogout ? (
    <ArcherElement
      id="root"
      relations={[
        {
          targetId: 'menu-student-selection',
          targetAnchor: 'right',
          sourceAnchor: 'left',
        },
      ]}
    >
      <div className="today-info-container">
        <span className="today-info-text">{t('general.todaySelectAStudentFirst')}</span>
      </div>
    </ArcherElement>
  ) : (
    <div className="today-info-container">
      <span className="today-info-text">{t('general.todaySelectAStudentFirst')}</span>
    </div>
  );
});

export default LessonsList;
