import { computed } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';

import { inject, Store } from '@/types/store';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import { TimeGridSlotDto } from '@untis/wu-rest-view-api';

export interface ITimeGridSlot {
  startTime: Dayjs;
  endTime: Dayjs;
  name?: string;
  value?: number;
  isBreak?: boolean;
}

/**
 * Minutes for first/last break duration
 */
const FIRST_LAST_BREAK_DURATION = 10;

@Store()
export class TimetableGridSlotsStore {
  private timetableFormatStore = inject(TimetableFormatStore);

  @computed
  get timeGridSlots(): ITimeGridSlot[] {
    if (this.timetableFormatStore.isUntisGrid) {
      return this.timeGridSlotsLessonGrid();
    } else {
      return this.timeGridSlotsClockHours();
    }
  }

  private timeGridSlotsClockHours(): ITimeGridSlot[] {
    const result: ITimeGridSlot[] = [];
    const timeGridAbsoluteStart = dayjs(this.timetableFormatStore.timetableFormat?.duration.start, 'HH:mm');
    const timeGridAbsoluteEnd = dayjs(this.timetableFormatStore.timetableFormat?.duration.end, 'HH:mm');
    const firstGridHour = this.getFirstGridHour();
    const lastGridHour = this.getLastGridHour();

    let currentHour = firstGridHour.clone();
    while (currentHour.isSameOrBefore(lastGridHour)) {
      const nextHour = currentHour.clone().add(1, 'hours');
      let endTime;
      if (nextHour.isAfter(timeGridAbsoluteEnd)) {
        break;
      } else {
        endTime = nextHour;
      }

      if (currentHour.diff(endTime, 'minutes') !== 0) {
        result.push({
          startTime: currentHour,
          endTime: endTime,
        });
      }
      currentHour = currentHour.clone().add(1, 'hours');
    }

    // Add first break
    if (!timeGridAbsoluteStart.isSame(firstGridHour)) {
      result.unshift({
        startTime: timeGridAbsoluteStart,
        endTime: firstGridHour,
        isBreak: true,
      });
    }

    // Add last break
    if (!timeGridAbsoluteEnd.isSame(lastGridHour)) {
      result.push({
        startTime: lastGridHour,
        endTime: timeGridAbsoluteEnd,
        isBreak: true,
      });
    }

    return result;
  }

  private timeGridSlotsLessonGrid(): ITimeGridSlot[] {
    const timeGridSlots = this.timetableFormatStore.timetableFormat?.timeGridSlots ?? [];

    const result: ITimeGridSlot[] = timeGridSlots
      .filter((slot) => this.overlapsTimeGridDuration(slot))
      .map((slot) => ({
        startTime: dayjs(slot.duration.start, 'HH:mm'),
        endTime: dayjs(slot.duration.end, 'HH:mm'),
        name: slot.name,
        value: slot.number,
      }));

    if (!this.timetableFormatStore.showBreaks) {
      return result;
    }

    // Add first break
    const slotBefore = this.getSlotBefore(result[0].startTime, timeGridSlots);
    const breakStart = slotBefore
      ? dayjs(slotBefore.duration.end, 'HH:mm')
      : dayjs(result[0]?.startTime, 'HH:mm').subtract(FIRST_LAST_BREAK_DURATION, 'm');
    result.unshift({
      startTime: breakStart,
      endTime: breakStart,
      isBreak: true,
    });

    // Add last break
    const slotAfter = this.getSlotAfter(result[result.length - 1].endTime, timeGridSlots);
    const breakEnd = slotAfter
      ? dayjs(slotAfter.duration.start, 'HH:mm')
      : dayjs(result[result.length - 1]?.endTime, 'HH:mm').add(FIRST_LAST_BREAK_DURATION, 'm');
    result.push({
      startTime: breakEnd,
      endTime: breakEnd,
      isBreak: true,
    });

    return result;
  }

  private getFirstGridHour(): Dayjs {
    let start = dayjs(this.timetableFormatStore.timetableFormat?.duration.start, 'HH:mm');
    if (start.get('minutes') != 0) {
      start = start.set('minutes', 0).set('hours', start.get('hours') + 1);
    }
    return start;
  }

  private getLastGridHour(): Dayjs {
    let end = dayjs(this.timetableFormatStore.timetableFormat?.duration.end, 'HH:mm');
    if (end.get('minutes') != 0) {
      end = end.set('minutes', 0);
    }
    return end;
  }

  /**
   * Returns if the given timeGridSlot overlaps the timeRange of the selected timetableFormat
   * @param timeGridSlot where format.startTime < slot.endTime && slot.startTime < format.endTime => true
   */
  private overlapsTimeGridDuration(timeGridSlot: TimeGridSlotDto): boolean {
    const timeGridAbsoluteStart = dayjs(this.timetableFormatStore.timetableFormat?.duration.start, 'HH:mm');
    const timeGridAbsoluteEnd = dayjs(this.timetableFormatStore.timetableFormat?.duration.end, 'HH:mm');
    const startTime = dayjs(timeGridSlot.duration.start, 'HH:mm');
    const endTime = dayjs(timeGridSlot.duration.end, 'HH:mm');

    return endTime.isAfter(timeGridAbsoluteStart, 'minutes') && startTime.isBefore(timeGridAbsoluteEnd, 'minutes');
  }

  /**
   * Returns the last slot before the given
   * @param time where slot.endTime <= time
   * @param timeGridSlots all slots
   */
  private getSlotBefore(time: Dayjs, timeGridSlots: TimeGridSlotDto[]) {
    for (let i = timeGridSlots.length - 1; i >= 0; i--) {
      const slot = timeGridSlots[i];

      if (dayjs(slot.duration.end, 'HH:mm').isSameOrBefore(time)) {
        return slot;
      }
    }
  }

  /**
   * Returns the first slot after the given
   * @param time where time <= slot.startTime
   * @param timeGridSlots all slots
   */
  private getSlotAfter(time: Dayjs, timeGridSlots: TimeGridSlotDto[]) {
    for (let i = 0; i < timeGridSlots.length; i++) {
      const slot = timeGridSlots[i];

      if (dayjs(slot.duration.start, 'HH:mm').isSameOrAfter(time)) {
        return slot;
      }
    }
  }

  @computed
  get timeGridStartTime(): Dayjs {
    if (this.timeGridSlots.length > 0) {
      return this.timeGridSlots[0].startTime;
    } else {
      return dayjs().startOf('day');
    }
  }

  @computed
  get timeGridEndTime(): Dayjs {
    if (this.timeGridSlots.length > 0) {
      return this.timeGridSlots[this.timeGridSlots.length - 1].endTime;
    } else {
      return dayjs().startOf('day');
    }
  }

  @computed
  get showBreakBeforeSlots(): boolean {
    if (this.timetableFormatStore.showBreaks && this.timeGridSlots.length > 0) {
      return Math.abs(this.timeGridSlots[0].startTime.diff(this.timeGridSlots[0].endTime, 'minutes')) === 0;
    } else {
      return false;
    }
  }

  @computed
  get showBreakAfterSlots(): boolean {
    if (this.timetableFormatStore.showBreaks && this.timeGridSlots.length > 0) {
      return (
        Math.abs(
          this.timeGridSlots[this.timeGridSlots.length - 1].startTime.diff(
            this.timeGridSlots[this.timeGridSlots.length - 1].endTime,
            'minutes',
          ),
        ) === 0
      );
    } else {
      return false;
    }
  }

  public get numberOfBreaks(): number {
    return this.numberOfBreaksBefore();
  }

  /**
   * Returns the number of breaks in the timeGrid before the given time where
   * - a break is defined as minutes between slot1.endTime and slot2.startTime > 0
   * - a break is considered before if slot.startTime <= time
   *
   * @param time until the number of breaks before shall be returned
   */
  public numberOfBreaksBefore(time?: Dayjs): number {
    let numberOfBreaks = 0;

    for (let i = 0; i < this.timeGridSlots.length; i++) {
      const slot = this.timeGridSlots[i];

      if (time && slot.startTime.isAfter(time)) {
        break;
      }
      if (this.hasBreakBefore(i)) {
        numberOfBreaks++;
      }
    }
    return numberOfBreaks;
  }

  /**
   * Returns whether there is a break before the timeGridSlot with the given
   * @param index
   */
  public hasBreakBefore(index: number): boolean {
    const previous = index > 0 && this.timeGridSlots[index - 1];
    const current = this.timeGridSlots[index];

    return previous && current.startTime.diff(previous.endTime) > 0;
  }

  /**
   * Returns if the slot at the given
   * @param index is hidden
   */
  public isSlotHidden(index: number): boolean {
    return this.timeGridSlots[index]?.isBreak ?? false;
  }
}
