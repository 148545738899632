import { computed, observable, action } from 'mobx';
import { t } from 'i18next';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';

import {
  MessagesOfDayForm,
  IMessagesOfDayFormMessage,
} from '@/pages/administration/messages-of-day/messages-of-day-form';
import { Store, inject } from '@/types/store';
import { ColumnType, Columns } from '@/ui-components/wu-table/wu-table-column-mapper';
import { defaultSorting, sortingByDate, sortingByBoolean } from '@/utils/sorting/sorting-util';
import {
  MessageOfDayDto,
  MessagesOfDaySaveRequestDto,
  DisplayAreas,
  DayToShow,
  MessagesOfDayFormDto,
  MessagesOfDayUpdateRequestDto,
} from '@untis/wu-rest-view-api';
import { IListViewAction, IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import ModalStore from '@/stores/modal-store';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { DeprecatedDropDownItem } from '@/ui-components/deprecated-drop-down/drop-down';
import NotificationStore from '@/stores/notification-store/notification-store';
import ConfigStore from '@/stores/config-store';
import { IDeprecatedFilter, IFilterItem } from '@/ui-components/filter-bar/filter/deprecatedFilter';
import { MessagesOfDayViewApi } from '@/stores/api-store';
import { ElementType, Right } from '@/stores/rights-store';
import { showErrorResponse } from '@/utils/error-handling/error-message';
import OneDriveStore from '@/stores/one-drive-store';
import { createChunks } from '@/utils/array/array-util';
import { TestIds } from '@/testIds';
import TodayMessagesOfTheDayStore from '@/pages/today/stores/today-messages-of-the-day-store';
import { AbstractListViewStore } from '@/pages/master-data/common/abstract-list-view-store';
import { DeprecatedFilterBarValue } from '@/ui-components/filter-bar/deprecated-filter-bar';
import RouterStore from '@/stores/router-store';

export function translateDayToShowEnum(dayToShow: DayToShow): string {
  switch (dayToShow) {
    case DayToShow.MONDAY:
      return t('weekDays.monday');
    case DayToShow.TUESDAY:
      return t('weekDays.tuesday');
    case DayToShow.WEDNESDAY:
      return t('weekDays.wednesday');
    case DayToShow.THURSDAY:
      return t('weekDays.thursday');
    case DayToShow.FRIDAY:
      return t('weekDays.friday');
    case DayToShow.SATURDAY:
      return t('weekDays.saturday');
    case DayToShow.SUNDAY:
      return t('weekDays.sunday');
    case DayToShow.EVERY_DAY:
      return t('general.everyDay');
    default:
      return '';
  }
}

export function translateDislayAreaEnum(displayArea: DisplayAreas): string {
  return t(`general.motdDisplayAreaEnum.${displayArea}`);
}

const dayToShowSortOrder: Record<DayToShow, number> = {
  EVERY_DAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 6,
};

function sortingByDayToShow(a: DayToShow, b: DayToShow): number {
  return dayToShowSortOrder[a] - dayToShowSortOrder[b];
}

export interface IMessagesOfTheDayRow {
  id: number;
  key: number;
  subject: string;
  startDate: Dayjs;
  endDate: Dayjs;
  dayToShowEnum: DayToShow;
  dayToShow: string;
  displayAreas: string[];
  dto: MessageOfDayDto;
  hasAttachments?: boolean;
  isActive: boolean;
  activeTag: string;
  icon: string;
}

@Store()
export class MessagesOfTheDayStore extends AbstractListViewStore<IMessagesOfDayFormMessage> {
  @observable messagesOfTheDay: MessageOfDayDto[] = [];
  @observable messagesOfTheDayFormData?: MessagesOfDayFormDto;
  @observable filterValues: Map<string, string | undefined>;

  oneDriveStore = inject(OneDriveStore);
  configStore = inject(ConfigStore);
  private routerStore = inject(RouterStore);
  private modalStore = inject(ModalStore);
  private notificationStore = inject(NotificationStore);
  private todayMessagesOfTheDayStore = inject(TodayMessagesOfTheDayStore);

  // editId is used if an entry was edited by linking to the messages of the day page
  private editId?: number;

  constructor() {
    super({ right: Right.MSG_OF_DAY, elementType: ElementType.ALL });

    this.filterValues = new Map();
    this.fetchData();
  }

  async fetchData() {
    this.setIsDataLoading(true);
    const messagesOfDayResponse = MessagesOfDayViewApi.getMessagesOfTheDay();
    const messagesOfDayFormResponse = MessagesOfDayViewApi.getMessagesOfTheDayForm();

    const [messagesOfDayData, formData] = await Promise.allSettled([messagesOfDayResponse, messagesOfDayFormResponse]);

    if (messagesOfDayData.status === 'fulfilled') {
      this.messagesOfTheDay = messagesOfDayData.value.data.messagesOfDay;
    }

    if (formData.status === 'fulfilled') {
      this.messagesOfTheDayFormData = formData.value.data;
    }

    this.setIsDataLoading(false);
  }

  @action.bound
  openCreateForm() {
    this.form?.resetFields();
    this.modalStore.openModalDialog({
      children: <MessagesOfDayForm store={this} />,
      title: t('general.motdNewTitle'),
      size: 'full-size',
      onAfterClose: () => this.routerStore.redirect('/messages-of-the-day-new'),
      containsForm: true,
    });
  }

  @action
  async openEditForm(editRow?: IMessagesOfTheDayRow) {
    const row = editRow ?? this.messagesOfTheDayRows.find((row) => row.id === this.editId);

    if (!row) {
      return;
    }

    const motd = row.dto;
    this.form?.resetFields();
    if (motd) {
      try {
        await this.modalStore.openModalDialog({
          title: motd.subject,
          size: 'full-size',
          children: <MessagesOfDayForm messageOfDay={motd} store={this} />,
          onAfterClose: () => this.routerStore.redirect('/messages-of-the-day-new'),
          containsForm: true,
        });
      } catch (error) {
        // noop
      }
    }
  }

  @action
  async saveUpdatedMessageOfDay(id: number, messageOfDayUpdateRequestDto: MessagesOfDayUpdateRequestDto) {
    try {
      const {
        data: { savedMessageOfDay },
      } = await MessagesOfDayViewApi.updateMessageOfTheDay(id, messageOfDayUpdateRequestDto);

      const index = this.messagesOfTheDay.findIndex((m) => m.id === savedMessageOfDay.id);

      this.messagesOfTheDay[index] = savedMessageOfDay;

      this.notificationStore.success({ title: t('general.motdUpdated') });
      this.form?.resetFields();
      this.modalStore.closeModal();

      await this.todayMessagesOfTheDayStore.getUnreadMessagesOfTheDayCount();
    } catch (error) {
      showErrorResponse(error as Error);
    }
  }

  @action
  async saveNewMessageOfDay(messageOfDaySaveRequestDto: MessagesOfDaySaveRequestDto, saveAndNew: boolean) {
    try {
      const {
        data: { savedMessageOfDay },
      } = await MessagesOfDayViewApi.saveMessageOfTheDay(messageOfDaySaveRequestDto);

      this.messagesOfTheDay.push(savedMessageOfDay);
      this.notificationStore.success({ title: t('general.motdCreated') });
      this.form?.resetFields();

      if (!saveAndNew) {
        this.modalStore.closeModal();
      }

      await this.todayMessagesOfTheDayStore.getUnreadMessagesOfTheDayCount();
    } catch (error) {
      showErrorResponse(error as Error);
    }
  }

  @action
  async deleteMessageOfDay(id: number) {
    try {
      const shouldDelete = await this.modalStore.openDeletePrompt(
        t('general.motdDeleteConfirmationTitle', { count: 1 }),
        t('general.motdDeleteConfirmationSubtitle', { count: 1 }),
      );

      if (!shouldDelete) {
        return;
      }

      await MessagesOfDayViewApi.deleteMessagesOfTheDay([id]);

      this.messagesOfTheDay = this.messagesOfTheDay.filter((motd) => motd.id !== id);
      this.resetRowSelection([id]);
      this.notificationStore.success({ title: t('general.motdDeleted', { count: 1 }) });

      // close the modal when for example in edit mode
      this.modalStore.closeModal();
    } catch (error) {
      showErrorResponse(error as Error);
    }
  }

  async deleteMessagesOfDay(ids: number[]) {
    try {
      const shouldDelete = await this.modalStore.openDeletePrompt(
        t('general.motdDeleteConfirmationTitle', { count: ids.length }),
        t('general.motdDeleteConfirmationSubtitle', { count: ids.length }),
      );

      if (!shouldDelete) {
        return;
      }

      const idChunks = createChunks<number>(ids, 100);
      for (const idChunk of idChunks) {
        await MessagesOfDayViewApi.deleteMessagesOfTheDay(idChunk);
      }

      this.messagesOfTheDay = this.messagesOfTheDay.filter((motd) => !ids.includes(motd.id));
      this.resetRowSelection();
      this.notificationStore.success({ title: t('general.motdDeleted', { count: ids.length }) });
    } catch (error) {
      showErrorResponse(error as Error);
    }
  }

  @action
  resetRowSelection(idsToBeDeleted?: number[]) {
    if (idsToBeDeleted) {
      const newSelectedRowKeys = this.selectedRowKeys.filter((key) => !idsToBeDeleted.includes(key as number));
      this.setSelectedRowKeys(newSelectedRowKeys);
      return;
    }

    this.setSelectedRowKeys([]);
  }

  @action.bound
  handleFilterChange(
    newFilterItems: DeprecatedFilterBarValue,
    filter: IDeprecatedFilter,
    item: IFilterItem | undefined,
  ) {
    const newFilterValues = new Map(this.filterValues);
    newFilterValues.set(filter.id, item ? item.id : undefined);
    this.filterValues = newFilterValues;
  }

  @action
  onFormCancel() {
    this.modalStore.closeModal();
  }

  @action
  setModalTitle(title: string) {
    this.modalStore.setTitle(title);
  }

  @action
  setEditId(editId: string) {
    this.editId = editId ? Number(editId) : undefined;
  }

  getUserGroupMultiTagSelectItem(id: string) {
    return this.userGroupFiltersSelectItems.find((item) => item.id === id);
  }

  getDepartmentMultiTagSelectItem(id: string) {
    return this.departmentFiltersSelectItems.find((item) => item.id === id);
  }

  isMultiTagSelectItem(item: IMultiTagSelectItem | undefined): item is IMultiTagSelectItem {
    return !!item;
  }

  @computed
  get columns(): Columns<IMessagesOfTheDayRow> {
    const cols: Columns<IMessagesOfTheDayRow> = [
      {
        type: ColumnType.Icon,
        key: 'icon-col',
        header: t('general.icon'),
        sorter: (a, b) => defaultSorting(a.icon, b.icon),
        getIcon: (row) => row.icon,
        subdir: 'icon-picker',
        tooltip: (row) => t(`icons.${row.icon}`),
      },
      {
        type: ColumnType.Text,
        key: 'subject',
        header: t('general.motdSubject'),
        sorter: (a, b) => defaultSorting(a.subject, b.subject),
      },
      {
        type: ColumnType.Date,
        key: 'startDate',
        header: t('general.motdFrom'),
        sorter: (a, b) => sortingByDate(a.startDate.toDate(), b.startDate.toDate()),
      },
      {
        type: ColumnType.Date,
        key: 'endDate',
        header: t('general.motdTill'),
        sorter: (a, b) => sortingByDate(a.endDate.toDate(), b.endDate.toDate()),
      },
      {
        type: ColumnType.DeprecatedTag,
        key: 'activeTag',
        variant: (row) => (row.isActive ? 'success' : 'light-gray'),
        header: t('general.active'),
        sorter: (a, b) => sortingByBoolean(a.isActive, b.isActive),
      },
      {
        type: ColumnType.DeprecatedTag,
        key: 'dayToShow',
        variant: 'light-gray',
        header: t('general.motdDayOfTheWeek'),
        sorter: (a, b) => sortingByDayToShow(a.dayToShowEnum, b.dayToShowEnum),
      },
      {
        type: ColumnType.TextArray,
        key: 'displayAreas-col',
        header: t('general.motdDisplayArea'),
        getTexts: (row) => {
          return row.displayAreas.map((wts) => ({ value: wts }));
        },
        sorter: (a, b) => {
          return defaultSorting(a.displayAreas.join(', '), b.displayAreas.join(', '));
        },
      },
    ];

    if (this.hasOneDrive) {
      cols.push({
        type: ColumnType.Icon,
        key: 'has-attachments-col',
        icon: 'attachment',
        condition: (row) => row?.hasAttachments ?? false,
        align: 'center',
      });
    }

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.OverlayHoverActions,
        key: 'actions',
        width: this.columnActions.length * 45 + 'px',
        actionIcons: this.columnActions,
      });
    }

    return cols;
  }

  @computed
  private get columnActions() {
    const actions = [];

    if (this.canEdit) {
      actions.push({
        tooltip: t('general.edit'),
        href: (row: IMessagesOfTheDayRow) => `/messages-of-the-day-new/edit/${row.id}`,
        icon: 'edit',
        testId: TestIds.MESSAGES_OF_DAY_EDIT,
      });
    }

    if (this.canDelete) {
      actions.push({
        tooltip: t('general.delete'),
        onClick: (row: IMessagesOfTheDayRow) => this.deleteMessageOfDay(row.id),
        icon: 'shared_trash',
        testId: TestIds.MESSAGES_OF_DAY_DELETE,
      });
    }

    return actions;
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<IMessagesOfTheDayRow>[] | undefined {
    const actions = [];

    if (this.canDelete) {
      actions.push({
        label: t('general.delete'),
        icon: 'shared_trash',
        onClick: () => {
          this.deleteMessagesOfDay(this.selectedRowKeys as number[]);
        },
      });
    }
    return actions;
  }

  @computed
  get messagesOfTheDayRows(): IMessagesOfTheDayRow[] {
    const rows = this.messagesOfTheDay
      .map((moftd) => {
        const endDate = dayjs(moftd.endDate, 'YYYY-MM-DDTHH:mm:ss');
        const isActive = dayjs().isSameOrBefore(endDate, 'day');

        return {
          id: moftd.id,
          key: moftd.id,
          subject: moftd.subject ?? '',
          displayAreas: moftd.displayAreas.map(translateDislayAreaEnum),
          dayToShowEnum: moftd.dayToShow,
          dayToShow: translateDayToShowEnum(moftd.dayToShow),
          startDate: dayjs(moftd.startDate, 'YYYY-MM-DDTHH:mm:ss'),
          endDate,
          dto: moftd,
          hasAttachments: moftd.oneDriveAttachments && moftd.oneDriveAttachments.length > 0,
          isActive,
          activeTag: isActive ? t('general.active') : t('general.inactive'),
          icon: moftd.icon,
        };
      })
      .sort((motdA, motdB) =>
        motdA.isActive === motdB.isActive
          ? sortingByDate(motdB.endDate.toDate(), motdA.endDate.toDate())
          : sortingByBoolean(motdA.isActive, motdB.isActive),
      );

    return rows;
  }

  @computed
  get filteredRows(): IMessagesOfTheDayRow[] {
    if (
      !this.deprecatedSearchInputValue &&
      !this.hasAllSchoolYearsFilterActive &&
      !this.hasOnlyActiveMessagesFilterActive
    ) {
      return this.messagesOfTheDayRows;
    }

    if (this.hasOnlyActiveMessagesFilterActive) {
      return this.messagesOfTheDayRows.filter((row) => {
        return row.isActive && row.subject.toLowerCase().includes(this.deprecatedSearchInputValue.toLowerCase());
      });
    }

    return this.messagesOfTheDayRows.filter((row) => {
      return row.subject.toLowerCase().includes(this.deprecatedSearchInputValue.toLowerCase());
    });
  }

  @computed
  get listViewActions(): IListViewAction[] {
    const actions: IListViewAction[] = [];
    if (this.canCreate) {
      actions.push({
        label: t('general.new'),
        href: '/messages-of-the-day-new/new',
        testId: TestIds.MESSAGES_OF_DAY_NEW,
      });
    }

    return actions;
  }

  @computed
  get displayAreasSelectItems(): IMultiTagSelectItem[] {
    return (
      this.messagesOfTheDayFormData?.displayAreas.map((displayArea) => ({
        id: displayArea,
        label: translateDislayAreaEnum(displayArea),
      })) ?? []
    );
  }

  @computed
  get daysToShowDropdownItems(): DeprecatedDropDownItem[] {
    return (
      this.messagesOfTheDayFormData?.dayToShow.map((dayToShow) => ({
        key: dayToShow,
        value: translateDayToShowEnum(dayToShow),
      })) ?? []
    );
  }

  @computed
  get userGroupFiltersSelectItems(): IMultiTagSelectItem[] {
    return (
      this.messagesOfTheDayFormData?.userGroupFilters.map((userGroupFilter) => ({
        id: String(userGroupFilter.id),
        label: userGroupFilter.name,
      })) ?? []
    );
  }

  @computed
  get departmentFiltersSelectItems(): IMultiTagSelectItem[] {
    return (
      this.messagesOfTheDayFormData?.departmentFilters.map((departmentFilter) => ({
        id: String(departmentFilter.id),
        label: departmentFilter.name,
      })) ?? []
    );
  }

  @computed
  get filter(): IDeprecatedFilter[] {
    return [
      {
        id: 'filter-allSchoolyears',
        items: [{ id: 'allSchoolyears', label: t('general.motdFilterAllSchoolyears') }],
      },
      {
        id: 'filter-onlyActiveMessages',
        items: [{ id: 'onlyActiveMessages', label: t('general.active') }],
      },
    ];
  }

  @computed
  get hasAllSchoolYearsFilterActive(): boolean {
    return this.filterValues.get('filter-allSchoolyears') !== undefined;
  }

  @computed
  get hasOnlyActiveMessagesFilterActive(): boolean {
    return this.filterValues.get('filter-onlyActiveMessages') !== undefined;
  }

  @computed
  get hasOneDrive() {
    return this.configStore.hasOneDrive;
  }

  @computed
  get icons() {
    return [
      { icon: 'megaphone', label: t('icons.megaphone') },
      { icon: 'alert', label: t('icons.alert') },
      { icon: 'briefcase', label: t('icons.briefcase') },
      { icon: 'calendar', label: t('icons.calendar') },
      { icon: 'calendar_alert', label: t('icons.calendar_alert') },
      { icon: 'cam', label: t('icons.cam') },
      { icon: 'christmas', label: t('icons.christmas') },
      { icon: 'class', label: t('icons.class') },
      { icon: 'clean', label: t('icons.clean') },
      { icon: 'computer', label: t('icons.computer') },
      { icon: 'dashboard', label: t('icons.dashboard') },
      { icon: 'exit', label: t('icons.exit') },
      { icon: 'flag', label: t('icons.flag') },
      { icon: 'football', label: t('icons.football') },
      { icon: 'hammer', label: t('icons.hammer') },
      { icon: 'homework', label: t('icons.homework') },
      { icon: 'leaf', label: t('icons.leaf') },
      { icon: 'location', label: t('icons.location') },
      { icon: 'party_balloons', label: t('icons.party_balloons') },
      { icon: 'renovate', label: t('icons.renovate') },
      { icon: 'room', label: t('icons.room') },
      { icon: 'school', label: t('icons.school') },
      { icon: 'snowflake', label: t('icons.snowflake') },
      { icon: 'spray', label: t('icons.spray') },
      { icon: 'stop', label: t('icons.stop') },
      { icon: 'student', label: t('icons.student') },
      { icon: 'subject', label: t('icons.subject') },
      { icon: 'sun', label: t('icons.sun') },
      { icon: 'teacher', label: t('icons.teacher') },
      { icon: 'thunderstorm', label: t('icons.thunderstorm') },
      { icon: 'umbrella', label: t('icons.umbrella') },
      { icon: 'wrench', label: t('icons.wrench') },
    ];
  }
}
