import React, { useState } from 'react';
import Form from 'antd/lib/form';
import dayjs, { Dayjs } from 'dayjs';

import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import { DatePicker } from '@/ui-components';

interface IProps extends Omit<IFormItemProps<Dayjs>, 'initialValue' | 'label'> {
  initialValue: Dayjs | undefined;
  label: string;
  removable?: boolean;
  disabledDate?: ((date: Dayjs) => boolean) | undefined;
  onChange?: (date: Dayjs | undefined) => void;
  useWrapperWidth?: boolean;
}

export const FormDatePicker = (props: IProps) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(dayjs());

  const onChange = (date: Dayjs | undefined) => {
    setSelectedDate(date);
    props.onChange && props.onChange(date);
  };

  const disabledDate: ((date: Dayjs) => boolean) | undefined = props.disabledDate
    ? (date) => {
        return props.disabledDate!(date);
      }
    : undefined;

  return (
    <Form.Item
      name={props.name}
      initialValue={props.initialValue}
      className="form-date-picker"
      rules={props.rules}
      dependencies={props.dependencies}
    >
      <DatePicker
        value={selectedDate}
        label={props.label}
        onChange={onChange}
        disabled={props.disabled}
        removable={props.removable}
        disabledDate={disabledDate}
        testId={props.testId}
        useWrapperWidth={props.useWrapperWidth}
      />
    </Form.Item>
  );
};
