import React, { Ref } from 'react';
import clsx from 'clsx';

import { ISelectOptionListItem } from '@/ui-components/select-option-list/select-option-list';
import nameToInitials from '@/utils/name-to-initials/name-to-initials';
import { Avatar, Button, Icon, DeprecatedTag, Tooltip } from '@/ui-components';

import './select-option-list-item.less';

interface IProps {
  item: ISelectOptionListItem;
  isSingleSelect: boolean;
  onKeyUp?: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  focusableRef?: Ref<HTMLLIElement>;
}

const SelectOptionListItem = (props: IProps) => {
  const renderIconOrAvatar = () => {
    if (props.item.avatarSrc) {
      return (
        <Avatar src={props.item.avatarSrc} size={40}>
          {nameToInitials(props.item.name)}
        </Avatar>
      );
    } else if (props.item.icon) {
      return <Icon className={props.item.circleIcon ? 'circle-icon' : undefined} type={props.item.icon} />;
    } else {
      return <Avatar size={40}>{nameToInitials(props.item.name)}</Avatar>;
    }
  };

  const renderName = () => {
    return <span>{props.item.name}</span>;
  };

  const renderTags = () => {
    return (
      props.item.tags &&
      props.item.tags.map((tag, index) => {
        const tagContent = (
          <span>
            <DeprecatedTag key={index} type={tag.type ? tag.type : undefined} active={!!tag.active}>
              {tag.name}
            </DeprecatedTag>
          </span>
        );
        return <>{tag.tooltip ? <Tooltip {...tag.tooltip}>{tagContent}</Tooltip> : tagContent}</>;
      })
    );
  };

  const renderTextDescription = () => {
    return <span className="text-description">{props.item.description}</span>;
  };

  let className = 'select-option-list-item';
  className += props.item.value === undefined && !props.isSingleSelect ? ' left-offset' : '';
  className += props.isSingleSelect ? ' single-select' : ' multi-select';
  className += props.item.onClick !== undefined ? ' with-click-handler' : '';
  className += props.item.greyedOut ? ' greyed-out' : '';
  className += props.item.className !== undefined ? ` ${props.item.className}` : '';

  let cRight = 'right-section';
  cRight += props.item.value === undefined ? ' not-selectable' : '';

  return (
    <span
      className={className}
      onClick={props.item.onClick}
      onKeyUp={props.onKeyUp}
      onKeyDown={props.onKeyDown}
      ref={props.focusableRef}
      tabIndex={0}
      title={props.item.htmlTitle}
    >
      <div className="main-container">
        {!props.item.noAvatar && <div className="avatar-container">{renderIconOrAvatar()}</div>}
        <div
          className={clsx('left-section', {
            'no-avatar': props.item.noAvatar,
          })}
        >
          <div className="name-container">{renderName()}</div>
          <div className="tags-container">
            {renderTags()}
            {renderTextDescription()}
          </div>
        </div>
        <div className="middle-section">{props.item.descriptionComponent}</div>
        <div className={cRight}>
          {props.item.hint}
          {props.item.actionButton && (
            <Button
              disabled={props.item.actionButton.disabled}
              testId={props.item.actionButton.testId}
              outline
              onClick={(e) => {
                e.stopPropagation();
                if (props.item.actionButton && props.item.actionButton.onClick) {
                  props.item.actionButton.onClick(e);
                }
              }}
            >
              {props.item.actionButton.text}
            </Button>
          )}
          {props.item.iconButton && (
            <button
              type="button"
              className="select-option-list-item-icon-button"
              onClick={(e) => {
                e.stopPropagation();
                if (props.item.iconButton && props.item.iconButton.onClick) {
                  props.item.iconButton.onClick(e);
                }
              }}
              disabled={props.item.iconButton.disabled}
              data-testid={props.item.iconButton.testId}
            >
              <Icon type={props.item.iconButton.icon} />
            </button>
          )}
        </div>
      </div>
      {props.item.content && <div className="content-container">{props.item.content}</div>}
    </span>
  );
};

export default SelectOptionListItem;
