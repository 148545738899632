import React, { useMemo } from 'react';

import { IFeatureOnboardingStep } from '@/stores/feature-onboarding-store';

const ONBOARDING_IMAGES_PATH = '/assets/images/onboarding/';
const ONBOARDING_VIDEOS_PATH = '/assets/videos/onboarding/';

const FeatureOnboardingModalContent = ({
  textContent,
  textContainsHtml,
  graphic,
}: Pick<IFeatureOnboardingStep, 'textContent' | 'textContainsHtml' | 'graphic'>) => {
  const graphicContent = useMemo(() => {
    return graphic.type === 'video' ? (
      <video
        src={`${ONBOARDING_VIDEOS_PATH}${graphic.content.name}`}
        autoPlay={true}
        loop={graphic.content.loop}
        muted={true}
      />
    ) : (
      <img src={`${ONBOARDING_IMAGES_PATH}${graphic.name}`} alt="onboarding-image" />
    );
  }, [graphic]);

  const text = useMemo(() => {
    return (
      <>
        {textContainsHtml ? (
          <div className="feature-onboarding-modal--text" dangerouslySetInnerHTML={{ __html: textContent }} />
        ) : (
          <div className="feature-onboarding-modal--text">{textContent}</div>
        )}
      </>
    );
  }, [textContent, textContainsHtml]);

  return (
    <div className="feature-onboarding-modal--content">
      {text}
      <div className="feature-onboarding-modal--image">{graphicContent}</div>
    </div>
  );
};

export default FeatureOnboardingModalContent;
