import { FileDescriptorDtoV2, FileDescriptorDtoV2StorageProviderEnum } from '@untis/wu-rest-view-api';
import { inject } from '@/types/store';
import { S3Store } from '@/stores/s3-store';
import { IAttachmentProps } from '@/ui-components/attachments/attachments';
import OneDriveStore from '@/stores/one-drive-store';
import ConfigStore from '@/stores/config-store';

/**
 * If you are going to introduce new types of attachments, you are going to adapt this file accordingly.
 * - extend AllAttachmentsUnionType : e.g.: AllAttachmentsUnionType = IDriveFileDescriptor | <Your new attachment here>
 * - write a typeguard that securely is able to identify your new type of attachment
 * - extend "onDownloadAttachment" - what happens if the user clicks on a file of the new attachment type?
 * - extend "getAttachmentId" - how can the base component retrieve a unique id from the new attachment type?
 *    - be carefull: id must be unique across ALL types of attachments
 * - extend "getAttachmentName" - how can the base component retrieve the from the new attachment type?
 *    - this string will be displayed to the user
 *
 * See Storybook examples to get the idea of this concept
 */

export const onDownloadAttachment = (attachment: FileDescriptorDtoV2) => {
  const s3Store = inject(S3Store);

  switch (attachment.storageProvider) {
    case FileDescriptorDtoV2StorageProviderEnum.STORAGE_SERVICE:
      s3Store.downloadAttachment(attachment);
      return;
    default:
      window.open(attachment.downloadUrl, '_blank');
      return;
  }
};

/*
  If another base component is rendering Attachments, it needs the correct props as well.
  This function can be used to provide those.
  (value and onChange handler still need to be passed in individually)
 */

export const getAttachmentProps = (): Omit<IAttachmentProps, 'value' | 'onChange'> => {
  const configStore = inject(ConfigStore);
  const oneDriveStore = inject(OneDriveStore);
  const s3Store = inject(S3Store);

  if (configStore.hasOneDrive) {
    return {
      onUpload: () => oneDriveStore.openOneDriveDialog(false),
      onDownload: onDownloadAttachment,
    };
  }

  return {
    onUpload: () => s3Store.openFileUploadDialog(),
    onDownload: onDownloadAttachment,
  };
};
