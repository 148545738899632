import React from 'react';
import clsx from 'clsx';

import { useLessonCardHiddenItemCount } from '@/components/lesson-card/hooks/use-lesson-card-hidden-item-count';
import HiddenItemCount from '@/components/lesson-card/components/hidden-item-count/hidden-item-count';

interface IProps {
  studentGroup: string;
  isTopElement?: boolean;
}

const LessonCardStudentGroup = ({ studentGroup, isTopElement }: IProps) => {
  const { ref, hiddenItemCount, hideInnerContent } = useLessonCardHiddenItemCount(isTopElement);

  return (
    <div className="lesson-card-student-group-container" ref={ref}>
      <div
        className={clsx('lesson-card-student-groups', {
          'lesson-card-top-element': isTopElement,
          hide: hideInnerContent,
        })}
        data-testid="lesson-card-student-groups"
      >
        <span
          className={clsx('lesson-card-student-group', { 'lesson-card-top-element': isTopElement })}
          data-testid="lesson-card-student-group"
        >
          {studentGroup}
        </span>
      </div>
      <HiddenItemCount count={hiddenItemCount} isTopElement={isTopElement} />
    </div>
  );
};

export default LessonCardStudentGroup;
