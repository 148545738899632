import React from 'react';

import './lesson-card-indicator-shape.less';

export type LessonCardIndicatorShapeType =
  | 'circle'
  | 'triangle'
  | 'square'
  | 'rhombus'
  | 'horizontal-line'
  | 'vertical-line';

interface IProps {
  type: LessonCardIndicatorShapeType;
  color?: string;
  hasOutline?: boolean;
  width?: number;
  height?: number;
}

const LessonCardIndicatorShape = (props: IProps) => {
  const { type, color, hasOutline, width, height } = props;

  const renderShapeSvg = (type: LessonCardIndicatorShapeType) => {
    switch (type) {
      case 'triangle':
        return (
          <svg
            width={width}
            height={height}
            viewBox="-50 -50 300 300"
            data-testid={`lesson-card-indicator-shape-${type}`}
          >
            {hasOutline ? (
              <>
                <polygon
                  className="lesson-card-indicator-shape--fill-bk"
                  strokeLinejoin="round"
                  stroke="round"
                  points="100,0 0,173 200,173"
                />
                <polygon
                  className="lesson-card-indicator-shape--fill"
                  strokeLinejoin="round"
                  stroke="round"
                  points="100,0 0,173 200,173"
                />
              </>
            ) : (
              <polygon
                className="lesson-card-indicator-shape--fill-no-outline"
                strokeLinejoin="round"
                stroke="round"
                points="100,0 0,173 200,173"
              />
            )}
          </svg>
        );
      case 'square':
        return (
          <svg width={width} height={height} viewBox="-50 -50 300 300">
            {hasOutline ? (
              <>
                <polygon
                  className="lesson-card-indicator-shape--fill-bk"
                  strokeLinejoin="round"
                  stroke="round"
                  points="0,0 200,0 200,200, 0,200"
                />
                <polygon
                  className="lesson-card-indicator-shape--fill"
                  strokeLinejoin="round"
                  stroke="round"
                  fill="color"
                  points="0,0 200,0 200,200, 0,200"
                />
              </>
            ) : (
              <polygon
                className="lesson-card-indicator-shape--fill-no-outline"
                strokeLinejoin="round"
                stroke="round"
                fill="color"
                points="0,0 200,0 200,200, 0,200"
              />
            )}
          </svg>
        );
      case 'rhombus':
        return (
          <svg width={width} height={height} viewBox="-50 -50 300 300">
            {hasOutline ? (
              <>
                <polygon
                  className="lesson-card-indicator-shape--fill-bk"
                  strokeLinejoin="round"
                  stroke="round"
                  points="100,0 200,100 100,200, 0,100"
                />
                <polygon
                  className="lesson-card-indicator-shape--fill"
                  strokeLinejoin="round"
                  stroke="round"
                  fill="color"
                  points="100,0 200,100 100,200, 0,100"
                />
              </>
            ) : (
              <polygon
                className="lesson-card-indicator-shape--fill-no-outline"
                strokeLinejoin="round"
                stroke="round"
                fill="color"
                points="100,0 200,100 100,200, 0,100"
              />
            )}
          </svg>
        );
      case 'circle':
        return (
          <svg width={width} height={height} viewBox="-50 -50 300 300">
            {hasOutline ? (
              <circle className="lesson-card-indicator-shape--fill-circle" id="path3707" cx="100" cy="100" r="130" />
            ) : (
              <circle
                className="lesson-card-indicator-shape--fill-circle-no-outline"
                id="path3707"
                cx="100"
                cy="100"
                r="130"
              />
            )}
          </svg>
        );
      case 'horizontal-line':
        return (
          <div
            className="lesson-card-indicator-shape--line"
            style={{
              background: color,
            }}
          ></div>
        );
      case 'vertical-line':
        return (
          <div
            className="lesson-card-indicator-shape--line"
            style={{
              background: color,
              transform: 'rotate(90deg)',
            }}
          ></div>
        );
    }
  };

  return (
    <span className="lesson-card-indicator-shape" style={{ color: color }}>
      {renderShapeSvg(type)}
    </span>
  );
};

export default LessonCardIndicatorShape;
