import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button, ComplexOptionPopover } from '@/ui-components';
import useStore from '@/hooks/useStore';
import { TimetableLegendFilterStore } from '@te/standard/stores/filter/timetable-legend-filter-store';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import { TimetableIntegrationStore } from '@te/standard/stores/integration/timetable-integration-store';
import LessonCardIndicatorShape from '@/components/lesson-card/components/indicator/lesson-card-indicator-shape';
import { TestIds } from '@/testIds';

import './timetable-legend-filter.less';

const TimetableLegendFilter = observer(() => {
  const { t } = useTranslation();
  const timetableLegendFilterStore = useStore(TimetableLegendFilterStore);
  const timetableFormatStore = useStore(TimetableFormatStore);
  const timetableIntegrationStore = useStore(TimetableIntegrationStore);

  return (
    <div data-testid={TestIds.TIMETABLE_LEGEND_FILTER} className="timetable-legend-filter--content">
      {timetableFormatStore.timetableFormat?.showCancellations && (
        <Button
          testId="legend-filter-cancellation"
          type="secondary"
          size="small"
          outline={!timetableLegendFilterStore.selectedLegendFilters.includes('cancellation')}
          onClick={() => timetableLegendFilterStore.toggleLegendFilter('cancellation')}
        >
          <span className="timetable-legend-filter--shape-wrapper">
            <LessonCardIndicatorShape type="triangle" color="#ff4040" hasOutline={true} width={22} height={22} />
          </span>
          {t('general.cancellation')}
        </Button>
      )}
      <Button
        testId="legend-filter-exam"
        type="secondary"
        size="small"
        outline={!timetableLegendFilterStore.selectedLegendFilters.includes('exam')}
        onClick={() => timetableLegendFilterStore.toggleLegendFilter('exam')}
      >
        <span className="timetable-legend-filter--shape-wrapper">
          <LessonCardIndicatorShape type="circle" color="#ffd615" hasOutline={true} width={22} height={22} />
        </span>
        {t('general.exam')}
      </Button>
      <Button
        testId="legend-filter-change"
        type="secondary"
        size="small"
        outline={!timetableLegendFilterStore.selectedLegendFilters.includes('change')}
        onClick={() => timetableLegendFilterStore.toggleLegendFilter('change')}
      >
        <span className="timetable-legend-filter--shape-wrapper">
          <LessonCardIndicatorShape type="square" color="#199c5f" hasOutline={true} width={22} height={22} />
        </span>
        {t('general.modification')}
      </Button>
      {timetableIntegrationStore.enabledExternalIntegrations.length > 0 && (
        <Button
          testId="legend-filter-external"
          type="secondary"
          size="small"
          outline={!timetableLegendFilterStore.selectedLegendFilters.includes('external')}
          onClick={() => timetableLegendFilterStore.toggleLegendFilter('external')}
        >
          <span className="timetable-legend-filter--shape-wrapper">
            <LessonCardIndicatorShape type="rhombus" color="#5180F9" hasOutline={true} width={22} height={22} />
          </span>
          {t('general.externalCalendars')}
        </Button>
      )}
      {timetableLegendFilterStore.complexPopoverOptionGroups.length > 0 && (
        <ComplexOptionPopover
          contentTestId={TestIds.TIMETABLE_SETTING_POPOVER}
          icon={{ type: 'more', ariaLabel: 'more' }}
          isOpen={timetableLegendFilterStore.controlledIsPopoverOpen}
          optionGroups={timetableLegendFilterStore.complexPopoverOptionGroups}
        />
      )}
    </div>
  );
});

export default TimetableLegendFilter;
