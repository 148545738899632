import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { RowProps } from 'antd';

import { Col, Row } from '@/ui-components/grid/grid';
import { WUForm } from '@/ui-components';
import { BuildingRefDto, DepartmentRefDto, RoomDto, RoomGroupRefDto } from '@untis/wu-rest-view-api/api';
import { FormColorPicker } from '@/ui-components/wu-form/form-color-picker/form-color-picker';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { FormMultiTagSelect } from '@/ui-components/wu-form/form-multi-tag-select/form-multi-tag-select';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { createMultiSelectAddHandlerWithOptions, validateForm } from '@/utils/form/form-util';
import { FormCheckboxes } from '@/ui-components/wu-form/form-checkboxes/form-checkboxes';
import { RoomViewApi } from '@/stores/api-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { RoomStore } from '@/pages/master-data/room/room-store';
import { FormSection } from '@/ui-components/wu-form/form-section/form-section';
import { FormLabel } from '@/ui-components/wu-form/form-label/form-label';
import { IFormButton } from '@/ui-components/wu-form/wu-form';
import { getResponseError, IResponseError, ResponseErrorType } from '@/utils/error-handling/error-handling';
import { IValidationError } from '@/types/validation-error';
import { TextValidationPatterns } from '@/utils/form/common-form-rules';
import { DeprecatedDropDownItem } from '@/ui-components/deprecated-drop-down/drop-down';
import FormTaggedSwitch from '@/ui-components/wu-form/form-tagged-switch/form-tagged-switch';
import { TestIds } from '@/testIds';
import { textTodo } from '@/utils/text-todo/text-todo';
import deprecatedGetFormColProps from '@md/helpers/deprecatedGetFormColProps';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import { FormTextarea } from '@/ui-components/wu-form';
import { ISelectItem } from '@/ui-components/select/select';

const FORM_ROW_PROPS: RowProps = {
  gutter: [{ xl: 16 }, { xl: 16 }],
  style: { marginTop: '24px' },
};

const FORM_MAIN_ROW_PROPS: RowProps = {
  gutter: [{ xl: 16 }, { xl: 16 }],
  style: { marginTop: '48px' },
};

export interface IRoomForm {
  name: string;
  id?: number;
  longName?: string;
  alternateName?: string;
  externKey?: string;
  text?: string;
  roomType?: string;
  roomGroups?: IMultiTagSelectItem[];
  foreColor?: string;
  backColor?: string;
  capacity?: number;
  examCapacity?: number;
  latestBookingInMinutes?: number;
  building?: string;
  department?: string;
  userInCharge?: IMultiTagSelectItem[];
  resources?: IMultiTagSelectItem[];
  checkboxes: string[];
  active: boolean;
}

interface IProps {
  room?: RoomDto | undefined;
  store: RoomStore;
}

const defaultHexColor = '#000000';

const getMultiSelectValues = (
  value: IRoomForm,
  props: IProps,
): {
  departmentDtos: DepartmentRefDto[];
  roomGroupDtos: RoomGroupRefDto[];
  buildingDtos: BuildingRefDto[];
} => {
  const departmentDtos: DepartmentRefDto[] = [];
  const roomGroupDtos: RoomGroupRefDto[] = [];
  const buildingDtos: BuildingRefDto[] = [];

  value.roomGroups?.forEach((item: IMultiTagSelectItem) => {
    const roomGroupDto = props.store.roomGroups.find((s) => s.id && s.id.toString() === item.id);
    if (roomGroupDto) {
      roomGroupDtos.push(roomGroupDto);
    }
  });

  return {
    departmentDtos,
    roomGroupDtos,
    buildingDtos,
  };
};

const formRoomToRoomDto = (value: IRoomForm, props: IProps): RoomDto => {
  const multiSelectValues = getMultiSelectValues(value, props);
  const {
    name,
    longName,
    alternateName,
    externKey,
    text,
    active,
    roomType,
    userInCharge,
    foreColor,
    backColor,
    capacity,
    examCapacity,
    latestBookingInMinutes,
    building,
    department,
    resources,
    checkboxes,
  } = value;
  const usrInCharge = (userInCharge && userInCharge[0]) || undefined;
  return {
    roomGroups: multiSelectValues.roomGroupDtos,
    id: props.room?.id,
    roomType: props.store.roomTypes.find((rt) => rt.id.toString() === roomType),
    name,
    longName,
    alternateName,
    externKey,
    text,
    active,
    foreColor: foreColor?.replace('#', ''),
    backColor: backColor?.replace('#', ''),
    capacity,
    examCapacity,
    latestBookingInMinutes,
    canBeBooked: checkboxes.includes('canBeBooked'),
    canBeReservedOnly: checkboxes.includes('canBeReservedOnly'),
    requiresSupervision: checkboxes.includes('requiresSupervision'),
    department: props.store.departments.find((dp) => dp.id.toString() === department),
    building: props.store.buildings.find((bld) => bld.id.toString() === building),
    userInCharge: usrInCharge ? { id: parseInt(usrInCharge.id), displayName: usrInCharge.label } : undefined,
    resources: resources?.map(({ id, label }) => ({ id: parseInt(id), displayName: label })),
  };
};

export const RoomForm = observer((props: IProps) => {
  const { store } = props;
  const [canBeBookedChecked, setCanBeBookedChecked] = useState(props.room?.canBeBooked);
  const modalStore = useStore(ModalStore);
  const notificationStore = useStore(NotificationStore);
  const { t } = useTranslation();
  const history = useHistory();
  const isInEditMode = !!props.room;
  const disabled = isInEditMode && !store.canEdit;
  const userFilterTypes = { userGroup: 'userGroup', department: 'department' };
  const resourceFilterTypes = { resourceType: 'resourceType' };

  const roomGroupItems: IMultiTagSelectItem[] = store.roomGroups.map((roomGroup) => {
    return {
      id: roomGroup.id ? roomGroup.id.toString() : '',
      label: roomGroup.displayName ? roomGroup.displayName : '',
    };
  });

  const users: IMultiTagSelectItem[] = store.users.map((person) => {
    const { user, userGroup, department } = person;
    return {
      id: user.id ? user.id.toString() : '',
      label: user.displayName ? user.displayName : '',
      // filter object attrs has to match
      filterObject: {
        [userFilterTypes.userGroup]: `${userGroup?.id}`,
        [userFilterTypes.department]: `${department?.id}`,
      },
    };
  });
  const departments: DeprecatedDropDownItem[] = store.departments.map((department) => {
    return {
      key: department.id ? department.id.toString() : '',
      value: department.displayName ? department.displayName : '',
    };
  });
  const userGroups: DeprecatedDropDownItem[] = store.userGroups.map((userGroup) => {
    return {
      key: userGroup.id ? userGroup.id.toString() : '',
      value: userGroup.displayName ? userGroup.displayName : '',
    };
  });
  const inputNumberRules = [
    {
      validator: (_: any, value: number) => {
        if (value > 32767 || !/^\d{0,5}$/.test(`${value}`)) {
          return Promise.reject(t('masterData.examTypes.form.validation.numberValidationError'));
        }
        return Promise.resolve();
      },
    },
  ];
  const resourceItems: IMultiTagSelectItem[] = store.resources.map((formResource) => {
    const { resource, resourceType } = formResource;
    return {
      id: resource.id.toString(),
      label: resource.displayName ? resource.displayName : '',
      filterObject: { [resourceFilterTypes.resourceType]: `${resourceType?.id}` },
    };
  });
  const resourceTypeItems: DeprecatedDropDownItem[] = store.resourceTypes.map((resourceType) => {
    return {
      key: resourceType.id.toString(),
      value: resourceType.displayName ? resourceType.displayName : '',
    };
  });

  const userFilters = [
    { name: t('general.userGroups'), id: userFilterTypes.userGroup, options: userGroups },
    { name: t('general.departments'), id: userFilterTypes.department, options: departments },
  ];
  const resourceFilters = [
    { name: t('general.resourceTypes'), id: resourceFilterTypes.resourceType, options: resourceTypeItems },
  ];

  const leftButtons: IFormButton[] =
    isInEditMode && store.canDelete
      ? [
          {
            testId: TestIds.MASTER_DATA_DELETE,
            label: t('general.delete'),
            onClick: () => {
              if (room?.id) {
                store.deleteRoom(room.id);
              }
            },
          },
        ]
      : [];

  const rightButtons: IFormButton[] = [];
  if (!isInEditMode) {
    rightButtons.push({
      label: t('general.saveAndNew'),
      testId: TestIds.MASTER_DATA_SAVE_AND_NEW,
      type: 'primary',
      outline: true,
      disabled: store.submitAllDisabled,
      onClick: () => {
        validateForm(store.form).then((isValid) => {
          if (isValid) {
            handleSaveNewRoom(store.form.getFieldsValue(), true);
          }
        });
      },
    });
  }
  rightButtons.push({
    testId: disabled ? TestIds.MASTER_DATA_OK : TestIds.MASTER_DATA_CANCEL,
    label: disabled ? t('general.ok') : t('general.cancel'),
    onClick: () => {
      modalStore.closeModal();
    },
  });

  const handleSaveNewRoom = (value: IRoomForm, saveAndNew: boolean) => {
    const roomDto = formRoomToRoomDto(value, props);

    RoomViewApi.createRoom(roomDto)
      .then((result) => {
        notificationStore.success({ title: t('general.roomCreated') });
        store.onRoomCreated(result.data);
        store.form.resetFields();
        if (!saveAndNew) {
          modalStore.closeModal();
        }
      })
      .catch((error: AxiosError) => {
        const responseError: IResponseError = getResponseError(error);
        if (responseError.type === ResponseErrorType.VALIDATION_ERROR) {
          handleValidationError(responseError);
        } else {
          notificationStore.error({ title: t('general.roomCouldNotBeCreated'), message: error.toString() });
        }
      });
  };

  const handleChangeRoom = (value: IRoomForm) => {
    const roomDto = formRoomToRoomDto(value, props);
    RoomViewApi.updateRoom(roomDto)
      .then((result) => {
        notificationStore.success({ title: t('general.roomEdited') });
        store.onRoomChanged(result.data);
        modalStore.closeModal();
      })
      .catch((error) => {
        const responseError: IResponseError = getResponseError(error);
        if (responseError.type === ResponseErrorType.VALIDATION_ERROR) {
          handleValidationError(responseError);
        } else {
          notificationStore.error({ title: t('general.roomCouldNotBeEdited'), message: error.toString() });
        }
      });
  };

  const handleValidationError = (error: IResponseError): void => {
    const validationErrors: IValidationError[] = error.payload as IValidationError[];
    for (const validationError of validationErrors) {
      if (validationError.path.includes('.name')) {
        store.form.setFields([{ name: 'name', errors: [t(validationError.errorMessage)] }]);
      }
    }
  };

  const initialFormCheckboxValues = [];

  const initialUserInCharge = [];
  if (props.room) {
    props.room.canBeReservedOnly && initialFormCheckboxValues.push('canBeReservedOnly');
    props.room.requiresSupervision && initialFormCheckboxValues.push('requiresSupervision');
    props.room.canBeBooked && initialFormCheckboxValues.push('canBeBooked');
    props.room.userInCharge &&
      initialUserInCharge.push({
        id: props.room.userInCharge?.id?.toString(),
        label: props.room.userInCharge.displayName,
      });
  }

  const isSearchable = true;
  const isSingleSelect = true;

  const room: RoomDto = props.room ?? { active: true, name: '' };

  const initialRoomGroups = room.roomGroups
    ? roomGroupItems.filter((group) => room.roomGroups?.map((sg: any) => sg.id!.toString()).includes(group.id))
    : [];

  const initialResources = room.resources
    ? resourceItems.filter((resource) => room.resources?.map((sg: any) => sg.id!.toString()).includes(resource.id))
    : [];

  const initialBuilding: ISelectItem | undefined = room.building
    ? { id: room.building.id.toString(), label: room.building.displayName! }
    : undefined;

  const initialDepartment: ISelectItem | undefined = room.department
    ? { id: room.department.id.toString(), label: room.department.displayName! }
    : undefined;

  const initialRoomType: ISelectItem | undefined = room.roomType
    ? {
        id: room.roomType.id.toString(),
        label: room.roomType.displayName!,
      }
    : undefined;

  return (
    <WUForm<IRoomForm>
      form={store.form}
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      fixedButtonBar={true}
      testIdSaveButton={TestIds.MASTER_DATA_SAVE}
      hideSubmitButton={disabled}
      onDisableChange={(disabled) => store.setSubmitAllDisabled(disabled)}
      onSubmit={isInEditMode ? handleChangeRoom : (value) => handleSaveNewRoom(value, false)}
      onValuesChange={() => {
        const checkBoxValues = store.form.getFieldValue('checkboxes');
        const roomCanBeBooked = checkBoxValues.includes('canBeBooked');
        setCanBeBookedChecked(roomCanBeBooked);
        !roomCanBeBooked &&
          store.form.setFieldsValue({
            checkboxes: checkBoxValues.filter((item: string) => item !== 'canBeReservedOnly'),
          });
      }}
      maxWidth
    >
      <Col {...deprecatedGetFormColProps({ span: 24 })}>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection text={textTodo('Status')} />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormTaggedSwitch
              name="active"
              checked={room.active}
              initialValue={room.active}
              disabled={!store.canEdit}
              onLabel={t('general.active')}
              offLabel={t('general.inactive')}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 24 })}>
            <FormSection text={t('general.studentDetails.personalInformation')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel text={'* ' + t('general.studentDetails.requiredField')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormInput
              label={`${t('general.name')} *`}
              variant="floating"
              name="name"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              initialValue={room.name ? room.name : ''}
              rules={[
                { required: true, message: t('general.inputRequired') },
                {
                  max: 20,
                  message: t('general.inputTooLong'),
                },
              ]}
              disabled={disabled}
              testId="short-name-input"
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}></Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormInput
              label={t('general.longName')}
              variant="floating"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              name="longName"
              initialValue={room.longName || ''}
              disabled={disabled}
              rules={[
                {
                  max: 100,
                  message: t('general.inputTooLong'),
                },
              ]}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormInput
              label={t('general.aliasName')}
              variant="floating"
              name="alternateName"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              initialValue={room.alternateName || ''}
              disabled={disabled}
              rules={[
                {
                  max: 60,
                  message: t('general.inputTooLong'),
                },
              ]}
            />
          </Col>
        </Row>

        <Row {...FORM_MAIN_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection text={textTodo('Room details')} />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSingleSelect
              items={store?.roomTypes?.map((roomType) => {
                return {
                  id: roomType.id ? roomType.id.toString() : '',
                  label: roomType.displayName || '',
                };
              })}
              placeholder={t('general.roomType')}
              name="roomType"
              initialValue={initialRoomType ? initialRoomType.id : undefined}
              disabled={disabled}
              searchable
              styleVariant="floating"
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSingleSelect
              items={store?.departments?.map((department) => {
                return {
                  id: department.id ? department.id.toString() : '',
                  label: department.displayName || '',
                };
              })}
              placeholder={t('general.departments')}
              name="department"
              initialValue={initialDepartment ? initialDepartment.id : undefined}
              disabled={disabled}
              searchable
              styleVariant="floating"
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSingleSelect
              items={store?.buildings?.map((building) => {
                return {
                  id: building.id ? building.id.toString() : '',
                  label: building.displayName || '',
                };
              })}
              placeholder={t('general.building')}
              name="building"
              initialValue={initialBuilding ? initialBuilding.id : undefined}
              disabled={disabled}
              searchable
              styleVariant="floating"
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel />
          </Col>

          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormInput
              name="capacity"
              label={t('general.capacity')}
              initialValue={room.capacity ? room.capacity : 0}
              disabled={!store.canEdit}
              rules={inputNumberRules}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormInput
              label={t('general.capacityForExams')}
              name="examCapacity"
              initialValue={room.examCapacity ? room.examCapacity : 0}
              disabled={!store.canEdit}
              rules={inputNumberRules}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormInput
              label={t('general.latestBookingInMinutes')}
              name="latestBookingInMinutes"
              initialValue={room.latestBookingInMinutes ? room.latestBookingInMinutes : 0}
              disabled={!store.canEdit}
              rules={inputNumberRules}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel text={t('general.studentDetails.externalIdHint')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormInput
              label={t('general.externalId')}
              variant="floating"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              name="externKey"
              rules={[
                {
                  max: 100,
                  message: t('general.inputTooLong'),
                },
              ]}
              initialValue={room.externKey ?? ''}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormTextarea
              name="text"
              floatingLabel={t('general.text')}
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              initialValue={room.text || ''}
              rules={[
                {
                  max: 255,
                  message: t('general.inputTooLong'),
                },
              ]}
              disabled={!store.canEdit}
            />
          </Col>
        </Row>
        <Row {...FORM_MAIN_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 24 })}>
            <FormSection text={textTodo('Room properties')} />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel text={t('general.personInCharge')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormMultiTagSelect
              name="userInCharge"
              removable
              onAdd={createMultiSelectAddHandlerWithOptions({
                form: store.form,
                name: 'userInCharge',
                items: users,
                title: t('general.personInCharge'),
                modalStore,
                filters: userFilters,
                isSearchable,
                redirectToCreate: () => history.push('/teachers'),
                isSingleSelect: true,
              })}
              isSingleSelect={isSingleSelect}
              initialValue={initialUserInCharge}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel text={t('general.roomGroups')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormMultiTagSelect
              name="roomGroups"
              removable
              onAdd={createMultiSelectAddHandlerWithOptions({
                form: store.form,
                name: 'roomGroups',
                items: roomGroupItems,
                title: t('general.addRoomGroups'),
                modalStore,
                redirectToCreate: () => history.push('/room-groups'),
              })}
              initialValue={initialRoomGroups}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel text={t('general.resources')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormMultiTagSelect
              name="resources"
              removable
              onAdd={createMultiSelectAddHandlerWithOptions({
                form: store.form,
                name: 'resources',
                items: resourceItems,
                title: t('general.addResources'),
                modalStore,
                filters: resourceFilters,
                isSearchable,
                redirectToCreate: () => history.push('/resources'),
              })}
              initialValue={initialResources}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row {...FORM_MAIN_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection text={t('general.settings')} />
          </Col>

          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormCheckboxes
              name="checkboxes"
              options={[
                {
                  label: t('general.canBeBooked'),
                  value: 'canBeBooked',
                },
                {
                  label: t('general.onlyReservation'),
                  value: 'canBeReservedOnly',
                  disabled: !canBeBookedChecked,
                },
                {
                  label: t('general.supervisionArea'),
                  value: 'requiresSupervision',
                },
              ]}
              initialValue={initialFormCheckboxValues}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection text={t('general.colors')} />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormColorPicker
              name="foreColor"
              placeholder={t('general.foregroundColor')}
              testId="room-fore-color"
              initialValue={room.foreColor ? '#' + room.foreColor : defaultHexColor}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormColorPicker
              name="backColor"
              placeholder={t('general.backgroundColor')}
              testId="room-back-color"
              initialValue={room.backColor ? '#' + room.backColor : defaultHexColor}
              disabled={disabled}
            />
          </Col>
        </Row>
      </Col>
    </WUForm>
  );
});
