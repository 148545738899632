import { Row } from 'antd';
import React, { forwardRef, ReactElement, Ref } from 'react';

import { Button, Icon, Tag } from '@/ui-components';

import './sub-menu-header.less';

interface IProps {
  collapsed: boolean;
  isInSubMenu: boolean;
  isSubMenuHeaderOverlap: boolean;
  showMenuItemsFadeAnimationWithIcons: boolean;
  subMenuHeaderTitle: string;
  subMenuHeaderTag?: string;
  handleBack: () => void;
  customHeaderComponent?: {
    component: ReactElement;
    height: number;
  };
  headerHeight: number;
}

const SubMenuHeader = forwardRef((props: IProps, ref: Ref<HTMLDivElement>) => {
  const className =
    'untis-menu-header untis-menu-header--sub' +
    (props.collapsed ? ' untis-menu-header--collapsed' : '') +
    (props.isInSubMenu ? ' untis-menu-header--sub-left' : ' untis-menu-header--sub-right') +
    (props.collapsed
      ? ' untis-menu-header--scale-animation'
      : props.showMenuItemsFadeAnimationWithIcons
      ? ' untis-menu-header--swipe-animation'
      : '');

  const titleColumn = props.collapsed ? null : (
    <div className="title-column" style={{ height: props.headerHeight }}>
      <Row className="title-row">
        <span className="title-text">{props.subMenuHeaderTitle}</span>
        {props.subMenuHeaderTag && (
          <Tag color="orange" uppercase={true} size="lg">
            {props.subMenuHeaderTag}
          </Tag>
        )}
      </Row>
    </div>
  );

  return (
    <>
      <div
        style={{ height: props.headerHeight + (props.customHeaderComponent?.height ?? 0) }}
        ref={ref}
        className={props.isSubMenuHeaderOverlap ? 'shadow-helper shadow-helper-active' : 'shadow-helper'}
      />
      <div
        className={className}
        style={{
          height: props.headerHeight,
        }}
      >
        <div className="wrapper">
          <div className="icon-column">
            <Button
              circle
              className="back-button"
              size="large"
              onClick={props.handleBack}
              tabIndex={props.isInSubMenu ? undefined : -1}
            >
              <Icon type="navigation-back" />
            </Button>
          </div>
          {titleColumn}
          {props.customHeaderComponent?.component && (
            <div
              className="costum-component-container"
              style={{ top: props.headerHeight, height: props.customHeaderComponent.height }}
            >
              {props.customHeaderComponent.component}
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default SubMenuHeader;
