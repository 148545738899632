import React from 'react';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';
import './student-images-upload-error.less';

interface IStudentImagesUploadErrorProps {
  unmatchedFiles: string[] | undefined;
  totalNumberOfFiles: number | undefined;
}

export const StudentImagesUploadError = observer((props: IStudentImagesUploadErrorProps) => {
  const maxShownFilenames = 30;

  const wrongFilenames = () => {
    if (props.unmatchedFiles?.length && props.unmatchedFiles?.length > maxShownFilenames) {
      return props.unmatchedFiles?.slice(0, maxShownFilenames).join(', ') + ', ...';
    } else {
      return props.unmatchedFiles?.join(', ');
    }
  };

  return (
    <div className="import-error-container">
      <h3 className="import-error-header">{t('general.photoImport.matchingError.message')}</h3>
      <h2 className="import-error-summary">
        {t('general.photoImport.matchingError.summary', {
          unmatchedCount: props.unmatchedFiles?.length,
          totalCount: props.totalNumberOfFiles,
        })}
      </h2>
      <p>{wrongFilenames()}</p>
      <p className="import-error-footer">{t('general.photoImport.matchingError.footer')}</p>
    </div>
  );
});
