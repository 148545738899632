import * as React from 'react';
import { ReactNode } from 'react';

import EmptyIndicator from '@/components/empty-indicator/empty-indicator';

interface IProps {
  text: string;
  description?: string;
  children?: ReactNode;
}

/**
 * Component that should render text if no children exist. Can be used to e.g. display an "Empty" message
 * if children rely on certain conditions.
 * e.g.:
 * <EmptyWrapper text="No data available">
 *    {condition && <p>{data}</p>}
 * </EmptryWrapper>
 */
const EmptyWrapper = (props: IProps) => {
  // if passed in children are rendered in conditions like e.g. {condition && <div>Example</div>}
  // then the props.children can contain null and false like e.g. [null, null, false]
  // -> filter those out to determine if the Empty Content should be rendered
  const filteredChildren = React.Children.toArray(props.children).filter((child) => !!child);
  return filteredChildren.length > 0 ? (
    <>{filteredChildren}</>
  ) : (
    <EmptyIndicator title={props.text} description={props.description} />
  );
};

export default EmptyWrapper;
