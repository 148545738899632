import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { IStudentDutyRow, StudentDutiesStore } from '@md/student-duties/student-duties-store';
import { WUForm } from '@/ui-components';
import { FormSection, IFormButton, WUFormCol, WUFormRow } from '@/ui-components/wu-form';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { StudentDutyDto } from '@untis/wu-rest-view-api/api';
import { StudentDutyViewApi } from '@/stores/api-store';
import useStore from '@/hooks/useStore';
import NotificationStore from '@/stores/notification-store/notification-store';
import ModalStore from '@/stores/modal-store';
import { validateForm } from '@/utils/form/form-util';
import FormTaggedSwitch from '@/ui-components/wu-form/form-tagged-switch/form-tagged-switch';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';

export interface IFormStudentDuty {
  name: string;
  longName: string;
  active: boolean;
  type: string;
  periodicity: string;
}

interface IProps {
  studentDuty?: IStudentDutyRow;
  store: StudentDutiesStore;
}

export const StudentDutiesForm = observer((props: IProps) => {
  const { t } = useTranslation();
  const notificationStore = useStore(NotificationStore);
  const modalStore = useStore(ModalStore);
  const { studentDuty, store } = props;
  const isInEditMode = !!props.studentDuty;
  const disabled = isInEditMode && !store.canEdit;

  const formDutyToStudentDutyDto = (value: IFormStudentDuty): StudentDutyDto => {
    return {
      id: props.studentDuty?.id,
      name: value.name,
      longName: value.longName,
      active: value.active,
      type: store.types.find((t) => t.id.toString() === value.type)!,
      periodicity: store.periodicities.find((p) => p.id.toString() === value.periodicity)!,
    };
  };

  const rightButtons: IFormButton[] = [];
  if (!isInEditMode) {
    rightButtons.push({
      label: t('general.saveAndNew'),
      type: 'primary',
      disabled: store.submitAllDisabled,
      outline: true,
      onClick: () => {
        validateForm(store.form).then((isValid) => {
          if (isValid) {
            handleSave(store.form.getFieldsValue(), true);
          }
        });
      },
    });
  }

  rightButtons.push({
    label: disabled ? t('general.ok') : t('general.cancel'),
    onClick: () => {
      modalStore.closeModal();
    },
  });

  const leftButtons: IFormButton[] =
    isInEditMode && store.canDelete
      ? [
          {
            label: t('general.delete'),
            type: 'destructive',
            onClick: () => store.deleteStudentDuty(props.studentDuty!.id),
          },
        ]
      : [];

  const handleSave = (value: IFormStudentDuty, saveAndNew: boolean) => {
    const studentDutyDto: StudentDutyDto = formDutyToStudentDutyDto(value);

    StudentDutyViewApi.create(studentDutyDto).then((response) => {
      notificationStore.success({ title: t('general.studentDutyCreated') });
      store.onDutyCreated(response.data);
      store.form.resetFields();
      if (!saveAndNew) {
        modalStore.closeModal();
      }
    });
  };

  const handleChange = (value: IFormStudentDuty) => {
    store
      .changeStudentDuty(formDutyToStudentDutyDto(value))
      .then(() => {
        notificationStore.success({ title: t('general.studentDutyEdited') });
        store.form.resetFields();
        modalStore.closeModal();
      })
      .catch(() => {
        notificationStore.error({ title: t('general.studentDutyCouldNotBeEdited') });
      });
  };

  const initialType: string | undefined = studentDuty?.dutyType
    ? studentDuty?.dutyType.id.toString()
    : store.initialTypeItem?.id ?? undefined;

  const initialPeriodicity: string | undefined = studentDuty?.periodicity
    ? studentDuty?.periodicity.id.toString()
    : store.initialPeriodicityItem?.id ?? undefined;

  return (
    <WUForm<IFormStudentDuty>
      form={store.form}
      onSubmit={isInEditMode ? handleChange : (value) => handleSave(value, false)}
      hideSubmitButton={disabled}
      rightButtons={rightButtons}
      leftButtons={leftButtons}
      onDisableChange={(disabled) => store.setSubmitAllDisabled(disabled)}
      fixedButtonBar
      hideTopBorder
      maxWidth
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.status')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormTaggedSwitch
              testId="student-duties-active"
              name="active"
              checked={studentDuty?.active}
              initialValue={studentDuty ? studentDuty?.active : true}
              disabled={!store.canEdit}
              onLabel={t('general.active')}
              offLabel={t('general.inactive')}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection noMargin text={t('general.name')} description={`${t('general.asteriskRequired')}`} />
          </WUFormCol>
          <WUFormCol lg={4}>
            <FormInput
              testId="student-duties-name"
              rules={[
                { required: true, message: t('general.inputRequired') },
                { max: 20, message: t('general.valueMustBeWithinRange', { min: 1, max: 20 }) },
              ]}
              name="name"
              variant="floating"
              label={`${t('general.name')}*`}
              initialValue={studentDuty?.name ? studentDuty.name : ''}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormInput
              testId="student-duties-long-name"
              name="longName"
              variant="floating"
              label={t('general.longName')}
              rules={[{ max: 100, message: t('general.valueMustBeWithinRange', { min: 0, max: 100 }) }]}
              initialValue={studentDuty?.fullName ?? ''}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.periodicity')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormSingleSelect
              required
              testId="student-duties-periodicity"
              items={store?.periodicityItems}
              placeholder={t('general.periodicity')}
              name="periodicity"
              initialValue={initialPeriodicity}
              disabled={disabled}
              allowClear={false}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.type')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormSingleSelect
              items={store?.typeItems}
              placeholder={t('general.type')}
              testId="student-duties-type"
              name="type"
              required
              initialValue={initialType}
              disabled={disabled}
              allowClear={false}
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});
