import React from 'react';
import { action, computed, observable } from 'mobx';
import { FormInstance } from 'antd';
import { t } from 'i18next';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';

import {
  DepartmentRefDto,
  TeacherDto,
  TeacherFormDto,
  TeacherQualificationRefDto,
  TeacherStatusRefDto,
  RoomRefDto,
  TeacherVarStatusDto,
} from '@untis/wu-rest-view-api';
import { ElementType } from '@/stores/rights-store';
import { TeacherViewApi } from '@/stores/api-store';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { IDeprecatedSearchBarOption } from '@/ui-components/deprecated-search-bar/deprecated-search-bar';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { booleanSorting, defaultSorting, sortByDateOrString } from '@/utils/sorting/sorting-util';
import { ITeacherForm, TeacherForm } from '@/pages/master-data/teacher/teacher-form';
import { IListViewAction, IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import { createChunks } from '@/utils/array/array-util';
import { inject } from '@/types/store';
import RouterStore from '@/stores/router-store';
import { DeprecatedAbstractListViewStore } from '@/pages/master-data/common/deprecated-abstract-list-view-store';
import { ISelectItem } from '@/ui-components/select/select';

export interface ITeacherRow extends ITableRowKey {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  text: string;
  active: boolean;
  title: string;
  entryDate: Dayjs | undefined;
  exitDate: Dayjs | undefined;
}

export class TeacherStore extends DeprecatedAbstractListViewStore<ITeacherForm> {
  @observable private _teachers: TeacherDto[] = [];
  @observable private _teacherForm: TeacherFormDto | undefined;
  @observable private _selectedOptions: IDeprecatedSearchBarOption[] = [];

  private _routerStore = inject(RouterStore);

  constructor(form: FormInstance<ITeacherForm>) {
    super(form, { elementType: ElementType.TEACHER });
  }

  @computed
  get teacherStatusItems(): ISelectItem[] {
    return this.teacherStatuses.map((status) => {
      return {
        id: status.id.toString(),
        label: status.displayName,
      };
    });
  }

  getInitialStatusByTeacherDto(teacher: TeacherDto | undefined): ISelectItem | undefined {
    const firstStatus: TeacherVarStatusDto | null =
      teacher && teacher.varStatuses && teacher.varStatuses.length > 0 ? teacher.varStatuses[0] : null;

    return this.teacherStatusItems.find((s) => parseInt(s.id) === firstStatus?.status?.id);
  }

  getInitialRoomByTeacherDto(teacher: TeacherDto | undefined): ISelectItem | undefined {
    if (!teacher || !teacher.room) {
      return undefined;
    }

    return {
      id: teacher.room.id.toString(),
      label: teacher.room.displayName || '',
    };
  }

  @action
  async fetchData() {
    await Promise.all([this.fetchTeachers(), this.fetchFormConfig()]);
    this.setIsDataLoading(false);
  }

  @action
  async fetchTeachers() {
    try {
      await TeacherViewApi.getTeachers(this.selectedDepartmentId).then((response: any) => {
        this.setTeachers(response.data.teachers);
      });
    } catch (error) {
      console.error(error);
    }
  }

  @action
  async fetchFormConfig() {
    try {
      await TeacherViewApi.getTeacherForm().then((response: any) => {
        this._teacherForm = response.data;
      });
    } catch (error) {
      console.error(error);
    }
  }

  @computed
  get teachers(): TeacherDto[] {
    return this._teachers;
  }

  @action
  setTeachers(teachers: TeacherDto[]) {
    this._teachers = teachers;
  }

  @computed
  get selectedOptions(): IDeprecatedSearchBarOption[] {
    return this._selectedOptions;
  }

  @action
  setSelectedOptions(options: IDeprecatedSearchBarOption[]) {
    this._selectedOptions = options;
  }

  @computed
  get columns(): Columns<ITeacherRow> {
    const cols: Columns<ITeacherRow> = [
      {
        type: ColumnType.Text,
        key: 'name',
        header: t('general.shortName'),
        sorter: (a, b) => defaultSorting(a.name, b.name),
      },
      {
        type: ColumnType.Text,
        key: 'firstName',
        header: t('general.firstname'),
        sorter: (a, b) => defaultSorting(a.firstName, b.firstName),
      },
      {
        type: ColumnType.Text,
        key: 'lastName',
        header: t('general.lastname'),
        sorter: (a, b) => defaultSorting(a.lastName, b.lastName),
      },
      {
        type: ColumnType.Text,
        key: 'title',
        header: t('general.title'),
        sorter: (a, b) => defaultSorting(a.title, b.title),
      },
      {
        type: ColumnType.Date,
        key: 'entryDate',
        header: t('general.entryDate'),
        sorter: (a, b) => sortByDateOrString(a.entryDate, b.entryDate, a.lastName, b.lastName),
      },
      {
        type: ColumnType.Date,
        key: 'exitDate',
        header: t('general.exitDay'),
        sorter: (a, b) => sortByDateOrString(a.exitDate, b.exitDate, a.lastName, b.lastName),
      },
      {
        type: ColumnType.Text,
        key: 'text',
        header: t('general.text'),
        sorter: (a, b) => defaultSorting(a.text, b.text),
      },
      {
        type: ColumnType.Label,
        key: 'active',
        header: t('general.active'),
        align: 'center',
        sorter: (a, b) => booleanSorting(a.active, b.active),
        getTagProps: (active) => ({
          type: active ? 'success' : 'light-gray',
          text: active ? t('general.active') : t('general.inactive'),
        }),
      },
    ];

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.OverlayHoverActions,
        key: 'actions',
        actionIcons: this.columnActions,
      });
    }

    return cols;
  }

  @computed
  get departments(): DepartmentRefDto[] {
    return this._teacherForm?.departments || [];
  }

  @computed
  get rooms(): RoomRefDto[] {
    return this._teacherForm?.rooms || [];
  }

  @computed
  get teacherQualifications(): TeacherQualificationRefDto[] {
    return this._teacherForm?.teacherQualifications || [];
  }

  @computed
  get teacherStatuses(): TeacherStatusRefDto[] {
    return this._teacherForm?.teacherStatuses || [];
  }

  @computed
  get filteredRows(): ITeacherRow[] {
    return this.teacherRows.filter((teacher: ITeacherRow) => this.containsSearchInput([teacher.name, teacher.text]));
  }

  @computed
  get teacherRows(): ITeacherRow[] {
    return TeacherStore.mapTeachersToRows(this._teachers);
  }

  private static mapTeachersToRows(teachers: TeacherDto[]): ITeacherRow[] {
    return teachers.map((teacher) => {
      return {
        id: teacher.id || -1,
        key: teacher.id || -1,
        name: teacher.name,
        firstName: teacher.firstName || '',
        lastName: teacher.lastName || '',
        entryDate: teacher?.entryDate ? dayjs(teacher.entryDate) : undefined,
        exitDate: teacher?.exitDate ? dayjs(teacher.exitDate) : undefined,
        title: teacher.title || '',
        text: teacher.text || '',
        active: teacher.active === true,
        externKey: teacher.externKey,
      };
    });
  }

  @computed
  private get columnActions() {
    const actions = [
      { tooltip: t('general.edit'), onClick: (row: ITeacherRow) => this.openEditForm(row), icon: 'edit' },
    ];

    if (this.canDelete) {
      actions.push({
        tooltip: t('general.delete'),
        onClick: (row: ITeacherRow) => this.deleteTeacher(row.id),
        icon: 'shared_trash',
      });
    }

    return actions;
  }

  openEditForm(row: ITeacherRow) {
    const teacher = this.getTeacherDtoByRowId(row.id.toString());
    this.form.resetFields();
    if (teacher) {
      this._modalStore.openModalDialog({
        title: `${t('general.teacher')} (${teacher.name})`,
        size: 'full-size',
        children: <TeacherForm teacher={teacher} store={this} />,
        containsForm: true,
      });
    }
  }

  deleteTeacher(id: number) {
    this._modalStore
      .booleanUserPrompt({
        title: t('general.deleteTeacher'),
        hasDestructiveAction: true,
        children: <div>{t('general.deleteTeacherConfirmationQuestion')}</div>,
        showFooterSeparator: true,
        okButton: {
          label: t('general.delete'),
        },
        cancelButton: {
          label: t('general.cancel'),
        },
      })
      .then((result) => {
        if (result) {
          TeacherViewApi.deleteTeacher(id)
            .then(() => {
              this._notificationStore.success({ title: t('general.teacherDeleted') });
              this._modalStore.closeModal();
              this.onTeachersDeleted([id]);
            })
            .catch((error) => {
              const axiosError: AxiosError = error as AxiosError;
              if (axiosError.response?.status === 422) {
                this._notificationStore.error({
                  title: t('general.teacherCouldNotBeDeleted'),
                  message: t('general.teachersInUseCanNotBeDeleted'),
                });
              } else {
                this._notificationStore.error({
                  title: t('general.teacherCouldNotBeDeleted'),
                  message: error.toString(),
                });
              }
            });
        }
      });
  }

  @action.bound
  openCreateForm() {
    this.form.resetFields();
    this._modalStore.openModalDialog({
      children: <TeacherForm store={this} />,
      title: t('general.newTeacher'),
      size: 'full-size',
      containsForm: true,
    });
  }

  @action
  updateTeacher(teacher: TeacherDto) {
    TeacherViewApi.updateTeacher(teacher)
      .then((result: any) => {
        this.onTeacherChanged(result.data);
      })
      .catch((error: any) => {
        this._notificationStore.error({
          title: t('general.teacherCouldNotBeEdited'),
          message: error.toString(),
        });
      });
  }

  @action
  onTeacherChanged(teacher: TeacherDto) {
    this.setTeachers(this._teachers.map((s) => (s.id === teacher.id ? teacher : s)));
  }

  @action
  onTeachersDeleted(deletedTeacherIds: number[]) {
    this.setTeachers(this._teachers.filter((s) => s.id && !deletedTeacherIds.includes(s.id)));
  }

  @action
  onTeacherCreated(teacher: TeacherDto) {
    this.setTeachers([...this._teachers, teacher]);
  }

  @computed
  get listViewActions(): IListViewAction[] {
    const actions: IListViewAction[] = [];
    if (this.canCreate) {
      actions.push({
        label: t('general.new'),
        onClick: this.openCreateForm,
      });
    }
    actions.push({
      label: t('general.report'),
      onClick: () => {
        this.generateReport('Teacher');
      },
    });

    actions.push({
      label: t('general.import'),
      onClick: () => {
        this._routerStore.routing.push('/teachers-import');
      },
    });

    return actions;
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<ITeacherRow>[] | undefined {
    return this.canDelete
      ? [
          {
            label: t('general.delete'),
            icon: 'shared_trash',
            onClick: (rows) => {
              this._modalStore
                .booleanUserPrompt({
                  hasDestructiveAction: true,
                  title: t('general.deleteTeacher', { count: rows.length }),
                  children: (
                    <div>
                      <div>{t('general.deleteTeacherConfirmationQuestion', { count: rows.length })}</div>
                    </div>
                  ),
                  showFooterSeparator: true,
                  okButton: {
                    label: t('general.delete'),
                  },
                  cancelButton: {
                    label: t('general.cancel'),
                  },
                })
                .then((result) => {
                  if (result) {
                    const chunks = createChunks<ITeacherRow>(rows, 100);
                    chunks.forEach((chunk) => {
                      console.debug(chunk);
                      TeacherViewApi.deleteTeachers(chunk.map((row) => row.id))
                        .then(() => {
                          this._notificationStore.success({
                            title: t('general.teachersDeleted'),
                          });
                        })
                        .catch((error) => {
                          const axiosError: AxiosError = error as AxiosError;
                          if (axiosError.response?.status === 422) {
                            // const response: AxiosResponse<EntityRefDto[]> =
                            // axiosError.response as AxiosResponse<EntityRefDto[]>;
                            this._notificationStore.error({
                              title: t('general.someTeachersCouldNotBeDeleted'),
                              message: t('general.teachersInUseCanNotBeDeleted'),
                            });
                          } else {
                            console.debug('error', axiosError);
                            this._notificationStore.error({
                              title: error.toString(),
                            });
                          }
                        });
                    });
                  }
                });
            },
          },
        ]
      : undefined;
  }

  getTeacherDtoByRowId = (id: string): TeacherDto | undefined => {
    return this._teachers.find((teacher) => teacher.id?.toString() === id);
  };
}
