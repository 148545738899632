import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';

import { Icon, DeprecatedTag } from '@/ui-components';
import { CalendarKlasseDto, CalendarRoomDto, CalendarSubjectDto, CalendarTeacherDto } from '@untis/wu-rest-view-api';
import { renderCalendarEntryHeaderElements } from '@/ui-components/calendar-entry-details-header/calendar-entry-details-header-util';
import { PeriodSelectionList } from '@/ui-components/calendar-entry-details-header/period-selection-list';
import useStore from '@/hooks/useStore';
import RouterStore from '@/stores/router-store';

import './calendar-entry-details-header.less';

interface IPeriodDetailsHeaderPeriodPart {
  periodId: number;
  startDateTime: Dayjs;
  endDateTime: Dayjs;
}

export interface IPeriodDetailsHeaderPeriod {
  subject: CalendarSubjectDto | undefined;
  classes: CalendarKlasseDto[];
  teachers: CalendarTeacherDto[];
  removedTeachers: CalendarTeacherDto[]; // original (removed) teacher(s)
  rooms: CalendarRoomDto[];
  removedRooms: CalendarRoomDto[]; // original (removed) room(s)
  studentGroup?: string;
  tags: string[];
  parts: IPeriodDetailsHeaderPeriodPart[];
  lessonNumber?: number;
}

interface IProps {
  periods: IPeriodDetailsHeaderPeriod[];
  selectedPeriodId: number;
  isBlockSelected: boolean;
  isDropdown: boolean;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  idParam: string | undefined;
  dateParam: string | undefined;
  onSelect?: (periodId: number, isBlock: boolean) => void;
  minimized: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const PeriodContent = (props: IProps) => {
  const routerStore = useStore(RouterStore);

  const period = props.periods.find((p) => {
    return p.parts
      .map((part) => {
        return part.periodId;
      })
      .includes(props.selectedPeriodId);
  })!;

  const periodPart = period.parts.find((p) => p.periodId === props.selectedPeriodId);
  const weekDay = periodPart!.startDateTime.format('dddd');
  const date = periodPart!.startDateTime.format('l');
  const { t } = useTranslation();
  let startTime = periodPart!.startDateTime.format('LT');
  let endTime = periodPart!.endDateTime.format('LT');

  if (props.isBlockSelected) {
    startTime = period.parts[0].startDateTime.format('LT');
    endTime = period.parts[period.parts.length - 1].endDateTime.format('LT');
  }

  let className = 'title-row';
  className += props.isDropdown ? ' isDropdown' : '';

  const subject = period.subject;
  const subjectName = subject ? (subject.displayName ? subject.displayName : subject.shortName) : '';
  const displayRooms = period.removedRooms.length > 0 || period.rooms.length > 0;
  const displayTeachers = period.removedTeachers.length > 0 || period.teachers.length > 0;

  return (
    <>
      <div
        className={className}
        onClick={() => {
          if (props.isDropdown) {
            const open = !props.isOpen;
            props.setIsOpen(open);
            open ? props.onOpen && props.onOpen() : props.onClose && props.onClose();
          }
        }}
      >
        <div className="title-content-container">
          <div className="text-container">
            {period.classes.length > 0 &&
              renderCalendarEntryHeaderElements(
                'class',
                [],
                period.classes,
                props.idParam,
                props.dateParam,
                routerStore,
              )}
            {period.studentGroup && (
              <div className="pipe-separator">
                <span>{period.studentGroup}</span>
              </div>
            )}
            {subject ? (
              renderCalendarEntryHeaderElements('subject', [], [subject], props.idParam, props.dateParam, routerStore)
            ) : (
              <div className="pipe-separator">
                <span>{subjectName}</span>
              </div>
            )}
            {period.tags?.map((tag) => (
              <DeprecatedTag key={tag} active>
                {tag.toUpperCase()}
              </DeprecatedTag>
            ))}
          </div>
          {props.isDropdown && <Icon type="dropdown" />}
        </div>
      </div>
      {(!props.minimized || props.isOpen) && (
        <div className="subtitle-row">
          <div className="subtitle-elements-flex-container">
            <div className="pipe-separator">
              <span>{weekDay}</span>
            </div>
            <div className="pipe-separator">
              <span>{date}</span>
            </div>
            <div className="pipe-separator">
              <span>{startTime + '-' + endTime}</span>
            </div>
            {displayRooms &&
              renderCalendarEntryHeaderElements(
                'room',
                period.removedRooms,
                period.rooms,
                props.idParam,
                props.dateParam,
                routerStore,
              )}
            {displayTeachers &&
              renderCalendarEntryHeaderElements(
                'teacher',
                period.removedTeachers,
                period.teachers,
                props.idParam,
                props.dateParam,
                routerStore,
              )}
          </div>
          {period.lessonNumber && (
            <div>
              {t`general.lesson`} Nr.: {period.lessonNumber}
            </div>
          )}
        </div>
      )}
      {props.isOpen && (
        <PeriodSelectionList
          periods={props.periods}
          selectedPeriodId={props.selectedPeriodId}
          isBlockSelected={props.isBlockSelected}
          onSelect={props.onSelect}
        />
      )}
    </>
  );
};

export default PeriodContent;
