import React, { useMemo } from 'react';
import { Menu } from 'antd';

import { IPageHeaderTabs } from '@/ui-components/page/page';

import './page-header-tabs.less';

export const PageHeaderTabs = (props: IPageHeaderTabs) => {
  const selectedTab = useMemo(() => {
    const tabBySelectedTab = props.items.find((tab) => tab.key === props.selectedTab);
    let result: string | undefined;
    if (tabBySelectedTab) {
      result = tabBySelectedTab.key;
    } else if (props.items.length > 0) {
      result = props.items[0].key;
    }
    return result;
  }, [props.selectedTab, props.items]);

  return (
    <div className="page-header-tabs">
      <Menu
        selectedKeys={selectedTab ? [selectedTab] : []}
        mode="horizontal"
        items={props.items}
        onSelect={({ key }) => props.onTabSelected(key)}
      />
    </div>
  );
};
