import React, { ReactElement, ReactNode, useRef, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { BaseSelectRef } from 'rc-select';

import { Icon, Tooltip } from '@/ui-components';
import './drop-down.less';

export interface IDropDownItem {
  id: string;
  label: string;
  alias?: string;
  /* tooltips are currently only supported for single-select*/
  tooltip?: string;
}

type Props = ISingleSelectDropDownProps | IMultiSelectDropDownProps;

const isSingleSelectTypeGuard = (props: Props): props is ISingleSelectDropDownProps => {
  return !props.multi;
};

interface IDropDownArrowButtonProps {
  icon: string;
  onClick: () => void;
  testId?: string;
}

/* not using regular icon buttons here because they have a very different style. */
const DropDownArrowButton = (props: IDropDownArrowButtonProps) => {
  return (
    <button className="drop-down-arrow-button" onClick={props.onClick} data-testid={props.testId}>
      <Icon type={props.icon} />
    </button>
  );
};

const DropDown = (props: Props) => {
  const { t } = useTranslation();
  const selectRef = useRef<BaseSelectRef>(null);
  const [searchValue, setSearchValue] = useState('');
  const placeholder = props.placeholder ?? t('general.pleaseSelect');
  const isSingleSelect = isSingleSelectTypeGuard(props);

  const filterOption = (input: string, antDesignOption: any): boolean => {
    const option = props.items.find((o) => o.id === antDesignOption.key);
    const lowerCaseInput = input.toLowerCase();
    return (
      !!option &&
      (option.label.toLowerCase().includes(lowerCaseInput) ||
        (!!option.alias && option.alias.toLowerCase().includes(lowerCaseInput)))
    );
  };

  const testId = props.testId ? `${props.testId}-drop-down` : undefined;
  const clearTestId = testId ? `${testId}-clear` : undefined;
  const nextTestId = testId ? `${testId}-next` : undefined;
  const previousTestId = testId ? `${testId}-previous` : undefined;

  const onChange = isSingleSelect
    ? (value: string | undefined) => {
        props.onChange && props.onChange(value);
        selectRef.current?.blur();
      }
    : (value: string[]) => {
        props.onChange && props.onChange(value);
      };

  // If multi select, it is always allowed to clear the input, therefore the icon will never be hidden.
  // If single select, icons can not be hidden, if allow clear is set to true.
  // When icons are hidden, we have to adapt the padding to the right to0.
  const hideIcons = isSingleSelect ? props.hideIcons && !props.allowClear : false;

  // when arrow controls are shown, we ignore the other styles like dark or transparent.
  const showArrowControls = isSingleSelect ? props.showArrowControls : false;

  const dropdownClassName = clsx('drop-down', props.className, {
    multi: !isSingleSelect,
    empty: isSingleSelect ? props.value === undefined : props.value?.length === 0,
    searchable: !!props.searchable,
    dark:
      !showArrowControls &&
      props.style === 'dark' &&
      ((isSingleSelect && !!props.value) || (!isSingleSelect && props.value && props.value.length > 0)),
    transparent: !showArrowControls && props.style === 'transparent',
    'show-arrow-controls': showArrowControls,
    'hide-icon': hideIcons,
    // there is a bug in ant design which we have to handle until ant design eventually fixes it:
    // (The width of searchable Multiselects that display the placeholder is too short)
    'apply-fix':
      !isSingleSelect &&
      placeholder !== undefined &&
      searchValue.length === 0 &&
      (props.value === undefined || props.value.length === 0),
  });

  const popUpClassName = clsx('pop-up-container', props.dropdownClassName, {
    multi: !isSingleSelect,
  });

  // If you change this classname, have in mind that there is a workaround for
  // an issue with the "use outside click" hook in place - see sidebar.tsx
  const clearAllClassName = 'clear-all-icon';

  // (take care of beginning space if you change something here)
  const moreText =
    !isSingleSelect && props.value
      ? ` ${t('general.plusXMore', {
          count: props.value!.length - props.maxDisplayedItems!,
        })}`
      : undefined;

  const selectNext = () => {
    if (!isSingleSelect || props.items.length === 0) {
      return;
    }

    const currentIndex = props.items.findIndex((item) => item.id === props.value);
    const nextIndex = !props.value || currentIndex === props.items.length - 1 ? 0 : currentIndex + 1;
    props.onChange && props.onChange(props.items[nextIndex].id);
  };

  const selectPrevious = () => {
    if (!isSingleSelect || props.items.length === 0) {
      return;
    }

    const currentIndex = props.items.findIndex((item) => item.id === props.value);
    const previousIndex = !props.value || currentIndex === 0 ? props.items.length - 1 : currentIndex - 1;
    props.onChange && props.onChange(props.items[previousIndex].id);
  };

  return (
    <div className="drop-down-container">
      {isSingleSelect && props.showArrowControls && (
        <DropDownArrowButton icon="arrow-left" onClick={selectPrevious} testId={previousTestId} />
      )}
      <Select
        mode={!isSingleSelect ? 'multiple' : undefined}
        className={dropdownClassName}
        dropdownClassName={popUpClassName}
        value={props.value}
        placeholder={placeholder}
        showSearch={props.searchable === true}
        filterOption={(inputValue, option) => filterOption(inputValue, option)}
        suffixIcon={<Icon type="dropdown" />}
        disabled={props.disabled}
        onChange={onChange as unknown as any}
        open={props.open}
        showArrow={!hideIcons}
        dropdownMatchSelectWidth={200}
        maxTagCount={!isSingleSelect ? props.maxDisplayedItems : undefined}
        maxTagPlaceholder={moreText}
        allowClear={!isSingleSelect || (isSingleSelect && props.allowClear)}
        tagRender={(tag) => {
          return <span className="ant-select-selection-item">{tag.label}</span>;
        }}
        menuItemSelectedIcon={!isSingleSelect ? <Icon type="activation" /> : undefined}
        optionLabelProp="renderedSelectedItem"
        clearIcon={
          <div className={clearAllClassName} data-testid={clearTestId}>
            <Icon type="cancel-circle-filled" />
          </div>
        }
        notFoundContent={<span className="no-results">{t('general.noSearchResults')}</span>}
        data-testid={testId}
        id={props.id}
        style={{
          minWidth: props.minWidth ? `${props.minWidth}px` : undefined,
          maxWidth: props.maxWidth ? `${props.maxWidth}px` : undefined,
        }}
        onDropdownVisibleChange={(visible) => {
          props.onDropdownVisibleChange && props.onDropdownVisibleChange(visible);
          if (!visible) {
            setSearchValue('');
          }
        }}
        dropdownRender={props.dropdownRender}
        listHeight={props.listHeight}
        onSearch={(value) => setSearchValue(value)}
        onClear={() => {
          setSearchValue('');
        }}
        ref={selectRef}
      >
        {props.items.map((item) => {
          return (
            <Select.Option
              title={item.label}
              key={item.id}
              value={item.id}
              label={item.label}
              renderedSelectedItem={renderSelectedItem(item)}
            >
              {item.label}
              {item.alias && <span> | {item.alias}</span>}
            </Select.Option>
          );
        })}
      </Select>
      {isSingleSelect && props.showArrowControls && (
        <DropDownArrowButton icon="arrow-right" onClick={selectNext} testId={nextTestId} />
      )}
    </div>
  );
};

const renderSelectedItem = (item: IDropDownItem): ReactNode => {
  const renderedItem = (
    <div className="custom-selected-item">
      <div className="item-label">{item.label}</div>
      {item.tooltip && <Icon type="info-circle" />}
    </div>
  );

  return item.tooltip ? <Tooltip title={item.tooltip}>{renderedItem}</Tooltip> : renderedItem;
};

export type DropDownStyle = 'dark' | 'transparent';

interface ICommonProps {
  placeholder?: string;
  searchable?: boolean;
  items: IDropDownItem[];
  disabled?: boolean;
  /* style will be ignored for single dropdown if showArrowControls is true */
  style?: DropDownStyle;
  id?: string;
  testId?: string;
  maxWidth?: number;
  minWidth?: number;
  className?: string;
  onDropdownVisibleChange?: (open: boolean) => void;
  dropdownRender?: (menu: ReactElement) => ReactElement;
  dropdownClassName?: string;
  listHeight?: number;
  open?: boolean;
}

export interface ISingleSelectDropDownProps extends ICommonProps {
  multi: false;
  value?: string | undefined;
  onChange?: (value: string | undefined) => void;
  allowClear?: boolean;
  /* hides the arrow- and clear icon to save space, therefore it is not compatible with the "allowClear" prop */
  hideIcons?: boolean;
  /* showing the arrow controls disables "style" property from ICommonProps. Dropdown must match arrow control styles*/
  showArrowControls?: boolean;
}

export const SingleDropDown = (props: Omit<ISingleSelectDropDownProps, 'multi'>) => {
  return <DropDown {...props} multi={false} />;
};

export interface IMultiSelectDropDownProps extends ICommonProps {
  multi: true;
  value?: string[];
  onChange?: (values: string[]) => void;
  maxDisplayedItems?: number;
}

export const MultiDropDown = (props: Omit<IMultiSelectDropDownProps, 'multi'>) => {
  return <DropDown {...props} multi={true} />;
};
