import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PlatformCard from '../platform-card/platform-card';

import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import PlatformStore, { IPlatformListApplication } from '@pa/stores/platform-store';
import { Button, Card, Skeleton } from '@/ui-components';
import useStore from '@/hooks/useStore';

interface ISkeletonRowProps {
  active?: boolean;
  bordered?: boolean;
}

const SkeletonCard = ({ active, bordered }: ISkeletonRowProps) => (
  <>
    <Card className="platform-card" bordered={bordered} tabIndex={0} style={{ pointerEvents: 'none' }}>
      <div className="platform-info">
        <Skeleton.Avatar className="icon icon--skeleton" active={active} size={44} />
        <span className="skeleton-text">
          <div>
            <Skeleton.Input className="skeleton-input" active={active} size="small" />
          </div>
          <div>
            <Skeleton.Input className="skeleton-input" active={active} size="small" />
          </div>
        </span>
      </div>
    </Card>
  </>
);

interface IProps {
  isLoading: boolean;
  platforms: IPlatformListApplication[];
  isRegisterMode: boolean;
}

const PlatformList = observer((props: IProps) => {
  const { t } = useTranslation();
  const platformStore = useStore(PlatformStore);

  function handleNewButtonClick() {
    platformStore.openRegisterNewPlatformView();
  }

  if (props.isLoading) {
    return (
      <>
        <SkeletonCard active bordered={props.isRegisterMode} />
        <SkeletonCard active bordered={props.isRegisterMode} />
        <SkeletonCard active bordered={props.isRegisterMode} />
      </>
    );
  }

  if (props.platforms.length <= 0) {
    return (
      <EmptyIndicator
        title={props.isRegisterMode ? t('platform.noFurtherApplications') : t('platform.noApplicationsActivated')}
        description={!props.isRegisterMode ? t('general.addYourFirstPlatformApplication') : undefined}
        className="application-list-empty-indicator"
        card={
          <>
            <SkeletonCard bordered={props.isRegisterMode} />
          </>
        }
      >
        {!props.isRegisterMode && (
          <Button type="primary" size="large" onClick={handleNewButtonClick}>
            {t`general.addApplication`}
          </Button>
        )}
      </EmptyIndicator>
    );
  }

  return (
    <div className="platform-list">
      {props.platforms.map((app) => (
        <PlatformCard key={app.id} platform={app} isRegisterMode={props.isRegisterMode} />
      ))}
    </div>
  );
});
export default PlatformList;
