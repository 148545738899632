import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExcuseStatusStore, IExcuseStatusRow } from './excuse-status-store';

import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { WUForm } from '@/ui-components';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { FormSection } from '@/ui-components/wu-form/form-section/form-section';
import { IFormButton } from '@/ui-components/wu-form/wu-form';
import { validateForm } from '@/utils/form/form-util';
import NotificationStore from '@/stores/notification-store/notification-store';
import FormTaggedSwitch from '@/ui-components/wu-form/form-tagged-switch/form-tagged-switch';
import { FormCheckbox, WUFormCol, WUFormRow } from '@/ui-components/wu-form';

export interface IExcuseStatusFormData {
  id: number;
  name: string;
  longName: string;
  active: boolean;
  excused: boolean;
  key: number;
}

interface IProps {
  store: ExcuseStatusStore;
  excuseStatus?: IExcuseStatusRow;
}

export const ExcuseStatusForm = observer((props: IProps) => {
  const store = props.store;
  const { t } = useTranslation();
  const modalStore = useStore(ModalStore);
  const isInEditMode = !!props.excuseStatus;
  const notificationStore = useStore(NotificationStore);
  const disabled = isInEditMode && !store.canEdit;

  const leftButtons: IFormButton[] =
    isInEditMode && store.canDelete
      ? [
          {
            label: t('general.delete'),
            type: 'destructive',
            onClick: () => {
              if (props.excuseStatus && props.excuseStatus?.id) {
                store.deleteExcuseStatus(props.excuseStatus.id);
              }
            },
          },
        ]
      : [];

  const rightButtons: IFormButton[] = [];
  if (!isInEditMode) {
    rightButtons.push({
      label: t('general.saveAndNew'),
      type: 'primary',
      outline: true,
      disabled: store.submitAllDisabled,
      onClick: () => {
        validateForm(store.form).then((isValid) => {
          if (isValid) {
            handleSaveNewExcuseStatus(store.form.getFieldsValue(), true);
          }
        });
      },
    });
  }
  rightButtons.push({
    label: disabled ? t('general.ok') : t('general.cancel'),
    onClick: () => {
      modalStore.closeModal();
    },
  });

  const handleSaveNewExcuseStatus = (value: IExcuseStatusFormData, saveAndNew: boolean) => {
    store
      .createExcuseStatus(value)
      .then(() => {
        notificationStore.success({ title: t('general.excuseStatusCreated') });
        store.form.resetFields();
        if (!saveAndNew) {
          modalStore.closeModal();
        }
      })
      .catch((error) => {
        notificationStore.error({
          title: t('general.excuseStatusCouldNotBeCreated'),
          message: t('menu.menuItems.' + error.response.data.validationErrors[0].errorMessage),
        });
      });
  };

  const handleChangeExcuseStatus = (value: IExcuseStatusFormData) => {
    store
      .onExcuseStatusChanged(value, props.excuseStatus!)
      .then(() => {
        notificationStore.success({ title: t('general.excuseStatusUpdated') });
        store.form.resetFields();
        modalStore.closeModal();
      })
      .catch(() => {
        notificationStore.error({ title: t('general.excuseStatusCouldNotBeEdited') });
      });
  };

  return (
    <WUForm<IExcuseStatusFormData>
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      fixedButtonBar={true}
      form={store.form}
      hideSubmitButton={disabled}
      onDisableChange={(disabled) => store.setSubmitAllDisabled(disabled)}
      onSubmit={
        isInEditMode && store.canEdit
          ? (value) => handleChangeExcuseStatus(value)
          : (value) => handleSaveNewExcuseStatus(value, false)
      }
      maxWidth
      hideTopBorder
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.status')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormTaggedSwitch
              testId="excuse-status-active"
              name="active"
              checked={props?.excuseStatus?.active}
              initialValue={props?.excuseStatus ? props?.excuseStatus?.active : true}
              disabled={!store.canEdit}
              onLabel={t('general.active')}
              offLabel={t('general.inactive')}
            />
          </WUFormCol>
        </WUFormRow>
        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection description={t('general.asteriskRequired')} text={t('general.name')} centered />
          </WUFormCol>
          <WUFormCol lg={4}>
            <FormInput
              name="name"
              testId="excuse-status-name"
              variant="floating"
              label={`${t('general.name')}*`}
              initialValue={props?.excuseStatus?.name ? props.excuseStatus.name : ''}
              rules={[
                { required: true, message: t('general.inputRequired') },
                { max: 30, message: t('general.valueMustBeWithinRange', { min: 1, max: 30 }) },
              ]}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              name="longName"
              testId="excuse-status-long-name"
              variant="floating"
              label={t('general.longName')}
              initialValue={props.excuseStatus && props?.excuseStatus.longName ? props.excuseStatus.longName : ''}
              disabled={disabled}
              rules={[{ max: 100, message: t('general.valueMustBeWithinRange', { min: 0, max: 100 }) }]}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow noMarginBottom>
          <WUFormCol lg={2}>
            <FormSection text={`${t('general.excused')}`} description={t('general.excuseStatusDesc')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormCheckbox
              testId="excuse-status-checkbox"
              name="excused"
              text={t('general.excuseCounts')}
              initialValue={props.excuseStatus ? props.excuseStatus.excused : false}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});
