import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeprecatedSearchBar } from '../../../ui-components/deprecated-search-bar/deprecated-search-bar';

import { IMasterDataDto } from '@/pages/calendar-entry/types';
import { Button, Col } from '@/ui-components';
import './provisional-dto-selector.less';
import { DeprecatedFullsizeModalWrapper } from '@/ui-components/deprecated-modal/deprecated-fullsize-modal-wrapper';
import DeprecatedPageHeader from '@/ui-components/deprecated-page-header/deprecated-page-header';
import { ISelectOptionListItem } from '@/ui-components/select-option-list/select-option-list';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { ComplexCheckbox } from '@/ui-components/complex-checkbox/complex-checkbox';
import SelectOptionListItem from '@/ui-components/select-option-list/select-option-list-item/select-option-list-item';
import { IItemType } from '@/pages/calendar-entry/create-calendar-entry/create-calendar-entry-form-store';

interface IProps {
  items: IMasterDataDto[];
  initialSelected: IMasterDataDto[];
  onOk: (items: IMasterDataDto[]) => void;
  title: string;
  itemType?: IItemType;
}

const icon = (itemType: IItemType | undefined): string => {
  return itemType?.isTeacher ? '' : 'classes_daily';
};
/**
 *  Temporary component to enable the user to select Master Data DTOs for the CalendarEntryForm Component.
 *  Will be removed as soon the proper selection of all master data types is implemented.
 */
const ProvisionalDtoSelector = (props: IProps) => {
  const modalStore = useStore(ModalStore);

  const [selected, setSelected] = useState(props.initialSelected);
  const [searchBarValue, setSearchBarValue] = useState('');

  const { t } = useTranslation();

  const addItem = (key: number) => {
    const newItems = selected.map((item) => item);
    newItems.push(props.items.find((item) => item.id === key)!);
    setSelected(newItems);
  };

  const removeItem = (key: number) => {
    const newItems = selected.filter((item) => item.id !== key);
    setSelected(newItems);
  };

  const handleSubmit = () => {
    props.onOk(selected);
  };

  const filteredItems = React.useMemo(() => {
    let filtered = props.items;

    if (searchBarValue.trim().length > 0) {
      filtered = filtered.filter((item) => {
        return item.displayName.toLowerCase().includes(searchBarValue.toLowerCase());
      });
    }

    return filtered;
  }, [props.items, searchBarValue]);

  const renderItems = () => {
    return filteredItems.map((item) => {
      const checked = selected.map((s) => s.id).includes(item.id);

      const handleChange = (e: CheckboxChangeEvent) => {
        e.target.checked ? addItem(item.id) : removeItem(item.id);
      };

      const mappedItem = (): ISelectOptionListItem => {
        return {
          id: item.id,
          value: item.id,
          name: item.displayName,
          icon: icon(props.itemType),
        };
      };
      return (
        <div className="item-wrapper" key={item.id}>
          <ComplexCheckbox value={checked} checked={checked} onChange={handleChange}>
            <SelectOptionListItem item={mappedItem()} isSingleSelect={false} />
          </ComplexCheckbox>
        </div>
      );
    });
  };

  return (
    <DeprecatedFullsizeModalWrapper
      className="provisional-selection-dialog"
      footerChildren={
        <>
          <Button
            outline
            size="large"
            onClick={() => {
              modalStore.closeModal();
            }}
          >
            {t('general.cancel')}
          </Button>
          <Button size="large" type="primary" onClick={handleSubmit}>
            {t('general.save')}
          </Button>
        </>
      }
    >
      <DeprecatedPageHeader>
        <Col span={24}>
          <h1>{props.title}</h1>
        </Col>
      </DeprecatedPageHeader>
      <div className="content">
        <DeprecatedSearchBar
          value={searchBarValue}
          onChange={(value) => setSearchBarValue(value)}
          placeholder={props.itemType?.isTeacher ? t('general.searchTeacher') : t('general.searchClass')}
        />
        <div className="item-container">{renderItems()}</div>
      </div>
    </DeprecatedFullsizeModalWrapper>
  );
};

export default ProvisionalDtoSelector;
