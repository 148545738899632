import { Tag as AntTag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import React from 'react';
import clsx from 'clsx';

import './deprecated-tag.less';

export type DeprecatedTagType = 'light-gray' | 'success' | 'warning' | 'error' | 'conflict' | 'blue-dark' | 'white';

export interface IDeprecatedTagTypeTagProps extends TagProps {
  active?: boolean;
  className?: string;
  type?: DeprecatedTagType;
  outline?: boolean;
  rounded?: boolean;
}

/**
 * @deprecated in favor of Tag
 */
export const DeprecatedTag = (props: IDeprecatedTagTypeTagProps) => {
  const { active, type, children, outline, rounded } = props;

  return (
    <AntTag
      className={clsx(
        'untis-tag',
        {
          'untis-tag--active': active,
          ['untis-tag--' + type]: type,
          outline: outline,
          rounded: rounded,
        },
        props.className,
      )}
    >
      {children}
    </AntTag>
  );
};
