import React, { useState, useCallback } from 'react';
import { Form } from 'antd';

import DeprecatedDropDown, { DeprecatedDropDownItem } from '@/ui-components/deprecated-drop-down/drop-down';
import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';

interface IProps extends IFormItemProps<string> {
  items: DeprecatedDropDownItem[];
  placeholder?: string;
  value?: DeprecatedDropDownItem;
  searchable?: boolean;
  onChange?: (item: string | undefined) => void;
  onItemSelected?: (item: string) => void;
  canUndoSelection?: boolean;
  rightAlign?: boolean;
}

/** *
 * @deprecated in favor of FormSingleDropDown
 */
export const DeprecatedFormDropdown = (props: IProps) => {
  const [selectedKey, setSelectedKey] = useState(() => props.initialValue?.toString() ?? '');

  const handleOnSelect = useCallback((item: DeprecatedDropDownItem) => {
    setSelectedKey(item.key);
    props.onItemSelected && props.onItemSelected(item.key);
  }, []);

  return (
    <Form.Item
      name={props.name}
      label={props.label}
      valuePropName="selectedKey"
      className="form-dropdown"
      rules={props.rules}
      dependencies={props.dependencies}
      initialValue={props.initialValue}
    >
      <DeprecatedDropDown
        selectedKey={selectedKey}
        onSelect={handleOnSelect}
        items={props.items}
        placeholder={props.placeholder}
        disabled={props.disabled}
        canUndoSelection={props.canUndoSelection}
        rightAlign={props.rightAlign}
        searchable={props.searchable}
        testId={props.testId}
      />
    </Form.Item>
  );
};
