import React from 'react';
import { Checkbox as AntDCheckbox, Form } from 'antd';
import clsx from 'clsx';

import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import { Checkbox } from '@/ui-components/checkbox/checkbox';

import './form-chexboxes.less';

type CheckboxOption = {
  label: string;
  value: string;
  disabled?: boolean;
  testId?: string;
};

interface IProps extends IFormItemProps<string[]> {
  options: CheckboxOption[];
  verticalAlign?: boolean;
  onChange?: (value: string[]) => void;
}

export const FormCheckboxes = (props: IProps) => {
  return (
    <Form.Item
      name={props.name}
      label={props.label}
      initialValue={props.initialValue}
      rules={props.rules}
      dependencies={props.dependencies}
    >
      <AntDCheckbox.Group
        options={props.options}
        disabled={props.disabled}
        className={clsx({
          'vertical-align': !!props.verticalAlign,
        })}
        onChange={(checkedValue) => props.onChange && props.onChange(checkedValue)}
        data-testid={props.testId}
      >
        {props.options.map((option) => {
          return (
            <Checkbox
              key={option.label}
              name={option.value}
              label={option.label}
              disabled={option.disabled}
              testId={option.testId}
            />
          );
        })}
      </AntDCheckbox.Group>
    </Form.Item>
  );
};
