import React from 'react';
import { observer } from 'mobx-react-lite';

import SendMessageViewStore, { SendMessageViewEnum } from '@mg/stores/send-message-view-store';
import { SendMessageEditor } from '@mg/components/send-message-editor/send-message-editor';
import { RecipientPicker } from '@mg/components/recipient-picker/recipient-picker';
import { useComponentWillUnmount } from '@/hooks/useComponentWillUnmount';
import useStore from '@/hooks/useStore';

import './send-message-view.less';

const SendMessageView = observer(() => {
  const sendMessageViewStore = useStore(SendMessageViewStore);

  useComponentWillUnmount(() => {
    sendMessageViewStore.reset();
  });

  return (
    <div className="send-message-modal">
      {sendMessageViewStore.view === SendMessageViewEnum.EDITOR && <SendMessageEditor />}
      {sendMessageViewStore.view === SendMessageViewEnum.RECIPIENTS_LIST && (
        <RecipientPicker mainActions={sendMessageViewStore.recipientPickerMainActions} />
      )}
    </div>
  );
});

export default SendMessageView;
