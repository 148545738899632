interface ITextWithMeasureOptions {
  fontSize?: number;
  fontWeight?: number;
  font?: string;
}

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');

export const measureTextWidth = (textToMeasure: string, options?: ITextWithMeasureOptions): number | undefined => {
  const font = `${options?.fontWeight ?? 400} ${options?.fontSize ?? 14}px ${options?.font ?? ''}`;
  if (ctx) {
    ctx.font = font;
    return ctx.measureText(textToMeasure).width;
  } else {
    return undefined;
  }
};
