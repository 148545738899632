import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { MotdCard } from '../motd-card/motd-card';

import { DashboardCardStatusDto } from '@untis/wu-rest-view-api';
import useStore from '@/hooks/useStore';
import TodayMessagesOfTheDayStore from '@today/stores/today-messages-of-the-day-store';
import { Carousel } from '@/ui-components/carousel/carousel';

import './motd-card-carousel.less';

const MotdCardCarousel = observer(() => {
  const { t } = useTranslation();

  const todayMessagesOfTheDayStore = useStore(TodayMessagesOfTheDayStore);

  return (
    ((todayMessagesOfTheDayStore.isLoading || todayMessagesOfTheDayStore.messagesOfTheDay.length) && (
      <Carousel className="today-page-motd-card-carousel" variant="motd" itemsToScroll={3}>
        {todayMessagesOfTheDayStore.isLoading ? (
          <>
            <MotdCard.Skeleton />
            <MotdCard.Skeleton />
            <MotdCard.Skeleton />
          </>
        ) : (
          todayMessagesOfTheDayStore.messagesOfTheDay.map((card) => (
            <MotdCard
              newTag={card.status === DashboardCardStatusDto.UNREAD ? t('general.new') : ''}
              key={card.id}
              title={card.title}
              subtitle={card.subtitle}
              hasAttachment={card.hasAttachments}
              headerColor={card.headerColor}
              headerIcon={card.icon}
              onClick={() => todayMessagesOfTheDayStore.openMessagesOfTheDayDetail(card.id)}
            />
          ))
        )}
      </Carousel>
    )) || <></>
  );
});

export default MotdCardCarousel;
