import { InputNumber, Space, Tooltip } from 'antd';
import { ColumnsType as AntDesignTableColumns, ColumnType as AntDesignColumnType } from 'antd/lib/table/interface';
import classNames from 'clsx';
import * as React from 'react';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { v4 } from 'uuid';
import { t } from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { AsyncButton } from '@/ui-components/async-button/async-button';
import { ICellTextStyle } from '@/ui-components/wu-table/wu-table';
import {
  AmountIndicator,
  Button,
  ColorDisplay,
  DeprecatedTag,
  DeprecatedTagType,
  Flex,
  Icon,
  IconButton,
  Input,
  OptionPopup,
  Stack,
  Tag,
  UserAvatar,
} from '@/ui-components';
import { ExpandableList } from '@/ui-components/wu-table/expandable-list/expandable-list';
import WUTableHeaderIcon from '@/ui-components/wu-table/wu-table-header-icon';
import {
  formatDate,
  formatDateRange,
  formatDateTime,
  formatDateTimeRange,
  formatTime,
  formatTimeRange,
} from '@/utils/date/date-util';
import { IDeprecatedAttachment } from '@/ui-components/deprecated-attachment/deprecated-attachment';
import TextPopover from '@/ui-components/text-popover/text-popover';
import { TagColor } from '@/ui-components/tag/tag';
import { DeprecatedAttachments } from '@/ui-components/deprecated-attachments/deprecated-attachments';
import { DropDownStyle, IDropDownItem, SingleDropDown } from '@/ui-components/drop-down/drop-down';
import { ISubstitutionTextProps, SubstitutionText } from '@/ui-components/substitution-text/substitution-text';
import { IFloatingLabelInputProps, IInputDefaultProps, IInputLargeProps } from '@/ui-components/input/input';
import { Checkbox } from '@/ui-components/checkbox/checkbox';

export enum ButtonType {
  Sync,
  Async,
}

export enum ColumnType {
  Text,
  HoverActions,
  OverlayHoverActions,
  DeprecatedTag,
  Tag,
  Button,
  DateRange,
  List,
  Custom,
  Avatar,
  Boolean,
  Indicator,
  DropDownActions,
  SingleDropDown,
  TextArray,
  Absences,
  TextOrButton,
  Date,
  Icon,
  Attachments,
  TextPopover,
  Color,
  Label,
  Number,
  SubstitutionText,
}

/**
 * Optional selection of date format to be displayed in the table <br>
 * Date (Default) -> 01.12.2022 <br>
 * DateTime -> 01.12.2022 08:00
 */
export enum DateFormat {
  Date,
  Time,
  DateTime,
}

/**
 * Optional selection of time range format to be displayed in the table <br>
 * TimeRange (Default) -> 08:00 - 08:45 <br>
 * DateTimeRange -> 01.12.2022 08:00 - 08:45 <br>
 * MultipleDatesTimeRange -> 01.12.2022 08:00 - 02.12.2022 08:45
 */
export enum DateRangeFormat {
  DateRange,
  TimeRange,
  DateTimeRange,
}

// Note: If you add a new Column, you will need to adapt the "genericColumns" array
// in the table component, so that the column has access to the row data at runtime.
export type Column<T> =
  | ITextColumn<T>
  | IHoverActionsColumn<T>
  | IOverlayHoverActionsColumn<T>
  | IDeprecatedTagColumn<T>
  | ITagColumn<T>
  | ICustomColumn<T>
  | IButtonColumn<T>
  | IDateRangeColumn<T>
  | IListColumn<T>
  | IAvatarColumn<T>
  | IBooleanColumn<T>
  | IIndicatorColumn<T>
  | IDropDownActions<T>
  | ISingleDropDownColumn<T>
  | ITextArrayColumn<T>
  | IAbsencesColumn<T>
  | ITextOrButtonColumn<T>
  | IDateColumn<T>
  | IIconColumn<T>
  | IAttachmentsColumn<T>
  | ITextPopoverColumn<T>
  | IColorColumn<T>
  | ILabelColumn<T>
  | INumberColumn<T>
  | ISubstitutionTextColumn<T>;

export type Columns<T> = Column<T>[];

// The 'type' attribute is a type guard attribute.
// Devs must set this and therefore typescripts type inference knows exactly, which attributes
// are allowed in the column definitions below.
export interface IColumn<T> {
  type: ColumnType;
  header?: string;
  key: string;
  align?: 'left' | 'right' | 'center';
  className?: string;
  width?: string | number;
  sorter?: (a: T, b: T) => number;
  noPaddingLeft?: boolean;
  fixed?: 'left' | 'right';
  defaultSortOrder?: 'ascend' | 'descend';
  headerIcon?: {
    icon: string;
    tooltip: string;
  };
}

export interface ITextColumn<T> extends IColumn<T> {
  type: ColumnType.Text;
  textStyle?: ICellTextStyle;
  edit?: {
    defaultValue?: string | number;
    onChange: (
      row: T,
      key: keyof T | string,
      value: string | number,
      setVal: Dispatch<SetStateAction<string | undefined>>,
    ) => void;
    onBlur?: (row: T, key: keyof T | string, value: string | number) => void;
    getValue: (row: T) => string;
    inputProps?:
      | Omit<IInputDefaultProps, 'onChange' | 'onBlur'>
      | Omit<IInputLargeProps, 'onChange' | 'onBlur'>
      | Omit<IFloatingLabelInputProps, 'onChange' | 'onBlur'>;
    disabled?: boolean;
  };
  ellipsis?: boolean;
  className?: string;
}

export interface INumberColumn<T> extends IColumn<T> {
  type: ColumnType.Number;
  getValue: (row: T) => number;
  edit?: {
    defaultValue?: number;
    onChange: (
      row: T,
      key: keyof T | string,
      value: number,
      setVal: Dispatch<SetStateAction<number | undefined>>,
    ) => void;
    onBlur?: (row: T, key: keyof T | string, value: number) => void;
    min?: number;
    max?: number;
    /* optional function to check, if a value change really should be applied. E.g.: if you want to avoid, that the sum
    of all values in the table is bigger than a certain threshold. */
    validate?: (row: T, newValue: number) => boolean;
    disabled?: boolean;
  };
}

export interface ITextArrayColumn<T> extends IColumn<T> {
  type: ColumnType.TextArray;
  getTexts: (row: T) => { value: string; strike?: boolean }[];
}

export interface IDeprecatedTagColumn<T> extends IColumn<T> {
  type: ColumnType.DeprecatedTag;
  variant?: ((row: T) => DeprecatedTagType) | DeprecatedTagType;
}

export interface ITagColumn<T> extends IColumn<T> {
  type: ColumnType.Tag;
  tags: (row: T) => TagProps[];
}

export interface ISubstitutionTextColumn<T> extends IColumn<T> {
  type: ColumnType.SubstitutionText;
  getSubstitutions: (row: T) => ISubstitutionTextProps[];
}

export interface IButtonColumn<T> extends IColumn<T> {
  type: ColumnType.Button;
  buttons: (IAsyncButtonDefinition<T> | ISyncButtonDefinition<T>)[];
}

export interface ICustomColumn<T> extends IColumn<T> {
  type: ColumnType.Custom;
  render?: (row: T) => ReactNode;
}

interface IHoverActionIcon<T> {
  icon: string;
  tooltip?: string | ((row: T) => string);
  label?: string;
  ariaLabel?: string;
  onClick?: (row: T, event?: React.BaseSyntheticEvent) => void;
  condition?: (row: T) => boolean; // condition to decide if the action should be displayed or not
  testId?: string;
  className?: string;
  href?: (row: T) => string;
}

type HoverActionsColumn<T> = IColumn<T> & {
  placeholder?: string; // display icon when not hovered
  showPlaceholder?: (row: T) => boolean;
  actionIcons: IHoverActionIcon<T>[];
  buttonActions?: ISyncButtonDefinition<T>[];
};

export interface IHoverActionsColumn<T> extends HoverActionsColumn<T> {
  type: ColumnType.HoverActions;
}

export interface IOverlayHoverActionsColumn<T> extends Omit<HoverActionsColumn<T>, 'fixed' | 'header'> {
  type: ColumnType.OverlayHoverActions;
  header?: string;
}

export interface IDateRangeColumn<T> extends IColumn<T> {
  type: ColumnType.DateRange;
  getStart: (row: T) => Dayjs;
  getEnd: (row: T) => Dayjs;
  dateRangeFormat?: DateRangeFormat;
}

export interface IListColumn<T> extends IColumn<T> {
  type: ColumnType.List;
  entryThreshold?: number;
  getEntries: (row: T) => string[];
  ellipsis?: boolean;
}

export interface IAvatarColumn<T> extends IColumn<T> {
  type: ColumnType.Avatar;
  getImageUrl: (row: T) => string | undefined;
  getDisplayName: (row: T) => string | undefined;
}

export interface IBooleanColumn<T> extends IColumn<T> {
  type: ColumnType.Boolean;
  render?: (value: boolean) => ReactNode; // used to render a custom boolean column
  disabled?: (row: T) => boolean;
  edit?: {
    defaultValue?: boolean;
    onChange: (row: T, key: keyof T | string, value: boolean) => void;
  };
}

export interface IIconColumn<T> extends IColumn<T> {
  type: ColumnType.Icon;
  icon?: string;
  getIcon?: (row: T) => string;
  tooltip?: string | ((row: T) => string);
  condition?: (row: T) => boolean;
  subdir?: string;
}

export interface IColorColumn<T> extends IColumn<T> {
  type: ColumnType.Color;
  color: string;
}

type DeprecatedTagProps = {
  text: string;
  type: DeprecatedTagType;
};

type TagProps = {
  text: string;
  color?: TagColor;
};

export interface ILabelColumn<T> extends IColumn<T> {
  type: ColumnType.Label;
  getTagProps: (row: T) => DeprecatedTagProps;
}

export interface IIndicatorColumn<T> extends IColumn<T> {
  type: ColumnType.Indicator;
  getValue: (row: T) => boolean;
}

export interface IDateColumn<T> extends IColumn<T> {
  type: ColumnType.Date;
  format?: DateFormat;
}

interface IDropDownOption<T> {
  label: string;
  onClick: (row: T, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
  condition?: (row: T) => boolean;
}

export interface IDropDownActions<T> extends IColumn<T> {
  type: ColumnType.DropDownActions;
  actions: IDropDownOption<T>[];
}

export interface ISingleDropDownColumn<T> extends IColumn<T> {
  type: ColumnType.SingleDropDown;
  items: IDropDownItem[];
  getValue: (row: T) => string | undefined;
  placeholder?: string;
  allowClear?: boolean;
  onSelect: (row: T, key: keyof T | string, value: string | undefined) => void;
  disabled: boolean;
  style?: DropDownStyle;
}

export interface IAbsencesColumn<T> extends IColumn<T> {
  type: ColumnType.Absences;
  getAbsences: (row: T) =>
    | {
        total: number;
        excused: number;
        isChecked: boolean;
      }
    | undefined;
}

// a column that displays a text, or if empty, a button that appears on hover. Clicking on the button or text
// opens the same click handler
export interface ITextOrButtonColumn<T> extends IColumn<T> {
  type: ColumnType.TextOrButton;
  getText: (row: T) => string;
  buttonLabel: string;
  onClick: (row: T) => void;
  disabled?: (row: T) => boolean;
  ellipsis?: boolean;
}

interface IButtonDefinition<T> {
  type: ButtonType; // type guard, similar to type guard in IColumn (see above)
  label: string | ((row: T) => string);
  isHoverButton?: boolean;
  minWidth?: number;
  condition?: (row: T) => boolean;
  outline?: boolean;
  style?: (row: T) => 'primary' | 'success' | 'secondary';
  icon?: string;
  disabled?: (row: T) => boolean;
}

interface IAsyncButtonDefinition<T> extends IButtonDefinition<T> {
  type: ButtonType.Async;
  asyncOnClick: (row: T) => Promise<any>;
}

export interface ISyncButtonDefinition<T> extends IButtonDefinition<T> {
  type: ButtonType.Sync;
  onClick: (row: T) => void;
}

export interface IAttachmentsColumn<T> extends IColumn<T> {
  type: ColumnType.Attachments;
  getAttachments: (row: T) => IDeprecatedAttachment[];
}

export interface ITextPopoverColumn<T> extends IColumn<T> {
  type: ColumnType.TextPopover;
  getValue: (row: T) => string[];
  maxWidth: number;
}

// all columns share must have these properties set
const mapColumnToCommonColumnAttributes = (col: IColumn<any>): AntDesignColumnType<any> => {
  const title = (
    <>
      {col.header}
      {col.headerIcon && <WUTableHeaderIcon icon={col.headerIcon.icon} tooltip={col.headerIcon.tooltip} />}
    </>
  );

  return {
    title,
    key: col.key,
    dataIndex: col.key,
    align: col.align,
    className: col.className,
    width: col.width,
    sorter: col.sorter,
    fixed: col.fixed,
    defaultSortOrder: col.defaultSortOrder,
  };
};

// Column that contains Icons with a hover effect and a click handler. May also display a conditional placeholder icon
const mapHoverActions = <T,>(col: HoverActionsColumn<T>, isOverlay?: boolean): AntDesignColumnType<T> => {
  const type = !!isOverlay ? ColumnType.OverlayHoverActions : ColumnType.HoverActions;
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn({ ...col, type: type }),
    render: (row) => {
      const actionIconsToRender: IHoverActionIcon<T>[] = [];
      const moreActionsToRender: IHoverActionIcon<T>[] = [];
      const buttonsToRender: ISyncButtonDefinition<T>[] = [];

      col.actionIcons.forEach((action) => {
        if (!action.condition || (action.condition && action.condition(row))) {
          if (action.icon === 'more') {
            moreActionsToRender.push(action);
          } else {
            actionIconsToRender.push(action);
          }
        }
      });

      col.buttonActions?.forEach((action) => {
        if (action.condition && action.condition(row)) {
          buttonsToRender.push(action);
        }
      });

      const options = moreActionsToRender
        .filter((action: IHoverActionIcon<T>) => {
          return !action.condition || action.condition(row);
        })
        .map((action: IHoverActionIcon<T>) => {
          return {
            label: action.label ? action.label : '',
            onClick: () => action?.onClick?.(row),
            href: action?.href?.(row),
          };
        });

      col.width = undefined;

      const className = isOverlay ? 'hover-action-icons-column-overlay' : 'hover-action-icons-column';

      return (
        <div className={className} style={{ minWidth: col.width, width: col.width }}>
          {/* Render helper with 0 width and certain height, so that
        rows don't grow/shrink depending on the visibility of content*/}
          <div className="helper" />
          {col.placeholder && (col.showPlaceholder ? col.showPlaceholder(row) : true) && (
            <div className="placeholder">
              <IconButton size="table-row" type={col.placeholder} ariaLabel={col.placeholder} />
            </div>
          )}
          {(actionIconsToRender.length > 0 || options.length > 0 || buttonsToRender.length > 0) && (
            <Flex x="xs" className="hover-actions-container" alignItems="center" justifyContent="space-around">
              {buttonsToRender.map((buttonDefinition, index) => {
                const actionButtonClassName = classNames('action-button', {
                  'hover-button': buttonDefinition.isHoverButton,
                });

                const commonButtonProps = {
                  className: actionButtonClassName,
                  style: { minWidth: buttonDefinition.minWidth ? buttonDefinition.minWidth + 'px' : undefined },
                  outline: buttonDefinition.outline,
                  disabled: buttonDefinition.disabled && buttonDefinition.disabled(row),
                };

                const buttonKey = 'col-' + col.key + '-action-button-' + index;

                return (
                  <Button
                    key={buttonKey}
                    {...commonButtonProps}
                    type={buttonDefinition.style ? buttonDefinition.style(row) : undefined}
                    onClick={(e) => {
                      e.stopPropagation();
                      buttonDefinition.onClick(row);
                    }}
                  >
                    {typeof buttonDefinition.label === 'function'
                      ? buttonDefinition.label(row)
                      : buttonDefinition.label}
                    {buttonDefinition.icon && <Icon type={buttonDefinition.icon} />}
                  </Button>
                );
              })}
              {actionIconsToRender.map((action, index) => {
                let tooltip: string | undefined;

                if (typeof action.tooltip === 'string') {
                  tooltip = action.tooltip;
                } else {
                  tooltip = action.tooltip?.(row);
                }

                return (
                  <IconButton
                    key={index}
                    type={action.icon}
                    size="table-row"
                    onClick={(e: React.BaseSyntheticEvent) => {
                      e.stopPropagation();
                      action?.onClick?.(row, e);
                    }}
                    ariaLabel={action.ariaLabel ? action.ariaLabel : ''}
                    tooltip={
                      tooltip
                        ? {
                            title: tooltip,
                            placement: 'top',
                            arrowPointAtCenter: false,
                          }
                        : undefined
                    }
                    testId={action.testId}
                    className={action.className}
                    href={action?.href?.(row)}
                  />
                );
              })}
              {options.length > 0 && (
                <OptionPopup options={options} placement="bottomRight">
                  <IconButton
                    ariaLabel={t('general.rowActions')}
                    type="more"
                    size="table-row"
                    tooltip={{
                      title: t('general.more'),
                      placement: 'top',
                      arrowPointAtCenter: false,
                    }}
                  />
                </OptionPopup>
              )}
            </Flex>
          )}
        </div>
      );
    },
  };
};

// Same as "HoverActions" but actions are shown as overlay in each table row instead of a separate column.
const mapOverlayHoverActions = <T,>(col: IOverlayHoverActionsColumn<T>): AntDesignColumnType<T> => {
  const hoverActionProps: HoverActionsColumn<T> = { ...col, fixed: 'right' };
  return mapHoverActions(hoverActionProps, true);
};

// Column that can contain an array of buttons, with several different properties and appearances
// It is also possible to work with asynchronous buttons (e.g.: buttons that trigger a network call, like the "publish"
// button in substitution planning
const mapButtonColumn = <T,>(col: IButtonColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (row: T) => {
      const buttons = col.buttons.map((buttonDefinition, index) => {
        if (buttonDefinition.condition && !buttonDefinition.condition(row)) {
          return null;
        }

        const commonButtonProps = {
          className: buttonDefinition.isHoverButton ? 'hover-button' : undefined,
          style: { minWidth: buttonDefinition.minWidth ? buttonDefinition.minWidth + 'px' : undefined },
          outline: buttonDefinition.outline,
          disabled: buttonDefinition.disabled && buttonDefinition.disabled(row),
        };

        const buttonKey = 'col-' + col.key + '-button-' + index;

        if (buttonDefinition.type === ButtonType.Async) {
          return (
            <AsyncButton
              key={buttonKey}
              {...commonButtonProps}
              type={buttonDefinition.style ? buttonDefinition.style(row) : undefined}
              onClick={(e) => {
                e.stopPropagation();
                return buttonDefinition.asyncOnClick(row);
              }}
              style={{ width: col.width }}
            >
              {typeof buttonDefinition.label === 'function' ? buttonDefinition.label(row) : buttonDefinition.label}
              {buttonDefinition.icon && <Icon type={buttonDefinition.icon} />}
            </AsyncButton>
          );
        } else {
          return (
            <Button
              key={buttonKey}
              {...commonButtonProps}
              type={buttonDefinition.style ? buttonDefinition.style(row) : undefined}
              onClick={(e) => {
                e.stopPropagation();
                buttonDefinition.onClick(row);
              }}
              style={{ width: col.width }}
            >
              {typeof buttonDefinition.label === 'function' ? buttonDefinition.label(row) : buttonDefinition.label}
              {buttonDefinition.icon && <Icon type={buttonDefinition.icon} />}
            </Button>
          );
        }
      });

      return <Space>{buttons}</Space>;
    },
  };
};

// Column that displays the text as a DeprecatedTag, or multiple DeprecatedTags if it is an array of strings
const mapDeprecatedTagColumn = <T,>(col: IDeprecatedTagColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    render: (value, row) => {
      const variant = typeof col.variant === 'function' ? col.variant(row) : col.variant;

      if (value instanceof Array) {
        return (
          <div>
            {value.map((tag) => (
              <DeprecatedTag type={variant} key={tag}>
                {tag}
              </DeprecatedTag>
            ))}
          </div>
        );
      }

      return <DeprecatedTag type={variant}>{value}</DeprecatedTag>;
    },
  };
};

const mapTagColumn = <T,>(col: ITagColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    render: (value, row) => {
      return (
        <Stack x="md">
          {col.tags(row).map((tag, i) => (
            <Tag color={tag.color} key={`${tag.text}-tag-${i}`} size="lg">
              {tag.text}
            </Tag>
          ))}
        </Stack>
      );
    },
  };
};

const mapSubstitutionTextColumns = <T,>(col: ISubstitutionTextColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    render: (value, row) => {
      return col.getSubstitutions(row).map((substitution, i) => {
        return <SubstitutionText substituted={substitution.substituted} value={substitution.value} key={i} />;
      });
    },
  };
};

// Column that gets the start- end end out of the row data and displays it.
const mapDateRangeColumn = <T,>(col: IDateRangeColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    render: (value) => (
      <div>{applyDateRangeFormatting(col.getStart(value), col.getEnd(value), col.dateRangeFormat)}</div>
    ),
  };
};

function applyDateRangeFormatting(
  startDate: Dayjs,
  endDate: Dayjs,
  dateRangeFormat: DateRangeFormat | undefined,
): string {
  switch (dateRangeFormat) {
    case DateRangeFormat.DateRange: {
      return formatDateRange(startDate, endDate);
    }
    case DateRangeFormat.TimeRange: {
      return formatTimeRange(startDate, endDate);
    }
    default: {
      return formatDateTimeRange(startDate, endDate);
    }
  }
}

const mapListColumn = <T,>(col: IListColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    render: (value) => (
      <ExpandableList entries={col.getEntries(value)} width={col.width} entryThreshold={col.entryThreshold} />
    ),
  };
};

// Column that can be used for custom cell rendering. If you use it, ask yourself if it makes sence
// to introduce another column definition that can be re-used instead.
const mapCustomColumn = <T,>(col: ICustomColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    render: col.render,
    className: getClassNameForColumn(col),
  };
};

const mapTextArrayColumn = <T,>(col: ITextArrayColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (row: T) => {
      const texts = col.getTexts(row);
      return (
        <div>
          {texts.map((text, i) => {
            return (
              <span key={i}>
                {text.strike ? <s>{text.value}</s> : text.value}
                {i < texts.length - 1 ? ', ' : ''}
              </span>
            );
          })}
        </div>
      );
    },
  };
};

const mapDateColumn = <T,>(col: IDateColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (value: T) => {
      if (dayjs.isDayjs(value)) {
        return <div>{applyDateFormatting(value, col.format)}</div>;
      }

      if (value instanceof Date) {
        return <div>{applyDateFormatting(dayjs(value), col.format)}</div>;
      }

      return null;
    },
  };
};

function applyDateFormatting(date: Dayjs, dateFormat: DateFormat | undefined): string {
  switch (dateFormat) {
    case DateFormat.DateTime: {
      return formatDateTime(date);
    }
    case DateFormat.Time: {
      return formatTime(date);
    }
    default:
      return formatDate(date);
  }
}

function InlineEditInput<T>(props: { value: string; row: T; col: ITextColumn<T> }) {
  const [, setVal] = useState<string | undefined>();
  if (props.col.edit) {
    const successId = v4();
    const errorId = v4();
    return (
      <div
        className="wu-table-edit-text"
        style={{ width: props.col.width }}
        onClick={(event) => event.stopPropagation()}
      >
        <Input
          {...props.col?.edit.inputProps}
          className="wu-table-edit-text-input"
          value={props.col?.edit?.getValue(props.row)}
          onBlur={(event) => {
            event.persist();
            props.col?.edit?.onBlur?.(props.row, props.col.key, event.target.value);
          }}
          disabled={props.col.edit.disabled}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            props.col?.edit?.onChange(props.row, props.col.key, event.target.value, setVal);
          }}
        />
        <CheckCircleOutlined id={successId} className="wu-table-edit-text-icon wu-table-edit-text-icon--success" />
        <CloseCircleOutlined id={errorId} className="wu-table-edit-text-icon wu-table-edit-text-icon--error" />
      </div>
    );
  }

  return <span style={{ width: props.col.width }}>{props.value}</span>;
}

function InlineNumberInput<T>(props: { value: number; row: T; col: INumberColumn<T> }) {
  const [, setVal] = useState<number | undefined>();
  if (props.col.edit) {
    const successId = v4();
    const errorId = v4();
    return (
      <div
        className="wu-table-edit-text"
        style={{ width: props.col.width }}
        onClick={(event) => event.stopPropagation()}
      >
        <InputNumber
          className="wu-table-edit-text-input"
          value={props.col?.getValue(props.row) || 0}
          onBlur={(event) => {
            event.persist();
            const parsedValue = Number(event.target.value);
            if (!Number.isNaN(parsedValue)) {
              if (props.col.edit?.validate && !props.col.edit.validate(props.row, parsedValue)) {
                return;
              }
              props.col?.edit?.onBlur?.(props.row, props.col.key, parsedValue);
            }
          }}
          disabled={props.col.edit.disabled}
          onChange={(value) => {
            const parsedValue = Number(value);
            if (!Number.isNaN(parsedValue)) {
              let newValue = parsedValue;
              if (props.col?.edit?.min !== undefined && parsedValue < props.col.edit.min) {
                newValue = props.col.edit.min;
              }
              if (props.col?.edit?.max !== undefined && parsedValue > props.col.edit.max) {
                newValue = props.col.edit.max;
              }
              if (props.col.edit?.validate && !props.col.edit.validate(props.row, newValue)) {
                return;
              }
              props.col?.edit?.onChange(props.row, props.col.key, newValue, setVal);
            }
          }}
        />
        <CheckCircleOutlined id={successId} className="wu-table-edit-text-icon wu-table-edit-text-icon--success" />
        <CloseCircleOutlined id={errorId} className="wu-table-edit-text-icon wu-table-edit-text-icon--error" />
      </div>
    );
  }

  return <div style={{ width: props.col.width }}>{props.value}</div>;
}

// Just render the content as regular text
const mapTextColumn = <T,>(col: ITextColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    defaultSortOrder: col.defaultSortOrder,
    sortDirections: col.sorter ? ['ascend', 'descend'] : undefined,
    className: getClassNameForColumn(col),
    render: (value: string, row: T) => <InlineEditInput value={value} row={row} col={col} />,
  };
};

const mapNumberColumn = <T,>(col: INumberColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (value: number, row: T) => <InlineNumberInput value={col.getValue(row)} row={row} col={col} />,
  };
};

const mapAvatarColumn = <T,>(col: IAvatarColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (value) => {
      return (
        <UserAvatar user={{ imageUrl: col.getImageUrl(value) ?? '', displayName: col.getDisplayName(value) ?? '' }} />
      );
    },
  };
};

const mapBooleanColumn = <T,>(col: IBooleanColumn<T>) => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    width: col.width,
    // changing the window size
    render: (value: boolean, row: T) => {
      if (!col.edit?.onChange) {
        return (
          <Icon
            style={{ position: 'relative', top: '4px', pointerEvents: 'none' }}
            type={value ? 'checkmark-circle-filled' : 'minus-circle-filled'}
          />
        );
      }
      return (
        <div onClick={(event) => event.stopPropagation()}>
          {/* needed :/ otherwise onRowClick will be called */}
          {col.render ? (
            col.render(value)
          ) : (
            <Checkbox
              onChange={(val) => {
                col.edit?.onChange?.(row, col.key, val);
              }}
              checked={value}
              disabled={col.disabled && col.disabled(row)}
              className="wu-table-checkbox"
            />
          )}
        </div>
      );
    },
  };
};

const mapIndicatorColumn = <T,>(col: IIndicatorColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (row) => {
      return col.getValue(row) ? <div className="wu-table-indicator" /> : null;
    },
  };
};

const mapDropDownActionsColumn = <T,>(col: IDropDownActions<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (row) => {
      const options = col.actions
        .filter((action) => {
          return !action.condition || action.condition(row);
        })
        .map((action) => {
          return {
            label: action.label,
            onClick: () => action.onClick(row),
          };
        });

      if (options.length === 0) {
        return null;
      }

      return (
        <OptionPopup options={options} placement="bottomRight">
          <IconButton ariaLabel={t('general.rowActions')} size="sm" type="more" />
        </OptionPopup>
      );
    },
  };
};

const mapSingleDropDownColumn = <T,>(col: ISingleDropDownColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (row) => {
      return (
        <SingleDropDown
          items={col.items}
          value={col.getValue(row)}
          placeholder={col.placeholder}
          allowClear={col.allowClear}
          onChange={(value: string | undefined) => {
            col.onSelect(row, col.key, value);
          }}
          disabled={col.disabled}
          style={col.style}
        />
      );
    },
  };
};

const mapAbsencesColumn = <T,>(col: IAbsencesColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (row) => {
      const absences = col.getAbsences(row);
      if (!absences) {
        return null;
      }
      return (
        <AmountIndicator
          empty={!absences.isChecked}
          emptyLabel={t('general.check')}
          fulfilled={absences.total === 0}
          fulfilledLabel={t('general.noAbsences')}
          amount={absences.excused}
          amountTotal={absences.total}
          amountLabel={t('general.excused')}
        />
      );
    },
  };
};

const mapTextOrButtonColumn = <T,>(col: ITextOrButtonColumn<T>): AntDesignColumnType<T> => {
  const className = classNames('text-or-button-col', {
    ellipsis: !!col.ellipsis,
    clickable: !!col.onClick,
  });
  //  -32 for removing padding
  const customStyle = { ...(typeof col.width === 'number' ? { style: { width: col.width - 32 } } : {}) };
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (row) => {
      const text = col.getText(row).trim();
      return (
        <div className={className} {...customStyle}>
          {text.length > 0 ? (
            <p onClick={() => col.onClick(row)}>{text}</p>
          ) : (
            <Button outline disabled={!!col.disabled && col.disabled(row)} onClick={() => col.onClick(row)}>
              {col.buttonLabel}
            </Button>
          )}
        </div>
      );
    },
  };
};

const mapIconColumn = <T,>(col: IIconColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (row: T) => {
      if (col.condition && !col.condition(row)) return null;
      if (!col.icon && !col.getIcon) return null;

      let tooltip: string | undefined;

      if (typeof col.tooltip === 'string') {
        tooltip = col.tooltip;
      } else if (typeof col.tooltip === 'function') {
        tooltip = col.tooltip(row);
      }

      const icon = <Icon subdir={col.subdir} type={col.icon ?? (col.getIcon && col.getIcon(row))} />;

      if (tooltip) {
        return <Tooltip title={tooltip}>{icon}</Tooltip>;
      }

      return icon;
    },
  };
};

const mapColorColumn = <T,>(col: IColorColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (row: any) => {
      const rowColor = row[col.color];
      const color = rowColor ? `#${rowColor}` : undefined;
      if (row) {
        return <ColorDisplay color={color} />;
      }
    },
  };
};

const mapLabelColumn = <T,>(col: ILabelColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (row: any) => {
      const { type, text } = col.getTagProps(row);
      return (
        <DeprecatedTag rounded type={type}>
          {text}
        </DeprecatedTag>
      );
    },
  };
};

const mapAttachmentsColumn = <T,>(col: IAttachmentsColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (row: T) => {
      if (row) {
        return (
          <div className="wu-table-attachments">
            <DeprecatedAttachments attachments={col.getAttachments(row)} readOnly compact />
          </div>
        );
      }
    },
  };
};

const mapTextPopoverColumn = <T,>(col: ITextPopoverColumn<T>): AntDesignColumnType<T> => {
  return {
    ...mapColumnToCommonColumnAttributes(col),
    className: getClassNameForColumn(col),
    render: (row: T) => {
      if (row) {
        // we have to apply a margin left of -16px because otherwise it would appear as if the component
        // is not aligned with the header properly since the border of the
        // component only appears on hover
        if (col.getValue(row).length > 0) {
          return (
            <Flex onClick={(e) => e.stopPropagation()}>
              <TextPopover value={col.getValue(row)} maxWidth={col.maxWidth} marginLeft={-16} />
            </Flex>
          );
        } else return null;
      }
    },
  };
};

// For now only Text Columns need to support a variety of styles. This might change in the future
const getClassNameForColumn = <T,>(column: Column<T>): string => {
  const name = 'wu-table-cell';

  if (column.type === ColumnType.Text) {
    return classNames(
      name,
      {
        [`${name}--no-border`]: column.textStyle?.border === false,
        [`${name}--bold`]: column.textStyle?.bold,
        [`${name}--semibold`]: column.textStyle?.semibold,
        [`${name}--gray`]: column.textStyle?.color === 'gray',
        [`${name}--gray-dark`]: column.textStyle?.color === 'gray-dark',
        [`${name}--no-padding-left`]: column.noPaddingLeft === true,
        [`${name}--ellipsis`]: column.ellipsis === true,
      },
      column.className,
    );
  }

  if (column.type === ColumnType.Boolean) {
    return classNames(
      name,
      {
        [`${name}--align-left`]: column.align === 'left',
        [`${name}--align-center`]: column.align === 'center',
        [`${name}--align-right`]: column.align === 'right',
      },
      column.className,
    );
  }

  if (column.type === ColumnType.Custom) {
    return classNames(column.className);
  }

  if (column.type === ColumnType.Button) {
    return classNames(`${name}--button`);
  }

  if (column.type === ColumnType.Avatar) {
    return classNames(`${name}--avatar`, column.className);
  }

  if (column.type === ColumnType.OverlayHoverActions) {
    return classNames(`${name}--overlay-actions`, column.className);
  }

  if (column.type === ColumnType.Icon) {
    return classNames('wu-table-cell--icon');
  }

  return name;
};

/**
 * Maps all our well defined column definitions to objects that can be handled properly by the Ant Design Table
 */
export const mapColumnsToAntDesignColumn = <T,>(columns: Columns<T>): AntDesignTableColumns<T> => {
  return columns.map((col) => {
    switch (col.type) {
      case ColumnType.HoverActions:
        return mapHoverActions(col);
      case ColumnType.OverlayHoverActions:
        return mapOverlayHoverActions(col);
      case ColumnType.Button:
        return mapButtonColumn(col);
      case ColumnType.DeprecatedTag:
        return mapDeprecatedTagColumn(col);
      case ColumnType.Tag:
        return mapTagColumn(col);
      case ColumnType.DateRange:
        return mapDateRangeColumn(col);
      case ColumnType.List:
        return mapListColumn(col);
      case ColumnType.Custom:
        return mapCustomColumn(col);
      case ColumnType.Avatar:
        return mapAvatarColumn(col);
      case ColumnType.Boolean:
        return mapBooleanColumn(col);
      case ColumnType.Indicator:
        return mapIndicatorColumn(col);
      case ColumnType.DropDownActions:
        return mapDropDownActionsColumn(col);
      case ColumnType.SingleDropDown:
        return mapSingleDropDownColumn(col);
      case ColumnType.TextArray:
        return mapTextArrayColumn(col);
      case ColumnType.Absences:
        return mapAbsencesColumn(col);
      case ColumnType.TextOrButton:
        return mapTextOrButtonColumn(col);
      case ColumnType.Date:
        return mapDateColumn(col);
      case ColumnType.Icon:
        return mapIconColumn(col);
      case ColumnType.Attachments:
        return mapAttachmentsColumn(col);
      case ColumnType.TextPopover:
        return mapTextPopoverColumn(col);
      case ColumnType.Color:
        return mapColorColumn(col);
      case ColumnType.Label:
        return mapLabelColumn(col);
      case ColumnType.Number:
        return mapNumberColumn(col);
      case ColumnType.SubstitutionText:
        return mapSubstitutionTextColumns(col);
      default:
        return mapTextColumn(col);
    }
  });
};
