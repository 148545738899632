import React, { forwardRef, Ref } from 'react';

import './sub-menu-item-list.less';
import { MenuItemListClassName } from '../main-menu-item-list/main-menu-item-list';

interface IProps {
  handleScroll: (e: any) => void;
  isInSubMenu: boolean;
  showMenuItemsFadeAnimationWithIcons: boolean;
  collapsed: boolean;
  items: any;
  showDense: boolean;
  menuHeaderHeight: number;
  menuBottomViewHeight: number;
}

const SubMenuItemList = forwardRef((props: IProps, ref: Ref<HTMLDivElement>) => {
  const className =
    `${MenuItemListClassName} menu-item-list--sub` +
    (props.isInSubMenu ? ' menu-item-list--sub-left' : ' menu-item-list--sub-right') +
    (!props.collapsed ? ' menu-item-list--full-width' : ' menu-item-list--collapsed') +
    (props.showMenuItemsFadeAnimationWithIcons
      ? ' menu-item-list--fade-animation-with-icons'
      : ' menu-item-list--fade-animation-without-icons') +
    (props.collapsed && props.showMenuItemsFadeAnimationWithIcons
      ? ' menu-item-list--icon-scale-animation'
      : ' menu-item-list--no-icon-scale-animation') +
    (props.showDense ? ' menu-item-list--dense-mode' : '');

  return (
    <div
      className={className}
      onScroll={props.handleScroll}
      style={{
        height: 'calc(100% - (' + (props.menuBottomViewHeight + props.menuHeaderHeight) + 'px))',
        marginTop: props.menuHeaderHeight,
      }}
    >
      <div className="menu-item-container" ref={ref}>
        {props.items}
      </div>
    </div>
  );
});

export default SubMenuItemList;
