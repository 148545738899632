import { action, computed } from 'mobx';
import { Dayjs } from 'dayjs';

import { inject, Store } from '@/types/store';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import { WeekdayEnum } from '@untis/wu-rest-view-api/api';
import { getNextWeekDay, getPreviousWeekDay, WEEKDAY_ENUM_MAP } from '@/utils/time-grid/time-grid-util';

@Store()
export class TimetableGridDaysStore {
  private timetableFormatStore = inject(TimetableFormatStore);

  @computed
  get timetableFormatTimeGridDays(): WeekdayEnum[] {
    return this.timetableFormatStore.timetableFormat?.timeGridDays || [];
  }

  @computed
  get timetableFormatTimeGridFirstDay(): WeekdayEnum {
    if (this.timetableFormatTimeGridDays.length > 0) {
      return this.timetableFormatTimeGridDays[0];
    } else {
      return WeekdayEnum.MO;
    }
  }

  @computed
  get timetableFormatTimeGridLastDay(): WeekdayEnum {
    if (this.timetableFormatTimeGridDays.length > 0) {
      return this.timetableFormatTimeGridDays[this.timetableFormatTimeGridDays.length - 1];
    } else {
      return WeekdayEnum.SU;
    }
  }

  hasTimeGridDay(day: Dayjs): boolean {
    let result = false;
    const weekDayEnum = WEEKDAY_ENUM_MAP.get(day.day());
    if (weekDayEnum) {
      result = this.timetableFormatTimeGridDays.includes(weekDayEnum);
    }
    return result;
  }

  @action.bound
  getNextTimeGridDay(day: Dayjs): Dayjs {
    return getNextWeekDay(day, this.timetableFormatTimeGridDays);
  }

  @action.bound
  getPreviousTimeGridDay(day: Dayjs): Dayjs {
    return getPreviousWeekDay(day, this.timetableFormatTimeGridDays);
  }

  getTimeGridDay(date: Dayjs): WeekdayEnum {
    return WEEKDAY_ENUM_MAP.get(date.day()) || WeekdayEnum.MO;
  }
}
