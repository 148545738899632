import React from 'react';
import Form from 'antd/lib/form';
import { Converter } from 'showdown';

import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import { Wysiwyg } from '@/ui-components/wysiwyg';

interface IProps extends IFormItemProps<string> {
  output?: 'html' | 'json' | 'markdown';
  markdownConverter?: Converter;
}

export const FormWysiwyg = (props: IProps) => {
  return (
    <Form.Item
      name={props.name}
      label={props.label}
      initialValue={props.initialValue}
      className="form-text-area"
      rules={props.rules}
      dependencies={props.dependencies}
    >
      <Wysiwyg
        options={{
          editable: !props.disabled,
          autofocus: false,
        }}
        output={props.output}
        markdownConverter={props.markdownConverter}
        testId={props.testId}
      />
    </Form.Item>
  );
};
