import React from 'react';
import { observer } from 'mobx-react-lite';

import { CheckDataModalView } from '@/components/modal-views/check-data-modal-view/check-data-modal-view';
import { TriggerStore } from '@/stores/trigger-store';
import useStore from '@/hooks/useStore';
import { OnStartupActionsV2Enum } from '@untis/wu-rest-view-api';
import './trigger-modal-view.less';

const { VERIFY_PROFILE_DATA } = OnStartupActionsV2Enum;

const TriggerModalView = observer(() => {
  const triggerStore = useStore(TriggerStore);

  const view = React.useMemo(() => {
    switch (triggerStore.currentAction) {
      case VERIFY_PROFILE_DATA:
        return <CheckDataModalView />;
      default:
        return null;
    }
  }, [triggerStore.currentAction]);

  return (
    <div className="check-data-modal-view">
      {triggerStore.actionsCount > 1 && (
        <div>
          {triggerStore.currentTaskOrder} out of {triggerStore.actionsCount} open tasks
        </div>
      )}
      {view}
    </div>
  );
});

export { TriggerModalView };
