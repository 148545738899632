import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import { TodayButton, ToggleSwitch } from '@/ui-components';
import useStore from '@/hooks/useStore';
import { TimetableUserActionsStore } from '@te/standard/stores/user-actions/timetable-user-actions-store';
import { TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import { TimetableTimeStore } from '@te/standard/stores/time/timetable-time-store';
import SchoolYearStore from '@/stores/schoolyear-store';
import { TestIds } from '@/testIds';

import './timetable-header-controls.less';

const TimetableHeaderControls = observer(() => {
  const { t } = useTranslation();
  const timetableUserActionsStore = useStore(TimetableUserActionsStore);
  const timetableMetaStore = useStore(TimetableMetaStore);
  const timetableTimeStore = useStore(TimetableTimeStore);
  const schoolYearStore = useStore(SchoolYearStore);

  const { timetableViewType } = timetableMetaStore;
  const { currentTime } = timetableTimeStore;
  const isTodayButtonActive = timetableMetaStore.isDateInCurrentTimeGridDateRange(currentTime);

  const handleTodayButtonClicked = (todayDate: Dayjs) => {
    if (!isTodayButtonActive) {
      timetableUserActionsStore.selectToday(todayDate);
    }
  };

  return (
    <div className="timetable-header-controls">
      {schoolYearStore.isDateInCurrentSchoolYear(dayjs()) && (
        <TodayButton
          isActive={isTodayButtonActive}
          testId="timetable-header-controls--today-button"
          handleClick={handleTodayButtonClicked}
        />
      )}
      <ToggleSwitch
        testId={TestIds.TIMETABLE_DAY_WEEK_TOGGLE}
        toggles={[
          {
            value: 'day',
            label: t('general.day'),
            testId: 'toggle-day',
          },
          {
            value: 'week',
            label: t('general.week'),
            testId: 'toggle-week',
          },
        ]}
        value={timetableViewType.toString()}
        onChange={() => timetableUserActionsStore.toggleTimetableViewType()}
      />
    </div>
  );
});

export default TimetableHeaderControls;
