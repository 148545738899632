export const LAYOUT_NODE_ID = 'layout-main';

const getMainLayoutNode = (): HTMLElement => {
  return document.getElementById(LAYOUT_NODE_ID)!;
};

export const addLayoutScrollListener = (callback: () => void) => {
  // we get return the document as fallback so it also works in our component library
  const affectedNode = getMainLayoutNode() ? getMainLayoutNode() : document;

  affectedNode.addEventListener('scroll', callback);
};

export const removeLayoutScrollListener = (callback: () => void) => {
  // we get return the document as fallback so it also works in our component library
  const affectedNode = getMainLayoutNode() ? getMainLayoutNode() : document;

  affectedNode.removeEventListener('scroll', callback);
};

export const getLayoutScrollTop = (): number => {
  return getMainLayoutNode().scrollTop;
};

export const setLayoutScrollTop = (value: number) => {
  return (getMainLayoutNode().scrollTop = value);
};
