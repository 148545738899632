/**
 * Returns true, if the target string includes ALL strings in the
 * searches array (case insensitive), or if the searches array is empty
 *
 * Can be used e.g.: to filter (logical AND, not OR) rows of a table with multiple search values of the searchbar
 * -> map your row to a string that contains all values you want to be affected by the search and use this
 * function to check, if all search values match the row.
 */
export const matchesAllSearches = (target: string, searches: string[]) => {
  if (searches.length === 0) {
    return true;
  }
  let matchesAll = true;
  searches.forEach((search) => {
    if (!target.toLowerCase().includes(search.toLowerCase())) {
      matchesAll = false;
      return;
    }
  });
  return matchesAll;
};
