import { action, computed, observable } from 'mobx';
import { AxiosResponse } from 'axios';

import LogoutBroadcastService from '../services/logout-broadcast-service';

import { AppDataDto, PollingType } from '@untis/wu-rest-view-api';
import ApiStore, { AppViewApi } from '@/stores/api-store';
import ConfigStore from '@/stores/config-store';
import EnvironmentStore from '@/stores/environment-store';
import LocaleStore from '@/stores/locale-store';
import RouterStore from '@/stores/router-store';
import { TimegridStore } from '@/stores/timegrid-store';
import SchoolYearStore from '@/stores/schoolyear-store';
import TokenStore from '@/stores/token-store';
import { TriggerStore } from '@/stores/trigger-store';
import { inject, IStore, Store } from '@/types/store';
import AnalyticsStore from '@/stores/analytics-store/analytics-store';
import RightsStore from '@/stores/rights-store';
import SettingsStore from '@/stores/settings-store';
import ReportStore from '@/stores/report-store';
import UntisNewsStore from '@/stores/untis-news-store';
import { LocalStorageContext, LocalStorageStore } from '@/stores/local-storage-store';
import { SHOW_SCHOOLYEAR_GAP_DIALOG } from '@/types/local-storage-ids';

/**
 * Store for internal State management of the app
 * e.g.: no data that comes from the server but local state that is needed.
 */
@Store()
export default class AppStore implements IStore {
  @observable isLoading = true;
  @observable modalIsVisible = false;
  @observable showFocusedElements = false;
  @observable private _renderFrontChannelLogout = false;
  @observable private _showLegacyWebUntis = false;
  @observable private _embeddedWebUntisLoaded = false;
  stickyHeaderContainerRef: HTMLDivElement | null = null;

  private configStore = inject(ConfigStore);
  private localeStore = inject(LocaleStore);
  private environment = inject(EnvironmentStore);
  private apiStore = inject(ApiStore);
  private tokenStore = inject(TokenStore);
  private timegridStore = inject(TimegridStore);
  private schoolYearsStore = inject(SchoolYearStore);
  private routerStore = inject(RouterStore);
  private triggerStore = inject(TriggerStore);
  private analyticsStore = inject(AnalyticsStore);
  private rightsStore = inject(RightsStore);
  private settingsStore = inject(SettingsStore);
  private reportStore = inject(ReportStore);
  private untisNewsStore = inject(UntisNewsStore);
  private localStorageStore = inject(LocalStorageStore);
  private logoutBroadcastChannelStore = inject(LogoutBroadcastService);

  async init() {
    await this.environment.init();
    await this.tokenStore.init();
    const successfullyInitialized = await this.apiStore.init().then(async () => {
      await this.timegridStore.fetchTimegridForCurrentSchoolyear();
      await this.schoolYearsStore.getSchoolYears();
      return AppViewApi.getAppData().then((result: AxiosResponse<AppDataDto>) => {
        if (!result.data.ui2020) {
          console.error('Ui2020 Config isnt enabled');
          window.location.href = this.environment.webUntisURL!;

          return false;
        }

        const user = result.data.user;
        const tenant = result.data.tenant;
        if (user && tenant) {
          this.configStore.setData(result.data);
          this.localStorageStore.readAllAndRemoveInvalidEntries();
          this.rightsStore.initialize(result.data.permissions);
          this.settingsStore.initialize(result.data.settings);
          this.schoolYearsStore.storeCurrentSchoolYear(result.data.currentSchoolYear);
          if (result.data.pollingJobs?.includes(PollingType.REPORT)) {
            this.reportStore.triggerPolling();
          }
        }
        return true;
      });
    });

    const platformApplicationMenuItemsInitialized = await this.configStore.fetchPlatformApplicationMenuItems();

    try {
      const { data: thirdpartyData } = await AppViewApi.getThirdPartyData();
      this.configStore.setThirdPartyData(thirdpartyData);
    } catch (error) {
      console.error('Third-party data could not be loaded correctly');
    }

    if (successfullyInitialized && platformApplicationMenuItemsInitialized) {
      await this.localeStore.init();
      this.untisNewsStore.initScript(this.localeStore.locale);
      this.isLoading = false;
      this.routerStore.goToLastRoute();
      this.triggerStore.init();
      this.analyticsStore.init();
    }
  }

  @action.bound
  setStickyHeaderContainerRef(element: HTMLDivElement | null) {
    this.stickyHeaderContainerRef = element;
  }

  @action
  setShowLegacyWebUntis(value: boolean) {
    return (this._showLegacyWebUntis = value);
  }

  @computed
  get showLegacyWebUntis(): boolean {
    return this._showLegacyWebUntis;
  }

  @action
  setEmbeddedWebUntisLoaded(value: boolean) {
    return (this._embeddedWebUntisLoaded = value);
  }

  @action
  setRenderFrontChannelLogout(value: boolean) {
    this._renderFrontChannelLogout = value;
  }

  @action
  handleLogout() {
    this.tokenStore.logout();
    this.setRenderFrontChannelLogout(true);
    this.logoutBroadcastChannelStore.sendLogoutSessionEvent();
    this.localStorageStore.writeBoolean(LocalStorageContext.GLOBAL, SHOW_SCHOOLYEAR_GAP_DIALOG, true);
  }

  @computed
  get embeddedWebUntisLoaded(): boolean {
    return this._embeddedWebUntisLoaded;
  }

  @computed
  get renderFrontChannelLogout(): boolean {
    return this._renderFrontChannelLogout;
  }
}
