import React, { ReactNode, RefObject, useContext, useRef, useState } from 'react';
import classNames from 'clsx';
import { useTranslation } from 'react-i18next';

import { Option } from '@/ui-components/option-popup/option-popup';
import { ButtonType } from '@/ui-components/button/button';
import { PageHeader } from '@/ui-components/page/page-header/page-header';
import { useRenderDelayedLoadingIndicator } from '@/hooks/useRenderDelayedLoadingIndicator';
import EmptyIndicator, { IEmptyIndicatorProps } from '@/components/empty-indicator/empty-indicator';
import { IDeprecatedSearchBarProps } from '@/ui-components/deprecated-search-bar/deprecated-search-bar';
import { PageDatePickerProps } from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import { IDeprecatedDropDownProps } from '@/ui-components/deprecated-drop-down/drop-down';
import { ITestComponentProps } from '@/types/test-component-props';
import { ResponsivenessContext } from '@/context/responsiveness-context';
import './page.less';
import { ISearchBarProps } from '@/ui-components/search-bar/search-bar';

// we can have different kinds of buttons in the header which share these props
interface ICommonButtonProps {
  label: string;
  icon?: string;
  dataTestId?: string;
  outline?: boolean;
  type?: ButtonType;
}

export type PageHeaderButton =
  | IPageHeaderRegularButtonProps
  | IPageHeaderLinkButtonProps
  | IPageHeaderOptionButtonProps
  | IPageHeaderIconButtonProps;

// a regular button with a click handler
export interface IPageHeaderRegularButtonProps extends ICommonButtonProps {
  onClick: (e: React.BaseSyntheticEvent) => any;
  async?: boolean;
}

// a button that just brings you to another page
export interface IPageHeaderLinkButtonProps extends ICommonButtonProps {
  linkTo: string; // if set, the onClick handler that is passed in will be ignored
}

// a button that offers several options in a dropdown when you click on it
export interface IPageHeaderOptionButtonProps extends ICommonButtonProps {
  options: Option[];
  onClick?: () => any;
  async?: boolean;
}

// a button that can only display an icon, no text, and is rendered as a circle
export interface IPageHeaderIconButtonProps {
  icon: string;
  dataTestId?: string;
  outline?: boolean;
  type?: ButtonType;
  onClick?: () => any;
}

export interface IPageHeaderTabItem {
  key: string;
  label: string;
}

export interface IPageHeaderTabs {
  selectedTab: string;
  items: IPageHeaderTabItem[];
  onTabSelected: (tab: string) => void;
}

export interface IPageProps extends ITestComponentProps {
  title?: string;
  // what should happen when user scrolls down? Should the fixed "sticky" header appear
  // or should be the page content scrollable only? If not specified then header is not visible once scrolled down.
  scrollMode?: 'stickyHeader' | 'scrollContent';
  spacing?: 'lg' | 'md' | 'sm'; // how much space should be left to the sides?
  children?: React.ReactNode;
  buttons?: (
    | IPageHeaderRegularButtonProps
    | IPageHeaderLinkButtonProps
    | IPageHeaderOptionButtonProps
    | IPageHeaderIconButtonProps
  )[];
  tabs?: IPageHeaderTabs;
  dropDown?: IDeprecatedDropDownProps;
  // set this if you need to identify the scrollable element of the page
  scrollableContainerId?: string;
  deprecatedSearchBar?: IDeprecatedSearchBarProps;
  searchBar?: ISearchBarProps;
  description?: ReactNode;
  contentContainerRef?: RefObject<any>;
  isPageLoading?: boolean; // can be used to pass a loading state, so that the page can render an loading indicator
  isPageContentLoading?: boolean; // like isLoadingPage, but only replaced the content with the loading indicator
  loadingIndicator?: IEmptyIndicatorProps;
  pageDatePickerProps?: PageDatePickerProps;
  className?: string;
  displayTitle?: boolean;
  compact?: boolean; // if set then the content areas width is limited to 1200px
  whiteHeader?: boolean;
  whiteContent?: boolean;
}

/**
 * Component that should be used for every page in WebUntis.
 * It should handle the header appearance and responsive content spacing
 * to the sides, so that each new view in WebUntis looks and behaves similar.
 */
export const Page = (props: IPageProps) => {
  const [scrollTop, setScrollTop] = useState(0);
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const renderDelayedPageLoadingIndicator = useRenderDelayedLoadingIndicator(!!props.isPageLoading);
  const renderDelayedPageContentLoadingIndicator = useRenderDelayedLoadingIndicator(!!props.isPageContentLoading);
  const reponsivenessContext = useContext(ResponsivenessContext);

  const stickyHeaderThreshold = 76; // how many pixels to scroll down so that the sticky header appears
  const isStickyHeader = props.scrollMode === 'stickyHeader';
  const isScrollableContent = props.scrollMode === 'scrollContent';

  const handleScroll = () => {
    isStickyHeader && setScrollTop(ref.current ? ref.current.scrollTop : 0);
  };

  const stickyHeaderClass = classNames('sticky-header', {
    'sticky-header--visible': scrollTop > stickyHeaderThreshold,
  });

  const isWhite: boolean =
    props.whiteHeader ||
    !!props.deprecatedSearchBar ||
    !!props.searchBar ||
    !!props.pageDatePickerProps ||
    !!props.tabs;
  // when the searchbar is used, lets break the rest into the next line
  // and let the searchbar take the full width, when screen size decreases;
  const shouldBreak: boolean = !!props.deprecatedSearchBar || !!props.searchBar;
  const pageClass = classNames('page', props.className, {
    'page--is-white': isWhite,
  });

  if (props.isPageLoading) {
    return (
      <div className={pageClass} ref={ref} onScroll={handleScroll}>
        {renderDelayedPageLoadingIndicator(
          props.loadingIndicator ? (
            <EmptyIndicator {...props.loadingIndicator} />
          ) : (
            <EmptyIndicator title={t('general.loading')} />
          ),
        )}
      </div>
    );
  }

  const pageContent = (
    <div
      className={classNames('page-content-container', {
        'page-content-container--scrollable': isScrollableContent,
      })}
    >
      <div
        className={classNames('page-content', reponsivenessContext?.responsiveClassName, {
          'page-content--padding-top': !isScrollableContent,
          'page-content--white': props.whiteContent,
        })}
        id={props.scrollableContainerId}
        ref={props.contentContainerRef}
      >
        <div className="max-width-container" style={{ maxWidth: props.compact ? '1200px' : 'inherit' }}>
          {props.children}
        </div>
      </div>
    </div>
  );

  return (
    <div className={pageClass} ref={ref} onScroll={handleScroll} data-testid={props.testId}>
      <PageHeader
        {...props}
        isSticky={false}
        shouldBreak={shouldBreak}
        isWhite={isWhite}
        description={props.description}
        displayTitle={props.displayTitle}
      />
      {isStickyHeader && (
        <div className={stickyHeaderClass}>
          <PageHeader
            {...props}
            isSticky={true}
            displayTitle={props.displayTitle}
            shouldBreak={shouldBreak}
            isWhite={isWhite}
            description={undefined}
          />
        </div>
      )}
      {props.isPageContentLoading
        ? renderDelayedPageContentLoadingIndicator(
            props.loadingIndicator ? (
              <EmptyIndicator {...props.loadingIndicator} />
            ) : (
              <EmptyIndicator title={t('general.loading')} />
            ),
          )
        : pageContent}
    </div>
  );
};
