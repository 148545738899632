import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { WUForm } from '@/ui-components';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { FormSection, IFormButton, WUFormCol, WUFormRow } from '@/ui-components/wu-form';
import { InfoSection } from '@/ui-components/info-section/info-section';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import { GradingFormStore } from '@ls/exams/grading-form/grading-form-store';
import { ExamsStore } from '@ls/exams/overview/exams-store';
import { ISelectItem } from '@/ui-components/select/select';

interface IProps {
  gradingFormStore: GradingFormStore;
  examStore: ExamsStore;
}

interface IGradingSchemeFormValue {
  scheme: string;
}

export const GradingSchemeDialog = observer((props: IProps) => {
  const modalStore = useStore(ModalStore);
  const { t } = useTranslation();

  const handleSubmit = (value: IGradingSchemeFormValue) => {
    const examId = props.gradingFormStore.exam?.examId;
    if (examId) {
      props.gradingFormStore.saveGradingScheme(value.scheme).then(() => {
        modalStore.closeModal();
        props.examStore.openGradesModal(examId);
      });
    }
  };

  const rightButtons: IFormButton[] = [
    {
      label: t('general.cancel'),
      onClick: () => {
        modalStore.closeModal();
      },
    },
  ];

  const schemeItems: ISelectItem[] = props.gradingFormStore.gradingSchemes.map((gradingScheme) => {
    return {
      id: gradingScheme.id.toString(),
      label: gradingScheme.displayName ?? '',
    };
  });

  return (
    <WUForm<IGradingSchemeFormValue>
      onSubmit={handleSubmit}
      rightButtons={rightButtons}
      className="grading-scheme-dialog"
      fixedButtonBar
      hideTopBorder
      maxWidth
    >
      <WUFormCol lg={1}>
        <WUFormCol lg={1}>
          <InfoSection infoText={t('general.youNeedToAssignGradingScheme')} />
        </WUFormCol>
        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection
              centered
              text={t('general.gradingScheme')}
              description={t('general.selectGradingSchemeForExam')}
            />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormSingleSelect
              placeholder={t('general.selectGradingScheme')}
              items={schemeItems}
              name="scheme"
              allowClear={false}
              required
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});
