import React from 'react';
import './user-item.less';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import useStore from '@/hooks/useStore';
import MenuItem from '@/ui-components/navigation-bar/menu-item/menu-item';
import { NavigationBarStore } from '@/ui-components/navigation-bar/navigation-bar-store';
import nameToInitials from '@/utils/name-to-initials/name-to-initials';
import { Avatar } from '@/ui-components';

interface IProps {
  name: string;
  role: string;
  image?: string;
  route?: string;
}

const UserItem = observer((props: IProps) => {
  const store = useStore(NavigationBarStore);
  const { t } = useTranslation();

  return (
    <MenuItem
      className="user-item"
      name={props.name}
      content={
        <>
          <div className="name">{props.name}</div>
          <div className="role">{props.role}</div>
        </>
      }
      tooltipTitle={t('menu.bottomUpViewMenuItems.profile')}
      isDense={false}
      selected={store.isUserItemSelected}
      route={store.userRoute}
      icon={
        <Avatar shape="circle" size={40} src={props.image}>
          {nameToInitials(props.name)}
        </Avatar>
      }
      isAccessible={true}
    />
  );
});

export default UserItem;
