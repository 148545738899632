import { Badge as AntdBadge } from 'antd';
import { BadgeProps as AntdBadgeProps } from 'antd/lib/badge';
import React from 'react';

import './badge.less';

type Position = 'topRight' | 'bottomRight' | 'bottomLeft' | 'topLeft';

export type BadgeProps = AntdBadgeProps & {
  corner?: Position;
};

export const Badge: React.FC<BadgeProps> = (props) => {
  const getCornerModifier = (): string => {
    switch (props.corner) {
      case 'topRight':
        return ' untis-badge--top-right';
      case 'bottomRight':
        return ' untis-badge--bottom-right';
      case 'bottomLeft':
        return ' untis-badge--bottom-left';
      case 'topLeft':
        return ' untis-badge--top-left';
      default:
        return '';
    }
  };

  return (
    <AntdBadge {...props} className={`untis-badge${getCornerModifier()}${' ' + props.className}`}>
      {props.children}
    </AntdBadge>
  );
};

export default Badge;
