import { observable } from 'mobx';

import { Store } from '@/types/store';

// extend if necessary
export enum NumberSetting {
  ROOM_TIMETABLE_FORMAT = 'ttelemsettings.room.timetableformatid',
  LINES_PER_PAGE = 'system.linesperpage',
  FIRST_DAY_OF_WEEK = 'system.firstDayOfWeek',
}

export enum BooleanSetting {
  TODAY_SHOW_PAST_LESSONS = 'system.showlessonsofday',
  BOOKING_LIST_FILTER_ACTIVE = 'bookinglistfilter.active',
}

export enum JsonSetting {
  EXAMLIST_SELECTED_DATE_RANGE = 'examlistsettings.selecteddaterange',
}

export enum StringSetting {
  SYSTEM_EMAIL_ADMIN = 'system.emailadmin',
}

type Setting = NumberSetting | StringSetting | BooleanSetting | JsonSetting;

interface ISetting {
  name: Setting;
  value: string; // can contain anything (string | stringified numbers/booleans/JSON...)
}

@Store()
export default class SettingsStore {
  @observable private _settings: ISetting[] = [];

  initialize(settings: string[] | undefined) {
    if (!settings) {
      return;
    }
    this._settings = settings.map((setting) => {
      // split into two parts, at first occurence of ':'
      const firstSeparatorIndex = setting.indexOf(':');
      return {
        name: setting.substring(0, firstSeparatorIndex) as Setting,
        value: setting.substring(firstSeparatorIndex + 1),
      };
    });
  }

  hasBooleanSetting(setting: BooleanSetting, value: boolean) {
    const storedSetting = this._settings.find((s) => s.name === setting);
    if (!storedSetting) {
      return false;
    }
    return value.toString() === storedSetting.value;
  }

  hasNumberSetting(setting: NumberSetting, value: number): boolean {
    const storedSetting = this._settings.find((s) => s.name === setting);
    if (!storedSetting) {
      return false;
    }
    const parsedValue = Number(storedSetting.value);
    if (isNaN(parsedValue)) {
      console.warn('Warning: Expected number setting, but setting is NaN');
      return false;
    }
    return value === parsedValue;
  }

  hasStringSetting(setting: StringSetting, value: string) {
    const storedSetting = this._settings.find((s) => s.name === setting);
    if (!storedSetting) {
      return false;
    }
    return value === storedSetting.value;
  }

  getJsonSetting(setting: JsonSetting): object | undefined {
    const storedSetting = this._settings.find((s) => s.name === setting);
    if (!storedSetting) {
      return undefined;
    }
    return JSON.parse(storedSetting.value);
  }

  getNumberSetting(setting: NumberSetting): number | undefined {
    const storedSetting = this._settings.find((s) => s.name === setting);
    if (!storedSetting) {
      return undefined;
    }
    return storedSetting.value as unknown as number;
  }

  getStringSetting(setting: StringSetting): string | undefined {
    const storedSetting = this._settings.find((s) => s.name === setting);
    if (!storedSetting) {
      return undefined;
    }
    return storedSetting.value as unknown as string;
  }
}
