import { observable } from 'mobx';
import React from 'react';

import { RequestConfirmationStatusDto } from '@untis/wu-rest-view-api';
import { MessageViewApi } from '@/stores/api-store';
import ModalStore from '@/stores/modal-store';
import { inject, IStore, Store } from '@/types/store';
import { ErrorMessageEnum, showError } from '@/utils/error-handling/error-message';
import ReadConfirmationView from '@mg/components/read-confirmation-view/read-confirmation-view';

@Store()
export default class ReadConfirmationStore implements IStore {
  @observable isLoading = true;
  @observable requestConfirmations: RequestConfirmationStatusDto[] = [];

  private modalStore = inject(ModalStore);

  openReadConfirmationView(messageId: number, subject: string) {
    this.modalStore.openModalDialog({
      className: 'read-confirmation-modal',
      children: <ReadConfirmationView subject={subject} />,
      size: 'lg',
      noPadding: true,
    });
    this.getReadConfirmationStatus(messageId);
  }

  private async getReadConfirmationStatus(messageId: number) {
    this.isLoading = true;

    try {
      const response = await MessageViewApi.getRequestConfirmationStatus(messageId);
      this.requestConfirmations = response.data;
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
      this.requestConfirmations.length = 0;
    }

    this.isLoading = false;
  }

  get totalRequestCount() {
    return this.requestConfirmations.length;
  }

  get confirmedRequestCount() {
    return this.requestConfirmations.filter((rc) => rc.confirmationStates.some((state) => !!state.date)).length;
  }
}
