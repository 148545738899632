import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

import { inject, Store } from '@/types/store';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { StudentLessonDto, SchoolYearDto } from '@untis/wu-rest-view-api/api';
import { StudentViewApi } from '@/stores/api-store';
import { getErrorMessageFromResponseError } from '@/utils/error-handling/error-handling';
import NotificationStore from '@/stores/notification-store/notification-store';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import {
  DateRangeOptionEnum,
  ICustomDateRange,
  IDateRange,
} from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import { formatDateForServerRequest } from '@/utils/date/date-util';
import ModalStore from '@/stores/modal-store';

export interface IStudentLessonRow extends ITableRowKey {
  lessonNumber?: string;
  lectureId?: string;
  class?: string;
  teacher?: string;
  subject?: string;
  period?: number;
  startDate?: Dayjs;
  endDate?: Dayjs;
}

export enum SelectOption {
  CUSTOM = 1,
  CURRENT_DAY,
  CURRENT_WEEK,
  CURRENT_MONTH,
  CURRENT_SCHOOLYEAR,
}

@Store()
export default class StudentLessonsStore {
  @observable protected _notificationStore: NotificationStore = inject(NotificationStore);
  @observable protected _modalStore: ModalStore = inject(ModalStore);

  @observable private _isDataLoading: boolean = true;
  @observable private _showLectureIdColumn: boolean = false;
  @observable private _showLessonNumberColumn: boolean = false;
  @observable private _currentSchoolYear: SchoolYearDto;
  @observable private _studentLessons: StudentLessonDto[] = [];
  @observable private _studentId: number;

  @observable private _selectedDateRange: IDateRange | ICustomDateRange = {
    option: DateRangeOptionEnum.DATE_RANGE_WEEK,
    startDate: dayjs().startOf('week'),
    endDate: dayjs().endOf('week'),
  };

  constructor(studentId: number, schoolYear: SchoolYearDto) {
    this._currentSchoolYear = schoolYear;
    this._studentId = studentId;
    this.fetchData();
  }

  @action
  async fetchData() {
    await this.fetchStudentLessons();
  }

  @action
  async fetchStudentLessons() {
    const startDate = formatDateForServerRequest(this._selectedDateRange.startDate);
    const endDate = formatDateForServerRequest(this._selectedDateRange.endDate);

    StudentViewApi.getStudentLessons(this._studentId, startDate, endDate)
      .then((response) => {
        this._studentLessons = response.data;
        this.setIsDataLoading(false);
        this.calculateDisplayOfLessonIdColumns(response.data);
      })
      .catch((error) => {
        const errorMessage = getErrorMessageFromResponseError(error);
        this._notificationStore.error({
          title: t('general.studentDetails.lessonsCouldNotBeLoaded'),
          message: errorMessage,
        });
      });
  }

  @action
  onDateSelect(value: string | undefined) {
    switch (value) {
      case SelectOption.CURRENT_DAY.toString():
        this.setSelectedDateRange({
          startDate: dayjs(),
          endDate: dayjs(),
          option: DateRangeOptionEnum.DATE_RANGE_TODAY,
        });
        break;
      case SelectOption.CURRENT_WEEK.toString():
        this.setSelectedDateRange({
          startDate: dayjs().startOf('week'),
          endDate: dayjs().endOf('week'),
          option: DateRangeOptionEnum.DATE_RANGE_WEEK,
        });
        break;
      case SelectOption.CURRENT_MONTH.toString():
        this.setSelectedDateRange({
          startDate: dayjs().startOf('month'),
          endDate: dayjs().endOf('month'),
          option: DateRangeOptionEnum.DATE_RANGE_MONTH,
        });
        break;
      case SelectOption.CURRENT_SCHOOLYEAR.toString():
        this.setSelectedDateRange({
          startDate: dayjs(this._currentSchoolYear.dateRange?.start),
          endDate: dayjs(this._currentSchoolYear.dateRange?.end),
          option: DateRangeOptionEnum.DATE_RANGE_SCHOOLYEAR,
        });
        break;
      case SelectOption.CUSTOM.toString():
        this._modalStore
          .openModalDateRangePicker(this.selectedDateRange.startDate, this.selectedDateRange.endDate)
          .then((result) => {
            if (result.value) {
              this.setSelectedDateRange({
                startDate: result.value.startDate,
                endDate: result.value.endDate,
                option: DateRangeOptionEnum.USER_SELECTION_DATE_RANGE,
              });
            }
          });
        break;
    }
  }

  @action
  async setSelectedDateRange(value: IDateRange | ICustomDateRange) {
    this._selectedDateRange = value;
    await this.fetchStudentLessons();
  }

  @computed
  get selectedDateRange(): IDateRange | ICustomDateRange {
    return this._selectedDateRange;
  }

  @computed
  get columns(): Columns<IStudentLessonRow> {
    const cols: Columns<IStudentLessonRow> = [];

    if (this._showLessonNumberColumn) {
      cols.push({
        type: ColumnType.Text,
        width: 20,
        key: 'lessonNumber',
        header: t('general.studentDetails.lessonId'),
      });
    }

    if (this._showLectureIdColumn) {
      cols.push({
        type: ColumnType.Text,
        width: 20,
        key: 'lectureId',
        header: t('general.studentDetails.lectureId'),
      });
    }

    cols.push(
      {
        type: ColumnType.Text,
        width: 200,
        key: 'class',
        header: t('general.class'),
      },
      {
        type: ColumnType.Text,
        width: 150,
        key: 'teacher',
        header: t('general.teacher'),
      },
      {
        type: ColumnType.Text,
        width: 20,
        key: 'subject',
        header: t('general.subject'),
      },
      {
        type: ColumnType.Text,
        width: 20,
        key: 'period',
        header: t('general.period'),
      },
      {
        type: ColumnType.Date,
        width: 20,
        key: 'startDate',
        header: t('general.startDate'),
      },
      {
        type: ColumnType.Date,
        width: 20,
        key: 'endDate',
        header: t('general.endDate'),
      },
    );

    return cols;
  }

  @computed
  get studentLessonsRows(): IStudentLessonRow[] {
    return StudentLessonsStore.mapStudentLessonsToRows(this._studentLessons);
  }

  @computed
  get isDataLoading(): boolean {
    return this._isDataLoading;
  }

  @action
  setIsDataLoading(value: boolean) {
    this._isDataLoading = value;
  }

  private static mapStudentLessonsToRows(_studentLessons: StudentLessonDto[]) {
    return _studentLessons.map((studentLesson) => {
      return {
        key: studentLesson.lectureId ? String(studentLesson.lectureId) : String(studentLesson.lessonNumber),
        lessonNumber: studentLesson.lectureId ? '' : String(studentLesson.lessonNumber),
        lectureId: studentLesson.lectureId ? String(studentLesson.lectureId) : '',
        class: studentLesson.class,
        teacher: studentLesson.teacher,
        subject: studentLesson.subject,
        period: studentLesson.period,
        startDate: studentLesson.startDate ? dayjs(studentLesson.startDate) : undefined,
        endDate: studentLesson.endDate ? dayjs(studentLesson.endDate) : undefined,
      };
    });
  }

  private calculateDisplayOfLessonIdColumns(data: Array<StudentLessonDto>) {
    data.forEach((element) => {
      if (element.lectureId) {
        this._showLectureIdColumn = true;
      } else {
        this._showLessonNumberColumn = true;
      }
    });
  }
}
