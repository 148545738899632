import React, { ChangeEvent } from 'react';
import clsx from 'clsx';

import { Icon } from '@/ui-components';

import './search-field.less';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  testId?: string;
  variant?: 'white';
  disabled?: boolean;
}

export const SearchField = (props: IProps) => {
  const onClear = () => {
    props.onChange('');
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value);
  };

  const testId = props.testId ? `${props.testId}-searchfield` : undefined;
  const inputTestId = testId ? `${testId}-input` : undefined;
  const clearButtonTestId = testId ? `${testId}-clear` : undefined;

  return (
    <div
      className={clsx('search-field', {
        'search-field--default': props.variant === undefined,
        'search-field--white': props.variant === 'white',
      })}
      data-testid={testId}
    >
      <Icon className="search-icon" type="search" />
      <input
        placeholder={props.placeholder}
        onChange={onChange}
        value={props.value}
        data-testid={inputTestId}
        disabled={props.disabled}
      />
      {props.value.length > 0 && (
        <button
          className="button-cancel"
          type="button"
          onClick={onClear}
          data-testid={clearButtonTestId}
          disabled={props.disabled}
        >
          <Icon type="cancel-circle-filled" />
        </button>
      )}
    </div>
  );
};
