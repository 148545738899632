/**
 * Use this delegate to open the particular dialog managed by the router or modal store
 * depending on modal routes configuration.
 * (We can remove this hook once the affected dialogs are available via the routes only)
 */
import dayjs from 'dayjs';

import useStore from '@/hooks/useStore';
import {
  IPeriodIdData,
  ITimeSlotArguments,
  buildCalendarEntryModalDialogProps,
} from '@/pages/calendar-entry/calendar-entry-detail-view/calendar-entry-detail-view';
import ModalStore from '@/stores/modal-store';
import RouterStore from '@/stores/router-store';
import { modalRoutes } from '@/utils/router/modal-routes-definitions';
import PostMessageStore from '@sp/stores/post-message-store';

export default function useModalDelegate() {
  const modalStore = useStore(ModalStore);
  const routerStore = useStore(RouterStore);
  const postMessageStore = useStore(PostMessageStore);

  function _getCalendarEntryTabWithFallback(tab: string) {
    return tab || 'class-registry';
  }

  function openPeriodDetailsDialog(data: { [param in keyof ITimeSlotArguments]: any }, tab: string) {
    const modalRoute = modalRoutes.periodDetailsRoute.getPathname(routerStore.routing.location.pathname, {
      ...data,
      tab: _getCalendarEntryTabWithFallback(tab),
    });
    modalRoute
      ? routerStore.routing.push(modalRoute)
      : modalStore.deprecatedOpenModalDialog(
          buildCalendarEntryModalDialogProps(
            {
              ...data,
              startDateTime: dayjs(data.startDateTime),
              endDateTime: dayjs(data.endDateTime),
            },
            tab,
          ),
        );
  }

  function openSinglePeriodDetailsDialog(data: { [param in keyof IPeriodIdData]: any }, tab: string) {
    const modalRoute = modalRoutes.singlePeriodDetailsRoute.getPathname(routerStore.routing.location.pathname, {
      ...data,
      tab: _getCalendarEntryTabWithFallback(tab),
    });
    modalRoute
      ? routerStore.routing.push(modalRoute)
      : modalStore.deprecatedOpenModalDialog(
          buildCalendarEntryModalDialogProps(data, tab, () => {
            postMessageStore.postReloadContentMessage();
          }),
        );
  }

  return { openPeriodDetailsDialog, openSinglePeriodDetailsDialog };
}
