import { observable } from 'mobx';

import { Store } from '@/types/store';
import { ReportType } from '@/components/reports-exporting-table/reports-exporting-table';
import { ReportUrlMapperStore } from '@/stores/report-url-mapper-store';

export interface IStudentReportParameters {
  classId: number | string;
  schoolyearId: number;
  newListPage: boolean;
}

enum StudentReportUrlParameter {
  CLASS_ID,
  SCHOOLYEAR_ID,
  NEW_LIST_PAGE,
}

export type StudentReportingType = 'students' | 'studentsWithPictures' | 'studentsWithAddresses';

interface IStudentReportConfig {
  reportName: string;
  urlParameters: StudentReportUrlParameter[];
}

const STUDENT_REPORT_CONFIG_MAP: Map<StudentReportingType, IStudentReportConfig> = new Map<
  StudentReportingType,
  IStudentReportConfig
>([
  [
    'students',
    {
      reportName: 'Student',
      urlParameters: [
        StudentReportUrlParameter.CLASS_ID,
        StudentReportUrlParameter.SCHOOLYEAR_ID,
        StudentReportUrlParameter.NEW_LIST_PAGE,
      ],
    },
  ],
  [
    'studentsWithPictures',
    {
      reportName: 'StudentImg',
      urlParameters: [
        StudentReportUrlParameter.CLASS_ID,
        StudentReportUrlParameter.SCHOOLYEAR_ID,
        StudentReportUrlParameter.NEW_LIST_PAGE,
      ],
    },
  ],
  [
    'studentsWithAddresses',
    {
      reportName: 'StudentAddress',
      urlParameters: [
        StudentReportUrlParameter.CLASS_ID,
        StudentReportUrlParameter.SCHOOLYEAR_ID,
        StudentReportUrlParameter.NEW_LIST_PAGE,
      ],
    },
  ],
]);

const STUDENT_REPORT_URL_PARAMETER_MAPPER: Map<
  StudentReportUrlParameter,
  (params: IStudentReportParameters) => string
> = new Map<StudentReportUrlParameter, (params: IStudentReportParameters) => string>([
  [StudentReportUrlParameter.CLASS_ID, (params: IStudentReportParameters) => `&klasseId=${params.classId}`],
  [
    StudentReportUrlParameter.SCHOOLYEAR_ID,
    (params: IStudentReportParameters) => `&schoolyearId=${params.schoolyearId}`,
  ],
  [StudentReportUrlParameter.NEW_LIST_PAGE, (params: IStudentReportParameters) => `&newListPage=${params.newListPage}`],
]);

@Store()
export class StudentReportUrlMapperStore extends ReportUrlMapperStore {
  @observable isLoading: boolean = false;

  mapToReportUrl(key: string, reportType: ReportType, params: IStudentReportParameters): string {
    const reportConfig = STUDENT_REPORT_CONFIG_MAP.get(key as StudentReportingType);
    const reportName = reportConfig?.reportName;
    const reportUrlParameters = reportConfig?.urlParameters;
    const reportFormat = reportType.toString().toLowerCase();

    const reportParameters = reportUrlParameters
      ?.map((reportUrlParameter) => STUDENT_REPORT_URL_PARAMETER_MAPPER.get(reportUrlParameter))
      .map((parameterMapper) => parameterMapper?.(params))
      .reduce((mappedParameterA, mappedParameterB) => mappedParameterA?.concat(mappedParameterB || ''));

    return this.getReportUrl(reportName, reportFormat, reportParameters);
  }
}
