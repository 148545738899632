import React from 'react';

import { Button } from '@/ui-components';
import { PlusOutlined } from '@ant-design/icons';

interface IProps {
  onTabAdd?: () => void;
}

const TabsAddButton = (props: IProps) => {
  return (
    <>
      {props.onTabAdd && (
        <Button className="untis-add-button" testId="tabs__add__tab" outline onClick={props.onTabAdd}>
          <PlusOutlined translate="no" />
        </Button>
      )}
    </>
  );
};

export default TabsAddButton;
