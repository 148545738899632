import dayjs from 'dayjs';
import React, { ReactNode } from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import useStore from '@/hooks/useStore';
import CalendarEntryDetailView from '@/pages/calendar-entry/calendar-entry-detail-view/calendar-entry-detail-view';
import { DeprecatedModalDefinition } from '@/stores/modal-store';
import RouterStore from '@/stores/router-store';
import { modalRoutes, ModalRoute } from '@/utils/router/modal-routes-definitions';
import PostMessageStore from '@sp/stores/post-message-store';
import { DeprecatedModal } from '@/ui-components';

interface IProps {
  containerRefEl?: HTMLDivElement;
  expanded: boolean;
  showFocusedElements: boolean;
}

interface IBaseRouteModalProps extends IProps {
  children: ReactNode;
  modalRoute: ModalRoute<any>;
  onClose?: () => void;
}

function BaseRouteModal(brmProps: IBaseRouteModalProps) {
  const routerStore = useStore(RouterStore);

  const onClose = () => {
    routerStore.routing.push(brmProps.modalRoute.removeModalSubPath(routerStore.routing.location.pathname));
    brmProps.onClose && brmProps.onClose();
  };
  const md = new DeprecatedModalDefinition(onClose, onClose);
  md.size = 'full-size';
  md.closeOnEscape = true;
  md.lastFocusedElement = document.activeElement as HTMLElement;
  md.onClose = onClose;

  return (
    <DeprecatedModal
      {...md.getADModalProps(brmProps.expanded, brmProps.showFocusedElements)}
      getContainer={brmProps.containerRefEl}
    >
      {brmProps.children}
    </DeprecatedModal>
  );
}

/**
 * Component defining all the modal routes with particular wrapped components.
 * This is yet another complementary approach of modal dialogs handling.
 * Another main approach is still managed by the modal-store.ts
 * which is responsible for all other dialogs not needing to be accessible via the routes (yet).
 * @see hooks/useModalDelegate.tsx
 * @see utils/router/modal-routes-definitions.ts
 */
export default function ModalRoutes(props: IProps) {
  const postMessageStore = useStore(PostMessageStore);

  return (
    <Switch>
      <Route
        path={modalRoutes.periodDetailsRoute.getRoutePath()}
        render={(
          routeProps: RouteComponentProps<{
            initialSelectedPeriodId?: string;
            initialIsBlockSelected?: string;
            elementId?: string;
            elementType?: string;
            startDateTime?: string;
            endDateTime?: string;
            periodId?: string;
          }>,
        ) => {
          return (
            <BaseRouteModal {...props} modalRoute={modalRoutes.periodDetailsRoute}>
              <CalendarEntryDetailView
                data={{
                  initialSelectedPeriodId: parseInt(routeProps.match.params.initialSelectedPeriodId ?? ''),
                  initialIsBlockSelected: routeProps.match.params.initialIsBlockSelected?.toLowerCase() === 'true',
                  elementId: parseInt(routeProps.match.params.elementId ?? ''),
                  elementType: parseInt(routeProps.match.params.elementType ?? ''),
                  startDateTime: dayjs(decodeURIComponent(routeProps.match.params.startDateTime ?? '')),
                  endDateTime: dayjs(decodeURIComponent(routeProps.match.params.endDateTime ?? '')),
                }}
              />
            </BaseRouteModal>
          );
        }}
      />
      <Route
        path={modalRoutes.singlePeriodDetailsRoute.getRoutePath()}
        render={(routeProps) => {
          return (
            <BaseRouteModal
              {...props}
              modalRoute={modalRoutes.singlePeriodDetailsRoute}
              onClose={() => postMessageStore.postReloadContentMessage()}
            >
              <CalendarEntryDetailView
                data={{
                  periodId: parseInt(routeProps.match.params.periodId ?? ''),
                }}
              />
            </BaseRouteModal>
          );
        }}
      />
    </Switch>
  );
}
