import { action, observable } from 'mobx';
import { t } from 'i18next';
import { Dayjs } from 'dayjs';

import { ExamDto, MasterDataRefDto } from '@untis/wu-rest-view-api/api';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { ExamsViewApi } from '@/stores/api-store';
import { formatDateTimeForServerRequest } from '@/utils/date/date-util';
import { AbstractExamFormStore } from '@ls/exams/exam-form/abstract-exam-form-store';
import { AssignedTeachersRow } from '@/components/assigned-teachers/assigned-teachers';

export interface IExamWizardForm {
  name: string;
  text: string;
  dateTimeRange: {
    startDateTime: Dayjs;
    endDateTime: Dayjs;
  };
  examType: string | undefined;
  rooms: IMultiTagSelectItem[];
  students: IMultiTagSelectItem[];
  assignedTeachers: AssignedTeachersRow[];
}

export enum ExamWizardType {
  LESSON,
  PERIOD,
}

export class ExamWizardStore extends AbstractExamFormStore<IExamWizardForm> {
  @observable private readonly _examWizardType: ExamWizardType;
  @observable private readonly _elementId: number;
  @observable private readonly _elementStartTime: Dayjs;
  @observable private readonly _elementEndTime: Dayjs;
  @observable private readonly _isBlockSelected: boolean;

  constructor(type: ExamWizardType, elementId: number, startTime: Dayjs, endTime: Dayjs, isBlockSelected: boolean) {
    super();
    this._examWizardType = type;
    this._elementId = elementId;
    this._elementStartTime = startTime;
    this._elementEndTime = endTime;
    this._isBlockSelected = isBlockSelected;
  }

  @action
  async fetchData() {
    await this.getExamSettings();
    await this.getExamWizardData();
    this.updateModalHeader();

    this._isLoading = false;
  }

  @action
  private async getExamWizardData() {
    if (this._examWizardType === ExamWizardType.PERIOD) {
      const wizardFormResponse = await ExamsViewApi.getExamWizardForPeriod(
        this._elementId,
        formatDateTimeForServerRequest(this._elementStartTime),
        formatDateTimeForServerRequest(this._elementEndTime),
        this._isBlockSelected,
      );
      const formData = wizardFormResponse.data;
      this._exam = formData.exam;
      this.setupFormData(formData);
      this.setupExam();
    } else {
      const wizardFormResponse = await ExamsViewApi.getExamWizardForLesson(
        this._elementId,
        formatDateTimeForServerRequest(this._elementStartTime),
        formatDateTimeForServerRequest(this._elementEndTime),
      );
      const formData = wizardFormResponse.data;
      this._exam = formData.exam;
      this.setupFormData(formData);
      this.setupExam();
    }
  }

  @action
  private setupExam() {
    if (this._exam) {
      this._exam.examName = this._exam.subject?.displayName;
    }
  }

  @action
  async saveExamWizard(value: IExamWizardForm, onSaveCallback?: () => void) {
    this._isSubmitting = true;
    const examDto = this.formExamToExamDto(value, this._exam!);
    try {
      await ExamsViewApi.createExam(examDto);
      onSaveCallback && onSaveCallback();
      this.notificationStore.success({ title: t('lessons.exams.messages.examSaved') });
      this.modalStore.closeModal();
    } catch (error) {
      this._isSubmitting = false;
      this.displayError(error, t('lessons.exams.messages.examSavedError'), this.form);
    }
  }

  @action
  private formExamToExamDto(value: IExamWizardForm, examDto: ExamDto): ExamDto {
    const emptyMasterDataRef: MasterDataRefDto = {
      id: -1,
      displayName: '',
      shortName: '',
      longName: '',
    };

    return {
      ...examDto,
      examId: -1,
      examName: value.name,
      examText: value.text,
      examStart: formatDateTimeForServerRequest(value.dateTimeRange.startDateTime),
      examEnd: formatDateTimeForServerRequest(value.dateTimeRange.endDateTime),
      examType: this._examTypes.find((e) => e.id.toString() === value.examType) || emptyMasterDataRef,
      rooms: value.rooms.map(this.mapToMasterDataRefDto),
      students: value.students.map(this.mapToExamStudentDto),
      invigilators: this.calculateInvigilators(value.dateTimeRange, value.assignedTeachers),
      exported: false,
      deleted: false,
      isUntisExam: false,
      examBookedUser: {
        id: this.configStore.userId || -1,
        displayName: this.configStore.userName,
        shortName: this.configStore.userName,
        longName: this.configStore.userName,
      },
      examModifiedUser: {
        id: this.configStore.userId || -1,
        displayName: this.configStore.userName,
        shortName: this.configStore.userName,
        longName: this.configStore.userName,
      },
    };
  }
}
