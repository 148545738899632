import React from 'react';
import { useTranslation } from 'react-i18next';

import './calendar-footer.less';
import { Button } from '@/ui-components/button/button';

interface IProps {
  onSubmit?: () => void;
  submitDisabled?: boolean;
  testId?: string;
}

export const CalendarFooter = (props: IProps) => {
  const { t } = useTranslation();
  const testId = props.testId ? `${props.testId}-submit` : undefined;

  return (
    <div className="calendar-footer">
      {props.onSubmit && (
        <Button testId={testId} disabled={props.submitDisabled} type="primary" onClick={props.onSubmit}>
          {t('general.ok')}
        </Button>
      )}
    </div>
  );
};
