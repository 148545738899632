import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/ui-components';

import './lesson-card-removed-tag.less';

interface IProps {
  removedDisplayName: string;
  showTag?: boolean;
  showChange?: boolean;
  isTopElement?: boolean;
}

const LessonCardRemovedTag = (props: IProps) => {
  const { t } = useTranslation();
  const { removedDisplayName, showTag, showChange, isTopElement } = props;

  return (
    <span
      className={clsx('lesson-card-remove-tag', {
        'lesson-card-top-element': isTopElement,
        'show-tag': showTag,
      })}
      data-testid="lesson-card-remove-tag"
      key={removedDisplayName}
    >
      <span className="removed-resource" data-testid="removed-resource">
        {removedDisplayName}
      </span>
      {showChange && showTag && (
        <>
          <Icon type="arrow-right-16" />
          <span className="no-substitution" data-testid="no-substitution">
            {t('general.noSubstitution')}
          </span>
        </>
      )}
    </span>
  );
};

export default LessonCardRemovedTag;
