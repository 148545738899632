/**
 * @deprecated
 * Instead of having a helper function that creates some props for a component, the component itself should be
 * modified in a way, so that it is rendered correctly and this is not necessary (Code smell)
 */
const deprecatedGetFormColProps = ({
  span,
  sm,
  offset,
  smOffset,
}: {
  span: number;
  sm?: number;
  offset?: number;
  smOffset?: number;
}) => ({
  xl: {
    span,
    offset,
  },
  sm: {
    span: sm ? sm : 24,
    offset: smOffset ? smOffset : 0,
  },
});

export default deprecatedGetFormColProps;
