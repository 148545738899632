import React from 'react';
import { Form, Switch } from 'antd';
import clsx from 'clsx';

import { IFormItemProps } from '../wu-form-item';

interface IFormSwitchProps extends IFormItemProps<boolean> {
  centered?: boolean;
}

export const FormSwitch = (props: IFormSwitchProps) => {
  const className = clsx('form-radio-buttons', {
    centered: !!props.centered,
  });

  return (
    <Form.Item
      name={props.name}
      label={props.label}
      initialValue={props.initialValue}
      className={className}
      rules={props.rules}
      dependencies={props.dependencies}
      valuePropName="checked"
      labelAlign="left"
    >
      <Switch data-testid={props.testId} />
    </Form.Item>
  );
};
