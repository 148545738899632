import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormItemProps as AntFormItemProps, Rule } from 'antd/lib/form';
import { FormInstance } from 'antd/es/form';
import { v4 } from 'uuid';

import { ITestComponentProps } from '@/types/test-component-props';
import { DeprecatedForm } from '@/ui-components';
import FormRow from '@/pages/calendar-entry/form-row/form-row';

export interface IDeprecatedValidateTriggerProps {
  validateTrigger?: 'onChange' | 'onSubmit'; // default: 'onChange'
}

export interface IDeprecatedFormItemValidationRule<T> {
  errorMessage: string;
  validationCallback: (value: T) => boolean;
}

interface IProps<T> extends ITestComponentProps {
  children?: React.ReactNode;
  errorMessage?: string;
  rules?: IDeprecatedFormItemValidationRule<T>[];
  validationDependencies?: any[]; // array of properties on which the validation callbacks of this component depend
  form?: FormInstance;
  formSubmittedOnce?: boolean;
  value?: T;
  className?: string;
  formRowIcon?: string;
  formRowNoMargin?: boolean;
}

type AntFormItemWrapperProps = Pick<AntFormItemProps, 'label' | 'name' | 'help' | 'validateStatus'>;
export type DeprecatedFormItemWrapperProps<T> = Omit<IProps<T>, 'children'> & AntFormItemWrapperProps;

type FormItemProps<T> = IProps<T> & Omit<AntFormItemProps, 'className' | 'required' | 'rules'>;

/**
 * This component can be used as a wrapper for other components, which shall be used within a <WUForm>. It offers a
 * simple way to use validation constraints on form elements. These constraints can be set via the 'rules' property. The
 * 'validationDependencies' property can be used to specify validation dependencies between the provided 'value'
 * property and other (external) properties.
 *
 * Validation Constraint Example:
 * ####################################################################################################################
 * rules: [
 *    {
 *       errorMessage: 'End date has to be after start date!',
 *       validationCallback: (newDate) => newDate.isAfter(testFormStore.startDate),
 *    },
 * ],
 * validationDependencies: [testFormStore.startDate],
 * ####################################################################################################################
 *
 * In the upper example the 'validationCallback' depends on 'testFormStore.startDate', therefore this property is
 * provided to the component via the 'validationDependencies' property.
 *
 * Furthermore, the 'errorMessage' property of the <FormItem> component can be used to display a constant error message
 * beneath the corresponding form element.
 */
function DeprecatedFormItem<T>(props: FormItemProps<T>) {
  const {
    children,
    className,
    errorMessage,
    help,
    validateStatus,
    name,
    testId,
    valuePropName,
    validateTrigger,
    rules,
    validationDependencies,
    form,
    formSubmittedOnce,
    value,
    formRowIcon,
    formRowNoMargin,
    ...itemProps
  } = props;
  const [itemName] = useState(name ? name : () => v4());
  const renderedOnce = useRef(false);
  const dynamicUseEffectDependencies: any[] = validationDependencies ?? [];
  const validationEffectDependencies =
    validateTrigger === 'onChange' ? [form, itemName, value, ...dynamicUseEffectDependencies] : [];

  useEffect(() => {
    if (renderedOnce.current) {
      if (formSubmittedOnce) {
        form?.validateFields([itemName]);
      }
    } else {
      renderedOnce.current = true;
    }
    // eslint-disable-next-line
  }, validationEffectDependencies);

  const itemRules = useCallback(
    (value: any): Rule[] => {
      return rules
        ? rules.map((rule): Rule => {
            return {
              validator: () => {
                if (rule.validationCallback(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error(rule.errorMessage));
                }
              },
            };
          })
        : [];
    },
    [rules],
  );

  const validationTrigger = validateTrigger && validateTrigger === 'onBlur' ? validateTrigger : [];

  return (
    <FormRow className={className} icon={formRowIcon} noMargin={formRowNoMargin}>
      <DeprecatedForm.Item
        initialValue={value}
        {...itemProps}
        name={itemName}
        help={errorMessage ? errorMessage : help}
        validateStatus={errorMessage ? 'error' : validateStatus}
        data-testid={testId}
        rules={itemRules(value)}
        valuePropName={valuePropName ?? ''}
        validateTrigger={validationTrigger}
      >
        {children}
      </DeprecatedForm.Item>
    </FormRow>
  );
}

export default DeprecatedFormItem;
