import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import TimetableFormatSettingsStore, {
  HIGHLIGHT_CHANGES,
  HIGHLIGHT_EXAMS,
  ITimetableFormatFormData,
  ITimetableFormatRow,
  SHOW_PERIOD_NAME,
  SHOW_SYMBOLS,
  SHOW_TIME_IN_GRID,
  SHOW_UNCONFIRMED_BOOKINGS,
  SHOW_TIME_IN_CARD,
  SHOW_PERIOD_NUMBER,
  SHOW_LESSON_TEXT,
  SHOW_USER,
  SHOW_INFO,
  SHOW_EXTERNAL_CALENDARS,
  SHOW_OFFICE_HOURS,
  SHOW_STANDBY_PERIODS,
  SHOW_CANCELLED_PERIODS,
  HIGHLIGHT_CANCELLED_PERIODS,
  HIGHLIGHT_EXTERNAL_CALENDARS,
} from '@te/settings/timetable-format/timetable-format-settings-store';
import { WUForm } from '@/ui-components';
import {
  FormCheckbox,
  FormCheckboxes,
  FormInput,
  FormRadioButtons,
  FormSection,
  FormTimeRangePicker,
  IFormButton,
  WUFormCol,
  WUFormRow,
} from '@/ui-components/wu-form';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { TimegridStore } from '@/stores/timegrid-store';
import { TimeRange } from '@/ui-components/wu-form/form-time-range-picker/form-time-range-picker';
import { EntryResourceTypeEnum, TimeGridTypeEnum } from '@untis/wu-rest-view-api/api';
import {
  FormWeekdaysPicker,
  IWeekDaySelection,
} from '@te/settings/timetable-format/form-components/weekdays-picker/form-weekdays-picker';
import { dayjsFromTimeString } from '@/utils/date/date-util';
import { FormLessonCardLayoutConfigurer } from '@te/settings/timetable-format/form-components/lesson-card-layout-configurer/form-lesson-card-layout-configurer';

interface IProps {
  store: TimetableFormatSettingsStore;
  format?: ITimetableFormatRow;
}

const TimetableFormatSettingsForm = observer((props: IProps) => {
  const { t } = useTranslation();
  const { store } = props;

  const modalStore = useStore(ModalStore);
  const timegridStore = useStore(TimegridStore);

  const isInEditMode = !!props.format;
  const leftButtons: IFormButton[] = isInEditMode
    ? [
        {
          label: t('general.delete'),
          type: 'destructive',
          onClick: () => {
            if (props.format) {
              store.deleteTimetableFormat(props.format.key);
            }
          },
        },
      ]
    : [];

  const rightButtons: IFormButton[] = [
    {
      label: t('general.cancel'),
      onClick: () => {
        modalStore.closeModal();
      },
    },
  ];

  const initialTimeGridSettings = useMemo(() => {
    const result = [];
    if (!props.format || props.format.showTimeInGrid) {
      result.push(SHOW_TIME_IN_GRID);
    }
    if (props.format?.showPeriodNumber) {
      result.push(SHOW_PERIOD_NUMBER);
    }
    if (props.format?.showPeriodName) {
      result.push(SHOW_PERIOD_NAME);
    }
    return result;
  }, [props.format]);

  const initialPeriodCardSettings = useMemo(() => {
    const result = [];
    if (props.format?.showTimeInCard) {
      result.push(SHOW_TIME_IN_CARD);
    }
    if (props.format?.showLessonText) {
      result.push(SHOW_LESSON_TEXT);
    }
    if (props.format?.showUser) {
      result.push(SHOW_USER);
    }
    if (props.format?.showInfo) {
      result.push(SHOW_INFO);
    }
    return result;
  }, [props.format]);

  const initialAdditionalActivitiesSettings = useMemo(() => {
    const result = [];
    if (!props.format || props.format.showUnconfirmedBookings) {
      result.push(SHOW_UNCONFIRMED_BOOKINGS);
    }
    if (props.format?.showCancelledPeriods) {
      result.push(SHOW_CANCELLED_PERIODS);
    }
    if (!props.format || props.format.showStandByPeriods) {
      result.push(SHOW_STANDBY_PERIODS);
    }
    if (!props.format || props.format.showOfficeHours) {
      result.push(SHOW_OFFICE_HOURS);
    }
    if (!props.format || props.format.showExternalCalendars) {
      result.push(SHOW_EXTERNAL_CALENDARS);
    }
    return result;
  }, [props.format]);

  const initialPeriodCardHighlight = useMemo(() => {
    const result = [];
    if (!props.format || props.format.highlightChanges) {
      result.push(HIGHLIGHT_CHANGES);
    }
    if (!props.format || props.format.highlightExams) {
      result.push(HIGHLIGHT_EXAMS);
    }
    if (!props.format || props.format.highlightCancelledPeriods) {
      result.push(HIGHLIGHT_CANCELLED_PERIODS);
    }
    if (!props.format || props.format.highlightExternalCalendars) {
      result.push(HIGHLIGHT_EXTERNAL_CALENDARS);
    }
    if (!props.format || props.format.showSymbols) {
      result.push(SHOW_SYMBOLS);
    }
    return result;
  }, [props.format]);

  const initialDuration: TimeRange = useMemo(() => {
    return {
      startTime: props.format
        ? dayjsFromTimeString(props.format.duration.start)
        : dayjs().startOf('day').set('hour', 8).set('minute', 0),
      endTime: props.format
        ? dayjsFromTimeString(props.format.duration.end)
        : dayjs().startOf('day').set('hour', 18).set('minute', 0),
    };
  }, [props.format]);

  const initialTimeGridType = useMemo(
    () => props.format?.timeGridType?.toString() ?? TimeGridTypeEnum.LESSON_GRID.toString(),
    [props.format],
  );

  const weekDaysPickerInitialValue: IWeekDaySelection = useMemo(
    () => ({
      isTimeGridDays: props.format ? props.format.isTimeGridDays : true,
      days: props.format ? props.format.days : [],
    }),
    [props.format],
  );

  const initialLessonCardLayout = useMemo(() => {
    if (props.format) {
      return props.format.cardLayout;
    } else {
      return [
        EntryResourceTypeEnum.CLASS,
        EntryResourceTypeEnum.TEACHER,
        EntryResourceTypeEnum.SUBJECT,
        EntryResourceTypeEnum.ROOM,
      ];
    }
  }, [props.format]);

  const [selectedTimeGridType, setSelectedTimeGridType] = useState<string>(() => initialTimeGridType);
  const [showCancellations, setShowCancellations] = useState<boolean>(() =>
    initialAdditionalActivitiesSettings.includes(SHOW_CANCELLED_PERIODS),
  );

  return (
    <WUForm<ITimetableFormatFormData>
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      fixedButtonBar
      onSubmit={
        isInEditMode
          ? (value) => store.updateTimetableFormat(value, props.format?.key ?? -1)
          : (value) => store.createTimetableFormat(value)
      }
      maxWidth
      paddingTop
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.general')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              name="name"
              testId="name"
              initialValue={props?.format?.name ? props.format.name : ''}
              rules={[
                { required: true, message: t('general.inputRequired') },
                { max: 20, message: t('general.valueMustBeWithinRange', { min: 1, max: 20 }) },
              ]}
              variant="floating"
              label={t('general.name')}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow noMarginBottom>
          <WUFormCol lg={2}>
            <FormSection description={t('general.detailsOfPeriods')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormCheckbox
              name="showDetails"
              testId="show-details"
              initialValue={props.format?.showDetails ?? true}
              text={t('general.showPeriodDetailsByClickOnCard')}
              textNoBreakWhiteSpace
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow noMarginBottom>
          <WUFormCol lg={2}>
            <FormSection description={t('general.unannouncedExams')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormCheckbox
              name="showUnannouncedExams"
              testId="show-unannounced-exams"
              initialValue={props.format?.showUnannouncedExams ?? false}
              text={t('general.showUnannouncedExams')}
              textNoBreakWhiteSpace
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection description={t('general.displayOfIndividualColors')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormCheckbox
              name="showSubjectColors"
              testId="show-subject-colors"
              initialValue={props.format?.showSubjectColors ?? true}
              text={t('general.showIndividualColorsOnlySubjectColors')}
              textNoBreakWhiteSpace
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.timetableGridSettings')} description={t('general.timeGridOptions')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormRadioButtons
              name="timeGridType"
              testId="time-grid-type"
              options={
                new Map([
                  [TimeGridTypeEnum.LESSON_GRID.toString(), t('general.untisTimeGrid')],
                  [TimeGridTypeEnum.CLOCK_HOURS.toString(), t('general.actualTime')],
                ])
              }
              rules={[{ required: true, message: t('general.inputRequired') }]}
              onChange={(v) => setSelectedTimeGridType(v as string)}
              initialValue={initialTimeGridType}
              display="vertical"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection description={t('general.headerOfPeriodsTimetableGridRows')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormCheckboxes
              name="timeGridSlotSettings"
              testId="time-grid-settings"
              options={[
                {
                  label: t('general.showStartAndEndTimes'),
                  value: SHOW_TIME_IN_GRID,
                  testId: 'show-time-in-grid',
                },
                {
                  label: t('general.showPeriodNumber'),
                  value: SHOW_PERIOD_NUMBER,
                  testId: 'show-period-number',
                },
                {
                  label: t('general.showPeriodName'),
                  value: SHOW_PERIOD_NAME,
                  testId: 'show-period-name',
                },
              ]}
              disabled={selectedTimeGridType === TimeGridTypeEnum.CLOCK_HOURS.toString()}
              verticalAlign
              initialValue={initialTimeGridSettings}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection
              text={t('general.periodCardLayout')}
              description={`${t('general.1stElementOnCardRequired')}\n\n${t('general.2ndElementOnCardOptional')}\n\n${t(
                'general.3rdElementOnCardOptional',
              )}\n\n${t('general.4thElementOnCardOptional')}`}
            />
          </WUFormCol>
          <WUFormCol lg={2}>
            <div style={{ marginTop: 30 }}>
              <FormLessonCardLayoutConfigurer
                name="lessonCardLayout"
                testId="lesson-card-layout"
                initialValue={initialLessonCardLayout}
                rules={[
                  {
                    message: t('general.inputRequired'),
                    validator(rule, lessonCardLayout: EntryResourceTypeEnum[]) {
                      if (lessonCardLayout.length === 0) {
                        return Promise.reject(new Error('Empty input'));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              />
            </div>
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection
              text={t('general.timetableAvailabilityOptions')}
              description={t('general.selectDaysToDisplayTheTimetableFor')}
            />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormWeekdaysPicker
              name="weekdays"
              testId="weekdays"
              initialValue={weekDaysPickerInitialValue}
              firstDayOfTheWeek={store.firstDayOfWeek}
              rules={[
                {
                  message: t('general.inputRequired'),
                  validator(_, weekDaySelection: IWeekDaySelection) {
                    const { days, isTimeGridDays } = weekDaySelection;
                    if (!isTimeGridDays && (!days || days.length === 0)) {
                      return Promise.reject(new Error('Empty input'));
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection description={t('general.timesToDisplayTheTimetableFor')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormTimeRangePicker
              name="duration"
              testId="duration"
              timeSlots={timegridStore.timeRangePickerTimeSlots}
              onlyEditOwnTimeSlot
              initialValue={initialDuration}
              rules={[
                {
                  message: t('general.dateTimeRangeEndBeforeStart'),
                  validator(_, dateTimeRange: TimeRange) {
                    const { startTime, endTime } = dateTimeRange;
                    if (endTime.isSameOrBefore(startTime, 'minute')) {
                      return Promise.reject(new Error('End time is before start time'));
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.additionalInformationOnPeriodCards')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormCheckboxes
              name="periodCardSettings"
              testId="period-card-settings"
              options={[
                {
                  label: t('general.showStartAndEndTimes'),
                  value: SHOW_TIME_IN_CARD,
                  testId: 'show-time-in-card',
                },
                {
                  label: t('general.showLessonTextDayViewOnly'),
                  value: SHOW_LESSON_TEXT,
                  testId: 'show-lesson-text',
                },
                {
                  label: t('general.showUserDayViewOnly'),
                  value: SHOW_USER,
                  testId: 'show-user',
                },
                {
                  label: t('general.showPeriodInformationDayViewOnly'),
                  value: SHOW_INFO,
                  testId: 'show-info',
                },
              ]}
              verticalAlign
              initialValue={initialPeriodCardSettings}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection
              text={t('general.displayOfAdditionalActivities')}
              description={`${t('general.selectAdditionalActivitiesToShowOnTimetable')}\n\n\n${t(
                'general.externalEventsOptionDescription',
              )}`}
            />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormCheckboxes
              name="additionalActivities"
              testId="additional-activities-settings"
              options={[
                {
                  label: t('general.showUnconfirmedBookings'),
                  value: SHOW_UNCONFIRMED_BOOKINGS,
                  testId: 'show-unconfirmed-bookings',
                },
                {
                  label: t('general.showCancelledLessons'),
                  value: SHOW_CANCELLED_PERIODS,
                  testId: 'show-cancelled-periods',
                },
                {
                  label: t('general.showStandbyPeriods'),
                  value: SHOW_STANDBY_PERIODS,
                  testId: 'show-standby-periods',
                },
                {
                  label: t('general.showContactHours'),
                  value: SHOW_OFFICE_HOURS,
                  testId: 'show-office-hours',
                },
                {
                  label: t('general.showExternalCalendars'),
                  value: SHOW_EXTERNAL_CALENDARS,
                  disabled: store.externalCalendarIntegrations.length === 0,
                  testId: 'show-external-calendars',
                },
              ]}
              verticalAlign
              onChange={(checkedValue) => setShowCancellations(checkedValue.includes(SHOW_CANCELLED_PERIODS))}
              initialValue={initialAdditionalActivitiesSettings}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection
              text={t('general.defaultConfigurationOfHighlightBarTitle')}
              description={t('general.defaultConfigurationOfHighlightBarDescription')}
            />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormCheckboxes
              name="periodCardHighlight"
              testId="period-card-highlight-settings"
              options={[
                {
                  label: t('general.highlightChanges'),
                  value: HIGHLIGHT_CHANGES,
                  testId: 'highlight-changes',
                },
                {
                  label: t('general.highlightExams'),
                  value: HIGHLIGHT_EXAMS,
                  testId: 'highlight-exams',
                },
                {
                  label: t('general.highlightCancelledLessons'),
                  value: HIGHLIGHT_CANCELLED_PERIODS,
                  disabled: !showCancellations,
                  testId: 'highlight-cancelled-periods',
                },
                {
                  label: t('general.highlightExternalCalendars'),
                  value: HIGHLIGHT_EXTERNAL_CALENDARS,
                  disabled: store.externalCalendarIntegrations.length === 0,
                  testId: 'highlight-external-calendars',
                },
                {
                  label: t('general.showSymbolsOnCards'),
                  value: SHOW_SYMBOLS,
                  testId: 'show-symbols',
                },
              ]}
              verticalAlign
              initialValue={initialPeriodCardHighlight}
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});

export default TimetableFormatSettingsForm;
