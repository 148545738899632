import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DeprecatedSearchBar } from '../../ui-components/deprecated-search-bar/deprecated-search-bar';

import { DeprecatedFilterBar, DeprecatedFilterBarValue } from '@/ui-components/filter-bar/deprecated-filter-bar';
import { MultiSelectOptionList } from '@/ui-components/select-option-list/select-option-list';
import useStore from '@/hooks/useStore';
import WuItemPickerDialogStore from '@/components/wu-item-picker-dialog/wu-item-picker-dialog-store';
import { IDeprecatedFilter, IFilterItem } from '@/ui-components/filter-bar/filter/deprecatedFilter';

import './wu-item-picker-dialog.less';

const WuItemPickerDialog = observer(() => {
  const wuItemPickerDialogStore = useStore(WuItemPickerDialogStore);
  const { t } = useTranslation();

  return (
    <div className="wu-item-picker-content">
      <DeprecatedSearchBar
        value={wuItemPickerDialogStore.searchBarValue}
        onChange={(value) => wuItemPickerDialogStore.setSearchBarValue(value)}
        placeholder={t('general.search')}
      />
      <DeprecatedFilterBar
        filter={wuItemPickerDialogStore.filters}
        value={wuItemPickerDialogStore.selectedFilterTags}
        onChange={(
          newFilterItems: DeprecatedFilterBarValue,
          filter: IDeprecatedFilter,
          item: IFilterItem | undefined,
        ) => wuItemPickerDialogStore.handleFilterChange(filter, item)}
      />
      <MultiSelectOptionList
        value={wuItemPickerDialogStore.selectedItems}
        options={wuItemPickerDialogStore.selectOptions}
        name="wu-item-picker"
        onChange={(values) => wuItemPickerDialogStore.setVisibleSelectedItems(values as string[])}
      />
    </div>
  );
});

export default WuItemPickerDialog;
