import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { observer } from 'mobx-react-lite';

import { WeekdayEnum } from '@untis/wu-rest-view-api/api';
import { IWeekDayValue, TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import { TimetableUserActionsStore } from '@te/standard/stores/user-actions/timetable-user-actions-store';
import { TimetableDataStore } from '@te/standard/stores/data/timetable-data-store';
import { TimetableTimeStore } from '@te/standard/stores/time/timetable-time-store';
import { TimetableGridDimensionsStore } from '@te/standard/stores/grid/timetable-grid-dimensions-store';
import TimetableFullDayEvent from '@te/standard/components/grid/day/full-day-event/timetable-full-day-event';
import TimetableMoreFullDayEventIndicator from '@te/standard/components/grid/day/full-day-event/timetable-more-full-day-event-indicator';
import useStore from '@/hooks/useStore';

import './timetable-grid-day-header.less';

const WEEKDAY_TRANSLATION_MAP: Map<WeekdayEnum, string> = new Map<WeekdayEnum, string>([
  [WeekdayEnum.MO, 'weekDays.mondayShort'],
  [WeekdayEnum.TU, 'weekDays.tuesdayShort'],
  [WeekdayEnum.WE, 'weekDays.wednesdayShort'],
  [WeekdayEnum.TH, 'weekDays.thursdayShort'],
  [WeekdayEnum.FR, 'weekDays.fridayShort'],
  [WeekdayEnum.SA, 'weekDays.saturdayShort'],
  [WeekdayEnum.SU, 'weekDays.sundayShort'],
]);

interface IProps {
  weekDay: IWeekDayValue;
  isToday?: boolean;
}

const TimetableGridDayHeader = observer((props: IProps) => {
  const timetableMetaStore = useStore(TimetableMetaStore);
  const timetableUserActionsStore = useStore(TimetableUserActionsStore);
  const timetableDataStore = useStore(TimetableDataStore);
  const timetableTimeStore = useStore(TimetableTimeStore);
  const timetableGridDimensionsStore = useStore(TimetableGridDimensionsStore);

  const { t } = useTranslation();
  const { weekDay, isToday } = props;
  const { weekDayEnum, dayValue } = weekDay;
  const { timetableGridMinDayWidth } = timetableGridDimensionsStore;

  const translationKey = WEEKDAY_TRANSLATION_MAP.get(weekDayEnum) || '';
  const content = `${t(translationKey)} ${dayValue.format('DD.MM.')}`;

  const fullDayEvents = timetableDataStore.getFullDayEvents(dayValue);
  const isPast = dayValue.clone().endOf('day').isBefore(timetableTimeStore.currentTime, 'minutes');

  return (
    <div
      className={classNames('timetable-grid--weekday-header', { today: isToday })}
      style={{ minWidth: timetableGridMinDayWidth }}
    >
      {timetableMetaStore.timetableViewType === 'week' && (
        <div className="timetable-grid--weekday-header-label">
          <span
            className="timetable-grid--weekday-header-label-text"
            onClick={() => timetableUserActionsStore.toggleDayColumnHeaderDate(weekDay.dayValue)}
          >
            {content}
            {isToday && (
              <>
                <br />
                {t('general.today')}
              </>
            )}
          </span>
        </div>
      )}
      {fullDayEvents.events.length > 0 && (
        <div
          className={classNames('timetable-grid--full-day-events', {
            'margin-top': timetableMetaStore.timetableViewType === 'day',
          })}
        >
          {fullDayEvents.events.map((fullDayEvent) => (
            <TimetableFullDayEvent key={fullDayEvent.id} fullDayEvent={fullDayEvent} />
          ))}
          {fullDayEvents.additionalCount && fullDayEvents.additionalCount > 0 && (
            <TimetableMoreFullDayEventIndicator day={dayValue} count={fullDayEvents.additionalCount} active={!isPast} />
          )}
        </div>
      )}
    </div>
  );
});

export default TimetableGridDayHeader;
