import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import useStore from '@/hooks/useStore';
import SchoolYearStore from '@/stores/schoolyear-store';
import DateTimeRangePicker, { IDateTimePickerProps } from '@/ui-components/date-time-range/date-time-range-picker';
import { TimeRangePickerMode } from '@/ui-components/time-range-picker/time-range-picker-store';

const DateTimeRangePickerSchoolYear = observer((props: IDateTimePickerProps) => {
  const schoolYearStore = useStore(SchoolYearStore);

  const { currentSchoolYear } = schoolYearStore;

  const isDateOutsideSelectedSchoolYear = (date: Dayjs): boolean => {
    return !schoolYearStore.isDateInCurrentSchoolYear(date);
  };

  useEffect(() => {
    if (!schoolYearStore.isDateInCurrentSchoolYear(props.startDateTime)) {
      const startHours = props.startDateTime.hour();
      const startMinutes = props.startDateTime.minute();
      const endHours = props.endDateTime.hour();
      const endMinutes = props.endDateTime.minute();

      props.onDateTimeRangeChange &&
        props.onDateTimeRangeChange(
          dayjs(currentSchoolYear?.dateRange?.start).set('hours', startHours).set('minutes', startMinutes),
          dayjs(currentSchoolYear?.dateRange?.start).set('hours', endHours).set('minutes', endMinutes),
          TimeRangePickerMode.Start,
        );
    }
  }, [props.startDateTime]);

  return (
    <DateTimeRangePicker
      onDateTimeRangeChange={props.onDateTimeRangeChange}
      startDateTime={props.startDateTime}
      endDateTime={props.endDateTime}
      timeSlots={props.timeSlots}
      onlySameDay={props.onlySameDay}
      disabled={props.disabled}
      disabledDate={(date) => isDateOutsideSelectedSchoolYear(date)}
    />
  );
});

export default DateTimeRangePickerSchoolYear;
