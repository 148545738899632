import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Flex } from '@/ui-components';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import { ITimetableRestrictionLayer } from '@te/standard/stores/restriction/timetable-restriction-layer-store';
import { DayDataStatusEnum } from '@untis/wu-rest-view-api';

import './timetable-restriction-layer.less';

const TimetableRestrictionLayer = observer((props: ITimetableRestrictionLayer) => {
  const { top, height, left, width, type, resource } = props;
  const { t } = useTranslation();

  let description: string = '';

  switch (type) {
    case DayDataStatusEnum.NO_DATA:
    case DayDataStatusEnum.REGULAR:
      return null;
    case DayDataStatusEnum.NOT_ALLOWED:
      description = t('timetable.restrictionLayer.dayNotAllowedDescription');
      break;
    case DayDataStatusEnum.NOT_ALLOWED_FOR_RESOURCE:
      description = t('timetable.restrictionLayer.dayNotAllowedForResourceDescription', {
        teacher: resource?.shortName,
      });
      break;
    default:
      break;
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className="timetable-restriction-layer"
      testId="timetable-restriction-layer"
      style={{
        top: top + 1,
        height: `${height === 0 ? 0 : height - 1}px`,
        left: left,
        width: width,
      }}
    >
      <EmptyIndicator
        title={t('timetable.restrictionLayer.dayNotAllowedTitle')}
        description={description}
        icon="lock-outline"
      />
    </Flex>
  );
});

export default TimetableRestrictionLayer;
