import { action, computed, observable } from 'mobx';
import { FormInstance } from 'antd';
import { Key } from 'react';
import { t } from 'i18next';
import dayjs from 'dayjs';

import { inject, Store } from '@/types/store';
import {
  IChangeClassForm,
  IClassHistoryRow,
  IStudentGroupRow,
} from '@/pages/master-data/student/class/change-class-form';
import { IFormButton } from '@/ui-components/wu-form';
import ModalStore from '@/stores/modal-store';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { StudentViewApi } from '@/stores/api-store';
import { ChangeClassDto, StudentClassHistoryDto, StudentGroupDto } from '@untis/wu-rest-view-api/api';
import { formatDateForServerRequest } from '@/utils/date/date-util';
import { getErrorMessageFromResponseError } from '@/utils/error-handling/error-handling';
import NotificationStore from '@/stores/notification-store/notification-store';

@Store()
export class ChangeClassStore {
  protected modalStore: ModalStore = inject(ModalStore);
  private notificationStore = inject(NotificationStore);

  @observable protected _formInstance: FormInstance<IChangeClassForm>;
  @observable private _selectedStudentGroupRowKeys: Key[] = [];
  @observable private _classHistoryRows: IClassHistoryRow[] = [];
  @observable private _studentGroupRows: IStudentGroupRow[] = [];
  @observable private _studentId: number;

  constructor(form: FormInstance<IChangeClassForm>, studentId: number) {
    this._formInstance = form;
    this._studentId = studentId;
    this.findClassHistoryRecords(studentId);
  }

  @computed
  get form(): FormInstance<IChangeClassForm> {
    return this._formInstance;
  }

  @action
  setForm(formInstance: FormInstance<IChangeClassForm>) {
    this._formInstance = formInstance;
  }

  @computed
  get getClassHistoryRows(): IClassHistoryRow[] {
    return this._classHistoryRows;
  }

  @action
  setClassHistoryRows(classHistoryRows: IClassHistoryRow[]) {
    this._classHistoryRows = classHistoryRows;
  }

  @computed
  get getStudentGroupRows(): IStudentGroupRow[] {
    return this._studentGroupRows;
  }

  @action
  setStudentGroupRows(studentGroupRows: IStudentGroupRow[]) {
    this._studentGroupRows = studentGroupRows;
  }

  @computed
  get buttonsOnRightSide(): IFormButton[] {
    return [
      {
        label: t('general.cancel'),
        onClick: () => this.modalStore.closeModal(),
      },
    ];
  }

  @computed
  get historyColumns(): Columns<IClassHistoryRow> {
    return [
      {
        type: ColumnType.Text,
        key: 'className',
        header: t('general.class'),
      },
      {
        type: ColumnType.Date,
        key: 'startDate',
        header: t('general.startDate'),
      },
      {
        type: ColumnType.Date,
        key: 'endDate',
        header: t('general.endDate'),
      },
    ];
  }

  @computed
  get studentGroupColumns(): Columns<IStudentGroupRow> {
    return [
      {
        type: ColumnType.Text,
        key: 'studentGroupName',
        header: t('general.name'),
      },
      {
        type: ColumnType.Text,
        key: 'classes',
        header: t('general.classes'),
      },
      {
        type: ColumnType.Text,
        key: 'subject',
        header: t('general.subject'),
      },
    ];
  }

  @computed
  get selectedStudentGroupRowKeys(): Key[] {
    return this._selectedStudentGroupRowKeys;
  }

  @action
  setSelectedStudentGroupRowKeys(keys: Key[]) {
    this._selectedStudentGroupRowKeys = keys;
  }

  @action
  async changeStudentClass(form: IChangeClassForm, onSuccess: () => void) {
    await StudentViewApi.changeStudentClass(
      this._studentId,
      ChangeClassStore.mapFormToDto(form, this._selectedStudentGroupRowKeys),
    )
      .then((response) => {
        if (response.status === 200) {
          this.notificationStore.success({ title: t('general.classChange.updatedSuccessfully') });
          onSuccess();
        }
      })
      .catch((error) => {
        const errorMessage = getErrorMessageFromResponseError(error);
        this.notificationStore.error({
          title: t('general.classChange.updatedUnsuccessfully'),
          message: errorMessage,
        });
      });
  }

  private static mapFormToDto(form: IChangeClassForm, selectedStudentGroupRowKeys: Key[]): ChangeClassDto {
    return {
      classId: Number(form.classId),
      startDate: formatDateForServerRequest(form.startDate),
      studentGroupsIds: selectedStudentGroupRowKeys.map((value) => Number(value)),
    };
  }

  @action
  async findAvailableStudentGroups(classId: string | undefined) {
    if (classId) {
      StudentViewApi.getStudentGroups(Number(classId)).then((response) => {
        if (response.data) {
          this.setStudentGroupRows(ChangeClassStore.mapStudentGroupRecordsToRows(response.data));
        }
      });
    } else {
      this.setStudentGroupRows([]);
    }
  }

  private static mapStudentGroupRecordsToRows(studentGroupDtos: StudentGroupDto[]): IStudentGroupRow[] {
    return studentGroupDtos.map((studentGroupDto) => {
      return {
        key: studentGroupDto.studentGroupId!,
        studentGroupName: studentGroupDto.studentGroupName ?? '',
        subject: studentGroupDto.subject ?? '',
        classes: studentGroupDto.classes ?? '',
      };
    });
  }

  @action
  async findClassHistoryRecords(studentId: number) {
    StudentViewApi.getStudentClassHistory(studentId).then((response) => {
      if (response.data) {
        this.setClassHistoryRows(ChangeClassStore.mapClassHistoryRecordsToRows(response.data));
      }
    });
  }

  private static mapClassHistoryRecordsToRows(studentClassHistoryDtos: StudentClassHistoryDto[]): IClassHistoryRow[] {
    return studentClassHistoryDtos.map((studentClassHistoryDto, index) => {
      return {
        className: studentClassHistoryDto.className ?? '',
        startDate: studentClassHistoryDto.startDate ? dayjs(studentClassHistoryDto.startDate) : undefined,
        endDate: studentClassHistoryDto.endDate ? dayjs(studentClassHistoryDto.endDate) : undefined,
        key: index,
      };
    });
  }
}
