import React, { ReactNode } from 'react';
import clsx from 'clsx';

import './untis-card.less';

export type UntisCardProps = {
  labelColor?: string;
  title: string;
  content?: ReactNode; // body of card component (everything goes into styled p element)
  customContent?: ReactNode; // custom way to make body of card component
  badge?: string; // small circle on top right corner with content in it
  isFullWidth?: boolean; // when true card element will be full width
  isActive?: boolean;
  onClick?: () => void;
  blockSize?: number;
  id?: string;
  testId?: string;
};

const UntisCard = ({
  labelColor = '#5a5652',
  content,
  customContent,
  badge,
  title,
  isActive,
  isFullWidth,
  onClick,
  blockSize,
  id,
  testId,
}: UntisCardProps) => {
  const heightForBlock = `${((blockSize || 0) + 1) * 85}px`;

  return (
    <div
      onClick={onClick}
      id={id}
      data-testid={testId}
      className={clsx(
        'wu__untis-card',
        isActive && 'wu__untis-card--active',
        isFullWidth && 'wu__untis-card--full-width',
      )}
    >
      <div className="wu__untis-card__body" style={{ minHeight: heightForBlock }}>
        <label
          className="wu__untis-card__label"
          style={{ backgroundColor: labelColor, height: heightForBlock }}
        ></label>
        <h2 className="wu__untis-card__title" title={title}>
          {title}
        </h2>
        {customContent}
        {content && <div className="wu__untis-card__content">{content}</div>}
        {badge && <span className="wu__untis-card__badge">{badge}</span>}
      </div>
    </div>
  );
};

export default UntisCard;
