import React from 'react';
import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import { message } from 'antd';
import fileDownload from 'js-file-download';
import { v4 } from 'uuid';
import { uniqBy } from 'lodash';

import { inject, Store } from '@/types/store';
import ModalStore from '@/stores/modal-store';
import { MessageViewApi } from '@/stores/api-store';
import {
  DraftMessageRefDto,
  DraftMessageRefDtoV2,
  DraftMessageViewDto,
  MessageFilterTypeV2,
  MessagePermissionDto,
  MessagePersonDto,
  MessageRecipientOption,
  MessageReplyFormDto,
  OneDriveFileDescriptorDto,
} from '@untis/wu-rest-view-api';
import SendMessageView from '@mg/components/send-message-view/send-message-view';
import { ErrorMessageEnum, showError, showErrorResponse } from '@/utils/error-handling/error-message';
import { Option } from '@/ui-components/option-popup/option-popup';
import RecipientPickerStore from '@mg/stores/recipient-picker-store';
import ConfigStore from '@/stores/config-store';
import { PageHeaderButton } from '@/ui-components/page/page';
import DraftMessagesStore from '@mg/stores/draft-messages-store';
import { IButtonProps } from '@/ui-components/button/button';
import OneDriveStore from '@/stores/one-drive-store';
import { humanFileSize } from '@/utils/format/humanFileSize';
import AnalyticsStore from '@/stores/analytics-store/analytics-store';
import { IAttachmentProps, IDeprecatedAttachment } from '@/ui-components/deprecated-attachment/deprecated-attachment';
import { TestIds } from '@/testIds';
import SentMessagesStore from '@mg/stores/sent-messages-store';

export function getOptionPopUpTranslation(option: MessageRecipientOption) {
  const { CUSTOM, PARENTS, STAFF, STUDENTS, TEACHER } = MessageRecipientOption;
  switch (option) {
    case CUSTOM:
      return t('general.custom');
    case PARENTS:
      return t('general.parents');
    case STAFF:
      return t('general.colleagues');
    case STUDENTS:
      return t('general.students');
    case TEACHER:
      return t('general.teacher');

    default:
      return '';
  }
}

const touchedDefaultValue = {
  subject: false,
  content: false,
  recipients: false,
  sendCopyToStudent: false,
  requestConfirmation: false,
  forbidReply: false,
  attachments: false,
};

export enum SendMessageViewEnum {
  EDITOR = 'editor',
  RECIPIENTS_LIST = 'Recipients_list',
}

type UploadedAttachment = {
  id: string;
  file: File;
};

export type OpenSendMessageViewArgs = {
  message?: DraftMessageRefDto;
  recipientOption?: MessageRecipientOption;
  initialView?: SendMessageViewEnum;
  initialRecipientsUserIds?: number[];
  clickedElement?: HTMLElement;
  initialFilterData?: {
    dynamic: boolean;
    id: string;
  };
};

@Store()
export default class SendMessageViewStore {
  @observable isReply: boolean = false;
  @observable view: SendMessageViewEnum = SendMessageViewEnum.EDITOR;

  @observable permissions: MessagePermissionDto | null = null;

  @observable draftMessage: DraftMessageViewDto | undefined;
  @observable replyMessage: MessageReplyFormDto | undefined;
  @observable loadingMessage = false;
  @observable sendingMessage = false;
  @observable savingMessage = false;

  @observable currentMessageId: number | null = null;
  @observable subject: string = '';
  @observable content: string = '';
  @observable touched = touchedDefaultValue;
  @observable uploadedAttachments: UploadedAttachment[] = [];
  @observable oneDriveAttachments: OneDriveFileDescriptorDto[] = [];
  @observable attachmentIdsToDelete: string[] = [];

  @observable requestConfirmation = false;
  @observable copyToStudent = false;
  @observable forbidReply = false;

  @observable replyRecipient: MessagePersonDto | undefined;

  private modalStore = inject(ModalStore);
  private recipientPickerStore = inject(RecipientPickerStore);
  private configStore = inject(ConfigStore);
  private sendMessageStore = inject(SentMessagesStore);
  private draftMessagesStore = inject(DraftMessagesStore);
  private oneDriveStore = inject(OneDriveStore);
  private analyticsStore = inject(AnalyticsStore);

  constructor() {
    this.loadPermissions();
  }

  async loadPermissions() {
    if (this.permissions) return null;

    const { data } = await MessageViewApi.getMessagePermissions();
    this.permissions = data;
  }

  showRecipientsView() {
    this.view = SendMessageViewEnum.RECIPIENTS_LIST;
  }

  showEditorView() {
    this.view = SendMessageViewEnum.EDITOR;
  }

  async openSendMessageView(args: OpenSendMessageViewArgs) {
    if (args.recipientOption) {
      this.recipientPickerStore.recipientOption = args.recipientOption;
    }

    if (args.initialView) {
      this.view = args.initialView;
    }

    if (args.initialFilterData && args.recipientOption === MessageRecipientOption.CUSTOM) {
      this.recipientPickerStore.setInitialFilter(
        args.initialFilterData.dynamic ? MessageFilterTypeV2.DYNAMIC : MessageFilterTypeV2.QUICK,
        [args.initialFilterData.id],
      );
    }

    this.modalStore.openModalDialog({
      className: 'send-message-modal',
      children: <SendMessageView />,
      size: 'lg',
      noPadding: true,
      closeCondition: () => this.closeCondition(),
      lastFocusedElement: args.clickedElement,
    });

    if (args.initialRecipientsUserIds) {
      try {
        this.loadingMessage = true;
        await this.recipientPickerStore.setInitialRecipients(args.initialRecipientsUserIds);
      } catch (error) {
        this.loadingMessage = false;
      }
    }

    // load draft message
    if (args.message) {
      this.loadingMessage = true;

      try {
        this.recipientPickerStore.recipientOption = args.message.recipientOption;

        await this.recipientPickerStore.getRecipients();
        await this.loadDraftMessage(args.message.id);
      } catch (error) {
        console.error(error);
      }
    }

    this.loadingMessage = false;
  }

  async openReplySendMessageView(messageId: number, clickedElement?: HTMLElement) {
    this.loadingMessage = true;
    // indicate that this is a reply
    this.isReply = true;

    this.modalStore.openModalDialog({
      className: 'send-message-modal',
      children: <SendMessageView />,
      size: 'lg',
      noPadding: true,
      closeCondition: () => this.closeCondition(),
      lastFocusedElement: clickedElement,
    });

    try {
      const { data: message } = await MessageViewApi.generateReplyForm(messageId, true);

      this.currentMessageId = messageId;
      this.replyMessage = message;
      this.subject = message.subject;
      this.replyRecipient = message.recipient;
    } catch (error) {
      console.error(error);
    }

    this.loadingMessage = false;
  }

  @action
  async loadDraftMessage(messageId: number) {
    const { data } = await MessageViewApi.getDraftMessage(messageId);

    this.recipientPickerStore.recipientOption = data.recipientOption;
    this.draftMessage = data;
    this.subject = data.subject;
    this.content = data.content ? data.content : '';
    this.copyToStudent = data.copyToStudents;
    this.requestConfirmation = data.requestConfirmation;
    this.forbidReply = data.forbidReply;
    this.oneDriveAttachments = data.attachments.map((attachment) => ({
      downloadUrl: attachment.downloadUrl,
      driveId: attachment.id,
      id: parseInt(attachment.id, 10),
      fileName: attachment.name,
    }));
    this.uploadedAttachments = [];
  }

  @action
  handleFileUploadClick(fileInputRef: React.RefObject<HTMLInputElement>) {
    if (this.configStore.hasOneDrive) {
      this.touched.attachments = true;
      this.oneDriveStore.deprecatedOpenOneDriveDialog({
        onSuccess: (selectedFiles) => {
          const oneDriveAttachments: OneDriveFileDescriptorDto[] = selectedFiles
            .map((file) => ({
              id: file.id,
              driveId: file.driveId ?? '',
              fileName: file.name,
              downloadUrl: file.downloadUrl,
            }))
            .filter((file) => file.driveId !== '');

          // merge existing attachments and the new attachments and filter the ones that are duplicates
          this.oneDriveAttachments = uniqBy(
            [...this.oneDriveAttachments, ...oneDriveAttachments],
            (attachment) => attachment.driveId,
          );
        },
      });
      return;
    }
    fileInputRef.current?.click();
  }

  /* API actions */

  @action
  async sendMessage() {
    if (this.isReply) {
      this.replyToMessage();
      return;
    }

    if (
      this.recipientPickerStore.selectedGroupIds.length <= 0 &&
      this.recipientPickerStore.selectedPersonsIds.length <= 0
    ) {
      alert(t('general.sendMessageRecipientsRequired'));
      return;
    }

    const { CUSTOM, STAFF, TEACHER } = MessageRecipientOption;

    switch (this.recipientPickerStore.recipientOption) {
      case CUSTOM:
      case STAFF:
      case TEACHER:
        this.handleSendCustomMessage();
        break;
      default:
        this.handleSendMessage();
    }
  }

  async replyToMessage() {
    if (!this.replyMessage || this.currentMessageId === null) {
      return;
    }

    try {
      this.sendingMessage = true;
      await MessageViewApi.replyToMessageV2(
        this.currentMessageId,
        {
          subject: this.subject,
          content: this.content,
          oneDriveAttachments: this.oneDriveAttachments,
        },
        this.uploadedAttachmentsFiles,
      );
      this.sendMessageStore.getSentMessages();

      message.success(
        t('general.sendMessageStudentsSendSuccess', {
          name: this.replyMessage.recipient.displayName,
        }),
      );
    } catch (error) {
      showErrorResponse(error as Error);
    } finally {
      this.sendingMessage = false;
    }

    this.close(true);
  }

  async handleSendMessage() {
    try {
      this.sendingMessage = true;

      await this.saveDraftMessageBeforeSending();

      const { data } = await MessageViewApi.sendMessageV2(
        {
          subject: this.subject,
          content: this.content,
          recipientOption: this.recipientPickerStore.recipientOption,
          recipientPersonIds: this.recipientPickerStore.selectedPersonsIds,
          recipientGroupIds: this.recipientPickerStore.selectedGroupIds,
          copyToStudent: this.copyToStudent,
          requestConfirmation: this.requestConfirmation,
          draftMessageId: this.draftMessage?.id,
          oneDriveAttachments: this.oneDriveAttachments,
          forbidReply: this.forbidReply,
        },
        this.uploadedAttachmentsFiles,
      );

      this.trackEvents();

      // remove draft message
      if (this.draftMessage?.id) {
        this.draftMessagesStore.deleteMessage(this.draftMessage.id);
      }
      this.sendMessageStore.getSentMessages();

      this.recipientPickerStore.successMessage(data);
      this.recipientPickerStore.readReceiptNotice(this.requestConfirmation);

      this.close(true);
    } catch (error) {
      showErrorResponse(error as Error);
    } finally {
      this.sendingMessage = false;
    }
  }

  async handleSendCustomMessage() {
    const { title, subtitle } = this.recipientPickerStore.customConfirmationModalTranslations;

    const answer = await this.modalStore.booleanUserPrompt({
      title,
      children: subtitle,
      okButton: {
        label: t('general.send'),
      },
      cancelButton: {
        label: t('general.cancel'),
      },
    });

    if (!answer) return;

    try {
      this.sendingMessage = true;

      await this.saveDraftMessageBeforeSending();

      const { data } = await MessageViewApi.sendMessageToUsersV2(
        {
          subject: this.subject,
          content: this.content,
          requestConfirmation: this.requestConfirmation,
          recipientUserIds: this.recipientPickerStore.selectedPersonsIds,
          draftMessageId: this.draftMessage?.id,
          oneDriveAttachments: this.oneDriveAttachments,
          forbidReply: this.forbidReply,
        },
        this.uploadedAttachmentsFiles,
      );

      this.trackEvents();

      // remove draft message
      if (this.draftMessage?.id) {
        this.draftMessagesStore.deleteMessage(this.draftMessage.id);
      }
      this.sendMessageStore.getSentMessages();

      this.recipientPickerStore.successMessage(data);
      this.recipientPickerStore.readReceiptNotice(this.requestConfirmation);

      this.close(true);
    } catch (error) {
      showErrorResponse(error as Error);
    } finally {
      this.sendingMessage = false;
    }
  }

  close(shouldCloseAll?: boolean) {
    if (shouldCloseAll) {
      this.modalStore.closeAll();
    } else {
      this.modalStore.closeModal();
    }
  }

  async closeCondition(): Promise<boolean> {
    if (this.isReply) {
      return this.openShouldDiscardDialog();
    }

    return await this.openSaveDraftDialog({
      okText: t('general.save'),
      cancelText: t('general.discard'),
      onCancel: () => this.close(true),
    });
  }

  async openShouldDiscardDialog() {
    if (!this.isTouched) {
      return true;
    }

    return await this.modalStore.booleanUserPrompt({
      hasDestructiveAction: true,
      title: t('general.discardChanges'),
      okButton: {
        label: t('general.discard'),
      },
      cancelButton: {
        label: t('general.cancel'),
      },
    });
  }

  async openSaveDraftDialog({
    title,
    okText,
    cancelText,
    onCancel,
    hasDestructiveAction,
  }: {
    title?: string;
    okText?: string;
    cancelText?: string;
    onCancel?: () => void;
    hasDestructiveAction?: boolean;
  } = {}) {
    if (!this.isTouched) {
      return true;
    }

    const shouldSave = await this.modalStore.booleanUserPrompt({
      hasDestructiveAction: hasDestructiveAction,
      title: title ?? t('general.sendMessageDiscardConfirmation'),
      okButton: {
        label: okText ?? t('general.save'),
      },
      cancelButton: {
        label: cancelText ?? t('general.cancel'),
      },
    });

    if (shouldSave) {
      try {
        this.savingMessage = true;
        const savedDraft = await this.saveDraftMessage();

        // prevents creating multiple drafts when draft was saved and saved again
        if (!savedDraft) {
          this.savingMessage = false;
          this.resetTouched();
          return shouldSave;
        }

        await this.loadDraftMessage(savedDraft.id);
      } catch (error) {
        showErrorResponse(error as Error);
      } finally {
        this.savingMessage = false;
        this.resetTouched();
      }
    } else {
      onCancel?.();
    }

    return shouldSave;
  }

  @action
  reset() {
    this.view = SendMessageViewEnum.EDITOR;

    this.subject = '';
    this.content = '';
    this.isReply = false;
    this.replyMessage = undefined;
    this.draftMessage = undefined;
    this.currentMessageId = null;
    this.loadingMessage = false;
    this.copyToStudent = false;
    this.requestConfirmation = false;
    this.forbidReply = false;
    this.uploadedAttachments = [];
    this.oneDriveAttachments = [];
    this.attachmentIdsToDelete = [];

    this.resetTouched();
    this.recipientPickerStore.reset();
  }

  @action
  resetTouched() {
    this.touched = touchedDefaultValue;
  }

  @action
  setTouchedAll() {
    this.touched = {
      subject: true,
      content: true,
      recipients: true,
      sendCopyToStudent: true,
      requestConfirmation: true,
      forbidReply: true,
      attachments: true,
    };
  }

  @action
  async saveDraftMessageBeforeSending() {
    if (!this.draftMessage) {
      return false;
    }

    return MessageViewApi.saveDraftMessageChangesV2(
      this.draftMessage.id,
      {
        copyToStudent: this.copyToStudent,
        content: this.content,
        recipientOption: this.recipientPickerStore.recipientOption,
        subject: this.subject,
        requestConfirmation: this.requestConfirmation,
        oneDriveAttachments: this.oneDriveAttachments,
        forbidReply: this.forbidReply,
        attachmentIdsToDelete: this.attachmentIdsToDelete,
      },
      this.uploadedAttachmentsFiles,
    );
  }

  @action
  async saveDraftMessage(): Promise<DraftMessageRefDtoV2 | undefined> {
    return this.draftMessagesStore.saveDraftMessage(
      {
        draftMessageSaveData: {
          copyToStudent: this.copyToStudent,
          content: this.content,
          recipientOption: this.recipientPickerStore.recipientOption,
          subject: this.subject,
          requestConfirmation: this.requestConfirmation,
          oneDriveAttachments: this.oneDriveAttachments,
          forbidReply: this.forbidReply,
        },
        draftMessageSaveChangesData: {
          copyToStudent: this.copyToStudent,
          content: this.content,
          recipientOption: this.recipientPickerStore.recipientOption,
          subject: this.subject,
          requestConfirmation: this.requestConfirmation,
          oneDriveAttachments: this.oneDriveAttachments,
          forbidReply: this.forbidReply,
          attachmentIdsToDelete: this.attachmentIdsToDelete,
        },
      },
      this.draftMessage?.id,
      this.uploadedAttachmentsFiles,
    );
  }

  @action
  async openDeleteDraftConfirmationModal(messageId?: number) {
    if (!messageId) {
      // no ID specified
      return;
    }

    const shouldDelete = await this.modalStore.openDeletePrompt(
      t('general.messageDraftDeleteConfirmation'),
      t('general.messageDraftDeleteConfirmationSubtitle'),
    );

    if (!shouldDelete) {
      return;
    }

    try {
      await MessageViewApi.deleteMessage(messageId);
      this.draftMessagesStore.deleteMessage(messageId);
      message.success(t('general.messageDeleteSuccess'));
      this.modalStore.closeAll();
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
    }
  }

  @action.bound
  handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (this.maxFileCountReached) {
      message.error(t('general.maxFileCountReached', { count: this.maxFileCount }));
      return;
    }

    const files = [...(event.target.files ?? [])].filter((file) => {
      if ((file?.size ?? 0) > this.maxFileSize) {
        message.error(
          t('general.fileWithNameTooLarge', {
            name: file.name,
            maxFileSize: humanFileSize(this.permissions?.maxFileSize ?? 0),
          }),
        );
        return false;
      }

      return true;
    });

    const uploadedFilesToUploadedAttachments = files.map((file) => ({
      id: v4(),
      file,
    }));
    const updatedUploadedAttachments = [...this.uploadedAttachments, ...uploadedFilesToUploadedAttachments];

    if (updatedUploadedAttachments.length >= this.maxFileCount) {
      message.info(t('general.maxFileCountReached', { count: this.maxFileCount }));
    }

    // needed in Chrome otherwise you can't upload the same file
    // eq. you deleted it and want to re-upload it in the same session
    event.target.value = '';

    this.touched.attachments = true;
    this.uploadedAttachments = updatedUploadedAttachments.slice(0, this.maxFileCount);
  }

  @action
  removeAttachment(attachmentId: string) {
    this.touched.attachments = true;

    // currently the downloadUrl is used as the id, because the id is not unique
    this.oneDriveAttachments = this.oneDriveAttachments.filter((attachment) => attachment.downloadUrl !== attachmentId);

    // blobAttachment can only be ever one
    if (this.draftMessage?.blobAttachment) {
      this.attachmentIdsToDelete.push(this.draftMessage.blobAttachment.name);
      this.draftMessage.blobAttachment = undefined;
    }

    // handle storage attachments
    if (this.draftMessage && this.draftMessage.storageAttachments.length > 0) {
      this.attachmentIdsToDelete.push(attachmentId);
      this.draftMessage.storageAttachments = this.draftMessage.storageAttachments.filter(
        (attachment) => attachment.id !== attachmentId,
      );
    }

    // when a user uploads a file, saves the draft and than deletes the file,
    // we need to track the removed file from the uploadedAttachments and add it
    // to the attachmentIdsToDelete
    this.attachmentIdsToDelete = [
      ...this.attachmentIdsToDelete,
      ...this.uploadedAttachments
        .filter((attachment) => attachment.id === attachmentId)
        .map((attachment) => attachment.id),
    ];

    // update the UI with only the uploaded attachments
    this.uploadedAttachments = this.uploadedAttachments.filter((attachment) => attachment.id !== attachmentId);
  }

  async downloadBlobAttachment(messageId: number, fileName: string) {
    // we need to pass 'blob' as responseType so that Axios downloads the file correctly
    const result = await MessageViewApi.downloadBlobAttachment(messageId, { responseType: 'blob' });
    /* we need to cast the data of the result to 'any' because there is no correct mapping
     * for blob files in swagger 2 */
    fileDownload(result.data as any, fileName);
  }

  @computed
  get canSendMessages(): boolean {
    return this.permissions !== null && this.permissions.recipientOptions.length > 0;
  }

  @computed
  get uploadedAttachmentsFiles(): File[] {
    return this.uploadedAttachments.map((ua) => ua.file);
  }

  @computed
  get blobAttachment(): IAttachmentProps | undefined {
    // either one drive attachments or blob attachments can exists
    // we hide the blob if for example the user uploads one drive
    // attachments, because on save they will get overwritten
    if (this.oneDriveAttachments.length > 0) {
      return undefined;
    }

    if (this.uploadedAttachments.length > 0) {
      return undefined;
    }

    if (!this.draftMessage?.blobAttachment) {
      return undefined;
    }

    const messageId = this.draftMessage.id;
    const blobAttachment = this.draftMessage.blobAttachment;

    return {
      name: blobAttachment.name,
      hoverIcon: {
        type: 'cancel-circle-small',
        onClick: (event) => {
          event.stopPropagation();
          this.removeAttachment(blobAttachment.name);
        },
      },
      onClick: () => this.downloadBlobAttachment(messageId, blobAttachment.name),
    };
  }

  @computed
  get attachments(): IDeprecatedAttachment[] {
    let attachments: IDeprecatedAttachment[] = [];

    if (this.uploadedAttachments?.length) {
      const uploadedAttachments = this.uploadedAttachments.map((ua) => ({
        id: ua.id,
        name: ua.file.name,
        src: URL.createObjectURL(ua.file),
      }));

      attachments = [...attachments, ...uploadedAttachments];
    }

    if (this.oneDriveAttachments.length) {
      const oneDriveAttachments = this.oneDriveAttachments.map((oneDriveAttachment) => ({
        // currently the downloadUrl is used as the id, because the id is not unique
        id: oneDriveAttachment.downloadUrl, // String(oneDriveAttachment.id) ?? oneDriveAttachment.driveId,
        name: oneDriveAttachment.fileName,
        src: oneDriveAttachment.downloadUrl,
      }));
      attachments = [...attachments, ...oneDriveAttachments];
    }

    if (this.draftMessage?.storageAttachments?.length) {
      const storageAttachments = this.draftMessage.storageAttachments.map((storageAttachment) => ({
        id: storageAttachment.id,
        name: storageAttachment.name,
        src: undefined,
      }));
      attachments = [...attachments, ...storageAttachments];
    }

    return attachments;
  }

  @computed
  get optionPopUpOptions(): Option[] {
    if (!this.permissions) return [];
    return this.permissions.recipientOptions.map((option: MessageRecipientOption) => ({
      label: getOptionPopUpTranslation(option),
      onClick: () => {
        this.recipientPickerStore.recipientOption = option;
        this.openSendMessageView({});
      },
    }));
  }

  @computed
  get isMessageToParents() {
    return this.recipientPickerStore.recipientOption === MessageRecipientOption.PARENTS;
  }

  @computed
  get formErrors() {
    const errors = { subject: '', content: '', recipients: '' };

    if (
      !this.isReply &&
      this.touched.recipients &&
      this.recipientPickerStore.selectedGroups.length <= 0 &&
      this.recipientPickerStore.selectedPersons.length <= 0
    ) {
      errors.recipients = t('general.sendMessageRecipientsRequired');
    }

    if (this.touched.subject && this.subject.trim().length < 1) {
      errors.subject = t('general.sendMessageSubjectMinLength', {
        charCount: 1,
      });
    }

    return errors;
  }

  @computed
  get isTouched() {
    if (this.isReply) {
      return this.touched.content;
    }

    return (
      this.touched.content ||
      this.touched.subject ||
      this.touched.requestConfirmation ||
      this.touched.sendCopyToStudent ||
      this.touched.forbidReply ||
      this.touched.attachments
    );
  }

  @computed
  get isFormValid() {
    return Object.entries(this.formErrors).reduce((valid, [, value]) => {
      return valid && value === '';
    }, true);
  }

  @computed
  get headerButtons(): PageHeaderButton[] {
    // the user can preselect the recipient type before the send message dialog opens
    const optionPopupButton = {
      icon: 'lesson-plus',
      label: t('general.new'),
      dataTestId: TestIds.MESSAGES_NEW_BUTTON,
      options: this.optionPopUpOptions,
    };

    if (this.optionPopUpOptions.length > 1) {
      return [optionPopupButton];
    }

    return [
      {
        icon: 'lesson-plus',
        dataTestId: TestIds.MESSAGES_NEW_BUTTON,
        type: 'primary',
        label: t('general.new'),
        onClick: this.optionPopUpOptions?.[0]?.onClick,
      },
    ];
  }

  @computed
  get recipientPickerMainActions(): IButtonProps[] {
    const handleButtonClick = (action: 'cancel' | 'apply') => {
      return () => {
        if (this.recipientPickerStore.showCustomList || this.recipientPickerStore.showStaffList) {
          this.recipientPickerStore.customViewStore.showSelectedViewIfNeeded();
        }

        if (action === 'apply') {
          this.recipientPickerStore.applySelection();
        }

        if (action === 'cancel') {
          this.recipientPickerStore.resetSelection();
        }

        this.showEditorView();
      };
    };

    return [
      {
        children: t('general.cancel'),
        size: 'large',
        type: 'secondary',
        outline: true,
        onClick: handleButtonClick('cancel'),
        testId: TestIds.MESSAGES_RECIPIENT_PICKER_CANCEL_BUTTON,
      },
      {
        children: t('general.apply'),
        size: 'large',
        type: 'primary',
        onClick: handleButtonClick('apply'),
        testId: TestIds.MESSAGES_RECIPIENT_PICKER_APPLY_BUTTON,
      },
    ];
  }

  @computed
  get maxFileCountReached() {
    return this.uploadedAttachments.length >= this.maxFileCount;
  }

  @computed
  get maxFileSize() {
    return this.permissions?.maxFileSize ?? 7_000_000;
  }

  @computed
  get maxFileCount() {
    return this.permissions?.maxFileCount ?? 0;
  }

  @computed
  get canUploadMultipleAttachments() {
    return this.maxFileCount > 1;
  }

  private trackEvents() {
    this.analyticsStore.trackEvent('Messaging', 'SentMessage');
    this.analyticsStore.trackEvent(
      'Messaging',
      'SentMessageRecipientOption',
      this.recipientPickerStore.recipientOption,
    );
    this.analyticsStore.trackEvent('Messaging', 'SentMessageNoReply', this.forbidReply ? 'true' : 'false');
    this.analyticsStore.trackEvent('Messaging', 'SentMessageCcOption', this.copyToStudent ? 'true' : 'false');
    this.analyticsStore.trackEvent('Messaging', 'SentMessageReadReceipt', this.requestConfirmation ? 'true' : 'false');
  }
}
