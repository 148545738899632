import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { t } from 'i18next';

import { Tooltip } from '@/ui-components';
import { CalendarKlasseDto, CalendarRoomDto, CalendarSubjectDto, CalendarTeacherDto } from '@untis/wu-rest-view-api';
import { getSubMenuItem, SubMenuItemEnum } from '@/components/menu/menu-items';
import RouterStore from '@/stores/router-store';

type Element = CalendarTeacherDto | CalendarKlasseDto | CalendarRoomDto | CalendarSubjectDto;
type ElementType = 'teacher' | 'class' | 'room' | 'subject';

/**
 * Just some extracted functions for period-content and calendar-entry-content (both used
 * by the calendar-entry-details-header)
 */

const teacherBaseRoute = getSubMenuItem(SubMenuItemEnum.TIMETABLE_TEACHERS)!.route;
const roomBaseRoute = getSubMenuItem(SubMenuItemEnum.TIMETABLE_ROOMS)!.route;
const classBaseRoute = getSubMenuItem(SubMenuItemEnum.TIMETABLE_CLASSES)!.route;
const subjectBaseRoute = getSubMenuItem(SubMenuItemEnum.TIMETABLE_SUBJECTS)!.route;
const defaultElementClassName = 'element';

const getBaseRouteForType = (type: ElementType) => {
  switch (type) {
    case 'teacher':
      return teacherBaseRoute;
    case 'class':
      return classBaseRoute;
    case 'room':
      return roomBaseRoute;
    case 'subject':
      return subjectBaseRoute;
  }
};

/**
 * Renders an element (teacher, class, ...) in the header
 * @param type: teacher, class,...? Is needed to create an potential timetable link
 * @param element: the element to be rendered
 * @param name: Regardless of the element, which "string" should be rendered?
 * @param active: If false, the element is striked through
 * @param clickable: Should it link to the associated timetable page?
 * @param routerStore: Needed for the click handler
 */
const renderElement = (
  type: ElementType,
  element: Element,
  name: string,
  active: boolean,
  clickable: boolean,
  routerStore: RouterStore,
  date: string | undefined,
) => {
  const className = clsx(defaultElementClassName, {
    inactive: !active,
    clickable: clickable,
  });

  const route = getBaseRouteForType(type) + '/' + element.id + (date ? '/' + date : '');

  const onClick = clickable
    ? () => {
        routerStore.routing.push(route);
      }
    : undefined;

  const key = `${type}-${element.id}`;

  const renderedElement = (
    <span key={key} className={className} onClick={onClick}>
      {name}
    </span>
  );

  return clickable ? (
    <Tooltip key={key} title={t('general.showInTimetable')} placement="bottomLeft">
      {renderedElement}
    </Tooltip>
  ) : (
    renderedElement
  );
};

/**
 * Checks it an element is clickable
 * @param element: Element to be checked
 * @param type: What type is the element?
 * @param idParam: The id of the element whose timetable is currently displayed beneath the modal dialog
 * @param routerStore
 */
const isClickable = (
  element: Element,
  type: ElementType,
  idParam: string | undefined,
  routerStore: RouterStore,
): boolean => {
  if (!element.hasTimetable) {
    return false;
  }

  const currentPath = routerStore.history.location.pathname;

  // e.g.: if under the modal Aigners timetable is opened, Aigner should not be clickable
  // this only applies to weekly timetable views, so therefore we have to check the routes
  const checkElementId =
    (type === 'class' && currentPath.includes(classBaseRoute)) ||
    (type === 'subject' && currentPath.includes(subjectBaseRoute)) ||
    (type === 'teacher' && currentPath.includes(teacherBaseRoute)) ||
    (type === 'room' && currentPath.includes(roomBaseRoute));

  return checkElementId ? element.id.toString() !== idParam : true;
};

export const renderCalendarEntryHeaderString = (value: string) => {
  return <span className={defaultElementClassName}>{value}</span>;
};

/**
 * Renders an elements (teacher, classes, ...) in the header. Depending on their properties
 * they are clickable and link to their associated timetable page.
 * @param type: teacher, class,...? Is needed to create an potential timetable link
 * @param inactiveElements: elements the should be rendered strike-through
 * @param activeElements: elements that should be rendered normally
 * @param idParam: The id of the element whose timetable is currently displayed beneath the modal dialog
 * @param routerStore: Needed if the element should be a link
 */
export const renderCalendarEntryHeaderElements = (
  type: ElementType,
  inactiveElements: Element[],
  activeElements: Element[],
  idParam: string | undefined,
  dateParam: string | undefined,
  routerStore: RouterStore,
) => {
  const renderedElements: ReactElement[] = [];
  const renderedActiveElements: ReactElement[] = [];
  const renderedInactiveElements: ReactElement[] = [];

  inactiveElements.forEach((teacher) => {
    renderedInactiveElements.push(
      renderElement(
        type,
        teacher,
        teacher.displayName,
        false,
        isClickable(teacher, type, idParam, routerStore),
        routerStore,
        dateParam,
      ),
    );
  });

  activeElements.forEach((teacher) => {
    renderedActiveElements.push(
      renderElement(
        type,
        teacher,
        teacher.displayName,
        true,
        isClickable(teacher, type, idParam, routerStore),
        routerStore,
        dateParam,
      ),
    );
  });

  if (inactiveElements.length > 0) {
    renderedElements.push(
      <div key="inactive-container" className="inactive-container">
        {renderedInactiveElements}
      </div>,
    );
  }
  if (inactiveElements.length > 0 && activeElements.length > 0) {
    renderedElements.push(
      <span key="arrow" className="arrow">
        &#8594;
      </span>,
    );
  }
  if (activeElements.length > 0) {
    renderedElements.push(
      <div key="active-container" className="active-container">
        {renderedActiveElements}
      </div>,
    );
  }

  return <div className="pipe-separator">{renderedElements}</div>;
};
