import React from 'react';

import { IFormItemProps, WUFormItem } from '@/ui-components/wu-form/wu-form-item';
import { IconPicker, IconPickerProps } from '@/ui-components/icon-picker/icon-picker';

export interface IFormIconPickerProps extends IFormItemProps<string> {
  iconPickerProps: IconPickerProps;
}

export function FormIconPicker(props: IFormIconPickerProps) {
  return (
    <WUFormItem
      name={props.name}
      valuePropName="defaultIcon"
      label={props.label}
      rules={props.rules}
      dependencies={props.dependencies}
      initialValue={props.initialValue}
    >
      <IconPicker {...props.iconPickerProps} />
    </WUFormItem>
  );
}
