import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import { isEqual } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';

import {
  ClassRegAttachmentDto,
  ClassRegDateTimeRangeDto,
  ClassRegLessonTopicDto,
  ClassRegLessonTopicModifyReqDto,
  ClassRegPeriodDto,
  ClassRegPeriodLessonTopicDto,
  ClassRegTeachingMethodDto,
  FileDescriptorDtoV2StorageProviderEnum,
} from '@untis/wu-rest-view-api/api';
import { ClassRegLessonTopicViewApi } from '@/stores/api-store';
import { inject } from '@/types/store';
import { dayJsFromDateTimeString } from '@/utils/date/date-util';
import NotificationStore from '@/stores/notification-store/notification-store';
import ModalStore from '@/stores/modal-store';
import { SidebarStore } from '@/stores/sidebar-store';
import { IDropDownItem } from '@/ui-components/drop-down/drop-down';
import { FileDescriptorDtoV2 } from '@untis/wu-rest-view-api';

interface ITeachingContentItem {
  id?: number;
  periodId?: number;
  methodId: number | undefined;
  text: string;
  attachments: FileDescriptorDtoV2[];
}

interface IPeriodItem {
  periodId: number;
  start: Dayjs;
  end: Dayjs;
  subject: string;
  classes: string;
  teachers: string;
  rooms: string;
}

export interface IPeriodTeachingContentItem extends ITeachingContentItem, IPeriodItem {
  periodId: number;
  backColor: string;
  canSave: boolean;
  blockRange?: ClassRegDateTimeRangeDto;
}

export interface ITeachingContentPeriod {
  key: number;
  id: number;
  date: string;
  day: string;
  teachingContent: string;
  attachments: FileDescriptorDtoV2[];
}

export const PREV_PERIODS_PER_SCREEN = 5;

export class TeachingContentSidebarStore {
  private readonly _periodId: number;
  private _selectedPeriodCache: IPeriodTeachingContentItem | undefined;

  @observable private _notificationStore = inject(NotificationStore);
  @observable private _modalStore = inject(ModalStore);
  @observable private _sidebarStore = inject(SidebarStore);

  @observable private _isNoMorePrevPeriodsToFetch: boolean = false;
  @observable private _isNoMoreFuturePeriodsToFetch: boolean = false;
  @observable private _isLoading: boolean = true;
  @observable private _isPrevPeriodsLoading: boolean = true;
  @observable private _isFutureTopicAllowed: boolean = false;
  @observable private _isOneDriveAllowed: boolean = false;
  @observable private _isSaving: boolean = false;
  @observable private _teachingMethods: ClassRegTeachingMethodDto[] = [];
  @observable private _periods: IPeriodTeachingContentItem[] = [];
  @observable private _untouchedPeriods: IPeriodTeachingContentItem[] = [];
  @observable private _selectedPeriodIndex: number = 0;
  @observable private _prevPeriodsPointerIndex: number = 0;
  @observable private _saveForBlock: boolean = false;

  constructor(periodId: number) {
    this._periodId = periodId;
    ClassRegLessonTopicViewApi.getLessonTopicsMetaData()
      .then((resp) => {
        this._teachingMethods = resp.data.teachingMethods;
        this._isFutureTopicAllowed = resp.data.futureTopicAllowed;
        this._isOneDriveAllowed = resp.data.oneDriveAllowed;
      })
      // handle exception
      .then(() => {
        this.fetchPeriods(-PREV_PERIODS_PER_SCREEN);
      });
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @computed
  get isPrevPeriodsLoading(): boolean {
    return this._isPrevPeriodsLoading;
  }

  @computed
  get isSaving(): boolean {
    return this._isSaving;
  }

  @computed
  get isSaveAllowed(): boolean {
    return !!this.selectedPeriod && this.selectedPeriod.canSave;
  }

  @computed
  get isBeginningReached(): boolean {
    return (
      this._isNoMorePrevPeriodsToFetch &&
      this._prevPeriodsPointerIndex + PREV_PERIODS_PER_SCREEN >= this.prevPeriods.length
    );
  }

  @computed
  get isNextAllowed(): boolean {
    return (
      this._selectedPeriodIndex > 0 ||
      (!this._isNoMoreFuturePeriodsToFetch &&
        (this._isFutureTopicAllowed || (!!this.selectedPeriod && this.selectedPeriod.start.isBefore(dayjs()))))
    );
  }

  @computed
  get isBackAllowed(): boolean {
    return !this._isNoMorePrevPeriodsToFetch || this._selectedPeriodIndex < this._periods.length - 1;
  }

  @computed
  get isOneDriveAllowed(): boolean {
    return this._isOneDriveAllowed;
  }

  @computed
  get teachingMethodDropDownItems(): IDropDownItem[] {
    return this._teachingMethods.map((tm) => ({ id: '' + tm.id, label: tm.name }));
  }

  @computed
  get selectedPeriod(): IPeriodTeachingContentItem | undefined {
    return this._selectedPeriodIndex <= this._periods.length - 1 ? this._periods[this._selectedPeriodIndex] : undefined;
  }

  @computed
  get prevPeriods(): IPeriodTeachingContentItem[] {
    return this._periods.length < 2 ? [] : this._periods.slice(this._selectedPeriodIndex + 1);
  }

  @computed
  get prevPeriodsPointerIndex(): number {
    return this._prevPeriodsPointerIndex;
  }

  @computed
  get isEdited(): boolean {
    let hasEdited = true;
    if (this.selectedPeriod) {
      const initialSelectedPeriod = this._untouchedPeriods[this._selectedPeriodIndex];
      hasEdited = !(
        (
          initialSelectedPeriod?.text === this.selectedPeriod.text &&
          initialSelectedPeriod?.methodId === this.selectedPeriod.methodId &&
          isEqual(initialSelectedPeriod?.attachments.slice(), this.selectedPeriod.attachments.slice())
        )
        /* isEqual used for array comparison  as '===' comparison does not work as its reference type,
        '===' works for primitive types */
      );
    }
    return hasEdited;
  }

  @action
  selectTeachingMethod(methodId: number | undefined) {
    if (this.selectedPeriod) {
      this.selectedPeriod.methodId = methodId;
    }
  }

  @action.bound
  setTopicText(text: string) {
    if (this._periods[this._selectedPeriodIndex]) {
      this._periods[this._selectedPeriodIndex].text = text;
    }
  }

  @action.bound
  mergeTopics(period: ITeachingContentPeriod) {
    if (this.selectedPeriod) {
      this.selectedPeriod.text =
        this.selectedPeriod.text + (this.selectedPeriod.text ? '\n' : '') + period.teachingContent;

      period.attachments.forEach((attachment) => {
        if (!this.selectedPeriod?.attachments.map((a) => a.storageId).includes(attachment.storageId)) {
          this.selectedPeriod?.attachments.push(attachment);
        }
      });
    }
  }

  @action.bound
  clearTopicText() {
    if (this.selectedPeriod) {
      this.selectedPeriod.text = '';
    }
  }

  @action.bound
  async selectPrevPeriod(periodId: number) {
    if (await this.checkForUnsaved()) {
      const newIndex = this._periods.findIndex((p) => p.periodId === periodId);
      if (newIndex > -1) {
        this._selectedPeriodIndex = newIndex;
        this.completePrevPeriodsIfNeeded(newIndex);
      }
    }
  }

  @action
  completePrevPeriodsIfNeeded(currentIndex: number) {
    const toLoad = PREV_PERIODS_PER_SCREEN - (this._periods.length - 1 - currentIndex);
    if (toLoad > 0) {
      this.fetchPeriods(-toLoad);
    }
  }

  @action.bound
  prevPeriodsBack() {
    const nextIndex = this.prevPeriodsPointerIndex + PREV_PERIODS_PER_SCREEN;
    const toLoad = nextIndex + 1 + PREV_PERIODS_PER_SCREEN - this.prevPeriods.length;
    this._prevPeriodsPointerIndex = nextIndex;
    if (toLoad > 0 && !this._isNoMorePrevPeriodsToFetch) {
      this.fetchPeriods(-toLoad);
    }
  }

  @action.bound
  prevPeriodsForward() {
    const nextIndex = this.prevPeriodsPointerIndex - PREV_PERIODS_PER_SCREEN;
    this._prevPeriodsPointerIndex = nextIndex < 0 ? 0 : nextIndex;
  }

  @action.bound
  async goNext() {
    // Concurrency issues: return if earlier data fetching is still in progress
    if (this.isLoading || this.isPrevPeriodsLoading) {
      return;
    }
    if (await this.checkForUnsaved()) {
      if (this._selectedPeriodIndex > 0) {
        this._selectedPeriodIndex--;
      } else if (this.isNextAllowed) {
        this.fetchPeriods(1);
      }
    }
  }

  @action.bound
  async goBack() {
    // Concurrency issues: return if earlier data fetching is still in progress
    if (this.isLoading || this.isPrevPeriodsLoading) {
      return;
    }
    if (await this.checkForUnsaved()) {
      if (this._selectedPeriodIndex < this._periods.length - 1) {
        const newIndex = this._selectedPeriodIndex + 1;
        this.completePrevPeriodsIfNeeded(newIndex);
        this._selectedPeriodIndex = newIndex;
      }
    }
  }

  @action.bound
  changeAttachments(attachments: FileDescriptorDtoV2[]) {
    if (this.selectedPeriod) {
      this.selectedPeriod.attachments = attachments;
    }
  }

  @computed
  get selectedPeriodCache(): IPeriodTeachingContentItem | undefined {
    if (
      !!this.selectedPeriod &&
      (!this._selectedPeriodCache || this._selectedPeriodCache.periodId !== this.selectedPeriod.periodId)
    ) {
      this._selectedPeriodCache = { ...this.selectedPeriod };
    }
    return this._selectedPeriodCache;
  }

  @computed
  get saveForBLock(): boolean {
    return this._saveForBlock;
  }

  /**
   * @param nearbyCount if negative, searching for particular number of previous periods,
   *        if positive, searching for upcoming.
   */
  @action.bound
  fetchPeriods(nearbyCount: number) {
    this._isLoading = nearbyCount > 0 || !this._periods.length;
    this._isPrevPeriodsLoading = nearbyCount < 0;
    const startPeriodId =
      this._periods.length > 0
        ? nearbyCount > 0
          ? this._periods[0].periodId
          : this._periods[this._periods.length - 1].periodId
        : this._periodId;
    ClassRegLessonTopicViewApi.getPeriodLessonTopics(startPeriodId, nearbyCount, !!this._periods.length).then(
      (resp) => {
        const loadedPeriods = resp.data.periodTopics.map((pt) => this.mapPeriodLessonTopic(pt));
        this._isLoading = false;
        this._isPrevPeriodsLoading = false;
        if (nearbyCount < 0) {
          this._periods = [...this._periods, ...loadedPeriods];
          this._untouchedPeriods = [...this._untouchedPeriods, ...loadedPeriods];
          this._isNoMorePrevPeriodsToFetch = loadedPeriods.length < Math.abs(nearbyCount);
        } else {
          this._periods = [...loadedPeriods.reverse(), ...this._periods];
          this._untouchedPeriods = [...loadedPeriods.reverse(), ...this._untouchedPeriods];
          this._isNoMoreFuturePeriodsToFetch = loadedPeriods.length < nearbyCount;
        }
      },
    );
  }

  @action.bound
  async saveOrUpdate(): Promise<ClassRegLessonTopicDto[]> {
    this._isSaving = true;
    if (!this.selectedPeriod) {
      return [];
    }

    const attachments: FileDescriptorDtoV2[] = [];
    this.selectedPeriod.attachments.forEach((a) => {
      attachments.push({ ...a });
    });

    const saveRequestDto: ClassRegLessonTopicModifyReqDto = {
      forceBlock: this._saveForBlock,
      topic: {
        id: this.selectedPeriod.id,
        periodId: this.selectedPeriod.periodId,
        text: this.selectedPeriod.text,
        methodId: this.selectedPeriod.methodId,
        attachments: attachments.map(
          (a): ClassRegAttachmentDto => ({
            id: 0,
            driveId: a.storageId,
            name: a.name,
            url: a.downloadUrl,
          }),
        ),
      },
    };

    try {
      const resp = await ClassRegLessonTopicViewApi.saveOrUpdateLessonTopic(saveRequestDto);
      this._notificationStore.success({ title: t('general.teachingContentSaved') });
      const savedTopics: ClassRegLessonTopicDto[] = (resp.data && resp.data.topics) || [];
      if (savedTopics.length) {
        this._periods.forEach((p) => {
          const savedTopic = savedTopics.find((t) => t.periodId === p.periodId);
          if (savedTopic) {
            Object.assign(p, this.mapLessonTopic(savedTopic));
          }
        });
        this._untouchedPeriods.forEach((p) => {
          const savedTopic = savedTopics.find((t) => t.periodId === p.periodId);
          if (savedTopic) {
            Object.assign(p, this.mapLessonTopic(savedTopic));
          }
        });
      }
      return savedTopics;
    } catch (e) {
      this._notificationStore.error({ title: t('general.errorOccurred') });
    } finally {
      this._isSaving = false;
    }
    return [];
  }

  @action.bound
  public onCloseTeachingContentSidebar() {
    this.checkForUnsaved().then((allowed) => {
      allowed && this._sidebarStore.closeSidebar();
    });
  }

  @action.bound
  public setSaveForBlock(value: boolean) {
    this._saveForBlock = value;
  }

  @action.bound
  async checkForUnsaved(): Promise<boolean> {
    if (!this.isEdited) {
      return true;
    }
    // TODO: use another approach or fix automatic closing due to the sidebar outside click
    const confirmed = await this._modalStore.booleanUserPrompt({
      title: t('general.discardChanges'),
      children: t('general.allUnsavedChangesAreLost'),
      okButton: {
        label: t('general.discard'),
      },
      cancelButton: {
        label: t('general.cancel'),
      },
    });
    if (confirmed && this.selectedPeriodCache && this._periods[this._selectedPeriodIndex]) {
      this._periods[this._selectedPeriodIndex] = this.selectedPeriodCache;
      this.clearTopicText();
    }
    return confirmed;
  }

  private mapPeriod(period: ClassRegPeriodDto): IPeriodItem {
    return {
      periodId: period.id,
      start: dayJsFromDateTimeString(period.dtRange.start),
      end: dayJsFromDateTimeString(period.dtRange.end),
      subject: period.subject ? period.subject.el.name : '',
      classes: period.classes.map((k) => k.el.name).join(', '),
      teachers: period.teachers.map((t) => t.el.name).join(', '),
      rooms: period.rooms.map((r) => r.el.name).join(', '),
    };
  }

  public getAttachmentsForTopic(topic: ClassRegLessonTopicDto | undefined): FileDescriptorDtoV2[] {
    const attachments: FileDescriptorDtoV2[] = [];
    topic?.attachments.forEach((a) => {
      attachments.push({
        storageId: a.driveId ?? `${a.id}`,
        name: a.name,
        downloadUrl: a.url ?? '',
        storageProvider: FileDescriptorDtoV2StorageProviderEnum.ONEDRIVE,
      });
    });
    return attachments;
  }

  private mapLessonTopic(topic: ClassRegLessonTopicDto | undefined): ITeachingContentItem {
    return {
      id: (topic && topic.id) || undefined,
      periodId: (topic && topic.periodId) || undefined,
      methodId: (topic && topic.methodId) || undefined,
      text: (topic && topic.text) || '',
      attachments: this.getAttachmentsForTopic(topic),
    };
  }

  private mapPeriodLessonTopic(periodTopic: ClassRegPeriodLessonTopicDto): IPeriodTeachingContentItem {
    return {
      ...this.mapLessonTopic(periodTopic.topic),
      ...this.mapPeriod(periodTopic.period),
      backColor: periodTopic.backColor,
      canSave: periodTopic.canSave,
      blockRange: periodTopic.blockRange,
    };
  }
}
