import React from 'react';
import { Form } from 'antd';
import clsx from 'clsx';

import { Checkbox } from '../../checkbox/checkbox';

import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import './form-checkbox.less';

interface IProps extends IFormItemProps<boolean> {
  // label is for the text that appears above the chexbox
  label?: string;
  // text is for the text that appears on the right side of the chexbox
  text?: string;
  onChange?: (checked: boolean) => void;
  textNoBreakWhiteSpace?: boolean;
}

export const FormCheckbox = (props: IProps) => {
  return (
    <Form.Item
      name={props.name}
      label={props.label}
      initialValue={props.initialValue}
      rules={props.rules}
      dependencies={props.dependencies}
      valuePropName="checked"
      className={clsx('form-checkbox', {
        'text-no-break-white-space': !!props.textNoBreakWhiteSpace,
      })}
    >
      <Checkbox
        disabled={props.disabled}
        defaultChecked={props.initialValue}
        label={props.text}
        onChange={props.onChange}
        testId={props.testId}
      />
    </Form.Item>
  );
};
