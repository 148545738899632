import { action, computed, observable } from 'mobx';
import uniqBy from 'lodash/uniqBy';
import { t } from 'i18next';

import { MessageRecipient } from '@mg/components/recipient-picker/recipient-picker';
import { Store } from '@/types/store';
import {
  MessagePersonsSectionDto,
  MessagePersonRecipientDto,
  SearchResultMessageUserRefDto,
} from '@untis/wu-rest-view-api';
import { ISelectOptionListItem } from '@/ui-components/select-option-list/select-option-list';
import { MessageViewApi } from '@/stores/api-store';

@Store()
export class RecipientPickerPersonViewStore {
  @observable personSections: MessagePersonsSectionDto[] = [];
  @observable searchQuery = '';
  @observable _selectedPersonId: number = -1;
  @observable selectedPersonId: number = -1;
  @observable loadingState: 'success' | 'idle' | 'loading' | 'error' = 'idle';

  async fetchPersonRecipients() {
    if (this.isSuccess) return;

    try {
      this.loadingState = 'loading';
      const { data: personSections } = await MessageViewApi.getPersonRecipients();

      this.personSections = personSections;

      this.resetSelection();

      this.loadingState = 'success';
    } catch (error) {
      this.loadingState = 'error';
    }
  }

  @action
  applySelection() {
    this.selectedPersonId = this._selectedPersonId;
  }

  @action
  resetSelection() {
    if (this.selectedPersonId === -1) {
      this._selectedPersonId = this.personSections?.[0]?.persons?.[0]?.userId ?? -1;
      return;
    }

    this._selectedPersonId = this.selectedPersonId;
  }

  @action
  selectOrDeselectPersonById(id: number) {
    if (this._selectedPersonId === id) {
      this._selectedPersonId = -1;
    } else {
      this._selectedPersonId = id;
    }
  }

  @action
  reset() {
    this.personSections = [];
    this.searchQuery = '';
    this._selectedPersonId = -1;
    this.selectedPersonId = -1;
    this.loadingState = 'idle';
  }

  @computed
  get persons() {
    return this.personSections.reduce((persons: MessagePersonRecipientDto[], personSection) => {
      if (!personSection.persons) return persons;

      return uniqBy([...persons, ...personSection.persons], (p) => p.userId);
    }, []);
  }

  @computed
  get filteredPersonSections() {
    const filteredPersonSections: MessagePersonsSectionDto[] = [];

    this.personSections.forEach((personSection) => {
      const newPersonSection = { ...personSection };

      if (!personSection.persons) {
        return;
      }

      const query = this.searchQuery.toLowerCase();

      newPersonSection.persons = personSection.persons.filter(
        (person) =>
          person.displayName.toLowerCase().includes(query) || person.tags?.join(' ').toLowerCase().includes(query),
      );

      // remove empty sections
      if (newPersonSection.persons.length > 0) {
        filteredPersonSections.push(newPersonSection);
      }
    });

    return filteredPersonSections;
  }

  @computed
  get selectedPersons(): SearchResultMessageUserRefDto[] {
    return this.persons
      .filter((person) => this.selectedPersonId === person.userId)
      .map(this.messagePersonRecipientDtoToPersonSearchDto);
  }

  @computed
  get selectedMessageRecipients(): MessageRecipient[] {
    return this.selectedPersons.map((person) => {
      return {
        type: 'person',
        person: {
          id: person.personId,
          displayName: person.displayName,
          className: person.className,
        },
      };
    });
  }

  @computed
  get selectedCount() {
    return this._selectedPersonId > -1 ? 1 : 0;
  }

  @computed
  get isLoading() {
    return this.loadingState === 'loading';
  }

  @computed
  get isSuccess() {
    return this.loadingState === 'success';
  }

  @computed
  get hasError() {
    return this.loadingState === 'error';
  }

  successMessage() {
    const name = this.persons.find((person) => person.userId === this.selectedPersonId)?.displayName;

    return t('general.sendMessageTeacherSendSuccess', {
      count: this.selectedCount,
      name,
    });
  }

  messagePersonRecipientDtoToPersonSearchDto(person: MessagePersonRecipientDto): SearchResultMessageUserRefDto {
    return {
      displayName: person.displayName,
      personId: person.userId,
      className: '',
      imageUrl: person.imageUrl,
      role: '',
    };
  }

  getOptionsByPersons(persons: MessagePersonRecipientDto[]): ISelectOptionListItem[] {
    return persons.map((person) => ({
      id: person.userId,
      name: person.displayName,
      value: person.userId,
      tags: person.tags.map((tag) => ({ name: tag })),
      avatarSrc: person.imageUrl,
    }));
  }

  @computed
  get selectedPersonName() {
    return this.selectedPersons?.[0].displayName ?? '';
  }

  @computed
  get selectedPersonRolesSeperatedByComma() {
    return this.selectedPersons?.[0].role ?? '';
  }
}
