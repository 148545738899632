import useStore from '@/hooks/useStore';
import ConfigStore from '@/stores/config-store';
import TokenStore, { TokenRole, TokenPermission } from '@/stores/token-store';

export interface IRequiredPermissions {
  requiredRoles?: TokenRole[];
  requiredTokenPermissions?: TokenPermission[];
  requiredViewPermissions?: string[];
}

export default function useIsAuthorized(permissions: IRequiredPermissions) {
  const tokenStore = useStore(TokenStore);
  const configStore = useStore(ConfigStore);

  let isUserAuthorized = true;

  if (permissions.requiredTokenPermissions) {
    isUserAuthorized = tokenStore.hasTokenPermissions(...permissions.requiredTokenPermissions);
  }
  if (isUserAuthorized && permissions.requiredRoles) {
    isUserAuthorized = tokenStore.hasRoles(...permissions.requiredRoles);
  }
  if (isUserAuthorized && permissions.requiredViewPermissions) {
    isUserAuthorized = configStore.hasViewPermissions(...permissions.requiredViewPermissions);
  }

  return isUserAuthorized;
}
