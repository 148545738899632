import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ListView } from '@/components/list-view/list-view';
import {
  DateRangeOptionEnum,
  PageDatePickerMode,
} from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import SchoolYearStore from '@/stores/schoolyear-store';
import { ExamsStore, IExamRow } from '@ls/exams/overview/exams-store';
import { TestIds } from '@/testIds';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import './exams.less';
import { formatDateForServerRequest } from '@/utils/date/date-util';

const Exams = observer(() => {
  const [examsStore] = useState(() => new ExamsStore());
  const modalStore = useStore(ModalStore);
  const schoolYearStore = useStore(SchoolYearStore);

  const { t } = useTranslation();

  const { currentSchoolYear } = schoolYearStore;

  useComponentDidMount(() => {
    examsStore.init();
  });

  useEffect(() => {
    examsStore.getExamListFilters(
      formatDateForServerRequest(examsStore.selectedDateRange.startDate),
      formatDateForServerRequest(examsStore.selectedDateRange.endDate),
    );
  }, [examsStore.selectedDateRange]);

  useEffect(() => {
    examsStore.getExamList();
  }, [examsStore.selectedDateRange, examsStore.selectedOptions, examsStore.showDeleted]);

  return (
    <ListView<IExamRow>
      title={t('lessons.exams.title')}
      isPageLoading={examsStore.isDataLoading}
      pageDatePickerProps={{
        mode: PageDatePickerMode.DATE_RANGE,
        options: [
          DateRangeOptionEnum.DATE_RANGE_TODAY,
          DateRangeOptionEnum.DATE_RANGE_WEEK,
          DateRangeOptionEnum.DATE_RANGE_NEXT_WEEK,
          DateRangeOptionEnum.DATE_RANGE_MONTH,
          DateRangeOptionEnum.DATE_RANGE_SCHOOLYEAR,
          DateRangeOptionEnum.USER_SELECTION_DATE_RANGE,
          DateRangeOptionEnum.CUSTOM_DATE_RANGE,
        ],
        disabledOptions: examsStore.disabledOptions(dayjs()),
        value: examsStore.selectedDateRange,
        currentSchoolyear: {
          startDate: dayjs(currentSchoolYear?.dateRange?.start),
          endDate: dayjs(currentSchoolYear?.dateRange?.end),
        },
        onDateRangeChange: (value) => examsStore.setSelectedDateRange(value),
        customDateRanges: [
          {
            startDate: dayjs(),
            endDate: dayjs(currentSchoolYear?.dateRange?.end),
            name: t('general.toEndOfSchoolyear'),
          },
        ],
        disabledCustomDateRanges: examsStore.disabledCustomDateRanges(dayjs()),
        onCustomClick: () => {
          modalStore
            .openModalDateRangePicker(
              examsStore.selectedDateRange.startDate,
              examsStore.selectedDateRange.endDate,
              undefined,
              examsStore.disabledDate,
            )
            .then((result) => {
              if (result.value) {
                examsStore.setSelectedDateRange({
                  option: DateRangeOptionEnum.USER_SELECTION_DATE_RANGE,
                  startDate: result.value.startDate,
                  endDate: result.value.endDate,
                });
              }
            });
        },
      }}
      searchBar={{
        selectedFreeTextOptions: examsStore.selectedFreeTextOptions,
        onChangeFreeTextOptions: (values) => examsStore.setSelectedFreeTextSearchOptions(values),
        options: examsStore.options,
        onChangeSelectOptions: (options) => examsStore.setSelectedOptions(options),
        selectedOptions: examsStore.selectedOptions,
        onlyOneOptionPerCategory: true,
        placeholder: t('general.search'),
        toggleFilters: examsStore.toggleFilters,
      }}
      table={{
        columns: examsStore.columns,
        rowData: examsStore.rowData,
        rowSelection: {
          selectedRowKeys: examsStore.selectedRowKeys,
          type: 'checkbox',
          onChange: (keys, selectedRows) =>
            examsStore.setSelectedRowKeys(
              selectedRows.filter((row) => !row.isUntisExam && row.userCanDeleteExam).map((row) => row.key),
            ),
          getCheckboxProps: (row: IExamRow) => ({
            disabled: row.isUntisExam || !row.userCanDeleteExam,
          }),
        },
        rowClassName: (row) => (row.isDeleted ? 'exam-row--deleted' : ''),
        shadow: true,
        className: 'exams--table',
      }}
      selectedRowsActions={examsStore.selectedRowsActions}
      actions={examsStore.globalActions}
      testId={TestIds.EXAMS_LIST}
    />
  );
});

export default Exams;
