import React from 'react';
import { useTranslation } from 'react-i18next';
import { isSafari } from 'react-device-detect';

import PlatformStore from '../../stores/platform-store';

import { Button } from '@/ui-components';
import './open-application-in-new-tab-view.less';
import { PlatformApplicationUrlDtoViewTypeEnum } from '@untis/wu-rest-view-api';
import useStore from '@/hooks/useStore';

interface IProps {
  name: string;
  url: string;
  platformApplicationId: number;
}

const OpenApplicationInNewTabView = (props: IProps) => {
  const platformStore = useStore(PlatformStore);
  const { t } = useTranslation();

  const handleOpenApplication = () => {
    platformStore.trackPlatformApplicationUsage(
      props.platformApplicationId,
      PlatformApplicationUrlDtoViewTypeEnum.OVERVIEW,
    );
    window.open(props.url, '_blank');
  };

  return (
    <div className="open-application-in-new-tab-view">
      <h1>{t('platform.clickToOpenInNewTab', { applicationName: props.name })}</h1>
      <Button
        className="open-application-button"
        type="primary"
        size="large"
        onClick={handleOpenApplication}
        testId="open-button"
      >
        {t('platform.openApplication')}
      </Button>
      {isSafari && <small className="safari-hint">{t('platform.safariTabHint')}</small>}
    </div>
  );
};

export default OpenApplicationInNewTabView;
