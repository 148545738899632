import { t } from 'i18next';
import { action, computed, observable } from 'mobx';

import {
  CalendarBuildingDto,
  CalendarDepartmentDto,
  CalendarRoomDetailDto,
  RoomAvailabilityEnum,
  CalendarRoomTypeDto,
} from '@untis/wu-rest-view-api';
import { ISelectOptionListItem, ISelectOptionTag } from '@/ui-components/select-option-list/select-option-list';
import { IDeprecatedFilter } from '@/ui-components/filter-bar/filter/deprecatedFilter';

class RoomSelectionDialogStore {
  @observable private _availableRooms: CalendarRoomDetailDto[];
  @observable private _buildings: CalendarBuildingDto[];
  @observable private _departments: CalendarDepartmentDto[];
  @observable private _roomTypes: CalendarRoomTypeDto[];
  @observable private _filterBarValue: Map<string, string | undefined> = new Map();
  @observable private _searchBarValue: string = '';
  @observable private _selectedRoomId: number | undefined;
  @observable private _selectedRoomIds: number[] = [];
  @observable private isSingleSelect: boolean | undefined;

  constructor(
    availableRooms: CalendarRoomDetailDto[],
    buildings: CalendarBuildingDto[],
    departments: CalendarDepartmentDto[],
    roomTypes: CalendarRoomTypeDto[],
    selectedRoomIds: number[],
    selectedRoomId?: number,
    initialBuildingFilter?: number,
    initialDepartmentFilter?: number,
    isSingleSelect?: boolean,
  ) {
    this._availableRooms = availableRooms;
    this._selectedRoomId = selectedRoomId;
    this._selectedRoomIds = selectedRoomIds;
    this._buildings = buildings;
    this._departments = departments;
    this._roomTypes = roomTypes;
    if (initialBuildingFilter) {
      this._filterBarValue.set('building', initialBuildingFilter.toString());
    }
    if (initialDepartmentFilter) {
      this._filterBarValue.set('department', initialDepartmentFilter.toString());
    }
    this.isSingleSelect = isSingleSelect;
  }

  @computed get filterBarValue() {
    return this._filterBarValue;
  }

  @action setFilterBarValue = (val: Map<string, string | undefined>) => {
    this._filterBarValue = val;
  };

  @computed get searchBarValue() {
    return this._searchBarValue;
  }

  @action setSearchBarValue = (val: string) => {
    this._searchBarValue = val;
  };

  @computed get selectedRoomId() {
    return this._selectedRoomId;
  }

  @action setSelectedRoomId = (val: number | undefined) => {
    this._selectedRoomId = val;
  };

  @computed get selectedRoomIds() {
    return this._selectedRoomIds;
  }

  @action.bound setSelectedRoomIds(selectedRoomIds: number[]) {
    this._selectedRoomIds = selectedRoomIds;
  }

  @computed get filteredRooms() {
    const enumOrder = [RoomAvailabilityEnum.BOOKABLE, RoomAvailabilityEnum.RESERVABLE, RoomAvailabilityEnum.NONE];
    const sortedRooms = this._availableRooms
      .sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
      .sort((a, b) => {
        return enumOrder.indexOf(a.availability) - enumOrder.indexOf(b.availability);
      });

    let filteredRooms = this.isSingleSelect
      ? this._availableRooms.filter((room) => {
          return room.availability !== RoomAvailabilityEnum.NONE;
        })
      : sortedRooms;

    if (this.searchBarValue.trim().length > 0) {
      filteredRooms = filteredRooms.filter((room) => {
        return room.displayName.toLowerCase().includes(this.searchBarValue.toLowerCase());
      });
    }

    // apply building and department filter (&& filter)
    this.filterBarValue.forEach((val, key) => {
      if (key === 'building' && !!val) {
        filteredRooms = filteredRooms.filter((room) => {
          return room.building && room.building.id.toString() === val;
        });
      } else if (key === 'department' && !!val) {
        filteredRooms = filteredRooms.filter((room) => {
          return room.department && room.department.id.toString() === val;
        });
      }
    });

    // apply roomType filter (|| filter)
    const selectedRoomTypes: string[] = [];
    this.filterBarValue.forEach((val, key) => {
      if (key !== 'building' && key !== 'department' && !!val) {
        selectedRoomTypes.push(val);
      }
    });

    if (selectedRoomTypes.length > 0) {
      filteredRooms = filteredRooms.filter((room) => {
        return room.roomType && selectedRoomTypes.includes(room.roomType.id.toString());
      });
    }

    return filteredRooms;
  }

  @computed get roomItems(): ISelectOptionListItem[] {
    return this.filteredRooms.map((item) => {
      let onlyReservableOption = {};
      if (item.availability === RoomAvailabilityEnum.RESERVABLE) {
        onlyReservableOption = {
          value: undefined,
          actionButton: {
            text: t('general.onlyReservationExtended'),
            disabled: true,
          },
        };
      }
      const defaultOption = {
        id: item.id,
        value: item.id,
        name: this.getName(item),
        icon: 'shared_roomEntity',
        tags: this.getTagsForRoom(item),
      };
      return { ...defaultOption, ...onlyReservableOption };
    });
  }

  @computed get filter(): IDeprecatedFilter[] {
    const filterArray: IDeprecatedFilter[] = [];

    if (this._buildings.length > 0) {
      filterArray.push({
        id: 'building',
        placeholder: t('general.building'),
        items: this._buildings.map((building) => {
          return {
            id: building.id.toString(),
            label: this.getName(building),
          };
        }),
      });
    }

    if (this._departments.length > 0) {
      filterArray.push({
        id: 'department',
        placeholder: t('general.department'),
        items: this._departments.map((department) => {
          return {
            id: department.id.toString(),
            label: this.getName(department),
          };
        }),
      });
    }

    this._roomTypes.forEach((roomType) => {
      filterArray.push({
        id: roomType.id.toString(),
        items: [{ id: roomType.id.toString(), label: this.getName(roomType) }],
      });
    });

    return filterArray;
  }

  getTagsForRoom = (room: CalendarRoomDetailDto): ISelectOptionTag[] => {
    const tags: ISelectOptionTag[] = [];
    if (room.building) {
      tags.push({
        name: this.getName(room.building),
        active:
          !!this.filterBarValue.get('building') && this.filterBarValue.get('building') === room.building.id.toString(),
      });
    }
    if (room.department && room.department.id > -1) {
      tags.push({
        name: this.getName(room.department),
        active:
          !!this.filterBarValue.get('department') &&
          this.filterBarValue.get('department') === room.department.id.toString(),
      });
    }
    if (room.roomType) {
      tags.push({
        name: this.getName(room.roomType),
        active:
          !!this.filterBarValue.get(room.roomType.id.toString()) &&
          this.filterBarValue.get(room.roomType.id.toString()) === room.roomType.id.toString(),
      });
    }

    if (room.availability === RoomAvailabilityEnum.NONE) {
      tags.push({
        name: t('substitutionPlanning.forms.solutionView.tags.HAS_PERIOD_COLLISION'),
        active: false,
      });
    }
    return tags;
  };

  getName = (item: { displayName: string }) => item.displayName;
}

export default RoomSelectionDialogStore;
