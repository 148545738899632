import { t } from 'i18next';
import { action, computed, observable } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';

import TodayPermissionsStore from '@today/stores/today-permissions-store';
import { Store, inject } from '@/types/store';
import { TodayViewApi } from '@/stores/api-store';
import ConfigStore from '@/stores/config-store';
import { formatDateTime, formatDate } from '@/utils/date/date-util';
import { TodayMetaDto } from '@untis/wu-rest-view-api/api';
import { showErrorResponse } from '@/utils/error-handling/error-message';

@Store()
class TodayStore {
  private configStore = inject(ConfigStore);
  private permissionStore = inject(TodayPermissionsStore);
  private _today = dayjs();
  @observable private _meta: TodayMetaDto = {};
  @observable private _isMetaReady = false;

  @action
  setToday(date?: string | Dayjs) {
    if (!date) {
      return;
    }
    this._today = dayjs(date);
  }

  @action
  async initTodayMeta() {
    try {
      this._meta = (await TodayViewApi.getTodayMetaInfo()).data;
      this._isMetaReady = true;
    } catch (error) {
      showErrorResponse(error);
    }
  }

  @computed
  get greeting() {
    const hours = new Date().getHours();
    let greeting = t('general.todayGreeting');

    if (hours < 22) {
      greeting = t('general.todayGreetingEvening');
    }

    if (hours < 18) {
      greeting = t('general.todayGreetingAfternoon');
    }

    if (hours < 12) {
      greeting = t('general.todayGreetingMorning');
    }

    return this.userName ? `${greeting}, ${this.userName}!` : `${greeting}!`;
  }

  @computed
  get userName() {
    return this._meta?.greetingName || '';
  }

  @computed
  get today() {
    return this._today;
  }

  @computed
  get lastLoginFormatted() {
    if (!this.configStore.lastLogin) {
      return '';
    }
    return formatDateTime(this.configStore.lastLogin);
  }

  // licence

  @computed
  get licenseExpiresAt() {
    return this.configStore.licenseExpiresAt;
  }

  @computed
  get licenseExpiresAtFormatted() {
    return t('general.licenseExpiresAt', { date: formatDate(this.configStore.licenseExpiresAt) });
  }

  @computed
  get validLicenseDays() {
    if (!this.licenseExpiresAt) return undefined;

    return this.licenseExpiresAt.diff(this.today, 'days') + 1;
  }

  @computed
  get isSupportAccessOpen() {
    return this.configStore.isSupportAccessOpen;
  }

  @computed
  get canSeeNewMotdButton() {
    return this.permissionStore.canSeeNewMotdButton;
  }

  @computed
  get isReady(): boolean {
    return this._isMetaReady;
  }

  @computed
  get isCalendarEntriesAllowed(): boolean {
    return !!this._meta.calendar;
  }

  @computed
  get calendarEntriesClasses(): { id: number; name: string }[] {
    return (this._meta.calendar && this._meta.calendar.classes.map((c) => ({ id: c.id, name: c.name }))) || [];
  }

  @computed
  get calendarEntriesMinStart(): string | undefined {
    return this._meta.calendar?.minStart;
  }

  @computed
  get calendarEntriesMaxEnd(): string | undefined {
    return this._meta.calendar?.maxEnd;
  }
}

export default TodayStore;
