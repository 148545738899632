import React from 'react';
import clsx from 'clsx';

import { ITestComponentProps } from '@/types/test-component-props';

import './tag.less';

export type TagColor = 'grey' | 'dark-grey' | 'light-grey' | 'green' | 'orange' | 'red' | 'yellow';
export type TagSize = 'md' | 'lg';

export interface ITagProps extends React.HTMLAttributes<HTMLDivElement>, ITestComponentProps {
  color?: TagColor;
  size?: TagSize;
  uppercase?: boolean;
  children: React.ReactNode;
}

export const Tag = ({ children, color, size, uppercase, ...props }: ITagProps) => {
  const sizeClassName: string = size ?? 'md';

  return (
    <div
      {...props}
      className={clsx('tag', {
        [color as string]: color,
        [sizeClassName as string]: sizeClassName,
        uppercase,
      })}
      data-testid={props.testId}
    >
      {children}
    </div>
  );
};
