import React, { ReactNode, HTMLAttributes, ElementType } from 'react';
import clsx from 'clsx';

import { Flex, IFlexProps } from '@/ui-components/flex/flex';
import { Stack, IStackProps } from '@/ui-components/stack/stack';

import './box.less';

type Token = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type AllAttrs = {
  Flex: IFlexProps;
  Stack: IStackProps;
  div: HTMLAttributes<HTMLDivElement>;
};

export type IBoxProps = {
  as?: typeof Flex | typeof Stack | 'div';
  children: ReactNode;
  inline?: boolean;
  inlineFlex?: boolean;
  m?: Token;
  mx?: Token;
  my?: Token;
  mt?: Token;
  mb?: Token;
  ml?: Token;
  mr?: Token;
  p?: Token;
  px?: Token;
  py?: Token;
  pt?: Token;
  pb?: Token;
  pl?: Token;
  pr?: Token;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

function Box<T extends keyof AllAttrs>({
  as,
  children,
  className,
  inline,
  inlineFlex,
  m,
  mx,
  my,
  mt,
  mb,
  ml,
  mr,
  p,
  px,
  py,
  pt,
  pb,
  pl,
  pr,
  ...props
}: IBoxProps & AllAttrs[T]) {
  const Element: ElementType = as ? as : 'div';

  return (
    <Element
      className={clsx(
        'box-container',
        {
          inline: inline,
          'inline-flex': inlineFlex,
          [`m-${m}`]: m,
          [`mx-${mx}`]: mx,
          [`my-${my}`]: my,
          [`mt-${mt}`]: mt,
          [`mb-${mb}`]: mb,
          [`ml-${ml}`]: ml,
          [`mr-${mr}`]: mr,
          [`p-${p}`]: p,
          [`px-${px}`]: px,
          [`py-${py}`]: py,
          [`pt-${pt}`]: pt,
          [`pb-${pb}`]: pb,
          [`pl-${pl}`]: pl,
          [`pr-${pr}`]: pr,
        },
        className,
      )}
      {...props}
    >
      {children}
    </Element>
  );
}

export { Box };
