import { Checkbox, notification } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStore from '@/hooks/useStore';
import { ApprenticeRepresentativesViewApi, LegalGuardiansViewApi, StudentViewApi } from '@/stores/api-store';
import ModalStore from '@/stores/modal-store';
import PostMessageStore from '@sp/stores/post-message-store';
import { Button, message } from '@/ui-components';
import { StudentBulkDeleteDto } from '@untis/wu-rest-view-api/api';

import './deletion-service-warning.less';

interface IPersonToDeleteData {
  id: number;
  name: string;
}

interface IProps {
  persons: IPersonToDeleteData[];
  isForm: boolean;
  type: 'students' | 'legalGuardians' | 'apprenticeRepresentatives';
  onOk?: () => void;
}

const DeletionServiceWarning = observer(({ persons, isForm, type, onOk }: IProps) => {
  const postMessageStore = useStore(PostMessageStore);
  const modalStore = useStore(ModalStore);
  const { t } = useTranslation();

  const [active, setActive] = useState(false);
  const [isDeleteParentsTooChecked, setIsDeleteParentsTooChecked] = useState(false);

  const isStudentView = type === 'students';
  const isLegalGuardiansView = type === 'legalGuardians';
  const isApprenticeRepresentativesView = type === 'apprenticeRepresentatives';

  const maxShownStudents = 50;

  const personsIds = persons.map((s) => s.id);
  const personsNames = persons
    .map((s) => s.name ?? null)
    .filter(Boolean)
    .slice(0, maxShownStudents)
    .join(', ');
  const personsCount = persons.length;

  function confirmationChecked(e: any) {
    setActive(e.target.checked);
  }

  function onOkClick() {
    onOk && onOk();
  }

  const sendDeleteRequest = async () => {
    const hide = personProcessDisplay();
    const deletedStudent: StudentBulkDeleteDto = {
      deleteParents: isDeleteParentsTooChecked,
      studentIds: personsIds,
    };
    try {
      if (isStudentView) {
        await StudentViewApi.studentBulkDelete(deletedStudent);
      }

      if (isLegalGuardiansView) {
        await LegalGuardiansViewApi.deleteLegalGuardians(personsIds);
      }

      if (isApprenticeRepresentativesView) {
        await ApprenticeRepresentativesViewApi.deleteApprenticeRepresentatives(personsIds);
      }

      hide();
      successDisplay();
      if (isForm) {
        postMessageStore.postGoBackMessage();
      } else {
        postMessageStore.postReloadContentMessage();
      }
    } catch (e) {
      console.error(e);
      hide();
      errorNotification();
    }
  };

  const successDisplay = () => {
    let text = '';

    if (isStudentView) {
      text =
        personsCount > 1
          ? t('deletionService.messages.success.multipleStudents', { studentCount: personsCount })
          : t('deletionService.messages.success.singleStudent', { student: personsNames });
    }

    if (isLegalGuardiansView || isApprenticeRepresentativesView) {
      text =
        personsCount > 1
          ? t('deletionService.messages.success.multipleLgAr', { lgArCount: personsCount })
          : t('deletionService.messages.success.single', { name: personsNames });
    }

    return message.success(text, 10);
  };

  const personProcessDisplay = () => {
    let text = '';

    if (isStudentView) {
      text =
        personsCount > 1
          ? t('deletionService.messages.process.multipleStudents', { studentCount: personsCount })
          : t('deletionService.messages.process.singleStudent', { student: personsNames });
    }

    if (isLegalGuardiansView || isApprenticeRepresentativesView) {
      text =
        personsCount > 1
          ? t('deletionService.messages.process.multipleLgAr', { lgArCount: personsCount })
          : t('deletionService.messages.process.single', { name: personsNames });
    }

    return message.info(text, 0);
  };

  const errorNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => notification.destroy(key)}>
        Confirm
      </Button>
    );
    let description = '';

    if (isStudentView) {
      description =
        personsCount > 1
          ? t('deletionService.messages.errors.multipleStudents', { studentCount: personsCount })
          : t('deletionService.messages.errors.singleStudent', { student: personsNames });
    }

    if (isLegalGuardiansView || isApprenticeRepresentativesView) {
      description =
        personsCount > 1
          ? t('deletionService.messages.errors.multipleLgAr', { lgArCount: personsCount })
          : t('deletionService.messages.errors.single', { name: personsNames });
    }

    notification.open({
      message: 'Error',
      description,
      btn,
      key,
      duration: 0,
    });
  };

  const deletionText = React.useMemo(() => {
    if (isStudentView) {
      if (personsCount > 1 && personsCount <= maxShownStudents) {
        return (
          <>
            <p>{t('deletionService.deleteMultipleStudents', { studentCount: personsCount })}</p>
            <p>{personsNames}</p>
          </>
        );
      } else if (personsCount > maxShownStudents) {
        return (
          <>
            <p>{t('deletionService.deleteMultipleStudents', { studentCount: personsCount })}</p>
            <p>
              {personsNames} ...{t('general.moreRecordsExist')}
            </p>
          </>
        );
      } else if (personsCount === 1) {
        return <p>{t('deletionService.deleteSingleStudent', { student: personsNames })}</p>;
      }
    }

    if (isLegalGuardiansView || isApprenticeRepresentativesView) {
      if (personsCount > 1 && personsCount <= maxShownStudents) {
        return (
          <>
            <p>{t('deletionService.deleteMultipleLgAr', { lGArCount: personsCount })}</p>
            <p>{personsNames}</p>
          </>
        );
      } else if (personsCount > maxShownStudents) {
        return (
          <>
            <p>{t('deletionService.deleteMultipleLgAr', { lGArCount: personsCount })}</p>
            <p>
              {personsNames} ...{t('general.moreRecordsExist')}
            </p>
          </>
        );
      } else if (personsCount === 1) {
        return <p>{t('deletionService.deleteSingleLgAr', { lGAr: personsNames })}</p>;
      }
    }

    return '';
  }, [isApprenticeRepresentativesView, isLegalGuardiansView, isStudentView, personsCount, personsNames, t]);

  const handleOnClick = async () => {
    await sendDeleteRequest();
    onOkClick();
    modalStore.closeModal();
  };

  return (
    <div className="container">
      <div>
        {isStudentView && <p>{t`deletionService.deleteStudentWarning`}</p>}
        {(isLegalGuardiansView || isApprenticeRepresentativesView) && <p>{t`deletionService.deleteLgArWarning`}</p>}
        {deletionText}
      </div>
      <br />
      <div className="checkbox-container">
        <div className="confirmation-check-container">
          <Checkbox
            onChange={confirmationChecked}
            className={
              active
                ? 'confirmation-description confirmation-description--checked'
                : 'confirmation-description confirmation-description--unchecked'
            }
          >
            {t`deletionService.messages.confirmDeletionCheck`}
          </Checkbox>
        </div>
        <br />
        {isStudentView && (
          <div className="parents-check-container">
            <Checkbox
              onChange={(e) => setIsDeleteParentsTooChecked(e.target.checked)}
              value={isDeleteParentsTooChecked}
            >{t`deletionService.messages.deleteParentsCheck`}</Checkbox>
          </div>
        )}
      </div>
      <br />
      <div className="button-wrapper">
        <Button type="secondary" onClick={modalStore.closeModal}>{t`general.cancel`}</Button>
        <Button type="primary" disabled={!active} onClick={handleOnClick}>
          {t`deletionService.confirmDeletion`}
        </Button>
      </div>
    </div>
  );
});

export default DeletionServiceWarning;
