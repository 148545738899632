import { observer } from 'mobx-react-lite';
import { Dayjs } from 'dayjs';
import classNames from 'clsx';
import React from 'react';

import { TimetableUserActionsStore } from '@te/standard/stores/user-actions/timetable-user-actions-store';
import useStore from '@/hooks/useStore';

import './timetable-more-full-day-event-indicator.less';

const TimetableMoreFullDayEventIndicator = observer(
  ({ day, count, active }: { day: Dayjs; count: number; active: boolean }) => {
    const timetableUserActionsStore = useStore(TimetableUserActionsStore);

    return (
      <div
        className={classNames('timetable-full-day-event--more-indicator', { active: active })}
        onClick={() => timetableUserActionsStore.toggleTimetableViewType(day)}
      >{`+${count}`}</div>
    );
  },
);

export default TimetableMoreFullDayEventIndicator;
