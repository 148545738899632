import { action, computed, observable, reaction } from 'mobx';
import { FormInstance } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';

import { DeprecatedAbstractListViewStore } from '@/pages/master-data/common/deprecated-abstract-list-view-store';
import { IStudentForm, StudentForm } from '@/pages/master-data/student/student-form';
import {
  ClassInfoDto,
  CustomOrderDto,
  SchoolYearClassInfoDto,
  SchoolYearDto,
  StudentDto,
} from '@untis/wu-rest-view-api/api';
import { ElementType } from '@/stores/rights-store';
import { ImageViewApi, StudentViewApi } from '@/stores/api-store';
import { getErrorMessageFromResponseError } from '@/utils/error-handling/error-handling';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { booleanSorting, defaultSorting, sortByDateOrString } from '@/utils/sorting/sorting-util';
import { IListViewAction, IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import { TestIds } from '@/testIds';
import DeletionServiceWarning from '@/components/deletion-service-warning/deletion-service-warning';
import { dayjsFormatWithoutTimeDeprecated } from '@/utils/date/date-util';
import { IDeprecatedFilter, IFilterItem } from '@/ui-components/filter-bar/filter/deprecatedFilter';
import {
  DateOptionEnum,
  IDate,
} from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import ReportStore from '@/stores/report-store';
import { inject } from '@/types/store';
import { IReportExportingRow, ReportType } from '@/components/reports-exporting-table/reports-exporting-table';
import {
  IStudentReportParameters,
  StudentReportUrlMapperStore,
} from '@/pages/master-data/student/student-report-url-mapper-store';
import { StudentImagesUploadForm } from '@/pages/master-data/student/import/student-images-upload-form';
import { DeprecatedFilterBarValue } from '@/ui-components/filter-bar/deprecated-filter-bar';
import SchoolYearStore from '@/stores/schoolyear-store';

export interface IStudentRow extends ITableRowKey {
  id: number;
  firstName: string;
  lastName: string;
  shortName: string;
  birthDate?: Dayjs;
  gender: string;
  className: string;
  catalogueNumber: string;
  active: boolean;
  entryDate?: Dayjs;
  exitDate?: Dayjs;
  externKey: string;
  text: string;
}

export class StudentStore extends DeprecatedAbstractListViewStore<IStudentForm> {
  private reportStore: ReportStore = inject(ReportStore);
  private studentReportUrlMapperStore: StudentReportUrlMapperStore = inject(StudentReportUrlMapperStore);
  private schoolYearStore: SchoolYearStore = inject(SchoolYearStore);

  @observable private _students: StudentDto[] = [];
  @observable private _schoolyearClassInfos: SchoolYearClassInfoDto[] = [];
  @observable private _filterValues: Map<string, string | undefined> = new Map();
  @observable private _selectedExitDate: IDate | undefined = undefined;
  @observable private _uploadedZipFile: File | undefined = undefined;

  constructor(form: FormInstance<IStudentForm>) {
    super(form, { elementType: ElementType.STUDENT });
    this.fetchData();
    reaction(
      () => this.schoolYearStore.currentSchoolYear,
      (newSchoolYear: SchoolYearDto | undefined) => {
        this.fetchStudentOverviewDataBySchoolyear(newSchoolYear?.id);
      },
    );
  }

  @action
  async fetchData() {
    await this.fetchStudentOverviewDataBySchoolyear(this.schoolYearStore.currentSchoolYear?.id);
  }

  @action
  async fetchStudentOverviewDataBySchoolyear(schoolyearId: number | undefined) {
    StudentViewApi.getStudentsOverview(schoolyearId)
      .then((response) => {
        this._students = response.data.students;
        this._schoolyearClassInfos = response.data.schoolyears;
        this.setSchoolyearFilterValue(schoolyearId);
        this.resetClassesFilterValue();
        this.setIsDataLoading(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessageFromResponseError(error);
        this._notificationStore.error({
          title: t('general.studentsCouldNotBeLoaded'),
          message: errorMessage,
        });
      });
  }

  @action
  resetClassesFilterValue() {
    this._filterValues.set('class-filter', undefined);
  }

  @action
  setSchoolyearFilterValue(schoolyearId: number | undefined) {
    if (schoolyearId && schoolyearId === -1) {
      this._filterValues.set('schoolyear-filter', 'all');
    } else if (schoolyearId) {
      this._filterValues.set('schoolyear-filter', String(schoolyearId));
    } else {
      const currentSchoolyear = this._schoolyearClassInfos.filter((sy) => sy.isCurrentSchoolYear)[0].schoolYear;
      this._filterValues.set('schoolyear-filter', String(currentSchoolyear.id));
    }
  }

  @action
  getSchoolYearFilterValue(): SchoolYearDto {
    const selectedSchoolYear = this._filterValues.get('schoolyear-filter');
    let foundSchoolYear;

    if (!selectedSchoolYear || selectedSchoolYear === 'all') {
      foundSchoolYear = this._schoolyearClassInfos.filter((sy) => sy.isCurrentSchoolYear)[0].schoolYear;
    } else {
      const schoolYearClassInfoById = this._schoolyearClassInfos.find(
        (value) => value.schoolYear.id === Number(selectedSchoolYear),
      );
      foundSchoolYear =
        schoolYearClassInfoById?.schoolYear ??
        this._schoolyearClassInfos.filter((sy) => sy.isCurrentSchoolYear)[0].schoolYear;
    }

    return foundSchoolYear;
  }

  @computed
  get filters(): IDeprecatedFilter[] {
    const result: IDeprecatedFilter[] = [];

    const schoolyears: IFilterItem[] = [];

    this._schoolyearClassInfos.forEach((sy) => {
      if (!sy.isCurrentSchoolYear) {
        schoolyears.push({
          id: String(sy.schoolYear.id ?? ''),
          label: sy.schoolYear.name ?? '',
        });
      }
    });

    schoolyears.push({
      id: String('all'),
      label: t('general.motdFilterAllSchoolyears'),
    });

    const classFilterItems: IFilterItem[] = this.classeInfoDtosToFilterItems(this.classes);
    result.push({
      id: 'class-filter',
      placeholder: t('general.allClasses'),
      items: classFilterItems,
    });

    return result;
  }

  @computed
  get classes(): ClassInfoDto[] {
    if (this.selectedSchoolyear && this.selectedSchoolyear.id !== -1) {
      const schoolyearClassInfo = this._schoolyearClassInfos.find(
        (schoolyearClassInfo) => schoolyearClassInfo.schoolYear.id === this.selectedSchoolyear!.id,
      );

      return schoolyearClassInfo ? schoolyearClassInfo.classes : [];
    }
    return [];
  }

  private classeInfoDtosToFilterItems = (dtos: ClassInfoDto[]): IFilterItem[] => {
    return dtos.map((dto) => {
      return {
        id: String(dto.id),
        label: dto.name,
      };
    });
  };

  @action
  handleFilterChange = (
    newFilterItems: DeprecatedFilterBarValue,
    filter: IDeprecatedFilter,
    item: IFilterItem | undefined,
  ) => {
    const newFilterValues = new Map(this._filterValues);
    newFilterValues.set(filter.id, item ? item.id : undefined);
    this._filterValues = newFilterValues;

    if (filter.id === 'schoolyear-filter' && item?.id === 'all') {
      this.fetchStudentOverviewDataBySchoolyear(-1);
    } else if (filter.id === 'schoolyear-filter' && !item?.label) {
      this.fetchStudentOverviewDataBySchoolyear(undefined);
    } else if (filter.id === 'schoolyear-filter') {
      this.fetchStudentOverviewDataBySchoolyear(Number(item?.id));
    }
  };

  @computed
  get selectedClassFilter(): string | undefined {
    return this._filterValues.get('class-filter');
  }

  @computed
  get selectedSchoolyear(): SchoolYearDto | undefined {
    const selectedSchoolyearId: string | undefined = this._filterValues.get('schoolyear-filter');
    if (!selectedSchoolyearId) {
      return undefined;
    }
    const schoolyearClassInfo = this._schoolyearClassInfos.find(
      (dto) => String(dto.schoolYear.id) === selectedSchoolyearId,
    );
    return schoolyearClassInfo ? schoolyearClassInfo.schoolYear : undefined;
  }

  @computed
  get filteredStudents(): StudentDto[] {
    let result: StudentDto[] = this._students;

    if (this.selectedClassFilter) {
      result = result.filter((student) => {
        if (!student.classInfo) {
          return false;
        }
        return String(student.classInfo.id) === this.selectedClassFilter;
      });
    }

    if (this.selectedExitDate) {
      result = result.filter((s: StudentDto): boolean => {
        const mainEntryExitDates = s.entryExitDateInfo?.filter((pair) => pair.isMainRange)[0];
        return dayjs(mainEntryExitDates?.exitDate).isBefore(this.selectedExitDate?.date);
      });
    }

    return result;
  }

  @computed
  get filteredRows(): IStudentRow[] {
    return this.studentRows.filter((student: IStudentRow) =>
      this.containsSearchInput([student.firstName, student.lastName, student.shortName]),
    );
  }

  @computed
  get studentRows(): IStudentRow[] {
    return StudentStore.mapStudentsToRows(this.filteredStudents);
  }

  private static mapStudentsToRows(students: StudentDto[]): IStudentRow[] {
    return students.map((student) => {
      const mainEntryExitDates = student.entryExitDateInfo?.filter((pair) => pair.isMainRange)[0];
      return {
        id: student.id ?? 0,
        key: student.id ?? 0,
        firstName: student.firstName ?? '',
        lastName: student.lastName,
        shortName: student.shortName,
        birthDate: student.birthDate ? dayjs(student.birthDate) : undefined,
        gender: String(student.gender ?? ''),
        className: student.classInfo?.name ?? '',
        catalogueNumber: student.catalogueNumber ?? '',
        active: student.active ?? false,
        entryDate: mainEntryExitDates?.entryDate ? dayjs(mainEntryExitDates?.entryDate) : undefined,
        exitDate: mainEntryExitDates?.exitDate ? dayjs(mainEntryExitDates?.exitDate) : undefined,
        externKey: student.externKey ?? '',
        text: student.text ?? '',
      };
    });
  }

  @computed
  get listViewActions(): IListViewAction[] {
    const actions: IListViewAction[] = [];
    if (this.canCreate) {
      actions.push({
        label: t('general.new'),
        onClick: this.openCreateForm,
        testId: TestIds.STUDENT_NEW,
      });
    }

    actions.push({
      label: t('general.photoImport.header'),
      onClick: () => {
        this._modalStore.openModalDialog({
          title: t('general.photoImport.header'),
          size: 'square-md',
          mask: true,
          showFooterSeparator: true,
          children: <StudentImagesUploadForm />,
          containsForm: true,
        });
      },
      testId: TestIds.STUDENT_IMAGES,
    });
    actions.push({
      label: t('general.report'),
      onClick: () => {
        this.reportStore.openReportsModal(this.reportRows, this.getReportUrl);
      },
      testId: TestIds.STUDENT_REPORT,
    });

    return actions;
  }

  @computed
  get reportRows(): IReportExportingRow[] {
    return [
      {
        key: 'students',
        title: t('general.students'),
        description: t('masterData.students.reports.studentsReportDescription'),
        reportTypes: [ReportType.PDF, ReportType.XLS, ReportType.CSV],
      },
      {
        key: 'studentsWithPictures',
        title: t('masterData.students.reports.studentWithPicturesReportTitle'),
        description: t('masterData.students.reports.studentWithPicturesReportDescription'),
        reportTypes: [ReportType.PDF],
      },
      {
        key: 'studentsWithAddresses',
        title: t('masterData.students.reports.studentWithAddressesReportTitle'),
        description: t('masterData.students.reports.studentWithAddressesReportDescription'),
        reportTypes: [ReportType.XLS],
      },
    ];
  }

  @action.bound
  private getReportUrl(key: string, reportType: ReportType): string {
    const reportParams: IStudentReportParameters = {
      classId: this._filterValues.get('class-filter') ?? -1,
      schoolyearId: this.selectedSchoolyear?.id ?? -1,
      newListPage: true,
    };

    return this.studentReportUrlMapperStore.mapToReportUrl(key, reportType, reportParams);
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<IStudentRow>[] | undefined {
    const actions: IListViewSelectedRowsAction<IStudentRow>[] = [];

    if (this.canDelete) {
      actions.push({
        label: t('general.delete'),
        icon: 'shared_trash',
        onClick: async (rows) => {
          try {
            const studentsToDelete = rows.map((row) => ({ id: row.id, name: row.lastName + ' ' + row.firstName }));
            const studentsIdsToDelete = rows.map((row) => row.id);

            await this._modalStore.openModalDialog({
              children: (
                <DeletionServiceWarning
                  persons={studentsToDelete}
                  isForm={false}
                  type="students"
                  onOk={() => this.onStudentsDeleted(studentsIdsToDelete)}
                />
              ),
              className: 'untis-deletion-warning',
              size: 'md',
            });
          } catch (error) {
            const axiosError: AxiosError = error as AxiosError;
            if (axiosError.response?.status === 500) {
              this._notificationStore.error({
                title: t('general.someStudentsCouldNotBeDeleted'),
                message: t('general.someStudentsCouldNotBeDeleted'),
              });
            } else {
              this._notificationStore.error({
                title: error.toString(),
              });
            }
          }
        },
      });
    }

    if (this.canEdit) {
      actions.push({
        label: t('general.setExitDate'),
        icon: 'exit_date',
        onClick: async (rows) => {
          await this._modalStore
            .openModalDatePicker(undefined, t('general.setExitDate'), undefined, true)
            .then((result) => {
              if (!result.cancelled && result.value) {
                this.updateExitDate(dayjsFormatWithoutTimeDeprecated(result.value), rows);
              }
            });
        },
      });

      actions.push({
        label: t('general.deleteExitDate'),
        icon: 'cancel-circle',
        onClick: async (rows) => {
          await this._modalStore
            .booleanUserPrompt({
              size: 'sm',
              title: t('general.studentsExitDateDeletion'),
              okButton: {
                label: t('general.yes'),
              },
              cancelButton: {
                label: t('general.cancel'),
              },
            })
            .then((result) => {
              if (result) {
                this.updateExitDate(undefined, rows);
              }
            });
        },
      });
    }

    return actions;
  }

  private updateExitDate(exitDate: string | undefined, studentsIdsToUpdate: IStudentRow[]) {
    StudentViewApi.updateStudentsExitDate(
      studentsIdsToUpdate.map((row) => row.id),
      exitDate,
    )
      .then(() => {
        this._notificationStore.success({
          title: t('general.studentsExitDateSet'),
        });
        this.fetchStudentOverviewDataBySchoolyear(this.selectedSchoolyear?.id);
        this.setSelectedRowKeys([]);
      })
      .catch((error) => {
        this._notificationStore.error({
          title: error.toString(),
        });
      });
  }

  @action
  async onCustomClick() {
    await this._modalStore
      .openModalDatePicker(
        this.selectedExitDate ? this.selectedExitDate.date : undefined,
        t('general.exitDateUntil'),
        undefined,
        false,
      )
      .then((result) => {
        if (result.value) {
          this.setSelectedExitDate({
            option: DateOptionEnum.USER_SELECTION,
            date: result.value,
          });
        }
      });
  }

  @action
  async onUndoSelection() {
    this.setSelectedExitDate(undefined);
  }

  @action
  async deleteStudent(id: number, firstName: string, lastName: string) {
    const studentToDelete = { id: id, name: lastName + ' ' + firstName };
    this._modalStore.deprecatedOpenModalDialog({
      content: (
        <DeletionServiceWarning
          persons={[studentToDelete]}
          isForm={false}
          type="students"
          onOk={() => {
            this.onStudentsDeleted([id]);
            this._modalStore.closeModal();
          }}
        />
      ),
      className: 'untis-deletion-warning',
      size: 'sm',
      closable: true,
    });
  }

  @computed
  get columns(): Columns<IStudentRow> {
    const cols: Columns<IStudentRow> = [
      {
        type: ColumnType.Text,
        key: 'lastName',
        header: t('general.lastname'),
        sorter: (a, b) => defaultSorting(a.lastName, b.lastName),
      },
      {
        type: ColumnType.Text,
        key: 'firstName',
        header: t('general.firstname'),
        sorter: (a, b) => defaultSorting(a.firstName, b.firstName),
      },
      {
        type: ColumnType.Date,
        key: 'birthDate',
        header: t('general.birthDate'),
        sorter: (a, b) => sortByDateOrString(a.birthDate, b.birthDate, a.lastName, b.lastName),
      },
      {
        type: ColumnType.Icon,
        key: 'gender',
        header: t('general.gender'),
        getIcon: (row) => row.gender.toLowerCase(),
        sorter: (a, b) => defaultSorting(a.gender, b.gender),
      },
      {
        type: ColumnType.Text,
        key: 'className',
        header: t('general.class'),
        sorter: (a, b) => defaultSorting(a.className, b.className),
      },
      {
        type: ColumnType.Text,
        key: 'catalogueNumber',
        header: t('general.catalogueNumber'),
        sorter: (a, b) => defaultSorting(a.catalogueNumber, b.catalogueNumber),
      },
      {
        type: ColumnType.Boolean,
        key: 'active',
        header: t('general.active'),
        sorter: (a, b) => booleanSorting(a.active, b.active),
      },
      {
        type: ColumnType.Text,
        key: 'shortName',
        header: t('general.shortName'),
        sorter: (a, b) => defaultSorting(a.shortName, b.shortName),
      },
      {
        type: ColumnType.Date,
        key: 'entryDate',
        header: t('general.entryDate'),
        sorter: (a, b) => sortByDateOrString(a.entryDate, b.entryDate, a.lastName, b.lastName),
      },
      {
        type: ColumnType.Date,
        key: 'exitDate',
        header: t('general.exitDay'),
        sorter: (a, b) => sortByDateOrString(a.exitDate, b.exitDate, a.lastName, b.lastName),
      },
      {
        type: ColumnType.Text,
        key: 'externKey',
        header: t('general.externalId'),
        sorter: (a, b) => defaultSorting(a.externKey, b.externKey),
      },
      {
        type: ColumnType.Text,
        key: 'text',
        header: t('general.text'),
        sorter: (a, b) => defaultSorting(a.text, b.text),
      },
    ];

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.OverlayHoverActions,
        key: 'actions',
        header: t('general.actions'),
        actionIcons: this.columnActions,
        align: 'center',
      });
    }

    return cols;
  }

  @action
  onCustomSortSave(order: CustomOrderDto[]) {
    StudentViewApi.createCustomOrderForStudents(order).then(() => {
      this.fetchStudentOverviewDataBySchoolyear(this.selectedSchoolyear?.id);
    });
  }

  @action
  onCustomSortDeletion() {
    StudentViewApi.deleteCustomOrderForStudents().then(() => {
      this.fetchStudentOverviewDataBySchoolyear(this.selectedSchoolyear?.id);
    });
  }

  @computed
  get selectedExitDate(): IDate | undefined {
    return this._selectedExitDate;
  }

  @action
  setSelectedExitDate(value: IDate | undefined) {
    this._selectedExitDate = value;
  }

  @action
  onStudentCreated(student: StudentDto) {
    this._students = [...this._students, student];
  }

  @action
  onStudentUpdated(student: StudentDto) {
    this._students = this._students.map((s) => (s.id === student.id ? student : s));
  }

  @action
  onStudentClassUpdated(studentId: number, classInfo: ClassInfoDto) {
    const studentDto = this._students.find((student) => student.id === studentId);
    if (studentDto) {
      studentDto.classInfo = classInfo;
    }
  }

  @action
  onStudentsDeleted(deletedStudentIds: number[]) {
    this._students = this._students.filter((s) => s.id && !deletedStudentIds.includes(s.id));
  }

  @computed get filterValues() {
    return this._filterValues;
  }

  @computed
  private get columnActions() {
    const actions = [
      { tooltip: t('general.edit'), onClick: (row: IStudentRow) => this.openEditForm(row), icon: 'edit' },
    ];

    if (this.canDelete) {
      actions.push({
        tooltip: t('general.delete'),
        onClick: (row: IStudentRow) => this.deleteStudent(row.id, row.firstName, row.lastName),
        icon: 'shared_trash',
      });
    }

    return actions;
  }

  @action.bound
  async openCreateForm() {
    this.form.resetFields();
    const classesForCurrentSchoolyear =
      this._schoolyearClassInfos.filter((s) => s.isCurrentSchoolYear)[0].classes ?? [];

    try {
      const response = await StudentViewApi.getStudentForm(-1);
      const studentImageResponse = await ImageViewApi.getImage(Number(ElementType.STUDENT), -1, {
        responseType: 'blob',
      });
      await this._modalStore.openModalDialog({
        children: (
          <StudentForm
            store={this}
            isEditMode={false}
            studentFormDto={response.data}
            studentImage={URL.createObjectURL(studentImageResponse.data)}
            classes={classesForCurrentSchoolyear}
          />
        ),
        title: t('general.newStudent'),
        size: 'full-size',
        containsForm: true,
      });
    } catch (error) {
      this._notificationStore.error({ title: t('general.studentCouldNotBeCreated'), message: error.toString() });
    }
  }

  @action.bound
  async openEditForm(row: IStudentRow) {
    this.form.resetFields();
    const classesForCurrentSchoolyear =
      this._schoolyearClassInfos.filter((s) => s.isCurrentSchoolYear)[0].classes ?? [];

    try {
      const response = await StudentViewApi.getStudentForm(row.id);
      const studentImageResponse = await ImageViewApi.getImage(Number(ElementType.STUDENT), row.id, {
        responseType: 'blob',
      });
      await this._modalStore.openModalDialog({
        children: (
          <StudentForm
            store={this}
            isEditMode
            studentFormDto={response.data}
            studentImage={URL.createObjectURL(studentImageResponse.data)}
            classes={classesForCurrentSchoolyear}
            schoolYearClassInfo={this._schoolyearClassInfos}
            onStudentClassUpdated={(studentId, classInfo) => this.onStudentClassUpdated(studentId, classInfo)}
          />
        ),
        title: t('general.editStudent'),
        size: 'full-size',
        containsForm: true,
      });
    } catch (error) {
      this._notificationStore.error({ title: t('general.studentCouldNotBeUpdated'), message: error.toString() });
    }
  }
}
