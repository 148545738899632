import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import Seperator from '@mg/components/mg-seperator/mg-seperator';

import './message-date-time.less';

interface IProps {
  dateTime: string;
  showBoth?: boolean; // describes if the component should show both: the date and the time
}

const MessageDateTime = ({ dateTime, showBoth }: IProps) => {
  const dayjsDateTime = useMemo(() => dayjs(dateTime), [dateTime]);
  const isSameDay = useMemo(() => {
    return dayjsDateTime.isSame(dayjs(), 'day');
  }, [dayjsDateTime]);

  if (showBoth) {
    return (
      <div className="message-date-time">
        {dayjsDateTime.format('L')}
        <Seperator />
        {dayjsDateTime.format('LT')}
      </div>
    );
  }

  return (
    <b className="message-date-time">{isSameDay ? dayjsDateTime.format('HH:mm') : dayjsDateTime.format('DD. MMM')}</b>
  );
};
export default MessageDateTime;
