import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Resizer from 'react-image-file-resizer';
import clsx from 'clsx';

import { Button, Icon } from '@/ui-components';
import { IFormItemProps, WUFormItem } from '@/ui-components/wu-form/wu-form-item';

import './form-file-upload.less';

interface IFormImageUploadProps extends IFormItemProps<File> {
  value?: File;
  variant: 'image' | 'zip';
  disabled?: boolean;
  onClick?: () => void;
  onError?: (error: string | undefined) => void;
}

interface IFormImageUploadWrapperProps extends IFormImageUploadProps {
  value?: File;
  onChange?: (value: File | undefined) => void;
}

export const FormImageUploadWrapper = (props: IFormImageUploadWrapperProps) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const IMAGE_MAX_SIZE = 4194304; // 4MB
  const ZIP_MAX_SIZE = 104857600; // 100MB
  const MIN_IMAGE_HEIGHT_WIDTH = 50; // 50px

  const [file, setFile] = useState<File | undefined>(props.initialValue);
  const [image, setImage] = useState(props.initialValue ?? '');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    props.onClick && props.onClick();
    const files = e.target.files;
    await handleFile(files);
  };

  const onFileDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    props.onClick && props.onClick();
    const files = e.dataTransfer.files;
    await handleFile(files);
  };

  const handleFile = async (files: FileList | null) => {
    if (files && files[0]) {
      const newFile = files[0];

      const error = props.variant === 'image' ? validateImageFile(newFile) : validateZipFile(newFile);
      if (error) {
        setHasError(true);
        props.onError && props.onError(error);
        setLoading(false);
        return;
      }

      setHasError(false);
      props.onError && props.onError(undefined);

      if (props.variant === 'image') {
        setImage(await resizeAndValidateImageDimensions(newFile));
      }
      setFile(newFile);
      props.onChange && props.onChange(newFile);
    }
    setLoading(false);
  };

  const validateZipFile = (file: File): string | undefined => {
    if (file.type != 'application/x-zip-compressed') {
      return t('general.invalidFileFormat');
    }

    if (file.size > ZIP_MAX_SIZE) {
      return t('general.exceededSize') + ' (max ' + (ZIP_MAX_SIZE / 1048576).toFixed(0) + 'MB)';
    }
  };

  const validateImageFile = (file: File): string | undefined => {
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      return t('general.invalidFileFormat');
    }

    if (file.size > IMAGE_MAX_SIZE) {
      return t('general.exceededSize') + ' (max ' + (IMAGE_MAX_SIZE / 1048576).toFixed(0) + 'MB)';
    }
  };

  const resizeAndValidateImageDimensions = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        file.type === 'image/jpeg' ? 'JPEG' : 'PNG',
        100,
        0,
        (uri: string | Blob | File | ProgressEvent<FileReader>) => {
          const img = new Image();
          img.onload = () => {
            if (img.height < MIN_IMAGE_HEIGHT_WIDTH || img.width < MIN_IMAGE_HEIGHT_WIDTH) {
              setHasError(true);
              props.onError && props.onError(t('general.minimumImageSize') + ' ' + MIN_IMAGE_HEIGHT_WIDTH + 'px');
              setLoading(false);
            } else {
              resolve(uri);
            }
          };

          if (typeof uri === 'string') {
            img.src = uri;
          }
        },
        'base64',
      );
    });

  const onUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onDeleteFile = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.variant === 'image') {
      const file = new File(['avatar-default'], '/assets/images/avatar-default.png', { type: 'image/png' });
      setImage('/assets/images/avatar-default.png');
      props.onChange && props.onChange(file);
    } else {
      setFile(undefined);
      props.onChange && props.onChange(undefined);
    }
    setHasError(false);
    props.onError && props.onError(undefined);
    setLoading(false);
  };

  const getFileMaxSize = () => {
    return ' (max. ' + ((props.variant === 'image' ? IMAGE_MAX_SIZE : ZIP_MAX_SIZE) / 1048576).toFixed(0) + 'MB)';
  };

  const uploadedFileClassName = () => {
    return clsx('uploaded-filename', {
      'image-filename': props.variant === 'image',
      'zip-filename': props.variant === 'zip',
    });
  };

  return (
    <div
      className="form-file-upload-container"
      onDragEnter={onFileDrop}
      onDragLeave={onFileDrop}
      onDragOver={onFileDrop}
      onDrop={onFileDrop}
    >
      {image && (
        <span className="form-image">
          <img src={image} alt="picture" />
        </span>
      )}
      <span className="form-file-upload-button-wrapper">
        <input
          ref={fileInputRef}
          type="file"
          onClick={(event) => {
            event.currentTarget.value = '';
          }} // to allow uploading same picture
          onChange={onFileChange}
          multiple={false}
        />
        <Button
          className="form-file-upload-button drag-active"
          onClick={onUploadClick}
          loading={loading}
          outline
          disabled={props.disabled}
          size="small"
        >
          <Icon type="attachment" />
          {props.variant === 'image' && (
            <div>
              <div className="upload-file-text">Upload Image</div>
              <div className="max-file-size-tip">{getFileMaxSize()}</div>
            </div>
          )}
          {props.variant === 'zip' && !file && <span> {'Upload Zip File' + getFileMaxSize()} </span>}
          {props.variant === 'zip' && file && <div className={uploadedFileClassName()}> {file.name} </div>}
          {file && <Icon className="delete-icon" type="cancel-circle-small" onClick={onDeleteFile} />}
          {props.variant === 'zip' && file && !hasError && <Icon className="success-icon" type="activation" />}
        </Button>
      </span>
    </div>
  );
};

export const FormFileUpload = (props: IFormImageUploadProps) => {
  return (
    <WUFormItem {...props}>
      <FormImageUploadWrapper {...props} />
    </WUFormItem>
  );
};
