import React from 'react';
import './calendar-header.less';

interface IProps {
  title: string;
  testId?: string;
}

export const CalendarHeader = (props: IProps) => {
  const testId = props.testId ? `${props.testId}-title` : undefined;
  return (
    <div className="calendar-header" data-testid={testId}>
      {props.title}
    </div>
  );
};
