import { action, computed, observable } from 'mobx';
import { t } from 'i18next';

import { inject } from '@/types/store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { TimetableSettingsViewApi } from '@/stores/api-store';
import { TimetableSettingsDto } from '@untis/wu-rest-view-api/api';

export interface ITimetableGeneralSettingsFormData {
  generalSettings: string[];
}

export const SHOW_DEPARTMENT_SELECTION = 'showDepartmentSelection';
export const SHOW_DETAILS_FOR_PUBLIC = 'showDetailsForPublic';

class TimetableGeneralSettingsStore {
  @observable protected _notificationStore: NotificationStore = inject(NotificationStore);
  @observable private _timetableSettings: TimetableSettingsDto | undefined;
  @observable private _submitDisabled: boolean = true;
  @observable private _isDataLoading: boolean = true;

  @computed
  get isDataLoading(): boolean {
    return this._isDataLoading;
  }

  @computed
  get timetableSettings(): TimetableSettingsDto | undefined {
    return this._timetableSettings;
  }

  @computed
  get submitAllDisabled(): boolean {
    return this._submitDisabled;
  }

  @action
  async fetchData() {
    this.setIsDataLoading(true);
    try {
      const response = await TimetableSettingsViewApi.getTimetableSettings();
      this._timetableSettings = response.data ?? {};
    } catch (error) {
      this._notificationStore.error({
        title: '',
        message: error.toString(),
      });
    } finally {
      this.setIsDataLoading(false);
    }
  }

  @action
  async handleUpdateGeneralSettings(form: ITimetableGeneralSettingsFormData) {
    const timetableSettingsDto = this.formTimetableSettingsToTimetableSettingsDto(form);
    try {
      await TimetableSettingsViewApi.updateTimetableSettings(timetableSettingsDto);
      this._timetableSettings = timetableSettingsDto;
      this._notificationStore.success({ title: t('general.successfullySaved') });
      this.setSubmitDisabled(true);
    } catch (error) {
      this._notificationStore.error({
        title: '',
        message: error.toString(),
      });
    }
  }

  formTimetableSettingsToTimetableSettingsDto(form: ITimetableGeneralSettingsFormData): TimetableSettingsDto {
    return {
      showDepartmentSelection: form.generalSettings.includes(SHOW_DEPARTMENT_SELECTION),
      showDetailsForPublic: form.generalSettings.includes(SHOW_DETAILS_FOR_PUBLIC),
    };
  }

  @action
  setIsDataLoading(value: boolean) {
    this._isDataLoading = value;
  }

  @action
  setSubmitDisabled(disabled: boolean) {
    this._submitDisabled = disabled;
  }
}

export default TimetableGeneralSettingsStore;
