import { RefObject, useCallback, useEffect, useState } from 'react';

import { ScrollerStore } from '@/ui-components/scroller/scroller-store';

const createIntersectionObserver = (callback: (entries: IntersectionObserverEntry[]) => void) => {
  return new IntersectionObserver((entries) => callback(entries), {
    threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  });
};

interface IProps {
  intersectedRef: RefObject<HTMLDivElement>;
  store: ScrollerStore;
  container: RefObject<HTMLElement>;
  onScrolled?: () => any;
  disableScroll?: boolean;
}

/**
 * Scroller hook handling the user's scrolling by using Intersection Observer API.
 * @param props
 */
export default (props: IProps) => {
  const { store, disableScroll, onScrolled, container, intersectedRef } = props;
  const [lastIntersectionRatio, setLastIntersectionRatio] = useState(0);

  const intersectionCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (disableScroll || !onScrolled) {
        return;
      }
      entries.forEach((entry) => {
        const scrolled = entry.intersectionRatio > lastIntersectionRatio;
        store.setLevel(Math.floor(entry.intersectionRatio * 10));
        if (entry.intersectionRatio >= 0.9 && scrolled) {
          store.callCallback(onScrolled, container);
        }
        setLastIntersectionRatio(entry.intersectionRatio);
      });
    },
    [store, lastIntersectionRatio, container, onScrolled, disableScroll],
  );

  const [observer, setObserver] = useState<IntersectionObserver>(() =>
    createIntersectionObserver(intersectionCallback),
  );

  useEffect(() => {
    setObserver(createIntersectionObserver(intersectionCallback));
  }, [lastIntersectionRatio, setLastIntersectionRatio, intersectionCallback]);

  useEffect(() => {
    if (intersectedRef.current) {
      observer.observe(intersectedRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [intersectedRef, observer]);
};
