import React from 'react';
import { action, computed, observable } from 'mobx';
import { t } from 'i18next';

import TimetableAnalyticsStore from '../analytics/timetable-analytics-store';

import { inject, Store } from '@/types/store';
import { TimetableIntegrationStore } from '@te/standard/stores/integration/timetable-integration-store';
import { TimetableEntriesSettingsDto } from '@untis/wu-rest-view-api';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import ConfigStore from '@/stores/config-store';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import ModalStore from '@/stores/modal-store';
import { IComplexOption, IComplexOptionGroup } from '@/ui-components/complex-option-popover/complex-option-popover';
import { TimetableEntityType } from '@te/standard/stores/timetable-root-store';
import ICalSubscriptionForm from '@te/standard/components/ical/i-cal-subscription-form';

export type TimetableLegendFilterType = 'cancellation' | 'exam' | 'change' | 'external';
const TIMETABLE_LEGEND_FILTER_ORDER: TimetableLegendFilterType[] = ['cancellation', 'exam', 'change', 'external'];

export enum TimetableSettingTypeEnum {
  CALENDAR_SETTING = 'CALENDAR_SETTING',
  LAYERS = 'LAYERS',
}

export enum TimetableSettingEnum {
  SHOW_SYMBOLS = 'SHOW_SYMBOLS',
  SHOW_TEACHER_ABSENCES = 'SHOW_TEACHER_ABSENCES',
  SHOW_STUDENT_ABSENCES = 'SHOW_STUDENT_ABSENCES',
  SHOW_ROOM_LOCKS = 'SHOW_ROOM_LOCKS',
  SHOW_RESOURCE_LOCKS = 'SHOW_RESOURCE_LOCKS',
}

export interface ITimetableSetting {
  type: TimetableSettingTypeEnum;
  setting: TimetableSettingEnum;
  label: string;
  active: boolean;
  visible: boolean;
}

const SHOW_TEACHER_ABSENCES_TYPES: TimetableEntityType[] = ['teacher', 'my-teacher'];
const SHOW_STUDENT_ABSENCES_TYPES: TimetableEntityType[] = ['student', 'my-student'];

@Store()
export class TimetableLegendFilterStore {
  private configStore: ConfigStore = inject(ConfigStore);
  private rightsStore: RightsStore = inject(RightsStore);
  private modalStore: ModalStore = inject(ModalStore);
  private timetableIntegrationStore: TimetableIntegrationStore = inject(TimetableIntegrationStore);
  private timetableFormatStore: TimetableFormatStore = inject(TimetableFormatStore);
  private timetableAnalyticsStore: TimetableAnalyticsStore = inject(TimetableAnalyticsStore);

  @observable private _showICal = false;
  @observable private _controlledIsPopoverOpen: boolean | undefined;
  @observable private _timetableSettings: ITimetableSetting[] = [];
  @observable private _selectedLegendFilters: TimetableLegendFilterType[] = [];
  @observable private _previousTimetableEntriesSettings: TimetableEntriesSettingsDto | undefined = undefined;
  @observable private _printHandler: any;

  @action
  reset() {
    this._showICal = false;
    this._controlledIsPopoverOpen = undefined;
    this._timetableSettings = [];
    this._selectedLegendFilters = [];
    this._previousTimetableEntriesSettings = undefined;
  }

  @action
  init(timetableEntriesSettings: TimetableEntriesSettingsDto) {
    this.reset();
    this.initTimetableSettings(timetableEntriesSettings);
    this.initSelectedLegendFilters(timetableEntriesSettings);
  }

  @action
  private initTimetableSettings(timetableEntriesSettings: TimetableEntriesSettingsDto) {
    this._showICal = timetableEntriesSettings.showICal;
    this._previousTimetableEntriesSettings = timetableEntriesSettings;
    this._timetableSettings.push({
      type: TimetableSettingTypeEnum.CALENDAR_SETTING,
      setting: TimetableSettingEnum.SHOW_SYMBOLS,
      label: t('timetable.legend.showSymbols'),
      active: timetableEntriesSettings.showSymbols,
      visible: true,
    });

    this._timetableSettings.push({
      type: TimetableSettingTypeEnum.LAYERS,
      setting: TimetableSettingEnum.SHOW_TEACHER_ABSENCES,
      label: t('general.absences'),
      active: timetableEntriesSettings.showTeacherAbsences,
      visible:
        !!this.timetableFormatStore.timetableEntityType &&
        SHOW_TEACHER_ABSENCES_TYPES.includes(this.timetableFormatStore.timetableEntityType) &&
        this.rightsStore.canRead(Right.TEACHER_ABSENCE_VIEW_RESTRICTED, ElementType.ALL, false),
    });

    this._timetableSettings.push({
      type: TimetableSettingTypeEnum.LAYERS,
      setting: TimetableSettingEnum.SHOW_STUDENT_ABSENCES,
      label: t('general.absences'),
      active: timetableEntriesSettings.showStudentAbsences,
      visible:
        !!this.timetableFormatStore.timetableEntityType &&
        SHOW_STUDENT_ABSENCES_TYPES.includes(this.timetableFormatStore.timetableEntityType),
    });

    this._timetableSettings.push({
      type: TimetableSettingTypeEnum.LAYERS,
      setting: TimetableSettingEnum.SHOW_ROOM_LOCKS,
      label: t('general.roomLocks'),
      active: timetableEntriesSettings.showRoomLocks,
      visible: this.timetableFormatStore.timetableEntityType === 'room',
    });

    this._timetableSettings.push({
      type: TimetableSettingTypeEnum.LAYERS,
      setting: TimetableSettingEnum.SHOW_RESOURCE_LOCKS,
      label: t('general.resourceBlockings'),
      active: timetableEntriesSettings.showResourceLocks,
      visible: this.timetableFormatStore.timetableEntityType === 'resource',
    });
  }

  @action
  private initSelectedLegendFilters(timetableEntriesSettings: TimetableEntriesSettingsDto) {
    timetableEntriesSettings.highlightExams && this._selectedLegendFilters.push('exam');
    timetableEntriesSettings.highlightChanges && this._selectedLegendFilters.push('change');
    timetableEntriesSettings.highlightCancellations && this._selectedLegendFilters.push('cancellation');
    timetableEntriesSettings.highlightExternalEntries && this._selectedLegendFilters.push('external');
  }

  @computed
  get selectedLegendFilters(): TimetableLegendFilterType[] {
    const result: TimetableLegendFilterType[] = [];
    this._selectedLegendFilters.includes('change') && result.push('change');
    this._selectedLegendFilters.includes('exam') && result.push('exam');
    this.timetableFormatStore.timetableFormat?.showCancellations &&
      this._selectedLegendFilters.includes('cancellation') &&
      result.push('cancellation');
    this.timetableIntegrationStore.enabledExternalIntegrations.length > 0 &&
      this._selectedLegendFilters.includes('external') &&
      result.push('external');
    return result;
  }

  @computed
  get hasSelectedLegendFilters(): boolean {
    return this.selectedLegendFilters.length > 0;
  }

  public getTimetableLegendFilterOrder(type: TimetableLegendFilterType) {
    return TIMETABLE_LEGEND_FILTER_ORDER.indexOf(type);
  }

  @action
  toggleLegendFilter(value: TimetableLegendFilterType) {
    this._previousTimetableEntriesSettings = this.timetableEntriesSettings;
    if (this._selectedLegendFilters.includes(value)) {
      this._selectedLegendFilters = this._selectedLegendFilters.filter((legendFilter) => legendFilter !== value);
      this.timetableAnalyticsStore.trackToggle(
        'TimetableToolbarClick',
        this.timetableFormatStore.timetableEntityType,
        value,
        'off',
      );
    } else {
      this._selectedLegendFilters.push(value);
      this.timetableAnalyticsStore.trackToggle(
        'TimetableToolbarClick',
        this.timetableFormatStore.timetableEntityType,
        value,
        'on',
      );
    }
  }

  @computed
  get previousTimetableEntriesSettings(): TimetableEntriesSettingsDto | undefined {
    return this._previousTimetableEntriesSettings;
  }

  @computed
  get timetableEntriesSettings(): TimetableEntriesSettingsDto {
    return {
      showSymbols: this.hasSelectedTimetableSetting(TimetableSettingEnum.SHOW_SYMBOLS),
      showTeacherAbsences: this.hasSelectedTimetableSetting(TimetableSettingEnum.SHOW_TEACHER_ABSENCES),
      showStudentAbsences: this.hasSelectedTimetableSetting(TimetableSettingEnum.SHOW_STUDENT_ABSENCES),
      showRoomLocks: this.hasSelectedTimetableSetting(TimetableSettingEnum.SHOW_ROOM_LOCKS),
      showResourceLocks: this.hasSelectedTimetableSetting(TimetableSettingEnum.SHOW_RESOURCE_LOCKS),
      highlightExams: this._selectedLegendFilters.includes('exam'),
      highlightChanges: this._selectedLegendFilters.includes('change'),
      highlightCancellations: this._selectedLegendFilters.includes('cancellation'),
      highlightExternalEntries: this._selectedLegendFilters.includes('external'),
      showICal: this._showICal,
    };
  }

  private hasSelectedTimetableSetting(timetableSettingEnum: TimetableSettingEnum): boolean {
    return this._timetableSettings.find((setting) => setting.setting === timetableSettingEnum)?.active ?? false;
  }

  @computed
  get enabledTimetableSettings(): TimetableSettingEnum[] {
    return this._timetableSettings
      .filter((timetableSetting) => timetableSetting.active && timetableSetting.visible)
      .map((timetableSetting) => timetableSetting.setting);
  }

  @action.bound
  toggleTimetableSetting(setting: TimetableSettingEnum) {
    this._previousTimetableEntriesSettings = this.timetableEntriesSettings;
    this._timetableSettings.forEach((timetableSetting) => {
      if (timetableSetting.setting === setting) {
        timetableSetting.active = !timetableSetting.active;
        this.timetableAnalyticsStore.trackToggle(
          'TimetableToolbarClick',
          this.timetableFormatStore.timetableEntityType,
          setting,
          timetableSetting.active ? 'on' : 'off',
        );
      }
    });
  }

  @action
  setControlledIsPopoverOpen(controlledIsPopoverOpen: boolean | undefined) {
    this._controlledIsPopoverOpen = controlledIsPopoverOpen;
  }

  @computed
  get controlledIsPopoverOpen(): boolean | undefined {
    return this._controlledIsPopoverOpen;
  }

  @computed
  get complexPopoverOptionGroups(): IComplexOptionGroup[] {
    const result: IComplexOptionGroup[] = [];

    this.addCalendarSettings(result);
    this.addLayersSettings(result);
    this.addExternalIntegrations(result);
    this.addTimetableFormats(result);
    this.addOther(result);

    return result;
  }

  private addCalendarSettings(result: IComplexOptionGroup[]) {
    this.addFromTimetableSettings(
      result,
      TimetableSettingTypeEnum.CALENDAR_SETTING,
      'calendar-settings',
      'timetable.legend.calendarSettings',
    );
  }

  private addLayersSettings(result: IComplexOptionGroup[]) {
    this.addFromTimetableSettings(result, TimetableSettingTypeEnum.LAYERS, 'layers', 'timetable.legend.layers');
  }

  private addFromTimetableSettings(
    result: IComplexOptionGroup[],
    settingType: TimetableSettingTypeEnum,
    key: string,
    translationKey: string,
  ) {
    const options: IComplexOption[] = [];
    const selectedKeys: string[] = [];
    this._timetableSettings.forEach((setting) => {
      if (setting.type === settingType && setting.visible) {
        if (setting.active) {
          selectedKeys.push(setting.setting.toString());
        }
        options.push({
          key: setting.setting.toString(),
          label: setting.label,
          onClick: () => this.toggleTimetableSetting(setting.setting),
        });
      }
    });

    if (options.length > 0) {
      result.push({
        key: key,
        label: t(translationKey),
        options: options,
        multiple: true,
        selectedKey: selectedKeys,
      });
    }
  }

  private addExternalIntegrations(result: IComplexOptionGroup[]) {
    if ((this.timetableIntegrationStore.externalCalendarIntegrations?.integrations?.length ?? 0) > 0) {
      const options: IComplexOption[] = [];
      const selectedKeys: string[] = [];
      this.timetableIntegrationStore.externalCalendarIntegrations?.integrations?.forEach((integration) => {
        if (integration.active) {
          selectedKeys.push(integration.name);
        }
        options.push({
          key: integration.name,
          label: integration.name,
          onClick: () => this.timetableIntegrationStore.toggleExternalIntegration(integration.name),
        });
      });

      result.push({
        key: 'external-calendars',
        label: t('general.externalCalendars'),
        options: options,
        multiple: true,
        selectedKey: selectedKeys,
      });
    }
  }

  private addTimetableFormats(result: IComplexOptionGroup[]) {
    const isSchoolStaff =
      !this.configStore.isStudent && !this.configStore.isLegalGuardian && !this.configStore.isApprenticeRepresentatives;

    if (isSchoolStaff && this.timetableFormatStore.availableTimetableFormats.length > 1) {
      const options: IComplexOption[] = [];

      this.timetableFormatStore.availableTimetableFormats.forEach((timetableFormat) => {
        options.push({
          key: timetableFormat.id.toString(),
          label: timetableFormat.name,
          onClick: () => this.timetableFormatStore.setSetSelectedTimetableFormatId(timetableFormat.id),
        });
      });

      result.push({
        key: 'timetable-formats',
        label: t('timetable.legend.timetableFormat'),
        options: options,
        selectedKey: this.timetableFormatStore.selectedTimetableFormatId?.toString() ?? '',
      });
    }
  }

  private addOther(result: IComplexOptionGroup[]) {
    const { isMyTimetable } = this.timetableFormatStore;
    const actions = [];
    if (this._showICal && isMyTimetable) {
      actions.push({
        key: 'manage-ical-subscription',
        label: t('general.manageICalSubscription'),
        onClick: this.openICalSubscriptionModal,
      });
    }
    actions.push({
      key: 'print',
      label: t('general.print'),
      onClick: this.print,
    });
    result.push({
      key: 'other',
      label: t('general.other'),
      actions,
    });
  }

  @action.bound
  private openICalSubscriptionModal() {
    this.modalStore.openModalDialog({
      children: <ICalSubscriptionForm />,
      size: 'md',
      title: t('general.manageICalSubscription'),
      containsForm: true,
    });
  }

  @action.bound
  public setPrintHandler(printHandler: any) {
    this._printHandler = printHandler;
  }

  @action.bound
  private print() {
    this._printHandler();
  }
}
