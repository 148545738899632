import React, { useState } from 'react';

import CalendarEntryContent, { ICalendarEntryDetailsData } from './calendar-entry-content';
import PeriodContent, { IPeriodDetailsHeaderPeriod } from './period-content';

import { useOutsideClick } from '@/hooks/useClickOutside';

import './calendar-entry-details-header.less';

export interface IPeriodDetailsData {
  periods: IPeriodDetailsHeaderPeriod[];
  selectedPeriodId: number;
  isBlockSelected: boolean;
  zIndex?: number;
  idParam: string | undefined;
  dateParam: string | undefined;
}

export interface IBaseDropdown {
  list: any[];
  value: any;
}

export interface IProps {
  entryData: IPeriodDetailsData | ICalendarEntryDetailsData;
  handleSelect?: (periodId: number, isBlock: boolean) => void;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  idParam: string | undefined;
  dateParam: string | undefined;
  minimized?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

// https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
function isPeriodData(data: IPeriodDetailsData | ICalendarEntryDetailsData): data is IPeriodDetailsData {
  return (data as IPeriodDetailsData).periods !== undefined;
}

type DropdownSkeletonProps = {
  zIndex?: number;
  isFloating?: boolean;
  onSelect?: (selectedItemId: number, isBlock: boolean) => void;
  entryData: IPeriodDetailsData | ICalendarEntryDetailsData | IBaseDropdown;
  idParam: string | undefined;
  dateParam: string | undefined;
  minimized?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
};

export const withDropdownSkeleton = (WrappedComponent: any) => (props: DropdownSkeletonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { onSelect, entryData, zIndex, isFloating, minimized, onOpen, onClose, idParam, dateParam } = props;
  const ref = useOutsideClick(() => {
    if (isOpen) {
      setIsOpen(false);
      props.onClose && props.onClose();
    }
  });

  const handleSelect = (periodId: number, isBlock: boolean) => {
    setIsOpen(false);
    props.onClose && props.onClose();
    onSelect && onSelect(periodId, isBlock);
  };

  const className = `calendar-entry-details-header ${isOpen ? ' isOpen' : ''} ${isFloating ? 'dd--floating' : ''} ${
    props.minimized ? ' minimized' : ''
  }`;

  const dropdownProps = {
    handleSelect,
    onSelect,
    isOpen,
    setIsOpen,
    entryData,
    minimized,
    onOpen,
    onClose,
    idParam,
    dateParam,
  };
  return (
    <div className={className}>
      <div ref={ref} className="content" style={zIndex ? { zIndex: zIndex } : undefined}>
        <WrappedComponent {...dropdownProps} />
      </div>
    </div>
  );
};

const CalendarEntryDetailsHeader = (props: IProps) => {
  const { isOpen, setIsOpen, handleSelect } = props;

  const createPeriodContent = (data: IPeriodDetailsData) => {
    const isDropdown = data.periods.length > 1 || (data.periods.length === 1 && data.periods[0].parts.length > 1);

    return (
      <PeriodContent
        periods={data.periods}
        idParam={props.idParam}
        dateParam={props.dateParam}
        isBlockSelected={data.isBlockSelected}
        selectedPeriodId={data.selectedPeriodId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isDropdown={isDropdown}
        onSelect={handleSelect}
        minimized={!!props.minimized}
        onOpen={props.onOpen}
        onClose={props.onClose}
      />
    );
  };

  const createCalendarEntryContent = (data: ICalendarEntryDetailsData) => {
    return (
      <CalendarEntryContent
        entryData={data}
        minimized={!!props.minimized}
        idParam={props.idParam}
        dateParam={props.dateParam}
      />
    );
  };

  const content = isPeriodData(props.entryData)
    ? createPeriodContent(props.entryData)
    : createCalendarEntryContent(props.entryData);

  return content;
};

export default withDropdownSkeleton(CalendarEntryDetailsHeader);
