import { observer } from 'mobx-react-lite';
import React from 'react';
import { Form } from 'antd';
import { Route } from 'react-router-dom';

import { ReasonsOfAbsenceStore } from './reasons-of-absence-store';
import { MasterDataReasonsOfAbsenceList } from './reasons-of-absence-list';
import { ReasonsOfAbsenceNew } from './reasons-of-absence-new';
import { ReasonsOfAbsenceEdit } from './reasons-of-absence-edit';

import useStore from '@/hooks/useStore';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';

export const MasterDataReasonsOfAbsence = observer(() => {
  const [form] = Form.useForm<ReasonsOfAbsenceStore>();
  const reasonsOfAbsenceStore = useStore(ReasonsOfAbsenceStore);

  useComponentDidMount(() => {
    reasonsOfAbsenceStore.setFormInstance(form);
  });

  return (
    <>
      <MasterDataReasonsOfAbsenceList />
      <Route path="/reasons-of-absence/new" render={() => <ReasonsOfAbsenceNew />} />
      <Route path="/reasons-of-absence/edit/:id" render={() => <ReasonsOfAbsenceEdit />} />
    </>
  );
});
