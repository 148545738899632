import React, { useEffect, useRef, useState } from 'react';
import './wu-page-iframe.less';
import { useTranslation } from 'react-i18next';

import {
  appendStylesToIframe,
  fetchToken,
  getTypeOfIFrameMessage,
  IFrameHandleMessagePayload,
} from '@/components/embedded-webuntis/embedded-webuntis';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import { useComponentWillUnmount } from '@/hooks/useComponentWillUnmount';
import useStore from '@/hooks/useStore';
import EnvironmentStore from '@/stores/environment-store';
import IframeMessageCallbackStore from '@/stores/iframe-message-callback-store';
import TokenStore from '@/stores/token-store';
import PostMessageStore, { HandleMessageAction } from '@sp/stores/post-message-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { createAutomaticStudentAbsenceNotificationArgs } from '@/utils/notification/notification-util';

interface IProps {
  page: string | null; // null means that the iframe does not navigate to any page and stays where it is.
  showSidebar?: boolean;
  hide?: boolean; // we might want to hide the view so that we can show it again without needing to reload WebUntis
  loadingText?: string;
  img?: string;
  onStartLoad?: () => void;
  onFinishLoad?: () => void;
}

/**
 * In some cases we need a separate iFrame when we need to display legacy WebUntis content in
 * the new frontend (e.g.: embedded wu pages inside of modal dialogs).
 * This component is meant do be used for these cases.
 */
const WuPageIframe = (props: IProps) => {
  const environment = useStore(EnvironmentStore);
  const tokenStore = useStore(TokenStore);
  const postMessageStore = useStore(PostMessageStore);
  const notificationStore = useStore(NotificationStore);
  const iframeMessageCallbackStore = useStore(IframeMessageCallbackStore);
  const iFrame = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRender, setShouldRender] = useState(true);
  const { t } = useTranslation();

  const onFadeOutEnd = () => {
    setShouldRender(false);
  };

  useComponentDidMount(() => {
    props.onStartLoad && props.onStartLoad();
    window.addEventListener('message', handleFrameTasks);
  });

  useComponentWillUnmount(() => {
    window.removeEventListener('message', handleFrameTasks);
  });

  useEffect(() => {
    if (props.page !== null) {
      postMessageStore.postNavigationMessage(iFrame, props.page, true);
    }
  }, [props.page, postMessageStore]);

  const handleFrameTasks = async (e: MessageEvent) => {
    let messageObject: IFrameHandleMessagePayload;
    let type: string;

    try {
      const dataType = typeof e.data;
      if (dataType !== 'object') {
        return;
      }
      messageObject = e.data;
      type = getTypeOfIFrameMessage(messageObject);
    } catch (e) {
      return;
    }

    // We only want to handle events that come from this iframe
    if (
      e.source &&
      'frameElement' in e.source &&
      e.source.frameElement &&
      e.source.frameElement.id !== 'wu-page-iframe'
    ) {
      return;
    }

    const actionType: HandleMessageAction | undefined = (HandleMessageAction as any)[type];
    if (actionType) {
      iframeMessageCallbackStore.handleWuPageIframeMessage(actionType, messageObject);
    }

    // besides the registered actions, also some common actions are required to be handled
    if (actionType === HandleMessageAction.AUTOMATIC_STUDENT_ABSENCE_NOTIFICATIONS_SENT) {
      if (Array.isArray(messageObject.payload.notifications)) {
        await notificationStore.notifyMultiple(
          messageObject.payload.notifications.map((n: any) => {
            return {
              type: n.notificationSentSuccessfully ? 'success' : 'error',
              args: createAutomaticStudentAbsenceNotificationArgs(n.studentName, n.notificationSentSuccessfully),
            };
          }),
        );
      }
    }
  };

  const handleLoad = () => {
    fetchToken(iFrame, tokenStore);

    // the iframe should include a link tag to a css file to overwrite some styles
    appendStylesToIframe(iFrame, '../overwrites.css');
    appendStylesToIframe(iFrame, '../overwrites-embedded-in-modal.css');

    if (props.page !== null) {
      postMessageStore.postNavigationMessage(iFrame, props.page, true);
    }

    // we wait a little bit to give the browser time to apply CSS and then display the page to reduce 'flickering'
    setTimeout(() => {
      setIsLoading(false);
      props.onFinishLoad && props.onFinishLoad();
    }, 250);
  };

  const isEmbeddedInModal = '?isEmbeddedInModal=true';
  const showSidebar = !!props.showSidebar ? '&showSidebar=true' : '';
  const hidingStyleIframe = {
    width: props.hide || isLoading ? '0' : '100%',
    height: props.hide || isLoading ? '0' : '100%',
  };
  const hidingStyleContainer = {
    width: props.hide ? '0' : '100%',
    height: props.hide ? '0' : '100%',
  };
  /* To hide the view we set width and height to 0 instead of using display: none, because otherwise Chrome seems to
     crash if the visibility state toggles before the iframe content has finished loading.
     (RESULT_CODE_INVALID_CMDLINE_URL) */
  return (
    <div className="wu-page-iframe" style={hidingStyleContainer}>
      <iframe
        src={environment.webUntisURL + '/embedded.do' + isEmbeddedInModal + showSidebar}
        ref={iFrame}
        style={hidingStyleIframe}
        onLoad={handleLoad}
        title="WebUntis"
        id="wu-page-iframe"
      />
      <EmptyIndicator
        title={props.loadingText ? props.loadingText : t('general.loading')}
        description=""
        img={props.img}
        animationArgs={{
          onFadeOutEnd,
          shouldRender,
          fadeOut: !isLoading,
        }}
        fullScreen={true}
        white={true}
      />
    </div>
  );
};

export default WuPageIframe;
