import { action, computed, observable, toJS } from 'mobx';
import { isNil } from 'lodash';
import { t } from 'i18next';
import dayjs from 'dayjs';

import {
  ClassFilterDto,
  MasterDataRefDto,
  ResourceFilterDto,
  RoomFilterDto,
  StudentClassDto,
  StudentFilterDto,
  SubjectFilterDto,
  TeacherFilterDto,
  TimetableFilterDto,
} from '@untis/wu-rest-view-api/api';
import { inject, Store } from '@/types/store';
import SchoolYearStore from '@/stores/schoolyear-store';
import { TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import { IDropDownItem } from '@/ui-components/drop-down/drop-down';
import { IFilter } from '@te/standard/components/header/entity-selector/timetable-entity-filter-selector';

type FilterSelectorOption =
  | RoomFilterDto
  | StudentFilterDto
  | ResourceFilterDto
  | SubjectFilterDto
  | ClassFilterDto
  | TeacherFilterDto;

@Store()
export class TimetableFilterStore {
  @observable private _timetableFilterData: TimetableFilterDto | undefined;
  @observable private _selectedFilterValue: number | undefined;

  private schoolYearStore: SchoolYearStore = inject(SchoolYearStore);
  private timetableMetaStore: TimetableMetaStore = inject(TimetableMetaStore);
  private timetableFormatStore: TimetableFormatStore = inject(TimetableFormatStore);

  @action
  init(timetableFilterData: TimetableFilterDto) {
    this._timetableFilterData = timetableFilterData;
    if (this._selectedFilterValue === undefined) {
      this.initSelectedFilterValue(timetableFilterData);
    }
  }

  @action
  reset() {
    this._timetableFilterData = undefined;
    this._selectedFilterValue = undefined;
  }

  @action
  private initSelectedFilterValue(timetableFilterData: TimetableFilterDto) {
    const { timetableEntityType } = this.timetableFormatStore;
    switch (timetableEntityType) {
      case 'subject':
        if (timetableFilterData.subjects && timetableFilterData.subjects.length > 0) {
          this._selectedFilterValue = timetableFilterData.subjects[0].subject.id;
        }
        break;
      case 'class':
      case 'my-class':
        if (timetableFilterData.classes && timetableFilterData.classes.length > 0) {
          this._selectedFilterValue = timetableFilterData.classes[0].class.id;
        }
        break;
      case 'student':
      case 'my-student':
        if (timetableFilterData.students && timetableFilterData.students.length > 0) {
          this._selectedFilterValue = timetableFilterData.students[0].student.id;
        }
        break;
      case 'teacher':
      case 'my-teacher':
        if (timetableFilterData.teachers && timetableFilterData.teachers.length > 0) {
          this._selectedFilterValue = timetableFilterData.teachers[0].teacher.id;
        }
        break;
      case 'room':
        if (timetableFilterData.rooms && timetableFilterData.rooms.length > 0) {
          this._selectedFilterValue = timetableFilterData.rooms[0].room.id;
        }
        break;
      case 'resource':
        if (timetableFilterData.resources && timetableFilterData.resources.length > 0) {
          this._selectedFilterValue = timetableFilterData.resources[0].resource?.id;
        }
        break;
    }
  }

  @computed
  get myTimetableFilterOptions(): IDropDownItem[] {
    const { timetableEntityType } = this.timetableFormatStore;
    if (timetableEntityType) {
      switch (timetableEntityType) {
        case 'my-class':
          return this.myClassFilterItems;
        case 'my-teacher':
          return this.myTeacherFilterItems;
        case 'my-student':
          return this.myStudentFilterItems;
        default:
          return [];
      }
    } else {
      return [];
    }
  }

  @computed
  get filterOptions(): FilterSelectorOption[] {
    const { timetableEntityType } = this.timetableFormatStore;
    if (timetableEntityType) {
      switch (timetableEntityType) {
        case 'room':
          return this._timetableFilterData?.rooms ?? [];
        case 'student':
          return this._timetableFilterData?.students ?? [];
        case 'subject':
          return this._timetableFilterData?.subjects ?? [];
        case 'resource':
          return this._timetableFilterData?.resources ?? [];
        case 'teacher':
          return this._timetableFilterData?.teachers ?? [];
        case 'class':
          return this._timetableFilterData?.classes ?? [];
        default:
          return [];
      }
    } else {
      return [];
    }
  }

  @computed
  get roomFilterSelectorFilters(): IFilter<RoomFilterDto>[] {
    return [
      {
        name: 'ROOM_GROUP',
        placeholder: t('general.roomGroups'),
        filterOptions: toJS(this._timetableFilterData?.roomGroups ?? []),
        filterBy: (room, roomGroupId) => !!(room.roomGroups ?? []).find((rg) => rg.id == roomGroupId),
      },
      {
        name: 'BUILDING',
        placeholder: t('general.buildings'),
        filterOptions: toJS(this._timetableFilterData?.buildings ?? []),
        filterBy: (room, buildingId) => room.building?.id == buildingId,
      },
      {
        name: 'DEPARTMENT',
        placeholder: t('general.departments'),
        filterOptions: toJS(this._timetableFilterData?.departments ?? []),
        filterBy: (room, departmentId) => room.department?.id == departmentId,
      },
    ];
  }

  @computed
  get resourceFilterSelectorFilters(): IFilter<ResourceFilterDto>[] {
    return [
      {
        name: 'RESOURCE_TYPE',
        placeholder: t('general.resourceTypes'),
        filterOptions: toJS(this._timetableFilterData?.resourceTypes ?? []),
        filterBy: (resource, resourceTypeId) => resource.resourceType?.id === resourceTypeId,
      },
      {
        name: 'DEPARTMENT',
        placeholder: t('general.departments'),
        filterOptions: toJS(this._timetableFilterData?.departments ?? []),
        filterBy: (resource, departmentId) => resource.department?.id == departmentId,
      },
    ];
  }

  @computed
  get subjectFilterSelectorFilters(): IFilter<SubjectFilterDto>[] {
    return [
      {
        name: 'DEPARTMENT',
        placeholder: t('general.departments'),
        filterOptions: toJS(this._timetableFilterData?.departments ?? []),
        filterBy: (subject, departmentId) => !!(subject.departments ?? []).find((d) => d.id == departmentId),
      },
    ];
  }

  @computed
  get classFilterSelectorFilters(): IFilter<ClassFilterDto>[] {
    return [
      {
        name: 'DEPARTMENT',
        placeholder: t('general.departments'),
        filterOptions: toJS(this._timetableFilterData?.departments ?? []),
        filterBy: (clazz, departmentId) => clazz.department?.id === departmentId,
      },
    ];
  }

  @computed
  get teacherFilterSelectorFilters(): IFilter<TeacherFilterDto>[] {
    return [
      {
        name: 'DEPARTMENT',
        placeholder: t('general.departments'),
        filterOptions: toJS(this._timetableFilterData?.departments ?? []),
        filterBy: (teacher, departmentId) => !!(teacher.departments ?? []).find((d) => d.id == departmentId),
      },
    ];
  }

  @computed
  get studentFilterSelectorFilters(): IFilter<StudentFilterDto>[] {
    return [
      {
        name: 'CLASS',
        placeholder: t('general.classes'),
        filterOptions: this._timetableFilterData?.classes?.map((filterDto) => filterDto.class) ?? [],
        filterBy: (student, classId) =>
          this.filterStudentByClassAssignment(
            student,
            classId ?? -1,
            (studentClass, classId) => studentClass.class.id === classId,
          ),
      },
      {
        name: 'ASSIGNMENT_GROUP',
        placeholder: t('general.assignmentGroups'),
        filterOptions: toJS(this._timetableFilterData?.assignmentGroups ?? []),
        filterBy: (student, assignmentGroupId) =>
          !!(student.assignmentGroups ?? []).find((ag) => ag.id == assignmentGroupId),
      },
      {
        name: 'DEPARTMENT',
        placeholder: t('general.departments'),
        filterOptions: toJS(this._timetableFilterData?.departments ?? []),
        filterBy: (student, departmentId) =>
          this.filterStudentByClassAssignment(
            student,
            departmentId ?? -1,
            (studentClass, departmentId) => studentClass.department?.id === departmentId,
          ),
      },
    ];
  }

  private filterStudentByClassAssignment(
    student: StudentFilterDto,
    classId: number,
    idMatcher: (studentClass: StudentClassDto, targetId: number) => boolean,
  ): boolean {
    const foundClass = (student.classes ?? []).find((sc) => {
      const notInCurrentSchoolYear =
        !this.schoolYearStore.isDateInCurrentSchoolYear(this.timetableMetaStore.currentTimetableStartDate) &&
        !this.schoolYearStore.isDateInCurrentSchoolYear(this.timetableMetaStore.currentTimetableEndDate);
      if (notInCurrentSchoolYear) {
        return idMatcher(sc, classId);
      } else {
        const isDatesOverlap = !(
          (dayjs(sc.dateRange?.start).isBefore(this.timetableMetaStore.currentTimetableStartDate) &&
            dayjs(sc.dateRange?.end).isBefore(this.timetableMetaStore.currentTimetableStartDate)) ||
          (dayjs(sc.dateRange?.start).isAfter(this.timetableMetaStore.currentTimetableEndDate) &&
            dayjs(sc.dateRange?.end).isAfter(this.timetableMetaStore.currentTimetableEndDate))
        );
        return idMatcher(sc, classId) && isDatesOverlap;
      }
    });

    return foundClass !== undefined;
  }

  getClassTooltipText(klasse: ClassFilterDto): string {
    const classTeacher1 = klasse.classTeacher1;
    const classTeacher2 = klasse.classTeacher2;
    let tooltip = t('timetable.header.class.noClassTeacher');
    if (!isNil(classTeacher1) && !isNil(classTeacher2)) {
      tooltip = t('timetable.header.class.classTeachers', {
        teacherOne: classTeacher1.displayName,
        teacherTwo: classTeacher2.displayName,
      });
    } else if (!isNil(classTeacher1) && isNil(classTeacher2)) {
      tooltip = t('timetable.header.class.classTeacher', {
        teacher: classTeacher1.displayName,
      });
    } else if (isNil(classTeacher1) && !isNil(classTeacher2)) {
      tooltip = t('timetable.header.class.classTeacher', {
        teacher: classTeacher2.displayName,
      });
    }
    return tooltip;
  }

  @computed
  private get myTeacherFilterItems(): IDropDownItem[] {
    return (
      this._timetableFilterData?.teachers?.map((teacher) => {
        return {
          id: teacher.teacher.id.toString(),
          label: teacher.teacher.displayName ?? '',
        };
      }) ?? []
    );
  }

  @computed
  private get myClassFilterItems(): IDropDownItem[] {
    return (
      this._timetableFilterData?.classes?.map((klasse) => {
        return {
          id: klasse.class.id?.toString(),
          label: klasse.class.displayName ?? '',
        };
      }) ?? []
    );
  }

  @computed
  private get myStudentFilterItems(): IDropDownItem[] {
    return (
      this._timetableFilterData?.students?.map((student) => {
        return {
          id: student.student.id.toString(),
          label: student.student.displayName ?? '',
        };
      }) ?? []
    );
  }

  @action
  setSelectedFilterValue(value: number) {
    if (!isNaN(value)) {
      this._selectedFilterValue = value;
    }
  }

  @action
  resetSelectedFilterValue() {
    this._selectedFilterValue = undefined;
    if (this._timetableFilterData) {
      this.initSelectedFilterValue(this._timetableFilterData);
    }
  }

  @computed
  get selectedFilterValue(): number | undefined {
    return this._selectedFilterValue;
  }

  @computed
  get selectedFilterItem(): MasterDataRefDto | undefined {
    const { timetableEntityType } = this.timetableFormatStore;
    const timetableFilterData = this._timetableFilterData;
    if (!timetableFilterData) {
      return;
    }
    switch (timetableEntityType) {
      case 'subject':
        if (timetableFilterData.subjects && timetableFilterData.subjects.length > 0) {
          return timetableFilterData.subjects.find((filterDto) => filterDto.subject.id == this._selectedFilterValue)
            ?.subject;
        }
        break;
      case 'class':
      case 'my-class':
        if (timetableFilterData.classes && timetableFilterData.classes.length > 0) {
          return timetableFilterData.classes.find((filterDto) => filterDto.class.id == this._selectedFilterValue)
            ?.class;
        }
        break;
      case 'student':
      case 'my-student':
        if (timetableFilterData.students && timetableFilterData.students.length > 0) {
          return timetableFilterData.students.find((filterDto) => filterDto.student.id == this._selectedFilterValue)
            ?.student;
        }
        break;
      case 'teacher':
      case 'my-teacher':
        if (timetableFilterData.teachers && timetableFilterData.teachers.length > 0) {
          return timetableFilterData.teachers.find((filterDto) => filterDto.teacher.id == this._selectedFilterValue)
            ?.teacher;
        }
        break;
      case 'room':
        if (timetableFilterData.rooms && timetableFilterData.rooms.length > 0) {
          return timetableFilterData.rooms.find((filterDto) => filterDto.room.id == this._selectedFilterValue)?.room;
        }
        break;
      case 'resource':
        if (timetableFilterData.resources && timetableFilterData.resources.length > 0) {
          return timetableFilterData.resources.find((filterDto) => filterDto.resource.id == this._selectedFilterValue)
            ?.resource;
        }
        break;
    }
  }
}
