import React, { useMemo } from 'react';
import { Skeleton } from 'antd';
import { Dayjs } from 'dayjs';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  LessonCardIndicator,
  LessonIndicator,
} from '@/components/lesson-card/components/indicator/lesson-card-indicator';
import { Timespan } from '@untis/wu-rest-view-api';
import {
  STANDBY_LESSON_CARD_HEIGHT_THRESHOLD,
  useComputedLessonCardProps,
} from '@/components/lesson-card/hooks/use-computed-lesson-card-props';
import { useLessonCardDimensions } from '@/components/lesson-card/hooks/use-lesson-card-dimensions';
import LessonCardTime from '@/components/lesson-card/components/time/lesson-card-time';
import { LessonCardIndicators } from '@/components/lesson-card/components/indicator/lesson-card-indicators';
import LessonCardColorBar from '@/components/lesson-card/components/color-bar/lesson-card-color-bar';
import LessonCardRow from '@/components/lesson-card/components/row/lesson-card-row';
import LessonCardRight from '@/components/lesson-card/components/right/lesson-card-right';

import './lesson-card.less';

export enum LessonCardType {
  NORMAL_TEACHING_PERIOD = 'NORMAL_TEACHING_PERIOD',
  STAND_BY_PERIOD = 'STAND_BY_PERIOD',
  OFFICE_HOUR = 'OFFICE_HOUR',
  BREAK_SUPERVISION = 'BREAK_SUPERVISION',
  EXAM = 'EXAM',
  ADDITIONAL_PERIOD = 'ADDITIONAL_PERIOD',
  EVENT = 'EVENT',
  EXTERNAL_CALENDAR_EVENT = 'EXTERNAL_CALENDAR_EVENT',
}

export enum LessonCardStatus {
  REGULAR = 'REGULAR',
  CANCELLED = 'CANCELLED',
  ADDITIONAL = 'ADDITIONAL',
  CHANGED = 'CHANGED',
  MOVED_AWAY = 'MOVED_AWAY',
  MOVED_HERE = 'MOVED_HERE',
}

export type LessonCardDisplayVariant = 'default' | 'long' | 'minimal-height' | 'minimal-width';

export interface ILessonCardResourceWithChange {
  regularResource?: string;
  addedResource?: string;
  removedResource?: string;
}

export interface ILessonCardKlasseWithStudentGroupContent {
  klassen: ILessonCardResourceWithChange[];
  studentGroup?: string;
}

export interface ILessonCardTextContent {
  texts: string[];
  previewOnly?: boolean;
  marginTop?: boolean;
}

export type LessonCardRowContentType =
  | 'subject'
  | 'klasse'
  | 'klasse-student-group'
  | 'student-group'
  | 'rooms'
  | 'teachers'
  | 'text'
  | 'empty';

interface ILessonCardRowCommonContent {
  type: LessonCardRowContentType;
  hidden?: boolean;
  indicator?: LessonIndicator;
  displayProps?: ILessonCardDisplayProps;
  isTopElement?: boolean;
}

export type LessonCardRowContent = (
  | {
      type: 'subject';
      content: string[];
    }
  | {
      type: 'klasse';
      content: ILessonCardResourceWithChange[];
    }
  | {
      type: 'klasse-student-group';
      content: ILessonCardKlasseWithStudentGroupContent;
    }
  | {
      type: 'student-group';
      content: string;
    }
  | {
      type: 'rooms';
      content: ILessonCardResourceWithChange[];
    }
  | {
      type: 'teachers';
      content: ILessonCardResourceWithChange[];
    }
  | {
      type: 'text';
      content: ILessonCardTextContent;
    }
  | {
      type: 'empty';
      content: undefined;
    }
) &
  ILessonCardRowCommonContent;

export interface ILessonCardColorBar {
  color: string;
  isStriped?: boolean;
}

export interface ILessonCardDisplayProps {
  displayVariant?: LessonCardDisplayVariant;
  showStartEndTime?: boolean;
  showIndicators?: boolean;
  showSubstitutions?: boolean;
  showSingleRow?: boolean;
  hideShadow?: boolean;
  isPast?: boolean;
  isHighlighted?: boolean;
  backgroundColor?: string;
}

export interface ILessonCardProps {
  periodIds: number[];
  type: LessonCardType;
  status: LessonCardStatus;
  colorBar: ILessonCardColorBar;
  rows: LessonCardRowContent[];
  indicators?: LessonIndicator[];
  startDateTime: Dayjs;
  endDateTime: Dayjs;
  periodMove?: Timespan;
  calendarName?: string;
  displayProps?: ILessonCardDisplayProps;
  onLessonClick?: () => void;
  href?: string | null;
  fullWidthHref?: boolean;
}

export const LessonCard = (props: ILessonCardProps) => {
  if (props.type === LessonCardType.STAND_BY_PERIOD) {
    return <StandbyLessonCard {...props} />;
  } else {
    return <GenericLessonCard {...props} />;
  }
};

export function SkeletonLessonCard() {
  return (
    <div className="lesson-card-skeleton">
      <Skeleton.Button active />
    </div>
  );
}

export const StandbyLessonCard = (props: ILessonCardProps) => {
  const { t } = useTranslation();
  const { displayProps, onLessonClick } = props;
  const { lessonCardRef, lessonCardDimensions } = useLessonCardDimensions();
  const { testId, handleButtonClick, isCancelled, updatedDisplayProps, updatedIndicators } = useComputedLessonCardProps(
    {
      ...props,
      lessonCardDimensions,
    },
  );

  const isMinimalHeightVariant = updatedDisplayProps.displayVariant === 'minimal-height';
  const showSingleRow = updatedDisplayProps.showSingleRow;
  const { startDateTime, endDateTime } = props;

  const textContentMarginTop = useMemo(
    () =>
      updatedIndicators.length > 0 &&
      updatedDisplayProps.showIndicators &&
      (lessonCardDimensions?.height ?? 0) > STANDBY_LESSON_CARD_HEIGHT_THRESHOLD
        ? -STANDBY_LESSON_CARD_HEIGHT_THRESHOLD
        : 0,
    [updatedIndicators, updatedDisplayProps.showIndicators, lessonCardDimensions],
  );

  return (
    <div
      className={clsx('lesson-card-standby', {
        cancelled: isCancelled,
        'in-the-past': updatedDisplayProps.isPast,
        highlighted: displayProps?.isHighlighted,
        'minimal-height-variant': isMinimalHeightVariant,
        'show-single-row': showSingleRow,
        clickable: !!onLessonClick,
      })}
      style={{ backgroundColor: displayProps?.backgroundColor }}
      ref={lessonCardRef}
      onClick={handleButtonClick}
      data-testid={testId}
    >
      {displayProps?.showStartEndTime && !isMinimalHeightVariant && (
        <div className="lesson-card-standby--time">
          <LessonCardTime
            timePeriod={{
              startDateTime: !isMinimalHeightVariant ? startDateTime : undefined,
              endDateTime: !isMinimalHeightVariant && !showSingleRow ? endDateTime : undefined,
            }}
          />
        </div>
      )}
      <div className="lesson-card-standby--content">
        {displayProps?.showIndicators && (
          <LessonCardIndicators indicators={updatedIndicators} displayVariant={updatedDisplayProps?.displayVariant} />
        )}
        <div className="lesson-card-standby--content-inner" style={{ marginTop: textContentMarginTop }}>
          <div className="lesson-card-standby--content-inner-text">{t('general.standby')}</div>
        </div>
      </div>
    </div>
  );
};

export const GenericLessonCard = (props: ILessonCardProps) => {
  const { startDateTime, endDateTime, onLessonClick, fullWidthHref, href } = props;

  const { lessonCardRef, lessonCardDimensions } = useLessonCardDimensions();
  const {
    testId,
    handleButtonClick,
    updatedColorBar,
    updatedDisplayProps,
    updatedRows,
    updatedIndicators,
    isCancelled,
  } = useComputedLessonCardProps({ ...props, lessonCardDimensions });

  const backgroundColorStyle = {
    backgroundColor: updatedDisplayProps?.backgroundColor,
  };

  const isMinimalHeightVariant = updatedDisplayProps.displayVariant === 'minimal-height';
  const isMinimalWidthVariant = updatedDisplayProps.displayVariant === 'minimal-width';
  const showSingleRow = updatedDisplayProps.showSingleRow;

  return (
    <div
      className={clsx('lesson-card', {
        cancelled: isCancelled,
        'in-the-past': updatedDisplayProps.isPast,
        'no-shadow': updatedDisplayProps?.hideShadow,
        highlighted: updatedDisplayProps?.isHighlighted,
        'minimal-height-variant': isMinimalHeightVariant,
        'minimal-width-variant': isMinimalWidthVariant,
        'show-single-row': showSingleRow,
        clickable: !!onLessonClick,
      })}
      style={backgroundColorStyle as React.CSSProperties}
      ref={lessonCardRef}
      onClick={handleButtonClick}
      data-testid={testId}
    >
      <div className="lesson-card-container">
        {fullWidthHref && href && <Link className="lesson-card-full-width-link" to={href} />}
        {updatedDisplayProps?.showStartEndTime && (
          <LessonCardTime
            timePeriod={{
              startDateTime: !isMinimalHeightVariant ? startDateTime : undefined,
              endDateTime: !isMinimalHeightVariant && !showSingleRow ? endDateTime : undefined,
            }}
          />
        )}
        <LessonCardColorBar {...updatedColorBar} />
        <LessonCardInnerContent
          rows={updatedRows}
          displayProps={updatedDisplayProps}
          indicators={updatedIndicators}
          isMinimalHeightVariant={isMinimalHeightVariant}
          showSingleRow={showSingleRow ?? false}
        />
        {(fullWidthHref || href) && <LessonCardRight fullWidthHref={fullWidthHref} href={href} />}
      </div>
    </div>
  );
};

const LessonCardInnerContent = ({
  rows,
  displayProps,
  indicators,
  isMinimalHeightVariant,
  showSingleRow,
}: {
  rows: LessonCardRowContent[];
  displayProps: ILessonCardDisplayProps;
  indicators: LessonIndicator[];
  isMinimalHeightVariant: boolean;
  showSingleRow: boolean;
}) => {
  return (
    <div className="lesson-card-inner-card">
      <div
        className={clsx('lesson-card-info-container', {
          'minimal-height-variant': isMinimalHeightVariant,
          'show-single-row': showSingleRow,
        })}
        data-testid="lesson-card-info-container"
      >
        {isMinimalHeightVariant && (
          <LessonCardMinimalHeightVariant displayProps={displayProps} indicators={indicators} />
        )}
        {!isMinimalHeightVariant && rows.map((row) => <LessonCardRow key={row.type} {...row} />)}
      </div>
    </div>
  );
};

const LessonCardMinimalHeightVariant = ({
  displayProps,
  indicators,
}: {
  displayProps: ILessonCardDisplayProps;
  indicators: LessonIndicator[];
}) => {
  return (
    <div className="lesson-card-minimal-height-variant-helper" data-testid="lesson-card-minimal-variant-helper">
      {displayProps?.showIndicators && indicators.length > 0 && (
        <LessonCardIndicator indicator={indicators[0]} displayVariant="minimal-height" />
      )}
    </div>
  );
};
