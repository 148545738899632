import { action, computed, observable } from 'mobx';
import i18next, { t } from 'i18next';

import { inject, Store } from '@/types/store';
import { OnboardingApi } from '@/stores/api-store';
import { OnboardingTypeEnum } from '@untis/wu-rest-view-api';
import { OnboardingResponseDto } from '@untis/wu-rest-view-api/api';
import { FeatureOnboardingStore } from '@/stores/feature-onboarding-store';
import { TimetableLegendFilterStore } from '@te/standard/stores/filter/timetable-legend-filter-store';
import { TestIds } from '@/testIds';
import AnalyticsStore from '@/stores/analytics-store/analytics-store';

@Store()
export default class TimetableFeatureOnboardingStore {
  private featureOnboardingStore = inject(FeatureOnboardingStore);
  private timetableLegendFilterStore = inject(TimetableLegendFilterStore);
  private analyticsStore = inject(AnalyticsStore);

  @observable private _timetableOnboarding: OnboardingResponseDto | undefined;
  @observable private _isOnboardingStarted: boolean = false;

  @action
  async getOnboarding() {
    const result = await OnboardingApi.getOnboarding(OnboardingTypeEnum.TIMETABLE);
    this._timetableOnboarding = result.data;
    this._isOnboardingStarted = (result.data.step?.length ?? 0) > 0;
  }

  @action
  reset() {
    this._timetableOnboarding = undefined;
    this._isOnboardingStarted = false;
  }

  @computed
  get isOnboardingStarted(): boolean {
    return this._isOnboardingStarted;
  }

  @action.bound
  showOnboarding() {
    this._isOnboardingStarted = true;
    this.trackOnboardingClickEvent();
    this.featureOnboardingStore.start({
      type: OnboardingTypeEnum.TIMETABLE,
      maxReachedStep: this._timetableOnboarding?.step,
      withExitStep: true,
      steps: [
        {
          id: 'timetable--day-toggle',
          title: t('timetable.featureOnboarding.dayWeekToggleStep.title'),
          textContent: t('timetable.featureOnboarding.dayWeekToggleStep.text'),
          graphic: {
            type: 'video',
            content: {
              name: this.getGraphicName('timetable-day-week-toggle[lang].mp4'),
              loop: true,
            },
          },
          placement: 'auto',
          target: `[data-testid="${TestIds.TIMETABLE_DAY_WEEK_TOGGLE}"]`,
        },
        {
          id: 'timetable--legend-filter',
          title: t('timetable.featureOnboarding.legendFilterStep.title'),
          textContent: t('timetable.featureOnboarding.legendFilterStep.text'),
          graphic: {
            type: 'video',
            content: {
              name: this.getGraphicName('timetable-legend-filter[lang].mp4'),
              loop: true,
            },
          },
          placement: 'auto',
          target: `[data-testid="${TestIds.TIMETABLE_LEGEND_FILTER}"]`,
        },
        {
          id: 'timetable--settings',
          title: t('timetable.featureOnboarding.settingsStep.title'),
          textContent: t('timetable.featureOnboarding.settingsStep.text'),
          graphic: {
            type: 'video',
            content: {
              name: this.getGraphicName('timetable-legend-filter-settings[lang].mp4'),
              loop: true,
            },
          },
          placement: 'left-end',
          target: `[data-testid="${TestIds.TIMETABLE_SETTING_POPOVER}"]`,
          beforeStep: () => this.timetableLegendFilterStore.setControlledIsPopoverOpen(true),
          afterStep: () => this.timetableLegendFilterStore.setControlledIsPopoverOpen(undefined),
        },
        {
          id: 'timetable--help-button',
          title: t('timetable.featureOnboarding.helpButtonStep.title'),
          textContent: this.constructHelpCenterStepText(),
          textContainsHtml: true,
          graphic: {
            type: 'image',
            name: 'timetable-help-button.png',
          },
          placement: 'top-start',
          target: `[data-testid="${TestIds.TIMETABLE_HELP_CENTER_BUTTON}"]`,
        },
      ],
    });
  }

  private getGraphicName(graphicNameTemplate: string): string {
    const isGerman = i18next.language.startsWith('de');
    return graphicNameTemplate.replace('[lang]', isGerman ? '-de' : '');
  }

  private constructHelpCenterStepText(): string {
    const helpCenterArticleText = t('timetable.featureOnboarding.helpButtonStep.helpCenterArticle');
    // eslint-disable-next-line max-len
    const helpCenterArticleLink = `<a href="https://help.untis.at/hc/de/articles/10176456202268-Neue-Stundenplanansicht-BETA" target="_blank" rel="noreferrer">${helpCenterArticleText}</a>`; // needs to be a string
    return t('timetable.featureOnboarding.helpButtonStep.text', {
      helpCenterArticleLink: helpCenterArticleLink,
    });
  }

  private trackOnboardingClickEvent() {
    this.analyticsStore.trackEvent('TimetableNew', 'TimetableOnboardingShown');
  }
}
