import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import useStore from '@/hooks/useStore';
import Keys from '@/types/keys';
import { ITestComponentProps } from '@/types/test-component-props';
import { NavigationBarStore } from '@/ui-components/navigation-bar/navigation-bar-store';
import { Badge, Icon, Tag, Tooltip } from '@/ui-components';

import './menu-item.less';

/**
 * enum to handle different types of appearance
 */
export enum MenuItemType {
  SUB_MENU_ITEM,
  BOTTOM_VIEW_ITEM,
}

interface IProps extends ITestComponentProps {
  name: string;
  content?: JSX.Element;
  className?: string;
  icon?: string | JSX.Element;
  tooltipTitle?: string;
  selected: boolean;
  type?: MenuItemType;
  badge?: string;
  tag?: string;
  route?: string;
  onClick?: () => void;
  onKeyDown?: (event: any) => void;
  isDense: boolean;
  isAccessible: boolean;
  isFirst?: boolean;
}

const MenuItem = observer<IProps>((props) => {
  const navigationBarStore = useStore(NavigationBarStore);
  const collapsed = navigationBarStore.collapsed;

  const className = clsx('menu-item', props.className, {
    'menu-item--dense': props.isDense,
    'menu-item--normal-height': !props.isDense && props.type !== MenuItemType.BOTTOM_VIEW_ITEM,
    'menu-item--selected': props.selected,
    'menu-item--collapsed': collapsed,
    'menu-item--sub-menu-item': props.type === MenuItemType.SUB_MENU_ITEM,
    'menu-item--bottom-view-item': props.type === MenuItemType.BOTTOM_VIEW_ITEM,
  });

  let icon;
  if (typeof props.icon === 'string') {
    icon = <Icon type={props.icon} />;
  } else {
    icon = props.icon;
  }

  const iconWithTooltip = (
    <Tooltip
      title={collapsed ? (props.tooltipTitle ? props.tooltipTitle : props.content ? props.content : props.name) : ''}
      placement="right"
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
    >
      <div>
        {props.badge ? (
          <Badge corner="topRight" status="error" className="badge-icon">
            {icon}
          </Badge>
        ) : (
          icon
        )}
      </div>
    </Tooltip>
  );

  const showIcon = useMemo(
    () => props.icon && (props.type === MenuItemType.BOTTOM_VIEW_ITEM || !props.isDense),
    [props.icon, props.type, props.isDense],
  );

  const menuItem = (
    <div className={className} onClick={props.onClick}>
      <div className="item-container">
        <div className="icon-container">{showIcon && iconWithTooltip}</div>
        {!navigationBarStore.collapsed && <div className="item-name">{props.content ? props.content : props.name}</div>}
        {!navigationBarStore.collapsed && props.badge && <div className="badge">{props.badge}</div>}
        {!navigationBarStore.collapsed && props.tag && (
          <Tag color="orange" uppercase={true} size="lg">
            {props.tag}
          </Tag>
        )}
      </div>
    </div>
  );

  const isExternalUrl = props.route?.startsWith('http://') || props.route?.startsWith('https://');
  const tabIndexLink = props.isAccessible ? undefined : -1;

  const { handleBack } = navigationBarStore;
  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.key === Keys.Escape) handleBack();
      else if (event.key === Keys.Enter) {
        props.onClick?.();
        event.target.click();
      }

      props.onKeyDown?.(event);
    },
    [handleBack, props.onClick, props.onKeyDown],
  );

  const linkClassName =
    `menu-item-link menu-item-link-${props.name}` +
    (props.isFirst && props.isAccessible ? ' menu-item-link-first' : '');

  return props.route ? (
    isExternalUrl ? (
      <a
        className={linkClassName}
        href={props.route}
        target="_blank"
        rel="noopener noreferrer"
        data-testid={props.testId}
        tabIndex={tabIndexLink}
        onKeyDown={handleKeyDown}
      >
        {menuItem}
      </a>
    ) : (
      <Link
        className={linkClassName}
        to={props.route}
        data-testid={props.testId}
        tabIndex={tabIndexLink}
        onKeyDown={handleKeyDown}
      >
        {menuItem}
      </Link>
    )
  ) : (
    <div
      className={linkClassName}
      data-testid={props.testId}
      tabIndex={props.isAccessible ? 0 : -1}
      onKeyDown={handleKeyDown}
    >
      {menuItem}
    </div>
  );
});

export default MenuItem;
