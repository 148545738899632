import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import React from 'react';

import { AbstractListViewStore } from '../common/abstract-list-view-store';

import { IReasonsOfAbsenceFormData, ReasonsOfAbsenceForm } from './reasons-of-absence-form';

import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { IListViewAction, IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import { ElementType, Right } from '@/stores/rights-store';
import { StudentAbsenceReasonsViewApi } from '@/stores/api-store';
import { createChunks } from '@/utils/array/array-util';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { booleanSorting } from '@/utils/sorting/sorting-util';
import {
  AbsenceReasonDto,
  AbsenceReasonPrivilegeEnum,
  AbsenceReasonsMetaDto,
  CustomOrderDto,
} from '@untis/wu-rest-view-api/api';
import { DeprecatedDropDownItem } from '@/ui-components/deprecated-drop-down/drop-down';
import { inject, Store } from '@/types/store';
import { matchesAllSearches } from '@/utils/filtering/filtering-util';
import RouterStore from '@/stores/router-store';
import { ISelectItem } from '@/ui-components/select/select';

export interface IReasonsOfAbsenceRows extends ITableRowKey {
  id: number;
  name: string;
  longName: string;
  active: boolean;
  absenceCounts: boolean;
  autoExcuseStatusId: number;
  notify: boolean;
  privilege: AbsenceReasonPrivilegeEnum;
  privilegeI18n: string;
  key: number;
  notificationEnabled: boolean;
  notificationConfirmRequired: boolean;
  notificationReplyForbidden: boolean;
  notificationMessage: string;
  notificationSenderId: number;
}
@Store()
export class ReasonsOfAbsenceStore extends AbstractListViewStore<IReasonsOfAbsenceFormData> {
  @observable private _reasonOfAbsenceDtos: AbsenceReasonDto[] = [];
  @observable private _metaDto: AbsenceReasonsMetaDto | undefined = undefined;
  private editId?: number;
  private routerStore = inject(RouterStore);

  constructor() {
    super({ right: Right.MASTERDATA, elementType: ElementType.ABSENCE_REASON });
    this.fetchData();
  }

  @action
  setEditId(editId: string) {
    this.editId = editId ? Number(editId) : undefined;
  }

  @action
  async fetchData() {
    this.setIsDataLoading(true);
    StudentAbsenceReasonsViewApi.readAll().then((response) => {
      this._reasonOfAbsenceDtos = response.data.reasons;
      this._metaDto = response.data.meta;
      this.setIsMetaLoading(false);
      this.setIsDataLoading(false);
    });
  }

  @computed
  get reasonsOfAbsenceRows(): IReasonsOfAbsenceRows[] {
    return this._reasonOfAbsenceDtos
      .filter((dto) => matchesAllSearches(this.mapDtoToSearchString(dto), this.selectedFreeTextOptions))
      .map((reason) => ({
        id: reason.id!,
        name: reason.name,
        longName: reason.longName,
        active: reason.active,
        absenceCounts: reason.counts,
        autoExcuseStatusId: reason.autoExcuseStatusId ? reason.autoExcuseStatusId : -1,
        privilege: reason.privilege,
        privilegeI18n: this.resolvePrivilegeName(reason.privilege),
        notify: reason.notify,
        key: reason.id!,
        notificationEnabled: !!reason.notificationEnabled,
        notificationConfirmRequired: !!reason.notificationConfirmationRequired,
        notificationReplyForbidden: !!reason.notificationReplyForbidden,
        notificationMessage: reason.notificationMessage || '',
        notificationSenderId: reason.notificationSenderId || -1,
      }));
  }

  private mapDtoToSearchString(dto: AbsenceReasonDto): string {
    let res = '';
    res += dto.name;
    res += dto.longName;
    return res.toLocaleLowerCase();
  }

  @computed
  get excuseStatuses(): DeprecatedDropDownItem[] {
    return this._metaDto && this._metaDto.excuseStatuses
      ? this._metaDto.excuseStatuses.map((status) => ({
          key: '' + status.id,
          value: status.name,
        }))
      : [];
  }

  @action
  async createReasonOfAbsence(formValue: IReasonsOfAbsenceFormData): Promise<any> {
    return StudentAbsenceReasonsViewApi.create(this.mapToDto(formValue)).then((response) => {
      this._reasonOfAbsenceDtos = [...this._reasonOfAbsenceDtos, response.data];
    });
  }

  @action
  updateReasonOfAbsence(formValue: IReasonsOfAbsenceFormData, rowValue: IReasonsOfAbsenceRows) {
    return StudentAbsenceReasonsViewApi.update(rowValue.id, this.mapToDto(formValue, rowValue)).then((response) => {
      const cachedDto = this._reasonOfAbsenceDtos.find((reason) => reason.id === rowValue.id);
      if (cachedDto) {
        Object.assign(cachedDto, response.data);
      }
    });
  }

  @action
  updateActiveStateOfReasonOfAbsence(row: IReasonsOfAbsenceRows, value: boolean) {
    const cachedDto = this._reasonOfAbsenceDtos.find((reason) => reason.id === row.id);
    const dto: AbsenceReasonDto = Object.assign({}, cachedDto, { active: value });
    StudentAbsenceReasonsViewApi.update(dto.id!, dto).then((response) => {
      if (cachedDto) {
        Object.assign(cachedDto, response.data);
      }
    });
  }

  @action
  onReasonsOfAbsenceDeleted(deletedReasonsOfAbsenceIds: number[]) {
    this._reasonOfAbsenceDtos = this._reasonOfAbsenceDtos.filter(
      (s) => s.id && !deletedReasonsOfAbsenceIds.some((idToDelete) => s.id === idToDelete),
    );
  }

  @computed
  get showNotifyCheckbox() {
    return !!this._metaDto?.showNotificationOption;
  }

  deleteReasonOfAbsence(id: number) {
    this._modalStore
      .openDeletePrompt(t('general.deleteReasonOfAbsenceQuestionmark'), t('general.reasonOfAbsenceDeleteConfirmation'))
      .then((result) => {
        if (result) {
          StudentAbsenceReasonsViewApi._delete(id).then(() => {
            this._notificationStore.success({ title: t('general.reasonOfAbsenceDeleted') });
            this._modalStore.closeModal();
            this.onReasonsOfAbsenceDeleted([id]);
          });
        }
      })
      .catch((error) => {
        this._notificationStore.error({
          title: t('general.reasonOfAbsenceCouldNotBeDeleted'),
          message: error.toString(),
        });
      });
  }

  @action
  async openEditForm(editRow?: IReasonsOfAbsenceRows) {
    const row = editRow ?? this.reasonsOfAbsenceRows?.find((row) => row.id === this.editId);

    if (!row) {
      return;
    }

    const reason = this.getReasonByRowId(row.id.toString());
    this.form?.resetFields();
    if (reason) {
      try {
        await this._modalStore.openModalDialog({
          title: `${t('general.reasonOfAbsence')} ${reason.longName} (${reason.name})`,
          size: 'md',
          children: <ReasonsOfAbsenceForm store={this} reason={reason} />,
          onAfterClose: () => this.routerStore.redirect('/reasons-of-absence'),
          containsForm: true,
        });
      } catch (error) {
        // error handle
      }
    }
  }

  @action.bound
  openCreateForm() {
    this.form.resetFields();
    this._modalStore.openModalDialog({
      children: <ReasonsOfAbsenceForm store={this} />,
      title: t('general.newReasonOfAbsence'),
      size: 'md',
      onAfterClose: () => this.routerStore.redirect('/reasons-of-absence'),
      containsForm: true,
    });
  }

  @computed
  private get columnActions() {
    const actions = [];

    if (this.canEdit) {
      actions.push({
        tooltip: t('general.edit'),
        href: (row: IReasonsOfAbsenceRows) => `/reasons-of-absence/edit/${row.id}`,
        icon: 'edit',
      });
    }
    if (this.canDelete) {
      actions.push({
        tooltip: t('general.delete'),
        href: undefined,
        onClick: (row: IReasonsOfAbsenceRows) => this.deleteReasonOfAbsence(row.id),
        icon: 'shared_trash',
      });
    }
    return actions;
  }

  @computed
  get columns(): Columns<IReasonsOfAbsenceRows> {
    const cols: Columns<IReasonsOfAbsenceRows> = [
      {
        type: ColumnType.Text,
        header: t('general.name'),
        key: 'name',
        sorter: (a, b) => {
          return a.name.localeCompare(b.name);
        },
      },
      {
        type: ColumnType.Text,
        header: t('general.longName'),
        key: 'longName',
        sorter: (a, b) => {
          return a.longName.localeCompare(b.longName);
        },
      },
      {
        type: ColumnType.Text,
        header: t('general.privilege'),
        key: 'privilegeI18n',
        sorter: (a, b) => {
          return a.privilege.localeCompare(b.privilege);
        },
      },
      {
        type: ColumnType.Tag,
        header: t('general.active'),
        tags: (row) =>
          row.active
            ? [{ text: t('general.active'), color: 'green' }]
            : [{ text: t('general.inactive'), color: 'grey' }],
        key: 'active',
        sorter: (a, b) => booleanSorting(a.active, b.active),
      },
    ];

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.OverlayHoverActions,
        key: 'actions',
        width: this.columnActions.length * 45 + 'px',
        actionIcons: this.columnActions,
      });
    }

    return cols;
  }

  getReasonByRowId = (id: string): IReasonsOfAbsenceRows | undefined => {
    return this.reasonsOfAbsenceRows.find((reasonOfAbsence) => reasonOfAbsence.id?.toString() === id);
  };

  @computed
  get listViewActions(): IListViewAction[] {
    const actions: IListViewAction[] = [];
    if (this.canCreate) {
      actions.push({
        label: t('general.new'),
        href: '/reasons-of-absence/new',
        onClick: this.openCreateForm,
      });
    }
    actions.push({
      label: t('general.report'),
      onClick: () => {
        this.generateReport('AbsenceReason');
      },
    });
    return actions;
  }

  @computed
  get saveSorting(): (order: CustomOrderDto[]) => void {
    return (order) => {
      StudentAbsenceReasonsViewApi.order(order).then(() => {
        this.fetchData();
      });
    };
  }

  @computed
  get resetSorting(): () => void {
    return () => {
      StudentAbsenceReasonsViewApi.resetOrder().then(() => {
        this.fetchData();
      });
    };
  }

  @computed
  get isSubjectTeacherExcuseActive() {
    return this._metaDto?.subjectTeacherExcusesActive;
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<IReasonsOfAbsenceRows>[] | undefined {
    return this.canDelete
      ? [
          {
            label: t('general.delete'),
            icon: 'shared_trash',
            onClick: (rows) => {
              this._modalStore
                .openDeletePrompt(
                  t('general.deleteReasonsOfAbsenceQuestionmark'),
                  t('general.reasonOfAbsenceBulkDeleteConfirmation', {
                    count: rows.length,
                  }),
                )
                .then((result) => {
                  if (result) {
                    const chunks = createChunks<IReasonsOfAbsenceRows>(rows, 100);
                    chunks.forEach((chunk) => {
                      StudentAbsenceReasonsViewApi.deleteMultiple(chunk.map((row) => row.id))
                        .then(() => {
                          this._notificationStore.success({
                            title: t('general.reasonOfAbsenceDeleted'),
                          });
                          this.onReasonsOfAbsenceDeleted(chunk.map((row) => row.id));
                        })
                        .catch((error) => {
                          this._notificationStore.error({
                            title: t('general.someReasonsOfAbsenceCouldNotBeDeleted'),
                            message: error.toString(),
                          });
                        });
                    });
                  }
                });
            },
          },
        ]
      : undefined;
  }

  @computed
  get notificationSenderOptions(): ISelectItem[] {
    const defaultOption = {
      id: '-1',
      label: `${t('general.absenceCreator')} (${t('general.auto')})`,
    };
    return this._metaDto && this._metaDto.notificationSenderOptions
      ? [
          defaultOption,
          ...this._metaDto.notificationSenderOptions.map((user) => ({
            id: '' + user.userId,
            label: user.userName + ` (${user.userRole})`,
          })),
        ]
      : [defaultOption];
  }

  @computed
  get autoNotificationFeatureActive() {
    return this.notificationSenderOptions.length > 1;
  }

  @computed
  get notificationDefaultText() {
    return this._metaDto?.notificationDefaultText || '';
  }

  mapToDto(formValue: IReasonsOfAbsenceFormData, rowValue?: IReasonsOfAbsenceRows): AbsenceReasonDto {
    return {
      id: rowValue?.id || undefined,
      name: formValue.name,
      longName: formValue.longName,
      active: formValue.active,
      counts: formValue.absenceCounts,
      autoExcuseStatusId: formValue.autoExcuseStatusId ? Number(formValue.autoExcuseStatusId) : -1,
      privilege: formValue.privilege,
      notify: this.showNotifyCheckbox && formValue.notify,
      notificationEnabled: formValue.notificationEnabled,
      notificationReplyForbidden: formValue.notificationReplyForbidden,
      notificationConfirmationRequired: formValue.notificationConfirmRequired,
      notificationSenderId: formValue.notificationEnabled ? formValue.notificationSenderId : undefined,
      notificationMessage: formValue.notificationEnabled ? formValue.notificationMessage : undefined,
    };
  }

  private resolvePrivilegeName(privilege: AbsenceReasonPrivilegeEnum): string {
    if (privilege === AbsenceReasonPrivilegeEnum.HIGH) {
      return t('general.high');
    } else if (privilege === AbsenceReasonPrivilegeEnum.LOW) {
      return t('general.low');
    } else {
      return t('general.normal');
    }
  }
}
