import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { ExamsViewApi } from '@/stores/api-store';
import { inject, Store } from '@/types/store';
import { ExamStatisticsDto } from '@untis/wu-rest-view-api/api';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import { formatDateForServerRequest } from '@/utils/date/date-util';
import { Columns, ColumnType, DateFormat } from '@/ui-components/wu-table/wu-table-column-mapper';
import { defaultSorting, sortingByDayjs, sortStringListByFirstElement } from '@/utils/sorting/sorting-util';
import { IListViewAction } from '@/components/list-view/list-view';
import { IReportExportingRow, ReportType } from '@/components/reports-exporting-table/reports-exporting-table';
import { IExamReportParameters } from '@ls/exams/overview/exams-report-url-mapper-store';
import { ExamFormStore } from '@ls/exams/exam-form/exam-form-store';
import ExamForm from '@ls/exams/exam-form/exam-form';
import { AbstractExamListStore } from '@ls/exams/overview/abstract-exam-list-store';
import { matchesAllSearches } from '@/utils/filtering/filtering-util';

export interface IExamStatisticsRow extends ITableRowKey {
  examId: number;
  examType: string;
  examName: string;
  examDate: Dayjs;
  startTime: Dayjs;
  endTime: Dayjs;
  subject: string;
  classes: string[];
  teachers: string[];
  duration: number;
  numberOfParticipants: string;
  gradingScheme: string;
  grade: string;
  gradesPerType: Map<string, string>;
  combinedGrades: string;
  userCanEditExam: boolean;
}

@Store()
export class ExamStatisticsStore extends AbstractExamListStore<IExamStatisticsRow> {
  private rightStore: RightsStore = inject(RightsStore);

  @observable private _examsStatistics: ExamStatisticsDto[] = [];

  constructor() {
    super(Right.EXAMINATION, false);
  }

  @action
  async init() {
    await this.getExamListFilters();
    await this.getExamStatistics();

    this.setIsDataLoading(false);
  }

  @action
  async getExamStatistics() {
    const response = await ExamsViewApi.getExamStatistics(
      formatDateForServerRequest(this._selectedDateRange.startDate),
      formatDateForServerRequest(this._selectedDateRange.endDate),
      this.getSelectedOptionByCategory(this._localizedType),
      this.getSelectedOptionByCategory(this._localizedClass),
      this.getSelectedOptionByCategory(this._localizedSubject),
      this.getSelectedOptionByCategory(this._localizedTeacher),
    );
    this._examsStatistics = response.data.exams || [];
  }

  private mapDtoToSearchString(dto: ExamStatisticsDto): string {
    let res = '';
    res += dto.exam.classes.map((c) => c.shortName).join(' ');
    res += dto.exam.teachers.map((t) => t.shortName).join(' ');
    res += dto.exam.subject ? dto.exam.subject.shortName : '';
    res += dto.exam.examType ? dto.exam.examType.shortName : '';
    res += dto.exam.examName ? dto.exam.examName : '';
    return res.toLocaleLowerCase();
  }

  @computed
  get rowData(): IExamStatisticsRow[] {
    let filteredDtos = this._examsStatistics;

    filteredDtos = filteredDtos.filter((dto) => {
      return matchesAllSearches(this.mapDtoToSearchString(dto), this.selectedFreeTextOptions);
    });

    return filteredDtos?.map((examsStatistic): IExamStatisticsRow => {
      const { exam, countPerGrade, averageGrade } = examsStatistic;
      const { examStart, examEnd } = exam;
      const examStartDayjs = dayjs(examStart);
      const examEndDayjs = dayjs(examEnd);
      const classes = exam.classes.map((klasse) => klasse.displayName ?? '');
      const teachers = exam.teachers?.map((teacher) => teacher.displayName ?? '');

      const gradesPerType = new Map<string, string>();
      countPerGrade?.forEach((grade) => {
        gradesPerType.set(grade.grade.displayName ?? '', grade.count.toString());
      });

      return {
        key: exam.examId,
        examId: exam.examId,
        examType: exam.examType?.displayName || '',
        examName: exam.examName || '',
        examDate: examEndDayjs,
        startTime: examStartDayjs,
        endTime: examEndDayjs,
        subject: exam.subject?.displayName || '',
        classes: classes,
        teachers: teachers || [],
        duration: exam.examDuration || 0,
        numberOfParticipants: `${examsStatistic.numParticipantsWithGrade || 0} / ${
          examsStatistic.numParticipants || 0
        }`,
        gradingScheme: examsStatistic.gradingScale?.displayName || '',
        grade: averageGrade?.toString() || '',
        gradesPerType: gradesPerType,
        combinedGrades: countPerGrade?.map((grade) => `${grade.grade.displayName}: ${grade.count}`).join('; ') || '',
        userCanEditExam: exam.canEdit || false,
      };
    });
  }

  @computed
  get columns(): Columns<IExamStatisticsRow> {
    const cols: Columns<IExamStatisticsRow> = [
      {
        type: ColumnType.Text,
        key: 'examType',
        header: t('general.type'),
        sorter: (a, b) => defaultSorting(a.examType, b.examType),
      },
      {
        type: ColumnType.Text,
        key: 'examName',
        header: t('general.name'),
        sorter: (a, b) => defaultSorting(a.examName, b.examName),
      },
      {
        type: ColumnType.List,
        key: 'class',
        header: t('general.class'),
        getEntries: (row) => row.classes,
        entryThreshold: 3,
        ellipsis: true,
        sorter: (a, b) => sortStringListByFirstElement(a.classes, b.classes),
      },
      {
        type: ColumnType.Text,
        key: 'subject',
        header: t('general.subject'),
        sorter: (a, b) => defaultSorting(a.subject, b.subject),
      },
      {
        type: ColumnType.List,
        key: 'teacher',
        header: t('general.teacher'),
        getEntries: (row) => row.teachers,
        entryThreshold: 3,
        ellipsis: true,
        sorter: (a, b) => sortStringListByFirstElement(a.teachers, b.teachers),
      },
      {
        type: ColumnType.Date,
        key: 'examDate',
        format: DateFormat.Date,
        header: t('general.date'),
        sorter: (a, b) => sortingByDayjs(a.examDate, b.examDate),
      },
      {
        type: ColumnType.Date,
        key: 'startTime',
        format: DateFormat.Time,
        header: t('general.fromDate'),
      },
      {
        type: ColumnType.Date,
        key: 'endTime',
        format: DateFormat.Time,
        header: t('general.toDate'),
      },
      {
        type: ColumnType.Text,
        key: 'duration',
        header: t('lessons.examsStatistics.tableColumns.durationInMinutes'),
      },
      {
        type: ColumnType.Text,
        key: 'numberOfParticipants',
        header: t('lessons.examsStatistics.tableColumns.numberOfParticipants'),
      },
      {
        type: ColumnType.Text,
        key: 'gradingScheme',
        header: t('general.gradingScheme'),
      },
      {
        type: ColumnType.Text,
        key: 'grade',
        header: t('general.grade'),
      },
    ];

    const gradingSchemes = this._examsStatistics
      .map((e) => e.gradingScale?.displayName || '')
      .filter((e) => e.length > 0);

    const uniqueGradingSchemes = [...new Set(gradingSchemes)];

    if (uniqueGradingSchemes.length > 1) {
      cols.push({
        type: ColumnType.Text,
        key: 'combinedGrades',
        header: t('general.grades'),
        className: 'exam-statistics--combined-grades-column',
      });
    } else {
      const uniqueGradingScaleMarks = new Set();
      this._examsStatistics.forEach((examStatistic) => {
        examStatistic.gradingScale?.marks?.forEach((mark) => {
          uniqueGradingScaleMarks.add(mark.name);
        });
      });

      uniqueGradingScaleMarks.forEach((markName) => {
        cols.push({
          type: ColumnType.Custom,
          key: 'gradesPerType',
          header: String(markName),
          width: '80px',
          render: ({ gradesPerType }) => gradesPerType.get(String(markName) ?? '') || '',
        });
      });
    }

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.OverlayHoverActions,
        key: 'actions',
        actionIcons: this.columnActions,
      });
    }

    return cols;
  }

  @computed
  private get columnActions() {
    const actions = [];

    if (this.rightStore.canRead(Right.EXAMINATION, ElementType.ALL, false)) {
      actions.push({
        tooltip: t('general.edit'),
        icon: 'edit',
        condition: (row: IExamStatisticsRow) => row.userCanEditExam,
        onClick: (row: IExamStatisticsRow) => {
          const examFormStore = new ExamFormStore(row.examId);
          this._modalStore.openModalDialog({
            children: (
              <ExamForm
                examFormStore={examFormStore}
                onSaveCallback={() => this.getExamStatistics()}
                onDeleteCallback={() => this.getExamStatistics()}
              />
            ),
            title: '',
            size: 'full-size',
            containsForm: true,
          });
        },
      });
    }

    return actions;
  }

  @computed
  get globalActions(): IListViewAction[] {
    return [
      {
        label: t('general.reports'),
        onClick: () => this._reportStore.openReportsModal(this.reportRows, this.getReportUrl),
      },
    ];
  }

  @computed
  get reportRows(): IReportExportingRow[] {
    return [
      {
        key: 'examStatistics',
        title: t('lessons.exams.reports.examsStatisticsReportTitle'),
        description: t('lessons.exams.reports.examsStatisticsReportDescription'),
        reportTypes: [ReportType.PDF, ReportType.CSV, ReportType.XLS],
      },
    ];
  }

  @action.bound
  private getReportUrl(key: string, reportType: ReportType): string {
    const reportParams: IExamReportParameters = {
      startDate: formatDateForServerRequest(this._selectedDateRange.startDate),
      endDate: formatDateForServerRequest(this._selectedDateRange.endDate),
      examTypeId: this.getSelectedOptionByCategory(this._localizedType)?.toString() || '-1',
      classId: this.getSelectedOptionByCategory(this._localizedClass)?.toString() || '-1',
      subjectId: this.getSelectedOptionByCategory(this._localizedSubject)?.toString() || '-1',
      teacherId: this.getSelectedOptionByCategory(this._localizedTeacher)?.toString() || '-1',
      gradingSchemeId: '-1',
      showDeleted: false,
      showEmpty: false,
    };
    return this.examsReportUrlMapperStore.mapToReportUrl(key, reportType, reportParams);
  }
}
