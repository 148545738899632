import React from 'react';
import './substitution-text.less';

export interface ISubstitutionTextProps {
  value: string;
  substituted?: string;
}

export const SubstitutionText = (props: ISubstitutionTextProps) => {
  return (
    <div className="substitution-text">
      {props.substituted && (
        <>
          <span className="substituted">{props.substituted}</span>
          <span> &#8594; </span>
        </>
      )}
      <span>{props.value}</span>
    </div>
  );
};
