import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import LessonCardColorBar from '@/components/lesson-card/components/color-bar/lesson-card-color-bar';
import { IExternalTimetableEvent } from '@te/standard/stores/url/external-event-details-modal-handler';
import { LessonCardIndicators } from '@/components/lesson-card/components/indicator/lesson-card-indicators';
import { LessonIndicator } from '@/components/lesson-card/components/indicator/lesson-card-indicator';
import { Flex, IconButton } from '@/ui-components';
import { formatTime, formatTimeRange } from '@/utils/date/date-util';

import './external-event-details.less';

type Duration = {
  startDateTime: Dayjs;
  endDateTime: Dayjs;
  isFullDayEvent: boolean;
};

const ExternalEventDetails = (props: IExternalTimetableEvent) => {
  const { t } = useTranslation();

  const {
    title,
    location,
    groupName,
    description,
    color,
    calendarName,
    isTentative,
    startDateTime,
    endDateTime,
    durationTotal,
  } = props;

  const parseLink = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  const start = durationTotal ? dayjs(durationTotal.start) : startDateTime;
  const end = durationTotal ? dayjs(durationTotal.end) : endDateTime;
  const isFullDayEvent = start.hour() === 0 && start.minute() === 0 && end.hour() === 23 && end.minute() === 59;
  const isMultiDay = durationTotal && !start.isSame(end, 'day');

  const parsedDescription = parseLink(description.trim());

  const indicators: LessonIndicator[] = [];

  if (isFullDayEvent || isMultiDay) {
    indicators.push({
      type: 'full-day-event',
      displayName: t('timetable.indicators.fullDayEvent'),
    });
  }
  indicators.push({
    type: 'external',
    displayName: calendarName,
  });

  return (
    <Flex className="external-event-details" gap="md">
      <LessonCardColorBar color={color} isStriped={isTentative} />
      <div className="external-event-details--content">
        <Flex className="external-event-details--title" gap="md">
          <span className="external-event-details--title-label">{title}</span>
          <LessonCardIndicators indicators={indicators} displayVariant="long" />
        </Flex>
        <Flex className="external-event-details--row" gap="md">
          <IconButton ariaLabel="time" type="absences_times" viewOnly={true} />
          {isMultiDay ? (
            <MultiDayDuration startDateTime={start} endDateTime={end} isFullDayEvent={isFullDayEvent} />
          ) : (
            <SingleDayDuration startDateTime={start} endDateTime={end} isFullDayEvent={isFullDayEvent} />
          )}
        </Flex>
        <Flex className="external-event-details--row" gap="md">
          <IconButton ariaLabel="location" type="location" viewOnly={true} />
          <span className="external-event-details--row-label">{location || '-'}</span>
        </Flex>
        <Flex className="external-event-details--row" gap="md">
          <IconButton ariaLabel="groupName" type="shared_classEntity" viewOnly={true} />
          <span className="external-event-details--row-label">{groupName || '-'}</span>
        </Flex>
        <Flex className="external-event-details--row" gap="md">
          <IconButton ariaLabel="description" type="note" viewOnly={true} />
          <span className="external-event-details--row-label external-event-description">
            {parsedDescription || '-'}
          </span>
        </Flex>
      </div>
    </Flex>
  );
};

const SingleDayDuration = ({ startDateTime, endDateTime, isFullDayEvent }: Duration) => {
  return (
    <Flex className="external-event-details--duration" flexDirection="column" gap="sm">
      <span className="external-event-details--row-label">{startDateTime.format('dddd, DD. MMMM YYYY')}</span>
      {!isFullDayEvent && (
        <span className="external-event-details--row-label">{formatTimeRange(startDateTime, endDateTime)}</span>
      )}
    </Flex>
  );
};

const MultiDayDuration = ({ startDateTime, endDateTime, isFullDayEvent }: Duration) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="row" gap="md">
      <Flex flexDirection="column" gap="xxs">
        <span className="external-event-details--duration-label">
          {t('timetable.externalEvent.durationStartLabel')}
        </span>
        <span className="external-event-details--duration-label">{t('timetable.externalEvent.durationEndLabel')}</span>
      </Flex>
      <Flex flexDirection="column" gap="xxs">
        <span className="external-event-details--duration-date">{startDateTime.format('dddd, DD. MMMM YYYY')}</span>
        <span className="external-event-details--duration-date">{endDateTime.format('dddd, DD. MMMM YYYY')}</span>
      </Flex>
      {!isFullDayEvent && (
        <Flex flexDirection="column" gap="xxs">
          <span className="external-event-details--duration-time">{formatTime(startDateTime)}</span>
          <span className="external-event-details--duration-time">{formatTime(endDateTime)}</span>
        </Flex>
      )}
    </Flex>
  );
};

export default ExternalEventDetails;
