import { RadioProps } from 'antd/lib/radio';
import React, { MutableRefObject, Ref, useRef } from 'react';
import { Radio } from 'antd';

import { ITestComponentProps } from '@/types/test-component-props';

import './complex-radio.less';

export interface IComplexRadioProps extends RadioProps, ITestComponentProps {
  value: any;
  children?: any;
  onKeyUp?: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  tabIndex?: number;
  focusableRef?: Ref<HTMLLIElement>;

  [key: string]: any;
}

type RadioRefObject = MutableRefObject<HTMLLIElement | null>;

/**
 * Complex Radios should be used in combination with ComplexRadioGroup and are useful for richer contents
 * (e.g. custom components) beside the radio button. See the solution-view for example.
 * TODO: documentation in storybook
 */
export const ComplexRadio = (props: IComplexRadioProps) => {
  const { className, value, onKeyUp, onKeyDown, tabIndex, focusableRef } = props;
  const liElementRef = useRef<HTMLLIElement>(null) as RadioRefObject;

  const focus = () => {
    liElementRef.current && liElementRef.current.focus();
  };

  return (
    <li
      className={`untis-complex-radio${className ? ` ${className}` : ''}`}
      tabIndex={typeof tabIndex === 'undefined' ? -1 : tabIndex}
      ref={(ref) => {
        liElementRef.current = ref;

        if (focusableRef) {
          if (typeof focusableRef === 'function') {
            focusableRef(ref);
          } else {
            (focusableRef as RadioRefObject).current = ref;
          }
        }
      }}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
    >
      <Radio
        data-testid={props.testId}
        value={value}
        onChange={focus}
        tabIndex={-1}
        checked={props.checked}
        disabled={props.disabled}
      >
        {props.children}
      </Radio>
    </li>
  );
};

export default ComplexRadio;
