import React, { useLayoutEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { observer } from 'mobx-react-lite';
import ResizeObserver from 'resize-observer-polyfill';
import dayjs, { Dayjs } from 'dayjs';
import classNames from 'clsx';
import { t } from 'i18next';

import useStore from '@/hooks/useStore';
import { useComponentWillUnmount } from '@/hooks/useComponentWillUnmount';
import TimetableGridDay from '@te/standard/components/grid/day/timetable-grid-day';
import TimetableGridDayHeader from '@te/standard/components/grid/day/timetable-grid-day-header';
import TimetableGridSlot from '@te/standard/components/grid/slot/timetable-grid-slot';
import TimetableGridCurrentTime from '@te/standard/components/grid/current-time/timetable-grid-current-time';
import TimetableRestrictionLayer from '@te/standard/components/grid/restriction-layer/timetable-restriction-layer';
import { IWeekDayValue, TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import {
  TIME_GRID_START_DAY_OFFSET,
  TIMETABLE_GRID_PADDING_BOTTOM,
  TimetableGridDimensionsStore,
} from '@te/standard/stores/grid/timetable-grid-dimensions-store';
import { TimetableGridSlotsStore } from '@te/standard/stores/grid/timetable-grid-slots-store';
import { TimetableFilterStore } from '@te/standard/stores/filter/timetable-filter-store';
import { TimetableLegendFilterStore } from '@te/standard/stores/filter/timetable-legend-filter-store';
import {
  ITimetableRestrictionLayer,
  TimetableRestrictionLayerStore,
} from '@te/standard/stores/restriction/timetable-restriction-layer-store';

import './timetable-grid.less';

const TimetableGrid = observer(() => {
  const timetableMetaStore = useStore(TimetableMetaStore);
  const timetableGridDimensionsStore = useStore(TimetableGridDimensionsStore);
  const timetableGridSlotsStore = useStore(TimetableGridSlotsStore);
  const timetableRestrictionLayerStore = useStore(TimetableRestrictionLayerStore);
  const timetableFilterStore = useStore(TimetableFilterStore);
  const timetableLegendFilterStore = useStore(TimetableLegendFilterStore);

  const ref = useRef<HTMLDivElement>(null);
  const timetableGridRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => timetableGridRef.current,
  });
  timetableLegendFilterStore.setPrintHandler(handlePrint);

  const [resizeObserver] = useState<ResizeObserver>(
    () =>
      new ResizeObserver(() => {
        timetableGridDimensionsStore.setTimetableGridDimension({
          height: ref.current?.offsetHeight || 0,
          width: ref.current?.offsetWidth || 0,
        });
      }),
  );

  const { currentTimetableDays, timetableViewType } = timetableMetaStore;
  const { timeGridSlots } = timetableGridSlotsStore;
  const { timetableRestrictionLayers } = timetableRestrictionLayerStore;

  useLayoutEffect(() => {
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }
  });

  useComponentWillUnmount(() => {
    resizeObserver.disconnect();
  });

  const isToday = (dayValue: Dayjs): boolean => {
    return timetableViewType == 'week' && dayValue.isSame(dayjs(), 'd');
  };

  const timetableName = t('menu.menuItems.timetable.timetable');
  const selectedFilterItem = timetableFilterStore.selectedFilterItem;

  return (
    <div
      ref={timetableGridRef}
      className={classNames('timetable-grid', { 'is-today': timetableViewType == 'day' })}
      style={{ paddingBottom: TIMETABLE_GRID_PADDING_BOTTOM }}
      data-testid="timetable-grid"
    >
      {selectedFilterItem && (
        <div className="timetable-grid-print-header">
          {timetableName} - {selectedFilterItem?.displayName}
        </div>
      )}
      <div className="timetable-grid--headers-container">
        <TimetableGridDayHeaders timetableDays={currentTimetableDays} isToday={isToday} />
      </div>
      <div ref={ref} className="timetable-grid--content">
        <div className="timetable-grid--time-slots-container">
          {timeGridSlots.map((timeGridSlot, index) => (
            <>
              <TimetableGridSlot
                key={index}
                index={index}
                timeGridSlot={timeGridSlot}
                isFirstSlot={index === 0}
                isLastSlot={index === timeGridSlots.length - 1}
              />
              {/* bottom spacing allowing scrolling downwards, fully seeing both timetable contents & footer section */}
              {index === timeGridSlots.length - 1 && (
                <div
                  style={{
                    height: TIMETABLE_GRID_PADDING_BOTTOM,
                    width: '100%',
                    position: 'absolute',
                    top: timetableGridDimensionsStore.offsetFromStartTime(timeGridSlot.endTime),
                  }}
                />
              )}
            </>
          ))}
          <TimetableGridCurrentTime timetableGridRef={timetableGridRef} />
        </div>
        <TimetableGridDays
          restrictionLayers={timetableRestrictionLayers}
          timetableDays={currentTimetableDays}
          isToday={isToday}
        />
      </div>
    </div>
  );
});

const TimetableGridDayHeaders = (props: { timetableDays: IWeekDayValue[]; isToday: (dayJs: Dayjs) => boolean }) => {
  const { timetableDays, isToday } = props;

  return (
    <>
      <div className="timetable-grid--day-headers-helper" style={{ marginLeft: TIME_GRID_START_DAY_OFFSET }} />
      {timetableDays?.map((timeGridDay) => (
        <TimetableGridDayHeader
          key={timeGridDay.weekDayEnum.toString()}
          weekDay={timeGridDay}
          isToday={isToday(timeGridDay.dayValue)}
        />
      ))}
    </>
  );
};

const TimetableGridDays = (props: {
  restrictionLayers: ITimetableRestrictionLayer[];
  timetableDays: IWeekDayValue[];
  isToday: (dayJs: Dayjs) => boolean;
}) => {
  const { restrictionLayers, timetableDays, isToday } = props;

  return (
    <div className="timetable-grid--days-container">
      <div className="timetable-grid--day-content-helper" style={{ marginLeft: TIME_GRID_START_DAY_OFFSET }} />
      {restrictionLayers.map((timetableRestrictionLayer, index) => (
        <TimetableRestrictionLayer key={index} {...timetableRestrictionLayer} />
      ))}
      {timetableDays?.map((timeGridDay) => (
        <TimetableGridDay
          key={timeGridDay.weekDayEnum.toString()}
          timeGridDay={timeGridDay}
          isToday={isToday(timeGridDay.dayValue)}
        />
      ))}
    </div>
  );
};

export default TimetableGrid;
