import React from 'react';
import { action, computed, observable } from 'mobx';
import { FormInstance } from 'antd';
import { AxiosError } from 'axios';
import { t } from 'i18next';

import { SubjectViewApi } from '@/stores/api-store';
import {
  DepartmentRefDto,
  SubjectDto,
  SubjectFormDto,
  SubjectGroupRefDto,
  SubjectTypeRefDto,
  TeacherQualificationRefDto,
} from '@untis/wu-rest-view-api/api';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { booleanSorting, defaultSorting } from '@/utils/sorting/sorting-util';
import { IListViewAction, IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import { ISubjectFormSubject, SubjectForm } from '@/pages/master-data/subject/subject-form';
import { IDeprecatedSearchBarOption } from '@/ui-components/deprecated-search-bar/deprecated-search-bar';
import { createChunks } from '@/utils/array/array-util';
import { DeprecatedAbstractListViewStore } from '@/pages/master-data/common/deprecated-abstract-list-view-store';
import { ElementType } from '@/stores/rights-store';
export interface ISubjectRow extends ITableRowKey {
  id: number;
  name: string;
  longName: string;
  alternateName: string;
  text: string;
  subjectType: string;
  active: boolean;
  backColor: string;
}

export class SubjectStore extends DeprecatedAbstractListViewStore<ISubjectFormSubject> {
  @observable private _subjects: SubjectDto[] = [];
  @observable private _subjectForm: SubjectFormDto | undefined;
  @observable private _selectedOptions: IDeprecatedSearchBarOption[] = [];

  constructor(form: FormInstance<ISubjectFormSubject>) {
    super(form, { elementType: ElementType.SUBJECT });
    this.fetchData();
  }

  @action
  async fetchData() {
    await Promise.all([this.fetchSubjects(), this.fetchFormConfig()]);
    this.setIsDataLoading(false);
  }

  @action
  async fetchSubjects() {
    await SubjectViewApi.getSubjects(this.selectedDepartmentId).then((response) => {
      // todo: error handling
      this.setSubjects(response.data.subjects);
    });
  }

  @action
  async fetchFormConfig() {
    await SubjectViewApi.getSubjectForm().then((response) => {
      this._subjectForm = response.data;
    });
  }

  @computed
  get subjects(): SubjectDto[] {
    return this._subjects;
  }

  @computed
  get subjectRows(): ISubjectRow[] {
    return SubjectStore.mapSubjectsToRows(this._subjects);
  }

  @computed
  get filteredRows(): ISubjectRow[] {
    return this.subjectRows.filter((subject: ISubjectRow) =>
      this.containsSearchInput([
        subject.name,
        subject.longName,
        subject.alternateName,
        subject.text,
        subject.subjectType,
      ]),
    );
  }

  @computed
  get subjectTypes(): SubjectTypeRefDto[] {
    return this._subjectForm && this._subjectForm.subjectTypes ? this._subjectForm.subjectTypes : [];
  }

  @computed
  get departments(): DepartmentRefDto[] {
    return this._subjectForm && this._subjectForm.departments ? this._subjectForm.departments : [];
  }

  @computed
  get teachingQualifications(): TeacherQualificationRefDto[] {
    return this._subjectForm && this._subjectForm.teacherQualifications ? this._subjectForm.teacherQualifications : [];
  }

  @computed
  get subjectGroups(): SubjectGroupRefDto[] {
    return this._subjectForm && this._subjectForm.subjectGroups ? this._subjectForm.subjectGroups : [];
  }

  @computed
  get selectedOptions(): IDeprecatedSearchBarOption[] {
    return this._selectedOptions;
  }

  @action
  setSubjects(subjects: SubjectDto[]) {
    this._subjects = subjects;
  }

  @action
  onSubjectChanged(subject: SubjectDto) {
    this.setSubjects(this._subjects.map((s) => (s.id === subject.id ? subject : s)));
  }

  @action
  onSubjectsDeleted(deletedSubjectIds: number[]) {
    this.setSubjects(this._subjects.filter((s) => s.id && !deletedSubjectIds.includes(s.id)));
  }

  @action
  onSubjectCreated(subject: SubjectDto) {
    this.setSubjects([...this._subjects, subject]);
  }

  @action
  setSelectedOptions(options: IDeprecatedSearchBarOption[]) {
    this._selectedOptions = options;
  }

  openEditForm(row: ISubjectRow) {
    const subject = this.getSubjectDtoByRowId(row.id.toString());
    this.form.resetFields();
    if (subject) {
      this._modalStore.openModalDialog({
        title: `${t('general.subject')} ${subject.longName} (${subject.name})`,
        size: 'full-size',
        children: <SubjectForm subject={subject} store={this} />,
        containsForm: true,
      });
    }
  }

  @action.bound
  openCreateForm() {
    this.form.resetFields();
    this._modalStore.openModalDialog({
      children: <SubjectForm store={this} />,
      title: t('general.newSubject'),
      size: 'full-size',
      containsForm: true,
    });
  }
  @action
  updateSubject(subject: SubjectDto) {
    SubjectViewApi.updateSubject(subject)
      .then((result: any) => {
        this.onSubjectChanged(result.data);
      })
      .catch((error: any) => {
        this._notificationStore.error({
          title: t('general.subjectCouldNotBeEdited'),
          message: error.toString(),
        });
      });
  }

  @action
  deleteSubject(id: number) {
    this._modalStore
      .openDeletePrompt(t('general.deleteSubject'), t('general.deleteSubjectConfirmationQuestion'))
      .then((result) => {
        if (result) {
          SubjectViewApi.deleteSubject(id)
            .then(() => {
              this.onSubjectsDeleted([id]);
              this._notificationStore.success({ title: t('general.subjectDeleted') });
              this._modalStore.closeModal();
            })
            .catch((error) => {
              this._notificationStore.error({
                title: t('general.subjectCouldNotBeDeleted'),
                message: error.toString(),
              });
            });
        }
      });
  }

  @computed
  private get columnActions() {
    const actions = [
      {
        tooltip: t('general.edit'),
        onClick: (row: ISubjectRow) => this.openEditForm(row),
        icon: 'edit',
        className: 'edit-inline-button',
      },
    ];

    if (this.canDelete) {
      actions.push({
        tooltip: t('general.delete'),
        onClick: (row: ISubjectRow) => this.deleteSubject(row.id),
        icon: 'shared_trash',
        className: 'delete-inline-button',
      });
    }

    return actions;
  }

  @computed
  get columns(): Columns<ISubjectRow> {
    const cols: Columns<ISubjectRow> = [
      {
        type: ColumnType.Text,
        key: 'name',
        header: t('general.shortName'),
        sorter: (a, b) => defaultSorting(a.name, b.name),
      },
      {
        type: ColumnType.Text,
        key: 'longName',
        header: t('general.longName'),
        sorter: (a, b) => defaultSorting(a.longName, b.longName),
      },

      {
        type: ColumnType.Text,
        key: 'alternateName',
        header: t('general.aliasName'),
        sorter: (a, b) => defaultSorting(a.alternateName, b.alternateName),
      },
      {
        type: ColumnType.Text,
        key: 'text',
        header: t('general.text'),
        sorter: (a, b) => defaultSorting(a.text, b.text),
      },
      {
        type: ColumnType.Text,
        key: 'subjectType',
        header: t('general.subjectType'),
        sorter: (a, b) => defaultSorting(a.subjectType, b.subjectType),
      },
      {
        type: ColumnType.Color,
        key: 'backColor',
        header: t('general.backgroundColor'),
        color: 'backColor',
      },
      {
        type: ColumnType.Label,
        key: 'active',
        header: t('general.active'),
        align: 'center',
        sorter: (a, b) => booleanSorting(a.active, b.active),
        getTagProps: (subject) => ({
          type: subject.active ? 'success' : 'light-gray',
          text: subject.active ? t('general.active') : t('general.inactive'),
        }),
      },
    ];

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.OverlayHoverActions,
        key: 'actions',
        actionIcons: this.columnActions,
      });
    }

    return cols;
  }

  @computed
  get listViewActions(): IListViewAction[] {
    const actions: IListViewAction[] = [];
    if (this.canCreate) {
      actions.push({
        label: t('general.new'),
        onClick: this.openCreateForm,
        testId: 'new-button',
      });
    }
    actions.push({
      label: t('general.report'),
      onClick: () => {
        this.generateReport('Subject');
      },
      testId: 'report-button',
    });

    return actions;
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<ISubjectRow>[] | undefined {
    return this.canDelete
      ? [
          {
            label: t('general.delete'),
            icon: 'shared_trash',
            onClick: (rows) => {
              this._modalStore
                .booleanUserPrompt({
                  hasDestructiveAction: true,
                  title: t('general.deleteSubject', { count: rows.length }),
                  children: <div>{t('general.deleteSubjectConfirmationQuestion', { count: rows.length })}</div>,
                  showFooterSeparator: true,
                  okButton: {
                    label: t('general.delete'),
                  },
                  cancelButton: {
                    label: t('general.cancel'),
                  },
                })
                .then((result) => {
                  if (result) {
                    const chunks = createChunks<ISubjectRow>(rows, 100);
                    chunks.forEach((chunk) => {
                      SubjectViewApi.deleteSubjects(chunk.map((row) => row.id))
                        .then(() => {
                          this._notificationStore.success({
                            title: t('general.subjectsDeleted'),
                          });
                          this.onSubjectsDeleted(rows.map((row) => row.id));
                          this.setSelectedRowKeys([]);
                        })
                        .catch((error) => {
                          const axiosError: AxiosError = error as AxiosError;
                          if (axiosError.response?.status === 422) {
                            // const response: AxiosResponse<EntityRefDto[]> =
                            // axiosError.response as AxiosResponse<EntityRefDto[]>;
                            this._notificationStore.error({
                              title: t('general.someSubjectsCouldNotBeDeleted'),
                              message: t('general.subjectsInUseCanNotBeDeleted'),
                            });
                          } else {
                            this._notificationStore.error({
                              title: error.toString(),
                            });
                          }
                        });
                    });
                  }
                });
            },
          },
        ]
      : undefined;
  }

  getSubjectDtoByRowId = (id: string): SubjectDto | undefined => {
    return this._subjects.find((subject) => subject.id?.toString() === id);
  };

  private static mapSubjectsToRows(subjects: SubjectDto[]): ISubjectRow[] {
    return subjects.map((subject) => {
      return {
        id: subject.id ? subject.id : -1,
        key: subject.id ? subject.id : -1,
        name: subject.name,
        longName: subject.longName ? subject.longName : '',
        alternateName: subject.alternateName ? subject.alternateName : '',
        text: subject.text ? subject.text : '',
        subjectType: subject.subjectType?.displayName ? subject.subjectType.displayName : '',
        active: subject.active === true,
        backColor: subject.backColor || '',
      };
    });
  }
}
