import { action, computed, observable } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';

import { WeekdayEnum } from '@untis/wu-rest-view-api/api';
import { inject, Store } from '@/types/store';
import { TimetableGridDaysStore } from '@te/standard/stores/grid/timetable-grid-days-store';
import SchoolYearStore from '@/stores/schoolyear-store';

export type ITimetableViewType = 'week' | 'day';

export interface IWeekDayValue {
  weekDayEnum: WeekdayEnum;
  dayValue: Dayjs;
}

export const TIMETABLE_VIEW_TYPES: ITimetableViewType[] = ['week', 'day'];

@Store()
export class TimetableMetaStore {
  private timetableGridDaysStore = inject(TimetableGridDaysStore);
  private schoolYearStore = inject(SchoolYearStore);

  @observable private _timetableViewType: ITimetableViewType = 'week';
  @observable private _isInitialDateInCurrentSchoolYear: boolean = true;
  @observable private _selectedDate: Dayjs = dayjs();

  @action
  init(initialDate: Dayjs, timetableViewType: ITimetableViewType) {
    this._timetableViewType = timetableViewType;
    this.setSelectedDate(initialDate);
  }

  @action
  reset() {
    this._timetableViewType = 'week';
    this._selectedDate = dayjs();
  }

  @computed
  get timetableViewType(): ITimetableViewType {
    return this._timetableViewType;
  }

  @computed
  get selectedDate(): Dayjs {
    return this._selectedDate;
  }

  @computed
  get currentTimetableDays(): IWeekDayValue[] {
    const result: IWeekDayValue[] = [];
    let currentDay = this.startDate.clone();
    while (currentDay.isSameOrBefore(this.endDate)) {
      const weekDayEnum = this.timetableGridDaysStore.getTimeGridDay(currentDay);
      if (
        weekDayEnum &&
        this.timetableGridDaysStore.timetableFormatTimeGridDays.includes(weekDayEnum) &&
        this.isDateInCurrentSchoolYear(currentDay)
      ) {
        result.push({
          weekDayEnum: weekDayEnum,
          dayValue: currentDay.clone(),
        });
      }
      currentDay = currentDay.clone().add(1, 'day');
    }

    return result;
  }

  @computed
  get currentTimetableStartDate(): Dayjs {
    if (this.currentTimetableDays.length > 0) {
      return this.currentTimetableDays[0].dayValue;
    } else {
      return this.startDate;
    }
  }

  @computed
  get currentTimetableEndDate(): Dayjs {
    if (this.currentTimetableDays.length > 0) {
      return this.currentTimetableDays[this.currentTimetableDays.length - 1].dayValue;
    } else {
      return this.endDate;
    }
  }

  @computed
  private get startDate(): Dayjs {
    return this._selectedDate.clone().startOf(this._timetableViewType);
  }

  @computed
  private get endDate(): Dayjs {
    return this._selectedDate.clone().endOf(this._timetableViewType);
  }

  isDateInCurrentTimeGridDateRange(date: Dayjs): boolean {
    return (
      this.currentTimetableStartDate.isSameOrBefore(date, 'day') &&
      this.currentTimetableEndDate.isSameOrAfter(date, 'day')
    );
  }

  @action
  toggleViewType(date?: Dayjs) {
    if (this._timetableViewType === 'week') {
      if (date) {
        this.setSelectedDate(date);
      } else if (this.isDateInCurrentTimeGridDateRange(dayjs())) {
        this.setSelectedDate(dayjs());
      } else {
        this.setSelectedDate(this.startDate);
      }
    } else {
      this.setSelectedDate(this._selectedDate.startOf('week'));
    }

    this._timetableViewType === 'week' ? (this._timetableViewType = 'day') : (this._timetableViewType = 'week');
  }

  @action.bound
  selectDate(date: Dayjs) {
    this.setSelectedDate(date);
  }

  @action
  private setSelectedDate(date: Dayjs, recursionDepth?: number) {
    if (!this.timetableGridDaysStore.hasTimeGridDay(date)) {
      if ((recursionDepth ?? 0) < 7) {
        this.setSelectedDate(date.clone().add(1, 'days'), (recursionDepth ?? 0) + 1);
      }
    } else {
      this._selectedDate = date.clone().startOf('day');
    }
  }

  @action.bound
  isDateInCurrentSchoolYear(date: Dayjs): boolean {
    if (this.schoolYearStore.currentSchoolYear === undefined) {
      return false;
    }

    const adjacentSchoolYears = this.schoolYearStore.findAdjacentSchoolYears(
      this.schoolYearStore.currentSchoolYearStart ?? dayjs(),
    );

    const previousSchoolYearEnd = adjacentSchoolYears.past?.dateRange?.end;
    const nextSchoolYearStart = adjacentSchoolYears.next?.dateRange?.start;

    return (
      (previousSchoolYearEnd ? date.isAfter(previousSchoolYearEnd, 'day') : true) &&
      (nextSchoolYearStart ? date.isBefore(nextSchoolYearStart, 'day') : true)
    );
  }

  @computed
  get hasSelectedSchoolYear(): boolean {
    return this.schoolYearStore.currentSchoolYear?.id !== undefined;
  }

  @action
  setIsInitialDateInCurrentSchoolYear(isInitialDateInCurrentSchoolYear: boolean) {
    this._isInitialDateInCurrentSchoolYear = isInitialDateInCurrentSchoolYear;
  }

  @computed
  get isInitialDateInCurrentSchoolYear(): boolean {
    return this._isInitialDateInCurrentSchoolYear;
  }
}
