import { observable } from 'mobx';
import Axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { t } from 'i18next';

import LogoutBroadcastService from '../services/logout-broadcast-service';

import { LocalStorageContext, LocalStorageStore } from './local-storage-store';
import ModalStore from './modal-store';

import { getSubMenuItem, SubMenuItemEnum } from '@/components/menu/menu-items';
import EnvironmentStore from '@/stores/environment-store';
import RouterStore from '@/stores/router-store';
import { inject, Store } from '@/types/store';
import { ISubMenuItem } from '@/ui-components/navigation-bar/navigation-bar-store';
import {
  LAST_INTERACTION,
  LOCAL_STORAGE_SESSIONTIMEOUT,
  LOCAL_STORAGE_TOKEN,
  RESPONSE_HEADER_SESSION_DURATION_IN_MILLI,
} from '@/types/local-storage-ids';
import UntisNewsStore from '@/stores/untis-news-store';
import { createInteractionInformation } from '@/utils/session/session-extension-util';
import { IModalProps } from '@/ui-components/modal/modal';

// the maximum time left (of the expiration time) when a token should be refreshed (in seconds)
const TOKEN_REFRESH_TIME_LEFT = 10;

interface IToken {
  exp: number;
  iat: number;
  iss: string;
  sub: string;
  roles: string;
  per: string[];
  tenantId: string;
  userId: string;
  personId?: string;
}

export enum TokenPermission {
  SUBSTITUTION_PLANNING = 'sp',
  OWN_TEACHER_ABSENCE_CREATE = 'ta:c',
  OWN_TEACHER_ABSENCE_READ = 'ta:r',
  SP_OWN_ASK_TEACHER_REQUESTS = 'sp_atr',
  MESSAGES_READ = 'mg:r',
}

export enum TokenRole {
  ADMIN = 'ADMIN',
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',
}

@Store()
export default class TokenStore {
  tokenEndpoint!: string; // will be initialized in the init method

  private tokenExpiredTime: number = 0;
  private token: IToken | null = null;
  private sessionTimeout: number = 0;
  private modalTimeoutId: number = -1;
  @observable
  private permissions: TokenPermission[] = []; // Current Token Permissions, are being parsed everytime we retrieve a
  // new token
  @observable
  private roles: TokenRole[] = []; // Current Token Roles, are being parsed everytime we retrieve a new token

  private environment = inject(EnvironmentStore);

  private routerStore = inject(RouterStore);

  private untisNewsStore = inject(UntisNewsStore);

  private localStorageStore = inject(LocalStorageStore);

  private modalStore = inject(ModalStore);

  private logoutBroadcastChannelStore = inject(LogoutBroadcastService);

  async init() {
    this.logoutBroadcastChannelStore.registerHandleLogoutFromAllTabs();
    this.tokenEndpoint = this.environment.webUntisURL + '/api/token/new';
  }

  set tokenString(value: string) {
    window.localStorage.setItem(LOCAL_STORAGE_TOKEN, value);
  }

  get tokenString(): string {
    return window.localStorage.getItem(LOCAL_STORAGE_TOKEN) as string;
  }

  public async fetchToken(): Promise<string> {
    const response = await Axios.get(this.tokenEndpoint);
    const request = response.request;

    const wrongResponseURL = request && request.responseURL && request.responseURL !== this.tokenEndpoint;
    const hasSessionTimeout = response && response.data && !!response.data.isSessionTimeout;

    // we need to check the responseURL, because if the user doesn't have a valid WU Session WebUntis will redirect
    // to nosession.jsp and therefore we know that the user isn't logged in
    if (!wrongResponseURL && !hasSessionTimeout && response.status === 200) {
      const sessionDurationInMilliseconds = response.headers[RESPONSE_HEADER_SESSION_DURATION_IN_MILLI];
      this.handleSessionExtensionModal(sessionDurationInMilliseconds);
      return this.handleNewToken(response);
    } else {
      this.redirectToLogin();
      throw new Error('Error fetching the token from WebUntis');
    }
  }

  public isTokenExpiring(): boolean {
    if (this.token) {
      const currentTime = dayjs().unix();
      // calculating the token expire-time by subtracting the current time and the expired timestamp of the token
      // the time is measured in seconds (unix timestamps)
      const timeLeft = this.tokenExpiredTime - currentTime;
      if (timeLeft <= TOKEN_REFRESH_TIME_LEFT) {
        return true;
      }
    }

    return false;
  }

  public hasRoles(...roles: TokenRole[]) {
    for (const role of roles) {
      if (!this.roles.includes(role)) {
        return false;
      }
    }

    return true;
  }

  public isStudent = (): boolean => {
    return this.hasRoles(TokenRole.STUDENT);
  };

  public isTeacher = (): boolean => {
    return this.hasRoles(TokenRole.TEACHER);
  };

  public hasTokenPermissions(...permissions: TokenPermission[]) {
    for (const permission of permissions) {
      if (!this.permissions.includes(permission)) {
        return false;
      }
    }

    return true;
  }

  public getUserId(): number {
    if (this.token && this.token.userId) {
      const userId = +this.token.userId;
      if (!isNaN(userId)) {
        return userId;
      }
    }

    return -1;
  }

  public getPersonId(): number {
    if (this.token && this.token.personId) {
      const personId = +this.token.personId;
      if (!isNaN(personId)) {
        return personId;
      }
    }

    return -1;
  }

  /**
   * Adds a sub menu item to the given array if it is permitted by the token store (based on permissions)
   * @param item: the item that needs to be added if it is permitted
   * @param items: the array of items in which the item should be added
   * @param requiredPermissions: the array of permissions that are required to show this item
   * @param badge: a optional string that should be displayed in the menu item
   */
  public addSubMenuItemIfPermitted(
    item: SubMenuItemEnum,
    items: ISubMenuItem[],
    requiredPermissions: TokenPermission[],
    badge?: string,
  ) {
    if (this.hasTokenPermissions(...requiredPermissions)) {
      const subMenuItem = getSubMenuItem(item, badge);
      subMenuItem && items.push(subMenuItem);
    }
  }

  /**
   * Redirects to the base url with all the search params (e.g. school or wu_login for the sso redirection)
   * so the backend has more info when deciding about further redirection.
   */
  public redirectToLogin(): void {
    this.routerStore.rememberCurrentRoute();
    const currentUrl = new window.URL(window.location.href);
    const redirectUrl = new URL(this.environment.webUntisURL!);
    currentUrl.searchParams &&
      currentUrl.searchParams.forEach((value, name) => {
        redirectUrl.searchParams.append(name, value);
      });
    window.location.href = redirectUrl.toString();
  }

  public logout(): void {
    this.localStorageStore.deleteItem(LocalStorageContext.GLOBAL, LOCAL_STORAGE_TOKEN);
    sessionStorage.removeItem(LAST_INTERACTION);
    this.untisNewsStore.resetUser();
    window.location.replace(this.environment.webUntisURL + '/saml/logout');
  }

  private handleSessionExtensionModal(sessionDurationFromResponse: string) {
    const sessionDurationInMilliseconds = Number.parseInt(sessionDurationFromResponse);
    window.clearTimeout(this.modalTimeoutId);
    const modalTimeoutInMilliseconds: number = sessionDurationInMilliseconds - 60000;
    const currentInteraction = createInteractionInformation(dayjs(), dayjs(modalTimeoutInMilliseconds).minute());
    sessionStorage.setItem(LAST_INTERACTION, JSON.stringify(currentInteraction));

    this.modalTimeoutId = window.setTimeout(() => {
      const sessionModalProps: IModalProps = {
        title: t('general.sessionTimeoutModalHeadline'),
        children: t('general.sessionTimeoutModalDescription'),
        okButton: {
          label: t('general.sessionTimeoutModalButton'),
        },
        showFooterSeparator: true,
      };
      this.modalStore.booleanUserPrompt(sessionModalProps);
    }, modalTimeoutInMilliseconds);
  }

  private handleNewToken(response: AxiosResponse<any>) {
    this.tokenString = response.data as string;
    this.setToken();

    this.localStorageStore.writeString(
      LocalStorageContext.GLOBAL,
      LOCAL_STORAGE_SESSIONTIMEOUT,
      response.headers[RESPONSE_HEADER_SESSION_DURATION_IN_MILLI],
    );

    const sessionDurationInMilliseconds = Number.parseInt(response.headers[RESPONSE_HEADER_SESSION_DURATION_IN_MILLI]);

    if (!isNaN(sessionDurationInMilliseconds)) {
      window.clearTimeout(this.sessionTimeout);
      this.sessionTimeout = window.setTimeout(() => {
        this.logout();
        this.logoutBroadcastChannelStore.sendLogoutSessionEvent();
      }, sessionDurationInMilliseconds);
    }

    return this.tokenString;
  }

  private setToken() {
    this.token = this.parseToken(this.tokenString);
    this.tokenExpiredTime = dayjs().unix() + this.token.exp - this.token.iat; // Current time plus token valid time
    this.permissions = this.token.per as TokenPermission[];
    this.roles = this.token.roles.split(',') as TokenRole[];
    this.token.tenantId = this.token.tenantId || this.getTokenValue(this.token, 'tenant_id');
    this.token.userId = this.token.userId || this.getTokenValue(this.token, 'user_id');
    this.token.personId = this.token.personId || this.getTokenValue(this.token, 'person_id');
  }

  private getTokenValue(token: IToken, key: string): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return token[key] || '';
  }

  private parseToken = (token: string): IToken => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  };
}
