import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import { Button, WUForm } from '@/ui-components';
import { ClassInfoDto, SchoolYearClassInfoDto, StudentFormDto } from '@untis/wu-rest-view-api/api';
import { StudentStore } from '@/pages/master-data/student/student-store';
import { IFormButton } from '@/ui-components/wu-form/wu-form';
import {
  FormCheckboxes,
  FormColorPicker,
  FormDatePicker,
  FormLabel,
  FormRadioButtons,
  FormSection,
  FormSwitch,
  FormTextarea,
  WUFormCol,
  WUFormRow,
} from '@/ui-components/wu-form';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { TextValidationPatterns } from '@/utils/form/common-form-rules';
import { TestIds } from '@/testIds';
import { FormFileUpload } from '@/ui-components/wu-form/form-file-upload/form-file-upload';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { validateForm } from '@/utils/form/form-util';
import StudentFormStore from '@/pages/master-data/student/student-form-store';
import {
  DateRange,
  FormMultiDateRangePicker,
} from '@/ui-components/wu-form/form-multi-date-range-picker/form-multi-date-range-picker';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import { ChangeClassForm } from '@/pages/master-data/student/class/change-class-form';
import { StudentLessons } from '@/pages/master-data/student/lessons/student-lessons';
import { ISelectItem } from '@/ui-components/select/select';

interface IStudentFormProps {
  studentFormDto?: StudentFormDto;
  classes?: ClassInfoDto[];
  schoolYearClassInfo?: SchoolYearClassInfoDto[];
  store: StudentStore;
  isEditMode: boolean;
  studentImage?: string;
  onStudentClassUpdated?: (studentId: number, classInfo: ClassInfoDto) => void;
}

export interface IStudentForm {
  lastName: string;
  firstName: string;
  shortName: string;
  externalId: string;
  street: string;
  postCode: string;
  city: string;
  email: string;
  mobilePhone: string;
  phone: string;
  fax: string;
  birthDate: Dayjs;
  legalAge: string[];
  schoolAge: string[];
  dateRanges: DateRange[];
  studentAttributes: string[];
  gender: string;
  classId: string;
  status: boolean;
  certificate: string[];
  certificateDate: Dayjs;
  catalogueNumber: string;
  description: string;
  frontColor: string;
  backColor: string;
  studentImage: File;
}

export const StudentForm = observer((props: IStudentFormProps) => {
  const store = props.store;
  const modalStore = useStore(ModalStore);
  const studentFormStore = new StudentFormStore(props.store);
  const { t } = useTranslation();
  const disabled = !store.canEdit;

  const { isEditMode, studentFormDto } = props;

  const initialStudentEntryDates = (): DateRange[] => {
    const initialValues: DateRange[] = [];
    let showEmptyRow = true;

    if (studentFormDto && studentFormDto.entryExitDateInfo) {
      studentFormDto.entryExitDateInfo
        .filter((row) => {
          if (studentFormDto.entryExitDateInfo?.length && studentFormDto.entryExitDateInfo?.length > 1) {
            return !row.isMainRange;
          }
          return true;
        })
        .map((dates) => {
          if (!dates.exitDate) {
            showEmptyRow = false;
          }

          initialValues.push({
            startDate: dates.entryDate ? dayjs(dates.entryDate) : undefined,
            endDate: dates.exitDate ? dayjs(dates.exitDate) : undefined,
          });
        });
    }

    if (showEmptyRow) {
      return [
        ...initialValues,
        {
          startDate: undefined,
          endDate: undefined,
        },
      ];
    }

    return initialValues;
  };

  const medicalCertificateInitialCheckboxValue = studentFormDto?.medicalData?.hasCertificate ? ['certificate'] : [];
  const [disableCertificateDateChange, setDisableCertificateDateChange] = useState(
    !studentFormDto?.medicalData?.hasCertificate,
  );

  const leftButtons: IFormButton[] =
    store.canDelete && isEditMode
      ? [
          {
            label: t('general.delete'),
            onClick: () => {
              if (studentFormDto?.id) {
                store.deleteStudent(studentFormDto.id, studentFormDto.firstName ?? '', studentFormDto.lastName);
              }
            },
          },
        ]
      : [];

  const rightButtons: IFormButton[] = [];
  !isEditMode &&
    rightButtons.push({
      label: t('general.saveAndNew'),
      type: 'primary',
      disabled: store.submitAllDisabled,
      outline: false,
      onClick: () => {
        validateForm(store.form).then((isValid) => {
          if (isValid) {
            studentFormStore.handleSaveNewStudent(store.form.getFieldsValue(), studentFormDto, props.classes, true);
          }
        });
      },
    });

  rightButtons.push({
    label: disabled ? t('general.ok') : t('general.cancel'),
    onClick: () => {
      modalStore.closeModal();
    },
  });

  const studentLegalAgeInitialCheckboxValue = useMemo(() => {
    const values = [];
    if (studentFormDto?.ofLegalAge) {
      values.push('ofLegalAge');
    }
    return values;
  }, [studentFormDto]);

  const studentSchoolAgeInitialCheckboxValue = useMemo(() => {
    const values = [];
    if (studentFormDto?.ofSchoolAge) {
      values.push('ofSchoolAge');
    }
    return values;
  }, [studentFormDto]);

  const initialForegroundColor = useMemo(() => {
    if (studentFormDto?.foregroundColor) {
      return '#' + studentFormDto?.foregroundColor.toString(16);
    } else {
      return '#000000';
    }
  }, [studentFormDto?.foregroundColor]);

  const initialBackgroundColor = useMemo(() => {
    if (studentFormDto?.backgroundColor) {
      return '#' + studentFormDto?.backgroundColor.toString(16);
    } else {
      return '#000000';
    }
  }, [studentFormDto?.backgroundColor]);

  const genderInitialCheckboxValue = useMemo(() => {
    if (studentFormDto?.gender) {
      return studentFormDto.gender.toLowerCase();
    }

    return '';
  }, [studentFormDto?.gender]);

  const classes: ISelectItem[] = useMemo(() => {
    const items: ISelectItem[] = [];
    props.classes?.forEach((availableClass) => {
      items.push({
        id: String(availableClass.id),
        label: availableClass.name,
      });
    });

    return items;
  }, [props.classes]);

  const onValuesChange = (changedValues: Partial<IStudentForm>) => {
    if (changedValues.certificate) {
      if (changedValues.certificate.includes('certificate')) {
        setDisableCertificateDateChange(false);
      } else {
        setDisableCertificateDateChange(true);
      }
    }
  };

  const studentProperties = studentFormDto?.availableStudentAttributes;

  return (
    <WUForm<IStudentForm>
      form={store.form}
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      fixedButtonBar={true}
      hideSubmitButton={disabled}
      onValuesChange={(changedValues) => onValuesChange(changedValues)}
      onDisableChange={(disabled) => store.setSubmitAllDisabled(disabled)}
      onSubmit={
        isEditMode
          ? (form) => studentFormStore.handleUpdateStudent(form, studentFormDto, props.classes)
          : (form) => studentFormStore.handleSaveNewStudent(form, studentFormDto, props.classes, false)
      }
      maxWidth
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.profilePicture')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormFileUpload
              name="studentImage"
              variant="image"
              onClick={() => store.form?.setFields([{ name: 'file', errors: [] }])}
              onError={(error: string | undefined) =>
                store.form?.setFields([{ name: 'studentImage', errors: error ? [error] : [] }])
              }
              initialValue={props.studentImage}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection
              text={t('general.studentDetails.personalInformation')}
              description={'* ' + t('general.studentDetails.requiredField')}
            />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              label={t('general.lastname') + '*'}
              name="lastName"
              initialValue={studentFormDto?.lastName ?? ''}
              required
              maxLength={100}
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              disabled={disabled}
              testId={TestIds.STUDENT_LAST_NAME}
              variant="floating"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormInput
              label={`${t('general.firstname')}`}
              name="firstName"
              initialValue={studentFormDto?.firstName ?? ''}
              maxLength={60}
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              disabled={disabled}
              testId={TestIds.STUDENT_FIRST_NAME}
              variant="floating"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormInput
              label={t('general.studentDetails.shortName') + '*'}
              name="shortName"
              initialValue={studentFormDto?.shortName ?? ''}
              required
              maxLength={64}
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              disabled={disabled}
              testId={TestIds.STUDENT_SHORT_NAME}
              variant="floating"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormLabel text={t('general.studentDetails.externalIdHint')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              label={`${t('general.externalId')}`}
              name="externalId"
              initialValue={studentFormDto?.externKey ?? ''}
              maxLength={40}
              disabled={disabled}
              testId={TestIds.STUDENT_EXTERN_KEY}
              variant="floating"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.addressDetails')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              label={`${t('general.street')}`}
              name="street"
              initialValue={studentFormDto?.addressData?.street ?? ''}
              maxLength={100}
              disabled={disabled}
              testId={TestIds.STUDENT_ADDRESS_STREET}
              variant="floating"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <WUFormRow noMarginBottom>
              <WUFormCol removePadding lg={2} md={1}>
                <FormInput
                  label={`${t('general.postCode')}`}
                  name="postCode"
                  initialValue={studentFormDto?.addressData?.postCode ?? ''}
                  maxLength={20}
                  disabled={disabled}
                  testId={TestIds.STUDENT_ADDRESS_POST_CODE}
                  variant="floating"
                />
              </WUFormCol>

              <WUFormCol removePadding lg={2} md={1} lastNestedColumn>
                <FormInput
                  label={`${t('general.city')}`}
                  name="city"
                  initialValue={studentFormDto?.addressData?.city ?? ''}
                  maxLength={20}
                  disabled={disabled}
                  testId={TestIds.STUDENT_ADDRESS_CITY}
                  variant="floating"
                />
              </WUFormCol>
            </WUFormRow>
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormInput
              label={`${t('general.email')}`}
              name="email"
              initialValue={studentFormDto?.addressData?.email ?? ''}
              maxLength={40}
              disabled={disabled}
              testId={TestIds.STUDENT_ADDRESS_EMAIL}
              pattern={TextValidationPatterns.EMAIL}
              errorLabel={`${t('general.form.validation.invalidEmail')}`}
              variant="floating"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormInput
              label={`${t('general.mobilePhone')}`}
              name="mobilePhone"
              initialValue={studentFormDto?.addressData?.mobileNumber ?? ''}
              maxLength={20}
              disabled={disabled}
              testId={TestIds.STUDENT_ADDRESS_MOBILE}
              pattern={TextValidationPatterns.NUMBERS_ONLY}
              errorLabel={`${t('general.form.validation.invalidPhoneNumber')}`}
              variant="floating"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormInput
              label={`${t('general.phone')}`}
              name="phone"
              initialValue={studentFormDto?.addressData?.phoneNumber ?? ''}
              maxLength={20}
              disabled={disabled}
              testId={TestIds.STUDENT_ADDRESS_PHONE}
              pattern={TextValidationPatterns.NUMBERS_ONLY}
              errorLabel={`${t('general.form.validation.invalidPhoneNumber')}`}
              variant="floating"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormInput
              label={`${t('general.fax')}`}
              name="fax"
              initialValue={studentFormDto?.addressData?.fax ?? ''}
              maxLength={20}
              disabled={disabled}
              testId={TestIds.STUDENT_ADDRESS_FAX}
              pattern={TextValidationPatterns.NUMBERS_ONLY}
              errorLabel={`${t('general.form.validation.invalidPhoneNumber')}`}
              variant="floating"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.studentDetails.ageAndDependencies')} />
          </WUFormCol>

          <WUFormRow noMarginBottom>
            <WUFormCol lg={1} preventBreak>
              <FormDatePicker
                name="birthDate"
                label={t('general.birthDate')}
                initialValue={studentFormDto?.birthDate ? dayjs(studentFormDto?.birthDate) : undefined}
                testId={TestIds.STUDENT_BIRTH_DATE}
                disabled={disabled}
                removable={true}
                useWrapperWidth
              />
            </WUFormCol>
            <WUFormCol lg={7} flex={1} />
          </WUFormRow>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormLabel text={t('general.studentDetails.ofLegalAgeHint')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormCheckboxes
              name="legalAge"
              options={[
                {
                  label: t('general.studentDetails.ofLegalAge'),
                  value: 'ofLegalAge',
                },
              ]}
              initialValue={studentLegalAgeInitialCheckboxValue}
              testId={TestIds.STUDENT_LEGAL_AGE}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormCheckboxes
              name="schoolAge"
              options={[
                {
                  label: t('general.studentDetails.ofSchoolAge'),
                  value: 'ofSchoolAge',
                },
              ]}
              initialValue={studentSchoolAgeInitialCheckboxValue}
              testId={TestIds.STUDENT_LEGAL_AGE}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.gender')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormRadioButtons
              name="gender"
              options={
                new Map([
                  ['female', t('general.female')],
                  ['male', t('general.male')],
                  ['inter', t('general.thirdGender')],
                ])
              }
              initialValue={genderInitialCheckboxValue}
              display="vertical"
              testId={TestIds.STUDENT_GENDER}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={1}>
            <FormSection text={t('general.class')} />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormLabel text={t('general.studentDetails.classHint')} centered />
          </WUFormCol>
          <WUFormCol lg={5}>
            {!isEditMode && (
              <FormSingleSelect
                items={classes}
                placeholder={t('general.class')}
                name="classId"
                initialValue={studentFormDto?.classInfo?.name ?? undefined}
                disabled={disabled}
                testId={TestIds.STUDENT_CLASS}
                searchable
              />
            )}
            {isEditMode && (
              <FormInput
                label={`${t('general.class')}`}
                name="className"
                initialValue={studentFormDto?.classInfo?.name ?? ''}
                maxLength={20}
                disabled={true}
                testId={TestIds.STUDENT_CLASS}
                variant="floating"
              />
            )}
          </WUFormCol>
          <WUFormCol lg={8} />
          <WUFormCol lg={5}>
            {isEditMode && (
              <Button
                disabled={disabled}
                testId={TestIds.STUDENT_CHANGE_CLASS}
                type="secondary"
                outline
                size="small"
                style={{ position: 'absolute', top: '12%' }}
                onClick={() => {
                  modalStore.openModalDialog({
                    children: (
                      <ChangeClassForm
                        schoolYearClassInfo={props.schoolYearClassInfo ?? []}
                        studentId={studentFormDto?.id!}
                        onStudentClassUpdated={(studentId, classInfo) => {
                          store.form?.setFields([{ name: 'className', value: classInfo.name }]);
                          props.onStudentClassUpdated && props.onStudentClassUpdated(studentId, classInfo);
                        }}
                      />
                    ),
                    title: t('general.classChange.for') + ' ' + studentFormDto?.lastName,
                    size: 'full-size',
                    showHeaderSeparator: true,
                    containsForm: true,
                  });
                }}
              >
                {t('general.changeClass')}
              </Button>
            )}
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormLabel text={t('general.studentDetails.activeHint')} centered />
          </WUFormCol>
          <WUFormRow noMarginBottom>
            <WUFormCol lg={2} preventBreak>
              <FormLabel text={t('general.active')} alignLeft />
            </WUFormCol>
            <WUFormCol lg={2} flex={1}>
              <FormSwitch
                name="status"
                initialValue={studentFormDto?.active ?? true}
                centered
                testId={TestIds.STUDENT_ACTIVE_FLAG}
              />
            </WUFormCol>
          </WUFormRow>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormLabel text={t('general.studentDetails.entryExitDateHint')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormMultiDateRangePicker
              name="dateRanges"
              initialValue={initialStudentEntryDates()}
              removableDates
              removableRows
              startLabel={t('general.entryDate')}
              endLabel={t('general.exitDate')}
            />
          </WUFormCol>
        </WUFormRow>

        {isEditMode && (
          <WUFormRow>
            <WUFormCol lg={2}>
              <FormLabel text={t('general.studentDetails.showLessonsHint')} centered />
            </WUFormCol>
            <WUFormCol lg={2}>
              <Button
                disabled={disabled}
                testId={TestIds.STUDENT_SHOW_LESSONS}
                type="secondary"
                outline
                size="small"
                onClick={() => {
                  modalStore.openModalDialog({
                    children: (
                      <StudentLessons studentId={studentFormDto?.id!} schoolYear={store.getSchoolYearFilterValue()} />
                    ),
                    title:
                      t('general.studentDetails.lessonsOf') +
                      ' ' +
                      studentFormDto?.lastName +
                      ' ' +
                      studentFormDto?.firstName,
                    size: 'lesson-details',
                  });
                }}
              >
                {t('general.studentDetails.showLessons')}
              </Button>
            </WUFormCol>
          </WUFormRow>
        )}

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.studentDetails.additionalDetails')} />
          </WUFormCol>
          <WUFormCol lg={4}>
            <FormCheckboxes
              name="certificate"
              options={[
                {
                  label: t('general.studentDetails.certificate'),
                  value: 'certificate',
                },
              ]}
              initialValue={medicalCertificateInitialCheckboxValue}
              testId={TestIds.STUDENT_MEDICAL_CERTIFICATE}
              disabled={disabled}
            />
          </WUFormCol>
          <WUFormCol lg={5}>
            <FormDatePicker
              name="certificateDate"
              label={t('general.date')}
              initialValue={
                studentFormDto?.medicalData?.certificateDate
                  ? dayjs(studentFormDto?.medicalData?.certificateDate)
                  : undefined
              }
              testId={TestIds.STUDENT_MEDICAL_CERTIFICATE_DATE}
              disabled={disableCertificateDateChange}
              removable={true}
              useWrapperWidth
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormInput
              label={`${t('general.catalogueNumber')}`}
              name="catalogueNumber"
              initialValue={studentFormDto?.catalogueNumber ?? '0'}
              maxLength={20}
              disabled={disabled}
              testId={TestIds.STUDENT_CATALOGUE}
              pattern={TextValidationPatterns.NUMBERS_ONLY}
              errorLabel={`${t('general.form.validation.invalidNumber')}`}
              variant="floating"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormLabel text={t('general.studentDetails.textHint')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormTextarea
              name="description"
              initialValue={studentFormDto?.text ?? ''}
              testId={TestIds.STUDENT_DESCRIPTION}
              disabled={disabled}
              floatingLabel={t('general.studentDetails.enterDescriptionText')}
            />
          </WUFormCol>
        </WUFormRow>

        {studentProperties && studentProperties.length > 0 && (
          <WUFormRow>
            <WUFormCol lg={1}>
              <FormSection text={t('menu.menuItems.masterData.subMenuItems.studentProperties')} />
            </WUFormCol>
          </WUFormRow>
        )}

        {studentProperties &&
          studentProperties.sort().map((attribute, index) => {
            const isBooleanAttribute = attribute.type === 'boolean';
            const isSelectAttribute = attribute.type === 'select';
            const isTextAttribute = attribute.type === 'text';

            let textAttributeInitialValue = '';
            let booleanAttributeInitialValue = '';
            let selectAttributeInitialValue = undefined;
            let selectAttributeItems: ISelectItem[] = [];

            const foundAttribute = studentFormDto?.studentAttributes?.find((sa) => sa.name === attribute.name);

            if (isTextAttribute) {
              textAttributeInitialValue = foundAttribute ? foundAttribute?.value! : '';
            } else if (isBooleanAttribute) {
              booleanAttributeInitialValue = foundAttribute && foundAttribute?.value === 'true' ? 'checked' : '';
            } else if (isSelectAttribute) {
              selectAttributeItems = attribute.selectValues!.map((value, index) => {
                return {
                  id: String(index),
                  label: value,
                };
              });

              if (foundAttribute && foundAttribute.value) {
                selectAttributeInitialValue = String(Number(foundAttribute.value) - 1);
              }
            }

            return (
              <WUFormRow key={index}>
                <WUFormCol lg={2}>
                  <FormLabel text={attribute.name} centered />
                </WUFormCol>
                <WUFormCol lg={2}>
                  {isTextAttribute && (
                    <FormInput
                      label={`${t('general.studentDetails.propertyText')}`}
                      name={['studentAttributes', index]}
                      initialValue={textAttributeInitialValue}
                      maxLength={20}
                      disabled={disabled}
                      variant="floating"
                    />
                  )}
                  {isSelectAttribute && (
                    <FormSingleSelect
                      items={selectAttributeItems}
                      placeholder={`${t('general.studentDetails.selectAttribute')}`}
                      name={['studentAttributes', index]}
                      initialValue={selectAttributeInitialValue}
                      disabled={disabled}
                    />
                  )}
                  {isBooleanAttribute && (
                    <FormCheckboxes
                      name={['studentAttributes', index]}
                      options={[
                        {
                          label: '',
                          value: 'checked',
                        },
                      ]}
                      initialValue={[booleanAttributeInitialValue]}
                      testId={TestIds.STUDENT_MEDICAL_CERTIFICATE}
                      disabled={disabled}
                    />
                  )}
                </WUFormCol>
              </WUFormRow>
            );
          })}

        <WUFormRow>
          <WUFormCol lg={5}>
            <FormSection text={t('general.studentDetails.accountDetails')} />
          </WUFormCol>
          <WUFormCol lg={5} />
          <WUFormCol lg={7} />
          <WUFormCol lg={2}>
            <FormColorPicker
              name="frontColor"
              placeholder={t('general.foregroundColor')}
              initialValue={initialForegroundColor}
              testId={TestIds.STUDENT_FRONT_COLOR}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormColorPicker
              name="backColor"
              placeholder={t('general.backgroundColor')}
              initialValue={initialBackgroundColor}
              testId={TestIds.STUDENT_BACK_COLOR}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});
