import { useEffect } from 'react';

import useStore from './useStore';

import PostMessageStore from '@/pages/substitution-planning/stores/post-message-store';
import RefStore from '@/stores/ref-store';
import AppStore from '@/stores/app-store';

function waitFor<T>(element: T | null): Promise<T> {
  return new Promise((resolve) => {
    const wait = () => {
      if (element) {
        resolve(element);
      }
      window.requestAnimationFrame(wait);
    };
    wait();
  });
}

type UseRenderEmbeddedPageParams = {
  url: string;
};

export function useRenderLegacyPage({ url }: UseRenderEmbeddedPageParams) {
  const postMessageStore = useStore(PostMessageStore);
  const refStore = useStore(RefStore);
  const appStore = useStore(AppStore);

  useEffect(() => {
    appStore.setShowLegacyWebUntis(true);

    function handleIFrameOnLoad() {
      postMessageStore.postNavigationMessage(refStore.embeddedWebUntisIFrameRef, url, true);
    }

    async function navigate() {
      await waitFor<HTMLIFrameElement>(refStore.embeddedWebUntisIFrameRef.current);

      if (!refStore.embeddedWebUntisIFrameRef.current) return;

      refStore.embeddedWebUntisIFrameRef.current.addEventListener('load', handleIFrameOnLoad);

      // called when the iframe was already loaded
      handleIFrameOnLoad();
    }

    navigate();

    return () => {
      if (!refStore.embeddedWebUntisIFrameRef.current) return;

      refStore.embeddedWebUntisIFrameRef.current.removeEventListener('load', handleIFrameOnLoad);
    };
  }, [appStore, postMessageStore, refStore.embeddedWebUntisIFrameRef, url]);

  return null;
}
