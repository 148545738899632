import { action, computed, observable, reaction } from 'mobx';
import { flatten, isEmpty } from 'lodash';
import { t } from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

import TodayStore from '@today/stores/today-store';
import { inject, Store } from '@/types/store';
import { CalendarViewApi } from '@/stores/api-store';
import { dayJsFromDateTimeString, formatDate, formatDateForServerRequest } from '@/utils/date/date-util';
import { showErrorMessage } from '@/utils/error-handling/error-message';
import { IErrorResponseData } from '@/utils/error-handling/error-handling';
import ConfigStore from '@/stores/config-store';
import { CalendarDto, RoomStatusEnum, TeacherStatusEnum } from '@untis/wu-rest-view-api';
import {
  ILessonCardColorBar,
  ILessonCardProps,
  ILessonCardResourceWithChange,
  LessonCardRowContent,
} from '@/components/lesson-card/lesson-card';
import TodaySettingsStore from '@today/stores/today-settings-store';
import lessonsRoutesBuilder from '@/utils/router/lessons-routes-builder';
import { ElementType } from '@/stores/rights-store';
import ModalStore from '@/stores/modal-store';
import { buildCalendarEntryModalDialogProps } from '@/pages/calendar-entry/calendar-entry-detail-view/calendar-entry-detail-view';
import { DeprecatedDropDownItem } from '@/ui-components/deprecated-drop-down/drop-down';
import { LocalStorageContext, LocalStorageStore } from '@/stores/local-storage-store';
import SchoolYearStore from '@/stores/schoolyear-store';
import { CalendarKlasseDto, CalendarRoomDto, CalendarTeacherDto } from '@untis/wu-rest-view-api/api';
import { CommonTimetableDataMapper } from '@te/standard/stores/data/common-timetable-data-mapper';

const DROPDOWN_DEFAULT_KEY = 'my';
const DROPDOWN_CLASS_KEY_PREFIX = 'cl';
const LOCAL_STORAGE_LESSONS_DROPDOWN_MODE = 'lessons-dropdown-mode';

function formatSectionDate(date: Dayjs) {
  const formattedDay = date.isSame(dayjs(), 'day') // is today
    ? t('general.today')
    : date.isSame(dayjs().add(1, 'day'), 'day') // is tomorrow
    ? t('general.tomorrow')
    : date.format('dddd');

  return `${formattedDay}, ${formatDate(date)}`;
}

type LessonsWithLessonCardProps = {
  id: number;
  props: ILessonCardProps;
};

@Store()
class TodayLessonsStore {
  private configStore = inject(ConfigStore);
  private todayStore = inject(TodayStore);
  private todaySettingsStore = inject(TodaySettingsStore);
  private modalStore = inject(ModalStore);
  private localStorageStore: LocalStorageStore = inject(LocalStorageStore);
  private schoolYearStore: SchoolYearStore = inject(SchoolYearStore);
  private commonTimetableDataMapper: CommonTimetableDataMapper = inject(CommonTimetableDataMapper);

  // this is not a computed property because the caluculations take some time and
  // the rendering looks very laggy, so this is just being set directly in the getLessons
  // method
  @observable lessonsByDateWithLessonCardProps: Record<string, LessonsWithLessonCardProps[]> = {};
  @observable areLessonsLoading = true;
  @observable hasLessonLoadingError = false;
  @observable private _dropDownKey: string;
  @observable private _minStartDateLoaded: Dayjs | undefined;
  @observable private _maxEndDateLoaded: Dayjs | undefined;

  constructor() {
    this._dropDownKey =
      this.localStorageStore.readString(LocalStorageContext.TODAY, LOCAL_STORAGE_LESSONS_DROPDOWN_MODE) ||
      DROPDOWN_DEFAULT_KEY;
    lessonsRoutesBuilder.init({
      timetableView: 'default',
    });
    reaction(
      () => this.configStore.selectedStudentId,
      () => {
        this.reset();
        this.getLessons();
      },
    );
  }

  @action
  reset() {
    this._minStartDateLoaded = undefined;
    this._maxEndDateLoaded = undefined;
    this.lessonsByDateWithLessonCardProps = {};
    this.areLessonsLoading = false;
    this.hasLessonLoadingError = false;
  }

  @action
  async getLessons(backwards?: boolean) {
    const { startDate, endDate } = backwards ? this.calculatePrevDateRange() : this.calculateNextDateRange();
    if (this.configStore.isAdmin || this.needsToSelectAStudentFirst) {
      return;
    }
    if (!this.canLoadDateRange(startDate, endDate)) {
      this._minStartDateLoaded = startDate;
      this._maxEndDateLoaded = endDate;
      return;
    }
    try {
      this.areLessonsLoading = true;
      this.hasLessonLoadingError = false;

      const { data: lessons } = await CalendarViewApi.getViewCalendarEntryForToday(
        formatDateForServerRequest(startDate),
        formatDateForServerRequest(endDate),
        this.teacherId,
        this.studentId,
        this.classId,
      );

      const newLessons = {
        [formatSectionDate(startDate)]: this.getLessonsWithCardProps(lessons),
      };
      this.lessonsByDateWithLessonCardProps = backwards
        ? {
            ...newLessons,
            ...(this.lessonsByDateWithLessonCardProps ?? {}),
          }
        : {
            ...(this.lessonsByDateWithLessonCardProps ?? {}),
            ...newLessons,
          };

      if (backwards) {
        this._minStartDateLoaded = startDate;
      } else {
        this._maxEndDateLoaded = endDate;
      }

      return lessons;
    } catch (error) {
      showErrorMessage((error.response?.data as IErrorResponseData)?.errorMessage);
      this.hasLessonLoadingError = true;
    } finally {
      setTimeout(() => {
        this.areLessonsLoading = false;
      }, 250);
    }
  }

  @action.bound
  updateDropDownMode(key: string) {
    if (this._dropDownKey !== key) {
      this._dropDownKey = key;
      this.localStorageStore.writeString(LocalStorageContext.TODAY, LOCAL_STORAGE_LESSONS_DROPDOWN_MODE, key);
      this.reset();
      this.getLessons();
    }
  }

  @action.bound
  async loadNextLessons() {
    this.getLessons();
  }

  @action.bound
  async loadPreviousLessons() {
    this.getLessons(true);
  }

  getLessonsWithCardProps(lessons: CalendarDto[]): LessonsWithLessonCardProps[] {
    return lessons
      .filter((lesson) => {
        // consider putting this into the backend
        if (!this.settings.showPastLessons) {
          return dayjs().isSameOrBefore(lesson.endDateTime, 'minutes');
        }

        return true;
      })
      .map((lesson): LessonsWithLessonCardProps => {
        const colorBar: ILessonCardColorBar = {
          color: lesson.color ?? '',
        };
        return {
          id: lesson.id,
          props: {
            periodIds: [lesson.id],
            type: this.commonTimetableDataMapper.mapCalendarDtoTypeEnum(lesson.type),
            colorBar: colorBar,
            status: this.commonTimetableDataMapper.mapCalendarDtoStatusEnum(lesson.status),
            rows: this.getLessonCardRows(lesson),
            startDateTime: dayjs(lesson.startDateTime),
            endDateTime: dayjs(lesson.endDateTime),
            displayProps: {
              showIndicators: true,
              showSubstitutions: true,
              showStartEndTime: true,
              displayVariant: 'default',
            },
            onLessonClick: () => {
              this.modalStore.deprecatedOpenModalDialog(
                buildCalendarEntryModalDialogProps(
                  {
                    elementId: this.elementId,
                    elementType: this.elementType,
                    startDateTime: dayjs(lesson.startDateTime),
                    endDateTime: dayjs(lesson.endDateTime),
                    initialIsBlockSelected: false,
                    initialSelectedPeriodId: lesson.id,
                  },
                  'class-register',
                ),
              );
            },
            href: lessonsRoutesBuilder.buildPeriodDetailsHref({
              elementId: this.elementId,
              elementType: this.elementType,
              startDate: dayjs(lesson.startDateTime).format('YYYY-MM-DD'),
              startDateTime: lesson.startDateTime,
              endDateTime: lesson.endDateTime,
              initialIsBlockSelected: 'false',
              initialSelectedPeriodId: lesson.id,
              tab: 'class-register',
            }),
          },
        };
      });
  }

  @computed
  get isReady() {
    return this.todayStore.isReady;
  }

  @computed
  get elementType(): number {
    if (this.configStore.isLegalGuardian || this.configStore.isApprenticeRepresentatives || this.configStore.isParent) {
      return Number(ElementType.STUDENT);
    }

    return Number(this.configStore.elementType);
  }

  @computed
  get elementId(): number {
    if (this.configStore.isLegalGuardian || this.configStore.isApprenticeRepresentatives || this.configStore.isParent) {
      return this.studentId ?? -1;
    }

    return this.configStore.personId ?? -1; // -1 should never be a real value, because the user needs to be logged in
  }

  //

  @computed
  get settings() {
    return {
      showPastLessons: this.todaySettingsStore,
    };
  }

  get teacherId() {
    return undefined;
  }

  @computed
  get studentId() {
    if (
      (this.configStore.isLegalGuardian || this.configStore.isApprenticeRepresentatives) &&
      this.configStore.userStudents.length === 1
    ) {
      return this.configStore.userStudents[0].id;
    }

    return this.configStore.selectedStudentId > 0 ? this.configStore.selectedStudentId : undefined;
  }

  @computed
  get classId(): number | undefined {
    return (
      (this.modeDropDownKey.startsWith(DROPDOWN_CLASS_KEY_PREFIX) &&
        parseInt(this.modeDropDownKey.substr(DROPDOWN_CLASS_KEY_PREFIX.length))) ||
      undefined
    );
  }

  @computed
  get lessons() {
    return flatten(Object.values(this.lessonsByDateWithLessonCardProps).map((values) => [...values]));
  }

  @computed
  get lessonsEmpty() {
    return isEmpty(this.lessonsByDateWithLessonCardProps);
  }

  // scroll logic

  @computed
  get scrollUpDisabled() {
    return (
      (!this.hasLessonLoadingError && !this.areLessonsLoading && this.lessonsEmpty) ||
      this.areLessonsLoading ||
      this.schoolYearStartReached ||
      (this._minStartDateLoaded && !this.canLoadStartDate(this._minStartDateLoaded.clone().subtract(1, 'days')))
    );
  }

  @computed
  get scrollDownDisabled() {
    return (
      (!this.hasLessonLoadingError && !this.areLessonsLoading && this.lessonsEmpty) ||
      this.areLessonsLoading ||
      this.schoolYearEndReached ||
      (this._maxEndDateLoaded && !this.canLoadEndDate(this._maxEndDateLoaded.clone().add(1, 'days')))
    );
  }

  @computed
  get scrollUpDisabledLabel() {
    return this.areLessonsLoading || this.hasLessonLoadingError
      ? ''
      : this.schoolYearStartReached
      ? t('general.startOfSchoolyear')
      : t('general.lessonsCanNotBeDisplayedForDateRange');
  }

  @computed
  get scrollDownDisabledLabel() {
    return this.areLessonsLoading || this.hasLessonLoadingError
      ? ''
      : this.schoolYearEndReached
      ? t('general.endOfSchoolyear')
      : t('general.lessonsCanNotBeDisplayedForDateRange');
  }

  @computed
  get schoolYearStartReached() {
    return this._minStartDateLoaded?.isSameOrBefore(this.schoolYearStore.currentSchoolYearStart, 'days');
  }

  @computed
  get schoolYearEndReached() {
    return this._maxEndDateLoaded?.isSameOrAfter(this.schoolYearStore.currentSchoolYearEnd, 'days');
  }
  // LG/AR logic

  @computed
  get needsToSelectAStudentFirst() {
    return (
      (this.configStore.isLegalGuardian || this.configStore.isApprenticeRepresentatives) &&
      this.configStore.selectedStudentId === -1 &&
      this.configStore.userStudents.length > 1
    );
  }

  private getLessonCardRows(lesson: CalendarDto): LessonCardRowContent[] {
    return [
      {
        type: 'subject',
        content: [lesson.subject?.displayName ?? ''],
      },
      {
        type: 'klasse',
        content: this.mapClasses(lesson.klasses),
      },
      {
        type: 'teachers',
        content: this.mapTeachers(lesson.teachers),
      },
      {
        type: 'rooms',
        content: this.mapRooms(lesson.rooms),
      },
    ];
  }

  private mapClasses(classes: CalendarKlasseDto[]): ILessonCardResourceWithChange[] {
    return classes.map((klasse) => ({ regularResource: klasse.displayName }));
  }

  private mapTeachers(teachers: CalendarTeacherDto[]): ILessonCardResourceWithChange[] {
    const result: ILessonCardResourceWithChange[] = [];
    teachers.forEach((teacher) => {
      switch (teacher.status) {
        case TeacherStatusEnum.REGULAR:
          result.push({
            regularResource: teacher.displayName,
          });
          break;
        case TeacherStatusEnum.REMOVED:
          result.push({
            removedResource: teacher.displayName,
          });
          break;
        case TeacherStatusEnum.SUBSTITUTION:
          result.push({
            addedResource: teacher.displayName,
            removedResource: teacher.displayName,
          });
          break;
      }
    });
    return result;
  }

  private mapRooms(rooms: CalendarRoomDto[]): ILessonCardResourceWithChange[] {
    const result: ILessonCardResourceWithChange[] = [];
    rooms.forEach((room) => {
      switch (room.status) {
        case RoomStatusEnum.REGULAR:
          result.push({
            regularResource: room.displayName,
          });
          break;
        case RoomStatusEnum.REMOVED:
          result.push({
            removedResource: room.displayName,
          });
          break;
        case RoomStatusEnum.SUBSTITUTION:
          result.push({
            addedResource: room.displayName,
            removedResource: room.displayName,
          });
          break;
      }
    });
    return result;
  }

  @computed
  get modeDropDownItems() {
    const items: DeprecatedDropDownItem[] = [
      { key: DROPDOWN_DEFAULT_KEY, value: t('menu.menuItems.lessons.subMenuItems.my') },
    ];
    if (this.todayStore.calendarEntriesClasses) {
      items.push(
        ...this.todayStore.calendarEntriesClasses.map((c) => ({
          key: DROPDOWN_CLASS_KEY_PREFIX + c.id,
          value: c.name,
        })),
      );
    }
    return items;
  }

  @computed
  get modeDropDownKey(): string {
    return (
      (this._dropDownKey != DROPDOWN_DEFAULT_KEY &&
        this._dropDownKey.startsWith(DROPDOWN_CLASS_KEY_PREFIX) &&
        this.todayStore.calendarEntriesClasses.find((c) => DROPDOWN_CLASS_KEY_PREFIX + c.id == this._dropDownKey) &&
        this._dropDownKey) ||
      DROPDOWN_DEFAULT_KEY
    );
  }

  @computed
  get isModeDropDownVisible(): boolean {
    return this.modeDropDownItems.length > 1;
  }

  private calculateNextDateRange(): { startDate: Dayjs; endDate: Dayjs } {
    const date = this._maxEndDateLoaded ? this._maxEndDateLoaded.clone().add(1, 'days') : this.todayStore.today;
    return { startDate: date, endDate: date };
  }

  private calculatePrevDateRange(): { startDate: Dayjs; endDate: Dayjs } {
    const date = this._minStartDateLoaded
      ? this._minStartDateLoaded.clone().subtract(1, 'days')
      : this.todayStore.today.clone().subtract(1, 'days');
    return { startDate: date, endDate: date };
  }

  private canLoadStartDate(dayjs: Dayjs): boolean {
    return (
      !this.todayStore.calendarEntriesMinStart ||
      dayJsFromDateTimeString(this.todayStore.calendarEntriesMinStart).isSameOrBefore(dayjs, 'day')
    );
  }

  private canLoadEndDate(dayjs: Dayjs): boolean {
    return (
      !this.todayStore.calendarEntriesMaxEnd ||
      dayJsFromDateTimeString(this.todayStore.calendarEntriesMaxEnd).isSameOrAfter(dayjs, 'day')
    );
  }

  private canLoadDateRange(start: Dayjs, end: Dayjs): boolean {
    return this.canLoadStartDate(start) && this.canLoadEndDate(end);
  }
}

export default TodayLessonsStore;
