import { Dropdown, MenuProps } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import React, { PropsWithChildren, useMemo } from 'react';
import kebabCase from 'lodash/kebabCase';
import './option-popup.less';

export type Option = {
  label: string;
  onClick: (id?: string) => void;
};

export interface IPropsOptionPopup extends Pick<DropDownProps, 'placement'> {
  itemId?: string;
  options: Option[];
  // Additional handler that is called all option after the options click handler was executed
  onClickOption?: (option: Option) => void;
  onOpenChange?: (isOpen: boolean) => void;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
}

const OptionPopup = ({ options, itemId, onClickOption, ...props }: PropsWithChildren<IPropsOptionPopup>) => {
  const items: MenuProps['items'] = useMemo(
    () =>
      options.map((option) => ({
        key: option.label,
        label: option.label,
        onClick: () => {
          option.onClick(itemId);
          onClickOption?.(option);
        },
        'data-testid': `option-${kebabCase(option.label)}`,
      })),
    [itemId, onClickOption, options],
  );

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      placement={props.placement || 'bottomLeft'}
      overlayClassName="option-popup"
      className="options-popup-target"
      onOpenChange={props.onOpenChange}
      getPopupContainer={props.getPopupContainer}
    >
      {props.children}
    </Dropdown>
  );
};

export default OptionPopup;
