import React from 'react';
import { observable, autorun, computed } from 'mobx';

import { Store, inject } from '@/types/store';
import ModalStore from '@/stores/modal-store';
import { ExperimentalModalView } from '@/components/modal-views/experimental-modal-view/experimental-modal-view';
import { LocalStorageContext, LocalStorageStore } from '@/stores/local-storage-store';

export type Experiment = {
  id: string; // uuid
  title: string;
  description: string;
  url?: string;
  allowList: EXPERIMENTS_ALLOW_HOSTNAMES[];
};

export enum EXPERIMENTS_ALLOW_HOSTNAMES {
  WEBUNTIS_LOCALHOST = 'webuntis.localhost',
  WEBUNTIS_DOD = 'webuntis.dod',
  WEBUNTIS_LOCAL = 'webuntis.local',
  WEBUNTIS_MINI = 'webuntis.mini',
  WEBUNTIS_NIGHTLY = 'nightly.webuntis.com',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultAllowList = [
  EXPERIMENTS_ALLOW_HOSTNAMES.WEBUNTIS_LOCALHOST,
  EXPERIMENTS_ALLOW_HOSTNAMES.WEBUNTIS_DOD,
  EXPERIMENTS_ALLOW_HOSTNAMES.WEBUNTIS_LOCAL,
  EXPERIMENTS_ALLOW_HOSTNAMES.WEBUNTIS_MINI,
  EXPERIMENTS_ALLOW_HOSTNAMES.WEBUNTIS_NIGHTLY,
];

const ACTIVE_EXPERIMENTS_KEY = 'active';

@Store()
export class ExperimentalStore {
  @observable enabledExperimentsIds: string[] = [];

  @observable isModalOpen = false;

  private modalStore = inject(ModalStore);
  private localStorageStore = inject(LocalStorageStore);

  constructor() {
    const defaultEnabledExperimentsIds: string[] = [];

    this.enabledExperimentsIds =
      this.localStorageStore.readJSON(LocalStorageContext.EXPERIMENTS, ACTIVE_EXPERIMENTS_KEY) ??
      defaultEnabledExperimentsIds;

    this.enabledExperimentsIds = this.enabledExperimentsIds.filter((id) => this.isExperimentAllowed(id));
  }

  public async toggleModal() {
    if (!this.experimentsAllowed) {
      return;
    }

    if (this.isModalOpen) {
      this.modalStore.closeModal();
      this.isModalOpen = false;
      return;
    }

    this.isModalOpen = true;
    await this.modalStore.openModalDialog({
      title: 'WebUntis Experimental Settings',
      children: <ExperimentalModalView />,
    });
    this.isModalOpen = false;
  }

  public enableExperiment(experimentId: string) {
    if (this.enabledExperimentsIds.includes(experimentId)) {
      return;
    }
    this.enabledExperimentsIds.push(experimentId);
  }

  public disableExperiment(experimentId: string) {
    this.enabledExperimentsIds = this.enabledExperimentsIds.filter((id) => experimentId !== id);
  }

  public getExperimentById(experimentId: string) {
    return this.experiments.find((experiment) => experiment.id === experimentId);
  }

  public isExperimentAllowed(experimentId: string) {
    const experiment = this.getExperimentById(experimentId);
    if (!experiment) {
      return false;
    }

    return experiment.allowList.includes(window.location.hostname as EXPERIMENTS_ALLOW_HOSTNAMES);
  }

  @computed
  get experimentsAllowed() {
    return this.experiments.reduce((allowed, experiment) => allowed || this.isExperimentAllowed(experiment.id), false);
  }

  @computed
  get experimentsIds() {
    // use UUIDs, you can generate them for example here https://www.uuidgenerator.net/version4
    return {
      RESPONSIVENESS_THRESHOLDS: '51545d8e-b1ed-44c9-9bb4-90cf4cac84dc',
    };
  }

  @computed
  get experiments(): Experiment[] {
    return [
      {
        id: this.experimentsIds.RESPONSIVENESS_THRESHOLDS,
        title: 'Responsiveness',
        description: 'Enables display of Responsiveness thresholds, when the screen width changes.',
        allowList: defaultAllowList,
      },
    ];
  }

  @computed
  get allowedExperiments(): Experiment[] {
    return this.experiments.filter((experiment) => this.isExperimentAllowed(experiment.id));
  }

  @computed
  get isResponsivenessThresholdHintEnabled() {
    return this.enabledExperimentsIds.includes(this.experimentsIds.RESPONSIVENESS_THRESHOLDS);
  }

  // do not remove, this is used to react to mobx store changes and save to localStorage
  private saveToLocalStorage = autorun(() => {
    if (this.enabledExperimentsIds.length === 0) {
      this.localStorageStore.writeJSON(LocalStorageContext.EXPERIMENTS, ACTIVE_EXPERIMENTS_KEY, []);
      return;
    }

    this.localStorageStore.writeJSON(
      LocalStorageContext.EXPERIMENTS,
      ACTIVE_EXPERIMENTS_KEY,
      this.enabledExperimentsIds,
    );
  });
}
