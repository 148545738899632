import React from 'react';
import { Tag } from 'antd';

import './recipient-tag.less';

interface IRecipientTagProps {
  name: string;
  closable?: boolean;
  handleClose?: () => void;
}

const RecipientTag = ({ name, closable, handleClose }: IRecipientTagProps) => {
  return (
    <Tag className="recipient-tag" closable={closable ?? true} onClose={handleClose}>
      <span className="recipient-tag--name">{name}</span>
    </Tag>
  );
};

export { RecipientTag };
