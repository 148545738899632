import { action, observable } from 'mobx';

import { inject, Store } from '@/types/store';
import ConfigStore from '@/stores/config-store';
import {
  LOCAL_STORAGE_PAGE_REFRESH_FORCED,
  LOCAL_STORAGE_SESSIONTIMEOUT,
  LOCAL_STORAGE_TOKEN,
  SHOW_SCHOOLYEAR_GAP_DIALOG,
  TIMETABLE_FILTERS,
} from '@/types/local-storage-ids';

/* Context should help us to clarify the purpose of a stored entry (global? module? only a page?)
 and also help us to avoid overwriting other entries with the same name by accident */
export enum LocalStorageContext {
  GLOBAL = 'GLOBAL', // use this if your entry is supposed to be used application wide
  OPEN_PERIODS = 'OPEN_PERIODS', // entries for OPEN_PERIODS_PAGE,
  TODAY = 'TODAY', // entries for Today page
  EXPERIMENTS = 'EXPERIMENTS', // entries for experiments
  ANALYTICS = 'ANALYTICS', // entries for analytics
  TIMETABLE_CLASS = 'TIMETABLE_CLASS', // entries for the class timetable
  TIMETABLE_TEACHER = 'TIMETABLE_TEACHER', // entries for the teacher timetable
  TIMETABLE_ROOM = 'TIMETABLE_ROOM', // entries for the room timetable
  TIMETABLE_RESOURCE = 'TIMETABLE_RESOURCE', // entries for the resource timetable
  TIMETABLE_STUDENT = 'TIMETABLE_STUDENT', // entries for the student timetable
  TIMETABLE_SUBJECT = 'TIMETABLE_SUBJECT', // entries for the subject timetable
  TIMETABLE_CLASS_MY = 'TIMETABLE_CLASS_MY', // entries for the user's own timetable
  TIMETABLE_STUDENT_MY = 'TIMETABLE_STUDENT_MY', // entries for the user's own timetable
  TIMETABLE_TEACHER_MY = 'TIMETABLE_TEACHER_MY', // entries for the user's own timetable
}

interface ILocalStorageEntry {
  context: LocalStorageContext;
  name: string;
  value: string; // can contain anything (string | stringified numbers/booleans/JSON...)
  userId: number;
  tenantId: number;
}

interface IValidStorageEntry {
  context: LocalStorageContext;
  name: string;
}

const validEntries: IValidStorageEntry[] = [
  { context: LocalStorageContext.OPEN_PERIODS, name: 'date-range-picker-value' },
  { context: LocalStorageContext.TODAY, name: 'lessons-dropdown-mode' },
  { context: LocalStorageContext.EXPERIMENTS, name: 'active' },
  { context: LocalStorageContext.ANALYTICS, name: 'config' },
  { context: LocalStorageContext.TIMETABLE_CLASS, name: TIMETABLE_FILTERS },
  { context: LocalStorageContext.TIMETABLE_TEACHER, name: TIMETABLE_FILTERS },
  { context: LocalStorageContext.TIMETABLE_ROOM, name: TIMETABLE_FILTERS },
  { context: LocalStorageContext.TIMETABLE_RESOURCE, name: TIMETABLE_FILTERS },
  { context: LocalStorageContext.TIMETABLE_STUDENT, name: TIMETABLE_FILTERS },
  { context: LocalStorageContext.TIMETABLE_SUBJECT, name: TIMETABLE_FILTERS },
  { context: LocalStorageContext.TIMETABLE_CLASS_MY, name: TIMETABLE_FILTERS },
  { context: LocalStorageContext.TIMETABLE_STUDENT_MY, name: TIMETABLE_FILTERS },
  { context: LocalStorageContext.TIMETABLE_TEACHER_MY, name: TIMETABLE_FILTERS },
  { context: LocalStorageContext.GLOBAL, name: SHOW_SCHOOLYEAR_GAP_DIALOG },
];

/**
 * Valid items of the local storage, that is not managed by this store - therefore this store should
 * not delete them
 */
const validItems: string[] = [LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_PAGE_REFRESH_FORCED, LOCAL_STORAGE_SESSIONTIMEOUT];

const isLocalStorageEnabled = () => {
  return !!window.localStorage;
};

/**
 * Store to read and write entries to the devices local storage per user.
 * Use this store to access the localstorage. It also considers userId and schoolId of the user, to avoid
 * overwriting other users settings, if multiple people use the same device.
 * Only use the localstorage for insensitive data.
 * Keep in mind that the localstorage is a persistent storage.
 * To minimise number of accesses to the localstorage (= best practise), all entries are read after the users logs in
 * and kept in synch when writing.
 * If an entry that is read is not included in the Array of validEntries, it will be deleted from the device. This is
 * how we can get rid of entries, that might be removed in the future.
 */
@Store()
export class LocalStorageStore {
  @observable private _entries: ILocalStorageEntry[] = [];
  @observable public _configStore = inject(ConfigStore);
  @observable private _internalStorage: Map<string, string> = new Map();
  @observable private _validEntries: IValidStorageEntry[] = validEntries;
  @observable private _isLocalStorageAvailable: boolean = false;
  @observable private _isInitialised: boolean = false;

  constructor() {
    this._isLocalStorageAvailable = isLocalStorageEnabled();
  }

  @action.bound
  readAllAndRemoveInvalidEntries() {
    this._isLocalStorageAvailable = isLocalStorageEnabled();
    this._internalStorage = new Map();

    if (!this._isLocalStorageAvailable) {
      return;
    }

    const keys = Object.keys(window.localStorage);
    for (let i = 0; i < keys.length; i++) {
      const key: string = keys[i];
      const value = window.localStorage.getItem(key)!;
      this._internalStorage.set(key, value);
    }

    const validEntriesIdentifiers = this._validEntries.map((entry) => `${entry.context}-${entry.name}`);

    this._internalStorage.forEach((value, key) => {
      const isValidEntry = validEntriesIdentifiers.some((item) => key.includes(item));
      const isValidItem = validItems.some((item) => key.includes(item));
      if (!isValidItem && !isValidEntry) {
        window.localStorage.removeItem(key);
        this._internalStorage.delete(key);
      }
    });

    this._isInitialised = true;
  }

  @action.bound
  setValidEntries(validEntries: IValidStorageEntry[]) {
    this._validEntries = validEntries;
  }

  getKey(context: LocalStorageContext, name: string): string {
    const userId = this._configStore.userId;
    const tenantId = this._configStore.tenantId;
    return `${tenantId}-${userId}-${context}-${name}`;
  }

  @action.bound
  writeJSON(context: LocalStorageContext, name: string, value: object) {
    if (!this._isLocalStorageAvailable) {
      return;
    }
    window.localStorage.setItem(this.getKey(context, name), JSON.stringify(value));
    this._internalStorage.set(this.getKey(context, name), JSON.stringify(value));
  }

  @action.bound
  writeBoolean(context: LocalStorageContext, name: string, value: boolean) {
    if (!this._isLocalStorageAvailable) {
      return;
    }
    window.localStorage.setItem(this.getKey(context, name), value.toString());
    this._internalStorage.set(this.getKey(context, name), value.toString());
  }

  @action.bound
  writeString(context: LocalStorageContext, name: string, value: string) {
    if (!this._isLocalStorageAvailable) {
      return;
    }
    window.localStorage.setItem(this.getKey(context, name), value);
    this._internalStorage.set(this.getKey(context, name), value);
  }

  @action.bound
  writeNumber(context: LocalStorageContext, name: string, value: number) {
    if (!this._isLocalStorageAvailable) {
      return;
    }
    window.localStorage.setItem(this.getKey(context, name), value.toString());
    this._internalStorage.set(this.getKey(context, name), value.toString());
  }

  @action.bound
  readJSON(context: LocalStorageContext, name: string) {
    const value = this._internalStorage.get(this.getKey(context, name));
    if (!value) {
      return undefined;
    }

    try {
      return JSON.parse(value);
    } catch (e) {
      return undefined;
    }
  }

  @action.bound
  readString(context: LocalStorageContext, name: string): string | undefined {
    const value = this._internalStorage.get(this.getKey(context, name));
    if (!value) {
      return undefined;
    }

    return value;
  }

  @action.bound
  readBoolean(context: LocalStorageContext, name: string): boolean | undefined {
    const value = this._internalStorage.get(this.getKey(context, name));
    if (!value || (value.toLocaleLowerCase() !== 'true' && value.toLocaleLowerCase() !== 'false')) {
      return undefined;
    }

    return value.toLowerCase() === 'true';
  }

  @action.bound
  readNumber(context: LocalStorageContext, name: string): number | undefined {
    const value = this._internalStorage.get(this.getKey(context, name));
    if (!value || Number.isNaN(Number(value))) {
      return undefined;
    }

    return Number(value);
  }

  @action.bound
  deleteItem(context: LocalStorageContext, name: string) {
    if (!this._isLocalStorageAvailable) {
      return;
    }
    this._internalStorage.delete(this.getKey(context, name));
    window.localStorage.removeItem(this.getKey(context, name));
  }
}
