import { useTranslation } from 'react-i18next';
import React from 'react';
import { Tooltip, Switch } from 'antd';
import { useObserver } from 'mobx-react-lite';

import TodaySupportAccessStore from '@today/stores/today-support-access-store';
import useStore from '@/hooks/useStore';

import './support-access-toggle.less';

export function SupportAccessToggle() {
  const todaySupportAccessStore = useStore(TodaySupportAccessStore);
  const { t } = useTranslation();

  return useObserver(() => (
    <div className="today-page-support-access-toggle">
      <label htmlFor="support-access-toggle">{t('general.supportAccess')}</label>
      <Tooltip title={todaySupportAccessStore.supportAccessCta}>
        <Switch
          id="support-access-toggle"
          disabled={todaySupportAccessStore.isSupportAccessBeingChanged}
          checked={todaySupportAccessStore.isSupportAccessOpen}
          onChange={todaySupportAccessStore.handleSupportAccessChange}
        />
      </Tooltip>
    </div>
  ));
}
