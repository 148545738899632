import { action, observable, computed } from 'mobx';
import { t } from 'i18next';

import TodayPermissionsStore from './today-permissions-store';

import { Store, inject } from '@/types/store';
import { SystemSettingsApi } from '@/stores/api-store';
import { data } from '@/pages/messages/tests/helpers/recipient-picker-helpers';
import { SystemLockingDto, LockingStateEnum, LockingTypeEnum } from '@untis/wu-rest-view-api';
import { ILinkCardProps } from '@/ui-components/link-card/link-card';

@Store()
class TodayLockingStore {
  private todayPermissionsStore = inject(TodayPermissionsStore);

  @observable systemLockings: SystemLockingDto[] = [];

  @action
  async getLockingDetails() {
    if (
      !this.todayPermissionsStore.canSeeSystemLockingNotice &&
      !this.todayPermissionsStore.canSeeBookingLockingNotice
    ) {
      return;
    }

    try {
      const { data } = await SystemSettingsApi.getLockingDetails();
      this.systemLockings = data;
    } catch (error) {}

    return data;
  }

  @computed
  get quickLinks(): ILinkCardProps[] {
    const linkCards: ILinkCardProps[] = [];

    this.systemLockings
      .filter((systemLocking) => systemLocking.lockingState === LockingStateEnum.LOCKED)
      .forEach((systemLocking) => {
        switch (systemLocking.lockingType) {
          case LockingTypeEnum.SYSTEM:
            if (!this.todayPermissionsStore.canSeeSystemLockingNotice) {
              return;
            }
            linkCards.push({
              title: t('general.systemLocked', {
                user: systemLocking.lockingUserDto?.lockingUserName,
              }),
              subtitle: t('general.unlockNow'),
              icon: 'alert',
              iconColor: 'red',
              href: '/system-lock',
            });
            break;
          case LockingTypeEnum.BOOKING:
          default:
            if (!this.todayPermissionsStore.canSeeBookingLockingNotice) {
              return;
            }
            linkCards.push({
              title: t('general.bookingSystemlocked', {
                user: systemLocking.lockingUserDto?.lockingUserName,
              }),
              subtitle: this.todayPermissionsStore.canWriteBookingLockingNotice ? t('general.unlockNow') : undefined,
              icon: 'alert',
              iconColor: 'red',
              href: this.todayPermissionsStore.canWriteBookingLockingNotice ? '/booking-lock' : undefined,
            });
        }
      });

    return linkCards;
  }
}

export default TodayLockingStore;
