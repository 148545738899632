import { action } from 'mobx';

import { ProfileViewApi } from '../../../stores/api-store';

import { UpdateAdminDetailsDto } from '@untis/wu-rest-view-api';
import { inject } from '@/types/store';
import ModalStore from '@/stores/modal-store';
import { showErrorMessage } from '@/utils/error-handling/error-message';

export interface IAdminLoginFormData {
  username: string;
  password: string;
  email: string;
  systemEmail: string;
  passwordRetype: string;
}

export class AdminLoginStore {
  private modalStore = inject(ModalStore);

  @action
  async createAdminLogin(formValue: IAdminLoginFormData): Promise<void> {
    const adminDto = this.mapToDto(formValue);
    return ProfileViewApi.updateAdminDetails(adminDto)
      .then(() => {
        this.modalStore.closeModal();
      })
      .catch((error) => {
        showErrorMessage(error?.response?.data?.errorMessage);
      });
  }

  mapToDto(formValue: IAdminLoginFormData): UpdateAdminDetailsDto {
    return {
      username: formValue.username,
      password: formValue.password,
      email: formValue.email,
      systemEmail: formValue.systemEmail,
    };
  }
}
