import { ReactElement, useEffect, useRef, useState } from 'react';

/**
 * Returns a render function that renders nothing (null) until a certain
 * time has passed (delay).
 * After the delay, it renders the given loading indicator
 * This can be used to prevent flickering when loading is fast.
 * @param isLoading
 * @param delay
 */
export const useRenderDelayedLoadingIndicator = (isLoading: boolean, delay?: number) => {
  const [delayed, setDelayed] = useState(true);
  const [timeoutRef] = useState(useRef<NodeJS.Timeout>()); // to prevent infinity loops

  useEffect(() => {
    if (isLoading) {
      setDelayed(true);
      timeoutRef.current = setTimeout(() => setDelayed(false), delay ? delay : 200);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  }, [isLoading, timeoutRef, delay]);

  return (loadingIndicator: ReactElement) => {
    return !delayed && loadingIndicator;
  };
};
