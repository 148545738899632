import React, { ReactNode } from 'react';
import clsx from 'clsx';

import DeprecatedDropDown, { IDeprecatedDropDownProps } from '@/ui-components/deprecated-drop-down/drop-down';
import { Input } from '@/ui-components';
import { getFinalDomainParts } from '@pa/utils/platform-utils';
import { IInputProps } from '@/ui-components/input/input';

export enum RedirectUriInputType {
  TextInput,
  DropDown,
  Text,
}

interface IRedirectUriTextInputProps {
  text: string;
  className?: string;
}

type RedirectUriItemProps = IInputProps | IDeprecatedDropDownProps | IRedirectUriTextInputProps;

interface IRedirectUriItem {
  type: RedirectUriInputType;
  props: RedirectUriItemProps;
}

export interface IRedirectUriTextInput extends IRedirectUriItem {
  type: RedirectUriInputType.TextInput;
  props: IInputProps;
}

export interface IRedirectUriDropDown extends IRedirectUriItem {
  type: RedirectUriInputType.DropDown;
  props: IDeprecatedDropDownProps;
}

export interface IRedirectUriText extends IRedirectUriItem {
  type: RedirectUriInputType.Text;
  props: IRedirectUriTextInputProps;
}

export type RedirectUriItem = IRedirectUriTextInput | IRedirectUriDropDown | IRedirectUriText;
type RedirectUriItems = RedirectUriItem[];

export const mapRedirectUriItemsToReactNodes = (items: RedirectUriItems): ReactNode => {
  return items.map((item, index) => {
    const className = clsx('redirect-uri-input-item', item.props.className);
    switch (item.type) {
      case RedirectUriInputType.TextInput:
        return <Input {...item.props} className={className} key={index} />;
      case RedirectUriInputType.DropDown:
        return <DeprecatedDropDown {...item.props} className={className} key={index} />;
      case RedirectUriInputType.Text:
        return (
          <span className={className} key={index}>
            {item.props.text}
          </span>
        );
      default:
        return undefined;
    }
  });
};

export function isDomainPatternAsteriskOnly(pattern: string): boolean {
  return pattern === '*';
}

export function isDomainPatternTextOnly(pattern: string): boolean {
  return !(pattern.includes('*') || pattern.includes('('));
}

export function getRedirectUriInputType(patternPart: string): RedirectUriInputType {
  if (patternPart === '*') {
    return RedirectUriInputType.TextInput;
  } else if (patternPart.length > 0 && patternPart.startsWith('(')) {
    return RedirectUriInputType.DropDown;
  }
  return RedirectUriInputType.Text;
}

export function getInitialDomainArray(initialValue: string, pattern: string): string[] {
  if (pattern === '*') {
    return [initialValue];
  }

  let initialDomainParts = initialValue ? initialValue.split('.') : [];
  const patternParts = pattern ? pattern.split('.') : [];
  initialDomainParts = getFinalDomainParts(initialDomainParts, patternParts.length - 1);
  const initialDomainValid = initialDomainParts.length === patternParts.length;
  const domainToSet = Array(patternParts.length).fill('');

  patternParts.forEach((part, index) => {
    const redirectUriInputType = getRedirectUriInputType(part);
    if (redirectUriInputType === RedirectUriInputType.Text) {
      domainToSet[index] = part;
    } else if (initialDomainValid) {
      domainToSet[index] = initialDomainParts[index];
    }
  });

  return domainToSet;
}
