import React from 'react';
import { Form } from 'antd';
import { Dayjs } from 'dayjs';

import { DateTimeRangePickerWrapperUpdateProps } from '../form-date-time-range-picker/form-date-time-range-picker';

import { ITimeRangePickerTimeSlot } from '@/ui-components/time-range-picker/time-range-picker-store';
import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import { IDateTimePickerProps } from '@/ui-components/date-time-range/date-time-range-picker';
import DateTimeRangePickerSchoolYear from '@/components/date-time-range-picker-school-year/date-time-range-picker-school-year';

export type DateTimeRange = {
  startDateTime: Dayjs;
  endDateTime: Dayjs;
};

interface IProps extends IFormItemProps<DateTimeRange> {
  value?: DateTimeRange;
  onChange?: (timeRange: DateTimeRange) => void;
  timeSlots: ITimeRangePickerTimeSlot[];
  onlySameDay?: boolean;
}

interface IDateTimeRangePickerSchoolYearWrapper extends Omit<IDateTimePickerProps, 'startDateTime' | 'endDateTime'> {
  value?: DateTimeRange;
  initialValue?: DateTimeRange;
  onChange?: (timeRange: DateTimeRange) => void;
}

const DateTimeRangePickerSchoolYearWrapper = (props: IDateTimeRangePickerSchoolYearWrapper) => {
  const updateProps = DateTimeRangePickerWrapperUpdateProps(props);
  const { dateTimeRange, handleSelect } = updateProps;

  return (
    <DateTimeRangePickerSchoolYear
      startDateTime={dateTimeRange.startDateTime}
      endDateTime={dateTimeRange.endDateTime}
      timeSlots={props.timeSlots}
      onlySameDay={props.onlySameDay}
      disabled={props.disabled}
      onDateTimeRangeChange={(startDateTime: Dayjs, endDateTime: Dayjs) => handleSelect({ startDateTime, endDateTime })}
    />
  );
};

export const FormDateTimeRangePickerSchoolYear = (props: IProps) => {
  return (
    <Form.Item
      name={props.name}
      label={props.label}
      className="form-date-time-range-picker"
      rules={props.rules}
      dependencies={props.dependencies}
      initialValue={props.initialValue}
    >
      <DateTimeRangePickerSchoolYearWrapper
        timeSlots={props.timeSlots}
        initialValue={props.initialValue}
        onlySameDay={props.onlySameDay}
        disabled={props.disabled}
        onChange={props.onChange}
      />
    </Form.Item>
  );
};
