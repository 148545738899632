import { Dayjs } from 'dayjs';

import { WeekdayEnum } from '@untis/wu-rest-view-api/api';

export const WEEKDAY_ENUM_MAP: Map<number, WeekdayEnum> = new Map<number, WeekdayEnum>([
  [1, WeekdayEnum.MO],
  [2, WeekdayEnum.TU],
  [3, WeekdayEnum.WE],
  [4, WeekdayEnum.TH],
  [5, WeekdayEnum.FR],
  [6, WeekdayEnum.SA],
  [0, WeekdayEnum.SU],
]);

/**
 * Returns the next day from "date", that is considered to be a day of the timegrid.
 *
 * Example: "date" is a Friday and the timeGridWeekdays contain the days from Monday to Friday.
 * "getNextWeekDay" will return the date for the Monday after the date
 *
 * @param date
 * @param timeGridWeekdays
 */
export const getNextWeekDay = (date: Dayjs, timeGridWeekdays: WeekdayEnum[]): Dayjs => {
  let resultDate = date.clone();
  const currentWeekDay: WeekdayEnum = WEEKDAY_ENUM_MAP.get(date.day())!;
  const targetWeekDay = getNextValidTimeGridWeekDay(currentWeekDay, timeGridWeekdays);

  let resultDateWeekDay: WeekdayEnum = WEEKDAY_ENUM_MAP.get(resultDate.day())!;
  while (resultDateWeekDay !== targetWeekDay) {
    resultDate = resultDate.add(1, 'day');
    resultDateWeekDay = WEEKDAY_ENUM_MAP.get(resultDate.day())!;
  }
  return resultDate;
};

/**
 * Returns the previous day from "date", that is considered to be a day of the timegrid.
 *
 * Example: "date" is a Monday and the timeGridWeekdays contain the days from Monday to Friday.
 * "getNextWeekDay" will return the date for the Friday before the date
 *
 * @param date
 * @param timeGridWeekdays
 */
export const getPreviousWeekDay = (date: Dayjs, timeGridWeekdays: WeekdayEnum[]): Dayjs => {
  let resultDate = date.clone();
  const currentWeekDay: WeekdayEnum = WEEKDAY_ENUM_MAP.get(date.day())!;
  const targetWeekDay = getPreviousValidTimeGridWeekDay(currentWeekDay, timeGridWeekdays);

  let resultDateWeekDay: WeekdayEnum = WEEKDAY_ENUM_MAP.get(resultDate.day())!;
  while (resultDateWeekDay !== targetWeekDay) {
    resultDate = resultDate.subtract(1, 'day');
    resultDateWeekDay = WEEKDAY_ENUM_MAP.get(resultDate.day())!;
  }
  return resultDate;
};

const getKeyForEnumValue = (enumValue: WeekdayEnum): number => {
  switch (enumValue) {
    case WeekdayEnum.MO:
      return 1;
    case WeekdayEnum.TU:
      return 2;
    case WeekdayEnum.WE:
      return 3;
    case WeekdayEnum.TH:
      return 4;
    case WeekdayEnum.FR:
      return 5;
    case WeekdayEnum.SA:
      return 6;
    case WeekdayEnum.SU:
      return 0;
  }
};

const getPrevWeekDayEnum = (weekdayEnum: WeekdayEnum): WeekdayEnum => {
  const currentIndex = getKeyForEnumValue(weekdayEnum);
  return WEEKDAY_ENUM_MAP.get((currentIndex - 1 + 7) % 7)!;
};

const getNextWeekDayEnum = (weekdayEnum: WeekdayEnum): WeekdayEnum => {
  const currentIndex = getKeyForEnumValue(weekdayEnum);
  return WEEKDAY_ENUM_MAP.get((currentIndex + 1) % 7)!;
};

const getPreviousValidTimeGridWeekDay = (weekdayEnum: WeekdayEnum, timegridDays: WeekdayEnum[]): WeekdayEnum => {
  const startEnum = weekdayEnum;
  let currentEnum = getPrevWeekDayEnum(weekdayEnum);

  while (timegridDays.findIndex((weekDay) => weekDay == currentEnum) == -1) {
    currentEnum = getPrevWeekDayEnum(currentEnum);
    if (currentEnum === startEnum) {
      break;
    }
  }
  return currentEnum;
};

const getNextValidTimeGridWeekDay = (weekdayEnum: WeekdayEnum, timegridDays: WeekdayEnum[]): WeekdayEnum => {
  const startEnum = weekdayEnum;
  let currentEnum = getNextWeekDayEnum(weekdayEnum);

  while (timegridDays.findIndex((weekDay) => weekDay == currentEnum) == -1) {
    currentEnum = getNextWeekDayEnum(currentEnum);
    if (currentEnum === startEnum) {
      break;
    }
  }
  return currentEnum;
};
