import React, { useState } from 'react';
import { isAndroid, isIOS, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import useStore from '@/hooks/useStore';
import EnvironmentStore from '@/stores/environment-store';
import { Icon } from '@/ui-components';

import './mobile-banner.less';

/**
 * Link-Definition zur Anzeige eines Links auf den entsprechenden App-Store
 */
interface IAppStore {
  /**
   * src der Badge, die angezeigt werden soll
   */
  img: string;

  /**
   * Name des entsprechenden App-Stores
   */
  storeName: string;
}

const ANDROID: IAppStore = {
  img: 'assets/images/playstore.png',
  storeName: 'Google Play Store',
};
const IOS: IAppStore = {
  img: 'assets/images/appstore.png',
  storeName: 'App Store',
};

const MobileBanner = () => {
  const [visible, setVisible] = useState(true);
  const { t } = useTranslation();
  const environmentStore = useStore(EnvironmentStore);

  if (visible && !isTablet && (isAndroid || isIOS)) {
    const { appStoreURLs } = environmentStore;
    const appStore = isIOS ? IOS : ANDROID;

    return (
      <div className="mobile-banner">
        <div className="mobile-banner-close" onClick={() => setVisible(false)}>
          <Icon type="cancel" />
        </div>
        <a href={isIOS ? appStoreURLs.ios : appStoreURLs.android}>
          <img src={appStore.img} alt={appStore.storeName} />
        </a>
        <p>{t`general.mobileBanner.header`}</p>
        <p>{t('general.mobileBanner.description', { storeName: appStore.storeName })}</p>
      </div>
    );
  }

  return null;
};

export default MobileBanner;
