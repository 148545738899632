import { Constructable, Container, Service } from 'typedi';

export interface IStore {
  isLoading: boolean;
  [key: string]: any;
}

/**
 * This function should be used to inject stores inside TypeScript Classes.
 * If you want to use a store inside a React component use the useStore hook.
 */
export function inject<T>(type: Constructable<T>) {
  return Container.get(type);
}

export { Service as Store };
