import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import classNames from 'clsx';

import TimeRangePickerForm from './time-range-picker-form/time-range-picker-form';
import TimeRangePickerStore, {
  ITimeRangePickerStoreArgs,
  ITimeRangePickerTimeSlot,
  TimeRangePickerMode,
} from './time-range-picker-store';

import { TimePicker } from '@/ui-components/date/time-picker/time-picker';

import './time-range-picker.less';

export interface ITimeRangeProps {
  startTime: Dayjs;
  endTime: Dayjs;
  mode: TimeRangePickerMode;
  onTimeRangeChange?: (startTime: Dayjs, endTime: Dayjs, mode: TimeRangePickerMode) => void;
  timeSlots?: ITimeRangePickerTimeSlot[];
  disabled?: boolean;
  onlyEditOwnTimeSlot?: boolean;
  label: string;
}

function initStore(props: ITimeRangeProps) {
  const args: ITimeRangePickerStoreArgs = {
    startTime: props.startTime,
    endTime: props.endTime,
    onTimeRangeChange: props.onTimeRangeChange,
    timeSlots: props.timeSlots,
    mode: props.mode,
    onlyEditOwnTimeSlot: props.onlyEditOwnTimeSlot,
  };
  return new TimeRangePickerStore(args);
}

/**
 * Component to enable the user to enter a start time and an end time (using dayjs.js).
 * Typically when you use this component you will need two of them (one for entering start time and one for entering
 * end time, see prop 'mode') and have to place them where you want.
 *
 * After the form closes, the 'onTimeRangeChange' is called with new dayjs objects that hold the correct times.
 * As in other time pickers you will have to ignore the date.
 */
const TimeRangePicker = observer((props: ITimeRangeProps) => {
  const [store, setStore] = useState(() => initStore(props));

  useEffect(() => {
    setStore(initStore(props));
  }, [
    props,
    props.startTime,
    props.endTime,
    props.onTimeRangeChange,
    props.timeSlots,
    props.mode,
    props.onlyEditOwnTimeSlot,
  ]);

  // only toggle edit mode when component is not disabled
  const toggleEditMode = () => {
    if (!props.disabled) {
      store.toggleEditMode();
    }
  };

  const time: Dayjs = props.mode === TimeRangePickerMode.Start ? props.startTime : props.endTime;

  const pickerClassName = classNames('time-range-picker', {
    'twelve-hour-format': store.is12HourFormat,
  });

  const form = (
    <TimeRangePickerForm store={store} startTime={props.startTime} endTime={props.endTime} mode={props.mode} />
  );

  const timePicker = (
    <TimePicker
      testId="time-picker"
      disabled={props.disabled}
      onClick={toggleEditMode}
      value={time}
      label={props.label}
    />
  );

  return <div className={pickerClassName}>{store.isInEditMode ? form : timePicker}</div>;
});

export default TimeRangePicker;
