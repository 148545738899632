import React from 'react';
import { observer } from 'mobx-react-lite';

import { PlusOutlined } from '@ant-design/icons';
import TagSelect from '@/ui-components/tag-select/tag-select';
import { Option } from '@/ui-components/option-popup/option-popup';
import { Button } from '@/ui-components';

import './multi-tag-select.less';

export interface IMultiTagSelectProps {
  value: IMultiTagSelectItem[];
  placeholder?: string;
  onAdd: () => void;
  disabled?: boolean;
  addButtonTestId?: string;
  isSingleSelect?: boolean;
}

export interface IMultiTagSelectItem {
  label: string;
  options?: Option[];
  id: string;
  hasError?: boolean;
  filterObject?: any;
}

export const MultiTagSelect = observer((props: IMultiTagSelectProps) => {
  const { disabled, placeholder, value, onAdd, addButtonTestId, isSingleSelect } = props;
  const hidePlusButton = isSingleSelect && value && value.length > 0;
  return (
    <div className="multi-tag-select">
      {value.length === 0 && <span className={disabled ? 'disabled-text' : ''}>{placeholder ? placeholder : ''}</span>}
      {value.map((i) => (
        <TagSelect disabled={disabled} key={i.id} label={i.label} options={i.options} id={i.id} hasError={i.hasError} />
      ))}
      {!disabled && !hidePlusButton && (
        <Button disabled={disabled} outline onClick={onAdd} testId={addButtonTestId}>
          <PlusOutlined translate="no" />
        </Button>
      )}
    </div>
  );
});
