import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useStore from '@/hooks/useStore';
import './platform-detail-info-dialog.less';
import LocaleStore from '@/stores/locale-store';
import { FormInput } from '@/ui-components/wu-form';
import { TestIds } from '@/testIds';

const PlatformDetailInfoServerToServerDisclaimer = observer(() => {
  const { t } = useTranslation();
  const localeStore = useStore(LocaleStore);

  const url: string =
    localeStore.locale === 'de'
      ? 'untis.at/datenschutz-wu-integrationen'
      : 'untis.at/en/privacy-policy-wu-integrations';

  return (
    <>
      <div data-testid={TestIds.PLATFORM_APPLICATION_SERVER_TO_SERVER_DISCLAIMER_SECTION}>
        <Trans t={t} i18nKey="general.platformDataProtectionNoticeDisclaimer" values={{ url: url }}>
          <a href={`//${url}`} target="#" />
        </Trans>
      </div>
      <div className="disclaimer-confirmation-email-input">
        <FormInput
          testId="headmaster-email-input"
          errorLabel={`${t('general.form.validation.invalidEmail')}`}
          maxLength={255}
          variant="floating"
          label={t('platform.headMasterEmailLabel')}
          name="headmasterEmail"
          required
          type="email"
          isEmail
        />
      </div>
    </>
  );
});

export default PlatformDetailInfoServerToServerDisclaimer;
