import React, { useMemo } from 'react';

import './progress-bar.less';

export interface IProgressBarProps {
  /**
   * The progress-bar is divided into N steps. For N=4, for example, the progress is displayed as 4 steps, which are
   * completed at currentProgress >= 25.0, >= 50.0, >= 75.0 and finally >= 100.0.
   */
  numberOfSteps: number;

  /**
   * The current progress (between 0.0 and 100.0).
   */
  currentProgress: number;

  testId?: string;
}

interface IStep {
  /**
   * At which percentage is this step completed (e.g. 33.3)?
   */
  completedAt: number;
}

const ProgressBar = (props: IProgressBarProps) => {
  const { numberOfSteps, currentProgress, testId } = props;

  // we memoize the created steps and only re-create the array, when the number of steps are changing
  const steps = useMemo(() => {
    const stepSize: number = 100 / numberOfSteps;

    const result: IStep[] = [];
    for (let stepNumber = 1; stepNumber <= numberOfSteps; stepNumber++) {
      result.push({ completedAt: stepNumber === numberOfSteps ? 100 : stepSize * stepNumber });
    }
    return result;
  }, [numberOfSteps]);

  return (
    <div data-testid={testId} className="untis-progress-bar">
      {steps.map((step: IStep) => {
        return (
          <div
            key={'step-' + step.completedAt}
            role="progressbar-step"
            className={currentProgress >= step.completedAt ? 'step-complete' : 'step-incomplete'}
          />
        );
      })}
    </div>
  );
};

export default ProgressBar;
