import { Table } from 'antd';
import classNames from 'clsx';
import * as React from 'react';
import { TableRowSelection } from 'antd/lib/table/interface';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { Columns, ColumnType, mapColumnsToAntDesignColumn } from '@/ui-components/wu-table/wu-table-column-mapper';
import { WuTableControls, WuTableControlsProps } from '@/ui-components/wu-table/wu-table-controls/wu-table-controls';
import './wu-table.less';

export interface ITableRowKey {
  key: number | string; // needed to avoid React Warnings when AntDesign renders the rows
}

// Use this to change the style of text columns. If you need more, feel free to extend this.
// We should avoid writing custom CSS for each usage of the table, therefore we should work with this
// well defined styles so that we stay consistent through the Application.
export interface ICellTextStyle {
  border?: boolean; // default: true. Hides the horizontal table grid lines
  color?: 'gray-dark' | 'gray'; // default: black.
  bold?: boolean; // default: false
  semibold?: boolean; // default: false
}

export type WUTableRowSelection<T> = Omit<TableRowSelection<T>, 'renderCell'>;

// Generic Type T stands for the Row Data Type
export interface IWUTableProps<T> {
  columns: Columns<T>;
  rowData: T[];
  shadow?: boolean; // should the table have a shadowed border?
  showHeader?: boolean;
  // click handler for rows. Also applies hover styles properly, if set
  onRowClick?: (row: T, event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  verticalAlign?: 'middle' | 'top'; // vertical alignment of row content
  rowSelection?: Omit<TableRowSelection<T>, 'renderCell' | 'fixed'>;
  controls?: WuTableControlsProps;
  emptyText?: string;
  className?: string; // todo - consider classname
  addNewRow?: boolean;
  rowClassName?: string | ((row: T) => string);
  pagination?: number;
  withoutCellPaddingTopBottom?: boolean;
  withoutCellPaddingLeftRight?: boolean;
  withoutSortedHeaderBackground?: boolean;
}

const tableClassName = 'wu-table';

/**
 *  This component should be used whenever you need to use a table, rather than directly using the Ant Design Table.
 *  The benefit of using this Wrapper Component for the Ant Design Table is, that this is a generic Typesafe
 *  implementation, and also it should not be necessary to write any LESS styles.
 */
export function WUTable<T extends ITableRowKey>(props: IWUTableProps<T>) {
  // generic columns need to access the whole rows data. This is why we need to identify those columns
  // and add the row data to the attributes of the Ant Design datasource object (attribute name of datasource
  // has to match the column name - this is just how Ant Design works)

  // get generic column names
  const genericColumnKeys: string[] = React.useMemo(() => {
    return props.columns
      .filter(
        (col) =>
          col.type === ColumnType.HoverActions ||
          col.type === ColumnType.OverlayHoverActions ||
          col.type === ColumnType.Button ||
          col.type === ColumnType.Custom ||
          col.type === ColumnType.List ||
          col.type === ColumnType.DateRange ||
          col.type === ColumnType.Avatar ||
          col.type === ColumnType.Indicator ||
          col.type === ColumnType.TextArray ||
          col.type === ColumnType.DropDownActions ||
          col.type === ColumnType.SingleDropDown ||
          col.type === ColumnType.Absences ||
          col.type === ColumnType.TextOrButton ||
          col.type === ColumnType.Icon ||
          col.type === ColumnType.Attachments ||
          col.type === ColumnType.Color ||
          col.type === ColumnType.TextPopover ||
          col.type === ColumnType.Number ||
          col.type === ColumnType.Label,
      )
      .map((col) => col.key);
  }, [props.columns]);

  // add row data to datasource for each generic column name
  const extendedRowData: T[] = React.useMemo(() => {
    const extendedRowData = props.rowData.map((row) => {
      const extendedRow: T = { ...row };

      genericColumnKeys.forEach((colKey) => {
        // Before enabling this warning we will have to revise all tables to avoid flooding the console logs.
        // if (Object.keys(extendedRow).includes(colKey)) {
        //   console.warn(
        //     'The attribute list of the type of the table row data must not contain an attribute ' +
        //       'with the same key as a column key of the table. Choose another key for the column with key "' +
        //       colKey +
        //       '"',
        //   );
        // }
        // add an attribute with the column name that contains the row data
        (extendedRow as any)[`${colKey}`] = row;
      });

      return extendedRow;
    });

    if (props.addNewRow) {
      const emptyRow: T = props.columns.reduce((emptyRow, column) => {
        if (!('edit' in column && column.edit !== undefined)) return emptyRow;

        return {
          ...emptyRow,
          [column.key]: column.edit.defaultValue,
        };
      }, {} as T);

      emptyRow.key = v4();

      extendedRowData.push(emptyRow);
    }

    return extendedRowData;
  }, [genericColumnKeys, props.addNewRow, props.columns, props.rowData]);

  const { t } = useTranslation();

  const hasData = props.rowData.length !== 0 || props.addNewRow;

  const table = (
    <Table
      className={
        (props.className ? props.className + ' ' : '') +
        classNames(tableClassName, {
          [tableClassName + '--clickable-rows']: !!props.onRowClick,
          [tableClassName + '--vertical-align-top']: props.verticalAlign === 'top',
          [tableClassName + '--with-pagination']: !!props.pagination,
          [tableClassName + '--without-padding-top-bottom']: !!props.withoutCellPaddingTopBottom,
          [tableClassName + '--without-padding-left-right']: !!props.withoutCellPaddingLeftRight,
          [tableClassName + '--without-sorted-header-background']: !!props.withoutSortedHeaderBackground,
          [props.className as string]: !props.shadow,
        })
      }
      dataSource={extendedRowData}
      columns={mapColumnsToAntDesignColumn(props.columns)}
      showHeader={props.showHeader}
      showSorterTooltip={false}
      pagination={
        props.pagination
          ? {
              pageSize: props.pagination,
              position: ['bottomCenter'],
              showSizeChanger: false,
            }
          : false
      }
      rowSelection={props.rowSelection ? { ...props.rowSelection, fixed: true } : undefined}
      onRow={(row: T) => {
        return {
          onClick: (event) => {
            props.onRowClick?.(row, event);
          },
        };
      }}
      locale={{ emptyText: props.emptyText }}
      rowClassName={props.rowClassName}
      scroll={hasData ? { x: true } : undefined}
    />
  );

  const tableContent = (
    <>
      {table}
      {!hasData && !props.emptyText && <div className="empty">{t('general.noData')}</div>}
    </>
  );

  return (
    <>
      {props.controls && <WuTableControls {...props.controls} />}
      {props.shadow ? (
        <div className="shadow-container">{tableContent}</div>
      ) : (
        <div className="table-container">{tableContent}</div>
      )}
    </>
  );
}
