import { CSSProperties, RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { DeprecatedDropDownItem, IDeprecatedDropDownProps } from '@/ui-components/deprecated-drop-down/drop-down';
import { useOutsideClick } from '@/hooks/useClickOutside';

const DELAY_IN_MS = 300;

export function useDropDownUIHelper({ open, onClose }: IDeprecatedDropDownProps) {
  // states if dropdown is open/opening or  closed/closing
  const [isOpen, setIsOpen] = useState(open ?? false);
  // states if the scroll bar is visible or not. (the scrollbar should only appear after
  // the dropdown is fully opened
  const [showScrollbar, setShowScrollbar] = useState(false);
  // states if the component overlaps other components. (the z-index must be increased when
  // the dropdow is about to open, fully opened or about to close. Only when the component is
  // fully closed, the z-Index is reset to zero):
  const [zIndexInitial, setZIndexInitial] = useState(true);
  const [preSelectedItem, setPreSelectedItem] = useState<DeprecatedDropDownItem | undefined>(undefined);
  const [helperStyle, setHelperStyle] = useState<CSSProperties>({});
  const [filter, setFilter] = useState('');

  // refs
  const zIndexTimoutRef = useRef<any | null>(null);
  const scrollBarTimeoutRef = useRef<any | null>(null);
  const inputRef = useRef<null | HTMLInputElement>(null);
  const scrollableRef: RefObject<HTMLDivElement> = useRef(null);
  const outsideClickRef = useOutsideClick(() => {
    if (isOpen) {
      toggleOpen(false);
    }
  });

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const toggleOpen = useCallback(
    (override?: boolean) => {
      setFilter('');

      if (override === undefined) {
        setIsOpen(!isOpen);
      } else {
        setIsOpen(override);
      }

      if (isOpen) {
        setShowScrollbar(false);
      } else {
        if (scrollableRef && scrollableRef.current != null) {
          scrollableRef.current!.scrollTop = 0;
          setShowScrollbar(false);
          setZIndexInitial(false);
        }
      }

      handleClose();
    },
    [handleClose, isOpen],
  );

  const getHelperWidth = useCallback(() => {
    // + 2px because of the dropdowns 1px border that is always set but visible/invisible depending on its color.
    return (outsideClickRef.current && outsideClickRef.current.clientWidth + 2) || 0;
  }, [outsideClickRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      !isOpen && setHelperStyle({ width: `${getHelperWidth()}px` });
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [isOpen, getHelperWidth, setHelperStyle]);

  useEffect(() => {
    if (isOpen) {
      scrollBarTimeoutRef.current = setTimeout(() => {
        setShowScrollbar(true);
      }, DELAY_IN_MS);
    } else {
      if (scrollBarTimeoutRef.current != null) {
        clearTimeout(scrollBarTimeoutRef.current);
      }
    }

    return () => {
      if (scrollBarTimeoutRef.current != null) {
        clearTimeout(scrollBarTimeoutRef.current);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      zIndexTimoutRef.current = setTimeout(() => {
        setZIndexInitial(true);
      }, DELAY_IN_MS);
    } else {
      if (zIndexTimoutRef.current != null) {
        clearTimeout(zIndexTimoutRef.current);
      }
    }

    return () => {
      if (zIndexTimoutRef.current != null) {
        clearTimeout(zIndexTimoutRef.current);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  return {
    isOpen,
    setIsOpen,
    filter,
    setHelperStyle,
    getHelperWidth,
    setPreSelectedItem,
    preSelectedItem,
    scrollableRef,
    toggleOpen,
    setFilter,
    showScrollbar,
    inputRef,
    zIndexInitial,
    helperStyle,
    outsideClickRef,
  };
}
