const TAB_ROUTE_PARAM_SEPARATOR = '--';

export enum CalendarEntryDetailsTab {
  DETAILS = 'details',
  STUDENTS = 'students',
  CLASS_REGISTER = 'class-register',
  STUDENT_PERIOD_ASSIGNMENT = 'student-period-assignment',
  PLATFORM_APPLICATION = 'platform-application',
}

export interface ITabData {
  tab: CalendarEntryDetailsTab;
  platformApplicationId?: number;
}

export const DEFAULT_TAB: CalendarEntryDetailsTab = CalendarEntryDetailsTab.DETAILS;

export const parseTabData = (routeTabParam: string | undefined): ITabData => {
  if (routeTabParam) {
    const tabSplit = routeTabParam.split(TAB_ROUTE_PARAM_SEPARATOR);
    const _Tab: { [idx: string]: CalendarEntryDetailsTab } = CalendarEntryDetailsTab;
    for (const key in _Tab) {
      if (_Tab[key] === tabSplit[0]) {
        return { tab: _Tab[key], platformApplicationId: (tabSplit[1] && parseInt(tabSplit[1])) || undefined };
      }
    }
  }
  return { tab: DEFAULT_TAB };
};

export const toRouteTabParam = (tabData: ITabData): string => {
  const suffix =
    tabData.platformApplicationId && tabData.tab === CalendarEntryDetailsTab.PLATFORM_APPLICATION
      ? TAB_ROUTE_PARAM_SEPARATOR + tabData.platformApplicationId
      : '';
  return tabData.tab + suffix;
};
