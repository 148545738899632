import { FormInstance } from 'antd';
import { Dayjs } from 'dayjs';
import { NamePath } from 'antd/lib/form/interface';

import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import ModalStore from '@/stores/modal-store';
import {
  IDate,
  IDateRange,
  DateOptionEnum,
  DateRangeOptionEnum,
} from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import { DeprecatedDropDownItem } from '@/ui-components/deprecated-drop-down/drop-down';
import { IDateCalendarHolidayProps } from '@/ui-components/date/calendar/date-calendar/date-calendar';

type MultiSelectOptions = {
  form: FormInstance;
  name: string;
  items: IMultiTagSelectItem[];
  title: string;
  modalStore: ModalStore;
  filters?: MultiSelectFilter[];
  isSearchable?: boolean;
  isSingleSelect?: boolean;
  redirectToCreate?: () => void;
};

export type MultiSelectFilter = {
  id: string;
  name: string;
  options: DeprecatedDropDownItem[];
};

export const createMultiSelectAddHandler = (
  form: FormInstance,
  name: string,
  items: IMultiTagSelectItem[],
  title: string,
  modalStore: ModalStore,
  filters?: MultiSelectFilter[],
  isSearchable?: boolean,
  isSingleSelect?: boolean,
): (() => Promise<IMultiTagSelectItem[]>) => {
  return () =>
    new Promise<IMultiTagSelectItem[]>((resolve) => {
      return new Promise(() => {
        modalStore
          .openModalItemPicker(title, items, form.getFieldValue(name), filters, isSearchable, isSingleSelect)
          .then((result) => {
            if (result.value) {
              resolve(result.value);
            }
          });
      });
    });
};

export const createMultiSelectAddHandlerWithOptions = (
  options: MultiSelectOptions,
): (() => Promise<IMultiTagSelectItem[]>) => {
  return () =>
    new Promise<IMultiTagSelectItem[]>((resolve) => {
      return new Promise(() => {
        const { form, name, items, title, redirectToCreate, modalStore, filters, isSearchable, isSingleSelect } =
          options;
        modalStore
          .openModalItemPickerWithOptions({
            title,
            items,
            selectedItems: form.getFieldValue(name),
            filters,
            isSearchable,
            isSingleSelect,
          })
          .then((result) => {
            if (result.value) {
              resolve(result.value);
            } else {
              if (redirectToCreate && !result.cancelled && items.length === 0) {
                redirectToCreate();
              }
            }
          });
      });
    });
};

export const createCustomDateClickHandler = (
  modalStore: ModalStore,
  selectedDate: IDate,
  setSelectedDate: (date: IDate) => void,
  title?: string,
  disabledDate?: (date: Dayjs) => boolean,
  withSubmitButton?: boolean,
  holidays?: IDateCalendarHolidayProps[],
) => {
  return () =>
    modalStore
      .openModalDatePicker(selectedDate.date, title, disabledDate, withSubmitButton, holidays)
      .then((result) => {
        if (result.value) {
          setSelectedDate({
            date: result.value,
            option: DateOptionEnum.USER_SELECTION,
          });
        }
      });
};

export const createCustomDateRangeClickHandler = (props: {
  modalStore: ModalStore;
  selectedDateRange: IDateRange;
  setSelectedDateRange: (dateRange: IDateRange) => void;
  title?: string;
  disabledDate?: (date: Dayjs) => boolean;
  holidays?: IDateCalendarHolidayProps[];
}) => {
  return () =>
    props.modalStore
      .openModalDateRangePicker(
        props.selectedDateRange.startDate,
        props.selectedDateRange.endDate,
        props.title,
        props.disabledDate,
        props.holidays,
      )
      .then((result) => {
        if (result.value) {
          props.setSelectedDateRange({
            startDate: result.value.startDate,
            endDate: result.value.endDate,
            option: DateRangeOptionEnum.USER_SELECTION_DATE_RANGE,
          });
        }
      });
};

export const validateForm = (form: FormInstance): Promise<boolean> => {
  return new Promise((resolve) => {
    form.validateFields().then(() => {
      resolve(!form.getFieldsError().some((e) => e.errors.length));
    });
  });
};

export const resolveNamePath = (namePath: NamePath): string | undefined => {
  if (Array.isArray(namePath)) {
    return namePath.join('-');
  }

  switch (typeof namePath) {
    case 'string':
      return namePath;
    case 'number':
      return namePath.toString();
  }
  return undefined;
};
