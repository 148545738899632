import React from 'react';
import { t } from 'i18next';
import { action, computed, observable } from 'mobx';
import dayjs from 'dayjs';

import CalendarEntryDetailViewStore from '../calendar-entry-detail-view-store';

import {
  CalendarBookingDto,
  CalendarEntryDetailDtoV2,
  CalendarPermissionsEnum as Permission,
  CancelEnum,
  FileDescriptorDtoV2,
  RoomStatusEnum,
  UpdateCalendarEntryDtoV2,
  VideoCallDescriptorDto,
} from '@untis/wu-rest-view-api';
import CalendarEntryBaseFormStore, { FormMode } from '@/pages/calendar-entry/calendar-entry-base-form-store';
import { ITimeSlotArguments } from '@/pages/calendar-entry/calendar-entry-detail-view/calendar-entry-detail-view';
import { CalendarViewApi } from '@/stores/api-store';
import { inject } from '@/types/store';
import { showErrorMessage } from '@/utils/error-handling/error-message';
import PostMessageStore from '@sp/stores/post-message-store';
import RouterStore from '@/stores/router-store';
import { CR_OVERVIEW_ROUTE } from '@/pages/class-register/class-register-overview/class-register-overview';
import { ExamFormStore } from '@ls/exams/exam-form/exam-form-store';
import ExamForm from '@ls/exams/exam-form/exam-form';
import { ExamWizardStore, ExamWizardType } from '@ls/exams/wizard/exam-wizard-store';
import { ExamWizardForm } from '@ls/exams/wizard/exam-wizard-form';
import { CalendarSingleEntryDto } from '@untis/wu-rest-view-api/api';

class CalendarEntryDetailFormStore extends CalendarEntryBaseFormStore {
  // data that needs to be fetched
  @observable attachmentsAll: FileDescriptorDtoV2[] = [];
  @observable attachmentsStaff: FileDescriptorDtoV2[] = [];

  @observable private _notesStaff: string | undefined;
  @observable private _notesAll: string | undefined;
  @observable private _videoCall: VideoCallDescriptorDto | undefined;

  @observable private readonly _ttid: number | undefined;
  @observable private readonly _timeSlotArgs: ITimeSlotArguments | undefined;
  @observable private _isBlockSelected: boolean | undefined = undefined;
  @observable private _fetchCalendarEntryDetails: ((periodId?: number, examId?: number) => void) | undefined;
  @observable private _showIframe = false;
  @observable private _iFramePage: string = '';

  private _postMessageStore = inject(PostMessageStore);
  private _routerStore = inject(RouterStore);

  constructor(detailViewStore: CalendarEntryDetailViewStore) {
    super(
      dayjs(detailViewStore.selectedCalendarEntry!.startDateTime),
      dayjs(detailViewStore.selectedCalendarEntry!.endDateTime),
      FormMode.EDIT,
      undefined,
      detailViewStore,
    );
    this.setPatchRoomHandler(this.patchRooms);

    this.attachmentsAll = detailViewStore.selectedCalendarEntry?.notesAllFiles
      ? detailViewStore.selectedCalendarEntry?.notesAllFiles
      : [];
    this.attachmentsStaff = detailViewStore.selectedCalendarEntry?.notesStaffFiles
      ? detailViewStore.selectedCalendarEntry?.notesStaffFiles
      : [];
    this._videoCall = detailViewStore.selectedCalendarEntry?.videoCall
      ? detailViewStore.selectedCalendarEntry.videoCall
      : undefined;

    this._ttid = detailViewStore.selectedPeriodId;
    this._timeSlotArgs = detailViewStore.timeSlotArguments;

    this.fetchRooms().then(() => {
      this._isBlockSelected = detailViewStore.isBlockSelected;
      this._fetchCalendarEntryDetails = detailViewStore.fetchCalendarEntryDetails;

      if (detailViewStore.selectedCalendarEntry!.rooms) {
        this.selectRooms(
          detailViewStore
            .selectedCalendarEntry!.rooms.filter((r) => {
              return r.status !== RoomStatusEnum.REMOVED;
            })
            .map((r) => r!.id),
        );
      }
    });
  }

  @computed get calendarEntry(): CalendarEntryDetailDtoV2 | undefined {
    return this._detailViewStore?.selectedCalendarEntry;
  }

  @computed get singleCalendarEntry(): (CalendarEntryDetailDtoV2 | CalendarSingleEntryDto) | undefined {
    const selectedCalendarEntry = this._detailViewStore?.selectedCalendarEntry;
    const selectedPeriodId = this._detailViewStore?.selectedPeriodId;
    const isBlockSelected = this._detailViewStore?.isBlockSelected;

    if (isBlockSelected && selectedCalendarEntry?.id !== selectedCalendarEntry) {
      return selectedCalendarEntry?.singleEntries.find((entry) => entry.id === selectedPeriodId);
    } else {
      return selectedCalendarEntry;
    }
  }

  @computed get showIframe(): boolean {
    return this._showIframe;
  }

  @computed get iFramePage(): string {
    return this._iFramePage;
  }

  @computed get canEditRooms(): boolean {
    return !!this.calendarEntry?.permissions?.includes(Permission.EDIT_ROOM);
  }

  @computed get canCancel(): boolean {
    return !!this.calendarEntry?.permissions?.includes(Permission.CANCEL);
  }

  @computed get canMove(): boolean {
    return !!this.calendarEntry?.permissions?.includes(Permission.MOVE);
  }

  @computed get canCreateExam(): boolean {
    return !this.calendarEntry?.exam && !!this.calendarEntry?.permissions?.includes(Permission.CREATE_EXAM);
  }

  @computed get canEditExam(): boolean {
    return !!this.calendarEntry?.exam && !!this.calendarEntry?.permissions?.includes(Permission.EDIT_EXAM);
  }

  @computed get canEditNotesAll(): boolean {
    return !!this.calendarEntry?.permissions.includes(Permission.WRITE_NOTES_ALL);
  }

  @computed get canEditNotesStaff(): boolean {
    return !!this.calendarEntry?.permissions.includes(Permission.WRITE_NOTES_STAFF);
  }

  @computed get canEditVideoCall(): boolean {
    return !!this.calendarEntry?.permissions.includes(Permission.WRITE_VIDEO_CALL);
  }

  @computed get canReadHomework(): boolean {
    return !!this.calendarEntry?.permissions.includes(Permission.READ_HOMEWORK);
  }

  @computed get showFooterActionButtons(): boolean {
    return !this._routerStore.routing.location.pathname.startsWith(CR_OVERVIEW_ROUTE) && !this._showIframe;
  }

  @computed get hasNextPeriod(): boolean {
    return this.singleCalendarEntry?.nextId != undefined;
  }

  @computed get hasPreviousPeriod(): boolean {
    return this.singleCalendarEntry?.previousId != undefined;
  }

  @action setNotesAll = (value: string | undefined) => {
    this._notesAll = value;
  };

  @action setNotesStaff = (value: string | undefined) => {
    this._notesStaff = value;
  };

  @action setAttachmentsAll = (attachments: FileDescriptorDtoV2[]) => {
    this.attachmentsAll = attachments;
  };

  @action setAttachmentsStaff = (attachments: FileDescriptorDtoV2[]) => {
    this.attachmentsStaff = attachments;
  };

  @action setVideoCall = (videoCall: VideoCallDescriptorDto | undefined) => {
    this._videoCall = videoCall;
  };

  @action handleCreateExam = () => {
    const examWizardStore = new ExamWizardStore(
      ExamWizardType.PERIOD,
      this._ttid || 0,
      this._timeSlotArgs?.startDateTime || dayjs(),
      this._timeSlotArgs?.endDateTime || dayjs(),
      !!this._isBlockSelected,
    );
    this._modalStore.openModalDialog({
      children: (
        <ExamWizardForm
          examWizardStore={examWizardStore}
          onSaveCallBack={() => this.refreshTimetableAndRefetchCalendarEntryDetails()}
        />
      ),
      title: '',
      size: 'full-size',
      containsForm: true,
    });
  };

  @action handleBooking = (booking: CalendarBookingDto | undefined) => {
    if (booking && booking.id && this._ttid) {
      if (this._detailViewStore?.embedDetailsIframe) {
        this._detailViewStore?.setHideTopSection(true);
        this._showIframe = true;
        this._iFramePage =
          'bookingform.do?selId=' + booking.id + '&fromCalendar=' + this._ttid + '&isEmbeddedInDetailsView=true';
      } else {
        this._postMessageStore.postOpenBookingDetailsForId(booking.id, this._ttid, true);
        this._modalStore.closeAllAndRemoveModalRoutes();
      }
    }
  };

  @action handleEditExam = () => {
    const examId = this.calendarEntry?.exam?.id;
    if (examId) {
      const examFormStore = new ExamFormStore(examId);
      this._modalStore.openModalDialog({
        children: (
          <ExamForm
            examFormStore={examFormStore}
            onSaveCallback={() => this.refreshTimetableAndRefetchCalendarEntryDetails(examId)}
            onDeleteCallback={() => this.refreshTimetableAndRefetchCalendarEntryDetails(examId)}
          />
        ),
        title: '',
        size: 'full-size',
        containsForm: true,
      });
    }
  };

  @action fetchSinglePeriod = (periodId: number | undefined) => {
    this._detailViewStore && this._detailViewStore.fetchCalendarEntryDetails(periodId);
  };

  @action handleCancel = () => {
    this._modalStore
      .deprecatedBooleanUserPrompt({
        content: t('general.confirmCancellation'),
        okText: t('general.yes'),
        cancelText: t('general.no'),
      })
      .then((result) => {
        if (result) {
          this.updateEntry(this._ttid, { isCancelled: { value: CancelEnum.CANCEL } });
        }
      });
  };

  @action handleMove = () => {
    if (this._ttid) {
      if (this._detailViewStore?.embedDetailsIframe) {
        this._detailViewStore?.setHideTopSection(true);
        this._showIframe = true;
        this._iFramePage = 'rescheduleform?&ttid=' + this._ttid;
      } else {
        this._postMessageStore.postMovePeriodMessage(this._ttid);
        this._modalStore.closeAllAndRemoveModalRoutes();
      }
    }
  };

  private refreshTimetableAndRefetchCalendarEntryDetails = (examId?: number) => {
    this._postMessageStore.postReloadContentMessage();
    this._fetchCalendarEntryDetails && this._fetchCalendarEntryDetails(undefined, examId);
    this.fetchRooms();
  };

  public handleRemoveAttachmentAll = (storageId: string) => {
    const newFiles = this.attachmentsAll.filter((a) => a.storageId !== storageId);
    this.setAttachmentsAll(newFiles);
    this.patchAttachmentsAll(newFiles);
  };

  public handleRemoveAttachmentStaff = (storageId: string) => {
    const newFiles = this.attachmentsStaff.filter((a) => a.storageId !== storageId);
    this.setAttachmentsStaff(newFiles);
    this.patchAttachmentsStaff(newFiles);
  };

  public handleAddAttachmentsAll = (files: FileDescriptorDtoV2[]) => {
    this.setAttachmentsAll(files);
    this.patchAttachmentsAll(files);
  };

  public handleAddAttachmentsStaff = (files: FileDescriptorDtoV2[]) => {
    this.setAttachmentsStaff(files);
    this.patchAttachmentsStaff(files);
  };

  private updateEntry = (id: number | undefined, body: UpdateCalendarEntryDtoV2) => {
    if (!id) {
      return;
    }

    // We only should do a block request if the main period actually has several single entries.
    const isBlockRequest = this._isBlockSelected && this.calendarEntry && this.calendarEntry?.singleEntries.length > 1;

    if (isBlockRequest) {
      CalendarViewApi.updateBlockCalendarEntryV2(id, undefined, body)
        .then(() => {
          this.refreshTimetableAndRefetchCalendarEntryDetails();
        })
        .catch((error) => {
          showErrorMessage(this.getTranslationForError(error.response.data.errorMessage));
        });
    } else {
      CalendarViewApi.updateCalendarEntryV2(id, undefined, undefined, body)
        .then(() => {
          this.refreshTimetableAndRefetchCalendarEntryDetails();
        })
        .catch((error) => {
          showErrorMessage(this.getTranslationForError(error.response.data.errorMessage));
        });
    }
  };

  public patchNotesStaff = (value: string) => {
    this.setNotesStaff(value);
    this.updateEntry(this._ttid, { notesStaff: { value } });
  };

  public patchNotesAll = (value: string) => {
    this.setNotesAll(value);
    this.updateEntry(this._ttid, { notesAll: { value } });
  };

  public patchVideoCall = (url: string) => {
    const newVideoCall: VideoCallDescriptorDto = {
      videoCallUrl: url,
      isActive: true,
    };
    this.updateEntry(this._ttid, {
      videoCall: {
        value: newVideoCall,
      },
    });
  };

  public createVideoCall = () => {
    const newVideoCall: VideoCallDescriptorDto = {
      isActive: true,
    };
    this.updateEntry(this._ttid, {
      videoCall: {
        value: newVideoCall,
      },
    });
  };

  public removeVideoCall = () => {
    const newVideoCall: VideoCallDescriptorDto = {
      isActive: false,
    };
    this.updateEntry(this._ttid, {
      videoCall: {
        value: newVideoCall,
      },
    });
  };

  private patchRooms = () => {
    this.updateEntry(this._ttid, { rooms: { value: this.selectedRooms.map((r) => r.id) } });
  };

  public patchAttachmentsStaff = (attachments: FileDescriptorDtoV2[]) => {
    this.updateEntry(this._ttid, { notesStaffFiles: { value: attachments } });
  };

  public patchAttachmentsAll = (attachments: FileDescriptorDtoV2[]) => {
    this.updateEntry(this._ttid, { notesAllFiles: { value: attachments } });
  };
}

export default CalendarEntryDetailFormStore;
