import React from 'react';

import { LessonCardRowContent } from '@/components/lesson-card/lesson-card';
import { LessonCardIndicator } from '@/components/lesson-card/components/indicator/lesson-card-indicator';
import LessonCardSubject from '@/components/lesson-card/components/subject/lesson-card-subject';
import LessonCardTextContent from '@/components/lesson-card/components/text/lesson-card-text-content';
import LessonCardResourceWithChange from '@/components/lesson-card/components/resource-with-change/lesson-card-resource-with-change';
import { LESSON_CARD_ROW_HEIGHT } from '@/components/lesson-card/hooks/use-computed-lesson-card-props';
import LessonCardStudentGroup from '@/components/lesson-card/components/student-group/lesson-card-student-group';

const LessonCardRow = (props: LessonCardRowContent) => {
  const { displayProps, isTopElement } = props;

  const renderRow = () => {
    switch (props.type) {
      case 'subject':
        return <LessonCardSubject key={props.type} subjects={props.content} isTopElement={isTopElement} />;
      case 'student-group':
        return <LessonCardStudentGroup studentGroup={props.content} isTopElement={isTopElement} />;
      case 'teachers':
      case 'rooms':
        return (
          <LessonCardResourceWithChange
            key={props.type}
            type={props.type}
            resourcesWithChange={props.content}
            showChange={displayProps?.displayVariant === 'long'}
            showTag={displayProps?.showSubstitutions}
            isTopElement={isTopElement}
          />
        );
      case 'klasse':
        return (
          <LessonCardResourceWithChange
            key={props.type}
            type={props.type}
            resourcesWithChange={props.content}
            showChange={false}
            showTag={displayProps?.showSubstitutions}
            isTopElement={isTopElement}
          />
        );
      case 'klasse-student-group':
        return (
          <LessonCardResourceWithChange
            key={props.type}
            type={props.type}
            resourcesWithChange={props.content.klassen}
            extraText={props.content.studentGroup}
            showChange={false}
            showTag={displayProps?.showSubstitutions}
            isTopElement={isTopElement}
          />
        );
      case 'text':
        return (
          <LessonCardTextContent
            key={props.type}
            texts={props.content.texts}
            previewOnly={props.content.previewOnly}
            marginTop={props.content.marginTop}
            isTopElement={isTopElement}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="lesson-card-row" data-testid="lesson-card-row" style={{ height: LESSON_CARD_ROW_HEIGHT }}>
      {!!props.content && !props.hidden && renderRow()}
      {props.displayProps?.showIndicators && props.indicator && (
        <LessonCardIndicator
          key={props.indicator.type}
          indicator={props.indicator}
          displayVariant={props.displayProps?.displayVariant}
        />
      )}
    </div>
  );
};

export default LessonCardRow;
