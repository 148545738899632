import { computed } from 'mobx';
import { t } from 'i18next';

import TodayStore from '@today/stores/today-store';
import TodayPermissionsStore from '@today/stores/today-permissions-store';
import { REMINDER_DAYS_BEFORE_END_OF_LICENCE } from '@today/stores/today-settings-store';
import TodayLockingStore from '@today/stores/today-locking-store';
import { Store, inject } from '@/types/store';
import { ILinkCardProps } from '@/ui-components/link-card/link-card';
import IncomingMessagesStore from '@/pages/messages/stores/incoming-messages-store';
import TodayParentTeacherDayStore from '@today/stores/today-parent-teacher-day-store';
import ConfigStore from '@/stores/config-store';

@Store()
class TodayQuicklinksStore {
  private incomingMessagesStore = inject(IncomingMessagesStore);
  private todayParentTeacherDayStore = inject(TodayParentTeacherDayStore);
  private todayLockingStore = inject(TodayLockingStore);
  private todayPermissionsStore = inject(TodayPermissionsStore);
  private todayStore = inject(TodayStore);
  private configStore = inject(ConfigStore);

  @computed
  get quickLinks() {
    const linkCardsProps: ILinkCardProps[] = [];

    // license
    if (this.showLicenseExpiresAt) {
      linkCardsProps.push({
        title: this.todayStore.licenseExpiresAtFormatted,
        icon: 'alert',
        iconColor: 'red',
      });
    }

    // locking notices
    linkCardsProps.push(...this.todayLockingStore.quickLinks);

    // parent teacher days
    linkCardsProps.push(...this.todayParentTeacherDayStore.quickLinks);
    const inboxBadge = this.incomingMessagesStore.badge;
    if (this.todayPermissionsStore.canSeeInboxQuickLink && inboxBadge) {
      linkCardsProps.push({
        title: t('general.inbox'),
        icon: 'admin-messages',
        href: '/messages/inbox',
        badge: Number(inboxBadge),
      });
    }

    if (this.todayPermissionsStore.canSeeStudentAbsencesQuickLink) {
      linkCardsProps.push({
        title: t('menu.menuItems.classRegister.subMenuItems.absences'),
        icon: 'absences',
        href: '/student-absences',
      });
    }

    if (this.todayPermissionsStore.canSeeAbsencesQuickLink) {
      linkCardsProps.push({
        title: t('menu.menuItems.classRegister.subMenuItems.absences'),
        icon: 'absences',
        href: '/absences',
      });
    }

    if (this.todayPermissionsStore.canSeeOpenPeriods) {
      linkCardsProps.push({
        title: t('general.openPeriods'),
        icon: 'open_periods_teacher',
        href: '/open-periods',
      });
    }

    if (this.todayPermissionsStore.canSeeStudentHomeworkQuickLink) {
      linkCardsProps.push({
        title: t('menu.menuItems.homework.homework'),
        icon: 'homework',
        href: '/student-homework',
      });
    }

    if (this.todayPermissionsStore.canSeeStudentGradesQuickLink) {
      linkCardsProps.push({
        title: t('menu.menuItems.grades.grades'),
        icon: 'grades',
        href: '/student-grades',
      });
    }

    if (this.todayPermissionsStore.canSeeClassServicesQuickLink) {
      linkCardsProps.push({
        title: t('menu.menuItems.classServices.classServices'),
        icon: 'class_services',
        href: '/class-services',
      });
    }

    if (this.todayPermissionsStore.canSeeStudentClassServicesQuickLink) {
      linkCardsProps.push({
        title: t('menu.menuItems.classServices.classServices'),
        icon: 'class_services',
        href: '/student-class-services',
      });
    }

    if (this.todayPermissionsStore.canSeeTasksQuickLink) {
      linkCardsProps.push({
        title: t('menu.menuItems.lessons.subMenuItems.tasks'),
        icon: 'tasks',
        href: '/lessons-tasks',
      });
    }

    if (this.todayPermissionsStore.canSeeContactHoursQuickLink) {
      linkCardsProps.push({
        title: t('general.contactHoursRegistrations'),
        icon: 'infoCenter_officeHours',
        href: `/contact-hours/registrations/${this.configStore.personId}`,
      });
    }

    return linkCardsProps;
  }

  @computed
  get showLicenseExpiresAt() {
    if (this.todayStore.validLicenseDays === undefined) {
      return false;
    }

    return (
      this.todayPermissionsStore.canSeeExpireLicense &&
      this.todayStore.validLicenseDays <= REMINDER_DAYS_BEFORE_END_OF_LICENCE
    );
  }
}

export default TodayQuicklinksStore;
