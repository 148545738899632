import React, { useCallback, useState } from 'react';
import Form from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import {
  ITimeRangePickerTimeSlot,
  TimeRangePickerMode,
} from '@/ui-components/time-range-picker/time-range-picker-store';
import TimeRangePicker, { ITimeRangeProps } from '@/ui-components/time-range-picker/time-range-picker';
import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';

import './form-time-range-picker.less';

export type TimeRange = {
  startTime: Dayjs;
  endTime: Dayjs;
};

interface IProps extends IFormItemProps<TimeRange> {
  value?: TimeRange;
  onChange?: (timeRange: TimeRange) => void;
  timeSlots: ITimeRangePickerTimeSlot[];
  onlyEditOwnTimeSlot?: boolean;
}

interface ITimeRangePickerWrapper
  extends Omit<ITimeRangeProps, 'startTime' | 'endTime' | 'mode' | 'label' | 'onTimeRangeChange'> {
  value?: TimeRange;
  initialValue?: TimeRange;
  onChange?: (timeRange: TimeRange) => void;
}

const TimeRangePickerWrapper = (props: ITimeRangePickerWrapper) => {
  const { t } = useTranslation();
  const [timeRange, setTimeRange] = useState<TimeRange>(
    () => props.initialValue ?? { startTime: dayjs(), endTime: dayjs() },
  );

  const handleSelect = useCallback((timeRange: TimeRange) => {
    setTimeRange(timeRange);
    props.onChange && props.onChange(timeRange);
  }, []);

  return (
    <div className="form-time-range-picker">
      <TimeRangePicker
        label={t('general.startTime')}
        startTime={timeRange.startTime}
        endTime={timeRange.endTime}
        timeSlots={props.timeSlots}
        mode={TimeRangePickerMode.Start}
        disabled={props.disabled}
        onlyEditOwnTimeSlot={props.onlyEditOwnTimeSlot}
        onTimeRangeChange={(startTime: Dayjs, endTime: Dayjs) => handleSelect({ startTime, endTime })}
      />
      <TimeRangePicker
        label={t('general.endTime')}
        startTime={timeRange.startTime}
        endTime={timeRange.endTime}
        timeSlots={props.timeSlots}
        mode={TimeRangePickerMode.End}
        disabled={props.disabled}
        onlyEditOwnTimeSlot={props.onlyEditOwnTimeSlot}
        onTimeRangeChange={(startTime: Dayjs, endTime: Dayjs) => handleSelect({ startTime, endTime })}
      />
    </div>
  );
};

export const FormTimeRangePicker = (props: IProps) => {
  return (
    <Form.Item
      name={props.name}
      label={props.label}
      className="form-time-range-picker"
      rules={props.rules}
      dependencies={props.dependencies}
      labelAlign="left"
      initialValue={props.initialValue}
    >
      <TimeRangePickerWrapper
        timeSlots={props.timeSlots}
        value={props.value}
        onChange={props.onChange}
        initialValue={props.initialValue}
        onlyEditOwnTimeSlot={props.onlyEditOwnTimeSlot}
        disabled={props.disabled}
      />
    </Form.Item>
  );
};
