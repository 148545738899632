import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import TodaySupportAccessStore from '@today/stores/today-support-access-store';
import { SupportAccessToggle } from '@today/components/support-access-toggle/support-access-toggle';
import MotdCardCarousel from '@today/components/motd-card-carousel/motd-card-carousel';
import TodayParentTeacherDayStore from '@today/stores/today-parent-teacher-day-store';
import QuickLinksList from '@today/components/quicklinks-list/qucklinks-list';
import LessonsList from '@today/components/lessons-list/lessons-list';
import TodayStore from '@today/stores/today-store';
import TodayMessagesOfTheDayStore from '@today/stores/today-messages-of-the-day-store';
import { Button, Icon } from '@/ui-components';
import useStore from '@/hooks/useStore';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import TodayLockingStore from '@today/stores/today-locking-store';

import './today.less';

const Today = observer(() => {
  const { t } = useTranslation();
  const todayStore = useStore(TodayStore);
  const todayMessagesOfTheDayStore = useStore(TodayMessagesOfTheDayStore);
  const todayParentTeacherDayStore = useStore(TodayParentTeacherDayStore);
  const todayLockingStore = useStore(TodayLockingStore);
  const todaySupportAccessStore = useStore(TodaySupportAccessStore);

  function handleDisableNewTodaysExperimentPage() {
    window.location.href = '/today';
  }

  useComponentDidMount(async () => {
    try {
      await Promise.allSettled([
        todayStore.initTodayMeta(),
        todayMessagesOfTheDayStore.getMessagesOfTheDay(),
        todayParentTeacherDayStore.getParentTeacherDays(),
        todayLockingStore.getLockingDetails(),
        todayMessagesOfTheDayStore.getUnreadMessagesOfTheDayCount(),
      ]);
    } catch (error) {
      //
    }
  });

  return (
    <div>
      <div className="today-page">
        <div className="today-page-header">
          <div className="today-page-header-greetings">
            <h1>{todayStore.greeting}</h1>
            {todayStore.lastLoginFormatted && (
              <p>
                {t('general.todayLastLogin')} {todayStore.lastLoginFormatted}
              </p>
            )}
          </div>

          <div className="today-page-header-buttons">
            <Button onClick={handleDisableNewTodaysExperimentPage}>{t('general.backToOldTodayPage')}</Button>
            {todayStore.canSeeNewMotdButton && (
              <Button outline href="/messages-of-the-day-new/new" className="new-motd-button">
                <Icon type="lesson-plus" />
                <span>New</span>
              </Button>
            )}
          </div>
        </div>

        <MotdCardCarousel />

        <div className="today-page-container">
          <div className="today-page-main-container">
            <LessonsList />
            <QuickLinksList />
          </div>
          {todaySupportAccessStore.showIsSupportAccessOpen && (
            <div className="today-page-footer-container">
              <SupportAccessToggle />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Today;
