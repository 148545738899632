import { action, computed, observable } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';

import { inject, Store } from '@/types/store';
import { TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import { TimetableGridSlotsStore } from '@te/standard/stores/grid/timetable-grid-slots-store';
import { TimetableGridDimensionsStore } from '@te/standard/stores/grid/timetable-grid-dimensions-store';
import {
  TIMEGRID_SLOT_TEXT_POSITION_ABOVE,
  TIMEGRID_SLOT_TEXT_POSITION_BELOW,
  TimeGridSlotTextPosition,
} from '@te/standard/components/grid/slot/timetable-grid-slot-time';

const MAX_TIME_OFFSET_DIFFERENCE = 12;

@Store()
export class TimetableTimeStore {
  private timetableMetaStore = inject(TimetableMetaStore);
  private timetableGridSlotsStore = inject(TimetableGridSlotsStore);
  private timetableGridDimensionsStore = inject(TimetableGridDimensionsStore);

  @observable private _currentTime: Dayjs = dayjs();
  @observable private _currentTimeInterval: NodeJS.Timer | undefined;

  @action
  watchCurrentTime(currentTime: Dayjs) {
    this._currentTime = currentTime;
    this._currentTimeInterval = setInterval(() => {
      if (!this._currentTime.isSame(dayjs(), 'minutes')) {
        this._currentTime = dayjs();
      }
    }, 1000);
  }

  @action
  reset() {
    if (this._currentTimeInterval) {
      clearInterval(this._currentTimeInterval);
    }
  }

  @computed
  get currentTime(): Dayjs {
    return this._currentTime;
  }

  @computed
  get isCurrentTimeVisible(): boolean {
    const isCurrentTimeInSelectedWeek = this.timetableMetaStore.isDateInCurrentTimeGridDateRange(this.currentTime);
    const isCurrentTimeBetweenTimeGridRange = this.isCurrentTimeBetweenTimeGridRange;

    return isCurrentTimeInSelectedWeek && isCurrentTimeBetweenTimeGridRange;
  }

  @action.bound
  isTimeRangeBetweenTimeGridRange(startTime: Dayjs, endTime: Dayjs): boolean {
    const { timeGridStartTime, timeGridEndTime } = this.timetableGridSlotsStore;
    const timeGridStartTimeMinutes = timeGridStartTime.minute() + timeGridStartTime.hour() * 60;
    const timeGridEndTimeMinutes = timeGridEndTime.minute() + timeGridEndTime.hour() * 60;

    const startTimeMinutes = startTime.minute() + startTime.hour() * 60;
    const endTimeMinutes = endTime.minute() + endTime.hour() * 60;

    return startTimeMinutes >= timeGridStartTimeMinutes && endTimeMinutes <= timeGridEndTimeMinutes;
  }

  @action.bound
  shouldHideTimeGridSlotText(timeGridSlot: Dayjs, textPosition: TimeGridSlotTextPosition): boolean {
    const currentTimeOffset = this.timetableGridDimensionsStore.offsetFromStartTime(this.currentTime);
    const timeOffset = this.timetableGridDimensionsStore.offsetFromStartTime(timeGridSlot);
    const textPositionOffset =
      textPosition === 'above'
        ? TIMEGRID_SLOT_TEXT_POSITION_ABOVE
        : textPosition === 'below'
        ? TIMEGRID_SLOT_TEXT_POSITION_BELOW
        : 0;
    const timeGridSlotTextPosition = timeOffset + textPositionOffset;
    return (
      this.isCurrentTimeVisible && Math.abs(timeGridSlotTextPosition - currentTimeOffset) <= MAX_TIME_OFFSET_DIFFERENCE
    );
  }

  @computed
  private get isCurrentTimeBetweenTimeGridRange(): boolean {
    const { timeGridStartTime, timeGridEndTime } = this.timetableGridSlotsStore;
    const startMinutes = timeGridStartTime.minute() + timeGridStartTime.hour() * 60;
    const endMinutes = timeGridEndTime.minute() + timeGridEndTime.hour() * 60;
    const currentMinutes = this.currentTime.minute() + this.currentTime.hour() * 60;

    return currentMinutes >= startMinutes && currentMinutes <= endMinutes;
  }
}
