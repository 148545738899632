import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import { ExamDto } from '@untis/wu-rest-view-api/api';
import { WUTable } from '@/ui-components';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { Columns, ColumnType, DateFormat } from '@/ui-components/wu-table/wu-table-column-mapper';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import { ExamFormStore } from '@ls/exams/exam-form/exam-form-store';
import useStore from '@/hooks/useStore';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import ExamForm from '@ls/exams/exam-form/exam-form';
import ModalStore from '@/stores/modal-store';
import { AdministrationExamLockStore } from '@ad/exam-lock/administration-exam-lock-store';

import './exam-lock-conflict-table.less';

interface IProps {
  store: AdministrationExamLockStore;
  conflictExams: ExamDto[];
}

interface IExamLockConflictRow extends ITableRowKey {
  examId: number;
  examName: string;
  examType: string;
  examDate: Dayjs;
  startTime: Dayjs;
  endTime: Dayjs;
}

const ExamLockConflictTable = (props: IProps) => {
  const { t } = useTranslation();
  const rightStore = useStore(RightsStore);
  const modalStore = useStore(ModalStore);

  const errorTextRef = useRef<HTMLDivElement>(null);

  useComponentDidMount(() => {
    props.store.setErrorTextRef(errorTextRef);
  });

  const columns: Columns<IExamLockConflictRow> = [
    {
      type: ColumnType.Text,
      key: 'examType',
      header: t('general.type'),
    },
    {
      type: ColumnType.Text,
      key: 'examName',
      header: t('general.name'),
    },
    {
      type: ColumnType.Date,
      key: 'examDate',
      format: DateFormat.Date,
      header: t('general.date'),
    },
    {
      type: ColumnType.Date,
      key: 'startTime',
      format: DateFormat.Time,
      header: t('general.fromDate'),
    },
    {
      type: ColumnType.Date,
      key: 'endTime',
      format: DateFormat.Time,
      header: t('general.toDate'),
    },
  ];

  const columnActions = [];

  if (rightStore.canRead(Right.EXAMINATION, ElementType.ALL, true)) {
    columnActions.push({
      tooltip: t('general.edit'),
      icon: 'edit',
      onClick: (row: IExamLockConflictRow) => {
        const examFormStore = new ExamFormStore(row.examId);
        modalStore.openModalDialog({
          children: (
            <ExamForm
              examFormStore={examFormStore}
              onSaveCallback={() => props.store.findConflictsForExamLock()}
              onDeleteCallback={() => props.store.findConflictsForExamLock()}
            />
          ),
          title: '',
          size: 'full-size',
          containsForm: true,
        });
      },
    });
  }

  if (columnActions.length > 0) {
    columns.push({
      type: ColumnType.HoverActions,
      key: 'actions',
      width: columnActions.length * 45 + 'px',
      actionIcons: columnActions,
    });
  }

  const rowData: IExamLockConflictRow[] = props.conflictExams.map((exam) => {
    const { examStart, examEnd } = exam;
    const examStartDayjs = dayjs(examStart);
    const examEndDayjs = dayjs(examEnd);
    return {
      key: exam.examId,
      examId: exam.examId,
      examName: exam.examName || '',
      examType: exam.examType?.displayName || '',
      examDate: examEndDayjs,
      startTime: examStartDayjs,
      endTime: examEndDayjs,
    };
  });

  return (
    <div className="exam-lock-conflict-table-container">
      <div className="exam-lock-conflict-error-text" ref={errorTextRef}>
        {t('administration.examLock.form.examConflict')}
      </div>
      <WUTable<IExamLockConflictRow> columns={columns} rowData={rowData} />
    </div>
  );
};

export default ExamLockConflictTable;
