import { observer } from 'mobx-react-lite';
import React, { ReactElement, Ref } from 'react';

import useStore from '@/hooks/useStore';
import MainMenuHeader from '@/ui-components/navigation-bar/menu-header/main-menu-header/main-menu-header';
import SubMenuHeader from '@/ui-components/navigation-bar/menu-header/sub-menu-header/sub-menu-header';
import { NavigationBarStore } from '@/ui-components/navigation-bar/navigation-bar-store';
import './menu-header.less';

interface IProps {
  title: string;
  mainMenuHeaderRef: Ref<HTMLDivElement>;
  subMenuHeaderRef: Ref<HTMLDivElement>;
  departmentHeaderRef: Ref<HTMLDivElement>;
  studentHeaderRef: Ref<HTMLDivElement>;
  logo?: JSX.Element;
  customHeaderComponent?: {
    component: ReactElement;
    height: number;
  };
  headerHeight: number;
}

const MenuHeader = observer((props: IProps) => {
  const store = useStore(NavigationBarStore);

  return (
    <div className="menu-header-container">
      <MainMenuHeader
        title={props.title}
        isInSubMenu={!!store.isInSubMenu || store.isInDepartmentSelection || store.isInStudentSelection}
        collapsed={store.collapsed}
        showMenuItemsFadeAnimationWithIcons={store.showMenuItemsFadeAnimationWithIcons}
        subtitle={store.mainMenuHeaderSubTitle}
        logo={props.logo}
        customHeaderComponent={props.customHeaderComponent}
        ref={props.mainMenuHeaderRef}
        isMainMenuHeaderOverlap={store.isMainMenuHeaderOverlap}
        headerHeight={props.headerHeight}
      />
      <SubMenuHeader
        isInSubMenu={!!store.isInSubMenu}
        showMenuItemsFadeAnimationWithIcons={store.showMenuItemsFadeAnimationWithIcons}
        subMenuHeaderTitle={store.subMenuHeaderTitle}
        subMenuHeaderTag={store.subMenuHeaderTag}
        collapsed={store.collapsed}
        handleBack={store.handleBack}
        customHeaderComponent={props.customHeaderComponent}
        ref={props.subMenuHeaderRef}
        isSubMenuHeaderOverlap={store.isSubMenuHeaderOverlap}
        headerHeight={props.headerHeight}
      />
      <SubMenuHeader
        isInSubMenu={store.isInDepartmentSelection}
        showMenuItemsFadeAnimationWithIcons={store.showMenuItemsFadeAnimationWithIcons}
        subMenuHeaderTitle={store.departmentsText}
        collapsed={store.collapsed}
        handleBack={store.handleBack}
        customHeaderComponent={props.customHeaderComponent}
        ref={props.departmentHeaderRef}
        isSubMenuHeaderOverlap={store.isDepartmentHeaderOverlap}
        headerHeight={props.headerHeight}
      />
      <SubMenuHeader
        isInSubMenu={store.isInStudentSelection}
        showMenuItemsFadeAnimationWithIcons={store.showMenuItemsFadeAnimationWithIcons}
        subMenuHeaderTitle={store.studentsText}
        collapsed={store.collapsed}
        handleBack={store.handleBack}
        customHeaderComponent={props.customHeaderComponent}
        ref={props.studentHeaderRef}
        isSubMenuHeaderOverlap={store.isStudentHeaderOverlap}
        headerHeight={props.headerHeight}
      />
    </div>
  );
});

export default MenuHeader;
