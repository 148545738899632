import React from 'react';

import { Button, Icon } from '@/ui-components';
import { AsyncButton } from '@/ui-components/async-button/async-button';
import { IPageHeaderRegularButtonProps } from '@/ui-components/page/page';

export const PageHeaderRegularButton = (props: IPageHeaderRegularButtonProps) => {
  const ButtonElement = props.async ? AsyncButton : Button;
  return (
    <ButtonElement
      type={props.type || 'primary'}
      outline={props.outline}
      size="large"
      onClick={props.onClick}
      testId={props.dataTestId}
    >
      {props.icon && <Icon type={props.icon} />}
      {props.label}
    </ButtonElement>
  );
};
