import { message } from 'antd';
import { t } from 'i18next';
import { action, observable } from 'mobx';
import React from 'react';

import { PlatformApplicationDto, PlatformApplicationRegistrationOverviewDto } from '@untis/wu-rest-view-api';
import { PlatformApplicationViewApi } from '@/stores/api-store';
import ConfigStore from '@/stores/config-store';
import ModalStore from '@/stores/modal-store';
import { inject, IStore, Store } from '@/types/store';
import { ErrorMessageEnum, showError } from '@/utils/error-handling/error-message';
import RegisterNewPlatformView from '@pa/components/register-new-platform-view/register-new-platform-view';
import { IPlatformApplicationMenuItem } from '@pa/types/IPlatformApplicationMenuItem';

// Due to a property of the mini-css-extract-plugin plugin, PLATFORM_APPLICATION_DEFAULT_ICON has to be exported from
// this file. Otherwise the error: "Conflicting order. Following module has been added: ... despite it was not able to
// fulfill desired ordering with these modules: ... - couldn't fulfill desired order of chunk group(s)" occurs.
export const PLATFORM_APPLICATION_DEFAULT_ICON = 'pa-stack.svg';

export interface IPlatformListApplication extends PlatformApplicationRegistrationOverviewDto {
  deletable?: boolean;
  schoolActive?: boolean;
}

type SortablePlatform = IPlatformListApplication | PlatformApplicationDto;

/**
 * Responsible for the platform overview page.
 */
@Store()
export default class PlatformStore implements IStore {
  @observable isLoading = true;
  @observable isLoadingAvailablePlatforms = true;
  @observable registeredPlatforms: PlatformApplicationDto[] = [];
  @observable availablePlatforms: IPlatformListApplication[] = [];

  private modalStore = inject(ModalStore);
  private configStore = inject(ConfigStore);

  @action
  public async fetchRegisteredPlatforms() {
    this.isLoading = true;

    try {
      const response = await PlatformApplicationViewApi.getRegisteredPlatforms();
      this.registeredPlatforms = response.data;
      this.registeredPlatforms = this.sortPlatforms(this.registeredPlatforms) as PlatformApplicationDto[];
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
      this.registeredPlatforms.length = 0;
    }

    this.isLoading = false;
  }

  @action
  private async fetchAvailablePlatforms() {
    this.isLoadingAvailablePlatforms = true;

    try {
      const response = await PlatformApplicationViewApi.getAvailablePlatforms();
      this.availablePlatforms = response.data;
      this.availablePlatforms = this.sortPlatforms(this.availablePlatforms);
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
      this.availablePlatforms.length = 0;
    }

    this.isLoadingAvailablePlatforms = false;
  }

  private sortPlatforms(platforms: SortablePlatform[]): SortablePlatform[] {
    let activePlatforms: SortablePlatform[] = [];
    let inactivePlatforms: SortablePlatform[] = [];

    platforms.forEach((platform: IPlatformListApplication) => {
      if (!!platform.schoolActive) {
        activePlatforms.push(platform);
      } else {
        inactivePlatforms.push(platform);
      }
    });
    activePlatforms = activePlatforms.sort((pa1, pa2) => {
      return pa1.name.localeCompare(pa2.name);
    });
    inactivePlatforms = inactivePlatforms.sort((pa1, pa2) => {
      return pa1.name.localeCompare(pa2.name);
    });
    return [...activePlatforms, ...inactivePlatforms];
  }

  public async handleDelete(id: number, name: string) {
    if (!id) {
      showError(ErrorMessageEnum.ERROR);
      return;
    }

    const result = await this.modalStore.deprecatedBooleanUserPrompt({
      content: t('platform.delete', { applicationName: name }),
      okText: t('general.delete'),
    });
    if (result) {
      await this.deletePlatformRegistration(id);
      message.success(t('platform.applicationRemoved'));
      this.fetchRegisteredPlatforms();
    }
  }

  @action
  private async deletePlatformRegistration(platformId: number) {
    if (!platformId) {
      return;
    }
    this.isLoading = true;
    try {
      await PlatformApplicationViewApi._delete(platformId);
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
    }
    await this.fetchRegisteredPlatforms();
    this.configStore.fetchPlatformApplicationMenuItems();
    this.isLoading = false;
  }

  public openRegisterNewPlatformView() {
    this.fetchAvailablePlatforms();

    this.modalStore.openModalDialog({
      className: 'register-new-platform-view',
      children: <RegisterNewPlatformView />,
      size: 'full-size',
    });
  }

  public getPlatformApplicationItem(name: string): IPlatformApplicationMenuItem | undefined {
    return this.configStore.platformApplicationMenuItems.find(
      (item: IPlatformApplicationMenuItem) => item.name === name,
    );
  }

  public isRegisteredGetId(externId: number): number | undefined {
    const item = this.registeredPlatforms.find((item: PlatformApplicationDto) => item.externId === externId);
    return item?.id;
  }

  public async trackPlatformApplicationUsage(id: number, platformApplicationViewType: string) {
    PlatformApplicationViewApi.trackPlatformUsage(id, platformApplicationViewType);
  }
}
