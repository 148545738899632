import { message } from 'antd';
import { t } from 'i18next';
import { action, computed, observable } from 'mobx';
import React from 'react';
import { FormInstance } from 'antd/es/form';
import { AxiosError } from 'axios';

import PlatformDetailView from '../components/platform-detail-view/platform-detail-view';

import {
  PlatformApplicationDto,
  PlatformApplicationUrlDto,
  PlatformApplicationUrlDtoViewTypeEnum,
} from '@untis/wu-rest-view-api';
import { PlatformApplicationViewApi } from '@/stores/api-store';
import ConfigStore from '@/stores/config-store';
import ModalStore from '@/stores/modal-store';
import { inject, IStore, Store } from '@/types/store';
import { ErrorMessageEnum, showError } from '@/utils/error-handling/error-message';
import PlatformDetailInfoDialog from '@pa/components/platform-detail-info-dialog/platform-detail-info-dialog';
import PlatformStore from '@pa/stores/platform-store';
import { getInitialDomainArray } from '@pa/components/redirect-uri-input/redirect-uri-input-item-mapper';
import {
  apiPermissionDescriptionMapping,
  apiPermissionNameMapping,
  getNameForRoleType,
  isPlatformApplicationSaved,
  sortUrlInformations,
  validateDomain,
} from '@pa/utils/platform-utils';
import {
  IAccessDetailsRowData,
  IApiPermissionRowData,
} from '@/pages/platform/components/platform-detail-view/platform-detail-data-interface-integration-section';

/**
 * Responsible for data and logic of the platform details page.
 */
@Store()
export default class PlatformDetailViewStore implements IStore {
  @observable isLoading = false;
  @observable isRegisterMode = true;
  @observable platformViewData: PlatformApplicationDto | null = null;
  @observable domain = '';
  @observable headmasterEmail: string = '';
  @observable domainPattern = '';
  @observable active = false;
  @observable sendCredentialsAutomatically = false;
  @observable menuUrlInformations: PlatformApplicationUrlDto[] = []; // contains url infos with view type "OVERVIEW"
  @observable detailUrlInformations: PlatformApplicationUrlDto[] = [];
  @observable examUrlInformation: PlatformApplicationUrlDto[] = [];
  @observable submitFailed = false;

  private modalStore = inject(ModalStore);
  private platformStore = inject(PlatformStore);
  private configStore = inject(ConfigStore);
  private form: FormInstance | undefined;
  private domainFormFieldName: string = '';
  private responseErrorMapping: Map<number, string> = new Map([
    [-1, 'general.enterAllFields'],
    [406, 'general.domainContainsInvalidCharacters'],
    [409, 'general.domainNotValid'],
  ]);

  @computed get unconfiguredMenuUserRoleDropDownItems() {
    return this.menuUrlInformations
      .filter((item) => !item.active)
      .map((item) => {
        return { key: item.role, value: getNameForRoleType(item.role) };
      });
  }

  @computed get unconfiguredDetailUserRoleDropDownItems() {
    return this.detailUrlInformations
      .filter((item) => !item.active)
      .map((item) => {
        return { key: item.role, value: getNameForRoleType(item.role) };
      });
  }

  @computed get showDataInterfaceSection(): boolean {
    return this.platformViewData?.serverToServerCommunicationEnabled ?? false;
  }

  @computed get showUserInterfaceIntegration(): boolean {
    return this.platformViewData?.enableUiIntegration ?? false;
  }

  @computed get apiPermissionRows(): IApiPermissionRowData[] {
    if (!this.platformViewData) {
      return [];
    }

    return this.platformViewData.permissions.permissions
      .map((permission): IApiPermissionRowData => {
        return {
          key: permission.valueOf(),
          name: t(apiPermissionNameMapping.get(permission) ?? ''),
          description: t(apiPermissionDescriptionMapping.get(permission) ?? ''),
        };
      })
      .sort((a, b) => {
        return ('' + a.key).localeCompare(b.key);
      });
  }

  @computed get accessDetailsRows(): IAccessDetailsRowData[] {
    return this.platformViewData
      ? [
          {
            key: 'schoolName',
            name: t('general.schoolName'),
            value: this.platformViewData?.schoolName ?? '',
          },
          {
            key: 'tenantId',
            name: t('general.tenantId'),
            value: this.configStore.tenantId?.toString() ?? '',
          },
          {
            key: 'serverUrl',
            name: t('platform.accessDetails.serverUrl'),
            value: this.platformViewData?.serverUrl ?? '',
          },
          {
            key: 'oidcClientId',
            name: t('platform.accessDetails.oidcClientId'),
            value: this.platformViewData?.oidcClientId ?? '',
          },
          {
            key: 'oidcSecret',
            name: t('platform.accessDetails.oidcSecret'),
            value: this.platformViewData?.oidcSecret ?? '',
          },
        ]
      : [];
  }

  public setForm(form: FormInstance) {
    this.form = form;
  }

  public setDomainFormFieldName(fieldName: string) {
    this.domainFormFieldName = fieldName;
  }

  @action.bound
  public registerApplication(applicationId: number) {
    this.isRegisterMode = true;
    this.modalStore.closeModal();
    this.reset();
    this.loadRegistrationDetailsForApplicationWithId(applicationId);
    this.openPlatformDetails();
  }

  @action.bound
  public async resetPlatformApplicationPassword(applicationId: number) {
    const userPromptResult = await this.modalStore.deprecatedBooleanUserPrompt({
      content: t('general.passwordResetConfirm'),
    });
    if (userPromptResult) {
      await this.resetPlatformApplicationPasswordWithId(applicationId, this.sendCredentialsAutomatically);
    }
  }

  @action.bound
  public openPlatformDetailView(id: number) {
    this.isRegisterMode = false;
    this.fetchPlatformViewData(id);
    this.openPlatformDetails();
  }

  @action.bound
  public async handleDelete() {
    if (!this.platformViewData) {
      showError(ErrorMessageEnum.ERROR);
      return;
    }
    const result = await this.modalStore.deprecatedBooleanUserPrompt({
      content: t('platform.delete', { applicationName: this.platformViewData.name }),
      okText: t('general.delete'),
    });
    if (result) {
      await this.deletePlatformRegistration(this.platformViewData.id);
      this.modalStore.closeModal();
      message.success(t('platform.applicationRemoved'));
      this.platformStore.fetchRegisteredPlatforms();
    }
  }

  @action.bound
  public savePlatformDetails() {
    const domainValidationError = validateDomain(this.domainPattern, this.domain);
    if (domainValidationError !== 0) {
      this.handleError(domainValidationError);
      return;
    }

    if (!this.platformViewData?.id) {
      this.openPlatformDetailInfoDialog(true);
    } else {
      this.saveAction();
    }
  }

  @action.bound
  public setUrlInformationRoleIsActiveStatus(
    urlInformationList: PlatformApplicationUrlDto[],
    element: PlatformApplicationUrlDto | undefined,
    isActive: boolean,
  ) {
    const elementIndex = urlInformationList.findIndex((item) => item.role === element?.role && item.id === element?.id);
    if (elementIndex !== -1) {
      const updatedElement: PlatformApplicationUrlDto = { ...urlInformationList[elementIndex], active: isActive };
      urlInformationList[elementIndex] = updatedElement;
    }
  }

  @action.bound
  public setDomain(domain: string) {
    this.domain = domain;
  }

  @action.bound
  public setSubmitFailed() {
    this.submitFailed = true;
  }

  @action.bound
  private async resetPlatformApplicationPasswordWithId(applicationId: number, credentialTransferEnabled: boolean) {
    this.isLoading = true;

    try {
      const response = await PlatformApplicationViewApi.resetPassword(applicationId);
      this.reset();
      this.platformViewData = response.data;
      this.initializeFromPlatformViewData(response.data);
      this.isRegisterMode = false;

      if (credentialTransferEnabled) {
        message.success(
          t('platform.details.credentialTransfer.success', { applicationName: this.platformViewData?.name }),
        );
      } else {
        this.openPlatformDetailInfoDialog(false, true);
      }
    } catch (error) {
      console.error(error);

      if (credentialTransferEnabled) {
        message.error(t('platform.details.credentialTransfer.error', { applicationName: this.platformViewData?.name }));
      } else {
        showError(ErrorMessageEnum.ERROR);
      }
    }

    this.isLoading = false;
  }

  @action.bound
  private async fetchPlatformViewData(applicationId: number) {
    this.reset();
    this.isLoading = true;
    try {
      const response = await PlatformApplicationViewApi.findById(applicationId);
      this.platformViewData = response.data;
      this.initializeFromPlatformViewData(response.data);
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
      this.platformViewData = null;
      this.modalStore.closeModal();
    }
    this.isLoading = false;
  }

  @action.bound
  private async deletePlatformRegistration(platformId: number) {
    if (!platformId) {
      return;
    }
    this.isLoading = true;

    try {
      await PlatformApplicationViewApi._delete(platformId);
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
    }

    this.isLoading = false;
  }

  @action.bound
  private initializeFromPlatformViewData(platform: PlatformApplicationDto) {
    this.active = this.getPlatformApplicationActiveStatus(platform);
    this.sendCredentialsAutomatically = platform.sendCredentialsAutomatically;
    this.domain = getInitialDomainArray(platform.domain, platform.domainPattern).join('.');
    this.domainPattern = platform.domainPattern ?? '';
    platform.urlInformation.forEach((info: PlatformApplicationUrlDto) => {
      switch (info.viewType) {
        case PlatformApplicationUrlDtoViewTypeEnum.OVERVIEW:
          this.menuUrlInformations.push(info);
          break;
        case PlatformApplicationUrlDtoViewTypeEnum.DETAIL:
          this.detailUrlInformations.push(info);
          break;
        case PlatformApplicationUrlDtoViewTypeEnum.EXAMLIST:
          this.examUrlInformation.push(info);
          break;
      }
    });

    this.menuUrlInformations = sortUrlInformations(this.menuUrlInformations);
    this.detailUrlInformations = sortUrlInformations(this.detailUrlInformations);
    this.examUrlInformation = sortUrlInformations(this.examUrlInformation);
  }

  @action.bound
  private async saveAction(isPasswordReset?: boolean, headmasterEmail?: string) {
    if (!this.platformViewData) {
      showError(ErrorMessageEnum.ERROR);
      return;
    }

    this.copyPlatformViewDataIntoDto();
    this.isLoading = true;
    this.platformViewData.headmasterEmail = headmasterEmail;

    try {
      if (this.platformViewData.id) {
        await PlatformApplicationViewApi.save(this.platformViewData);
      } else {
        await PlatformApplicationViewApi.create(this.platformViewData);
      }
      this.platformStore.fetchRegisteredPlatforms();
      this.configStore.fetchPlatformApplicationMenuItems();
      if (isPasswordReset) {
        message.success(t('general.passwordResetSuccess'));
        this.modalStore.closeModal();
      } else {
        message.success(t('platform.changesSaved'));
        this.modalStore.closeAll();
      }
    } catch (error) {
      this.isLoading = false;
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const errorStatusCode = axiosError.response.status;
        this.handleError(errorStatusCode);
      }

      console.error(error);
    }

    this.isLoading = false;
  }

  @action.bound
  private async loadRegistrationDetailsForApplicationWithId(applicationId: number) {
    this.isLoading = true;

    try {
      const response = await PlatformApplicationViewApi.register(applicationId);
      this.platformViewData = response.data;
      this.initializeFromPlatformViewData(response.data);
    } catch (error) {
      this.modalStore.closeModal();
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
    }

    this.isLoading = false;
  }

  @action.bound
  private reset() {
    this.platformViewData = null;
    this.domain = '';
    this.domainPattern = '';
    this.active = false;
    this.sendCredentialsAutomatically = false;
    this.submitFailed = false;
    this.menuUrlInformations.length = 0;
    this.detailUrlInformations.length = 0;
    this.examUrlInformation.length = 0;
  }

  private copyPlatformViewDataIntoDto() {
    if (!this.platformViewData) {
      return;
    }
    this.platformViewData.domain = this.domain;
    this.platformViewData.schoolActive = this.active;
    this.platformViewData.sendCredentialsAutomatically = this.sendCredentialsAutomatically;
    this.platformViewData.urlInformation = [];
    this.platformViewData.urlInformation.push(...this.menuUrlInformations);
    this.platformViewData.urlInformation.push(...this.detailUrlInformations);
    this.platformViewData.urlInformation.push(...this.examUrlInformation);
  }

  private openPlatformDetails() {
    this.modalStore.deprecatedOpenModalDialog({
      content: <PlatformDetailView />,
      size: 'full-size',
      closable: true,
      closeOnEscape: false,
    });
  }

  private openPlatformDetailInfoDialog = (isFirstSave?: boolean, isResetPassword?: boolean) => {
    if (this.platformViewData) {
      const isClosable = isFirstSave ?? false;
      this.modalStore.deprecatedOpenModalDialog({
        content: (
          <PlatformDetailInfoDialog
            isFirstSave={isFirstSave ?? false}
            isServerToServerCommunicationEnabled={this.platformViewData.serverToServerCommunicationEnabled}
            password={this.platformViewData.password}
            isResetPassword={isResetPassword}
            onOk={(headmasterEmail?: string) => this.saveAction(!isFirstSave, headmasterEmail)}
          />
        ),
        size: 'md',
        closable: isClosable,
        closeOnEscape: false,
        maskClosable: isClosable,
      });
    }
  };

  private getPlatformApplicationActiveStatus(platformApplication: PlatformApplicationDto): boolean {
    const firstSave = isPlatformApplicationSaved(platformApplication);
    return firstSave ? true : platformApplication.schoolActive;
  }

  private handleError(statusCode: number) {
    const formItemErrorMessage = this.responseErrorMapping.get(statusCode);
    if (formItemErrorMessage) {
      this.form &&
        this.form.setFields([
          {
            name: this.domainFormFieldName,
            errors: [t(formItemErrorMessage)],
          },
        ]);
    } else {
      showError(ErrorMessageEnum.ERROR);
    }
  }
}
