import React from 'react';

import { Button, Icon, OptionPopup } from '@/ui-components';
import { IPageHeaderOptionButtonProps } from '@/ui-components/page/page';

export const PageHeaderOptionButton = (props: IPageHeaderOptionButtonProps) => {
  return (
    <OptionPopup options={props.options} placement="bottomRight">
      <Button size="large" type={props.type || 'primary'} outline={props.outline} testId={props.dataTestId}>
        <Icon type={props.icon} />
        {props.label}
      </Button>
    </OptionPopup>
  );
};
