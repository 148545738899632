import React from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import { Route } from 'react-router-dom';

import { ReasonsOfExemptionStore } from '@/pages/master-data/reasons-of-exemption/reasons-of-exemption-store';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import useStore from '@/hooks/useStore';
import { MasterDataReasonsOfExemptionList } from '@/pages/master-data/reasons-of-exemption/reasons-of-exemption-list';
import { ReasonsOfExemptionNew } from '@/pages/master-data/reasons-of-exemption/reasons-of-exemption-new';
import { ReasonsOfExemptionEdit } from '@/pages/master-data/reasons-of-exemption/reasons-of-exemption-edit';

export const MasterDataReasonsOfExemption = observer(() => {
  const [form] = Form.useForm<ReasonsOfExemptionStore>();
  const reasonsOfExemptionStore = useStore(ReasonsOfExemptionStore);

  useComponentDidMount(() => {
    reasonsOfExemptionStore.setFormInstance(form);
  });

  return (
    <>
      <MasterDataReasonsOfExemptionList />
      <Route path="/reasons-of-exemptions/new" render={() => <ReasonsOfExemptionNew />} />
      <Route path="/reasons-of-exemptions/edit/:id" render={() => <ReasonsOfExemptionEdit />} />
    </>
  );
});
