import Axios, { AxiosError } from 'axios';

import ModalStore from '@/stores/modal-store';
import RouterStore from '@/stores/router-store';
import {
  Configuration,
  CalendarViewControllerApi,
  LegalGuardiansViewApi as LGApi,
  ApprenticeRepresentativesViewApi as ARApi,
  ProfileViewApi as ProfileApi,
  MessageViewApi as MessageApi,
  ClassregAbsenceViewApi as ClassregAbsenceApi,
  ClassRegOverviewViewV2Api as ClassRegOverviewV2Api,
  ClassRegOpenPeriodsViewApi as ClassRegOpenPeriodsApi,
  ClassRegLessonTopicViewApi as ClassRegLessonTopicApi,
  PlatformApplicationViewControllerApi,
  TeacherAbsenceViewApi as TeacherAbsenceViewApiImpl,
  TeacherAbsenceReasonViewApi as TeacherAbsenceReasonViewApiImpl,
  SubstitutionDataViewApi as SubstitutionDataViewApiImpl,
  TriggerViewApi as TriggerViewApiImpl,
  AppViewApi as AppViewApiImpl,
  TimetableViewApi as TimetableViewApiImpl,
  TimetableSettingsApi as TimetableSettingsViewApiImpl,
  IcalSettingsApi as ICalSettingsViewApiImpl,
  TimegridViewApi as TimegridViewApiImpl,
  StudentViewApi as StudentViewApiImpl,
  ExcuseStatusViewApi as ExcuseStatusViewApiImpl,
  SubjectViewApi as SubjectViewApiImpl,
  RoomViewApi as RoomViewApiImpl,
  StudentAbsenceReasonsViewApi as StudentAbsenceReasonsViewApiImpl,
  SchoolyearViewApi as SchoolyearViewApiImpl,
  ExamTypesApi as ExamTypesViewApiImpl,
  BuildingViewApi as BuildingViewApiImpl,
  ClassRegHomeworkViewApi as ClassRegHomeworkViewApiImpl,
  DashboardViewApi as DashboardViewApiImpl,
  MessagesOfTheDayViewApi as MessagesOfTheDayViewApiImpl,
  ExamsApi as ExamsViewApiImpl,
  ExamSettingsApi as ExamSettingsViewApiImpl,
  ExamLocksApi as ExamLocksViewApiImpl,
  ExemptionReasonsViewApi as ExemptionReasonsViewApiImpl,
  StudentDutyViewApi as StudentDutyViewApiImpl,
  ParentTeacherDayDashboardViewApi as ParentTeacherDayDashboardViewApiImpl,
  ImageViewApi as ImageViewApiImpl,
  TeacherViewApi as TeacherViewApiImpl,
  SystemSettingsApi as SystemSettingsApiImpl,
  TodayViewApi as TodayViewApiImpl,
  FileViewApi as FileViewApiImpl,
  OnboardingApi as OnboardingApiImpl,
  UsageStatisticsApi as UsageStatisticsViewApiImpl,
} from '@untis/wu-rest-view-api';
import {
  ConflictControllerApi,
  AskTeacherControllerApi,
  PeriodControllerApi,
  CoreDataControllerApi,
  ProposalControllerApi,
  ReportingControllerApi,
} from '@untis/sp-rest-api';
import {
  TtChangeSetViewApi as TtChangeSetViewApiImpl,
  TtDiagnosisViewApi as TtDiagnosisViewApiImpl,
  TtHorizonViewApi as TtHorizonViewApiImpl,
  TtMasterDataConstraintViewApi as TtMasterDataConstraintViewApiImpl,
  TtOptimizationJobViewApi as TtOptimizationJobViewApiImpl,
  TtPeriodViewApi as TtPeriodViewApiImpl,
  TtSchedulingUnitViewApi as TtSchedulingUnitViewApiImpl,
  TtTimePreferenceViewApi as TtTimePreferenceViewApiImpl,
  TtWuLessonViewApi as TtWuLessonViewApiImpl,
} from '@untis/wu-rest-tt-api';
import { inject, Store } from '@/types/store';
import TokenStore from '@/stores/token-store';
import EnvironmentStore from '@/stores/environment-store';
import { LOCAL_STORAGE_TOKEN } from '@/types/local-storage-ids';
import SchoolYearStore from '@/stores/schoolyear-store';

type TokenSubscriberCallback = (token: string) => void;

let ProposalApi: ProposalControllerApi;
let PeriodApi: PeriodControllerApi;
let CoreDataApi: CoreDataControllerApi;
let SubstitutionPlanningApi: ConflictControllerApi;
let SubstitutionDataViewApi: SubstitutionDataViewApiImpl;
let AskTeacherApi: AskTeacherControllerApi;
let ReportingApi: ReportingControllerApi;
let CalendarViewApi: CalendarViewControllerApi;
let LegalGuardiansViewApi: LGApi;
let ApprenticeRepresentativesViewApi: ARApi;
let ProfileViewApi: ProfileApi;
let MessageViewApi: MessageApi;
let ClassregAbsenceViewApi: ClassregAbsenceApi;
let ClassRegOverviewViewV2Api: ClassRegOverviewV2Api;
let ClassRegLessonTopicViewApi: ClassRegLessonTopicApi;
let ClassRegOpenPeriodsViewApi: ClassRegOpenPeriodsApi;
let PlatformApplicationViewApi: PlatformApplicationViewControllerApi;
let TeacherAbsenceViewApi: TeacherAbsenceViewApiImpl;
let TeacherAbsenceReasonViewApi: TeacherAbsenceReasonViewApiImpl;
let TtChangeSetViewApi: TtChangeSetViewApiImpl;
let TtDiagnosisViewApi: TtDiagnosisViewApiImpl;
let TtHorizonViewApi: TtHorizonViewApiImpl;
let TtMasterDataConstraintViewApi: TtMasterDataConstraintViewApiImpl;
let TtOptimizationJobViewApi: TtOptimizationJobViewApiImpl;
let TtPeriodViewApi: TtPeriodViewApiImpl;
let TtSchedulingUnitViewApi: TtSchedulingUnitViewApiImpl;
let TtTimePreferenceViewApi: TtTimePreferenceViewApiImpl;
let TtWuLessonViewApi: TtWuLessonViewApiImpl;
let TriggerViewApi: TriggerViewApiImpl;
let AppViewApi: AppViewApiImpl;
let TimetableViewApi: TimetableViewApiImpl;
let TimetableSettingsViewApi: TimetableSettingsViewApiImpl;
let ICalSettingsViewApi: ICalSettingsViewApiImpl;
let TimegridViewApi: TimegridViewApiImpl;
let StudentViewApi: StudentViewApiImpl;
let StudentAbsenceReasonsViewApi: StudentAbsenceReasonsViewApiImpl;
let SubjectViewApi: SubjectViewApiImpl;
let RoomViewApi: RoomViewApiImpl;
let SchoolyearViewApi: SchoolyearViewApiImpl;
let ExamTypesViewApi: ExamTypesViewApiImpl;
let BuildingViewApi: BuildingViewApiImpl;
let ClassRegHomeworkViewApi: ClassRegHomeworkViewApiImpl;
let DashboardViewApi: DashboardViewApiImpl;
let MessagesOfDayViewApi: MessagesOfTheDayViewApiImpl;
let ExamsViewApi: ExamsViewApiImpl;
let ExamSettingsViewApi: ExamSettingsViewApiImpl;
let ExamLocksViewApi: ExamLocksViewApiImpl;
let ExemptionReasonsViewApi: ExemptionReasonsViewApiImpl;
let ExcuseStatusViewApi: ExcuseStatusViewApiImpl;
let StudentDutyViewApi: StudentDutyViewApiImpl;
let TeacherViewApi: TeacherViewApiImpl;
let ParentTeacherDayDashboardViewApi: ParentTeacherDayDashboardViewApiImpl;
let ImageViewApi: ImageViewApiImpl;
let SystemSettingsApi: SystemSettingsApiImpl;
let TodayViewApi: TodayViewApiImpl;
let FileViewApi: FileViewApiImpl;
let OnboardingApi: OnboardingApiImpl;
let UsageStatisticsViewApi: UsageStatisticsViewApiImpl;

const ERROR_TOKEN_IS_EXPIRING = 'TokenExpiring';

@Store()
export default class ApiStore {
  private tokenStore = inject(TokenStore);
  private environment = inject(EnvironmentStore);
  private routerStore = inject(RouterStore);
  private modalStore = inject(ModalStore);
  private schoolYearStore = inject(SchoolYearStore);

  private isAlreadyFetchingAccessToken = false;
  // This is the list of waiting requests that will retry after the JWT refresh complete
  private tokenSubscribers: TokenSubscriberCallback[] = [];

  public async init() {
    const SP_API_BASEPATH = this.environment.spApiURL;
    const WU_API_BASEPATH = this.environment.webUntisURL;

    const configuration = new Configuration();

    ProposalApi = new ProposalControllerApi({}, SP_API_BASEPATH);
    PeriodApi = new PeriodControllerApi({}, SP_API_BASEPATH);
    CoreDataApi = new CoreDataControllerApi({}, SP_API_BASEPATH);
    SubstitutionPlanningApi = new ConflictControllerApi({}, SP_API_BASEPATH);
    AskTeacherApi = new AskTeacherControllerApi({}, SP_API_BASEPATH);
    ReportingApi = new ReportingControllerApi({}, SP_API_BASEPATH);
    SubstitutionDataViewApi = new SubstitutionDataViewApiImpl(configuration, WU_API_BASEPATH);
    CalendarViewApi = new CalendarViewControllerApi(configuration, WU_API_BASEPATH);
    LegalGuardiansViewApi = new LGApi(configuration, WU_API_BASEPATH);
    ApprenticeRepresentativesViewApi = new ARApi(configuration, WU_API_BASEPATH);
    ProfileViewApi = new ProfileApi(configuration, WU_API_BASEPATH);
    MessageViewApi = new MessageApi(configuration, WU_API_BASEPATH);
    ClassregAbsenceViewApi = new ClassregAbsenceApi(configuration, WU_API_BASEPATH);
    ClassRegOverviewViewV2Api = new ClassRegOverviewV2Api(configuration, WU_API_BASEPATH);
    ClassRegOpenPeriodsViewApi = new ClassRegOpenPeriodsApi(configuration, WU_API_BASEPATH);
    ClassRegLessonTopicViewApi = new ClassRegLessonTopicApi(configuration, WU_API_BASEPATH);
    PlatformApplicationViewApi = new PlatformApplicationViewControllerApi(configuration, WU_API_BASEPATH);
    TeacherAbsenceViewApi = new TeacherAbsenceViewApiImpl(configuration, WU_API_BASEPATH);
    TeacherAbsenceReasonViewApi = new TeacherAbsenceReasonViewApiImpl(configuration, WU_API_BASEPATH);
    TtChangeSetViewApi = new TtChangeSetViewApiImpl(configuration, WU_API_BASEPATH);
    TtDiagnosisViewApi = new TtDiagnosisViewApiImpl(configuration, WU_API_BASEPATH);
    TtHorizonViewApi = new TtHorizonViewApiImpl(configuration, WU_API_BASEPATH);
    TtMasterDataConstraintViewApi = new TtMasterDataConstraintViewApiImpl(configuration, WU_API_BASEPATH);
    TtOptimizationJobViewApi = new TtOptimizationJobViewApiImpl(configuration, WU_API_BASEPATH);
    TtPeriodViewApi = new TtPeriodViewApiImpl(configuration, WU_API_BASEPATH);
    TtSchedulingUnitViewApi = new TtSchedulingUnitViewApiImpl(configuration, WU_API_BASEPATH);
    TtTimePreferenceViewApi = new TtTimePreferenceViewApiImpl(configuration, WU_API_BASEPATH);
    TtWuLessonViewApi = new TtWuLessonViewApiImpl(configuration, WU_API_BASEPATH);
    TriggerViewApi = new TriggerViewApiImpl(configuration, WU_API_BASEPATH);
    AppViewApi = new AppViewApiImpl(configuration, WU_API_BASEPATH);
    TimetableViewApi = new TimetableViewApiImpl(configuration, WU_API_BASEPATH);
    TimetableSettingsViewApi = new TimetableSettingsViewApiImpl(configuration, WU_API_BASEPATH);
    ICalSettingsViewApi = new ICalSettingsViewApiImpl(configuration, WU_API_BASEPATH);
    TimegridViewApi = new TimegridViewApiImpl(configuration, WU_API_BASEPATH);
    StudentViewApi = new StudentViewApiImpl(configuration, WU_API_BASEPATH);
    StudentAbsenceReasonsViewApi = new StudentAbsenceReasonsViewApiImpl(configuration, WU_API_BASEPATH);
    SubjectViewApi = new SubjectViewApiImpl(configuration, WU_API_BASEPATH);
    RoomViewApi = new RoomViewApiImpl(configuration, WU_API_BASEPATH);
    SchoolyearViewApi = new SchoolyearViewApiImpl(configuration, WU_API_BASEPATH);
    ExamTypesViewApi = new ExamTypesViewApiImpl(configuration, WU_API_BASEPATH);
    BuildingViewApi = new BuildingViewApiImpl(configuration, WU_API_BASEPATH);
    ClassRegHomeworkViewApi = new ClassRegHomeworkViewApiImpl(configuration, WU_API_BASEPATH);
    DashboardViewApi = new DashboardViewApiImpl(configuration, WU_API_BASEPATH);
    MessagesOfDayViewApi = new MessagesOfTheDayViewApiImpl(configuration, WU_API_BASEPATH);
    ExamsViewApi = new ExamsViewApiImpl(configuration, WU_API_BASEPATH);
    ExamSettingsViewApi = new ExamSettingsViewApiImpl(configuration, WU_API_BASEPATH);
    ExamLocksViewApi = new ExamLocksViewApiImpl(configuration, WU_API_BASEPATH);
    ExemptionReasonsViewApi = new ExemptionReasonsViewApiImpl(configuration, WU_API_BASEPATH);
    ExcuseStatusViewApi = new ExcuseStatusViewApiImpl(configuration, WU_API_BASEPATH);
    StudentDutyViewApi = new StudentDutyViewApiImpl(configuration, WU_API_BASEPATH);
    TeacherViewApi = new TeacherViewApiImpl(configuration, WU_API_BASEPATH);
    ParentTeacherDayDashboardViewApi = new ParentTeacherDayDashboardViewApiImpl(configuration, WU_API_BASEPATH);
    ImageViewApi = new ImageViewApiImpl(configuration, WU_API_BASEPATH);
    FileViewApi = new FileViewApiImpl(configuration, WU_API_BASEPATH);
    SystemSettingsApi = new SystemSettingsApiImpl(configuration, WU_API_BASEPATH);
    TodayViewApi = new TodayViewApiImpl(configuration, WU_API_BASEPATH);
    OnboardingApi = new OnboardingApiImpl(configuration, WU_API_BASEPATH);
    UsageStatisticsViewApi = new UsageStatisticsViewApiImpl(configuration, WU_API_BASEPATH);

    try {
      await this.tokenStore.fetchToken();

      Axios.interceptors.request.use((config) => {
        if (config.url !== this.tokenStore.tokenEndpoint) {
          const token = window.localStorage.getItem(LOCAL_STORAGE_TOKEN);
          config.headers.Authorization = `Bearer ${token}`;
          if (this.tokenStore.isTokenExpiring()) {
            const error: AxiosError = {
              name: ERROR_TOKEN_IS_EXPIRING,
              config,
              code: '',
              message: '',
              isAxiosError: false,
              toJSON: (): object => {
                return {};
              },
            };
            throw error;
          }
          const shouldSetSchoolYearIdHeader =
            this.urlPathStartsWith(config.url, '/WebUntis/') || this.urlPathStartsWith(config.url, SP_API_BASEPATH);
          if (shouldSetSchoolYearIdHeader && this.schoolYearStore.currentSchoolYear?.id) {
            config.headers['X-Webuntis-Api-School-Year-Id'] = this.schoolYearStore.currentSchoolYear.id;
          }
        }

        return config;
      });

      Axios.interceptors.response.use(
        (response) => response, // If the request succeeds, we don't have to do anything and just return the response
        (error: AxiosError) => {
          if (this.isTokenExpiredError(error)) {
            return this.resetTokenAndReattemptRequest(error);
          }

          // In case modal is opened, a popup message appears instead of redirection
          // modal-routes always contain /modal in the URL
          const dontRedirectTo403 = this.routerStore.routing.location.pathname.includes('/modal');

          if (error?.response?.status === 403) {
            if (dontRedirectTo403) {
              return Promise.reject(error);
            } else {
              this.routerStore.routing.push('/not-authorized');
              return Promise.reject(error);
            }
          }

          // If the error is due to other reasons, we just throw it back to axios
          return Promise.reject(error);
        },
      );
    } catch (error) {
      console.error(error);
      // user is not logged into webuntis, let's redirect to the webuntis login page
      this.tokenStore.redirectToLogin();
      throw error;
    }
  }

  private async resetTokenAndReattemptRequest(error: AxiosError) {
    try {
      const { config } = error;

      if (config === undefined) return;

      /* Proceed to the token refresh procedure
      We create a new Promise that will retry the request,
      clone all the request configuration from the failed
      request in the error object. */
      const retryOriginalRequest = new Promise((resolve) => {
        /* We need to add the request retry to the queue
        since there another request that already attempt to
        refresh the token */
        this.addTokenSubscriber((token) => {
          config.headers.Authorization = `Bearer ${token}`;
          resolve(Axios.request(config));
        });
      });

      if (!this.isAlreadyFetchingAccessToken) {
        this.isAlreadyFetchingAccessToken = true;

        const newToken = await this.tokenStore.fetchToken();

        this.isAlreadyFetchingAccessToken = false;
        this.onAccessTokenFetched(newToken);
      }

      return retryOriginalRequest;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  private onAccessTokenFetched(token: string) {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    for (const callback of this.tokenSubscribers) {
      callback(token);
    }
    this.tokenSubscribers.length = 0;
  }

  private isTokenExpiredError(error: AxiosError): boolean {
    if (error.config && error.config.url === this.tokenStore.tokenEndpoint) {
      return false; // we don't want to retry if there was a problem with fetching the token
    }

    if (error.name === ERROR_TOKEN_IS_EXPIRING) {
      return true;
    }

    return !!error.response && error.response.status === 401;
  }

  private addTokenSubscriber(callback: TokenSubscriberCallback) {
    this.tokenSubscribers.push(callback);
  }

  private urlPathStartsWith(urlString: string | undefined, pathStart: string): boolean {
    try {
      return !!urlString && (urlString.startsWith(pathStart) || new URL(urlString).pathname.startsWith(pathStart));
    } catch (e) {
      return false;
    }
  }
}

export {
  SubstitutionPlanningApi,
  AskTeacherApi,
  PeriodApi,
  CoreDataApi,
  ProposalApi,
  ReportingApi,
  CalendarViewApi,
  LegalGuardiansViewApi,
  ApprenticeRepresentativesViewApi,
  ProfileViewApi,
  MessageViewApi,
  ClassregAbsenceViewApi,
  ClassRegOverviewViewV2Api,
  ClassRegLessonTopicViewApi,
  ClassRegOpenPeriodsViewApi,
  PlatformApplicationViewApi,
  TeacherAbsenceViewApi,
  TeacherAbsenceReasonViewApi,
  SubstitutionDataViewApi,
  TtChangeSetViewApi,
  TtDiagnosisViewApi,
  TtHorizonViewApi,
  TtMasterDataConstraintViewApi,
  TtOptimizationJobViewApi,
  TtPeriodViewApi,
  TtSchedulingUnitViewApi,
  TtTimePreferenceViewApi,
  TtWuLessonViewApi,
  TriggerViewApi,
  AppViewApi,
  TimetableViewApi,
  TimetableSettingsViewApi,
  ICalSettingsViewApi,
  TimegridViewApi,
  StudentViewApi,
  StudentAbsenceReasonsViewApi,
  SubjectViewApi,
  RoomViewApi,
  SchoolyearViewApi,
  ExamTypesViewApi,
  BuildingViewApi,
  ClassRegHomeworkViewApi,
  DashboardViewApi,
  MessagesOfDayViewApi,
  ExamsViewApi,
  ExamSettingsViewApi,
  ExamLocksViewApi,
  ExemptionReasonsViewApi,
  ExcuseStatusViewApi,
  StudentDutyViewApi,
  TeacherViewApi,
  ParentTeacherDayDashboardViewApi,
  ImageViewApi,
  SystemSettingsApi,
  TodayViewApi,
  FileViewApi,
  OnboardingApi,
  UsageStatisticsViewApi,
};
