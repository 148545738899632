import { action } from 'mobx';
import { t } from 'i18next';
import dayjs from 'dayjs';

import { inject, Store } from '@/types/store';
import { ImageViewApi, StudentViewApi } from '@/stores/api-store';
import { getResponseError, IResponseError, ResponseErrorType } from '@/utils/error-handling/error-handling';
import ModalStore from '@/stores/modal-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { StudentStore } from '@/pages/master-data/student/student-store';
import { IStudentForm } from '@/pages/master-data/student/student-form';
import {
  AddressDto,
  ClassInfoDto,
  EntryExitDateRangeDto,
  Gender,
  MedicalDataDto,
  StudentAttributesDto,
  StudentFormDto,
} from '@untis/wu-rest-view-api/api';
import { formatDateForServerRequest } from '@/utils/date/date-util';
import { IValidationError } from '@/types/validation-error';
import { ElementType } from '@/stores/rights-store';

@Store()
export default class StudentFormStore {
  private studentStore: StudentStore;
  private modalStore = inject(ModalStore);
  private notificationStore = inject(NotificationStore);

  constructor(studentStore: StudentStore) {
    this.studentStore = studentStore;
  }

  @action
  async handleUpdateStudent(
    form: IStudentForm,
    formDto: StudentFormDto | undefined,
    classes: ClassInfoDto[] | undefined,
  ) {
    const studentFormDto = this.formStudentToStudentFormDto(form, formDto, classes);

    try {
      const response = await StudentViewApi.updateStudent(studentFormDto);
      await this.updateStudentImage(form.studentImage, response.data.id!);
      this.notificationStore.success({ title: t('general.studentUpdated') });
      this.studentStore.onStudentUpdated(response.data);
      this.studentStore.form.resetFields;
      await this.modalStore.closeModal();
    } catch (error) {
      const responseError: IResponseError = getResponseError(error);
      if (responseError.type === ResponseErrorType.VALIDATION_ERROR) {
        this.handleValidationError(responseError);
      } else {
        this.notificationStore.error({ title: t('general.studentCouldNotBeUpdated'), message: error.toString() });
      }
    }
  }

  @action
  async handleSaveNewStudent(
    form: IStudentForm,
    formDto: StudentFormDto | undefined,
    classes: ClassInfoDto[] | undefined,
    saveAndNew: boolean,
  ) {
    const studentFormDto = this.formStudentToStudentFormDto(form, formDto, classes);

    try {
      const response = await StudentViewApi.createStudent(studentFormDto);
      await this.updateStudentImage(form.studentImage, response.data.id!);
      this.notificationStore.success({ title: t('general.studentCreated') });
      this.studentStore.onStudentCreated(response.data);
      this.studentStore.form.resetFields();
      if (!saveAndNew) {
        await this.modalStore.closeModal();
      }
    } catch (error) {
      const responseError: IResponseError = getResponseError(error);
      if (responseError.type === ResponseErrorType.VALIDATION_ERROR) {
        this.handleValidationError(responseError);
      } else {
        this.notificationStore.error({ title: t('general.studentCouldNotBeCreated'), message: error.toString() });
      }
    }
  }

  async updateStudentImage(image: File, entityId: number) {
    if (image && image.name && image.name.includes('avatar-default')) {
      await ImageViewApi.deleteImage(Number(ElementType.STUDENT), entityId);
      return;
    }

    if (image && image.type) {
      await ImageViewApi.saveImage(image, Number(ElementType.STUDENT), entityId);
      return;
    }
  }

  handleValidationError(error: IResponseError) {
    const validationErrors: IValidationError[] = error.payload as IValidationError[];
    for (const validationError of validationErrors) {
      if (validationError.path.includes('.lastName')) {
        this.studentStore.form.setFields([{ name: 'lastName', errors: [t(validationError.errorMessage)] }]);
      } else if (validationError.path.includes('.shortName')) {
        this.studentStore.form.setFields([{ name: 'shortName', errors: [t(validationError.errorMessage)] }]);
      } else if (validationError.path.includes('.externKey')) {
        this.studentStore.form.setFields([{ name: 'externKey', errors: [t(validationError.errorMessage)] }]);
      }
    }
  }

  private static mapMedicalCertificateData(form: IStudentForm): MedicalDataDto | undefined {
    let medicalData: MedicalDataDto | undefined = undefined;
    if (form.certificate.includes('certificate')) {
      medicalData = {
        hasCertificate: form.certificate.includes('certificate'),
        certificateDate: form.certificateDate ? formatDateForServerRequest(form.certificateDate) : undefined,
      };
    }
    return medicalData;
  }

  private static mapAddressData(form: IStudentForm, formDto: StudentFormDto | undefined): AddressDto | undefined {
    return form.street || form.fax || form.phone || form.mobilePhone || form.email || form.city || form.postCode
      ? {
          id: formDto?.addressData?.id ?? 0,
          street: form.street,
          fax: form.fax,
          phoneNumber: form.phone,
          mobileNumber: form.mobilePhone,
          email: form.email,
          city: form.city,
          postCode: form.postCode,
          lastModified: formDto?.addressData?.lastModified,
        }
      : undefined;
  }

  private mapStudentAttributes(
    form: IStudentForm,
    availableStudentAttributes: StudentAttributesDto[] | undefined,
  ): StudentAttributesDto[] {
    const studentAttributes: StudentAttributesDto[] = [];
    availableStudentAttributes?.sort().forEach((studentAttribute, index) => {
      let value;
      if (studentAttribute.type === 'text') {
        value = form.studentAttributes[index] === '' ? undefined : form.studentAttributes[index];
      } else if (studentAttribute.type === 'select') {
        if (form.studentAttributes[index]) {
          const key = Number(form.studentAttributes[index]);
          if (studentAttribute.selectValues) {
            value = studentAttribute.selectValues[key];
          }
        }
      } else {
        value = String(form.studentAttributes[index].includes('checked'));
      }

      studentAttributes.push({
        type: studentAttribute.type,
        name: studentAttribute.name,
        value: value,
        id: studentAttribute.id,
        lastModified: studentAttribute.lastModified,
      });
    });

    return studentAttributes;
  }

  private mapEntryExitDateRanges(form: IStudentForm): EntryExitDateRangeDto[] {
    const entryExitDateInfo: EntryExitDateRangeDto[] = [];
    form.dateRanges?.forEach((element) => {
      if (element) {
        entryExitDateInfo.push({
          entryDate: element.startDate ? formatDateForServerRequest(element.startDate) : '',
          exitDate: element.endDate ? formatDateForServerRequest(element.endDate) : '',
          isMainRange: false,
        });
      }
    });

    return entryExitDateInfo;
  }

  private static mapGender(form: IStudentForm): Gender | undefined {
    let gender = undefined;
    switch (form.gender) {
      case 'male':
        gender = Gender.MALE;
        break;
      case 'female':
        gender = Gender.FEMALE;
        break;
      case 'inter':
        gender = Gender.INTER;
        break;
    }

    return gender;
  }

  private mapSelectedClass(form: IStudentForm, classes: ClassInfoDto[] | undefined): ClassInfoDto | undefined {
    const selectedClass = classes?.filter((cl) => cl.id === Number(form.classId))[0];
    let classInfo: ClassInfoDto | undefined = undefined;
    if (selectedClass) {
      classInfo = {
        name: selectedClass.name,
        id: selectedClass.id,
        startDate: formatDateForServerRequest(dayjs()),
      };
    }

    return classInfo;
  }

  formStudentToStudentFormDto(
    form: IStudentForm,
    formDto: StudentFormDto | undefined,
    classes: ClassInfoDto[] | undefined,
  ): StudentFormDto {
    const medicalData = StudentFormStore.mapMedicalCertificateData(form);
    const addressData = StudentFormStore.mapAddressData(form, formDto);
    const classInfo = this.mapSelectedClass(form, classes);
    const entryExitDateInfo = this.mapEntryExitDateRanges(form);
    const studentAttributes = this.mapStudentAttributes(form, formDto?.availableStudentAttributes);
    const gender = StudentFormStore.mapGender(form);

    function defaultIfEmpty(value: string, defaultValue: string | undefined) {
      if (value && value !== '') {
        return value;
      }
      return defaultValue;
    }

    return {
      id: formDto?.id,
      firstName: form.firstName,
      lastName: form.lastName,
      shortName: form.shortName,
      externKey: form.externalId,
      text: form.description,
      gender: gender,
      active: form.status,
      backgroundColor: Number.parseInt(form.backColor.replace('#', ''), 16),
      foregroundColor: Number.parseInt(form.frontColor.replace('#', ''), 16),
      catalogueNumber: defaultIfEmpty(form.catalogueNumber, undefined),
      ofSchoolAge: form.schoolAge.includes('ofSchoolAge'),
      ofLegalAge: form.legalAge.includes('ofLegalAge'),
      birthDate: form.birthDate ? formatDateForServerRequest(form.birthDate) : undefined,
      studentAttributes: studentAttributes,
      medicalData: medicalData,
      entryExitDateInfo: entryExitDateInfo,
      addressData: addressData,
      classInfo: classInfo,
      lastModified: formDto?.lastModified,
    };
  }
}
