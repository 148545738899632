import { action, computed, observable } from 'mobx';
import { t } from 'i18next';

import { ISelectItem } from '@/ui-components/select/select';
import { TimetableSettingsViewApi } from '@/stores/api-store';
import {
  TimetableFormatMappingFormDto,
  TimetableFormatMappingsDto,
  TimetableFormatPerDepartment,
} from '@untis/wu-rest-view-api/api';
import { inject } from '@/types/store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { TimetableEntityType } from '@te/standard/stores/timetable-root-store';
import { replaceLabelPlaceholder } from '@te/settings/utils/utils';

export interface ITimetableFormatMappingsFormData {
  classPublic: boolean;
  classFormat: string;
  classDisplayName: string;
  teacherPublic: boolean;
  teacherFormat: string;
  teacherDisplayName: string;
  studentPublic: boolean;
  studentFormat: string;
  studentDisplayName: string;
  subjectPublic: boolean;
  subjectFormat: string;
  subjectDisplayName: string;
  roomPublic: boolean;
  roomFormat: string;
  roomDisplayName: string;
  resourcePublic: boolean;
  resourceFormat: string;
  resourceDisplayName: string;
}

export interface IDepartmentRef {
  id: number;
  name: string;
}

export interface IDepartmentFormatMappings {
  class: Map<number, string | undefined>;
  teacher: Map<number, string | undefined>;
  student: Map<number, string | undefined>;
  subject: Map<number, string | undefined>;
  room: Map<number, string | undefined>;
  resource: Map<number, string | undefined>;
}

export default class TimetableFormatMappingsStore {
  @observable private _isLoading = true;
  @observable private _submitDisabled = true;
  @observable private _formData: TimetableFormatMappingFormDto | undefined = undefined;
  @observable private _formatMappings: TimetableFormatMappingsDto | undefined = undefined;

  @observable private _showClassDepartmentMappings = false;
  @observable private _showTeacherDepartmentMappings = false;
  @observable private _showStudentDepartmentMappings = false;
  @observable private _showSubjectDepartmentMappings = false;
  @observable private _showRoomDepartmentMappings = false;
  @observable private _showResourceDepartmentMappings = false;
  @observable private _departmentFormatMappings: IDepartmentFormatMappings = {
    class: new Map<number, string | undefined>(),
    teacher: new Map<number, string | undefined>(),
    student: new Map<number, string | undefined>(),
    subject: new Map<number, string | undefined>(),
    room: new Map<number, string | undefined>(),
    resource: new Map<number, string | undefined>(),
  };

  private _notificationStore = inject(NotificationStore);

  @action
  async fetchData() {
    this._isLoading = true;
    try {
      const formDataResponse = await TimetableSettingsViewApi.getTimetableFormatMappingForm();
      const formatMappingsResponse = await TimetableSettingsViewApi.getTimetableFormatMappings();

      this._formData = formDataResponse.data;
      this._formatMappings = formatMappingsResponse.data;
      this.initSwitchValues();
    } catch (error) {
      this._notificationStore.error({
        title: '',
        message: error.toString(),
      });
    } finally {
      this._isLoading = false;
    }
  }

  @action
  private initSwitchValues() {
    this._showClassDepartmentMappings = (this._formatMappings?.klasse.selectedFormatPerDepartment?.length ?? 0) > 0;
    this._showTeacherDepartmentMappings = (this._formatMappings?.teacher.selectedFormatPerDepartment?.length ?? 0) > 0;
    this._showStudentDepartmentMappings = (this._formatMappings?.student.selectedFormatPerDepartment?.length ?? 0) > 0;
    this._showSubjectDepartmentMappings = (this._formatMappings?.subject.selectedFormatPerDepartment?.length ?? 0) > 0;
    this._showRoomDepartmentMappings = (this._formatMappings?.room.selectedFormatPerDepartment?.length ?? 0) > 0;
    this._showResourceDepartmentMappings =
      (this._formatMappings?.resource.selectedFormatPerDepartment?.length ?? 0) > 0;

    this._formData?.departments.forEach((dep) => {
      this._departmentFormatMappings.class.set(
        dep.id,
        this.findFormatPerDepartment(this._formatMappings?.klasse.selectedFormatPerDepartment ?? [], dep.id),
      );
      this._departmentFormatMappings.teacher.set(
        dep.id,
        this.findFormatPerDepartment(this._formatMappings?.teacher.selectedFormatPerDepartment ?? [], dep.id),
      );
      this._departmentFormatMappings.student.set(
        dep.id,
        this.findFormatPerDepartment(this._formatMappings?.student.selectedFormatPerDepartment ?? [], dep.id),
      );
      this._departmentFormatMappings.subject.set(
        dep.id,
        this.findFormatPerDepartment(this._formatMappings?.subject.selectedFormatPerDepartment ?? [], dep.id),
      );
      this._departmentFormatMappings.room.set(
        dep.id,
        this.findFormatPerDepartment(this._formatMappings?.room.selectedFormatPerDepartment ?? [], dep.id),
      );
      this._departmentFormatMappings.resource.set(
        dep.id,
        this.findFormatPerDepartment(this._formatMappings?.resource.selectedFormatPerDepartment ?? [], dep.id),
      );
    });
  }

  private findFormatPerDepartment(
    departmentMappings: TimetableFormatPerDepartment[],
    departmentId: number,
  ): string | undefined {
    return departmentMappings.find((dm) => dm.department === departmentId)?.format?.toString();
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @computed
  get submitDisabled(): boolean {
    return this._submitDisabled;
  }

  @action
  setSubmitDisabled(submitDisabled: boolean) {
    this._submitDisabled = submitDisabled;
  }

  @computed
  get showClassDepartmentMappings(): boolean {
    return this._showClassDepartmentMappings;
  }

  @action.bound
  setShowClassDepartmentMappings(showClassDepartmentMappings: boolean) {
    this._showClassDepartmentMappings = showClassDepartmentMappings;
  }

  @computed
  get showTeacherDepartmentMappings(): boolean {
    return this._showTeacherDepartmentMappings;
  }

  @action.bound
  setShowTeacherDepartmentMappings(showTeacherDepartmentMappings: boolean) {
    this._showTeacherDepartmentMappings = showTeacherDepartmentMappings;
  }

  @computed
  get showStudentDepartmentMappings(): boolean {
    return this._showStudentDepartmentMappings;
  }

  @action.bound
  setShowStudentDepartmentMappings(showStudentDepartmentMappings: boolean) {
    this._showStudentDepartmentMappings = showStudentDepartmentMappings;
  }

  @computed
  get showSubjectDepartmentMappings(): boolean {
    return this._showSubjectDepartmentMappings;
  }

  @action.bound
  setShowSubjectDepartmentMappings(showSubjectDepartmentMappings: boolean) {
    this._showSubjectDepartmentMappings = showSubjectDepartmentMappings;
  }

  @computed
  get showRoomDepartmentMappings(): boolean {
    return this._showRoomDepartmentMappings;
  }

  @action.bound
  setShowRoomDepartmentMappings(showRoomDepartmentMappings: boolean) {
    this._showRoomDepartmentMappings = showRoomDepartmentMappings;
  }

  @computed
  get showResourceDepartmentMappings(): boolean {
    return this._showResourceDepartmentMappings;
  }

  @action.bound
  setShowResourceDepartmentMappings(showResourceDepartmentMappings: boolean) {
    this._showResourceDepartmentMappings = showResourceDepartmentMappings;
  }

  @computed
  get departments(): IDepartmentRef[] {
    return this._formData?.departments.map((department) => ({ id: department.id, name: department.shortName })) ?? [];
  }

  @computed
  get timetableFormats(): ISelectItem[] {
    const formats =
      this._formData?.formats.map((format) => ({ id: format.id.toString(), label: format.shortName })) ?? [];
    if (formats.length > 1) {
      return formats.filter((format) => format.id !== '0');
    } else {
      return formats;
    }
  }

  @computed
  get classDisplayNameTypes(): ISelectItem[] {
    return (
      this._formData?.classLabels.map((label) => ({
        id: label.id.toString(),
        label: replaceLabelPlaceholder(label.label ?? ''),
      })) ?? []
    );
  }

  @computed
  get teacherDisplayNameTypes(): ISelectItem[] {
    return (
      this._formData?.teacherLabels.map((label) => ({
        id: label.id.toString(),
        label: replaceLabelPlaceholder(label.label ?? ''),
      })) ?? []
    );
  }

  @computed
  get studentDisplayNameTypes(): ISelectItem[] {
    return (
      this._formData?.studentLabels.map((label) => ({
        id: label.id.toString(),
        label: replaceLabelPlaceholder(label.label ?? ''),
      })) ?? []
    );
  }

  @computed
  get subjectDisplayNameTypes(): ISelectItem[] {
    return (
      this._formData?.subjectLabels.map((label) => ({
        id: label.id.toString(),
        label: replaceLabelPlaceholder(label.label ?? ''),
      })) ?? []
    );
  }

  @computed
  get roomDisplayNameTypes(): ISelectItem[] {
    return (
      this._formData?.roomLabels.map((label) => ({
        id: label.id.toString(),
        label: replaceLabelPlaceholder(label.label ?? ''),
      })) ?? []
    );
  }

  @computed
  get resourceDisplayNameTypes(): ISelectItem[] {
    return (
      this._formData?.resourceLabels.map((label) => ({
        id: label.id.toString(),
        label: replaceLabelPlaceholder(label.label ?? ''),
      })) ?? []
    );
  }

  @computed
  get timetableFormatMappings(): TimetableFormatMappingsDto | undefined {
    return this._formatMappings;
  }

  @computed
  get departmentFormatMappings(): IDepartmentFormatMappings {
    return this._departmentFormatMappings;
  }

  @action.bound
  setDepartmentFormatMapping(type: TimetableEntityType, departmentId: number, formatId: string | undefined) {
    switch (type) {
      case 'class':
        this._departmentFormatMappings.class.set(departmentId, formatId);
        break;
      case 'teacher':
        this._departmentFormatMappings.teacher.set(departmentId, formatId);
        break;
      case 'student':
        this._departmentFormatMappings.student.set(departmentId, formatId);
        break;
      case 'subject':
        this._departmentFormatMappings.subject.set(departmentId, formatId);
        break;
      case 'room':
        this._departmentFormatMappings.room.set(departmentId, formatId);
        break;
      case 'resource':
        this._departmentFormatMappings.resource.set(departmentId, formatId);
        break;
    }
  }

  @action
  async save(formData: ITimetableFormatMappingsFormData) {
    const formatMappings = this.mapToDto(formData);
    try {
      await TimetableSettingsViewApi.saveTimetableFormatMappings(formatMappings);
      this._notificationStore.success({ title: t('general.successfullySaved') });
      this._submitDisabled = true;
    } catch (error) {
      this._notificationStore.error({
        title: '',
        message: error.toString(),
      });
    }
  }

  private mapToDto(formData: ITimetableFormatMappingsFormData): TimetableFormatMappingsDto {
    return {
      klasse: {
        public: formData.classPublic,
        selectedFormat: Number(formData.classFormat),
        selectedLabel: Number(formData.classDisplayName),
        selectedFormatPerDepartment: this.toTimetableFormatPerDepartment(this._departmentFormatMappings.class),
      },
      teacher: {
        public: formData.teacherPublic,
        selectedFormat: Number(formData.teacherFormat),
        selectedLabel: Number(formData.teacherDisplayName),
        selectedFormatPerDepartment: this.toTimetableFormatPerDepartment(this._departmentFormatMappings.teacher),
      },
      student: {
        public: formData.studentPublic,
        selectedFormat: Number(formData.studentFormat),
        selectedLabel: Number(formData.studentDisplayName),
        selectedFormatPerDepartment: this.toTimetableFormatPerDepartment(this._departmentFormatMappings.student),
      },
      subject: {
        public: formData.subjectPublic,
        selectedFormat: Number(formData.subjectFormat),
        selectedLabel: Number(formData.subjectDisplayName),
        selectedFormatPerDepartment: this.toTimetableFormatPerDepartment(this._departmentFormatMappings.subject),
      },
      room: {
        public: formData.roomPublic,
        selectedFormat: Number(formData.roomFormat),
        selectedLabel: Number(formData.roomDisplayName),
        selectedFormatPerDepartment: this.toTimetableFormatPerDepartment(this._departmentFormatMappings.room),
      },
      resource: {
        public: formData.resourcePublic,
        selectedFormat: Number(formData.resourceFormat),
        selectedLabel: Number(formData.resourceDisplayName),
        selectedFormatPerDepartment: this.toTimetableFormatPerDepartment(this._departmentFormatMappings.resource),
      },
    };
  }

  private toTimetableFormatPerDepartment(
    departmentMappings: Map<number, string | undefined>,
  ): TimetableFormatPerDepartment[] {
    return Array.from(departmentMappings.entries())
      .filter(([_, value]) => value !== undefined)
      .map(([id, value]) => ({
        department: id,
        format: Number(value),
      }));
  }
}
