import { action, computed, observable } from 'mobx';

import { ISearchBarOption, ISearchBarProps } from '@/ui-components/search-bar/search-bar';

type PreSelectedOption = undefined | ISearchBarOption;

export class SearchBarStore {
  // is the searchbar active/expanded?
  @observable active: boolean = false;
  @observable inputValue: string = '';
  // if undefined -> if there is an option for the currently typed it input value, then this is selected
  @observable preSelectedOption: PreSelectedOption;
  @observable selectedCategory: string | undefined;
  @observable props: ISearchBarProps;

  /* Dropdown options and free text values and their change handlers are managed separately.
    However, one acceptance criteria was, that the order of FilterChips as they are added in the searchbar, should
    be preserved.
    To achieve this, we need to manage an array that can contain both, in parallel to the options and free text values.
    This array should then be used to render the current FilterChips in the correct order
   */
  @observable internalSelectedOptions: (ISearchBarOption | string)[] = [];

  constructor(props: ISearchBarProps) {
    this.props = props;
  }

  @computed
  get allOptions(): ISearchBarOption[] {
    return this.props.options ? this.props.options : [];
  }

  @computed
  get filteredOptions(): ISearchBarOption[] {
    let result = this.allOptions
      // filter input value
      .filter((option) => option.label.toLowerCase().includes(this.inputValue.toLowerCase()))
      // filter already selected option
      .filter(
        (option) =>
          !(
            this.props.selectedOptions &&
            this.props.selectedOptions.some((o) => {
              return o.id === option.id && o.category === option.category;
            })
          ),
      )
      .filter((option) => !this.getIsAlreadySelected(option.label))
      // sort by occurrence of input value
      .sort((a, b) => {
        const indexA = a.label.toLowerCase().indexOf(this.inputValue.toLowerCase());
        const indexB = b.label.toLowerCase().indexOf(this.inputValue.toLowerCase());

        if (indexA === indexB) {
          return 0;
        }
        return indexA - indexB;
      });

    if (this.selectedCategory) {
      result = result.filter((option) => option.category === this.selectedCategory);
    }

    return result;
  }

  @computed
  get filteredOptionsWithoutCategory() {
    return this.filteredOptions.filter((o) => o.category === undefined);
  }

  @computed
  get allCategories(): string[] {
    const categoriesSet: Set<string | undefined> = new Set<string | undefined>();
    this.allOptions.forEach((option) => {
      categoriesSet.add(option.category);
    });
    return Array.from(categoriesSet.values()).filter((c) => c !== undefined) as string[];
  }

  @computed
  get categorisedOptions() {
    const result = new Map<string | undefined, ISearchBarOption[]>();

    this.allCategories.forEach((group) => {
      const optionsOfGroup = this.filteredOptions.filter((option) => option.category === group);
      result.set(group, optionsOfGroup);
    });

    return result;
  }

  @computed
  get sortedFilteredOptions(): ISearchBarOption[] {
    let result: ISearchBarOption[] = [];

    this.filteredOptionsWithoutCategory.forEach((option) => {
      result.push(option);
    });

    this.categorisedOptions.forEach((options) => {
      options.forEach((option) => {
        result.push(option);
      });
    });

    result = result.filter((option) => !this.getIsAlreadySelected(option.label));

    return result;
  }

  @computed
  get showSeparateInputRow(): boolean {
    // clear icon button should be rendered if anything is set or entered
    return (
      this.active &&
      ((!!this.props.toggleFilters?.filter && this.props.toggleFilters?.filter?.length > 0) ||
        (!!this.props.selectedFreeTextOptions && this.props.selectedFreeTextOptions.length > 0) ||
        (!!this.props.selectedOptions && this.props.selectedOptions.length > 0))
    );
  }

  @computed
  get showClearAllButton(): boolean {
    // clear icon button should be rendered if anything is set or entered
    return (
      this.inputValue.length > 0 ||
      (this.props.selectedFreeTextOptions && this.props.selectedFreeTextOptions.length > 0) ||
      (this.props.selectedOptions && this.props.selectedOptions.length > 0) ||
      !!this.props.toggleFilters?.some((filter) => filter.value)
    );
  }

  @computed
  get showPlaceholder(): boolean {
    return (
      this.inputValue.length === 0 &&
      (!this.props.selectedFreeTextOptions ||
        (this.props.selectedFreeTextOptions && this.props.selectedFreeTextOptions.length === 0)) &&
      (!this.props.selectedOptions || (this.props.selectedOptions && this.props.selectedOptions.length === 0))
    );
  }

  /**
   * The categories of filtered options that appear in the dropdown
   */
  @computed
  get filteredCategories(): string[] {
    const categories: (string | undefined)[] = [...new Set(this.filteredOptions.map((option) => option.category))];
    return categories.filter((c) => c !== undefined) as string[];
  }

  @computed
  get selectedChipLabels(): string[] {
    const selectedChipLabels: string[] = [];
    this.internalSelectedOptions.forEach((o) => {
      if (typeof o === 'string') {
        selectedChipLabels.push(o);
      } else {
        selectedChipLabels.push(o.label);
      }
    });
    return selectedChipLabels;
  }

  @computed
  get searchPhraseAlreadySelected(): boolean {
    return this.getIsAlreadySelected(this.inputValue);
  }

  getIsAlreadySelected(value: string): boolean {
    return this.selectedChipLabels.map((label) => label.trim().toLowerCase()).includes(value.trim().toLowerCase());
  }

  @action.bound
  resetPreSelection() {
    if (this.sortedFilteredOptions.length > 0) {
      const firstOption = this.sortedFilteredOptions[0];
      if (firstOption.label.trim().toLowerCase() === this.inputValue.trim().toLowerCase()) {
        this.preSelectedOption = firstOption;
        return;
      }
    }
    this.preSelectedOption = undefined;
  }

  @action.bound
  closeSearchbar() {
    this.clearInput();
    this.active = false;
  }

  @action.bound
  clearInput() {
    this.inputValue = '';
  }

  @action.bound
  handleOutsideClick() {
    this.active = false;
    this.clearInput();
  }

  @action.bound
  handleSelectSearchValue(handleScrollEndRefIntoView: () => void) {
    if (!this.searchPhraseAlreadySelected) {
      const value = this.inputValue.trim();
      this.addFreeTextSearchOption(value);
      handleScrollEndRefIntoView();
    }
    this.closeSearchbar();
  }

  @action.bound
  handleSelectOption(option: ISearchBarOption, handleScrollEndRefIntoView: () => void) {
    this.addOption(option);
    handleScrollEndRefIntoView();
    this.closeSearchbar();
  }

  @action.bound
  private addOption(option: ISearchBarOption) {
    const currentSelectedOptions = this.props.selectedOptions ? this.props.selectedOptions : [];
    if (this.props.onlyOneOptionPerCategory) {
      const newInternalOptions = this.internalSelectedOptions.filter((o) => {
        if (typeof o === 'string') {
          return true;
        }
        return o.category !== option.category;
      });
      this.internalSelectedOptions = [...newInternalOptions, option];
      this.props.onChangeSelectOptions &&
        this.props.onChangeSelectOptions([
          ...currentSelectedOptions.filter((o) => o.category !== option.category),
          option,
        ]);
    } else {
      this.internalSelectedOptions = [...this.internalSelectedOptions, option];
      this.props.onChangeSelectOptions && this.props.onChangeSelectOptions([...currentSelectedOptions, option]);
    }
  }

  @action.bound
  private addFreeTextSearchOption(value: string) {
    if (
      value.length > 0 &&
      !(this.props.selectedFreeTextOptions && this.props.selectedFreeTextOptions.includes(this.inputValue))
    ) {
      this.props.onChangeFreeTextOptions &&
        this.props.onChangeFreeTextOptions([...(this.props.selectedFreeTextOptions ?? []), value]);
      this.internalSelectedOptions = [...this.internalSelectedOptions, value];
    }
  }

  public getIdForOption(option: ISearchBarOption): string {
    const category = option.category ?? 'no-category';
    return `option-${category}-${option.id}`;
  }

  @action.bound
  private scrollOptionIntoView(option: ISearchBarOption) {
    const optionNode = window.document.getElementById(this.getIdForOption(option));
    if (optionNode) {
      optionNode.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }

  @action.bound
  handleKeyDown(e: React.KeyboardEvent, handleScrollEndRefIntoView: () => void) {
    if (e.key === 'Backspace') {
      this.handleBackspace(handleScrollEndRefIntoView);
    }
    if (e.key === 'Escape') {
      this.clearInput();
      this.active = false;
    }
    if (e.key === 'Enter') {
      this.handleEnter(handleScrollEndRefIntoView);
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      this.handleArrowUp();
    }
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      this.handleArrowDown();
    }
  }

  @action.bound
  private handleEnter(handleScrollEndRefIntoView: () => void) {
    if (!this.preSelectedOption) {
      this.handleSelectSearchValue(handleScrollEndRefIntoView);
    } else {
      this.handleSelectOption(this.preSelectedOption, handleScrollEndRefIntoView);
    }
  }

  @action.bound
  handleBackspace(handleScrollEndRefIntoView: () => void) {
    if (this.inputValue.length > 0) {
      // if there is an input value, just delete one character and return
      return;
    }
    if (this.internalSelectedOptions.length > 0) {
      const lastOption = this.internalSelectedOptions[this.internalSelectedOptions.length - 1];
      if (typeof lastOption === 'string') {
        this.handleRemoveFreeTextOption(lastOption);
      } else {
        this.handleRemoveOption(lastOption);
      }
      handleScrollEndRefIntoView();
    }
  }

  @action.bound
  private handleArrowUp() {
    if (this.preSelectedOption) {
      const currentIndex = this.sortedFilteredOptions.findIndex(
        (o) =>
          this.preSelectedOption &&
          o.id === this.preSelectedOption.id &&
          o.category === this.preSelectedOption.category,
      );
      if (currentIndex > 0) {
        const option = this.sortedFilteredOptions[currentIndex - 1];
        this.preSelectedOption = option;
        this.scrollOptionIntoView(option);
      }
      if (currentIndex === 0) {
        this.preSelectedOption = undefined;
        const option = this.sortedFilteredOptions[0];
        this.scrollOptionIntoView(option);
      }
    }
  }

  @action.bound
  private handleArrowDown() {
    if (!this.preSelectedOption) {
      if (this.sortedFilteredOptions.length > 0) {
        this.preSelectedOption = this.sortedFilteredOptions[0];
      }
    } else {
      const currentIndex = this.sortedFilteredOptions.findIndex(
        (o) =>
          this.preSelectedOption &&
          o.id === this.preSelectedOption.id &&
          o.category === this.preSelectedOption.category,
      );
      if (this.sortedFilteredOptions.length > currentIndex + 1) {
        const option = this.sortedFilteredOptions[currentIndex + 1];
        this.preSelectedOption = option;
        this.scrollOptionIntoView(option);
      }
    }
  }

  @action.bound
  handleClick = () => {
    if (!this.props.disabled) {
      this.active = true;
    }
  };

  @action.bound
  handleInputValueChange(value: string) {
    this.inputValue = value;
  }

  @action.bound clearAll() {
    this.clearInput();
    this.props.onChangeSelectOptions && this.props.onChangeSelectOptions([]);
    this.props.onChangeFreeTextOptions && this.props.onChangeFreeTextOptions([]);
    this.props.toggleFilters?.forEach((filter) => {
      filter.onChange && filter.onChange(false);
    });
    this.internalSelectedOptions = [];
    this.closeSearchbar();
  }

  @action.bound
  handleMouseEnterSearchPhraseRow() {
    this.preSelectedOption = undefined;
  }

  @action.bound
  handleMouseEnterOptionRow(option: ISearchBarOption) {
    this.preSelectedOption = option;
  }

  @action.bound
  handleRemoveFreeTextOption(value: string) {
    const values = this.props.selectedFreeTextOptions ? this.props.selectedFreeTextOptions : [];
    this.internalSelectedOptions = this.internalSelectedOptions.filter((o) => {
      if (typeof o !== 'string') {
        return true;
      }
      return o !== value;
    });
    this.props.onChangeFreeTextOptions && this.props.onChangeFreeTextOptions(values.filter((v) => v !== value));
  }

  @action.bound
  handleRemoveOption(option: ISearchBarOption) {
    let newOptions = this.props.selectedOptions ? this.props.selectedOptions : [];
    newOptions = newOptions.filter((o) => o.category !== option.category || o.id !== option.id);

    this.internalSelectedOptions = this.internalSelectedOptions.filter((o) => {
      if (typeof o === 'string') {
        return true;
      }
      return o.category !== option.category || o.id !== option.id;
    });
    this.props.onChangeSelectOptions && this.props.onChangeSelectOptions(newOptions);
  }
}
