import React from 'react';
import { action, computed, observable } from 'mobx';
import { FormInstance } from 'antd';
import { t } from 'i18next';
import { AxiosError } from 'axios';

import { DeprecatedAbstractListViewStore } from '@/pages/master-data/common/deprecated-abstract-list-view-store';
import {
  BuildingRefDto,
  DepartmentRefDto,
  ResourceRefDto,
  ResourceTypeRefDto,
  RoomDto,
  RoomFormDto,
  RoomGroupRefDto,
  RoomTypeRefDto,
  RoomFormUserRefDto,
  RoomFormResourceRefDto,
  UserRefDto,
  UserGroupRefDto,
} from '@untis/wu-rest-view-api';
import { ElementType } from '@/stores/rights-store';
import { RoomViewApi } from '@/stores/api-store';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { IDeprecatedSearchBarOption } from '@/ui-components/deprecated-search-bar/deprecated-search-bar';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { booleanSorting, defaultSorting, numberSorting, sortingByDisplayName } from '@/utils/sorting/sorting-util';
import { IRoomForm, RoomForm } from '@/pages/master-data/room/room-form';
import { IListViewAction, IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import { createChunks } from '@/utils/array/array-util';
import { inject } from '@/types/store';
import RouterStore from '@/stores/router-store';
import { TestIds } from '@/testIds';

export interface IRoomRow extends ITableRowKey {
  id: number;
  name: string;
  longName: string;
  alternateName: string;
  text: string;
  roomType: RoomTypeRefDto | undefined;
  active: boolean;
  userInCharge: UserRefDto | undefined;
  department: DepartmentRefDto | undefined;
  building: BuildingRefDto | undefined;
  resources: ResourceRefDto[] | undefined;
  capacity: number | undefined;
  canBeBooked: boolean | undefined;
  externKey: string | undefined;
}

export class RoomStore extends DeprecatedAbstractListViewStore<IRoomForm> {
  @observable private _rooms: RoomDto[] = [];
  @observable private _roomForm: RoomFormDto | undefined;
  @observable private _selectedOptions: IDeprecatedSearchBarOption[] = [];
  private _routerStore = inject(RouterStore);

  constructor(form: FormInstance<IRoomForm>) {
    super(form, { elementType: ElementType.ROOM });
  }

  @action
  async fetchData() {
    await Promise.all([this.fetchRooms(), this.fetchFormConfig()]);
    this.setIsDataLoading(false);
  }

  @action
  async fetchRooms() {
    try {
      await RoomViewApi.getRooms(this.selectedDepartmentId).then((response: any) => {
        this.setRooms(response.data.rooms);
      });
    } catch (error) {
      console.error(error);
    }
  }

  @action
  async fetchFormConfig() {
    try {
      await RoomViewApi.getRoomForm().then((response: any) => {
        this._roomForm = response.data;
      });
    } catch (error) {
      console.error(error);
    }
  }

  @computed
  get rooms(): RoomDto[] {
    return this._rooms;
  }

  @action
  setRooms(rooms: RoomDto[]) {
    this._rooms = rooms;
  }

  @computed
  get selectedOptions(): IDeprecatedSearchBarOption[] {
    return this._selectedOptions;
  }

  @action
  setSelectedOptions(options: IDeprecatedSearchBarOption[]) {
    this._selectedOptions = options;
  }

  @computed
  get columns(): Columns<IRoomRow> {
    const cols: Columns<IRoomRow> = [
      {
        type: ColumnType.Text,
        key: 'name',
        header: t('general.shortName'),
        sorter: (a, b) => defaultSorting(a.name, b.name),
      },
      {
        type: ColumnType.Text,
        key: 'longName',
        header: t('general.longName'),
        sorter: (a, b) => defaultSorting(a.longName, b.longName),
      },

      {
        type: ColumnType.Custom,
        key: '_userInCharge',
        header: t('general.personInCharge'),
        render: (row) => row.userInCharge?.displayName || '',
        sorter: (a, b) => sortingByDisplayName(a.userInCharge, b.userInCharge),
      },
      {
        type: ColumnType.Custom,
        key: '_roomType',
        header: t('general.roomType'),
        render: (row) => row.roomType?.displayName || '',
        sorter: (a, b) => sortingByDisplayName(a.roomType, b.roomType),
      },
      {
        type: ColumnType.Custom,
        key: '_department',
        header: t('general.department'),
        render: (row) => row.department?.displayName || '',
        sorter: (a, b) => sortingByDisplayName(a.department, b.department),
      },
      {
        type: ColumnType.Custom,
        key: '_building',
        header: t('general.building'),
        render: (row) => row.building?.displayName || '',
        sorter: (a, b) => sortingByDisplayName(a.building, b.building),
      },
      {
        type: ColumnType.List,
        key: 'resources',
        header: t('general.resources'),
        getEntries: (row: IRoomRow) =>
          row.resources ? row.resources.map((resource) => resource.displayName || '') : [],
      },
      {
        type: ColumnType.Text,
        key: 'capacity',
        header: t('general.capacity'),
        sorter: (a, b) => numberSorting(a.capacity || 0, b.capacity || 0),
      },
      {
        type: ColumnType.Boolean,
        key: 'canBeBooked',
        header: t('general.canBeBooked'),
        sorter: (a, b) => booleanSorting(a.canBeBooked || false, b.canBeBooked || false),
        edit: {
          onChange: (row: IRoomRow) => {
            const currentRoom: any = this._rooms.find((s) => s.id == row.id);
            if (currentRoom) {
              this.updateRoom({
                ...currentRoom,
                canBeBooked: !currentRoom.canBeBooked,
                canBeReservedOnly: false,
              } as RoomDto);
            }
          },
        },
        disabled: () => !this.canEdit,
      },
      {
        type: ColumnType.Label,
        key: 'active',
        header: t('general.active'),
        align: 'center',
        sorter: (a, b) => booleanSorting(a.active, b.active),
        getTagProps: (active) => ({
          type: active ? 'success' : 'light-gray',
          text: active ? t('general.active') : t('general.inactive'),
        }),
      },
    ];

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.OverlayHoverActions,
        key: 'actions',
        actionIcons: this.columnActions,
      });
    }

    return cols;
  }

  @computed
  get filteredRows(): IRoomRow[] {
    return this.roomRows.filter((room: IRoomRow) =>
      this.containsSearchInput([room.name, room.longName, room.alternateName, room.text]),
    );
  }

  @computed
  get roomTypes(): RoomTypeRefDto[] {
    return this._roomForm?.roomTypes || [];
  }

  @computed
  get departments(): DepartmentRefDto[] {
    return this._roomForm?.departments || [];
  }

  @computed
  get userGroups(): UserGroupRefDto[] {
    return this._roomForm?.userGroups || [];
  }

  @computed
  get roomGroups(): RoomGroupRefDto[] {
    return this._roomForm?.roomGroups || [];
  }

  @computed
  get buildings(): BuildingRefDto[] {
    return this._roomForm?.buildings || [];
  }
  @computed
  get users(): RoomFormUserRefDto[] {
    return this._roomForm?.users || [];
  }

  @computed
  get roomRows(): IRoomRow[] {
    return RoomStore.mapRoomsToRows(this._rooms);
  }

  @computed
  get resources(): RoomFormResourceRefDto[] {
    return this._roomForm?.resources || [];
  }

  @computed
  get resourceTypes(): ResourceTypeRefDto[] {
    return this._roomForm?.resourceTypes || [];
  }

  private static mapRoomsToRows(rooms: RoomDto[]): IRoomRow[] {
    return rooms.map((room) => ({
      id: room.id || -1,
      key: room.id || -1,
      name: room.name,
      longName: room.longName || '',
      alternateName: room.alternateName || '',
      text: room.text || '',
      roomType: room.roomType,
      active: room.active === true,
      userInCharge: room.userInCharge,
      department: room.department,
      building: room.building,
      resources: room.resources,
      capacity: room.capacity,
      canBeBooked: room.canBeBooked,
      externKey: room.externKey,
    }));
  }

  @computed
  private get columnActions() {
    const actions = [
      {
        tooltip: t('general.edit'),
        onClick: (row: IRoomRow) => this.openEditForm(row),
        icon: 'edit',
        testId: 'room-edit-button',
      },
    ];

    if (this.canDelete) {
      actions.push({
        tooltip: t('general.delete'),
        onClick: (row: IRoomRow) => this.deleteRoom(row.id),
        icon: 'shared_trash',
        testId: 'room-delete-button',
      });
    }

    return actions;
  }

  openEditForm(row: IRoomRow) {
    const room = this.getRoomDtoByRowId(row.id.toString());
    this.form.resetFields();
    if (room) {
      this._modalStore.openModalDialog({
        title: `${t('general.room')} ${room.longName} (${room.name})`,
        size: 'full-size',
        children: <RoomForm room={room} store={this} />,
        containsForm: true,
      });
    }
  }

  deleteRoom(id: number) {
    this._modalStore
      .openDeletePrompt(t('general.deleteRoom'), t('general.deleteRoomConfirmationQuestion'))
      .then((result) => {
        if (result) {
          RoomViewApi.deleteRoom(id)
            .then(() => {
              this._notificationStore.success({ title: t('general.roomDeleted') });
              this._modalStore.closeModal();
              this.onRoomsDeleted([id]);
            })
            .catch((error) => {
              const axiosError: AxiosError = error as AxiosError;
              if (axiosError.response?.status === 422) {
                this._notificationStore.error({
                  title: t('general.roomCouldNotBeDeleted'),
                  message: t('general.roomsInUseCanNotBeDeleted'),
                });
              } else {
                this._notificationStore.error({
                  title: t('general.roomCouldNotBeDeleted'),
                  message: error.toString(),
                });
              }
            });
        }
      });
  }

  @action.bound
  openCreateForm() {
    this.form.resetFields();
    this._modalStore.openModalDialog({
      children: <RoomForm store={this} />,
      title: t('general.newRoom'),
      size: 'full-size',
      containsForm: true,
    });
  }
  @action
  updateRoom(room: RoomDto) {
    RoomViewApi.updateRoom(room)
      .then((result: any) => {
        this.onRoomChanged(result.data);
      })
      .catch((error: any) => {
        this._notificationStore.error({
          title: t('general.roomCouldNotBeEdited'),
          message: error.toString(),
        });
      });
  }

  @action
  onRoomChanged(room: RoomDto) {
    this.setRooms(this._rooms.map((s) => (s.id === room.id ? room : s)));
  }

  @action
  onRoomsDeleted(deletedRoomIds: number[]) {
    this.setRooms(this._rooms.filter((s) => s.id && !deletedRoomIds.includes(s.id)));
  }

  @action
  onRoomCreated(room: RoomDto) {
    this.setRooms([...this._rooms, room]);
  }

  @computed
  get listViewActions(): IListViewAction[] {
    const actions: IListViewAction[] = [];
    if (this.canCreate) {
      actions.push({
        label: t('general.new'),
        testId: TestIds.MASTER_DATA_NEW,
        onClick: this.openCreateForm,
      });
    }
    actions.push({
      label: t('general.report'),
      testId: TestIds.MASTER_DATA_REPORT,
      onClick: () => {
        this.generateReport('Room');
      },
    });

    actions.push({
      label: t('general.import'),
      testId: TestIds.MASTER_DATA_IMPORT,
      onClick: () => {
        this._routerStore.routing.push('/rooms-import');
      },
    });

    return actions;
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<IRoomRow>[] | undefined {
    return this.canDelete
      ? [
          {
            label: t('general.delete'),
            icon: 'shared_trash',
            onClick: (rows) => {
              this._modalStore
                .openDeletePrompt(
                  t('general.deleteRoom', { count: rows.length }),
                  t('general.deleteRoomConfirmationQuestion', { count: rows.length }),
                )
                .then((result) => {
                  if (result) {
                    const chunks = createChunks<IRoomRow>(rows, 100);
                    chunks.forEach((chunk) => {
                      RoomViewApi.deleteRooms(chunk.map((row) => row.id))
                        .then(() => {
                          this.onRoomsDeleted(rows.map((row) => row.id));
                          this.setSelectedRowKeys([]);
                          this._notificationStore.success({
                            title: t('general.roomsDeleted'),
                          });
                        })
                        .catch((error) => {
                          const axiosError: AxiosError = error as AxiosError;
                          if (axiosError.response?.status === 422) {
                            // const response: AxiosResponse<EntityRefDto[]> =
                            // axiosError.response as AxiosResponse<EntityRefDto[]>;
                            this._notificationStore.error({
                              title: t('general.someRoomsCouldNotBeDeleted'),
                              message: t('general.roomsInUseCanNotBeDeleted'),
                            });
                          } else {
                            console.debug('error', axiosError);
                            this._notificationStore.error({
                              title: error.toString(),
                            });
                          }
                        });
                    });
                  }
                });
            },
          },
        ]
      : undefined;
  }

  getRoomDtoByRowId = (id: string): RoomDto | undefined => {
    return this._rooms.find((room) => room.id?.toString() === id);
  };
}
