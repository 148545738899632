import { Radio } from 'antd';
import React from 'react';
import classNames from 'clsx';

import { IPeriodDetailsHeaderPeriod } from '@/ui-components/calendar-entry-details-header/period-content';
import { DeprecatedTag } from '@/ui-components';

import './calendar-entry-details-header.less';

interface IProps {
  periods: IPeriodDetailsHeaderPeriod[];
  selectedPeriodId: number;
  isBlockSelected: boolean;
  onSelect?: (periodId: number, isBlock: boolean) => void;
}

const renderLineData = (data: string, isInactive: boolean, key: string) => {
  return (
    <span key={key} className={classNames('pipe-separator', { 'subtitle-item--inactive': isInactive })}>
      {data}
    </span>
  );
};

export const PeriodSelectionList = (props: IProps) => {
  const toDisplayName = (element: { displayName: string }) => element.displayName;

  const handleSelect = (periodId: number, isBlock: boolean) => {
    props.onSelect && props.onSelect(periodId, isBlock);
  };

  const getFirstLineData = (period: IPeriodDetailsHeaderPeriod): string[] => {
    const firstLineData: string[] = [];
    if (period.classes.length > 0) {
      firstLineData.push(period.classes.map(toDisplayName).join(', '));
    }
    if (period.studentGroup) {
      firstLineData.push(period.studentGroup);
    }
    if (period.subject) {
      firstLineData.push(period.subject.displayName);
    }
    return firstLineData;
  };

  const getSecondLineData = (period: IPeriodDetailsHeaderPeriod): { content: string; inactive?: boolean }[] => {
    const secondLineData: { content: string; inactive?: boolean }[] = [];
    if (period.removedRooms.length > 0) {
      secondLineData.push({ content: period.removedRooms.map(toDisplayName).join(', '), inactive: true });
    }
    if (period.rooms.length > 0) {
      secondLineData.push({ content: period.rooms.map(toDisplayName).join(', ') });
    }
    if (period.removedTeachers.length > 0) {
      secondLineData.push({ content: period.removedTeachers.map(toDisplayName).join(', '), inactive: true });
    }
    if (period.teachers.length > 0) {
      secondLineData.push({ content: period.teachers.map(toDisplayName).join(', ') });
    }
    return secondLineData;
  };

  return (
    <div className="period-selection-list">
      {props.periods.map((period, index) => {
        return (
          <span className="main-period" key={'main-period' + index}>
            <Radio
              className="block-period-radio"
              checked={
                (props.isBlockSelected || period.parts.length === 1) &&
                period.parts.map((p) => p.periodId).includes(props.selectedPeriodId)
              }
              onClick={() => handleSelect(period.parts[0].periodId, true)}
            />
            <span className="main-period-data">
              <div onClick={() => handleSelect(period.parts[0].periodId, true)}>
                <span className="first-line">
                  {getFirstLineData(period).map((data, index) => {
                    return renderLineData(data, false, 'data-' + index);
                  })}
                </span>
                <span className="second-line">
                  {getSecondLineData(period).map((data, index) => {
                    return renderLineData(data.content, !!data.inactive, 'data-' + index);
                  })}
                </span>
                <span className="tag-line">
                  {period.tags?.map((tag) => (
                    <DeprecatedTag key={tag} active>
                      {tag.toUpperCase()}
                    </DeprecatedTag>
                  ))}
                </span>
              </div>
              {period.parts.length > 1 &&
                period.parts.map((part) => {
                  return (
                    <span className="period" key={part.periodId} onClick={() => handleSelect(part.periodId, false)}>
                      <Radio checked={!props.isBlockSelected && part.periodId === props.selectedPeriodId} />
                      <span>{part.startDateTime.format('LT') + '-' + part.endDateTime.format('LT')}</span>
                    </span>
                  );
                })}
            </span>
          </span>
        );
      })}
    </div>
  );
};
