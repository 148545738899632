import React from 'react';
import { Form } from 'antd';

import { ColorPicker, ColorPickerProps } from '@/ui-components/color-picker';
import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';

export interface IColorPickerProps extends IFormItemProps<string>, ColorPickerProps {
  triangle?: 'hide' | 'top-left' | 'top-right' | undefined;
}

export const FormColorPicker = (props: IColorPickerProps) => {
  const {
    triangle,
    name,
    label,
    initialValue,
    rules,
    dependencies,
    disabled,
    colors,
    variant,
    hideValue,
    placeholder,
  } = props;

  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={initialValue}
      className="form-radio-buttons"
      rules={rules}
      dependencies={dependencies}
    >
      <ColorPicker
        disabled={disabled}
        triangle={triangle}
        colors={colors}
        variant={variant}
        hideValue={hideValue}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export default FormColorPicker;
