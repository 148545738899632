import { observable, action, computed, reaction } from 'mobx';
import { debounce } from 'lodash';
import { AxiosResponse } from 'axios';

import { BaseMessagesStore } from '@mg/stores/base-messages-store';
import { IncomingMessageRefDto, MessagesViewDto } from '@untis/wu-rest-view-api';
import { MessageViewApi } from '@/stores/api-store';
import { Store } from '@/types/store';

@Store()
export default class IncomingMessagesStore extends BaseMessagesStore<IncomingMessageRefDto> {
  // as soon as this store is calculating the unread count we don't want false information from embedded webuntis
  blockIframeUnreadUpdates = false;

  @observable initialReadConfirmationMessages: IncomingMessageRefDto[] = [];
  @observable searchedReadConfirmationMessages: IncomingMessageRefDto[] = [];
  @observable private unreadCount: number = 0;

  constructor(typediParams: any) {
    super(typediParams);

    reaction(
      () => this.searchText,
      (searchText) => {
        if (searchText.length === 0) {
          this.getIncomingMessages();
        } else {
          this.debouncedSearchIncomingMessages();
        }
      },
    );
  }

  @action
  async getIncomingMessages() {
    try {
      await this.fetchMessages<{ incomingMessages: IncomingMessageRefDto[] }>(
        (searchText, options) => MessageViewApi.getIncomingMessages(searchText, options),
        'incomingMessages',
      );
    } catch {
      this.initialReadConfirmationMessages.length = 0;
    }
  }

  @action
  debouncedSearchIncomingMessages = debounce(this.getIncomingMessages, 300);

  @action
  protected handleFetchResponse(response: AxiosResponse<MessagesViewDto>): number {
    if (this.hasActiveSearch) {
      this.searchedReadConfirmationMessages = response.data.readConfirmationMessages;
      return this.searchedReadConfirmationMessages.length;
    }

    this.initialReadConfirmationMessages = response.data.readConfirmationMessages;
    return this.initialReadConfirmationMessages.length;
  }

  @action
  protected resetSearchedMessages() {
    super.resetSearchedMessages();
    this.searchedReadConfirmationMessages = [];
  }

  @action
  updateMessageUnreadCount() {
    this.blockIframeUnreadUpdates = true;
    let count = 0;
    count += this.messages.filter((m) => !m.isMessageRead).length;
    count += this.initialReadConfirmationMessages.filter((m) => !m.isMessageRead).length;
    this.setUnreadMessagesCount(count);
  }

  @action
  setUnreadMessagesCount = (count: number) => {
    this.unreadCount = count;
  };

  @action
  async getUnreadMessagesCount() {
    const {
      data: { unreadMessagesCount },
    } = await MessageViewApi.getMessageCenterStatus();
    this.setUnreadMessagesCount(unreadMessagesCount);
  }

  @action
  deleteMessages(messageIds: number[]) {
    super.deleteMessages(messageIds);
    this.updateMessageUnreadCount();
  }

  @computed
  get readConfirmationMessages() {
    if (this.hasActiveSearch) {
      return this.searchedReadConfirmationMessages;
    }

    return this.initialReadConfirmationMessages;
  }

  @computed
  get hasReadConfirmationMessages() {
    return this.initialReadConfirmationMessages.length > 0;
  }

  @computed
  get badge() {
    return this.unreadCount > 0 ? this.unreadCount.toString() : '';
  }
}
