import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

interface IProps extends PropsWithChildren {
  wrap?: boolean;
}

export const FilterBar = (props: IProps) => {
  const className = clsx('new-filter-bar', {
    wrap: !!props.wrap,
  });
  return <div className={className}>{props.children}</div>;
};
