import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Dayjs } from 'dayjs';

import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { useComponentWillUnmount } from '../../../hooks/useComponentWillUnmount';
import TimeRangePickerStore, { TimeRangePickerMode } from '../time-range-picker-store';

import '../time-range-picker.less';

interface IProps {
  startTime: Dayjs;
  endTime: Dayjs;
  mode: TimeRangePickerMode;
  store: TimeRangePickerStore;
}

/**
 *  Component that renders three inputs (hour, minutes, AM/PM) and is optimized for
 *  entering times in a 12h format.
 */
const TimeRangePickerInput24h = (props: IProps) => {
  const { store } = props;
  const time = props.mode === TimeRangePickerMode.Start ? props.startTime : props.endTime;
  const [initialTime] = useState(time);
  const hoursInputRef: RefObject<HTMLInputElement> = useRef(null);
  const minutesInputRef: RefObject<HTMLInputElement> = useRef(null);
  const [hoursInputValue, setHoursInputValue] = useState(() => store.prefixZero(time.hour().toString()));
  const [minutesInputValue, setMinutesInputValue] = useState(() => store.prefixZero(time.minute().toString()));
  const hoursInputValueRef = useRef(hoursInputValue);
  const minutesInputValueRef = useRef(minutesInputValue);
  const [closeAfterRerender, setCloseAfterRerender] = useState(false);

  // effect so that the form can close itself
  useEffect(() => {
    if (closeAfterRerender) {
      props.store.toggleEditMode();
    }
  }, [closeAfterRerender, props.store]);

  // effect to update reference values (needed in useComponentWillUnmount)
  useEffect(() => {
    hoursInputValueRef.current = hoursInputValue;
    minutesInputValueRef.current = minutesInputValue;
  }, [hoursInputValue, minutesInputValue]);

  useComponentWillUnmount(() => {
    if (!props.store.closedByTimeSlotClick) {
      const newValue: Dayjs = store.createNewDayjs24h(hoursInputValueRef.current, minutesInputValueRef.current);
      store.onTimeChange(newValue, props.mode, props.startTime, props.endTime);
    }
  });

  useComponentDidMount(() => {
    store.selectRef(hoursInputRef);
  });

  const resetHours = () => {
    setHoursInputValue(initialTime.hour().toString());
  };

  const resetMinutes = () => {
    setMinutesInputValue(initialTime.minute().toString());
  };

  const handleHoursKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const hours = Number(hoursInputValue);
    if (!isNaN(hours)) {
      switch (e.key) {
        case 'Enter':
        case 'Tab':
          e.preventDefault();
          setHoursInputValue(store.prefixZero(hoursInputValue));
          store.selectRef(minutesInputRef);
          break;
        case 'ArrowUp':
          e.preventDefault();
          setHoursInputValue(hours < 23 ? store.prefixZero((hours + 1).toString()) : '00');
          break;
        case 'ArrowDown':
          e.preventDefault();
          setHoursInputValue(hours > 0 ? store.prefixZero((hours - 1).toString()) : '23');
          break;
      }
    }
  };

  const handleMinutesKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const minutes = Number(minutesInputValue);
    if (!isNaN(minutes)) {
      switch (e.key) {
        case 'Enter':
        case 'Tab':
          e.preventDefault();
          props.store.toggleEditMode();
          break;
        case 'ArrowUp':
          e.preventDefault();
          setMinutesInputValue(minutes < 59 ? store.prefixZero((minutes + 1).toString()) : '00');
          break;
        case 'ArrowDown':
          e.preventDefault();
          setMinutesInputValue(minutes > 0 ? store.prefixZero((minutes - 1).toString()) : '59');
          break;
      }
    }
  };

  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    const numericValue: number = Number(value);
    if (isNaN(numericValue) || numericValue > 23 || numericValue < 0) {
      resetHours();
      store.selectRef(hoursInputRef);
      return;
    }

    if (numericValue >= 3 && numericValue <= 9) {
      setHoursInputValue(store.prefixZero(e.target.value));
      store.selectRef(minutesInputRef);
      return;
    }

    setHoursInputValue(e.target.value);
    if (value.length === 2) {
      store.selectRef(minutesInputRef);
    }
  };

  const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    const numericValue: number = Number(value);
    if (isNaN(numericValue) || numericValue > 59 || numericValue < 0) {
      resetMinutes();
      store.selectRef(minutesInputRef);
      return;
    }

    if (numericValue >= 6 && numericValue <= 9) {
      setMinutesInputValue(store.prefixZero(e.target.value));
      setCloseAfterRerender(true);
      return;
    }

    setMinutesInputValue(e.target.value);

    if (e.target.value.length > 1) {
      setCloseAfterRerender(true);
    }
  };

  return (
    <div className="time-range-picker-input">
      <input
        value={hoursInputValue}
        onChange={handleHoursChange}
        onKeyDown={handleHoursKeyDown}
        onClick={() => store.selectRef(hoursInputRef)}
        ref={hoursInputRef}
        data-testid="hours-input"
      />
      <p>:</p>
      <input
        value={minutesInputValue}
        onChange={handleMinutesChange}
        onKeyDown={handleMinutesKeyDown}
        onClick={() => store.selectRef(minutesInputRef)}
        ref={minutesInputRef}
        data-testid="minutes-input"
      />
    </div>
  );
};

export default TimeRangePickerInput24h;
