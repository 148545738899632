import React, { useState } from 'react';
import './expandable-list.less';
import { useTranslation } from 'react-i18next';

interface IProps {
  entries: string[];
  entryThreshold?: number;
  width?: string | number;
}

export const ExpandableList = (props: IProps) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const entriesToRender =
    props.entryThreshold && !expanded ? props.entries.slice(0, props.entryThreshold) : props.entries;

  const handleExpandClick = (e: React.MouseEvent<HTMLLabelElement>, expand: boolean) => {
    e.stopPropagation();
    setExpanded(expand);
  };

  const renderExpander = () => {
    const itemsLeft = props.entries.length - props.entryThreshold!;
    return expanded ? (
      <label className="expander" onClick={(e) => handleExpandClick(e, false)}>
        {t('general.showLess')}
      </label>
    ) : (
      <label className="expander" onClick={(e) => handleExpandClick(e, true)}>
        {t('general.andXMore', { amount: itemsLeft })}
      </label>
    );
  };

  return (
    <div className="expandable-list" style={{ width: props.width }}>
      <ul>
        {entriesToRender.map((entry, index) => {
          return (
            <li title={entry} key={index}>
              {entry}
            </li>
          );
        })}
      </ul>
      {props.entryThreshold && props.entries.length > props.entryThreshold && renderExpander()}
    </div>
  );
};
