import { action, observable } from 'mobx';
import { RefObject } from 'react';

type IntersectedDivPosition = 'top' | 'bottom';

export class ScrollerStore {
  @observable level: number = 0;
  @observable inProgress: boolean = false;
  @observable hideIcon: boolean = false;

  private readonly position: IntersectedDivPosition;

  constructor(position: IntersectedDivPosition) {
    this.position = position;
  }

  private isTop() {
    return this.position === 'top';
  }

  @action
  setLevel = (value: number) => {
    this.level = value;
  };

  @action
  callCallback = (callback: () => any, container: RefObject<HTMLElement>) => {
    let scrollHeight = 0;
    if (container.current) {
      scrollHeight = container.current.scrollHeight;
      const clientHeight = container.current.clientHeight;
      const scrollTop = this.isTop() ? container.current.scrollTop + 100 : scrollHeight - clientHeight - 100;
      container.current.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      });
    }
    if (this.inProgress) {
      return;
    }
    this.inProgress = true;
    this.hideIcon = true;
    setTimeout(() => {
      callback().finally(() => {
        this.inProgress = false;
        container.current &&
          this.isTop() &&
          container.current.scrollTo({
            top: container.current.scrollHeight - scrollHeight + 100,
          });
        setTimeout(() => {
          this.hideIcon = false;
        }, 150); // wait until we scrolled up, until we display the icon again.
      });
    }, 500); // some delay, so that the loading indicator does not look weird.
  };
}
