import React from 'react';
import clsx from 'clsx';
import { Checkbox as AntDCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { ITestComponentProps } from '@/types/test-component-props';

import './checkbox.less';

export interface ICheckboxProps extends ITestComponentProps {
  checked?: boolean;
  defaultChecked?: boolean;
  label?: string;
  disabled?: boolean;
  key?: string;
  name?: string;
  onChange?: (checked: boolean) => void;
  className?: string;
}

export const Checkbox = (props: ICheckboxProps) => {
  const handleCheck = (e: CheckboxChangeEvent) => {
    props.onChange && props.onChange(e.target.checked);
  };

  const className = clsx(props.className, 'wu-checkbox');

  return (
    <AntDCheckbox
      {...props}
      checked={props.checked}
      onChange={handleCheck}
      className={className}
      data-testid={props.testId}
    >
      {props.label}
    </AntDCheckbox>
  );
};
