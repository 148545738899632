import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Icon } from '@/ui-components';
import { IPageHeaderLinkButtonProps } from '@/ui-components/page/page';

export const PageHeaderLinkButton = (props: IPageHeaderLinkButtonProps) => {
  return (
    <Link to={props.linkTo} data-testid={props.dataTestId}>
      <Button size="large" type={props.type || 'primary'} outline={props.outline}>
        <Icon type={props.icon} />
        {props.label}
      </Button>
    </Link>
  );
};
