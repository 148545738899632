import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/ui-components';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import { copyTextToClipboard } from '@/utils/clipboard/clipboard';
import './error-page.less';

interface IErrorPageProps {
  title: string;
  description: string;
  error?: Error;
  iconColor: 'default' | 'primary' | 'error';
}

const ErrorPage = (props: IErrorPageProps) => {
  const { t } = useTranslation();
  const [copyButtonText, setCopyButtonText] = useState(() => t('general.copyToClipboard'));

  async function handleButtonClick() {
    try {
      await copyTextToClipboard(props.error?.stack ?? '');
      setCopyButtonText(t('general.copyToClipboardSuccess'));
      setTimeout(() => setCopyButtonText(t('general.copyToClipboard')), 1000);
    } catch (error) {}
  }

  return (
    <EmptyIndicator
      className="empty-indicator error-page"
      title={props.title}
      description={props.description}
      icon="not-available"
      iconColor={props.iconColor}
      fullScreen
    >
      {props.error && (
        <details className="error-details">
          <summary>
            {t('general.errors.detailedError')} | <span className="error-details-message">{props.error.message}</span>
          </summary>
          <pre>{props.error.stack}</pre>
          <Button onClick={handleButtonClick}>{copyButtonText}</Button>
        </details>
      )}
    </EmptyIndicator>
  );
};

export default ErrorPage;
