import React, { useState } from 'react';

import { Attachments } from '@/ui-components';
import { IFormItemProps, WUFormItem } from '@/ui-components/wu-form/wu-form-item';
import './form-attachments.less';
import { FileDescriptorDtoV2 } from '@untis/wu-rest-view-api';

export interface IFormAttachmentsProps extends IFormItemProps<FileDescriptorDtoV2[]> {
  onUpload: () => Promise<FileDescriptorDtoV2[]>;
  onDownload: (attachment: any) => void;
}

export const FormAttachments = (props: IFormAttachmentsProps) => {
  const [value, setValue] = useState<FileDescriptorDtoV2[]>(props.initialValue ?? []);

  const onChange = (value: FileDescriptorDtoV2[]) => {
    setValue(value);
  };

  return (
    <WUFormItem
      name={props.name}
      label={props.label}
      initialValue={value}
      dependencies={props.dependencies}
      disabled={props.disabled}
      rules={props.rules}
    >
      <Attachments
        value={value}
        onUpload={props.onUpload}
        onChange={onChange}
        onDownload={props.onDownload}
        disabled={props.disabled}
        testId={props.testId}
      />
    </WUFormItem>
  );
};
