import { computed } from 'mobx';

import { Store, inject } from '@/types/store';
import SettingsStore, { BooleanSetting } from '@/stores/settings-store';
import ConfigStore from '@/stores/config-store';

export const REMINDER_DAYS_BEFORE_END_OF_LICENCE = 21;

@Store()
class TodaySettingsStore {
  private settingsStore = inject(SettingsStore);
  private configStore = inject(ConfigStore);

  @computed
  get showPastLessons() {
    if (this.configStore.isStudent) {
      return true;
    }

    return this.settingsStore.hasBooleanSetting(BooleanSetting.TODAY_SHOW_PAST_LESSONS, true);
  }
}

export default TodaySettingsStore;
