import React from 'react';

import { ILessonCardProps } from '@/components/lesson-card/lesson-card';
import { Icon, IconButton } from '@/ui-components';

const LessonCardRight = ({ href, fullWidthHref }: Pick<ILessonCardProps, 'href' | 'fullWidthHref'>) => {
  return (
    <div className="lesson-card-right" data-testid="lesson-card-right">
      {!fullWidthHref && href && (
        <div className="lesson-card-timetable-link-container" onClick={(e) => e.stopPropagation()}>
          <IconButton href={href} ariaLabel="arrow" type="point-arrow-right" />
        </div>
      )}
      {fullWidthHref && (
        <div className="lesson-card-timetable-link-container" onClick={(e) => e.stopPropagation()}>
          <Icon type="point-arrow-right" />
        </div>
      )}
    </div>
  );
};

export default LessonCardRight;
