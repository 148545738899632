import React, { ReactNode, useEffect, useState } from 'react';
import { Form, FormInstance } from 'antd';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { StoreValue } from 'antd/lib/form/interface';
import { RuleObject } from 'antd/lib/form';

import { WUFormRow } from './wu-form-row/wu-form-row';

import { Button } from '@/ui-components';
import { ButtonType } from '@/ui-components/button/button';
import { ITestComponentProps } from '@/types/test-component-props';

import './wu-form.less';

export interface IFormButton extends ITestComponentProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  type?: ButtonType;
  outline?: boolean;
}

interface IProps<T> {
  children?: ReactNode;
  onSubmit?: (value: T) => void;
  /**
   * Usually you don't have to use "propsForm".
   * But it is necessary if e.g. you want to manually trigger
   * validation outside of the form, like in the Form Stories
   */
  form?: FormInstance<T>;
  leftButtons?: IFormButton[];
  rightButtons?: IFormButton[];
  fixedButtonBar?: boolean;
  hideSubmitButton?: boolean;
  enableSubmitButton?: boolean;
  disableSubmitButton?: boolean;
  withDiscardButton?: boolean;
  onDisableChange?: (disabled: boolean) => void;
  onValuesChange?: (changedValues: Partial<T>, values: T) => void;
  maxWidth?: boolean;
  testIdSaveButton?: string;
  testIdDiscardButton?: string;
  noWrappingRow?: boolean; // only needed as long we don't move to the antd grid
  submitButtonLabel?: string;
  discardButtonLabel?: string;
  submitButtonLoading?: boolean;
  // once all forms have been changed to the new drafts, this property can be removed along with the border
  hideTopBorder?: boolean;
  showAbsoluteButtonBarSeparator?: boolean;
  paddingTop?: boolean;
  className?: string;
  fullHeight?: boolean;
}

// WUFormValdator === Validator from AntDesign exposed, so devs can make use of type inference
export type WUFormValidator = (
  rule: RuleObject,
  value: StoreValue,
  callback: (error?: string) => void,
) => Promise<void | any> | void;

export function WUForm<T>(props: IProps<T>) {
  const [stateForm] = Form.useForm<T>();
  const [disabled, setDisabled] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const { t } = useTranslation();

  const form = props.form ? props.form : stateForm;

  useEffect(() => {
    props.onDisableChange && props.onDisableChange(!props.enableSubmitButton ?? true);
  }, []);

  const handleOnFieldsChange = () => {
    const hasErrors = form.getFieldsError().some((e) => e.errors.length);
    setDisabled(hasErrors);
    props.onDisableChange && props.onDisableChange(hasErrors);
    if (!hasChanged) {
      setHasChanged(true);
    }
  };

  const handleFinish = (value: T) => {
    props.onSubmit && props.onSubmit(value);
    props.withDiscardButton && setHasChanged(false);
  };

  const handleFinishFailed = () => {
    setDisabled(true);
  };

  const handleValuesChanged = (changedValues: Partial<T>, values: T) => {
    props.onValuesChange && props.onValuesChange(changedValues, values);
  };

  const className = clsx('wu-form', props.className, {
    fixed: !!props.fixedButtonBar,
    'with-max-width': !!props.maxWidth,
    'hide-top-border': !!props.hideTopBorder,
    'padding-top': !!props.paddingTop,
    'full-height': !!props.fullHeight,
  });

  const showRightButtons = props.onSubmit || (props.rightButtons && props.rightButtons.length > 0);
  const showLeftButtons = props.leftButtons && props.leftButtons.length > 0;

  return (
    <div className={className}>
      <Form<T>
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        form={form}
        onFieldsChange={handleOnFieldsChange}
        onValuesChange={handleValuesChanged}
        layout="vertical"
        requiredMark={false}
        labelAlign="left"
      >
        <div className="form-container">
          <div className="form-children-container">
            <div className="padding-container">
              {props.maxWidth ? (
                <div className="max-width-container">
                  {props.noWrappingRow ? props.children : <WUFormRow>{props.children}</WUFormRow>}
                </div>
              ) : props.noWrappingRow ? (
                <WUFormRow>{props.children}</WUFormRow>
              ) : (
                props.children
              )}
            </div>
          </div>

          {props.showAbsoluteButtonBarSeparator && <div className="absolute-button-bar-separator" />}
          <div className="form-buttons">
            {showLeftButtons && (
              <div className="form-buttons-left">
                {props.leftButtons?.map((button: IFormButton) => {
                  return (
                    <Button
                      key={button.label}
                      size="large"
                      onClick={button.onClick}
                      type={button.type}
                      disabled={button.disabled}
                      outline={button.outline !== false}
                      testId={button.testId}
                    >
                      {button.label}
                    </Button>
                  );
                })}
              </div>
            )}

            {showRightButtons && (
              <div className="form-buttons-right">
                {props.onSubmit && !props.hideSubmitButton && (
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      disabled={
                        disabled ||
                        props.disableSubmitButton ||
                        (!hasChanged && !props.enableSubmitButton) ||
                        props.submitButtonLoading
                      }
                      loading={props.submitButtonLoading}
                      onClick={() => {
                        form.submit();
                      }}
                      testId={props.testIdSaveButton}
                    >
                      {props.submitButtonLabel ? props.submitButtonLabel : t('general.save')}
                    </Button>
                  </Form.Item>
                )}
                {props.withDiscardButton && (
                  <Button
                    type="secondary"
                    size="large"
                    onClick={() => {
                      form.resetFields();
                      setHasChanged(false);
                    }}
                    outline
                    disabled={!hasChanged}
                    testId={props.testIdDiscardButton}
                  >
                    {props.discardButtonLabel ? props.discardButtonLabel : t('general.discardChangesAction')}
                  </Button>
                )}
                {props.rightButtons?.map((button) => {
                  return (
                    <Button
                      key={button.label}
                      size="large"
                      onClick={button.onClick}
                      type={button.type}
                      disabled={button.disabled}
                      outline={button.outline !== false}
                      testId={button.testId}
                    >
                      {button.label}
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
}
