import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { Input as AntdInput } from 'antd';

import { ISidebarToolbarButton, SidebarToolbar } from '@/ui-components/sidebar/sidebar-toolbar/sidebar-toolbar';
import { SidebarContent } from '@/ui-components/sidebar/sidebar-content/sidebar-content';
import Skeleton from '@/ui-components/skeleton/skeleton';
import {
  ITeachingContentPeriod,
  PREV_PERIODS_PER_SCREEN,
  TeachingContentSidebarStore,
} from '@/pages/class-register/teaching-content-sidebar/teaching-content-sidebar-store';
import { ExpandableTextContainer, IconButton, SwitchComponent } from '@/ui-components';
import { formatDate, formatTime, formatWeekDay } from '@/utils/date/date-util';
import { ClassRegDateTimeRangeDto, ClassRegLessonTopicDto } from '@untis/wu-rest-view-api/api';
import UntisCard from '@/ui-components/untis-card';
import './teaching-content-sidebar.less';
import { useDelay } from '@/hooks/useDelay';
import { OneDriveAttachments } from '@/components/attachments/one-drive-attachments';
import { SingleDropDown } from '@/ui-components/drop-down/drop-down';
import { getAttachmentProps } from '@/components/attachments/attachments-common';

export interface ITeachingContentPreview {
  periodId: number;
  topicId?: number;
  text: string;
}

interface IProps {
  onSave: (savedTeachingContents: ITeachingContentPreview[]) => void;
  store: TeachingContentSidebarStore;
}

export const TeachingContentSidebar = observer((props: IProps) => {
  const store = props.store;
  const { t } = useTranslation();
  const isPeriodsLoading = useDelay(store.isLoading);
  const isPrevPeriodsLoadingDelayed = useDelay(store.isPrevPeriodsLoading);

  const prevPeriodRowsPerScreen: ITeachingContentPeriod[] = useMemo(() => {
    return store.prevPeriods
      .slice(store.prevPeriodsPointerIndex, store.prevPeriodsPointerIndex + PREV_PERIODS_PER_SCREEN)
      .map((item) => ({
        key: item.periodId,
        id: item.periodId,
        date: formatDate(item.start),
        day: formatWeekDay(item.start),
        teachingContent: item.text,
        attachments: item.attachments,
      }));
  }, [store.prevPeriods, store.prevPeriodsPointerIndex]);

  const onSaveClick = () => {
    store.saveOrUpdate().then((dtos: ClassRegLessonTopicDto[]) => {
      props.onSave(dtos.map((dto) => ({ periodId: dto.periodId, text: dto.text || '', topicId: dto.id })));
    });
  };

  const toolbarButtons: ISidebarToolbarButton[] = [];
  if (store.isSaveAllowed) {
    toolbarButtons.push({
      label: t('general.save'),
      type: 'primary',
      disabled: store.isSaving || !store.isEdited,
      loading: store.isSaving,
      onClick: onSaveClick,
    });
  }

  toolbarButtons.push({
    label: t('general.cancel'),
    outline: true,
    onClick: () => store.onCloseTeachingContentSidebar(),
  });

  const isDropdownDisabled = !store.selectedPeriod || !store.isSaveAllowed;
  // hide the dropdown if the user can't change its value and no teaching method is set, or if there are no teaching
  // methods to choose from at all
  const hideDropdown =
    (isDropdownDisabled && !store.selectedPeriod?.methodId) || store.teachingMethodDropDownItems.length === 0;

  const formatedTimeRangeOfBlock = store.selectedPeriod
    ? `${formatTime(store.selectedPeriod.start)} - ${formatTime(store.selectedPeriod.end)}`
    : '';

  const getFormattedBlockRange = (blockRange: ClassRegDateTimeRangeDto): string => {
    const start: Dayjs = dayjs(blockRange.start);
    const end: Dayjs = dayjs(blockRange.end);

    if (start.isSame(end, 'date')) {
      return `${formatTime(start)} - ${formatTime(end)}`;
    }

    return `${formatDate(start)} - ${formatDate(end)}`;
  };

  return (
    <>
      <SidebarContent>
        <div className="teaching-content-sidebar">
          <div className="scroll-container">
            {isPeriodsLoading ? (
              <Skeleton />
            ) : !store.selectedPeriod ? (
              <div className="empty">{t('general.noData')}</div>
            ) : (
              <div className="period-container">
                <div className="top-row">
                  <div className="date-time-container">
                    <span className="date">{store.selectedPeriod.start.format('MMM DD YYYY')}</span>
                    <span className="time">{formatedTimeRangeOfBlock}</span>
                  </div>
                  <div className="arrow-button-container">
                    <IconButton
                      type="arrow-left"
                      ariaLabel={t('general.previousPeriod')}
                      onClick={store.goBack}
                      disabled={!store.isBackAllowed}
                    />
                    <IconButton
                      type="arrow-right"
                      ariaLabel={t('general.nextPeriod')}
                      onClick={store.goNext}
                      disabled={!store.isNextAllowed}
                    />
                  </div>
                </div>
                <div className="untis-card-container">
                  <UntisCard
                    title={store.selectedPeriod.subject || '-'}
                    content={
                      <>
                        {store.selectedPeriod.classes}
                        <br />
                        {store.selectedPeriod.teachers}
                        <br />
                        {store.selectedPeriod.rooms}
                      </>
                    }
                    labelColor={store.selectedPeriod.backColor}
                    isFullWidth
                  />
                </div>
              </div>
            )}
            <div className="teaching-content-container">
              <div className="top-row">
                <label className="section-label">{t('general.teachingContent')}</label>
                {!hideDropdown && (
                  <SingleDropDown
                    items={store.teachingMethodDropDownItems}
                    disabled={isDropdownDisabled}
                    allowClear
                    value={!!store.selectedPeriod?.methodId ? store.selectedPeriod.methodId.toString() : undefined}
                    onChange={(value) => {
                      store.selectTeachingMethod(value !== undefined ? parseInt(value) : undefined);
                    }}
                    placeholder={`${t('general.teachingMethod')}...`}
                  />
                )}
              </div>
              <AntdInput.TextArea
                placeholder={t('general.enterTeachingContentHere')}
                disabled={!store.selectedPeriod || !store.isSaveAllowed}
                value={store.selectedPeriod?.text}
                onChange={(e) => store.setTopicText(e.target.value)}
              />
              <OneDriveAttachments
                readOnly={!store.isSaveAllowed || !store.isOneDriveAllowed}
                value={(store.selectedPeriod && store.selectedPeriod.attachments) || []}
                onChange={store.changeAttachments}
              />
            </div>
            <div className="previous-periods">
              <div className="top-row">
                <label className="section-label">{t('general.previousPeriods')}</label>
                <IconButton
                  type="arrow-left"
                  ariaLabel={t('general.previousPeriod')}
                  disabled={store.isPrevPeriodsLoading || store.isBeginningReached}
                  onClick={store.prevPeriodsBack}
                />
                <IconButton
                  type="arrow-right"
                  ariaLabel={t('general.nextPeriod')}
                  disabled={store.isPrevPeriodsLoading || store.prevPeriodsPointerIndex === 0}
                  onClick={store.prevPeriodsForward}
                />
              </div>
              {isPrevPeriodsLoadingDelayed ? (
                <Skeleton />
              ) : (
                prevPeriodRowsPerScreen.map((period, i) => {
                  return (
                    <>
                      <ExpandableTextContainer
                        title={period.day}
                        date={period.date}
                        text={period.teachingContent}
                        iconButtons={[
                          { type: 'edit', ariaLabel: 'edit', onClick: () => store.selectPrevPeriod(period.id) },
                          { type: 'copy', ariaLabel: 'copy', onClick: () => store.mergeTopics(period) },
                        ]}
                        attachments={{
                          value: period.attachments,
                          readOnly: true,
                          onChange: () => null,
                          ...getAttachmentProps(),
                        }}
                        key={period.id}
                      />
                      {i + 1 < prevPeriodRowsPerScreen.length && <div className="expandable-textarea-separator" />}
                    </>
                  );
                })
              )}
            </div>
          </div>
          {store.selectedPeriod?.blockRange && (
            <>
              <div className="separator" />
              <div className="save-block">
                <label>
                  {t('general.saveForBlockX', {
                    block: getFormattedBlockRange(store.selectedPeriod.blockRange),
                  })}
                </label>
                <SwitchComponent onClick={store.setSaveForBlock} checked={store.saveForBLock} />
              </div>
            </>
          )}
        </div>
      </SidebarContent>
      <SidebarToolbar buttons={toolbarButtons} />
    </>
  );
});
