import { RadioChangeEvent } from 'antd/es/radio';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { Fragment, Ref } from 'react';
import { Divider } from 'antd';
import { TooltipProps } from 'antd/es/tooltip';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { ComplexCheckboxGroup } from '@/ui-components/complex-checkbox/complex-checkbox-group';
import { ComplexRadio, ComplexRadioGroup, Skeleton, DeprecatedTagType } from '@/ui-components';
import { ITestComponentProps } from '@/types/test-component-props';
import { ComplexCheckbox } from '@/ui-components/complex-checkbox/complex-checkbox';
import SelectOptionListItem from '@/ui-components/select-option-list/select-option-list-item/select-option-list-item';

import './select-option-list.less';

export interface ISelectOptionTag {
  name: string;
  value?: string;
  active?: boolean;
  type?: DeprecatedTagType;
  tooltip?: TooltipProps | undefined;
}

interface IOptionButtonProps extends ITestComponentProps {
  disabled?: boolean;
  onClick?: (e: React.BaseSyntheticEvent) => void;
}

export interface ISelectOptionListItem {
  id: number | string;
  name: string;
  className?: string;
  value?: any; // if there is no value the item will be displayed but not be selectable
  description?: string; // textual description
  descriptionComponent?: React.ReactElement; // component that will be displayed next to the name
  content?: React.ReactElement; // content which is shown below the option item
  avatarSrc?: string;
  icon?: string;
  circleIcon?: boolean; // should the icon be white and inside a grey circle?
  greyedOut?: boolean; // should the item be greyed out (for example if the teacher is not available)
  tags?: ISelectOptionTag[];
  hint?: any; // component that will be displayed on the right side of the item row
  htmlTitle?: string; // html title attribute which will be shown when hovering over the item
  onKeyUp?: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  focusableRef?: Ref<HTMLLIElement>;
  tabIndex?: number;
  onChange?: (e: RadioChangeEvent) => void;
  onClick?: () => void;
  iconButton?: {
    icon: string;
  } & IOptionButtonProps;
  actionButton?: {
    text: string;
  } & IOptionButtonProps;
  visible?: boolean;
  noAvatar?: boolean;
  filterObject?: any;
}

interface ISingleProps {
  name: string;
  options: ISelectOptionListItem[];
  value?: string | number;
  onChange?: (value: string | number) => void;
  disabled?: boolean;
}

export const SingleSelectOptionList = (props: ISingleProps) => {
  return (
    <div className="select-option-list">
      <ComplexRadioGroup name={props.name} onChangeValue={props.onChange} disabled={props.disabled} value={props.value}>
        {props.options.map((option) => {
          const display = typeof option.visible === 'undefined' ? 'block' : option.visible ? 'block' : 'none';

          const itemContent =
            option.value !== undefined ? (
              <ComplexRadio
                testId={'radio-' + option.id}
                value={option.value}
                onKeyUp={option.onKeyUp}
                onKeyDown={option.onKeyDown}
              >
                <SelectOptionListItem focusableRef={option.focusableRef} item={option} isSingleSelect={true} />
              </ComplexRadio>
            ) : (
              <SelectOptionListItem
                item={option}
                isSingleSelect={true}
                focusableRef={option.focusableRef}
                onKeyUp={option.onKeyUp}
                onKeyDown={option.onKeyDown}
              />
            );

          return (
            <div className="item-wrapper" key={option.id} style={{ display }}>
              {itemContent}
            </div>
          );
        })}
      </ComplexRadioGroup>
    </div>
  );
};

interface IMultiProps {
  name: string;
  options: ISelectOptionListItem[];
  value?: (string | number)[];
  onChange?: (checkedValue: Array<CheckboxValueType>) => void;
  disabled?: boolean;
  onSelectAll?: (checked: boolean) => void;
}

export const MultiSelectOptionList = (props: IMultiProps) => {
  const { t } = useTranslation();
  const { options, value } = props;

  const allSelected = React.useMemo(() => {
    const selectedOptions = [];
    options.forEach((option) => {
      if (value?.includes(option.value)) {
        selectedOptions.push(option);
      }
    });
    return selectedOptions.length === options.length;
  }, [options, value]);

  return (
    <div className="select-option-list">
      {!!props.options.length && !!props.onSelectAll && (
        <div className="item-wrapper">
          <ComplexCheckbox
            onChange={(e) => props?.onSelectAll?.(!e.target.value)}
            value={allSelected}
            checked={allSelected}
            disabled={props.disabled}
          >
            <div className="select-all">{t('general.selectAll')}</div>
          </ComplexCheckbox>
        </div>
      )}
      <ComplexCheckboxGroup value={props.value} name={props.name} onChange={props.onChange} disabled={props.disabled}>
        {props.options.map((option) => {
          // decide if an option is visible or not, if it is undefined it is visible by default
          // otherwise check the boolean value
          const display = typeof option.visible === 'undefined' ? 'block' : option.visible ? 'block' : 'none';

          if (option.value !== undefined) {
            return (
              <div className="item-wrapper" key={option.id} style={{ display }}>
                <ComplexCheckbox testId={'checkbox-' + option.id} onChange={option.onChange} value={option.value}>
                  <SelectOptionListItem item={option} isSingleSelect={false} />
                </ComplexCheckbox>
              </div>
            );
          } else {
            return (
              <div className="item-wrapper" key={option.id} style={{ display }}>
                <SelectOptionListItem item={option} isSingleSelect={false} />
              </div>
            );
          }
        })}
      </ComplexCheckboxGroup>
    </div>
  );
};

interface IMultiSelectOptionListSkeletonProps {
  amount?: number;
}
export const MultiSelectOptionListSkeleton = ({ amount }: IMultiSelectOptionListSkeletonProps = { amount: 1 }) => {
  return (
    <>
      {Array.from(Array(amount), (_, index) => {
        return (
          <Fragment key={v4()}>
            <div className="msol-skeleton">
              <Skeleton.Avatar active size="small" shape="circle" className="msol-skeleton--checkbox" />
              <div>
                <Skeleton.Input active size="small" className="msol-skeleton--input" />
              </div>
              <Skeleton.Avatar active size="default" shape="circle" className="msol-skeleton--count" />
            </div>
            {index !== amount! - 1 && <Divider key={`divider-${index}`} style={{ margin: '16px 0' }} />}
          </Fragment>
        );
      })}
    </>
  );
};
