import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { ListView } from '@/components/list-view/list-view';
import {
  DateRangeOptionEnum,
  PageDatePickerMode,
} from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import SchoolYearStore from '@/stores/schoolyear-store';
import { ExamStatisticsStore, IExamStatisticsRow } from '@ls/exams-statistics/exams-statistics-store';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import './exams-statistics.less';
import { formatDateForServerRequest } from '@/utils/date/date-util';

const ExamStatistics = observer(() => {
  const [examStatisticsStore] = useState(() => new ExamStatisticsStore());
  const modalStore = useStore(ModalStore);
  const schoolYearStore = useStore(SchoolYearStore);

  const { t } = useTranslation();

  const { currentSchoolYear } = schoolYearStore;

  useComponentDidMount(() => {
    examStatisticsStore.init();
  });

  useEffect(() => {
    examStatisticsStore.getExamListFilters(
      formatDateForServerRequest(examStatisticsStore.selectedDateRange.startDate),
      formatDateForServerRequest(examStatisticsStore.selectedDateRange.endDate),
    );
  }, [examStatisticsStore.selectedDateRange]);

  useEffect(() => {
    examStatisticsStore.getExamStatistics();
  }, [examStatisticsStore.selectedDateRange, examStatisticsStore.selectedOptions]);

  return (
    <ListView<IExamStatisticsRow>
      title={t('lessons.examsStatistics.title')}
      isPageLoading={examStatisticsStore.isDataLoading}
      pageDatePickerProps={{
        mode: PageDatePickerMode.DATE_RANGE,
        onDateRangeChange: (value) => examStatisticsStore.setSelectedDateRange(value),
        onCustomClick: () => {
          modalStore
            .openModalDateRangePicker(
              examStatisticsStore.selectedDateRange.startDate,
              examStatisticsStore.selectedDateRange.endDate,
              undefined,
              examStatisticsStore.disabledDate,
            )
            .then((result) => {
              if (result.value) {
                examStatisticsStore.setSelectedDateRange({
                  option: DateRangeOptionEnum.USER_SELECTION_DATE_RANGE,
                  startDate: result.value.startDate,
                  endDate: result.value.endDate,
                });
              }
            });
        },
        options: [
          DateRangeOptionEnum.DATE_RANGE_TODAY,
          DateRangeOptionEnum.DATE_RANGE_WEEK,
          DateRangeOptionEnum.DATE_RANGE_NEXT_WEEK,
          DateRangeOptionEnum.DATE_RANGE_MONTH,
          DateRangeOptionEnum.DATE_RANGE_SCHOOLYEAR,
          DateRangeOptionEnum.CUSTOM_DATE_RANGE,
          DateRangeOptionEnum.USER_SELECTION_DATE_RANGE,
        ],
        disabledOptions: examStatisticsStore.disabledOptions(dayjs()),
        value: examStatisticsStore.selectedDateRange,
        currentSchoolyear: {
          startDate: dayjs(currentSchoolYear?.dateRange?.start),
          endDate: dayjs(currentSchoolYear?.dateRange?.end),
        },
        customDateRanges: [
          {
            startDate: dayjs(),
            endDate: dayjs(currentSchoolYear?.dateRange?.end),
            name: t('general.toEndOfSchoolyear'),
          },
        ],
        disabledCustomDateRanges: examStatisticsStore.disabledCustomDateRanges(dayjs()),
      }}
      searchBar={{
        selectedFreeTextOptions: examStatisticsStore.selectedFreeTextOptions,
        onChangeFreeTextOptions: (values) => examStatisticsStore.setSelectedFreeTextSearchOptions(values),
        options: examStatisticsStore.options,
        selectedOptions: examStatisticsStore.selectedOptions,
        onChangeSelectOptions: (options) => examStatisticsStore.setSelectedOptions([...options]),
        onlyOneOptionPerCategory: true,
        placeholder: t('general.search'),
      }}
      table={{
        columns: examStatisticsStore.columns,
        rowData: examStatisticsStore.rowData,
        shadow: true,
        className: 'exam-statistics',
      }}
      actions={examStatisticsStore.globalActions}
    />
  );
});

export default ExamStatistics;
