import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { t } from 'i18next';

import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import ModalStore from '@/stores/modal-store';
import useStore from '@/hooks/useStore';
import { StudentDutiesStore } from '@md/student-duties/student-duties-store';
import { StudentDutiesForm } from '@md/student-duties/student-duties-form';
import RouterStore from '@/stores/router-store';

export const StudentDutiesNew = () => {
  const modalStore = useStore(ModalStore);
  const routerStore = useStore(RouterStore);
  const [redirect, setRedirect] = useState(false);
  const store = useStore(StudentDutiesStore);

  useComponentDidMount(async () => {
    await modalStore.openModalDialog({
      children: <StudentDutiesForm store={store} />,
      title: t('general.newStudentDuty'),
      size: 'md',
      onAfterClose: () => routerStore.redirect('/services'),
      containsForm: true,
    });
    setRedirect(true);
  });

  if (redirect) {
    return <Redirect to="/services" />;
  }

  return null;
};
