// middle-age-frontend: ui2020RoutesBuilder.ts

import { ElementType } from '@/stores/rights-store';

/**
 * UI 2020 routes builder to support especially the anchored period details links in (PoDS) timetables.
 * !!!Routes must be in sync with the routes in the new 2020 UI!!!
 * Check the legacy-routes-map.ts and modal-routes-definitions.ts in the new frontend when doing some changes here!
 */
const TT_DATE_PARAM = '/:startDate';
const TT_ELEM_DATE_PARAMS = '/:elementId' + TT_DATE_PARAM;
const TT_PATHS_MAP: { [view in TimetableViewType]: { [typeId: number]: string } } = {
  my: {
    [Number(ElementType.CLASS)]: '/timetable-classes-my' + TT_DATE_PARAM,
    [Number(ElementType.TEACHER)]: '/timetable-teachers-my' + TT_DATE_PARAM,
    [Number(ElementType.STUDENT)]: '/timetable-students-my' + TT_DATE_PARAM,
  },
  myParent: {
    [Number(ElementType.CLASS)]: '/timetable-classes-parent' + TT_ELEM_DATE_PARAMS,
    [Number(ElementType.STUDENT)]: '/timetable-students-parent' + TT_ELEM_DATE_PARAMS,
  },
  // other TT views (e.g. /timetable-daily-overview-teachers) not supported so far - no wrapping <a> will be generated
  default: {
    [Number(ElementType.CLASS)]: '/timetable-classes' + TT_ELEM_DATE_PARAMS,
    [Number(ElementType.TEACHER)]: '/timetable-teachers' + TT_ELEM_DATE_PARAMS,
    [Number(ElementType.SUBJECT)]: '/timetable-subjects' + TT_ELEM_DATE_PARAMS,
    [Number(ElementType.ROOM)]: '/timetable-rooms' + TT_ELEM_DATE_PARAMS + '/:f__buildingId?/:f__roomGroupId?',
    [Number(ElementType.STUDENT)]: '/timetable-students' + TT_ELEM_DATE_PARAMS + '/:f__klasseOrStudentgroupId?',
    [Number(ElementType.APPRENTICE_REPRESENTATIVE)]:
      '/timetable-students' + TT_ELEM_DATE_PARAMS + '/:f__klasseOrStudentgroupId?',
    [Number(ElementType.LEGAL_GUARDIAN)]: '/timetable-students' + TT_ELEM_DATE_PARAMS + '/:f__klasseOrStudentgroupId?',
    [Number(ElementType.RESOURCE)]: '/timetable-resources' + TT_ELEM_DATE_PARAMS + '/:f__restypeId?',
  },
};
const PERIOD_DETAILS_SUB_PATH =
  '/modal/details/:initialSelectedPeriodId/:initialIsBlockSelected/:elementId/:elementType' +
  '/:startDateTime/:endDateTime/:tab';
const SINGLE_PERIOD_DETAILS_SUB_PATH = '/modal/spdetails/:periodId/:tab';

// fallback parameter value for the missing optional parameters so the number of parameters is the same
// and so the appended modal route and base route params do not interfere
const UNKNOWN_ROUTE_PARAM_VAL = '__';

// oidc backend auto-redirection supported only so far
export type WuLoginType = 'oidc';

export type TimetableViewType = 'my' | 'myParent' | 'default';

export type TabType = 'class-registry' | 'class-register' | 'details';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface Ui2020RoutesBuilderInitProps {
  globalPathParams?: { [p: string]: string };
  globalSearchParams?: IPeriodDetailsSearchParams;
  timetableView: TimetableViewType;
}

interface ITimetableParams {
  startDate: string;
  elementType: number;
  elementId: number;
}

interface ISinglePeriodDetailsHrefParams extends ITimetableParams {
  periodId: number;
  tab: TabType;
}

interface IPeriodDetailsHrefParams extends ITimetableParams {
  initialSelectedPeriodId: number;
  initialIsBlockSelected: string;
  startDateTime: string;
  endDateTime: string;
  tab: TabType;
}

interface IPeriodDetailsSearchParams {
  wu_login?: WuLoginType;
  school?: string;
}

class LessonsRoutesBuilder {
  private globalPathParams: { [p: string]: string } = {};
  private globalSearchParams: IPeriodDetailsSearchParams = {};
  private timetableView: TimetableViewType | null = null;

  /**
   * (Re)initializes this singleton instance with particular global settings to be used later then when building URLs
   * in particular child component.
   */
  init = ({ globalPathParams, globalSearchParams, timetableView }: Ui2020RoutesBuilderInitProps) => {
    this.globalPathParams = globalPathParams || {};
    this.globalSearchParams = globalSearchParams || {};
    this.timetableView = timetableView;
  };

  /**
   * Builds period details path
   * (e.g. /timetable-students-my/2021-01-18/modal/details/17636/false/3/5/2021-01-18T08%3A00%3A00%2B01%3A00
   * /2021-01-18T08%3A45%3A00%2B01%3A00/details)
   * intended for deep link new frontend period details navigation.
   * @param pathParams all the required parameters for dynamic route
   */
  buildPeriodDetailsHref = (pathParams: IPeriodDetailsHrefParams): string | null => {
    const timetablePath = this.buildTimetablePath(pathParams);
    const periodDetailsPath = this.buildPath(PERIOD_DETAILS_SUB_PATH, pathParams);

    if (periodDetailsPath === null || timetablePath === null) {
      return null;
    }

    return this.buildHref(timetablePath, periodDetailsPath);
  };

  /**
   * Builds single period details path (e.g. /timetable-students-my/2021-01-18/modal/spdetails/17636/details)
   * intended for deep link frontend single period details navigation.
   * @param pathParams all the required parameters for dynamic route
   */
  buildSinglePeriodDetailsHref = (pathParams: ISinglePeriodDetailsHrefParams): string | null => {
    const timetablePath = this.buildTimetablePath(pathParams);
    const singlePeriodDetailsPath = this.buildPath(SINGLE_PERIOD_DETAILS_SUB_PATH, pathParams);

    if (singlePeriodDetailsPath === null || timetablePath === null) {
      return null;
    }

    return this.buildHref(timetablePath, singlePeriodDetailsPath);
  };

  /**
   * Resets the global settings (when component is unmounting)
   * so they are not applied later then when building urls in another components.
   */
  reset = () => {
    this.globalPathParams = {};
    this.globalSearchParams = {};
    this.timetableView = null;
  };

  private isValid = (param: string) => {
    return param !== undefined && param !== null && param !== '';
  };

  // replaces the parameters (started with colon in given path) by the particular pathParams
  private buildPath = (path: string, pathParams: any): string | null => {
    if (!path || path.charAt(0) !== '/' || !pathParams) {
      return null;
    }

    const finalPath = path
      .split('/')
      .map((block) => {
        // block example: :startDateTime

        if (!block.startsWith(':')) {
          return block;
        }

        // startDateTime
        let param = block.substring(1);
        const isOptional = param.charAt(param.length - 1) === '?';

        // replace the ? if it exists
        param = isOptional ? param.substring(0, param.length - 1) : param;

        if (this.isValid(pathParams[param])) {
          return `${encodeURIComponent(pathParams[param])}`;
        }

        if (this.isValid(this.globalPathParams[param])) {
          return `${encodeURIComponent(this.globalPathParams[param])}`;
        }

        if (isOptional) {
          return `${UNKNOWN_ROUTE_PARAM_VAL}`;
        }

        return block;
      })
      .filter((path) => path !== null)
      .join('/');

    // if there are still : in the path than a substitution of the field failed
    // and it is not a valid path
    if (finalPath.includes(':')) {
      return null;
    }

    return finalPath;
  };

  private buildSearchString = (searchParams?: IPeriodDetailsSearchParams): string => {
    if (!searchParams) return '';

    const finalSearchParams = new URLSearchParams();
    Object.entries(searchParams).forEach(([key, value]) => {
      finalSearchParams.append(key, value);
    });
    const searchString = finalSearchParams.toString();

    return searchString ? `?${searchString}` : '';
  };

  buildHref = (...paths: string[]): string => {
    return paths.join('') + this.buildSearchString(this.globalSearchParams);
  };

  /**
   * Builds the base (parent) path name for the timetable.
   * Based on this base path name the particular timetable view (class, student,etc.) is then opened in wu-frontend
   */
  private buildTimetablePath = (pathParams: ITimetableParams): string | null => {
    if (!this.timetableView) return null;

    const ttPath = TT_PATHS_MAP[this.timetableView][pathParams.elementType] || '';

    return this.buildPath(ttPath, pathParams);
  };
}

export default new LessonsRoutesBuilder();
