import localeAntDe from 'antd/locale/de_DE';
import { use } from 'i18next';
import { computed } from 'mobx';
import numeral from 'numeral';
import { initReactI18next } from 'react-i18next';
import ReactMoment from 'react-moment';
import { Locale } from 'antd/lib/locale';
import dayjs from 'dayjs';

import ConfigStore from '@/stores/config-store';
import SettingsStore, { NumberSetting } from '@/stores/settings-store';
import { inject, Store } from '@/types/store';
import 'numeral/locales/de';
import 'numeral/locales/en-gb';

const DEFAULT_LOCALE = 'de';

async function updateDayjsLocale(locale: string, _firstDayOfWeek: number) {
  // TODO SP-804 most of the mappings are already correct because moment has a locale for es, tr and so on
  // TODO SP-804 but we need to check them and need to find languages which don't have a desired moment locale
  // TODO SP-804 and would need english as default

  // day js locales are imported in setup.tsx. If more locales should be supported, import accordingly
  switch (locale) {
    case 'de':
    case 'deAT':
    case 'deHS':
      locale = 'de';
      break;
    case 'en':
      locale = 'en-gb';
      break;
    default:
      locale = 'en-gb';
  }

  ReactMoment.globalLocale = locale;
  const weekdaysMin =
    locale.substring(0, 2).toLowerCase() == 'en'
      ? ['S', 'M', 'T', 'W', 'T', 'F', 'S']
      : ['S', 'M', 'D', 'M', 'D', 'F', 'S'];
  dayjs.locale(locale);
  dayjs.updateLocale(locale, {
    weekStart: _firstDayOfWeek,
    weekdaysMin: weekdaysMin,
  });
}

async function getLocaleData(locale: string) {
  let localeData;
  try {
    localeData = await import(`@untis/wu-frontend-translations/locales/locale_${locale}.json`);
  } catch (e) {
    // we need to define an extra var here, because else the webpack importer can't find it
    const fallbackLocale = 'de';
    console.error(
      `Couldn't find the corresponding file for the locale ${locale}. Falling back to locale_${fallbackLocale}.json`,
    );
    localeData = await import(`@untis/wu-frontend-translations/locales/locale_${fallbackLocale}.json`);
  }

  return localeData;
}

@Store()
export default class LocaleStore {
  antdLocale: Locale = localeAntDe;
  private _locale: string = DEFAULT_LOCALE;

  private configStore = inject(ConfigStore);
  private settingsStore = inject(SettingsStore);

  async init() {
    let locale = DEFAULT_LOCALE;
    if (this.configStore.userLocale) {
      locale = this.configStore.userLocale;
    }

    const localeData = await getLocaleData(locale);

    const resources: any = {};
    resources[locale] = {
      translation: localeData,
    };
    use(initReactI18next).init({
      debug: false,
      lng: locale,
      resources,
      react: {
        bindI18n: 'languageChanged loaded',
        bindI18nStore: 'added removed',
        nsMode: 'default',
        useSuspense: true,
      },
      interpolation: {
        escapeValue: false,
      },
    });
    await this.setLocale(locale === 'en' ? 'en-gb' : locale);
  }

  setLocale = async (locale: string) => {
    const firstDayOfWeek = (this.settingsStore.getNumberSetting(NumberSetting.FIRST_DAY_OF_WEEK) ?? 2) - 1;
    this._locale = locale;
    this.updateNumeralsLocale();
    await this.updateAntdLocale();
    await updateDayjsLocale(this._locale, firstDayOfWeek);
  };

  toOrdinalNumber = (value: number): string => {
    return numeral(value).format('0o');
  };

  @computed
  get locale(): string {
    return this._locale;
  }

  private async updateAntdLocale() {
    let newAntLocale: any;
    switch (this._locale.substring(0, 2).toLowerCase()) {
      // TODO SP-804 create correct locale mapping here
      case 'en':
        newAntLocale = await import('antd/locale/en_GB');
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/naming-convention
        newAntLocale = localeAntDe;
        break;
    }

    this.antdLocale = newAntLocale;
  }

  private updateNumeralsLocale() {
    switch (this._locale.toLowerCase()) {
      // TODO: CAL-130 Add correct mapping for numeral locales
      case 'de':
      case 'deat':
      case 'dehs':
        numeral.locale('de');
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/naming-convention
        numeral.locale('en-gb');
        break;
    }
  }
}
