import clsx from 'clsx';
import React, { useMemo } from 'react';
import kebabCase from 'lodash/kebabCase';

import { DeprecatedDropDownItem } from '@/ui-components/deprecated-drop-down/drop-down';

type DropDownSingleItemProps = {
  item: DeprecatedDropDownItem;
  preSelectedItem?: DeprecatedDropDownItem;
  isPlaceHolder?: boolean;
  handleItemSelect: (item: DeprecatedDropDownItem) => void;
  handleItemPreSelect: (item: DeprecatedDropDownItem) => void;
  isSelected?: never;
  multiple?: never;
  testIdSuffix?: string;
  isDisabled?: boolean;
};

type DropDownMultiItemProps = {
  item: DeprecatedDropDownItem;
  preSelectedItem?: DeprecatedDropDownItem;
  isPlaceHolder?: boolean;
  handleItemSelect: (item: DeprecatedDropDownItem) => void;
  handleItemPreSelect: (item: DeprecatedDropDownItem) => void;
  isSelected?: boolean;
  multiple?: boolean;
  testIdSuffix?: string;
  isDisabled?: boolean;
};

type DropDownItemProps = DropDownSingleItemProps | DropDownMultiItemProps;

/** *
 * @deprecated in favor of Single-/MultiDropDown
 */
export function DeprecatedDropDownItemElement({
  item,
  isPlaceHolder,
  preSelectedItem,
  handleItemPreSelect,
  handleItemSelect,
  isSelected,
  multiple,
  testIdSuffix,
  isDisabled,
}: DropDownItemProps) {
  const className = clsx({
    placeholder: isPlaceHolder,
    'regular-item': !isPlaceHolder,
    preselected: preSelectedItem && preSelectedItem.key === item.key,
    'multi-item': !!multiple,
    selected: isSelected,
    'is-disabled': isDisabled,
  });

  const alias = useMemo(() => item.alias && <label className="dd-alias">{item.alias}</label>, [item.alias]);
  const testId = useMemo(() => {
    if (!testIdSuffix) {
      return `dropdown-value-${kebabCase(item.value)}`;
    }
    return `dropdown-value-${kebabCase(item.value)}-${testIdSuffix}`;
  }, [item.value, testIdSuffix]);

  return (
    <span
      className={className}
      onClick={(event) => {
        if (multiple || isDisabled) {
          event.stopPropagation();
        }
        if (!isDisabled) {
          handleItemSelect(item);
        }
      }}
      key={item.key}
      onMouseEnter={() => handleItemPreSelect(item)}
      data-testid={testId}
    >
      {multiple ? (
        <span>
          {alias}
          {item.value}
        </span>
      ) : (
        <>
          {alias}
          {item.value}
        </>
      )}
      {multiple ? isSelected && <span className="selection-mark">✓</span> : null}
    </span>
  );
}
