import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import ModalRoutes from '@/components/app/modal-routes';
import useStore from '@/hooks/useStore';
import AppStore from '@/stores/app-store';
import { MenuStore } from '@/stores/menu-store';
import ModalStore, { isDeprecatedModalDefinition } from '@/stores/modal-store';
import { DeprecatedModal } from '@/ui-components';
import { Modal } from '@/ui-components/modal/modal';

import './modal-container.less';

const ModalContainer = observer(() => {
  const modalStore = useStore(ModalStore);
  const menuStore = useStore(MenuStore);
  const appStore = useStore(AppStore);
  const [containerRefEl, setContainerRefEl] = useState<HTMLDivElement | null>();

  return (
    <div className="modal-container" ref={(refEl) => setContainerRefEl(refEl)}>
      {containerRefEl && (
        <>
          <ModalRoutes
            containerRefEl={containerRefEl}
            expanded={menuStore.expanded}
            showFocusedElements={appStore.showFocusedElements}
          />
          {modalStore.modalStack.map((modal, index) => {
            if (isDeprecatedModalDefinition(modal)) {
              return (
                <DeprecatedModal
                  {...modal.getADModalProps(menuStore.expanded, appStore.showFocusedElements)}
                  key={index}
                  getContainer={containerRefEl}
                >
                  {modal.content}
                </DeprecatedModal>
              );
            } else {
              return <Modal {...modal} key={index} getContainer={containerRefEl} />;
            }
          })}
        </>
      )}
    </div>
  );
});

export default ModalContainer;
