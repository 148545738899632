import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';

import { Card } from '@/ui-components';
import {
  getResponsivenessThresholdForWidth,
  ResponsivenessContext,
  ResponsivenessThreshold,
} from '@/context/responsiveness-context';

import './responsiveness-threshold-hint.less';

interface IProps {
  width: number;
}

const getTextForThreshold = (threshold: ResponsivenessThreshold): string => {
  switch (threshold) {
    case ResponsivenessThreshold.XS_MOBILE_DEVICES:
      return 'XS - Mobile Devices';
    case ResponsivenessThreshold.S_TABLETS:
      return 'S - Tablets';
    case ResponsivenessThreshold.M_LAPTOPS:
      return 'M - Laptops';
    case ResponsivenessThreshold.L_DESKTOP:
      return 'L - Desktop';
    case ResponsivenessThreshold.XL_TV:
      return 'XL - TV';
  }
};

const getRangeForThreshold = (threshold: ResponsivenessThreshold): string => {
  switch (threshold) {
    case ResponsivenessThreshold.XS_MOBILE_DEVICES:
      return '< 481px';
    case ResponsivenessThreshold.S_TABLETS:
      return '481px - 768px';
    case ResponsivenessThreshold.M_LAPTOPS:
      return '769px - 1024px';
    case ResponsivenessThreshold.L_DESKTOP:
      return '1025px - 1200px';
    case ResponsivenessThreshold.XL_TV:
      return '> 1200px';
  }
};

export const ResponsivenessThresholdHint = (props: IProps) => {
  const [hidden, setHidden] = useState(true);
  const responsivenessContext = useContext(ResponsivenessContext);

  useEffect(() => {
    setHidden(false);
    const timetout = setTimeout(() => {
      setHidden(true);
    }, 1000);

    return () => {
      clearTimeout(timetout);
    };
  }, [props.width]);

  const threshold = getResponsivenessThresholdForWidth(props.width);
  const text = getTextForThreshold(threshold);
  const range = getRangeForThreshold(threshold);

  const className = clsx(
    'responsiveness-threshold-hint',
    {
      hidden: hidden,
    },
    responsivenessContext?.responsiveClassName,
  );

  return (
    <Card className={className}>
      <div className="container">
        <h1>{text}</h1>
        <span className="range">{range}</span>
        <span>{props.width}px</span>
      </div>
    </Card>
  );
};
