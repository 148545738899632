import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ListView } from '@/components/list-view/list-view';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import TimetableFormatSettingsStore, {
  ITimetableFormatRow,
} from '@te/settings/timetable-format/timetable-format-settings-store';
import { TestIds } from '@/testIds';

const TimetableFormatSettings = observer(() => {
  const { t } = useTranslation();
  const [store] = useState(() => new TimetableFormatSettingsStore());

  useComponentDidMount(() => {
    store.fetchData();
  });

  return (
    <ListView<ITimetableFormatRow>
      title={t('general.timetableFormats')}
      testId={TestIds.TIMETABLE_FORMAT_SETTINGS_LIST}
      emptyIndicator={{
        show: store.timetableFormatRows.length === 0,
        description: t('general.noFormatExistsCreateANew'),
        button: {
          testId: 'new-format',
          title: t('general.new'),
          onClick: () => store.openTimetableFormatForm(undefined),
        },
      }}
      actions={store.listViewActions}
      table={{
        columns: store.columns,
        rowData: store.timetableFormatRows,
        className: 'timetable-format-settings-table',
        shadow: true,
        withoutSortedHeaderBackground: true,
      }}
      isPageLoading={store.isMetaLoading}
    />
  );
});

export default TimetableFormatSettings;
