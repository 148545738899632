import React, { useState } from 'react';
import { Dayjs } from 'dayjs';
import clsx from 'clsx';

import { Icon, OptionPopup } from '@/ui-components';
import { Option } from '@/ui-components/option-popup/option-popup';

import './calendar-popup.less';

interface IProps {
  date: Dayjs;
  options: Option[];
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
}

/**
 * Component for the Class Register Overview to jump to the Start/End of the allowed date range
 * or to a specific date. This component *might* be a candidate for ui-components in the futur, but not now.
 */
export const CalendarPopup = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const weekDay = props.date.format('dddd'); // Freitag
  const formattedDate = props.date.format('LL'); // 8. Oktober 2021

  const onOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  return (
    <OptionPopup
      onOpenChange={onOpenChange}
      options={props.options}
      onClickOption={() => onOpenChange(false)}
      getPopupContainer={props.getPopupContainer}
    >
      <div className={clsx('calendar-popup', { open: isOpen })}>
        <span>{weekDay}</span>
        <div className="separator" />
        <span>{formattedDate}</span>
        <Icon type="dropdown" />
      </div>
    </OptionPopup>
  );
};
