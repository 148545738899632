import React from 'react';

import { LessonCardIndicator, LessonIndicator } from './lesson-card-indicator';

import { LessonCardDisplayVariant } from '@/components/lesson-card/lesson-card';

import './lesson-card-indicators.less';

interface IProps {
  indicators: LessonIndicator[];
  displayVariant?: LessonCardDisplayVariant;
}

export const LessonCardIndicators = (props: IProps) => {
  const { indicators, displayVariant } = props;

  return (
    <>
      {indicators.length > 0 && (
        <div className="lesson-card-indicators" data-testid="lesson-card-indicators">
          {indicators.map(
            (indicator) =>
              indicator && (
                <LessonCardIndicator key={indicator?.type} indicator={indicator} displayVariant={displayVariant} />
              ),
          )}
        </div>
      )}
    </>
  );
};
