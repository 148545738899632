import { action, computed, observable } from 'mobx';
import { Dayjs } from 'dayjs';

import { inject, Store } from '@/types/store';
import { TimetableFilterStore } from '@te/standard/stores/filter/timetable-filter-store';
import { ITimetableViewType, TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import { formatDateForServerRequest } from '@/utils/date/date-util';
import { TimetableEntityType } from '@te/standard/stores/timetable-root-store';
import { UntisLessonDetailsModalHandler } from '@te/standard/stores/url/untis-lesson-details-modal-handler';
import {
  AbstractTimetableDetailsModalHandler,
  TimetableEntry,
} from '@te/standard/stores/url/abstract-timetable-details-modal-handler';
import { ExternalEventDetailsModalHandler } from '@te/standard/stores/url/external-event-details-modal-handler';

export const URL_SEARCH_PARAMETER_ENTITY_ID = 'entityId';
export const URL_SEARCH_PARAMETER_VIEW_TYPE = 'mode';
export const URL_SEARCH_PARAMETER_SELECTED_DATE = 'date';

export const TIMETABLE_ROUTE_MATCH_URL =
  // eslint-disable-next-line max-len
  '/timetable-new/:entityType/:subPath?/:entryId?/:timetableEntityId?/:timetableEntityTypeId?/:startDateTime?/:endDateTime?/:isBlock?';

const URL_SEARCH_PARAMETER_WHITELIST: string[] = [
  URL_SEARCH_PARAMETER_ENTITY_ID,
  URL_SEARCH_PARAMETER_VIEW_TYPE,
  URL_SEARCH_PARAMETER_SELECTED_DATE,
];

const PREVIOUS_URL_SUBTYPE_WHITELIST: TimetableUrlSubType[] = ['lessonDetails'];

export type TimetableUrlSubType = 'lessonDetails' | 'external';
type TimetableRootPath = '/timetable-new';

export type TimetableUrl = {
  entityType: TimetableEntityType;
  subPath: TimetableUrlSubType | undefined;
  entryId: string | undefined;
  timetableEntityId: string | undefined;
  timetableEntityTypeId: string | undefined;
  startDateTime: string | undefined;
  endDateTime: string | undefined;
  isBlock: string | undefined;
};

@Store()
export class TimetableUrlStore {
  private timetableFilterStore: TimetableFilterStore = inject(TimetableFilterStore);
  private timetableMetaStore: TimetableMetaStore = inject(TimetableMetaStore);
  private timetableFormatStore: TimetableFormatStore = inject(TimetableFormatStore);

  private untisLessonDetailsModalHandler = inject(UntisLessonDetailsModalHandler);
  private externalEventDetailsModalHandler = inject(ExternalEventDetailsModalHandler);
  private timetableDetailsModalUrlHandlers: AbstractTimetableDetailsModalHandler[] = [
    this.untisLessonDetailsModalHandler,
    this.externalEventDetailsModalHandler,
  ];

  @observable private _isInitialized: boolean = false;
  @observable private _timetableRootPath: TimetableRootPath = '/timetable-new';
  @observable private _timetableExtraPath: string = '';
  @observable private _previousTimetableExtraPath: string = '';
  @observable private _urlSearchParameterMap: Map<string, string> = new Map<string, string>();

  init(initialDate: Dayjs, initialViewType: ITimetableViewType, initialEntityId: number) {
    if (initialViewType) {
      this._urlSearchParameterMap.set(URL_SEARCH_PARAMETER_VIEW_TYPE, initialViewType);
    }

    if (initialDate.isValid()) {
      this._urlSearchParameterMap.set(URL_SEARCH_PARAMETER_SELECTED_DATE, formatDateForServerRequest(initialDate));
    }

    if (!isNaN(initialEntityId)) {
      this._urlSearchParameterMap.set(URL_SEARCH_PARAMETER_ENTITY_ID, initialEntityId.toString());
    }

    window.history.replaceState({}, '', this.timetableCurrentUrl);
    this._isInitialized = true;
  }

  handleTimetableUrl(timetableUrl: TimetableUrl) {
    if (this._isInitialized) {
      this.timetableDetailsModalUrlHandlers.forEach((handler) => {
        if (handler.shouldHandleTimetableUrl(timetableUrl)) {
          handler.handleTimetableUrl(timetableUrl, () => {
            this.navigateToCurrentTimetableUrl();
          });
        }
      });
    }
  }

  @action
  reset() {
    this._isInitialized = false;
    this._timetableRootPath = '/timetable-new';
    this._timetableExtraPath = '';
    this._previousTimetableExtraPath = '';
    this._urlSearchParameterMap = new Map<string, string>();
  }

  @computed
  get timetableCurrentUrl(): string {
    const { timetableEntityType } = this.timetableFormatStore;

    const urlSearchParameters = new URLSearchParams();
    this._urlSearchParameterMap.forEach((value, key) => {
      urlSearchParameters.set(key, value);
    });

    const extraPath = this._timetableExtraPath.length > 0 ? this._timetableExtraPath : '';
    const searchParameters = this._urlSearchParameterMap.size > 0 ? `?${urlSearchParameters.toString()}` : '';

    return `${this._timetableRootPath}/${timetableEntityType}${extraPath}${searchParameters}`;
  }

  @computed
  get hasModalOpen(): boolean {
    return this._timetableExtraPath.length > 0;
  }

  @computed
  get whitelistedModalClosed(): boolean {
    return (
      this._previousTimetableExtraPath.length === 0 ||
      PREVIOUS_URL_SUBTYPE_WHITELIST.some((whiteListedUrlSubtype) =>
        this._previousTimetableExtraPath.includes(whiteListedUrlSubtype),
      )
    );
  }

  @action
  addUrlParameter(urlParameterKey: string, value: string) {
    if (this._isInitialized && URL_SEARCH_PARAMETER_WHITELIST.includes(urlParameterKey)) {
      this._urlSearchParameterMap.set(urlParameterKey, value);
      this.navigateToCurrentTimetableUrl();
    }
  }

  @action
  addUrlParameters(urlParameters: Map<string, string>) {
    urlParameters.forEach((value, key) => {
      if (this._isInitialized && URL_SEARCH_PARAMETER_WHITELIST.includes(key)) {
        this._urlSearchParameterMap.set(key, value);
      }
    });
    this.navigateToCurrentTimetableUrl();
  }

  @action
  removeUrlParameter(urlParameterKey: string) {
    if (this._isInitialized && URL_SEARCH_PARAMETER_WHITELIST.includes(urlParameterKey)) {
      this._urlSearchParameterMap.delete(urlParameterKey);
      this.navigateToCurrentTimetableUrl();
    }
  }

  @action
  navigateToPeriodDetails(timetableEntry: TimetableEntry) {
    if (this._isInitialized) {
      this.timetableDetailsModalUrlHandlers.forEach((handler) => {
        if (handler.shouldHandleTimetableEntry(timetableEntry)) {
          const { currentTimetableStartDate } = this.timetableMetaStore;
          const elementId = this.timetableFilterStore.selectedFilterValue ?? -1;

          this._urlSearchParameterMap.set(URL_SEARCH_PARAMETER_ENTITY_ID, elementId.toString());
          this._urlSearchParameterMap.set(
            URL_SEARCH_PARAMETER_SELECTED_DATE,
            formatDateForServerRequest(currentTimetableStartDate),
          );

          handler.openDetailsModal(timetableEntry, () => {
            this._timetableExtraPath = '';
            this.navigateToCurrentTimetableUrl();
          });
          this._timetableExtraPath = handler.getTimetableExtraPath(timetableEntry);
          this.navigateToCurrentTimetableUrl();
        }
      });
    }
  }

  @action
  private navigateToCurrentTimetableUrl() {
    this._previousTimetableExtraPath = this._timetableExtraPath;
    window.history.pushState({}, '', this.timetableCurrentUrl);
  }

  @computed
  get urlSearchParameters(): Map<string, string> {
    return this._urlSearchParameterMap;
  }
}
