import React, { useState, useEffect, useRef, CSSProperties } from 'react';

import { IButtonProps, Button } from '../button/button';

export interface IAsyncButtonProps extends IButtonProps {
  onClick: (e?: any) => Promise<any>;
  style?: CSSProperties;
}

/**
 * The AsyncButton can be used like the regular Button, but instead
 * of a regular onClick handler this Button requires an async onClick
 * handler that returns a Promise when resolved.
 * A loading spinner indicates if the handler has already resolved
 * the Promise or not. Also the button is disabled until the handler
 * has resolved the Promise.
 */
export const AsyncButton = (props: IAsyncButtonProps) => {
  const [inProgress, setInProgress] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const inProgressRef = useRef(inProgress);
  inProgressRef.current = inProgress;
  const buttonRef = useRef(null);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    // only schedule the timer if inProgress changed to 'true'
    if (inProgress) {
      timer = setTimeout(() => {
        // only execute the callback if the current value
        // of inProgress is 'true'
        if (inProgressRef.current) {
          setShowLoading(true);
        }
      }, 100);
    }

    // cancel the timer (if it exists)
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [inProgress]);

  const onClick = async (e?: any) => {
    setInProgress(true);
    await props.onClick(e);

    if (buttonRef.current) {
      // we only update the state if the component is still mounted
      setInProgress(false);
      setShowLoading(false);
    }
  };

  return (
    <Button {...props} onClick={onClick} loading={showLoading} disabled={props.disabled || inProgress} ref={buttonRef}>
      {props.children}
    </Button>
  );
};

export default AsyncButton;
