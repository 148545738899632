import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button, Page, Skeleton, Tooltip, WUTable } from '@/ui-components';
import { ITableRowKey, IWUTableProps } from '@/ui-components/wu-table/wu-table';
import { ContextActions } from '@/stories/ui-components/context-actions/context-actions';
import { PageDatePickerProps } from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { CustomOrderDto } from '@untis/wu-rest-view-api/api';
import { ButtonType } from '@/ui-components/button/button';
import { ListViewStore } from '@/components/list-view/list-view-store';
import { ITestComponentProps } from '@/types/test-component-props';
import EmptyIndicator, { IEmptyIndicatorProps } from '@/components/empty-indicator/empty-indicator';
import { ISearchBarProps } from '@/ui-components/search-bar/search-bar';
import { IDeprecatedSearchBarProps } from '@/ui-components/deprecated-search-bar/deprecated-search-bar';
import './list-view.less';

export interface IListViewAction extends ITestComponentProps {
  label: string;
  onClick?: () => void;
  type?: ButtonType;
  outline?: boolean;
  disabled?: boolean;
  tooltip?: string;
  href?: string;
}

export interface IListViewSelectedRowsAction<T> {
  label: string;
  onClick?: (rows: T[]) => void;
  icon?: string;
}

interface IProps<T> extends ITestComponentProps {
  title?: string;
  counterLabel?: string;
  counterLabelTooltip?: string;
  deprecatedSearchBar?: IDeprecatedSearchBarProps;
  searchBar?: ISearchBarProps;
  table?: IWUTableProps<T>;
  actions?: IListViewAction[];
  selectedRowsActions?: IListViewSelectedRowsAction<T>[];
  pageDatePickerProps?: PageDatePickerProps;
  isPageLoading?: boolean;
  isPageContentLoading?: boolean;
  customSorting?: {
    onSave: (order: CustomOrderDto[]) => void;
    onDelete: () => void;
  };
  usePagination?: boolean;
  compact?: boolean;
  emptyIndicator?: { show: boolean } & Pick<IEmptyIndicatorProps, 'description' | 'icon' | 'button'>;
  selectedRowsFormFields?: React.ReactNode;
  displayPageHeaderTitle?: boolean; // when false it hides title of Page component that ListView is using
  displayHeader?: boolean; // when false it hides Listview header
}

export function ListView<T extends ITableRowKey>(props: IProps<T>) {
  const selectedRowKeys = props.table?.rowSelection?.selectedRowKeys;
  const selectedRows: T[] | undefined = props.table?.rowData.filter((row) => selectedRowKeys?.includes(row.key));
  const [showSortingColumn, setShowSortingColumn] = useState(false);
  const [store] = useState<ListViewStore<T>>(() => new ListViewStore<T>());
  const { t } = useTranslation();

  useEffect(() => {
    store.resetCustomSorting(props.table?.rowData);
  }, [props.table?.rowData]);

  const tableColumns = props.table?.columns;
  const sortingAction: IListViewAction = {
    label: t('general.sort'),
    onClick: () => {
      setShowSortingColumn(true);
    },
    testId: 'sort-button',
  };

  const sortingActions: IListViewAction[] = [
    {
      label: t('general.cancel'),
      onClick: () => {
        setShowSortingColumn(false);
      },
      testId: 'cancel-custom-sorting-button',
    },
    {
      label: t('general.resetSorting'),
      onClick: () => {
        props.customSorting && props.customSorting?.onDelete();
        setShowSortingColumn(false);
      },
      testId: 'reset-custom-sorting-button',
    },
    {
      label: t('general.saveSorting'),
      type: 'primary',
      outline: false,
      onClick: () => {
        props.customSorting && props.customSorting?.onSave(store.customSorting);
        setShowSortingColumn(false);
      },
      testId: 'save-custom-sorting-button',
    },
  ];

  const globalActions: IListViewAction[] | undefined = props.customSorting
    ? [...(props.actions ? props.actions : []), sortingAction]
    : props.actions;

  const renderCounterLabel = useMemo(() => {
    const label = props.counterLabel
      ? `${props.table?.rowData.length}  ${props.counterLabel}`
      : props.table?.rowData.length;

    return props.counterLabelTooltip ? (
      <Tooltip title={props.counterLabelTooltip} placement="right">
        <p className="counter">{label}</p>
      </Tooltip>
    ) : (
      <p className="counter">{label}</p>
    );
  }, [props.counterLabel, props.counterLabelTooltip, props.table]);

  const columns: Columns<T> | undefined = showSortingColumn
    ? [
        ...(tableColumns ? tableColumns : []),
        {
          type: ColumnType.Text,
          header: 'Position',
          key: 'sorting-position',
          width: '50px',
          edit: {
            defaultValue: '',
            getValue: (row: T) => {
              const sorting = store.customSorting.find((sorting) => sorting.id.toString() == row.key.toString());
              if (sorting && sorting.orderNo) {
                return sorting.orderNo.toString();
              }
              return '0';
            },
            onChange: (row, key, value, setVal) => {
              const sortingIndex = store.customSorting.findIndex(
                (sorting) => sorting.id.toString() == row.key.toString(),
              );
              const newSorting = [...store.customSorting];
              if (sortingIndex >= 0) {
                newSorting[sortingIndex] = {
                  id: Number(row.key),
                  orderNo: Number(value),
                };
                store.setCustomSorting(newSorting);
                setVal(value.toString());
              }
            },
          },
        },
      ]
    : tableColumns;

  const hasContextActions = props.selectedRowsActions && props.selectedRowsActions.length > 0;
  const isSelectedRowsFormFieldsActive = (selectedRowKeys?.length || 0) > 1;
  const hasRowSelection = !!props.table?.rowSelection && hasContextActions;
  const listViewClassName = clsx('list-view', {
    'with-row-selection': hasRowSelection,
    'with-rows-selected': selectedRows && selectedRows?.length >= 1,
  });
  let wuTableClassName = 'list-view--wu-table';
  if (props.table?.className) {
    wuTableClassName += ` ${props.table.className}`;
  }

  return useObserver(() => (
    <Page
      className="list-view-page"
      deprecatedSearchBar={props.deprecatedSearchBar}
      searchBar={props.searchBar}
      pageDatePickerProps={props.pageDatePickerProps}
      scrollMode="scrollContent"
      isPageLoading={props.isPageLoading}
      isPageContentLoading={props.isPageContentLoading}
      loadingIndicator={{
        title: t('general.loading'),
        fullScreen: true,
        card: (
          <div style={{ width: 600 }}>
            <Skeleton active={true} loading={true} />
          </div>
        ),
      }}
      testId={props.testId}
      compact={props.compact}
      displayTitle={props.displayPageHeaderTitle}
    >
      {props.emptyIndicator?.show && (
        <div className="empty-container">
          <EmptyIndicator {...props.emptyIndicator} />
        </div>
      )}
      {!props.emptyIndicator?.show && (
        <div className={listViewClassName}>
          {props.displayHeader && (
            <div className="table-meta-header">
              <div className="table-title-container">
                <h2 className="table-title">{props.title}</h2>
                {renderCounterLabel}
              </div>
              {globalActions && (
                <div className="global-actions">
                  {props.customSorting && showSortingColumn ? (
                    <div className="custom-sorting">
                      {sortingActions.map((action, index) => {
                        return (
                          <Button
                            key={index}
                            onClick={action.onClick}
                            outline={action.outline !== false}
                            type={action.type}
                            testId={action.testId}
                          >
                            {action.label}
                          </Button>
                        );
                      })}
                    </div>
                  ) : (
                    globalActions.map((action, index) => {
                      const buttonProps = {
                        key: index,
                        onClick: action.onClick,
                        outline: action.outline !== false,
                        type: action.type,
                        testId: action.testId,
                        disabled: action.disabled,
                        href: action.href,
                      };

                      return action.tooltip ? (
                        <Tooltip title={action.tooltip} placement="left">
                          {/* Because of a bug from ant design, in order to display the tooltip for a disabled button,
                        we have to wrap the button with a div and disable the pointer events for the button*/}
                          <div>
                            <Button {...buttonProps} style={{ pointerEvents: 'none' }}>
                              {action.label}
                            </Button>
                          </div>
                        </Tooltip>
                      ) : (
                        <Button {...buttonProps}>{action.label}</Button>
                      );
                    })
                  )}
                </div>
              )}
            </div>
          )}
          {props.table && columns && (
            <div className="table-container">
              <WUTable<T>
                {...props.table}
                columns={columns}
                className={wuTableClassName}
                rowSelection={
                  hasRowSelection
                    ? {
                        ...props.table.rowSelection,
                      }
                    : undefined
                }
                pagination={
                  props.usePagination && store.pageSize && store.pageSize < props.table.rowData.length
                    ? store.pageSize
                    : undefined
                }
              />
            </div>
          )}
        </div>
      )}
      {props.selectedRowsActions && selectedRows && (
        <div className="context-action-container">
          {props.selectedRowsFormFields ? (
            <div
              className={clsx(
                'floating__global__rows__fields',
                isSelectedRowsFormFieldsActive && 'global__actions--active',
              )}
            >
              {props.selectedRowsFormFields}
            </div>
          ) : (
            <ContextActions actions={props.selectedRowsActions} rows={selectedRows} />
          )}
        </div>
      )}
    </Page>
  ));
}

ListView.defaultProps = {
  displayHeader: true,
};
