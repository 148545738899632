import { action, computed, observable, reaction } from 'mobx';
import { t } from 'i18next';
import { AxiosError } from 'axios';

import { IErrorResponseData } from '@/utils/error-handling/error-handling';
import { TimetableUrl, TimetableUrlStore } from '@te/standard/stores/url/timetable-url-store';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import { TimetableIntegrationStore } from '@te/standard/stores/integration/timetable-integration-store';
import { TimetableLegendFilterStore } from '@te/standard/stores/filter/timetable-legend-filter-store';
import { inject } from '@/types/store';
import NotificationStore from '@/stores/notification-store/notification-store';
import TimetableFacadeStore from '@te/standard/stores/facade/timetable-facade-store';
import TimetableLocalStorageStore from '@te/standard/stores/local-storage/timetable-local-storage-store';
import TimetableAnalyticsStore from '@te/standard/stores/analytics/timetable-analytics-store';
import SchoolYearStore from '@/stores/schoolyear-store';

type TimetableStandardEntityType = 'student' | 'class' | 'subject' | 'teacher' | 'room' | 'resource';
type MyTimetableEntityType = 'my-student' | 'my-class' | 'my-teacher';
export type TimetableEntityType = TimetableStandardEntityType | MyTimetableEntityType;

export class TimetableRootStore {
  private notificationStore: NotificationStore = inject(NotificationStore);
  private schoolYearStore: SchoolYearStore = inject(SchoolYearStore);

  private timetableFacadeStore: TimetableFacadeStore = inject(TimetableFacadeStore);
  private timetableLocalStorageStore: TimetableLocalStorageStore = inject(TimetableLocalStorageStore);
  private timetableUrlStore: TimetableUrlStore = inject(TimetableUrlStore);
  private timetableFormatStore: TimetableFormatStore = inject(TimetableFormatStore);
  private timetableLegendFilterStore: TimetableLegendFilterStore = inject(TimetableLegendFilterStore);
  private timetableIntegrationStore: TimetableIntegrationStore = inject(TimetableIntegrationStore);
  private timetableAnalyticsStore: TimetableAnalyticsStore = inject(TimetableAnalyticsStore);

  @observable private _isGridFetched: boolean = false;
  @observable private _isFilterDataFetched: boolean = false;
  @observable private _isInitialDataFetched: boolean = false;
  @observable private _shouldPersistTimetableEntriesSettings: boolean = false;
  @observable private _initialTimetableUrl: TimetableUrl | undefined;

  constructor(timetableUrl: TimetableUrl) {
    this._initialTimetableUrl = timetableUrl;

    reaction(
      () => this._isGridFetched,
      () => {
        if (this._isGridFetched && this.timetableFormatStore.timetableEntityType) {
          this.fetchFilterData();
          this.timetableAnalyticsStore.trackTimetableVisit(this.timetableFormatStore.timetableEntityType);
        }
      },
    );

    reaction(
      () => this._isFilterDataFetched,
      () => {
        if (this._isGridFetched && this._isFilterDataFetched) {
          this.fetchTimetableEntries();
        }
      },
    );

    reaction(
      () => this.timetableUrlStore.timetableCurrentUrl,
      () => {
        if (
          this.timetableFormatStore.timetableEntityType &&
          this.isTimetableInitialized &&
          !this.timetableUrlStore.hasModalOpen &&
          this.timetableUrlStore.whitelistedModalClosed
        ) {
          this.fetchTimetableEntries();
          this.timetableLocalStorageStore.writeUrlSelectionToLocalStorage(
            this.timetableFormatStore.timetableEntityType,
            this.timetableUrlStore.urlSearchParameters,
          );
        }
      },
    );

    reaction(
      () => this.timetableIntegrationStore.enabledExternalIntegrations,
      () => {
        if (this.isTimetableInitialized) {
          this.updateTimetableIntegrations();
        }
      },
    );

    reaction(
      () => this.timetableLegendFilterStore.timetableEntriesSettings,
      () => {
        if (this.isTimetableInitialized && this._shouldPersistTimetableEntriesSettings) {
          this.updateTimetableEntriesSettings();
        }
      },
    );

    reaction(
      () => this.timetableFormatStore.selectedTimetableFormatId,
      () => {
        if (this.isTimetableInitialized) {
          this.handleActiveTimetableFormatChange();
        }
      },
    );

    reaction(
      () => this.schoolYearStore.currentSchoolYear,
      () => {
        if (this.isTimetableInitialized) {
          this.handleCurrentSchoolYearChange();
        }
      },
    );
  }

  @action
  async init(urlSearchParams: URLSearchParams, timetableEntityType: TimetableEntityType) {
    try {
      await this.timetableFacadeStore.init(timetableEntityType, urlSearchParams);
    } catch (error) {
      this.handleErrorMessage(error as AxiosError);
    } finally {
      this._isGridFetched = true;
    }
  }

  @action
  private async fetchFilterData() {
    try {
      await this.timetableFacadeStore.fetchFilterData();
    } catch (error) {
      this.handleErrorMessage(error as AxiosError);
    } finally {
      this._isFilterDataFetched = true;
      this._shouldPersistTimetableEntriesSettings = true;
    }
  }

  @action
  private async fetchTimetableEntries() {
    try {
      await this.timetableFacadeStore.fetchTimetableEntries();
      if (!this._isInitialDataFetched && this._initialTimetableUrl) {
        this._isInitialDataFetched = true;
        this.timetableUrlStore.handleTimetableUrl(this._initialTimetableUrl);
      }
    } catch (error) {
      if (error.response.status === 403 && this.timetableFormatStore.timetableEntityType) {
        // if there is an auth-error we want to delete the url from localStorage to be able to access timetable again
        this.timetableFacadeStore.resetUrl(this.timetableFormatStore.timetableEntityType);
        return;
      }
      if (error.response.status === 404 && this.timetableFormatStore.timetableEntityType) {
        // One or more entities could not be found -> reset the url and pre-select first element
        this.timetableFacadeStore.resetEntitySelection();
        return;
      }
      this.handleErrorMessage(error as AxiosError);
    }
  }

  @action
  private async updateTimetableIntegrations() {
    try {
      await this.timetableFacadeStore.updateTimetableIntegrations();
    } catch (error) {
      this.handleErrorMessage(error as AxiosError);
    }
  }

  @action
  private async updateTimetableEntriesSettings() {
    try {
      await this.timetableFacadeStore.updateTimetableEntriesSettings();
    } catch (error) {
      this.handleErrorMessage(error as AxiosError);
    }
  }

  @action
  private async handleActiveTimetableFormatChange() {
    try {
      this._shouldPersistTimetableEntriesSettings = false;
      await this.timetableFacadeStore.handleActiveTimetableFormatChange();
    } catch (error) {
      this.handleErrorMessage(error as AxiosError);
    } finally {
      this._shouldPersistTimetableEntriesSettings = true;
    }
  }

  @action
  private async handleCurrentSchoolYearChange() {
    try {
      const { timetableEntityType } = this.timetableFormatStore;
      this.reset();
      await this.timetableFacadeStore.handleCurrentSchoolYearChange(timetableEntityType);
    } catch (error) {
      this.handleErrorMessage(error as AxiosError);
    } finally {
      this._isGridFetched = true;
    }
  }

  @action
  reset() {
    this._isGridFetched = false;
    this._isFilterDataFetched = false;
    this._isInitialDataFetched = false;
    this._shouldPersistTimetableEntriesSettings = false;
    this._initialTimetableUrl = undefined;
    this.timetableFacadeStore.resetStores();
  }

  @computed
  get isTimetableInitialized(): boolean {
    return this._isGridFetched && this._isFilterDataFetched;
  }

  private handleErrorMessage(error: AxiosError) {
    let errorMessage = error.toString();
    if ((error.response?.data as IErrorResponseData).errorMessage) {
      errorMessage = (error.response?.data as IErrorResponseData).errorMessage;
    }

    this.notificationStore.error({
      title: t('general.error'),
      message: errorMessage,
    });
  }
}
