import React from 'react';

import './error-text.less';

const ErrorText = ({ text }: { text: string }) => {
  return (
    <div className="error-text" data-testid="error-text">
      {text}
    </div>
  );
};

export default ErrorText;
