import React from 'react';
import { t } from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

import { HistoryElementDto } from '@untis/wu-rest-view-api/api';
import { ITableRowKey, WUTable } from '@/ui-components/wu-table/wu-table';
import { Columns, ColumnType, DateFormat } from '@/ui-components/wu-table/wu-table-column-mapper';

interface IProps {
  history: HistoryElementDto[];
}

interface IHistoryRow extends ITableRowKey {
  field: string;
  oldValue: string;
  newValue: string;
  date: Dayjs;
  user: string;
}

const historyToRows = (history: HistoryElementDto[]): IHistoryRow[] => {
  return history.map((item, index) => {
    return {
      key: index,
      field: item.field,
      oldValue: item.oldValue || '',
      newValue: item.newValue || '',
      date: dayjs(item.timestamp),
      user: item.user,
    };
  });
};

export const HistoryView = (props: IProps) => {
  const columns: Columns<IHistoryRow> = [
    {
      type: ColumnType.Text,
      key: 'field',
      header: t('lessons.exams.historyChanges.tableColumns.field'),
    },
    {
      type: ColumnType.Text,
      key: 'oldValue',
      header: t('lessons.exams.historyChanges.tableColumns.currentContent'),
      width: 150,
      ellipsis: true,
    },
    {
      type: ColumnType.Text,
      key: 'newValue',
      header: t('lessons.exams.historyChanges.tableColumns.newContent'),
      width: 150,
      ellipsis: true,
    },
    {
      type: ColumnType.Date,
      key: 'date',
      header: t('general.date'),
      format: DateFormat.DateTime,
    },
    {
      type: ColumnType.Text,
      key: 'user',
      header: t('general.user'),
      width: 150,
      ellipsis: true,
    },
  ];

  return <WUTable<IHistoryRow> columns={columns} rowData={historyToRows(props.history)} />;
};
