import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { WUForm } from '@/ui-components';
import { BuildingDto, RoomRefDto } from '@untis/wu-rest-view-api/api';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { WUFormCol } from '@/ui-components/wu-form/wu-form-col/wu-form-col';
import { WUFormRow } from '@/ui-components/wu-form/wu-form-row/wu-form-row';
import { FormMultiTagSelect } from '@/ui-components/wu-form/form-multi-tag-select/form-multi-tag-select';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { createMultiSelectAddHandler, validateForm } from '@/utils/form/form-util';
import { FormSection } from '@/ui-components/wu-form/form-section/form-section';
import { IFormButton } from '@/ui-components/wu-form/wu-form';
import { BuildingStore } from '@/pages/master-data/building/building-store';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { getCommonTextInputRules, TextValidationPatterns } from '@/utils/form/common-form-rules';
import { TestIds } from '@/testIds';

export interface IBuildingFormBuilding {
  shortName: string;
  longName: string;
  rooms: IMultiTagSelectItem[];
}

interface IProps {
  building?: BuildingDto;
  store: BuildingStore;
}

const getSelectedRoomDtos = (value: IBuildingFormBuilding, props: IProps): RoomRefDto[] => {
  const roomDtos: RoomRefDto[] = [];

  value.rooms.forEach((item) => {
    const roomDto = props.store.rooms.find((d) => d.id.toString() === item.id);
    roomDto && roomDtos.push(roomDto);
  });

  return roomDtos;
};

const formBuildingToBuildingDto = (value: IBuildingFormBuilding, props: IProps): BuildingDto => {
  const selectedRoomDtos = getSelectedRoomDtos(value, props);
  return {
    id: props.building?.id,
    name: value.shortName,
    longName: value.longName,
    lastModified: props.building?.lastModified,
    rooms: selectedRoomDtos,
  };
};

export const BuildingForm = observer((props: IProps) => {
  const store = props.store;
  const modalStore = useStore(ModalStore);
  const { t } = useTranslation();
  const isInEditMode = !!props.building;
  const disabled = isInEditMode && !store.canEdit;

  const { building } = props;

  const roomItems: IMultiTagSelectItem[] = store.rooms.map((room) => {
    return {
      id: room.id.toString(),
      label: room.displayName ? room.displayName : '',
    };
  });

  const leftButtons: IFormButton[] =
    isInEditMode && store.canDelete
      ? [
          {
            label: t('general.delete'),
            onClick: () => {
              if (props.building?.id) {
                store.deleteBuilding(props.building.id).then();
              }
            },
          },
        ]
      : [];

  const rightButtons: IFormButton[] = [];
  if (!isInEditMode) {
    rightButtons.push({
      label: t('general.saveAndNew'),
      type: 'primary',
      disabled: store.submitAllDisabled,
      outline: false,
      onClick: () => {
        validateForm(store.form).then((isValid) => {
          if (isValid) {
            handleSaveNewBuilding(store.form.getFieldsValue(), true);
          }
        });
      },
      testId: TestIds.BUILDING_SAVE_AND_NEW,
    });
  }
  rightButtons.push({
    label: disabled ? t('general.ok') : t('general.cancel'),
    onClick: () => {
      modalStore.closeModal();
    },
  });

  const handleSaveNewBuilding = (value: IBuildingFormBuilding, saveAndNew: boolean) => {
    const buildingDto = formBuildingToBuildingDto(value, props);
    store.createBuilding(buildingDto, saveAndNew);
  };

  const handleChangeBuilding = (value: IBuildingFormBuilding) => {
    const buildingDto = formBuildingToBuildingDto(value, props);
    store.updateBuilding(buildingDto);
  };

  const initialRooms = building?.rooms
    ? roomItems.filter((room) => building.rooms.map((r) => r.id.toString()).includes(room.id))
    : [];

  return (
    <WUForm<IBuildingFormBuilding>
      form={store.form}
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      fixedButtonBar={true}
      hideSubmitButton={disabled}
      onDisableChange={(disabled) => store.setSubmitAllDisabled(disabled)}
      onSubmit={isInEditMode ? handleChangeBuilding : (value) => handleSaveNewBuilding(value, false)}
      maxWidth
      paddingTop
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={1}>
            <FormSection text={t('general.name')} />
          </WUFormCol>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormInput
              name="shortName"
              label={`${t('general.shortName')} ${t('general.requiredFieldBrackets')}`}
              initialValue={building?.name ? building.name : ''}
              rules={getCommonTextInputRules({}, [{ max: 20, message: t('general.inputTooLong') }])}
              required
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              disabled={disabled}
              testId={TestIds.BUILDING_SHORT_NAME}
              variant="floating"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormInput
              name="longName"
              label={t('general.longName')}
              initialValue={building?.longName ? building.longName : ''}
              rules={getCommonTextInputRules({}, [{ max: 255, message: t('general.inputTooLong') }])}
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              disabled={disabled}
              testId={TestIds.BUILDING_LONG_NAME}
              variant="floating"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.rooms')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormMultiTagSelect
              name="rooms"
              removable
              onAdd={createMultiSelectAddHandler(store.form, 'rooms', roomItems, t('general.addRooms'), modalStore)}
              initialValue={initialRooms}
              disabled={disabled}
              addButtonTestId={TestIds.BUILDING_ADD_ROOMS}
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});
