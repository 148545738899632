import React, { useMemo } from 'react';
import clsx from 'clsx';

import { useLessonCardHiddenItemCount } from '@/components/lesson-card/hooks/use-lesson-card-hidden-item-count';
import { ILessonCardResourceWithChange, LessonCardRowContentType } from '@/components/lesson-card/lesson-card';
import LessonCardSubstitutionTag from '@/components/lesson-card/components/substitution-tag/lesson-card-substitution-tag';
import LessonCardRemovedTag from '@/components/lesson-card/components/remove-tag/lesson-card-removed-tag';
import HiddenItemCount from '@/components/lesson-card/components/hidden-item-count/hidden-item-count';

interface IProps {
  type: LessonCardRowContentType;
  resourcesWithChange: ILessonCardResourceWithChange[];
  extraText?: string;
  showChange?: boolean;
  showTag?: boolean;
  isTopElement?: boolean;
}

interface ILessonCardResourceWithChangeType extends ILessonCardResourceWithChange {
  resourceChangeType?: ResourceChangeType;
}

type ResourceChangeType = 'substitution' | 'remove' | 'add' | 'regular';

const LessonCardResourceWithChange = ({
  type,
  resourcesWithChange,
  extraText,
  showChange,
  showTag,
  isTopElement,
}: IProps) => {
  const { ref, hiddenItemCount, hideInnerContent } = useLessonCardHiddenItemCount(isTopElement);

  const updatedResourcesWithChange: ILessonCardResourceWithChangeType[] = useMemo(() => {
    const getResourceChangeType = (
      resourceWithChange: ILessonCardResourceWithChange,
    ): ResourceChangeType | undefined => {
      const { regularResource, addedResource, removedResource } = resourceWithChange;
      if (removedResource !== undefined && addedResource === undefined && regularResource === undefined) {
        return 'remove';
      } else if (removedResource !== undefined && addedResource !== undefined && regularResource === undefined) {
        return 'substitution';
      } else if (removedResource == undefined && addedResource !== undefined && regularResource === undefined) {
        return 'add';
      } else if (removedResource == undefined && addedResource == undefined && regularResource !== undefined) {
        return 'regular';
      } else return undefined;
    };

    const resourcesWithChangeTypes: ILessonCardResourceWithChangeType[] = resourcesWithChange.map(
      (resourceWithChange) => {
        return {
          ...resourceWithChange,
          resourceChangeType: getResourceChangeType(resourceWithChange),
        };
      },
    );

    const updatedResourcesWithChangeTypes: ILessonCardResourceWithChangeType[] = [];
    if (showTag && !showChange) {
      resourcesWithChangeTypes.forEach((resourcesWithChangeType) => {
        if (resourcesWithChangeType.resourceChangeType !== 'substitution') {
          updatedResourcesWithChangeTypes.push(resourcesWithChangeType);
        } else {
          if (
            updatedResourcesWithChangeTypes.find(
              (updatedResourcesWithChangeType) =>
                updatedResourcesWithChangeType.addedResource === resourcesWithChangeType.addedResource,
            ) === undefined
          ) {
            updatedResourcesWithChangeTypes.push(resourcesWithChangeType);
          }
        }
      });
    } else {
      resourcesWithChangeTypes.forEach((resourcesWithChangeType) =>
        updatedResourcesWithChangeTypes.push(resourcesWithChangeType),
      );
    }

    return updatedResourcesWithChangeTypes;
  }, [resourcesWithChange, showTag, showChange]);

  return (
    <div className="lesson-card-resources-with-change-container" ref={ref}>
      <div
        className={clsx('lesson-card-resources-with-change', { hide: hideInnerContent })}
        data-testid={`lesson-card-resources-with-change-${type}`}
      >
        {updatedResourcesWithChange.map((updatedResourceWithChange, index) => {
          const { addedResource, removedResource, regularResource, resourceChangeType } = updatedResourceWithChange;
          return (
            <React.Fragment key={index}>
              {resourceChangeType === 'regular' && (
                <span
                  className={clsx('lesson-card-resource-with-change', { 'lesson-card-top-element': isTopElement })}
                  data-testid={`lesson-card-resource-with-change-${type}`}
                >
                  <span data-testid="regular-resource">{regularResource}</span>
                </span>
              )}

              {(resourceChangeType === 'substitution' || resourceChangeType === 'add') && (
                <LessonCardSubstitutionTag
                  addedDisplayName={addedResource ?? ''}
                  removedDisplayName={removedResource}
                  showTag={showTag}
                  showChange={showChange}
                  isTopElement={isTopElement}
                />
              )}

              {resourceChangeType === 'remove' && (
                <LessonCardRemovedTag
                  removedDisplayName={removedResource ?? ''}
                  showTag={showTag}
                  showChange={showChange}
                  isTopElement={isTopElement}
                />
              )}

              {index !== updatedResourcesWithChange.length - 1 ? ', ' : null}
            </React.Fragment>
          );
        })}
        {extraText && <span className="vertical-divider"></span>}
        {extraText && (
          <span
            className={clsx('lesson-card-resources-with-change-extra-text', {
              'lesson-card-top-element': isTopElement,
            })}
            data-testid="lesson-card-resources-with-change-extra-text"
          >
            {extraText}
          </span>
        )}
      </div>
      <HiddenItemCount count={hiddenItemCount} isTopElement={isTopElement} />
    </div>
  );
};

export default LessonCardResourceWithChange;
