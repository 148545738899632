import React from 'react';
import { Form } from 'antd';

import TaggedSwitch, { TaggedSwitchProps } from '@/ui-components/tagged-switch/tagged-switch';
import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';

export interface ITaggedSwitchProps extends IFormItemProps<string>, TaggedSwitchProps {
  onChange?: (value: boolean) => void;
}

const FormTaggedSwitch = (props: ITaggedSwitchProps) => {
  const { name, label, initialValue, rules, dependencies, disabled, checked, offLabel, onLabel } = props;

  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={initialValue}
      className="form-radio-buttons"
      rules={rules}
      dependencies={dependencies}
      valuePropName="checked"
    >
      <TaggedSwitch
        disabled={disabled}
        checked={checked}
        onLabel={onLabel}
        offLabel={offLabel}
        defaultChecked={initialValue}
        testId={props.testId}
      />
    </Form.Item>
  );
};

export default FormTaggedSwitch;
