import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { t } from 'i18next';

import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import ModalStore from '@/stores/modal-store';
import useStore from '@/hooks/useStore';
import { ReasonsOfExemptionStore } from '@/pages/master-data/reasons-of-exemption/reasons-of-exemption-store';
import { ReasonsOfExemptionForm } from '@/pages/master-data/reasons-of-exemption/reasons-of-exemption-form';
import RouterStore from '@/stores/router-store';

export const ReasonsOfExemptionNew = () => {
  const modalStore = useStore(ModalStore);
  const routerStore = useStore(RouterStore);
  const [redirect, setRedirect] = useState(false);
  const store = useStore(ReasonsOfExemptionStore);

  useComponentDidMount(async () => {
    await modalStore.openModalDialog({
      children: <ReasonsOfExemptionForm store={store} />,
      title: t('general.newReasonOfExemption'),
      size: 'md',
      onAfterClose: () => routerStore.redirect('/reasons-of-exemptions'),
      containsForm: true,
    });
    setRedirect(true);
  });

  if (redirect) {
    return <Redirect to="/reasons-of-exemptions" />;
  }

  return null;
};
