import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { IRoomForm } from '@/pages/master-data/room/room-form';
import { RoomStore, IRoomRow } from '@/pages/master-data/room/room-store';
import ConfigStore from '@/stores/config-store';
import useStore from '@/hooks/useStore';
import { ListView } from '@/components/list-view/list-view';
import { DeprecatedSearchBarRestriction } from '@/ui-components/deprecated-search-bar/deprecated-search-bar';
import { RoomViewApi } from '@/stores/api-store';

const MasterDataRooms = () => {
  const [form] = Form.useForm<IRoomForm>();
  const [store] = useState(() => new RoomStore(form));
  const { t } = useTranslation();
  const configStore = useStore(ConfigStore);

  useEffect(() => {
    store.fetchData();
  }, [configStore.selectedDepartmentId, store]);

  return (
    <ListView<IRoomRow>
      title={t('general.rooms')}
      isPageLoading={store.isDataLoading}
      deprecatedSearchBar={{
        value: store.searchInputValue,
        onChange: (value) => store.setSearchInputValue(value),
        onChangeSelectOptions: (option) => store.setSelectedOptions(option),
        selectedOptions: store.selectedOptions,
        restriction: DeprecatedSearchBarRestriction.ONLY_ONE_OPTION_PER_GROUP,
        color: 'white',
        placeholder: t('general.search'),
      }}
      table={{
        columns: store.columns,
        rowData: store.filteredRows,
        rowSelection: {
          selectedRowKeys: store.selectedRowKeys,
          type: 'checkbox',
          onChange: (keys) => store.setSelectedRowKeys(keys),
        },
        shadow: true,
      }}
      actions={store.listViewActions}
      selectedRowsActions={store.selectedRowsActions}
      customSorting={
        store.canEdit
          ? {
              onSave: (order) => {
                RoomViewApi.createCustomOrderForRooms(order).then(() => {
                  store.fetchRooms();
                });
              },
              onDelete: () => {
                RoomViewApi.deleteCustomOrderForRooms().then(() => {
                  store.fetchRooms();
                });
              },
            }
          : undefined
      }
    />
  );
};

export default observer(MasterDataRooms);
