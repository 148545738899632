import React from 'react';
import { action, computed, observable } from 'mobx';
import { t } from 'i18next';

import { AbstractListViewStore } from '@md/common/abstract-list-view-store';
import { ICalSettingsViewApi } from '@/stores/api-store';
import { ICalFormatDto, ICalFormatFormDto, ICalPropertyEnum } from '@untis/wu-rest-view-api/api';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { booleanSorting } from '@/utils/sorting/sorting-util';
import ICalFormatSettingsForm from '@te/settings/i-cal-format/i-cal-format-settings-form';
import { IListViewAction } from '@/components/list-view/list-view';
import { ISelectItem } from '@/ui-components/select/select';
import { replaceLabelPlaceholder } from '@te/settings/utils/utils';

export interface ICalFormatSettingsFormData {
  name: string;
  active: boolean;
  highlightExams: boolean;
  classTimetableProperty?: string;
  classICalProperty: string;
  teacherTimetableProperty?: string;
  teacherICalProperty: string;
  subjectTimetableProperty?: string;
  subjectICalProperty: string;
  roomTimetableProperty?: string;
  roomICalProperty: string;
  resourceTimetableProperty?: string;
  resourceICalProperty: string;
  bookingTimetableProperty?: string;
  bookingICalProperty: string;
}

export interface ICalFormatSettingsRow {
  key: number;
  name: string;
  active: boolean;
  highlightExams: boolean;
  classTimetableProperty?: string;
  classICalProperty: string;
  teacherTimetableProperty?: string;
  teacherICalProperty: string;
  subjectTimetableProperty?: string;
  subjectICalProperty: string;
  roomTimetableProperty?: string;
  roomICalProperty: string;
  resourceTimetableProperty?: string;
  resourceICalProperty: string;
  bookingTimetableProperty?: string;
  bookingICalProperty: string;
}

export default class ICalFormatSettingsStore extends AbstractListViewStore<ICalFormatSettingsFormData> {
  @observable private _iCalFormats: ICalFormatDto[] = [];
  @observable private _formValues: ICalFormatFormDto | undefined = undefined;

  constructor() {
    super();
  }

  @action
  async fetchData() {
    this.setIsDataLoading(true);
    try {
      const response = await ICalSettingsViewApi.getFormatList();
      const fromResponse = await ICalSettingsViewApi.getFormatForm();
      this._iCalFormats = response.data.formatRefs ?? [];
      this._formValues = fromResponse.data;
    } catch (error) {
      this._notificationStore.error({
        title: '',
        message: error.toString(),
      });
    } finally {
      this.setIsMetaLoading(false);
      this.setIsDataLoading(false);
    }
  }

  @computed
  get iCalFormatRows(): ICalFormatSettingsRow[] {
    return this._iCalFormats.map(this.mapToRow);
  }

  @computed
  get columns(): Columns<ICalFormatSettingsRow> {
    const cols: Columns<ICalFormatSettingsRow> = [
      {
        type: ColumnType.Text,
        header: t('general.name'),
        key: 'name',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          return a.name.localeCompare(b.name);
        },
      },
      {
        type: ColumnType.Tag,
        header: t('general.active'),
        tags: (row) =>
          row.active
            ? [{ text: t('general.active').toUpperCase(), color: 'green' }]
            : [{ text: t('general.inactive').toUpperCase(), color: 'grey' }],
        key: 'active',
        sorter: (a, b) => booleanSorting(a.active, b.active),
      },
    ];

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.HoverActions,
        key: 'actions',
        actionIcons: this.columnActions,
      });
    }

    return cols;
  }

  @computed
  private get columnActions() {
    const actions = [];

    actions.push({
      tooltip: t('general.copy'),
      onClick: (row: ICalFormatSettingsRow) => this.duplicateICalFormat(row.key),
      icon: 'copy',
    });

    actions.push({
      tooltip: t('general.edit'),
      onClick: (row: ICalFormatSettingsRow) => this.openICalFormatForm(row),
      icon: 'edit',
    });

    actions.push({
      tooltip: t('general.delete'),
      onClick: (row: ICalFormatSettingsRow) => this.deleteICalFormat(row.key),
      icon: 'shared_trash',
    });

    return actions;
  }

  @computed
  get listViewActions(): IListViewAction[] {
    const actions: IListViewAction[] = [];
    actions.push({
      label: t('general.new'),
      onClick: () => this.openICalFormatForm(undefined),
    });
    return actions;
  }

  @computed
  get iCalProperties(): ISelectItem[] {
    return [
      {
        id: ICalPropertyEnum.NONE.toString(),
        label: '-',
      },
      {
        id: ICalPropertyEnum.LOCATION.toString(),
        label: ICalPropertyEnum.LOCATION.toString(),
      },
      {
        id: ICalPropertyEnum.DESCRIPTION.toString(),
        label: ICalPropertyEnum.DESCRIPTION.toString(),
      },
      {
        id: ICalPropertyEnum.SUMMARY.toString(),
        label: ICalPropertyEnum.SUMMARY.toString(),
      },
    ];
  }

  @computed
  get classTimetableProperties(): ISelectItem[] {
    return (
      this._formValues?.classLabels
        .filter((l) => (l.label?.length ?? 0) > 0)
        .map((l) => ({ id: l.id.toString(), label: replaceLabelPlaceholder(l.label ?? '') })) ?? []
    );
  }

  @computed
  get teacherTimetableProperties(): ISelectItem[] {
    return (
      this._formValues?.teacherLabels
        .filter((l) => (l.label?.length ?? 0) > 0)
        .map((l) => ({ id: l.id.toString(), label: replaceLabelPlaceholder(l.label ?? '') })) ?? []
    );
  }

  @computed
  get subjectTimetableProperties(): ISelectItem[] {
    return (
      this._formValues?.subjectLabels
        .filter((l) => (l.label?.length ?? 0) > 0)
        .map((l) => ({ id: l.id.toString(), label: replaceLabelPlaceholder(l.label ?? '') })) ?? []
    );
  }

  @computed
  get roomTimetableProperties(): ISelectItem[] {
    return (
      this._formValues?.roomLabels
        .filter((l) => (l.label?.length ?? 0) > 0)
        .map((l) => ({ id: l.id.toString(), label: replaceLabelPlaceholder(l.label ?? '') })) ?? []
    );
  }

  @computed
  get resourceTimetableProperties(): ISelectItem[] {
    return (
      this._formValues?.resourceLabels
        .filter((l) => (l.label?.length ?? 0) > 0)
        .map((l) => ({ id: l.id.toString(), label: replaceLabelPlaceholder(l.label ?? '') })) ?? []
    );
  }

  @computed
  get bookingTimetableProperties(): ISelectItem[] {
    return (
      this._formValues?.bookingLabels
        .filter((l) => (l.label?.length ?? 0) > 0)
        .map((l) => ({ id: l.id.toString(), label: replaceLabelPlaceholder(l.label ?? '') })) ?? []
    );
  }

  @action.bound
  async openICalFormatForm(row: ICalFormatSettingsRow | undefined) {
    if (row) {
      const format = await ICalSettingsViewApi.getFormat(row.key);
      this._modalStore.openModalDialog({
        title: t('general.editICalFormat'),
        size: 'full-size',
        children: <ICalFormatSettingsForm store={this} format={this.mapToRow(format.data)} />,
        containsForm: true,
      });
    } else {
      this._modalStore.openModalDialog({
        title: t('general.createNewICalFormat'),
        size: 'full-size',
        children: <ICalFormatSettingsForm store={this} />,
        containsForm: true,
      });
    }
  }

  @action
  async duplicateICalFormat(id: number) {
    const formatToDuplicate = this._iCalFormats.find((f) => f.id === id);
    if (formatToDuplicate) {
      const duplicationResult = await ICalSettingsViewApi.changeFormat(this.copyDto(formatToDuplicate));
      this._notificationStore.success({ title: t('general.formatDuplicated') });
      this.onICalFormatCreated(duplicationResult.data);
    }
  }

  @action
  async deleteICalFormat(id: number) {
    const formatToDelete = this._iCalFormats.find((f) => f.id === id);
    const isConfirmed = await this._modalStore.openDeletePrompt(
      t('general.deleteFormatConfirmationTitle'),
      t('general.deleteFormatConfirmationQuestion', { formatName: formatToDelete?.name ?? '' }),
    );

    if (isConfirmed) {
      try {
        await ICalSettingsViewApi.deleteFormat(id);
        this._notificationStore.success({ title: t('general.formatDeleted') });
        this._modalStore.closeModal();
        this.onICalFormatDeleted(id);
      } catch (error) {
        this._notificationStore.error({
          title: '',
          message: error.toString(),
        });
      }
    }
  }

  @action
  async updateICalFormat(formData: ICalFormatSettingsFormData, id: number) {
    const formatToEdit = this._iCalFormats.find((f) => f.id === id);
    if (formatToEdit) {
      const editedFormat = this.mapToDto(formData, formatToEdit);
      try {
        const updatedFormat = await ICalSettingsViewApi.changeFormat(editedFormat);
        this._notificationStore.success({ title: t('general.formatEdited') });
        this._modalStore.closeModal();
        this.onICalFormatEdited(updatedFormat.data);
      } catch (error) {
        this._notificationStore.error({
          title: '',
          message: error.toString(),
        });
      }
    }
  }

  @action
  async createICalFormat(formData: ICalFormatSettingsFormData) {
    const newFormat = this.mapToDto(formData);
    try {
      const createdFormat = await ICalSettingsViewApi.changeFormat(newFormat);
      this._notificationStore.success({ title: t('general.formatCreated') });
      this._modalStore.closeModal();
      this.onICalFormatCreated(createdFormat.data);
    } catch (error) {
      this._notificationStore.error({
        title: '',
        message: error.toString(),
      });
    }
  }

  @action
  private onICalFormatDeleted(deletedICalFormatId: number) {
    this._iCalFormats = this._iCalFormats.filter((f) => f.id !== deletedICalFormatId);
  }

  @action
  private onICalFormatEdited(format: ICalFormatDto) {
    const cachedDto: ICalFormatDto | undefined = this._iCalFormats.find((f) => f.id === format.id);
    if (cachedDto) {
      Object.assign(cachedDto, format);
    }
  }

  @action
  private onICalFormatCreated(format: ICalFormatDto) {
    this._iCalFormats = [...this._iCalFormats, format];
  }

  private mapToRow(format: ICalFormatDto): ICalFormatSettingsRow {
    return {
      key: format.id ?? -1,
      name: format.name,
      active: format.active ?? false,
      highlightExams: format.markExams,
      classTimetableProperty: format.classFieldAssignment?.label.toString(),
      classICalProperty: format.classFieldAssignment?.property.toString() ?? ICalPropertyEnum.NONE.toString(),
      teacherTimetableProperty: format.teacherFieldAssignment?.label.toString(),
      teacherICalProperty: format.teacherFieldAssignment?.property.toString() ?? ICalPropertyEnum.NONE.toString(),
      subjectTimetableProperty: format.subjectFieldAssignment?.label.toString(),
      subjectICalProperty: format.subjectFieldAssignment?.property.toString() ?? ICalPropertyEnum.NONE.toString(),
      roomTimetableProperty: format.roomFieldAssignment?.label.toString(),
      roomICalProperty: format.roomFieldAssignment?.property.toString() ?? ICalPropertyEnum.NONE.toString(),
      resourceTimetableProperty: format.resourceFieldAssignment?.label.toString(),
      resourceICalProperty: format.resourceFieldAssignment?.property.toString() ?? ICalPropertyEnum.NONE.toString(),
      bookingTimetableProperty: format.bookingFieldAssignment?.label.toString(),
      bookingICalProperty: format.bookingFieldAssignment?.property.toString() ?? ICalPropertyEnum.NONE.toString(),
    };
  }

  private copyDto(format: ICalFormatDto): ICalFormatDto {
    return {
      ...format,
      id: undefined,
      name: `${format.name}-Copy`,
      classFieldAssignment: {
        label: format.classFieldAssignment?.label ?? -1,
        property: format.classFieldAssignment?.property ?? ICalPropertyEnum.NONE,
      },
      teacherFieldAssignment: {
        label: format.teacherFieldAssignment?.label ?? -1,
        property: format.teacherFieldAssignment?.property ?? ICalPropertyEnum.NONE,
      },
      subjectFieldAssignment: {
        label: format.subjectFieldAssignment?.label ?? -1,
        property: format.subjectFieldAssignment?.property ?? ICalPropertyEnum.NONE,
      },
      roomFieldAssignment: {
        label: format.roomFieldAssignment?.label ?? -1,
        property: format.roomFieldAssignment?.property ?? ICalPropertyEnum.NONE,
      },
      resourceFieldAssignment: {
        label: format.resourceFieldAssignment?.label ?? -1,
        property: format.resourceFieldAssignment?.property ?? ICalPropertyEnum.NONE,
      },
      bookingFieldAssignment: {
        label: format.bookingFieldAssignment?.label ?? -1,
        property: format.bookingFieldAssignment?.property ?? ICalPropertyEnum.NONE,
      },
    };
  }

  private mapToDto(formData: ICalFormatSettingsFormData, format?: ICalFormatDto): ICalFormatDto {
    return {
      id: format?.id,
      active: formData.active,
      name: formData.name,
      markExams: formData.highlightExams,
      classFieldAssignment: {
        id: format?.classFieldAssignment?.id,
        label: Number(formData.classTimetableProperty),
        property: ICalPropertyEnum[formData.classICalProperty as keyof typeof ICalPropertyEnum],
      },
      teacherFieldAssignment: {
        id: format?.teacherFieldAssignment?.id,
        label: Number(formData.teacherTimetableProperty),
        property: ICalPropertyEnum[formData.teacherICalProperty as keyof typeof ICalPropertyEnum],
      },
      subjectFieldAssignment: {
        id: format?.subjectFieldAssignment?.id,
        label: Number(formData.subjectTimetableProperty),
        property: ICalPropertyEnum[formData.subjectICalProperty as keyof typeof ICalPropertyEnum],
      },
      roomFieldAssignment: {
        id: format?.roomFieldAssignment?.id,
        label: Number(formData.roomTimetableProperty),
        property: ICalPropertyEnum[formData.roomICalProperty as keyof typeof ICalPropertyEnum],
      },
      resourceFieldAssignment: {
        id: format?.resourceFieldAssignment?.id,
        label: Number(formData.resourceTimetableProperty),
        property: ICalPropertyEnum[formData.resourceICalProperty as keyof typeof ICalPropertyEnum],
      },
      bookingFieldAssignment: {
        id: format?.bookingFieldAssignment?.id,
        label: Number(formData.bookingTimetableProperty),
        property: ICalPropertyEnum[formData.bookingICalProperty as keyof typeof ICalPropertyEnum],
      },
    };
  }
}
