import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getSubMenuItem, SubMenuItemEnum } from './menu-items';

import useGlobalKeyboardShortcuts from '@/hooks/useGlobalKeyboardShortcuts';
import useStore from '@/hooks/useStore';
import AppStore from '@/stores/app-store';
import ConfigStore from '@/stores/config-store';
import { MenuStore } from '@/stores/menu-store';
import RouterStore from '@/stores/router-store';
import { IMenuItem, ISubMenuItem } from '@/ui-components/navigation-bar/navigation-bar-store';
import PostMessageStore from '@sp/stores/post-message-store';
import { NavigationBar } from '@/ui-components';
import FrontChannelLogout from '@/components/front-channel-logout/front-channel-logout';
import GlobalSchoolYearSelection from '@/components/global-school-year-selection/global-school-year-selection';
import Keys from '@/types/keys';
import UntisNewsStore from '@/stores/untis-news-store';

import './menu.less';

interface IMenuProps {
  onNavigate: (item: IMenuItem, subMenuItem?: ISubMenuItem) => void;
}

export const Menu = observer((props: IMenuProps) => {
  const { t } = useTranslation();
  const routerStore = useStore(RouterStore);
  const postMessageStore = useStore(PostMessageStore);
  const configStore = useStore(ConfigStore);
  const menuStore = useStore(MenuStore);
  const appStore = useStore(AppStore);
  const untisNewsStore = useStore(UntisNewsStore);

  const logOutItem: ISubMenuItem = {
    name: t('menu.logout'),
    icon: 'navigation-logout',
    route: '',
    testId: 'menu-logout-item',
  };

  const handleDepartmentSelect = (id: number) => {
    configStore.selectedDepartmentId = id;
    postMessageStore.postDepartmentSelectedMessage(id);
  };

  const handleStudentSelect = (id: number) => {
    configStore.selectedStudentId = id;
    postMessageStore.postStudentSelectedMessage(id);
  };

  const handleKeyDown = (event: any) => {
    if (event.ctrlKey && event.keyCode === 77) {
      appStore.showFocusedElements = true;
    }
  };

  const handleInvalidMenuItemSelected = () => {
    routerStore.routing.push('/');
  };

  const bottomMenuItems: ISubMenuItem[] = [];
  if (configStore.isAdmin) {
    bottomMenuItems.push({
      name: t('menu.news'),
      icon: 'flame',
      route: '',
      onClick: () => {
        untisNewsStore.openChangelog();
      },
      onKeyDown: (event) => {
        if (event.key !== Keys.Enter) return;
        untisNewsStore.openChangelog();
      },
      highlight: untisNewsStore.hasUnreadUntisNews,
    });
  }

  return (
    <>
      <NavigationBar
        title="WebUntis"
        schoolName={configStore.schoolName}
        logo={
          configStore.isPlayground ? (
            <div className="playground-logo-container">
              <img src="assets/images/playground-white.png" alt={t('general.logo')} />
            </div>
          ) : (
            <img src="assets/images/logo.png" alt={t('general.logo')} />
          )
        }
        menuItems={menuStore.menuItems}
        bottomMenuItems={bottomMenuItems}
        user={{
          name: configStore.userName,
          role: configStore.userRole,
          image: configStore.userImage,
          userRoute: getSubMenuItem(SubMenuItemEnum.PROFILE)?.route,
        }}
        students={configStore.userStudents.map((student) => {
          return {
            id: student.id ? student.id : -1,
            name: student.displayName ? student.displayName : '',
            image: student.imageUrl,
          };
        })}
        logOutItem={logOutItem}
        currentRoute={routerStore.routing.location.pathname}
        onLogOut={() => {
          appStore.handleLogout();
        }}
        onNavigate={props.onNavigate}
        disabled={menuStore.disabled}
        onCollapse={() => menuStore.setExpanded(false)}
        onExpand={() => menuStore.setExpanded(true)}
        styles={{ zIndex: 901 }}
        departments={configStore.departments.map((d) => {
          const id: number = d.id ? d.id : -1;
          const name: string = d.displayName ? d.displayName : '';
          return { id, name };
        })}
        selectedDepartmentId={configStore.selectedDepartmentId}
        onSelectDepartment={handleDepartmentSelect}
        departmentsText={t('general.departments')}
        studentsText={t('general.students')}
        allStudentsText={t('general.allStudents')}
        onSelectStudent={handleStudentSelect}
        selectedStudentId={configStore.selectedStudentId}
        allText={t('general.all')}
        useGlobalKeyboardShortcuts={useGlobalKeyboardShortcuts}
        onKeyDown={handleKeyDown}
        isUserItemVisible={configStore.checkSubMenuItemPermission(SubMenuItemEnum.PROFILE)}
        customHeaderComponent={{
          component: <GlobalSchoolYearSelection isCollapsed={!menuStore.expanded} />,
          height: 40,
        }}
        onInvalidMenuItemSelected={handleInvalidMenuItemSelected}
      />
      {appStore.renderFrontChannelLogout && <FrontChannelLogout />}
    </>
  );
});
