import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WUTable, IconButton, SwitchComponent } from '@/ui-components';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import ReportStore from '@/stores/report-store';
import useStore from '@/hooks/useStore';

import './reports-exporting-table.less';

export enum ReportType {
  PDF = 'PDF',
  CSV = 'CSV',
  XLS = 'XLS',
}

export interface IReportExportingRow {
  key: string;
  title: string;
  description: string;
  reportTypes: ReportType[];
}

export interface IReportExtraOption {
  key: string;
  value: boolean;
  text: string;
}

interface IProps {
  rows: IReportExportingRow[];
  extraOptions?: IReportExtraOption[];
  getReportUrl: (key: string, reportType: ReportType, extraOptions?: IReportExtraOption[]) => string;
}

export const ReportsExportingTable = (props: IProps) => {
  const { rows } = props;

  const [extraOptions, setExtraOptions] = useState<IReportExtraOption[] | undefined>(props.extraOptions);

  const reportStore = useStore(ReportStore);
  const { t } = useTranslation();

  const renderIcon = (key: string, reportTypes: ReportType[], reportType: ReportType) => {
    return (
      <>
        {reportTypes.includes(reportType) ? (
          <IconButton
            onClick={() => reportStore.getReport(props.getReportUrl(key, reportType, extraOptions))}
            type="download"
            ariaLabel={t('general.download')}
          />
        ) : (
          <IconButton type="lesson-minus" viewOnly={true} ariaLabel={t('general.unavailable')} />
        )}
      </>
    );
  };

  const handleSwitchChange = (key: string, value: boolean) => {
    const newExtraOptions = extraOptions?.map((extraOption) => {
      if (extraOption.key === key) {
        return {
          ...extraOption,
          value: value,
        };
      } else {
        return extraOption;
      }
    });
    setExtraOptions(newExtraOptions);
  };

  const columns: Columns<IReportExportingRow> = [
    {
      key: 'title',
      type: ColumnType.Text,
      header: t('general.name'),
      textStyle: {
        bold: true,
      },
    },
    {
      key: 'description',
      type: ColumnType.Text,
      header: t('general.description'),
    },
    {
      key: 'pdf',
      type: ColumnType.Custom,
      header: 'PDF',
      width: 40,
      align: 'center',
      render: ({ key, reportTypes }) => renderIcon(key, reportTypes, ReportType.PDF),
    },
    {
      key: 'xls',
      type: ColumnType.Custom,
      header: 'XLS',
      width: 40,
      align: 'center',
      render: ({ key, reportTypes }) => renderIcon(key, reportTypes, ReportType.XLS),
    },
    {
      key: 'csv',
      type: ColumnType.Custom,
      header: 'CSV',
      width: 40,
      align: 'center',
      render: ({ key, reportTypes }) => renderIcon(key, reportTypes, ReportType.CSV),
    },
  ];

  return (
    <div className="reports-exporting-table">
      <WUTable<IReportExportingRow> columns={columns} rowData={rows} />
      {extraOptions?.map((extraOption) => {
        return (
          <div key={extraOption.key} className="reports-exporting-table--extra-option">
            <span className="extra-option-title">{extraOption.text}</span>
            <SwitchComponent
              size="default"
              title={extraOption.text}
              checked={extraOption.value}
              onChange={(checked: boolean) => handleSwitchChange(extraOption.key, checked)}
            />
          </div>
        );
      })}
    </div>
  );
};
