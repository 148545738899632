import { action, reaction } from 'mobx';
import { message as antdMessage } from 'antd';
import { t } from 'i18next';
import debounce from 'lodash/debounce';

import { Store } from '@/types/store';
import {
  DraftMessageSaveDtoV2,
  DraftMessageRefDtoV2,
  DraftMessageSaveChangesDtoV2,
  DraftMessageRefDto,
} from '@untis/wu-rest-view-api';
import { MessageViewApi } from '@/stores/api-store';
import { showErrorResponse } from '@/utils/error-handling/error-message';
import { BaseMessagesStore } from '@mg/stores/base-messages-store';

@Store()
export default class DraftMessagesStore extends BaseMessagesStore<DraftMessageRefDto> {
  constructor(typediParams: any) {
    super(typediParams);

    reaction(
      () => this.searchText,
      (searchText) => {
        if (searchText.length === 0) {
          this.getDraftMessages();
        } else {
          this.debouncedSearchDraftMessages();
        }
      },
    );
  }

  @action
  async getDraftMessages() {
    await this.fetchMessages<{ draftMessages: DraftMessageRefDto[] }>(
      (searchText) => MessageViewApi.getDraftMessages(searchText),
      'draftMessages',
    );
  }

  @action
  debouncedSearchDraftMessages = debounce(this.getDraftMessages, 300);

  @action
  async saveDraftMessage(
    {
      draftMessageSaveData,
      draftMessageSaveChangesData,
    }: {
      draftMessageSaveData: DraftMessageSaveDtoV2;
      draftMessageSaveChangesData: DraftMessageSaveChangesDtoV2;
    },
    id?: number,
    attachments?: File[],
  ) {
    let draft: DraftMessageRefDtoV2 | undefined;

    try {
      if (id) {
        const { data: updatedDraft } = await MessageViewApi.saveDraftMessageChangesV2(
          id,
          draftMessageSaveChangesData,
          attachments,
        );
        draft = updatedDraft;
        this.updateMessage(id, updatedDraft);
      } else {
        const { data: savedDraft } = await MessageViewApi.saveDraftMessageV2(draftMessageSaveData, attachments);
        draft = savedDraft;
        this.addMessage(savedDraft);
      }

      antdMessage.success(t('general.messageSaved'));
    } catch (error) {
      showErrorResponse(error);
    }

    return draft;
  }
}
