import { action, computed, observable } from 'mobx';
import dayjs from 'dayjs';

import { inject, Store } from '@/types/store';
import { DayDataStatusEnum, TimetableEntriesDto } from '@untis/wu-rest-view-api';
import { TimetableGridDimensionsStore } from '@te/standard/stores/grid/timetable-grid-dimensions-store';
import { TimetableGridSlotsStore } from '@te/standard/stores/grid/timetable-grid-slots-store';
import { DayDataDto, MasterDataRefDto } from '@untis/wu-rest-view-api/api';

export interface ITimetableRestrictionLayer {
  top: number;
  height: number;
  left: number;
  width: number;
  startDate: string;
  endDate: string;
  type: DayDataStatusEnum;
  resource: MasterDataRefDto | undefined;
}

interface ICombinedDayData {
  startDate: string;
  endDate: string;
  status: DayDataStatusEnum;
  resource: MasterDataRefDto | undefined;
}

@Store()
export class TimetableRestrictionLayerStore {
  private timetableGridDimensionsStore = inject(TimetableGridDimensionsStore);
  private timetableGridSlotsStore = inject(TimetableGridSlotsStore);

  @observable private _timeTableEntries: TimetableEntriesDto | undefined;

  @action
  reset() {
    this._timeTableEntries = undefined;
  }

  @computed
  get timetableRestrictionLayers(): ITimetableRestrictionLayer[] {
    const { timeGridStartTime, timeGridEndTime } = this.timetableGridSlotsStore;
    const offsetFromStart = this.timetableGridDimensionsStore.offsetFromStartTime(timeGridStartTime);
    const durationHeight = this.timetableGridDimensionsStore.durationHeight(timeGridStartTime, timeGridEndTime);
    const combinedDays = this.combineConsecutiveDaysByStatus(this._timeTableEntries?.days ?? []);
    return combinedDays
      .filter((day) => day.status !== DayDataStatusEnum.REGULAR)
      .map((day) => {
        const { startDate, endDate, status } = day;
        return {
          top: offsetFromStart,
          height: durationHeight,
          left: this.timetableGridDimensionsStore.offsetFromStartDate(dayjs(startDate)),
          width: this.timetableGridDimensionsStore.dayDurationWidth(dayjs(startDate), dayjs(endDate)),
          type: status,
          resource: day.resource,
          startDate,
          endDate,
        };
      });
  }

  private combineConsecutiveDaysByStatus(days: DayDataDto[]): ICombinedDayData[] {
    const result: ICombinedDayData[] = [];
    let current: ICombinedDayData | undefined = undefined;
    days.forEach((day) => {
      if (!current || current.status !== day.status) {
        current = { status: day.status, startDate: day.date, endDate: day.date, resource: day.resource };
        result.push(current);
      } else {
        current.endDate = day.date;
      }
    });
    return result;
  }

  @action
  setTimetableEntries(timetableEntries: TimetableEntriesDto | undefined) {
    this._timeTableEntries = timetableEntries;
  }
}
