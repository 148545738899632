import React from 'react';
import Axios from 'axios';
import { t } from 'i18next';

import { inject, Store } from '@/types/store';
import NotificationStore from '@/stores/notification-store/notification-store';
import {
  IReportExportingRow,
  IReportExtraOption,
  ReportsExportingTable,
  ReportType,
} from '@/components/reports-exporting-table/reports-exporting-table';
import ModalStore from '@/stores/modal-store';

interface IReportResponse {
  data: {
    error: boolean;
    finished: boolean;
    format: string;
    isPublic: boolean;
    messageId: number;
    reportName: string;
    reportParams: string;
  };
}

interface IPollingResponse {
  data: {
    hasRunningJobs: boolean;
    pollingJobs: {
      data: {
        format: string;
        isPublic: boolean;
        messageId: number;
        reportName: string;
        reportParams: string;
      };
      isJobFinished: boolean;
      hasJobError: boolean;
    }[];
    pollingType: string; // "REPORT"
  };
}

/**
 * Store to request reports from the backend.
 * Also handles notifications and polling of reports that might be in the process of generating on the backend.
 */
@Store()
export default class ReportStore {
  private notificationStore = inject(NotificationStore);
  private modalStore = inject(ModalStore);
  private pollingIntervalRunning = false;
  private initialPollFlag = false;
  private isReportInProgress = false;
  private pollingInterval = 3000;

  triggerPolling() {
    // Do one poll on app initialisation to check if there are reports in progress
    this.initialPollFlag = true;
    this.poll().then(() => {
      // if initialPollFlag was not set to false in the first poll, then there is something to poll
      // -> start regular polling
      if (this.initialPollFlag) {
        this.startPolling();
      }
    });
  }

  public openReportsModal(
    rows: IReportExportingRow[],
    getReportUrl: (key: string, reportType: ReportType, extraOptions?: IReportExtraOption[]) => string,
    extraOptions?: IReportExtraOption[],
  ) {
    this.modalStore.openModalDialog({
      className: 'reports-modal-header',
      title: t('general.reports'),
      size: 'lg',
      cancelButton: {
        label: t('general.cancel'),
        onClick: () => this.modalStore.closeModal(),
      },
      mask: true,
      showFooterSeparator: true,
      children: <ReportsExportingTable rows={rows} extraOptions={extraOptions} getReportUrl={getReportUrl} />,
    });
  }

  public getReport(url: string) {
    if (this.pollingIntervalRunning || this.isReportInProgress) {
      this.notificationStore.info({
        title: t('general.reportAlreadyInProgress'),
        message: t('general.pleaseWaitForReportToBeFinished'),
      });
      return;
    }

    this.isReportInProgress = true;
    Axios.get<IReportResponse>(url)
      .then((response) => {
        if (response.data.data.error) {
          this.notificationStore.error({
            title: t('general.error'),
            message: t('general.reportCouldNotBeGenerated'),
            keepOpen: true,
          });
        } else {
          if (response.data.data.finished) {
            this.notificationStore.success({
              title: t('general.reportGenerated'),
              message: t('general.reportGeneratedAndReadyToDownload'),
              keepOpen: true,
              downloadLink: {
                url: '/WebUntis/reports.do?' + response.data.data.reportParams,
                label: response.data.data.reportName + '.' + response.data.data.format,
              },
            });
          } else {
            this.notificationStore.info({
              title: t('general.reportGenerating'),
              message: t('general.reportGeneratingAndAvailableSoon'),
            });
            this.startPolling();
          }
        }
      })
      .catch((error) => {
        this.notificationStore.error({
          title: t('general.error'),
          message: error.toString(),
          keepOpen: true,
        });
      })
      .finally(() => {
        this.isReportInProgress = false;
      });
  }

  private startPolling() {
    // only one interval must running at a time!
    if (this.pollingIntervalRunning) {
      return;
    }
    this.pollingIntervalRunning = true;
    const interval = setInterval(() => {
      this.poll(interval);
    }, this.pollingInterval);
  }

  private stopPolling(interval?: NodeJS.Timer) {
    this.pollingIntervalRunning = false;
    interval && clearInterval(interval);
  }

  private async poll(interval?: NodeJS.Timer) {
    await Axios.get<IPollingResponse>('WebUntis/api/polling/REPORT')
      .then((response) => {
        if (
          !response.data.data.hasRunningJobs &&
          (!response.data.data.pollingJobs || response.data.data.pollingJobs.length === 0)
        ) {
          // There are no jobs running -> stop polling
          this.stopPolling(interval);
          this.initialPollFlag = false;
        } else {
          // There is a report in progress
          response.data.data.pollingJobs.forEach((job) => {
            // a job just finished -> download the report!
            if (job.isJobFinished) {
              this.notificationStore.success({
                title: t('general.reportGenerated'),
                message: t('general.reportGeneratedAndReadyToDownload'),
                keepOpen: true,
                downloadLink: {
                  url: 'WebUntis/reports.do?msgId=' + job.data.messageId + '&' + job.data.reportParams,
                  label: job.data.reportName + '.' + job.data.format,
                },
              });
            }
          });
        }
      })
      .catch((error) => {
        this.notificationStore.error({
          title: t('general.error'),
          message: error.toString(),
          keepOpen: true,
        });
        this.stopPolling(interval);
        this.initialPollFlag = false;
      });
  }
}
