import ResizeObserver from 'resize-observer-polyfill';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';

import { useComponentWillUnmount } from '@/hooks/useComponentWillUnmount';

interface IState {
  hiddenItemCount: number | undefined;
  hideInnerContent: boolean;
}

/**
 * Hook to handle calculating the number of hidden child items in the container not visible on RHS.
 * @returns object containing ref and hiddenItemCount {@link {ref, hiddenItemCount}}
 */
export function useLessonCardHiddenItemCount(isTopElement: boolean | undefined) {
  const ref = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<IState>(() => ({
    hiddenItemCount: undefined,
    hideInnerContent: false,
    hideRow: false,
  }));
  const [resizeObserver] = useState<ResizeObserver>(
    () =>
      new ResizeObserver(() => {
        if (ref.current) {
          const rowContainer = ref.current;
          setState(getPlusXValue(rowContainer));
        }
      }),
  );

  useLayoutEffect(() => {
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }
  });

  useComponentWillUnmount(() => {
    resizeObserver.disconnect();
  });

  const elementHiddenThreshold = useMemo(() => {
    return isTopElement ? 21 : 19;
  }, [isTopElement]);

  const getPlusXValue = (rowContainer: Element): IState => {
    const innerContainer = rowContainer.children[0];
    if (innerContainer) {
      const elements: Element[] = Array.from(innerContainer.children).filter(
        (element) => !element.className.toLowerCase().includes('vertical-divider'),
      );
      const innerContainerOffsetWidth = innerContainer.getBoundingClientRect().width;
      const innerContainerScrollWidth = innerContainer.scrollWidth;

      if (elements.length > 1 && innerContainerOffsetWidth < elementHiddenThreshold) {
        return {
          hiddenItemCount: elements.length,
          hideInnerContent: true,
        };
      }

      let hiddenCount = 0;
      if (elements.length > 1 && innerContainerScrollWidth > innerContainerOffsetWidth + elementHiddenThreshold) {
        elements.forEach((element) => {
          const elementLeft = element.getBoundingClientRect().x - innerContainer.getBoundingClientRect().x;
          if (elementLeft > innerContainerOffsetWidth - elementHiddenThreshold) {
            hiddenCount++;
          }
        });

        return {
          hiddenItemCount: hiddenCount,
          hideInnerContent: false,
        };
      } else {
        return {
          hiddenItemCount: undefined,
          hideInnerContent: false,
        };
      }
    } else {
      return {
        hiddenItemCount: undefined,
        hideInnerContent: false,
      };
    }
  };

  return { ref: ref, ...state };
}
