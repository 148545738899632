import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { IDropDownItem, SingleDropDown } from '@/ui-components/drop-down/drop-down';
import { SchoolYearDto, WuCurrentUserDtoRolesEnum } from '@untis/wu-rest-view-api';
import useStore from '@/hooks/useStore';
import SchoolYearStore from '@/stores/schoolyear-store';
import { TestIds } from '@/testIds';
import ConfigStore from '@/stores/config-store';
import './global-school-year-selection.less';
import { Tooltip } from '@/ui-components';

interface IProps {
  isCollapsed: boolean;
}

const GlobalSchoolYearSelection = observer((props: IProps) => {
  const schoolYearStore = useStore(SchoolYearStore);
  const configStore = useStore(ConfigStore);
  const { t } = useTranslation();
  const actualSchoolYearTranslation = t('menu.menuItems.gsys.actualSchoolYear');
  const schoolYearTranslation: string = t('menu.menuItems.gsys.schoolYear');
  const noSchoolYearSelectedTranslation: string = t('menu.menuItems.gsys.noSchoolYearSelected');
  const emptyStateName: string = t('menu.menuItems.gsys.emptySchoolYear');
  const maxWithInPixel: number = 188;
  const collapsedNavigationBarWidth: number = 76;
  const isEmptyState = useMemo(() => {
    return (
      schoolYearStore.schoolYears.length <= 0 || !schoolYearStore.schoolYears || !schoolYearStore.currentSchoolYear
    );
  }, [schoolYearStore.currentSchoolYear, schoolYearStore.schoolYears]);

  const emptySchoolYearState = useMemo(() => {
    return isEmptyState && props.isCollapsed ? emptyStateName.replace(schoolYearTranslation, '') : emptyStateName;
  }, [emptyStateName, isEmptyState, props.isCollapsed, schoolYearTranslation]);

  const getSchoolYearRange = useCallback((schoolYear: SchoolYearDto) => {
    if (!schoolYear || !schoolYear.dateRange) {
      return { startYear: null, endYear: null };
    }

    const startYear = new Date(schoolYear.dateRange.start).getFullYear().toString();
    const endYear = new Date(schoolYear.dateRange.end).getFullYear().toString();
    return { startYear, endYear };
  }, []);

  const shortenSchoolYear = useCallback((schoolYear: string) => {
    return `${schoolYear.substring(schoolYear.length - 2)}`;
  }, []);

  const createSchoolYearLabel = useCallback(
    (schoolYear: SchoolYearDto | undefined, isNavigationCollapsed?: boolean): string => {
      if (!schoolYear || !schoolYear.name) {
        return emptySchoolYearState;
      }

      const { startYear, endYear } = getSchoolYearRange(schoolYear);

      if (!(startYear || endYear)) {
        return emptySchoolYearState;
      }

      const longSchoolYearName = schoolYear.name;
      const shortSchoolYearName =
        startYear === endYear
          ? shortenSchoolYear(startYear)
          : `${shortenSchoolYear(startYear)}/${shortenSchoolYear(endYear)}`;
      return isNavigationCollapsed ? shortSchoolYearName : longSchoolYearName;
    },
    [emptySchoolYearState, getSchoolYearRange, shortenSchoolYear],
  );

  const selectedSchoolyearTooltip = useCallback(() => {
    if (isEmptyState) {
      return noSchoolYearSelectedTranslation;
    }
    return `${actualSchoolYearTranslation}: ${schoolYearStore.currentSchoolYear?.name}`;
  }, [
    actualSchoolYearTranslation,
    isEmptyState,
    noSchoolYearSelectedTranslation,
    schoolYearStore.currentSchoolYear?.name,
  ]);

  const createDropDownItem = useCallback(
    (schoolYear: SchoolYearDto, index: number): IDropDownItem => {
      const label: string = createSchoolYearLabel(schoolYear, false);
      return {
        id: schoolYear.id ? `${schoolYear.id}` : `${index}`,
        label: label,
      };
    },
    [createSchoolYearLabel],
  );
  const dropDownItems = useMemo(() => {
    const dropDownItems: IDropDownItem[] = [];
    schoolYearStore.schoolYears.map((schoolYear: SchoolYearDto, index: number) => {
      const dropDownItem: IDropDownItem = createDropDownItem(schoolYear, index);
      dropDownItems.push(dropDownItem);
    });

    return dropDownItems;
  }, [createDropDownItem, schoolYearStore.schoolYears]);

  const selectedSchoolYear = useMemo(() => {
    if (isEmptyState) {
      return props.isCollapsed ? emptySchoolYearState : emptyStateName;
    }

    if (props.isCollapsed) {
      return createSchoolYearLabel(schoolYearStore.currentSchoolYear, props.isCollapsed);
    }

    const currentSchoolYear = schoolYearStore.currentSchoolYear?.id;
    return currentSchoolYear
      ? dropDownItems.find((dropDownItem) => dropDownItem.id === `${currentSchoolYear}`)?.id
      : dropDownItems[0].id;
  }, [
    isEmptyState,
    props.isCollapsed,
    schoolYearStore.currentSchoolYear,
    dropDownItems,
    emptySchoolYearState,
    emptyStateName,
    createSchoolYearLabel,
  ]);

  const isDropdownDisabled = useMemo(() => {
    const firstUserRole = configStore.firstUserRole;

    return !(
      firstUserRole === WuCurrentUserDtoRolesEnum.ADMIN ||
      firstUserRole === WuCurrentUserDtoRolesEnum.TEACHER ||
      firstUserRole === WuCurrentUserDtoRolesEnum.DIRECTORATE ||
      firstUserRole === WuCurrentUserDtoRolesEnum.STAFF
    );
  }, [configStore.firstUserRole]);

  const onChangeSchoolYear = (schoolYearId: string | undefined) => {
    if (!schoolYearId) {
      return;
    }

    const schoolYearIdNumber: number = Number.parseInt(schoolYearId);
    const selectedSchoolYear: SchoolYearDto | undefined = schoolYearStore.getCurrentSchoolYearById(schoolYearIdNumber);
    schoolYearStore.setCurrentSchoolYear(selectedSchoolYear);
  };

  return (
    <>
      <Tooltip title={selectedSchoolyearTooltip} placement="right" autoAdjustOverflow={false}>
        <div
          className={clsx('global-school-year-selection-container', {
            'gsys--collapsed': props.isCollapsed,
          })}
          id={TestIds.GLOBAL_SCHOOL_YEAR_SELECTION_SECTION}
        >
          <SingleDropDown
            disabled={isDropdownDisabled}
            placeholder={selectedSchoolYear}
            // subtract 4px for margin left/right 2px
            maxWidth={props.isCollapsed ? collapsedNavigationBarWidth - 4 : maxWithInPixel}
            items={dropDownItems}
            hideIcons={props.isCollapsed || isDropdownDisabled}
            value={selectedSchoolYear}
            onChange={(schoolYearId) => onChangeSchoolYear(schoolYearId)}
          />
        </div>
      </Tooltip>
    </>
  );
});

export default GlobalSchoolYearSelection;
