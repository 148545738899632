import { RefObject } from 'react';
import { Dayjs } from 'dayjs';

import RefStore from '@/stores/ref-store';
import { inject, Store } from '@/types/store';
import { dayjsFormatWithTimeDeprecated } from '@/utils/date/date-util';

/**
 * These types are defined by legacy WebUntis
 */
enum MessageType {
  INIT = 'INIT',
  // NOTIFY = 'NOTIFY', uncomment if needed
  // FINISH = 'FINISH', uncomment if needed
}

// If a modal dialog is open in legacy WebUntis, we also have to lock the new UI.
// There are two kinds of modals in legacy WebUntis (JSP and React). To prevent concurrency
// issues we handle React and JSP Dialogs separately.
export enum HandleMessageAction {
  NAVIGATION = 'NAVIGATION', // legacy WebUntis navigated to another site
  RELOAD = 'RELOAD', // legacy WebUntis triggers a full page reload
  OPEN_MODAL_REACT = 'OPEN_MODAL_REACT', // legacy WebUntis opened a modal Dialog (React)
  CLOSE_MODAL_REACT = 'CLOSE_MODAL_REACT', // legacy WebUntis closed a modal Dialog (React)
  OPEN_MODAL_DOJO = 'OPEN_MODAL_DOJO', // legacy WebUntis opened a modal Dialog (DOJO)
  CLOSE_MODAL_DOJO = 'CLOSE_MODAL_DOJO', // legacy WebUntis closed a modal Dialog (DOJO)
  NOTIFY = 'NOTIFY', // legacy WebUntis sends a notification message
  SHOW_SINGLE_PERIOD_DETAILS = 'SHOW_SINGLE_PERIOD_DETAILS', // WU wants the new UI to open the period details dialog
  SHOW_PERIOD_DETAILS = 'SHOW_PERIOD_DETAILS', // WU wants the new UI to open the period details dialog
  SELECTED_DEPARTMENT_ID = 'SELECTED_DEPARTMENT_ID', // tells legacy WU which department is currently selected
  SHOW_STUDENT_DELETE_DIALOG = 'SHOW_STUDENT_DELETE_DIALOG',
  SHOW_LEGAL_GUARDIAN_DELETE_DIALOG = 'SHOW_LEGAL_GUARDIAN_DELETE_DIALOG',
  SHOW_APPRENTICE_REPRESENTATIVES_DELETE_DIALOG = 'SHOW_APPRENTICE_REPRESENTATIVES_DELETE_DIALOG',
  LEGACY_MESSAGE_ACTION = 'LEGACY_MESSAGE_ACTION', // legacy WU sent a message, saved a draft or aborted message dialog
  SELECT_STUDENT = 'SELECT_STUDENT', // legacy WU selected a different student
  LEGACY_TT_SEARCH_CHANGED = 'LEGACY_TT_SEARCH_CHANGED', // message from legacy WU about changed timetable search params
  SCROLL = 'SCROLL', // legacy WU scrolled. Payload should contain id of scrolling element and the scrollTop value
  OPEN_UNREAD_MESSAGES = 'OPEN_UNREAD_MESSAGES', // legacy WU wants to navigate to the Messages 2021 UI
  OPEN_TIMETABLING = 'OPEN_TIMETABLING', // legacy WU wants to navigate to the Timetabling 2021 UI
  // legacy WU class register notifies about changes related to the lesson topic, homeworks or absences
  CR_OVERVIEW_PERIOD_DETAILS_UPDATE = 'CR_OVERVIEW_PERIOD_DETAILS_UPDATE',
  OPEN_SEND_MESSAGE_DIALOG = 'OPEN_SEND_MESSAGE_DIALOG', // opens the message sending dialog
  OPEN_ABSENCE_SIDEBAR = 'OPEN_ABSENCE_SIDEBAR', // legacy WU wants us to open the sidebar for absences
  USER_ACTIVITY = 'USER_ACTIVITY',
  SELECT_SCHOOLYEAR = 'SELECT_SCHOOLYEAR',
  ANALYTICS_TRACK_EVENT = 'ANALYTICS_TRACK_EVENT', // legacy WU wants us to track an analytics event
  AUTOMATIC_STUDENT_ABSENCE_NOTIFICATIONS_SENT = 'AUTOMATIC_STUDENT_ABSENCE_NOTIFICATIONS_SENT',
}

enum PostMessageAction {
  NAVIGATION = 'NAVIGATION', // legacy WebUntis navigated to another site
  SELECT_DEPARTMENT = 'SELECT_DEPARTMENT', // tells legacy WebUntis that it should select a department
  RELOAD_CONTENT = 'RELOAD_CONTENT', // tells legacy WebUntis to Reload the page content
  GO_BACK = 'GO_BACK', // tells legacy WebUntis to go back. For example in forms after success save
  MOVE_PERIOD = 'MOVE_PERIOD', // tells legacy WebUntis that we want to move a period
  CREATE_EXAM = 'CREATE_EXAM', // tells legacy WebUntis that we want to create an exam
  EDIT_EXAM = 'EDIT_EXAM', // tells legacy WebUntis that we want to edit an existing exam
  CREATE_SUPPORT_PLAYGROUND = 'CREATE_SUPPORT_PLAYGROUND', // Action for the Create Dialog for a support playground
  MY_BOOKING = 'MY_BOOKING', // tells legacy WebUntis to open the detail booking page
  SELECT_STUDENT = 'SELECT_STUDENT', // tells legacy WebUntis, that the (parent) user selected a different child
  SELECT_SCHOOLYEAR = 'SELECT_SCHOOLYEAR',
}

/**
 * Sometimes the new frontend needs to send messages through the iframe to legacy WebUntis (that need to
 * be handled by legacy WebUntis). This store offers the functionality to do that.
 */
@Store()
export default class PostMessageStore {
  private refStore = inject(RefStore);

  postNavigationMessage = (iFrameRef: RefObject<HTMLIFrameElement>, page: string, replace: boolean) => {
    this.postToIFrame(iFrameRef, PostMessageAction.NAVIGATION, {
      contentUrl: page,
      replace,
    });
  };

  postMovePeriodMessage = (ttid: number) => {
    this.postToEmbeddedWebUntis(PostMessageAction.MOVE_PERIOD, {
      ttid: ttid,
    });
  };

  postDepartmentSelectedMessage = (departmentId: number) => {
    this.postToEmbeddedWebUntis(PostMessageAction.SELECT_DEPARTMENT, { id: departmentId });
  };

  postStudentSelectedMessage = (studentId: number) => {
    this.postToEmbeddedWebUntis(PostMessageAction.SELECT_STUDENT, { id: studentId });
  };

  postSchoolYearSelectedMessage = (schoolYearId: number | undefined) => {
    this.postToEmbeddedWebUntis(PostMessageAction.SELECT_SCHOOLYEAR, { id: schoolYearId });
  };

  postReloadContentMessage = () => {
    this.postToEmbeddedWebUntis(PostMessageAction.RELOAD_CONTENT);
  };

  postGoBackMessage = () => {
    this.postToEmbeddedWebUntis(PostMessageAction.GO_BACK);
  };

  postOpenBookingDetailsForId = (selId: number, ttid: number, isEmbeddedInDetailsView: boolean) => {
    this.postToEmbeddedWebUntis(PostMessageAction.MY_BOOKING, {
      selId,
      ttid,
      isEmbeddedInDetailsView,
    });
  };

  postEditExamMessageWithArgs = (
    selId: number,
    ttId: number,
    isBlockSelected: boolean,
    elementId: number,
    elementType: number,
    startDateTime: Dayjs,
    endDateTime: Dayjs,
  ) => {
    this.postToEmbeddedWebUntis(PostMessageAction.EDIT_EXAM, {
      selId: selId,
      ttid: ttId,
      isBlockSelected: isBlockSelected,
      elementId: elementId,
      elementType: elementType,
      startDateTime: dayjsFormatWithTimeDeprecated(startDateTime),
      endDateTime: dayjsFormatWithTimeDeprecated(endDateTime),
    });
  };

  postCreateSupportPlayground = () => {
    this.postToEmbeddedWebUntis(PostMessageAction.CREATE_SUPPORT_PLAYGROUND);
  };

  private postToEmbeddedWebUntis = (action: PostMessageAction, payload?: any) => {
    if (this.refStore.embeddedWebUntisIFrameRef && this.refStore.embeddedWebUntisIFrameRef.current) {
      this.postToIFrame(this.refStore.embeddedWebUntisIFrameRef, action, payload);
    }
  };

  private postToIFrame = (iFrame: RefObject<HTMLIFrameElement>, action: PostMessageAction, payload?: any) => {
    if (iFrame && iFrame.current && iFrame.current.contentWindow) {
      iFrame.current.contentWindow.postMessage(
        {
          __untis: true,
          messageType: MessageType.INIT,
          payload: Object.assign(
            {
              type: action,
            },
            payload,
          ),
        },
        '*',
      );
    }
  };
}
