import dayjs from 'dayjs';

import { ICustomDateRange } from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';

interface IDateRange {
  start: string;
  end: string;
}

interface ISemester {
  id: number;
  name: string;
  dateRange: IDateRange;
  parentId: number;
}

/**
 * Utility function that takes all semesters and returns custom date ranges for the list views date picker.
 * Results are sorted
 * @param schoolyears
 */
export const getCustomDateRangesFromSemesters = (semesters: ISemester[]): ICustomDateRange[] => {
  const sortedSemesters = semesters.sort((a, b) => {
    const startA = dayjs(a.dateRange.start);
    const startB = dayjs(b.dateRange.start);

    return startA.isAfter(startB) ? 1 : -1;
  });

  return sortedSemesters.map((sem) => {
    return {
      startDate: dayjs(sem.dateRange.start),
      endDate: dayjs(sem.dateRange.end),
      name: sem.name,
    };
  });
};
