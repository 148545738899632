import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route } from 'react-router-dom';
import { Form } from 'antd';

import { ExcuseStatusStore } from './excuse-status-store';
import { MasterDataExcuseStatusList } from './excuse-status-list';

import { ExcuseStatusNew } from '@/pages/master-data/excuse-status/excuse-status-new';
import { ExcuseStatusEdit } from '@/pages/master-data/excuse-status/excuse-status-edit';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import useStore from '@/hooks/useStore';

export const MasterDataExcuseStatus = observer(() => {
  const [form] = Form.useForm<ExcuseStatusStore>();
  const excuseStatusStore = useStore(ExcuseStatusStore);

  useComponentDidMount(() => {
    excuseStatusStore.setFormInstance(form);
  });

  return (
    <>
      <MasterDataExcuseStatusList />
      <Route path="/excuse-status/new" render={() => <ExcuseStatusNew />} />
      <Route path="/excuse-status/edit/:id" render={() => <ExcuseStatusEdit />} />
    </>
  );
});
