import Axios from 'axios';

import environmentCore from '../../config/environment';

import { Store } from '@/types/store';

interface IEnvironment {
  // last commit hash of the frontend build, can be used to version files, empty during development
  version?: string;
  webUntisURL?: string;
  spApiURL: string;
  websocketURL: string;
  appStoreURLs: {
    android: string;
    ios: string;
  };
}

export const getEnvironment = (): IEnvironment => {
  return { ...environmentCore };
};

export enum DomainStagingType {
  Nightly = 'nightly',
  Staging = 'staging',
  Review = 'review',
  Beta = 'beta',
  Integration = 'integration',
}

export type DomainType = { kind: 'production' } | { kind: 'local' } | { kind: 'staging'; type: DomainStagingType };

@Store()
export default class EnvironmentStore {
  private environment: IEnvironment = getEnvironment();

  async init(): Promise<void> {
    return new Promise(async (resolve) => {
      let override;
      try {
        override = (await Axios.get('/environment.json').then(({ data }) => data)) || {};
        if (typeof override !== 'object') {
          override = {};
        }
      } catch (error) {
        override = {};
      }

      this.environment = {
        ...this.environment,
        ...override,
      };

      // if no Webuntis Url is configured, we use the current hostname as base for our WebUntis Url
      if (!this.environment.webUntisURL) {
        this.environment.webUntisURL = window.location.protocol + '//' + window.location.hostname + '/WebUntis';
      }

      resolve();
    });
  }

  get version() {
    return this.environment.version;
  }

  get webUntisURL() {
    return this.environment.webUntisURL;
  }

  get spApiURL() {
    return this.environment.spApiURL;
  }

  get websocketURL() {
    return this.environment.websocketURL;
  }

  get appStoreURLs() {
    return this.environment.appStoreURLs;
  }

  isProduction(): boolean {
    const domainType = this.getDomainType(this.webUntisURL || window.location.hostname);
    return domainType.kind === 'production';
  }

  isStaging(domainType: DomainStagingType = DomainStagingType.Staging): boolean {
    const type = this.getDomainType(this.webUntisURL || window.location.hostname);
    return type.kind === 'staging' && type.type === domainType;
  }

  isNightly(): boolean {
    const type = this.getDomainType(this.webUntisURL || window.location.hostname);
    return type.kind === 'staging' && type.type === DomainStagingType.Nightly;
  }

  isIntegration(): boolean {
    const type = this.getDomainType(this.webUntisURL || window.location.hostname);
    return type.kind === 'staging' && type.type === DomainStagingType.Integration;
  }

  isLocal(): boolean {
    const type = this.getDomainType(this.webUntisURL || window.location.hostname);
    return type.kind === 'local';
  }

  getDomainType(url: string): DomainType {
    const allStagingTypes = Object.values(DomainStagingType);

    for (const stagingType of allStagingTypes) {
      if (url.includes(`${getSubdomain(stagingType)}.`)) {
        return { kind: 'staging', type: stagingType };
      }
    }

    if (
      url.includes('webuntis.local') ||
      url.includes('webuntis.localhost') ||
      url.includes('webuntis.dod') ||
      url.includes('webuntis.mini')
    ) {
      return { kind: 'local' };
    }

    return { kind: 'production' };
  }
}

function getSubdomain(stagingType: DomainStagingType): string {
  return stagingType;
}
