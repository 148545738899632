import React, { useState, useCallback } from 'react';
import { Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { ITimeRangePickerTimeSlot } from '@/ui-components/time-range-picker/time-range-picker-store';
import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import DateTimeRangePicker, { IDateTimePickerProps } from '@/ui-components/date-time-range/date-time-range-picker';

export type DateTimeRange = {
  startDateTime: Dayjs;
  endDateTime: Dayjs;
};

interface IProps extends IFormItemProps<DateTimeRange> {
  value?: DateTimeRange;
  onChange?: (timeRange: DateTimeRange) => void;
  timeSlots: ITimeRangePickerTimeSlot[];
  onlySameDay?: boolean;
  disabledDate?: (date: Dayjs) => boolean;
}

interface IDateTimeRangePickerWrapper extends Omit<IDateTimePickerProps, 'startDateTime' | 'endDateTime'> {
  value?: DateTimeRange;
  initialValue?: DateTimeRange;
  onChange?: (timeRange: DateTimeRange) => void;
}

export const DateTimeRangePickerWrapperUpdateProps = (props: IDateTimeRangePickerWrapper) => {
  const [dateTimeRange, setDateTimeRange] = useState<DateTimeRange>(
    () => props.initialValue ?? { startDateTime: dayjs(), endDateTime: dayjs() },
  );

  const handleSelect = useCallback((timeRange: DateTimeRange) => {
    const { startDateTime: startDateTime, endDateTime: endDateTime } = timeRange;
    if (
      !props.initialValue?.endDateTime.isSame(endDateTime) ||
      !props.initialValue?.startDateTime.isSame(startDateTime)
    ) {
      setDateTimeRange(timeRange);
      props.onChange && props.onChange(timeRange);
    }
  }, []);

  return {
    dateTimeRange,
    handleSelect,
  };
};

export const DateTimeRangePickerWrapper = (props: IDateTimeRangePickerWrapper) => {
  const updateProps = DateTimeRangePickerWrapperUpdateProps(props);
  const { dateTimeRange, handleSelect } = updateProps;

  return (
    <DateTimeRangePicker
      startDateTime={dateTimeRange.startDateTime}
      endDateTime={dateTimeRange.endDateTime}
      timeSlots={props.timeSlots}
      onlySameDay={props.onlySameDay}
      disabledDate={props.disabledDate}
      disabled={props.disabled}
      onDateTimeRangeChange={(startDateTime: Dayjs, endDateTime: Dayjs) => handleSelect({ startDateTime, endDateTime })}
    />
  );
};

export const FormDateTimeRangePicker = (props: IProps) => {
  return (
    <Form.Item
      name={props.name}
      label={props.label}
      className="form-date-time-range-picker"
      rules={props.rules}
      dependencies={props.dependencies}
      initialValue={props.initialValue}
    >
      <DateTimeRangePickerWrapper
        timeSlots={props.timeSlots}
        initialValue={props.initialValue}
        onlySameDay={props.onlySameDay}
        disabledDate={props.disabledDate}
        disabled={props.disabled}
        onChange={props.onChange}
      />
    </Form.Item>
  );
};
