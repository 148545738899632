import { observer } from 'mobx-react-lite';
import React from 'react';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

import { Col } from '@/ui-components';
import { PlatformApplicationUrlDto, PlatformApplicationUrlDtoRoleEnum } from '@untis/wu-rest-view-api';
import DeprecatedTextFormItem from '@/ui-components/deprecated-form/deprecated-text-form-item/deprecated-text-form-item';
import { getCommonRules } from '@/ui-components/deprecated-form/common-form-rules';
import './platform-configuration-tab.less';
import { AvailableApplication } from '@pa/utils/platform-utils';
import { PlatformApplicationConfigurationTranslation } from '@pa/utils/platform-application-translations';

interface IProps {
  info: PlatformApplicationUrlDto;
  labelName: string;
  openNewTabReadonly?: boolean;
}

const PlatformConfigurationTab = observer((props: IProps) => {
  const { t } = useTranslation();
  const info = props.info;

  const getPlatformAvailability = () => {
    const elements = [];
    elements.push(AvailableApplication.WEB_UNTIS);
    if (info.mobileView || info.mobileAppLink) {
      elements.push(AvailableApplication.UNTIS_MOBILE);
    }

    return elements.join(', ');
  };

  return (
    <div className="tab-content">
      {info.role === PlatformApplicationUrlDtoRoleEnum.DEFAULT && (
        <div className="default-hint" data-testid="default-hint">
          {t(PlatformApplicationConfigurationTranslation.defaultTabHint)}
        </div>
      )}
      <div className="name">
        <Col className="label-column">
          <label>{t(props.labelName)}</label>
        </Col>
        <Col>
          <DeprecatedTextFormItem
            value={info.menuName}
            onChange={(newValue) => {
              info.menuName = newValue;
            }}
            placeholder={t(PlatformApplicationConfigurationTranslation.namePlaceholder)}
            rules={getCommonRules({ required: true }, t('general.name'))}
          />
        </Col>
      </div>
      <div className="available-at">
        <Col className="label-column">
          <label>{t(PlatformApplicationConfigurationTranslation.availableAt)}</label>
        </Col>
        <Col>
          <label>{getPlatformAvailability()}</label>
        </Col>
      </div>
      <div className="open-in-tab">
        <Col className="label-column">
          <label>{t(PlatformApplicationConfigurationTranslation.openInNewTab)}</label>
        </Col>
        <Col>
          <Checkbox
            disabled={props.openNewTabReadonly}
            checked={info.openInTab}
            onChange={(e) => (info.openInTab = e.target.checked)}
          />
        </Col>
      </div>
    </div>
  );
});
export default PlatformConfigurationTab;
