import React, { RefObject } from 'react';
import { action, computed, observable, reaction } from 'mobx';
import { t } from 'i18next';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';

import { ExamLocksViewApi } from '@/stores/api-store';
import { inject, Store } from '@/types/store';
import { ExamDto, ExamLockDto, MasterDataRefDto } from '@untis/wu-rest-view-api/api';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import ConfigStore from '@/stores/config-store';
import { Columns, ColumnType, DateFormat } from '@/ui-components/wu-table/wu-table-column-mapper';
import { booleanSorting, defaultSorting, sortingByDayjs } from '@/utils/sorting/sorting-util';
import { IListViewAction, IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import { AdministrationExamLockForm } from '@ad/exam-lock/administration-exam-lock-form';
import { createChunks } from '@/utils/array/array-util';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { formatDateTimeForServerRequest } from '@/utils/date/date-util';
import { IErrorResponseData } from '@/utils/error-handling/error-handling';
import { DateTimeRange } from '@/ui-components/wu-form/form-date-time-range-picker/form-date-time-range-picker';
import SchoolYearStore from '@/stores/schoolyear-store';
import { AbstractListViewStore } from '@/pages/master-data/common/abstract-list-view-store';
import { TimegridStore } from '@/stores/timegrid-store';
import { ISelectItem } from '@/ui-components/select/select';

export const OPTION_TYPE_ALL = '0';
export const OPTION_TYPE_SELECT = '1';

export interface IExamLockRow extends ITableRowKey {
  id: number;
  classes: string[];
  examType: string;
  from: Dayjs;
  to: Dayjs;
  startTime: Dayjs;
  endTime: Dayjs;
  isContinuous: boolean;
  dayOfTheWeek: string;
  text: string;
  canEdit?: boolean;
  canDelete?: boolean;
}

export interface IExamLockForm {
  classSelectionType: string;
  classes: IMultiTagSelectItem[];
  examType: string;
  dateTimeRange: DateTimeRange;
  settings: string[];
  weekDay: string;
  text: string;
}

@Store()
export class AdministrationExamLockStore extends AbstractListViewStore<IExamLockForm> {
  private rightStore: RightsStore = inject(RightsStore);
  private configStore: ConfigStore = inject(ConfigStore);
  private schoolyearStore: SchoolYearStore = inject(SchoolYearStore);
  private timegridStore: TimegridStore = inject(TimegridStore);

  @observable private _examLocks: ExamLockDto[] = [];
  @observable private _classes: MasterDataRefDto[] = [];
  @observable private _examTypes: MasterDataRefDto[] = [];
  @observable private _classSelectionType: string = OPTION_TYPE_ALL;
  @observable private _examLockConflicts: ExamDto[] = [];
  @observable private _errorTextRef: RefObject<HTMLDivElement> | undefined = undefined;
  @observable private _isFormLoading: boolean = false;

  private _weekDaysMap: Map<number, string> = new Map<number, string>();

  constructor() {
    super({ right: Right.EXAMLOCK, elementType: ElementType.ALL });
    this._weekDaysMap = new Map<number, string>([
      [0, t('general.everyDay')],
      [2, t('weekDays.monday')],
      [3, t('weekDays.tuesday')],
      [4, t('weekDays.wednesday')],
      [5, t('weekDays.thursday')],
      [6, t('weekDays.friday')],
      [7, t('weekDays.saturday')],
      [1, t('weekDays.sunday')],
    ]);
    this.fetchData();

    reaction(
      () => this.schoolyearStore.currentSchoolYear,
      () => {
        this._modalStore.closeModal();
        this.fetchData();
      },
    );
  }

  @action
  private async fetchData() {
    this.setIsDataLoading(true);
    await this.getExamLocks();
    this.setIsDataLoading(false);
  }

  @action
  async getExamLocks() {
    const response = await ExamLocksViewApi.getExamLocks();
    this._examLocks = response.data.examLocks || [];
  }

  @action
  public async getExamLockFormData(examLock?: ExamLockDto) {
    this._isFormLoading = true;

    const start = this.toDayjs(examLock?.start) ?? dayjs();
    const schoolyear = this.schoolyearStore.getSchoolYearOfDate(start);

    const response = await ExamLocksViewApi.getExamLockForm(schoolyear?.id ?? -1);
    this._classes = response.data.classes || [];
    this._examTypes = response.data.examTypes || [];

    this._isFormLoading = false;
  }

  public toDayjs(date: string | undefined): Dayjs | undefined {
    return date ? dayjs(date) : undefined;
  }

  /**
   * Sets the year of the passed date to the selected school year's year for default
   * values in DateTimePicker and UX purposes.
   */
  public setDefaultYearForSchoolYearDate(date: Dayjs): Dayjs {
    const currentSchoolYearDateRange = this.schoolyearStore.currentSchoolYear?.dateRange;

    if (currentSchoolYearDateRange && !this.schoolyearStore.isDateInCurrentSchoolYear(date)) {
      return dayjs(date).year(dayjs(currentSchoolYearDateRange.start).year());
    }

    return dayjs(date);
  }

  /**
   * Checks if a particular date visible in the DatePicker is outside the selected school year.
   */
  @action.bound
  isDateOutsideSelectedSchoolYear(date: Dayjs): boolean {
    return !this.schoolyearStore.isDateInCurrentSchoolYear(date);
  }

  /**
   * Return the initial startDateTime and endDateTime for DateTimePicker based on SY and TF.
   */
  public getInitialDateTimeRangePickerValue(examLock: ExamLockDto | undefined): DateTimeRange {
    return {
      startDateTime: this.getStartDateTime(examLock),
      endDateTime: this.getEndDateTime(examLock),
    };
  }

  getStartDateTime(examLock: ExamLockDto | undefined): Dayjs {
    const defaultStartDateTime = this.setDefaultYearForSchoolYearDate(
      this.timegridStore.timeRangePickerTimeSlots[0].startTime,
    );

    return this.toDayjs(examLock?.start) ?? defaultStartDateTime;
  }

  getEndDateTime(examLock: ExamLockDto | undefined): Dayjs {
    const defaultEndDateTime = this.setDefaultYearForSchoolYearDate(
      this.timegridStore.timeRangePickerTimeSlots[this.timegridStore.timeRangePickerTimeSlots.length - 1].endTime,
    );

    return this.toDayjs(examLock?.end) ?? defaultEndDateTime;
  }

  @computed
  get isFormLoading() {
    return this._isFormLoading;
  }

  @computed
  get classSelectionType(): string {
    return this._classSelectionType;
  }

  @action.bound
  setClassSelectionType(classSelectionType?: string) {
    this._classSelectionType = classSelectionType ?? OPTION_TYPE_ALL;
  }

  @computed
  get classSelectionTypes(): ISelectItem[] {
    return [
      {
        id: OPTION_TYPE_ALL.toString(),
        label: t('general.allClasses'),
      },
      {
        id: OPTION_TYPE_SELECT.toString(),
        label: t('general.chooseClasses'),
      },
    ];
  }

  @computed
  get classes(): IMultiTagSelectItem[] {
    return this._classes.map((g) => {
      return {
        id: g.id.toString(),
        label: g.displayName ?? '',
      };
    });
  }

  @computed
  get examTypes(): ISelectItem[] {
    const examTypeDropdownItems = this._examTypes.map((examType) => {
      return {
        id: examType.id.toString(),
        label: examType.displayName ?? '',
      };
    });
    const allExamTypesOption = {
      id: OPTION_TYPE_ALL.toString(),
      label: t('administration.examLock.form.allExaminations'),
    };
    return [allExamTypesOption, ...examTypeDropdownItems];
  }

  @computed
  get weekDays(): ISelectItem[] {
    const dayOfTheWeeks: ISelectItem[] = [];
    this._weekDaysMap.forEach((value, key) => {
      dayOfTheWeeks.push({
        id: key.toString(),
        label: value,
      });
    });
    return dayOfTheWeeks;
  }

  @computed
  get examLockConflicts(): ExamDto[] {
    return this._examLockConflicts;
  }

  @computed
  get rowData(): IExamLockRow[] {
    return this._examLocks?.map((e): IExamLockRow => {
      const { start, end } = e;
      const startDateTime = dayjs(start);
      const endDateTime = dayjs(end);

      return {
        key: e.id || -1,
        id: e.id || -1,
        classes: e.classes?.length === 0 ? [t('general.allClasses')] : e.classes?.map((e) => e.displayName ?? '') || [],
        examType: e.examType?.displayName || t('administration.examLock.form.allExaminations'),
        from: startDateTime,
        to: endDateTime,
        startTime: startDateTime,
        endTime: endDateTime,
        isContinuous: e.continuous || false,
        dayOfTheWeek: this._weekDaysMap.get(e.weekDay || 0) || '',
        text: e.text || '',
        canEdit: e.canEdit,
        canDelete: e.canDelete,
      };
    });
  }

  @computed
  get columns(): Columns<IExamLockRow> {
    const cols: Columns<IExamLockRow> = [
      {
        type: ColumnType.List,
        key: 'classes',
        header: t('general.class'),
        getEntries: (row: IExamLockRow) => row.classes,
        entryThreshold: 3,
      },
      {
        type: ColumnType.Text,
        key: 'examType',
        header: t('general.examinationType'),
        sorter: (a: IExamLockRow, b: IExamLockRow) => defaultSorting(a.examType, b.examType),
      },
      {
        type: ColumnType.Date,
        key: 'from',
        format: DateFormat.Date,
        header: t('general.fromDate'),
        sorter: (a: IExamLockRow, b: IExamLockRow) => sortingByDayjs(a.from, b.from),
      },
      {
        type: ColumnType.Date,
        key: 'to',
        format: DateFormat.Date,
        header: t('general.toDate'),
        sorter: (a: IExamLockRow, b: IExamLockRow) => sortingByDayjs(a.to, b.to),
      },
      {
        type: ColumnType.Date,
        key: 'startTime',
        format: DateFormat.Time,
        header: t('general.startTime'),
        sorter: (a: IExamLockRow, b: IExamLockRow) => sortingByDayjs(a.startTime, b.startTime),
      },
      {
        type: ColumnType.Date,
        key: 'endTime',
        format: DateFormat.Time,
        header: t('general.endTime'),
        sorter: (a: IExamLockRow, b: IExamLockRow) => sortingByDayjs(a.endTime, b.endTime),
      },
      {
        type: ColumnType.Boolean,
        key: 'isContinuous',
        header: t('general.continuous'),
        align: 'center',
        sorter: (a: IExamLockRow, b: IExamLockRow) => booleanSorting(a.isContinuous, b.isContinuous),
      },
      {
        type: ColumnType.DeprecatedTag,
        key: 'dayOfTheWeek',
        header: t('administration.examLock.tableColumns.dayOfTheWeek'),
        sorter: (a: IExamLockRow, b: IExamLockRow) => defaultSorting(a.dayOfTheWeek, b.dayOfTheWeek),
      },
      {
        type: ColumnType.Text,
        key: 'text',
        header: t('general.text'),
        sorter: (a: IExamLockRow, b: IExamLockRow) => defaultSorting(a.text, b.text),
      },
    ];

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.OverlayHoverActions,
        key: 'actions',
        actionIcons: this.columnActions,
      });
    }

    return cols;
  }

  @computed
  private get columnActions() {
    return [
      {
        tooltip: t('general.edit'),
        icon: 'edit',
        onClick: (row: IExamLockRow) => this.openEditForm(row),
        condition: (row: IExamLockRow) => !!row.canEdit,
      },
      {
        tooltip: t('general.delete'),
        icon: 'shared_trash',
        onClick: (row: IExamLockRow) => this.deleteExamLock(row.id),
        condition: (row: IExamLockRow) => !!row.canDelete,
      },
    ];
  }

  @computed
  get listViewActions(): IListViewAction[] {
    const actions: IListViewAction[] = [];
    if (this.configStore.isAdmin || this.rightStore.canCreate(Right.EXAMLOCK, ElementType.ALL, false)) {
      actions.push({
        label: t('general.new'),
        onClick: this.openCreateForm,
      });
    }
    actions.push({
      label: t('general.report'),
      onClick: () => {
        this.generateReport('ExamLock', 'pdf');
      },
    });

    return actions;
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<IExamLockRow>[] | undefined {
    return this.configStore.isAdmin || this.rightStore.canDelete(Right.EXAMLOCK, ElementType.ALL, false)
      ? [
          {
            label: t('general.delete'),
            icon: 'shared_trash',
            onClick: (rows) => this.bulkDeleteExamLocks(rows),
          },
        ]
      : undefined;
  }

  @action
  setErrorTextRef(errorTextRef: RefObject<HTMLDivElement>) {
    this._errorTextRef = errorTextRef;
  }

  @action.bound
  openCreateForm() {
    this.resetForm();
    this._modalStore.openModalDialog({
      children: <AdministrationExamLockForm store={this} />,
      title: t('administration.examLock.form.createFormTitle'),
      size: 'full-size',
      containsForm: true,
    });
  }

  @action.bound
  openEditForm(row: IExamLockRow) {
    const examLock = this.getExamLockDtoByRowId(row.id.toString());
    if (!examLock) {
      return;
    }
    this.resetForm();
    this._modalStore.openModalDialog({
      title: t('administration.examLock.form.editFormTitle'),
      size: 'full-size',
      children: <AdministrationExamLockForm store={this} examLock={examLock} />,
      containsForm: true,
    });
  }

  @action.bound
  private getExamLockDtoByRowId(id: string): ExamLockDto | undefined {
    return this._examLocks.find((e) => e.id?.toString() === id);
  }

  @action
  saveNewExamLock(value: IExamLockForm, saveAndNew: boolean) {
    const examLockDto = this.mapFormValuesToDto(value, undefined);

    ExamLocksViewApi.createExamLock(examLockDto)
      .then(async () => {
        this._notificationStore.success({ title: t('administration.examLock.messages.examLockCreated') });
        await this.getExamLocks();
        this.resetForm();
        if (!saveAndNew) {
          this._modalStore.closeModal();
        }
      })
      .catch((error) => {
        const axiosError: AxiosError = error as AxiosError;
        this.handleSaveAndUpdateError(axiosError, t('administration.examLock.messages.examLockCreatedError'));
      });
  }

  @action
  updateExamLock(value: IExamLockForm, examLock?: ExamLockDto) {
    const examLockDto = this.mapFormValuesToDto(value, examLock);

    ExamLocksViewApi.updateExamLock(examLockDto)
      .then(async () => {
        this._notificationStore.success({ title: t('administration.examLock.messages.examLockEdited') });
        await this.getExamLocks();
        this.resetForm();
        this._modalStore.closeModal();
      })
      .catch((error) => {
        const axiosError: AxiosError = error as AxiosError;
        this.handleSaveAndUpdateError(axiosError, t('administration.examLock.messages.examLockEditedError'));
      });
  }

  @action
  private handleSaveAndUpdateError(error: AxiosError, errorNotificationTitle: string) {
    if ((error.response?.data as IErrorResponseData)?.errorCode === 'VALIDATION_ERROR') {
      this.findConflictsForExamLock();
    } else if ((error.response?.data as IErrorResponseData)?.errorCode === 'FORBIDDEN') {
      this._notificationStore.error({
        title: errorNotificationTitle,
        message: (error.response?.data as IErrorResponseData)?.errorMessage,
      });
    } else {
      this._notificationStore.error({
        title: errorNotificationTitle,
        message: error.toString(),
      });
    }
  }

  @action
  async findConflictsForExamLock() {
    const examLock = this.mapFormValuesToDto(this.form.getFieldsValue());
    const response = await ExamLocksViewApi.findConflictsForExamLock(examLock);
    this._examLockConflicts = response.data.exams ?? [];
    this._errorTextRef && this._errorTextRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  private mapFormValuesToDto(formValues: IExamLockForm, examLock?: ExamLockDto): ExamLockDto {
    const selectedClasses = this._classes.filter((klasse) =>
      formValues.classes?.map((sc) => sc.id).includes(klasse.id.toString()),
    );
    const emptyMasterDataRef = {
      id: -1,
      displayName: '',
      shortName: '',
      longName: '',
    };

    return {
      id: examLock?.id ?? -1,
      classes: this._classSelectionType === OPTION_TYPE_ALL ? [emptyMasterDataRef] : selectedClasses,
      examType: this._examTypes.find((e) => e.id === Number(formValues.examType)) || emptyMasterDataRef,
      start: formatDateTimeForServerRequest(formValues.dateTimeRange.startDateTime),
      end: formatDateTimeForServerRequest(formValues.dateTimeRange.endDateTime),
      continuous: formValues.settings.includes('continuous'),
      weekDay: formValues.weekDay ? Number(formValues.weekDay) : 0,
      text: formValues.text,
    };
  }

  @action
  private resetForm() {
    this.form.resetFields();
    this._classSelectionType = OPTION_TYPE_ALL;
    this._examLockConflicts = [];
  }

  @action
  deleteExamLock(id: number) {
    this._modalStore.openDeletePrompt(t('administration.examLock.messages.deletePrompt'), '').then((result) => {
      if (result) {
        ExamLocksViewApi.deleteExamLock(id)
          .then(async () => {
            this._notificationStore.success({ title: t('administration.examLock.messages.examLockDeleted') });
            this._modalStore.closeModal();
            await this.getExamLocks();
          })
          .catch((error) => {
            if ((error.response?.data as IErrorResponseData)?.errorCode === 'FORBIDDEN') {
              this._notificationStore.error({
                title: t('administration.examLock.messages.examLockDeletedError'),
                message: (error.response?.data as IErrorResponseData)?.errorMessage,
              });
            } else {
              this._notificationStore.error({
                title: t('administration.examLock.messages.examLockDeletedError'),
                message: error.toString(),
              });
            }
          });
      }
    });
  }

  @action
  bulkDeleteExamLocks(rows: IExamLockRow[]) {
    this._modalStore
      .booleanUserPrompt({
        title: t('administration.examLock.messages.bulkDeletePrompt', { count: rows.length }),
        okButton: {
          label: t('general.yes'),
        },
        cancelButton: {
          label: t('general.cancel'),
        },
      })
      .then((result) => {
        if (result) {
          const chunks = createChunks<IExamLockRow>(rows, 100);
          chunks.forEach((chunk) => {
            ExamLocksViewApi.deleteExamLocks(chunk.map((row) => row.id))
              .then(() => {
                this._notificationStore.success({
                  title: t('administration.examLock.messages.examLocksDeleted'),
                });
                this.getExamLocks();
              })
              .catch((error) => {
                this._notificationStore.error({
                  title: t('administration.examLock.messages.examLocksDeletedError'),
                  message: error.toString(),
                });
              });
          });
        }
      });
  }
}
