import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import { Button } from '@/ui-components';

import './today-button.less';

interface IProps {
  isActive: boolean;
  handleClick: (todayDate: Dayjs) => void;
  testId?: string;
}

const TodayButton = (props: IProps) => {
  const { isActive, handleClick, testId } = props;
  const { t } = useTranslation();

  const handleTodayButtonClicked = () => {
    if (!isActive) {
      handleClick(dayjs());
    }
  };

  return (
    <Button
      className="today-button"
      testId={testId}
      type="secondary"
      size="small"
      outline={!isActive}
      onClick={handleTodayButtonClicked}
    >
      {t('general.today')}
    </Button>
  );
};

export default TodayButton;
