import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import TimetableGeneralSettingsStore, {
  ITimetableGeneralSettingsFormData,
  SHOW_DEPARTMENT_SELECTION,
  SHOW_DETAILS_FOR_PUBLIC,
} from '@te/settings/timetable-general/timetable-general-settings-store';
import { TestIds } from '@/testIds';
import { WUForm } from '@/ui-components';
import { FormCheckboxes, FormSection, WUFormCol, WUFormRow } from '@/ui-components/wu-form';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';

const TimetableGeneralSettings = observer(() => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ITimetableGeneralSettingsFormData>();
  const [store] = useState(() => new TimetableGeneralSettingsStore());

  useComponentDidMount(() => {
    store.fetchData();
  });

  const [generalSettingsInitialCheckboxValue] = useState<string[]>([]);
  if (store.timetableSettings?.showDepartmentSelection) {
    generalSettingsInitialCheckboxValue.push(SHOW_DEPARTMENT_SELECTION);
  }
  if (store.timetableSettings?.showDetailsForPublic) {
    generalSettingsInitialCheckboxValue.push(SHOW_DETAILS_FOR_PUBLIC);
  }

  if (store.isDataLoading) {
    return <EmptyIndicator title={t('general.loading')} />;
  }

  return (
    <WUForm<ITimetableGeneralSettingsFormData>
      form={form}
      fixedButtonBar={true}
      disableSubmitButton={store.submitAllDisabled}
      onValuesChange={() => store.setSubmitDisabled(false)}
      onSubmit={(form) => store.handleUpdateGeneralSettings(form)}
      maxWidth
      fullHeight
    >
      <WUFormCol lg={1} removePadding>
        <WUFormRow>
          <WUFormCol lg={2} removePadding>
            <FormSection text={t('general.timetableGeneralSettings')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormCheckboxes
              name="generalSettings"
              testId={TestIds.TIMETABLE_GENERAL_SETTINGS}
              options={[
                {
                  label: t('general.showDepartmentSelectionOnTimetables'),
                  value: SHOW_DEPARTMENT_SELECTION,
                },
                {
                  label: t('general.showDetailsForPublicTimetables'),
                  value: SHOW_DETAILS_FOR_PUBLIC,
                },
              ]}
              initialValue={generalSettingsInitialCheckboxValue}
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});

export default TimetableGeneralSettings;
