import React from 'react';
import { t } from 'i18next';

import { PlatformApplicationConfigurationTranslation } from './platform-application-translations';

import { PLATFORM_APPLICATION_DEFAULT_ICON } from '@pa/stores/platform-store';
import {
  PlatformApplicationDto,
  PlatformApplicationUrlDto,
  PlatformApplicationUrlDtoRoleEnum,
} from '@untis/wu-rest-view-api';

const PLATFORM_APPLICATION_LOGOS_PATH = '/assets/platform-app-logos/';

export enum AvailableApplication {
  WEB_UNTIS = 'WebUntis',
  UNTIS_MOBILE = 'Untis Mobile',
}

export function getPlatformLogo(logo?: string): JSX.Element {
  const className = 'platform-app-logo';
  const logoName = logo ?? PLATFORM_APPLICATION_DEFAULT_ICON;
  const logoPath = PLATFORM_APPLICATION_LOGOS_PATH + logoName;
  return <img className={className} src={logoPath} alt={className} />;
}

export function getFinalDomainParts(domainParts: string[], domainPatternPartLen: number): string[] {
  const finalDomainParts: string[] = [];
  for (let i = 0; i < domainParts.length; i++) {
    if (i <= domainPatternPartLen) {
      finalDomainParts.push(domainParts[i]);
    } else {
      finalDomainParts[domainPatternPartLen] += '.' + domainParts[i];
    }
  }
  return finalDomainParts;
}

export function validateDomain(domainPattern: string, domain: string): number {
  if (!domainPattern) {
    return 0;
  }

  const domainPatternPartLen: number = domainPattern.split('.').length - 1;
  let errorCode: number = 0;
  const domainParts = domain.split('.');
  const finalDomainParts: string[] = getFinalDomainParts(domainParts, domainPatternPartLen);

  const partsLen = finalDomainParts.length - 1;
  for (let i = 0; i <= partsLen; i++) {
    const part = finalDomainParts[i];
    if (part.length === 0) {
      return -1;
    } else if (part.length > 2 && i < partsLen) {
      if (!part.match('^[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]$')) {
        errorCode = 406;
      }
    } else if (i === partsLen) {
      if (!part.match("^[A-Za-z0-9_~!$&'()*+,;=:@/.-]*$")) {
        errorCode = 406;
      }
    } else {
      if (!part.match('^[A-Za-z0-9]+$')) {
        errorCode = 406;
      }
    }
  }
  return errorCode;
}

export function getNameForRoleType(type: string): string {
  return t(`${PlatformApplicationConfigurationTranslation.userRoles}.${type.toLowerCase()}`);
}

export function isPlatformApplicationSaved(platformApplication: PlatformApplicationDto): boolean {
  return !platformApplication?.id ?? false;
}

export function sortUrlInformations(informations: PlatformApplicationUrlDto[]): PlatformApplicationUrlDto[] {
  let defaultUrlInformation: PlatformApplicationUrlDto | undefined;
  let sortedUrlInformations: PlatformApplicationUrlDto[] = [];

  informations.forEach((info: PlatformApplicationUrlDto) => {
    if (info.role === PlatformApplicationUrlDtoRoleEnum.DEFAULT) {
      defaultUrlInformation = info;
    } else {
      sortedUrlInformations.push(info);
    }
  });
  sortedUrlInformations = sortedUrlInformations.sort((info1, info2) => {
    return getNameForRoleType(info1.role)?.localeCompare(getNameForRoleType(info2.role));
  });
  defaultUrlInformation && sortedUrlInformations.unshift(defaultUrlInformation);
  return sortedUrlInformations;
}

export const apiPermissionNameMapping: Map<string, string> = new Map([
  ['ROSTER_CORE_READONLY', 'general.rosterCoreReadOnlyPermissionName'],
  ['STUDENT_API_READ', 'general.studentApiReadPermissionName'],
  ['STUDENT_API_WRITE', 'general.studentApiWritePermissionName'],
  ['TIMETABLE_API_READ', 'general.timetableApiReadPermissionName'],
  ['STUDENT_ABSENCE_TIMES_API_READ', 'general.studentAbsenceTimesApiReadPermissionName'],
  ['EXAM_API_READ', 'general.examApiReadPermissionName'],
  ['LESSONTOPIC_API_WRITE', 'general.lessonTopicApiWritePermissionName'],
  ['CLASS_API_READ', 'general.classApiReadPermissionName'],
  ['CLASS_API_WRITE', 'general.classApiWritePermissionName'],
  ['LESSON_API_READ', 'general.lessonApiReadPermissionName'],
  ['LEGAL_GUARDIAN_API_READ', 'general.lgApiReadPermissionName'],
  ['LEGAL_GUARDIAN_API_WRITE', 'general.lgApiWritePermissionName'],
  ['TEACHER_API_READ', 'general.teacherApiReadPermissionName'],
  ['TEACHER_API_WRITE', 'general.teacherApiWritePermissionName'],
]);

export const apiPermissionDescriptionMapping: Map<string, string> = new Map([
  ['ROSTER_CORE_READONLY', 'general.rosterCoreReadOnlyPermissionDescription'],
  ['STUDENT_API_READ', 'general.studentApiReadPermissionDescription'],
  ['STUDENT_API_WRITE', 'general.studentApiWritePermissionDescription'],
  ['TIMETABLE_API_READ', 'general.timetableApiReadPermissionDescription'],
  ['STUDENT_ABSENCE_TIMES_API_READ', 'general.studentAbsenceTimesApiReadPermissionDescription'],
  ['EXAM_API_READ', 'general.examApiReadPermissionDescription'],
  ['LESSONTOPIC_API_WRITE', 'general.lessonTopicApiWritePermissionDescription'],
  ['CLASS_API_READ', 'general.classApiReadPermissionDescription'],
  ['CLASS_API_WRITE', 'general.classApiWritePermissionDescription'],
  ['LESSON_API_READ', 'general.lessonApiReadPermissionDescription'],
  ['LEGAL_GUARDIAN_API_READ', 'general.lgApiReadPermissionDescription'],
  ['LEGAL_GUARDIAN_API_WRITE', 'general.lgApiWritePermissionDescription'],
  ['TEACHER_API_READ', 'general.teacherApiReadPermissionDescription'],
  ['TEACHER_API_WRITE', 'general.teacherApiWritePermissionDescription'],
]);
