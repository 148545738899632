import { t } from 'i18next';
import { action, computed, observable } from 'mobx';
import dayjs from 'dayjs';

import {
  CalendarDtoStatusEnum,
  CalendarDtoTypeEnum,
  CalendarEntryDetailDtoV2,
  CalendarPermissionsEnum,
  CalendarRoomDto,
  CalendarTeacherDto,
  HomeworkOptionEnum,
  PlatformApplicationMenuItemDto,
  RoomStatusEnum,
  TeacherStatusEnum,
} from '@untis/wu-rest-view-api';
import {
  CalendarEntryDetailsTab,
  ITabData,
  toRouteTabParam,
} from '@/pages/calendar-entry/calendar-entry-detail-view/calendar-entry-detail-tab-meta';
import {
  IPeriodIdData,
  ITimeSlotArguments,
} from '@/pages/calendar-entry/calendar-entry-detail-view/calendar-entry-detail-view';
import { CalendarViewApi } from '@/stores/api-store';
import ConfigStore from '@/stores/config-store';
import ModalStore from '@/stores/modal-store';
import { inject } from '@/types/store';
import { ICalendarEntryDetailsData } from '@/ui-components/calendar-entry-details-header/calendar-entry-content';
import { IPeriodDetailsData } from '@/ui-components/calendar-entry-details-header/calendar-entry-details-header';
import { showErrorMessage } from '@/utils/error-handling/error-message';
import { formatDateTimeForServerRequest } from '@/utils/date/date-util';

class CalendarEntryDetailViewStore {
  @observable private _selectedTab: CalendarEntryDetailsTab;
  @observable private _calendarEntryDetails: CalendarEntryDetailDtoV2[] | undefined;

  @observable private _data: IPeriodIdData | ITimeSlotArguments;
  @observable private _isBlockSelected: boolean;
  @observable private _selectedPeriodId: number;
  @observable private _iframeIsLoading: boolean;
  @observable private _hideTopSection: boolean;
  @observable private _embedDetailsIframe: boolean;
  @observable private _selectedPlatformApplicationId: number | undefined;
  @observable private _platformApplicationMenuItems: PlatformApplicationMenuItemDto[] = [];
  @observable private _minimized: boolean = false;
  @observable private _classregScrollTop: number;
  private _idParam: string | undefined;
  private _dateParam: string | undefined;
  private _resetClassregScrollTop: boolean;
  private _iFrameRequired: boolean;

  private _modalStore = inject(ModalStore);
  private _configStore = inject(ConfigStore);

  constructor(
    data: IPeriodIdData | ITimeSlotArguments,
    initialTabData: ITabData,
    idParam: string | undefined,
    dateParam: string | undefined,
    embedDetailsIframe?: boolean,
  ) {
    this._data = data;
    this._selectedTab = initialTabData.tab;
    this._selectedPlatformApplicationId = initialTabData.platformApplicationId;
    this._iFrameRequired = false;
    this._isBlockSelected = this.isPeriodData(data) ? false : data.initialIsBlockSelected;
    this._selectedPeriodId = this.isPeriodData(data) ? data.periodId : data.initialSelectedPeriodId;
    this._iframeIsLoading = false;
    this._hideTopSection = false;
    this._embedDetailsIframe = embedDetailsIframe ?? false;
    this._classregScrollTop = 0;
    this._resetClassregScrollTop = false;
    this._idParam = idParam;
    this._dateParam = dateParam;

    this.fetchCalendarEntryDetails();
    this.fetchPlatformApplicationMenuItems();
  }

  @action
  fetchCalendarEntryDetails = (periodId?: number, examId?: number) => {
    const isStudentOrParent = this._configStore.isStudent || this._configStore.hasStudent;
    const homeworkOption: HomeworkOptionEnum | undefined = isStudentOrParent ? HomeworkOptionEnum.DUE : undefined;

    if (periodId) {
      this._iframeIsLoading = true;
      CalendarViewApi.getCalendarEntryDetailV2(periodId, homeworkOption)
        .then((response) => {
          this._data = { periodId: response.data.id };
          this.setCalendarEntryDetails([response.data], examId);
          this._isBlockSelected = false;
          this._selectedPeriodId = response.data.id;
          this._idParam = undefined;
          this._dateParam = undefined;
        })
        .catch((response) => {
          showErrorMessage(response.response.data.errorMessage);
        })
        .finally(() => {
          this._iframeIsLoading = false;
        });
    } else if (this.isPeriodData(this._data)) {
      CalendarViewApi.getCalendarEntryDetailV2(this._data.periodId, homeworkOption)
        .then((response) => {
          this.setCalendarEntryDetails([response.data], examId);
        })
        .catch((response) => {
          showErrorMessage(response.response.data.errorMessage);
        });
    } else {
      CalendarViewApi.getCalendarEntryDetailsV2(
        this._data.elementId,
        this._data.elementType,
        formatDateTimeForServerRequest(this._data.endDateTime),
        formatDateTimeForServerRequest(this._data.startDateTime),
        homeworkOption,
      )
        .then((response) => {
          this.setCalendarEntryDetails(response.data.calendarEntries, examId);
        })
        .catch((response) => {
          showErrorMessage(response.response.data.errorMessage);
        });
    }
  };

  @action
  fetchPlatformApplicationMenuItems = () => {
    CalendarViewApi.getPlatformApplicationDetailMenus(this._selectedPeriodId)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const data: PlatformApplicationMenuItemDto[] = response.data
            .map((item: PlatformApplicationMenuItemDto) => {
              return {
                id: item.id,
                name: item.name,
                redirectUrl: decodeURIComponent(item.redirectUrl),
                icon: item.icon,
                openInNewTab: item.openInNewTab,
                logoutUrl: item.logoutUrl,
              };
            })
            .sort((item1, item2) => {
              return item1.name.localeCompare(item2.name);
            });
          this.setPlatformApplicationMenuItems(data);
        }
      })
      .catch((response) => {
        showErrorMessage(response.response.data.errorMessage);
      });
  };

  @action
  setIframeIsLoading = (val: boolean) => {
    this._iframeIsLoading = val;
  };

  @computed
  get hideTopSection(): boolean {
    return this._hideTopSection;
  }

  @action
  setHideTopSection = (val: boolean) => {
    this._hideTopSection = val;
  };

  @computed
  get isIframeLoading(): boolean {
    return this._iframeIsLoading;
  }

  @computed
  get embedDetailsIframe(): boolean {
    return this._embedDetailsIframe;
  }

  @computed
  get selectedPlatformApplicationId(): number | undefined {
    return this._selectedPlatformApplicationId;
  }

  @computed
  get selectedTab(): CalendarEntryDetailsTab {
    return this._selectedTab;
  }

  /**
   * The iframe can be required for two reasons:
   * 1) it is required because of the initial selected tab (that originates from legacy WebUntis in postMessage).
   *    In that case we have to do permission checks (which are computed values based on the current selected calendar
   *    entry)
   * 2) A user clicked on a Tab that is responsible to display an iframe view.
   *
   * This check used to be in the constructor. We had to moved it because permission checks have been added
   * and computed values are not available at construction time.
   */
  @computed
  get iFrameRequired(): boolean {
    // once required, always required (to prevent iframe re-loading when switching back from another tab)
    if (!this._iFrameRequired) {
      this._iFrameRequired =
        (this._selectedTab === CalendarEntryDetailsTab.CLASS_REGISTER && this.hasClassRegister) ||
        (this._selectedTab === CalendarEntryDetailsTab.STUDENT_PERIOD_ASSIGNMENT && this.hasStudentPeriodAssignment) ||
        (this._selectedTab === CalendarEntryDetailsTab.STUDENTS && this.showStudentTab);
    }
    return this._iFrameRequired;
  }

  @computed
  get iFramePage(): string | null {
    switch (this.selectedTab) {
      case CalendarEntryDetailsTab.CLASS_REGISTER:
        return `#classregpage?ttid=${this.selectedPeriodId}&isBlockSelected=${this.isBlockSelected}`;
      case CalendarEntryDetailsTab.STUDENTS:
        return '#lessonstudentlist.do?lsid=' + this.selectedCalendarEntry!.lesson.lessonId;
      case CalendarEntryDetailsTab.STUDENT_PERIOD_ASSIGNMENT:
        return '#studentlessonperiodmatrix?lessonId=' + this.selectedCalendarEntry!.lesson.lessonId;
      default:
        return null;
    }
  }

  @computed
  get iFrameLoadingText(): string {
    switch (this.selectedTab) {
      case CalendarEntryDetailsTab.CLASS_REGISTER:
        return t('general.loadingClassRegister');
      case CalendarEntryDetailsTab.STUDENT_PERIOD_ASSIGNMENT:
        return t('general.loadingStudentPeriodAssignment');
      default:
        return t('general.loading');
    }
  }

  @computed
  get iFrameLoadingImage(): string | undefined {
    if (this.selectedTab === CalendarEntryDetailsTab.CLASS_REGISTER) {
      return '/assets/images/class-register.png';
    }
    return undefined;
  }

  @computed
  get isIframeHidden(): boolean {
    return this.iFramePage === null;
  }

  @computed
  get calendarEntryDetails(): CalendarEntryDetailDtoV2[] | undefined {
    return this._calendarEntryDetails;
  }

  @computed
  get isBlockSelected(): boolean {
    return this._isBlockSelected;
  }

  @computed
  get selectedPeriodId(): number {
    return this._selectedPeriodId;
  }

  @computed
  get selectedCalendarEntry(): CalendarEntryDetailDtoV2 | undefined {
    if (!this.calendarEntryDetails) {
      return undefined;
    }

    return this.calendarEntryDetails.find((entry) => {
      if ((this._isBlockSelected || entry.singleEntries.length === 0) && entry.id === this._selectedPeriodId) {
        return true;
      }

      return entry.singleEntries
        .map((singleEntry) => {
          return singleEntry.id;
        })
        .includes(this._selectedPeriodId);
    });
  }

  @computed
  get notRemovedTeachers(): CalendarTeacherDto[] {
    return this.selectedCalendarEntry
      ? this.selectedCalendarEntry.teachers.filter((t) => {
          return t.status !== TeacherStatusEnum.REMOVED;
        })
      : [];
  }

  @computed
  get notRemovedRooms(): CalendarRoomDto[] {
    return this.selectedCalendarEntry
      ? this.selectedCalendarEntry.rooms.filter((r) => {
          return r.status !== RoomStatusEnum.REMOVED;
        })
      : [];
  }

  @computed get isCancelled(): boolean {
    return this.selectedCalendarEntry?.status === CalendarDtoStatusEnum.CANCELLED;
  }

  @computed
  get platformApplicationMenuItems(): PlatformApplicationMenuItemDto[] {
    return this._platformApplicationMenuItems;
  }

  public getPlatformApplicationMenuItem(id: number): PlatformApplicationMenuItemDto | undefined {
    return this._platformApplicationMenuItems?.find((item) => item.id === id);
  }

  private getNameForType = (entry: CalendarEntryDetailDtoV2): string => {
    if (entry.subType?.displayInPeriodDetails) {
      return entry.subType.displayName;
    }

    switch (entry.type) {
      case CalendarDtoTypeEnum.MEETING:
        return t('general.meeting');
      case CalendarDtoTypeEnum.STAND_BY_PERIOD:
        return t('general.standby');
      case CalendarDtoTypeEnum.OFFICE_HOUR:
        return t('general.officeHour');
      case CalendarDtoTypeEnum.BREAK_SUPERVISION:
        return t('general.breakSupervision');
      case CalendarDtoTypeEnum.NORMAL_TEACHING_PERIOD:
        return t('general.lesson');
      case CalendarDtoTypeEnum.ADDITIONAL_PERIOD:
        return t('general.additionalPeriod');
      case CalendarDtoTypeEnum.EXAM:
        return t('general.exam');
      case CalendarDtoTypeEnum.EVENT:
        return t('general.event');
      case CalendarDtoTypeEnum.CUSTOM:
        return t('general.other');
    }
  };

  private createPeriodDetailData = (): IPeriodDetailsData => {
    return {
      periods: this.calendarEntryDetails!.map((entry) => {
        const rooms: CalendarRoomDto[] = [];
        const removedRooms: CalendarRoomDto[] = [];
        entry.rooms.forEach((r) => {
          r.status === RoomStatusEnum.REMOVED ? removedRooms.push(r) : rooms.push(r);
        });
        const teachers: CalendarTeacherDto[] = [];
        const removedTeachers: CalendarTeacherDto[] = [];
        entry.teachers.forEach((t) => {
          t.status === TeacherStatusEnum.REMOVED ? removedTeachers.push(t) : teachers.push(t);
        });

        return {
          subject: entry.subject,
          classes: entry.klasses,
          teachers,
          removedTeachers,
          rooms,
          removedRooms,
          tags: this.createTagsForEntry(entry),
          studentGroup: entry.mainStudentGroup ? entry.mainStudentGroup.name : undefined,
          parts:
            entry.singleEntries.length > 0
              ? entry.singleEntries.map((singleEntry) => {
                  return {
                    periodId: singleEntry.id,
                    startDateTime: dayjs(singleEntry.startDateTime),
                    endDateTime: dayjs(singleEntry.endDateTime),
                  };
                })
              : [
                  {
                    periodId: entry.id,
                    startDateTime: dayjs(entry.startDateTime),
                    endDateTime: dayjs(entry.endDateTime),
                  },
                ],
          lessonNumber: this.selectedCalendarEntry?.permissions.includes(CalendarPermissionsEnum.SHOW_LESSON_NUMBER)
            ? this.selectedCalendarEntry?.lesson.lessonNumber
            : undefined,
        };
      }),
      selectedPeriodId: this.selectedPeriodId,
      isBlockSelected: this.isBlockSelected,
      idParam: this._idParam,
      dateParam: this._dateParam,
    };
  };

  private createCalendarEntryDetailData = (): ICalendarEntryDetailsData => {
    return {
      type: this.getNameForType(this.selectedCalendarEntry!),
      startDateTime: dayjs(this.selectedCalendarEntry!.startDateTime),
      endDateTime: dayjs(this.selectedCalendarEntry!.endDateTime),
      teachers: this.notRemovedTeachers,
      rooms: this.notRemovedRooms,
      tags: this.tags,
      lessonNumber: this.selectedCalendarEntry?.permissions.includes(CalendarPermissionsEnum.SHOW_LESSON_NUMBER)
        ? this.selectedCalendarEntry?.lesson.lessonNumber
        : undefined,
    };
  };

  @computed
  get hasClassRegister(): boolean {
    return (
      !!this.selectedCalendarEntry &&
      this.selectedCalendarEntry.permissions.includes(CalendarPermissionsEnum.ACCESS_CLASS_REGISTER)
    );
  }

  @computed
  get hasStudentPeriodAssignment(): boolean {
    return (
      !!this.selectedCalendarEntry &&
      this.selectedCalendarEntry.permissions.includes(CalendarPermissionsEnum.ACCESS_STUDENT_PERIOD_ASSIGNMENT)
    );
  }

  @computed
  get hasStudentAssignment(): boolean {
    return (
      !!this.selectedCalendarEntry &&
      this.selectedCalendarEntry.permissions.includes(CalendarPermissionsEnum.ACCESS_STUDENT_ASSIGN)
    );
  }

  @computed
  get tags(): string[] {
    if (!this.selectedCalendarEntry) {
      return [];
    }
    return this.createTagsForEntry(this.selectedCalendarEntry);
  }

  private createTagsForEntry = (entry: CalendarEntryDetailDtoV2): string[] => {
    const tags = [];

    if (
      entry.type !== CalendarDtoTypeEnum.STAND_BY_PERIOD &&
      entry.type !== CalendarDtoTypeEnum.OFFICE_HOUR &&
      entry.type !== CalendarDtoTypeEnum.BREAK_SUPERVISION &&
      entry.type !== CalendarDtoTypeEnum.CUSTOM &&
      entry.subType?.displayInPeriodDetails
    ) {
      tags.push(entry.subType.displayName);
    }

    if (entry.exam) {
      tags.push(t('general.exam'));
    }

    if (entry.type === CalendarDtoTypeEnum.ADDITIONAL_PERIOD) {
      tags.push(t('general.additionalPeriod'));
    }
    if (entry.type === CalendarDtoTypeEnum.EVENT) {
      tags.push(t('general.event'));
    }
    if (entry.type === CalendarDtoTypeEnum.MEETING) {
      tags.push(t('general.meeting'));
    }

    return tags;
  };

  @computed
  get entryData(): IPeriodDetailsData | ICalendarEntryDetailsData {
    if (
      this.selectedCalendarEntry!.type === CalendarDtoTypeEnum.OFFICE_HOUR ||
      this.selectedCalendarEntry!.type === CalendarDtoTypeEnum.BREAK_SUPERVISION ||
      this.selectedCalendarEntry!.type === CalendarDtoTypeEnum.STAND_BY_PERIOD ||
      this.selectedCalendarEntry!.type === CalendarDtoTypeEnum.CUSTOM
    ) {
      return this.createCalendarEntryDetailData();
    }
    return this.createPeriodDetailData();
  }

  @computed
  get timeSlotArguments(): ITimeSlotArguments | undefined {
    if (!this.isPeriodData(this._data)) {
      return this._data;
    }
    return undefined;
  }

  @computed
  get isReady(): boolean {
    return !!this.calendarEntryDetails && !!this.selectedCalendarEntry;
  }

  @computed
  get showStudentTab(): boolean {
    return !this.hasClassRegister && this.hasStudentAssignment;
  }

  @computed
  get isSelectedTabAllowed(): boolean {
    switch (this.selectedTab) {
      case CalendarEntryDetailsTab.DETAILS:
        return true;
      case CalendarEntryDetailsTab.CLASS_REGISTER:
        return this.hasClassRegister;
      case CalendarEntryDetailsTab.STUDENT_PERIOD_ASSIGNMENT:
        return this.hasStudentPeriodAssignment;
      case CalendarEntryDetailsTab.STUDENTS:
        return this.showStudentTab;
      case CalendarEntryDetailsTab.PLATFORM_APPLICATION:
        return (
          !!this.selectedPlatformApplicationId &&
          !!this.getPlatformApplicationMenuItem(this.selectedPlatformApplicationId)
        );
      default:
        return false;
    }
  }

  @computed
  get selectedTabRouteParam(): string {
    return toRouteTabParam({ tab: this.selectedTab, platformApplicationId: this.selectedPlatformApplicationId });
  }

  @action
  selectTab = (tabData: ITabData) => {
    this.setSelectedTab(tabData.tab);
    switch (tabData.tab) {
      case CalendarEntryDetailsTab.DETAILS:
        this.fetchCalendarEntryDetails();
        break;
      case CalendarEntryDetailsTab.PLATFORM_APPLICATION:
        tabData.platformApplicationId && this.setSelectedPlatformApplicationId(tabData.platformApplicationId);
        break;
    }
  };

  @action
  private setSelectedTab = (tab: CalendarEntryDetailsTab) => {
    if (tab === CalendarEntryDetailsTab.STUDENTS || tab === CalendarEntryDetailsTab.STUDENT_PERIOD_ASSIGNMENT) {
      this._resetClassregScrollTop = true;
    } else if (tab === 'class-register' && this._resetClassregScrollTop) {
      this._classregScrollTop = 0;
    }

    this._selectedTab = tab;
  };

  @action
  setCalendarEntryDetails = (val: CalendarEntryDetailDtoV2[], examId?: number) => {
    this._calendarEntryDetails = val;
    if (examId) {
      this.patchSelectedCalendarEntryByExamId(examId);
    }
  };

  @action
  setPlatformApplicationMenuItems = (val: PlatformApplicationMenuItemDto[]) => {
    this._platformApplicationMenuItems = val;
  };

  @action
  setIsBlockSelected = (val: boolean) => {
    this._isBlockSelected = val;
  };

  @action
  setSelectedPeriodId = (val: number) => {
    this._selectedPeriodId = val;
  };

  @action
  setSelectedPlatformApplicationId = (val: number) => {
    this._selectedPlatformApplicationId = val;
  };

  @action
  setClassregScrollTop = (val: number) => {
    this._classregScrollTop = val;
  };

  /*
   Editing an exam that is detached from the original period results that the id of the calendar entry changes
   Fix in BE side appeared to be more complicated, so we do a small patch in the FE code:
   Patch the selected id by the id of the calendar entry that has the matching exam that was edited
   */
  @action
  private patchSelectedCalendarEntryByExamId(examId: number) {
    if (this.calendarEntryDetails) {
      if (!this.isSelectedPeriodInEntryList) {
        const periodByExamId = this.calendarEntryDetails.find((c) => c.exam && c.exam.id === examId);
        if (periodByExamId) {
          this.setSelectedPeriodId(periodByExamId.id);
        }
      }
    }

    if (!this.isSelectedPeriodInEntryList) {
      this._modalStore.closeModal();
    }
  }

  @computed
  private get isSelectedPeriodInEntryList(): boolean {
    return this.calendarEntryDetails?.map((c) => c.id)?.includes(this.selectedPeriodId) ?? false;
  }

  public isPeriodData(data: IPeriodIdData | ITimeSlotArguments): data is IPeriodIdData {
    return (data as IPeriodIdData).periodId !== undefined;
  }

  @computed
  get hasNotes(): boolean {
    return !!this._calendarEntryDetails?.find((entry) => {
      return (
        !!entry.notesAll || entry.notesAllFiles.length > 0 || !!entry.notesStaff || entry.notesStaffFiles.length > 0
      );
    });
  }

  @computed
  get isMinimized(): boolean {
    return this._classregScrollTop > 0 && this._selectedTab === 'class-register';
  }
}

export default CalendarEntryDetailViewStore;
