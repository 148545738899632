import React from 'react';
import { observer } from 'mobx-react-lite';

import TimetableEntitySelector from '@te/standard/components/header/entity-selector/timetable-entity-selector';
import TimetableHeaderDatePicker from '@te/standard/components/header/date-picker/timetable-header-date-picker';
import TimetableHeaderControls from '@te/standard/components/header/controls/timetable-header-controls';

import './timetable-header.less';

const TimetableHeader = observer(() => {
  return (
    <div className="timetable-header">
      <TimetableEntitySelector />
      <TimetableHeaderDatePicker />
      <TimetableHeaderControls />
    </div>
  );
});

export default TimetableHeader;
