import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import dayjs from 'dayjs';

import { ExamWizardStore, IExamWizardForm } from '@ls/exams/wizard/exam-wizard-store';
import { WUForm } from '@/ui-components';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { IFormButton } from '@/ui-components/wu-form/wu-form';
import { FormSection } from '@/ui-components/wu-form/form-section/form-section';
import { TimegridStore } from '@/stores/timegrid-store';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { getCommonTextInputRules, TextValidationPatterns } from '@/utils/form/common-form-rules';
import { FormMultiTagSelect, WUFormCol, WUFormRow } from '@/ui-components/wu-form';
import {
  DateTimeRange,
  FormDateTimeRangePickerSchoolYear,
} from '@/ui-components/wu-form/form-date-time-range-picker-school-year/form-date-time-range-picker-school-year';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { AssignedTeachersRow } from '@/components/assigned-teachers/assigned-teachers';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import { FormAssignedTeachers } from '@/ui-components/wu-form/form-assigned-teachers/form-assigned-teachers';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';

interface IProps {
  examWizardStore: ExamWizardStore;
  onSaveCallBack?: () => void;
}

export const ExamWizardForm = observer((props: IProps) => {
  const { t } = useTranslation();
  const timegridStore = useStore(TimegridStore);
  const rightsStore = useStore(RightsStore);
  const modalStore = useStore(ModalStore);
  const [form] = Form.useForm<IExamWizardForm>();

  const { examWizardStore, onSaveCallBack } = props;
  const { isLoading, isSubmitting, exam, rooms, students } = examWizardStore;
  const disabled = !rightsStore.canWrite(Right.EXAMINATION, ElementType.ALL, false) || isSubmitting;

  useComponentDidMount(async () => {
    examWizardStore.setForm(form);
    examWizardStore.fetchData();
  });

  if (isLoading) {
    return <EmptyIndicator title={t('general.loading')} />;
  }

  const rightButtons: IFormButton[] = [
    {
      label: t('general.cancel'),
      onClick: () => modalStore.closeModal(),
    },
  ];

  const initialDateTimeRangePickerValue: DateTimeRange = {
    startDateTime: exam?.examStart ? dayjs(exam?.examStart) : timegridStore.timeRangePickerTimeSlots[0].startTime,
    endDateTime: exam?.examEnd
      ? dayjs(exam.examEnd)
      : timegridStore.timeRangePickerTimeSlots[timegridStore.timeRangePickerTimeSlots.length - 1].endTime,
  };

  const initialAssignedTeachersValue: AssignedTeachersRow[] = examWizardStore.assignedTeachers;

  const initialRooms: IMultiTagSelectItem[] = rooms
    .filter((room) => exam?.rooms.map((r) => r.id).includes(room.id))
    .map((room) => {
      return {
        id: room.id.toString(),
        label: room.displayName,
      };
    });

  const initialStudents: IMultiTagSelectItem[] = students
    .filter((student) => exam?.students.map((s) => s.id).includes(student.id))
    .map((student) => {
      return {
        id: student.id.toString(),
        label: student.displayName,
      };
    });

  const formDateTime = examWizardStore.form?.getFieldValue('dateTimeRange');
  let startTime = dayjs(exam?.examStart);
  let endTime = dayjs(exam?.examEnd);
  if (formDateTime) {
    startTime = dayjs(formDateTime.startDateTime);
    endTime = dayjs(formDateTime.endDateTime);
  }

  return (
    <WUForm<IExamWizardForm>
      form={examWizardStore.form}
      rightButtons={rightButtons}
      fixedButtonBar={true}
      hideSubmitButton={disabled}
      enableSubmitButton
      onSubmit={
        disabled ? undefined : (value: IExamWizardForm) => examWizardStore.saveExamWizard(value, onSaveCallBack)
      }
      maxWidth
      paddingTop
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.name')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              name="name"
              initialValue={exam?.examName || ''}
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              rules={getCommonTextInputRules(
                {
                  required: true,
                },
                [{ max: 100, message: t('general.inputTooLong') }],
              )}
              disabled={disabled || exam?.isUntisExam}
              onChange={() => examWizardStore.updateModalHeader()}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.text')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              name="text"
              initialValue={exam?.examText || ''}
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              rules={getCommonTextInputRules({}, [{ max: 255, message: t('general.inputTooLong') }])}
              disabled={disabled || exam?.isUntisExam}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.examinationType')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormSingleSelect
              name="examType"
              items={examWizardStore.examTypes}
              rules={getCommonTextInputRules({
                required: true,
              })}
              disabled={disabled}
              allowClear={false}
              onChange={examWizardStore.updateModalHeader}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.date')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormDateTimeRangePickerSchoolYear
              name="dateTimeRange"
              initialValue={initialDateTimeRangePickerValue}
              rules={[
                { required: true, message: t('general.inputRequired') },
                {
                  message: t('lessons.exams.messages.dateTimeValidationMessage'),
                  validator(rule, dateTimeRange) {
                    const { startDateTime, endDateTime } = dateTimeRange;
                    if (endDateTime.isSameOrBefore(startDateTime)) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
              disabled={disabled}
              onlySameDay={true}
              timeSlots={timegridStore.timeRangePickerTimeSlots}
              onChange={(dateTimeRange) => examWizardStore.onUpdateDateTimeRange(dateTimeRange)}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('lessons.exams.form.assignedTeachers')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormAssignedTeachers
              name="assignedTeachers"
              initialValue={initialAssignedTeachersValue}
              startTime={startTime}
              endTime={endTime}
              timeSlots={timegridStore.timeRangePickerTimeSlots}
              teacherOptions={examWizardStore.teachers}
              disabled={disabled || exam?.isUntisExam || !examWizardStore.canAssignedSelectTeachers}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.students')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormMultiTagSelect
              name="students"
              initialValue={initialStudents}
              removable
              onAdd={examWizardStore.addStudentsHandler}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.rooms')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormMultiTagSelect
              name="rooms"
              initialValue={initialRooms}
              removable
              onAdd={examWizardStore.addRoomsHandler}
              disabled={disabled || !examWizardStore.canSelectRooms}
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});
