import React from 'react';
import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import { AxiosError } from 'axios';

import { ExamTypesViewApi } from '@/stores/api-store';
import { inject, Store } from '@/types/store';
import { CustomOrderDto, ExamTypeDto, MasterDataRefDto } from '@untis/wu-rest-view-api/api';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import { IListViewAction, IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import ReportStore from '@/stores/report-store';
import ModalStore from '@/stores/modal-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { booleanSorting, defaultSorting, numberSorting } from '@/utils/sorting/sorting-util';
import { ExamTypeForm } from '@/pages/master-data/exam-type/exam-type-form';
import { createChunks } from '@/utils/array/array-util';
import { AbstractListViewStore } from '@/pages/master-data/common/abstract-list-view-store';
import { matchesAllSearches } from '@/utils/filtering/filtering-util';
import { getResponseError, IResponseError, ResponseErrorType } from '@/utils/error-handling/error-handling';
import { IValidationError } from '@/types/validation-error';
import { ISelectItem } from '@/ui-components/select/select';

export interface IExamTypeRow extends ITableRowKey {
  examTypeId: number;
  examType: string;
  longName: string;
  maxExamsPerDay: number;
  maxExamsPerWeek: number;
  maxExamsPerRange: string;
  numberFreeDays: number;
  typeRestriction: number;
  gradingScheme: string;
  isActive: boolean;
}

export interface IExamTypeForm {
  name: string;
  longName: string;
  examinationType: string;
  settings: string[];
  foregroundColor: string;
  backgroundColor: string;
  maxExamsPerDay: number;
  maxExamsPerWeek: number;
  maxExamsPerRange: number;
  examsPerRangeNumberDays: number;
  numberFreeDays: number;
  countingWeekend: string[];
  typeRestriction: number;
  gradingScheme: string | undefined;
  weightFactor: number;
}

@Store()
export class ExamTypeStore extends AbstractListViewStore<IExamTypeForm> {
  private rightStore: RightsStore = inject(RightsStore);
  private reportStore: ReportStore = inject(ReportStore);
  private modalStore: ModalStore = inject(ModalStore);
  private notificationStore: NotificationStore = inject(NotificationStore);

  @observable private _examsTypes: ExamTypeDto[] = [];
  @observable private _gradingSchemes: MasterDataRefDto[] = [];

  constructor() {
    super({ elementType: ElementType.EXAM_TYPE });
    this.fetchData();
  }

  @action
  async fetchData() {
    await this.getExamTypeFormData();
    await this.getExamTypes();
    this.setIsDataLoading(false);
  }

  @action
  async getExamTypes() {
    const response = await ExamTypesViewApi.getExamTypes();

    this._examsTypes = response.data.examTypes || [];
  }

  @action
  private async getExamTypeFormData() {
    const response = await ExamTypesViewApi.getExamTypeForm();
    this._gradingSchemes = response.data.gradingScales || [];
  }

  @action.bound
  getExamTypeDtoByRowId(id: string): ExamTypeDto | undefined {
    return this._examsTypes.find((examType) => examType.id?.toString() === id);
  }

  @computed
  get rowData(): IExamTypeRow[] {
    return this._examsTypes?.map((examType): IExamTypeRow => {
      return {
        key: examType.id || -1,
        examTypeId: examType.id || -1,
        examType: examType.name || '',
        longName: examType.longName || '',
        maxExamsPerDay: examType.maxExamsPerDay || 0,
        maxExamsPerWeek: examType.maxExamsPerWeek || 0,
        maxExamsPerRange: `${examType.maxExamsPerRange || 0} / ${examType.examsPerRangeNumberDays || 0}`,
        numberFreeDays: examType.numberFreeDays || 0,
        typeRestriction: examType.typeRestriction || 0,
        gradingScheme: examType.gradingScale?.displayName || '',
        isActive: examType.active || false,
      };
    });
  }

  @computed
  get gradingSchemes(): ISelectItem[] {
    return this._gradingSchemes.map((g) => {
      return {
        id: g.id.toString(),
        label: g.displayName ?? '',
      };
    });
  }

  private mapDtoToSearchString(dto: IExamTypeRow): string {
    let res = '';
    res += dto.examType ?? '';
    res += dto.longName ?? '';
    res += dto.gradingScheme ?? '';
    return res.toLocaleLowerCase();
  }

  @computed
  get filteredRows(): IExamTypeRow[] {
    let filteredDtos = this.rowData;

    filteredDtos = filteredDtos.filter((dto) => {
      return matchesAllSearches(this.mapDtoToSearchString(dto), this.selectedFreeTextOptions);
    });

    return filteredDtos;
  }

  @action.bound
  openCreateForm() {
    this.form.resetFields();
    this.modalStore.openModalDialog({
      children: <ExamTypeForm examTypeStore={this} />,
      title: t('masterData.examTypes.form.createFormTitle'),
      size: 'full-size',
      containsForm: true,
    });
  }

  @action.bound
  openEditForm(row: IExamTypeRow) {
    const examType = this.getExamTypeDtoByRowId(row.examTypeId.toString());
    this.form.resetFields();
    if (examType) {
      this.modalStore.openModalDialog({
        title: t('masterData.examTypes.form.editFormTitle', { longName: examType.longName, name: examType.name }),
        size: 'full-size',
        children: <ExamTypeForm examType={examType} examTypeStore={this} />,
        containsForm: true,
      });
    }
  }

  @action
  async saveNewExamType(value: IExamTypeForm, saveAndNew: boolean) {
    const examTypeDto = this.mapFormValuesToDto(value, undefined);
    const form = this.form;
    try {
      await ExamTypesViewApi.createExamType(examTypeDto);
      this.notificationStore.success({ title: t('masterData.examTypes.messages.examTypeCreated') });
      await this.getExamTypes();
      form.resetFields();
      if (!saveAndNew) {
        this.modalStore.closeModal();
      }
    } catch (error) {
      const responseError: IResponseError = getResponseError(error);
      if (responseError.type === ResponseErrorType.VALIDATION_ERROR) {
        this.handleValidationError(responseError);
      } else {
        this.notificationStore.error({
          title: t('masterData.examTypes.messages.examTypeCreatedError'),
          message: error.toString(),
        });
      }
    }
  }

  @action
  updateExamType(value: IExamTypeForm, examType?: ExamTypeDto) {
    const examTypeDto = this.mapFormValuesToDto(value, examType);

    ExamTypesViewApi.updateExamType(examTypeDto)
      .then(async () => {
        this.notificationStore.success({ title: t('masterData.examTypes.messages.examTypeEdited') });
        await this.getExamTypes();
        this.form.resetFields();
        this.modalStore.closeModal();
      })
      .catch((error) => {
        const responseError: IResponseError = getResponseError(error);
        if (responseError.type === ResponseErrorType.VALIDATION_ERROR) {
          this.handleValidationError(responseError);
        } else {
          this.notificationStore.error({
            title: t('masterData.examTypes.messages.examTypeEditedError'),
            message: error.toString(),
          });
        }
      });
  }

  @action
  private handleValidationError(error: IResponseError): void {
    const validationErrors: IValidationError[] = error.payload as IValidationError[];
    for (const validationError of validationErrors) {
      if (validationError.path.includes('.name')) {
        this.form.setFields([{ name: 'name', errors: [t(validationError.errorMessage)] }]);
      }
    }
  }

  @action
  deleteExamType(id: number) {
    this.modalStore.openDeletePrompt(t('masterData.examTypes.messages.deletePrompt'), '').then((result) => {
      if (result) {
        ExamTypesViewApi.deleteExamType(id)
          .then(async () => {
            this.notificationStore.success({ title: t('masterData.examTypes.messages.examTypeDeleted') });
            this.modalStore.closeModal();
            await this.getExamTypes();
          })
          .catch((error) => {
            const axiosError: AxiosError = error as AxiosError;
            if (axiosError.response?.status === 422) {
              this.notificationStore.error({
                title: t('masterData.examTypes.messages.examTypeDeletedError'),
                message: t('masterData.examTypes.messages.examTypeInUse'),
              });
            } else {
              this.notificationStore.error({
                title: error.toString(),
              });
            }
          });
      }
    });
  }

  @action
  bulkDeleteExamTypes(rows: IExamTypeRow[]) {
    this.modalStore
      .booleanUserPrompt({
        title: t('masterData.examTypes.messages.bulkDeletePrompt', { count: rows.length }),
        okButton: {
          label: t('general.yes'),
        },
        cancelButton: {
          label: t('general.cancel'),
        },
      })
      .then((result) => {
        if (result) {
          const chunks = createChunks<IExamTypeRow>(rows, 100);
          chunks.forEach((chunk) => {
            ExamTypesViewApi.deleteExamTypes(chunk.map((row) => row.examTypeId))
              .then(() => {
                this.notificationStore.success({
                  title: t('masterData.examTypes.messages.examTypesDeleted'),
                });
                this.getExamTypes();
              })
              .catch((error) => {
                const axiosError: AxiosError = error as AxiosError;
                if (axiosError.response?.status === 422) {
                  this.notificationStore.error({
                    title: t('masterData.examTypes.messages.examTypesDeletedError'),
                    message: t('masterData.examTypes.messages.examTypesInUse'),
                  });
                } else {
                  this.notificationStore.error({
                    title: error.toString(),
                  });
                }
              });
          });
        }
      });
  }

  mapFormValuesToDto(value: IExamTypeForm, examType?: ExamTypeDto): ExamTypeDto {
    const emptyMasterDataRefDto: MasterDataRefDto = {
      id: -1,
      displayName: '',
      shortName: '',
      longName: '',
    };

    return {
      id: examType?.id || -1,
      name: value.name,
      longName: value.longName,
      inWriting: value.examinationType === 'written',
      heralded: value.settings.includes('announced'),
      sendToUntis: value.settings.includes('transferToUntis'),
      active: value.settings.includes('active'),
      foreColor: value.foregroundColor.replace('#', ''),
      backColor: value.backgroundColor.replace('#', ''),
      maxExamsPerDay: value.maxExamsPerDay,
      maxExamsPerWeek: value.maxExamsPerWeek,
      maxExamsPerRange: value.maxExamsPerRange,
      examsPerRangeNumberDays: value.examsPerRangeNumberDays,
      numberFreeDays: value.numberFreeDays,
      countingWeekend: value.countingWeekend.includes('includingWeekends'),
      typeRestriction: value.typeRestriction,
      gradingScale: this._gradingSchemes.find((g) => g.id.toString() === value.gradingScheme) ?? emptyMasterDataRefDto,
      weightFactor: value.weightFactor ?? 0,
    };
  }

  @computed
  private get columnActions() {
    const actions = [];

    if (this.rightStore.canRead(Right.MASTERDATA, ElementType.EXAM_TYPE, true)) {
      actions.push({
        tooltip: t('general.edit'),
        icon: 'edit',
        onClick: (row: IExamTypeRow) => this.openEditForm(row),
      });
    }

    if (this.rightStore.canDelete(Right.MASTERDATA, ElementType.EXAM_TYPE, true)) {
      actions.push({
        tooltip: t('general.delete'),
        icon: 'shared_trash',
        onClick: (row: IExamTypeRow) => this.deleteExamType(row.examTypeId),
      });
    }

    return actions;
  }

  @computed
  get columns(): Columns<IExamTypeRow> {
    const cols: Columns<IExamTypeRow> = [
      {
        type: ColumnType.Text,
        key: 'examType',
        header: t('general.shortName'),
        textStyle: {
          bold: true,
          color: 'gray-dark',
        },
        sorter: (a, b) => defaultSorting(a.examType, b.examType),
      },
      {
        type: ColumnType.Text,
        key: 'longName',
        header: t('general.longName'),
        sorter: (a, b) => defaultSorting(a.longName, b.longName),
      },
      {
        type: ColumnType.Text,
        key: 'maxExamsPerDay',
        header: t('masterData.examTypes.tableColumns.maxPerDay'),
        sorter: (a, b) => numberSorting(a.maxExamsPerDay, b.maxExamsPerDay),
      },
      {
        type: ColumnType.Text,
        key: 'maxExamsPerWeek',
        header: t('masterData.examTypes.tableColumns.maxPerWeek'),
        sorter: (a, b) => numberSorting(a.maxExamsPerWeek, b.maxExamsPerWeek),
      },
      {
        type: ColumnType.Text,
        key: 'maxExamsPerRange',
        header: t('masterData.examTypes.tableColumns.maxPerDays'),
        sorter: (a, b) => defaultSorting(a.maxExamsPerRange, b.maxExamsPerRange),
      },
      {
        type: ColumnType.Text,
        key: 'numberFreeDays',
        header: t('masterData.examTypes.tableColumns.noExamAfter'),
        sorter: (a, b) => numberSorting(a.numberFreeDays, b.numberFreeDays),
      },
      {
        type: ColumnType.Text,
        key: 'typeRestriction',
        header: t('general.grouping'),
        sorter: (a, b) => numberSorting(a.typeRestriction, b.typeRestriction),
      },
      {
        type: ColumnType.Text,
        key: 'gradingScheme',
        header: t('general.gradingScheme'),
        sorter: (a, b) => defaultSorting(a.gradingScheme, b.gradingScheme),
      },
      {
        type: ColumnType.Boolean,
        key: 'isActive',
        header: t('general.active'),
        align: 'center',
        sorter: (a, b) => booleanSorting(a.isActive, b.isActive),
        className: 'exam-type--is-active',
      },
    ];

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.OverlayHoverActions,
        key: 'actions',
        actionIcons: this.columnActions,
      });
    }

    return cols;
  }

  @computed
  get listViewActions(): IListViewAction[] {
    const actions: IListViewAction[] = [];
    if (this.rightStore.canCreate(Right.MASTERDATA, ElementType.EXAM_TYPE, true)) {
      actions.push({
        label: t('general.new'),
        onClick: this.openCreateForm,
      });
    }
    actions.push({
      label: t('general.report'),
      onClick: () => {
        this.reportStore.getReport('WebUntis/reports.do?name=ExamType&format=pdf');
      },
    });

    return actions;
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<IExamTypeRow>[] | undefined {
    return this.rightStore.canDelete(Right.MASTERDATA, ElementType.EXAM_TYPE, true)
      ? [
          {
            label: t('general.delete'),
            icon: 'shared_trash',
            onClick: (rows) => this.bulkDeleteExamTypes(rows),
          },
        ]
      : undefined;
  }

  @action
  onSaveCustomOrder = async (order: CustomOrderDto[]) => {
    await ExamTypesViewApi.createCustomOrderForTypes(order);
    await this.getExamTypes();
  };

  @action
  onDeleteCustomOrder = async () => {
    await ExamTypesViewApi.deleteCustomOrderForTypes();
    await this.getExamTypes();
  };
}
