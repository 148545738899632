import React, { AnimationEventHandler, PropsWithChildren, useMemo } from 'react';
import clsx from 'clsx';

import { Button, Icon } from '@/ui-components';

import './empty-indicator.less';

export interface IEmptyIndicatorProps {
  title?: string;
  description?: string;
  card?: JSX.Element;
  img?: string;
  icon?: string;
  button?: {
    title: string;
    testId?: string;
    onClick: () => void;
  };
  iconColor?: 'default' | 'primary' | 'error';
  className?: string;
  animationArgs?: {
    shouldRender: boolean; // when set to false the component returns null
    fadeOut: boolean; // when set to true the component starts to fade out
    onFadeOutEnd: AnimationEventHandler; // called after fadeOut. Parent should then set 'shouldRender' to false.
  };
  fullScreen?: boolean;
  white?: boolean;
  zIndex?: number;
}

const EmptyIndicator = (props: PropsWithChildren<IEmptyIndicatorProps>) => {
  const className = useMemo(() => {
    return clsx('empty-indicator', props.className, {
      'empty-indicator--without-card': !props.card,
      'empty-indicator--full-screen': !!props.fullScreen,
      'empty-indicator--icon-color-primary': props.iconColor === 'primary',
      'empty-indicator--icon-color-error': props.iconColor === 'error',
      'empty-indicator--white': !!props.white,
    });
  }, [props.className, props.card, props.fullScreen, props.iconColor, props.white]);

  if (props.animationArgs?.shouldRender === false) {
    return null;
  }

  const emptyIndicatorStyle = {
    animation: props.animationArgs ? `${props.animationArgs.fadeOut ? 'fadeOut' : 'fadeIn'} 250ms` : '',
    zIndex: props.zIndex,
  };

  const imgStyle = props.animationArgs?.fadeOut ? { animation: 'grow 250ms' } : undefined;

  return (
    <div
      className={className}
      style={emptyIndicatorStyle}
      onAnimationEnd={(args) => {
        if (props.animationArgs?.onFadeOutEnd) {
          props.animationArgs?.onFadeOutEnd(args);
        }
      }}
    >
      {!!props.card && props.card}
      {!!props.img && <img src={props.img} style={imgStyle} alt={props.title} />}
      {props.icon && (
        <div className="icon-circle">
          <Icon type={props.icon} />
        </div>
      )}
      {props.title && <h1 className="empty-indicator-header">{props.title}</h1>}
      {!!props.description && <h3>{props.description}</h3>}
      {!!props.button && (
        <div className="empty-indicator-button">
          <Button testId={props.button.testId} type="secondary" outline={true} onClick={props.button.onClick}>
            {props.button.title}
          </Button>
        </div>
      )}
      {props.children}
    </div>
  );
};

export default EmptyIndicator;
