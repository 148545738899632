import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import React from 'react';
import { AxiosError } from 'axios';

import { AbstractListViewStore } from '@/pages/master-data/common/abstract-list-view-store';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { IFormStudentDuty, StudentDutiesForm } from '@md/student-duties/student-duties-form';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { IListViewAction, IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import { booleanSorting, defaultSorting } from '@/utils/sorting/sorting-util';
import { StudentDutyViewApi } from '@/stores/api-store';
import { StudentDutyDto, StudentDutyPeriodicityDto, StudentDutyTypeDto } from '@untis/wu-rest-view-api/api';
import { createChunks } from '@/utils/array/array-util';
import { ElementType, Right } from '@/stores/rights-store';
import { ISearchBarOption } from '@/ui-components/search-bar/search-bar';
import { matchesAllSearches } from '@/utils/filtering/filtering-util';
import { inject, Store } from '@/types/store';
import RouterStore from '@/stores/router-store';
import { ISelectItem } from '@/ui-components/select/select';

export interface IStudentDutyRow extends ITableRowKey {
  id: number;
  dutyType: StudentDutyTypeDto;
  dutyTypeTag: string;
  periodicity: StudentDutyPeriodicityDto;
  periodicityTag: string;
  name: string;
  fullName: string;
  active: boolean;
}

@Store()
export class StudentDutiesStore extends AbstractListViewStore<IFormStudentDuty> {
  @observable private _studentDuties: StudentDutyDto[] = [];
  @observable private _types: StudentDutyTypeDto[] = [];
  @observable private _periodicities: StudentDutyPeriodicityDto[] = [];
  @observable protected _selectedOptions: ISearchBarOption[] = [];
  @observable protected _options: ISearchBarOption[] = [];
  private editId?: number;
  private routerStore = inject(RouterStore);

  constructor() {
    super({ right: Right.MASTERDATA, elementType: ElementType.EXCUSE_STATUS });

    this.fetchStudentDuties();
  }

  @action
  setEditId(editId: string) {
    this.editId = editId ? Number(editId) : undefined;
  }

  @action
  async fetchStudentDuties() {
    await StudentDutyViewApi.readAll().then((response) => {
      this._studentDuties = response.data.studentDuties;
      this._types = response.data.meta?.types ?? [];
      this._periodicities = response.data.meta?.periodicities ?? [];
    });
    this.setIsDataLoading(false);
  }

  @action
  async openEditForm(editRow?: IStudentDutyRow) {
    const row = editRow ?? this._studentDuties.find((row) => row.id === this.editId);

    if (!row) {
      return;
    }

    const duty = this.getStudentDutyDtoByRowId(row?.id?.toString() || '');
    this.form?.resetFields();
    if (duty) {
      try {
        await this._modalStore.openModalDialog({
          title: `${t('general.studentDuty')} ${duty.fullName} (${duty.name})`,
          size: 'md',
          testId: 'student-duties-edit-form',
          children: <StudentDutiesForm store={this} studentDuty={duty} />,
          onAfterClose: () => this.routerStore.redirect('/services'),
          containsForm: true,
        });
      } catch (error) {
        // noop
      }
    }
  }

  private mapDtoToSearchString(dto: IStudentDutyRow): string {
    const res = `${dto.name} ${dto.fullName} ${dto.dutyTypeTag}`;
    return res.toLocaleLowerCase();
  }

  @computed
  get rows() {
    let filteredDtos = StudentDutiesStore.mapStudentDutyDtosToStudentDutyRows(this._studentDuties);
    filteredDtos = filteredDtos.filter((dto) => {
      return matchesAllSearches(this.mapDtoToSearchString(dto), this.selectedFreeTextOptions);
    });

    return filteredDtos;
  }

  @computed
  get selectedOptions(): ISearchBarOption[] {
    return this._selectedOptions;
  }

  @action
  setSelectedOptions(options: ISearchBarOption[]) {
    this._selectedOptions = options;
  }

  @computed
  get options(): ISearchBarOption[] {
    return this._options;
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<IStudentDutyRow>[] | undefined {
    return this.canDelete
      ? [
          {
            label: t('general.delete'),
            icon: 'shared_trash',
            onClick: (rows) => {
              this._modalStore
                .booleanUserPrompt({
                  title: `${t('general.delete')} ${t('general.studentDuties')}?`,
                  children: t('general.studentDutiesBulkDeleteConfirmation', {
                    count: rows.length,
                  }),
                  okButton: {
                    label: t('general.delete'),
                  },
                  cancelButton: {
                    label: t('general.cancel'),
                  },
                  hasDestructiveAction: true,
                  showFooterSeparator: true,
                })
                .then((result) => {
                  if (result) {
                    const chunks = createChunks<IStudentDutyRow>(rows, 100);
                    chunks.forEach((chunk) => {
                      StudentDutyViewApi.deleteMultiple(chunk.map((row) => row.id))
                        .then(() => {
                          this._notificationStore.success({
                            title: t('general.studentDutiesDeleted'),
                          });
                          this.onStudentDutyDeleted(chunk.map((row) => row.id));
                        })
                        .catch((error) => {
                          const axiosError: AxiosError = error as AxiosError;
                          if (axiosError.response?.status === 422) {
                            this._notificationStore.error({
                              title: t('general.studentDutiesCouldNotBeDeleted'),
                              message: t('general.studentDutiesInUseCanNotBeDeleted'),
                            });
                          } else {
                            this._notificationStore.error({
                              title: t('general.studentDutiesCouldNotBeDeleted'),
                              message: error.toString(),
                            });
                          }
                        });
                    });
                  }
                });
            },
          },
        ]
      : [];
  }

  @action
  onStudentDutyDeleted(deletedIds: number[]) {
    this._studentDuties = this._studentDuties.filter(
      (s) => s.id && !deletedIds.some((idToDelete) => s.id === idToDelete),
    );
  }

  @action
  changeStudentDuty(dto: StudentDutyDto) {
    return StudentDutyViewApi.update(dto.id!, dto).then((response) => {
      const currentDto = this._studentDuties.find((s) => s.id === dto.id);
      if (currentDto) {
        Object.assign(currentDto, response.data);
      }
    });
  }

  @action
  deleteStudentDuty(id: number) {
    this._modalStore
      .booleanUserPrompt({
        title: `${t('general.delete')} ${t('general.studentDuty')}?`,
        children: t('general.studentDutyDeleteConfirmation'),
        okButton: {
          label: t('general.delete'),
        },
        cancelButton: {
          label: t('general.cancel'),
        },
        hasDestructiveAction: true,
        showFooterSeparator: true,
      })
      .then((result) => {
        if (result) {
          StudentDutyViewApi._delete(id)
            .then(() => {
              this._notificationStore.success({ title: t('general.studentDutyDeleted') });
              this._modalStore.closeModal();
              this.onStudentDutyDeleted([id]);
            })
            .catch((error) => {
              const axiosError: AxiosError = error as AxiosError;
              if (axiosError.response?.status === 422) {
                this._notificationStore.error({
                  title: t('general.studentDutyCouldNotBeDeleted'),
                  message: t('general.studentDutiesInUseCanNotBeDeleted'),
                });
              } else {
                this._notificationStore.error({
                  title: t('general.studentDutyCouldNotBeDeleted'),
                  message: error.toString(),
                });
              }
            });
        }
      });
  }

  @computed
  get columns(): Columns<IStudentDutyRow> {
    const actionIcons = [];
    if (this.canEdit) {
      actionIcons.push({
        tooltip: t('general.edit'),
        testId: 'student-duties-edit-edit',
        icon: 'edit',
        href: (row: IStudentDutyRow) => `/services/edit/${row.id}`,
        onClick: (row: IStudentDutyRow) => {
          this.openEditForm(row);
        },
      });
    }

    if (this.canDelete) {
      actionIcons.push({
        tooltip: t('general.delete'),
        testId: 'student-duties-edit-delete',
        href: undefined,
        onClick: (row: IStudentDutyRow) => this.deleteStudentDuty(row.id),
        icon: 'shared_trash',
      });
    }

    return [
      {
        type: ColumnType.Text,
        key: 'name',
        header: t('general.name'),
        sorter: (a, b) => defaultSorting(a.name, b.name),
      },
      {
        type: ColumnType.Text,
        key: 'fullName',
        header: t('general.longName'),
        sorter: (a, b) => defaultSorting(a.fullName, b.fullName),
      },
      {
        type: ColumnType.Text,
        key: 'dutyTypeTag',
        header: t('general.type'),
        sorter: (a, b) => defaultSorting(a.dutyType.name, b.dutyType.name),
      },
      {
        type: ColumnType.Tag,
        header: t('general.active'),
        tags: (row) =>
          row.active
            ? [{ text: t('general.active'), color: 'green' }]
            : [{ text: t('general.inactive'), color: 'grey' }],
        key: 'active',
        sorter: (a, b) => booleanSorting(a.active, b.active),
      },
      {
        type: ColumnType.OverlayHoverActions,
        key: 'actions',
        actionIcons,
      },
    ];
  }

  @computed
  get listViewActions(): IListViewAction[] {
    return [
      {
        label: t('general.new'),
        href: '/services/new',
        testId: 'student-duties-edit-new',
        onClick: () => this.openCreateForm(),
      },
      {
        label: t('general.report'),
        testId: 'student-duties-edit-report',
        onClick: () => {
          this.generateReport('Duty');
        },
      },
    ];
  }

  @computed
  get canEdit(): boolean | undefined {
    return true;
  }

  @computed
  get canDelete(): boolean | undefined {
    return true;
  }

  @computed
  get periodicities(): StudentDutyPeriodicityDto[] {
    return this._periodicities;
  }

  @computed
  get types(): StudentDutyTypeDto[] {
    return this._types;
  }

  @computed
  get typeItems(): ISelectItem[] {
    return this._types.map((item) => ({
      label: StudentDutiesStore.getTextForStudentDutyType(item),
      id: item.id.toString(),
    }));
  }

  /*
    Given by the StudentDutyMapper (backend).
    4 = User defined
    In case the BE changes some day and there is no option 4 anymore, we will return undefined as fallback.
   */
  @computed
  get initialTypeItem(): ISelectItem | undefined {
    const dto: StudentDutyTypeDto | undefined = this._types.find((dto) => dto.id === 4);
    return dto
      ? {
          label: StudentDutiesStore.getTextForStudentDutyType(dto),
          id: dto.id.toString(),
        }
      : undefined;
  }

  /*
  Given by the StudentDutyMapper (backend).
  1 = Weekly
  In case the BE changes some day and there is no option 1 anymore, we will return undefined as fallback.
 */
  @computed
  get initialPeriodicityItem(): ISelectItem | undefined {
    const dto: StudentDutyTypeDto | undefined = this._periodicities.find((dto) => dto.id === 1);
    return dto
      ? {
          label: StudentDutiesStore.getTextForPeriodicity(dto),
          id: dto.id.toString(),
        }
      : undefined;
  }

  @computed
  get periodicityItems(): ISelectItem[] {
    return this._periodicities.map((item) => ({
      label: StudentDutiesStore.getTextForPeriodicity(item),
      id: item.id.toString(),
    }));
  }

  @action
  onDutyCreated(duty: StudentDutyDto) {
    this._studentDuties = [...this._studentDuties, duty];
  }

  private static mapStudentDutyDtoToRow(duty: StudentDutyDto): IStudentDutyRow {
    return {
      id: duty.id ?? -1,
      key: duty.id ?? -1,
      dutyType: duty.type,
      dutyTypeTag: this.getTextForStudentDutyType(duty.type),
      periodicity: duty.periodicity,
      periodicityTag: this.getTextForPeriodicity(duty.periodicity),
      name: duty.name,
      fullName: duty.longName,
      active: duty.active,
    };
  }

  private static mapStudentDutyDtosToStudentDutyRows(studentDutyDtos: StudentDutyDto[]): IStudentDutyRow[] {
    return studentDutyDtos.map((duty) => this.mapStudentDutyDtoToRow(duty));
  }

  public static getTextForStudentDutyType(type: StudentDutyTypeDto): string {
    switch (type.id) {
      case 1:
        return t('general.classOfficer');
      case 2:
        return t('general.classRepresentative');
      case 3:
        return t('general.deputyClassRepresentative');
    }
    return t('general.service'); // "user defined", but in the frontend it should appear as "service"
  }

  public static getTextForPeriodicity(periodicity: StudentDutyPeriodicityDto): string {
    switch (periodicity.id) {
      case 1:
        return t('general.weekly');
      case 2:
        return t('general.monthly');
    }
    return t('general.wholeSchoolyear');
  }

  getStudentDutyDtoByRowId = (id: string): IStudentDutyRow | undefined => {
    const selectedDuty = this._studentDuties.find((dto) => dto.id?.toString() === id);
    if (selectedDuty) {
      return StudentDutiesStore.mapStudentDutyDtoToRow(selectedDuty);
    }
    return undefined;
  };

  openCreateForm() {
    this.form.resetFields();
    this._modalStore.openModalDialog({
      title: `${t('general.newStudentDuty')}`,
      size: 'full-size',
      children: <StudentDutiesForm store={this} />,
      onAfterClose: () => this.routerStore.redirect('/services'),
      containsForm: true,
    });
  }
}
