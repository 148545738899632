import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import React from 'react';

import { IReasonsOfExemptionFormData, ReasonsOfExemptionForm } from './reasons-of-exemption-form';

import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { booleanSorting } from '@/utils/sorting/sorting-util';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { IListViewAction, IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import { createChunks } from '@/utils/array/array-util';
import { IDeprecatedSearchBarOption } from '@/ui-components/deprecated-search-bar/deprecated-search-bar';
import { ElementType, Right } from '@/stores/rights-store';
import { CustomOrderDto, ExemptionReasonDto } from '@untis/wu-rest-view-api';
import { ExemptionReasonsViewApi } from '@/stores/api-store';
import { inject, Store } from '@/types/store';
import { AbstractListViewStore } from '@/pages/master-data/common/abstract-list-view-store';
import { matchesAllSearches } from '@/utils/filtering/filtering-util';
import RouterStore from '@/stores/router-store';

export interface IReasonsOfExemptionRow extends ITableRowKey {
  id: number;
  name: string;
  longName: string;
  isActive: boolean;
  key: number;
}

@Store()
export class ReasonsOfExemptionStore extends AbstractListViewStore<IReasonsOfExemptionFormData> {
  @observable private _reasonsOfExemptionDtoList: ExemptionReasonDto[] = [];
  @observable private _selectedOptions: IDeprecatedSearchBarOption[] = [];
  private editId?: number;
  private routerStore = inject(RouterStore);

  constructor() {
    super({ right: Right.MASTERDATA, elementType: ElementType.EXEMPTION_REASON });
    this.fetchData();
  }

  @action
  setEditId(editId: string) {
    this.editId = editId ? Number(editId) : undefined;
  }

  @action
  async fetchData() {
    ExemptionReasonsViewApi.readAll().then((response) => {
      this._reasonsOfExemptionDtoList = response.data.reasons;
      this.setIsDataLoading(false);
    });
  }

  @computed
  get reasonsOfExemption(): IReasonsOfExemptionRow[] {
    return this._reasonsOfExemptionDtoList.map((reason) => ({
      id: reason.id!,
      name: reason.name,
      longName: reason.longName,
      isActive: reason.active,
      key: reason.id!,
    }));
  }

  private mapDtoToSearchString(row: IReasonsOfExemptionRow): string {
    let res = '';
    res += row.name;
    res += row.longName;
    return res.toLocaleLowerCase();
  }

  @computed
  get filteredRows(): IReasonsOfExemptionRow[] {
    return this.reasonsOfExemption.filter((row) =>
      matchesAllSearches(this.mapDtoToSearchString(row), this.selectedFreeTextOptions),
    );
  }

  @computed
  get columns(): Columns<IReasonsOfExemptionRow> {
    const cols: Columns<IReasonsOfExemptionRow> = [
      {
        type: ColumnType.Text,
        header: t('general.name'),
        key: 'name',
        sorter: (a, b) => {
          return a.name.localeCompare(b.name);
        },
      },
      {
        type: ColumnType.Text,
        header: t('general.longName'),
        key: 'longName',
        sorter: (a, b) => {
          return a.longName.localeCompare(b.longName);
        },
      },
      {
        type: ColumnType.Tag,
        header: t('general.active'),
        tags: (row) =>
          row.isActive
            ? [{ text: t('general.active'), color: 'green' }]
            : [{ text: t('general.inactive'), color: 'grey' }],
        key: 'active',
        sorter: (a, b) => booleanSorting(a.isActive, b.isActive),
      },
    ];

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.HoverActions,
        key: 'actions',
        width: this.columnActions.length * 45 + 'px',
        actionIcons: this.columnActions,
      });
    }

    return cols;
  }

  @computed
  private get columnActions() {
    const actions = [];

    if (this.canEdit) {
      actions.push({
        tooltip: t('general.edit'),
        href: (row: IReasonsOfExemptionRow) => `/reasons-of-exemptions/edit/${row.id}`,
        onClick: (row: IReasonsOfExemptionRow) => this.openEditForm(row),
        icon: 'edit',
      });
    }

    if (this.canDelete) {
      actions.push({
        tooltip: t('general.delete'),
        href: undefined,
        onClick: (row: IReasonsOfExemptionRow) => this.deleteReasonOfExemption(row.id),
        icon: 'shared_trash',
      });
    }
    return actions;
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<IReasonsOfExemptionRow>[] | undefined {
    return this.canDelete
      ? [
          {
            label: t('general.delete'),
            icon: 'shared_trash',
            onClick: (rows) => {
              this._modalStore
                .openDeletePrompt(
                  t('general.deleteReasonsOfExemptionQuestionmark'),
                  t('general.reasonOfExemptionBulkDeleteConfirmation', {
                    count: rows.length,
                  }),
                )
                .then((result) => {
                  if (result) {
                    const chunks = createChunks<IReasonsOfExemptionRow>(rows, 100);
                    chunks.forEach((chunk) => {
                      ExemptionReasonsViewApi.deleteMultiple(chunk.map((row) => row.id))
                        .then(() => {
                          this._notificationStore.success({
                            title: t('general.reasonOfExemptionDeleted'),
                          });
                          this.onReasonsOfExemptionDeleted(chunk.map((row) => row.id));
                        })
                        .catch((error) => {
                          this._notificationStore.error({
                            title: t('general.someReasonsOfExemptionCouldNotBeDeleted'),
                            message: error.toString(),
                          });
                        });
                    });
                  }
                });
            },
          },
        ]
      : undefined;
  }

  @computed
  get listViewActions(): IListViewAction[] {
    const actions: IListViewAction[] = [];
    if (this.canCreate) {
      actions.push({
        label: t('general.new'),
        href: '/reasons-of-exemptions/new',
        onClick: this.openCreateForm,
      });
    }
    actions.push({
      label: t('general.report'),
      onClick: () => {
        this.generateReport('ExemptionReason');
      },
    });
    return actions;
  }

  @action
  setSelectedOptions(options: IDeprecatedSearchBarOption[]) {
    this._selectedOptions = options;
  }

  @computed
  get selectedOptions(): IDeprecatedSearchBarOption[] {
    return this._selectedOptions;
  }

  deleteReasonOfExemption(id: number) {
    this._modalStore
      .openDeletePrompt(
        t('general.deleteReasonOfExemptionQuestionmark'),
        t('general.reasonOfExemptionDeleteConfirmation'),
      )
      .then((result) => {
        if (result) {
          ExemptionReasonsViewApi._delete(id).then(() => {
            this._notificationStore.success({ title: t('general.reasonOfExemptionDeleted') });
            this._modalStore.closeModal();
            this.onReasonsOfExemptionDeleted([id]);
          });
        }
      })
      .catch((error) => {
        this._notificationStore.error({
          title: t('general.reasonOfExemptionCouldNotBeDeleted'),
          message: error.toString(),
        });
      });
  }

  @action
  async createReasonOfExemption(formValue: IReasonsOfExemptionFormData): Promise<any> {
    return ExemptionReasonsViewApi.create(this.mapToDto(formValue)).then((response) => {
      this._reasonsOfExemptionDtoList = [...this._reasonsOfExemptionDtoList, response.data];
    });
  }

  @action
  updateReasonOfExemption(formValue: IReasonsOfExemptionFormData, rowValue: IReasonsOfExemptionRow) {
    return ExemptionReasonsViewApi.update(rowValue.id, this.mapToDto(formValue, rowValue)).then((response) => {
      const cachedDto = this._reasonsOfExemptionDtoList.find((reason) => reason.id === rowValue.id);
      if (cachedDto) {
        Object.assign(cachedDto, response.data);
      }
    });
  }

  @action
  onReasonsOfExemptionDeleted(deletedReasonsOfExemptionIds: number[]) {
    this._reasonsOfExemptionDtoList = this._reasonsOfExemptionDtoList.filter(
      (reason) => reason.id && !deletedReasonsOfExemptionIds.includes(reason.id),
    );
  }

  @action
  onCheckboxChanged(row: IReasonsOfExemptionRow, key: keyof IReasonsOfExemptionRow | string, value: boolean) {
    const cachedDto = this._reasonsOfExemptionDtoList.find((reason) => reason.id === row.id);
    const dtoToUpdate: ExemptionReasonDto = Object.assign({}, cachedDto, { [key]: value });
    ExemptionReasonsViewApi.update(dtoToUpdate.id!, dtoToUpdate).then((response) => {
      if (cachedDto) {
        Object.assign(cachedDto, response.data);
      }
    });
  }

  mapToDto(formValue: IReasonsOfExemptionFormData, rowValue?: IReasonsOfExemptionRow): ExemptionReasonDto {
    return {
      id: rowValue?.id || undefined,
      name: formValue.name,
      longName: formValue.longName,
      active: formValue.active,
    };
  }

  @action.bound
  openCreateForm() {
    this.form?.resetFields();
    this._modalStore.openModalDialog({
      children: <ReasonsOfExemptionForm store={this} />,
      title: t('general.newReasonOfExemption'),
      size: 'md',
      onAfterClose: () => this.routerStore.redirect('/reasons-of-exemptions'),
      containsForm: true,
    });
  }

  @action
  async openEditForm(editRow?: IReasonsOfExemptionRow) {
    const row = editRow ?? this.reasonsOfExemption.find((row) => row.id === this.editId);

    if (!row) {
      return;
    }

    const reason = this.getReasonOfExemptionByRowId(row.id.toString());
    this.form?.resetFields();
    if (reason) {
      try {
        await this._modalStore.openModalDialog({
          title: `${t('general.reasonOfExemption')} ${reason.longName} (${reason.name})`,
          size: 'md',
          children: <ReasonsOfExemptionForm store={this} reasonOfExemption={reason} />,
          onAfterClose: () => this.routerStore.redirect('/reasons-of-exemptions'),
          containsForm: true,
        });
      } catch (error) {
        // noop
      }
    }
  }

  getReasonOfExemptionByRowId = (id: string): IReasonsOfExemptionRow | undefined => {
    return this.reasonsOfExemption.find((reason) => reason.id?.toString() === id);
  };

  @computed
  get sortSave(): (order: CustomOrderDto[]) => void {
    return (order) => {
      ExemptionReasonsViewApi.order(order).then(() => {
        this.fetchData();
      });
    };
  }

  @computed
  get sortReset(): () => void {
    return () => {
      ExemptionReasonsViewApi.resetOrder().then(() => {
        this.fetchData();
      });
    };
  }
}
