import React, { ReactNode, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import { ClassregAbsenceViewApi } from '@/stores/api-store';
import { CRAbsencePeriodDto, CRAbsenceWithPeriodExcusesDto } from '@untis/wu-rest-view-api/api';
import { SidebarContent } from '@/ui-components/sidebar/sidebar-content/sidebar-content';
import { Tag } from '@/ui-components';

import './absence-sidebar.less';

interface IAbsenceSidebarContentProps {
  absenceId: number;
}

export const AbsenceSidebar = (props: IAbsenceSidebarContentProps) => {
  const [absenceData, setAbsenceData] = useState<CRAbsenceWithPeriodExcusesDto | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    ClassregAbsenceViewApi.readAbsenceTimesForAbsence(props.absenceId).then(
      (resp: AxiosResponse<CRAbsenceWithPeriodExcusesDto>) => {
        setAbsenceData(resp.data);
      },
    );
  }, [props]);

  if (!absenceData) {
    return null;
  }

  if (absenceData.absenceTimes.length === 0) {
    return <EmptyIndicator title={t('general.noAbsenceTimes')} />;
  }

  const sortedAbsenceTimes = absenceData.absenceTimes.sort((a, b) => (a.startDateTime > b.startDateTime ? -1 : 1));
  const groupedByDate: Map<string, CRAbsencePeriodDto[]> = new Map<string, CRAbsencePeriodDto[]>();

  for (let i = 0; i < sortedAbsenceTimes.length; i++) {
    const currentAbsenceTime = sortedAbsenceTimes[i];
    const currentDayjs = dayjs(sortedAbsenceTimes[i].startDateTime);
    const currentDate = currentDayjs.format('dddd') + ', ' + currentDayjs.format('L');

    if (!groupedByDate.has(currentDate)) {
      groupedByDate.set(currentDate, []);
    }

    const currentEntriesForDate = groupedByDate.get(currentDate);
    groupedByDate.set(currentDate, [...(currentEntriesForDate ?? []), currentAbsenceTime]);
  }

  const renderedNodes: ReactNode[] = [];
  const lastDate: string | undefined = undefined;
  groupedByDate.forEach((absenceTimes, date) => {
    if (date !== lastDate) {
      renderedNodes.push(
        <div className="date" key={date}>
          <span>{date}</span>
        </div>,
      );
    }

    renderedNodes.push(
      absenceTimes.map((absenceTime, i) => {
        const start = dayjs(absenceTime.startDateTime).format('LT');
        const end = dayjs(absenceTime.endDateTime).format('LT');
        return (
          <div key={i} className="absence-times">
            <span className="absence-time-range-row">
              <div className="absence-time-range">
                {start}&nbsp;-&nbsp;{end}
              </div>
              <Tag color={absenceTime.excuse?.counts ? 'green' : 'light-grey'}>
                {absenceTime.excuse && absenceTime.excuse.status
                  ? absenceTime.excuse.status.toUpperCase()
                  : t('general.open').toUpperCase()}
              </Tag>
            </span>
            <div className="absence-time-details">
              <span>
                {absenceTime.subject}&nbsp;-&nbsp;{absenceTime.teachers?.map((t) => t.displayName).join(', ')}
              </span>
            </div>
            {absenceTime.excuse?.text && <p className="text">{absenceTime.excuse?.text}</p>}
          </div>
        );
      }),
    );
  });

  const startDateTime =
    dayjs(absenceData.startDateTime).format('L') + ' ' + dayjs(absenceData.startDateTime).format('LT');
  const endDateTime = dayjs(absenceData.endDateTime).format('L') + ' ' + dayjs(absenceData.endDateTime).format('LT');

  return (
    <SidebarContent>
      <div className="absence-sidebar">
        <div className="absence">
          <span className="student">{absenceData.student?.displayName}</span>
          <span>
            <label>{t('general.start')}</label>:&nbsp;{startDateTime}
          </span>
          <span>
            <label>{t('general.end')}</label>:&nbsp;{endDateTime}
          </span>
          {absenceData.reason && (
            <span>
              <label>{t('general.absenceReason')}</label>:&nbsp;{absenceData.reason}
            </span>
          )}
        </div>
        {renderedNodes}
      </div>
    </SidebarContent>
  );
};
