import React, { useState, useMemo } from 'react';
import { CirclePicker, TwitterPicker } from 'react-color';
import clsx from 'clsx';

import PRESET_COLORS from './colors';

import { Input } from '@/ui-components';
import { useOutsideClick } from '@/hooks/useClickOutside';

import './color-picker.less';

export type ColorPickerProps = {
  onChange?: (color: string) => void;
  value?: string;
  triangle?: 'hide' | 'top-left' | 'top-right' | undefined;
  disabled?: boolean;
  colors?: string[];
  variant?: 'twitter' | 'circle';
  hideValue?: boolean;
  testId?: string;
  placeholder?: string;
};

export const ColorPicker = ({
  value,
  onChange,
  hideValue,
  triangle,
  variant,
  disabled,
  colors,
  testId,
  placeholder,
}: ColorPickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState(value || PRESET_COLORS[0].toLowerCase());
  const refWrapper = useOutsideClick(() => {
    setIsOpen(false);
  });

  const isTwitter = useMemo(() => variant === undefined || variant === 'twitter', [variant]);
  const isCircle = useMemo(() => variant === 'circle', [variant]);

  return (
    <div className="color-picker-input-container" ref={refWrapper} data-testid={testId}>
      <div className="color-circle" onClick={() => setIsOpen(true)} style={{ background: color }} />
      <Input
        className={clsx('color-picker')}
        disabled={disabled}
        onFocus={() => setIsOpen(true)}
        value={hideValue ? undefined : color}
        readOnly
        testId="color-picker-input"
        style={{ cursor: 'pointer' }}
        placeholder={placeholder}
        variant="floating"
        label={placeholder ?? ''}
      />
      {isOpen && isTwitter && (
        <TwitterPicker
          triangle={triangle}
          styles={{
            default: {
              card: { position: 'absolute', zIndex: 3 },
              swatch: {
                borderRadius: '50%',
              },
            },
          }}
          width="495px"
          onChange={(value) => {
            onChange && onChange(value.hex);
            setColor(value.hex);
          }}
          colors={colors || PRESET_COLORS}
          color={color}
        />
      )}
      {isOpen && isCircle && (
        <CirclePicker
          className="color-picker--circle"
          styles={{
            default: {
              card: { position: 'absolute', zIndex: 2 },
            },
          }}
          width="495px"
          onChange={(value) => {
            onChange && onChange(value.hex);
            setColor(value.hex);
          }}
          color={color}
          colors={colors || PRESET_COLORS}
        />
      )}
    </div>
  );
};

export default ColorPicker;
