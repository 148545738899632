import { Select as AntSelect } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import clsx from 'clsx';
import './select.less';

export interface ISelectItem {
  id: string;
  label: string;
  // displayed next to the label in option dropdown
  alias?: string;
}

type Props = ISingleSelectProps | IMultiSelectProps;

const isSingleSelectTypeGuard = (props: Props): props is ISingleSelectProps => {
  return !props.multi;
};

const Select = (props: Props) => {
  const isSingleSelect = isSingleSelectTypeGuard(props);
  const { t } = useTranslation();
  const id = React.useMemo(() => v4(), []);
  const [hasFocus, setHasFocus] = useState(false);

  const onChange = isSingleSelect
    ? (value: string | undefined) => {
        props.onChange && props.onChange(value);
      }
    : (value: string[]) => {
        props.onChange && props.onChange(value);
      };

  const isFloatingLabel = isSingleSelect && props.styleVariant === 'floating';

  const selectClassName = clsx({
    'floating-label-select-input': isFloatingLabel,
  });

  const floatingLabelClassName = clsx({
    'floating-label-select-label': isFloatingLabel,
    'no-value': props.value === undefined && !hasFocus,
    'has-value': props.value !== undefined || hasFocus,
  });

  const sortedItems = props.items.sort(props.customSorting);

  return (
    <div className="select-component-container">
      <AntSelect
        mode={!isSingleSelect ? 'multiple' : undefined}
        id={id}
        className={selectClassName}
        data-testid={props.testId}
        value={props.value}
        onChange={onChange as unknown as any}
        placeholder={
          !isSingleSelect || !props.styleVariant || props.styleVariant === 'default'
            ? props.placeholder ?? ' '
            : undefined
        }
        disabled={props.disabled}
        notFoundContent={t`general.noSearchResults`}
        allowClear={!isSingleSelect || (isSingleSelect && props.allowClear)}
        showSearch={props.searchable === true}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        popupClassName="select-popup"
        optionFilterProp="label"
      >
        {sortedItems.map((item) => (
          <AntSelect.Option title={item.label} key={item.id} value={item.id} label={item.label}>
            {item.label}
            {item.alias && <span className="alias">{item.alias}</span>}
          </AntSelect.Option>
        ))}
      </AntSelect>
      {isSingleSelect && props.styleVariant === 'floating' && (
        <label htmlFor={id} className={floatingLabelClassName}>
          {props.placeholder}
        </label>
      )}
    </div>
  );
};

export type SelectStyle = 'default' | 'floating';

interface ICommonProps {
  searchable?: boolean;
  placeholder?: string;
  items: ISelectItem[];
  disabled?: boolean;
  id?: string;
  testId?: string;
  customSorting?: (a: ISelectItem, b: ISelectItem) => number;
}

export interface ISingleSelectProps extends ICommonProps {
  multi: false;
  value?: string;
  onChange?: (value: string | undefined) => void;
  allowClear?: boolean;
  styleVariant?: SelectStyle;
}

export const SingleSelect = (props: Omit<ISingleSelectProps, 'multi'>) => {
  return <Select {...props} multi={false} />;
};

export interface IMultiSelectProps extends ICommonProps {
  multi: true;
  value?: string[];
  onChange?: (values: string[]) => void;
}

export const MultiSelect = (props: Omit<IMultiSelectProps, 'multi'>) => {
  return <Select {...props} multi={true} />;
};
