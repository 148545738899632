import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExcuseStatusStore, IExcuseStatusRow } from './excuse-status-store';

import { ListView } from '@/components/list-view/list-view';
import useStore from '@/hooks/useStore';

export const MasterDataExcuseStatusList = observer(() => {
  const store = useStore(ExcuseStatusStore);
  const { t } = useTranslation();

  return (
    <ListView<IExcuseStatusRow>
      title={t('general.excuseStatus')}
      table={{
        shadow: true,
        columns: store.columns,
        rowData: store.filteredRows,
        rowSelection: {
          selectedRowKeys: store.selectedRowKeys,
          type: 'checkbox',
          onChange: (keys) => store.setSelectedRowKeys(keys),
        },
      }}
      selectedRowsActions={store.selectedRowsActions}
      searchBar={{
        selectedFreeTextOptions: store.selectedFreeTextOptions,
        onChangeFreeTextOptions: (values) => store.setSelectedFreeTextSearchOptions(values),
        onChangeSelectOptions: (options) => store.setSelectedOptions(options),
        selectedOptions: store.selectedOptions,
        placeholder: t('general.search'),
        searchDescription: t('general.name'),
      }}
      actions={store.listViewActions}
      customSorting={{
        onSave: (order) => {
          store.sortSave(order);
        },
        onDelete: () => {
          store.sortReset();
        },
      }}
      isPageLoading={store.isDataLoading}
      compact
    />
  );
});
