import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { observer } from 'mobx-react-lite';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

import { IconButton, DeprecatedAttachments, DeprecatedTag, Icon } from '@/ui-components';
import { DashboardCardDetailDto, DashboardCardStatusDto } from '@untis/wu-rest-view-api';
import { calendarFileDescriptorDtoToAttachment } from '@/stores/one-drive-store';

import './message-of-the-day-detail.less';

type MessageOfTheDayProps = {
  scrollIntoView?: boolean;
  motd: DashboardCardDetailDto;
  onDelete: () => void;
  onEdit: () => void;
  detailsModalOpenInSeconds?: number;
  onMessageRead: (motd: DashboardCardDetailDto) => void;
};

export const MessageOfTheDay = observer(
  ({ onMessageRead, onDelete, onEdit, scrollIntoView, motd }: MessageOfTheDayProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollingIntoView = useRef(true);
    const { t } = useTranslation();

    const { ref: topRef, inView: topInView } = useInView({
      threshold: 0.9,
      trackVisibility: true,
      delay: 100,
      triggerOnce: true,
    });

    const { ref: bottomRef, inView: bottomInView } = useInView({
      threshold: 0.9,
      trackVisibility: true,
      delay: 100,
      triggerOnce: true,
    });

    const hasSeenWholeMessage = useMemo(() => topInView && bottomInView, [topInView, bottomInView]);
    const isNew = useMemo(() => motd.status === DashboardCardStatusDto.UNREAD, [motd.status]);
    const attachments = useMemo(() => motd.attachments.map(calendarFileDescriptorDtoToAttachment), [motd.attachments]);
    const color = useMemo(() => motd.color.replaceAll('#', ''), [motd.color]);

    const checkMessageRead = useCallback(() => {
      if (hasSeenWholeMessage) {
        onMessageRead(motd);
      }
    }, [hasSeenWholeMessage, onMessageRead, motd]);

    useEffect(() => {
      checkMessageRead();
      const intervalId = window.setInterval(checkMessageRead, 1000);

      return () => {
        window.clearInterval(intervalId);
      };
    }, [hasSeenWholeMessage, checkMessageRead]);

    useEffect(() => {
      if (containerRef.current && scrollIntoView) {
        scrollingIntoView.current = true;
        containerRef.current.scrollIntoView();
      }
    }, [scrollIntoView]);

    return (
      <div ref={containerRef} className="motd-detail">
        <div className="motd-accent" style={{ '--accent-color': `#${color}` } as React.CSSProperties} />
        <div className="motd-detail-container">
          <div className="motd-actions">
            {motd.canEdit && <IconButton ariaLabel="edit" type="edit" onClick={onEdit} />}
            {motd.canDelete && <IconButton ariaLabel="delete" type="shared_trash" onClick={onDelete} />}
          </div>
          <Icon className="motd-detail-icon" type={motd.icon} subdir="icon-picker" />
          <div ref={topRef} className="motd-detail-title-container">
            <h2 className="motd-detail-title">{motd.title}</h2>
            {isNew && (
              <DeprecatedTag outline rounded type="conflict" className="new-tag">
                {t('general.new')}
              </DeprecatedTag>
            )}
          </div>
          {motd.subtitle && (
            <div className="motd-detail-subtitle">
              <p>{motd.subtitle}</p>
            </div>
          )}
          <div className="motd-detail-content">
            <ReactMarkdown linkTarget="_blank">{motd.content}</ReactMarkdown>
          </div>
          <div ref={bottomRef} className="motd-detail-attachments">
            <DeprecatedAttachments readOnly attachments={attachments} />
          </div>
        </div>
      </div>
    );
  },
);
