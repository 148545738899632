import React from 'react';
import { Form } from 'antd';

import { Input } from '@/ui-components';
import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import { getCommonTextInputRules, ICommonTextInputRules } from '@/utils/form/common-form-rules';

interface IInputCommonProps {
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  type?: string;
}

export interface IFormInputProps extends IFormItemProps<string>, IInputCommonProps, ICommonTextInputRules {
  variant?: 'large' | 'floating';

  onChange?: (e: Event) => void;
}

export const FormInput = ({ initialValue, ...props }: IFormInputProps) => {
  const commonProps: IInputCommonProps = props;
  return (
    <Form.Item
      name={props.name}
      label={props.variant !== undefined ? null : props.label}
      initialValue={initialValue}
      className="form-input"
      rules={getCommonTextInputRules(props as ICommonTextInputRules, props.rules)}
      dependencies={props.dependencies}
    >
      {(() => {
        switch (props.variant) {
          case 'floating':
            return <Input variant="floating" label={props.label!} {...commonProps} />;
          case 'large':
            return <Input variant="large" label={props.label!} {...commonProps} />;
          default:
            return <Input {...commonProps} />;
        }
      })()}
    </Form.Item>
  );
};
