import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import { FormSection, WUFormCol, WUFormRow } from '@/ui-components/wu-form';
import { WUForm } from '@/ui-components';
import { IdentificationMethodEnum } from '@untis/wu-rest-view-api/api';
import { StudentImagesUploadStore } from '@/pages/master-data/student/import/student-images-upload-store';
import { FormFileUpload } from '@/ui-components/wu-form/form-file-upload/form-file-upload';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import useStore from '@/hooks/useStore';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import { TestIds } from '@/testIds';
import { ISelectItem } from '@/ui-components/select/select';

export interface IStudentFileUploadForm {
  file: File;
  identificationMethod: number;
}

export const StudentImagesUploadForm = observer(() => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IStudentFileUploadForm>();
  const [store] = useState(() => new StudentImagesUploadStore(form));
  const rightsStore = useStore(RightsStore);

  const disabled = !rightsStore.canWrite(Right.MASTERDATA, ElementType.STUDENT, true);

  const identificationMethodItems = (): ISelectItem[] => {
    const items: ISelectItem[] = [];

    const keys = [
      IdentificationMethodEnum.EXTERN_ID,
      IdentificationMethodEnum.SHORT_NAME,
      IdentificationMethodEnum.LAST_NAME_FIRST_NAME,
      IdentificationMethodEnum.LAST_NAME_FIRST_NAME_BIRTH_DATE,
      IdentificationMethodEnum.LAST_NAME_DOT_FIRST_NAME,
    ];

    keys.forEach((id) => {
      items.push({ id: String(id), label: getIdentificationLabel(Number(id)) });
    });

    return items;
  };

  const getIdentificationLabel = (value: number): string => {
    switch (value) {
      case IdentificationMethodEnum.EXTERN_ID: {
        return t('general.photoImport.identification.externKey');
      }
      case IdentificationMethodEnum.SHORT_NAME: {
        return t('general.photoImport.identification.shortName');
      }
      case IdentificationMethodEnum.LAST_NAME_FIRST_NAME: {
        return t('general.photoImport.identification.lastNameFirstName');
      }
      case IdentificationMethodEnum.LAST_NAME_DOT_FIRST_NAME: {
        return t('general.photoImport.identification.lastNameDotFirstName');
      }
      case IdentificationMethodEnum.LAST_NAME_FIRST_NAME_BIRTH_DATE: {
        return t('general.photoImport.identification.lastNameFirstNameBirthDate');
      }
      default: {
        return '';
      }
    }
  };

  return (
    <WUForm<IStudentFileUploadForm>
      form={store.form}
      rightButtons={store.buttonsOnRightSide}
      fixedButtonBar={true}
      onSubmit={(form) => {
        if (form.file) {
          store.setIsLoading(true);
          store.handleZipFileImport(form);
        } else {
          store.form?.setFields([{ name: 'file', errors: [t('general.photoImport.mandatoryFile')] }]);
        }
      }}
      maxWidth
      submitButtonLabel={t('general.import')}
      submitButtonLoading={store.isLoading}
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.photoImport.dataSource')} description={t('general.photoImport.fileTip')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormFileUpload
              onClick={() => store.form?.setFields([{ name: 'file', errors: [] }])}
              onError={(error: string | undefined) =>
                store.form?.setFields([{ name: 'file', errors: error ? [error] : [] }])
              }
              name="file"
              variant="zip"
              disabled={disabled || store.isLoading}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection
              text={t('general.photoImport.identification.label')}
              description={t('general.photoImport.identification.tip')}
            />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormSingleSelect
              items={identificationMethodItems()}
              placeholder={t('general.photoImport.identification.label')}
              name="identificationMethod"
              initialValue={String(IdentificationMethodEnum.EXTERN_ID)}
              disabled={disabled || store.isLoading}
              testId={TestIds.STUDENT_ZIP_FILE_IDENTIFICATION_METHOD}
              allowClear={false}
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});
