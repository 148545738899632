import React from 'react';
import clsx from 'clsx';
import Tooltip, { TooltipProps } from 'antd/lib/tooltip';
import { Link } from 'react-router-dom';

import { Icon, IIconProps } from '@/ui-components/icon/icon';

import './icon-button.less';

type Token = 'sm' | 'md' | 'lg' | 'xl' | 'table-row';

export type IconButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: Token;
  disabled?: boolean;
  ariaLabel: string;
  viewOnly?: boolean;
  tooltip?: TooltipProps;
  testId?: string;
  href?: string;
} & IIconProps;

function getSize(token: Token) {
  switch (token) {
    case 'sm':
      return '18px';
    case 'md':
      return '24px';
    case 'lg':
      return '28px';
    case 'xl':
      return '32px';
    case 'table-row':
      return '20px';
    default:
      return '28px';
  }
}

export const IconButton = ({
  viewOnly,
  size,
  onClick,
  disabled,
  ariaLabel,
  tooltip,
  testId,
  href,
  className,
  ...props
}: IconButtonProps) => {
  const style = { '--icon-button-size': getSize(size ?? 'lg') } as React.CSSProperties;
  const iconButton = href ? (
    <Link
      to={href}
      className={clsx('icon-button', className, { 'icon-button--view-only': viewOnly })}
      style={style}
      aria-label={ariaLabel}
      tabIndex={0}
      data-testid={testId}
    >
      <Icon {...props} />
    </Link>
  ) : (
    <button
      type="button"
      onClick={onClick}
      className={clsx('icon-button', className, { 'icon-button--view-only': viewOnly })}
      style={style}
      disabled={disabled}
      aria-label={ariaLabel}
      tabIndex={0}
      data-testid={testId}
    >
      <Icon {...props} />
    </button>
  );

  if (tooltip) {
    return (
      <Tooltip overlayClassName="tooltip-hover" {...tooltip}>
        {iconButton}
      </Tooltip>
    );
  }

  return iconButton;
};

export default IconButton;
