import { action, computed } from 'mobx';

import {
  EventAction,
  EventCategory,
  PageView,
  VisitCustomDimension,
} from '@/stores/analytics-store/analytics-store-tracking-config';
import { Store, inject } from '@/types/store';
import ConfigStore from '@/stores/config-store';
import { hash } from '@/utils/crypto/crypto';
import { UsageStatisticsViewApi } from '@/stores/api-store';
import { LocalStorageContext, LocalStorageStore } from '@/stores/local-storage-store';
import EnvironmentStore from '@/stores/environment-store';

export enum AnalyticServices {
  MATOMO = 'MATOMO',
}

// must match the site id in matomo
enum MatomoSiteId {
  PRODUCTION = 1,
  DEV = 2,
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    _paq: any;
  }
}

type AnalyticsConfig = {
  active: boolean;
  lastCheckedAt: Date;
};

const ANALYTICS_CONFIG_KEY = 'config';

/**
 * Store for client side analytics
 * e.g.: load scripts from 3rd parties and related tracking functions
 */
@Store()
export default class AnalyticsStore {
  private configStore = inject(ConfigStore);
  private localStorageStore = inject(LocalStorageStore);
  private environmentStore = inject(EnvironmentStore);

  private enabledServices: AnalyticServices[] = [];

  @action
  async init() {
    try {
      await this.addMatomo();
    } catch (error) {
      console.error('Could not load Matomo:', error);
    }
  }

  @action
  private async addMatomo() {
    const isUserAllowedToBeTracked = await this.isUserAllowedToBeTracked();

    if (!isUserAllowedToBeTracked) {
      return;
    }

    const userId = await hash(`${this.configStore.tenantId}-${this.configStore.userId}`);
    const matomoScript = `var _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */

    _paq.push(['setCustomDimension', ${VisitCustomDimension.ROLE}, '${this.configStore.userRolesEnum.join(',')}']);
    _paq.push(['enableLinkTracking']);

    (function() {
      var u="https://webuntis.matomo.cloud/";

      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', '${this.matomoSiteId}']);
      _paq.push(['setUserId', '${userId}']);

      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src='//cdn.matomo.cloud/webuntis.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
    })();`;

    this.addScriptTag(matomoScript);
    this.enabledServices.push(AnalyticServices.MATOMO);
  }

  @action
  private async isUserAllowedToBeTracked(): Promise<boolean> {
    if (this.environmentStore.isLocal()) {
      return false; // for testing flip this boolean to true
    }

    if (this.environmentStore.isNightly()) {
      return true;
    }

    const analyticsConfig = this.localStorageStore.readJSON(LocalStorageContext.ANALYTICS, ANALYTICS_CONFIG_KEY) as
      | AnalyticsConfig
      | undefined;

    // if no config, generate one and return the active status
    if (!analyticsConfig) {
      const analyticsConfig = await this.generateStoreAndGetUsageStatisticsStatus();

      return analyticsConfig.active;
    }

    analyticsConfig.lastCheckedAt = new Date(analyticsConfig.lastCheckedAt);
    const endOfMonth = new Date(
      analyticsConfig.lastCheckedAt.getFullYear(),
      analyticsConfig.lastCheckedAt.getMonth() + 1,
      0,
      23,
      59,
      59,
    );

    // if lastCheckedAt period is over, generate a new config and return the active status
    if (new Date() > endOfMonth) {
      const analyticsConfig = await this.generateStoreAndGetUsageStatisticsStatus();

      return analyticsConfig.active;
    }

    // analytics is still valid for that period, return the active status
    return analyticsConfig.active;
  }

  private async generateStoreAndGetUsageStatisticsStatus(): Promise<AnalyticsConfig> {
    const { data } = await UsageStatisticsViewApi.generateStoreAndGetUsageStatisticsStatus();

    const analyticsConfig: AnalyticsConfig = {
      active: data.active,
      lastCheckedAt: new Date(),
    };

    this.localStorageStore.writeJSON(LocalStorageContext.ANALYTICS, ANALYTICS_CONFIG_KEY, analyticsConfig);

    return analyticsConfig;
  }

  @action
  private addScriptTag(innerHTML: string) {
    const newScriptTag = document.createElement('script');
    newScriptTag.async = true;
    newScriptTag.innerHTML = innerHTML;

    document.body.appendChild(newScriptTag);
  }

  @action
  trackEvent(
    category: EventCategory,
    action: EventAction,
    name?: string,
    value?: number,
    metadata?: { [key: string]: string | number | undefined },
  ) {
    if (this.isMatomoEnabled) {
      if (metadata) {
        Object.keys(metadata).forEach((key, index) => {
          const metadataValue = metadata[key];
          if (metadataValue !== undefined) {
            this.matomo.push(['setCustomVariable', index + 1, key, metadataValue]);
          }
        });
      }

      const eventArray: (string | number | undefined)[] = ['trackEvent', category, action, name, value];

      // Filter out undefined values from the event array (if any).
      const filteredEventArray = eventArray.filter((item) => item !== undefined);

      // Push the event to Matomo
      this.matomo.push(filteredEventArray);
    }
  }

  @action
  trackPageView(customTitle: PageView) {
    if (this.isMatomoEnabled) {
      this.matomo.push(['setDocumentTitle', customTitle]);
      this.matomo.push(['trackPageView']);
    }
  }

  @computed
  get matomo() {
    if (!window._paq && !this.isMatomoEnabled) {
      throw new Error('Matomo is not enabled');
    }

    return window._paq;
  }

  @computed
  get isMatomoEnabled() {
    return this.enabledServices.includes(AnalyticServices.MATOMO);
  }

  private get matomoSiteId() {
    if (this.environmentStore.isProduction()) {
      return MatomoSiteId.PRODUCTION;
    }

    return MatomoSiteId.DEV;
  }
}
