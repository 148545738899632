import { useEffect, useRef, useState } from 'react';

/**
 * Takes in a boolean and returns its value, but with a given delay if it is set to true. Setting it to false
 * returns false immediately without any delay.
 * @param isLoading
 * @param delay
 */
export const useDelay = (isLoading: boolean, delay?: number) => {
  const [delayedIsLoading, setDelayedIsLoading] = useState(isLoading);
  const [timeoutRef] = useState(useRef<NodeJS.Timeout>()); // to prevent infinity loops

  useEffect(() => {
    if (isLoading) {
      timeoutRef.current = setTimeout(() => setDelayedIsLoading(true), delay ? delay : 200);
    } else {
      setDelayedIsLoading(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  }, [isLoading, timeoutRef, delay]);

  return delayedIsLoading;
};
