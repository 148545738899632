/**
 Utility functions for validation.
 */

/**
 * If you modify this validation, changes must be discussed with mobile developers, since Web and Mobile should
 * behave the same! Also don't forget to adapt the corresponding URL validator in the backend
 */
export const isValidUrl = (value: string): boolean => {
  /* eslint-disable */
  const pattern = "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.%]+$";
  /* eslint-enable */
  const regExp = new RegExp(pattern);
  return regExp.test(value);
};
