import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { MessagesOfTheDayStore } from './messages-of-day-store';

import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import useStore from '@/hooks/useStore';

const AdminMessagesOfDayEdit = observer(() => {
  const params = useParams<{ id: string }>();
  const messagesOfTheDayStore = useStore(MessagesOfTheDayStore);
  const isLoading = messagesOfTheDayStore.isDataLoading;
  const [redirect, setRedirect] = useState(false);

  useComponentDidMount(() => {
    messagesOfTheDayStore.setEditId(params.id);
  });

  useEffect(() => {
    async function openEditForm() {
      if (!isLoading) {
        await messagesOfTheDayStore.openEditForm();
        setRedirect(true);
      }
    }

    openEditForm();
  }, [messagesOfTheDayStore, isLoading]);

  if (redirect) {
    return <Redirect to="/messages-of-the-day-new" />;
  }

  return null;
});

export default AdminMessagesOfDayEdit;
