import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import TodayMessagesOfTheDayStore from '@today/stores/today-messages-of-the-day-store';
import { MessageOfTheDay } from '@today/components/message-of-the-day-detail/message-of-the-day-detail';
import useStore from '@/hooks/useStore';
import { DashboardCardDetailDto } from '@untis/wu-rest-view-api';

import './messages-of-the-day-detail.less';

type MessagesOfTheDayDetailProps = {
  activeId?: number;
};

const MessagesOfTheDayDetail = observer(({ activeId }: MessagesOfTheDayDetailProps) => {
  const todayMessagsOfTheDayStore = useStore(TodayMessagesOfTheDayStore);

  const handleDelete = useCallback(
    (motdId: number) => () => {
      todayMessagsOfTheDayStore.deleteMessageOfDay(motdId);
    },
    [todayMessagsOfTheDayStore],
  );

  const handleEdit = useCallback(
    (motdId: number) => () => {
      todayMessagsOfTheDayStore.navigate(`messages-of-the-day-new/edit/${motdId}`);
    },
    [todayMessagsOfTheDayStore],
  );

  const handleMessageRead = useCallback(
    (motd: DashboardCardDetailDto) => {
      todayMessagsOfTheDayStore.markMessageRead(motd);
    },
    [todayMessagsOfTheDayStore],
  );

  return (
    <div className="motd-detail-list">
      {todayMessagsOfTheDayStore.messagesOfTheDayDetails.map((motd) => {
        return (
          <MessageOfTheDay
            scrollIntoView={motd.id === activeId}
            key={motd.id}
            motd={motd}
            onDelete={handleDelete(motd.id)}
            onEdit={handleEdit(motd.id)}
            onMessageRead={handleMessageRead}
          />
        );
      })}
    </div>
  );
});

export default MessagesOfTheDayDetail;
