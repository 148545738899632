import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { IDeprecatedSearchBarOption } from '@/ui-components/deprecated-search-bar/deprecated-search-bar';
import {
  DateRangeOptionEnum,
  ICustomDateRange,
  IDateRange,
} from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import { ClassRegHomeworkViewApi } from '@/stores/api-store';
import {
  ClassRegAttachmentDto,
  ClassRegDateRangeTypeEnum,
  ClassRegElementDto,
  ClassRegHomeworkDto,
  ClassRegSchoolYearDto,
  SchoolYearDto,
} from '@untis/wu-rest-view-api/api';
import { dayjsFromDateString } from '@/utils/date/date-util';
import { defaultSorting, sortingByDayjs } from '@/utils/sorting/sorting-util';
import { matchesAllSearches } from '@/utils/filtering/filtering-util';

export interface IHomeworkRow extends ITableRowKey {
  id?: number;
  date?: Dayjs;
  dueDate?: Dayjs;
  subject?: string;
  homework?: string;
  key: number;
  attachments?: ClassRegAttachmentDto[];
  createdByUser?: string;
  lessonId?: number;
  completed?: boolean;
  remark?: string;
}

export class HomeworkStore {
  @observable private _homeworks: ClassRegHomeworkDto[] = [];
  @observable private _selectedOptions: IDeprecatedSearchBarOption[] = [];
  @observable private _selectedFreeTextOptions: string[] = [];
  @observable private _subjects: ClassRegElementDto[] = [];
  @observable private _teachers: ClassRegElementDto[] = [];
  @observable private _classes: ClassRegElementDto[] = [];
  @observable private _semesters: ClassRegSchoolYearDto[] = [];
  @observable private _isMetaLoading: boolean = true;
  @observable private _isDataLoading: boolean = true;
  @observable private _isInitialised: boolean = false;
  @observable private _currentSchoolyear: SchoolYearDto | undefined;

  @observable private _selectedDateRange: IDateRange = {
    startDate: dayjs(0),
    endDate: dayjs(0),
    option: DateRangeOptionEnum.CUSTOM_DATE_RANGE,
  };

  @observable private _dateValue!: IDateRange | ICustomDateRange;

  private classCategory = t('general.classes');
  private teacherCategory = t('general.teachers');
  private subjectCategory = t('general.subjects');

  isTodayInCurrentSchoolyear(): boolean {
    if (!this._currentSchoolyear) {
      return false;
    }
    const today = dayjs();
    return (
      today.isSameOrAfter(this._currentSchoolyear.dateRange?.start) &&
      today.isSameOrBefore(this._currentSchoolyear.dateRange?.end)
    );
  }

  @action init(schoolyear: SchoolYearDto) {
    this._currentSchoolyear = schoolyear;
    this._dateValue = this.isTodayInCurrentSchoolyear()
      ? {
          option: DateRangeOptionEnum.DATE_RANGE_WEEK,
          startDate: dayjs().startOf('week'),
          endDate: dayjs().endOf('week'),
        }
      : {
          option: DateRangeOptionEnum.DATE_RANGE_SCHOOLYEAR,
          startDate: dayjs(schoolyear.dateRange?.start),
          endDate: dayjs(schoolyear.dateRange?.end),
        };
    this.fetchMeta(schoolyear);
    this.fetchData();

    this._isInitialised = true;
  }

  @action async fetchData() {
    this._isDataLoading = true;
    const requestDto = {
      classId: parseInt(this._selectedOptions.find((option) => option.category === this.classCategory)?.id!),
      teacherId: parseInt(this._selectedOptions.find((option) => option.category === this.teacherCategory)?.id!),
      subjectId: parseInt(this._selectedOptions.find((option) => option.category === this.subjectCategory)?.id!),
      dateRange: {
        start:
          dayjs(this._dateValue.startDate).format('YYYY-MM-DD') ||
          dayjs(this._selectedDateRange.startDate).format('YYYY-MM-DD'),
        end:
          dayjs(this._dateValue.endDate).format('YYYY-MM-DD') ||
          dayjs(this._selectedDateRange.endDate).format('YYYY-MM-DD'),
      },
      dateRangeType: ClassRegDateRangeTypeEnum.WEEK,
    };

    ClassRegHomeworkViewApi.loadHomeworkList(requestDto)
      .then((response) => {
        this._homeworks = response.data.homeworkList;
      })
      .finally(() => (this._isDataLoading = false));
  }

  @action async fetchMeta(schoolyear: SchoolYearDto) {
    this._isMetaLoading = true;
    this._selectedDateRange = {
      startDate: dayjs().startOf('day'),
      endDate: dayjs().endOf('day'),
      option: DateRangeOptionEnum.CUSTOM_DATE_RANGE,
    };
    ClassRegHomeworkViewApi.loadHomeworkListMeta()
      .then((response) => {
        this._classes = response.data.classes;
        this._subjects = response.data.subjects;
        this._teachers = response.data.teachers;
        this._semesters = response.data.schoolYears!.filter((sy) => sy.parentId === schoolyear.id);
      })
      .finally(() => (this._isMetaLoading = false));
  }

  @computed
  get options(): IDeprecatedSearchBarOption[] {
    const classOptions: IDeprecatedSearchBarOption[] = this._classes.map((c) => ({
      id: c.id.toString(),
      label: c.name,
      category: this.classCategory,
    }));

    const subjectOptions: IDeprecatedSearchBarOption[] = this._subjects.map((c) => ({
      id: c.id.toString(),
      label: c.name,
      category: this.subjectCategory,
    }));

    const teacherOptions: IDeprecatedSearchBarOption[] = this._teachers.map((c) => ({
      id: c.id.toString(),
      label: c.name,
      category: this.teacherCategory,
    }));

    return [...classOptions, ...subjectOptions, ...teacherOptions];
  }

  @computed
  get selectedOptions(): IDeprecatedSearchBarOption[] {
    return this._selectedOptions;
  }

  @action
  setSelectedOptions(options: IDeprecatedSearchBarOption[]) {
    this._selectedOptions = options;
    this.fetchData();
  }

  @computed
  get homeworkRows(): IHomeworkRow[] {
    // filtering is applied in the backend - changing the filter triggers a refetch
    return this._homeworks.map((hw) => ({
      id: hw.id!,
      date: hw.date ? dayjsFromDateString(hw.date) : undefined,
      dueDate: hw.dueDate ? dayjsFromDateString(hw.dueDate) : undefined,
      subject: hw.subject?.name,
      homework: hw.homework,
      key: hw.id!,
      attachments: hw.attachments,
      createdByUser: hw.createdByUser,
      lessonId: hw.lessonId,
      completed: hw.completed,
      remark: hw.remark,
    }));
  }

  @computed
  get filteredRows(): IHomeworkRow[] {
    return this.homeworkRows.filter((row) =>
      matchesAllSearches(this.mapRowToSearchString(row), this._selectedFreeTextOptions),
    );
  }

  private mapRowToSearchString(row: IHomeworkRow): string {
    let res = '';
    res += row.subject ?? '';
    res += row.createdByUser ?? '';
    res += row.homework ?? '';
    res += row.attachments ? row.attachments.map((att) => att.name).join(' ') : '';
    return res.toLocaleLowerCase();
  }

  @computed
  get selectedFreeTextOptions(): string[] {
    return this._selectedFreeTextOptions;
  }

  @action.bound
  setSelectedFreeTextOptions(options: string[]) {
    this._selectedFreeTextOptions = options;
  }

  @computed
  get dateValue(): IDateRange | ICustomDateRange {
    return this._dateValue;
  }

  @action
  setDateValue(value: IDateRange | ICustomDateRange) {
    this._dateValue = value;
    this.fetchData();
  }

  @computed get semesters(): ClassRegSchoolYearDto[] {
    return this._semesters;
  }

  @computed get isDataLoading(): boolean {
    return this._isDataLoading;
  }
  @computed get isMedaLoading(): boolean {
    return this._isMetaLoading;
  }

  @action.bound
  setSelectedDateRange(dateRange: IDateRange) {
    this._selectedDateRange = dateRange;
    this.setDateValue(this._selectedDateRange);
  }

  @computed
  get selectedDateRange(): IDateRange {
    return this._selectedDateRange;
  }

  @computed
  get isInitialised(): boolean {
    return this._isInitialised;
  }

  @computed
  get currentSchoolyear(): SchoolYearDto | undefined {
    return this._currentSchoolyear;
  }

  @computed
  get columns(): Columns<IHomeworkRow> {
    const cols: Columns<IHomeworkRow> = [
      {
        type: ColumnType.Date,
        header: t('general.dateOfAssignment'),
        key: 'date',
        sorter: (a, b) => sortingByDayjs(a.date, b.date),
        width: 150,
      },
      {
        type: ColumnType.Date,
        header: t('general.dateDue'),
        key: 'dueDate',
        sorter: (a, b) => sortingByDayjs(a.dueDate, b.dueDate),
        width: 150,
      },
      {
        type: ColumnType.Text,
        header: t('general.subject'),
        key: 'subject',
        sorter: (a, b) => defaultSorting(a.subject ?? '', b.subject ?? ''),
        width: 150,
        ellipsis: true,
      },
      {
        type: ColumnType.Text,
        header: t('general.createdBy'),
        key: 'createdByUser',
        sorter: (a, b) => defaultSorting(a.createdByUser ?? '', b.createdByUser ?? ''),
        width: 150,
        ellipsis: true,
      },
      {
        type: ColumnType.TextPopover,
        header: t('general.homeworks'),
        key: 'homework-column',
        getValue: (row) => (row.homework ? [row.homework] : []),
        maxWidth: 150,
        sorter: (a, b) => defaultSorting(a.homework ?? '', b.homework ?? ''),
      },
      {
        type: ColumnType.Attachments,
        header: t('general.attachment_plural'),
        key: 'attachments',
        getAttachments: (row) =>
          row.attachments
            ? row.attachments.map((a) => {
                return {
                  id: a.id.toString(),
                  name: a.name,
                  src: a.url,
                };
              })
            : [],
      },
    ];
    return cols;
  }
}
