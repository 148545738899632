import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';

import { PlatformApplicationCalendarIntegrationDto } from '@untis/wu-rest-view-api';

import './platform-application-calendar-integration.less';

interface IPlatformApplicationCalendarIntegrationProps {
  info: PlatformApplicationCalendarIntegrationDto;
  testId: string;
}

const PlatformApplicationCalendarIntegration = observer((props: IPlatformApplicationCalendarIntegrationProps) => {
  const { t } = useTranslation();
  const { info } = props;
  return (
    <section id={props.testId} className="calendar-integration-section">
      <h2 className="calendar-integration-title">{t('general.calendarIntegration')}</h2>
      <div className="calendar-integration-section-content">
        <div className="calendar-integration-description">{t('platform.details.calendarIntegration.description')}</div>
        <div className="enable-calendar-integration">
          <label htmlFor="enable-calendar-integration-checkbox" className="calendar-integration-checkbox-label">
            {t('platform.details.calendarIntegration.enableIntegration')}
          </label>
          <Checkbox
            id="enable-calendar-integration-checkbox"
            checked={info.integrationEnabled}
            onChange={(e) => (info.integrationEnabled = e.target.checked)}
          />
        </div>
      </div>
    </section>
  );
});

export default PlatformApplicationCalendarIntegration;
