import React, { ReactNode, useRef, useState } from 'react';
import { DatePicker as AntDDatePicker } from 'antd';
import clsx from 'clsx';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import dayjs, { Dayjs } from 'dayjs';

import { CalendarHeader } from '@/ui-components/date/calendar/calendar-header/calendar-header';
import { CalendarFooter } from '@/ui-components/date/calendar/calendar-footer/calendar-footer';
import './date-calendar.less';
import '../calendar-overwrites.less';

type PickerMode = 'week' | 'month' | 'quarter' | 'year';

export interface IDateCalendarHolidayProps {
  start: Dayjs;
  end: Dayjs;
}

export interface IDateCalendarProps {
  title?: string;
  value?: Dayjs | undefined;
  onChange?: (date: Dayjs | undefined) => void;
  onClose?: () => void;
  disabledDate?: (date: Dayjs) => boolean;
  shadow?: boolean;
  picker?: PickerMode;
  onSubmit?: (value: Dayjs | undefined) => void;
  renderDate?: (date: Dayjs) => ReactNode;
  allowClear?: boolean;
  locale?: PickerLocale;
  format?: any;
  testId?: string;
  holidays?: IDateCalendarHolidayProps[];
}

export const DateCalendar = (props: IDateCalendarProps) => {
  const [value, setValue] = useState<Dayjs | undefined>(props.value);
  const ref = useRef<HTMLDivElement>(null);
  const hasFooter = props.onSubmit;
  const today = dayjs();

  const className = clsx('date-calendar', {
    'with-header': props.title,
    'with-footer': hasFooter,
    'day-mode': props.picker === undefined,
    'week-mode': props.picker === 'week',
    shadow: props.shadow === true || props.shadow === undefined,
  });

  const antClassName = clsx(className, 'inner');

  const handleChange = (value: Dayjs | null): void => {
    const newValue = value ? value : undefined;
    setValue(newValue);
    props.onChange && props.onChange(newValue);
  };

  // is called whenever the AntDesign DatePicker wants to open/close the calendar
  // e.g. "outside clicks", selection of dates, enter key,...
  // we only want it to close the calendar. only a click on the label should open it.
  const handleOnOpenChange = (status: boolean) => {
    if (!status) {
      props.onClose && props.onClose();
    }
  };

  const renderDate = (date: Dayjs) => {
    // normally, AntD picker does not call the onChange function when the
    // current day is selected again - but we want to know if the user does that.
    const isSelected = value && date.isSame(value, 'date');
    const isDisabled = props.disabledDate && props.disabledDate(date);

    const className = clsx('date-cell', {
      today: date.isSame(today, 'date'),
      selected: isSelected,
      disabled: isDisabled,
      ...DateCalendarUtils.resolveHolidayClasses(date, props.holidays),
    });

    return (
      <div className={className} onClick={isSelected && !isDisabled ? () => handleChange(date) : undefined}>
        <div className="inner">{date.date()}</div>
      </div>
    );
  };

  const testId = props.testId ? `${props.testId}-date-calendar` : undefined;

  return (
    <div className={className} ref={ref} data-testid={testId}>
      {props.title && <CalendarHeader title={props.title} testId={testId} />}
      <AntDDatePicker
        inputReadOnly
        value={value ?? null}
        onChange={handleChange}
        disabledDate={(date: Dayjs) => props.disabledDate?.(date) ?? false}
        showToday={false}
        className={antClassName}
        getPopupContainer={(node) => node.parentElement!}
        open
        onOpenChange={handleOnOpenChange}
        dateRender={(date: Dayjs) => props.renderDate?.(date) ?? renderDate(date)}
        picker={props.picker}
        allowClear={props.allowClear}
        locale={props.locale}
        format={props.format}
      />
      {hasFooter && (
        <CalendarFooter
          onSubmit={props.onSubmit ? () => props.onSubmit!(value) : undefined}
          submitDisabled={!value}
          testId={testId}
        />
      )}
    </div>
  );
};

export const DateCalendarUtils = {
  resolveHolidayClasses: (
    date: Dayjs,
    holidays?: IDateCalendarHolidayProps[],
  ): { 'holiday-around': boolean; 'holiday-start': boolean; 'holiday-end': boolean } | Record<string, never> => {
    if (holidays) {
      const around = !!holidays.find((h) => date.isBetween(h.start, h.end, 'd', '()'));
      return {
        'holiday-around': around,
        'holiday-start': !around && !!holidays.find((h) => date.isSame(h.start, 'd')),
        'holiday-end': !around && !!holidays.find((h) => date.isSame(h.end, 'd')),
      };
    }
    return {};
  },
};
