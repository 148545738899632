import { Tabs as AntTabs } from 'antd';
import { TabsProps as AntTabsProps } from 'antd/lib/tabs';
import React from 'react';

import { ITestComponentProps } from '@/types/test-component-props';
import './tabs.less';
import TabsAddButton from '@/ui-components/tabs/tabs-add-button';

interface IProps extends ITestComponentProps {
  onTabAdd?: () => void;
  onTabDelete?: (targetKey: string) => void;
}

export type TabsProps = Omit<AntTabsProps, 'type' | 'onEdit' | 'hideAdd'> & IProps;

const Tabs = (props: TabsProps) => {
  const { className, onTabAdd, onTabDelete, testId, ...tabsProps } = props;

  const onTabRemove = (targetKey: React.MouseEvent | React.KeyboardEvent | string) => {
    if (onTabDelete && typeof targetKey === 'string') {
      onTabDelete(targetKey);
    }
  };

  const tabsClassName = 'untis-tabs' + (className ? ' ' + className + ' ' : '');

  return (
    <AntTabs
      {...tabsProps}
      className={tabsClassName}
      type="editable-card"
      onEdit={onTabRemove}
      tabBarExtraContent={tabsProps.tabBarExtraContent ?? <TabsAddButton onTabAdd={onTabAdd} />}
      hideAdd
      data-testid={testId}
    />
  );
};

export default Tabs;
