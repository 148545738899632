import { observable } from 'mobx';

import TokenStore from '@/stores/token-store';
import { inject, IStore, Store } from '@/types/store';

/**
 * Metadata store for substitution planning that holds global data which is needed by other stores.
 */
@Store()
export default class SpMetadataStore implements IStore {
  byTeacherId: number | null = null;

  @observable isLoading = true;

  private tokenStore = inject(TokenStore);

  init(byTeacher: boolean) {
    if (byTeacher) {
      const personId = this.tokenStore.getPersonId();
      if (personId > -1) {
        this.byTeacherId = personId;
      } else {
        throw new Error('User doesnt have a person attached.');
      }
    } else {
      this.byTeacherId = null;
    }
  }

  get byTeacher() {
    return this.byTeacherId != null;
  }
}
