import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { IconButton, IconButtonProps } from '../icon-button/icon-button';

import { Attachments, IAttachmentProps } from '@/ui-components/attachments/attachments';
import './expandable-text-container.less';

export interface IExpandableTextContainerProps {
  text: string;
  title: string;
  date?: string;
  iconButtons?: IconButtonProps[];
  attachments?: IAttachmentProps;
  testId?: string;
}

export const ExpandableTextContainer = (props: IExpandableTextContainerProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);
  const testId = props.testId ? `${props.testId}-expandable-textarea` : undefined;

  const className = clsx('expandable-textarea', {
    collapsed: !expanded,
  });

  const handleToggle = () => setExpanded(!expanded);
  return (
    <div className={className} data-testid={testId}>
      <div className="header">
        <div className="left">
          {props.date && <time>{props.date}</time>}
          <h4>{props.title}</h4>
        </div>
        <div className="right">
          {props.iconButtons &&
            props.iconButtons.map((button, index) => {
              const buttonTestId = testId ? `${testId}-button-${button.type}` : undefined;
              return <IconButton testId={buttonTestId} size="md" key={button.ariaLabel + index} {...button} />;
            })}
          <IconButton
            type={expanded ? 'collapse' : 'expand'}
            onClick={handleToggle}
            size="md"
            ariaLabel={expanded ? t('general.collapse') : t('general.expand')}
            testId={testId ? `${testId}-expand` : undefined}
          />
        </div>
      </div>
      <div className="content">
        {props.text}
        {expanded && props.attachments && props.attachments.value.length > 0 && (
          <div className="attachments">
            <Attachments {...props.attachments} />
          </div>
        )}
      </div>
    </div>
  );
};
