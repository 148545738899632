import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import MessageDetailViewStore from '@mg/stores/message-detail-view-store';
import MessageDateTime from '@mg/components/message-date-time/message-date-time';
import { MessageHistoryDto } from '@untis/wu-rest-view-api';
import ConfigStore from '@/stores/config-store';
import useStore from '@/hooks/useStore';
import { DeprecatedAttachment, DeprecatedAttachments, Skeleton } from '@/ui-components';
import './history-messages.less';

function HistoryMessageSkeleton() {
  return (
    <div className="history-message">
      <div className="history-sender-container">
        <div style={{ display: 'flex' }}>
          <Skeleton.Input className="history-fromTo" size="small" active style={{ width: 20 }} />
          <Skeleton.Input className="history-fromTo-name" size="small" active style={{ width: 150 }} />
        </div>
        <div>
          <Skeleton.Input size="small" active style={{ width: 60 }} />
        </div>
      </div>
      <div className="history-subject">
        <Skeleton.Input size="small" active style={{ width: 60 }} />
      </div>

      <div className="history-content">
        <Skeleton.Input size="small" active style={{ width: 400 }} />
        <Skeleton.Input size="small" active style={{ width: 300, marginTop: 8 }} />
        <Skeleton.Input size="small" active style={{ width: 200, marginTop: 8 }} />
      </div>
    </div>
  );
}

interface IHistoryMessageProps {
  messageHistory: MessageHistoryDto;
  variant?: 'sent' | 'incoming';
}

/**
 *  Represents a single message inside the history message list.
 */
const HistoryMessage = observer(({ messageHistory, variant }: IHistoryMessageProps) => {
  const { t } = useTranslation();
  const configStore = useStore(ConfigStore);
  const messageDetailViewStore = useStore(MessageDetailViewStore);

  const iAmTheSender = configStore.userId === messageHistory.sender.userId;
  const iAmTheRecipient = configStore.userId === messageHistory.recipients[0]?.userId;
  const isSent = variant === 'sent';
  const isIncoming = variant === 'incoming';

  return (
    <div className="history-message">
      <div className="history-sender-container">
        <div>
          <span className="history-fromTo">{isSent ? t('general.to') : t('general.from')}:</span>
          <span className="history-fromTo-name">
            {isIncoming ? (iAmTheSender ? t('general.fromMe') : messageHistory.sender.displayName) : null}
            {isSent ? (iAmTheRecipient ? t('general.toMe') : messageHistory.recipients[0]?.displayName) : null}
          </span>
        </div>
        <MessageDateTime dateTime={messageHistory.sentDateTime} showBoth />
      </div>
      <div className="history-subject">{messageHistory.subject}</div>
      {messageHistory.isRevoked ? (
        <div className="history-content">{t('general.messageIsRevoked')}</div>
      ) : messageHistory.content ? (
        <div className="history-content" dangerouslySetInnerHTML={{ __html: messageHistory.content }} />
      ) : (
        <div className="history-content">{t('general.messageNoContent')}</div>
      )}
      <div className="history-attachments">
        {messageHistory.storageAttachments.length > 0 && (
          <DeprecatedAttachments
            readOnly
            attachments={messageDetailViewStore.getStorageAttachments(messageHistory.storageAttachments)}
          />
        )}
        {messageHistory.blobAttachment && (
          <DeprecatedAttachment
            name={messageHistory.blobAttachment.name}
            hoverIcon={{ type: 'download' }}
            onClick={() =>
              messageDetailViewStore.downloadBlobAttachment(messageHistory.id, messageHistory.blobAttachment!.name)
            }
          />
        )}
        {messageHistory.attachments.length > 0 && (
          <DeprecatedAttachments
            readOnly
            attachments={messageDetailViewStore.getOneDriveAttachments(messageHistory.attachments)}
          />
        )}
      </div>
    </div>
  );
});

const HistoryMessages = observer(
  ({
    messages,
    className,
    variant,
  }: {
    messages: MessageHistoryDto[];
    className?: string;
    variant?: 'sent' | 'incoming';
  }) => {
    return messages.length > 0 ? (
      <div className={className}>
        {messages.map((messageHistory) => {
          return (
            <HistoryMessage variant={variant} key={messageHistory.id} messageHistory={messageHistory}></HistoryMessage>
          );
        })}
      </div>
    ) : null;
  },
);

const SkeletonHistoryMessages = ({ className, count }: { className: string; count?: number }) => {
  const rows = useMemo(() => Array.from(Array(count ?? 2).keys()), [count]);
  return (
    <div className={className}>
      {rows.map((nr) => (
        <HistoryMessageSkeleton key={nr} />
      ))}
    </div>
  );
};

export { HistoryMessages, SkeletonHistoryMessages };
