import React, { useMemo } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { BackEntryTypeEnum } from '@untis/wu-rest-view-api';
import {
  ITimetableLayer,
  ITimetableLayerProps,
  ITimetableLayerHolidayProps,
} from '@te/standard/stores/data/timetable-data-store';
import { Icon } from '@/ui-components';

import './timetable-layer.less';

const TimetableLayer = observer((props: ITimetableLayer) => {
  const { type, text, leftPercentage, widthPercentage } = props;

  const parallelLeftOffset = leftPercentage !== 0 ? 4 : 0;
  const parallelWidthOffset = 8 * (widthPercentage / 100);

  const { t } = useTranslation();

  const hasText = useMemo(() => {
    return (text?.length ?? 0) > 0;
  }, [text]);

  const layerText = useMemo(() => {
    if (type === BackEntryTypeEnum.ABSENCE) {
      return hasText ? text : t('general.absent');
    } else if (type === BackEntryTypeEnum.BLOCKING) {
      return `${t('general.locked')}${hasText ? '\n' + text : ''}`;
    } else if (type === BackEntryTypeEnum.HOLIDAY) {
      return hasText ? text : t('general.holiday');
    } else {
      return '';
    }
  }, [type, text]);

  return type !== BackEntryTypeEnum.HOLIDAY ? (
    <TimetableLayerRegular
      {...props}
      layerText={layerText}
      parallelLeftOffset={parallelLeftOffset}
      parallelWidthOffset={parallelWidthOffset}
    />
  ) : (
    <TimetableLayerHoliday
      {...props}
      layerText={layerText}
      parallelLeftOffset={parallelLeftOffset}
      parallelWidthOffset={parallelWidthOffset}
    />
  );
});

const TimetableLayerRegular = (props: ITimetableLayerProps) => {
  const { height, layerText, leftPercentage, parallelLeftOffset, parallelWidthOffset, top, type, widthPercentage } =
    props;

  const timeTableLayerStyle = {
    top: top + 1,
    height: `${height === 0 ? 0 : height - 1}px`,
    left: `calc(${leftPercentage}% + ${parallelLeftOffset}px)`,
    width: `calc(${widthPercentage}% + ${parallelWidthOffset}px)`,
  };

  return (
    <div
      className="timetable-layer"
      data-testid={`timetable-layer--${type.toString().toLowerCase()}`}
      style={timeTableLayerStyle as React.CSSProperties}
    >
      <span className="timetable-layer--text">{layerText}</span>
    </div>
  );
};

const TimetableLayerHoliday = (props: ITimetableLayerHolidayProps) => {
  const {
    height,
    layerText,
    textDate: layerTextDate,
    leftPercentage,
    parallelLeftOffset,
    parallelWidthOffset,
    top,
    type,
    widthPercentage,
    bookable,
  } = props;

  const timeTableLayerStyle = {
    top: top + 1,
    height: `${height === 0 ? 0 : height - 1}px`,
    left: `calc(${leftPercentage}% + ${parallelLeftOffset}px)`,
    width: `calc(${widthPercentage}% + ${parallelWidthOffset}px)`,
  };

  const { t } = useTranslation();

  return (
    <div
      className={clsx('timetable-layer', {
        holidays: true,
      })}
      data-testid={`timetable-layer--${type.toString().toLowerCase()}`}
      style={timeTableLayerStyle as React.CSSProperties}
    >
      <div className="timetable-layer-title">
        {bookable === false && (
          <span
            className={clsx(
              'untis__timetabling_timetable__boarditem__icon',
              'untis__timetabling_timetable__boarditem__lock',
            )}
          >
            <Icon type="lock" />
          </span>
        )}
        <span className="timetable-layer--text-title">{t('menu.menuItems.masterData.subMenuItems.holidays')}</span>
      </div>
      <span className="timetable-layer--text">{layerText}</span>
      <span className="timetable-layer--text">{layerTextDate}</span>
    </div>
  );
};

export default TimetableLayer;
