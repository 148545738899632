import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import CreateCalendarEntryFormStore from './create-calendar-entry-form-store';

import DateTimeRangePicker from '@/ui-components/date-time-range/date-time-range-picker';
import useStore from '@/hooks/useStore';
import FormRow from '@/pages/calendar-entry/form-row/form-row';
import { TimegridStore } from '@/stores/timegrid-store';
import DeprecatedDropDown from '@/ui-components/deprecated-drop-down/drop-down';
import ErrorText from '@/ui-components/error-text/error-text';
import { Button, MultiTagSelect } from '@/ui-components';
import { OverbookingDto } from '@untis/sp-rest-api/api';
import TokenStore from '@/stores/token-store';
import ModalStore from '@/stores/modal-store';
import SchoolYearStore from '@/stores/schoolyear-store';

import '../calendar-entry-form.less';

interface IProps {
  initialDate?: Dayjs;
  onSuccessfulSubmit?: () => void;
  onCancel?: () => void;
  overbooking?: OverbookingDto;
  editMode?: boolean;
}

const CreateCalendarEntryForm = observer((props: IProps) => {
  const { t } = useTranslation();
  const timegridStore = useStore(TimegridStore);
  const tokenStore = useStore(TokenStore);
  const modalStore = useStore(ModalStore);
  const schoolYearStore = useStore(SchoolYearStore);
  const { initialDate, onSuccessfulSubmit, onCancel, overbooking, editMode } = props;

  const [store] = useState<CreateCalendarEntryFormStore>(() => {
    const getInitialDate = (
      overbookingDateTime: string | undefined,
      initialDate: Dayjs | undefined,
      timeSlotTime: Dayjs | undefined,
    ) => {
      if (overbookingDateTime) {
        return dayjs.utc(overbookingDateTime);
      } else if (timeSlotTime && initialDate) {
        if (schoolYearStore.isTodayInCurrentSchoolYear() && initialDate.isBefore(dayjs(), 'date')) {
          return dayjs().set('hour', timeSlotTime.hour()).set('minute', timeSlotTime.minute());
        } else {
          return initialDate.set('hour', timeSlotTime.hour()).set('minute', timeSlotTime.minute());
        }
      } else {
        return dayjs();
      }
    };

    const currentTimeSlot = timegridStore.getCurrentTimeSlot();
    const personId = tokenStore.getPersonId();

    // if a teacher uses this component form should be pre-selected with this teacher
    const teacherId: number | undefined = tokenStore.isTeacher() && personId > 0 ? personId : undefined;
    const initialStart = getInitialDate(overbooking?.startDateTime, initialDate, currentTimeSlot?.startTime);
    const initialEnd = getInitialDate(overbooking?.endDateTime, initialDate, currentTimeSlot?.endTime);
    return new CreateCalendarEntryFormStore(
      initialStart,
      initialEnd,
      teacherId ? [teacherId] : undefined,
      editMode,
      overbooking,
    );
  });

  const handleDateTimeRangeChange = (start: Dayjs, end: Dayjs) => {
    store.setStart(start);
    store.setEnd(end);
    store.handleTimeRangeChanged();
  };

  const onSubmit = async () => {
    const submitResult = await store.handleSubmit();
    if (submitResult) {
      onSuccessfulSubmit && onSuccessfulSubmit();
    }
  };

  const onDeleteCalendarEntry = async () => {
    const deleteConfirmed = await modalStore.deprecatedBooleanUserPrompt({
      content: t('general.deleteCalendarEntryConfirmation'),
      okText: t('general.delete'),
    });

    if (deleteConfirmed) {
      const deleteResult = await store.handleDelete();
      if (deleteResult) {
        onSuccessfulSubmit && onSuccessfulSubmit();
      }
    }
  };

  return (
    <div className="calendar-entry-form">
      <Row className="header-new">
        <Col className="side-col" xs={0} sm={2} md={4} lg={6} xl={8} />
        <h1> {!editMode ? t('general.createCalendarEntry') : t('general.editCalendarEntry')}</h1>
        <Col className="side-col" xs={0} sm={2} md={4} lg={6} xl={8} />
      </Row>

      <Row className="center-row">
        <Col className="side-col" xs={0} sm={2} md={4} lg={6} xl={8} />
        <div className="form-new">
          {store.typeItems.length > 1 && (
            <FormRow icon="tag">
              <DeprecatedDropDown
                placeholder={t('createCalendarEntry.chooseType')}
                items={store.typeItems}
                selectedKey={store.selectedType}
                onSelect={(item) => store.setSelectedType(item.key)}
                disabled={editMode}
              />
            </FormRow>
          )}
          {!!store.selectedType && (
            <>
              <FormRow icon="shared_absence" className="time-row">
                <div>
                  <DateTimeRangePicker
                    onDateTimeRangeChange={handleDateTimeRangeChange}
                    startDateTime={store.start}
                    endDateTime={store.end}
                    timeSlots={timegridStore.timeRangePickerTimeSlots}
                    onlySameDay={true}
                    disabledDate={store.disabledDate}
                    disabled={editMode}
                  />
                  {store.dateTimeRangeSelectionError && <ErrorText text={t('general.dateTimeRangeEndBeforeStart')} />}
                </div>
              </FormRow>
              <FormRow icon="shared_subjectEntity">
                <DeprecatedDropDown
                  items={store.subjectItems}
                  selectedKey={store.selectedSubjectKey}
                  onSelect={(item) => store.setSelectedSubject(item)}
                  placeholder={
                    editMode ? t('createCalendarEntry.noSubjectSelected') : t('createCalendarEntry.chooseSubject')
                  }
                  disabled={editMode}
                />
              </FormRow>
              <FormRow icon="shared_classEntity">
                <MultiTagSelect
                  placeholder={
                    editMode ? t('createCalendarEntry.noClassSelected') : t('createCalendarEntry.addClasses')
                  }
                  value={store.classItems}
                  onAdd={store.addClassHandler}
                  disabled={editMode}
                />
              </FormRow>
              <FormRow icon="shared_roomEntity">
                <MultiTagSelect
                  placeholder={t('general.addRooms')}
                  value={store.roomItems}
                  onAdd={store.addRoomHandler}
                />
              </FormRow>
              <FormRow icon="shared_teacherEntity">
                <MultiTagSelect
                  placeholder={t('createCalendarEntry.addTeachers')}
                  value={store.teacherItems}
                  onAdd={store.addTeacherHandler}
                />
              </FormRow>
            </>
          )}
        </div>
        <Col className="side-col" xs={0} sm={2} md={4} lg={6} xl={8} />
      </Row>

      <Row className="button-bar button-bar--SP">
        <Col className="side-col" xs={0} sm={2} md={4} lg={6} xl={8} />
        <div className="button-bar-content">
          {editMode && (
            <div className="left">
              <Button
                size="large"
                type="primary"
                outline
                onClick={onDeleteCalendarEntry}
                testId="sp-admin-calendar-entry-delete-button"
              >
                {t('general.delete')}
              </Button>
            </div>
          )}
          <div className="right">
            {onCancel && (
              <Button size="large" outline onClick={onCancel} testId="sp-admin-calendar-entry-cancel-button">
                {t('general.cancel')}
              </Button>
            )}
            <Button
              type="primary"
              size="large"
              onClick={onSubmit}
              disabled={store.submitDisabled}
              testId="sp-admin-calendar-entry-save-button"
            >
              {editMode ? t('general.update') : t('general.save')}
            </Button>
          </div>
        </div>
        <Col className="side-col" xs={0} sm={2} md={4} lg={6} xl={8} />
      </Row>
    </div>
  );
});
export default CreateCalendarEntryForm;
