import { Card as AntCard } from 'antd';
import { CardProps as AntCardProps } from 'antd/lib/card';
import React, { KeyboardEventHandler, Ref } from 'react';

import './card.less';
import { ITestComponentProps } from '@/types/test-component-props';

interface IProps extends ITestComponentProps {
  dropShadow?: boolean;
  tabIndex?: number;
  onKeyUp?: KeyboardEventHandler<KeyboardEvent>;
  focusableRef?: Ref<HTMLDivElement>;
}

export type CardProps = AntCardProps & IProps;

export const Card = (props: CardProps) => {
  const { dropShadow, className, tabIndex, onKeyUp, focusableRef, testId, ...cardProps } = props;

  const cardClassName =
    'untis-card' + (className ? ' ' + props.className + ' ' : '') + (dropShadow ? ' untis-card--shadow' : '');

  return (
    <AntCard {...cardProps} className={cardClassName} data-testid={testId}>
      <div
        tabIndex={typeof tabIndex === 'undefined' ? -1 : tabIndex}
        ref={focusableRef}
        className="untis-card-focusable"
        onKeyUp={onKeyUp}
      >
        {props.children}
      </div>
    </AntCard>
  );
};

export default Card;
