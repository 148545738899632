import React from 'react';
import clsx from 'clsx';

import { Input } from '@/ui-components';
import DeprecatedFormItem, {
  DeprecatedFormItemWrapperProps,
} from '@/ui-components/deprecated-form/deprecated-form-item/deprecated-form-item';
import './deprecated-text-form-item.less';

interface IDeprecatedTextFormItemProps extends DeprecatedFormItemWrapperProps<string> {
  validateTrigger?: 'onChange' | 'onBlur' | 'onSubmit';
  onChange?: (newValue: string) => void;
  placeholder?: string;
}

/**
 * This is the wrapper component for Input elements for usage within a <WUForm>.
 */
const DeprecatedTextFormItem = (props: IDeprecatedTextFormItemProps) => {
  const {
    name,
    onChange,
    errorMessage,
    value,
    className,
    label,
    help,
    validateStatus,
    rules,
    validationDependencies,
    form,
    formSubmittedOnce,
    validateTrigger,
    formRowIcon,
    formRowNoMargin,
    ...inputProps
  } = props;

  return (
    <DeprecatedFormItem
      label={label}
      className={clsx('text-form-item', className)}
      value={value}
      valuePropName="value"
      validateTrigger={validateTrigger}
      errorMessage={errorMessage}
      initialValue={value}
      name={name}
      help={help}
      validateStatus={validateStatus}
      rules={rules}
      validationDependencies={validationDependencies}
      form={form}
      formSubmittedOnce={formSubmittedOnce}
      formRowIcon={formRowIcon}
      formRowNoMargin={formRowNoMargin}
    >
      <Input
        {...inputProps}
        value={value}
        type="text"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(e.target.value)}
      />
    </DeprecatedFormItem>
  );
};

export default DeprecatedTextFormItem;
