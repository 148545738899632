import React from 'react';

import { Tooltip, Icon } from '@/ui-components';
import './info-icon.less';

export type InfoIconProps = {
  text: string;
};

export const InfoIcon = (props: InfoIconProps) => {
  return (
    <Tooltip title={props.text} trigger={['hover', 'focus']}>
      <Icon tabIndex={0} className="info-icon" type="info-circle" />
    </Tooltip>
  );
};
