import React from 'react';

import { MessageRecipient } from '@mg/components/recipient-picker/recipient-picker';
import { RecipientTag } from '@mg/components/recipient-tag/recipient-tag';
import { MessagePersonDto } from '@untis/wu-rest-view-api';

type MessageRecipientsProps = {
  isReply: boolean;
  replyRecipient?: MessagePersonDto;
  recipients: MessageRecipient[];
  deselectPersonById: (id: number) => void;
  deselectGroupById: (id: string) => void;
};

export function MessageRecipients({
  isReply,
  replyRecipient,
  recipients,
  deselectGroupById,
  deselectPersonById,
}: MessageRecipientsProps) {
  if (isReply && replyRecipient) {
    const name = replyRecipient.displayName + (replyRecipient.className ? ` | ${replyRecipient.className}` : '');

    return <RecipientTag key={replyRecipient.id} name={name} closable={false} />;
  }

  return (
    <>
      {recipients.map((recipient) => {
        if (recipient.type === 'group') {
          const name = recipient.group.title + (recipient.group.details ? ` | ${recipient.group.details}` : '');

          return (
            <RecipientTag
              key={recipient.group.groupId}
              name={name}
              handleClose={() => deselectGroupById(recipient.group.groupId)}
            />
          );
        }

        const name =
          recipient.person.displayName + (recipient.person.className ? ` | ${recipient.person.className}` : '');

        return (
          <RecipientTag
            key={recipient.person.id}
            name={name}
            handleClose={() => deselectPersonById(recipient.person.id)}
          />
        );
      })}
    </>
  );
}
