import React from 'react';
import clsx from 'clsx';

import { Skeleton } from '@/ui-components';
import { DeprecatedFilter, IDeprecatedFilter, IFilterItem } from '@/ui-components/filter-bar/filter/deprecatedFilter';
import './filter-bar.less';

export type DeprecatedFilterBarValue = Map<string, string[] | string | undefined>;

export interface IDeprecatedFilterBarProps {
  filter?: IDeprecatedFilter[];
  value: DeprecatedFilterBarValue;
  onChange?: (
    newFilterValues: DeprecatedFilterBarValue,
    filter: IDeprecatedFilter,
    value: IFilterItem | undefined,
  ) => void;
  staticWidth?: boolean;
  loading?: boolean;
  // should filters break into next line if there is not enough space? (default, yes - wrap)
  lineBreak?: 'wrap' | 'nowrap';
  booleanFiltersWithCancel?: boolean;
  hideInactiveBooleanFilter?: boolean;
  dataTestId?: string;
}

/**
 *  @Deprecated in favor if FilterBar
 *  This component contains DropDowns and Toggle Buttons that are used to change the
 *  values of filters.
 */
export const DeprecatedFilterBar = (props: IDeprecatedFilterBarProps) => {
  const selectValue = (filter: IDeprecatedFilter, item: IFilterItem | undefined) => {
    const newFilterValue = new Map(props.value);
    newFilterValue.set(filter.id, item ? item.id : undefined);

    // for filter that can have multiple values we need to handle the onChange differently
    if (filter.multiple) {
      props.onChange &&
        props.onChange(
          getUpdatedFilterMap(props.value as Map<string, string[] | undefined>, filter, item),
          filter,
          item,
        );
      return;
    }

    props.onChange && props.onChange(newFilterValue, filter, item);
  };

  if (props.loading) {
    return <FilterSkeleton />;
  }
  const className = clsx('filter-bar', {
    lineBreak: !props.lineBreak || props.lineBreak === 'wrap',
  });

  return (
    <div className={className}>
      {props.filter &&
        props.filter.map((filter) => {
          if (filter.multiple) {
            let value: string[] | undefined = undefined;

            if (props.value && props.value.has(filter.id)) {
              value = props.value.get(filter.id) as string[] | undefined;
            }

            return <DeprecatedFilter {...filter} key={filter.id} value={value} onChange={selectValue} />;
          }

          let value: string | undefined = undefined;

          if (props.value && props.value.has(filter.id)) {
            value = props.value.get(filter.id) as string | undefined;
          }

          return (
            <DeprecatedFilter
              {...filter}
              key={filter.id}
              value={value}
              onChange={selectValue}
              booleanFilterWithCancel={props.booleanFiltersWithCancel}
              hideInactiveBooleanFilter={props.hideInactiveBooleanFilter}
              dataTestId={props.dataTestId}
            />
          );
        })}
    </div>
  );
};

export const FilterSkeleton = () => (
  <div className="filter-bar filter-bar-skeleton">
    <div className="filter-container">
      <Skeleton.Button shape="round" active style={{ width: 120 }} />
    </div>
    <div className="filter-container">
      <Skeleton.Button shape="round" active />
    </div>
    <div className="filter-container">
      <Skeleton.Button shape="round" active style={{ width: 120 }} />
    </div>
    <div className="filter-container">
      <Skeleton.Button shape="round" active />
    </div>
    <div className="filter-container">
      <Skeleton.Button shape="round" active style={{ width: 120 }} />
    </div>
  </div>
);

export default DeprecatedFilterBar;

// only use when Filter has multiple prop
export const getUpdatedFilterMap = (
  value: Map<string, string[] | undefined>,
  filter: IDeprecatedFilter,
  item: IFilterItem | undefined,
) => {
  const newValue = new Map(value);
  const existingValues = newValue.get(filter.id) ?? [];

  // case if there is a multiple filter but only has 1 value
  // and it gets deselected
  if (!item) {
    newValue.set(filter.id, item);
    return newValue;
  }

  if (existingValues.includes(item.id)) {
    newValue.set(
      filter.id,
      existingValues.filter((ev) => ev !== item.id),
    );
  } else {
    newValue.set(filter.id, [...existingValues, item.id]);
  }

  newValue.forEach((value, key) => {
    if (!value) return;
    if (value.length <= 0) {
      newValue.delete(key);
    }
  });

  return newValue;
};
