import { action, computed, observable } from 'mobx';
import * as React from 'react';
import { t } from 'i18next';

import ModalStore from './modal-store';
import { ProfileViewApi, TriggerViewApi } from './api-store';

import { inject, IStore, Store } from '@/types/store';
import { TriggerModalView } from '@/components/modal-views/trigger-modal-view/trigger-modal-view';
import { OnStartupActionsV2Enum } from '@untis/wu-rest-view-api';
import { AdminLoginView } from '@/components/modal-views/admin-login-view/admin-login-view';

declare global {
  // eslint-disable-next-line
  interface Window {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    requestIdleCallback: (callback: () => void, options?: object) => void;
  }
}

/**
 * The TriggerStore holds logic to communicate with the backend after login to get actions a user needs to do.
 * The actions is usually an Enum which triggers a certain modal view. This logic is very generic and can be used
 * for any actions a user needs to do.
 *
 * For example after a migration a user needs to check if there data is valid, so the backend sends an action and
 * on the frontend we provide the logic to show the user a modal with input fields where it can udpate the information.
 */
@Store()
export class TriggerStore implements IStore {
  @observable isLoading = false;
  @observable currentAction: OnStartupActionsV2Enum | '' = '';
  @observable private startupActions: OnStartupActionsV2Enum[] = [];

  private modalStore = inject(ModalStore);

  /**
   * fetches the actions a user needs to perform and opens the modal view after the page is idle
   */
  async init() {
    try {
      const onStartup = await TriggerViewApi.onStartupV2();
      const {
        data: { startupActions },
      } = onStartup;
      this.startupActions = startupActions;

      if (this.currentAction === '') {
        this.currentAction = this.startupActions.length > 0 ? this.startupActions[0] : '';
      }

      if ('requestIdleCallback' in window) {
        window.requestIdleCallback(() => {
          this.openTriggerModal();
        });
        // fallback for unsporrted browsers
      } else {
        this.openTriggerModal();
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   *
   * Opens the TriggerModalView if there are actions a user needs to do
   */
  openTriggerModal() {
    if (this.modalStore.isAnyModalOpen || this.actionsCount < 1) {
      return;
    }

    if (this.currentAction === OnStartupActionsV2Enum.FORCE_ADMIN_DETAIL_CHANGE) {
      this.modalStore.openModalDialog({
        children: <AdminLoginView />,
        hideXIcon: true,
        title: t('general.initialRegistrationAdmin'),
        size: 'md',
        disableNavigation: true,
        keyboard: false,
      });
    } else {
      this.modalStore.deprecatedOpenModalDialog({
        className: 'trigger-modal',
        content: <TriggerModalView />,
        size: 'sm',
        closable: true,
        closeOnEscape: true,
        maskClosable: true,
        disableNavigation: true,
        closeCondition: async () => {
          // we want to check if the user really wants to stop editing the update data so we show a confirm dialog
          // before closing
          if (this.currentAction === OnStartupActionsV2Enum.VERIFY_PROFILE_DATA) {
            const shouldRemindLater = await this.modalStore.deprecatedBooleanUserPrompt({
              content: t('general.profileDataCheckCancelConfirmationText'),
              okText: t('general.remindMeLater'),
            });

            if (shouldRemindLater) {
              try {
                await ProfileViewApi.delayProfileVerification();
                this.next();
              } catch (error) {
                this.next();
              }
            }

            return shouldRemindLater;
          }

          return true;
        },
      });
    }
  }

  /**
   * Selects the next action in the modal view if there is one otherwise it closes the dialog
   */
  @action
  next() {
    const index = this.startupActions.indexOf(this.currentAction as OnStartupActionsV2Enum);
    const nextAction = this.startupActions[index + 1];

    if (nextAction) {
      this.currentAction = nextAction;
    } else {
      this.currentAction = '';
      this.modalStore.closeModal();
    }
  }

  @computed
  get currentTaskOrder() {
    return this.startupActions.indexOf(this.currentAction as OnStartupActionsV2Enum) + 1;
  }

  @computed
  get actionsCount() {
    return this.startupActions.length;
  }

  @computed
  get hasForceAdminDetailsChangeAction() {
    return this.startupActions.includes(OnStartupActionsV2Enum.FORCE_ADMIN_DETAIL_CHANGE);
  }
}
