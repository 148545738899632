import { action } from 'mobx';
import dayjs from 'dayjs';

import { inject, Store } from '@/types/store';
import { LocalStorageContext, LocalStorageStore } from '@/stores/local-storage-store';
import {
  URL_SEARCH_PARAMETER_ENTITY_ID,
  URL_SEARCH_PARAMETER_SELECTED_DATE,
  URL_SEARCH_PARAMETER_VIEW_TYPE,
} from '@te/standard/stores/url/timetable-url-store';
import { TIMETABLE_FILTERS } from '@/types/local-storage-ids';
import { TimetableEntityType } from '@te/standard/stores/timetable-root-store';
import { ITimetableFilterSelection } from '@te/standard/stores/facade/timetable-facade-store';
import { ITimetableViewType, TIMETABLE_VIEW_TYPES } from '@te/standard/stores/meta/timetable-meta-store';

interface ITimetableLocalStorageFilter {
  viewMode?: string;
  selectedDate?: string;
  entityId?: string;
}

@Store()
export default class TimetableLocalStorageStore {
  private localStorageStore: LocalStorageStore = inject(LocalStorageStore);

  @action
  writeUrlSelectionToLocalStorage(timetableEntityType: TimetableEntityType, urlSearchParameters: Map<string, string>) {
    const localStorageContext = this.getLocalStorageContext(timetableEntityType);
    const viewModeFromUrl = urlSearchParameters.get(URL_SEARCH_PARAMETER_VIEW_TYPE);
    const selectedDateFromUrl = urlSearchParameters.get(URL_SEARCH_PARAMETER_SELECTED_DATE);
    const entityIdFromUrl = urlSearchParameters.get(URL_SEARCH_PARAMETER_ENTITY_ID);

    const filterSelection: ITimetableLocalStorageFilter = {
      viewMode: viewModeFromUrl,
      selectedDate: selectedDateFromUrl,
      entityId: entityIdFromUrl,
    };
    this.localStorageStore.writeJSON(localStorageContext, TIMETABLE_FILTERS, filterSelection);
  }

  deleteUrlSelectionFromLocalStorage(timetableEntityType: TimetableEntityType) {
    const localStorageContext = this.getLocalStorageContext(timetableEntityType);
    this.localStorageStore.deleteItem(localStorageContext, TIMETABLE_FILTERS);
  }

  readTimetableFilterSelection(timetableEntityType: TimetableEntityType): ITimetableFilterSelection {
    const result: ITimetableFilterSelection = {
      selectedDate: dayjs(),
      viewMode: 'week',
      entityId: Number.NaN,
    };

    const filtersFromLocalStorage: ITimetableLocalStorageFilter | undefined = this.localStorageStore.readJSON(
      this.getLocalStorageContext(timetableEntityType),
      TIMETABLE_FILTERS,
    ) as ITimetableLocalStorageFilter | undefined;

    if (filtersFromLocalStorage) {
      const selectedDateFromLocalStorage = dayjs(filtersFromLocalStorage.selectedDate);
      const entityIdFromLocalStorage = Number(filtersFromLocalStorage.entityId);
      if (selectedDateFromLocalStorage.isValid()) {
        result.selectedDate = selectedDateFromLocalStorage;
      }

      if ((TIMETABLE_VIEW_TYPES as string[]).includes(filtersFromLocalStorage.viewMode ?? '')) {
        result.viewMode = filtersFromLocalStorage.viewMode as ITimetableViewType;
      }

      if (entityIdFromLocalStorage) {
        result.entityId = entityIdFromLocalStorage;
      }
    }

    return result;
  }

  private getLocalStorageContext(timetableEntityType: TimetableEntityType): LocalStorageContext {
    switch (timetableEntityType) {
      case 'student':
        return LocalStorageContext.TIMETABLE_STUDENT;
      case 'class':
        return LocalStorageContext.TIMETABLE_CLASS;
      case 'subject':
        return LocalStorageContext.TIMETABLE_SUBJECT;
      case 'teacher':
        return LocalStorageContext.TIMETABLE_TEACHER;
      case 'room':
        return LocalStorageContext.TIMETABLE_ROOM;
      case 'resource':
        return LocalStorageContext.TIMETABLE_RESOURCE;
      case 'my-class':
        return LocalStorageContext.TIMETABLE_CLASS_MY;
      case 'my-teacher':
        return LocalStorageContext.TIMETABLE_TEACHER_MY;
      case 'my-student':
        return LocalStorageContext.TIMETABLE_STUDENT_MY;
    }
  }
}
