import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { getNameForRoleType } from '../../utils/platform-utils';

import { PlatformApplicationUrlDto } from '@untis/wu-rest-view-api';
import useStore from '@/hooks/useStore';
import PlatformDetailViewStore from '@pa/stores/platform-detail-view-store';
import { Tabs } from '@/ui-components';
import { PlatformApplicationConfigurationTranslation } from '@pa/utils/platform-application-translations';
import PlatformConfigurationTab from '@pa/components/platform-configuration-tab/platform-configuration-tab';
import EmptyElement from '@/ui-components/tabs/tabs-empty-element';
import { IDropDownItem, SingleDropDown } from '@/ui-components/drop-down/drop-down';

interface IProps {
  urlInformationList: PlatformApplicationUrlDto[];
  tabsTitle: string;
  tabsDescription: string;
  additionalContainerClass: string;
  tabLabelName: string;
  openNewTabsDisabled?: boolean;
  testId?: string;
}

const PlatformConfigurationTabs = observer((props: IProps) => {
  const { t } = useTranslation();
  const platformDetailViewStore = useStore(PlatformDetailViewStore);
  const { urlInformationList } = props;

  const informationTabs = urlInformationList
    .filter((item) => item.active)
    .map((info) => {
      return {
        key: info.role,
        label: getNameForRoleType(info.role),
        className: 'configuration-tab',
        children: (
          <PlatformConfigurationTab
            labelName={props.tabLabelName}
            info={info}
            openNewTabReadonly={props.openNewTabsDisabled}
          />
        ),
      };
    });

  const dropDownItems: IDropDownItem[] = urlInformationList
    .filter((item) => !item.active)
    .map((item) => {
      return { id: item.role, label: getNameForRoleType(item.role) };
    });

  const onTabDelete = (tabKey: string) => {
    urlInformationList.forEach((item) => {
      if (item.role === tabKey) {
        item.active = false;
      }
    });
  };

  const activeTabKey: string | undefined = platformDetailViewStore.submitFailed
    ? urlInformationList.find((info) => info.menuName === '')?.role
    : undefined;

  const onDropDownSelect = useCallback(
    (dropDownItem: string | undefined) => {
      if (dropDownItem) {
        const element = urlInformationList.find((item) => item.role === dropDownItem);
        platformDetailViewStore.setUrlInformationRoleIsActiveStatus(urlInformationList, element, true);
      }
    },
    [platformDetailViewStore, urlInformationList],
  );

  const tabBarExtraContent = useMemo(() => {
    if (dropDownItems.length > 0) {
      return (
        <SingleDropDown
          id={`${props.testId}-dropdown`}
          items={dropDownItems}
          placeholder={t(PlatformApplicationConfigurationTranslation.addConfiguration)}
          onChange={onDropDownSelect}
        />
      );
    }
  }, [dropDownItems, onDropDownSelect, props.testId, t]);

  return (
    <div
      data-testid={props.testId}
      className={clsx(`${props.additionalContainerClass}`, 'configurations', {
        'configurations--empty': !informationTabs.length,
      })}
    >
      <div className="tabs-title">{t(props.tabsTitle)}</div>
      <div className="tabs-description">{t(props.tabsDescription)}</div>
      {informationTabs.length === 0 && (
        <EmptyElement emptyText={t(PlatformApplicationConfigurationTranslation.noConfigurations)} />
      )}
      <Tabs
        className="platform-configuration-tabs"
        activeKey={activeTabKey}
        onTabDelete={onTabDelete}
        tabBarExtraContent={tabBarExtraContent}
        items={informationTabs}
      />
    </div>
  );
});

export default PlatformConfigurationTabs;
