import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import { message } from 'antd';
import { ChangeEvent } from 'react';

import { inject, Store } from '@/types/store';
import {
  MessageFilterTypeV2,
  MessageRecipientOption,
  MessageSendResponseDtoV2,
  SearchResultMessageUserRefDto,
} from '@untis/wu-rest-view-api/api';
import { ErrorMessageEnum, showError } from '@/utils/error-handling/error-message';
import { RecipientPickerCustomStaffViewStore } from '@mg/stores/recipient-picker-custom-staff-view-store';
import { RecipientPickerPersonViewStore } from '@mg/stores/recipient-picker-person-view-store';
import { RecipientPickerClassStudentViewStore } from '@mg/stores/recipient-picker-class-student-view-store';
import { QuickFiltersDataStore } from '@mg/stores/quick-filters-data-store';

const { STUDENTS, PARENTS, TEACHER, STAFF, CUSTOM } = MessageRecipientOption;

export function getCustomConfirmationModalTranslations(
  option: MessageRecipientOption,
  { count, roles, name }: { count: number; roles?: string; name: string },
): { title: string; subtitle: string } {
  switch (option) {
    case MessageRecipientOption.STAFF:
      return {
        title: t('general.sendCustomMessageConfirmationTitle'),
        subtitle: t('general.sendStaffMessageConfirmationSubtitle', {
          count,
          name,
        }),
      };
    case MessageRecipientOption.TEACHER:
    case MessageRecipientOption.CUSTOM:
    default:
      return {
        title: t('general.sendCustomMessageConfirmationTitle'),
        subtitle: t('general.sendCustomMessageConfirmationSubtitle', {
          count,
          roles,
          name,
        }),
      };
  }
}

@Store()
export default class RecipientPickerStore {
  @observable recipientOption: MessageRecipientOption = MessageRecipientOption.STUDENTS;

  @observable classStudentViewStore = inject(RecipientPickerClassStudentViewStore);
  @observable personViewStore = inject(RecipientPickerPersonViewStore);
  @observable customViewStore = inject(RecipientPickerCustomStaffViewStore);

  @observable isLoadingQuickFilter = false;

  private quickFiltersDataStore = inject(QuickFiltersDataStore);

  @action
  async getRecipients() {
    try {
      switch (this.recipientOption) {
        case STUDENTS:
        case PARENTS: {
          return await this.classStudentViewStore.fetchPersonRecipients(this.recipientOption);
        }
        case TEACHER: {
          return await this.personViewStore.fetchPersonRecipients();
        }
        case STAFF: {
          this.customViewStore.recipientOption = MessageRecipientOption.STAFF;
          return await this.customViewStore.fetchPersonRecipients();
        }
        case CUSTOM: {
          this.customViewStore.recipientOption = MessageRecipientOption.CUSTOM;
          return await this.customViewStore.fetchPersonRecipients();
        }
        default:
          break;
      }
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
    }
  }

  @action
  reset() {
    this.resetSearchQuery();
    this.classStudentViewStore.reset();
    this.personViewStore.reset();
    this.customViewStore.reset();
    this.recipientOption = MessageRecipientOption.STUDENTS;
  }

  @action
  resetSelection() {
    switch (this.recipientOption) {
      case TEACHER:
        this.personViewStore.resetSelection();
        break;
      default:
        break;
    }
  }

  @action
  applySelection() {
    switch (this.recipientOption) {
      case TEACHER:
        this.personViewStore.applySelection();
        break;
      default:
        break;
    }
  }

  @action.bound
  onNameChange(event: ChangeEvent<HTMLInputElement>) {
    this.quickFiltersDataStore.setName(event.target.value);
  }

  @action
  search(query: string) {
    switch (this.recipientOption) {
      case PARENTS:
      case STUDENTS: {
        this.classStudentViewStore.searchQuery = query;
        this.classStudentViewStore.search(this.recipientOption);
        break;
      }
      case TEACHER: {
        this.personViewStore.searchQuery = query;
        break;
      }
      case STAFF:
      case CUSTOM:
        this.customViewStore.searchQuery = query;
        this.customViewStore.search();
        break;
      default:
        // search is done locally
        break;
    }
  }

  resetSearchQuery() {
    switch (this.recipientOption) {
      case PARENTS:
      case STUDENTS:
        this.classStudentViewStore.searchQuery = '';
        break;
      case TEACHER:
        this.personViewStore.searchQuery = '';
        break;
      case STAFF:
      case CUSTOM:
        this.customViewStore.searchQuery = '';
        break;
      default:
        break;
    }
  }

  @action
  deselectGroupById(groupId: string | number) {
    switch (this.recipientOption) {
      case STUDENTS:
      case PARENTS:
        this.classStudentViewStore.selectOrDeselectGroupById(String(groupId));
        break;
      default:
        break;
    }
  }

  @action
  deselectPersonById(personId: string | number) {
    switch (this.recipientOption) {
      case STUDENTS:
      case PARENTS:
        this.classStudentViewStore.selectOrDeselectPersonById(Number(personId));
        break;
      case TEACHER:
        this.personViewStore.selectOrDeselectPersonById(Number(personId));
        break;
      case STAFF:
      case CUSTOM:
        this.customViewStore.selectOrDeselectPersonById(Number(personId));
        break;
      default:
        break;
    }
  }

  @action
  selectRecipients(persons: SearchResultMessageUserRefDto[]) {
    switch (this.recipientOption) {
      case STUDENTS:
      case PARENTS:
        this.classStudentViewStore.setRecentSearchedPersons(persons);
        break;
      default:
        break;
    }
  }

  @action
  setSelectedUserIds(userIds: number[]) {
    switch (this.recipientOption) {
      case CUSTOM:
        this.customViewStore.setSelectedUserIds(userIds);
        break;
      default:
        break;
    }
  }

  readReceiptNotice(readConfirmation: boolean) {
    if (!readConfirmation) {
      return;
    }

    switch (this.recipientOption) {
      case STUDENTS:
        message.success(t('general.readReceiptSentToStudent'));
        break;
      case PARENTS:
        message.success(t('general.readReceiptSentToLegalGuardian'));
        break;
      case STAFF:
      case CUSTOM:
        message.success(t('general.readReceiptSentToLegalUser'));
        break;
      default:
        break;
    }
  }

  successMessage(messageSendResponse: MessageSendResponseDtoV2) {
    switch (this.recipientOption) {
      case STUDENTS:
      case PARENTS:
        message.success(this.classStudentViewStore.successMessage(this.recipientOption, messageSendResponse));
        if (messageSendResponse.numberOfCCRecipients) {
          message.success(this.classStudentViewStore.successCCMessage(this.recipientOption, messageSendResponse));
        }
        break;
      case TEACHER:
        message.success(this.personViewStore.successMessage());
        break;
      case STAFF:
      case CUSTOM:
        message.success(this.customViewStore.successMessage(messageSendResponse.numberOfRecipients));
        break;
      default:
        break;
    }
  }

  setInitialFilter(type: MessageFilterTypeV2, values: string[]) {
    switch (this.recipientOption) {
      case STAFF:
      case CUSTOM:
        if (type === MessageFilterTypeV2.QUICK) {
          this.customViewStore.setCustomListFilter(values);
        }
        if (type === MessageFilterTypeV2.DYNAMIC) {
          this.customViewStore.setDynamicListFilter(values);
        }
        break;
      default:
        break;
    }
  }

  async setInitialRecipients(userIds: number[]) {
    switch (this.recipientOption) {
      case CUSTOM: {
        return this.customViewStore.setInitialRecipients(userIds);
      }
      default:
        break;
    }
  }

  async initializeEditQuickFilter() {
    try {
      this.isLoadingQuickFilter = true;
      this.setInitialFilter(MessageFilterTypeV2.QUICK, [String(this.quickFiltersDataStore.quickFilterId)]);
      const [quickFilter] = await Promise.all([this.quickFiltersDataStore.getQuickFilter(), this.getRecipients()]);

      this.isLoadingQuickFilter = false;

      if (quickFilter) {
        this.setSelectedUserIds(quickFilter.userIds);
      } else {
        // could not load quickfilter so we show the user an error
        showError(ErrorMessageEnum.ERROR);
      }
    } catch (error) {
      this.isLoadingQuickFilter = false;
    }
  }

  @computed
  get showClassAndStudentsList() {
    return this.recipientOption === STUDENTS || this.recipientOption === PARENTS;
  }

  @computed
  get showTeachersList() {
    return this.recipientOption === TEACHER;
  }

  @computed
  get showCustomList() {
    return this.recipientOption === CUSTOM;
  }

  @computed
  get showStaffList() {
    return this.recipientOption === STAFF;
  }

  @computed
  get selectedPersons(): SearchResultMessageUserRefDto[] {
    switch (this.recipientOption) {
      case STUDENTS:
      case PARENTS:
        return this.classStudentViewStore.selectedPersons;
      case TEACHER:
        return this.personViewStore.selectedPersons;
      case STAFF:
      case CUSTOM:
        return this.customViewStore.selectedPersons;
      default:
        return [];
    }
  }

  @computed
  get selectedGroups() {
    switch (this.recipientOption) {
      case STUDENTS:
      case PARENTS:
        return this.classStudentViewStore.selectedGroups;
      default:
        return [];
    }
  }

  @computed
  get selectedMessageRecipients() {
    switch (this.recipientOption) {
      case STUDENTS:
      case PARENTS:
        return this.classStudentViewStore.selectedMessageRecipients;
      case TEACHER:
        return this.personViewStore.selectedMessageRecipients;
      case STAFF:
      case CUSTOM:
        return this.customViewStore.selectedMessageRecipients;
      default:
        return [];
    }
  }

  @computed
  get searchQuery() {
    switch (this.recipientOption) {
      case PARENTS:
      case STUDENTS:
        return this.classStudentViewStore.searchQuery;
      case TEACHER:
        return this.personViewStore.searchQuery;
      case STAFF:
      case CUSTOM:
        return this.customViewStore.searchQuery;
      default:
        return '';
    }
  }

  @computed
  get selectedCount() {
    switch (this.recipientOption) {
      case PARENTS:
      case STUDENTS:
        return this.classStudentViewStore.selectedCount;
      case TEACHER:
        return this.personViewStore.selectedCount;
      case STAFF:
      case CUSTOM:
        return this.customViewStore.selectedCount;
      default:
        return 0;
    }
  }

  @computed
  get selectedPersonsIds() {
    switch (this.recipientOption) {
      case PARENTS:
      case STUDENTS:
        return this.classStudentViewStore.selectedPersonsIds;
      case TEACHER:
        return [this.personViewStore.selectedPersonId];
      case STAFF:
      case CUSTOM:
        return this.customViewStore.selectedPersonsIds;
      default:
        return [];
    }
  }

  @computed
  get selectedGroupIds() {
    switch (this.recipientOption) {
      case PARENTS:
      case STUDENTS:
        return this.classStudentViewStore.selectedGroupIds;
      default:
        return [];
    }
  }

  @computed
  get hasError() {
    switch (this.recipientOption) {
      case PARENTS:
      case STUDENTS:
        return this.classStudentViewStore.hasError;
      case TEACHER:
        return this.personViewStore.hasError;
      case STAFF:
      case CUSTOM:
        return this.customViewStore.hasError;
      default:
        return false;
    }
  }

  @computed
  get searchBarPlaceholder() {
    switch (this.recipientOption) {
      case STUDENTS:
      case PARENTS:
        return t('general.searchStudents');
      case TEACHER:
        return t('general.searchTeachers');
      case STAFF:
        return t('general.sendStaffMessageEntryTitle');
      case CUSTOM:
        return t('general.searchPersons');
      default:
        return '';
    }
  }

  @computed
  get customConfirmationModalTranslations() {
    switch (this.recipientOption) {
      case CUSTOM:
      case STAFF:
        return getCustomConfirmationModalTranslations(this.recipientOption, {
          count: this.customViewStore.selectedPersonsIds.length,
          name: this.customViewStore.selectedPersonName,
          roles: this.customViewStore.selectedPersonRolesSeperatedByComma,
        });
      case TEACHER:
        return getCustomConfirmationModalTranslations(this.recipientOption, {
          count: this.personViewStore.selectedCount,
          name: this.personViewStore.selectedPersonName,
          roles: this.personViewStore.selectedPersonRolesSeperatedByComma,
        });
      default:
        return getCustomConfirmationModalTranslations(this.recipientOption, {
          count: 0,
          name: '',
          roles: '',
        });
    }
  }

  @computed
  get name() {
    return this.quickFiltersDataStore.selectedQuickFilter?.name ?? '';
  }

  @computed
  get isEditMode() {
    return this.quickFiltersDataStore.mode === 'edit';
  }

  @computed
  get isLoading() {
    switch (this.recipientOption) {
      case CUSTOM:
        return this.isLoadingQuickFilter || this.customViewStore.isLoadingFilters;
      default:
        return false;
    }
  }
}
