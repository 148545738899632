import React from 'react';

import './sidebar-toolbar.less';
import { ButtonType } from '@/ui-components/button/button';
import { Button } from '@/ui-components';

export interface ISidebarToolbarButton {
  label: string;
  onClick?: () => void;
  type?: ButtonType;
  outline?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

interface IProps {
  buttons: ISidebarToolbarButton[];
}

export const SidebarToolbar = (props: IProps) => {
  return (
    <div className="sidebar-toolbar">
      {props.buttons.map((button, i) => {
        return (
          <Button
            size="large"
            onClick={button.onClick}
            key={i}
            type={button.type}
            outline={button.outline}
            disabled={button.disabled}
            loading={button.loading}
          >
            {button.label}
          </Button>
        );
      })}
    </div>
  );
};
