import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import clsx from 'clsx';
import { DndProvider, DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { observer } from 'mobx-react-lite';

import LessonCardLayoutConfigurerStore, {
  ILessonCardRowDragObject,
  LESSON_CARD_TEMPLATE_ROW_LIMIT,
  LessonCardRowDragPosition,
  LessonCardRowDropResult,
} from './lesson-card-layout-configurer-store';

import { Flex, Icon, IconButton } from '@/ui-components';
import { EntryResourceTypeEnum } from '@untis/wu-rest-view-api/api';
import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import LessonCardColorBar from '@/components/lesson-card/components/color-bar/lesson-card-color-bar';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';

import './form-lesson-card-layout-configurer.less';

interface IProps {
  store: LessonCardLayoutConfigurerStore;
  onChange?: (value: EntryResourceTypeEnum[]) => void;
  disabled?: boolean;
}

type FormLessonCardLayoutPickerProps = Omit<IProps, 'store'> & IFormItemProps<EntryResourceTypeEnum[]>;

const LESSON_CARD_ROW_TRANSLATION_MAP: Map<EntryResourceTypeEnum, string> = new Map<EntryResourceTypeEnum, string>([
  [EntryResourceTypeEnum.CLASS, 'general.class'],
  [EntryResourceTypeEnum.CLASS_STUDENT_GROUP, 'general.classStudentGroupWithPipe'],
  [EntryResourceTypeEnum.ROOM, 'general.room'],
  [EntryResourceTypeEnum.STUDENT_GROUP, 'general.studentGroup'],
  [EntryResourceTypeEnum.SUBJECT, 'general.subject'],
  [EntryResourceTypeEnum.TEACHER, 'general.teacher'],
]);

export const FormLessonCardLayoutConfigurer = (props: FormLessonCardLayoutPickerProps) => {
  const [store] = useState(() => new LessonCardLayoutConfigurerStore(props.initialValue));

  return (
    <Form.Item
      name={props.name}
      label={props.label}
      rules={props.rules}
      dependencies={props.dependencies}
      initialValue={props.initialValue}
    >
      <LessonCardLayoutConfigurer store={store} disabled={props.disabled} />
    </Form.Item>
  );
};

export const LessonCardLayoutConfigurer = observer((props: IProps) => {
  const { store } = props;
  return (
    <>
      {/* update the react-dnd dependency to resolve this type issue */}
      {/* @ts-ignore */}
      <DndProvider backend={HTML5Backend}>
        <Flex gap="lg" className="lesson-card-layout-configurer" data-testid="lesson-card-layout-configurer">
          <LessonCardTemplate
            rows={store.selectedLessonCardRows}
            onDrop={(dropResult) => store.handleLessonCardRowDropped(dropResult, props.onChange)}
            onRemove={(row) => store.handleLessonCardRowRemoved(row, props.onChange)}
            disabled={props.disabled}
          />
          <AvailableLessonCardRowPool
            rows={store.pool}
            onDrop={(dropResult) => store.handleLessonCardRowDropped(dropResult, props.onChange)}
            disabled={props.disabled}
          />
        </Flex>
      </DndProvider>
    </>
  );
});

const AvailableLessonCardRowPool = ({
  rows,
  onDrop,
  disabled,
}: {
  rows: EntryResourceTypeEnum[];
  onDrop: (dropResult: LessonCardRowDropResult) => void;
  disabled?: boolean;
}) => {
  const [{ canDrop }, drop] = useDrop<ILessonCardRowDragObject, LessonCardRowDropResult, any>({
    accept: 'LESSON_CARD_TEMPLATE_ROW',
    canDrop: (item) => !disabled && item.position === 'in-lesson-card',
    drop: (item: ILessonCardRowDragObject) => {
      const dropResult: LessonCardRowDropResult = {
        item,
        type: 'to-pool',
      };
      onDrop(dropResult);
      return dropResult;
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div ref={drop} className="available-lesson-card-row-pool" data-testid="available-lesson-card-row-pool">
      <Flex
        className={clsx('available-lesson-card-row-pool--content', {
          disabled,
          'can-drop': canDrop,
        })}
        flexDirection="column"
      >
        {rows.map((row, index) => (
          <LessonCardTemplateRow
            key={row}
            row={row}
            index={index}
            position="in-pool"
            disabled={disabled}
            onDrop={onDrop}
          />
        ))}
      </Flex>
    </div>
  );
};

const LessonCardTemplate = ({
  rows,
  onRemove,
  onDrop,
  disabled,
}: {
  rows: EntryResourceTypeEnum[];
  onRemove: (row: EntryResourceTypeEnum) => void;
  onDrop: (dropResult: LessonCardRowDropResult) => void;
  disabled?: boolean;
}) => {
  return (
    <div className="lesson-card-template-container">
      <Flex className={clsx('lesson-card-template', { disabled })} gap="xs">
        <LessonCardColorBar color="#36BAC9" />
        <Flex className="lesson-card-template--content" flexDirection="column" gap="sm">
          {rows.length > 0 && (
            <>
              {rows.map((row, index) => (
                <LessonCardTemplateRow
                  key={row}
                  row={row}
                  index={index}
                  position="in-lesson-card"
                  onRemove={onRemove}
                  onDrop={onDrop}
                  disabled={disabled}
                />
              ))}
              {rows.length < LESSON_CARD_TEMPLATE_ROW_LIMIT && (
                <LessonCardTemplateEmptyRow index={rows.length} disabled={disabled} onDrop={onDrop} />
              )}
            </>
          )}
        </Flex>
        {rows.length === 0 && (
          <>
            <LessonCardTemplateEmptyRow index={0} disabled={disabled} onDrop={onDrop} fullHeight />
            <LessonCardEmptyContent />
          </>
        )}
      </Flex>
    </div>
  );
};

const LessonCardTemplateRow = ({
  row,
  position,
  index,
  onRemove,
  onDrop,
  disabled,
}: {
  row: EntryResourceTypeEnum;
  position: LessonCardRowDragPosition;
  index: number;
  onDrop: (dropResult: LessonCardRowDropResult) => void;
  onRemove?: (row: EntryResourceTypeEnum) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const dragObject: ILessonCardRowDragObject = useMemo(() => {
    return {
      type: 'LESSON_CARD_TEMPLATE_ROW',
      row,
      position,
    };
  }, [row, onRemove]);

  const [{ isDragging }, drag] = useDrag<ILessonCardRowDragObject, LessonCardRowDropResult, any>({
    canDrag: !disabled,
    item: dragObject,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ canDrop }, drop] = useDrop<ILessonCardRowDragObject, LessonCardRowDropResult, any>({
    accept: 'LESSON_CARD_TEMPLATE_ROW',
    canDrop: (item) => !disabled && position === 'in-lesson-card' && item.row !== row,
    drop: (item: ILessonCardRowDragObject): LessonCardRowDropResult => {
      const dropResult: LessonCardRowDropResult = {
        item,
        type: 'to-card',
        toIndex: index,
      };
      onDrop(dropResult);
      return dropResult;
    },
    collect: (monitor: DropTargetMonitor) => ({
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      key={row}
      className={clsx('lesson-card-template-row', {
        disabled,
        'is-dragging': isDragging,
        'can-drop': canDrop,
        'in-lesson-card': position === 'in-lesson-card',
        'in-pool': position === 'in-pool',
      })}
      data-testid={`lesson-card-template-row-${row.toString()}`}
    >
      <Icon type="drag-and-drop" />
      <div className="lesson-card-template-row--label">{t(LESSON_CARD_ROW_TRANSLATION_MAP.get(row))}</div>
      {position === 'in-lesson-card' && onRemove && (
        <IconButton
          testId="delete-icon"
          ariaLabel={t('general.delete')}
          type="shared_trash"
          onClick={() => onRemove(row)}
        />
      )}
    </div>
  );
};

const LessonCardTemplateEmptyRow = ({
  index,
  fullHeight,
  disabled,
  onDrop,
}: {
  index: number;
  fullHeight?: boolean;
  disabled?: boolean;
  onDrop: (dropResult: LessonCardRowDropResult) => void;
}) => {
  const [{ canDrop }, drop] = useDrop<ILessonCardRowDragObject, LessonCardRowDropResult, any>({
    accept: 'LESSON_CARD_TEMPLATE_ROW',
    canDrop: (item) => !disabled && item.position === 'in-pool',
    drop: (item: ILessonCardRowDragObject): LessonCardRowDropResult => {
      const dropResult: LessonCardRowDropResult = {
        item,
        type: 'to-card',
        toIndex: index,
      };
      onDrop(dropResult);
      return dropResult;
    },
    collect: (monitor: DropTargetMonitor) => ({
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      data-testid="lesson-card-template--empty-row"
      className={clsx('lesson-card-template--empty-row', { disabled, 'can-drop': canDrop, 'full-height': fullHeight })}
    />
  );
};

const LessonCardEmptyContent = () => {
  const { t } = useTranslation();

  return (
    <div className="lesson-card-template--empty-content">
      <EmptyIndicator title={t('general.emptyPeriodCard')} description={t('general.emptyPeriodCardDragAndDrop')} />
    </div>
  );
};
