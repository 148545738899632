import { action, computed, observable } from 'mobx';

import { CustomOrderDto } from '@untis/wu-rest-view-api/api';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import SettingsStore, { NumberSetting } from '@/stores/settings-store';
import { inject } from '@/types/store';

export class ListViewStore<T extends ITableRowKey> {
  @observable protected _settingsStore: SettingsStore = inject(SettingsStore);
  @observable private _customSorting: CustomOrderDto[] = [];
  @observable private _rowData: T[] = [];

  @action
  resetCustomSorting(rowData?: T[]) {
    if (!rowData) {
      return;
    }
    this._rowData = rowData;
    this._customSorting = [];
    rowData.forEach((row, i) => {
      this._customSorting.push({
        id: Number(row.key),
        orderNo: i + 1,
      });
    });
  }

  @action
  setCustomSorting(sorting: CustomOrderDto[]) {
    this._customSorting = sorting;
  }

  @computed
  get customSorting(): CustomOrderDto[] {
    return this._customSorting;
  }

  @computed
  get pageSize() {
    return this._settingsStore.getNumberSetting(NumberSetting.LINES_PER_PAGE);
  }
}
