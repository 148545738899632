import { RadioChangeEvent } from 'antd/es/radio';
import { RadioGroupProps } from 'antd/lib/radio';
import React from 'react';
import { Radio } from 'antd';

export interface IComplexRadioGroupProps extends RadioGroupProps {
  value?: string | number;
  name: string;
  defaultValue?: string | number;
  onChangeValue?: (e: string | number) => void;
}

export const ComplexRadioGroup = (props: IComplexRadioGroupProps) => {
  const { className, children, ...other } = props;

  const handleChange = (e: RadioChangeEvent) => {
    const value: string | number = e.target.value as string | number;
    props.onChangeValue && props.onChangeValue(value);
  };

  return (
    <Radio.Group {...other} onChange={handleChange}>
      <ul className={`untis-complex-radio-group${className ? '' + className : ''}`}>{children}</ul>
    </Radio.Group>
  );
};

export default ComplexRadioGroup;
