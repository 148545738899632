import { t } from 'i18next';
import { action, computed, observable } from 'mobx';
import React from 'react';
import { AxiosResponse } from 'axios';
import dayjs, { Dayjs } from 'dayjs';

import {
  CalendarBuildingDto,
  CalendarDepartmentDto,
  CalendarEntryRoomsFormResponse,
  CalendarEntryTypesEnum,
  CalendarRoomDetailDto,
  CalendarRoomDto,
  CalendarRoomTypeDto,
} from '@untis/wu-rest-view-api';
import ProvisionalDtoSelector from '@/pages/calendar-entry/provisional-dto-selector/provisional-dto-selector';
import RoomSelectionDialog from '@/pages/calendar-entry/room-selection-dialog/room-selection-dialog';
import { IMasterDataDto } from '@/pages/calendar-entry/types';
import { CalendarViewApi } from '@/stores/api-store';
import ConfigStore from '@/stores/config-store';
import ModalStore from '@/stores/modal-store';
import { inject } from '@/types/store';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { dayjsFormatWithTimeDeprecated } from '@/utils/date/date-util';
import { IItemType } from '@/pages/calendar-entry/create-calendar-entry/create-calendar-entry-form-store';
import { OverbookingDto } from '@untis/sp-rest-api/api';
import CalendarEntryDetailViewStore from '@/pages/calendar-entry/calendar-entry-detail-view/calendar-entry-detail-view-store';

export enum FormMode {
  CREATE,
  EDIT,
  // Update form is used for the calendar entry edit view in SP (Uses different room component then edit mode)
  UPDATE,
}

abstract class CalendarEntryBaseFormStore {
  @observable protected _start: Dayjs;
  @observable protected _end: Dayjs;
  @observable protected _rooms: CalendarRoomDetailDto[] = [];
  @observable protected _buildings: CalendarBuildingDto[] = [];
  @observable protected _roomTypes: CalendarRoomTypeDto[] = [];
  @observable protected _departments: CalendarDepartmentDto[] = [];
  @observable selectedRooms: CalendarRoomDto[] = [];
  @observable protected _detailViewStore?: CalendarEntryDetailViewStore;

  _mode: FormMode;
  private _onRoomSelected: (() => void) | undefined;
  protected _configStore = inject(ConfigStore);
  protected _modalStore = inject(ModalStore);
  readonly overbooking: OverbookingDto | undefined;

  constructor(
    initialStart: Dayjs,
    initialEnd: Dayjs,
    mode: FormMode,
    overbooking?: OverbookingDto,
    detailViewStore?: CalendarEntryDetailViewStore,
  ) {
    this._start = initialStart;
    this._end = initialEnd;
    this._mode = mode;
    this.overbooking = overbooking;
    this._detailViewStore = detailViewStore;
  }

  @action
  setPatchRoomHandler = (handler: () => void) => {
    this._onRoomSelected = handler;
  };

  @action
  fetchRooms = (): Promise<AxiosResponse<CalendarEntryRoomsFormResponse>> => {
    let start = dayjsFormatWithTimeDeprecated(this.start);
    let end = dayjsFormatWithTimeDeprecated(this.end);
    if (this._detailViewStore) {
      const selectedCalendarEntry = this._detailViewStore.selectedCalendarEntry;
      const selectedPeriodId = this._detailViewStore.selectedPeriodId;
      const isBlockSelected = this._detailViewStore.isBlockSelected;
      if (!isBlockSelected) {
        selectedCalendarEntry?.singleEntries.forEach((entry) => {
          if (entry.id === selectedPeriodId) {
            start = entry.startDateTime;
            end = entry.endDateTime;
          }
        });
      }
    }
    return CalendarViewApi.getCalendarEntryRoomsFormData(end, start).then((response) => {
      this._buildings = response.data.buildings;
      this._roomTypes = response.data.roomTypes;
      this._departments = response.data.departments;
      this._rooms = response.data.rooms;

      this.prepopulateRoomsOverbooking();
      return response;
    });
  };

  @action prepopulateRoomsOverbooking = () => {
    if (this.overbooking?.period?.rooms) {
      const roomIds = this.overbooking?.period?.rooms?.map((room) => room.currentRoom?.id!);
      this.selectRooms(roomIds);
    }
  };

  @computed get start(): Dayjs {
    return this._start ? this._start : dayjs();
  }

  @computed get end(): Dayjs {
    return this._end ? this._end : dayjs();
  }

  @action setStart = (start: Dayjs) => {
    this._start = start;
  };

  @action setEnd = (end: Dayjs) => {
    this._end = end;
  };

  @action selectRooms = (ids: number[]) => {
    this.selectedRooms = this._rooms.filter((r) => ids.includes(r.id));
  };

  @computed
  get roomItems(): IMultiTagSelectItem[] {
    return this.createRoomItems(
      this._rooms,
      this.selectedRooms,
      this.selectRooms,
      this._configStore.selectedDepartmentId,
      this._buildings,
      this._roomTypes,
      this._departments,
      this._modalStore,
      this._mode === FormMode.EDIT,
      this._mode === FormMode.EDIT ? this._onRoomSelected : undefined,
    );
  }

  protected createRoomItems = (
    rooms: CalendarRoomDetailDto[],
    selectedRooms: CalendarRoomDto[],
    selectRooms: (ids: number[]) => void,
    selectedDepartmentId: number,
    buildings: CalendarBuildingDto[],
    roomTypes: CalendarRoomTypeDto[],
    departments: CalendarDepartmentDto[],
    modalStore: ModalStore,
    isSingleSelect: boolean,
    onRoomsSelected?: (rooms: number[]) => void,
  ): IMultiTagSelectItem[] => {
    return selectedRooms.map((dto) => {
      const replaceOption = {
        label: t('general.replace'),
        onClick: () =>
          this.createAddRoomHandler(
            rooms,
            selectedRooms,
            selectRooms,
            selectedDepartmentId,
            buildings,
            roomTypes,
            departments,
            modalStore,
            true,
            onRoomsSelected,
            dto,
          )(),
      };

      const deleteOption = {
        label: t('general.remove'),
        onClick: async () => {
          try {
            const shouldRemove = await this._modalStore.openDeletePrompt(
              t('general.removeRoomConfirmationTitle', { room: dto.displayName }),
              '',
              {
                okButton: t('general.remove'),
              },
            );

            if (!shouldRemove) {
              return;
            }

            const newRooms = selectedRooms.filter((d) => d.id !== dto.id).map((d) => d.id);
            selectRooms(newRooms);
            onRoomsSelected?.(newRooms);
          } catch {}
        },
      };

      const renderSelectedOptions = (options: any) => {
        return {
          label: dto.shortName,
          id: dto.id.toString(),
          options: options,
        };
      };

      return isSingleSelect
        ? renderSelectedOptions([replaceOption, deleteOption])
        : renderSelectedOptions([deleteOption]);
    });
  };

  @computed
  get addRoomHandler() {
    return this.createAddRoomHandler(
      this._rooms,
      this.selectedRooms,
      this.selectRooms,
      this._configStore.selectedDepartmentId,
      this._buildings,
      this._roomTypes,
      this._departments,
      this._modalStore,
      this._mode === FormMode.EDIT,
      this._mode === FormMode.EDIT ? this._onRoomSelected : undefined,
    );
  }

  protected createAddRoomHandler = (
    rooms: CalendarRoomDetailDto[],
    selectedRooms: CalendarRoomDto[],
    selectRooms: (ids: number[]) => void,
    selectedDepartmentId: number,
    buildings: CalendarBuildingDto[],
    roomTypes: CalendarRoomTypeDto[],
    departments: CalendarDepartmentDto[],
    modalStore: ModalStore,
    isSingleSelect?: boolean,
    onRoomsSelected?: (rooms: number[]) => void,
    initialSelected?: IMasterDataDto,
  ) => {
    return () => {
      const handleSubmit = (selected: IMasterDataDto | IMasterDataDto[] | undefined) => {
        const selectedElement: IMasterDataDto = selected as IMasterDataDto;
        const selectedArray: IMasterDataDto[] = selected as IMasterDataDto[];
        const selectedRoomIds = selectedRooms.map((r) => r.id);

        const handleSingleSelect = () => {
          if (initialSelected) {
            const filteredSelectedRoomIds = selectedRoomIds.filter((r) => r !== initialSelected.id);
            const newRooms = [...filteredSelectedRoomIds, selectedElement.id];
            selectRooms(newRooms);
            onRoomsSelected && onRoomsSelected(newRooms);
          } else {
            const newRooms = [...selectedRoomIds, selectedElement.id];
            selectRooms(newRooms);
            onRoomsSelected && onRoomsSelected(newRooms);
          }
        };

        const handleMultiSelect = () => {
          const selectedIds = selectedArray.map((s) => s.id);
          if (selectedRooms) {
            const newRooms = [...selectedRoomIds, ...selectedIds];
            selectRooms(newRooms);
            onRoomsSelected && onRoomsSelected(newRooms);
          }
        };

        isSingleSelect ? handleSingleSelect() : handleMultiSelect();
        modalStore.closeModal();
      };

      let buildingId = undefined;
      if (initialSelected) {
        const initialRoomDetail = rooms.find((r) => r.id === initialSelected.id);
        buildingId = initialRoomDetail && initialRoomDetail.building ? initialRoomDetail.building.id : undefined;
      }

      const title = initialSelected ? t('general.replaceX', { '0': initialSelected.shortName }) : t('general.addRooms');

      modalStore.deprecatedOpenModalDialog({
        content: (
          <RoomSelectionDialog
            isSingleSelect={isSingleSelect}
            title={title}
            availableRooms={
              isSingleSelect ? rooms.filter((r) => !selectedRooms.map((ro) => ro.id).includes(r.id)) : rooms
            }
            initialSelected={initialSelected}
            initialSelectedRooms={selectedRooms}
            initialBuildingFilter={buildingId}
            initialDepartmentFilter={selectedDepartmentId > 0 ? selectedDepartmentId : undefined}
            buildings={buildings}
            roomTypes={roomTypes}
            departments={departments}
            onOk={handleSubmit}
          />
        ),
        size: 'full-size',
        closable: true,
      });
    };
  };

  protected getTranslationForError = (error: string): string => {
    if (error === 'ERR_ROOM_NOT_AVAILABLE') {
      return t('general.errors.roomNotAvailable');
    } else {
      return error;
    }
  };

  protected getTranslationForCalendarEntryType = (type: CalendarEntryTypesEnum): string => {
    switch (type) {
      case CalendarEntryTypesEnum.STAND_BY_PERIOD:
        return t('createCalendarEntry.standBy');
      case CalendarEntryTypesEnum.OFFICE_HOUR:
        return t('general.officeHour');
      case CalendarEntryTypesEnum.BREAK_SUPERVISION:
        return t('createCalendarEntry.breakSupervision');
      case CalendarEntryTypesEnum.NORMAL_TEACHING_PERIOD:
        return t('createCalendarEntry.normal');
      case CalendarEntryTypesEnum.ADDITIONAL_PERIOD:
        return t('createCalendarEntry.additionalPeriod');
      case CalendarEntryTypesEnum.EXAM:
        return t('general.exam');
      case CalendarEntryTypesEnum.EVENT:
        return t('general.event');
      default:
        return type;
    }
  };

  protected createAddHandler =
    (
      items: IMasterDataDto[],
      selected: IMasterDataDto[],
      select: (ids: number[]) => void,
      title: string,
      modalStore: ModalStore,
      itemType: IItemType,
    ): (() => void) =>
    () => {
      modalStore.deprecatedOpenModalDialog({
        content: (
          <ProvisionalDtoSelector
            title={title}
            items={items}
            initialSelected={selected}
            onOk={(selected) => {
              select(selected.map((element) => element.id));
              modalStore.closeModal();
            }}
            itemType={itemType}
          />
        ),
        size: 'full-size',
        closable: true,
      });
    };

  protected createMultiSelectItems = (
    selected: IMasterDataDto[],
    select: (ids: number[]) => void,
  ): IMultiTagSelectItem[] => {
    return selected.map((dto) => {
      return {
        label: dto.shortName,
        id: dto.id.toString(),
        options: [
          {
            label: t('general.delete'),
            onClick: () => {
              select(selected.filter((d) => d.id !== dto.id).map((d) => d.id));
            },
          },
        ],
      };
    });
  };
}

export default CalendarEntryBaseFormStore;
