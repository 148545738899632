import clsx from 'clsx';
import React, { ReactNode, HTMLAttributes, ElementType } from 'react';

import { Flex, IFlexProps } from '@/ui-components/flex/flex';
import { Box, IBoxProps } from '@/ui-components/box/box';

import './stack.less';

type Token = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type AllAttrs = {
  Flex: IFlexProps;
  Box: IBoxProps;
  div: HTMLAttributes<HTMLDivElement>;
};

export type IStackProps = {
  as?: typeof Flex | typeof Box | 'div';
  children: ReactNode;
  x?: Token;
  y?: Token;
  className?: string;
};

function Stack<T extends keyof AllAttrs>({ children, x, y, className, ...props }: IStackProps & AllAttrs[T]) {
  const Element: ElementType = props.as ? props.as : 'div';
  return (
    <Element className={clsx('stack', x && `horizontal-${x}`, y && `vertical-${y}`, className)} {...props}>
      {children}
    </Element>
  );
}

export { Stack };
