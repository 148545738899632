import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import TimetableFormatMappingsStore, {
  IDepartmentRef,
  ITimetableFormatMappingsFormData,
} from '@te/settings/format-mapping/timetable-format-mappings-store';
import { FormCheckbox, FormSection, WUForm, WUFormCol, WUFormRow } from '@/ui-components/wu-form';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import { Flex, SwitchComponent } from '@/ui-components';
import { TimetableEntityType } from '@te/standard/stores/timetable-root-store';
import { ISelectItem } from '@/ui-components/select/select';
import { LoadingIndicator } from '@/ui-components/scroller/loading-indicator/loading-indicator';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import { TimetableFormatMappingDto } from '@untis/wu-rest-view-api/api';

import './timetable-format-mappings.less';

interface ITimetableTypeFormatMappingSectionProps {
  type: TimetableEntityType;
  switchValue: boolean;
  switchCallback: (value: boolean) => void;
  departments: IDepartmentRef[];
  formats: ISelectItem[];
  displayNameItems: ISelectItem[];
  initialMappingValues: TimetableFormatMappingDto | undefined;
  initialDepartmentMappings: Map<number, string | undefined>;
  onDepartmentMappingChange: (type: TimetableEntityType, departmentId: number, formatId: string | undefined) => void;
}

const TIMETABLE_TYPE_TITLE_TRANSLATION_MAP: Map<TimetableEntityType, string> = new Map<TimetableEntityType, string>([
  ['class', 'general.classTimetable'],
  ['teacher', 'general.teacherTimetable'],
  ['student', 'general.studentTimetable'],
  ['subject', 'general.subjectTimetable'],
  ['room', 'general.roomTimetable'],
  ['resource', 'general.resourceTimetable'],
]);

const ASSIGN_FORMAT_TO_TIMETABLE_TYPE_MAP: Map<TimetableEntityType, string> = new Map<TimetableEntityType, string>([
  ['class', 'general.assignFormatToClassTimetable'],
  ['teacher', 'general.assignFormatToTeacherTimetable'],
  ['student', 'general.assignFormatToStudentTimetable'],
  ['subject', 'general.assignFormatToSubjectTimetable'],
  ['room', 'general.assignFormatToRoomTimetable'],
  ['resource', 'general.assignFormatToResourceTimetable'],
]);

const SELECT_DISPLAY_NAME_FOR_TIMETABLE_TYPE_MAP: Map<TimetableEntityType, string> = new Map<
  TimetableEntityType,
  string
>([
  ['class', 'general.selectDisplayNameForClassDropdown'],
  ['teacher', 'general.selectDisplayNameForTeacherDropdown'],
  ['student', 'general.selectDisplayNameForStudentDropdown'],
  ['subject', 'general.selectDisplayNameForSubjectDropdown'],
  ['room', 'general.selectDisplayNameForRoomDropdown'],
  ['resource', 'general.selectDisplayNameForResourceDropdown'],
]);

const TimetableFormatMappings = observer(() => {
  const [store] = useState(() => new TimetableFormatMappingsStore());

  useComponentDidMount(() => {
    store.fetchData();
  });

  if (store.isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <WUForm<ITimetableFormatMappingsFormData>
      fixedButtonBar={true}
      disableSubmitButton={store.submitDisabled}
      onValuesChange={() => store.setSubmitDisabled(false)}
      onSubmit={(form) => store.save(form)}
      maxWidth
      className="timetable-format-mappings-form"
      fullHeight
    >
      <WUFormCol lg={1} removePadding>
        <Flex gap="md" flexDirection="column">
          <TimetableTypeFormatMappingSection
            type="class"
            switchValue={store.showClassDepartmentMappings}
            switchCallback={store.setShowClassDepartmentMappings}
            departments={store.departments}
            formats={store.timetableFormats}
            displayNameItems={store.classDisplayNameTypes}
            initialMappingValues={store.timetableFormatMappings?.klasse}
            initialDepartmentMappings={store.departmentFormatMappings.class}
            onDepartmentMappingChange={store.setDepartmentFormatMapping}
          />
          <TimetableTypeFormatMappingSection
            type="teacher"
            switchValue={store.showTeacherDepartmentMappings}
            switchCallback={store.setShowTeacherDepartmentMappings}
            departments={store.departments}
            formats={store.timetableFormats}
            displayNameItems={store.teacherDisplayNameTypes}
            initialMappingValues={store.timetableFormatMappings?.teacher}
            initialDepartmentMappings={store.departmentFormatMappings.teacher}
            onDepartmentMappingChange={store.setDepartmentFormatMapping}
          />
          <TimetableTypeFormatMappingSection
            type="student"
            switchValue={store.showStudentDepartmentMappings}
            switchCallback={store.setShowStudentDepartmentMappings}
            departments={store.departments}
            formats={store.timetableFormats}
            displayNameItems={store.studentDisplayNameTypes}
            initialMappingValues={store.timetableFormatMappings?.student}
            initialDepartmentMappings={store.departmentFormatMappings.student}
            onDepartmentMappingChange={store.setDepartmentFormatMapping}
          />
          <TimetableTypeFormatMappingSection
            type="subject"
            switchValue={store.showSubjectDepartmentMappings}
            switchCallback={store.setShowSubjectDepartmentMappings}
            departments={store.departments}
            formats={store.timetableFormats}
            displayNameItems={store.subjectDisplayNameTypes}
            initialMappingValues={store.timetableFormatMappings?.subject}
            initialDepartmentMappings={store.departmentFormatMappings.subject}
            onDepartmentMappingChange={store.setDepartmentFormatMapping}
          />
          <TimetableTypeFormatMappingSection
            type="room"
            switchValue={store.showRoomDepartmentMappings}
            switchCallback={store.setShowRoomDepartmentMappings}
            departments={store.departments}
            formats={store.timetableFormats}
            displayNameItems={store.roomDisplayNameTypes}
            initialMappingValues={store.timetableFormatMappings?.room}
            initialDepartmentMappings={store.departmentFormatMappings.room}
            onDepartmentMappingChange={store.setDepartmentFormatMapping}
          />
          <TimetableTypeFormatMappingSection
            type="resource"
            switchValue={store.showResourceDepartmentMappings}
            switchCallback={store.setShowResourceDepartmentMappings}
            departments={store.departments}
            formats={store.timetableFormats}
            displayNameItems={store.resourceDisplayNameTypes}
            initialMappingValues={store.timetableFormatMappings?.resource}
            initialDepartmentMappings={store.departmentFormatMappings.resource}
            onDepartmentMappingChange={store.setDepartmentFormatMapping}
          />
        </Flex>
      </WUFormCol>
    </WUForm>
  );
});

const TimetableTypeFormatMappingSection = observer((props: ITimetableTypeFormatMappingSectionProps) => {
  const { t } = useTranslation();
  const {
    type,
    switchValue,
    switchCallback,
    formats,
    departments,
    displayNameItems,
    initialMappingValues,
    initialDepartmentMappings,
    onDepartmentMappingChange,
  } = props;

  return (
    <div>
      <WUFormRow>
        <WUFormCol lg={2}>
          <FormSection text={t(TIMETABLE_TYPE_TITLE_TRANSLATION_MAP.get(type))} />
        </WUFormCol>
        <WUFormCol lg={2}>
          <FormCheckbox
            name={`${type}Public`}
            testId={`${type}-public`}
            initialValue={initialMappingValues?.public ?? false}
            text={t('general.public')}
            textNoBreakWhiteSpace
          />
        </WUFormCol>
      </WUFormRow>
      <WUFormRow>
        <WUFormCol lg={2}>
          <FormSection description={t(ASSIGN_FORMAT_TO_TIMETABLE_TYPE_MAP.get(type))} />
        </WUFormCol>
        <WUFormCol lg={2}>
          <FormSingleSelect
            name={`${type}Format`}
            placeholder={t('general.timetableFormat')}
            testId={`${type}-timetable-format`}
            items={formats}
            initialValue={initialMappingValues?.selectedFormat?.toString() ?? formats[0].id}
            styleVariant="floating"
            allowClear={false}
            required
          />
        </WUFormCol>
      </WUFormRow>
      <WUFormRow>
        <WUFormCol lg={2}>
          <FormSection description={t(SELECT_DISPLAY_NAME_FOR_TIMETABLE_TYPE_MAP.get(type))} />
        </WUFormCol>
        <WUFormCol lg={2}>
          <FormSingleSelect
            name={`${type}DisplayName`}
            placeholder={t('general.displayName')}
            testId={`${type}-timetable-display-name`}
            items={displayNameItems}
            initialValue={initialMappingValues?.selectedLabel?.toString() ?? displayNameItems[0].id}
            styleVariant="floating"
            allowClear={false}
            required
          />
        </WUFormCol>
      </WUFormRow>
      {departments.length > 0 && (
        <div className="department-mappings-container">
          <WUFormRow>
            <WUFormCol lg={2} />
            <WUFormCol lg={2}>
              <div className="department-switch">
                <SwitchComponent
                  size="default"
                  data-testid={`${type}-department-mappings-switch`}
                  checked={switchValue}
                  defaultChecked={switchValue}
                  onChange={switchCallback}
                />
                <span className="switch-label">{t('general.selectTimetableFormatForEachDepartment')}</span>
              </div>
            </WUFormCol>
          </WUFormRow>
          <motion.div
            animate={{ height: switchValue ? 'auto' : 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            style={{ overflow: switchValue ? 'visible' : 'hidden' }}
          >
            {departments.map((department) => (
              <WUFormRow key={department.id}>
                <WUFormCol lg={2}>
                  <FormSection description={t('general.assignFormatTo', { department: department.name })} />
                </WUFormCol>
                <WUFormCol lg={2}>
                  <FormSingleSelect
                    name={`${type}Department${department.id}`}
                    placeholder={t('general.department')}
                    testId={`${type}-department-${department.id}`}
                    items={formats}
                    initialValue={initialDepartmentMappings.get(department.id)}
                    allowClear={true}
                    styleVariant="floating"
                    onChange={(value) => onDepartmentMappingChange(type, department.id, value)}
                  />
                </WUFormCol>
              </WUFormRow>
            ))}
          </motion.div>
        </div>
      )}
    </div>
  );
});

export default TimetableFormatMappings;
