import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ResizeObserver from 'resize-observer-polyfill';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { ConfigProvider } from 'antd';

import EmbeddedWebuntis from '../embedded-webuntis/embedded-webuntis';
import { Menu } from '../menu/menu';
import MobileBanner from '../mobile-banner/mobile-banner';

import Routes from './routes';

import ModalContainer from '@/components/modal-container/modal-container';
import FeatureOnboarding from '@/components/feature-onboarding/feature-onboarding';
import useGlobalKeyboardShortcuts from '@/hooks/useGlobalKeyboardShortcuts';
import useStore from '@/hooks/useStore';
import AppStore from '@/stores/app-store';
import { MenuStore } from '@/stores/menu-store';
import RouterStore from '@/stores/router-store';
import ShortcutStore from '@/stores/shortcut-store';
import KeyCodes from '@/types/key-codes';
import { IMenuItem, ISubMenuItem } from '@/ui-components/navigation-bar/navigation-bar-store';
import { Sidebar } from '@/ui-components';
import { SidebarStore } from '@/stores/sidebar-store';
import { ExperimentalStore } from '@/stores/experimental-store';
import { ResponsivenessThresholdHint } from '@/components/responsiveness-threshold-hint/responsiveness-threshold-hint';
import {
  getResponsivenessClassName,
  getResponsivenessThresholdForWidth,
  ResponsivenessContext,
  ResponsivenessThreshold,
} from '@/context/responsiveness-context';
import ConfigStore from '@/stores/config-store';
import ModalStore from '@/stores/modal-store';
import './app.less';
import RefStore from '@/stores/ref-store';
import { inject } from '@/types/store';
import TokenStore from '@/stores/token-store';
import { FeatureOnboardingStore } from '@/stores/feature-onboarding-store';
import LocaleStore from '@/stores/locale-store';
import { defaultTheme } from '@ant-design/compatible';
import EnvironmentStore from '@/stores/environment-store';

/**
 * This hook is responsible for detecting if focused elements should be visible based on the user mouse- or keyboard-
 * actions.
 */
function useShowFocusedElements() {
  const appStore = useStore(AppStore);
  const shortcutStore = useStore(ShortcutStore);
  const experimentalStore = useStore(ExperimentalStore);

  useEffect(() => {
    const onMouseDown = () => {
      appStore.showFocusedElements = false;
    };
    window.addEventListener('mousedown', onMouseDown, true);

    const onAnyShortcut = () => {
      appStore.showFocusedElements = true;
    };
    shortcutStore.addGlobalCallback(onAnyShortcut);

    return () => {
      window.removeEventListener('mousedown', onMouseDown, true);
      shortcutStore.removeGlobalCallback(onAnyShortcut);
    };

    // Disable, because we only want the effect to be called once. Be careful with disabling on other places.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // activate focus visibility on tab
  useGlobalKeyboardShortcuts(
    {
      onKeyDown: (e) => {
        if (e.keyCode === KeyCodes.Tab) {
          appStore.showFocusedElements = true;
        }
      },
    },
    true,
  );

  useGlobalKeyboardShortcuts({
    onKeyDown: shortcutStore.createShortcutListener(
      {
        [KeyCodes.K]: {
          callback: (e) => {
            e.preventDefault();
            experimentalStore.toggleModal();
          },
        },
      },
      { fireOnOpenedModals: true, withMetaKey: true },
    ),
  });
}

export const App = observer(() => {
  const contentRef = useRef<HTMLElement>(null);
  const [width, setWidth] = useState<number>(0);
  const menuStore = useStore(MenuStore);
  const appStore = useStore(AppStore);
  const routerStore = useStore(RouterStore);
  const sidebarStore = useStore(SidebarStore);
  const configStore = useStore(ConfigStore);
  const modalStore = useStore(ModalStore);
  const experimentalStore = useStore(ExperimentalStore);
  const refStore = useStore(RefStore);
  const tokenStore = inject(TokenStore);
  const localeStore = inject(LocaleStore);
  const featureOnboardingStore = inject(FeatureOnboardingStore);
  const environmentStore = inject(EnvironmentStore);

  const [widthObserver] = useState<ResizeObserver>(
    () =>
      new ResizeObserver(() => {
        setWidth(contentRef.current?.offsetWidth ?? 0);
      }),
  );

  const currentResponsivenessThreshold: ResponsivenessThreshold = getResponsivenessThresholdForWidth(width);

  const responsivenessContextValue = useMemo(() => {
    return {
      threshold: currentResponsivenessThreshold,
      responsiveClassName: getResponsivenessClassName(currentResponsivenessThreshold),
    };
  }, [currentResponsivenessThreshold]);

  useEffect(() => {
    if (contentRef.current) {
      widthObserver.observe(contentRef.current);
    }
    return () => {
      widthObserver.disconnect();
    };
  }, [contentRef, setWidth, widthObserver]);

  const getClassName = () => {
    let value = 'app';

    if (menuStore.expanded) {
      value += ' menu-expanded';
    } else {
      value += ' menu-collapsed';
    }

    if (appStore.showFocusedElements) {
      value += ' show-focused-elements';
    }

    if (appStore.showLegacyWebUntis) {
      value += ' show-legacy-webuntis';
    }

    return value;
  };

  useShowFocusedElements();

  const handleNavigate = useCallback((item: IMenuItem, subMenuItem?: ISubMenuItem) => {
    if (item.subMenuItems === undefined || subMenuItem !== undefined) {
      contentRef.current?.focus();
    }
  }, []);

  const responsiveClassName = getResponsivenessClassName(currentResponsivenessThreshold);
  const className = clsx(
    'layout-app',
    {
      'layout-main': !appStore.showLegacyWebUntis,
    },
    experimentalStore.isResponsivenessThresholdHintEnabled && responsiveClassName,
  );

  const handleOnUserInteraction = () => {
    const timeoutForSessionRequestInMs: number = 500;
    document.onclick = debounce(requestNextSession, timeoutForSessionRequestInMs);
    document.onkeydown = debounce(requestNextSession, timeoutForSessionRequestInMs);
  };

  const requestNextSession = async () => {
    tokenStore.fetchToken();
  };

  // The sidebar (= old Dojo Calendar bar) can be hidden for this reasons
  // 1) The URL contains a parameter to hide it (= routerStore.sidebar)
  // 2) The user is a student or a parent (there is no view which requires the sidebar)
  // 3) Handled in WebUntis: See ContentManager.js
  const showSidebar = !configStore.hasStudentsUI && routerStore.sidebar;

  return (
    <Div100vh onLoad={handleOnUserInteraction} className={getClassName()}>
      <ConfigProvider
        locale={localeStore.antdLocale}
        theme={{
          ...defaultTheme,
          token: {
            colorPrimary: '#fe6033',
            colorLink: '#fe6033',
            colorSuccess: '#3bc886',
            colorWarning: '#f6891e',
            colorError: '#ff4040',
            borderRadius: 4,
            fontFamily: 'AvenirNextLTPro',
          },
          components: {
            Menu: {
              itemHoverColor: '#fe6033',
            },
          },
        }}
      >
        <Helmet htmlAttributes={{ lang: configStore.userLang }}></Helmet>
        {!routerStore.embedded && <Menu onNavigate={handleNavigate} />}
        <div className="sidebar-container">
          <Sidebar
            size={currentResponsivenessThreshold === ResponsivenessThreshold.XL_TV ? 'lg' : 'md'}
            title={sidebarStore.sidebar?.title}
            isOpen={sidebarStore.isOpen}
            overlap={sidebarStore.sidebar?.overlap !== false}
            handleClose={sidebarStore.closeSidebar}
            onClose={sidebarStore.sidebar?.onClose}
            content={sidebarStore.sidebar?.content}
            disableOutsideClick={modalStore.isAnyModalOpen}
            withoutPadding={sidebarStore.sidebar?.withoutPadding}
          >
            <ResponsivenessContext.Provider value={responsivenessContextValue}>
              <section
                onScroll={() => refStore.archerContainerRef.current?.refreshScreen()}
                className={className}
                ref={contentRef}
                tabIndex={0}
              >
                <Routes />
                <div className="sticky-header-container" ref={appStore.setStickyHeaderContainerRef} />
                <EmbeddedWebuntis src={environmentStore.webUntisURL + '/embedded.do?showSidebar=' + showSidebar} />
                <ModalContainer />
                {featureOnboardingStore.isRunning && <FeatureOnboarding />}
                {experimentalStore.isResponsivenessThresholdHintEnabled && (
                  <ResponsivenessThresholdHint width={width} />
                )}
              </section>
            </ResponsivenessContext.Provider>
          </Sidebar>
        </div>
        <MobileBanner />
      </ConfigProvider>
    </Div100vh>
  );
});

export default withRouter(App);
