/**
 * Makes a scrollable element (= the element with the scrollbar) scroll to the target element.
 * You can also specify an offset, so that it scrolls a little bit more/less.
 */
export const scrollToTarget = (scrollableElement: HTMLElement, target: HTMLElement, offset: number) => {
  const elementRect = target.getBoundingClientRect();

  // If an element has 0 height, then it is hidden, do not scroll
  if (elementRect.height === 0) {
    return;
  }

  const top = elementRect.top - offset;

  scrollableElement.scrollBy({
    top: top,
    behavior: 'smooth',
  });
};

export const scrollIntoView = (
  target: HTMLElement,
  options?: {
    block?: 'start' | 'center' | 'end' | 'nearest';
    inline?: 'start' | 'center' | 'end' | 'nearest';
    behavior?: 'auto' | 'smooth';
  },
) => {
  target.scrollIntoView({
    ...{
      block: 'start',
      inline: 'nearest',
      behavior: 'smooth',
    },
    ...options,
  });
};
