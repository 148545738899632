import React, { useMemo } from 'react';
import { Dayjs } from 'dayjs';
import classNames from 'clsx';

import './lesson-card-time.less';

const LessonCardTime = ({ timePeriod }: { timePeriod: { startDateTime?: Dayjs; endDateTime?: Dayjs } }) => {
  const { startDateTime, endDateTime } = timePeriod;

  const startDateTimeFormatted = useMemo(() => startDateTime?.format('HH:mm'), [startDateTime]);
  const endDateTimeFormatted = useMemo(() => endDateTime?.format('HH:mm'), [endDateTime]);

  return (
    <>
      <div
        className={classNames('lesson-card-time-container', { 'single-value': !endDateTime })}
        data-testid="lesson-card-time"
      >
        {startDateTimeFormatted && (
          <span className="lesson-card-time" data-testid="lesson-card-time-start">
            {startDateTimeFormatted}
          </span>
        )}
        {endDateTimeFormatted && (
          <span className="lesson-card-time" data-testid="lesson-card-time-end">
            {endDateTimeFormatted}
          </span>
        )}
      </div>
    </>
  );
};

export default LessonCardTime;
