import { t } from 'i18next';
import { action, computed, observable } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';

import { ElementType } from './rights-store';

import UntisNews from '@/stores/untis-news-store';
import {
  AppDataDto,
  AppThirdPartyData,
  OneDriveDataDto,
  WuCurrentTenantDto,
  WuCurrentUserDto,
  WuCurrentUserDtoPerson,
  WuCurrentUserDtoRolesEnum,
  WuDepartmentDto,
  HolidayDto,
} from '@untis/wu-rest-view-api';
import { getSubMenuItem, MenuItemEnum, MenuPermissionEnum, SubMenuItemEnum } from '@/components/menu/menu-items';
import { AppViewApi } from '@/stores/api-store';
import { Store, inject } from '@/types/store';
import { ISubMenuItem } from '@/ui-components/navigation-bar/navigation-bar-store';
import { ErrorMessageEnum, showError } from '@/utils/error-handling/error-message';
import { IPlatformApplicationMenuItem } from '@pa/types/IPlatformApplicationMenuItem';
import { getPlatformLogo } from '@pa/utils/platform-utils';
import { getIso6391LanguageCode } from '@/utils/locale/locale';
import { IDateCalendarHolidayProps } from '@/ui-components/date/calendar/date-calendar/date-calendar';

/**
 * Store for basic data that has to be delivered from WebUntis
 * e.g.: user, menu items, language, school,...
 */
@Store()
export default class ConfigStore {
  @observable departments: WuDepartmentDto[] = [];
  @observable selectedDepartmentId = 0; // 0 is the id of the NODEPARTMENT
  @observable selectedStudentId = -1; // -1 means, no (=all) student is selected
  @observable isPlayground = false;
  @observable playgroundUrl: string | undefined;
  @observable isSupportAccessOpen = false;

  @observable private user: WuCurrentUserDto | undefined;
  @observable private tenant: WuCurrentTenantDto | undefined;
  @observable private _platformApplicationMenuItems: IPlatformApplicationMenuItem[] = [];
  @observable private untisNews = inject(UntisNews);
  @observable private oneDriveData: OneDriveDataDto | undefined;
  @observable private _licenseExpiresAt?: string;
  @observable private _holidays?: HolidayDto[] = [];

  @action
  setData = (data: AppDataDto) => {
    this.user = data.user;
    this.tenant = data.tenant;
    this.departments = data.departments ? data.departments : [];
    this.isPlayground = !!data.isPlayground;
    this.oneDriveData = data.oneDriveData;
    this._licenseExpiresAt = data.licenceExpiresAt ?? undefined;
    this.isSupportAccessOpen = Boolean(data.isSupportAccessOpen);
    this._holidays = data.holidays;
  };

  @action
  setThirdPartyData = (data: AppThirdPartyData) => {
    this.playgroundUrl = data.playgroundUrl;

    if (data.sleekplanToken) {
      this.untisNews.setUser(data.sleekplanToken);
    }
  };

  @computed
  get licenseExpiresAt() {
    return dayjs(this._licenseExpiresAt);
  }

  @computed
  get userDisplayName(): string {
    return this.user?.person?.displayName || '';
  }

  @computed
  get userName(): string {
    return this.user?.name || '';
  }

  @computed
  get userEmail(): string {
    return this.user?.email || '';
  }

  @computed
  get userId(): number | undefined {
    return this.user?.id;
  }

  @computed
  /**
   * Returns the locale of the user in the ISO 639-1 format
   */
  get userLang(): string {
    return getIso6391LanguageCode(this.user?.locale ?? '');
  }

  @computed
  get personId(): number | undefined {
    return this.user?.person?.id;
  }

  @computed
  get tenantId(): string | undefined {
    return this.tenant?.id;
  }

  getTranslationForRole = (role: WuCurrentUserDtoRolesEnum): string => {
    switch (role) {
      case WuCurrentUserDtoRolesEnum.ADMIN:
        return t('general.userRoleAdmin');
      case WuCurrentUserDtoRolesEnum.KLASSE:
        return t('general.userRoleClass');
      case WuCurrentUserDtoRolesEnum.DIRECTORATE:
        return t('general.userRoleDirectorate');
      case WuCurrentUserDtoRolesEnum.OTHER:
        return t('general.userRoleOther');
      case WuCurrentUserDtoRolesEnum.PARENT:
        return t('general.userRoleParent');
      case WuCurrentUserDtoRolesEnum.STAFF:
        return t('general.userRoleStaff');
      case WuCurrentUserDtoRolesEnum.STUDENT:
        return t('general.userRoleStudent');
      case WuCurrentUserDtoRolesEnum.TEACHER:
        return t('general.userRoleTeacher');
      case WuCurrentUserDtoRolesEnum.UNTIS:
        return t('general.userRoleUntis');
      case WuCurrentUserDtoRolesEnum.LEGAL_GUARDIAN:
        return t('general.userRoleLegalGuardian');
      case WuCurrentUserDtoRolesEnum.APPRENTICE_REPRESENTATIVE:
        return t('general.userRoleApprenticeRepresentatives');
      default:
        return '';
    }
  };

  @computed
  get userRolesEnum(): WuCurrentUserDtoRolesEnum[] {
    return this.user && this.user.roles ? this.user.roles : [];
  }

  /**
   * users usually only have one role
   * used to get the elementType
   */
  @computed
  get firstUserRole(): WuCurrentUserDtoRolesEnum {
    return this.userRolesEnum[0];
  }

  @computed
  get userRole(): string {
    return this.user && this.user.roles
      ? this.user.roles
          .map((role) => {
            return this.getTranslationForRole(role);
          })
          .join(', ')
      : '';
  }

  @computed
  get elementType(): string {
    return ElementType[this.firstUserRole as keyof typeof ElementType];
  }

  @computed
  get isAdmin(): boolean {
    return !!this.user && !!this.user.roles?.includes(WuCurrentUserDtoRolesEnum.ADMIN);
  }

  @computed
  get isDirectorate(): boolean {
    return !!this.user && !!this.user.roles?.includes(WuCurrentUserDtoRolesEnum.DIRECTORATE);
  }

  @computed
  get isTeacher(): boolean {
    return !!this.user && !!this.user.roles?.includes(WuCurrentUserDtoRolesEnum.TEACHER);
  }

  @computed
  get isStudent(): boolean {
    return !!this.user && !!this.user.roles?.includes(WuCurrentUserDtoRolesEnum.STUDENT);
  }

  @computed
  get hasStudent(): boolean {
    return this.isParent || this.isLegalGuardian || this.isApprenticeRepresentatives;
  }

  @computed
  get isParent(): boolean {
    return !!this.user && !!this.user.roles?.includes(WuCurrentUserDtoRolesEnum.PARENT);
  }

  @computed
  get isClass(): boolean {
    return !!this.user && !!this.user.roles?.includes(WuCurrentUserDtoRolesEnum.KLASSE);
  }

  @computed
  get isLegalGuardian(): boolean {
    return !!this.user && !!this.user.roles?.includes(WuCurrentUserDtoRolesEnum.LEGAL_GUARDIAN);
  }

  @computed
  get isApprenticeRepresentatives(): boolean {
    return !!this.user && !!this.user.roles?.includes(WuCurrentUserDtoRolesEnum.APPRENTICE_REPRESENTATIVE);
  }

  // Parents, Students and Class Users should get the students UI
  @computed
  get hasStudentsUI(): boolean {
    return this.isStudent || this.isParent || this.isClass || this.isLegalGuardian || this.isApprenticeRepresentatives;
  }

  @computed
  get userImage(): string {
    return this.user && this.user.person && this.user.person.imageUrl ? this.user.person.imageUrl : '';
  }

  @computed
  get schoolName(): string {
    return this.tenant && this.tenant.displayName ? this.tenant.displayName : '';
  }

  @computed
  get viewPermissions(): string[] {
    return this.user && this.user.permissions && this.user.permissions.views ? this.user.permissions.views : [];
  }

  @computed
  get platformApplicationMenuItems(): IPlatformApplicationMenuItem[] {
    return this._platformApplicationMenuItems;
  }

  @computed
  get hasOneDrive(): boolean {
    return this.oneDriveData && this.oneDriveData.hasOneDriveRight ? this.oneDriveData.hasOneDriveRight : false;
  }

  @computed
  get oneDriveClientId(): string {
    return this.oneDriveData && this.oneDriveData.oneDriveClientId ? this.oneDriveData.oneDriveClientId : '';
  }

  @computed
  get userStudents(): Array<WuCurrentUserDtoPerson> {
    return this.user?.students ? this.user.students : [];
  }

  @computed
  get userLocale() {
    return this.user && this.user.locale;
  }

  @computed
  get lastLogin(): Dayjs | null {
    if (!this.user?.lastLogin) {
      return null;
    }

    return dayjs(this.user.lastLogin);
  }

  @computed
  get calendarHolidays(): IDateCalendarHolidayProps[] {
    return (
      (this._holidays &&
        this._holidays
          .map((dto) => ({ start: dayjs(dto.start), end: dayjs(dto.end) }))
          .sort((h1, h2) => h1.start.diff(h2.start, 'd'))
          .reduce((merged: IDateCalendarHolidayProps[], current) => {
            const last = merged[merged.length - 1];
            if (!last || current.start.isAfter(last.end, 'd')) {
              merged.push(current);
            } else if (current.end.isAfter(last.end, 'd')) {
              last.end = current.end;
            }
            return merged;
          }, [])) ||
      []
    );
  }

  @action
  public async fetchPlatformApplicationMenuItems() {
    try {
      const response = await AppViewApi.getPlatformApplicationMenus();
      const decodedData: IPlatformApplicationMenuItem[] = response.data
        .map((item) => {
          return {
            id: item.id,
            name: item.name,
            redirectUrl: decodeURIComponent(item.redirectUrl),
            icon: getPlatformLogo(item.icon),
            openInNewTab: item.openInNewTab,
            logoutUrl: item.logoutUrl,
          };
        })
        .sort((item1, item2) => {
          return item1.name.localeCompare(item2.name);
        });
      this._platformApplicationMenuItems = decodedData.sort((item1, item2) => {
        return item1.name.localeCompare(item2.name);
      });
      return true;
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
      this._platformApplicationMenuItems.length = 0;
      return false;
    }
  }

  public hasViewPermissions(...viewPermissions: string[]) {
    if (this.viewPermissions) {
      for (const permission of viewPermissions) {
        if (!this.viewPermissions.includes(permission)) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  public checkMenuItemPermission = (item: MenuItemEnum) => {
    return this.viewPermissions.includes(MenuItemEnum[item]);
  };

  public checkSubMenuItemPermission = (item: SubMenuItemEnum) => {
    return this.viewPermissions.includes(SubMenuItemEnum[item]);
  };

  public checkMenuPermission = (permission: MenuPermissionEnum) => {
    return this.viewPermissions.includes(MenuPermissionEnum[permission]);
  };

  /**
   * Adds a sub menu item to the given array if it is permitted by the config store
   * @param item: the item that needs to be added if it is permitted
   * @param items: the array of items in which the item should be added
   * @param badge: a optional string that should be displayed in the menu item
   */
  public addSubMenuItemIfPermitted = (
    item: SubMenuItemEnum,
    items: ISubMenuItem[],
    badge?: string,
    activeHorizonId?: number,
  ) => {
    if (this.checkSubMenuItemPermission(item)) {
      const subMenuItem = getSubMenuItem(item, badge, activeHorizonId);
      subMenuItem && items.push(subMenuItem);
    }
  };

  @computed
  get myTimetablePath(): string {
    if (this.hasStudentsUI) {
      if (this.hasViewPermissions('TIMETABLE_NEW_STUDENTS_MY')) {
        return '/timetable-new/my-student';
      } else if (this.hasViewPermissions('TIMETABLE_NEW_CLASSES_MY')) {
        return '/timetable-new/my-class';
      }
    } else if (this.hasViewPermissions('TIMETABLE_NEW_TEACHERS_MY')) {
      return '/timetable-new/my-teacher';
    }
    return '/';
  }
}
