import { action, computed, observable } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { AxiosResponse } from 'axios';

import { IStore, Store, inject } from '@/types/store';
import { SchoolYearDto } from '@untis/wu-rest-view-api/api';
import { AppViewApi, SchoolyearViewApi } from '@/stores/api-store';
import PostMessageStore from '@/pages/substitution-planning/stores/post-message-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { AppDataDto } from '@untis/wu-rest-view-api';
import ConfigStore from '@/stores/config-store';
import RightsStore from '@/stores/rights-store';

@Store()
export default class SchoolYearStore implements IStore {
  @observable isCurrentSchoolYearSet = false; // true if SY is initially set and confirmed by embedded WU
  @observable isLoading = true;
  @observable currentSchoolYear: SchoolYearDto | undefined;
  @observable schoolYears: Array<SchoolYearDto> = [];

  private postMessageStore: PostMessageStore = inject(PostMessageStore);
  private notificationStore: NotificationStore = inject(NotificationStore);
  private configStore = inject(ConfigStore);
  private rightsStore = inject(RightsStore);

  private isDateInSchoolYearRange(schoolYear: SchoolYearDto, date: Dayjs | null | undefined): boolean | undefined {
    if (schoolYear.dateRange) {
      const { start, end } = schoolYear.dateRange;
      return date?.isSameOrAfter(dayjs(start), 'd') && date?.isSameOrBefore(dayjs(end), 'd');
    } else {
      return false;
    }
  }

  @action
  async getSchoolYears() {
    const schoolYears = await SchoolyearViewApi.getSchoolyears();
    this.schoolYears = [...schoolYears.data];
    this.isLoading = false;
  }

  @action
  setCurrentSchoolYear(schoolYear?: SchoolYearDto) {
    this.storeCurrentSchoolYear(schoolYear) && this.triggerAppDataReload();
    this.postMessageStore.postSchoolYearSelectedMessage(schoolYear?.id);
  }

  @action
  handleSchoolyearChanged(schoolYear?: SchoolYearDto) {
    this.storeCurrentSchoolYear(schoolYear) && this.triggerAppDataReload();
    this.isCurrentSchoolYearSet = true;
  }

  @action
  storeCurrentSchoolYear(schoolYear?: SchoolYearDto) {
    if (this.currentSchoolYear?.id !== schoolYear?.id) {
      this.currentSchoolYear = schoolYear;
      return true;
    }
    return false;
  }

  @action.bound
  getCurrentSchoolYearById(schoolYearId?: number): SchoolYearDto | undefined {
    return this.schoolYears.find((x) => x.id === schoolYearId);
  }

  @action.bound
  isDateInCurrentSchoolYear = (date: Dayjs): boolean => {
    if (this.currentSchoolYear) {
      return this.isDateInSchoolYearRange(this.currentSchoolYear, date)!;
    }
    return false;
  };

  @action.bound
  isDateWithinSchoolYearsRange(date?: Dayjs | null): boolean | undefined {
    let first: string | undefined = undefined;
    let last: string | undefined = undefined;
    this.schoolYears.forEach((schoolYear) => {
      if (schoolYear.dateRange) {
        const { start, end } = schoolYear.dateRange;
        if (first === undefined || dayjs(start).isBefore(dayjs(first))) {
          first = start;
        }

        if (last === undefined || dayjs(end).isAfter(dayjs(last))) {
          last = end;
        }
      }
    });

    return date?.isSameOrAfter(dayjs(first)) && date?.isSameOrBefore(dayjs(last));
  }

  isTodayInCurrentSchoolYear(): boolean {
    return this.isDateInCurrentSchoolYear(dayjs());
  }

  @action.bound
  getSchoolYearOfDate(date: Dayjs): SchoolYearDto | undefined {
    return this.schoolYears.find((schoolYear) => {
      return this.isDateInSchoolYearRange(schoolYear, date);
    });
  }

  @action.bound
  findSchoolYearOfDate(date: Dayjs): SchoolYearDto | undefined {
    const result = this.findAdjacentSchoolYears(date);
    return result?.current || result?.next;
  }

  @action.bound
  getNextSchoolYearOfDate(date: Dayjs): SchoolYearDto | undefined {
    return this.findAdjacentSchoolYears(date)?.next;
  }

  @action
  handleSchoolYearFallback() {
    if (this.currentSchoolYearDoesNotExist) {
      const adjacentSchoolYears = this.findAdjacentSchoolYears(dayjs());
      this.setCurrentSchoolYear(adjacentSchoolYears.current || adjacentSchoolYears.past || adjacentSchoolYears.next);
    }
  }

  findAdjacentSchoolYears(date: Dayjs): {
    past: SchoolYearDto | undefined;
    current: SchoolYearDto | undefined;
    next: SchoolYearDto | undefined;
  } {
    const result: {
      past: SchoolYearDto | undefined;
      current: SchoolYearDto | undefined;
      next: SchoolYearDto | undefined;
    } = { past: undefined, current: undefined, next: undefined };
    const sortedSchoolYears = [...this.schoolYears].sort(
      (a, b) => dayjs(a.dateRange?.start).unix() - dayjs(b.dateRange?.start).unix(),
    );
    sortedSchoolYears.forEach((schoolYear) => {
      if (schoolYear.dateRange) {
        const { start, end } = schoolYear.dateRange;
        if (!result.next && date.isBefore(dayjs(start), 'd') && date.isBefore(dayjs(end), 'd')) {
          result.next = schoolYear;
        }
        if (!result.current && this.isDateInSchoolYearRange(schoolYear, date)) {
          result.current = schoolYear;
        } else if (date.isAfter(dayjs(start), 'd') && date.isAfter(dayjs(end), 'd')) {
          result.past = schoolYear;
        }
      }
    });
    return result;
  }

  @computed
  get currentSchoolYearStart(): Dayjs | undefined {
    return (
      (this.currentSchoolYear && this.currentSchoolYear.dateRange && dayjs(this.currentSchoolYear.dateRange.start)) ||
      undefined
    );
  }

  @computed
  get currentSchoolYearEnd(): Dayjs | undefined {
    return (
      (this.currentSchoolYear && this.currentSchoolYear.dateRange && dayjs(this.currentSchoolYear.dateRange.end)) ||
      undefined
    );
  }

  // true if the current school year is not available even after initial setup
  @computed
  get currentSchoolYearDoesNotExist(): boolean {
    return this.isCurrentSchoolYearSet && !this.currentSchoolYear;
  }

  private triggerAppDataReload() {
    AppViewApi.getAppData()
      .then((result: AxiosResponse<AppDataDto>) => {
        if (result.data.user && result.data.tenant) {
          this.configStore.setData(result.data);
          this.rightsStore.initialize(result.data.permissions);
        }
      })
      .catch(() => {
        this.notificationStore.error({
          title: t('general.error'),
          message: t('general.errors.unexpectedError'),
        });
      });
  }
}
