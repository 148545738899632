import React from 'react';
import clsx from 'clsx';
import './form-section.less';

interface IProps {
  text?: string;
  description?: string;
  centered?: boolean;
  noMargin?: boolean;
}

export const FormSection = (props: IProps) => {
  const className = clsx('wu-form-section', {
    centered: !!props.centered,
    noMargin: !!props.noMargin,
    description: !!props.description,
  });
  return (
    <div className={className}>
      {props.text && <h3>{props.text}</h3>}
      {props.description && <p>{props.description}</p>}
    </div>
  );
};
