import React from 'react';
import { observer } from 'mobx-react-lite';
import Joyride, { Step } from 'react-joyride';

import { inject } from '@/types/store';
import { FeatureOnboardingStore } from '@/stores/feature-onboarding-store';
import FeatureOnboardingModal from '@/components/feature-onboarding/feature-onboarding-modal';

import './feature-onboarding.less';

const FeatureOnboarding = observer(() => {
  const featureOnboardingStore = inject(FeatureOnboardingStore);

  const joyrideSteps: Step[] = featureOnboardingStore.joyRideSteps.map((step) => {
    return {
      target: step.target,
      content: '',
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      placement: step.placement,
    };
  });

  return (
    <Joyride
      run={featureOnboardingStore.isRunning}
      continuous={true}
      steps={joyrideSteps}
      stepIndex={featureOnboardingStore.currentJoyrideStepIndex}
      tooltipComponent={(props) => (
        <FeatureOnboardingModal {...props} modalSize={featureOnboardingStore.currentStep?.modalSize} />
      )}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
});

export default FeatureOnboarding;
