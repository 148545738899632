import React, { ReactNode } from 'react';
import './sidebar-content.less';

interface IProps {
  children?: ReactNode;
}

export const SidebarContent = (props: IProps) => {
  return <div className="sidebar-content">{props.children}</div>;
};
