import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useLocation } from 'react-router-dom';

import TimetableGrid from './grid/timetable-grid';
import TimetableHeader from './header/timetable-header';
import TimetableFooter from './footer/timetable-footer';

import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import { useComponentWillUnmount } from '@/hooks/useComponentWillUnmount';
import useStore from '@/hooks/useStore';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import { TimetableRootStore } from '@te/standard/stores/timetable-root-store';
import { TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import { TimetableFilterStore } from '@te/standard/stores/filter/timetable-filter-store';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import { TIMETABLE_ROUTE_MATCH_URL, TimetableUrl } from '@te/standard/stores/url/timetable-url-store';

import './timetable.less';

const Timetable = observer(() => {
  const { t } = useTranslation();
  const match = useRouteMatch<TimetableUrl>({
    path: TIMETABLE_ROUTE_MATCH_URL,
    strict: true,
  });
  const location = useLocation();
  const [timetableStore] = useState<TimetableRootStore>(() => new TimetableRootStore(match?.params as TimetableUrl));
  const timetableMetaStore = useStore(TimetableMetaStore);
  const timetableFilterStore = useStore(TimetableFilterStore);
  const timetableFormatStore = useStore(TimetableFormatStore);

  const { isTimetableInitialized } = timetableStore;
  const { hasSelectedSchoolYear, isInitialDateInCurrentSchoolYear } = timetableMetaStore;
  const { myTimetableFilterOptions } = timetableFilterStore;
  const { isMyTimetable } = timetableFormatStore;

  useComponentDidMount(() => {
    const timetableUrl = match?.params as TimetableUrl;
    if (timetableUrl.entityType) {
      timetableStore.init(new URLSearchParams(location.search), timetableUrl.entityType);
    }
  });

  useComponentWillUnmount(() => {
    timetableStore.reset();
  });

  if (!isTimetableInitialized) {
    return <EmptyIndicator title={t('general.loading')} />;
  } else if (!hasSelectedSchoolYear || !isInitialDateInCurrentSchoolYear) {
    const errorDescription = !hasSelectedSchoolYear
      ? t('general.noDataNoSchoolyearConfigured')
      : t('general.navigatedOutsideOfSchoolYear');
    return (
      <div className="timetable-error-container">
        <EmptyIndicator icon="calendar_24" title={t('general.outsideOfSchoolYear')} description={errorDescription} />
      </div>
    );
  } else if (isMyTimetable && myTimetableFilterOptions.length === 0) {
    return (
      <div className="timetable-error-container">
        <EmptyIndicator description={t('timetable.messages.myTimetableNoMasterDataAssigned')} />
      </div>
    );
  }

  return (
    <div className="timetable-container">
      <TimetableHeader />
      <TimetableGrid />
      <TimetableFooter />
    </div>
  );
});

export default Timetable;
