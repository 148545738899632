import React from 'react';
import clsx from 'clsx';

import { Icon } from '@/ui-components';
import './filter-chip.less';

interface IProps {
  text: string;
  appearance?: 'light' | 'dark';
  onClick?: () => void;
  onCancel?: () => void;
  className?: string;
  dataTestId?: string;
}

export const FilterChip = (props: IProps) => {
  let className = clsx('filter-chip', {
    light: props.appearance === 'light',
    clickable: !!props.onClick,
    'with-cancel-button': !!props.onCancel,
  });

  if (props.className) {
    className = `${className} ${props.className}`;
  }

  const testId = props.dataTestId;
  const cancelTestId = testId ? `${testId}-cancel` : undefined;

  return (
    <div className={className} onClick={props.onCancel ? undefined : props.onClick} data-testid={testId}>
      <span>{props.text}</span>
      {props.onCancel && (
        <button data-testid={cancelTestId} className="cancel-button" type="button" onClick={props.onCancel}>
          <Icon type="cancel-circle-filled" />
        </button>
      )}
    </div>
  );
};
