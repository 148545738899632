import React, { useState } from 'react';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';

import { AdminLoginStore, IAdminLoginFormData } from './admin-login-store';

import { WUForm } from '@/ui-components';
import { WUFormCol } from '@/ui-components/wu-form/wu-form-col/wu-form-col';
import { WUFormRow } from '@/ui-components/wu-form/wu-form-row/wu-form-row';
import { FormSection } from '@/ui-components/wu-form/form-section/form-section';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import useStore from '@/hooks/useStore';
import ConfigStore from '@/stores/config-store';
import SettingsStore, { StringSetting } from '@/stores/settings-store';

const AdminLoginView = observer(() => {
  const [form] = Form.useForm<IAdminLoginFormData>();
  const [store] = useState(() => new AdminLoginStore());
  const configStore = useStore(ConfigStore);
  const settingsStore = useStore(SettingsStore);

  return (
    <WUForm form={form} onSubmit={(value) => store.createAdminLogin(value)}>
      <WUFormCol lg={1}>
        <WUFormRow>{t('general.adminDetailChange')}</WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} removePadding>
            <FormSection text={t('general.newUsername')} centered />
          </WUFormCol>
          <WUFormCol lg={2} removePadding>
            <FormInput
              variant="floating"
              label={t('general.username')}
              name="username"
              initialValue={configStore.userName}
              required
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} removePadding>
            <FormSection text={t('general.newPassword')} centered />
          </WUFormCol>
          <WUFormCol lg={2} removePadding>
            <FormInput
              variant="floating"
              label={t('general.password')}
              name="password"
              initialValue=""
              type="password"
              required
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} removePadding>
            <FormSection text={t('general.retypeNewPassword')} centered />
          </WUFormCol>
          <WUFormCol lg={2} removePadding>
            <FormInput
              variant="floating"
              label={t('general.retypeNewPassword')}
              name="passwordRetype"
              initialValue=""
              type="password"
              required
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('general.retypePasswordCorrectly')));
                  },
                }),
              ]}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} removePadding>
            <FormSection
              text={t('general.emailForUserProfile')}
              description={t('general.forForwardingMessages')}
              centered
            />
          </WUFormCol>
          <WUFormCol lg={2} removePadding>
            <FormInput
              variant="floating"
              label={t('general.emailForUserProfile')}
              name="email"
              initialValue={configStore.userEmail}
              required
              type="email"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} removePadding>
            <FormSection text={t('general.emailForContact')} description={t('general.forImportantMessages')} centered />
          </WUFormCol>
          <WUFormCol lg={2} removePadding>
            <FormInput
              variant="floating"
              label={t('general.emailForContact')}
              name="systemEmail"
              initialValue={settingsStore.getStringSetting(StringSetting.SYSTEM_EMAIL_ADMIN)}
              type="email"
              required
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});

export { AdminLoginView };
