import { useParams } from 'react-router-dom';

import { useRenderLegacyPage } from '@/hooks/useRenderLegacyPage';

export const ContactHoursRegistrations = () => {
  const params = useParams<{ personId: string }>();

  const url = `officehourteacher/userregistrations?teacherId=${params.personId}`;

  useRenderLegacyPage({ url });

  return null;
};
