import React from 'react';

import './wu-table-header-icon.less';
import { Icon, Tooltip } from '@/ui-components';

export type WUTableHeaderIconProps = {
  icon: string;
  tooltip: string;
};

const WUTableHeaderIcon = (props: WUTableHeaderIconProps) => {
  return (
    <div className="wu-table-header-icon">
      <Tooltip title={props.tooltip} placement="topLeft">
        <Icon type={props.icon} />
      </Tooltip>
    </div>
  );
};

export default WUTableHeaderIcon;
