import { CheckboxGroupProps } from 'antd/lib/checkbox';
import React, { PropsWithChildren } from 'react';

import { Checkbox } from '../index';

export const ComplexCheckboxGroup = (props: PropsWithChildren<CheckboxGroupProps>) => {
  const { className, children, ...other } = props;

  return (
    <Checkbox.Group {...other} className={`untis-complex-checkbox-group${className ? '' + className : ''}`}>
      <ul>{children}</ul>
    </Checkbox.Group>
  );
};

export default ComplexCheckboxGroup;
