import React, { useRef, useState, useMemo, useLayoutEffect } from 'react';
import clsx from 'clsx';

import { Icon, Skeleton } from '@/ui-components';

import './carousel.less';

export type CarouselProps = {
  children: React.ReactNode;
  variant?: 'motd';
  hideArrows?: boolean;
  showScrollBar?: boolean;
  itemsToScroll?: number;
  gap?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  carouselTestId?: string;
  containerTestId?: string;
  leftButtonTestId?: string;
  rightButtonTestId?: string;
  loading?: boolean;
  className?: string;
};

const gapMap = {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
};

export const Carousel = ({
  className,
  gap: propGap,
  itemsToScroll,
  children,
  variant,
  hideArrows,
  showScrollBar,
  carouselTestId,
  containerTestId,
  leftButtonTestId,
  rightButtonTestId,
  loading,
}: CarouselProps) => {
  const gap = propGap ?? 'lg';
  const carouselRef = useRef<HTMLDivElement>(null);

  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);

  const showRightButton = useMemo(() => {
    return scrollLeft + clientWidth < scrollWidth - 5; // -5 is a workaround
  }, [scrollLeft, clientWidth, scrollWidth]);
  const showLeftButton = useMemo(() => {
    return scrollLeft > 0;
  }, [scrollLeft]);

  function scrollEventListener() {
    setScrollLeft(carouselRef.current?.scrollLeft ?? 0);
    setScrollWidth(carouselRef.current?.scrollWidth ?? 0);
    setClientWidth(carouselRef.current?.clientWidth ?? 0);
  }

  useLayoutEffect(() => {
    if (!carouselRef.current) {
      return;
    }

    const carouselRefCopy = carouselRef;

    setTimeout(() => {
      setScrollLeft(carouselRef.current?.scrollLeft ?? 0);
      setScrollWidth(carouselRef.current?.scrollWidth ?? 0);
      setClientWidth(carouselRef.current?.clientWidth ?? 0);
    }, 100);

    carouselRef.current.addEventListener('scroll', scrollEventListener);

    return () => {
      carouselRefCopy.current?.removeEventListener('scroll', scrollEventListener);
    };
  }, []);

  function handleScrollClick(direction: 'left' | 'right') {
    if (direction === 'left') {
      if (itemsToScroll) {
        const childWidth = carouselRef.current?.firstElementChild?.clientWidth ?? 0;
        const margin = gapMap[gap];

        carouselRef.current?.scroll({
          left: carouselRef.current?.scrollLeft - (childWidth + margin) * itemsToScroll,
          behavior: 'smooth',
        });
        return;
      }

      carouselRef.current?.scroll({
        left: carouselRef.current?.scrollLeft - carouselRef.current.clientWidth,
        behavior: 'smooth',
      });
      return;
    }

    if (direction === 'right') {
      if (itemsToScroll) {
        const childWidth = carouselRef.current?.firstElementChild?.clientWidth ?? 0;
        const margin = gapMap[gap];

        carouselRef.current?.scroll({
          left: carouselRef.current?.scrollLeft + (childWidth + margin) * itemsToScroll,

          behavior: 'smooth',
        });
        return;
      }

      carouselRef.current?.scroll({
        left: carouselRef.current?.scrollLeft + carouselRef.current.clientWidth,
        behavior: 'smooth',
      });
      return;
    }
  }

  if (loading) {
    return <CarouselSkeleton />;
  }

  return (
    <div
      data-testid={containerTestId}
      className={clsx(
        'carousel-container',
        {
          'motd-variant': variant === 'motd',
          'hide-scrollbar': !showScrollBar,
        },
        className,
      )}
    >
      {!hideArrows && showLeftButton && (
        <div className="carousel-button-container carousel-button-left">
          <button
            data-testid={leftButtonTestId}
            className="carousel-button"
            onClick={() => {
              handleScrollClick('left');
            }}
          >
            <Icon type="arrow-left" />
          </button>
        </div>
      )}
      <div ref={carouselRef} data-testid={carouselTestId} className={clsx('carousel', `gap-${gap}`)}>
        {React.Children.map(children, (child) => {
          return child;
        })}
      </div>
      {!hideArrows && showRightButton && (
        <div className="carousel-button-container carousel-button-right">
          <button
            data-testid={rightButtonTestId}
            className="carousel-button"
            onClick={() => {
              handleScrollClick('right');
            }}
          >
            <Icon type="arrow-right" />
          </button>
        </div>
      )}
    </div>
  );
};

function CarouselSkeleton() {
  return (
    <div className={clsx('carousel-container motd-variant hide-scrollbar')}>
      <div className="carousel gap-lg">
        <Skeleton.Input className="carousel-skeleton" active />
        <Skeleton.Input className="carousel-skeleton" active />
        <Skeleton.Input className="carousel-skeleton" active />
      </div>
    </div>
  );
}
