import React from 'react';
import { action, computed, observable } from 'mobx';
import { t } from 'i18next';

import { inject, IStore, Store } from '@/types/store';
import ModalStore from '@/stores/modal-store';
import WuItemPickerDialog from '@/components/wu-item-picker-dialog/wu-item-picker-dialog';
import { IDeprecatedFilter, IFilterItem } from '@/ui-components/filter-bar/filter/deprecatedFilter';
import { ISelectOptionListItem } from '@/ui-components/select-option-list/select-option-list';

export type IWuItemPickerItemOption = Pick<
  ISelectOptionListItem,
  'id' | 'value' | 'name' | 'avatarSrc' | 'tags' | 'icon'
>;

const OPTION_ALL = 'option-all';
const ACTION_INVERT = 'action-invert';

@Store()
export default class WuItemPickerDialogStore implements IStore {
  private modalStore = inject(ModalStore);

  @observable private _isLoading = false;
  @observable private _allItems: IWuItemPickerItemOption[] = [];
  @observable private _searchBarValue = '';
  @observable private _filterTags: IDeprecatedFilter[] = [];
  @observable private _selectedFilterTags: Map<string, string | undefined> = new Map();
  @observable private _allFilterTagSelected = false;
  @observable private _selectedItems: string[] = [];

  @action.bound
  openItemPickerDialog(
    title: string,
    items: IWuItemPickerItemOption[],
    selectedItems: string[],
    filterTags: IDeprecatedFilter[] | undefined,
    onSubmit: (setSelectedItems: string[]) => void,
  ) {
    this.reset();
    this._allItems = items;
    this._selectedItems = selectedItems;
    this._filterTags = filterTags ? filterTags : [];
    this.modalStore.openModalDialog({
      title: title,
      size: 'full-size',
      okButton: {
        label: t('general.ok'),
        onClick: () => onSubmit(this._selectedItems),
      },
      cancelButton: {
        label: t('general.cancel'),
        onClick: () => this.modalStore.closeModal(),
      },
      showFooterSeparator: true,
      children: <WuItemPickerDialog />,
    });
  }

  @action
  private reset() {
    this._isLoading = false;
    this._searchBarValue = '';
    this._filterTags = [];
    this._selectedFilterTags = new Map();
    this._selectedItems = [];
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  @computed
  get searchBarValue(): string {
    return this._searchBarValue;
  }

  @action
  setSearchBarValue(searchBarValue: string) {
    this._searchBarValue = searchBarValue;
  }

  @computed
  get selectedFilterTags(): Map<string, string | undefined> {
    return this._selectedFilterTags;
  }

  @action
  setSelectedFilterTags(selectedFilterTags: Map<string, string | undefined>) {
    this._selectedFilterTags = selectedFilterTags;
  }

  @computed
  get selectedItems(): string[] {
    return this._selectedItems;
  }

  @action
  setSelectedItems(selectedItems: string[]) {
    this._selectedItems = selectedItems;

    this._selectedFilterTags.set(OPTION_ALL, this._selectedItems.length === this._allItems.length ? 'all' : undefined);
  }

  @action
  setVisibleSelectedItems(selectedItems: string[]) {
    // due to search/filtering sometimes not all selected options are visible in the UI
    const invisibleSelected = this.selectedItems.filter((id) => !this.selectOptions.find((option) => option.id == id));
    this.setSelectedItems(selectedItems.concat(invisibleSelected));
  }

  @computed
  get filters(): IDeprecatedFilter[] {
    return [
      {
        id: OPTION_ALL,
        items: [{ id: 'all', label: t('general.all') }],
        value: this.selectedFilterTags.get(OPTION_ALL),
      },
      {
        id: ACTION_INVERT,
        items: [{ id: 'invert', label: t('general.invert') }],
      },
      ...this._filterTags,
    ];
  }

  @computed
  private get sortedItems(): IWuItemPickerItemOption[] {
    return this._allItems.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }

  private filterItemsBySearchBar(items: IWuItemPickerItemOption[]): IWuItemPickerItemOption[] {
    let result = items;
    if (this.searchBarValue.trim().length > 0) {
      result = result.filter((item) => {
        return item.name.toLowerCase().includes(this.searchBarValue.toLowerCase());
      });
    }
    return result;
  }

  private filterItemsByFilterBarTags(items: IWuItemPickerItemOption[]): IWuItemPickerItemOption[] {
    let result = items;
    this._filterTags.forEach((filterTag) => {
      const selectedValueOfFilterTag = this.selectedFilterTags.get(filterTag.id);
      if (selectedValueOfFilterTag) {
        result = result.filter((item) => {
          const matchedTag = item.tags?.find(
            (tag) => (tag.value ?? tag.name).toString().toLowerCase() === selectedValueOfFilterTag.toLowerCase(),
          );
          return matchedTag !== undefined;
        });
      }
    });
    return result;
  }

  @computed
  private get selectedFilterTagValues(): (string | undefined)[] {
    return this._filterTags
      .map((filterTag) => this.selectedFilterTags.get(filterTag.id.toString())?.toLowerCase())
      .filter((filterTag) => filterTag !== undefined);
  }

  @computed
  get selectOptions(): ISelectOptionListItem[] {
    let result = this.sortedItems;
    result = this.filterItemsBySearchBar(result);
    result = this.filterItemsByFilterBarTags(result);

    return result.map((item) => {
      return {
        ...item,
        tags: item.tags?.map((tag) => {
          return {
            ...tag,
            name: tag.name,
            active: this.selectedFilterTagValues.includes(tag.name.toLowerCase()),
          };
        }),
      };
    });
  }

  @action
  handleFilterChange(filter: IDeprecatedFilter, item: IFilterItem | undefined) {
    if (filter.id === ACTION_INVERT || filter.id === OPTION_ALL) {
      this.removeSelectedTags();
    }

    if (filter.id === ACTION_INVERT) {
      this.invertSelection();
    } else if (filter.id === OPTION_ALL) {
      this.addSelectedFilterTag(filter, item);
      this.toggleSelectAll(item);
    } else {
      this.addSelectedFilterTag(filter, item);
      this.setSelectedItems(this.selectOptions.map((option) => option.id.toString()));
    }
  }

  @action
  private removeSelectedTags() {
    for (const selectedFilterTagKey of this._selectedFilterTags.keys()) {
      if (
        selectedFilterTagKey.toLowerCase() !== OPTION_ALL.toLowerCase() ||
        selectedFilterTagKey.toLowerCase() !== ACTION_INVERT.toLowerCase()
      ) {
        this._selectedFilterTags.delete(selectedFilterTagKey);
      }
    }
  }

  @action
  private invertSelection() {
    const invertedSelection = this._allItems.map((s) => s.id.toString()).filter((s) => !this.selectedItems.includes(s));
    this.setSelectedItems(invertedSelection);
  }

  @action
  private addSelectedFilterTag(filter: IDeprecatedFilter, item: IFilterItem | undefined) {
    const newFilterValue = new Map(this.selectedFilterTags);
    newFilterValue.set(filter.id, item ? item.id : undefined);
    this.setSelectedFilterTags(newFilterValue);
  }

  @action
  private toggleSelectAll(item: IFilterItem | undefined) {
    this._allFilterTagSelected = item !== undefined;
    if (this._allFilterTagSelected) {
      this.setSelectedItems(this._allItems.map((s) => s.id.toString()));
    } else {
      this.setSelectedItems([]);
    }
  }
}
