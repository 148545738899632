import { computed, observable } from 'mobx';

import { Store } from '@/types/store';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    $sleek: any;
  }
}

type SleekplanUser = {
  mail?: string;
  token: string;
};

type Sleekplan = {
  setUser: (user: SleekplanUser) => boolean;
  shutdown?: () => boolean; // resetUser is being called within sleekplan in the shutdown function
  open: (view: string) => boolean;
  sso: (cb: any) => void;
  on: (event: string, cb: any) => void;
};

const DEV_PRODUCT_ID = 918100522;
const DACH_PRODUCT_ID = 585971679;
const INTERNATIONAL_PRODUCT_ID = 980803070;

@Store()
class UntisNewsStore {
  @observable unreadUntisNewsCount: number = 0;
  @observable unreadFeedbackCount: number = 0;

  private _sleek: Sleekplan | undefined;
  private token: string | undefined;
  private mutationObserver: MutationObserver | undefined;

  constructor() {
    this.observeSleekplanUnreadCounts();
  }

  initScript(locale: string) {
    window.document.addEventListener(
      'sleek:init',
      () => {
        this._sleek = window.$sleek;

        if (this.token && this.sleek) {
          this.sleek.setUser({ token: this.token });
        }
      },
      { once: true },
    );

    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = `
    window.$sleek = [];
    window.SLEEK_PRODUCT_ID = ${this.productId(locale)};
    (function () {
      d = document;
      s = d.createElement('script');
      s.src = 'https://client.sleekplan.com/sdk/e.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
    `;
    document.body.appendChild(script);
  }

  public observeSleekplanUnreadCounts() {
    const changelogBadgeId = 'sleekplan-badge-changelog';
    const feedbackBadgeId = 'sleekplan-badge-feedback';

    const changelogBadgeTarget = document.getElementById(changelogBadgeId);
    const feedbackBadgeTarget = document.getElementById(feedbackBadgeId);

    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }

    this.mutationObserver = new MutationObserver((mutationList: MutationRecord[]) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          const target = mutation.target as HTMLElement;

          if (target.id === feedbackBadgeId) {
            if (target.dataset.count) {
              this.setUnreadFeedbackCount(Number(target.dataset.count));
            } else {
              this.setUnreadFeedbackCount(0);
            }
            continue;
          }

          if (target.id === changelogBadgeId) {
            if (target.dataset.count) {
              this.setUnreadUntisNewsCount(Number(target.dataset.count));
            } else {
              this.setUnreadUntisNewsCount(0);
            }
          }
        }
      }
    });

    if (changelogBadgeTarget) {
      this.setUnreadUntisNewsCount(Number(changelogBadgeTarget.dataset.count));
      this.mutationObserver.observe(changelogBadgeTarget, {
        attributes: true,
        childList: false,
        subtree: false,
      });
    }

    if (feedbackBadgeTarget) {
      this.setUnreadFeedbackCount(Number(feedbackBadgeTarget.dataset.count));
      this.mutationObserver.observe(feedbackBadgeTarget, {
        attributes: true,
        childList: false,
        subtree: false,
      });
    }
  }

  public setUnreadUntisNewsCount(count: number) {
    this.unreadUntisNewsCount = count;
  }

  public setUnreadFeedbackCount(count: number) {
    this.unreadFeedbackCount = count;
  }

  public setUser(token: string) {
    this.token = token;
  }

  public resetUser() {
    this.sleek?.shutdown?.();
  }

  public openChangelog() {
    this.sleek?.open('changelog');
  }

  public openFeedback(categoryType?: string) {
    if (this.hasUnreadFeedback) {
      this.sleek?.open('notifications');
      return;
    }
    this.sleek?.open(`feedback.add${categoryType ? '?type=' + categoryType : ''}`);
  }

  public productId(locale: string) {
    const hostname = window.location.hostname;
    if (
      hostname.includes('webuntis.localhost') ||
      hostname.includes('webuntis.dod') ||
      hostname.includes('nightly.webuntis.com') ||
      hostname.includes('staging.webuntis.com')
    ) {
      return DEV_PRODUCT_ID;
    }

    if (locale.toLowerCase().startsWith('de')) {
      return DACH_PRODUCT_ID;
    }

    return INTERNATIONAL_PRODUCT_ID;
  }

  @computed
  get sleek(): Sleekplan | undefined {
    if (!this._sleek) {
      console.warn('Sleekplan not initialized');
    }

    return this._sleek;
  }

  @computed
  get hasUnreadUntisNews(): boolean {
    return this.unreadUntisNewsCount > 0;
  }

  @computed
  get hasUnreadFeedback(): boolean {
    return this.unreadFeedbackCount > 0;
  }
}

export default UntisNewsStore;
