import * as React from 'react';
import { motion } from 'framer-motion';
import { v4 } from 'uuid';

import { Tooltip } from '@/ui-components';

import './toggle-switch.less';

export type ToggleSwitchItem = {
  label: string;
  value: string;
  testId?: string;
};

export interface IToggleSwitchProps {
  testId?: string;
  toggles: [ToggleSwitchItem, ToggleSwitchItem, ...ToggleSwitchItem[]];
  value?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  tooltip?: string;
}

export const ToggleSwitch = (props: IToggleSwitchProps) => {
  const name = React.useMemo(() => v4(), []);
  const ids = React.useMemo(() => Array.from({ length: props.toggles.length }, () => v4()), [props.toggles.length]);

  const toggleSwitchContent = (
    <div data-testid={props.testId} className="wu-toggle-switch">
      {props.toggles.map((toggle, index) => {
        const id = ids[index];
        return (
          <React.Fragment key={id}>
            <input
              id={id}
              type="radio"
              name={name}
              defaultChecked={props.value === undefined ? index === 0 : undefined}
              value={toggle.value}
              onChange={(event) => {
                props.onChange(event.target.value);
              }}
              checked={props.value ? toggle.value === props.value : undefined}
              data-testid={toggle.testId}
              disabled={props.disabled}
            />
            <label
              style={{
                WebkitTapHighlightColor: 'transparent',
              }}
              htmlFor={id}
            >
              {props.value === toggle.value && (
                <motion.span
                  layoutId="bubble"
                  className="wu-toggle-switch-bubble"
                  style={{ borderRadius: 9999 }}
                  transition={{ type: 'spring', bounce: 0.2, duration: 0.6 }}
                />
              )}
              {toggle.label}
            </label>
          </React.Fragment>
        );
      })}
    </div>
  );

  return props.tooltip ? <Tooltip title={props.tooltip}>{toggleSwitchContent}</Tooltip> : toggleSwitchContent;
};
