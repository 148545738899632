import React from 'react';
import { notification } from 'antd';
import './notification-content.less';

/*
 * Because of how notifications of Ant Design work, we can not provide a base component. However, we can
 * still encapsulate at least the content area of the notifications. This also makes it easier to test.
 */

interface IProps {
  id: string;
  message?: string;
  downloadLink?: {
    url: string;
    label: string;
  };
}

export const NotificationContent = (props: IProps) => {
  return (
    <div className="notification-content">
      {props.message}
      {props.downloadLink && (
        <a className="download-link" href={props.downloadLink.url} onClick={() => notification.destroy(props.id)}>
          {props.downloadLink.label}
        </a>
      )}
    </div>
  );
};
