import React from 'react';
import clsx from 'clsx';
import { TooltipRenderProps } from 'react-joyride';
import { observer } from 'mobx-react-lite';

import FeatureOnboardingModalHeader from './feature-onboarding-modal-header';
import FeatureOnboardingModalContent from './feature-onboarding-modal-content';
import FeatureOnboardingModalFooter from './feature-onboarding-modal-footer';

import { inject } from '@/types/store';
import { FeatureOnboardingStore } from '@/stores/feature-onboarding-store';

import './feature-onboarding-modal.less';

export type FeatureOnboardingModalSize = 'md' | 'lg';

interface IProps extends TooltipRenderProps {
  modalSize?: FeatureOnboardingModalSize;
}

const FeatureOnboardingModal = observer((props: IProps) => {
  const featureOnboardingStore = inject(FeatureOnboardingStore);
  const { modalSize } = props;
  const { currentStep } = featureOnboardingStore;

  const size = modalSize ?? 'sm';

  if (!currentStep) {
    return null;
  }

  return (
    <div
      ref={props.tooltipProps.ref}
      className={clsx('feature-onboarding-modal', {
        'size-md': size === 'md',
        'size-lg': size === 'lg',
      })}
    >
      <FeatureOnboardingModalHeader currentStep={currentStep} />
      <FeatureOnboardingModalContent
        textContent={currentStep.textContent}
        textContainsHtml={currentStep.textContainsHtml}
        graphic={currentStep.graphic}
      />
      <FeatureOnboardingModalFooter />
    </div>
  );
});

export default FeatureOnboardingModal;
