import { action } from 'mobx';
import { Dayjs } from 'dayjs';

import { TimetableFormatStore } from '../format/timetable-format-store';
import TimetableAnalyticsStore from '../analytics/timetable-analytics-store';
import { IGridEntry } from '../data/timetable-data-store';

import { inject, Store } from '@/types/store';
import { TimetableFilterStore } from '@te/standard/stores/filter/timetable-filter-store';
import { TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import {
  TimetableUrlStore,
  URL_SEARCH_PARAMETER_ENTITY_ID,
  URL_SEARCH_PARAMETER_SELECTED_DATE,
  URL_SEARCH_PARAMETER_VIEW_TYPE,
} from '@te/standard/stores/url/timetable-url-store';
import { formatDateForServerRequest } from '@/utils/date/date-util';

@Store()
export class TimetableUserActionsStore {
  private timetableFilterStore: TimetableFilterStore = inject(TimetableFilterStore);
  private timetableMetaStore: TimetableMetaStore = inject(TimetableMetaStore);
  private timetableUrlStore: TimetableUrlStore = inject(TimetableUrlStore);
  private timetableAnalyticsStore: TimetableAnalyticsStore = inject(TimetableAnalyticsStore);
  private timetableFormatStore: TimetableFormatStore = inject(TimetableFormatStore);
  @action.bound
  setSelectedFilterValue(value: number) {
    this.timetableFilterStore.setSelectedFilterValue(value);
    const { selectedFilterValue } = this.timetableFilterStore;
    this.timetableUrlStore.addUrlParameter(URL_SEARCH_PARAMETER_ENTITY_ID, selectedFilterValue?.toString() ?? '');
  }

  @action.bound
  toggleTimetableViewType(date?: Dayjs) {
    this.timetableMetaStore.toggleViewType(date);
    const { timetableViewType, currentTimetableStartDate } = this.timetableMetaStore;
    this.timetableUrlStore.addUrlParameters(
      new Map<string, string>([
        [URL_SEARCH_PARAMETER_VIEW_TYPE, timetableViewType],
        [URL_SEARCH_PARAMETER_SELECTED_DATE, formatDateForServerRequest(currentTimetableStartDate)],
      ]),
    );
    this.timetableAnalyticsStore.trackClick(
      'TimetableDateViewButtonClick',
      this.timetableFormatStore.timetableEntityType,
      this.timetableMetaStore.timetableViewType,
    );
  }

  @action.bound
  toggleDayColumnHeaderDate(date?: Dayjs) {
    this.toggleTimetableViewType(date);
    this.timetableAnalyticsStore.trackClick(
      'TimetableDayColumnDateClick',
      this.timetableFormatStore.timetableEntityType,
      this.timetableMetaStore.timetableViewType,
    );
  }

  @action.bound
  selectDate(date: Dayjs) {
    this.timetableMetaStore.selectDate(date);
    this.timetableUrlStore.addUrlParameter(
      URL_SEARCH_PARAMETER_SELECTED_DATE,
      formatDateForServerRequest(this.timetableMetaStore.currentTimetableStartDate),
    );
  }

  @action.bound
  selectToday(date: Dayjs) {
    this.selectDate(date);
    this.timetableAnalyticsStore.trackClick(
      'TimetableDateViewButtonClick',
      this.timetableFormatStore.timetableEntityType,
      'today',
    );
  }
  @action.bound
  handlePeriodClick(gridEntry: IGridEntry) {
    this.timetableUrlStore.navigateToPeriodDetails(gridEntry);
    this.timetableAnalyticsStore.trackClick('TimetablePeriodClick', this.timetableFormatStore.timetableEntityType);
  }
}
