import { observable } from 'mobx';
import { v4 } from 'uuid';

import { IFrameHandleMessagePayload } from '@/components/embedded-webuntis/embedded-webuntis';
import { IStore, Store } from '@/types/store';
import { HandleMessageAction } from '@sp/stores/post-message-store';

export type CallBack = (payload?: IFrameHandleMessagePayload) => void;
type CallBackMap = Map<string, [HandleMessageAction, CallBack]>;

/**
 * Store that can be used to register Callback Functions that should be called when a certain
 * iframe message is received. Don't forget to clear the Callback Function when no longer needed.
 *
 * Iframes will need to notify this store that a message has been received.
 * Any other Components can register a Callback that should then be called.
 *
 * E.g.: "Old WebUntis class register scrolled down" -> Message is sent to wu-frontend -> Calendar Entry Details Header
 * should get smaller
 */
@Store()
export default class IframeMessageCallbackStore implements IStore {
  @observable isLoading = false;
  @observable private registeredCallbacks: CallBackMap;

  constructor() {
    this.registeredCallbacks = new Map<string, [HandleMessageAction, CallBack]>();
  }

  /**
   * Register a Callback for a certain action.
   * @param id - unique string e.g. uuid. Needed to clear the callback when no longer needed
   * @param action - action that triggers the callback
   * @param callBack
   */
  registerCallback = (action: HandleMessageAction, callBack: CallBack): string => {
    const id = v4();
    this.registeredCallbacks.set(id, [action, callBack]);
    return id;
  };

  clearCallback = (id: string) => {
    this.registeredCallbacks.delete(id);
  };

  handleWuPageIframeMessage = (action: HandleMessageAction, payload?: IFrameHandleMessagePayload) => {
    this.registeredCallbacks.forEach((value) => {
      if (value[0] === action) {
        value[1](payload);
      }
    });
  };
}
