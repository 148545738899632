import { DragObjectWithType } from 'react-dnd/lib/interfaces/hooksApi';
import { action, computed, observable } from 'mobx';

import { EntryResourceTypeEnum } from '@untis/wu-rest-view-api/api';

export type LessonCardDropResultType = 'to-card' | 'to-pool';
export type LessonCardRowDragPosition = 'in-lesson-card' | 'in-pool';

export interface ILessonCardRowDragObject extends DragObjectWithType {
  row: EntryResourceTypeEnum;
  position: LessonCardRowDragPosition;
}

type CommonLessonCardDropResult = {
  item: ILessonCardRowDragObject;
  type: LessonCardDropResultType;
};

type ToAvailablePoolDropResult = {
  type: 'to-pool';
};

type ToLessonCardTemplateDropResult = {
  type: 'to-card';
  toIndex: number;
};

type ConditionalProps = ToAvailablePoolDropResult | ToLessonCardTemplateDropResult;

export type LessonCardRowDropResult = CommonLessonCardDropResult & ConditionalProps;

const POSSIBLE_LESSON_CARD_ROWS: EntryResourceTypeEnum[] = [
  EntryResourceTypeEnum.CLASS,
  EntryResourceTypeEnum.CLASS_STUDENT_GROUP,
  EntryResourceTypeEnum.ROOM,
  EntryResourceTypeEnum.STUDENT_GROUP,
  EntryResourceTypeEnum.SUBJECT,
  EntryResourceTypeEnum.TEACHER,
];

export const LESSON_CARD_TEMPLATE_ROW_LIMIT = 4;

export default class LessonCardLayoutConfigurerStore {
  @observable private _selectedLessonCardRows: EntryResourceTypeEnum[] = [];
  @observable private _pool: EntryResourceTypeEnum[] = [];

  constructor(value: EntryResourceTypeEnum[]) {
    this._selectedLessonCardRows = value ?? [];
    this._pool = POSSIBLE_LESSON_CARD_ROWS.filter((row) => !this._selectedLessonCardRows.includes(row));
  }

  @computed
  get selectedLessonCardRows(): EntryResourceTypeEnum[] {
    return this._selectedLessonCardRows;
  }

  @computed
  get pool(): EntryResourceTypeEnum[] {
    return this._pool;
  }

  @action
  handleLessonCardRowRemoved(row: EntryResourceTypeEnum, onChangeCallback?: (value: EntryResourceTypeEnum[]) => void) {
    this._selectedLessonCardRows = this._selectedLessonCardRows.filter((r) => r !== row);
    this._pool = [...this._pool, row];
    onChangeCallback && onChangeCallback(this._selectedLessonCardRows);
  }

  @action
  handleLessonCardRowDropped(
    dropResult: LessonCardRowDropResult,
    onChangeCallback?: (value: EntryResourceTypeEnum[]) => void,
  ) {
    if (dropResult) {
      if (dropResult.type === 'to-pool') {
        this.handleDropToAvailablePool(dropResult);
      } else {
        this.handleDropToLessonCard(dropResult);
      }
      onChangeCallback && onChangeCallback(this._selectedLessonCardRows);
    }
  }

  @action
  private handleDropToAvailablePool(dropResult: LessonCardRowDropResult) {
    this.handleLessonCardRowRemoved(dropResult?.item.row);
  }

  @action
  private handleDropToLessonCard(dropResult: LessonCardRowDropResult) {
    if (dropResult.type === 'to-card') {
      const draggedItemPosition = dropResult.item.position;
      const isDropToEmptyRow = dropResult.toIndex >= this._selectedLessonCardRows.length;
      const fromPoolToEmptyRow = draggedItemPosition === 'in-pool' && isDropToEmptyRow;
      const fromPoolToFilledRow = draggedItemPosition === 'in-pool' && !isDropToEmptyRow;
      const fromCardToFilledRow = draggedItemPosition === 'in-lesson-card' && !isDropToEmptyRow;

      if (fromPoolToEmptyRow) {
        this.moveFromPoolToEmptyRow(dropResult.toIndex, dropResult.item.row);
      } else if (fromPoolToFilledRow) {
        this.replaceRowWithRowFromPool(dropResult.toIndex, dropResult.item.row);
      } else if (fromCardToFilledRow) {
        const from = this._selectedLessonCardRows.findIndex((r) => r === dropResult.item.row);
        const to = dropResult.toIndex;

        this.swapRowsInCard(from, to);
      }
    }
  }

  @action
  private moveFromPoolToEmptyRow(toIndex: number, row: EntryResourceTypeEnum) {
    if (toIndex > -1) {
      const rows = [...this._selectedLessonCardRows];
      rows[toIndex] = row;
      this._selectedLessonCardRows = [...rows];
      this._pool = this._pool.filter((r) => r !== row);
    }
  }

  @action
  private replaceRowWithRowFromPool(toIndex: number, row: EntryResourceTypeEnum) {
    if (toIndex > -1) {
      const rowReplaced = this._selectedLessonCardRows[toIndex];
      this.moveFromPoolToEmptyRow(toIndex, row);
      this._pool = [...this._pool, rowReplaced];
    }
  }

  @action
  private swapRowsInCard(index1: number, index2: number) {
    if (index1 >= 0 && index2 >= 0) {
      const rows = [...this._selectedLessonCardRows];

      const row = rows[index1];
      rows[index1] = rows[index2];
      rows[index2] = row;

      this._selectedLessonCardRows = [...rows];
    }
  }
}
