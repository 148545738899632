// import React from 'react';

import { useEffect } from 'react';

import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import useStore from '@/hooks/useStore';
import AnalyticsStore from '@/stores/analytics-store/analytics-store';
import AppStore from '@/stores/app-store';

interface ILegacyWebuntisProps {
  path: string;
}

/**
 * This class only exists to provide a 'hook' to set the 'showLegacyWebUntis'
 * to 'true'.
 * After mobx-react-router navigates to an legacy WebUntis page we just
 * render nothing and set the property in 'componentDidMount' to 'true'
 * so that the iframe appears.
 *
 * All other usages of the 'PageTemplate' will set 'showLegacyWebUntis' to
 * 'false'.
 */
const LegacyWebuntis = ({ path }: ILegacyWebuntisProps) => {
  const appStore = useStore(AppStore);
  const analyticsStore = useStore(AnalyticsStore);

  useComponentDidMount(() => {
    appStore.setShowLegacyWebUntis(true);
  });

  useEffect(() => {
    if (path === '/today') {
      analyticsStore.trackPageView('Today');
    }
  }, [path, analyticsStore]);

  // if (path === '/today') {
  //   return <NewTodayPageToggle />;
  // }

  return null;
};

// const NewTodayPageToggle = () => {
//   const { t } = useTranslation();
//   const experimentalStore = useStore(ExperimentalStore);
//
//   if (!experimentalStore.isExperimentAllowed(experimentalStore.experimentsIds.TODAY_PAGE)) {
//     return null;
//   }
//
//   return (
//     <ExperimentalBanner
//       onChange={() => {
//         experimentalStore.enableExperiment(experimentalStore.experimentsIds.TODAY_PAGE);
//         setTimeout(() => {
//           window.location.href = '/today';
//         }, 400);
//       }}
//     >
//       {t('general.newTodayPageToggle')}
//     </ExperimentalBanner>
//   );
// };

export default LegacyWebuntis;
