import { action, computed, observable } from 'mobx';
import { ReactNode } from 'react';

import { Store } from '@/types/store';

// Time, the sidebar transition animation while opening/closing needs to finish.
const transitionDuration: number = 250;

// It is not sure, how the sidebar content will look, so for now we have a very general
// approach. When things get clearer we might think about extending the sidebar vew component
// to avoid, that developers need to review basic functionality from scratch everytime they introduce
// a new sidebar usage.
export interface ISidebarDefinition {
  title?: string;
  content?: ReactNode;
  overlap?: boolean;
  /* The sidebar closes itself when the user clicks outside. But if you want to have custom behavior, e.g. for
   *  opening an user prompt before closing, then you can use this onClose prop.
   *  However, in that case, you have to call the closeSidebar function of this store by yourself. */
  onClose?: () => void;
  withoutPadding?: boolean;
}

/**
 * Use this store to open the sidebar component.
 */
@Store()
export class SidebarStore {
  @observable private _sidebar: ISidebarDefinition | undefined;
  @observable private _isOpen: boolean;
  @observable private _isTransitionInProgress: boolean;

  constructor() {
    this._isOpen = false;
    this._isTransitionInProgress = false;
  }

  @computed
  get sidebar(): ISidebarDefinition | undefined {
    return this._sidebar;
  }

  @computed
  get isOpen(): boolean {
    return this._isOpen;
  }

  @computed
  public get isFinishedOpen(): boolean {
    return this._isOpen && !this._isTransitionInProgress;
  }

  @computed
  public get isFinishedClosed(): boolean {
    return !this._isOpen && !this._isTransitionInProgress;
  }

  @action
  public openSidebar(sidebar: ISidebarDefinition) {
    if (this.isFinishedClosed) {
      this._sidebar = sidebar;
      this.updateTransition();
      this._isOpen = true;
    }
  }

  @action.bound
  public closeSidebar() {
    if (this.isFinishedOpen) {
      this.clearContent();
      this.updateTransition();
      this._isOpen = false;
    }
  }

  @action
  public clearContent() {
    this._sidebar = undefined;
  }

  @action
  private updateTransition() {
    this._isTransitionInProgress = true;
    setTimeout(() => {
      this._isTransitionInProgress = false;
    }, transitionDuration);
  }
}
