import React from 'react';

import { Button, Icon, Tooltip } from '@/ui-components';
import { ITestComponentProps } from '@/types/test-component-props';

import './help-center-button.less';

interface IProps extends ITestComponentProps {
  disabled?: boolean;
  onClick?: (e: React.BaseSyntheticEvent) => void;
  tooltip?: string;
  withBlinking?: boolean;
}

const HelpCenterButton = (props: IProps) => {
  const { disabled, onClick, tooltip, withBlinking, testId } = props;

  const handleClick = (event: React.BaseSyntheticEvent) => {
    onClick?.(event);
  };

  const button = (
    <span>
      <Button
        className="help-center-button"
        size="large"
        circle
        onClick={handleClick}
        disabled={disabled}
        testId={testId}
      >
        <Icon type="question-circle" />
      </Button>
      {withBlinking && <div className="help-center-button--blinking-dot" />}
    </span>
  );

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip} placement="top">
          {button}
        </Tooltip>
      ) : (
        <>{button}</>
      )}
    </>
  );
};

export default HelpCenterButton;
