import { action } from 'mobx';

import { inject } from '@/types/store';
import { TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import {
  BackEntryDto,
  DayEntryDto,
  EntryResourceDto,
  EntryResourcePairDto,
  EntryResourceTypeEnum,
  ResourceStatusEnum,
} from '@untis/wu-rest-view-api';
import { IFullDayEvent, IGridEntry } from '@te/standard/stores/data/timetable-data-store';
import { GridEntryDto } from '@untis/wu-rest-view-api/api';
import { ILessonCardResourceWithChange, LessonCardRowContent } from '@/components/lesson-card/lesson-card';

export abstract class AbstractTimetableDataEnricher {
  protected timetableMetaStore = inject(TimetableMetaStore);

  abstract enrichPeriod(toEnrich: IGridEntry, gridEntryDto: GridEntryDto): void;
  abstract enrichFullDayEvent(toEnrich: IFullDayEvent, dayEntryDto: DayEntryDto): void;
  abstract supportsGridEntry(gridEntryDto: GridEntryDto): boolean;
  abstract supportsDayEntry(dayEntryDto: DayEntryDto): boolean;
  abstract supportsBackEntry(backEntryDto: BackEntryDto): boolean;

  protected mapEntryResourcePairs(entryResourcePairs: EntryResourcePairDto[]): LessonCardRowContent | undefined {
    let result: LessonCardRowContent | undefined = undefined;
    const entryResourceType = this.getEntryResourceType(entryResourcePairs);
    if (entryResourceType) {
      switch (entryResourceType) {
        case EntryResourceTypeEnum.SUBJECT:
          result = {
            type: 'subject',
            content: entryResourcePairs.map(this.mapEntryResourcePair),
          };
          break;
        case EntryResourceTypeEnum.CLASS:
          result = {
            type: 'klasse',
            content: this.mapToResourceWithChange(entryResourcePairs),
          };
          break;
        case EntryResourceTypeEnum.CLASS_STUDENT_GROUP:
          result = {
            type: 'klasse-student-group',
            content: {
              klassen: this.mapToResourceWithChange(entryResourcePairs.slice(0, entryResourcePairs.length - 1)),
              studentGroup: entryResourcePairs.map(this.mapEntryResourcePair)[entryResourcePairs.length - 1],
            },
          };
          break;
        case EntryResourceTypeEnum.STUDENT_GROUP:
          result = {
            type: 'student-group',
            content: entryResourcePairs.map(this.mapEntryResourcePair)[0] ?? '',
          };
          break;
        case EntryResourceTypeEnum.ROOM:
          result = {
            type: 'rooms',
            content: this.mapToResourceWithChange(entryResourcePairs),
          };
          break;
        case EntryResourceTypeEnum.TEACHER:
          result = {
            type: 'teachers',
            content: this.mapToResourceWithChange(entryResourcePairs),
          };
          break;
        case EntryResourceTypeEnum.INFO:
          result = {
            type: 'text',
            content: {
              texts: entryResourcePairs.map(this.mapEntryResourcePair),
            },
          };
          break;
      }
    }

    return result;
  }

  private mapToResourceWithChange(entryResourcePairs: EntryResourcePairDto[]): ILessonCardResourceWithChange[] {
    const result: ILessonCardResourceWithChange[] = [];
    const { timetableViewType } = this.timetableMetaStore;
    entryResourcePairs.forEach((entryResourcePair) => {
      const currentResource: EntryResourceDto | undefined = entryResourcePair.current;
      const removedResource: EntryResourceDto | undefined = entryResourcePair.removed;

      if (currentResource !== undefined || removedResource !== undefined) {
        if (removedResource) {
          result.push({
            addedResource: timetableViewType === 'week' ? currentResource?.shortName : currentResource?.longName,
            removedResource: timetableViewType === 'week' ? removedResource.shortName : removedResource.longName,
          });
        } else if (currentResource?.status === ResourceStatusEnum.ADDED) {
          result.push({
            addedResource: timetableViewType === 'week' ? currentResource?.shortName : currentResource?.longName,
          });
        } else {
          result.push({
            regularResource: timetableViewType === 'week' ? currentResource?.shortName : currentResource?.longName,
          });
        }
      }
    });
    return result;
  }

  protected getEntryResourceType(entryResourcePairs: EntryResourcePairDto[]): EntryResourceTypeEnum | undefined {
    const foundEntryResourcePair = entryResourcePairs.find(
      (entryResourcePair) => entryResourcePair.current !== undefined || entryResourcePair.removed !== undefined,
    );
    let entryResourceType = foundEntryResourcePair?.current?.type ?? foundEntryResourcePair?.removed?.type;
    const lastEntryResourceType =
      entryResourcePairs[entryResourcePairs.length - 1]?.current?.type ??
      entryResourcePairs[entryResourcePairs.length - 1]?.removed?.type;
    if (
      entryResourceType === EntryResourceTypeEnum.CLASS &&
      lastEntryResourceType === EntryResourceTypeEnum.STUDENT_GROUP
    ) {
      entryResourceType = EntryResourceTypeEnum.CLASS_STUDENT_GROUP;
    }
    return entryResourceType;
  }

  @action.bound
  protected mapEntryResourcePair(entryResourcePair: EntryResourcePairDto): string {
    const entryResource: EntryResourceDto | undefined = entryResourcePair.current ?? entryResourcePair.removed;
    return this.mapEntryResource(entryResource);
  }

  @action.bound
  protected mapEntryResource(entryResourceDto: EntryResourceDto | undefined): string {
    const { timetableViewType } = this.timetableMetaStore;

    if (!entryResourceDto) {
      return '';
    }
    return timetableViewType === 'week' ? entryResourceDto?.shortName : entryResourceDto?.longName;
  }
}
