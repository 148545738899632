import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { IListViewAction, ListView } from '@/components/list-view/list-view';
import { IOpenPeriodsRow, OpenPeriodsStore } from '@/pages/class-register/open-periods/open-periods-store';
import {
  DateRangeOptionEnum,
  IDateRange,
  PageDatePickerMode,
} from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import { createCustomDateRangeClickHandler } from '@/utils/form/form-util';
import ModalStore from '@/stores/modal-store';
import useStore from '@/hooks/useStore';
import ReportStore from '@/stores/report-store';
import { useComponentWillUnmount } from '@/hooks/useComponentWillUnmount';
import { TestIds } from '@/testIds';
import ConfigStore from '@/stores/config-store';
import SchoolYearStore from '@/stores/schoolyear-store';

export const OpenPeriods = observer(() => {
  const [store] = useState(() => new OpenPeriodsStore());
  const modalStore = useStore(ModalStore);
  const reportStore = useStore(ReportStore);
  const configStore = useStore(ConfigStore);
  const schoolYearStore = useStore(SchoolYearStore);
  const { t } = useTranslation();

  useComponentWillUnmount(() => store.onDestroy());

  useEffect(() => {
    schoolYearStore.handleSchoolYearFallback();
  }, [schoolYearStore.currentSchoolYearDoesNotExist]);

  useEffect(() => {
    if (schoolYearStore.currentSchoolYear) {
      store.setSelectedOptions([]);
      store.setSelectedFreeTextSearchOptions([]);
      store.loadMetaData();
    }
  }, [schoolYearStore.currentSchoolYear]);

  useEffect(() => {
    store.preselectSearchOptions(store.searchBarOptions);
  }, [store.searchBarOptions]);

  useEffect(() => {
    store.refreshOnFilterChange();
  }, [store.selectedOptions, store.selectedDateRange]);

  const actions: IListViewAction[] = [];
  if (store.rows.length) {
    if (store.canSendMessage) {
      actions.push({
        label: t('general.sendMessage'),
        onClick: () => store.sendMessage(store.rows),
        testId: TestIds.OPEN_PERIODS_SEND_MESSAGE,
      });
    }

    actions.push({
      label: t('general.report'),
      onClick: () => reportStore.getReport(store.reportUrl),
      testId: TestIds.OPEN_PERIODS_REPORT,
    });
  }

  const dateRangeOptions: [DateRangeOptionEnum, ...DateRangeOptionEnum[]] = [
    DateRangeOptionEnum.USER_SELECTION_DATE_RANGE,
  ];
  if (schoolYearStore.currentSchoolYear) {
    dateRangeOptions.splice(0, 0, DateRangeOptionEnum.START_OF_SCHOOLYEAR);
  }
  if (schoolYearStore.isTodayInCurrentSchoolYear()) {
    dateRangeOptions.splice(0, 0, DateRangeOptionEnum.START_OF_WEEK, DateRangeOptionEnum.START_OF_MONTH);
  }
  return (
    <ListView<IOpenPeriodsRow>
      title={t('general.openPeriods')}
      isPageLoading={store.isPageLoading}
      isPageContentLoading={store.isPageContentLoading}
      usePagination
      counterLabel={`/ ${store.totalPeriods}`}
      counterLabelTooltip={t('general.openPeriodsTotalPeriod')}
      table={{
        columns: store.columns,
        rowData: store.rows,
        shadow: true,
        rowSelection: {
          selectedRowKeys: store.selectedRowKeys,
          type: 'checkbox',
          onChange: (keys) => store.setSelectedRowKeys(keys),
        },
      }}
      emptyIndicator={{
        show: !store.canReadAll && store.selectedOptions.length === 0,
        icon: 'search',
        description: t('general.useSearchToNarrowDownResults'),
      }}
      selectedRowsActions={store.selectedRowsActions}
      searchBar={{
        selectedFreeTextOptions: store.selectedFreeTextOptions,
        onChangeFreeTextOptions: (values) => store.setSelectedFreeTextSearchOptions(values),
        options: store.searchBarOptions,
        onChangeSelectOptions: (options) => store.setSelectedOptions(options),
        selectedOptions: store.selectedOptions,
        onlyOneOptionPerCategory: true,
        toggleFilters: store.filter,
      }}
      pageDatePickerProps={{
        mode: PageDatePickerMode.DATE_RANGE,
        options: dateRangeOptions,
        currentSchoolyear: store.schoolyearDateRange,
        onDateRangeChange: (dateRange) => {
          store.updateLocaleStorage(dateRange);
          store.setSelectedDateRange(dateRange as IDateRange);
        },
        value: store.selectedDateRange,
        onCustomClick: createCustomDateRangeClickHandler({
          modalStore,
          selectedDateRange: store.selectedDateRange,
          setSelectedDateRange: store.setSelectedDateRange,
          disabledDate: (date) => !schoolYearStore.isDateInCurrentSchoolYear(date),
          holidays: configStore.calendarHolidays,
        }),
        firstDayOfWeek: store.firstDayOfWeek,
      }}
      actions={actions}
    />
  );
});
