import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { IListViewAction, ListView } from '@/components/list-view/list-view';
import { TestIds } from '@/testIds';
import { IStudentRow, StudentStore } from '@/pages/master-data/student/student-store';
import { PageDatePickerMode } from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import { IStudentForm } from '@/pages/master-data/student/student-form';

export const MasterDataStudent = observer(() => {
  const [form] = Form.useForm<IStudentForm>();
  const [store] = useState(() => new StudentStore(form));
  const { t } = useTranslation();
  const history = useHistory();

  const listViewActions = (): IListViewAction[] => {
    // useHistory cannot be used in store
    // Solution is temporary, remove this when implementing new student import page
    return store.listViewActions.concat({
      label: t('general.import'),
      onClick: () => {
        history.push('/students-import');
      },
      testId: TestIds.STUDENT_IMPORT,
    });
  };

  return (
    <ListView<IStudentRow>
      title={t('menu.menuItems.masterData.subMenuItems.students')}
      isPageLoading={store.isDataLoading}
      deprecatedSearchBar={{
        value: store.searchInputValue,
        onChange: (value) => store.setSearchInputValue(value),
        filterProps: {
          filter: store.filters,
          value: store.filterValues,
          onChange: store.handleFilterChange,
          staticWidth: false,
        },
        color: 'white',
        placeholder: t('general.search'),
      }}
      table={{
        columns: store.columns,
        rowData: store.filteredRows,
        rowSelection: {
          selectedRowKeys: store.selectedRowKeys,
          type: 'checkbox',
          onChange: (keys) => store.setSelectedRowKeys(keys),
        },
        shadow: true,
      }}
      actions={listViewActions()}
      pageDatePickerProps={{
        mode: PageDatePickerMode.DATE,
        placeholder: t('general.exitDateUntil'),
        onCustomClick: () => store.onCustomClick(),
        onDateChange: (value) => store.setSelectedExitDate(value),
        value: store.selectedExitDate,
        canUndoSelection: true,
        onUndoSelection: () => store.onUndoSelection(),
      }}
      selectedRowsActions={store.selectedRowsActions}
      customSorting={
        store.canEdit
          ? {
              onSave: (order) => store.onCustomSortSave(order),
              onDelete: () => store.onCustomSortDeletion(),
            }
          : undefined
      }
      usePagination
      testId={TestIds.STUDENT_LIST}
    />
  );
});
