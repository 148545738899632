import React from 'react';

import { Button, Icon } from '@/ui-components';
import { IPageHeaderIconButtonProps } from '@/ui-components/page/page';

export const PageHeaderIconButton = (props: IPageHeaderIconButtonProps) => {
  return (
    <Button
      type={props.type || 'primary'}
      outline={props.outline}
      size="large"
      onClick={props.onClick}
      testId={props.dataTestId}
      circle={true}
    >
      <Icon type={props.icon} />
    </Button>
  );
};
