import React from 'react';

import { inject } from '@/types/store';
import { FeatureOnboardingStore, IFeatureOnboardingStep } from '@/stores/feature-onboarding-store';
import { IconButton, Tag } from '@/ui-components';

export interface IFeatureOnboardingModalHeader {
  currentStep: IFeatureOnboardingStep | undefined;
}

const FeatureOnboardingModalHeader = ({ currentStep }: IFeatureOnboardingModalHeader) => {
  const featureOnboardingStore = inject(FeatureOnboardingStore);

  return (
    <div className="feature-onboarding-modal--header">
      {currentStep?.stepNumber && (
        <div className="feature-onboarding-modal--tag">
          <Tag size="lg">{currentStep.stepNumber}</Tag>
        </div>
      )}
      <span className="feature-onboarding-modal--title" data-testid="feature-onboarding-modal--title">
        {currentStep?.title}
      </span>
      <div className="feature-onboarding-modal--close">
        <IconButton
          ariaLabel="close"
          type="cancel-gray"
          testId="icon-close"
          size="md"
          onClick={featureOnboardingStore.close}
        />
      </div>
    </div>
  );
};

export default FeatureOnboardingModalHeader;
