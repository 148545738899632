import { TTHorizon, TTKlasse, TTLesson, TTRoom, TTSchedulingUnit, TTSubject, TTTeacher } from '@tt/types';
import { defaultSorting, sortingByName } from '@/utils/sorting/sorting-util';

/**
 * Sorts horizons by date-range (descending -> latest first) and then by name (ascending).
 *
 * @param a Some TTHorizon.
 * @param b Some other TTHorizon.
 */
export function byDateRangeAndName(a: TTHorizon, b: TTHorizon): number {
  const compareValue = a.dateRange.start.diff(b.dateRange.start) * -1;
  if (compareValue !== 0) {
    return compareValue;
  }
  return a.name.localeCompare(b.name);
}

export function byKlasseName(a: TTKlasse, b: TTKlasse): number {
  return defaultSorting(a.name, b.name);
}

export function byRoomName(a: TTRoom, b: TTRoom): number {
  return defaultSorting(a.name, b.name);
}

export function bySubjectName(a: TTSubject, b: TTSubject): number {
  return defaultSorting(a.name, b.name);
}

export function byTeacherName(a: TTTeacher, b: TTTeacher): number {
  return defaultSorting(a.name, b.name);
}

export function sortingSchedulingUnitsBySubjectName(unitA: TTSchedulingUnit, unitB: TTSchedulingUnit): number {
  // sort scheduling-units by subject name
  if (unitA.lessons && unitB.lessons && unitA.lessons[0] && unitB.lessons[0]) {
    const sortedUnitA = unitA.lessons.sort((a, b) => sortingByName(a.subject, b.subject));
    const sortedUnitB = unitB.lessons.sort((a, b) => sortingByName(a.subject, b.subject));
    return sortingByName(sortedUnitA[0].subject, sortedUnitB[0].subject);
  }
  return 0;
}

export function sortingLessonsBySubjectName(lessonA: TTLesson, lessonB: TTLesson): number {
  // sort lessons by subject name
  if (lessonA && lessonB) {
    return sortingByName(lessonA.subject, lessonB.subject);
  }
  return 0;
}
