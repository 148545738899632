import { observer } from 'mobx-react-lite';
import React, { CSSProperties, ReactElement, RefObject, useEffect, useRef } from 'react';
import clsx from 'clsx';

import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import useStore from '@/hooks/useStore';
import BottomView from '@/ui-components/navigation-bar/bottom-view/bottom-view';
import MenuHeader from '@/ui-components/navigation-bar/menu-header/menu-header';
import MenuItemList from '@/ui-components/navigation-bar/menu-item-list/menu-item-list';
import {
  IMenuDepartment,
  IMenuItem,
  IMenuStudent,
  IMenuUser,
  ISubMenuItem,
  NavigationBarStore,
} from '@/ui-components/navigation-bar/navigation-bar-store';
import './navigation-bar.less';

interface IEventListenerMap {
  onKeyUp?: (e: any) => void;
  onKeyDown?: (e: any) => void;
}

export interface INavigationProps {
  collapsed?: boolean;
  disabled?: boolean;
  title: string;
  schoolName: string;
  menuItems: IMenuItem[];
  bottomMenuItems: ISubMenuItem[];
  user: IMenuUser;
  students?: IMenuStudent[];
  selectedStudentId?: number;
  onSelectStudent?: (value: number) => void;
  studentsText: string;
  logOutItem: ISubMenuItem;
  onLogOut: () => void;
  currentRoute: string;
  selectedDepartmentId?: number;
  departments?: IMenuDepartment[];
  styles?: CSSProperties;
  onCollapse?: () => void;
  onExpand?: () => void;
  onSelectDepartment?: (value: number) => void;
  departmentsText: string;
  allText: string;
  allStudentsText: string;
  logo: JSX.Element;
  onNavigate: (item: IMenuItem, subMenuItem?: ISubMenuItem) => void;
  useGlobalKeyboardShortcuts?: (eventListeners: IEventListenerMap, useCapture?: boolean) => void;
  onKeyDown?: (e: any) => void;
  isUserItemVisible: boolean;
  /**
   * Because of permission changes, it could happen, that the user looses the permission for
   * the menu item, that is currently selected. Use this function for these cases to decide
   * what should happen, e.g.: a redirect
   */
  onInvalidMenuItemSelected?: () => void;
  /**
   * You can inject a costum component (e.g. global school year selector) here.
   * However, if you do this, you also need to provide the height it requires
   * so that calculations of absolute positioned elements work
   */
  customHeaderComponent?: {
    component: ReactElement;
    height: number;
  };
}

const NavigationBar = observer((props: INavigationProps) => {
  const navigationBarStore = useStore(NavigationBarStore);

  // ctrl+m to focus first menu item
  props.useGlobalKeyboardShortcuts &&
    props.useGlobalKeyboardShortcuts({
      onKeyDown: (e) => {
        props.onKeyDown && props.onKeyDown(e);
        if (e.ctrlKey && e.keyCode === 77) {
          navigationBarStore.focusFirstItem();
        }
      },
    });

  const mainMenuItemList = useRef<HTMLDivElement>(null);
  const subMenuItemList = useRef<HTMLDivElement>(null);
  const departmentList = useRef<HTMLDivElement>(null);
  const studentList = useRef<HTMLDivElement>(null);
  const bottomView: RefObject<HTMLDivElement> | null = useRef<HTMLDivElement>(null);
  const mainMenuHeader = useRef<HTMLDivElement>(null);
  const subMenuHeader = useRef<HTMLDivElement>(null);
  const departmentHeader = useRef<HTMLDivElement>(null);
  const studentHeader = useRef<HTMLDivElement>(null);

  /**
   * When the current route changes and causes a rerender, we reset the state for 'isInSubMenu' back to undefined.
   * After this, the logic below determines if we are in the sub menu or not
   *
   * All other rerenders should not affect this state variable! This is why this it is not a boolean but a boolean or
   * undefined. Otherwise we would not be able to push the 'Back Button', because the logic below would always set
   * the state variable again - we only set the variable if it is undefined (= after a route change).
   */
  useEffect(() => {
    navigationBarStore.isInSubMenu = undefined;
    navigationBarStore.setCurrentRoute(props.currentRoute);
  }, [props.currentRoute, navigationBarStore]);

  useEffect(() => {
    navigationBarStore.handleInvalidMenuItemSelected(props.onInvalidMenuItemSelected);
  }, [navigationBarStore.isSomeItemSelected]);

  useComponentDidMount(() => {
    navigationBarStore.setCollapsed(!!props.collapsed);
    navigationBarStore.mainMenuItemList = mainMenuItemList;
    navigationBarStore.subMenuItemList = subMenuItemList;
    navigationBarStore.departmentList = departmentList;
    navigationBarStore.studentList = studentList;
    navigationBarStore.bottomView = bottomView;
    navigationBarStore.subMenuHeader = subMenuHeader;
    navigationBarStore.mainMenuHeader = mainMenuHeader;
    navigationBarStore.departmentHeader = departmentHeader;
    navigationBarStore.studentHeader = studentHeader;
    navigationBarStore.onExpandCallback = props.onExpand;
    navigationBarStore.onCollapseCallback = props.onCollapse;
    navigationBarStore.departments = !!props.departments ? props.departments : [];
    navigationBarStore.students = !!props.students ? props.students : [];
    navigationBarStore.onSelectDepartment = props.onSelectDepartment;
    navigationBarStore.onSelectStudent = props.onSelectStudent;
    navigationBarStore.schoolName = props.schoolName;
    navigationBarStore.setSelectedDepartmentId(!!props.selectedDepartmentId ? props.selectedDepartmentId : -1);
    navigationBarStore.setSelectedStudentId(!!props.selectedStudentId ? props.selectedStudentId : -1);
    navigationBarStore.departmentsText = props.departmentsText;
    navigationBarStore.studentsText = props.studentsText;
    navigationBarStore.allText = props.allText;
    navigationBarStore.allStudentsText = props.allStudentsText;
    navigationBarStore.userRoute = props.user.userRoute;
  });

  useEffect(() => {
    navigationBarStore.menuItems = props.menuItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.menuItems]);
  useEffect(() => {
    navigationBarStore.bottomMenuItems = props.bottomMenuItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bottomMenuItems]);

  navigationBarStore.setSelectedDepartmentId(
    props.selectedDepartmentId !== undefined ? props.selectedDepartmentId : -1,
  );

  const defaultMenuHeaderHeight = 76;
  const menuBottomViewHeight = 200 + props.bottomMenuItems.length * 32;

  navigationBarStore.setSelectedStudentId(props.selectedStudentId !== undefined ? props.selectedStudentId : -1);

  if (navigationBarStore.isInSubMenu === undefined && navigationBarStore.selectedMainMenuItemHasSubMenuItems) {
    navigationBarStore.isInSubMenu = true;
  }

  if (navigationBarStore.isInSubMenu === undefined && !!navigationBarStore.selectedSubMenuItem) {
    navigationBarStore.isInSubMenu = true;
  }

  return (
    <div
      className={clsx('untis-navigation-bar', 'gsys', {
        'untis-navigation-bar--collapsed': navigationBarStore.collapsed,
        'untis-navigation-bar--disabled': props.disabled,
      })}
      style={props.styles}
    >
      <MenuHeader
        departmentHeaderRef={departmentHeader}
        studentHeaderRef={studentHeader}
        title={props.title}
        mainMenuHeaderRef={mainMenuHeader}
        subMenuHeaderRef={subMenuHeader}
        logo={props.logo}
        customHeaderComponent={props.customHeaderComponent}
        headerHeight={defaultMenuHeaderHeight}
      />
      <MenuItemList
        departmentRef={departmentList}
        studentRef={studentList}
        mainMenuRef={mainMenuItemList}
        subMenuRef={subMenuItemList}
        onNavigate={props.onNavigate}
        menuListContainerTop={defaultMenuHeaderHeight + (props.customHeaderComponent?.height ?? 0)}
        menuBottomViewHeight={menuBottomViewHeight}
      />
      <BottomView
        isBottomViewOverlap={navigationBarStore.isBottomViewOverlap}
        selectedSubMenuItem={navigationBarStore.selectedBottomViewItem}
        user={props.user}
        bottomViewMenuItems={props.bottomMenuItems}
        ref={bottomView}
        logOutItem={props.logOutItem}
        onLogOut={props.onLogOut}
        isUserItemVisible={props.isUserItemVisible}
        height={menuBottomViewHeight}
      />
      <div className="overlay" />
    </div>
  );
});

export default NavigationBar;
