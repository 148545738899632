import { action, computed, observable } from 'mobx';
import { ObservableMap } from 'mobx/lib/internal';
import { t } from 'i18next';
import { AxiosPromise, AxiosResponse } from 'axios';
import dayjs, { Dayjs } from 'dayjs';

import { IPeriodRowData } from './class-register-overview';

import {
  dayjsFormatWithoutTimeDeprecated,
  dayjsFromUDateWithoutTime,
  dayjsWithoutTimeDeprecated,
  uDateFromDayjs,
} from '@/utils/date/date-util';
import { ClassRegOverviewViewV2Api } from '@/stores/api-store';
import {
  ClassRegOverviewAbsenceV2Dto,
  ClassRegOverviewDataRequestV2Dto,
  ClassRegOverviewDataResponseV2Dto,
  ClassRegOverviewHomeworkV2Dto,
  ClassRegOverviewMetaResponseV2Dto,
  ClassRegOverviewPeriodAbsenceV2Dto,
  ClassRegOverviewPeriodV2Dto,
} from '@untis/wu-rest-view-api';
import { scrollToTarget } from '@/utils/scroll/scroll-util';
import { inject } from '@/types/store';
import ConfigStore from '@/stores/config-store';
import { defaultSorting } from '@/utils/sorting/sorting-util';
import { ISearchBarOption } from '@/ui-components/search-bar/search-bar';
import { ITextSelectOption } from '@/ui-components/text-select/text-select';
import { matchesAllSearches } from '@/utils/filtering/filtering-util';
import SchoolYearStore from '@/stores/schoolyear-store';
import { IToggleFilterProps } from '@/ui-components/filter-bar/filter/toggle-filter';

export interface IEmptyDateRange {
  startDate: number;
  endDate: number;
}

interface IAbsenceDateTimeRange {
  startDate: number;
  endDate: number;
  startTime: number;
  endTime: number;
}

// representation of an absence expected to be received from the old frontend (old CR page)
export interface IAbsence extends IAbsenceDateTimeRange {
  id: number;
  person: { id: number; displayName: string };
  interruptions: Array<IAbsenceDateTimeRange>;
  excuseStatus: { isOpen: boolean; isExcused: boolean };
}

// an array of maps, that store all periods by a day
type GroupedPeriods = [number, ClassRegOverviewPeriodV2Dto[]][];

/**
 * Depending on the user the filers are different
 * Teachers: Can only filter for their lessons + teaching content / absences
 * Class teachers: Same as teachers + an additional filter for teachers (of their classes)
 * Admin: Free text input to search and select classes, teachers and subjects + teaching content / absences
 */

/**
 * Store for the class register overview.
 * Main purpose:
 * Depending on the initial fetch date range of data and how many days the user
 * fetches by scrolling down, this store keeps all the periods it receives from the backend.
 * It also should properly merge "days with no data" into Empty Date Ranges so we can display
 * the information that there is "no data for [from] - [to]
 */
export class ClassRegisterOverviewStore {
  // the actual data
  @observable private _meta: ClassRegOverviewMetaResponseV2Dto | undefined;
  // Map period id -> period
  @observable private _periods: ObservableMap<number, ClassRegOverviewPeriodV2Dto> = observable.map();
  @observable private _homeworks: { [p: string]: ClassRegOverviewHomeworkV2Dto } = {}; // Map homework id -> homework
  @observable private _absences: { [p: string]: ClassRegOverviewAbsenceV2Dto } = {}; // Map absence id -> absence

  @observable private _hasFetchedConfig: boolean = false;
  @observable private _hasFetchedData: boolean = false;
  @observable private _inProgress: boolean = false; // to display a loading indicator

  // daterange for that the data already has been fetched
  @observable private _minFetchedDate!: Dayjs;
  @observable private _maxFetchedDate!: Dayjs;
  @observable private _currentDate!: Dayjs;
  @observable private _schoolyearStartDate: Dayjs | undefined;
  @observable private _schoolyearEndDate: Dayjs | undefined;

  // depending on timetable restrictions, the user might only be allowed to see lessons for a certain daterange.
  // server returns 0 = no restriction in the past
  // server returns 29991231 = no restriction in the future
  @observable private _allowedStartDate: Dayjs | undefined;
  @observable private _allowedEndDate: Dayjs | undefined;

  // the maximum date range of data to fetch in the backend is restricted to 15 days.
  @observable public readonly daysToFetch: number;

  // selected option can contain a lesson Id or a class Id as a value
  @observable private _selectedOption: string = '-1';

  @observable private _absenceCheckMissingFilter: boolean = false;
  @observable private _teachingContentMissingFilter: boolean = false;
  @observable private _selectedFreeTextOptions: string[] = [];
  @observable private _selectedOptions: ISearchBarOption[] = [];

  // The localized String is used as "Group" of search result options in the admin filter
  private _localizedTeacher: string = '';
  private _localizedClass: string = '';
  private _localizedSubject: string = '';
  private _initialFromDate!: Dayjs;
  private _initialToDate!: Dayjs;

  private _metaStore: ConfigStore = inject(ConfigStore);
  private _schoolyearStore: SchoolYearStore = inject(SchoolYearStore);

  constructor(days: number) {
    this.daysToFetch = days;
  }

  @action
  public init(initialFromDate: Dayjs) {
    this._currentDate = dayjs(initialFromDate);
    this._initialFromDate = initialFromDate;
    this._initialToDate = dayjs(initialFromDate).add(this.daysToFetch, 'days');

    this._minFetchedDate = this._initialFromDate;
    this._maxFetchedDate = this._initialToDate;
    this._homeworks = {};
    this._absences = {};
    // The localized String is used as "Group" of search result options in the admin filter
    this._localizedTeacher = t('general.teacher');
    this._localizedClass = t('general.class');
    this._localizedSubject = t('general.subject');

    this._absenceCheckMissingFilter = false;
    this._teachingContentMissingFilter = false;
    this._selectedOptions = [];
    this._selectedFreeTextOptions = [];

    this.fetchMeta().then((response) => {
      this._meta = response.data;
      this._hasFetchedConfig = true;

      this._schoolyearStartDate = this._schoolyearStore.currentSchoolYearStart;
      this._schoolyearEndDate = this._schoolyearStore.currentSchoolYearEnd;

      // sort all filter options
      this._meta.classes = this._meta.classes.sort((a, b) => defaultSorting(a.name, b.name));
      this._meta.teachers = this._meta.teachers.sort((a, b) => defaultSorting(a.name, b.name));
      this._meta.subjects = this._meta.subjects.sort((a, b) => defaultSorting(a.name, b.name));

      // pre select the filter values, depending on the user.
      // The class register overview can only be seen by admins, directorate and teachers.
      if (this._metaStore.isTeacher) {
        const personId = this._metaStore.personId;
        const teacherId: number | undefined = this._meta.teachers.find((t) => t.id === personId)?.id;

        if (teacherId) {
          const teacherOption = this.searchBarOptions.find(
            (o) => o.category === this._localizedTeacher && o.id === teacherId.toString(),
          );
          if (teacherOption) {
            this.setSelectedOptions([teacherOption]);
          }
        }
      }

      this._allowedStartDate = dayjsFromUDateWithoutTime(this._meta.allowedStartDate);
      this._allowedEndDate = dayjsFromUDateWithoutTime(this._meta.allowedEndDate);

      if (!this.isOutsideOfAllowedDateRange) {
        const fetchDataResult = this.fetchData(this._initialFromDate, this._initialToDate);
        if (fetchDataResult) {
          fetchDataResult.then((response) => {
            this.mergeFetchedData(response);
            this._hasFetchedData = true;
          });
        } else {
          this._hasFetchedData = true;
        }
      }
    });
  }

  @action
  private mergeFetchedData = (
    response: AxiosResponse<ClassRegOverviewDataResponseV2Dto>,
    deleteExistingPeriods?: boolean,
  ) => {
    if (deleteExistingPeriods) {
      this._periods = observable.map();
    }
    const newPeriodsMap = observable.map();
    response.data.periods.forEach((period) => {
      newPeriodsMap.set(period.id, period);
    });
    this._periods.merge(newPeriodsMap);
    this._homeworks = { ...this._homeworks, ...response.data.homeworksMap };
    this._absences = { ...this._absences, ...response.data.studentAbsencesMap };
  };

  // ignore the warning - it can be static because the config is not used yet
  private fetchMeta() {
    return ClassRegOverviewViewV2Api.loadCROverviewMetaV2();
  }

  private fetchData(dateFrom: Dayjs, dateTo: Dayjs): AxiosPromise<ClassRegOverviewDataResponseV2Dto> | undefined {
    // clip the date if it is before the start of the schoolyear
    let dateFromClipped =
      this._schoolyearStartDate && dateFrom.isBefore(this._schoolyearStartDate) ? this._schoolyearStartDate : dateFrom;
    // clip the date if it is before the allowed daterange
    dateFromClipped =
      this._allowedStartDate && dateFromClipped.isBefore(this._allowedStartDate)
        ? this._allowedStartDate
        : dateFromClipped;

    // clip the date if it is after the end of the schoolyear
    let dateToClipped =
      this._schoolyearEndDate && dateTo.isAfter(this._schoolyearEndDate) ? this._schoolyearEndDate : dateTo;
    // clip the date if it is after the allowed daterange
    dateToClipped =
      this._allowedEndDate && dateToClipped.isAfter(this._allowedEndDate) ? this._allowedEndDate : dateToClipped;

    // Either a class or a teacher (or both) must be set in the request.
    // If none is set, the requests will return a 400 error.
    // This restriction exists because of performance reasons.

    const classId: string | undefined = this._selectedOptions.find(
      (option) => option.category === this._localizedClass,
    )?.id;

    const teacherId: string | undefined = this._selectedOptions.find(
      (option) => option.category === this._localizedTeacher,
    )?.id;

    if (!teacherId && !classId) {
      return undefined;
    }

    const request: ClassRegOverviewDataRequestV2Dto = {
      dateRange: {
        start: dayjsFormatWithoutTimeDeprecated(dateFromClipped),
        end: dayjsFormatWithoutTimeDeprecated(dateToClipped),
      },
      teacherId: teacherId !== undefined ? Number(teacherId) : undefined,
      classId: classId !== undefined ? Number(classId) : undefined,
    };
    return ClassRegOverviewViewV2Api.loadCROverviewDataV2(request);
  }

  @action
  public fetchPreviousPeriods() {
    const nextRangeStart = dayjs(this._minFetchedDate).subtract(this.daysToFetch, 'days');
    const nextRangeEnd = dayjs(this._minFetchedDate).subtract(1, 'days');
    const fetchDataResult = this.fetchData(nextRangeStart, nextRangeEnd);
    if (fetchDataResult) {
      return fetchDataResult.then((response) => {
        this.mergeFetchedData(response);
        this._minFetchedDate = nextRangeStart;
      });
    }
    return new Promise<void>(() => undefined); // empty promise
  }

  @action
  public fetchNextPeriods() {
    const nextRangeStart = dayjs(this._maxFetchedDate).add(1, 'days');
    const nextRangeEnd = dayjs(this._maxFetchedDate).add(this.daysToFetch, 'days');
    const fetchDataResult = this.fetchData(nextRangeStart, nextRangeEnd);
    if (fetchDataResult) {
      return fetchDataResult.then((response) => {
        this.mergeFetchedData(response);
        this._maxFetchedDate = nextRangeEnd;
      });
    }
    return new Promise<void>(() => undefined); // empty promise
  }

  // type guard
  isEmptyDateRange = (block: ClassRegOverviewPeriodV2Dto[] | IEmptyDateRange): block is IEmptyDateRange => {
    return (block as IEmptyDateRange).startDate !== undefined;
  };

  @action
  public jumpToToday() {
    this.handleDateChange(dayjs(), false);
  }

  @action
  public jumpToStart() {
    const start = this._allowedStartDate!.isBefore(this._schoolyearStartDate)
      ? this._schoolyearStartDate
      : this._allowedStartDate;
    this.handleDateChange(start!, false);
  }

  @action
  public jumpToEnd() {
    const end = this._allowedEndDate!.isAfter(this._schoolyearEndDate) ? this._schoolyearEndDate : this._allowedEndDate;
    this.handleDateChange(end!, false);
  }

  @action
  public handleDateChange(date: Dayjs, forceUpdate: boolean) {
    this._currentDate = dayjs(date);
    const minStartDayjs = dayjsWithoutTimeDeprecated(this._minFetchedDate);
    const maxEndDayjs = dayjsWithoutTimeDeprecated(this._maxFetchedDate);

    if (!forceUpdate && date.isSameOrAfter(minStartDayjs, 'date') && date.isSameOrBefore(maxEndDayjs, 'date')) {
      // the data has already been fetched - just jump to that date
      // therefore we first need to find the periods array or empty date range for that date
      const entry: ClassRegOverviewPeriodV2Dto[] | IEmptyDateRange = this.periods.find((entry) => {
        if (this.isEmptyDateRange(entry)) {
          const start = dayjsFromUDateWithoutTime(entry.startDate);
          const end = dayjsFromUDateWithoutTime(entry.endDate);
          return date.isSameOrAfter(start, 'date') && date.isSameOrBefore(end, 'date');
        } else {
          const periodDate = dayjsFromUDateWithoutTime(entry[0].date);
          return periodDate.isSame(date, 'date');
        }
      })!; // we already fetched the data, there must be an entry

      // in the UI, the period tables have the uDate as ID. Empty date ranges have the uDate of the start of the range.
      const scrollId = this.isEmptyDateRange(entry) ? entry.startDate : entry[0].date;
      const scrollTarget = document.getElementById(scrollId.toString());
      const scrollableElement = document.getElementById('class-register-overview-page');
      if (scrollTarget && scrollableElement) {
        scrollToTarget(scrollableElement, scrollTarget, 140); // offset = height of the header
      }
    } else {
      // the data has not been fetched yet
      this._inProgress = true;
      const end: Dayjs = dayjs(date).add(this.daysToFetch, 'days');
      this._minFetchedDate = date;
      this._maxFetchedDate = end;

      if (!this.isOutsideOfAllowedDateRange) {
        const fetchDataResult = this.fetchData(date, end);
        if (fetchDataResult) {
          fetchDataResult.then((response) => {
            this.mergeFetchedData(response, true);
            this._inProgress = false;
          });
        } else {
          this._inProgress = false;
        }
      }
    }
  }

  @computed
  get hasFetchedConfig() {
    return this._hasFetchedConfig;
  }

  @computed
  get hasFetchedData() {
    return this._hasFetchedData;
  }

  @computed
  get isInProgress() {
    return this._inProgress;
  }

  @computed
  get isOutsideOfSchoolyear(): boolean {
    // When the user opens the overview and is outside of any schoolyear, then the server does not send a
    // start- or enddate for the schoolyear in the config, which means, that the user is not in any schoolyear.
    // If the user is in a schoolyear, he is not able to leave it because scrolling and datepicker is restricted
    // to the start- and enddate.
    return !this._meta?.schoolYearStartDate || !this._meta?.schoolYearEndDate;
  }

  // depending on if the user reached the end by scrolling, there can be two labels, to describe, why he can not scroll
  // further.
  // If the user can not scroll because he is outside of a schoolyear or allowedRange, there should not be a label at
  // all.
  private getDisableScrollDownLabel(isScrollingDisabled: boolean, reachedEndOfSchoolyear: boolean): string | undefined {
    if (isScrollingDisabled) {
      return undefined;
    }
    return reachedEndOfSchoolyear ? t('general.endOfSchoolyear') : t('general.lessonsCanNotBeDisplayedForDateRange');
  }

  @computed
  get disabledScrollDownLabel(): string | undefined {
    return this.getDisableScrollDownLabel(this.isScrollingDisabled, this.reachedEndOfSchoolyear);
  }

  private getDisabledScrollUpLabel(
    isScrollingDisabled: boolean,
    reachedStartOfSchoolyear: boolean,
  ): string | undefined {
    if (isScrollingDisabled) {
      return undefined;
    }
    return reachedStartOfSchoolyear
      ? t('general.startOfSchoolyear')
      : t('general.lessonsCanNotBeDisplayedForDateRange');
  }

  @computed
  get disabledScrollUpLabel(): string | undefined {
    return this.getDisabledScrollUpLabel(this.isScrollingDisabled, this.reachedStartOfSchoolyear);
  }

  @computed
  get isOutsideOfAllowedDateRange(): boolean {
    return this._currentDate.isBefore(this._allowedStartDate) || this._currentDate.isAfter(this._allowedEndDate);
  }

  @computed
  get disabledDate(): (date: Dayjs) => boolean {
    return (date: Dayjs) => {
      const outsideSchoolyear =
        !this._schoolyearStartDate ||
        !this._schoolyearEndDate ||
        date.isBefore(this._schoolyearStartDate) ||
        date.isAfter(this._schoolyearEndDate);
      const outsideAllowedDateRange =
        !this._allowedStartDate ||
        !this._allowedEndDate ||
        date.isBefore(this._allowedStartDate) ||
        date.isAfter(this._allowedEndDate);

      return outsideSchoolyear || outsideAllowedDateRange;
    };
  }

  @computed
  private get filteredAndSortedPeriodsArray(): ClassRegOverviewPeriodV2Dto[] {
    let periods = this.periodsSortedByDate;

    if (!this._meta) {
      return periods;
    }

    const teacherOption = this.selectedOptions.find((option) => option.category === this._localizedTeacher);
    const classOption = this.selectedOptions.find((option) => option.category === this._localizedClass);
    const subjectOption = this.selectedOptions.find((option) => option.category === this._localizedSubject);
    const freeTextOption = this.selectedFreeTextOptions.length > 0;

    if (teacherOption) {
      periods = periods.filter((period) => {
        const teacherId = parseInt(teacherOption.id);
        return period.teachers.some(
          (periodTeacher) => periodTeacher.el.id === teacherId || periodTeacher.orgEl?.id === teacherId,
        );
      });
    }
    if (classOption) {
      periods = periods.filter((period) => {
        return period.classes.some((periodClass) => periodClass.el.id === parseInt(classOption.id));
      });
    }
    if (subjectOption) {
      periods = periods.filter((period) => {
        return period.subject && period.subject.el.id === parseInt(subjectOption.id);
      });
    }

    if (freeTextOption) {
      periods = periods.filter((period) => {
        return matchesAllSearches(this.mapDtoToSearchString(period), this._selectedFreeTextOptions);
      });
    }

    if (this._teachingContentMissingFilter) {
      periods = periods.filter((period) => {
        return !period.topic;
      });
    }

    if (this._absenceCheckMissingFilter) {
      periods = periods.filter((period) => {
        return !period.absChecked;
      });
    }

    return periods;
  }

  private mapDtoToSearchString(dto: ClassRegOverviewPeriodV2Dto): string {
    let res = '';
    res += dto.classes.map((c) => c.el.name.toLocaleLowerCase()).join(' ');
    res += dto.teachers.map((t) => t.el.name.toLocaleLowerCase()).join(' ');
    res += dto.subject ? dto.subject.el.name.toLocaleLowerCase() : '';
    return res.toLocaleLowerCase();
  }

  @computed
  private get periodsSortedByDate(): ClassRegOverviewPeriodV2Dto[] {
    return Array.from(this._periods)
      .sort((a, b) => {
        return a[1].date - b[1].date;
      })
      .map((p) => p[1]);
  }

  // if the user is a class teacher, the backend returns more teachers. In that case, the teacher column makes sense
  @computed
  get showTeacherColumn(): boolean {
    return !!this._meta && this._meta.teachers.length > 1;
  }

  // Helper. Sorts all the periods that have been received so far by date.
  // Then in collects all periods that are on the same day and put it into a map, where the key is the date of the
  // periods
  @computed
  private get periodArraysSortedAndGroupedByDate(): Map<number, ClassRegOverviewPeriodV2Dto[]> {
    const result = new Map<number, ClassRegOverviewPeriodV2Dto[]>();
    this.filteredAndSortedPeriodsArray &&
      this.filteredAndSortedPeriodsArray.forEach((period) => {
        const periodsBlock = result.get(period.date);
        periodsBlock ? periodsBlock.push(period) : result.set(period.date, [period]);
      });
    return result;
  }

  // Helper. Returns all periods for a day, or undefined if there are none
  private getPeriodsForDate = (date: number, periods: GroupedPeriods): ClassRegOverviewPeriodV2Dto[] | undefined => {
    const periodsForDate = periods.find((p) => p[0] === date);
    if (periodsForDate) {
      return periodsForDate[1];
    }
    return undefined;
  };

  @computed
  get periods(): (ClassRegOverviewPeriodV2Dto[] | IEmptyDateRange)[] {
    const groupedPeriods: GroupedPeriods = Array.from(this.periodArraysSortedAndGroupedByDate);

    if (groupedPeriods.length === 0) {
      return [
        {
          startDate: uDateFromDayjs(this._minFetchedDate),
          endDate: uDateFromDayjs(this._maxFetchedDate),
        },
      ];
    }

    const result: (ClassRegOverviewPeriodV2Dto[] | IEmptyDateRange)[] = [];
    let currentEmptyRange: IEmptyDateRange | null = null;

    const startDayjs = dayjsWithoutTimeDeprecated(this._minFetchedDate);
    const endDayjs = dayjsWithoutTimeDeprecated(this._maxFetchedDate);

    // Iterate from the earliest to the latest day for that the data has been fetched
    // and either push the periods for that day into the result array, if there are some.
    // If there are none, combine all following empty days into one empty date range
    for (let date = startDayjs; date.isSameOrBefore(endDayjs); date = date.add(1, 'day')) {
      const dateNumber: number = uDateFromDayjs(date);
      const periodsForDate: ClassRegOverviewPeriodV2Dto[] | undefined = this.getPeriodsForDate(
        dateNumber,
        groupedPeriods,
      );

      if (periodsForDate) {
        if (currentEmptyRange) {
          currentEmptyRange.endDate = uDateFromDayjs(dayjs(date).subtract(1, 'day'));
          result.push(currentEmptyRange);
          currentEmptyRange = null;
        }

        result.push(periodsForDate.sort((a, b) => a.startTime - b.startTime));
      } else if (!currentEmptyRange) {
        currentEmptyRange = {
          startDate: dateNumber,
          endDate: dateNumber,
        };
      }
    }

    // when we are done and there is still an currentEmptyRange -> we must add it
    if (currentEmptyRange) {
      currentEmptyRange.endDate = uDateFromDayjs(endDayjs);
      result.push(currentEmptyRange);
    }

    return result;
  }

  @computed get filter(): IToggleFilterProps[] {
    const filter: IToggleFilterProps[] = [];

    if (this._meta) {
      filter.push({
        label: t('general.teachingContentMissing'),
        value: this._teachingContentMissingFilter,
        onChange: (value) => this.onTeachingContentMissingFilterChange(value),
      });
    }
    if (this._meta?.absenceChecking) {
      filter.push({
        label: t('general.absenceCheckMissing'),
        value: this._absenceCheckMissingFilter,
        onChange: (value) => this.onAbsenceCheckMissingFilterChange(value),
      });
    }
    return filter;
  }

  @action.bound
  onTeachingContentMissingFilterChange(value: boolean) {
    if (value) {
      this._absenceCheckMissingFilter = false;
    }
    this._teachingContentMissingFilter = value;
  }

  @action.bound
  onAbsenceCheckMissingFilterChange(value: boolean) {
    if (value) {
      this._teachingContentMissingFilter = false;
    }
    this._absenceCheckMissingFilter = value;
  }

  @computed
  get selectedTeacher(): string | undefined {
    return this.selectedOptions.find((o) => o.category === 'teacher')?.id;
  }

  @computed
  get textSelectOptions(): ITextSelectOption[] {
    const options: ITextSelectOption[] = [];

    if (this._meta?.teacherLessons) {
      const currentTeacher: string | undefined = this.selectedTeacher;
      let lessons = this._meta.teacherLessons;
      if (currentTeacher) {
        lessons = lessons.filter((lesson) => lesson.teachers.map((t) => t.id.toString()).includes(currentTeacher));
      }

      const optionsByLessonId = new Map<string, ITextSelectOption>();
      const optionsByClassId = new Map<string, { lessonCount: number; classValue: ITextSelectOption }>();

      // iterate through all lessons and initialize the map for each class
      lessons.forEach((lesson) => {
        lesson.classes.forEach((c) => {
          if (!optionsByClassId.get(c.id.toString())) {
            optionsByClassId.set(c.id.toString(), {
              lessonCount: 0,
              classValue: { id: c.id.toString(), label: c.name },
            });
          }
        });
      });

      lessons.forEach((lesson) => {
        optionsByLessonId.set(lesson.lessonId.toString(), {
          id: lesson.lessonId.toString(),
          label: [lesson.classes.map((c) => c.name).join(', '), lesson.subject.name],
        });

        // count lessons in classes
        lesson.classes.forEach((c) => {
          const entry = optionsByClassId.get(c.id.toString());
          if (entry) {
            entry.lessonCount += 1;
          }
        });
      });
      options.push(...optionsByLessonId.values());

      // if a class has more than one lesson, an option for that class is added
      optionsByClassId.forEach((value) => {
        if (value.lessonCount > 1) {
          options.push(value.classValue);
        }
      });
    }

    const sortedOptions: ITextSelectOption[] = options.sort((a, b) => (a.label > b.label ? 1 : -1));
    sortedOptions.unshift({
      label: t('general.allLessons'),
      id: '-1',
    });

    return sortedOptions;
  }

  @computed
  get selectedFreeTextOptions(): string[] {
    return this._selectedFreeTextOptions;
  }

  @action
  setSelectedFreeTextSearchOptions(value: string[]) {
    this._selectedFreeTextOptions = value;
    this.handleSearchBarOptionsChanged;
  }

  @computed
  get selectedOptions(): ISearchBarOption[] {
    return this._selectedOptions;
  }

  @action
  setSelectedOptions(value: ISearchBarOption[]) {
    this._selectedOptions = value;
  }

  @computed
  get searchBarOptions(): ISearchBarOption[] {
    const options: ISearchBarOption[] = [];

    if (this._meta?.teachers) {
      this._meta.teachers.forEach((teacher) => {
        options.push({
          label: teacher.name,
          id: teacher.id.toString(),
          category: this._localizedTeacher,
        });
      });
    }

    if (this._meta?.classes) {
      this._meta.classes.forEach((clazz) => {
        options.push({
          label: clazz.name,
          id: clazz.id.toString(),
          category: this._localizedClass,
        });
      });
    }

    if (this._meta?.subjects) {
      this._meta.subjects.forEach((subject) => {
        options.push({
          label: subject.name,
          id: subject.id.toString(),
          category: this._localizedSubject,
        });
      });
    }

    return options;
  }

  @action
  handleSearchBarOptionsChanged = (options: ISearchBarOption[]) => {
    const oldTeacherOption = this.selectedOptions.find((option) => option.category === this._localizedTeacher);
    const oldClassOption = this.selectedOptions.find((option) => option.category === this._localizedClass);
    const newTeacherOption = options.find((option) => option.category === this._localizedTeacher);
    const newClassOption = options.find((option) => option.category === this._localizedClass);
    this.setSelectedOptions(options);

    const scrollableElement = document.getElementById('class-register-overview-page');
    if (scrollableElement) {
      scrollableElement.scrollTo({ top: 100 });
    }

    // only refetch the data if a class oder a teacher filter changed
    if (newTeacherOption === oldTeacherOption && newClassOption === oldClassOption) {
      return;
    }

    const classId: string | undefined = this.selectedOptions.find(
      (option) => option.category === this._localizedClass,
    )?.id;

    const teacherId: string | undefined = this.selectedOptions.find(
      (option) => option.category === this._localizedTeacher,
    )?.id;

    if (!teacherId && !classId) {
      return;
    }

    this._inProgress = true;
    const fetchDataResult = this.fetchData(this._currentDate, dayjs(this._currentDate).add(this.daysToFetch, 'days'));

    if (fetchDataResult) {
      fetchDataResult.then((response) => {
        this.mergeFetchedData(response, true);
        this._minFetchedDate = this._currentDate;
        this._maxFetchedDate = dayjs(this._currentDate).add(this.daysToFetch, 'days');
        this._inProgress = false;
      });
    } else {
      this._inProgress = false;
    }
  };

  @action
  handleOptionChange = (option: ITextSelectOption) => {
    this._selectedOption = option.id;
  };

  @computed
  get selectedOptionId(): string {
    return this._selectedOption;
  }

  @computed
  get showHint(): boolean {
    const isATeacherSelected = this.selectedOptions.some((option) => option.category === this._localizedTeacher);
    const isAClassSelected = this.selectedOptions.some((option) => option.category === this._localizedClass);
    return !isATeacherSelected && !isAClassSelected;
  }

  @computed
  get isScrollingDisabled(): boolean {
    return this.showHint || this.isOutsideOfSchoolyear || this.isOutsideOfAllowedDateRange;
  }

  private getEmptyIndicatorLabel(
    isInProgress: boolean,
    isOutsideOfSchoolyear: boolean,
    isOutsideOfAllowedDateRange: boolean,
    showHint: boolean,
  ): string | undefined {
    if (isInProgress) {
      return t('general.loading');
    }
    if (isOutsideOfSchoolyear) {
      return t('general.youAreNotInASchoolyear');
    }
    if (isOutsideOfAllowedDateRange) {
      return t('general.lessonsCanNotBeDisplayedForDateRange');
    }
    if (showHint) {
      return t('general.searchAtLeastOneTeacherOrClass');
    }
    return undefined;
  }

  @computed
  get emptyIndicatorLabel(): string | undefined {
    return this.getEmptyIndicatorLabel(
      this.isInProgress,
      this.isOutsideOfSchoolyear,
      this.isOutsideOfAllowedDateRange,
      this.showHint,
    );
  }

  @computed get reachedStartOfSchoolyear(): boolean {
    return this._minFetchedDate.isSameOrBefore(this._schoolyearStartDate, 'date');
  }

  @computed get reachedEndOfSchoolyear(): boolean {
    return this._maxFetchedDate.isSameOrAfter(this._schoolyearEndDate, 'date');
  }

  @computed get reachedAllowedStartDate(): boolean {
    return this._minFetchedDate.isSameOrBefore(this._allowedStartDate, 'date');
  }

  @computed get reachedAllowedEndDate(): boolean {
    return this._maxFetchedDate.isSameOrAfter(this._allowedEndDate, 'date');
  }

  resolveHomeworkById = (id: number): string => {
    const homework = this._homeworks[id];
    return homework ? homework.homework : id.toString();
  };

  resolveAbsenceById = (id: number): string => {
    const absence = this._absences[id];
    return absence ? absence.studentName : id.toString();
  };

  resolveAbsencesStats = (
    periodAbsences: ClassRegOverviewPeriodAbsenceV2Dto[],
  ): Omit<Required<IPeriodRowData>['absences'], 'isChecked'> => {
    return periodAbsences.reduce(
      (result, periodAbsence) => ({
        excused: result.excused + (periodAbsence.excused ? 1 : 0),
        total: result.total + 1,
      }),
      {
        excused: 0,
        total: 0,
      },
    );
  };

  @action
  saveOrUpdateHomework(homework: ClassRegOverviewHomeworkV2Dto, homeworkLessonId: number) {
    if (!homework || !homework.id) return;
    this._homeworks = Object.assign({}, this._homeworks, { [homework.id.toString()]: homework });
    this._periods.forEach((period) => {
      if (period.lsId === homeworkLessonId && period.date >= homework.date && period.date <= homework.dueDate) {
        period.hwIds.indexOf(homework.id) === -1 && period.hwIds.push(homework.id);
      } else if (period.hwIds.indexOf(homework.id) > -1) {
        period.hwIds = period.hwIds.filter((currentId) => currentId !== homework.id);
      }
    });
  }

  @action
  removeHomework(id: number) {
    this._periods.forEach((period) => {
      if (period.hwIds.indexOf(id) > -1) {
        period.hwIds = period.hwIds.filter((currentId) => currentId !== id);
      }
    });
    delete this._homeworks[id.toString()];
  }

  @action
  updateLessonTopic(periodId: number, lessonTopic: string) {
    const period = periodId && this._periods.get(periodId);
    if (period) period.topic = lessonTopic;
  }

  // Updating absences in the store as a reaction to the changes in old frontend (iframe).
  @action
  updateAbsencesInStore(modifiedAbsences: Array<IAbsence>) {
    modifiedAbsences &&
      modifiedAbsences.forEach((abs) => {
        this._absences = Object.assign({}, this._absences, {
          [abs.id.toString()]: {
            id: abs.id,
            studentId: abs.person.id,
            studentName: abs.person.displayName,
            excused: abs.excuseStatus.isExcused,
          },
        });
        // Dealing with Subject Teacher (Period) excuses:
        // (Ideally, such logic should not be handled on the frontend side.)
        // (Reimplementing old CR Page in the new frontend should help to get rid of this.)
        this._periods.forEach((period) => {
          if (period.stIds.indexOf(abs.person.id) > -1 && this.isAbsenceOverlapping(abs, period)) {
            const periodStAbsence = period.stAbsences.find((periodAbsence) => periodAbsence.absenceId === abs.id);
            if (periodStAbsence) {
              // absence's excuse status needs to be updated in case it is changed automatically
              // (e.g. by setting the particular absence reason on absence edit) and  absence is no more open
              !abs.excuseStatus.isOpen && (periodStAbsence.excused = abs.excuseStatus.isExcused);
            } else {
              // if an absence starts overlapping the period now, then period-absence association needs to be added
              period.stAbsences.push({ absenceId: abs.id, excused: abs.excuseStatus.isExcused });
            }
          } else if (period.stAbsences.some((periodAbsence) => periodAbsence.absenceId === abs.id)) {
            // if absence does not overlap period anymore, then period-absence association needs to be removed
            period.stAbsences = period.stAbsences.filter((periodAbsence) => periodAbsence.absenceId !== abs.id);
          }
        });
      });
  }

  // Removing absences from the store as a reaction to the changes in old frontend (iframe).
  @action
  removeAbsencesFromStore(ids: Array<number>) {
    ids &&
      ids.forEach((absId) => {
        this._periods.forEach((period) => {
          if (period.stAbsences.some((periodAbsence) => periodAbsence.absenceId === absId)) {
            period.stAbsences = period.stAbsences.filter((periodAbsence) => periodAbsence.absenceId !== absId);
          }
        });
        delete this._absences[absId.toString()];
      });
  }

  @action
  updateAbsencesChecked(periodId: number, isAbsencesChecked: boolean) {
    const period = periodId && this._periods.get(periodId);
    if (period) period.absChecked = isAbsencesChecked;
  }

  private isAbsenceOverlapping(absence: IAbsence, period: ClassRegOverviewPeriodV2Dto): boolean {
    return (
      // student attending period partially is not considered as absent
      (absence.startDate < period.date ||
        (absence.startDate === period.date && absence.startTime <= period.startTime)) &&
      (absence.endDate > period.date || (absence.endDate === period.date && absence.endTime >= period.endTime)) &&
      (!absence.interruptions ||
        absence.interruptions.length === 0 ||
        !absence.interruptions.find(
          (interruption) =>
            (interruption.startDate < period.date ||
              (interruption.startDate === period.date && interruption.startTime < period.endTime)) &&
            (interruption.endDate > period.date ||
              (interruption.endDate === period.date && interruption.endTime > period.startTime)),
        ))
    );
  }
}
