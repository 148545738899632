import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';

import { MessagesOfTheDayStore, translateDislayAreaEnum } from './messages-of-day-store';

import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import {
  DeprecatedFormDropdown,
  FormColorPicker,
  FormDatePicker,
  FormIconPicker,
  FormInput,
  FormMultiTagSelect,
  FormSection,
  FormSwitch,
  IFormButton,
  WUForm,
  WUFormCol,
  WUFormRow,
} from '@/ui-components/wu-form';
import { createMultiSelectAddHandler, validateForm } from '@/utils/form/form-util';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { FormWysiwyg } from '@/ui-components/wu-form/form-wysiwyg/form-wysiwyg';
import {
  DayToShow,
  DisplayAreas,
  FileDescriptorDtoV2,
  FileDescriptorDtoV2StorageProviderEnum,
  MessageOfDayAttachmentDto,
  MessageOfDayDto,
  MessagesOfDaySaveRequestDto,
  MessagesOfDayUpdateRequestDto,
} from '@untis/wu-rest-view-api';
import { TestIds } from '@/testIds';
import { TextValidationPatterns } from '@/utils/form/common-form-rules';
import { FormOneDriveAttachments } from '@/components/attachments/one-drive-attachments';

interface IMessageOfDayFormProps {
  store: MessagesOfTheDayStore;
  messageOfDay?: MessageOfDayDto;
}

export interface IMessagesOfDayFormMessage {
  startDate: Dayjs;
  endDate: Dayjs;
  subject: string;
  content: string;
  dayToShow: DayToShow;
  public: boolean;
  userGroupFilters: IMultiTagSelectItem[];
  departmentFilters: IMultiTagSelectItem[];
  displayAreas: IMultiTagSelectItem[];
  orderNo: number;
  attachments?: FileDescriptorDtoV2[];
  headerColor: string;
  subtitle: string;
  icon: string;
}

const colors = [
  '#ffa94d',
  '#ffd43b',
  '#9ccb61',
  '#36c98c',
  '#36bac9',
  '#4b9fd8',
  '#4e9abf',
  '#837be4',
  '#d47aeb',
  '#f781aa',
  '#ff8787',
  '#b27f61',
  '#67747e',
];

export const MessagesOfDayForm = observer(({ store, messageOfDay }: IMessageOfDayFormProps) => {
  const { t } = useTranslation();
  const modalStore = useStore(ModalStore);
  const [disabledAfterValidaton, setDisabledAfterValidation] = useState(!!messageOfDay?.publicAccess);

  const isInEditMode = !!messageOfDay;
  const disabled = isInEditMode && !store.canEdit;
  const rightButtons: IFormButton[] = [
    {
      label: disabled ? t('general.ok') : t('general.cancel'),
      onClick: () => {
        store.onFormCancel();
      },
      testId: TestIds.MESSAGES_OF_DAY_FORM_CANCEL_BUTTON,
    },
  ];
  const leftButtons: IFormButton[] = useMemo(() => {
    if (isInEditMode) {
      return [
        {
          label: t('general.delete'),
          onClick: () => {
            if (messageOfDay?.id) {
              store.deleteMessageOfDay(messageOfDay.id);
            }
          },
          testId: 'delete-button',
        },
      ];
    }

    return [];
  }, [isInEditMode, t, messageOfDay, store]);

  if (!isInEditMode) {
    rightButtons.unshift({
      label: t('general.saveAndNew'),
      type: 'primary',
      disabled: store.submitAllDisabled,
      outline: false,
      onClick: () => {
        validateForm(store.form).then((isValid) => {
          if (isValid) {
            handleSaveNewMessageOfDay(store.form.getFieldsValue(), true);
          }
        });
      },
      testId: TestIds.MESSAGES_OF_DAY_FORM_SAVE_AND_NEW_BUTTON,
    });
  }

  const formMessagesOfDayToUpdateDto = useCallback(
    (value: IMessagesOfDayFormMessage): MessagesOfDayUpdateRequestDto => {
      return {
        ...getCommonDtoFields(value),
        lastUpdate: messageOfDay?.lastUpdate ?? -1,
      };
    },
    [messageOfDay?.lastUpdate],
  );

  const handleChangeMessageOfDay = useCallback(
    (value: IMessagesOfDayFormMessage) => {
      store.saveUpdatedMessageOfDay(messageOfDay?.id ?? -1, formMessagesOfDayToUpdateDto(value));
    },
    [formMessagesOfDayToUpdateDto, messageOfDay?.id, store],
  );

  const formMessagesOfDayToSaveDto = useCallback((value: IMessagesOfDayFormMessage): MessagesOfDaySaveRequestDto => {
    return getCommonDtoFields(value);
  }, []);

  const handleSaveNewMessageOfDay = useCallback(
    (value: IMessagesOfDayFormMessage, saveAndNew: boolean) => {
      store.saveNewMessageOfDay(formMessagesOfDayToSaveDto(value), saveAndNew);
    },
    [formMessagesOfDayToSaveDto, store],
  );

  function getCommonDtoFields(value: IMessagesOfDayFormMessage): MessagesOfDaySaveRequestDto {
    const displayAreas: DisplayAreas[] = value.displayAreas.map((item) => {
      return DisplayAreas[item.id as keyof typeof DisplayAreas];
    });

    const oneDriveAttachments: MessageOfDayAttachmentDto[] =
      value?.attachments?.map((att) => {
        return {
          id: '0', // unused according to comment in API
          driveId: att.storageId,
          name: att.name,
          downloadUrl: att.downloadUrl ?? '',
        };
      }) ?? [];

    return {
      startDate: value.startDate.format('YYYY-MM-DDTHH:mm:ss'),
      endDate: value.endDate.format('YYYY-MM-DDTHH:mm:ss'),
      subject: value.subject,
      subtitle: value.subtitle,
      content: value.content,
      dayToShow: value.dayToShow,
      publicAccess: value.public,
      userGroupFilters: value.userGroupFilters.map((item) => Number(item.id)),
      departmentFilters: value.departmentFilters.map((item) => Number(item.id)),
      displayAreas,
      orderNo: value.orderNo,
      oneDriveAttachments,
      headerColor: value.headerColor.replaceAll('#', ''),
      icon: value.icon,
    };
  }

  const initialHeaderColor = useMemo(
    () => (messageOfDay?.headerColor ? `#${messageOfDay.headerColor}` : colors[0]),
    [messageOfDay?.headerColor],
  );

  const handleOnSubmit = useCallback(
    (value: IMessagesOfDayFormMessage) => {
      if (isInEditMode) {
        handleChangeMessageOfDay(value);
        return;
      }

      handleSaveNewMessageOfDay(value, false);
    },
    [isInEditMode, handleChangeMessageOfDay, handleSaveNewMessageOfDay],
  );

  const oneDriveAttachmentsFromDto = (dto: MessageOfDayDto): FileDescriptorDtoV2[] => {
    if (!dto.oneDriveAttachments) {
      return [];
    }

    const attachments = dto.oneDriveAttachments.map((att) => {
      return {
        storageProvider: FileDescriptorDtoV2StorageProviderEnum.ONEDRIVE,
        storageId: att.id,
        name: att.name,
        downloadUrl: att.downloadUrl,
      };
    });

    return attachments;
  };

  return (
    <WUForm<IMessagesOfDayFormMessage>
      form={store.form}
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      fixedButtonBar={true}
      hideSubmitButton={disabled}
      onDisableChange={(disabled: boolean) => {
        store.setSubmitAllDisabled(disabled);
      }}
      onSubmit={handleOnSubmit}
      maxWidth
      testIdSaveButton={TestIds.MESSAGES_OF_DAY_FORM_SAVE_BUTTON}
      noWrappingRow
      paddingTop
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={3}>
            <FormSection text={t('general.motdFormGroupMessage')} />
          </WUFormCol>

          <WUFormCol lg={1.5}>
            <WUFormRow noMarginBottom>
              <WUFormCol preventBreak lg={6} removePadding>
                <FormIconPicker
                  label={t('general.icon')}
                  name="icon"
                  initialValue={isInEditMode ? messageOfDay?.icon : store.icons[0].icon}
                  disabled={disabled}
                  rules={[{ required: true, message: t('general.inputRequired') }]}
                  iconPickerProps={{
                    icons: store.icons,
                    translations: {
                      heading: t('general.icon', { count: 2 }),
                      triggerTooltip: t('general.pickAnIcon'),
                    },
                    testId: TestIds.MESSAGES_OF_DAY_ICON_PICKER,
                  }}
                />
              </WUFormCol>
              <WUFormCol preventBreak lg={21} removePadding flex={1}>
                <FormColorPicker
                  name="headerColor"
                  initialValue={isInEditMode ? initialHeaderColor : colors[0]}
                  disabled={disabled}
                  rules={[{ required: true, message: t('general.inputRequired') }]}
                  variant="circle"
                  colors={colors}
                  label={t('general.motdHeaderColor')}
                />
              </WUFormCol>
            </WUFormRow>
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3} />

          <WUFormCol lg={1.5}>
            <FormInput
              label={t('general.motdSubject')}
              variant="floating"
              name="subject"
              initialValue={isInEditMode ? messageOfDay?.subject ?? '' : ''}
              disabled={disabled}
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              rules={[
                { required: true, message: t('general.inputRequired') },
                { max: 255, message: t('general.maxLength', { name: t('general.motdSubject'), charCount: 255 }) },
                {
                  // updates the modal title
                  validator(rule, subject) {
                    store.setModalTitle(subject);
                    return Promise.resolve();
                  },
                },
              ]}
              testId={TestIds.MESSAGES_OF_DAY_FORM_TITLE_INPUT}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3} />
          <WUFormCol lg={1.5}>
            <FormInput
              label={t('general.motdSubtitle')}
              variant="floating"
              name="subtitle"
              initialValue={isInEditMode ? messageOfDay?.subtitle ?? '' : ''}
              disabled={disabled}
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              rules={[
                { max: 200, message: t('general.maxLength', { name: t('general.motdSubtitle'), charCount: 200 }) },
              ]}
              testId={TestIds.MESSAGES_OF_DAY_FORM_SUBTITLE_INPUT}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3} />
          <WUFormCol lg={1.5}>
            <FormWysiwyg
              label={t('general.motdContent')}
              name="content"
              output="markdown"
              initialValue={isInEditMode ? messageOfDay?.content ?? '' : ''}
              disabled={disabled}
              rules={[{ required: true, message: t('general.inputRequired') }]}
              testId={TestIds.MESSAGES_OF_DAY_FORM_CONTENT_INPUT}
            />
          </WUFormCol>
        </WUFormRow>

        {store.hasOneDrive && (
          <WUFormRow>
            <WUFormCol lg={3} />
            <WUFormCol lg={1.5}>
              <FormOneDriveAttachments
                name="attachments"
                initialValue={isInEditMode ? oneDriveAttachmentsFromDto(messageOfDay) : []}
                testId={TestIds.MESSAGES_OF_DAY_FORM_ATTACHMENTS}
              />
            </WUFormCol>
          </WUFormRow>
        )}

        <WUFormRow>
          <WUFormCol lg={3}>
            <FormSection text={t('general.motdFormGroupWhenToShow')} />
          </WUFormCol>
          <WUFormCol lg={1.5}>
            <DeprecatedFormDropdown
              label={t('general.motdDayOfTheWeek')}
              name="dayToShow"
              initialValue={
                isInEditMode ? DayToShow[messageOfDay?.dayToShow ?? DayToShow.EVERY_DAY] : DayToShow.EVERY_DAY
              }
              items={store.daysToShowDropdownItems}
              disabled={disabled}
              rules={[{ required: true, message: t('general.inputRequired') }]}
              testId={TestIds.MESSAGES_OF_DAY_FORM_DAY_OF_WEEK_DROPDOWN}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3} />
          <WUFormCol lg={1.5}>
            <WUFormRow>
              <WUFormCol lg={2} removePadding>
                <FormDatePicker
                  label={t('general.motdFrom')}
                  name="startDate"
                  initialValue={isInEditMode ? dayjs(messageOfDay?.startDate) : dayjs()}
                  rules={[
                    { required: true, message: t('general.inputRequired') },
                    {
                      validator(rule, startDate) {
                        const { endDate } = store.form.getFieldsValue();
                        if (endDate.isBefore(startDate)) {
                          store.form.setFieldsValue({ endDate: startDate.clone() });
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  disabled={disabled}
                  testId={TestIds.MESSAGES_OF_DAY_FORM_STARTDATE}
                />
              </WUFormCol>
              <WUFormCol lg={2} lastNestedColumn removePadding>
                <FormDatePicker
                  label={t('general.motdTill')}
                  name="endDate"
                  initialValue={isInEditMode ? dayjs(messageOfDay?.endDate) : dayjs()}
                  dependencies={['startDate']}
                  rules={[{ required: true, message: t('general.inputRequired') }]}
                  disabled={disabled}
                  disabledDate={(current) => current.isBefore(store.form.getFieldValue('startDate'))}
                  testId={TestIds.MESSAGES_OF_DAY_FORM_ENDDATE}
                />
              </WUFormCol>
            </WUFormRow>
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3}>
            <FormSection text={t('general.motdFormGroupWhereToShow')} />
          </WUFormCol>
          <WUFormCol lg={1.5}>
            <FormMultiTagSelect
              label={t('general.motdDisplayArea')}
              name="displayAreas"
              removable
              onAdd={createMultiSelectAddHandler(
                store.form,
                'displayAreas',
                store.displayAreasSelectItems,
                t('general.motdDisplayArea'),
                modalStore,
              )}
              initialValue={
                isInEditMode
                  ? messageOfDay?.displayAreas?.map((da) => ({
                      id: da,
                      label: translateDislayAreaEnum(da),
                    })) ?? []
                  : []
              }
              placeholder={t('general.add')}
              disabled={disabled}
              rules={[{ required: true, message: t('general.inputRequired') }]}
              addButtonTestId={TestIds.MESSAGES_OF_DAY_FORM_DISPLAY_AREA_MULTI_SELECT_BUTTON}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3} />
          <WUFormCol lg={1.5}>
            <FormInput
              label={t('general.orderNo')}
              name="orderNo"
              initialValue={isInEditMode ? String(messageOfDay?.orderNo ?? 10) : '10'}
              disabled={disabled}
              type="number"
              rules={[{ max: 2147483647, type: 'number', transform: (value) => Number(value) }]}
              testId={TestIds.MESSAGES_OF_DAY_FORM_POSITION_INPUT}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3}>
            <FormSection
              noMargin
              text={t('general.motdFormGroupWhoCanSee')}
              description={t('general.motdFormGroupWhoCanSeeInfo')}
            />
          </WUFormCol>
          <WUFormCol lg={1.5}>
            <FormSwitch
              label={t('general.public')}
              initialValue={isInEditMode ? messageOfDay?.publicAccess ?? false : false}
              name="public"
              rules={[
                { required: true, message: t('general.inputRequired') },
                {
                  validator(rule, value) {
                    if (value) {
                      store.form.setFieldsValue({ userGroupFilters: [], departmentFilters: [] });
                      setDisabledAfterValidation(true);
                    } else {
                      setDisabledAfterValidation(false);
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              testId={TestIds.MESSAGES_OF_DAY_FORM_PUBLIC_TOGGLE}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3} />
          <WUFormCol lg={1.5}>
            <FormMultiTagSelect
              label={t('general.userGroup')}
              name="userGroupFilters"
              removable
              onAdd={createMultiSelectAddHandler(
                store.form,
                'userGroupFilters',
                store.userGroupFiltersSelectItems,
                t('general.userGroup'),
                modalStore,
              )}
              initialValue={
                isInEditMode
                  ? messageOfDay?.userGroupFilters
                      ?.map((ugf) => store.getUserGroupMultiTagSelectItem(String(ugf)))
                      .filter(store.isMultiTagSelectItem) ?? []
                  : []
              }
              placeholder={t('general.add')}
              disabled={disabledAfterValidaton || disabled}
              addButtonTestId={TestIds.MESSAGES_OF_DAY_FORM_USER_GROUP_MULTI_SELECT_BUTTON}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3} />
          <WUFormCol lg={1.5}>
            <FormMultiTagSelect
              label={t('general.department')}
              name="departmentFilters"
              removable
              onAdd={createMultiSelectAddHandler(
                store.form,
                'departmentFilters',
                store.departmentFiltersSelectItems,
                t('general.department'),
                modalStore,
              )}
              initialValue={
                isInEditMode
                  ? messageOfDay?.departmentFilters
                      ?.map((df) => store.getDepartmentMultiTagSelectItem(String(df)))
                      .filter(store.isMultiTagSelectItem) ?? []
                  : []
              }
              placeholder={t('general.add')}
              disabled={disabledAfterValidaton || disabled}
              addButtonTestId={TestIds.MESSAGES_OF_DAY_FORM_DEPARTMENT_MULTI_SELECT_BUTTON}
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});
