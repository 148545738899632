import React, { useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker as AntDatePicker } from 'antd';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@/ui-components';
import { format, getPickerLocaleFromDayjs } from '@/ui-components/date/date-picker/date-picker';

import './deprecated-date-picker.less';

export interface IDeprecatedDatePickerProps {
  value: Dayjs;
  disabled?: boolean;
  onChange?: (date: Dayjs, changedByArrowButton: boolean) => void;
  hide?: boolean;
  arrowButtonsUnit?: 'week'; // maybe we want to introduce also 'DAY' or 'MONTH' in the future
  disabledDate?: (date: Dayjs) => boolean;
}

/**
 * Deprecated in favour of <DatePicker />
 *  Component that renders a date as text and opens a calendar on click to choose another date.
 *  Uses (or wraps) the AntDesign DatePicker, with adaptions to css and some tricks to match
 *  the desired designs.
 *  The selection of time is not considered in this component.
 */
export const DeprecatedDatePicker = (props: IDeprecatedDatePickerProps) => {
  const [dateString, setDateString] = useState(() => props.value.format(format));
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const [timeOutCallback, setTimeOutCallback] = useState<any | undefined>(undefined);
  const popupRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  // Update the label string when a new value comes in
  useEffect(() => {
    setDateString(props.value.format(format));
  }, [props.value]);

  const onChange = (date: Dayjs | null, changedByArrowButton: boolean) => {
    if (date) {
      setDateString(date.format(format));
      props.onChange && props.onChange(date, changedByArrowButton);
    }
  };

  const getAriaLabelForButton = (button: 'previous' | 'next'): string => {
    switch (props.arrowButtonsUnit) {
      case 'week':
        return button === 'previous' ? t('general.previousWeek') : t('general.nextWeek');
      default:
        return '';
    }
  };

  // is called whenever the AntDesign DatePicker wants to open/close the calendar
  // e.g. "outside clicks", selection of dates, enter key,...
  // we only want it to close the calendar. only a click on the label should open it.
  const handleOnOpenChange = (status: boolean) => {
    if (!status) {
      close();
    }
  };

  const handleClickNext = () => {
    const unit = props.arrowButtonsUnit;
    onChange(dayjs(props.value).add(1, unit), true);
  };

  const handleClickPrevious = () => {
    const unit = props.arrowButtonsUnit;
    onChange(dayjs(props.value).subtract(1, unit), true);
  };

  const checkIfNextDisabled = () => {
    const unit = props.arrowButtonsUnit;
    const nextDate = dayjs(props.value).add(1, unit);
    return props.disabledDate && props.disabledDate(nextDate);
  };

  const checkifPreviousDisabled = () => {
    const unit = props.arrowButtonsUnit;
    const previousDate = dayjs(props.value).subtract(1, unit);
    return props.disabledDate && props.disabledDate(previousDate);
  };

  const close = () => {
    setIsInEditMode(false);
    const callback = setTimeout(() => setIsClosed(true), 250);
    setTimeOutCallback(callback);
    if (timeOutCallback) {
      clearTimeout(timeOutCallback);
      setTimeOutCallback(undefined);
    }
  };

  const open = () => {
    if (!props.disabled) {
      setIsClosed(false);
      setIsInEditMode(true);
    }
  };

  const datePickerClass = props.hide ? 'deprecated-untis-date-picker hidden' : 'deprecated-untis-date-picker';
  const labelClass = props.disabled ? 'date-label disabled' : 'date-label';
  const containerHelperClass = isClosed ? 'container-helper-closed' : 'container-helper-open';

  const locale = getPickerLocaleFromDayjs(props.value);

  const dateTimePicker = (
    <div className={datePickerClass}>
      <span className={labelClass} onClick={open} data-testid="date-picker-label">
        {dateString}
      </span>
      <div className={'container-helper ' + containerHelperClass}>
        <AntDatePicker
          value={props.value}
          open={isInEditMode}
          onOpenChange={handleOnOpenChange}
          onChange={(val) => val && onChange(val, false)}
          allowClear={false}
          disabledDate={props.disabledDate}
          locale={locale}
          format={locale?.lang.locale.startsWith('de') ? 'DD.MM.YYYY' : undefined}
          getPopupContainer={(node: HTMLElement) => node.parentElement!}
        />
        <div ref={popupRef} className="deprecated-untis-date-picker-popup" />
      </div>
    </div>
  );

  if (props.arrowButtonsUnit) {
    return (
      <div className="arrow-wrapper">
        <IconButton
          type="arrow-left"
          size="sm"
          onClick={handleClickPrevious}
          className="arrow arrow-left"
          ariaLabel={getAriaLabelForButton('previous')}
          disabled={checkifPreviousDisabled()}
        />
        {dateTimePicker}
        <IconButton
          type="arrow-right"
          size="sm"
          onClick={handleClickNext}
          className="arrow arrow-right"
          ariaLabel={getAriaLabelForButton('next')}
          disabled={checkIfNextDisabled()}
        />
      </div>
    );
  }

  return dateTimePicker;
};

export default DeprecatedDatePicker;
