import React from 'react';
import { ReactSVG } from 'react-svg';

import { ITestComponentProps } from '@/types/test-component-props';

import './icon.less';

// TODO: make use webpack svg-inline-loader to unpack SVGs
export interface IIconProps extends ITestComponentProps {
  type?: string;
  className?: string;
  onClick?: (event: React.BaseSyntheticEvent) => void;
  style?: React.CSSProperties;
  title?: string;
  path?: string;
  tabIndex?: number;
  subdir?: string;
  png?: boolean;
}

const assetsPublicPathPrefix = process.env.REACT_APP_ASSETS_PUBLIC_PATH_PREFIX ?? '/assets';

export const Icon = (props: IIconProps) => {
  const { type, className, testId, path, subdir, png, ...other } = props;
  const src = `${path ?? `${assetsPublicPathPrefix}/icons/${subdir ? `${subdir}/` : ''}`}${type}${
    png ? '.png' : '.svg'
  }`;

  const classNameWithType = `untis-icon untis-icon-${type} ${className ? className : ''}`;

  return (
    <i {...other} className={classNameWithType} data-testid={testId}>
      {png ? <img src={src} alt={type} /> : <ReactSVG src={src} />}
    </i>
  );
};

export default Icon;
