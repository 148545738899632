import { message } from 'antd';
import { t } from 'i18next';

import HttpStatusCode from '@/types/http-status-code';
import { getResponseError, isValidationErrors } from '@/utils/error-handling/error-handling';

export enum ErrorMessageEnum {
  ERROR = 'general.errors.error',
  BAD_REQUEST = 'general.httpBadRequest',
  UNAUTHORIZED = 'general.httpUnauthorized',
  FORBIDDEN = 'general.httpForbidden',
  NOT_FOUND = 'general.httpNotFound',
  REQUEST_ENTITY_TOO_LARGE = 'general.httpRequestEntityTooLarge',
}

export const showError = (errorType: ErrorMessageEnum) => {
  message.error(t(errorType));
};

export const showErrorMessage = (msg: string) => {
  message.error(msg);
};

export const showErrorResponse = (error: Error) => {
  console.error(error);

  const responseError = getResponseError(error);

  if (isValidationErrors(responseError.payload)) {
    showErrorMessage(responseError.payload[0].errorMessage);
    return;
  }

  showErrorMessage(responseError.payload);
};

export const httpStatusCodeToErrorMessageMapping: Map<HttpStatusCode, string> = new Map([
  [HttpStatusCode.BAD_REQUEST, ErrorMessageEnum.BAD_REQUEST],
  [HttpStatusCode.UNAUTHORIZED, ErrorMessageEnum.UNAUTHORIZED],
  [HttpStatusCode.FORBIDDEN, ErrorMessageEnum.FORBIDDEN],
  [HttpStatusCode.NOT_FOUND, ErrorMessageEnum.NOT_FOUND],
  [HttpStatusCode.REQUEST_ENTITY_TOO_LARGE, ErrorMessageEnum.REQUEST_ENTITY_TOO_LARGE],
  [HttpStatusCode.INTERNAL_SERVER_ERROR, ErrorMessageEnum.ERROR],
]);
