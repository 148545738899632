import { Tooltip as AntdTooltip } from 'antd';
import { TooltipProps } from 'antd/es/tooltip';
import React from 'react';
import clsx from 'clsx';

import './tooltip.less';

const Tooltip = (props: TooltipProps) => {
  return <AntdTooltip {...props} className={clsx('tooltip', props.className)} />;
};

export default Tooltip;
