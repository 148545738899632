import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { ListView } from '@/components/list-view/list-view';
import { BuildingViewApi } from '@/stores/api-store';
import { IBuildingFormBuilding } from '@/pages/master-data/building/building-form';
import { BuildingStore, IBuildingRow } from '@/pages/master-data/building/building-store';
import { TestIds } from '@/testIds';

export const MasterDataBuilding = observer(() => {
  const [form] = Form.useForm<IBuildingFormBuilding>();
  const [store] = useState(() => new BuildingStore(form));
  const { t } = useTranslation();

  return (
    <ListView<IBuildingRow>
      title={t('menu.menuItems.masterData.subMenuItems.buildings')}
      isPageLoading={store.isDataLoading}
      compact
      deprecatedSearchBar={{
        value: store.searchInputValue,
        onChange: (value) => store.setSearchInputValue(value),
        color: 'white',
        placeholder: t('general.search'),
      }}
      table={{
        columns: store.columns,
        rowData: store.filteredRows,
        rowSelection: {
          selectedRowKeys: store.selectedRowKeys,
          type: 'checkbox',
          onChange: (keys) => store.setSelectedRowKeys(keys),
        },
        shadow: true,
      }}
      actions={store.listViewActions}
      selectedRowsActions={store.selectedRowsActions}
      customSorting={
        store.canEdit
          ? {
              onSave: (order) => {
                BuildingViewApi.createCustomOrderForBuildings(order).then(() => {
                  store.fetchBuildings();
                });
              },
              onDelete: () => {
                BuildingViewApi.deleteCustomOrderForBuildings().then(() => {
                  store.fetchBuildings();
                });
              },
            }
          : undefined
      }
      testId={TestIds.BUILDING_LIST}
    />
  );
});
