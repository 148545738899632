import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { t } from 'i18next';

import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import ModalStore from '@/stores/modal-store';
import useStore from '@/hooks/useStore';
import { ReasonsOfAbsenceStore } from '@/pages/master-data/reasons-of-absence/reasons-of-absence-store';
import { ReasonsOfAbsenceForm } from '@/pages/master-data/reasons-of-absence/reasons-of-absence-form';
import RouterStore from '@/stores/router-store';

export const ReasonsOfAbsenceNew = () => {
  const modalStore = useStore(ModalStore);
  const routerStore = useStore(RouterStore);
  const [redirect, setRedirect] = useState(false);
  const store = useStore(ReasonsOfAbsenceStore);

  useComponentDidMount(async () => {
    await modalStore.openModalDialog({
      children: <ReasonsOfAbsenceForm store={store} />,
      title: t('general.newReasonOfAbsence'),
      size: 'md',
      onAfterClose: () => routerStore.redirect('/reasons-of-absence'),
      containsForm: true,
    });
    setRedirect(true);
  });

  if (redirect) {
    return <Redirect to="/reasons-of-absence" />;
  }

  return null;
};
