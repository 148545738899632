import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { HomeworkStore, IHomeworkRow } from './homework-store';

import { ListView } from '@/components/list-view/list-view';
import {
  DateRangeOptionEnum,
  PageDatePickerMode,
} from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import { getCustomDateRangesFromSemesters } from '@/utils/list-view-util/list-view-util';
import ModalStore from '@/stores/modal-store';
import useStore from '@/hooks/useStore';
import { createCustomDateRangeClickHandler } from '@/utils/form/form-util';
import SchoolYearStore from '@/stores/schoolyear-store';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';

export const ClassRegisterHomework = observer(() => {
  const [store] = useState(() => new HomeworkStore());
  const { t } = useTranslation();
  const modalStore = useStore(ModalStore);
  const schoolyearStore = useStore(SchoolYearStore);

  useEffect(() => {
    schoolyearStore.handleSchoolYearFallback();
  }, [schoolyearStore.currentSchoolYearDoesNotExist]);

  useEffect(() => {
    if (!schoolyearStore.currentSchoolYear) {
      return;
    }
    store.setSelectedFreeTextOptions([]);
    store.setSelectedOptions([]);
    store.init(schoolyearStore.currentSchoolYear);
  }, [schoolyearStore.currentSchoolYear]);

  if (!store.isInitialised) {
    return <EmptyIndicator fullScreen title={t('general.youAreNotInASchoolyear')} />;
  }

  const timerangeOptions: [DateRangeOptionEnum, ...DateRangeOptionEnum[]] = store.isTodayInCurrentSchoolyear()
    ? [
        DateRangeOptionEnum.DATE_RANGE_TODAY,
        DateRangeOptionEnum.DATE_RANGE_WEEK,
        DateRangeOptionEnum.DATE_RANGE_MONTH,
        DateRangeOptionEnum.DATE_RANGE_SCHOOLYEAR,
        DateRangeOptionEnum.USER_SELECTION_DATE_RANGE,
      ]
    : [DateRangeOptionEnum.DATE_RANGE_SCHOOLYEAR, DateRangeOptionEnum.USER_SELECTION_DATE_RANGE];

  return (
    <div>
      <ListView<IHomeworkRow>
        title={t('general.homeworks')}
        table={{
          columns: store.columns,
          rowData: store.filteredRows,
        }}
        isPageLoading={store.isMedaLoading}
        isPageContentLoading={store.isDataLoading}
        compact
        searchBar={{
          selectedFreeTextOptions: store.selectedFreeTextOptions,
          onChangeFreeTextOptions: (options) => store.setSelectedFreeTextOptions(options),
          onChangeSelectOptions: (option) => store.setSelectedOptions(option),
          selectedOptions: store.selectedOptions,
          onlyOneOptionPerCategory: true,
          placeholder: t('general.search'),
          options: store.options,
        }}
        pageDatePickerProps={{
          mode: PageDatePickerMode.DATE_RANGE,
          options: timerangeOptions,
          customDateRanges: getCustomDateRangesFromSemesters(store.semesters),
          value: store.dateValue,
          currentSchoolyear: {
            startDate: dayjs(store.currentSchoolyear?.dateRange?.start),
            endDate: dayjs(store.currentSchoolyear?.dateRange?.end),
          },
          onDateRangeChange: (value) => {
            store.setDateValue(value);
          },
          onCustomClick: createCustomDateRangeClickHandler({
            modalStore,
            selectedDateRange: store.selectedDateRange,
            setSelectedDateRange: store.setSelectedDateRange,
          }),
        }}
      />
    </div>
  );
});
