import React from 'react';
import clsx from 'clsx';

const HiddenItemCount = ({ count, isTopElement }: { count: number | undefined; isTopElement?: boolean }) => {
  return count && count > 0 ? (
    <span
      className={clsx('hidden-item-count', { 'lesson-card-top-element': isTopElement })}
      data-testid="hidden-item-count"
    >
      +{count}
    </span>
  ) : null;
};

export default HiddenItemCount;
