import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import StudentLessonsStore, {
  IStudentLessonRow,
  SelectOption,
} from '@/pages/master-data/student/lessons/student-lessons-store';
import { SchoolYearDto } from '@untis/wu-rest-view-api';
import { WUTable } from '@/ui-components';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import { FormLabel, FormSection } from '@/ui-components/wu-form';
import { formatDate } from '@/utils/date/date-util';
import { ISelectItem } from '@/ui-components/select/select';
import './student-lessons.less';

interface IStudentLessonsProps {
  studentId: number;
  schoolYear: SchoolYearDto;
}

export const StudentLessons = observer((props: IStudentLessonsProps) => {
  const [store] = useState(() => new StudentLessonsStore(props.studentId, props.schoolYear));
  const { t } = useTranslation();

  const getPeriods = (): ISelectItem[] => {
    const items: ISelectItem[] = [];
    items.push({ label: t('general.custom'), id: SelectOption.CUSTOM.toString() });
    items.push({ label: t('general.today'), id: SelectOption.CURRENT_DAY.toString() });
    items.push({ label: t('general.currentWeek'), id: SelectOption.CURRENT_WEEK.toString() });
    items.push({ label: t('general.currentMonth'), id: SelectOption.CURRENT_MONTH.toString() });
    items.push({ label: t('general.currentSchoolyear'), id: SelectOption.CURRENT_SCHOOLYEAR.toString() });
    return items;
  };

  return (
    <div className="student-lessons-container">
      <Row gutter={24}>
        <Col span={11}>
          <FormSection text={t('general.period')} centered />
        </Col>
        <Col span={7}>
          <FormLabel
            text={formatDate(store.selectedDateRange.startDate) + ' - ' + formatDate(store.selectedDateRange.endDate)}
            centered
          />
        </Col>
        <Col span={6}>
          <FormSingleSelect
            name="periods"
            style={{ marginBottom: '10px' }}
            items={getPeriods()}
            initialValue={SelectOption.CURRENT_WEEK.toString()}
            allowClear={false}
            onChange={(value) => store.onDateSelect(value)}
            customSorting={(a, b) => (a.id > b.id ? 1 : -1)}
          />
        </Col>
      </Row>

      <Row>
        <WUTable<IStudentLessonRow>
          columns={store.columns}
          rowData={store.studentLessonsRows}
          shadow
          emptyText={t('general.todayLessonEmpty')}
          pagination={10}
        />
      </Row>
    </div>
  );
});
