import { action, computed, observable } from 'mobx';
import { FormInstance } from 'antd';
import { t } from 'i18next';
import React from 'react';

import { inject, Store } from '@/types/store';
import { IStudentFileUploadForm } from '@/pages/master-data/student/import/student-images-upload-form';
import { IFormButton } from '@/ui-components/wu-form';
import ModalStore from '@/stores/modal-store';
import { ImageViewApi } from '@/stores/api-store';
import { ElementType } from '@/stores/rights-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { StudentImagesUploadError } from '@/pages/master-data/student/import/student-images-upload-error';

@Store()
export class StudentImagesUploadStore {
  protected modalStore: ModalStore = inject(ModalStore);
  protected notificationStore: NotificationStore = inject(NotificationStore);

  @observable protected _formInstance: FormInstance<IStudentFileUploadForm>;
  @observable protected _isLoading: boolean = false;

  constructor(form: FormInstance<IStudentFileUploadForm>) {
    this._formInstance = form;
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  @computed
  get form(): FormInstance<IStudentFileUploadForm> {
    return this._formInstance;
  }

  @action
  setForm(formInstance: FormInstance<IStudentFileUploadForm>) {
    this._formInstance = formInstance;
  }

  @action
  handleZipFileImport(form: IStudentFileUploadForm) {
    ImageViewApi.saveImages(
      { categoryId: Number(ElementType.STUDENT), identificationMethod: form.identificationMethod },
      form.file,
    )
      .then((result) => {
        if (result.data) {
          this.setIsLoading(false);
          if (result.data.unmatchedFiles?.length === 0) {
            this.notificationStore.success({ title: t('general.photoImport.success') });
            this.modalStore.closeModal();
          } else {
            this.modalStore.openModalDialog({
              title: t('general.photoImport.matchingError.header'),
              size: 'square-md',
              mask: true,
              showFooterSeparator: true,
              children: (
                <StudentImagesUploadError
                  unmatchedFiles={result.data.unmatchedFiles}
                  totalNumberOfFiles={result.data.totalNumberOfFiles}
                />
              ),
              okButton: {
                label: t('general.new') + ' ' + t('general.import'),
                onClick: () => {
                  this.modalStore.closeModal();
                },
              },
              cancelButton: {
                label: t('general.cancel'),
                onClick: () => {
                  this.modalStore.closeModal();
                  this.modalStore.closeModal();
                },
              },
            });
          }
        }
      })
      .catch(() => {
        this.setIsLoading(false);
        this.notificationStore.error({
          title: t('general.photoImport.error'),
          message: t('general.photoImport.zipContentInvalid'),
        });
      });
  }

  @computed
  get buttonsOnRightSide(): IFormButton[] {
    const rightButtons: IFormButton[] = [];
    rightButtons.push({
      label: t('general.cancel'),
      onClick: () => this.modalStore.closeModal(),
    });
    return rightButtons;
  }
}
