import { observer } from 'mobx-react-lite';
import React from 'react';

import { RequestConfirmationStatusDto } from '@untis/wu-rest-view-api';
import MessageDateTime from '@mg/components/message-date-time/message-date-time';
import { Icon, Skeleton, Tag } from '@/ui-components';

import './read-confirmation-table.less';

interface ISkeletonRowProps {
  active?: boolean;
}
const SkeletonRow = ({ active }: ISkeletonRowProps) => (
  <tr>
    <td className="column--confirmed">
      <Skeleton.Avatar active={active} size={24} />
    </td>
    <td className="column--recipient">
      <Skeleton.Input style={{ width: 100 }} active={active} size="small" />
    </td>
    <td></td>
    <td className="column--date-time">
      <Skeleton.Input style={{ width: 50 }} active={active} size="small" />
    </td>
  </tr>
);

function canRequestConfirmationBeConfirmedBySomeoneElse(requestConfirmation: RequestConfirmationStatusDto) {
  return requestConfirmation.confirmationStates.some((state) => state.userId !== requestConfirmation.recipientId);
}

interface IProps {
  isLoading: boolean;
  requestConfirmations: Array<RequestConfirmationStatusDto>;
}
const ReadConfirmationTable = observer((props: IProps) => {
  if (props.isLoading) {
    return (
      <table className="read-confirmation-table">
        <tbody>
          <SkeletonRow active />
          <SkeletonRow active />
          <SkeletonRow active />
        </tbody>
      </table>
    );
  }

  const existRecipientsWithClassNames = props.requestConfirmations.some((rc) => !!rc.recipientClassName);
  // we check if for any of the request confirmations also someone else than the original recipient can confirm
  const showRequestConfirmationStates = props.requestConfirmations.some((rc) =>
    canRequestConfirmationBeConfirmedBySomeoneElse(rc),
  );

  return (
    <table className="read-confirmation-table">
      <tbody>
        {props.requestConfirmations.map((requestConfirmation) => {
          const confirmedState = requestConfirmation.confirmationStates.find((state) => !!state.date);

          return (
            <tr key={requestConfirmation.recipientId}>
              <td className="column--confirmed">{!!confirmedState && <Icon type="activation" />}</td>
              <td className={'column--recipient' + (!!confirmedState ? ' column--recipient--confirmed' : '')}>
                <b>{requestConfirmation.recipientDisplayName}</b>
              </td>
              {existRecipientsWithClassNames && (
                <td className="column--class">
                  {requestConfirmation.recipientClassName && (
                    <Tag size="lg">{requestConfirmation.recipientClassName}</Tag>
                  )}
                </td>
              )}
              <td>{/** This is our empty column which adjusts its width to the free space available */}</td>
              <td className="column--date-time">
                {confirmedState && <MessageDateTime dateTime={confirmedState.date!} showBoth />}
              </td>
              {showRequestConfirmationStates && (
                <td className="column--confirm-states">
                  {canRequestConfirmationBeConfirmedBySomeoneElse(requestConfirmation) &&
                    requestConfirmation.confirmationStates.map((state) => (
                      <div key={state.userId} className={!!state.date ? 'confirmed' : 'unconfirmed'}>
                        {!!state.date && <Icon type="activation" />} {state.userDisplayName}
                      </div>
                    ))}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});
export default ReadConfirmationTable;
