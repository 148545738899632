import { observable } from 'mobx';

import { Store } from '@/types/store';

// extend if necessary (see Const.java in BE for reference)
// @flk: Note we should not have this stored in the frontend, but load it from backend
//       so that this stays in sync or be part of the OpenAPI package
export enum ElementType {
  ALL = '0',
  ABSENCE_REASON = '27',
  CLASS = '1',
  TEACHER = '2',
  SUBJECT = '3',
  ROOM = '4',
  STUDENT = '5',
  RESOURCE = '6',
  STAFF = '7',
  EXAM_TYPE = '39',
  MARK_SCHEMA = '83',
  BUILDING = '11',
  LEGAL_GUARDIAN = '12',
  APPRENTICE_REPRESENTATIVE = '21',
  EXEMPTION_REASON = '68',
  EXCUSE_STATUS = '71',
}

type Crud = 'R' | 'W' | 'C' | 'D';

// extend if necessary
// @flk: same here, this should also be loaded from backend or be part of the OpenAPI package
export enum Right {
  TT_VIEW = 'TT_VIEW',
  MASTERDATA = 'MASTERDATA',
  PARENTSDAYADMIN = 'PARENTSDAYADMIN',
  USERADMIN = 'USERADMIN',
  PARENTSDAY = 'PARENTSDAY',
  TTFORMAT = 'TTFORMAT',
  SHOW_LSNO = 'SHOW_LSNO',
  ADMIN = 'ADMIN',
  BOOKING_LOCK = 'BOOKING_LOCK',
  MSG_OF_DAY = 'MSG_OF_DAY',
  MESSAGES = 'MESSAGES',
  SCHOOLYEAR = 'SCHOOLYEAR',
  BACKUP = 'BACKUP',
  TIMEGRID = 'TIMEGRID',
  TT_OVERVIEW = 'TT_OVERVIEW',
  TT_DAY_OVERVIEW = 'TT_DAY_OVERVIEW',
  OFFICEHOURS = 'OFFICEHOURS',
  CONTACTDETAILS = 'CONTACTDETAILS',
  TT_YEARLYVIEW = 'TT_YEARLYVIEW',
  UNTIS_ACCESS = 'UNTIS_ACCESS',
  RESERVE = 'RESERVE',
  BOOK = 'BOOK',
  BOOKADMIN = 'BOOKADMIN',
  BOOK_PAST = 'BOOK_PAST',
  BOOK_OTHER = 'BOOK_OTHER',
  LSROOM_CHG = 'LSROOM_CHG',
  ASSIGNMENT_GROUP = 'ASSIGNMENT_GROUP',
  SELECT_TEACHER = 'SELECT_TEACHER',
  LS_STUD_ASSIGNMENT = 'LS_STUD_ASSIGNMENT',
  PERIODINFO = 'PERIODINFO',
  USERTASK = 'USERTASK',
  TEACHER_WORKINGTIME = 'TEACHER_WORKINGTIME',
  TEACHER_ABSENCE_VIEW_RESTRICTED = 'TEACHER_ABSENCE_VIEW_RESTRICTED',
  TEACHER_SALARY = 'TEACHER_SALARY',
  TIMEREQUEST = 'TIMEREQUEST',
  EXAMLOCK = 'EXAMLOCK',
  MSG_SENDING_TO_ADMIN = 'MSG_SENDING_TO_ADMIN',
  STUDABS = 'STUDABS',
  CLASSEVENT = 'CLASSEVENT',
  CLASSREGISTER = 'CLASSREGISTER',
  LESSONTOPIC = 'LESSONTOPIC',
  CLASSSERVICE = 'CLASSSERVICE',
  EXAMINATION = 'EXAMINATION',
  HOMEWORK = 'HOMEWORK',
  EXEMPTION = 'EXEMPTION',
  STUDABS_REPORT = 'STUDABS_REPORT',
  EXAMSTATISTICS = 'EXAMSTATISTICS',
  EXCUSE = 'EXCUSE',
  TODOPERDIODSQUERY = 'TODOPERDIODSQUERY',
  PERFORMANCEASSESSMENT = 'PERFORMANCEASSESSMENT',
  LESSONREPORTS = 'LESSONREPORTS',
  CLASSREGREPORTS = 'CLASSREGREPORTS',
  SELF_ABSENCEREASON = 'SELF_ABSENCEREASON',
  COURSEADMIN = 'COURSEADMIN',
  COURSECHOICE = 'COURSECHOICE',
  COURSEMANAGER = 'COURSEMANAGER',
  COURSETEMPLATE = 'COURSETEMPLATE',
  TEACHER_ABSENCE = 'TEACHER_ABSENCE',
  DAILYCHANGE = 'DAILYCHANGE',
  DAILYCHANGE_ALL = 'DAILYCHANGE_ALL',
  SUBSTITUTION_PLANNING = 'SUBSTITUTION_PLANNING',
  OWN_TEACHER_ABSENCE = 'OWN_TEACHER_ABSENCE',
  SP_ASK_TEACHER = 'SP_ASK_TEACHER',
  OWN_ASK_TEACHER_REQUEST = 'OWN_ASK_TEACHER_REQUEST',
  UNTIS_MASTERDATA = 'UNTIS_MASTERDATA',
  UNTIS_TIMETABLE = 'UNTIS_TIMETABLE',
  UNTIS_SUBSTITUTION = 'UNTIS_SUBSTITUTION',
  TIMETABLING = 'TIMETABLING',
}

interface IRight {
  name: Right;
  crud: Crud[];
  elementType: ElementType;
  all: boolean;
}

@Store()
export default class RightsStore {
  @observable private _rights: IRight[] = [];

  // backend returns rights as strings in format: "MASTERDATA:DCWR:3:ALL"
  // -> "<NAME>:<DELETE><CREATE><WRITE><READ>:<ELEMENT TYPE>:<SCOPE>"
  initialize(rights: string[] | undefined) {
    if (!rights) {
      return;
    }
    this._rights = rights.map((right) => {
      const parts = right.split(':');
      const name: string = parts[0];
      const crud: string = parts[1];
      const elementType: string = parts[2];
      const scope: string = parts[3];

      return {
        name: name as Right,
        crud: crud.split('') as Crud[],
        elementType: elementType as ElementType,
        all: scope === 'ALL',
      };
    });
  }

  private checkRight(right: Right, crud: Crud, elementType: ElementType, all: boolean): boolean {
    const storedRights: IRight[] = this._rights.filter((r) => r.name === right);

    return storedRights.some((r) => {
      return r.crud.includes(crud) && r.elementType === elementType && (!all || (all && r.all));
    });
  }

  canRead(right: Right, elementType: ElementType, all: boolean): boolean {
    return this.checkRight(right, 'R', elementType, all);
  }

  canWrite(right: Right, elementType: ElementType, all: boolean): boolean {
    return this.checkRight(right, 'W', elementType, all);
  }

  canCreate(right: Right, elementType: ElementType, all: boolean): boolean {
    return this.checkRight(right, 'C', elementType, all);
  }

  canDelete(right: Right, elementType: ElementType, all: boolean): boolean {
    return this.checkRight(right, 'D', elementType, all);
  }
}
