import React from 'react';
import clsx from 'clsx';

import { FilterChip } from '@/ui-components/filter-chip/filter-chip';
import './filter.less';
import DeprecatedDropDown, { DeprecatedDropDownItem } from '@/ui-components/deprecated-drop-down/drop-down';

export interface IFilterItem {
  id: string;
  label: string;
}

type CommonProps = {
  id: string;
  items: IFilterItem[];
  placeholder?: string;
  searchable?: boolean;
  canUndoSelection?: boolean;
  staticWidth?: boolean;
  onChange?: (filter: IDeprecatedFilter, item: IFilterItem | undefined) => void;
  booleanFilterWithCancel?: boolean;
  hideInactiveBooleanFilter?: boolean;
  dataTestId?: string;
};

type ConditionalProps =
  | {
      multiple?: never;
      value?: string;
      shorten?: never;
      translation?: never;
    }
  | {
      multiple: true;
      value?: string[];
      shorten?: boolean; // displays + X more if more than one selected
      translation?: {
        shorten: string;
      };
    };

export type IDeprecatedFilter = CommonProps & ConditionalProps;

export const DeprecatedFilter = (props: IDeprecatedFilter) => {
  const testId = props.dataTestId ? `${props.dataTestId}-filter-${props.id}` : undefined;

  const selectValue = (value: string | undefined) => {
    const item = props.items.find((item) => item.id === value);
    props.onChange?.(props, item);
  };

  const handleDropdownChange = (value: IFilterItem | undefined) => {
    selectValue(value ? value.id : undefined);
  };

  const handleBooleanButtonClick = () => {
    selectValue(props.value ? undefined : props.items[0].id);
  };

  const renderBooleanFilter = () => {
    if (!props.value && props.hideInactiveBooleanFilter) {
      return null;
    }

    if (props.booleanFilterWithCancel) {
      if (props.value) {
        return (
          <FilterChip text={props.items[0].label} onCancel={() => handleBooleanButtonClick()} dataTestId={testId} />
        );
      }
      return (
        <FilterChip
          onClick={() => handleBooleanButtonClick()}
          text={props.items[0].label}
          appearance="light"
          dataTestId={testId}
        />
      );
    }

    return (
      <button
        className={clsx('boolean-filter-button', { ['boolean-filter-button--active']: !!props.value })}
        data-testid={'filter-boolean-filter-' + props.id}
        onClick={() => handleBooleanButtonClick()}
      >
        {props.items[0].label}
      </button>
    );
  };

  const items: DeprecatedDropDownItem[] = props.items.map((filterItem) => {
    return {
      key: filterItem.id,
      value: filterItem.label,
    };
  });

  const onSelect = (item: DeprecatedDropDownItem) => {
    const filterItem = props.items.find((filterItem) => filterItem.id === item.key);
    handleDropdownChange(filterItem);
  };

  return (
    <div className="wu-filter">
      {props.items.length === 1 ? (
        renderBooleanFilter()
      ) : props.multiple ? (
        <DeprecatedDropDown
          multiple
          items={items}
          testId={'filter-dropdown-' + props.id}
          placeholder={props.placeholder}
          canUndoSelection={props.canUndoSelection !== false}
          selectedDark
          selectedKey={props.value}
          onSelect={onSelect}
          staticWidth={props.staticWidth}
          searchable={props.searchable}
          shorten={props.shorten}
          translation={props.translation}
        />
      ) : (
        <DeprecatedDropDown
          items={items}
          testId={'filter-dropdown-' + props.id}
          placeholder={props.placeholder}
          canUndoSelection={props.canUndoSelection !== false}
          selectedDark
          selectedKey={props.value as string | undefined}
          onSelect={onSelect}
          staticWidth={props.staticWidth}
          searchable={props.searchable}
        />
      )}
    </div>
  );
};
