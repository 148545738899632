import React, { ReactNode } from 'react';

import { Icon } from '@/ui-components';
import './form-row.less';

interface IProps {
  children: ReactNode;
  icon?: string;
  className?: string;
  noMargin?: boolean;
}

/**
 *  component to wrap different controls so that they appear in a consistent manner (optional icon
 *  on the left, distance to other form rows, text styling...)
 *  This might become a more general component (maybe even in ui-components) if it turns out
 *  that we will re-use it often.
 */
const FormRow = (props: IProps) => {
  let className = 'form-row';
  className = className + (props.className ? ' ' + props.className : '');
  className = className + (props.noMargin ? ' no-margin' : '');

  return (
    <div className={className}>
      {props.icon && (
        <div className="form-row-icon">
          <Icon type={props.icon} />
        </div>
      )}
      {props.children}
    </div>
  );
};

export default FormRow;
