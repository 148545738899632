import React from 'react';

import { RequestConfirmationSummaryDto, SentMessageRefDto, SentMessageViewDto } from '@untis/wu-rest-view-api';
import useStore from '@/hooks/useStore';
import Seperator from '@mg/components/mg-seperator/mg-seperator';
import ReadConfirmationStore from '@mg/stores/read-confirmation-store';

import './read-confirmation-state-badge.less';

interface IProps {
  message: SentMessageRefDto | SentMessageViewDto;
}
const ReadConfirmationStateBadge = ({ message }: IProps) => {
  const requestConfirmationStatus: RequestConfirmationSummaryDto = message.requestConfirmationStatus ?? {
    confirmedRequestCount: 1,
    totalRequestCount: 20,
  };
  const readConfirmationStore = useStore(ReadConfirmationStore);

  if (!requestConfirmationStatus) {
    return null;
  }

  return (
    <div
      className="read-confirmation-state-badge"
      onClick={(e) => {
        e.stopPropagation();
        readConfirmationStore.openReadConfirmationView(message.id, message.subject);
      }}
    >
      <span className={requestConfirmationStatus?.confirmedRequestCount > 0 ? 'read-confirmation-count' : ''}>
        {requestConfirmationStatus?.confirmedRequestCount}
      </span>
      <Seperator />
      {requestConfirmationStatus?.totalRequestCount}
    </div>
  );
};
export default ReadConfirmationStateBadge;
