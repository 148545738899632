import React from 'react';
import Form from 'antd/lib/form';

import TextArea from '@/ui-components/textarea/textarea';
import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import { getCommonTextInputRules, ICommonTextInputRules } from '@/utils/form/common-form-rules';
import { resolveNamePath } from '@/utils/form/form-util';

import './form-textarea.less';

export interface IFormTextareaProps extends IFormItemProps<string>, ICommonTextInputRules {
  placeholder?: string;
  maxLength?: number;
  floatingLabel?: string;
}

export const FormTextarea = (props: IFormTextareaProps) => {
  return (
    <Form.Item
      name={props.name}
      label={props.label}
      initialValue={props.initialValue}
      className="form-text-area"
      rules={getCommonTextInputRules(props as ICommonTextInputRules, props.rules)}
      dependencies={props.dependencies}
      labelAlign="left"
    >
      <TextArea
        placeholder={props.placeholder}
        disabled={props.disabled}
        maxLength={props.maxLength}
        name={resolveNamePath(props.name)}
        testId={props.testId}
        floatingLabel={props.floatingLabel}
      />
    </Form.Item>
  );
};
