import React, { useState } from 'react';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';

import { CalendarHeader } from '@/ui-components/date/calendar/calendar-header/calendar-header';
import {
  DateCalendar,
  DateCalendarUtils,
  IDateCalendarHolidayProps,
} from '@/ui-components/date/calendar/date-calendar/date-calendar';
import { CalendarFooter } from '@/ui-components/date/calendar/calendar-footer/calendar-footer';

import './date-range-calendar.less';
import '../calendar-overwrites.less';

export interface IDateRangeCalendarProps {
  start?: Dayjs | undefined;
  end?: Dayjs | undefined;
  title?: string;
  disabledDate?: (date: Dayjs) => boolean;
  shadow?: boolean;
  onSubmit?: (start: Dayjs, end: Dayjs) => void;
  testId?: string;
  holidays?: IDateCalendarHolidayProps[];
}

export const DateRangeCalendar = (props: IDateRangeCalendarProps) => {
  const [start, setStart] = useState<Dayjs | undefined>(props.start);
  const [end, setEnd] = useState<Dayjs | undefined>(props.end);
  const hasFooter = props.onSubmit;
  const today = dayjs();

  const className = clsx('date-range-calendar', {
    'with-header': props.title,
    'with-footer': hasFooter,
    shadow: props.shadow === true || props.shadow === undefined,
  });

  const handleStartChange = (value: Dayjs | undefined): void => {
    if (!end) {
      setEnd(value);
    }

    const currentEnd = end ? dayjs(end) : dayjs(value);
    if (value?.isAfter(currentEnd)) {
      setEnd(value);
    } else {
      setStart(value);
    }
  };

  const handleEndChange = (value: Dayjs | undefined): void => {
    if (!start) {
      setStart(value);
    }

    const currentStart = start ? dayjs(start) : dayjs(value);
    if (value?.isBefore(currentStart)) {
      setStart(value);
    } else {
      setEnd(value);
    }
  };

  const renderDate = (date: Dayjs, value: Dayjs | undefined, otherValue: Dayjs | undefined) => {
    const isToday = date.isSame(today, 'date');
    const isSelected = value && date.isSame(value, 'date');
    const isOtherSelected = otherValue && date.isSame(otherValue, 'date');
    const isDisabled = props.disabledDate && props.disabledDate(date);
    const isStart = start && date.isSame(start, 'date');
    const isEnd = end && date.isSame(end, 'date');
    const isInSelectedRange =
      start &&
      end &&
      !start.isSame(end, 'date') &&
      date.isSameOrBefore(end, 'date') &&
      date.isSameOrAfter(start, 'date');

    const className = clsx('date-cell', {
      today: isToday,
      selected: isSelected,
      'other-selected': isOtherSelected,
      disabled: isDisabled,
      'in-range': isInSelectedRange,
      start: isStart,
      end: isEnd,
      ...((!isInSelectedRange || isStart || isEnd) && DateCalendarUtils.resolveHolidayClasses(date, props.holidays)),
    });
    return (
      <div className={className}>
        <div className="inner">{date.date()}</div>
      </div>
    );
  };

  const testId = props.testId ? `${props.testId}-date-range-calendar` : undefined;

  const onSubmit = props.onSubmit
    ? (): void => {
        if (props.onSubmit && !!start && !!end) {
          props.onSubmit(start, end);
        }
      }
    : undefined;

  return (
    <div className={className} data-testid={testId}>
      {props.title && <CalendarHeader title={props.title} testId={testId} />}
      <div className="calendar-container">
        <DateCalendar
          shadow={false}
          value={start}
          onChange={handleStartChange}
          renderDate={(date) => renderDate(date, start, end)}
          disabledDate={props.disabledDate}
          holidays={props.holidays}
        />
        <DateCalendar
          shadow={false}
          value={end}
          onChange={handleEndChange}
          renderDate={(date) => renderDate(date, end, start)}
          disabledDate={props.disabledDate}
          holidays={props.holidays}
        />
      </div>
      {hasFooter && <CalendarFooter onSubmit={onSubmit} submitDisabled={!start || !end} testId={testId} />}
    </div>
  );
};
