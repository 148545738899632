import React from 'react';
import { action, computed, observable } from 'mobx';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import debounce from 'debounce';
import uniqBy from 'lodash/uniqBy';
import { t } from 'i18next';

import { Store } from '@/types/store';
import { MessageViewApi } from '@/stores/api-store';
import {
  MessagePersonDto,
  MessageSectionDto,
  MessageGroupDto,
  SearchResultMessageUserRefDto,
  MessageRecipientOption,
  MessageSendResponseDtoV2,
} from '@untis/wu-rest-view-api';
import { ISelectOptionListItem } from '@/ui-components/select-option-list/select-option-list';
import { ClassStudentCount, MessageRecipient } from '@mg/components/recipient-picker/recipient-picker';

@Store()
export class RecipientPickerClassStudentViewStore {
  @observable sections: MessageSectionDto[] = [];
  @observable persons: MessagePersonDto[] = [];
  @observable searchQuery = '';

  @observable searchedPersons: SearchResultMessageUserRefDto[] = [];
  @observable recentSearchedPersons: SearchResultMessageUserRefDto[] = [];

  @observable loadingState: 'idle' | 'loading' | 'success' | 'error' = 'idle';
  @observable searchState: 'idle' | 'loading' | 'success' | 'error' = 'idle';

  // contains only the ids of persons that are selected and not in a selected group
  @observable selectedPersonIdsSet: Set<number> = new Set();
  // contains the ids of selected groups
  @observable selectedGroupIdsSet: Set<string> = new Set();

  async fetchPersonRecipients(recipientOption: MessageRecipientOption) {
    if (this.isSuccess) return;

    try {
      this.loadingState = 'loading';
      const { data } = await MessageViewApi.getMessageRecipients(recipientOption);
      this.sections = data.sections;
      this.persons = data.persons;
      this.loadingState = 'success';
    } catch (error) {
      this.loadingState = 'error';
    }
  }

  @action
  search = debounce(async (recipientOption: MessageRecipientOption) => {
    let timeoutId: number = -1;

    try {
      timeoutId = window.setTimeout(() => {
        this.searchState = 'loading';
      }, 250);

      const { data } = await MessageViewApi.searchRecipients(recipientOption, this.searchQuery);
      clearTimeout(timeoutId);
      this.searchState = 'success';

      this.searchedPersons = data;
      // saving the recent searches so we can show them as selected when listed in the view
      this.setRecentSearchedPersons(data);
    } catch (error) {
      clearTimeout(timeoutId);
      this.searchState = 'error';

      // handle error
      console.error(error);
    }
  }, 500);

  @action
  reset() {
    this.sections = [];
    this.persons = [];
    this.recentSearchedPersons = [];
    this.searchState = 'idle';
    this.loadingState = 'idle';
    this.selectedPersonIdsSet.clear();
    this.selectedGroupIdsSet.clear();
  }

  /**
   * Handles logic for selecting a group if a checkbox is clicked for a group
   *
   * @param event CheckboxChangeEvent
   */
  selectOrDeselectGroupById(groupId: string) {
    if (this.selectedGroupIdsSet.has(groupId)) {
      this.deselectGroup(groupId);
    } else {
      this.selectGroup(groupId);
    }
  }

  selectGroup(groupId: string) {
    const group = this.groups.find((g) => g.groupId === groupId);
    if (!group) return;

    group.personIds.forEach((personId) => this.selectedPersonIdsSet.add(personId));
    this.selectedGroupIdsSet.add(groupId);
  }

  deselectGroup(groupId: string) {
    const group = this.groups.find((g) => g.groupId === groupId);
    if (!group) return;

    group.personIds.forEach((personId) => {
      if (!this.isPersonInMultipleGroups(personId)) {
        this.selectedPersonIdsSet.delete(personId);
      }
    });
    this.selectedGroupIdsSet.delete(groupId);
  }

  isPersonInMultipleGroups(personId: number) {
    return this.getGroupCountWithPerson(personId) > 1;
  }

  getGroupCountWithPerson(personId: number) {
    return this.selectedGroups.filter((group) => group.personIds.includes(personId)).length;
  }

  selectOrDeselectPersonById(personId: number) {
    if (this.selectedPersonIdsSet.has(personId)) {
      this.deselectPerson(personId);
    } else {
      this.selectPerson(personId);
    }
  }

  selectPerson(personId: number) {
    this.selectedPersonIdsSet.add(personId);
  }

  deselectPerson(personId: number) {
    this.selectedPersonIdsSet.delete(personId);
    this.updateGroupDeselectionByPerson(personId);
  }

  private updateGroupDeselectionByPerson(personId: number) {
    this.groups.forEach((group) => {
      if (group.personIds.includes(personId)) {
        this.selectedGroupIdsSet.delete(group.groupId);
      }
    });
  }

  @action
  setRecentSearchedPersons(persons: SearchResultMessageUserRefDto[]) {
    this.recentSearchedPersons = uniqBy([...this.recentSearchedPersons, ...persons], (person) => person.personId);
  }

  getOptionsByGroup(group: MessageGroupDto[]) {
    return group.map((group) => ({
      id: group.groupId,
      name: group.title,
      value: group.groupId,
      icon: ' ',
      description: group.details,
      hint: <ClassStudentCount count={group.personIds.length} />,
      onChange: (event: CheckboxChangeEvent) => this.selectOrDeselectGroupById(event.target.value),
      noAvatar: true,
    }));
  }

  @computed
  get personsOptions(): ISelectOptionListItem[] {
    return this.searchedPersons.map((person) => {
      return {
        id: person.personId,
        name: person.displayName,
        value: person.personId,
        circleIcon: true,
        greyedOut: true,
        tags: person.className ? [{ name: person.className }] : [],
        onChange: (event: CheckboxChangeEvent) => this.selectOrDeselectPersonById(event.target.value),
      };
    });
  }

  @computed
  get selectedPersonsIds() {
    return Array.from(this.selectedPersonIdsSet);
  }

  @computed
  get selectedGroupIds() {
    return Array.from(this.selectedGroupIdsSet);
  }

  @computed
  get selectedCount() {
    return this.selectedPersonsIds.length;
  }

  @computed
  get selectedGroups() {
    return this.groups.filter((group) => this.selectedGroupIds.includes(group.groupId));
  }

  @computed
  get selectedPersons() {
    return this.recentSearchedPersons.filter((student) => this.selectedPersonIdsSet.has(student.personId));
  }

  @computed
  get groups(): MessageGroupDto[] {
    return (
      this.sections?.reduce((groups: MessageGroupDto[], section) => {
        return [...groups, ...section.groups];
      }, []) ?? []
    );
  }

  @computed
  get selectedMessageRecipients(): MessageRecipient[] {
    const displayRecipients: MessageRecipient[] = [];
    const displayedGroupMemberIds: Set<number> = new Set();

    this.selectedGroupIds.forEach((groupId) => {
      const group = this.groups.find((group) => group.groupId === groupId);
      if (!group) return;

      displayRecipients.push({ type: 'group', group });
      group.personIds.forEach((id) => displayedGroupMemberIds.add(id));
    });

    this.selectedPersonsIds.forEach((personId) => {
      if (!displayedGroupMemberIds.has(personId)) {
        const person = this.persons.find((person) => person.id === personId);

        if (person) {
          displayRecipients.push({ type: 'person', person });
          return;
        }

        const searchPerson = this.searchedPersons.find((person) => person.personId === personId);

        if (searchPerson) {
          displayRecipients.push({
            type: 'person',
            person: {
              id: searchPerson.personId,
              displayName: searchPerson.displayName,
              className: searchPerson.className,
            },
          });
          return;
        }
      }
    });

    return displayRecipients;
  }

  @computed
  get isSearchLoading() {
    return this.searchState === 'loading';
  }

  @computed
  get isLoading() {
    return this.loadingState === 'loading';
  }

  @computed
  get isSuccess() {
    return this.loadingState === 'success';
  }

  @computed
  get hasError() {
    return this.loadingState === 'error';
  }

  @computed
  get isSearchSuccess() {
    return this.searchState === 'success';
  }

  @computed
  get showPersonsList() {
    return this.searchState !== 'idle' && this.searchQuery.length > 1;
  }

  @computed
  get isSearchEmpty() {
    return this.isSearchSuccess && this.searchedPersons.length === 0;
  }

  successMessage(recipientOption: MessageRecipientOption, messageSendResponse: MessageSendResponseDtoV2) {
    const name = this.selectedPersons.find((person) => person.personId === this.selectedPersonsIds[0])?.displayName;

    switch (recipientOption) {
      case MessageRecipientOption.STUDENTS:
        return t('general.sendMessageStudentsSendSuccess', {
          count: messageSendResponse.numberOfRecipients,
          name,
        });
      case MessageRecipientOption.PARENTS: {
        if (this.selectedPersons.length > 1 || this.selectedGroups.length > 0) {
          return t('general.sendMessageParentsSendSuccess', {
            count: messageSendResponse.numberOfRecipients,
            name,
          });
        }

        return t('general.sendMessageParentsSendSuccess', {
          count: 1,
          name,
        });
      }

      default:
        return '';
    }
  }

  successCCMessage(recipientOption: MessageRecipientOption, messageSendResponse: MessageSendResponseDtoV2) {
    const name = this.selectedPersons.find((person) => person.personId === this.selectedPersonsIds[0])?.displayName;

    if (recipientOption === MessageRecipientOption.PARENTS) {
      return t('general.sendMessageParentsCCSendSuccess', {
        count: messageSendResponse.numberOfCCRecipients,
        name,
      });
    }

    return '';
  }
}
