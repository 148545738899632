import axios, { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import { message } from 'antd';

import { inject, Store } from '@/types/store';
import { FileDescriptorDtoV2, HeaderDtoItem } from '@untis/wu-rest-view-api';
import { FileViewApi, MessageViewApi } from '@/stores/api-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { textTodo } from '@/utils/text-todo/text-todo';

@Store()
export class S3Store {
  private _notificationStore = inject(NotificationStore);

  public openFileUploadDialog = (): Promise<FileDescriptorDtoV2[]> => {
    return new Promise((resolve) => {
      const input: HTMLInputElement = document.createElement('input');
      input.type = 'file';
      input.onchange = (e) => {
        const target = <HTMLInputElement>e.target;

        // you can use this method to get file and perform respective operations
        const files = target && target.files ? Array.from(target.files) : [];

        const requests: Promise<AxiosResponse<FileDescriptorDtoV2>>[] = [];

        files.forEach((file) => {
          requests.push(FileViewApi.uploadFile(file).catch());
        });

        Promise.all(requests)
          .then((responses) => {
            const attachments: FileDescriptorDtoV2[] = responses.map((response) => response.data);
            resolve(attachments);
          })
          .catch(() => {
            this._notificationStore.error({ title: textTodo('Upload Attachments failed') });
          });
      };
      input.click();
    });
  };

  public downloadAttachment = async (attachment: FileDescriptorDtoV2) => {
    try {
      let downloadUrl = attachment.downloadUrl;
      let additionalHeaders = attachment.headers ?? {};
      if (!downloadUrl) {
        // if the presigned Request was not already generated, do it now
        const fileInfo = await MessageViewApi.getAttachmentStorageUrl(attachment.storageId);
        downloadUrl = fileInfo.data.downloadUrl;
        additionalHeaders =
          fileInfo.data.additionalHeaders?.reduce((headers: { [key: string]: string[] }, headerItem: HeaderDtoItem) => {
            if (headerItem.key === 'host') return headers;
            headers[headerItem.key] = [headerItem.value];
            return headers;
          }, {}) ?? {};
      }
      additionalHeaders['X-Amz-Date'] = [new URL(downloadUrl).searchParams.get('X-Amz-Date') ?? ''];
      const { data: blob } = await axios.get(downloadUrl, {
        responseType: 'blob',
        headers: additionalHeaders,
      });
      fileDownload(blob, attachment.name);
    } catch (error) {
      message.error(error.message);
    }
  };
}
