import { useObserver } from 'mobx-react-lite';
import React, { forwardRef, Ref, useEffect } from 'react';

import useStore from '@/hooks/useStore';
import MenuItem from '@/ui-components/navigation-bar/menu-item/menu-item';
import { IMenuItem, NavigationBarStore } from '@/ui-components/navigation-bar/navigation-bar-store';
import StudentSelection from '@/ui-components/navigation-bar/student-selection/student-selection';

import './main-menu-item-list.less';

export const MenuItemListClassName = 'menu-item-list';

interface IMainMenuItemListProps {
  onNavigate: (item: IMenuItem) => void;
  menuHeaderHeight: number;
  menuBottomViewHeight: number;
}

const MainMenuItemList = forwardRef<HTMLDivElement, IMainMenuItemListProps>((props, ref: Ref<HTMLDivElement>) => {
  const store = useStore(NavigationBarStore);

  // We want to check for intersections everytime the user navigates between different menus
  useEffect(() => {
    setTimeout(() => {
      store.checkForIntersections();
    }, 100);
  }, [store, store.isInDepartmentSelection, store.isInStudentSelection, store.isInSubMenu]);

  // ... and every time the user scrolled
  useEffect(() => {
    const handleOnScroll = (e: Event) => {
      if (e.target instanceof HTMLElement && !e.target.className?.includes(MenuItemListClassName)) {
        return;
      }
      store.checkForIntersections();
    };
    window.addEventListener('scroll', handleOnScroll, true);

    return () => window.removeEventListener('scroll', handleOnScroll, true);
  }, [props, store]);

  const className =
    `${MenuItemListClassName} menu-item-list--main` +
    (store.isInSubMenu || store.isInDepartmentSelection || store.isInStudentSelection
      ? ' menu-item-list--main-left'
      : ' menu-item-list--main-right') +
    (!store.collapsed ? ' menu-item-list--full-width' : ' menu-item-list--collapsed') +
    (store.showMenuItemsFadeAnimationWithIcons
      ? ' menu-item-list--fade-animation-with-icons'
      : ' menu-item-list--fade-animation-without-icons') +
    (store.collapsed && store.showMenuItemsFadeAnimationWithIcons
      ? ' menu-item-list--icon-scale-animation'
      : ' menu-item-list--no-icon-scale-animation');

  const handleMenuItemClick = (item: IMenuItem) => {
    if (item.showDenseMenuItems && store.collapsed) {
      store.setCollapsed(false);
    }
    if (item.subMenuItems && item.subMenuItems.length > 0) {
      store.isInSubMenu = undefined;
    }
    store.showMenuItemsFadeAnimationWithIcons = true;
    store.scrollMode = false;

    props.onNavigate(item);
  };

  const renderMenuItems = () => {
    const items = store.menuItems.map((item, index) => {
      return (
        <MenuItem
          key={item.name}
          name={item.name}
          icon={item.icon}
          selected={
            !!store.selectedMenuItem &&
            item.name === store.selectedMenuItem.name &&
            store.selectedMenuItem.tag === item.tag
          }
          badge={item.badge}
          tag={item.tag}
          route={item.route}
          onClick={() => handleMenuItemClick(item)}
          isDense={false}
          testId={item.testId}
          isAccessible={store.isMainMenuAccessible}
          isFirst={index === 0}
        />
      );
    });

    if (store.departments.length > 0) {
      const handleClick = () => {
        if (store.collapsed) {
          store.setCollapsed(false);
        }
        store.isInDepartmentSelection = true;
      };
      items.push(
        <MenuItem
          key={store.departmentsText}
          name={store.departmentsText}
          icon="navigation-department"
          selected={false}
          onClick={handleClick}
          isDense={false}
          isAccessible={store.isMainMenuAccessible}
        />,
      );
    }

    return items;
  };

  const handleScroll = (e: any) => {
    store.setMainScrollTop(e.target.scrollTop);
  };

  return useObserver(() => (
    <div
      className={className}
      onScroll={handleScroll}
      style={{
        height: 'calc(100% - (' + (props.menuBottomViewHeight + props.menuHeaderHeight) + 'px))',
        marginTop: props.menuHeaderHeight,
      }}
    >
      <div className="menu-item-container" ref={ref}>
        <StudentSelection />
        {renderMenuItems()}
      </div>
    </div>
  ));
});

export default MainMenuItemList;
