import { observable } from 'mobx';

import { Store } from '@/types/store';
import { ReportType } from '@/components/reports-exporting-table/reports-exporting-table';
import { ReportUrlMapperStore } from '@/stores/report-url-mapper-store';

export interface IExamReportParameters {
  startDate: string;
  endDate: string;
  examTypeId: string;
  classId: string;
  subjectId: string;
  teacherId: string;
  gradingSchemeId: string;
  showDeleted: boolean;
  showEmpty: boolean;
}

enum ExamReportUrlParameter {
  START_DATE,
  END_DATE,
  EXAM_TYPE_ID,
  CLASS_ID,
  SUBJECT_ID,
  TEACHER_ID,
  GRADING_SCHEME_ID,
  SHOW_EMPTY,
}

export type ExamReportingType =
  | 'exams'
  | 'examsByType'
  | 'examPlanner'
  | 'examsWithGrades'
  | 'examsByClass'
  | 'examsByTeacher'
  | 'examStatistics';

interface IExamReportConfig {
  reportName: string;
  urlParameters: ExamReportUrlParameter[];
}

const EXAM_REPORT_CONFIG_MAP: Map<ExamReportingType, IExamReportConfig> = new Map<ExamReportingType, IExamReportConfig>(
  [
    [
      'exams',
      {
        reportName: 'Examinations',
        urlParameters: [
          ExamReportUrlParameter.START_DATE,
          ExamReportUrlParameter.END_DATE,
          ExamReportUrlParameter.EXAM_TYPE_ID,
          ExamReportUrlParameter.TEACHER_ID,
          ExamReportUrlParameter.CLASS_ID,
          ExamReportUrlParameter.SUBJECT_ID,
        ],
      },
    ],
    [
      'examsByType',
      {
        reportName: 'ExamCalendar',
        urlParameters: [
          ExamReportUrlParameter.START_DATE,
          ExamReportUrlParameter.END_DATE,
          ExamReportUrlParameter.EXAM_TYPE_ID,
          ExamReportUrlParameter.CLASS_ID,
        ],
      },
    ],
    [
      'examPlanner',
      {
        reportName: 'ExamDailyCalendar',
        urlParameters: [
          ExamReportUrlParameter.START_DATE,
          ExamReportUrlParameter.END_DATE,
          ExamReportUrlParameter.EXAM_TYPE_ID,
          ExamReportUrlParameter.CLASS_ID,
        ],
      },
    ],
    [
      'examsWithGrades',
      {
        reportName: 'ExamDetails',
        urlParameters: [
          ExamReportUrlParameter.START_DATE,
          ExamReportUrlParameter.END_DATE,
          ExamReportUrlParameter.EXAM_TYPE_ID,
          ExamReportUrlParameter.TEACHER_ID,
          ExamReportUrlParameter.CLASS_ID,
          ExamReportUrlParameter.SUBJECT_ID,
        ],
      },
    ],
    [
      'examsByClass',
      {
        reportName: 'ExamCalendarTotal',
        urlParameters: [
          ExamReportUrlParameter.START_DATE,
          ExamReportUrlParameter.END_DATE,
          ExamReportUrlParameter.EXAM_TYPE_ID,
          ExamReportUrlParameter.SHOW_EMPTY,
        ],
      },
    ],
    [
      'examsByTeacher',
      {
        reportName: 'ExamCalendarTotal',
        urlParameters: [
          ExamReportUrlParameter.START_DATE,
          ExamReportUrlParameter.END_DATE,
          ExamReportUrlParameter.EXAM_TYPE_ID,
          ExamReportUrlParameter.TEACHER_ID,
          ExamReportUrlParameter.SHOW_EMPTY,
        ],
      },
    ],
    [
      'examStatistics',
      {
        reportName: 'ExamStatistics',
        urlParameters: [
          ExamReportUrlParameter.START_DATE,
          ExamReportUrlParameter.END_DATE,
          ExamReportUrlParameter.EXAM_TYPE_ID,
          ExamReportUrlParameter.TEACHER_ID,
          ExamReportUrlParameter.CLASS_ID,
          ExamReportUrlParameter.SUBJECT_ID,
          ExamReportUrlParameter.GRADING_SCHEME_ID,
        ],
      },
    ],
  ],
);

const EXAM_REPORT_URL_PARAMETER_MAPPER: Map<ExamReportUrlParameter, (params: IExamReportParameters) => string> =
  new Map<ExamReportUrlParameter, (params: IExamReportParameters) => string>([
    [ExamReportUrlParameter.START_DATE, (params: IExamReportParameters) => `&rpt_sd=${params.startDate}`],
    [ExamReportUrlParameter.END_DATE, (params: IExamReportParameters) => `&rpt_ed=${params.endDate}`],
    [ExamReportUrlParameter.EXAM_TYPE_ID, (params: IExamReportParameters) => `&examTypeId=${params.examTypeId}`],
    [ExamReportUrlParameter.TEACHER_ID, (params: IExamReportParameters) => `&teacherId=${params.teacherId}`],
    [ExamReportUrlParameter.SUBJECT_ID, (params: IExamReportParameters) => `&subjectId=${params.subjectId}`],
    [ExamReportUrlParameter.CLASS_ID, (params: IExamReportParameters) => `&klasseId=${params.classId}`],
    [
      ExamReportUrlParameter.GRADING_SCHEME_ID,
      (params: IExamReportParameters) => `&markSchemaId=${params.gradingSchemeId}`,
    ],
    [ExamReportUrlParameter.SHOW_EMPTY, (params: IExamReportParameters) => `&hideEmptyDates=${params.showEmpty}`],
  ]);

@Store()
export class ExamsReportUrlMapperStore extends ReportUrlMapperStore {
  @observable isLoading: boolean = false;

  mapToReportUrl(key: string, reportType: ReportType, params: IExamReportParameters): string {
    const reportConfig = EXAM_REPORT_CONFIG_MAP.get(key as ExamReportingType);
    const reportName = reportConfig?.reportName;
    const reportUrlParameters = reportConfig?.urlParameters;
    const reportFormat = reportType.toString().toLowerCase();

    const reportParameters = reportUrlParameters
      ?.map((reportUrlParameter) => EXAM_REPORT_URL_PARAMETER_MAPPER.get(reportUrlParameter))
      .map((parameterMapper) => parameterMapper?.(params))
      .reduce((mappedParameterA, mappedParameterB) => mappedParameterA?.concat(mappedParameterB || ''));

    return this.getReportUrl(reportName, reportFormat, reportParameters);
  }
}
