import React from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Page, WUForm } from '@/ui-components';
import useStore from '@/hooks/useStore';
import { ILessonsSettings, LessonsSettingsStore } from '@ls/settings/lessons-settings-store';
import { WUFormCol } from '@/ui-components/wu-form/wu-form-col/wu-form-col';
import { WUFormRow } from '@/ui-components/wu-form/wu-form-row/wu-form-row';
import { FormSection } from '@/ui-components/wu-form/form-section/form-section';
import { FormLabel } from '@/ui-components/wu-form/form-label/form-label';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import { useComponentWillUnmount } from '@/hooks/useComponentWillUnmount';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { FormCheckboxes } from '@/ui-components/wu-form/form-checkboxes/form-checkboxes';
import { DateRange, FormDateRangePicker } from '@/ui-components/wu-form/form-date-range-picker/form-date-range-picker';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import { TextValidationPatterns } from '@/utils/form/common-form-rules';

const LessonsSettings = observer(() => {
  const [form] = Form.useForm<ILessonsSettings>();
  const lessonsSettingsStore = useStore(LessonsSettingsStore);
  const rightsStore = useStore(RightsStore);
  const { t } = useTranslation();

  const { examSettings, isLoading } = lessonsSettingsStore;
  const disabled = !rightsStore.canWrite(Right.ADMIN, ElementType.ALL, true);

  useComponentDidMount(() => {
    lessonsSettingsStore.fetchData();
  });

  useComponentWillUnmount(() => {
    form.resetFields();
    lessonsSettingsStore.reset();
  });

  const initialPermitRoomsCheckboxValues = [];
  if (examSettings?.roomConflictsAllowed) {
    initialPermitRoomsCheckboxValues.push('allowRoomConflicts');
  }

  const initialTransferExamsCheckboxValues = [];
  if (examSettings?.exportOnlySubstitutionRelevant) {
    initialTransferExamsCheckboxValues.push('exportOnlyRelevant');
  }

  const initialDateRange: DateRange = {
    start: examSettings?.start ? dayjs(examSettings.start) : undefined,
    end: examSettings?.end ? dayjs(examSettings.end) : undefined,
  };

  return (
    <Page
      title={t('lessons.settings.title')}
      isPageContentLoading={isLoading}
      compact
      whiteHeader
      whiteContent
      scrollMode="scrollContent"
    >
      <WUForm<ILessonsSettings>
        form={form}
        showAbsoluteButtonBarSeparator
        hideTopBorder
        fixedButtonBar
        fullHeight
        paddingTop
        hideSubmitButton={disabled}
        withDiscardButton
        onSubmit={
          rightsStore.canWrite(Right.ADMIN, ElementType.ALL, true)
            ? (value: ILessonsSettings) => lessonsSettingsStore.updateExamSettings(value)
            : undefined
        }
      >
        <WUFormCol lg={1}>
          <WUFormRow>
            <WUFormCol lg={2}>
              <FormSection
                text={t('lessons.settings.examActivitySettingsSubTitle')}
                description={t('lessons.settings.allowedDateRange')}
              />
            </WUFormCol>
            <WUFormCol lg={2}>
              <FormDateRangePicker
                name="dateRange"
                startLabel={t('general.fromDate')}
                endLabel={t('general.toDate')}
                initialValue={initialDateRange}
                removable={true}
                disabled={disabled}
              />
            </WUFormCol>
          </WUFormRow>

          <WUFormRow>
            <WUFormCol lg={2}>
              <FormLabel text={t('lessons.settings.allowRoomConflict')} />
            </WUFormCol>
            <WUFormCol lg={2}>
              <FormCheckboxes
                name="allowRoomConflicts"
                options={[
                  {
                    label: t('lessons.settings.roomConflictsPermitted'),
                    value: 'allowRoomConflicts',
                  },
                ]}
                initialValue={initialPermitRoomsCheckboxValues}
                disabled={disabled}
              />
            </WUFormCol>
          </WUFormRow>

          <WUFormRow>
            <WUFormCol lg={2}>
              <FormSection
                text={t('lessons.settings.UntisTransferSettingsSubTitle')}
                description={t('lessons.settings.exportDaysFuture')}
              />
            </WUFormCol>
            <WUFormCol lg={2}>
              <div style={{ maxWidth: 200 }}>
                <FormInput
                  name="exportDaysInFuture"
                  initialValue={examSettings?.exportDaysInFuture ? examSettings.exportDaysInFuture.toString() : '0'}
                  label={t('general.days')}
                  variant="floating"
                  pattern={TextValidationPatterns.NUMBERS_ONLY}
                  rules={[
                    { required: true, message: t('general.inputRequired') },
                    {
                      message: t('general.valueMustBeWithinRange', { min: 0, max: 366 }),
                      validator(rule, value) {
                        const days = parseInt(value);
                        if (!isNaN(days)) {
                          if (days < 0 || days > 366) {
                            return Promise.reject();
                          }
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  disabled={disabled}
                />
              </div>
            </WUFormCol>
          </WUFormRow>

          <WUFormRow>
            <WUFormCol lg={2}>
              <FormLabel text={t('lessons.settings.exportOnlyRelevant')} />
            </WUFormCol>
            <WUFormCol lg={2}>
              <FormCheckboxes
                name="exportOnlyRelevant"
                options={[
                  {
                    label: t('lessons.settings.transferredExams'),
                    value: 'exportOnlyRelevant',
                  },
                ]}
                initialValue={initialTransferExamsCheckboxValues}
                disabled={disabled}
              />
            </WUFormCol>
          </WUFormRow>
        </WUFormCol>
      </WUForm>
    </Page>
  );
});

export default LessonsSettings;
