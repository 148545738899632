import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import WuItemPickerDialogStore from '../wu-item-picker-dialog/wu-item-picker-dialog-store';

import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { IconButton, MultiTagSelect } from '@/ui-components';
import Combobox, { DropDownItem } from '@/ui-components/combobox/combobox';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { ISingleTagSelectItem } from '@/ui-components/tag-select/single-tag-select/single-tag-select';
import { ISelectOptionListItem } from '@/ui-components/select-option-list/select-option-list';
import { MasterDataRefDto } from '@untis/wu-rest-view-api/api';
import './assigned-teachers.less';
import { ITimeRangePickerTimeSlot } from '@/ui-components/time-range-picker/time-range-picker-store';

export type AssignedTeachersRow = {
  id: number;
  selectedTime: Dayjs;
  selectedTeachers: MasterDataRefDto[];
};

export interface IAssignedTeachersProps {
  startTime: Dayjs;
  endTime: Dayjs;
  timeSlots?: ITimeRangePickerTimeSlot[];
  teacherOptions: MasterDataRefDto[];
  value?: AssignedTeachersRow[];
  onAssignedTeachersChange?: (rows: AssignedTeachersRow[]) => void;
  disabled?: boolean;
}

/**
 * This component represents the assigned teachers component used in the exam wizard.
 * A click on the plus button allows you to split the time for assigned teachers.
 * A click on the cross button removes the split time for assigned teachers.
 * Use the time dropdown to choose time.
 * Use the 'Add a supervision teacher' to select list of teachers.
 */
const AssignedTeachers = (props: IAssignedTeachersProps) => {
  const { startTime, endTime, timeSlots, teacherOptions, disabled } = props;
  const [rows, setRows] = useState<Array<AssignedTeachersRow>>([]);
  const [times, setTimes] = useState<ISingleTagSelectItem[]>([]); // TODO: do we need this part
  const [selectedTimes, setSelectedTimes] = useState<Dayjs[] | undefined>(
    props.value?.map((value) => value.selectedTime),
  );

  const modalStore = useStore(ModalStore);
  const wuItemPickerDialogStore: WuItemPickerDialogStore = useStore(WuItemPickerDialogStore);

  const { t } = useTranslation();

  const date: string = startTime.format('YYYYMMDD');

  const initCreateTimes = (t1: string = '', t2: string = '') => {
    let createTimes: ISingleTagSelectItem[] = [];
    let idx: number = 0;

    timeSlots?.map((timeSlot) => {
      if (
        timeSlot.startTime.format('LT') >= startTime.format('LT') &&
        timeSlot.startTime.format('LT') <= endTime.format('LT')
      ) {
        createTimes.push({ id: idx.toString(), label: timeSlot.startTime.format('LT') });
      }
      idx++;
    });

    let hours = startTime.hour();
    let mins = startTime.minute();

    let newStartTime = dayjs(`${hours}:${mins}`, 'hh:mm');
    newStartTime = newStartTime.add(10, 'minutes');
    hours = newStartTime.hour();
    mins = newStartTime.minute();
    mins = (mins - ((mins + 10) % 10)) % 60;
    newStartTime = dayjs(`${hours}:${mins}`, 'hh:mm');

    createTimes.push({ id: idx.toString(), label: startTime.format('LT') });
    idx++;

    while (endTime.format('LT') > newStartTime.format('LT')) {
      createTimes.push({ id: idx.toString(), label: newStartTime.format('LT') });
      newStartTime = newStartTime.add(10, 'minutes');
      idx++;
    }

    createTimes.push({ id: idx.toString(), label: endTime.format('LT') });

    // filter based on the t1 & t2 time range
    if (t1 !== '' && t2 !== '') {
      createTimes = createTimes.filter((time) => time.label >= t1 && time.label <= t2);
      createTimes.unshift({ id: '-1', label: t1 });
      createTimes.push({ id: '-2', label: t2 });
    }

    const sortedCreateTimes: ISingleTagSelectItem[] = createTimes.sort((a, b) => (a.label > b.label ? 1 : -1));
    const uniqueCreateTimes: ISingleTagSelectItem[] = [];
    const uniqueTimeLabels: string[] = [];

    sortedCreateTimes.forEach((time) => {
      if (time)
        if (!uniqueTimeLabels.includes(time.label)) {
          uniqueTimeLabels.push(time.label);
          uniqueCreateTimes.push(time);
        }
    });

    return uniqueCreateTimes;
  };

  const removeRows = () => {
    rows.map((row) => {
      const selectedTime = row.selectedTime.format('LT');
      if (selectedTime > endTime.format('LT') || selectedTime < startTime.format('LT')) {
        handleRowRemove(row.id);
      }
    });
  };

  const createTimes: ISingleTagSelectItem[] = initCreateTimes();

  useEffect(() => {
    setTimes(createTimes);
    removeRows();
  }, [startTime, endTime]);

  useEffect(() => {
    setTimes(createTimes);
    if (props.value && props.value.length > 0) {
      setRows(props.value);
    }
  }, []);

  const handleItemPickerChange = (selectedItems: string[], teacherOptions: ISelectOptionListItem[], rowId: number) => {
    const changedRow = rows.find((row) => row.id === rowId);

    if (!changedRow) {
      modalStore.closeModal();
      return;
    }

    const selectedTeachersDto: MasterDataRefDto[] = selectedItems.map((item) => {
      const teacherName = teacherOptions.find((teacher) => teacher.id === item)?.name ?? '';
      return {
        id: Number(item),
        displayName: teacherName,
        shortName: teacherName,
        longName: teacherName,
      };
    });

    changedRow.selectedTeachers = selectedTeachersDto;

    const newRows = rows.map((row) => {
      if (row.id === rowId) {
        return changedRow;
      }
      return row;
    });

    addTeacherItems(selectedTeachersDto, rowId);
    props.onAssignedTeachersChange?.(newRows);
    modalStore.closeModal();
  };

  const handleComboboxChange = (value: string | undefined, rowId: number) => {
    const time = dayjs(value, 'hh:mm');
    if (!time.isValid()) {
      return;
    }
    const newTime = time.format('LT');
    let foundTime = times.find((time) => time.label === newTime);
    foundTime = !!foundTime ? foundTime : { id: '-1', label: newTime };
    const rowIndex = rows.findIndex((row) => row.id === rowId);

    // combobox's start time / minimum value in dropdown list
    const comboboxStartTime =
      (selectedTimes && selectedTimes[rowIndex - 1] && selectedTimes[rowIndex - 1].format('LT')) ??
      startTime.format('LT');

    // combobox's end time / maximum value in dropdown list
    const comboboxEndTime =
      (selectedTimes && selectedTimes[rowIndex + 1] && selectedTimes[rowIndex + 1].format('LT')) ??
      endTime.format('LT');

    // correct found time if we are not within start and end time range
    if (rowIndex !== 0) {
      if (foundTime.label <= comboboxStartTime) {
        const newFoundTime = dayjs(`${comboboxStartTime}`, 'hh:mm').add(1, 'minutes');
        foundTime = { id: '-1', label: newFoundTime.format('LT') };
      } else {
        if (foundTime.label >= comboboxEndTime) {
          const newFoundTime = dayjs(`${comboboxEndTime}`, 'hh:mm').subtract(1, 'minutes');
          foundTime = { id: '-1', label: newFoundTime.format('LT') };
        }
      }
    }

    addTimeItem(foundTime, rowId);

    // check for change in selected time
    const newSelectedTimes = rows?.map((row) => row.selectedTime);
    if (newSelectedTimes) {
      for (let i = 0; i < newSelectedTimes.length; i++) {
        const newSelectedTimeFormat = newSelectedTimes[i].format('LT');
        const selectedTimeFormat = !!selectedTimes && selectedTimes[i]?.format('LT');
        if (newSelectedTimeFormat !== selectedTimeFormat) {
          props.onAssignedTeachersChange && props.onAssignedTeachersChange(rows);
          break;
        }
      }
      setSelectedTimes(newSelectedTimes);
    }
  };

  const getTimes = (startTime: Dayjs | string, endTime: Dayjs | string, date: string = '') => {
    const times: ISingleTagSelectItem[] = [];

    if (typeof startTime === 'string') {
      startTime = dayjs(!date ? startTime : `${date}, ${startTime}`, 'YYYYMMDD, hh:mm');
    }
    if (typeof endTime === 'string') {
      endTime = dayjs(!date ? endTime : `${date}, ${endTime}`, 'YYYYMMDD, hh:mm');
    }

    // find the next multiple of 10 minutes
    const diff = 10 - (startTime.minute() % 10);

    let i: number = 0;
    while (endTime >= startTime) {
      times.push({ id: i.toString(), label: startTime.format('LT') });
      if (i === 0) {
        startTime = startTime.add(diff, 'minutes');
      } else {
        startTime = startTime.add(10, 'minutes');
      }
      i++;
    }

    return times;
  };

  const getAverageTime = (startTime: Dayjs | string, endTime: Dayjs | string): Dayjs => {
    if (typeof startTime === 'string') {
      startTime = dayjs(startTime, 'hh:mm');
    }
    if (typeof endTime === 'string') {
      endTime = dayjs(endTime, 'hh:mm');
    }

    const t1 = startTime.hour() * 60 + startTime.minute();
    const t2 = endTime.hour() * 60 + endTime.minute();
    const t = t1 + (t2 - t1) * 0.5;
    const hours = Math.floor(t / 60);
    const minutes = t - hours * 60;

    return dayjs(`${hours}:${minutes}`, 'hh:mm');
  };

  const addTeacherItems = (selectedTeachers: MasterDataRefDto[], rowId: number) => {
    const newRows: AssignedTeachersRow[] = [...rows];
    const newRow = newRows.find((newRow) => newRow.id === rowId);
    newRow && (newRow.selectedTeachers = selectedTeachers);
    setRows(newRows);
  };

  const addTimeItem = (selectedTime: ISingleTagSelectItem, rowId: number) => {
    const newRows: AssignedTeachersRow[] = [...rows];
    const newRow = newRows.find((newRow) => newRow.id === rowId);
    if (newRow) {
      newRow.selectedTime = dayjs(selectedTime.label, 'hh:mm');

      // update row id to selected time (unix timestamp)
      const date = startTime.format('YYYY-MM-DD');
      const newDate = dayjs(date + selectedTime.label, 'YYYY-MM-DDHH:mm').format('YYYY-MM-DDTHH:mm:ss');
      newRow.id = dayjs(newDate).unix();
      setRows(newRows);
    }
  };

  const renderRow = (row: AssignedTeachersRow) => {
    const { id, selectedTeachers, selectedTime } = row;

    const rowIndex = rows.findIndex((row) => row.id === id);
    const prevRow: AssignedTeachersRow = rows[rowIndex - 1];
    const nextRow: AssignedTeachersRow = rows[rowIndex + 1];
    const t1 = prevRow === undefined ? startTime.format('LT') : prevRow.selectedTime.format('LT');
    const t2 = nextRow === undefined ? endTime.format('LT') : nextRow.selectedTime.format('LT');

    const teacherItems: IMultiTagSelectItem[] = teacherOptions.map((teacher) => {
      return {
        id: teacher.id.toString(),
        label: teacher.displayName ?? '',
      };
    });

    const timeItems: DropDownItem[] = initCreateTimes(t1, t2).map((time) => {
      return {
        key: time.id,
        value: time.label,
      };
    });

    const handleRemoveTeacher = (rowId: number, teacherId: string) => {
      const newRows: AssignedTeachersRow[] = [...rows];
      const newRow = newRows.find((newRow) => newRow.id === rowId);
      if (newRow) {
        newRow.selectedTeachers = newRow.selectedTeachers.filter((teacher) => teacher.id.toString() !== teacherId);
        setRows(newRows);
        props.onAssignedTeachersChange && props.onAssignedTeachersChange(newRows);
      }
    };

    return (
      <>
        <div className="control-buttons">
          <IconButton
            ariaLabel="add"
            size="md"
            type="plus"
            disabled={disabled}
            onClick={() => handleRowAdd(id)}
          ></IconButton>
          {rows.length >= 2 && (
            <IconButton
              ariaLabel="remove"
              size="md"
              type="cancel"
              disabled={disabled}
              onClick={() => handleRowRemove(id)}
            ></IconButton>
          )}
        </div>
        <Combobox
          value={selectedTime.format('LT')}
          onSelect={(value) => handleComboboxChange(value, id)}
          staticWidth
          disabled={disabled || rows[0].id === id}
          items={timeItems}
        />
        <MultiTagSelect
          disabled={disabled}
          value={selectedTeachers.map((val) => {
            return {
              id: val.id.toString(),
              label: val.displayName ?? '',
              options: [
                {
                  label: t('general.remove'),
                  onClick: () => {
                    handleRemoveTeacher(id, val.id.toString());
                  },
                },
              ],
            };
          })}
          onAdd={createMultiTagSelectAddHandler(teacherItems, t('lessons.exams.form.addSupervisionTeacher'), id)}
        />
      </>
    );
  };

  const handleRowAdd = (rowId: number) => {
    const rowIdx = rows.findIndex((row) => row.id === rowId);
    const prevRow: AssignedTeachersRow = rows[rowIdx];
    const nextRow: AssignedTeachersRow = rows[rowIdx + 1];
    const newStartTime: ISingleTagSelectItem =
      prevRow?.selectedTime === undefined
        ? times[0]
        : { id: prevRow?.selectedTime.unix().toString(), label: prevRow?.selectedTime.format('LT') };
    const newEndTime: ISingleTagSelectItem =
      nextRow?.selectedTime === undefined
        ? times[times.length - 1]
        : { id: nextRow?.selectedTime.unix().toString(), label: nextRow?.selectedTime.format('LT') };
    const averageTime: Dayjs = getAverageTime(newStartTime.label, newEndTime.label);
    const newTimes: ISingleTagSelectItem[] = getTimes(newStartTime.label, newEndTime.label, date);

    // check how many time values we can create in combobox, must be minimum 2
    if (newTimes.length <= 1) {
      return;
    }

    // check if we have these time splits already, if so, don't create a new row
    let addNewRow = false;
    for (let i = 0; i < newTimes.length; i++) {
      const foundTime = selectedTimes?.find((selectedTime) => selectedTime.format('LT') === newTimes[i].label);
      if (!foundTime) {
        addNewRow = true;
        break;
      }
    }

    if (addNewRow) {
      // add a new row (cloned from previous row above)
      const newRow: AssignedTeachersRow = {
        id: dayjs(averageTime).unix(),
        selectedTime: averageTime,
        selectedTeachers: [...prevRow.selectedTeachers],
      };
      const newRows = [...rows];
      newRows.splice(rowIdx + 1, 0, newRow);
      setRows(newRows);
    }
  };

  const handleRowRemove = (rowId: number) => {
    if (rows.length > 1) {
      const newRows = [...rows].filter((newRow) => newRow.id !== rowId);
      if (newRows && newRows.length > 0) {
        newRows[0].selectedTime = dayjs(times[0].label, 'hh:mm');
        setRows(newRows);
        props.onAssignedTeachersChange && props.onAssignedTeachersChange(newRows);

        // refresh selected times
        const newSelectedTimes = newRows?.map((row) => row.selectedTime);
        setSelectedTimes(newSelectedTimes);
      }
    }
  };

  const createMultiTagSelectAddHandler = (items: IMultiTagSelectItem[], title: string, rowId: number) => {
    return () => {
      const selectedRow = rows.find((row) => row.id === rowId);

      let teacherOptions = items.map((teacher) => {
        const item: ISelectOptionListItem = {
          id: teacher.id,
          value: teacher.id,
          name: teacher.label,
        };

        return item;
      });

      let selectedTeacherOptions = selectedRow?.selectedTeachers.map((teacher) => {
        return teacher.id.toString();
      });

      teacherOptions = !!teacherOptions ? teacherOptions : [];
      selectedTeacherOptions = !!selectedTeacherOptions ? selectedTeacherOptions : [];

      wuItemPickerDialogStore.openItemPickerDialog(
        title,
        teacherOptions,
        selectedTeacherOptions,
        undefined,
        (selectedItems: string[]) => handleItemPickerChange(selectedItems, teacherOptions, rowId),
      );
    };
  };

  return (
    <div className="assigned-teachers-container">
      {rows.map((row) => {
        return (
          <div className="assigned-teachers-row" key={row.id}>
            {renderRow(row)}
          </div>
        );
      })}
    </div>
  );
};

export default AssignedTeachers;
