import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStore from '@/hooks/useStore';
import { MenuStore } from '@/stores/menu-store';
import { IPlatformMenuItem } from '@/ui-components/navigation-bar/navigation-bar-store';
import EnvironmentStore from '@/stores/environment-store';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import RefStore from '@/stores/ref-store';

function getTimeoutEffectCallback(timeoutCallback: () => void, timeoutMilliseconds: number): React.EffectCallback {
  const timeoutId = setTimeout(() => timeoutCallback(), timeoutMilliseconds);
  return () => clearTimeout(timeoutId);
}

const FrontChannelLogout = () => {
  const { t } = useTranslation();
  const menuStore = useStore(MenuStore);
  const environment = useStore(EnvironmentStore);
  const [showLogoutInProgress, setShowLogoutInProgress] = useState(false);
  const [applicationsToLogout] = useState<IPlatformMenuItem[]>(getApplicationsToLogout());
  const refStore = useStore(RefStore);

  function getApplicationsToLogout(): IPlatformMenuItem[] {
    return menuStore.platformApplicationMenuItems?.filter((item) => item.logoutUrl) || [];
  }

  const triggerWebUntisLogout = useCallback(() => {
    window.location.href = environment.webUntisURL + '/saml/logout';
  }, [environment.webUntisURL]);

  useComponentDidMount(() => {
    getTimeoutEffectCallback(() => {
      setShowLogoutInProgress(true);
      refStore.archerContainerRef.current?.refreshScreen();
    }, 1000);
  });

  useEffect(() => {
    getTimeoutEffectCallback(triggerWebUntisLogout, 5000);
  }, [triggerWebUntisLogout]);

  useEffect(() => {
    let sentLogoutRequests = 0;

    if (!applicationsToLogout.length) {
      triggerWebUntisLogout();
    }

    applicationsToLogout.forEach((item) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', item.logoutUrl!);
      xhr.withCredentials = true;
      xhr.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.OPENED) {
          sentLogoutRequests++;
        }
        if (sentLogoutRequests === applicationsToLogout.length) {
          triggerWebUntisLogout();
        }
      };
      xhr.send();
    });
  }, [applicationsToLogout, triggerWebUntisLogout]);

  if (showLogoutInProgress) {
    return (
      <EmptyIndicator
        title={t('general.youAreBeingLoggedOut')}
        description={t('general.pleaseWait')}
        fullScreen
        white
        zIndex={100}
      />
    );
  } else {
    return <></>;
  }
};
export default FrontChannelLogout;
