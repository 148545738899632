import React, { PropsWithChildren } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { ErrorBoundary as ErrBound, FallbackProps } from 'react-error-boundary';

import './error-boundary.less';
import AppStore from '@/stores/app-store';
import { inject } from '@/types/store';
import ErrorPage from '@/pages/error-page/error-page';

interface IState {
  hasError: boolean;
}

/**
 * This component wraps the applications top level routes so that the user can still navigate if a page crashes.
 * Error Boundaries are a feature of React 16. See https://reactjs.org/docs/error-boundaries.html
 */

export const ErrorFallback = () => {
  const { t } = useTranslation();

  return (
    <div role="alert">
      <ErrorPage
        iconColor="primary"
        title={t`general.errors.unexpectedError`}
        description={t`general.errors.tryAgain`}
      />
    </div>
  );
};

export const ErrorBoundaryFallback = ({ error }: FallbackProps) => {
  const { t } = useTranslation();

  return (
    <div role="alert">
      <ErrorPage
        title={t`general.errors.unexpectedError`}
        description={t`general.errors.clearCacheBeforeContactingSupport`}
        error={error}
        iconColor="primary"
      />
    </div>
  );
};

class ErrorBoundary extends React.Component<PropsWithChildren, IState> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  private appStore = inject(AppStore);

  constructor(props: PropsWithChildren) {
    super(props);
    this.state = {
      hasError: false,
    };
  }
  /**
   * Like PageTemplate ErrorBoundary must also hide a possibly visible legacy page, because
   * a broken page will not do it anymore.
   */
  componentDidMount() {
    this.appStore.setShowLegacyWebUntis(false);
  }

  render() {
    return (
      <ErrBound
        FallbackComponent={ErrorBoundaryFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
        onError={(error: Error, info: { componentStack: string }) => {
          // Do something with the error
          // E.g. log to an error logging client here
          console.error('ErrorBoundary', error, info);
        }}
      >
        {this.props.children}
      </ErrBound>
    );
  }
}
export default withTranslation()(ErrorBoundary);
