import { action, computed, observable, toJS } from 'mobx';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import debounce from 'debounce';
import { t } from 'i18next';

import { MessageRecipient } from '@mg/components/recipient-picker/recipient-picker';
import { Store } from '@/types/store';
import { ISelectOptionListItem } from '@/ui-components/select-option-list/select-option-list';
import {
  MessageFilterGroupDto,
  MessageFilterResponseDto,
  MessageFilterTypeV2,
  MessageFilterUserDto,
  MessageRecipientOption,
  MessageUserRole,
  SearchResultMessageUserRefDto,
} from '@untis/wu-rest-view-api';
import { MessageViewApi } from '@/stores/api-store';
import { IFilterItem } from '@/ui-components/filter-bar/filter/deprecatedFilter';

export function getFilterRoleTranslation(role: MessageUserRole, count: number = 1) {
  const {
    ADMIN,
    APPRENTICE_REPRESENTATIVE,
    DIRECTORATE,
    KLASSE,
    LEGAL_GUARDIAN,
    OTHER,
    PARENT,
    STAFF,
    STUDENT,
    TEACHER,
    UNTIS,
  } = MessageUserRole;
  switch (role) {
    case ADMIN:
      return t('general.userRoleAdmin', { count });
    case APPRENTICE_REPRESENTATIVE:
      return t('general.userRoleApprenticeRepresentatives', { count });
    case DIRECTORATE:
      return t('general.userRoleDirectorate', { count });
    case KLASSE:
      return t('general.userRoleClass', { count });
    case LEGAL_GUARDIAN:
      return t('general.userRoleLegalGuardian', { count });
    case OTHER:
      return t('general.userRoleOther', { count });
    case PARENT:
      return t('general.userRoleParent', { count });
    case STAFF:
      return t('general.userRoleStaff', { count });
    case STUDENT:
      return t('general.userRoleStudent', { count });
    case TEACHER:
      return t('general.userRoleTeacher', { count });
    case UNTIS:
      return t('general.userRoleUntis', { count });
    default:
      return '';
  }
}

export type CustomResult = {
  id: number;
  displayName: string;
  className?: string;
};

export enum ViewState {
  SELECTED_VIEW_ACTIVE = 'SELECTED_VIEW_ACTIVE',
  MAIN_VIEW_ACTIVE = 'MAIN_VIEW_ACTIVE',
}

export enum MainViewState {
  NO_FILTER_SELECTED = 'NO_FILTER_SELECTED',
  NO_RESULTS = 'NO_RESULTS',
  GOT_RESULTS = 'GOT_RESULTS',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

// @TODO: Think about separating custom and staff logic so it is easier to grasp, even if it means duplication
//        Maybe we can put common logic in a parent class and inherit, but currently even if its not big differences
//        it's already not so clear as if it would be separated.
//        also think about using state machines like xstate

@Store()
export class RecipientPickerCustomStaffViewStore {
  @observable recipientOption: MessageRecipientOption = MessageRecipientOption.CUSTOM;

  @observable filterDtos: MessageFilterGroupDto[] = [];
  @observable staffData: MessageFilterResponseDto | undefined; // used to save all staff data on first load
  @observable selectedPersonsSet: Set<MessageFilterUserDto> = new Set();
  @observable searchQuery = '';
  @observable viewState: ViewState = ViewState.MAIN_VIEW_ACTIVE;
  @observable mainViewState: MainViewState = MainViewState.NO_FILTER_SELECTED;
  @observable isLoadingFilters = false;

  @observable private _selectedFilter: boolean = false;
  @observable private _customListFilter: string[] = [];
  @observable private _dynamicListFilter: string[] = [];
  @observable private _classesFilter: string[] = [];
  @observable private _departmentsFilter: string[] = [];
  @observable private _rolesFilter: string[] = [];
  @observable private _groupsFilter: string[] = [];

  @observable private _filteredUsers: MessageFilterUserDto[] = [];

  @computed
  get isAnyFilterSet(): boolean {
    return (
      this.selectedFilter ||
      this.customListFilter.length > 0 ||
      this.dynamicListFilter.length > 0 ||
      this.classesFilter.length > 0 ||
      this.departmentsFilter.length > 0 ||
      this.rolesFilter.length > 0 ||
      this._groupsFilter.length > 0
    );
  }

  @action
  async fetchPersonRecipients(): Promise<void> {
    // load all staff members on first visit
    // filter if we have a filter set.
    // this can happen if the users provides an initialFilter when opening the sending modal
    if (this.isStaff || this.isAnyFilterSet) {
      await this.applyFilter();
    }

    if (this.filterDtos.length <= 0) {
      this.isLoadingFilters = true;
    }

    try {
      const {
        data: { filters },
      } = await MessageViewApi.listFilterGroups(this.recipientOption);
      this.filterDtos = filters;
    } catch (error) {
      throw error;
    } finally {
      this.isLoadingFilters = false;
    }
  }

  @action
  showSelectedViewIfNeeded() {
    if (this.hasSelectedUsers) {
      this.viewState = ViewState.SELECTED_VIEW_ACTIVE;
      this.setSelectedFilter(true);
    } else {
      this.showMainView();
    }
  }

  @action
  setSelectedUserIds(userIds: number[]) {
    const selectedUsers = this._filteredUsers.filter((user) => userIds.includes(user.id));
    this.selectedPersonsSet = new Set(selectedUsers);
  }

  @action
  reset() {
    this.clearFilters(true);
    this._filteredUsers = [];

    this.staffData = undefined;
    this.viewState = ViewState.MAIN_VIEW_ACTIVE;
    this.mainViewState = MainViewState.NO_FILTER_SELECTED;
    this.selectedPersonsSet.clear();
  }

  @action
  clearFilters(includingSelected: boolean) {
    if (includingSelected) {
      this._selectedFilter = false;
    }
    this._groupsFilter = [];
    this._rolesFilter = [];
    this._classesFilter = [];
    this._departmentsFilter = [];
    this._dynamicListFilter = [];
    this._customListFilter = [];
  }

  @action
  showMainView(mainViewState?: MainViewState) {
    this.viewState = ViewState.MAIN_VIEW_ACTIVE;

    if (mainViewState) {
      this.mainViewState = mainViewState;
    }

    this.setSelectedFilter(false);
  }

  @action
  search = debounce(async () => {
    // only search the server if the main view is active
    // otherwise the search is down locally.
    // have a look at the computed options to see the filtering
    if (this.viewState === ViewState.MAIN_VIEW_ACTIVE) {
      await this.applyFilter();
    }
  }, 500);

  @action.bound
  handleSelectedToggleChange(value: boolean) {
    this.setSelectedFilter(value);
    this.viewState = value ? ViewState.SELECTED_VIEW_ACTIVE : ViewState.MAIN_VIEW_ACTIVE;

    if (this.isStaff) {
      this.mainViewState = value ? this.mainViewState : MainViewState.GOT_RESULTS;
    } else {
      this.clearFilters(false);
      this.mainViewState = value ? this.mainViewState : MainViewState.NO_FILTER_SELECTED;
    }
  }

  @action
  async applyFilter(): Promise<void> {
    // only show the no filter selected view when we are in the custom view
    if (this.isCustom && this.filterMapToMessageFilterItemDtoArray.length <= 0 && this.searchQuery === '') {
      this.mainViewState = MainViewState.NO_FILTER_SELECTED;
      this._filteredUsers = [];
      return;
    }

    this.showMainView(MainViewState.LOADING);

    try {
      const { data } = await MessageViewApi.filterRecipientsWithId(this.recipientOption, {
        filters: this.filterMapToMessageFilterItemDtoArray,
        searchText: this.searchQuery,
      });

      // save all staff data on the first load
      if (this.isStaff && !this.staffData) {
        this.staffData = data;
      }

      this._filteredUsers = data.users;

      if (this.selectedFilter) {
        this._filteredUsers = this._filteredUsers.filter((user) => this.selectedPersonsIds.includes(user.id));
      }

      this.mainViewState = MainViewState.GOT_RESULTS;
    } catch (error) {
      this.mainViewState = MainViewState.ERROR;
      console.error(error);
    }
  }

  @action.bound
  handleSelectAll(checked: boolean) {
    if (this.isSelectedViewActive) {
      if (checked) {
        this.selectedPersonsArray.forEach((user) => this.selectUser(user));
      } else {
        this.selectedPersonsArray.forEach((user) => this.deselectUser(user));
      }
      return;
    }

    if (checked) {
      this._filteredUsers.forEach((user) => this.selectUser(user));
    } else {
      this._filteredUsers.forEach((user) => this.deselectUser(user));
    }
  }

  selectUser(user: MessageFilterUserDto) {
    const person = this.selectedPersonsArray.find((person) => person.id === user.id);
    if (!person) {
      this.selectedPersonsSet.add(user);
    }
  }

  deselectUser(user: MessageFilterUserDto) {
    if (this.selectedPersonsSet.has(user)) {
      this.selectedPersonsSet.delete(user);
      return;
    }

    // if not found in set try to find it with the id and try again
    const userToDelete = Array.from(this.selectedPersonsSet).find((selectedUser) => selectedUser.id === user.id);
    if (userToDelete) {
      this.selectedPersonsSet.delete(userToDelete);
    }
  }

  selectOrDeselectPersonById(userId: number) {
    const person = this.selectedPersonsArray.find((person) => person.id === userId);

    if (person) {
      this.selectedPersonsSet.delete(person);
    } else {
      const user = this._filteredUsers.find((user) => user.id === userId);
      if (user) {
        this.selectedPersonsSet.add(user);
      }
    }
  }

  customPersonToPersonSearchDto(person: CustomResult): SearchResultMessageUserRefDto {
    return {
      displayName: person.displayName,
      personId: person.id,
      className: person.className,
      imageUrl: '',
      role: '',
    };
  }

  @action
  async setInitialRecipients(userIds: number[]) {
    try {
      const {
        data: { users },
      } = await MessageViewApi.getStaticUsers({ userIds });

      this.selectedPersonsSet = new Set(users);
    } catch (error) {
      console.error(error);
    }
  }

  @computed
  get selectedFilter(): boolean {
    return this._selectedFilter;
  }

  @computed
  get customListFilter(): string[] {
    return toJS(this._customListFilter);
  }

  @computed
  get dynamicListFilter(): string[] {
    return toJS(this._dynamicListFilter);
  }

  @computed
  get classesFilter(): string[] {
    return toJS(this._classesFilter);
  }

  @computed
  get departmentsFilter(): string[] {
    return toJS(this._departmentsFilter);
  }

  @computed
  get rolesFilter(): string[] {
    return toJS(this._rolesFilter);
  }

  @computed
  get groupsFilter(): string[] {
    return toJS(this._groupsFilter);
  }

  @action.bound
  setSelectedFilter(value: boolean) {
    this._selectedFilter = value;
  }

  @action.bound
  setCustomListFilter(value: string[]) {
    this._customListFilter = value;
    this.applyFilter();
  }

  @action.bound
  setDynamicListFilter(value: string[]) {
    this._dynamicListFilter = value;
    this.applyFilter();
  }

  @action.bound
  setClassesFilter(value: string[]) {
    this._classesFilter = value;
    this.applyFilter();
  }

  @action.bound
  setDepartmentsFilter(value: string[]) {
    this._departmentsFilter = value;
    this.applyFilter();
  }

  @action.bound
  setRolesFilter(value: string[]) {
    this._rolesFilter = value;
    this.applyFilter();
  }

  @action.bound
  setGroupsFilter(value: string[]) {
    this._groupsFilter = value;
    this.applyFilter();
  }

  private getFilterItemsByType(type: MessageFilterTypeV2): IFilterItem[] {
    const listFilterDto: MessageFilterGroupDto | undefined = this.filterDtos.find((dto) => dto.type === type);

    if (listFilterDto == undefined || listFilterDto.items.length === 0) {
      return [];
    }

    return listFilterDto.items.map((item) => {
      return {
        id: item.referenceId.toString(),
        label: item.name,
      };
    });
  }

  @computed
  get customListFilterItems(): IFilterItem[] {
    return this.getFilterItemsByType(MessageFilterTypeV2.QUICK);
  }

  @computed
  get dynamicListFilterItems(): IFilterItem[] {
    return this.getFilterItemsByType(MessageFilterTypeV2.DYNAMIC);
  }

  @computed
  get classesFilterItems(): IFilterItem[] {
    return this.getFilterItemsByType(MessageFilterTypeV2.CLASS);
  }

  @computed
  get departmentsFilterItems(): IFilterItem[] {
    return this.getFilterItemsByType(MessageFilterTypeV2.DEPARTMENT);
  }

  @computed
  get rolesFilterItems(): IFilterItem[] {
    return this.getFilterItemsByType(MessageFilterTypeV2.ROLE);
  }

  @computed
  get groupsFilterItems(): IFilterItem[] {
    return this.getFilterItemsByType(MessageFilterTypeV2.USER_GROUP);
  }

  @computed
  get showCustomListFilter(): boolean {
    return this.customListFilterItems.length > 0;
  }

  @computed
  get showDynamicListFilter(): boolean {
    return this.dynamicListFilterItems.length > 0;
  }

  @computed
  get showClassesFilter(): boolean {
    return this.classesFilterItems.length > 0;
  }

  @computed
  get showDepartmentsFilter(): boolean {
    return this.departmentsFilterItems.length > 0;
  }

  @computed
  get showRolesFilter(): boolean {
    return this.rolesFilterItems.length > 0;
  }

  @computed
  get showGroupsFilter(): boolean {
    return this.groupsFilterItems.length > 0;
  }

  @computed
  get showFilterBar(): boolean {
    return this.filterDtos.length > 0;
  }

  @computed
  get isMainViewActive() {
    return this.viewState === ViewState.MAIN_VIEW_ACTIVE;
  }

  @computed
  get isSelectedViewActive() {
    return this.viewState === ViewState.SELECTED_VIEW_ACTIVE;
  }

  @computed
  get isLoadingResults() {
    return this.isMainViewActive && this.mainViewState === MainViewState.LOADING;
  }

  @computed
  get isFirstVisit() {
    return this.isMainViewActive && this.mainViewState === MainViewState.NO_FILTER_SELECTED;
  }

  @computed
  get hasFilterResults() {
    return this.isMainViewActive && this.mainViewState === MainViewState.GOT_RESULTS;
  }

  @computed
  get hasEmptyResults() {
    return (
      (this.isMainViewActive && this.hasFilterResults && this._filteredUsers.length === 0) ||
      (this.isSelectedViewActive &&
        !this.isSelectedEmpty &&
        this.searchQuery !== '' &&
        this.personsOptions.length === 0)
    );
  }

  @computed
  get hasError() {
    return this.isMainViewActive && this.mainViewState === MainViewState.ERROR;
  }

  @computed
  get hasSelectedUsers() {
    return this.selectedPersons.length > 0;
  }

  @computed
  get isSelectedEmpty() {
    return this.isSelectedViewActive && this.selectedPersons.length <= 0;
  }

  @computed
  get hasResults() {
    return this.personsOptions.length > 0;
  }

  @computed
  get selectedPersonsIds() {
    return this.selectedPersonsArray.map((person) => person.id);
  }

  @computed
  get selectedPersonsArray() {
    return Array.from(this.selectedPersonsSet);
  }

  @computed
  get selectedPersons(): SearchResultMessageUserRefDto[] {
    return this.selectedPersonsArray.map(this.customPersonToPersonSearchDto);
  }

  @computed
  get selectedMessageRecipients(): MessageRecipient[] {
    return this.selectedPersons.map((person) => {
      return {
        type: 'person',
        person: {
          displayName: person.displayName,
          id: person.personId,
          className: person.className,
        },
      };
    });
  }

  @computed
  get selectedCount() {
    return this.selectedPersons.length;
  }

  @computed
  get personsOptions(): ISelectOptionListItem[] {
    const mapPerson = (person: MessageFilterUserDto) => {
      let tags: { name: string; active?: boolean }[] = person.role
        ? [{ name: getFilterRoleTranslation(person.role), active: this.showRolesFilter }]
        : [];
      tags = [...tags, ...person.tags.map((tag) => ({ name: tag }))];

      return {
        id: person.id,
        name: person.displayName,
        value: person.id,
        avatarSrc: person.imageUrl,
        circleIcon: true,
        greyedOut: true,
        tags,
        visible: true,
        onChange: (event: CheckboxChangeEvent) => this.selectOrDeselectPersonById(event.target.value),
      };
    };

    if (this.isSelectedViewActive) {
      return this.selectedPersonsArray
        .filter(
          (person) =>
            person.displayName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            person.role.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            person.tags.join(' ').toLowerCase().includes(this.searchQuery.toLowerCase()),
        )
        .sort((personA, personB) => personA.displayName.toLowerCase().localeCompare(personB.displayName.toLowerCase()))
        .map(mapPerson);
    }

    return this._filteredUsers.map(mapPerson);
  }

  private getFilterItemNameById(items: IFilterItem[], id: string): string {
    const item: IFilterItem | undefined = items.find((item) => item.id === id);
    return item?.label ?? '';
  }

  private createFilterDto(type: MessageFilterTypeV2, filter: string[], items: IFilterItem[]): MessageFilterGroupDto {
    return {
      type: type,
      items: filter.map((id) => {
        return {
          referenceId: Number(id),
          name: this.getFilterItemNameById(items, id),
        };
      }),
    };
  }

  @computed
  get filterMapToMessageFilterItemDtoArray(): MessageFilterGroupDto[] {
    const dtos: MessageFilterGroupDto[] = [];

    if (this.classesFilter.length > 0) {
      dtos.push(this.createFilterDto(MessageFilterTypeV2.CLASS, this.classesFilter, this.classesFilterItems));
    }

    if (this.rolesFilter.length > 0) {
      dtos.push(this.createFilterDto(MessageFilterTypeV2.ROLE, this.rolesFilter, this.rolesFilterItems));
    }

    if (this.departmentsFilter.length > 0) {
      dtos.push(
        this.createFilterDto(MessageFilterTypeV2.DEPARTMENT, this.departmentsFilter, this.departmentsFilterItems),
      );
    }

    if (this.groupsFilter.length > 0) {
      dtos.push(this.createFilterDto(MessageFilterTypeV2.USER_GROUP, this.groupsFilter, this.groupsFilterItems));
    }

    if (this.customListFilter.length > 0) {
      dtos.push(this.createFilterDto(MessageFilterTypeV2.QUICK, this.customListFilter, this.customListFilterItems));
    }

    if (this.dynamicListFilter.length > 0) {
      dtos.push(this.createFilterDto(MessageFilterTypeV2.DYNAMIC, this.dynamicListFilter, this.dynamicListFilterItems));
    }

    return dtos;
  }

  @computed
  get selectedPersonRolesSeperatedByComma() {
    const rolesByCount = this.selectedPersonsArray.reduce(
      (rolesByCount: Record<string | MessageUserRole, number>, person) => {
        const count = rolesByCount[person.role] ?? 0;
        rolesByCount[person.role] = count + 1;
        return rolesByCount;
      },
      {},
    );

    return Object.entries(rolesByCount)
      .sort(([roleA], [roleB]) => roleA.localeCompare(roleB))
      .map(([role, count]) => `${count} ${getFilterRoleTranslation(role as MessageUserRole, count)}`)
      .join(', ');
  }

  @computed
  get selectedPersonName() {
    if (this.selectedPersons.length === 1) {
      return this.selectedPersons[0].displayName;
    }

    return '';
  }

  @computed
  get isStaff() {
    return this.recipientOption === 'STAFF';
  }

  @computed
  get isCustom() {
    return this.recipientOption === 'CUSTOM';
  }

  successMessage(count: number) {
    const name = this.selectedPersons.find((person) => person.personId === this.selectedPersonsIds[0])?.displayName;

    return t(
      this.recipientOption === 'CUSTOM'
        ? 'general.sendMessageCustomSendSuccess'
        : 'general.sendMessageStaffSendSuccess',
      {
        count: count,
        name,
        roles: this.selectedPersonRolesSeperatedByComma,
      },
    );
  }
}
