import React from 'react';
import { RowProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { WUForm } from '@/ui-components';
import {
  Gender,
  TeacherDto,
  TeacherVarQuotaDto,
  TeacherVarSchoolDto,
  TeacherVarStatusDto,
} from '@untis/wu-rest-view-api';
import { FormColorPicker } from '@/ui-components/wu-form/form-color-picker/form-color-picker';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { Col, Row } from '@/ui-components/grid/grid';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { createMultiSelectAddHandlerWithOptions, validateForm } from '@/utils/form/form-util';
import { TeacherViewApi } from '@/stores/api-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { TeacherStore } from '@/pages/master-data/teacher/teacher-store';
import { FormSection } from '@/ui-components/wu-form/form-section/form-section';
import { FormLabel } from '@/ui-components/wu-form/form-label/form-label';
import { IFormButton } from '@/ui-components/wu-form/wu-form';
import { FormCheckboxes, FormDatePicker, FormMultiTagSelect, FormRadioButtons } from '@/ui-components/wu-form';
import { getResponseError, IResponseError, ResponseErrorType } from '@/utils/error-handling/error-handling';
import { IValidationError } from '@/types/validation-error';
import { TextValidationPatterns } from '@/utils/form/common-form-rules';
import { RoomRefDto } from '@untis/sp-rest-api';
import { textTodo } from '@/utils/text-todo/text-todo';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import FormTaggedSwitch from '@/ui-components/wu-form/form-tagged-switch/form-tagged-switch';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { formatDateForServerRequest } from '@/utils/date/date-util';
import deprecatedGetFormColProps from '@md/helpers/deprecatedGetFormColProps';
import { ISelectItem } from '@/ui-components/select/select';

const FORM_ROW_PROPS: RowProps = {
  gutter: [{ xl: 16 }, { xl: 16 }],

  style: { marginTop: '24px' },
};

export interface ITeacherForm {
  name: string;
  id?: number;
  externKey?: string;
  text?: string;
  foreColor?: string;
  backColor?: string;
  active?: boolean;
  firstName?: string;
  lastName?: string;
  orderNo?: number;
  title?: string;
  personnelNumber: string;
  birthDate: string;
  gender?: string;
  entryDate: string;
  exitDate: string;
  checkboxes: string[];
  dutyDiff: number;
  room: RoomRefDto;
  departments: IMultiTagSelectItem[];
  teacherQualifications: IMultiTagSelectItem[];
  varStatus: number;
  varStatusStart: string;
  varStatusEnd: string;
  varQuota: number;
  varQuotaStart: string;
  varQuotaEnd: string;
  varSchool: TeacherVarSchoolDto[];
}

interface IProps {
  teacher?: any;
  store: TeacherStore;
}

const defaultHexColor = '#000000';

const QUOTA_MULTIPLIER = 100000;

const formTeacherToTeacherDto = (value: ITeacherForm, props: IProps, store: TeacherStore): TeacherDto => {
  const {
    name,
    externKey,
    text,
    foreColor,
    backColor,
    active,
    firstName,
    lastName,
    orderNo,
    title,
    personnelNumber,
    birthDate,
    gender,
    entryDate,
    exitDate,
    checkboxes,
    dutyDiff,
    room,
    teacherQualifications,
    departments,
    varStatus,
    varStatusStart,
    varStatusEnd,
    varQuota,
    varQuotaStart,
    varQuotaEnd,
    varSchool,
  } = value;
  const selectedStatus = store?.teacherStatuses.find((ts) => ts.id === parseInt(`${varStatus}`));
  const selectedRoom = store?.rooms.find((r) => r.id === parseInt(`${room}`));
  return {
    id: props.teacher?.id,
    name,
    externKey,
    text,
    active,
    foreColor: foreColor?.replace('#', ''),
    backColor: backColor?.replace('#', ''),
    firstName,
    lastName,
    orderNo,
    title,
    personnelNumber,
    birthDate: formatDateForServerRequest(birthDate),
    gender: gender ? (gender.toUpperCase() as Gender) : undefined,
    entryDate: formatDateForServerRequest(entryDate),
    exitDate: formatDateForServerRequest(exitDate),
    relevantForSalaryCalculation: checkboxes.includes('teacherIsRelevant'),
    dutyDiff,
    room: selectedRoom,
    departments: departments?.map(({ id, label }) => ({ id: parseInt(id), displayName: label })),
    teacherQualifications: teacherQualifications?.map(({ id, label }) => ({ id: parseInt(id), displayName: label })),
    varQuotas: [
      {
        quota: varQuota * QUOTA_MULTIPLIER,
        start: formatDateForServerRequest(varQuotaStart),
        end: formatDateForServerRequest(varQuotaEnd),
      },
    ],
    varStatuses: selectedStatus
      ? [
          {
            status: selectedStatus,
            start: formatDateForServerRequest(varStatusStart),
            end: formatDateForServerRequest(varStatusEnd),
          },
        ]
      : [],
    varSchools: varSchool || [],
  };
};

export const TeacherForm = observer((props: IProps) => {
  const { store } = props;
  let { teacher } = props;
  const history = useHistory();
  const modalStore = useStore(ModalStore);
  const notificationStore = useStore(NotificationStore);
  const { t } = useTranslation();
  const isInEditMode = !!teacher;
  const disabled = isInEditMode && !store.canEdit;

  const departmentItems: IMultiTagSelectItem[] = store.departments.map((department) => {
    return {
      id: department.id ? department.id.toString() : '',
      label: department.displayName ? department.displayName : '',
    };
  });
  const initialDepartments = teacher?.departments
    ? departmentItems.filter((department) =>
        teacher?.departments?.map((sg: any) => sg.id!.toString()).includes(department.id),
      )
    : [];

  const teacherQualificationItems: IMultiTagSelectItem[] = store.teacherQualifications.map((teacherQualification) => {
    return {
      id: teacherQualification.id ? teacherQualification.id.toString() : '',
      label: teacherQualification.displayName ? teacherQualification.displayName : '',
    };
  });
  const initialTeacherQualifications = teacher?.teacherQualifications
    ? teacherQualificationItems.filter((teacherQualification) =>
        teacher?.teacherQualifications?.map((sg: any) => sg.id!.toString()).includes(teacherQualification.id),
      )
    : [];
  const leftButtons: IFormButton[] =
    isInEditMode && store.canDelete
      ? [
          {
            label: t('general.delete'),
            onClick: () => {
              if (teacher?.id) {
                store.deleteTeacher(teacher.id);
              }
            },
          },
        ]
      : [];

  const rightButtons: IFormButton[] = [];
  if (!isInEditMode) {
    rightButtons.push({
      label: t('general.saveAndNew'),
      type: 'primary',
      disabled: store.submitAllDisabled,
      outline: true,
      onClick: () => {
        validateForm(store.form).then((isValid) => {
          if (isValid) {
            handleSaveNewTeacher(store.form.getFieldsValue(), true);
          }
        });
      },
    });
  }
  rightButtons.push({
    label: disabled ? t('general.ok') : t('general.cancel'),
    onClick: () => {
      modalStore.closeModal();
    },
  });

  const handleSaveNewTeacher = (value: ITeacherForm, saveAndNew: boolean) => {
    const teacherDto = formTeacherToTeacherDto(value, props, store);

    TeacherViewApi.createTeacher(teacherDto)
      .then((result) => {
        notificationStore.success({ title: t('general.teacherCreated') });
        store.onTeacherCreated(result.data);
        store.form.resetFields();
        if (!saveAndNew) {
          modalStore.closeModal();
        }
      })
      .catch((error: AxiosError) => {
        const responseError: IResponseError = getResponseError(error);
        if (responseError.type === ResponseErrorType.VALIDATION_ERROR) {
          handleValidationError(responseError);
        } else {
          notificationStore.error({ title: t('general.teacherCouldNotBeCreated'), message: error.toString() });
        }
      });
  };

  const handleChangeTeacher = (value: ITeacherForm) => {
    const teacherDto = formTeacherToTeacherDto(value, props, store);
    TeacherViewApi.updateTeacher(teacherDto)
      .then((result) => {
        notificationStore.success({ title: t('general.teacherEdited') });
        store.onTeacherChanged(result.data);
        modalStore.closeModal();
      })
      .catch((error) => {
        const responseError: IResponseError = getResponseError(error);
        if (responseError.type === ResponseErrorType.VALIDATION_ERROR) {
          handleValidationError(responseError);
        } else {
          notificationStore.error({ title: t('general.teacherCouldNotBeEdited'), message: error.toString() });
        }
      });
  };

  const handleValidationError = (error: IResponseError): void => {
    const validationErrors: IValidationError[] = error.payload as IValidationError[];
    for (const validationError of validationErrors) {
      if (validationError.path.includes('.name')) {
        store.form.setFields([{ name: 'name', errors: [t(validationError.errorMessage)] }]);
      }
    }
  };
  const initialFormCheckboxValues = [];
  let firstStatus: TeacherVarStatusDto | null = null;
  let firstQuota: TeacherVarQuotaDto | null = null;
  if (!teacher) {
    teacher = { active: true, name: '' };
  }
  if (teacher) {
    teacher.relevantForSalaryCalculation && initialFormCheckboxValues.push('teacherIsRelevant');
    firstStatus = teacher?.varStatuses && teacher?.varStatuses[0];
    firstQuota = teacher?.varQuotas && teacher?.varQuotas[0];
  }

  const initialStatus: ISelectItem | undefined = store.getInitialStatusByTeacherDto(teacher);
  const initialRoom: ISelectItem | undefined = store.getInitialRoomByTeacherDto(teacher);

  return (
    <WUForm<ITeacherForm>
      form={store.form}
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      fixedButtonBar={true}
      hideSubmitButton={disabled}
      onDisableChange={(disabled) => store.setSubmitAllDisabled(disabled)}
      onSubmit={isInEditMode ? handleChangeTeacher : (value) => handleSaveNewTeacher(value, false)}
      maxWidth
    >
      <Col {...deprecatedGetFormColProps({ span: 24 })}>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormSection {...FORM_ROW_PROPS} text={textTodo('Status')} />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormTaggedSwitch
              name="active"
              checked={teacher?.active}
              initialValue={teacher?.active}
              disabled={!store.canEdit}
              onLabel={t('general.active')}
              offLabel={t('general.inactive')}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 24 })}>
            <FormSection centered text={t('general.studentDetails.personalInformation')} />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormLabel text={'* ' + t('general.studentDetails.requiredField')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 8, sm: 16 })}>
            <FormInput
              name="name"
              testId="short-name-input"
              label={t('general.shortName') + '*'}
              variant="floating"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              initialValue={teacher?.name ? teacher.name : ''}
              rules={[
                { required: true, message: t('general.inputRequired') },
                {
                  max: 20,
                  message: t('general.inputTooLong'),
                },
              ]}
              disabled={disabled}
            />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 8, sm: 7, offset: 0, smOffset: 1 })}>
            <FormInput
              name="title"
              label={textTodo('Title')}
              variant="floating"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              initialValue={teacher?.title ? teacher.title : ''}
              rules={[
                {
                  max: 50,
                  message: t('general.inputTooLong'),
                },
              ]}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })} />
          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormInput
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              name="firstName"
              label={t('general.firstname') + '*'}
              variant="floating"
              initialValue={teacher?.firstName || ''}
              disabled={disabled}
              rules={[
                {
                  max: 255,
                  message: t('general.inputTooLong'),
                },
              ]}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })} />
          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormInput
              name="lastName"
              label={t('general.lastname') + '*'}
              variant="floating"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              initialValue={teacher?.lastName || ''}
              disabled={disabled}
              rules={[
                {
                  max: 60,
                  message: t('general.inputTooLong'),
                },
              ]}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormLabel text={textTodo('Teacher’s personnel number')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormInput
              name="personnelNumber"
              label={textTodo('Personnel number')}
              variant="floating"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              initialValue={teacher?.personnelNumber || ''}
              rules={[
                {
                  max: 255,
                  message: t('general.inputTooLong'),
                },
              ]}
              disabled={!store.canEdit}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormLabel text={t('general.studentDetails.externalIdHint')} />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormInput
              label={t('general.externalId')}
              variant="floating"
              name="externKey"
              initialValue={teacher?.externKey || ''}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormLabel text={textTodo('Additional information on the teacher')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormInput
              name="text"
              label={t('general.text')}
              variant="floating"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              initialValue={teacher?.text || ''}
              rules={[
                {
                  max: 255,
                  message: t('general.inputTooLong'),
                },
              ]}
              disabled={!store.canEdit}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 24 })}>
            <FormSection centered text={textTodo('Teaching details')} />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormLabel text={t('Room this teacher is assigned to')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormSingleSelect
              items={store?.rooms?.map((room) => {
                return {
                  id: room.id ? room.id.toString() : '',
                  label: room.displayName || '',
                };
              })}
              placeholder={textTodo('Room')}
              name="room"
              initialValue={initialRoom ? initialRoom.id : undefined}
              disabled={disabled}
              searchable
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormLabel text={textTodo('Department(s) this teacher is assigned to')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormMultiTagSelect
              name="departments"
              removable
              onAdd={createMultiSelectAddHandlerWithOptions({
                form: store.form,
                name: 'departments',
                items: departmentItems,
                title: t('general.addDepartments'),
                modalStore,
                redirectToCreate: () => history.push('/departments'),
              })}
              initialValue={initialDepartments}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormLabel text={textTodo('Assigned teaching qualification(s) of this teacher')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormMultiTagSelect
              name="teacherQualifications"
              removable
              onAdd={createMultiSelectAddHandlerWithOptions({
                form: store.form,
                name: 'teacherQualifications',
                items: teacherQualificationItems,
                title: t('general.addTeachingQualifications'),
                modalStore,
                redirectToCreate: () => history.push('/teaching-qualifications'),
              })}
              initialValue={initialTeacherQualifications}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormSection centered text={textTodo('Date of birth')} />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormDatePicker
              name="birthDate"
              label={textTodo('Date of birth (DD/MM/YYYY)')}
              initialValue={teacher?.birthDate ? dayjs(teacher?.birthDate) : undefined}
              disabled={disabled}
              removable={true}
              useWrapperWidth
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormSection centered text={t('general.entryDate')} />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormDatePicker
              name="entryDate"
              label={textTodo('Date of entry (DD/MM/YYYY)')}
              initialValue={teacher?.entryDate ? dayjs(teacher?.entryDate) : undefined}
              disabled={disabled}
              removable={true}
              useWrapperWidth
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormSection centered text={t('general.exitDate')} />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormDatePicker
              name="exitDate"
              label={textTodo('Date of exit (DD/MM/YYYY)')}
              initialValue={teacher?.exitDate ? dayjs(teacher?.exitDate) : undefined}
              disabled={disabled}
              removable={true}
              useWrapperWidth
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormLabel text={textTodo('Teacher status')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 4, sm: 6 })}>
            <FormSingleSelect
              items={store.teacherStatusItems}
              placeholder={textTodo('Status')}
              name="varStatus"
              initialValue={initialStatus ? initialStatus.id : undefined}
              disabled={disabled}
              searchable
            />
          </Col>

          <Col {...deprecatedGetFormColProps({ span: 6, offset: 0, sm: 8, smOffset: 1 })}>
            <FormDatePicker
              name="varStatusStart"
              label={textTodo('Date of start')}
              initialValue={firstStatus?.start ? dayjs(firstStatus.start) : undefined}
              disabled={disabled}
              removable={true}
              useWrapperWidth
            />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 6, offset: 0, sm: 8, smOffset: 1 })}>
            <FormDatePicker
              name="varStatusEnd"
              label={textTodo('Date of end')}
              initialValue={firstStatus?.end ? dayjs(firstStatus.end) : undefined}
              disabled={disabled}
              removable={true}
              useWrapperWidth
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormLabel text={textTodo('Quotas')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 4, sm: 6 })}>
            <FormInput
              name="varQuota"
              label={textTodo('Quota')}
              variant="floating"
              pattern={TextValidationPatterns.NUMBERS_ONLY}
              initialValue={firstQuota ? firstQuota.quota / QUOTA_MULTIPLIER : ''}
              rules={[
                {
                  max: 255,
                  message: t('general.inputTooLong'),
                },
              ]}
              disabled={!store.canEdit}
            />
          </Col>

          <Col {...deprecatedGetFormColProps({ span: 6, offset: 0, sm: 8, smOffset: 1 })}>
            <FormDatePicker
              name="varQuotaStart"
              label={textTodo('Date of start')}
              initialValue={firstQuota?.start ? dayjs(firstQuota.start) : undefined}
              disabled={disabled}
              removable={true}
              useWrapperWidth
            />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 6, offset: 0, sm: 8, smOffset: 1 })}>
            <FormDatePicker
              name="varQuotaEnd"
              label={textTodo('Date of end')}
              initialValue={firstQuota?.end ? dayjs(firstQuota.end) : undefined}
              disabled={disabled}
              removable={true}
              useWrapperWidth
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormLabel text={textTodo('Other activities')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormInput
              name="dutyDiff"
              label={textTodo('Percentage')}
              variant="floating"
              initialValue={teacher?.dutyDiff || ''}
              rules={[
                {
                  validator: (_: any, value: number) => {
                    if (value > 100 || value < 0 || !/^\d{0,3}$/.test(`${value}`)) {
                      return Promise.reject(t('masterData.examTypes.form.validation.numberValidationError'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              disabled={!store.canEdit}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormSection centered text={textTodo('Accounting relevancy')} />
          </Col>

          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormCheckboxes
              name="checkboxes"
              options={[
                {
                  label: textTodo('Teacher is relevant to accounting'),
                  value: 'teacherIsRelevant',
                },
              ]}
              initialValue={initialFormCheckboxValues}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormSection centered text={t('general.gender')} />
          </Col>

          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormRadioButtons
              display="vertical"
              name="gender"
              options={
                new Map([
                  ['female', t('general.female')],
                  ['male', t('general.male')],
                  ['inter', t('general.thirdGender')],
                ])
              }
              initialValue={teacher?.gender?.toLowerCase()}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })}>
            <FormSection text={t('general.colors')} />
          </Col>

          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormColorPicker
              name="foreColor"
              placeholder={t('general.foregroundColor')}
              initialValue={teacher?.foreColor ? '#' + teacher.foreColor : defaultHexColor}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 8 })} />
          <Col {...deprecatedGetFormColProps({ span: 16 })}>
            <FormColorPicker
              placeholder={t('general.backgroundColor')}
              name="backColor"
              initialValue={teacher?.backColor ? '#' + teacher.backColor : defaultHexColor}
              disabled={disabled}
            />
          </Col>
        </Row>
      </Col>
    </WUForm>
  );
});
