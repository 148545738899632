import { action, computed, observable } from 'mobx';
import { RefObject } from 'react';
import dayjs, { Dayjs } from 'dayjs';

/**
 * Determines if the time input manipulates the start time or the end time
 */
export enum TimeRangePickerMode {
  Start,
  End,
}

/**
 * Should represent a time slot defined in the time grid.
 * These slots are used to create buttons for a quick time range selection
 */
export interface ITimeRangePickerTimeSlot {
  label: string;
  startTime: Dayjs;
  endTime: Dayjs;
}

type TimeRangeChangeHandler = (startTime: Dayjs, endTime: Dayjs, mode: TimeRangePickerMode) => void;

export interface ITimeRangePickerStoreArgs {
  startTime: Dayjs;
  endTime: Dayjs;
  onTimeRangeChange?: TimeRangeChangeHandler;
  timeSlots?: ITimeRangePickerTimeSlot[];
  mode: TimeRangePickerMode;
  onlyEditOwnTimeSlot?: boolean; // defines if the Picker only should edit it's own time (based on the mode)
}

/**
 * Store that is responsible to handle the logic for the TimeRangePicker component
 */
class TimeRangePickerStore {
  @observable isInEditMode: boolean = false;
  @observable timeSlots: ITimeRangePickerTimeSlot[] = [];
  @observable initialStartTime: Dayjs = dayjs();
  @observable initialEndTime: Dayjs = dayjs();
  @observable closedByTimeSlotClick: boolean = false;
  onlyEditOwnTimeSlot = false;
  mode: TimeRangePickerMode;

  private _onTimeRangeChange: TimeRangeChangeHandler;

  constructor(args: ITimeRangePickerStoreArgs) {
    this._onTimeRangeChange = args.onTimeRangeChange
      ? args.onTimeRangeChange
      : () => {
          console.debug('no time range picker handler set');
        };
    this.timeSlots = args.timeSlots ? args.timeSlots : [];
    this.initialStartTime = args.startTime;
    this.initialEndTime = args.endTime;
    this.mode = args.mode;
    this.onlyEditOwnTimeSlot = !!args.onlyEditOwnTimeSlot;
  }

  @action
  onTimeRangeButtonClick = (startTime: Dayjs, endTime: Dayjs) => {
    if (this.onlyEditOwnTimeSlot) {
      if (this.mode === TimeRangePickerMode.Start) {
        this._onTimeRangeChange(dayjs(startTime), this.initialEndTime, this.mode);
      } else {
        this._onTimeRangeChange(this.initialStartTime, dayjs(endTime), this.mode);
      }
    } else {
      this._onTimeRangeChange(dayjs(startTime), dayjs(endTime), this.mode);
    }
  };

  @action
  onTimeChange = (newtime: Dayjs, mode: TimeRangePickerMode, oldStart: Dayjs, oldEnd: Dayjs) => {
    if (newtime.isValid()) {
      const newStartTime = mode === TimeRangePickerMode.Start ? newtime : dayjs(oldStart);
      const newEndTime = mode === TimeRangePickerMode.Start ? dayjs(oldEnd) : newtime;
      this._onTimeRangeChange(newStartTime, newEndTime, this.mode);
    }
  };

  @action
  toggleEditMode = () => {
    if (!this.isInEditMode) {
      this.closedByTimeSlotClick = false;
    }
    this.isInEditMode = !this.isInEditMode;
  };

  /**
   * Uses the initial start time to determine if the dayjs is localized in a 12h or 24h format.
   * Depending on the result the correct time-range-picker-input will be rendered and minor CSS adaptions are made.
   */
  @computed
  get is12HourFormat(): boolean {
    const formattedTime = this.initialStartTime.format('LT');
    return formattedTime.includes('AM') || formattedTime.includes('PM');
  }

  prefixZero = (value: string): string => {
    if (value.length === 1) {
      value = '0' + value;
    }
    return value;
  };

  /**
   *  Formats an amount of hours (0 to 24) and converts it to a equivalent
   *  12h hour format.
   */
  format24hTo12h = (hours: number): string => {
    if (hours > 12) {
      hours = hours - 12;
    }

    if (hours === 0) {
      return '12';
    }

    return this.prefixZero(String(hours));
  };

  selectRef = (ref: RefObject<HTMLInputElement>) => {
    setTimeout(() => {
      ref.current && ref.current.select();
    }, 1);
  };

  private getCurrentDayjsVariables = (): { locale: string; day: string; month: string; year: string } => {
    const locale = this.initialStartTime.locale();
    const now: Dayjs = dayjs();
    const day = this.prefixZero(now.date().toString());
    const month = this.prefixZero((now.month() + 1).toString()); // dayjs returns months from 0-11
    const year = now.year().toString();
    return { locale, day, month, year };
  };

  createNewDayjs12h = (hoursInputValue: string, minutesInputValue: string, meridiem: string): Dayjs => {
    const { locale, day, month, year } = this.getCurrentDayjsVariables();
    const hours: string = this.prefixZero(hoursInputValue);
    const minutes: string = this.prefixZero(minutesInputValue);

    const parse = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ' ' + meridiem;
    return dayjs(parse, 'YYYY-MM-DD HH:mm A').locale(locale);
  };

  createNewDayjs24h = (hoursInputValue: string, minutesInputValue: string): Dayjs => {
    const { locale, day, month, year } = this.getCurrentDayjsVariables();
    const hours: string = this.prefixZero(hoursInputValue);
    const minutes: string = this.prefixZero(minutesInputValue);

    const parse = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
    return dayjs(parse, 'YYYY-MM-DD HH:mm').locale(locale);
  };
}

export default TimeRangePickerStore;
