import { Dayjs } from 'dayjs';

export interface IInteraction {
  lastInteraction: Dayjs;
  nextSessionModalPrompt: Dayjs;
}

export const createInteractionInformation = (
  interactionTimeStamp: Dayjs,
  minutesForNextModal: number,
): IInteraction => {
  return {
    lastInteraction: interactionTimeStamp,
    nextSessionModalPrompt: calculateNextSessionModalPrompt(interactionTimeStamp, minutesForNextModal),
  };
};

export const calculateNextSessionModalPrompt = (lastUserInteraction: Dayjs, minutesForNextModal: number): Dayjs => {
  return lastUserInteraction.add(minutesForNextModal, 'minute');
};
