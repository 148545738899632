import { Layout } from 'antd';
import React, { ReactNode, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import ErrorBoundary from '@/components/error-boundary/error-boundary';
import LazyLoadingFallbackView from '@/components/lazy-loading-fallback-view/lazy-loading-fallback-view';
import { MenuItemEnum, SubMenuItemEnum } from '@/components/menu/menu-items';
import ProtectedRoute from '@/components/protected-route/protected-route';
import useStore from '@/hooks/useStore';
import CreateCalendarEntryPage from '@/pages/calendar-entry/create-calendar-entry/create-calendar-entry-page';
import LegacyWebuntis from '@/pages/legacy-webuntis/legacy-webuntis';
import ModalStore from '@/stores/modal-store';
import { TokenPermission } from '@/stores/token-store';
import { LAYOUT_NODE_ID } from '@/utils/layout-scroll/layout-scroll';
import legacyRoutesMap from '@/utils/router/legacy-routes-map';
import SpMetadataStore from '@sp/stores/sp-metadata-store';
import {
  ClassRegisterOverview,
  CR_OVERVIEW_ROUTE,
} from '@/pages/class-register/class-register-overview/class-register-overview';
import { AdministrationExamLock } from '@ad/exam-lock/administration-exam-lock';
import { MasterDataSubject } from '@/pages/master-data/subject/subject';
import MasterDataRooms from '@/pages/master-data/room/room';
import MasterDataTeachers from '@/pages/master-data/teacher/teacher';
import { MasterDataExamType } from '@/pages/master-data/exam-type/exam-type';
import { MasterDataBuilding } from '@/pages/master-data/building/building';
import { ClassRegisterHomework } from '@/pages/class-register/homework/homework';
import { MasterDataExcuseStatus } from '@/pages/master-data/excuse-status/excuse-status';
import { MasterDataStudent } from '@/pages/master-data/student/student';
import { MasterDataReasonsOfAbsence } from '@/pages/master-data/reasons-of-absence/reasons-of-absence';
import Exams from '@ls/exams/overview/exams';
import ExamStatistics from '@ls/exams-statistics/exams-statistics';
import { OpenPeriods } from '@/pages/class-register/open-periods/open-periods';
import LessonsSettings from '@ls/settings/lessons-settings';
import Timetable from '@te/standard/components/timetable';
import ParentTeacherDay from '@/pages/parent-teacher-day/parent-teacher-day';
import ErrorPage from '@/pages/error-page/error-page';
import { ContactHoursRegistrations } from '@/pages/contact-hours/contact-hours-registrations';
import { MasterDataReasonsOfExemption } from '@/pages/master-data/reasons-of-exemption/reasons-of-exemption';
import { MasterDataStudentDuties } from '@/pages/master-data/student-duties/student-duties';
import AdminMessagesOfTheDay from '@ad/messages-of-day/messages-of-day';
import Today from '@today/today';
import { LOCAL_STORAGE_PAGE_REFRESH_FORCED } from '@/types/local-storage-ids';
import ConfigStore from '@/stores/config-store';
import TimetableSettings from '@te/settings/timetable-settings';

const { Content } = Layout;

const Routes = React.memo(() => {
  const modalStore = useStore(ModalStore);
  const configStore = useStore(ConfigStore);
  const spMetadataStore = useStore(SpMetadataStore);
  const { t } = useTranslation();

  const renderPage = (content: ReactNode, initFunction?: () => void) => {
    modalStore.closeAll();
    const errorBoundaryUuid = v4();

    if (initFunction) {
      try {
        initFunction();
      } catch (error) {
        console.error(error);
        return (
          <ErrorPage
            title={t`general.errors.unexpectedError`}
            description={t`general.errors.clearCacheBeforeContactingSupport`}
            error={error}
            iconColor="primary"
          />
        );
      }
    }

    return (
      <Content id={LAYOUT_NODE_ID}>
        <ErrorBoundary key={errorBoundaryUuid}>{content}</ErrorBoundary>
      </Content>
    );
  };

  const lazyWithRetry = (componentImport: CallableFunction) =>
    React.lazy(async () => {
      const pageHasAlreadyBeenForceRefreshed = JSON.parse(
        window.localStorage.getItem(LOCAL_STORAGE_PAGE_REFRESH_FORCED) || 'false',
      );

      try {
        const component = await componentImport();
        window.localStorage.setItem(LOCAL_STORAGE_PAGE_REFRESH_FORCED, 'false');
        return component;
      } catch (error) {
        if (!pageHasAlreadyBeenForceRefreshed) {
          window.localStorage.setItem(LOCAL_STORAGE_PAGE_REFRESH_FORCED, 'true');
          return window.location.reload();
        }

        throw error;
      }
    });

  const Home = lazyWithRetry(() => import('@/pages/home/home'));
  const SubstitutionRequests = lazyWithRetry(() => import('@sp/substitution-requests'));
  const SubstitutionCounters = lazyWithRetry(() => import('@sp/substitution-counters'));
  const TeacherAbsenceReasons = lazyWithRetry(() => import('@sp/teacher-absence-reasons'));
  const SubstitutionPlanningSettings = lazyWithRetry(() => import('@sp/substitution-planning-settings'));
  const SubstitutionPlanning = lazyWithRetry(() => import('@sp/substitution-planning'));
  const IncomingMessages = lazyWithRetry(() =>
    import('@mg/index').then((module) => ({ default: module.IncomingMessages })),
  );
  const SentMessages = lazyWithRetry(() =>
    import('@mg/index').then((module) => ({
      default: module.SentMessages,
    })),
  );
  const DraftMessages = lazyWithRetry(() =>
    import('@mg/index').then((module) => ({
      default: module.DraftMessages,
    })),
  );
  const QuickFilters = lazyWithRetry(() =>
    import('@mg/index').then((module) => ({
      default: module.QuickFilters,
    })),
  );

  const Lectures = lazyWithRetry(() => import('@le/index'));

  const TTHorizons = lazyWithRetry(() =>
    import('@tt/index').then((module) => ({
      default: module.Horizons,
    })),
  );
  const TTTimetabling = lazyWithRetry(() => import('@tt/timetabling'));
  const TTLessonConstraints = lazyWithRetry(() => import('@tt/lesson-constraints'));
  const TTTimePreferences = lazyWithRetry(() => import('@tt/time-preferences'));
  const TTMasterDataConstraints = lazyWithRetry(() => import('@tt/master-data-constraints'));

  const PageNotFound = lazyWithRetry(() => import('@/pages/page-not-found/page-not-found'));
  const PlatformOverview = lazyWithRetry(() => import('@pa/components/platform-overview/platform-overview'));
  const PlatformApplicationRouterIframe = lazyWithRetry(
    () => import('@pa/components/platform-application-router-iframe/platform-application-router-iframe'),
  );

  function ErrorBoundaryTest() {
    throw new Error('Test Error. This Error should be thrown!');
    return null; // NOSONAR
  }

  return (
    <Suspense fallback={<LazyLoadingFallbackView />}>
      <Switch>
        <Route exact path="/" render={() => renderPage(<Home />)} />
        <Route exact path="/error-boundary-test" render={() => renderPage(<ErrorBoundaryTest />)} />
        <ProtectedRoute
          exact
          path="/substitution-planning"
          render={() => <Redirect to="/substitution-planning/substitutions" />}
          requiredTokenPermissions={[TokenPermission.SUBSTITUTION_PLANNING]}
        />
        <ProtectedRoute
          exact
          path="/substitution-planning/substitutions"
          render={() =>
            renderPage(<SubstitutionPlanning />, () => {
              spMetadataStore.init(false);
            })
          }
          requiredTokenPermissions={[TokenPermission.SUBSTITUTION_PLANNING]}
        />
        <ProtectedRoute
          path="/substitution-planning/counter-overview"
          render={() => renderPage(<SubstitutionCounters />)}
          requiredTokenPermissions={[TokenPermission.SUBSTITUTION_PLANNING]}
        />
        <ProtectedRoute
          path="/substitution-planning/teacher-absence-reasons"
          render={() => renderPage(<TeacherAbsenceReasons />)}
          requiredTokenPermissions={[TokenPermission.SUBSTITUTION_PLANNING]}
        />
        <ProtectedRoute
          path="/substitution-planning/settings"
          render={() => renderPage(<SubstitutionPlanningSettings />)}
          requiredTokenPermissions={[TokenPermission.SUBSTITUTION_PLANNING]}
        />
        <ProtectedRoute
          path="/substitution-planning/substitution-requests"
          render={() => renderPage(<SubstitutionRequests />)}
          requiredTokenPermissions={[TokenPermission.SP_OWN_ASK_TEACHER_REQUESTS]}
        />
        <ProtectedRoute
          path="/substitution-planning/my-absences"
          render={() =>
            renderPage(<SubstitutionPlanning />, () => {
              spMetadataStore.init(true);
            })
          }
          requiredTokenPermissions={[TokenPermission.OWN_TEACHER_ABSENCE_READ]}
        />
        <ProtectedRoute
          exact
          path="/messages"
          render={() => <Redirect to="/messages/inbox" />}
          requiredViewPermissions={[MenuItemEnum[MenuItemEnum.MESSAGE_CENTER_2021]]}
        />
        <ProtectedRoute
          exact
          path="/messages/inbox"
          render={() => renderPage(<IncomingMessages />)}
          requiredViewPermissions={[MenuItemEnum[MenuItemEnum.MESSAGE_CENTER_2021]]}
        />
        <ProtectedRoute
          path="/messages/sent"
          render={() => renderPage(<SentMessages />)}
          requiredViewPermissions={[SubMenuItemEnum[SubMenuItemEnum.MESSAGE_CENTER_2021_SENT_MESSAGES]]}
        />
        <ProtectedRoute
          path="/messages/drafts"
          render={() => renderPage(<DraftMessages />)}
          requiredViewPermissions={[SubMenuItemEnum[SubMenuItemEnum.MESSAGE_CENTER_2021_DRAFT_MESSAGES]]}
        />
        <ProtectedRoute
          path="/messages/lists"
          render={() => renderPage(<QuickFilters />)}
          requiredViewPermissions={[SubMenuItemEnum[SubMenuItemEnum.MESSAGE_CENTER_2021_LISTS]]}
        />
        {/* Redirect legacy messages pages to updated locations */}
        <ProtectedRoute
          exact
          path="/legacy-send-message"
          render={() => <Redirect to="/messages/inbox" />}
          requiredViewPermissions={[MenuItemEnum[MenuItemEnum.MESSAGE_CENTER_2021]]}
        />
        <ProtectedRoute
          path="/legacy-distribution-list"
          render={() => <Redirect to="/messages/lists" />}
          requiredViewPermissions={[SubMenuItemEnum[SubMenuItemEnum.MESSAGE_CENTER_2021_LISTS]]}
        />
        <ProtectedRoute
          path="/platform-overview"
          render={() => renderPage(<PlatformOverview />)}
          requiredViewPermissions={[SubMenuItemEnum[SubMenuItemEnum.PLATFORM_APPLICATION]]}
        />
        <ProtectedRoute
          path="/timetabling/lectures"
          render={() => renderPage(<Lectures />)}
          requiredViewPermissions={[SubMenuItemEnum[SubMenuItemEnum.TIMETABLING_LESSONS]]}
        />
        <ProtectedRoute
          path="/timetabling/timetables"
          render={() => renderPage(<TTHorizons />)}
          exact
          requiredViewPermissions={[SubMenuItemEnum[SubMenuItemEnum.TIMETABLING_TIMETABLES]]}
        />
        <ProtectedRoute
          path="/timetabling/timetables/:activeHorizonId/timetabling"
          render={() => renderPage(<TTTimetabling />)}
          requiredViewPermissions={[SubMenuItemEnum[SubMenuItemEnum.TIMETABLING_TIMETABLING]]}
        />
        <ProtectedRoute
          path="/timetabling/timetables/:activeHorizonId/lesson-constraints"
          render={() => renderPage(<TTLessonConstraints />)}
          requiredViewPermissions={[SubMenuItemEnum[SubMenuItemEnum.TIMETABLING_BLOCKS_AND_COUPLINGS]]}
        />
        <ProtectedRoute
          path="/timetabling/timetables/:activeHorizonId/time-requests"
          render={() => renderPage(<TTTimePreferences />)}
          requiredViewPermissions={[SubMenuItemEnum[SubMenuItemEnum.TIMETABLING_TIME_PREFERENCES]]}
        />
        <ProtectedRoute
          path="/timetabling/timetables/:activeHorizonId/master-data-constraints"
          render={() => renderPage(<TTMasterDataConstraints />)}
          requiredViewPermissions={[SubMenuItemEnum[SubMenuItemEnum.TIMETABLING_MASTER_DATA_CONSTRAINTS]]}
        />

        <Route path="/new-calendar-entry" render={() => renderPage(<CreateCalendarEntryPage />)} />
        <Route
          path="/platform-application/:name"
          render={(props) => renderPage(<PlatformApplicationRouterIframe {...props} />)}
        />
        <Route path={CR_OVERVIEW_ROUTE} render={() => renderPage(<ClassRegisterOverview />)} />
        <Route path="/today-new" render={() => renderPage(<Today />)} />
        <ProtectedRoute path="/messages-of-the-day-new" render={() => renderPage(<AdminMessagesOfTheDay />)} />
        <Route path="/timetable-new/my" render={() => <Redirect to={configStore.myTimetablePath} />} />
        <Route path="/timetable-new/settings" render={() => renderPage(<TimetableSettings />)} />
        <Route path="/timetable-new/:entityType" render={() => renderPage(<Timetable />)} />
        <Route path="/subjects" render={() => renderPage(<MasterDataSubject />)} />
        <Route path="/rooms-new" render={() => renderPage(<MasterDataRooms />)} />
        <Route path="/teachers-new" render={() => renderPage(<MasterDataTeachers />)} />
        <Route path="/excuse-status" render={() => renderPage(<MasterDataExcuseStatus />)} />
        <Route path="/types-of-examinations" render={() => renderPage(<MasterDataExamType />)} />
        <Route path="/buildings" render={() => renderPage(<MasterDataBuilding />)} />
        <Route path="/homework" render={() => renderPage(<ClassRegisterHomework />)} />
        <Route path="/students-new" render={() => renderPage(<MasterDataStudent />)} />
        <Route path="/reasons-of-absence" render={() => renderPage(<MasterDataReasonsOfAbsence />)} />
        <Route path="/open-periods" render={() => renderPage(<OpenPeriods />)} />
        <Route path="/exams" render={() => renderPage(<Exams />)} />
        <Route path="/exam-statistics" render={() => renderPage(<ExamStatistics />)} />
        <Route path="/lessons-settings" render={() => renderPage(<LessonsSettings />)} />
        <Route path="/reasons-of-exemptions" render={() => renderPage(<MasterDataReasonsOfExemption />)} />
        <Route path="/services" render={() => renderPage(<MasterDataStudentDuties />)} />
        <Route path="/exam-blockings" render={() => renderPage(<AdministrationExamLock />)} />
        <Route path="/parent-teacher-day/:id" render={() => renderPage(<ParentTeacherDay />)} />
        <Route path="/contact-hours/registrations/:personId" render={() => renderPage(<ContactHoursRegistrations />)} />
        {/* Legacy routes should be after the actual routes, because Switch will take the first one it matches */}
        {Array.from(legacyRoutesMap.keys()).map((key) => (
          <Route
            path={key}
            key={key}
            render={() => {
              modalStore.closeAll();
              return <LegacyWebuntis path={key} />;
            }}
          />
        ))}
        <Route
          path="/not-authorized"
          render={() => {
            const title = t('general.noRights');
            const description = t('general.contactAdmin');
            return renderPage(<ErrorPage title={title} description={description} iconColor="error" />);
          }}
        />
        <Route render={() => renderPage(<PageNotFound />)} />
      </Switch>
    </Suspense>
  );
});
export default Routes;
