/* eslint-disable max-len */
import {
  MessageFilterItemDto,
  MessageFilterType,
  MessageFilterResponseDto,
  MessageUserRole,
  SectionType,
} from '@untis/wu-rest-view-api';

export const filters: MessageFilterItemDto[] = [
  {
    type: MessageFilterType.QUICK,
    items: [
      'Custom 1-AHELE',
      'Custom 1-AHELE V2',
      'Flos Liste 1',
      'Flos Liste 2',
      'Flos Liste 4',
      'Klassenlehrer',
      'Lehrer',
      'New List',
      'Schüler',
      'test',
    ],
  },
  {
    type: MessageFilterType.CLASS,
    items: ['1-AHELE', '1-BHELE', '2-AHELE', '2-BHELE', '3-AHELE', '3-BHELE', '4-HELE'],
  },
  { type: MessageFilterType.DEPARTMENT, items: ['A1', 'A2'] },
  {
    type: MessageFilterType.ROLE,
    items: [
      'Administration',
      'Andere',
      'Erziehungsberechtigter',
      'Klasse',
      'Lehrkräfte',
      'Lehrlingsbeauftragter',
      'Schulleitung',
      'Schüler*innen',
      'System',
      'Verwaltung',
      'Öffentlich',
    ],
  },
  {
    type: MessageFilterType.USER_GROUP,
    items: [
      'Admin',
      'Apprentice Repr',
      'Directorate',
      'Legal Guardian',
      'Other',
      'Parent',
      'Staff',
      'Student',
      'System',
      'Teacher',
    ],
  },
];

export const data: MessageFilterResponseDto = {
  users: [
    {
      id: 187,
      displayName: 'Baumgartner',
      role: MessageUserRole.TEACHER,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/wxjkMbDaVAWGqZ876xKO690GyWwd1DbmZa8nYPqzpmK3ZVJ58QBPLo6EdGewxX9JcON1abWyrORjMDAqkg0Y792lnMJklQoBgrA3Eej2L7pV5zRE9lyKRo0Y5J1BgLeO?school=demo01',
      tags: [],
    },
    {
      id: 8,
      displayName: 'Beck Niklas',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/ke2B7wdKEZzAYObPRnOR7zEx0ZrjJMG1kY2Vmp6o6ELkd8BpagZjWV9bnROGYXn2C3XwQ52yxlJDrzMAeK10Pq73mb9APeyw3BDW5aog8QlLKdqG5onqJ1Q6WgyxaM9p?school=demo01',
      tags: [],
    },
    {
      id: 14,
      displayName: 'Berger Maximilian',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/REWbxpmrD8yBjl5MAmg5rZKbAQ2ekl9zwLaEPB68kaqdErz3nPWy7mY1eGgAVvwDCzXpbJZ9oDx562KLQOBl0wMjRO1o8jWJqY3MV7ydxnRGp0DLkwad39eVKQ7OnJP2?school=demo01',
      tags: [],
    },
    {
      id: 24,
      displayName: 'Frank Max',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/6YVnmkrbZOMBDPWqKGo0Kb2q8YZyz3Lw1mA5V7k28Vl0MbkAnqdR1amQp6ZKLvAACKvzr9wPJxWOYEjDo57Gye3BgMPEjDBaRJlWnx9OdQep6grJd2ge1pzjEy93wao7?school=demo01',
      tags: [],
    },
    {
      id: 26,
      displayName: 'Friedrich Marc',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/2rdEyAq8YL07JmD1WkVAJEDd5GaPxmB9qO0eywZ6AljWoKZ7wgpMxRakdYQGe4ZDC6XzP0DJn2VBO59by38qLrE1moMgnz28W3lbp6R1QKjLr7YMaQVeBPkzn5boGORw?school=demo01',
      tags: [],
    },
    {
      id: 28,
      displayName: 'Fuchs Leni',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/LkWl2Y9jDq5mK7gMwn5ByEVladDOrY9bmMg3q81Ajz96d0V8yp1alLbBER7Gkvm3CnNQOnregwx2oP5MqWZDJmKY3KkpJ07jzR2ZQGe6AoxPLWwrGAeBnboxV061aPZR?school=demo01',
      tags: [],
    },
    {
      id: 31,
      displayName: 'Günther Marie',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/qOrYBQpJLkZyE5a10eV5oPAELmdjGR98OD6Y13ZwGODQ1bqYERle0ozmxrkK6X3rCQv5AaBy8LngP7JpWdV3j9MZ2rnybzMlpWkQ7g0wBJaqx2Km387ng9dwe2RDWbzV?school=demo01',
      tags: [],
    },
    {
      id: 33,
      displayName: 'Hahn Lucas',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/m8k9yJdxaWw5pGb0YYMxB37RP1WnepokGE0yOAbVAPjbr51kdEOn0YlGLBgZw4gdCdv8maKx376poqRWz2y9MJQeD6awz5QD98qZlj2grKJmVdLjZq7OMAQ2rP3B1Rez?school=demo01',
      tags: [],
    },
    {
      id: 47,
      displayName: 'Huber Lea',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/WDY8JZzReO6PGa0wo7LDlG95wnoYWKJVB1meORyDE8Zd6a3wQ97orx52kPYBqX7oCExX0mWjMOgLbzylVnJRKAGe1pPdx6kZ8qrEQa0Mg23jpzbApxng3kLEQK2Vbmjq?school=demo01',
      tags: [],
    },
    {
      id: 50,
      displayName: 'Jung Luca',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/y8dxVzrkA16WmapwbAzPpyoKnM0d962WZwJ8maxkPlm2dWprEGJO7ogzy3BKwXdGCwDvZ1AQjnbYx8D6Rqe05MLV9a57eqgkQl1YGEjBVR3ODLbrYMQD35JLRB9KoG2P?school=demo01',
      tags: [],
    },
    {
      id: 53,
      displayName: 'Kaiser Lena',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/Qrn893GE0OpZYwAdD1kQ2KnAGMdelgL90YqO738LPrRO9oVbyJ3KGEqB2xkQzvDPC3yvDla7jm18gdAY6w5npe0ZMWpjmbazwVrDyZxRoWJ6BPE5Kq12B7WjxPbyoM6R?school=demo01',
      tags: [],
    },
    {
      id: 56,
      displayName: 'Keller Maria',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/2GYDQR7A6EnoVzkgd18eg7rmqWJYLKZ2l6kB0oVkdQAJBlDLa7yM35obW1qzKvePCEP4VeOwmR8xgpGYnjr2E6P09ZpRjG3zwDbdynxMEOaQAP59Baq350xylOKZ98Pe?school=demo01',
      tags: [],
    },
    {
      id: 75,
      displayName: 'Lang Leonie',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/qOrYBQpJLkZyE5a10eV5oPAELmdjGR98OD6Y13ZwGODQ1bqYERle0ozmxrkK6X3rC6Qv5AaBy8LngP7JpWdV3j9MZ2rnybzMlpWkQ7g0wBJaqx2Km387ng9dwe2RDWbz?school=demo01',
      tags: [],
    },
    {
      id: 88,
      displayName: 'Mayer Laura',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/DpQdZJej6lRK57O2a96qkywdjJRVO01mP7QlKxYOPy2GB3AVbQx15lrRawqEzNoWCnZNZ76Mnm9eYKLkDpjJo0W8gdDMBp5oAagnE8b23WGZrLezbk9B8GwgzPxV3yMr?school=demo01',
      tags: [],
    },
    {
      id: 98,
      displayName: 'Möller Lisa',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/xEjMelgz8do056nWr98Qzx6nq7VWPdpRyg3GjLAK7yWdYeV2pGDwlZBro5zjkXPmCYRN1RxEL6P83bmaA9OgJqMnQ0okDmO0ErKMa5eYZl21wBJbZbw9B2KL3kPp1RO7?school=demo01',
      tags: [],
    },
    {
      id: 109,
      displayName: 'Peters Leon',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/ke2B7wdKEZzAYObPRnOR7zEx0ZrjJMG1kY2Vmp6o6ELkd8BpagZjWV9bnROGYXn2C9PXwQ52yxlJDrzMAeK10Pq73mb9APeyw3BDW5aog8QlLKdqG5onqJ1Q6WgyxaM9?school=demo01',
      tags: [],
    },
    {
      id: 116,
      displayName: 'Roth Moritz',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/m1ZzMK9nE3jlgYexGeDMpVBykmKnx80g1zRj6JOoxWVK2L5Ob6A9paYr8BgE3vRDCJzNeMZjJkPm0D7wndqlGQ1RyzZ7QrwqlbGPa9d2WoLYE35AO07Dr2RyJAPL5V6w?school=demo01',
      tags: [],
    },
    {
      id: 137,
      displayName: 'Scholz Lina',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/gdDVx62GJopaYQRe7PVnE7rwLxQMo0bK2Y3kly1WDBZlM6982AkGoadyrYEx3NGGCplN57zP0eObwqLpnJgmVQRj1K89qeD6mpjGgBZRaJWzA5dO0EL9l1y5BnZqPObM?school=demo01',
      tags: [],
    },
    {
      id: 144,
      displayName: 'Schubert Luis',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/92AnRDLYm6d1k8xg38eY9J0LwzgRrAkWoPn5OMjLxazlyjoGYdAM8qknbVpmJvjlC9pN937QZPwR5g1DB6eOE2K0WrZ6aEKQDl3Vb7xyG2dmpB1qyr5eb7Z0VMWOapoq?school=demo01',
      tags: [],
    },
    {
      id: 157,
      displayName: 'Vogel Maja',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/2GYDQR7A6EnoVzkgd18eg7rmqWJYLKZ2l6kB0oVkdQAJBlDLa7yM35obW1qzKvePCm74VeOwmR8xgpGYnjr2E6P09ZpRjG3zwDbdynxMEOaQAP59Baq350xylOKZ98Pe?school=demo01',
      tags: [],
    },
    {
      id: 172,
      displayName: 'Weiß Louisa',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/MgQJLpnGP0wO9kem5WoR5yleBEGwq6x9bmD0YQrJ29gKpk73OAYWnLMDz6RyPN88CpgNZQ58oGmwrqEaj1lVxdB0beMjAdL73O18JkzZnPVaK2gpV8DEy3j6xdrZRbY2?school=demo01',
      tags: [],
    },
    {
      id: 176,
      displayName: 'Winkler Mia',
      role: MessageUserRole.STUDENT,
      imageUrl:
        'http://webuntis.dod/WebUntis/api/public/image/qOrYBQpJLkZyE5a10eV5oPAELmdjGR98OD6Y13ZwGODQ1bqYERle0ozmxrkK6X3rC9ov5AaBy8LngP7JpWdV3j9MZ2rnybzMlpWkQ7g0wBJaqx2Km387ng9dwe2RDWbz?school=demo01',
      tags: [],
    },
  ],
};

export const classStudentViewStorePersons = [
  {
    id: 8,
    className: '1-AHELE',
    displayName: 'Bauer Emil',
  },
  {
    id: 11,
    className: '4-HELE',
    displayName: 'Bauer Noah',
  },
  {
    id: 14,
    className: '3-AHELE',
    displayName: 'Bauer Sara',
  },
  {
    id: 17,
    className: '4-HELE',
    displayName: 'Beck Leni',
  },
  {
    id: 20,
    className: '1-BHELE',
    displayName: 'Beck Niklas',
  },
  {
    id: 26,
    className: '1-AHELE',
    displayName: 'Becker Carla',
  },
  {
    id: 29,
    className: '4-HELE',
    displayName: 'Becker Max',
  },
  {
    id: 32,
    className: '3-AHELE',
    displayName: 'Becker Oskar',
  },
  {
    id: 35,
    className: '4-HELE',
    displayName: 'Berger Laura',
  },
  {
    id: 38,
    className: '1-BHELE',
    displayName: 'Berger Maximilian',
  },
  {
    id: 44,
    className: '4-HELE',
    displayName: 'Braun Sofia',
  },
  {
    id: 47,
    className: '1-AHELE',
    displayName: 'Fischer Anton',
  },
  {
    id: 50,
    className: '4-HELE',
    displayName: 'Fischer Louisa',
  },
  {
    id: 53,
    className: '4-HELE',
    displayName: 'Fischer Maja',
  },
  {
    id: 56,
    className: '3-AHELE',
    displayName: 'Fischer Mia',
  },
  {
    id: 65,
    className: '4-HELE',
    displayName: 'Frank Lara',
  },
  {
    id: 68,
    className: '1-BHELE',
    displayName: 'Frank Max',
  },
  {
    id: 71,
    className: '3-AHELE',
    displayName: 'Friedrich Julia',
  },
  {
    id: 74,
    className: '1-BHELE',
    displayName: 'Friedrich Marc',
  },
  {
    id: 80,
    className: '1-BHELE',
    displayName: 'Fuchs Leni',
  },
  {
    id: 77,
    className: '3-AHELE',
    displayName: 'Fuchs Lisa',
  },
  {
    id: 83,
    className: '3-AHELE',
    displayName: 'Günther Klara',
  },
  {
    id: 89,
    className: '1-BHELE',
    displayName: 'Günther Marie',
  },
  {
    id: 86,
    className: '3-AHELE',
    displayName: 'Hahn Jakob',
  },
  {
    id: 95,
    className: '1-BHELE',
    displayName: 'Hahn Lucas',
  },
  {
    id: 92,
    className: '3-AHELE',
    displayName: 'Hahn Maria',
  },
  {
    id: 110,
    className: '1-AHELE',
    displayName: 'Herrmann Julian',
  },
  {
    id: 119,
    className: '1-AHELE',
    displayName: 'Hoffmann Daniel',
  },
  {
    id: 122,
    className: '4-HELE',
    displayName: 'Hoffmann Mia',
  },
  {
    id: 98,
    className: '3-AHELE',
    displayName: 'Hoffmann Paula',
  },
  {
    id: 137,
    className: '1-BHELE',
    displayName: 'Huber Lea',
  },
  {
    id: 101,
    className: '3-AHELE',
    displayName: 'Jung Hanna',
  },
  {
    id: 146,
    className: '1-BHELE',
    displayName: 'Jung Luca',
  },
  {
    id: 107,
    className: '3-AHELE',
    displayName: 'Jung Marc',
  },
  {
    id: 155,
    className: '1-BHELE',
    displayName: 'Kaiser Lena',
  },
  {
    id: 125,
    className: '3-AHELE',
    displayName: 'Keller Julian',
  },
  {
    id: 164,
    className: '1-BHELE',
    displayName: 'Keller Maria',
  },
  {
    id: 170,
    className: '1-AHELE',
    displayName: 'Klein Emily',
  },
  {
    id: 173,
    className: '4-HELE',
    displayName: 'Klein Paul',
  },
  {
    id: 131,
    className: '3-AHELE',
    displayName: 'Klein Sofia',
  },
  {
    id: 182,
    className: '1-AHELE',
    displayName: 'Koch Elias',
  },
  {
    id: 185,
    className: '4-HELE',
    displayName: 'Koch Niklas',
  },
  {
    id: 134,
    className: '3-AHELE',
    displayName: 'Koch Rafael',
  },
  {
    id: 194,
    className: '1-AHELE',
    displayName: 'Köhler Julia',
  },
  {
    id: 203,
    className: '1-AHELE',
    displayName: 'König Klara',
  },
  {
    id: 221,
    className: '1-BHELE',
    displayName: 'Lang Leonie',
  },
  {
    id: 143,
    className: '3-AHELE',
    displayName: 'Lang Luca',
  },
  {
    id: 236,
    className: '1-AHELE',
    displayName: 'Lehmann Hanna',
  },
  {
    id: 242,
    className: '4-HELE',
    displayName: 'Lorenz Leon',
  },
  {
    id: 251,
    className: '1-AHELE',
    displayName: 'Maier Jonathan',
  },
  {
    id: 260,
    className: '1-BHELE',
    displayName: 'Mayer Laura',
  },
  {
    id: 269,
    className: '1-AHELE',
    displayName: 'Meyer Ben',
  },
  {
    id: 272,
    className: '4-HELE',
    displayName: 'Meyer Lucas',
  },
  {
    id: 275,
    className: '4-HELE',
    displayName: 'Meyer Maria',
  },
  {
    id: 149,
    className: '3-AHELE',
    displayName: 'Meyer Niklas',
  },
  {
    id: 290,
    className: '1-BHELE',
    displayName: 'Möller Lisa',
  },
  {
    id: 152,
    className: '3-AHELE',
    displayName: 'Möller Luis',
  },
  {
    id: 296,
    className: '1-AHELE',
    displayName: 'Müller Alexander',
  },
  {
    id: 299,
    className: '4-HELE',
    displayName: 'Müller Leonie',
  },
  {
    id: 161,
    className: '3-AHELE',
    displayName: 'Müller Marie',
  },
  {
    id: 314,
    className: '1-AHELE',
    displayName: 'Neumann Florian',
  },
  {
    id: 317,
    className: '4-HELE',
    displayName: 'Neumann Rafael',
  },
  {
    id: 323,
    className: '1-BHELE',
    displayName: 'Peters Leon',
  },
  {
    id: 176,
    className: '3-AHELE',
    displayName: 'Peters Louisa',
  },
  {
    id: 332,
    className: '1-AHELE',
    displayName: 'Richter Emilia',
  },
  {
    id: 335,
    className: '4-HELE',
    displayName: 'Richter Oskar',
  },
  {
    id: 188,
    className: '3-AHELE',
    displayName: 'Richter Simon',
  },
  {
    id: 341,
    className: '4-HELE',
    displayName: 'Roth Lena',
  },
  {
    id: 344,
    className: '1-BHELE',
    displayName: 'Roth Moritz',
  },
  {
    id: 362,
    className: '1-AHELE',
    displayName: 'Schmid Jakob',
  },
  {
    id: 368,
    className: '1-AHELE',
    displayName: 'Schmidt Amelie',
  },
  {
    id: 371,
    className: '4-HELE',
    displayName: 'Schmidt Lina',
  },
  {
    id: 200,
    className: '3-AHELE',
    displayName: 'Schmidt Max',
  },
  {
    id: 386,
    className: '1-AHELE',
    displayName: 'Schneider Anna',
  },
  {
    id: 389,
    className: '4-HELE',
    displayName: 'Schneider Lisa',
  },
  {
    id: 392,
    className: '4-HELE',
    displayName: 'Schneider Luis',
  },
  {
    id: 215,
    className: '3-AHELE',
    displayName: 'Schneider Maximilian',
  },
  {
    id: 407,
    className: '1-BHELE',
    displayName: 'Scholz Lina',
  },
  {
    id: 218,
    className: '3-AHELE',
    displayName: 'Scholz Lucas',
  },
  {
    id: 416,
    className: '1-AHELE',
    displayName: 'Schröder Felix',
  },
  {
    id: 419,
    className: '4-HELE',
    displayName: 'Schröder Philipp',
  },
  {
    id: 224,
    className: '3-AHELE',
    displayName: 'Schubert Jonas',
  },
  {
    id: 428,
    className: '1-BHELE',
    displayName: 'Schubert Luis',
  },
  {
    id: 434,
    className: '1-AHELE',
    displayName: 'Schulz Charlotte',
  },
  {
    id: 437,
    className: '4-HELE',
    displayName: 'Schulz Maximilian',
  },
  {
    id: 227,
    className: '3-AHELE',
    displayName: 'Schulz Paul',
  },
  {
    id: 446,
    className: '1-AHELE',
    displayName: 'Schulze Jonas',
  },
  {
    id: 455,
    className: '1-AHELE',
    displayName: 'Schwarz Emil',
  },
  {
    id: 458,
    className: '1-AHELE',
    displayName: 'Schwarz Gabriel',
  },
  {
    id: 461,
    className: '4-HELE',
    displayName: 'Schwarz Sara',
  },
  {
    id: 350,
    className: '1-AHELE',
    displayName: 'Schäfer David',
  },
  {
    id: 353,
    className: '4-HELE',
    displayName: 'Schäfer Moritz',
  },
  {
    id: 191,
    className: '3-AHELE',
    displayName: 'Schäfer Philipp',
  },
  {
    id: 233,
    className: '3-AHELE',
    displayName: 'Vogel Jonathan',
  },
  {
    id: 467,
    className: '1-BHELE',
    displayName: 'Vogel Maja',
  },
  {
    id: 473,
    className: '1-AHELE',
    displayName: 'Wagner Benjamin',
  },
  {
    id: 476,
    className: '4-HELE',
    displayName: 'Wagner Marie',
  },
  {
    id: 248,
    className: '3-AHELE',
    displayName: 'Wagner Noah',
  },
  {
    id: 485,
    className: '1-AHELE',
    displayName: 'Walter Lara',
  },
  {
    id: 491,
    className: '1-AHELE',
    displayName: 'Weber Antonia',
  },
  {
    id: 494,
    className: '4-HELE',
    displayName: 'Weber Luca',
  },
  {
    id: 497,
    className: '4-HELE',
    displayName: 'Weber Marc',
  },
  {
    id: 257,
    className: '3-AHELE',
    displayName: 'Weber Moritz',
  },
  {
    id: 512,
    className: '1-BHELE',
    displayName: 'Weiß Louisa',
  },
  {
    id: 278,
    className: '3-AHELE',
    displayName: 'Weiß Maja',
  },
  {
    id: 521,
    className: '4-HELE',
    displayName: 'Winkler Lea',
  },
  {
    id: 524,
    className: '1-BHELE',
    displayName: 'Winkler Mia',
  },
  {
    id: 530,
    className: '1-AHELE',
    displayName: 'Wolf Emma',
  },
  {
    id: 533,
    className: '4-HELE',
    displayName: 'Wolf Paula',
  },
  {
    id: 287,
    className: '3-AHELE',
    displayName: 'Wolf Sophia',
  },
  {
    id: 545,
    className: '1-AHELE',
    displayName: 'Zimmermann Greta',
  },
  {
    id: 548,
    className: '4-HELE',
    displayName: 'Zimmermann Simon',
  },
];

export const classStudentViewStoreSections = [
  {
    groups: [
      {
        groupId: 'CLASS23',
        title: '1-AHELE',
        subtitle: undefined,
        details: undefined,
        personIds: [
          194, 386, 455, 8, 458, 203, 332, 269, 530, 473, 26, 350, 416, 545, 485, 296, 170, 362, 491, 236, 110, 47, 368,
          434, 182, 119, 314, 251, 446,
        ],
      },
    ],
    sectionType: SectionType.CLASSTEACHER,
  },
  {
    groups: [
      {
        groupId: '473',
        title: '1-AHELE',
        subtitle: undefined,
        details: 'History',
        personIds: [
          194, 386, 455, 8, 458, 203, 332, 269, 530, 473, 26, 350, 416, 545, 485, 296, 170, 362, 491, 236, 110, 47, 368,
          434, 182, 119, 314, 251, 446,
        ],
      },
      {
        groupId: '383',
        title: '1-AHELE,1-BHELE',
        subtitle: undefined,
        details: 'Chemistry',
        personIds: [
          512, 386, 260, 8, 137, 524, 269, 146, 530, 20, 407, 26, 155, 416, 545, 290, 164, 38, 296, 170, 428, 47, 434,
          182, 314, 446, 194, 323, 68, 455, 74, 458, 203, 332, 80, 467, 344, 89, 473, 221, 350, 95, 485, 362, 491, 236,
          110, 368, 119, 251,
        ],
      },
      {
        groupId: '611',
        title: '1-BHELE',
        subtitle: 'Wk_1b',
        details: 'Works',
        personIds: [80, 20, 68, 38, 74],
      },
      {
        groupId: '488',
        title: '3-AHELE',
        subtitle: undefined,
        details: 'Mathematics',
        personIds: [
          257, 131, 134, 71, 200, 77, 14, 143, 83, 149, 86, 278, 215, 152, 218, 92, 287, 32, 224, 161, 98, 227, 101,
          233, 107, 176, 56, 248, 188, 125, 191,
        ],
      },
      {
        groupId: '449',
        title: '4-HELE',
        subtitle: undefined,
        details: 'Geometry1',
        personIds: [
          65, 389, 392, 521, 11, 461, 335, 272, 17, 275, 341, 533, 476, 29, 353, 35, 419, 548, 299, 44, 173, 494, 497,
          50, 242, 371, 53, 437, 185, 122, 317,
        ],
      },
      {
        groupId: '452',
        title: '4-HELE',
        subtitle: undefined,
        details: 'Geometry2',
        personIds: [
          65, 389, 392, 521, 11, 461, 335, 272, 17, 275, 341, 533, 476, 29, 353, 35, 419, 548, 299, 44, 173, 494, 497,
          50, 242, 371, 53, 437, 185, 122, 317,
        ],
      },
    ],
    sectionType: SectionType.SUBSTITUTIONS,
  },
];
