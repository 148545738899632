import { Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import { generatePath, useRouteMatch } from 'react-router-dom';
import { Dayjs } from 'dayjs';

import CalendarEntryDetailViewStore from '../calendar-entry-detail-view/calendar-entry-detail-view-store';

import CalendarEntryDetailForm from './calendar-entry-detail-form/calendar-entry-detail-form';

import { CalendarDtoStatusEnum, MessageRecipientOption } from '@untis/wu-rest-view-api';
import { IFrameHandleMessagePayload } from '@/components/embedded-webuntis/embedded-webuntis';
import WuPageIframe from '@/components/embedded-webuntis/wu-page-iframe/wu-page-iframe';
import useIframeMessageCallback from '@/hooks/useIframeMessageCallback';
import useStore from '@/hooks/useStore';
import {
  CalendarEntryDetailsTab,
  DEFAULT_TAB,
  ITabData,
  parseTabData,
  toRouteTabParam,
} from '@/pages/calendar-entry/calendar-entry-detail-view/calendar-entry-detail-tab-meta';
import RouterStore from '@/stores/router-store';
import PlatformApplicationIframe from '@pa/components/platform-application-iframe/platform-application-iframe';
import { HandleMessageAction } from '@sp/stores/post-message-store';
import { CalendarEntryDetailsHeader } from '@/ui-components';
import { HorizontalMenu, IHorizontalMenuItem } from '@/ui-components/horizontal-menu/horizontal-menu';
import { TestIds } from '@/testIds';
import SendMessageViewStore from '@/pages/messages/stores/send-message-view-store';
import './calendar-entry-detail-view.less';
import { IDeprecatedOpenModalProps } from '@/stores/modal-store';

export interface IPeriodIdData {
  periodId: number;
}

export interface ITimeSlotArguments {
  initialSelectedPeriodId: number;
  initialIsBlockSelected: boolean;
  elementId: number;
  elementType: number;
  startDateTime: Dayjs;
  endDateTime: Dayjs;
}

interface IProps {
  data: IPeriodIdData | ITimeSlotArguments;
  tab?: string;
  embedDetailsIframe?: boolean;
}

export function buildCalendarEntryModalDialogProps(
  data: IPeriodIdData | ITimeSlotArguments,
  tab: string,
  onClose?: () => void,
): IDeprecatedOpenModalProps {
  return {
    content: <CalendarEntryDetailView data={data} tab={tab} />,
    size: 'full-size',
    closable: true,
    onClose,
  };
}

const CalendarEntryDetailView = observer((props: IProps) => {
  const { t } = useTranslation();
  const routerStore = useStore(RouterStore);
  const sendMessageViewStore = useStore(SendMessageViewStore);
  const routeMatch = useRouteMatch<{ [tab: string]: string }>();

  const routeMatchParams = (routeMatch.params as any)[0];
  const idParam: string | undefined = routeMatchParams ? (routeMatch.params as any)[0].split('/')[0] : undefined;
  const dateParam: string | undefined = routeMatchParams ? (routeMatch.params as any)[0].split('/')[1] : undefined;

  const [store] = useState<CalendarEntryDetailViewStore>(
    () =>
      new CalendarEntryDetailViewStore(
        props.data,
        parseTabData(props.tab || routeMatch.params.tab),
        idParam,
        dateParam,
        props.embedDetailsIframe,
      ),
  );
  const [isOpen, setIsOpen] = useState(false); // we need to replicate the open state to set some css...
  const detailsClassName = useMemo(() => {
    return store.selectedTab === CalendarEntryDetailsTab.DETAILS ? 'display-initial' : 'display-none';
  }, [store.selectedTab]);

  useIframeMessageCallback(HandleMessageAction.SCROLL, (payload: IFrameHandleMessagePayload | undefined) =>
    store.setClassregScrollTop(payload?.payload.scrollTop),
  );
  useIframeMessageCallback(HandleMessageAction.OPEN_SEND_MESSAGE_DIALOG, (payload) => {
    const userIds = payload?.payload.targetIds;
    sendMessageViewStore.openSendMessageView({
      recipientOption: MessageRecipientOption.CUSTOM,
      initialRecipientsUserIds: userIds,
    });
  });

  const withRoutingFn = (routingFn: (path: string) => void) => {
    return {
      selectTab: (tabData: ITabData) => {
        // select tab either in "routing way" or directly with store action
        // as far as this component rendering can be invoked by the router or directly (modal store)
        if (routeMatch.params.tab) {
          const newTabParam = toRouteTabParam(tabData);
          newTabParam !== routeMatch.params.tab &&
            routingFn(generatePath(routeMatch.path, Object.assign({}, routeMatch.params, { tab: newTabParam })));
        } else {
          store.selectTab(tabData);
        }
      },
    };
  };

  // Order of the following 2 effect hooks is important!
  // First one is responsible for tab selection according to the tab route param.
  useEffect(() => {
    if (routeMatch.params.tab && routeMatch.params.tab !== store.selectedTabRouteParam) {
      store.selectTab(parseTabData(routeMatch.params.tab));
    }
  }, [routeMatch.params.tab, store]);

  // This effect is responsible for navigation to the default tab in case pre-selected tab is not allowed, invalid
  // or tab route param is out of the sync with the store.
  useEffect(() => {
    if (
      store.isReady &&
      (!store.isSelectedTabAllowed || (routeMatch.params.tab && routeMatch.params.tab !== store.selectedTabRouteParam))
    ) {
      withRoutingFn(routerStore.routing.replace).selectTab({ tab: DEFAULT_TAB });
    }
  });

  if (!store.isReady || !store.isSelectedTabAllowed) {
    return null;
  }

  const handleTabSelection = (tab: CalendarEntryDetailsTab): void => {
    const platformApplicationString: string = CalendarEntryDetailsTab.PLATFORM_APPLICATION;
    const isPlatformApplication = tab.includes(platformApplicationString);

    const tabString: string = tab;
    // The platform application id is appended with '-xyz'
    const platformApplicationId = isPlatformApplication
      ? Number.parseInt(tabString.substring(platformApplicationString.length + 1))
      : undefined;
    withRoutingFn(routerStore.routing.push).selectTab({
      tab: isPlatformApplication ? CalendarEntryDetailsTab.PLATFORM_APPLICATION : tab,
      platformApplicationId,
    });
  };

  let className = 'calendar-entry-detail-view';
  className = className + (store.selectedCalendarEntry?.status === CalendarDtoStatusEnum.CANCELLED ? ' cancelled' : '');
  className = className + (isOpen ? ' isOpen' : '');

  const selectedMenuKey =
    store.selectedTab + (store.selectedTab === 'platform-application' ? `-${store.selectedPlatformApplicationId}` : '');

  const menuItems: IHorizontalMenuItem<CalendarEntryDetailsTab>[] = [];

  if (store.hasClassRegister) {
    menuItems.push({
      label: t('menu.menuItems.classRegister.classRegister'),
      key: CalendarEntryDetailsTab.CLASS_REGISTER,
    });
  }

  menuItems.push({
    label: t('general.details'),
    key: CalendarEntryDetailsTab.DETAILS,
    icon: store.hasNotes ? 'note' : undefined,
  });

  if (store.hasStudentPeriodAssignment) {
    menuItems.push({
      label: t('general.studentPeriodAssignment'),
      key: CalendarEntryDetailsTab.STUDENT_PERIOD_ASSIGNMENT,
    });
  }

  if (store.showStudentTab) {
    menuItems.push({
      label: t('general.students'),
      key: CalendarEntryDetailsTab.STUDENTS,
    });
  }

  menuItems.push(
    ...store.platformApplicationMenuItems.map((item) => {
      return {
        label: item.name,
        key: (CalendarEntryDetailsTab.PLATFORM_APPLICATION + '-' + item.id) as CalendarEntryDetailsTab,
      };
    }),
  );

  return (
    <Div100vh className={className}>
      {store.isCancelled && (
        <Row className="header-row cancelled-banner">
          <div className="cancelled-banner">{t('general.cancelled').toUpperCase()}</div>
        </Row>
      )}
      {!store.hideTopSection && (
        <div className="top-section">
          <Row className="header-row">
            <div className="header-container">
              <CalendarEntryDetailsHeader
                minimized={store.isMinimized}
                entryData={store.entryData}
                onSelect={(periodId, isBlockSelected) => {
                  store.setSelectedPeriodId(periodId);
                  store.setIsBlockSelected(isBlockSelected);
                  store.fetchPlatformApplicationMenuItems();
                }}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                idParam={idParam}
                dateParam={dateParam}
              />
            </div>
          </Row>
          <Row className="menu-row">
            <HorizontalMenu<CalendarEntryDetailsTab>
              onTabSelect={(tab) => handleTabSelection(tab)}
              isLoading={store.isIframeLoading}
              selectedKeys={[selectedMenuKey]}
              menuItems={menuItems}
              testId={TestIds.CALENDAR_ENTRY_DETAILS_MENU}
            />
          </Row>
        </div>
      )}
      <div className="lesson-detail-content">
        <div className={detailsClassName}>
          <CalendarEntryDetailForm detailViewStore={store} />
        </div>
        {store.iFrameRequired && (
          <WuPageIframe
            onStartLoad={() => store.setIframeIsLoading(true)}
            onFinishLoad={() => store.setIframeIsLoading(false)}
            page={store.iFramePage}
            loadingText={store.iFrameLoadingText}
            hide={store.isIframeHidden}
            img={store.iFrameLoadingImage}
            showSidebar={false}
          />
        )}
        {store.platformApplicationMenuItems.map((item) => (
          <PlatformApplicationIframe key={item.id} itemId={item.id} detailViewStore={store} />
        ))}
      </div>
    </Div100vh>
  );
});

export default CalendarEntryDetailView;
