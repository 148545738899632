import { action, computed, observable } from 'mobx';
import { t } from 'i18next';

import { inject, IStore, Store } from '@/types/store';
import { ExamSettingsViewApi } from '@/stores/api-store';
import { ExamSettingsDto } from '@untis/wu-rest-view-api/api';
import NotificationStore from '@/stores/notification-store/notification-store';
import { DateRange } from '@/ui-components/wu-form/form-date-range-picker/form-date-range-picker';
import { formatDateForServerRequest } from '@/utils/date/date-util';

export interface ILessonsSettings {
  dateRange?: DateRange;
  allowRoomConflicts: string[];
  exportDaysInFuture: string;
  exportOnlyRelevant: string[];
}

@Store()
export class LessonsSettingsStore implements IStore {
  private notificationStore = inject(NotificationStore);

  @observable private _isLoading: boolean = true;
  @observable private _examSettings: ExamSettingsDto | undefined;

  @action
  async fetchData() {
    try {
      const response = await ExamSettingsViewApi.getExamSettings();
      this._examSettings = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      this._isLoading = false;
    }
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @computed
  get examSettings(): ExamSettingsDto | undefined {
    return this._examSettings;
  }

  @action
  updateExamSettings(settings: ILessonsSettings): Promise<void> {
    const examSettingsDto: ExamSettingsDto = {
      start: settings.dateRange?.start !== undefined ? formatDateForServerRequest(settings.dateRange.start) : undefined,
      end: settings.dateRange?.end !== undefined ? formatDateForServerRequest(settings.dateRange.end) : undefined,
      roomConflictsAllowed: settings.allowRoomConflicts.includes('allowRoomConflicts'),
      exportDaysInFuture: parseInt(settings.exportDaysInFuture),
      exportOnlySubstitutionRelevant: settings.exportOnlyRelevant.includes('exportOnlyRelevant'),
    };

    return ExamSettingsViewApi.updateExamSettings(examSettingsDto)
      .then(async () => {
        await this.fetchData();
        this.notificationStore.success({ title: t('lessons.settings.messages.saved') });
      })
      .catch((error) => {
        this.notificationStore.error({ title: t('general.errors.error'), message: error.toString() });
      });
  }

  @action
  reset() {
    this._isLoading = true;
    this._examSettings = undefined;
  }
}
