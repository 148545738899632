import { t } from 'i18next';
import { AxiosError } from 'axios';

import { IValidationError } from '@/types/validation-error';
import { ErrorMessageEnum, httpStatusCodeToErrorMessageMapping } from '@/utils/error-handling/error-message';
import HttpStatusCode from '@/types/http-status-code';

export interface IResponseError {
  type: ResponseErrorType;
  payload: string | IValidationError[];
}

export interface IErrorResponseData {
  errorCode: string;
  errorMessage: string;
  validationErrors: string;
}

export enum ResponseErrorType {
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  BAD_REQUEST = 'BAD_REQUEST',
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  UNKNOWN = 'UNKNOWN',
}

const httpStatusCodeToResponseErrorTypeMapping: Map<HttpStatusCode, ResponseErrorType> = new Map([
  [HttpStatusCode.BAD_REQUEST, ResponseErrorType.BAD_REQUEST],
  [HttpStatusCode.UNAUTHORIZED, ResponseErrorType.UNAUTHORIZED],
  [HttpStatusCode.FORBIDDEN, ResponseErrorType.FORBIDDEN],
  [HttpStatusCode.NOT_FOUND, ResponseErrorType.NOT_FOUND],
  [HttpStatusCode.INTERNAL_SERVER_ERROR, ResponseErrorType.INTERNAL_SERVER_ERROR],
]);

export function getResponseError(error: Error): IResponseError {
  const axiosError = error as AxiosError;

  if (!axiosError.response) {
    return {
      type: ResponseErrorType.UNKNOWN,
      payload: ErrorMessageEnum.ERROR,
    };
  }

  const statusCode = axiosError.response.status;

  const isBadRequest = statusCode === HttpStatusCode.BAD_REQUEST;
  const hasValidationErrorCode =
    (axiosError.response.data as IErrorResponseData)?.errorCode === ResponseErrorType.VALIDATION_ERROR;

  // special treatment for 400 bad request errors, useful in forms
  if (isBadRequest && hasValidationErrorCode) {
    return {
      type: ResponseErrorType.VALIDATION_ERROR,
      payload: mapAxiosErrorToValidationErrors(axiosError),
    };
  }

  // if there is a errorMessage from the server we show that
  const type = httpStatusCodeToResponseErrorTypeMapping.get(statusCode) ?? ResponseErrorType.UNKNOWN;
  if ((axiosError.response.data as IErrorResponseData)?.errorMessage) {
    return {
      type,
      payload: (axiosError.response.data as IErrorResponseData).errorMessage,
    };
  }

  // otherwise we show the generic error message for that status code
  const errorMessageLabel =
    httpStatusCodeToErrorMessageMapping.get(statusCode as HttpStatusCode) ?? ErrorMessageEnum.ERROR;

  return {
    type,
    payload: t(errorMessageLabel),
  };
}

function mapAxiosErrorToValidationErrors(error: AxiosError): IValidationError[] {
  const responseErrors = (error.response?.data as IErrorResponseData)?.validationErrors;
  if (isValidationErrors(responseErrors)) {
    return responseErrors;
  } else {
    console.error('Response validation errors have invalid format.');
    return [];
  }
}

export function getErrorMessageFromResponseError(error: Error): string | undefined {
  const responseError = getResponseError(error);
  if (responseError.type !== ResponseErrorType.VALIDATION_ERROR) {
    return responseError.payload as string;
  }
}

export function isValidationErrors(errors: any): errors is IValidationError[] {
  if (!(errors instanceof Array)) {
    return false;
  }

  return errors.every((error: any) => {
    return error.path !== undefined && error.errorMessage !== undefined;
  });
}
