import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { WUForm } from '@/ui-components';
import useStore from '@/hooks/useStore';
import {
  AdministrationExamLockStore,
  IExamLockForm,
  OPTION_TYPE_ALL,
  OPTION_TYPE_SELECT,
} from '@ad/exam-lock/administration-exam-lock-store';
import { IFormButton } from '@/ui-components/wu-form/wu-form';
import { createMultiSelectAddHandler, validateForm } from '@/utils/form/form-util';
import ModalStore from '@/stores/modal-store';
import { WUFormCol } from '@/ui-components/wu-form/wu-form-col/wu-form-col';
import { WUFormRow } from '@/ui-components/wu-form/wu-form-row/wu-form-row';
import { FormSection } from '@/ui-components/wu-form/form-section/form-section';
import { FormLabel } from '@/ui-components/wu-form/form-label/form-label';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { FormMultiTagSelect } from '@/ui-components/wu-form/form-multi-tag-select/form-multi-tag-select';
import { FormCheckboxes } from '@/ui-components/wu-form/form-checkboxes/form-checkboxes';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import { TimegridStore } from '@/stores/timegrid-store';
import { FormDateTimeRangePickerSchoolYear } from '@/ui-components/wu-form/form-date-time-range-picker-school-year/form-date-time-range-picker-school-year';
import ExamLockConflictTable from '@ad/exam-lock/exam-lock-conflict-table';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import ConfigStore from '@/stores/config-store';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import { ExamLockDto } from '@untis/wu-rest-view-api/api';

import './administration-exam-lock-form.less';

interface IProps {
  store: AdministrationExamLockStore;
  examLock?: ExamLockDto;
}

export const AdministrationExamLockForm = observer((props: IProps) => {
  const modalStore = useStore(ModalStore);
  const timegridStore = useStore(TimegridStore);
  const rightStore = useStore(RightsStore);
  const configStore = useStore(ConfigStore);
  const { t } = useTranslation();

  const { store, examLock } = props;
  const isInEditMode = !!examLock;
  const disabled = isInEditMode && !configStore.isAdmin && !rightStore.canWrite(Right.EXAMLOCK, ElementType.ALL, false);

  const initialClassSelectionType: string = (examLock?.classes?.length ?? 0) > 0 ? OPTION_TYPE_SELECT : OPTION_TYPE_ALL;

  useComponentDidMount(() => {
    store.setClassSelectionType(initialClassSelectionType);
    store.getExamLockFormData(examLock);
  });

  const leftButtons: IFormButton[] =
    isInEditMode && (configStore.isAdmin || rightStore.canDelete(Right.EXAMLOCK, ElementType.ALL, false))
      ? [
          {
            label: t('general.delete'),
            onClick: () => {
              if (examLock?.id) {
                store.deleteExamLock(examLock.id);
              }
            },
          },
        ]
      : [];

  const rightButtons: IFormButton[] = [];
  if (!isInEditMode) {
    rightButtons.push({
      label: t('general.saveAndNew'),
      type: 'primary',
      disabled: store.submitAllDisabled,
      outline: false,
      onClick: () => {
        validateForm(store.form).then((isValid) => {
          if (isValid) {
            store.saveNewExamLock(store.form.getFieldsValue(), true);
          }
        });
      },
    });
  }
  rightButtons.push({
    label: disabled ? t('general.ok') : t('general.cancel'),
    onClick: () => {
      modalStore.closeModal();
    },
  });

  const initialClasses = examLock?.classes
    ? store.classes.filter((klasse) => examLock.classes?.map((c) => c.id.toString()).includes(klasse.id))
    : [];

  const initialSettingsCheckboxValues = [];
  if (!examLock || examLock.continuous) {
    initialSettingsCheckboxValues.push('continuous');
  }

  const initialExamType: string = examLock?.examType?.id.toString() ?? OPTION_TYPE_ALL;
  const initialWeekDay: string = examLock?.weekDay?.toString() ?? OPTION_TYPE_ALL;

  if (store.isFormLoading) {
    return <EmptyIndicator title={t('general.loading')} />;
  }

  return (
    <WUForm<IExamLockForm>
      form={store.form}
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      fixedButtonBar={true}
      hideSubmitButton={disabled}
      onDisableChange={(disabled: boolean) => store.setSubmitAllDisabled(disabled)}
      onSubmit={
        isInEditMode
          ? (value: IExamLockForm) => store.updateExamLock(value, examLock)
          : (value: IExamLockForm) => store.saveNewExamLock(value, false)
      }
      onValuesChange={(changedValues) => {
        if (changedValues.classSelectionType) {
          store.setClassSelectionType(changedValues.classSelectionType);
        }
      }}
      maxWidth
      paddingTop
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={2}>
            <div className="administration-exam-lock-form">
              <FormSection text={t('general.classes')} />
              {t('administration.examLock.form.classesDescription')}
            </div>
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormSingleSelect
              name="classSelectionType"
              items={store.classSelectionTypes}
              initialValue={initialClassSelectionType.toString()}
              disabled={disabled}
              allowClear={false}
            />
          </WUFormCol>
        </WUFormRow>

        {store.classSelectionType !== OPTION_TYPE_ALL ? (
          <WUFormRow>
            <WUFormCol lg={2} />
            <WUFormCol lg={2}>
              <FormMultiTagSelect
                name="classes"
                removable
                onAdd={createMultiSelectAddHandler(
                  store.form,
                  'classes',
                  store.classes,
                  t('general.classes'),
                  modalStore,
                )}
                initialValue={initialClasses}
                disabled={disabled}
              />
            </WUFormCol>
          </WUFormRow>
        ) : null}

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.examinationType')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormSingleSelect
              name="examType"
              items={store.examTypes}
              initialValue={initialExamType.toString()}
              disabled={disabled}
              allowClear={false}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.date')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormDateTimeRangePickerSchoolYear
              name="dateTimeRange"
              initialValue={store.getInitialDateTimeRangePickerValue(examLock)}
              disabled={disabled}
              timeSlots={timegridStore.timeRangePickerTimeSlots}
              rules={[
                { required: true, message: t('general.inputRequired') },
                {
                  message: t('administration.examLock.form.dateTimeValidationMessage'),
                  validator(rule, dateTimeRange) {
                    const { startDateTime, endDateTime } = dateTimeRange;
                    if (endDateTime.isSameOrBefore(startDateTime)) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.settings')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormCheckboxes
              name="settings"
              options={[
                {
                  label: t('general.continuous'),
                  value: 'continuous',
                },
              ]}
              initialValue={initialSettingsCheckboxValues}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('administration.examLock.form.dayOfTheWeek')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormSingleSelect
              name="weekDay"
              items={store.weekDays}
              initialValue={initialWeekDay.toString()}
              disabled={disabled}
              allowClear={false}
              customSorting={(a, b) => {
                const firstIndex = store.weekDays.findIndex((w) => w.id === a.id);
                const secondIndex = store.weekDays.findIndex((w) => w.id === b.id);
                return firstIndex - secondIndex;
              }}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.text')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput name="text" initialValue={examLock?.text ? examLock.text : ''} disabled={disabled} />
          </WUFormCol>
        </WUFormRow>

        {store.examLockConflicts.length > 0 ? (
          <WUFormRow>
            <WUFormCol lg={2}>
              <FormSection text={t('general.conflicts')} centered />
            </WUFormCol>
            <WUFormCol lg={1}>
              <ExamLockConflictTable conflictExams={store.examLockConflicts} store={store} />
            </WUFormCol>
          </WUFormRow>
        ) : null}

        {examLock && (
          <WUFormRow>
            <WUFormCol lg={1}>
              <FormLabel
                text={t('general.bookedOnByUser', {
                  date: dayjs(examLock.created).format('MMM Do, YYYY h:mm A'),
                  user: examLock.createdUser?.displayName,
                })}
              />
            </WUFormCol>
            <WUFormCol lg={1}>
              <FormLabel
                text={t('general.changedOnByUser', {
                  date: dayjs(examLock.modified).format('MMM Do, YYYY h:mm A'),
                  user: examLock.modifiedUser?.displayName,
                })}
              />
            </WUFormCol>
          </WUFormRow>
        )}
      </WUFormCol>
    </WUForm>
  );
});
