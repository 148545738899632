import React from 'react';
import { action, computed, observable } from 'mobx';
import { t } from 'i18next';

import { AbstractListViewStore } from '@md/common/abstract-list-view-store';
import { TimetableSettingsViewApi, TimetableViewApi } from '@/stores/api-store';
import {
  EntryResourceTypeEnum,
  TimeGridTypeEnum,
  Timespan,
  TimetableFormatSettingsDto,
  TimetableIntegrationDto,
  WeekdayEnum,
} from '@untis/wu-rest-view-api/api';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import TimetableFormatSettingsForm from '@te/settings/timetable-format/timetable-format-settings-form';
import { TimeRange } from '@/ui-components/wu-form/form-time-range-picker/form-time-range-picker';
import { formatTimeForServerRequest, toDayJsFirstDayOfWeek } from '@/utils/date/date-util';
import { IWeekDaySelection } from '@te/settings/timetable-format/form-components/weekdays-picker/form-weekdays-picker';
import { NumberSetting } from '@/stores/settings-store';
import { IListViewAction } from '@/components/list-view/list-view';

export interface ITimetableFormatFormData {
  name: string;
  showDetails: boolean;
  showUnannouncedExams: boolean;
  showSubjectColors: boolean;
  timeGridType: string;
  timeGridSlotSettings: string[];
  lessonCardLayout: EntryResourceTypeEnum[];
  weekdays: IWeekDaySelection;
  duration: TimeRange;
  periodCardSettings: string[];
  additionalActivities: string[];
  periodCardHighlight: string[];
}

export interface ITimetableFormatRow {
  key: number;
  name: string;
  showDetails: boolean;
  showUnannouncedExams: boolean;
  showSubjectColors: boolean;
  timeGridType: TimeGridTypeEnum;
  showTimeInGrid: boolean;
  showPeriodName: boolean;
  showPeriodNumber: boolean;
  days: WeekdayEnum[];
  isTimeGridDays: boolean;
  duration: Timespan;
  showTimeInCard: boolean;
  showLessonText: boolean;
  showUser: boolean;
  showInfo: boolean;
  showUnconfirmedBookings: boolean;
  showCancelledPeriods: boolean;
  showStandByPeriods: boolean;
  showOfficeHours: boolean;
  showExternalCalendars: boolean;
  cardLayout: EntryResourceTypeEnum[];
  highlightChanges: boolean;
  highlightExams: boolean;
  highlightCancelledPeriods: boolean;
  highlightExternalCalendars: boolean;
  showSymbols: boolean;
}

export const SHOW_TIME_IN_GRID = 'SHOW_TIME_IN_GRID';
export const SHOW_PERIOD_NUMBER = 'SHOW_PERIOD_NUMBER';
export const SHOW_PERIOD_NAME = 'SHOW_PERIOD_NAME';

export const SHOW_TIME_IN_CARD = 'SHOW_TIME_IN_CARD';
export const SHOW_LESSON_TEXT = 'SHOW_LESSON_TEXT';
export const SHOW_USER = 'SHOW_USER';
export const SHOW_INFO = 'SHOW_INFO';

export const SHOW_UNCONFIRMED_BOOKINGS = 'SHOW_UNCONFIRMED_BOOKINGS';
export const SHOW_CANCELLED_PERIODS = 'SHOW_CANCELLED_PERIODS';
export const SHOW_STANDBY_PERIODS = 'SHOW_STANDBY_PERIODS';
export const SHOW_OFFICE_HOURS = 'SHOW_OFFICE_HOURS';
export const SHOW_EXTERNAL_CALENDARS = 'SHOW_EXTERNAL_CALENDARS';

export const HIGHLIGHT_CHANGES = 'HIGHLIGHT_CHANGES';
export const HIGHLIGHT_EXAMS = 'HIGHLIGHT_EXAMS';
export const HIGHLIGHT_CANCELLED_PERIODS = 'HIGHLIGHT_CANCELLED_PERIODS';
export const HIGHLIGHT_EXTERNAL_CALENDARS = 'HIGHLIGHT_EXTERNAL_CALENDARS';
export const SHOW_SYMBOLS = 'SHOW_SYMBOLS';

export default class TimetableFormatSettingsStore extends AbstractListViewStore<ITimetableFormatFormData> {
  @observable private _timetableFormats: TimetableFormatSettingsDto[] = [];
  @observable private _externalCalendarIntegrations: TimetableIntegrationDto[] = [];

  constructor() {
    super();
  }

  @action
  async fetchData() {
    this.setIsDataLoading(true);
    try {
      const formats = await TimetableSettingsViewApi.getTimetableFormatList();
      this._timetableFormats = formats.data.timetableFormats;

      const externalCalendarIntegrations = await TimetableViewApi.getTimetableIntegrations(false);
      this._externalCalendarIntegrations = externalCalendarIntegrations.data.integrations ?? [];
    } catch (error) {
      this._notificationStore.error({
        title: '',
        message: error.toString(),
      });
    } finally {
      this.setIsMetaLoading(false);
      this.setIsDataLoading(false);
    }
  }

  @computed
  get firstDayOfWeek(): number | undefined {
    const webUntisFirstDayOfWeek = this._settingsStore.getNumberSetting(NumberSetting.FIRST_DAY_OF_WEEK);
    if (webUntisFirstDayOfWeek) {
      return toDayJsFirstDayOfWeek(Number(webUntisFirstDayOfWeek));
    }
    return undefined;
  }

  @computed
  get externalCalendarIntegrations(): TimetableIntegrationDto[] {
    return this._externalCalendarIntegrations;
  }

  @computed
  get timetableFormatRows(): ITimetableFormatRow[] {
    return this._timetableFormats.map(this.mapDtoToRowItem);
  }

  @computed
  get columns(): Columns<ITimetableFormatRow> {
    const cols: Columns<ITimetableFormatRow> = [
      {
        type: ColumnType.Text,
        header: t('general.name'),
        key: 'name',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          return a.name.localeCompare(b.name);
        },
      },
    ];

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.HoverActions,
        key: 'actions',
        actionIcons: this.columnActions,
      });
    }

    return cols;
  }

  @computed
  private get columnActions() {
    const actions = [];

    actions.push({
      tooltip: t('general.copy'),
      onClick: (row: ITimetableFormatRow) => this.duplicateTimetableFormat(row.key),
      icon: 'copy',
    });

    actions.push({
      tooltip: t('general.edit'),
      onClick: (row: ITimetableFormatRow) => this.openTimetableFormatForm(row),
      icon: 'edit',
    });

    actions.push({
      tooltip: t('general.delete'),
      onClick: (row: ITimetableFormatRow) => this.deleteTimetableFormat(row.key),
      icon: 'shared_trash',
    });

    return actions;
  }

  @computed
  get listViewActions(): IListViewAction[] {
    const actions: IListViewAction[] = [];
    actions.push({
      label: t('general.new'),
      onClick: () => {
        (async () => {
          await this.openTimetableFormatForm(undefined);
        })();
      },
    });
    return actions;
  }

  @action.bound
  async openTimetableFormatForm(row: ITimetableFormatRow | undefined) {
    if (row) {
      const format = await TimetableSettingsViewApi.getTimetableFormat(row.key);
      this._modalStore.openModalDialog({
        title: t('general.editTimetableFormat'),
        size: 'full-size',
        children: <TimetableFormatSettingsForm store={this} format={this.mapDtoToRowItem(format.data)} />,
        containsForm: true,
      });
    } else {
      this._modalStore.openModalDialog({
        title: t('general.newTimetableFormat'),
        size: 'full-size',
        children: <TimetableFormatSettingsForm store={this} />,
        containsForm: true,
      });
    }
  }

  @action
  async duplicateTimetableFormat(id: number) {
    const formatToDuplicate = this._timetableFormats.find((f) => f.id === id);
    if (formatToDuplicate) {
      try {
        const duplicationResult = await TimetableSettingsViewApi.createTimetableFormat(this.copyDto(formatToDuplicate));
        this._notificationStore.success({ title: t('general.formatDuplicated') });
        this._modalStore.closeModal();
        this.onTimetableFormatCreated(duplicationResult.data);
      } catch (error) {
        this._notificationStore.error({
          title: '',
          message: error.toString(),
        });
      }
    }
  }

  @action
  async deleteTimetableFormat(id: number) {
    const formatToDelete = this._timetableFormats.find((tf) => tf.id === id);
    const isConfirmed = await this._modalStore.openDeletePrompt(
      t('general.deleteFormatConfirmationTitle'),
      t('general.deleteFormatConfirmationQuestion', { formatName: formatToDelete?.name ?? '' }),
    );

    if (isConfirmed) {
      try {
        await TimetableSettingsViewApi.deleteTimetableFormat(id);
        this._notificationStore.success({ title: t('general.formatDeleted') });
        this._modalStore.closeModal();
        this.onTimetableFormatDeleted([id]);
      } catch (error) {
        this._notificationStore.error({
          title: '',
          message: error.toString(),
        });
      }
    }
  }

  @action
  async updateTimetableFormat(formData: ITimetableFormatFormData, id: number) {
    const formatToEdit = this._timetableFormats.find((tf) => tf.id === id);
    if (formatToEdit) {
      const editedFormat = this.mapToDto(formData, formatToEdit);
      try {
        const updatedFormat = await TimetableSettingsViewApi.updateTimetableFormat(id, editedFormat);
        this._notificationStore.success({ title: t('general.timetableFormatEdited') });
        this._modalStore.closeModal();
        this.onTimetableFormatEdited(updatedFormat.data);
      } catch (error) {
        this._notificationStore.error({
          title: '',
          message: error.toString(),
        });
      }
    }
  }

  @action
  async createTimetableFormat(formData: ITimetableFormatFormData) {
    const newFormat = this.mapToDto(formData);
    try {
      const createdFormat = await TimetableSettingsViewApi.createTimetableFormat(newFormat);
      this._notificationStore.success({ title: t('general.formatCreated') });
      this._modalStore.closeModal();
      this.onTimetableFormatCreated(createdFormat.data);
    } catch (error) {
      this._notificationStore.error({
        title: '',
        message: error.toString(),
      });
    }
  }

  @action
  private onTimetableFormatDeleted(deletedTimetableFormatIds: number[]) {
    this._timetableFormats = this._timetableFormats.filter(
      (t) => t.id && !deletedTimetableFormatIds.some((idToDelete) => t.id === idToDelete),
    );
  }

  @action
  private onTimetableFormatEdited(format: TimetableFormatSettingsDto) {
    const cachedDto: TimetableFormatSettingsDto | undefined = this._timetableFormats.find((tf) => tf.id === format.id);
    if (cachedDto) {
      Object.assign(cachedDto, format);
    }
  }

  @action
  private onTimetableFormatCreated(format: TimetableFormatSettingsDto) {
    this._timetableFormats = [...this._timetableFormats, format];
  }

  private mapDtoToRowItem(dto: TimetableFormatSettingsDto): ITimetableFormatRow {
    return {
      key: dto.id ?? -1,
      name: dto.name,
      showDetails: dto.showDetails,
      showUnannouncedExams: dto.showUnannouncedExams,
      showSubjectColors: dto.showSubjectColors,
      timeGridType: dto.timeGridType,
      showTimeInGrid: dto.showTimeInGrid,
      showPeriodName: dto.showPeriodName,
      showPeriodNumber: dto.showPeriodNumber,
      days: dto.days,
      isTimeGridDays: dto.isTimeGridDays,
      duration: dto.duration,
      showTimeInCard: dto.showTimeInCard,
      showLessonText: dto.showLessonText,
      showUser: dto.showUser,
      showInfo: dto.showInfo,
      showUnconfirmedBookings: dto.showUnconfirmedBookings,
      showCancelledPeriods: dto.showCancelledPeriods,
      showStandByPeriods: dto.showStandByPeriods,
      showOfficeHours: dto.showOfficeHours,
      showExternalCalendars: dto.showExternalCalendars,
      cardLayout: dto.cardLayout,
      highlightChanges: dto.highlightChanges,
      highlightExams: dto.highlightExams,
      highlightCancelledPeriods: dto.highlightCancelledPeriods,
      highlightExternalCalendars: dto.highlightExternalCalendars,
      showSymbols: dto.showSymbols,
    };
  }

  private copyDto(format: TimetableFormatSettingsDto): TimetableFormatSettingsDto {
    return {
      ...format,
      id: -1,
      name: `${format.name}-Copy`,
      cardLayout: [...format.cardLayout],
      days: [...format.days],
    };
  }

  private mapToDto(
    formData: ITimetableFormatFormData,
    format?: TimetableFormatSettingsDto,
  ): TimetableFormatSettingsDto {
    return {
      id: format?.id ?? -1,
      name: formData.name,
      longName: formData.name,
      showDetails: formData.showDetails,
      showUnannouncedExams: formData.showUnannouncedExams,
      showSubjectColors: formData.showSubjectColors,
      timeGridType: TimeGridTypeEnum[formData.timeGridType as keyof typeof TimeGridTypeEnum],
      showTimeInGrid: formData.timeGridSlotSettings.includes(SHOW_TIME_IN_GRID),
      showPeriodName: formData.timeGridSlotSettings.includes(SHOW_PERIOD_NAME),
      showPeriodNumber: formData.timeGridSlotSettings.includes(SHOW_PERIOD_NUMBER),
      days: formData.weekdays.days,
      isTimeGridDays: formData.weekdays.isTimeGridDays,
      duration: {
        start: formatTimeForServerRequest(formData.duration.startTime),
        end: formatTimeForServerRequest(formData.duration.endTime),
      },
      showTimeInCard: formData.periodCardSettings.includes(SHOW_TIME_IN_CARD),
      showLessonText: formData.periodCardSettings.includes(SHOW_LESSON_TEXT),
      showUser: formData.periodCardSettings.includes(SHOW_USER),
      showInfo: formData.periodCardSettings.includes(SHOW_INFO),
      showUnconfirmedBookings: formData.additionalActivities.includes(SHOW_UNCONFIRMED_BOOKINGS),
      showCancelledPeriods: formData.additionalActivities.includes(SHOW_CANCELLED_PERIODS),
      showStandByPeriods: formData.additionalActivities.includes(SHOW_STANDBY_PERIODS),
      showOfficeHours: formData.additionalActivities.includes(SHOW_OFFICE_HOURS),
      showExternalCalendars: formData.additionalActivities.includes(SHOW_EXTERNAL_CALENDARS),
      highlightChanges: formData.periodCardHighlight.includes(HIGHLIGHT_CHANGES),
      highlightExams: formData.periodCardHighlight.includes(HIGHLIGHT_EXAMS),
      highlightCancelledPeriods: formData.periodCardHighlight.includes(HIGHLIGHT_CANCELLED_PERIODS),
      highlightExternalCalendars: formData.periodCardHighlight.includes(HIGHLIGHT_EXTERNAL_CALENDARS),
      showSymbols: formData.periodCardHighlight.includes(SHOW_SYMBOLS),
      cardLayout: formData.lessonCardLayout,
    };
  }
}
