import React from 'react';
import { observer } from 'mobx-react-lite';

import { Stack, ToggleSwitch, Flex, Tooltip, Icon } from '@/ui-components';
import { Experiment, ExperimentalStore } from '@/stores/experimental-store';
import useStore from '@/hooks/useStore';

import './experimental-modal-view.less';

export const ExperimentalModalView = observer(() => {
  const experimentalStore = useStore(ExperimentalStore);

  function handleChange(experiment: Experiment) {
    return (value: string) => {
      if (value === 'on') {
        experimentalStore.enableExperiment(experiment.id);
      } else {
        experimentalStore.disableExperiment(experiment.id);
      }
    };
  }

  return (
    <Stack y="md" className="experimental-container">
      {experimentalStore.allowedExperiments.map((exp) => {
        return (
          <Flex key={exp.id} alignItems="center" justifyContent="space-between">
            <Stack y="xs" className="content">
              <div className="header-container">
                <span className="title">{exp.title}</span>
                <Tooltip
                  title={`${exp.allowList.map((url) => url.replace('*', 'all WebUntis server')).join('\n')}`}
                  trigger={['hover', 'focus']}
                >
                  <Icon tabIndex={0} className="info-icon" type="info-circle" />
                </Tooltip>
              </div>
              <p>{exp.description}</p>
              {exp.url && (
                <span className="url">
                  Location:{' '}
                  <a href={exp.url} target="_blank" rel="noopener noreferrer">
                    {exp.url}
                  </a>
                </span>
              )}
            </Stack>
            <ToggleSwitch
              onChange={handleChange(exp)}
              toggles={[
                { label: 'On', value: 'on' },
                { label: 'Off', value: 'off' },
              ]}
              value={experimentalStore.enabledExperimentsIds.includes(exp.id) ? 'on' : 'off'}
            />
          </Flex>
        );
      })}
    </Stack>
  );
});
