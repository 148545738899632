import { createBrowserHistory, createMemoryHistory } from 'history';
import { autorun, computed } from 'mobx';
import { RouterStore as MobxReactRouterStore, syncHistoryWithStore, SynchronizedHistory } from 'mobx-react-router';
import { matchPath } from 'react-router-dom';

import RefStore from '@/stores/ref-store';
import { inject, Store } from '@/types/store';
import legacyRoutesMap from '@/utils/router/legacy-routes-map';
import { removeAllModalSubPaths } from '@/utils/router/modal-routes-definitions';
import PostMessageStore from '@sp/stores/post-message-store';
import { SidebarStore } from '@/stores/sidebar-store';

const { NODE_ENV } = process.env;
const STORAGE_ROUTE_PARAM = 'wuLastRoute';
const UNKNOWN_ROUTE_PARAM_VAL = '__';

@Store()
export default class RouterStore {
  refStore = inject(RefStore);
  postMessageStore = inject(PostMessageStore);
  sidebarStore = inject(SidebarStore);
  browserHistory = NODE_ENV === 'test' ? createMemoryHistory() : createBrowserHistory();
  routing: MobxReactRouterStore = new MobxReactRouterStore();
  history: SynchronizedHistory = syncHistoryWithStore(this.browserHistory, this.routing);
  currentPath: string | null = null;

  constructor() {
    autorun(() => {
      // TODO: WU-6382 - Refactor legacy page menu handling logic
      this.currentPath !== this.legacyPage && this.updateLegacyPage(this.legacyPage, true);
      // navigation should close the sidebar
      this.sidebarStore.closeSidebar();
    });
  }

  /**
   * Posts the message to the legacy page iframe to update its route
   * @param legacyPage
   * @param replace
   */
  updateLegacyPage = (legacyPage: string | null, replace: boolean) => {
    if (this.refStore.embeddedWebUntisIFrameRef && legacyPage) {
      this.currentPath = legacyPage;
      this.postMessageStore.postNavigationMessage(this.refStore.embeddedWebUntisIFrameRef, legacyPage, replace);
      this.sendLegacyEventsAfterNavigation();
    }
  };

  @computed
  get legacyPage(): string | null {
    return this.getLegacyPageForRoute(this.routing.location.pathname);
  }

  private sendLegacyEventsAfterNavigation() {
    switch (this.routing.location.pathname) {
      case '/playground':
        if (this.routing.location.search === '?createSupportPlayground') {
          this.postMessageStore.postCreateSupportPlayground();
        }
        break;
    }
  }

  // URL parameter that can be passed in.

  // default: false
  @computed
  get embedded(): boolean {
    const embedded = new URLSearchParams(this.routing.location.search).get('embedded');
    return embedded !== null && embedded.toLowerCase() === 'true';
  }

  // default: true
  @computed
  get sidebar(): boolean {
    const sidebar = new URLSearchParams(this.routing.location.search).get('sidebar');
    return sidebar === null || sidebar.toLowerCase() !== 'false';
  }

  /**
   * Stores the current route in session storage so it is available later then when the user is successfully logged in.
   */
  rememberCurrentRoute = () => {
    /^\/\w+/.test(this.routing.location.pathname) &&
      window.sessionStorage.setItem(STORAGE_ROUTE_PARAM, this.routing.location.pathname);
  };

  /**
   * Navigates user to the particular remembered route after login.
   */
  goToLastRoute = () => {
    const lastRoute = window.sessionStorage.getItem(STORAGE_ROUTE_PARAM);
    window.sessionStorage.removeItem(STORAGE_ROUTE_PARAM);
    lastRoute && /^\/\w+/.test(lastRoute) && this.routing.push(lastRoute);
  };

  redirect = (url: string) => {
    this.routing.replace(url);
  };

  /**
   * Updates the current route based on the route in legacy TT iframe.
   * @param legacyPage particular legacy page as defined in legacy-routes-map.ts
   */
  updateRouteForLegacy = (legacyPage: string): void => {
    const newRoute = this.getRouteForLegacyPage(legacyPage);
    if (newRoute && this.routing.location.pathname.indexOf(newRoute)) {
      // update current legacy page in advance to prevent another unnecessary postNavigationMessage invoked on autorun
      this.currentPath = this.getLegacyPageForRoute(newRoute);
      // replace in case of same base path e.g. /timetable-classes -> /timetable-classes/__/__ (back button support)
      newRoute.indexOf(this.routing.location.pathname) === 0
        ? this.routing.replace(newRoute)
        : this.routing.push(newRoute);
    }
  };

  /**
   * Helper, because if at runtime param or value is a number, parse to number and compare with === to avoid ESLint
   * warning because of comparison with ==
   * @param param
   * @param value
   */
  private isParamEqualsValue = (param: string, value: unknown): boolean => {
    const valueNum: number = Number(value);
    const paramNum: number = Number(param);
    if (!isNaN(paramNum) && !isNaN(valueNum)) {
      return paramNum === valueNum;
    }

    return param === value;
  };

  /**
   * Refreshes dynamic parameters of particular route based on parameters posted from the legacy page
   * whenever some search criteria changes (user changes the class, room, student, date, etc. in timetable iframe)
   * @param params
   */
  refreshRouteParamsForLegacy = (params: { [param: string]: string }): void => {
    for (const [key] of legacyRoutesMap) {
      const matchedPath = matchPath(this.routing.location.pathname, { path: key, exact: false });
      if (matchedPath) {
        const updatedRoute = this.resolveRouteWithLegacySearchParams(key, params && new URLSearchParams(params));
        // update current legacy page in advance to prevent another unnecessary postNavigationMessage
        this.currentPath = this.getLegacyPageForRoute(updatedRoute);
        // do not replace/push the same base path (so the possible modal child path is not removed on initial loading)
        if (this.routing.location.pathname.indexOf(updatedRoute)) {
          // replace when params appended first time, otherwise push (browser back button support)
          Object.entries(matchedPath.params).some(
            ([key, value]) =>
              !this.isParamEqualsValue(params[key], value) && value !== UNKNOWN_ROUTE_PARAM_VAL && value !== undefined,
          )
            ? this.routing.push(updatedRoute)
            : this.routing.replace(updatedRoute);
        }
        return;
      }
    }
  };

  /**
   * Closes all modal dialogs handled by router.
   */
  removeAllModalRoutes = (): void => {
    const pathWithoutModals = removeAllModalSubPaths(this.routing.location.pathname);
    pathWithoutModals !== this.routing.location.pathname && this.routing.push(pathWithoutModals);
  };

  private getLegacyPageForRoute = (route: string): string | null => {
    for (const [key, value] of legacyRoutesMap) {
      const matchedPath = matchPath(route, { path: key, exact: false });
      if (matchedPath) {
        return this.appendPageParams(value, matchedPath.params);
      }
    }
    return null;
  };

  /**
   * Returns route for the particular legacy page or null if such legacy page cannot be found.
   * In case of dynamic route it tries to map all the legacy query params to the same named dynamic route path params.
   * e.g. for the legacy page: `/basic/timetable?selectedTab=2&date=2021-02-01&id=31`
   * should be returned: `/timetable-teachers/31/2021-02-01`
   * @param legacyPage
   */
  private getRouteForLegacyPage = (legacyPage: string): string | null => {
    for (const [key, value] of legacyRoutesMap) {
      if (this.isPageMatched(legacyPage, value)) {
        return this.resolveRouteWithLegacySearchParams(key, new URLSearchParams(legacyPage.split('?')[1]));
      }
    }
    return null;
  };

  private resolveRouteWithLegacySearchParams(route: string, urlSearchParams?: URLSearchParams): string {
    route = route.replace(/\?/g, '');
    urlSearchParams &&
      urlSearchParams.forEach((value, key) => {
        if (value && value !== 'undefined' && value !== 'null') {
          route = route.replace(':' + key, value);
        }
      });
    // replace missing parameters with fallback value, so the number of params is still the same after refresh
    // and possibly appended modal dialog route is not partially considered as a timetable route param
    return route.replace(/:[^/]+/g, UNKNOWN_ROUTE_PARAM_VAL);
  }

  private appendPageParams(page: string, params: { [param: string]: string }) {
    const pageSplit = page.split('?');
    const searchParams = new URLSearchParams(pageSplit[1]);
    for (const param in params) {
      params[param] &&
        params[param] !== UNKNOWN_ROUTE_PARAM_VAL &&
        !searchParams.has(param) &&
        params[param] &&
        searchParams.append(param, params[param]);
    }
    page = pageSplit[0];
    const searchParamsString = searchParams.toString();
    if (searchParamsString) {
      page += '?' + searchParams.toString();
    }
    return page;
  }

  private isPageMatched(pageToMatch: string, basePage: string) {
    return (
      basePage === pageToMatch ||
      (pageToMatch.indexOf(basePage) === 0 && ['&', '/', '?', '='].includes(pageToMatch.charAt(basePage.length)))
    );
  }
}
