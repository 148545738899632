import React from 'react';
import { notification } from 'antd';
import { ArgsProps } from 'antd/es/notification';
import { NotificationPlacement } from 'antd/lib/notification/interface';

import { Store } from '@/types/store';
import { Icon } from '@/ui-components';
import { NotificationContent } from '@/stores/notification-store/notification-content/notification-content';

export interface INotificationArgs {
  title: string;
  message?: string;
  downloadLink?: {
    url: string;
    label: string;
  };
  keepOpen?: boolean;
  placement?: NotificationPlacement;
}

@Store()
export default class NotificationStore {
  public async notifyMultiple(
    notifications: {
      type: string;
      args: INotificationArgs;
    }[],
  ) {
    for (const notification of notifications) {
      // Ant design notifications seem to be messed up if multiple are created similtaneously.
      // A short timeout in between resolves that.
      await new Promise((resolve) => setTimeout(resolve, 50));
      this.notify(notification.type, notification.args);
    }
  }

  public notify(type: string, args: INotificationArgs) {
    switch (type) {
      case 'success':
        this.success(args);
        break;
      case 'error':
        this.error(args);
        break;
      case 'warning':
        this.warning(args);
        break;
      default:
        this.info(args);
        break;
    }
  }

  public success(args: INotificationArgs) {
    notification.success(this.mapNotificationArgsToAntDesignArgs(args, 'success'));
  }

  public error(args: INotificationArgs) {
    notification.error(this.mapNotificationArgsToAntDesignArgs(args, 'error'));
  }

  public warning(args: INotificationArgs) {
    notification.warning(this.mapNotificationArgsToAntDesignArgs(args, 'warning'));
  }

  public info(args: INotificationArgs) {
    notification.info(this.mapNotificationArgsToAntDesignArgs(args, 'info'));
  }

  private mapNotificationArgsToAntDesignArgs(
    args: INotificationArgs,
    type: 'success' | 'error' | 'warning' | 'info',
  ): ArgsProps {
    const id = `open${Date.now()}`;
    let color = '#E6F6F9';
    let icon = 'info-circle';

    switch (type) {
      case 'success':
        color = '#DFF6EC';
        icon = 'activation';
        break;
      case 'error':
        color = '#FFE5DE';
        icon = 'alert';
        break;
      case 'warning':
        color = '#FFF2DE';
        icon = 'exclamation-circle';
        break;
    }

    return {
      message: args.title,
      placement: args.placement ?? 'top',
      description: <NotificationContent id={id} message={args.message} downloadLink={args.downloadLink} />,
      duration: args.keepOpen ? 0 : undefined,
      key: id,
      style: {
        backgroundColor: color,
        borderRadius: '8px',
      },
      icon: <Icon type={icon} />,
    };
  }
}
