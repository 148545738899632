import React, { useContext } from 'react';
import classNames from 'clsx';
import { Dayjs } from 'dayjs';

import { DeprecatedSearchBar } from '@/ui-components';
import {
  IPageHeaderIconButtonProps,
  IPageHeaderLinkButtonProps,
  IPageHeaderOptionButtonProps,
  IPageHeaderRegularButtonProps,
  IPageProps,
  PageHeaderButton,
} from '@/ui-components/page/page';
import { PageHeaderIconButton } from '@/ui-components/page/page-header/page-header-icon-button/page-header-icon-button';
import { PageHeaderLinkButton } from '@/ui-components/page/page-header/page-header-link-button/page-header-link-button';
import { PageHeaderOptionButton } from '@/ui-components/page/page-header/page-header-option-button/page-header-option-button';
import { PageHeaderRegularButton } from '@/ui-components/page/page-header/page-header-regular-button/page-header-regular-button';
import { PageHeaderDatePicker } from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import { PageHeaderTabs } from '@/ui-components/page/page-header/page-header-tabs/page-header-tabs';
import DeprecatedDropDown from '@/ui-components/deprecated-drop-down/drop-down';
import { ResponsivenessContext } from '@/context/responsiveness-context';
import { SearchBar } from '@/ui-components/search-bar/search-bar';
import './page-header.less';

// When it is displayed sticky, we need consider the scrollbar width so that right aligned is aligned correctly.
// See scrollbar-helper in render

// declare const Spacings: ['sm', 'md', 'lg'];
// export declare type Spacing = typeof Spacings[number];

interface IProps extends IPageProps {
  isSticky: boolean;
  isWhite: boolean; // if there is a filter, or datepicker, we make it white
  shouldBreak: boolean;
  date?: Dayjs;
  displayTitle?: boolean;
}

/**
 * *** DON'T USE THIS COMPONENT DIRECTLY ***
 *
 * Component that should ONLY be used from the PAGE component and should be
 * capable of displaying all the different things, a header in WebUntis can
 * contain, e.g.: Title, Subtitle, Buttons,...
 * It also should handle behaviour like "is it sticky or not"
 */
export const PageHeader = (props: IProps) => {
  const { buttons, title, isSticky, dropDown, isWhite, shouldBreak, displayTitle } = props;

  const responsivenessContext = useContext(ResponsivenessContext);

  if (!displayTitle) {
    return null;
  }

  const pageHeaderContainerClass = classNames(
    'page-header-container',
    responsivenessContext?.responsiveClassName,
    shouldBreak ? 'should-break' : '',
    {
      'page-header-container--is-white': isWhite,
      'no-title': title === undefined,
      'minimum-version': !props.description && !props.tabs,
      'uses-new-search-bar': !!props.searchBar,
      'hide-bottom-padding': !!props.tabs,
    },
  );

  const isLinkButton = (button: PageHeaderButton): button is IPageHeaderLinkButtonProps => {
    return (button as IPageHeaderLinkButtonProps).linkTo !== undefined;
  };

  const isOptionButton = (button: PageHeaderButton): button is IPageHeaderOptionButtonProps => {
    return (button as IPageHeaderOptionButtonProps).options !== undefined;
  };

  const isRegularButton = (button: PageHeaderButton): button is IPageHeaderRegularButtonProps => {
    return (button as IPageHeaderRegularButtonProps).onClick !== undefined;
  };

  const isIconButton = (button: PageHeaderButton): button is IPageHeaderIconButtonProps => {
    // IconButtons are the only buttons that have no label
    return (button as any).label === undefined;
  };

  const renderButton = (buttonProps: PageHeaderButton, key: string) => {
    if (isIconButton(buttonProps)) {
      return <PageHeaderIconButton {...buttonProps} key={key} />;
    } else if (isLinkButton(buttonProps)) {
      return <PageHeaderLinkButton {...buttonProps} key={key} />;
    } else if (isOptionButton(buttonProps)) {
      return <PageHeaderOptionButton {...buttonProps} key={key} />;
    } else if (isRegularButton(buttonProps)) {
      return <PageHeaderRegularButton {...buttonProps} key={key} />;
    }
    return null;
  };

  return (
    <div className={pageHeaderContainerClass}>
      <div className="page-header" style={{ maxWidth: props.compact ? `1200px` : 'inherit' }}>
        <div className="top-container">
          {typeof title === 'string' ? <h1>{title}</h1> : title}
          {props.deprecatedSearchBar && !props.searchBar && (
            <div className="search-bar-container">
              <DeprecatedSearchBar {...props.deprecatedSearchBar} color="default" />
            </div>
          )}
          {props.searchBar && (
            <div className="search-bar-container">
              <SearchBar {...props.searchBar} />
            </div>
          )}
          <div className="button-container">
            {dropDown && <DeprecatedDropDown {...dropDown} />}
            {buttons &&
              buttons.map((buttonProps, index) => {
                return renderButton(buttonProps, 'page-header-button-' + index);
              })}
            {props.pageDatePickerProps && (
              <div className="date-picker-container">
                <PageHeaderDatePicker {...props.pageDatePickerProps} />
              </div>
            )}
          </div>
          {isSticky && <div className="scrollbar-helper" />}
        </div>
        <div className="bottom-container">
          {props.description && <p className="page-description">{props.description}</p>}
          {props.tabs && <PageHeaderTabs {...props.tabs} />}
        </div>
      </div>
    </div>
  );
};

PageHeader.defaultProps = {
  displayTitle: true,
};
