import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import TodayQuicklinksStore from '@today/stores/today-quicklinks-store';
import { Stack } from '@/ui-components';
import { LinkCard } from '@/ui-components/link-card/link-card';
import useStore from '@/hooks/useStore';

const QuickLinksList = observer(() => {
  const todayQuicklinksStore = useStore(TodayQuicklinksStore);
  const { t } = useTranslation();

  return (
    <Stack y="sm">
      <h2 className="today-page-section-heading">{t('general.todos')}</h2>
      {todayQuicklinksStore.quickLinks.map((link) => (
        <LinkCard key={link.href} {...link} />
      ))}
    </Stack>
  );
});

export default QuickLinksList;
