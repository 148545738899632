import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import { FormInstance } from 'antd';
import React from 'react';
import { AxiosError } from 'axios';

import { BuildingDto, BuildingFormDto, RoomRefDto } from '@untis/wu-rest-view-api/api';
import { BuildingViewApi } from '@/stores/api-store';
import { DeprecatedAbstractListViewStore } from '@/pages/master-data/common/deprecated-abstract-list-view-store';
import { ElementType } from '@/stores/rights-store';
import { BuildingForm, IBuildingFormBuilding } from '@/pages/master-data/building/building-form';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { defaultSorting } from '@/utils/sorting/sorting-util';
import { IListViewAction, IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import { createChunks } from '@/utils/array/array-util';
import { getErrorMessageFromResponseError } from '@/utils/error-handling/error-handling';
import { TestIds } from '@/testIds';

export interface IBuildingRow extends ITableRowKey {
  id: number;
  name: string;
  longName: string;
}

export class BuildingStore extends DeprecatedAbstractListViewStore<IBuildingFormBuilding> {
  @observable private _buildings: BuildingDto[] = [];
  @observable private _buildingForm: BuildingFormDto | undefined;

  constructor(form: FormInstance<IBuildingFormBuilding>) {
    super(form, { elementType: ElementType.BUILDING });
    this.fetchData();
  }

  @action
  async fetchData() {
    await Promise.all([this.fetchBuildings(), this.fetchFormConfig()]);
    this.setIsDataLoading(false);
  }

  @action
  async fetchBuildings() {
    try {
      this._buildings = (await BuildingViewApi.getBuildings()).data.buildings;
    } catch (error) {
      const errorMessage = getErrorMessageFromResponseError(error);
      errorMessage && console.error(errorMessage);
    }
  }

  @action
  async fetchFormConfig() {
    try {
      this._buildingForm = (await BuildingViewApi.getBuildingForm()).data;
    } catch (error) {
      const errorMessage = getErrorMessageFromResponseError(error);
      errorMessage && console.error(errorMessage);
    }
  }

  @computed
  get buildings(): BuildingDto[] {
    return this._buildings;
  }

  @computed
  get buildingRows(): IBuildingRow[] {
    return BuildingStore.mapBuildingsToRows(this._buildings);
  }

  @computed
  get filteredRows(): IBuildingRow[] {
    return this.buildingRows.filter((building: IBuildingRow) =>
      this.containsSearchInput([building.name, building.longName]),
    );
  }

  @computed
  get rooms(): RoomRefDto[] {
    return this._buildingForm && this._buildingForm.rooms ? this._buildingForm.rooms : [];
  }

  @computed
  get columns(): Columns<IBuildingRow> {
    const cols: Columns<IBuildingRow> = [
      {
        type: ColumnType.Text,
        key: 'name',
        header: t('general.shortName'),
        sorter: (a, b) => defaultSorting(a.name, b.name),
      },
      {
        type: ColumnType.Text,
        key: 'longName',
        header: t('general.longName'),
        sorter: (a, b) => defaultSorting(a.longName, b.longName),
      },
    ];

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.OverlayHoverActions,
        key: 'actions',
        actionIcons: this.columnActions,
      });
    }

    return cols;
  }

  @computed
  private get columnActions() {
    const actions = [
      { tooltip: t('general.edit'), onClick: (row: IBuildingRow) => this.openEditForm(row), icon: 'edit' },
    ];

    if (this.canDelete) {
      actions.push({
        tooltip: t('general.delete'),
        onClick: (row: IBuildingRow) => this.deleteBuilding(row.id),
        icon: 'shared_trash',
      });
    }

    return actions;
  }

  @computed
  get listViewActions(): IListViewAction[] {
    const actions: IListViewAction[] = [];
    if (this.canCreate) {
      actions.push({
        label: t('general.new'),
        onClick: this.openCreateForm,
        testId: TestIds.BUILDING_NEW,
      });
    }
    actions.push({
      label: t('general.report'),
      onClick: () => {
        this.generateReport('Building');
      },
      testId: TestIds.BUILDING_REPORT,
    });

    return actions;
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<IBuildingRow>[] | undefined {
    return this.canDelete
      ? [
          {
            label: t('general.delete'),
            icon: 'shared_trash',
            onClick: async (rows) => {
              try {
                const result = await this._modalStore.booleanUserPrompt({
                  title: t('general.buildingsBulkDeleteConfirmation', {
                    count: rows.length,
                  }),
                  okButton: {
                    label: t('general.yes'),
                  },
                  cancelButton: {
                    label: t('general.cancel'),
                  },
                });
                if (result) {
                  const chunks = createChunks<IBuildingRow>(rows, 100);
                  for (const chunk of chunks) {
                    const buildingIdsToDelete = chunk.map((row) => row.id);
                    await BuildingViewApi.deleteBuildings(buildingIdsToDelete);
                    this._notificationStore.success({
                      title: t('general.buildingsDeleted'),
                    });
                    this.onBuildingsDeleted(buildingIdsToDelete);
                  }
                }
              } catch (error) {
                const axiosError: AxiosError = error as AxiosError;
                if (axiosError.response?.status === 422) {
                  this._notificationStore.error({
                    title: t('general.someBuildingsCouldNotBeDeleted'),
                    message: t('general.buildingsInUseCanNotBeDeleted'),
                  });
                } else {
                  this._notificationStore.error({
                    title: error.toString(),
                  });
                }
              }
            },
          },
        ]
      : undefined;
  }

  getBuildingDtoByRowId = (id: string): BuildingDto | undefined => {
    return this._buildings.find((building) => building.id?.toString() === id);
  };

  @action
  onBuildingChanged(building: BuildingDto) {
    this._buildings = this._buildings.map((b) => (b.id === building.id ? building : b));
  }

  @action
  onBuildingsDeleted(deletedBuildingIds: number[]) {
    this._buildings = this._buildings.filter((b) => b.id && !deletedBuildingIds.includes(b.id));
  }

  @action
  onBuildingCreated(building: BuildingDto) {
    this._buildings = [...this._buildings, building];
  }

  @action
  async deleteBuilding(id: number) {
    try {
      const result = await this._modalStore.openDeletePrompt(t('general.buildingDeleteConfirmation'), '');
      if (result) {
        await BuildingViewApi.deleteBuilding(id);
        this._notificationStore.success({ title: t('general.buildingDeleted') });
        await this._modalStore.closeModal();
        this.onBuildingsDeleted([id]);
      }
    } catch (error) {
      this._notificationStore.error({
        title: t('general.buildingCouldNotBeDeleted'),
        message: error.toString(),
      });
    }
  }

  @action
  async createBuilding(buildingDto: BuildingDto, saveAndNew: boolean) {
    try {
      const createdBuilding: BuildingDto = (await BuildingViewApi.createBuilding(buildingDto)).data;
      this._notificationStore.success({ title: t('general.buildingCreated') });
      this.onBuildingCreated(createdBuilding);
      this.form.resetFields();
      await this.fetchBuildings();
      if (!saveAndNew) {
        await this._modalStore.closeModal();
      }
    } catch (error) {
      this._notificationStore.error({ title: t('general.buildingCouldNotBeCreated'), message: error.toString() });
    }
  }

  @action
  async updateBuilding(building: BuildingDto) {
    try {
      const updatedBuilding: BuildingDto = (await BuildingViewApi.updateBuilding(building)).data;
      this._notificationStore.success({ title: t('general.buildingEdited') });
      this.onBuildingChanged(updatedBuilding);
      await this.fetchBuildings();
      await this._modalStore.closeModal();
    } catch (error) {
      this._notificationStore.error({ title: t('general.buildingCouldNotBeEdited'), message: error.toString() });
    }
  }

  private static mapBuildingsToRows(buildings: BuildingDto[]): IBuildingRow[] {
    return buildings.map((building) => {
      return {
        id: building.id ? building.id : -1,
        key: building.id ? building.id : -1,
        name: building.name,
        longName: building.longName ? building.longName : '',
      };
    });
  }

  @action.bound
  openCreateForm() {
    this.form.resetFields();
    this._modalStore.openModalDialog({
      children: <BuildingForm store={this} />,
      title: t('general.newBuilding'),
      size: 'full-size',
      containsForm: true,
    });
  }

  @action
  openEditForm(row: IBuildingRow) {
    const building = this.getBuildingDtoByRowId(row.id.toString());
    this.form.resetFields();
    if (building) {
      this._modalStore.openModalDialog({
        title: `${t('general.building')} ${building.longName} (${building.name})`,
        size: 'full-size',
        children: <BuildingForm building={building} store={this} />,
        containsForm: true,
      });
    }
  }
}
