import React, { ReactNode } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import useIsAuthorized, { IRequiredPermissions } from '@/hooks/useIsAuthorized';

interface IProps extends RouteProps {
  render: (...args: any) => ReactNode;
}

/**
 * A wrapper for routes which ensures that the required permissions are fulfilled.
 * If the permissions aren't fulfilled it will redirect to the base url.
 */
const ProtectedRoute = ({ render, ...otherProps }: IProps & IRequiredPermissions) => {
  const isAuthorized = useIsAuthorized(otherProps);

  return (
    <Route
      {...otherProps}
      render={() => {
        return isAuthorized ? render() : <Redirect to={{ pathname: '/' }} />;
      }}
    />
  );
};
export default ProtectedRoute;
