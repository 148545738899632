import React, { useEffect, useMemo, useState } from 'react';
import { Popover } from 'antd';
import clsx from 'clsx';

import { Flex, IconButton } from '@/ui-components';
import { IconButtonProps } from '@/ui-components/icon-button/icon-button';
import { Checkbox } from '@/ui-components/checkbox/checkbox';

import './complex-option-popover.less';

export interface IComplexOption {
  key: string;
  label: string;
  onClick: (optionKey: string) => void;
}

interface IAction {
  key: string;
  label: string;
  onClick: () => void;
}

type CommonProps = {
  key: string;
  label: string;
};

type OptionGroupCommonProps = {
  options: IComplexOption[];
} & CommonProps;

type OptionGroupConditionalProps =
  | {
      selectedKey: string | undefined;
      multiple?: never;
    }
  | {
      selectedKey: string[] | undefined;
      multiple: true;
    };

export type ActionGroupProps = {
  actions: IAction[];
} & CommonProps;

export type ComplexOptionGroupProps = OptionGroupCommonProps & OptionGroupConditionalProps;

export type IComplexOptionGroup = ComplexOptionGroupProps | ActionGroupProps;

export interface IComplexOptionPopoverProps {
  icon: Pick<IconButtonProps, 'type' | 'ariaLabel' | 'testId'>;
  optionGroups: IComplexOptionGroup[];
  isOpen?: boolean;
  contentTestId?: string;
}

const ComplexOptionPopover = (props: IComplexOptionPopoverProps) => {
  const { icon, optionGroups, contentTestId } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(props.isOpen ?? false);
  }, [props.isOpen]);

  const handleVisibleChange = (visible: boolean) => {
    if (props.isOpen === undefined) {
      setIsOpen(visible);
    }
  };

  return (
    <Popover
      trigger="click"
      content={
        <ComplexOptionPopoverContent
          testId={contentTestId}
          optionGroups={optionGroups}
          onItemSelected={() => setIsOpen(false)}
        />
      }
      open={isOpen}
      placement="top"
      overlayClassName="complex-option-popover-container"
      arrow={{
        pointAtCenter: true,
      }}
      onOpenChange={handleVisibleChange}
    >
      <IconButton
        testId={icon.testId}
        type={icon.type}
        ariaLabel={icon.ariaLabel}
        size="sm"
        onClick={() => setIsOpen(true)}
      />
    </Popover>
  );
};

const ComplexOptionPopoverContent = (
  props: Pick<IComplexOptionPopoverProps, 'optionGroups'> & { onItemSelected: () => void; testId?: string },
) => {
  const { optionGroups, testId, onItemSelected } = props;

  return (
    <div className="complex-option-popover-content" data-testid={testId ?? 'complex-option-popover-content'}>
      {optionGroups.map((optionGroup) => (
        <ComplexOptionGroup key={optionGroup.key} optionGroup={optionGroup} onItemSelected={onItemSelected} />
      ))}
    </div>
  );
};

const ComplexOptionGroup = (props: { optionGroup: IComplexOptionGroup; onItemSelected: () => void }) => {
  const { optionGroup, onItemSelected } = props;

  const isOptionSelected = (option: IComplexOption): boolean => {
    if ('options' in optionGroup) {
      if (optionGroup.multiple) {
        return optionGroup.selectedKey?.includes(option.key) ?? false;
      } else {
        return optionGroup.selectedKey === option.key;
      }
    } else {
      return false;
    }
  };

  const isMultiSelect = useMemo(() => {
    if ('options' in optionGroup) {
      return optionGroup.multiple ?? false;
    } else {
      return false;
    }
  }, [optionGroup]);

  const renderComplexOptionGroup = (group: ComplexOptionGroupProps) => {
    return (
      <>
        {group.options.map((option) => {
          if ('options' in optionGroup) {
            const isSelected = isOptionSelected(option);
            const isSingleSelect = !optionGroup.multiple;
            return (
              <ComplexOptionItem
                key={option.key}
                option={option}
                isSelected={isSelected}
                onItemSelected={isSingleSelect && isSelected ? undefined : onItemSelected}
              />
            );
          }
        })}
      </>
    );
  };

  const renderActionGroup = (group: ActionGroupProps) => {
    return (
      <>
        {group.actions.map((action) => (
          <ActionGroupItem key={optionGroup.key} action={action} onItemSelected={onItemSelected} />
        ))}
      </>
    );
  };

  return (
    <div key={optionGroup.key} className="complex-option-popover-group">
      <Flex className="complex-option-popover-group--header" justifyContent="space-between" alignItems="center">
        <span className="complex-option-popover-group--label">{optionGroup.label}</span>
      </Flex>
      <div className={clsx('complex-option-popover-group--items', { 'multi-select': isMultiSelect })}>
        {'options' in optionGroup && renderComplexOptionGroup(optionGroup)}
        {'actions' in optionGroup && renderActionGroup(optionGroup)}
      </div>
    </div>
  );
};

const ComplexOptionItem = (props: { option: IComplexOption; isSelected: boolean; onItemSelected?: () => void }) => {
  const { option, isSelected, onItemSelected } = props;

  return (
    <Flex
      className={clsx('complex-option-item', { selected: isSelected })}
      justifyContent="space-between"
      alignItems="center"
      onClick={() => {
        onItemSelected && onItemSelected();
        option.onClick(option.key);
      }}
    >
      <Flex>
        <div className="complex-option-item--selected-icon">
          <Checkbox checked={isSelected} testId={`${option.key}-checkbox`} />
        </div>
        <span className="complex-option-item--label">{option.label}</span>
      </Flex>
    </Flex>
  );
};

const ActionGroupItem = (props: { action: IAction; onItemSelected: () => void }) => {
  const { action, onItemSelected } = props;

  return (
    <Flex
      className="action-item"
      justifyContent="space-between"
      alignItems="center"
      onClick={() => {
        onItemSelected && onItemSelected();
        action.onClick();
      }}
    >
      <Flex>
        <span className="action-item--label">{action.label}</span>
      </Flex>
    </Flex>
  );
};

export default ComplexOptionPopover;
