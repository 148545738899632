import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListView } from '@/components/list-view/list-view';
import { IStudentDutyRow, StudentDutiesStore } from '@md/student-duties/student-duties-store';
import { StudentDutyViewApi } from '@/stores/api-store';
import useStore from '@/hooks/useStore';

export const StudentDutiesList = observer(() => {
  const store = useStore(StudentDutiesStore);
  const { t } = useTranslation();

  return (
    <ListView<IStudentDutyRow>
      searchBar={{
        selectedFreeTextOptions: store.selectedFreeTextOptions,
        onChangeFreeTextOptions: (values) => store.setSelectedFreeTextSearchOptions(values),
        options: store.options,
        onChangeSelectOptions: (options) => store.setSelectedOptions(options),
        selectedOptions: store.selectedOptions,
        placeholder: t('general.search'),
        searchDescription: t('general.name'),
      }}
      title={t('general.studentDuties')}
      compact
      selectedRowsActions={store.selectedRowsActions}
      actions={store.listViewActions}
      isPageLoading={store.isDataLoading}
      table={{
        columns: store.columns,
        rowData: store.rows,
        rowSelection: {
          selectedRowKeys: store.selectedRowKeys,
          type: 'checkbox',
          onChange: (keys) => store.setSelectedRowKeys(keys),
        },
        shadow: true,
      }}
      customSorting={{
        onSave: (order) => {
          StudentDutyViewApi.order(order).then(() => store.fetchStudentDuties());
        },
        onDelete: () => {
          StudentDutyViewApi.resetOrder().then(() => store.fetchStudentDuties());
        },
      }}
    />
  );
});
