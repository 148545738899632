import React, { useMemo } from 'react';
import { Form } from 'antd';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { WeekdayEnum } from '@untis/wu-rest-view-api/api';
import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import { Flex, SwitchComponent } from '@/ui-components';

import './form-weekdays-picker.less';

export interface IWeekDaySelection {
  days: Array<WeekdayEnum>;
  isTimeGridDays: boolean;
}

interface IProps {
  value?: IWeekDaySelection;
  onChange?: (value: IWeekDaySelection) => void;
  disabled?: boolean;
  // starts from 0=MO to 6=SU
  firstDayOfTheWeek?: number;
}

type FormWeekdaysPickerProps = IProps & IFormItemProps<IWeekDaySelection>;

const WEEKDAYS: WeekdayEnum[] = [
  WeekdayEnum.MO,
  WeekdayEnum.TU,
  WeekdayEnum.WE,
  WeekdayEnum.TH,
  WeekdayEnum.FR,
  WeekdayEnum.SA,
  WeekdayEnum.SU,
];

const WEEKDAY_TRANSLATION_MAP: Map<WeekdayEnum, string> = new Map<WeekdayEnum, string>([
  [WeekdayEnum.MO, 'weekDays.mondayShort'],
  [WeekdayEnum.TU, 'weekDays.tuesdayShort'],
  [WeekdayEnum.WE, 'weekDays.wednesdayShort'],
  [WeekdayEnum.TH, 'weekDays.thursdayShort'],
  [WeekdayEnum.FR, 'weekDays.fridayShort'],
  [WeekdayEnum.SA, 'weekDays.saturdayShort'],
  [WeekdayEnum.SU, 'weekDays.sundayShort'],
]);

export const FormWeekdaysPicker = (props: FormWeekdaysPickerProps) => {
  return (
    <Form.Item
      name={props.name}
      label={props.label}
      rules={props.rules}
      dependencies={props.dependencies}
      initialValue={props.initialValue}
    >
      <WeekdaysPicker
        value={props.value}
        onChange={props.onChange}
        firstDayOfTheWeek={props.firstDayOfTheWeek}
        disabled={props.disabled}
      />
    </Form.Item>
  );
};

export const WeekdaysPicker = (props: IProps) => {
  const { t } = useTranslation();
  const weekDaysDisabled = props.disabled || !!props.value?.isTimeGridDays;

  const weekdays = useMemo(() => {
    // 0 is falsy
    if (props.firstDayOfTheWeek !== undefined) {
      const firstDayOfTheWeek = props.firstDayOfTheWeek % 7; // mod 7
      return [...WEEKDAYS.slice(firstDayOfTheWeek, WEEKDAYS.length), ...WEEKDAYS.slice(0, firstDayOfTheWeek)];
    }
    return WEEKDAYS;
  }, [props.firstDayOfTheWeek]);

  const onWeekDayClicked = (weekDay: WeekdayEnum) => {
    if (!weekDaysDisabled && props.value) {
      if (!props.value.days.includes(weekDay)) {
        props.onChange && props.onChange({ ...props.value, days: [...props.value.days, weekDay] });
      } else {
        const newValue = props.value.days.filter((w) => w !== weekDay);
        props.onChange && props.onChange({ ...props.value, days: newValue });
      }
    }
  };

  const onSwitch = (value: boolean) => {
    props.onChange && props.onChange({ isTimeGridDays: value, days: props.value?.days ?? [] });
  };

  const getLabel = (weekday: WeekdayEnum): string => {
    let result = weekday.toString();
    const translationKey = WEEKDAY_TRANSLATION_MAP.get(weekday);
    if (translationKey) {
      const translation = t(translationKey);
      if (translation && translation.length > 0) {
        result = translation;
      }
    }
    return result[0].toUpperCase();
  };

  return (
    <Flex gap="lg" className="weekdays-picker">
      <Flex className="weekdays" gap="sm">
        {weekdays.map((weekDay) => (
          <div
            key={weekDay}
            role="button"
            data-testid={`weekday-${weekDay}`}
            className={clsx('weekday', {
              selected: props.value?.days?.includes(weekDay) ?? false,
              disabled: weekDaysDisabled,
            })}
            onClick={() => onWeekDayClicked(weekDay)}
          >
            {getLabel(weekDay)}
          </div>
        ))}
      </Flex>
      <Flex className="days-from-time-grid-switch" gap="sm" alignItems="center" justifyContent="flex-start">
        <SwitchComponent
          size="default"
          data-testid="days-from-time-grid-switch"
          checked={props.value?.isTimeGridDays ?? false}
          disabled={props.disabled}
          onChange={onSwitch}
        />
        <span className="switch-label">{t('general.everyDayOfTheTimeGrid')}</span>
      </Flex>
    </Flex>
  );
};
