import 'reflect-metadata'; // needed for typedi
import 'react-app-polyfill/ie9';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/includes';
import 'core-js/features/string/includes';
import 'core-js/features/string/starts-with';
import 'core-js/features/promise/finally';
import 'core-js/features/object/values';
import 'core-js/features/reflect';
import 'url-search-params-polyfill';

import { ConfigProvider } from 'antd';
import { t, init } from 'i18next';
import { observer } from 'mobx-react-lite';
import { SynchronizedHistory } from 'mobx-react-router';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ArcherContainer } from 'react-archer';

import RefStore from '@/stores/ref-store';
import { App } from '@/components/app/app';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import useStore from '@/hooks/useStore';
import AppStore from '@/stores/app-store';
import LocaleStore from '@/stores/locale-store';
import RouterStore from '@/stores/router-store';
import './setup';
import './index.less';

const BootstrappedApp = observer(() => {
  const appStore = useStore(AppStore);
  const localeStore = useStore(LocaleStore);
  const routerStore = useStore(RouterStore);
  const refStore = useStore(RefStore);

  useComponentDidMount(() => {
    appStore.init();
    init(
      {
        compatibilityAPI: 'v1',
        compatibilityJSON: 'v1',
      },
      () => {
        /* resources are loaded */
      },
    );
  });

  if (appStore.isLoading) {
    return <div>{t('general.loading')}</div>; // Todo - proper, reusable loading indicator
  }

  return (
    <Router history={routerStore.history as SynchronizedHistory}>
      <ConfigProvider locale={localeStore.antdLocale}>
        <HelmetProvider>
          <ArcherContainer
            ref={refStore.archerContainerRef}
            strokeColor="#8A96A1" /* default stroke color, you can override that in the ArcherElement releation */
            offset={16}
            lineStyle="curve"
            svgContainerStyle={{
              zIndex: 1000 /* please adjust if there are problems when your arrows are not showing up */,
            }}
          >
            <App />
          </ArcherContainer>
        </HelmetProvider>
      </ConfigProvider>
    </Router>
  );
});

ReactDOM.createRoot(document.getElementById('root')!).render(<BootstrappedApp />);
