import * as React from 'react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';

import { Page, Scroller, WUTable } from '@/ui-components';
import { dayjsFromUDateWithoutTime, uDateFromDayjs } from '@/utils/date/date-util';
import { ClassRegOverviewPeriodV2Dto } from '@untis/wu-rest-view-api';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { HandleMessageAction } from '@sp/stores/post-message-store';
import { Option } from '@/ui-components/option-popup/option-popup';
import { CalendarPopup } from '@/pages/class-register/calendar-popup/calendar-popup';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import useIframeMessageCallback from '@/hooks/useIframeMessageCallback';
import useModalDelegate from '@/hooks/useModalDelegate';
import useStore from '@/hooks/useStore';
import ConfigStore from '@/stores/config-store';
import ModalStore from '@/stores/modal-store';
import SchoolYearStore from '@/stores/schoolyear-store';
import {
  ClassRegisterOverviewStore,
  IEmptyDateRange,
} from '@/pages/class-register/class-register-overview/class-register-overview-store';
import { crOverviewStorePostMsgHandler } from '@/pages/class-register/class-register-overview/class-register-overview-store-postmsg-handler';
import { TestIds } from '@/testIds';

import './class-register-overview.less';

export interface IPeriodRowData {
  key: number; // we use the periodId as the key
  classes: string;
  subject: string | undefined;
  teachers: {
    id: string;
    value: string;
    orgValue?: string;
  }[];
  teachingContent: string;
  absences?: {
    excused: number;
    total: number;
    isChecked: boolean;
  };
  homework: string[];
}

export const CR_OVERVIEW_ROUTE = '/class-register-overview';

const formatDayjs = (dayjsObject: Dayjs): string => {
  return dayjsObject.format('dddd') + ', ' + dayjsObject.format('L');
};

const getInitialDateForStoreInitialisation = (store: SchoolYearStore): Dayjs | undefined => {
  const today = dayjs();

  if (!store.currentSchoolYearStart || !store.currentSchoolYearEnd) {
    return undefined;
  }

  if (today.isAfter(store.currentSchoolYearStart) && today.isBefore(store.currentSchoolYearEnd)) {
    return today;
  } else if (today.isAfter(store.currentSchoolYearEnd)) {
    return store.currentSchoolYearEnd;
  } else if (today.isBefore(store.currentSchoolYearStart)) {
    return store.currentSchoolYearStart;
  }
};

export const ClassRegisterOverview = observer(() => {
  const { t } = useTranslation();
  const modalDelegate = useModalDelegate();
  const [store] = useState<ClassRegisterOverviewStore>(() => new ClassRegisterOverviewStore(7));
  const pageContentContainerRef = useRef<HTMLDivElement>(null);
  const modalStore = useStore(ModalStore);
  const configStore = useStore(ConfigStore);
  const schoolYearStore = useStore(SchoolYearStore);

  useIframeMessageCallback(HandleMessageAction.CR_OVERVIEW_PERIOD_DETAILS_UPDATE, crOverviewStorePostMsgHandler(store));

  useEffect(() => {
    const initialDate = getInitialDateForStoreInitialisation(schoolYearStore);
    if (initialDate) {
      store.init(initialDate);
    }
  }, [schoolYearStore.currentSchoolYear]);

  useEffect(() => {
    schoolYearStore.handleSchoolYearFallback();
  }, [schoolYearStore.currentSchoolYearDoesNotExist]);

  if (!store.hasFetchedConfig) {
    return null;
  }

  const isEmptyDateRange = (block: ClassRegOverviewPeriodV2Dto[] | IEmptyDateRange): block is IEmptyDateRange => {
    return (block as IEmptyDateRange).startDate !== undefined;
  };

  let columns: Columns<IPeriodRowData> = [
    {
      type: ColumnType.Text,
      key: 'hr',
      header: t('general.hr'),
      textStyle: {
        bold: true,
        color: 'gray-dark',
      },
      fixed: 'left',
      width: 20,
    },
    {
      type: ColumnType.Text,
      key: 'subject',
      header: t('general.subject'),
      textStyle: {
        bold: true,
        color: 'gray',
      },
      width: 100,
      ellipsis: true,
    },
    {
      type: ColumnType.SubstitutionText,
      key: 'teachers',
      header: t('general.teachers'),
      getSubstitutions: (row) => {
        return row.teachers.map((t) => {
          return {
            value: t.value,
            substituted: t.orgValue,
          };
        });
      },
      width: 100,
    },
    {
      type: ColumnType.Text,
      key: 'classes',
      header: t('general.classes'),
      textStyle: {
        bold: true,
      },
      width: 100,
    },
    {
      type: ColumnType.Text,
      key: 'teachingContent',
      header: t('general.teachingContent'),
      width: 200,
      ellipsis: true,
    },
    {
      type: ColumnType.Absences,
      key: 'absences',
      header: t('general.absences'),
      getAbsences: (row) => row.absences,
      width: 200,
    },
    {
      type: ColumnType.TextPopover,
      key: 'homework-column',
      header: t('general.homeworks'),
      getValue: (row) => row.homework,
      maxWidth: 200,
    },
  ];

  if (!store.showTeacherColumn) {
    columns = columns.filter((col) => col.key !== 'teachers');
  }

  const renderPeriods = (date: Dayjs, periods: ClassRegOverviewPeriodV2Dto[], week?: number) => {
    const rows: IPeriodRowData[] = periods.map((period) => {
      return {
        key: period.id,
        hr: period.hr > 0 ? period.hr : '-',
        classes: period.classes.map((cl) => cl.el.name).join(', '),
        subject: period.subject?.el?.name,
        teachers: period.teachers.map((t) => ({
          id: t.el.id.toString(),
          value: t.el.name,
          orgValue: t.orgEl?.name,
        })),
        teachingContent: period.topic ? period.topic : '',
        absences: date.isSameOrBefore(dayjs())
          ? {
              isChecked: period.absChecked === true,
              ...store.resolveAbsencesStats(period.stAbsences ? period.stAbsences : []),
            }
          : undefined,
        homework: period.hwIds ? period.hwIds?.map((id) => store.resolveHomeworkById(id)) : [],
      };
    });

    const id = uDateFromDayjs(date).toString();

    const today = dayjs();
    const options: Option[] =
      today.isBefore(schoolYearStore.currentSchoolYearEnd) && today.isAfter(schoolYearStore.currentSchoolYearStart)
        ? [
            {
              label: t('general.today'),
              onClick: () => store.jumpToToday(),
            },
          ]
        : [];

    options.push(
      ...[
        {
          label: t('general.schoolyearStart'),
          onClick: () => store.jumpToStart(),
        },
        {
          label: t('general.schoolyearEnd'),
          onClick: () => store.jumpToEnd(),
        },
        {
          label: t('general.openCalendar'),
          onClick: () => {
            setTimeout(() => {
              modalStore
                .openModalDatePicker(date, undefined, store.disabledDate, false, configStore.calendarHolidays)
                .then((result) => {
                  if (!result.cancelled && result.value) {
                    store.handleDateChange(result.value, false);
                  }
                });
            }, 300); // wait before open the modal until the popup is closed, otherwise it looks strange
          },
        },
      ],
    );

    return (
      <div className="day-content" id={id} key={id}>
        <div className="calendar-line">
          {!!week && (
            <h1 key={week} className="week-header">
              {t('general.weekNum', { week: week })}
            </h1>
          )}
          <div className="line" />
          {/* we need to use getPopupContainer, otherwise the popup won't scroll with the rest of the content */}
          <CalendarPopup
            getPopupContainer={() => document.getElementById('cro-popup-container') as HTMLElement}
            date={date}
            options={options}
          />
          <div className="line" />
        </div>
        <WUTable<IPeriodRowData>
          shadow={true}
          columns={columns}
          rowData={rows}
          verticalAlign="middle"
          onRowClick={(row) => modalDelegate.openSinglePeriodDetailsDialog({ periodId: row.key }, 'class-register')}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (store.emptyIndicatorLabel) {
      return <EmptyIndicator icon={store.showHint ? 'search' : undefined} title={store.emptyIndicatorLabel} />;
    }

    let currentWeek = 0;
    const components: ReactNode[] = [];
    store.periods.forEach((entry) => {
      if (isEmptyDateRange(entry)) {
        const startDayjs = dayjsFromUDateWithoutTime(entry.startDate);
        const endDayjs = dayjsFromUDateWithoutTime(entry.endDate);

        // only show placeholder (empty date ranges) if it is longer or equals the amount of days to fetch
        const hidePlaceholder = endDayjs.diff(startDayjs, 'days') < store.daysToFetch;

        const startDate = formatDayjs(startDayjs);
        const endDate = formatDayjs(endDayjs);

        const placeholder =
          entry.startDate === entry.endDate
            ? t('general.noResultsOn', { date: startDate })
            : t('general.noResultsFromTo', { startDate, endDate });

        const id = entry.startDate.toString();

        if (hidePlaceholder) {
          // even if not visible we need an anchor element, so that we still can "scroll" to the correct position
          components.push(<div className="invisible-placeholder" id={id} key={id} />);
        } else {
          components.push(
            <div className="placeholder" id={id} key={id}>
              <div className="line" />
              <h3>{placeholder}</h3>
              <div className="line" />
            </div>,
          );
        }
      } else {
        const date = dayjsFromUDateWithoutTime(entry[0].date);
        const week = date.isoWeek();
        if (week !== currentWeek) {
          currentWeek = week;
          components.push(renderPeriods(date, entry, currentWeek));
        } else {
          components.push(renderPeriods(date, entry));
        }
      }
    });

    return components;
  };

  const className = clsx('class-register-overview', {
    'show-admin-hint': store.showHint,
  });

  return (
    <Page
      scrollableContainerId="class-register-overview-page"
      compact
      searchBar={{
        selectedFreeTextOptions: store.selectedFreeTextOptions,
        onChangeFreeTextOptions: (values) => store.setSelectedFreeTextSearchOptions(values),
        options: store.searchBarOptions,
        onChangeSelectOptions: (options) => store.handleSearchBarOptionsChanged(options),
        selectedOptions: store.selectedOptions,
        onlyOneOptionPerCategory: true,
        toggleFilters: store.filter,
      }}
      scrollMode="scrollContent"
      contentContainerRef={pageContentContainerRef}
      isPageLoading={!store.hasFetchedConfig}
      isPageContentLoading={store.isInProgress}
    >
      <Scroller
        disabled={store.isInProgress}
        scrollableParentRef={pageContentContainerRef}
        hintDown={t('general.scrollToSeeNextXDays', { days: store.daysToFetch })}
        onScrollToEnd={() => store.fetchNextPeriods()}
        disableScrollDown={store.isScrollingDisabled || store.reachedEndOfSchoolyear || store.reachedAllowedEndDate}
        disableScrollDownLabel={store.disabledScrollDownLabel}
        hintUp={t('general.scrollToSeePrevXDays', { days: store.daysToFetch })}
        onScrollToStart={() => store.fetchPreviousPeriods()}
        disableScrollUp={store.isScrollingDisabled || store.reachedStartOfSchoolyear || store.reachedAllowedStartDate}
        disableScrollUpLabel={store.disabledScrollUpLabel}
        testId={TestIds.CLASS_REGISTER_OVERVIEW}
      >
        <div className={className} id="cro-popup-container">
          {renderContent()}
        </div>
      </Scroller>
    </Page>
  );
});
