import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dayjs } from 'dayjs';

import { useOutsideClick } from '../../../hooks/useClickOutside';
import TimeRangePickerInput12h from '../time-range-picker-input-12h/time-range-picker-input-12h';
import TimeRangePickerInput24h from '../time-range-picker-input-24h/time-range-picker-input-24h';
import TimeRangePickerStore, { ITimeRangePickerTimeSlot, TimeRangePickerMode } from '../time-range-picker-store';
import TimeRangePickerTimeSlot from '../time-range-picker-time-slot/time-range-picker-time-slot';

import '../time-range-picker.less';

interface IProps {
  startTime: Dayjs;
  endTime: Dayjs;
  mode: TimeRangePickerMode;
  store: TimeRangePickerStore;
}

/**
 * Form that opens after the user clicks the label of the TimeRangePicker.
 * Depending on the format it renders a special input for 12h or 24h formatted time entries.
 * Depending on the stores TimeRangePickerTimeSlot it creates buttons for quick selecting start and end time
 */
const TimeRangePickerForm = observer((props: IProps) => {
  const ref = useOutsideClick(() => {
    props.store.toggleEditMode();
  });

  const getLeftSlots = (): ITimeRangePickerTimeSlot[] => {
    const half: number = Math.ceil(props.store.timeSlots.length / 2);
    return props.store.timeSlots.slice(0, half);
  };

  const getRightSlots = (): ITimeRangePickerTimeSlot[] => {
    const half: number = Math.ceil(props.store.timeSlots.length / 2);
    return props.store.timeSlots.slice(half, props.store.timeSlots.length);
  };

  const renderSlots = (slots: ITimeRangePickerTimeSlot[]) => {
    return slots.map((slot, i) => {
      return (
        <TimeRangePickerTimeSlot
          store={props.store}
          label={slot.label}
          startTime={slot.startTime}
          endTime={slot.endTime}
          key={'slot-' + i}
        />
      );
    });
  };

  return (
    <div className="time-range-picker-form" ref={ref} data-testid="time-range-picker-form">
      <div className="input-row">
        {props.store.is12HourFormat ? (
          <TimeRangePickerInput12h
            store={props.store}
            startTime={props.startTime}
            endTime={props.endTime}
            mode={props.mode}
          />
        ) : (
          <TimeRangePickerInput24h
            startTime={props.startTime}
            endTime={props.endTime}
            mode={props.mode}
            store={props.store}
          />
        )}
      </div>
      <div className="time-slot-container">
        <div className="left-column">{renderSlots(getLeftSlots())}</div>
        <div className="right-column">{renderSlots(getRightSlots())}</div>
      </div>
    </div>
  );
});

export default TimeRangePickerForm;
