import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { TestIds } from '@/testIds';
import useStore from '@/hooks/useStore';
import TimetableLegendFilter from '@te/standard/components/footer/legend-filter/timetable-legend-filter';
import HelpCenterButton from '@/ui-components/help-center-button/help-center-button';
import TimetableFeatureOnboardingStore from '@te/standard/stores/onboarding/timetable-feature-onboarding-store';

import './timetable-footer.less';

const TimetableFooter = observer(() => {
  const { t } = useTranslation();
  const timetableFeatureOnboardingStore = useStore(TimetableFeatureOnboardingStore);

  const handleHelpButtonClicked = () => {
    timetableFeatureOnboardingStore.getOnboarding().then(timetableFeatureOnboardingStore.showOnboarding);
  };
  return (
    <div className="timetable-footer">
      <div className="timetable-footer--left-helper" />
      <TimetableLegendFilter />
      <div className="timetable-footer--right">
        <HelpCenterButton
          testId={TestIds.TIMETABLE_HELP_CENTER_BUTTON}
          onClick={handleHelpButtonClicked}
          tooltip={t('timetable.featureOnboarding.helpCenterButtonTooltip')}
          withBlinking={!timetableFeatureOnboardingStore.isOnboardingStarted}
        />
      </div>
    </div>
  );
});

export default TimetableFooter;
