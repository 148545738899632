import { t } from 'i18next';
import { observable, action, computed } from 'mobx';

import { Store, inject } from '@/types/store';
import { SystemSettingsApi } from '@/stores/api-store';
import { showErrorResponse } from '@/utils/error-handling/error-message';
import TodayPermissionsStore from '@today/stores/today-permissions-store';
import TodayStore from '@today/stores/today-store';

@Store()
class TodaySupportAccessStore {
  private todayStore = inject(TodayStore);
  private todayPermissionsStore = inject(TodayPermissionsStore);

  @observable private _isSupportAccessOpen?: boolean = undefined;

  @observable isSupportAccessBeingChanged = false;

  @action.bound
  async handleSupportAccessChange() {
    const current = this.isSupportAccessOpen;
    const enable = !current;

    this._isSupportAccessOpen = enable;
    this.isSupportAccessBeingChanged = true;

    try {
      await SystemSettingsApi.updateSupportAccess({ enable });
    } catch (error) {
      showErrorResponse(error);
      // optimistic response needs to be reverted on failure
      this._isSupportAccessOpen = current;
    } finally {
      this.isSupportAccessBeingChanged = false;
    }
  }

  @computed
  get isSupportAccessOpen() {
    if (this._isSupportAccessOpen === undefined) {
      return this.todayStore.isSupportAccessOpen;
    }
    return this._isSupportAccessOpen;
  }

  @computed
  get showIsSupportAccessOpen() {
    return this.todayPermissionsStore.canSeeSupportAccessToggle;
  }

  @computed
  get supportAccessCta() {
    return this.isSupportAccessOpen ? t('general.disableSupportAccess') : t('general.enableSupportAccess');
  }
}

export default TodaySupportAccessStore;
