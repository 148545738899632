import React from 'react';
import { observer } from 'mobx-react-lite';

import useStore from '@/hooks/useStore';
import { TimetableUserActionsStore } from '@te/standard/stores/user-actions/timetable-user-actions-store';
import { TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import { TimetableGridDaysStore } from '@te/standard/stores/grid/timetable-grid-days-store';
import DatePickerWithArrows from '@/ui-components/date-picker-with-arrows/date-picker-with-arrows';

const TimetableHeaderDatePicker = observer(() => {
  const timetableUserActionsStore = useStore(TimetableUserActionsStore);
  const timetableMetaStore = useStore(TimetableMetaStore);
  const timetableGridDaysStore = useStore(TimetableGridDaysStore);

  return (
    <DatePickerWithArrows
      mode={timetableMetaStore.timetableViewType === 'week' ? 'week' : 'day'}
      value={timetableMetaStore.selectedDate}
      onChange={timetableUserActionsStore.selectDate}
      disabledDate={(date) =>
        !timetableMetaStore.isDateInCurrentSchoolYear(date) || !timetableGridDaysStore.hasTimeGridDay(date)
      }
      getNextDay={timetableGridDaysStore.getNextTimeGridDay}
      getPreviousDay={timetableGridDaysStore.getPreviousTimeGridDay}
      minWidth={328}
    />
  );
});

export default TimetableHeaderDatePicker;
