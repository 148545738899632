import { AvatarProps as AntdAvatarProps } from 'antd/lib/avatar';
import React from 'react';

import nameToInitials from '@/utils/name-to-initials/name-to-initials';
import { Avatar } from '@/ui-components';

export interface IUser {
  imageUrl?: string;
  displayName: string;
}

export type AvatarProps = AntdAvatarProps & {
  user: IUser | undefined;
};

export const UserAvatar = (props: AvatarProps) => {
  const { user, ...other } = props;

  if (!user) return null;

  return (
    <Avatar src={user.imageUrl} {...other}>
      {nameToInitials(user.displayName!)}
    </Avatar>
  );
};

export default UserAvatar;
