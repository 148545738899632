import { action, computed, observable } from 'mobx';
import { FormInstance } from 'antd';
import { Key } from 'react';

import RightsStore, { ElementType as ElementTypeEnum, Right } from '@/stores/rights-store';
import { inject } from '@/types/store';
import ModalStore from '@/stores/modal-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import ConfigStore from '@/stores/config-store';
import ReportStore from '@/stores/report-store';
import SettingsStore from '@/stores/settings-store';

type DeprecatedAbstractListViewStoreOptions = {
  elementType?: ElementTypeEnum;
  right?: Right;
};

// TODO: AbstractListViewStore uses things that not necessarly makes sense for all list views. (e.g. submitAllDisabled)
// + also if we introduce a concept like this, we must ensure that everyone sticks to it.

/** *
 * @deprecated in favor of AbstractListViewStore (FP-248)
 */
export abstract class DeprecatedAbstractListViewStore<ElementFormType> {
  @observable protected _notificationStore: NotificationStore = inject(NotificationStore);
  @observable protected _modalStore: ModalStore = inject(ModalStore);
  @observable protected _settingsStore: SettingsStore = inject(SettingsStore);
  @observable private _reportStore: ReportStore = inject(ReportStore);
  @observable private _rightStore: RightsStore = inject(RightsStore);
  @observable private _configStore: ConfigStore = inject(ConfigStore);
  @observable private _isMetaLoading: boolean = true;
  @observable private _isDataLoading: boolean = true;
  @observable private readonly _formInstance: FormInstance<ElementFormType>;
  @observable private _submitAllDisabled: boolean = true;
  @observable private _searchInputValue: string = '';
  @observable private _selectedRowKeys: Key[] = [];
  private readonly _elementType?: ElementTypeEnum; // necessary for right checks
  private readonly _right: Right;

  protected constructor(
    form: FormInstance<ElementFormType>,
    { elementType, right }: DeprecatedAbstractListViewStoreOptions = {},
  ) {
    this._elementType = elementType;
    this._formInstance = form;
    this._right = right ?? Right.MASTERDATA;
  }

  public generateReport(name: string, format?: string, additionalProperties?: Map<string, string>) {
    let url = `WebUntis/reports.do?name=${name}&format=${format ?? 'pdf'}`;
    additionalProperties &&
      additionalProperties.forEach((value, key) => {
        url += `&${key}=${value}`;
      });
    this._reportStore.getReport(url);
  }

  @computed
  get submitAllDisabled(): boolean {
    return this._submitAllDisabled;
  }

  @computed
  get form(): FormInstance<ElementFormType> {
    return this._formInstance;
  }

  @computed
  get isMetaLoading(): boolean {
    return this._isMetaLoading;
  }

  @computed
  get isDataLoading(): boolean {
    return this._isDataLoading;
  }

  private static isElementTypeSpecified(elementType?: ElementTypeEnum): elementType is ElementTypeEnum {
    if (elementType) {
      return true;
    }
    throw new Error('No element type specified!');
  }

  @computed
  get canRead(): boolean | undefined {
    if (DeprecatedAbstractListViewStore.isElementTypeSpecified(this._elementType)) {
      return this._rightStore.canRead(this._right, this._elementType, true);
    }
  }

  @computed
  get canDelete(): boolean | undefined {
    if (DeprecatedAbstractListViewStore.isElementTypeSpecified(this._elementType)) {
      return this._rightStore.canDelete(this._right, this._elementType, true);
    }
  }

  @computed
  get canEdit(): boolean | undefined {
    if (DeprecatedAbstractListViewStore.isElementTypeSpecified(this._elementType)) {
      return this._rightStore.canWrite(this._right, this._elementType, true);
    }
  }

  @computed
  get canCreate(): boolean | undefined {
    if (DeprecatedAbstractListViewStore.isElementTypeSpecified(this._elementType)) {
      return this._rightStore.canCreate(this._right, this._elementType, true);
    }
  }

  @computed
  get searchInputValue(): string {
    return this._searchInputValue;
  }

  @computed
  get selectedRowKeys(): Key[] {
    return this._selectedRowKeys;
  }

  get selectedDepartmentId(): number {
    return this._configStore.selectedDepartmentId;
  }

  @action
  setIsMetaLoading(value: boolean) {
    this._isMetaLoading = value;
  }

  @action
  setIsDataLoading(value: boolean) {
    this._isDataLoading = value;
  }

  @action
  setSearchInputValue(value: string) {
    this._searchInputValue = value;
  }

  @action
  setSelectedRowKeys(keys: Key[]) {
    this._selectedRowKeys = keys;
  }

  @action
  setSubmitAllDisabled(disabled: boolean) {
    this._submitAllDisabled = disabled;
  }

  /**
   * @param columns All the columns of a row (e.g. short-name, long-name, ...) the search-function should consider.
   */
  containsSearchInput(columns: string[]): boolean {
    for (const column of columns) {
      if (!column) {
        continue;
      }
      if (column.toUpperCase().includes(this.searchInputValue.trim().toUpperCase())) {
        return true;
      }
    }
    return false;
  }
}
