import { useState } from 'react';
import { Constructable, Container } from 'typedi';

/**
 * Hook to retrieve the instance of a Store. It will only fetch the instance on the first render of the component
 * and should therefore be used instead of `inject` in React Components.
 */
export default function useStore<T>(type: Constructable<T>) {
  const [store] = useState(() => Container.get(type));

  return store;
}
