import { observer } from 'mobx-react-lite';
import React from 'react';
import { ArcherElement } from 'react-archer';

import useStore from '@/hooks/useStore';
import MenuItem from '@/ui-components/navigation-bar/menu-item/menu-item';
import { NavigationBarStore } from '@/ui-components/navigation-bar/navigation-bar-store';
import nameToInitials from '@/utils/name-to-initials/name-to-initials';
import { Avatar, Icon } from '@/ui-components';

import './student-selection.less';
import '../user-item/user-item.less';

const StudentSelection = observer(() => {
  const store = useStore(NavigationBarStore);

  // if there are less than 2 students, we don't need any student selection
  if (store.students.length < 2) {
    return null;
  }

  const handleStudentItemClick = (id: number) => {
    store.onSelectStudent && store.onSelectStudent(id);
  };

  // If there are more than 3 students, we just show one item to display the selected student
  // It the user clicks that item, a list of available students is displayed
  return (
    <ArcherElement id="menu-student-selection">
      <div className="menu-student-selection">
        {store.students.length > 3 ? (
          <MenuItem
            key={store.allStudentsText}
            name={store.selectedStudentName}
            content={<span className="name">{store.selectedStudentName}</span>}
            icon={
              store.selectedStudentId === -1 ? (
                <Avatar className="all-students-avatar" shape="circle" size={40} icon={<Icon type="students" />} />
              ) : (
                <Avatar shape="circle" size={40} src={store.selectedStudentImage}>
                  {nameToInitials(store.selectedStudentName)}
                </Avatar>
              )
            }
            selected={false}
            onClick={() => {
              if (store.collapsed) {
                store.setCollapsed(false);
              }
              store.isInStudentSelection = true;
            }}
            isDense={false}
            isAccessible={store.isMainMenuAccessible}
          />
        ) : (
          <>
            <MenuItem
              className="user-item"
              name={store.allStudentsText}
              content={<span className="name">{store.allStudentsText}</span>}
              tooltipTitle={store.allStudentsText}
              isDense={false}
              selected={store.selectedStudentId === -1}
              icon={<Avatar className="all-students-avatar" shape="circle" size={40} icon={<Icon type="students" />} />}
              isAccessible={true}
              onClick={() => handleStudentItemClick(-1)}
              key="student-all"
            />
            {store.students.map((student) => {
              return (
                <MenuItem
                  className="user-item"
                  name={student.name}
                  content={<span className="name">{student.name}</span>}
                  tooltipTitle={student.name}
                  isDense={false}
                  selected={store.selectedStudentId === student.id}
                  icon={
                    <Avatar shape="circle" size={40} src={student.image}>
                      {nameToInitials(student.name)}
                    </Avatar>
                  }
                  isAccessible={true}
                  onClick={() => handleStudentItemClick(student.id)}
                  key={'student-' + student.id}
                />
              );
            })}
          </>
        )}
      </div>
    </ArcherElement>
  );
});

export default StudentSelection;
