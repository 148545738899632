import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'clsx';

import useStore from '@/hooks/useStore';
import TimetableGridCard from '@te/standard/components/grid/day/card/timetable-grid-card';
import TimetableSummarisedEntries from '@te/standard/components/grid/day/card/timetable-summarised-entries';
import TimetableLayer from '@te/standard/components/grid/day/layer/timetable-layer';
import { TimetableDataStore } from '@te/standard/stores/data/timetable-data-store';
import { TimetableGridDimensionsStore } from '@te/standard/stores/grid/timetable-grid-dimensions-store';
import { IWeekDayValue } from '@te/standard/stores/meta/timetable-meta-store';

import './timetable-grid-day.less';

interface IProps {
  timeGridDay: IWeekDayValue;
  isToday?: boolean;
}

const TimetableGridDay = observer((props: IProps) => {
  const timetableDataStore = useStore(TimetableDataStore);
  const timetableGridDimensionsStore = useStore(TimetableGridDimensionsStore);

  const { timeGridDay, isToday } = props;
  const timetableEntries = timetableDataStore.getTimetableEntries(timeGridDay.dayValue);
  const { gridEntries, layers } = timetableEntries;
  const { timetableGridMinDayWidth } = timetableGridDimensionsStore;

  return (
    <div
      className={classNames('timetable-grid--weekday-container', { today: isToday })}
      style={{ minWidth: timetableGridMinDayWidth }}
    >
      <div className="timetable-grid--weekday-inner">
        {gridEntries.map((parallelEntries) => (
          <>
            {parallelEntries.entries.map((gridEntry) => {
              return <TimetableGridCard key={gridEntry.periodIds.join(',')} gridEntry={gridEntry} />;
            })}
            {parallelEntries.summarisedEntries && (
              <TimetableSummarisedEntries {...parallelEntries.summarisedEntries} day={timeGridDay.dayValue} />
            )}
          </>
        ))}
        {layers.map((layer) => (
          <TimetableLayer key={layer.id} {...layer} />
        ))}
      </div>
    </div>
  );
});

export default TimetableGridDay;
