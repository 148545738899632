import React from 'react';
import { observer } from 'mobx-react-lite';

import TimetableGridSlotTime from '@te/standard/components/grid/slot/timetable-grid-slot-time';
import TimetableGridSlotNameNumber from '@te/standard/components/grid/slot/timetable-grid-slot-name-number';
import { ITimeGridSlot, TimetableGridSlotsStore } from '@te/standard/stores/grid/timetable-grid-slots-store';
import useStore from '@/hooks/useStore';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import { TimetableTimeStore } from '@te/standard/stores/time/timetable-time-store';
import { TimetableGridDimensionsStore } from '@te/standard/stores/grid/timetable-grid-dimensions-store';

interface IProps {
  index: number;
  timeGridSlot: ITimeGridSlot;
  isFirstSlot: boolean;
  isLastSlot: boolean;
}

const HIDE_END_TIME_THRESHOLD = 50;

const TimetableGridSlot = observer((props: IProps) => {
  const { index, timeGridSlot, isFirstSlot, isLastSlot } = props;

  const timetableFormatStore = useStore(TimetableFormatStore);
  const timetableTimeStore = useStore(TimetableTimeStore);
  const timetableGridDimensionsStore = useStore(TimetableGridDimensionsStore);
  const timetableSlotsStore = useStore(TimetableGridSlotsStore);

  const { showStartEndTimeOfSlots, isUntisGrid } = timetableFormatStore;

  const startTimeOffset = timetableGridDimensionsStore.offsetFromStartTime(timeGridSlot.startTime);
  const endTimeOffset = timetableGridDimensionsStore.offsetFromStartTime(timeGridSlot.endTime);
  const durationHeight = endTimeOffset - startTimeOffset;

  const showSlotNameNumber = isUntisGrid && (!!timeGridSlot.value || !!timeGridSlot.name);
  const shouldAdjustSlotStartText = isUntisGrid && !timetableFormatStore.showBreaks && !isFirstSlot;
  const shouldAdjustSlotEndText = isUntisGrid && !timetableFormatStore.showBreaks && !isLastSlot;
  const slotStartTextPosition = shouldAdjustSlotStartText ? 'below' : 'normal';
  const slotEndTextPosition = shouldAdjustSlotEndText ? 'above' : 'normal';

  const hasBreakBefore = timetableSlotsStore.isSlotHidden(index - 1) || timetableSlotsStore.hasBreakBefore(index);
  const hasSameStartEnd = timeGridSlot.startTime.diff(timeGridSlot.endTime, 'minutes') == 0;
  const hideStartTime = timeGridSlot.isBreak || hasSameStartEnd || (!isFirstSlot && !hasBreakBefore);
  const hideEndTime = timeGridSlot.isBreak;
  const hideEndTimeText = showSlotNameNumber && durationHeight <= HIDE_END_TIME_THRESHOLD;

  return (
    <>
      {!hideStartTime && (
        <TimetableGridSlotTime
          value={timeGridSlot.startTime}
          top={startTimeOffset}
          hideText={
            timetableTimeStore.shouldHideTimeGridSlotText(timeGridSlot.startTime, slotStartTextPosition) ||
            (isUntisGrid && !showStartEndTimeOfSlots)
          }
          textPosition={slotStartTextPosition}
        />
      )}
      {!hideEndTime && (
        <TimetableGridSlotTime
          value={timeGridSlot.endTime}
          top={endTimeOffset}
          hideText={
            timetableTimeStore.shouldHideTimeGridSlotText(timeGridSlot.endTime, slotEndTextPosition) ||
            (isUntisGrid && !showStartEndTimeOfSlots) ||
            hideEndTimeText
          }
          textPosition={slotEndTextPosition}
        />
      )}
      {showSlotNameNumber && (
        <TimetableGridSlotNameNumber
          timeGridSlot={timeGridSlot}
          slotStartTextPosition={slotStartTextPosition}
          slotEndTextPosition={slotEndTextPosition}
        />
      )}
    </>
  );
});

export default TimetableGridSlot;
