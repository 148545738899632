import { t } from 'i18next';

const LABEL_PLACEHOLDER_MAP: Map<string, string> = new Map<string, string>([
  ['{IDC_KURZNAME}', 'general.shortName'],
  ['{IDC_LANGNAME}', 'general.longName'],
  ['{IDC_NAME}', 'general.name'],
  ['{IDC_NACHNAME}', 'general.lastname'],
  ['{IDC_VORNAME}', 'general.firstname'],
  ['{IDC_AKADEM_TITEL}', 'general.title'],
  ['{LBL_EXTERN_ID}', 'general.externalId'],
  ['{LBL_ALIAS}', 'general.aliasName'],
  ['{LBL_REMARK}', 'general.remark'],
  ['{LBL_CLASS_TEACHER}', 'general.classTeacher'],
]);

export const replaceLabelPlaceholder = (label: string): string => {
  let result = label;
  LABEL_PLACEHOLDER_MAP.forEach((value, key) => {
    result = result.replaceAll(key, t(value));
  });
  return result;
};
