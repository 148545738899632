import { observer } from 'mobx-react-lite';
import React from 'react';
import Div100vh from 'react-div-100vh';
import { useTranslation } from 'react-i18next';

import PlatformApplicationCalendarIntegration from '@pa/components/platform-application-calendar-integration/platform-application-calendar-integration';
import useStore from '@/hooks/useStore';
import PlatformDetailViewStore from '@pa/stores/platform-detail-view-store';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import { PageHeader } from '@/ui-components/page/page-header/page-header';
import { Button, DeprecatedForm, SwitchComponent, WUForm } from '@/ui-components';
import RedirectUriInput from '@pa/components/redirect-uri-input/redirect-uri-input';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import { getPlatformLogo } from '@pa/utils/platform-utils';
import { TestIds } from '@/testIds';
import './platform-detail-view.less';
import PlatformConfigurationDetailsTabs from '@pa/components/platform-configuration-tabs/platform-configuration-details-tabs';
import PlatformConfigurationMenuTabs from '@pa/components/platform-configuration-tabs/platform-configuration-menu-tabs';
import PlatformConfigurationExamTabs from '@pa/components/platform-configuration-tabs/platform-configuration-exam-tabs';
import { WUFormItem } from '@/ui-components/wu-form';
import PlatformDetailDataInterfaceIntegrationSection from '@pa/components/platform-detail-view/platform-detail-data-interface-integration-section';
import { Tooltip } from '@/ui-components';

const PlatformDetailView = observer(() => {
  const { t } = useTranslation();
  const platformDetailViewStore = useStore(PlatformDetailViewStore);
  const [form] = DeprecatedForm.useForm();
  const domainFormFieldName = 'domainField';

  useComponentDidMount(() => {
    platformDetailViewStore.setForm(form);
    platformDetailViewStore.setDomainFormFieldName(domainFormFieldName);
  });

  if (platformDetailViewStore.isLoading) {
    return (
      <PageHeader isSticky={false} shouldBreak={false} isWhite className="empty-indicator-wrapper">
        <EmptyIndicator title={t('general.loading')} description="" />
      </PageHeader>
    );
  }

  const platformViewData = platformDetailViewStore.platformViewData;

  const resetPassword = () => {
    platformViewData && platformDetailViewStore.resetPlatformApplicationPassword(platformViewData.id);
  };

  const isFirstSave = !platformDetailViewStore.platformViewData?.id;

  const isReadonlyPlatform = platformViewData ? !platformViewData.deletable : false;

  const userCannotDeactivate = isFirstSave || isReadonlyPlatform;

  const readonlyTooltip = isReadonlyPlatform ? t('platform.details.readonlyplatformhint') : '';

  return (
    <WUForm
      hideSubmitButton
      onSubmit={platformDetailViewStore.savePlatformDetails}
      className="platform-detail-view-form"
      form={form}
    >
      <Div100vh className="modal-body">
        <div className="platform-page-header">
          {getPlatformLogo(platformDetailViewStore.platformViewData?.icon)}
          <h1 className="platform-headline">{platformDetailViewStore.platformViewData?.name}</h1>
        </div>
        <div className="page-content">
          <div className="activate-registration">
            <label>{t('platform.details.activateApplication')}</label>
            <Tooltip title={readonlyTooltip} placement="right" autoAdjustOverflow={false}>
              <SwitchComponent
                id="activate-application-switch"
                size="default"
                checked={platformDetailViewStore.active}
                disabled={userCannotDeactivate}
                onChange={(checked) => (platformDetailViewStore.active = checked)}
              />
            </Tooltip>
          </div>
          <WUFormItem name={domainFormFieldName}>
            <div className="prefix-url">
              <span className="prefix-url--title">{t('platform.details.prefixUrl') + ':'}</span>
              <span>
                <RedirectUriInput
                  initialDomain={platformDetailViewStore.domain}
                  domainPattern={platformDetailViewStore.domainPattern}
                  setStoreDomain={platformDetailViewStore.setDomain}
                />
              </span>
            </div>
          </WUFormItem>
          {platformDetailViewStore.showDataInterfaceSection && <PlatformDetailDataInterfaceIntegrationSection />}
          {platformDetailViewStore.showUserInterfaceIntegration && (
            <div className="user-interface-section">
              <label className="title">{t('general.userInterfaceIntegration')}</label>
              <div className="configuration-tabs">
                <PlatformConfigurationMenuTabs />
                <PlatformConfigurationDetailsTabs />
                <PlatformConfigurationExamTabs />
              </div>
            </div>
          )}
          {platformViewData?.calendarIntegration.displayIntegrationSection && (
            <PlatformApplicationCalendarIntegration
              info={platformViewData.calendarIntegration}
              testId={TestIds.PLATFORM_APPLICATION_CALENDAR_INTEGRATION_SECTION}
            />
          )}
        </div>
        <div className="page-footer">
          {!platformDetailViewStore.isRegisterMode && (
            <span className="left-footer-buttons">
              <Tooltip title={readonlyTooltip} placement="top" autoAdjustOverflow={false}>
                <Button
                  id="delete-platform-app-button"
                  size="large"
                  outline
                  onClick={platformDetailViewStore.handleDelete}
                  disabled={isReadonlyPlatform}
                >
                  {t('general.delete')}
                </Button>
              </Tooltip>
              {platformDetailViewStore.showDataInterfaceSection && (
                <Button
                  id="reset-platform-app-password-button"
                  type="secondary"
                  outline
                  size="large"
                  onClick={resetPassword}
                >
                  {t('general.resetPassword')}
                </Button>
              )}
            </span>
          )}
          <Button
            id="save-platform-details-button"
            type="primary"
            size="large"
            onSubmit={platformDetailViewStore.savePlatformDetails}
            triggersSubmit
            disabled={false}
          >
            {t('general.save')}
          </Button>
        </div>
      </Div100vh>
    </WUForm>
  );
});

export default PlatformDetailView;
