import { action, computed, observable } from 'mobx';
import { Dayjs } from 'dayjs';

import { TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import { TimetableGridDaysStore } from '@te/standard/stores/grid/timetable-grid-days-store';
import { TimetableGridSlotsStore } from '@te/standard/stores/grid/timetable-grid-slots-store';
import { inject, Store } from '@/types/store';
import { MINIMAL_VARIANT_HEIGHT_THRESHOLD } from '@/components/lesson-card/hooks/use-computed-lesson-card-props';

export const MIN_CARD_HEIGHT = MINIMAL_VARIANT_HEIGHT_THRESHOLD + 2;
export const MIN_TIMETABLE_GRID_WIDTH = 768 - 76;
export const TIMETABLE_GRID_PADDING_BOTTOM = 80;
export const TIME_GRID_START_DAY_OFFSET = 40;
export const TIME_GRID_DAY_GAP = 16;

/**
 * LESSON_GRID - min height of a timegrid slot to show 4 lines in a lesson card that spans the whole slot
 */
const LESSON_GRID_MIN_SLOT_HEIGHT = 79;

/**
 * CLOCK_HOURS - min height of an hour to show 4 lines in a 45min lesson card
 */
const CLOCK_HOURS_MIN_HOUR_HEIGHT = 106;

export interface ITimeGridDimension {
  width: number;
  height: number;
}

@Store()
export class TimetableGridDimensionsStore {
  private timetableMetaStore = inject(TimetableMetaStore);
  private timetableFormatStore = inject(TimetableFormatStore);
  private timetableGridDaysStore = inject(TimetableGridDaysStore);
  private timetableGridSlotsStore = inject(TimetableGridSlotsStore);

  @observable private _timetableGridDimension: ITimeGridDimension = {
    width: 0,
    height: 0,
  };

  @action
  reset() {
    this._timetableGridDimension = {
      width: 0,
      height: 0,
    };
  }

  offsetFromStartTime(time: Dayjs): number {
    const { timeGridStartTime } = this.timetableGridSlotsStore;
    const normalizedTime = timeGridStartTime.set('hours', time.get('hours')).set('minutes', time.get('minutes'));
    if (this.timetableFormatStore.isUntisGrid) {
      return this.offsetFromStartTimeLessonGrid(normalizedTime);
    } else {
      return this.offsetFromStartTimeClockHour(normalizedTime);
    }
  }

  durationHeight(startTime: Dayjs, endTime: Dayjs): number {
    if (this.timetableFormatStore.isUntisGrid) {
      return this.durationLessonGridHeight(startTime, endTime);
    } else {
      return this.durationClockHoursHeight(startTime, endTime);
    }
  }

  @computed
  get gridDayWidth(): number {
    const { currentTimetableDays } = this.timetableMetaStore;
    const totalGapBetweenTimeGridDays = TIME_GRID_DAY_GAP * (currentTimetableDays.length - 1);
    const netTimeGridDayContent =
      this._timetableGridDimension.width - TIME_GRID_START_DAY_OFFSET - TIME_GRID_DAY_GAP - totalGapBetweenTimeGridDays;
    const calculatedDayWidth = netTimeGridDayContent / currentTimetableDays.length;
    return Math.max(this.timetableGridMinDayWidth, calculatedDayWidth);
  }

  @computed
  get timetableGridMinDayWidth(): number {
    const { currentTimetableDays, timetableViewType } = this.timetableMetaStore;
    return timetableViewType === 'day'
      ? MIN_TIMETABLE_GRID_WIDTH - TIME_GRID_START_DAY_OFFSET - TIME_GRID_DAY_GAP
      : (MIN_TIMETABLE_GRID_WIDTH - TIME_GRID_START_DAY_OFFSET - TIME_GRID_DAY_GAP) /
          Math.min(currentTimetableDays.length, 7);
  }

  offsetFromStartDate(date: Dayjs): number {
    let leftOffset = TIME_GRID_START_DAY_OFFSET + TIME_GRID_DAY_GAP;
    let foundTimeGridDay = false;
    const { currentTimetableDays } = this.timetableMetaStore;
    const timeGridDay = this.timetableGridDaysStore.getTimeGridDay(date);

    currentTimetableDays.forEach((timetableDay) => {
      if (timetableDay.weekDayEnum === timeGridDay) {
        foundTimeGridDay = true;
      }
      if (!foundTimeGridDay && timetableDay.weekDayEnum !== timeGridDay) {
        leftOffset += this.gridDayWidth;
        leftOffset += TIME_GRID_DAY_GAP;
      }
    });

    return foundTimeGridDay ? leftOffset : 0;
  }

  dayDurationWidth(startDate: Dayjs, endDate: Dayjs): number {
    if (endDate.isBefore(startDate, 'day')) {
      return 0;
    }
    const numberOfDays = endDate.diff(startDate, 'days') + 1;
    return numberOfDays * this.gridDayWidth + (numberOfDays - 1) * TIME_GRID_DAY_GAP;
  }

  @computed
  private get gridMinuteHeight(): number {
    const { timeGridStartTime, timeGridEndTime } = this.timetableGridSlotsStore;

    const minutes = Math.abs(timeGridStartTime.diff(timeGridEndTime, 'minutes'));
    const calculatedMinuteHeight = this._timetableGridDimension.height / minutes;

    return Math.max(CLOCK_HOURS_MIN_HOUR_HEIGHT / 60, calculatedMinuteHeight);
  }

  @computed
  public get gridUnitHeight(): number {
    const { timeGridSlots, numberOfBreaks, showBreakBeforeSlots, showBreakAfterSlots } = this.timetableGridSlotsStore;

    let numberOfSlots = timeGridSlots.length;
    showBreakBeforeSlots && numberOfSlots--;
    showBreakAfterSlots && numberOfSlots--;

    const breaksHeight = numberOfBreaks * this.gridBreakHeight;
    const gridUnitHeight = (this._timetableGridDimension.height - breaksHeight) / numberOfSlots;

    return Math.max(LESSON_GRID_MIN_SLOT_HEIGHT, gridUnitHeight);
  }

  @computed
  public get gridBreakHeight(): number {
    return this.timetableFormatStore.showBreaks ? MIN_CARD_HEIGHT : 0;
  }

  private offsetFromStartTimeClockHour(time: Dayjs): number {
    const { timeGridStartTime } = this.timetableGridSlotsStore;
    const timeDifferenceInMinutes = Math.abs(time.diff(timeGridStartTime, 'minutes'));
    return timeDifferenceInMinutes * this.gridMinuteHeight;
  }

  /**
   * Calculates the offset from the start of the timeGrid by summarizing
   * all slots + all breaks + offset from startTime of the slot and/or break the given
   * @param time is contained
   */
  private offsetFromStartTimeLessonGrid(time: Dayjs): number {
    const { showBreakBeforeSlots } = this.timetableGridSlotsStore;

    const timeGridSlotIndex = this.findGridSlotOfTime(time);
    const restrictedSlotIndex = Math.max(timeGridSlotIndex - (showBreakBeforeSlots ? 1 : 0), 0); // within first break
    const numberOfBreaksBefore = this.timetableGridSlotsStore.numberOfBreaksBefore(time);
    const offsetFromTimeGridSlotStart = this.offsetFromTimeGridSlotStart(timeGridSlotIndex, time);

    return (
      restrictedSlotIndex * this.gridUnitHeight +
      numberOfBreaksBefore * this.gridBreakHeight +
      offsetFromTimeGridSlotStart
    );
  }

  private durationClockHoursHeight(startTime: Dayjs, endTime: Dayjs): number {
    return Math.abs(startTime.diff(endTime, 'minutes')) * this.gridMinuteHeight;
  }

  /**
   * Calculates the height of a timeGridSlot via diff of the offsets of
   * @param startTime of the timeGridSlot and
   * @param endTime of the timeGridSlot
   * from the start of the timeGrid
   */
  private durationLessonGridHeight(startTime: Dayjs, endTime: Dayjs): number {
    const { timeGridStartTime } = this.timetableGridSlotsStore;

    const normalizedStartTime = timeGridStartTime
      .set('hours', startTime.get('hours'))
      .set('minutes', startTime.get('minutes'));
    const normalizedEndTime = timeGridStartTime
      .set('hours', endTime.get('hours'))
      .set('minutes', endTime.get('minutes'));

    return (
      this.offsetFromStartTimeLessonGrid(normalizedEndTime) - this.offsetFromStartTimeLessonGrid(normalizedStartTime)
    );
  }

  /**
   * Tries to find a timeGridSlot that contains the given normalizedTime where for
   * @param normalizedTime slot.startTime <= normalizedTime < slot.endTime
   */
  private findGridSlotOfTime(normalizedTime: Dayjs): number {
    const { timeGridStartTime, timeGridEndTime, timeGridSlots } = this.timetableGridSlotsStore;

    if (normalizedTime.isSameOrBefore(timeGridStartTime, 'minutes')) {
      return 0;
    } else if (normalizedTime.isSameOrAfter(timeGridEndTime, 'minutes')) {
      return timeGridSlots.length - 1;
    }

    let result = 0;
    timeGridSlots.forEach((timeGridSlot, index) => {
      const isLastSlot = index === timeGridSlots.length - 1;
      const timeGridSlotStart = timeGridSlot.startTime;
      const timeGridSlotEnd = isLastSlot ? timeGridSlot.endTime : timeGridSlots[index + 1].startTime;

      if (
        timeGridSlotStart.isSameOrBefore(normalizedTime, 'minutes') &&
        timeGridSlotEnd.isAfter(normalizedTime, 'minutes')
      ) {
        result = index;
      }
    });

    return result;
  }

  /**
   * Calculates the offset of the given time from the beginning timeGridSlot in px
   * @param timeGridSlotIndex of the timeGridSlot the
   * @param normalizedTime is contained in (startTime <= normalizedTime < endTime)
   */
  private offsetFromTimeGridSlotStart(timeGridSlotIndex: number, normalizedTime: Dayjs): number {
    const { timeGridSlots } = this.timetableGridSlotsStore;

    const timeGridSlot = timeGridSlots[timeGridSlotIndex];

    // to avoid rounding issues from JS
    if (normalizedTime.isSame(timeGridSlot.startTime, 'minutes')) {
      return 0;
    } else if (normalizedTime.isSame(timeGridSlot.endTime, 'minutes')) {
      return this.gridUnitHeight;
    } else if (normalizedTime.isAfter(timeGridSlot.endTime)) {
      return (
        (timeGridSlot.startTime.diff(timeGridSlot.endTime, 'minutes') == 0 ? 0 : this.gridUnitHeight) +
        this.offsetFromBreakStart(timeGridSlotIndex, normalizedTime)
      );
    }

    const lengthOfSlot = Math.abs(timeGridSlot.startTime.diff(timeGridSlot.endTime, 'minutes'));
    const minutesFromStartTime = Math.abs(timeGridSlot.startTime.diff(normalizedTime, 'minutes'));

    return (minutesFromStartTime / lengthOfSlot) * this.gridUnitHeight;
  }

  /**
   * Calculates the offset from the start of a break after the timeGridSlot at the given
   * @param timeGridSlotIndex of the last timeGridSlot before the break
   * @param time the offset should be calculated for.
   * BEWARE: This function does not do any additional checks to verify that time indeed IS within a break!
   */
  private offsetFromBreakStart(timeGridSlotIndex: number, time: Dayjs) {
    const slotBefore = timeGridSlotIndex >= 0 && this.timetableGridSlotsStore.timeGridSlots[timeGridSlotIndex];
    const slotAfter =
      timeGridSlotIndex < this.timetableGridSlotsStore.timeGridSlots.length - 1 &&
      this.timetableGridSlotsStore.timeGridSlots[timeGridSlotIndex + 1];

    if (!slotBefore || !slotAfter) {
      return 0;
    }
    const lengthOfBreak = Math.abs(slotBefore.endTime.diff(slotAfter.startTime, 'minutes'));
    const pixelsInBreak = Math.abs(slotBefore.endTime.diff(time, 'minutes'));

    if (lengthOfBreak == 0) {
      return 0;
    }
    return (pixelsInBreak / lengthOfBreak) * this.gridBreakHeight;
  }

  @action
  setTimetableGridDimension(timetableGridDimension: ITimeGridDimension) {
    this._timetableGridDimension = timetableGridDimension;
  }
}
