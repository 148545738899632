import React from 'react';
import clsx from 'clsx';

import { useLessonCardHiddenItemCount } from '@/components/lesson-card/hooks/use-lesson-card-hidden-item-count';
import HiddenItemCount from '@/components/lesson-card/components/hidden-item-count/hidden-item-count';

interface IProps {
  subjects: string[];
  isTopElement?: boolean;
}

const LessonCardSubject = ({ subjects, isTopElement }: IProps) => {
  const { ref, hiddenItemCount, hideInnerContent } = useLessonCardHiddenItemCount(isTopElement);

  return (
    <div className="lesson-card-subject-container" ref={ref}>
      <div
        className={clsx('lesson-card-subjects', { 'lesson-card-top-element': isTopElement, hide: hideInnerContent })}
        data-testid="lesson-card-subjects"
      >
        {subjects.map((subjectText, index) => {
          return (
            <React.Fragment key={index}>
              <span
                className={clsx('lesson-card-subject', { 'lesson-card-top-element': isTopElement })}
                data-testid="lesson-card-subject"
              >
                {subjectText}
              </span>
              {index !== subjects.length - 1 ? ', ' : null}
            </React.Fragment>
          );
        })}
      </div>
      <HiddenItemCount count={hiddenItemCount} isTopElement={isTopElement} />
    </div>
  );
};

export default LessonCardSubject;
