import { action, computed, observable } from 'mobx';
import { t } from 'i18next';

import { Store } from '@/types/store';
import {
  QuickFilterRefDto,
  QuickFilterViewDto,
  DynamicQuickFilterItemOptionsDto,
  DynamicQuickFilterViewDto,
  DefaultQuickFilterType,
} from '@untis/wu-rest-view-api';
import { MessageViewApi } from '@/stores/api-store';
import { ErrorMessageEnum, showError } from '@/utils/error-handling/error-message';
import { TestIds } from '@/testIds';

@Store()
export class QuickFiltersDataStore {
  @observable mode: 'create' | 'edit' = 'create';
  @observable type: 'static' | 'dynamic' | 'default' = 'static';
  @observable quickFilterId: number = -1; // id of the current (edited) quickfitler

  @observable canCreatePublic = false;

  @observable quickFilters: QuickFilterRefDto[] = [];
  @observable defaultQuickFilters: DefaultQuickFilterType[] = [];
  @observable selectedQuickFilter: QuickFilterViewDto | null = null;
  @observable dynamicQuickFilter: DynamicQuickFilterViewDto | null = null;

  addQuickFilter(quickFilter: QuickFilterRefDto) {
    this.quickFilters.push(quickFilter);
  }

  removeQuickFilterById(id: number) {
    this.quickFilters = this.quickFilters.filter((qf) => qf.id !== id);
  }

  async getDynamicQuickFilter() {
    try {
      const { data } = await MessageViewApi.getDynamicQuickFilter(this.quickFilterId);
      this.dynamicQuickFilter = data;

      return data;
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
    }
  }

  async getQuickFilter() {
    try {
      const { data } = await MessageViewApi.getQuickFilter(this.quickFilterId);
      this.selectedQuickFilter = data;
      return data;
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
    }
  }

  updateQuickFilter(id: number, quickFilter: Partial<Omit<QuickFilterRefDto, 'id'>>) {
    const index = this.quickFilters.findIndex((qf) => qf.id === id);

    if (index < 0) {
      return;
    }

    this.quickFilters[index] = {
      ...this.quickFilters[index],
      ...quickFilter,
    };
  }

  @action
  setQuickFilterProps({
    type,
    mode,
    quickFilterId,
  }: {
    type: 'static' | 'dynamic';
    mode: 'create' | 'edit';
    quickFilterId?: number;
  }) {
    this.type = type;
    this.mode = mode;
    this.quickFilterId = quickFilterId ?? -1;
  }

  @action
  setName(name: string) {
    if (this.isDynamicQuickFilter) {
      this.setDynamicQuickFilterName(name);
      return;
    }

    this.setStaticQuickFilterName(name);
  }

  @action
  setStaticQuickFilterName(name: string) {
    this.selectedQuickFilter = this.getSelectedQuickFilter();
    this.selectedQuickFilter.name = name;
  }

  @action
  setDynamicQuickFilterName(name: string) {
    this.dynamicQuickFilter = this.getSelectedDynamicQuickFilter();
    this.dynamicQuickFilter.name = name;
  }

  @action
  setIsPublicAccess(publicAccess: boolean) {
    if (this.isDynamicQuickFilter) {
      this.dynamicQuickFilter = this.getSelectedDynamicQuickFilter();
      this.dynamicQuickFilter.publicAccess = publicAccess;
      return;
    }
    this.selectedQuickFilter = this.getSelectedQuickFilter();
    this.selectedQuickFilter.publicAccess = publicAccess;
  }

  @action
  setSelectedUserIds(userIds: number[]) {
    this.selectedQuickFilter = this.getSelectedQuickFilter();
    this.selectedQuickFilter.userIds = userIds;
  }

  @action
  getSelectedQuickFilter(): QuickFilterViewDto {
    if (!this.selectedQuickFilter) {
      return {
        name: '',
        id: -1,
        userIds: [],
        publicAccess: false,
      };
    }

    return this.selectedQuickFilter;
  }

  @action
  getSelectedDynamicQuickFilter(): DynamicQuickFilterViewDto {
    if (!this.dynamicQuickFilter) {
      return {
        name: '',
        id: -1,
        items: [],
        publicAccess: false,
      };
    }

    return this.dynamicQuickFilter;
  }

  @action
  resetOverviewView() {
    this.canCreatePublic = false;
    this.quickFilters = [];
    this.selectedQuickFilter = null;
  }

  @action
  resetCreateEditView() {
    this.mode = 'create';
    this.type = 'static';
    this.quickFilterId = -1;
    this.selectedQuickFilter = null;
    this.dynamicQuickFilter = null;
  }

  @computed
  get isPublicAccess() {
    if (this.isDynamicQuickFilter) {
      return this.dynamicQuickFilter?.publicAccess ?? false;
    }

    return this.selectedQuickFilter?.publicAccess ?? false;
  }

  @computed
  get types() {
    const defaultTypes = [
      { label: t('general.quickFilterCustom'), value: 'static', testId: TestIds.MESSAGES_LIST_STATIC_TOGGLE },
      { label: t('general.quickFilterDynamic'), value: 'dynamic', testId: TestIds.MESSAGES_LIST_DYNAMIC_TOGGLE },
    ];

    // only ADMIN role should be able to restore default lists
    // we re-use the canCreatePublic right, which scopes the
    // permission to ADMIN role
    if (this.canCreatePublic) {
      defaultTypes.push({
        label: t('general.quickFilterDefault'),
        value: 'default',
        testId: TestIds.MESSAGES_LIST_DEFAULT_TOGGLE,
      });
    }

    return defaultTypes;
  }

  @computed
  get isEmpty() {
    return this.quickFilters.length < 1;
  }

  @computed
  get isStaticQuickFilter() {
    return this.type === 'static';
  }

  @computed
  get isDynamicQuickFilter() {
    return this.type === 'dynamic';
  }

  @computed
  get isStaticOrDynamicQuickFilter() {
    return this.isStaticQuickFilter || this.isDynamicQuickFilter;
  }

  @computed
  get isDefaultQuickfilter() {
    return this.type === 'default';
  }

  @computed
  get isCreateMode() {
    return this.mode === 'create';
  }

  @computed
  get isEditMode() {
    return this.mode === 'edit';
  }

  @computed
  get name() {
    if (this.isDynamicQuickFilter) {
      return this.dynamicQuickFilter?.name ?? '';
    }
    return this.selectedQuickFilter?.name ?? '';
  }

  @computed
  get dynamicFilterItemsDtos(): DynamicQuickFilterItemOptionsDto[] {
    return this.getSelectedDynamicQuickFilter().items.reduce(
      (selectedItems: DynamicQuickFilterItemOptionsDto[], item) => {
        const transformedItem: DynamicQuickFilterItemOptionsDto = {
          type: item.type,
          options: item.options?.filter((item) => item.selected) ?? [],
        };

        return [...selectedItems, transformedItem];
      },
      [],
    );
  }
}
