import React from 'react';
import clsx from 'clsx';

import './tag-select.less';
import OptionPopup, { Option } from '@/ui-components/option-popup/option-popup';

interface IProps {
  id: string;
  label: string;
  options?: Option[];
  disabled?: boolean;
  hasError?: boolean;
}

const TagSelect = (props: IProps) => {
  const { options, disabled, id, label, hasError } = props;
  const className = React.useMemo(
    () =>
      clsx('tag-select', {
        disabled: !!disabled,
        'has-options': options && options.length > 0,
        'has-error': !!hasError,
      }),
    [disabled, options, hasError],
  );

  return (
    <div className="tag-select-container">
      {options && options.length > 0 && !disabled ? (
        <OptionPopup options={options as Option[]} itemId={id}>
          <span className={className}>{label}</span>
        </OptionPopup>
      ) : (
        <span className={className}>{label}</span>
      )}
    </div>
  );
};

export default TagSelect;
