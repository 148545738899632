import Form from 'antd/lib/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import { DatePicker, IconButton } from '@/ui-components';

import './form-multi-date-range-picker.less';

interface IFormMultiDateRangePickerProps extends IFormItemProps<DateRange[]> {
  value?: DateRange[];
  onChange?: (dateRanges: DateRange[]) => void;
  removableRows?: boolean;
  removableDates?: boolean;
  startLabel: string;
  endLabel: string;
}

export type DateRange = {
  startDate?: Dayjs;
  endDate?: Dayjs;
};

export const FormMultiDateRangePicker = (props: IFormMultiDateRangePickerProps) => {
  const { t } = useTranslation();

  function disabledDate(date: Dayjs, prevDate: Dayjs | undefined, nextDate: Dayjs | undefined) {
    const truncatedDate = truncateDate(date);
    const truncatedPrevDate = truncateDate(prevDate);
    const truncatedNextDate = truncateDate(nextDate);
    if (truncatedPrevDate && truncatedNextDate) {
      return truncatedDate!.isSameOrBefore(truncatedPrevDate) || truncatedDate!.isSameOrAfter(truncatedNextDate);
    } else if (truncatedPrevDate) {
      return truncatedDate!.isSameOrBefore(truncatedPrevDate);
    } else if (truncatedNextDate) {
      return truncatedDate!.isSameOrAfter(truncatedNextDate);
    }

    return false;
  }

  function truncateDate(dateTime: Dayjs | undefined) {
    if (dateTime) {
      return dayjs(dateTime);
    }
    return undefined;
  }

  return (
    <div className="multi-date-range-picker-container">
      <Form.List name="dateRanges" initialValue={props.initialValue}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} className="multi-date-range-picker-row">
                <div className="multi-date-range-picker-start">
                  <Form.Item shouldUpdate>
                    {({ getFieldValue }) => {
                      let prevEndDate: Dayjs | undefined = undefined;
                      let nextEndDate: Dayjs | undefined = undefined;

                      if (name > 0) {
                        prevEndDate = getFieldValue(['dateRanges', name - 1, 'endDate']);
                      }
                      if (name < fields.length - 1) {
                        nextEndDate = getFieldValue(['dateRanges', name, 'endDate']);
                      }

                      return (
                        <Form.Item
                          {...restField}
                          name={[name, 'startDate']}
                          label={props.label}
                          className="form-multi-date-range-picker"
                          rules={props.rules}
                          dependencies={props.dependencies}
                        >
                          <DatePicker
                            value={undefined}
                            label={props.startLabel}
                            disabled={props.disabled}
                            removable={props.removableRows}
                            disabledDate={(date: Dayjs) => disabledDate(date, prevEndDate, nextEndDate)}
                            testId={props.testId}
                            useWrapperWidth
                            disabledDeleteButton={nextEndDate !== undefined && name !== 0}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </div>
                <div className="multi-date-range-picker-end">
                  <Form.Item shouldUpdate>
                    {({ getFieldValue }) => {
                      let prevStartDate: Dayjs | undefined = undefined;
                      let nextStartDate: Dayjs | undefined = undefined;

                      prevStartDate = getFieldValue(['dateRanges', name, 'startDate']);
                      if (name < fields.length - 1) {
                        nextStartDate = getFieldValue(['dateRanges', name + 1, 'startDate']);
                      }

                      return (
                        <Form.Item
                          {...restField}
                          name={[name, 'endDate']}
                          label={props.label}
                          className="form-multi-date-range-picker"
                          rules={props.rules}
                          dependencies={props.dependencies}
                        >
                          <DatePicker
                            value={undefined}
                            label={props.endLabel}
                            onChange={(date) => {
                              if (fields.length - 1 === name && date) {
                                add();
                              } else if (fields.length - 2 === name && !date) {
                                remove(name + 1);
                              }
                            }}
                            disabled={(prevStartDate === undefined || props.disabled) && name !== 0}
                            removable={props.removableRows}
                            disabledDate={(date: Dayjs) => disabledDate(date, prevStartDate, nextStartDate)}
                            testId={props.testId}
                            useWrapperWidth
                            disabledDeleteButton={nextStartDate !== undefined}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </div>
                {props.removableRows && (
                  <div key={'delete-button-wrapper' + key} className="multi-date-range-picker-delete-button">
                    <IconButton
                      type="cancel-circle"
                      ariaLabel={t('general.delete')}
                      disabled={props.disabled}
                      onClick={() => {
                        if (name < fields.length - 1) {
                          remove(name);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
};
