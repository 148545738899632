import React from 'react';
import clsx from 'clsx';

import { FilterChip } from '@/ui-components/filter-chip/filter-chip';
import { IFilter } from '@/ui-components/filter-bar/filter/filter';

import './toggle-filter.less';
import './filter.less';

export interface IToggleFilterProps extends IFilter<boolean> {
  label: string;
  withCancelIcon?: boolean;
  hideInactiveBooleanFilter?: boolean;
}

export const ToggleFilter = (props: IToggleFilterProps) => {
  const testId = props.testId ? `${props.testId}-toggle-filter` : undefined;

  const handleBooleanButtonClick = () => {
    if (props.value) {
      props.onChange(false);
    } else {
      props.onChange(true);
    }
  };

  if (!props.value && props.hideInactiveBooleanFilter) {
    return null;
  }

  if (props.withCancelIcon) {
    if (props.value) {
      return (
        <div className="new-filter">
          <FilterChip text={props.label} onCancel={() => handleBooleanButtonClick()} dataTestId={testId} />
        </div>
      );
    }
    return (
      <div className="new-filter">
        <FilterChip
          onClick={() => handleBooleanButtonClick()}
          text={props.label}
          appearance="light"
          dataTestId={testId}
        />
      </div>
    );
  }

  return (
    <div className="new-filter">
      <button
        className={clsx('toggle-filter', { ['toggle-filter--active']: props.value })}
        data-testid={testId}
        onClick={() => handleBooleanButtonClick()}
      >
        {props.label}
      </button>
    </div>
  );
};
