import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import { ListView } from '@/components/list-view/list-view';
import { AdministrationExamLockStore, IExamLockForm, IExamLockRow } from '@ad/exam-lock/administration-exam-lock-store';
import { ExamLocksViewApi } from '@/stores/api-store';

import './administration-exam-lock.less';

export const AdministrationExamLock = observer(() => {
  const [form] = Form.useForm<IExamLockForm>();
  const [examLockStore] = useState(() => new AdministrationExamLockStore());
  const { t } = useTranslation();

  useComponentDidMount(() => {
    examLockStore.setFormInstance(form);
  });

  return (
    <ListView<IExamLockRow>
      title={t('administration.examLock.title')}
      isPageLoading={examLockStore.isDataLoading}
      table={{
        columns: examLockStore.columns,
        rowData: examLockStore.rowData,
        rowSelection: {
          selectedRowKeys: examLockStore.selectedRowKeys,
          type: 'checkbox',
          onChange: (keys) => examLockStore.setSelectedRowKeys(keys),
          getCheckboxProps: (row: IExamLockRow) => ({
            disabled: !row.canDelete,
          }),
        },
        className: 'administration-exam-lock-table',
        shadow: true,
      }}
      actions={examLockStore.listViewActions}
      selectedRowsActions={examLockStore.selectedRowsActions}
      customSorting={
        examLockStore.canEdit
          ? {
              onSave: (order) => {
                ExamLocksViewApi.createCustomOrderForLocks(order).then(() => {
                  examLockStore.getExamLocks();
                });
              },
              onDelete: () => {
                ExamLocksViewApi.deleteCustomOrderForLocks().then(() => {
                  examLockStore.getExamLocks();
                });
              },
            }
          : undefined
      }
    />
  );
});
