import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import useStore from '@/hooks/useStore';
import { ExcuseStatusStore } from '@/pages/master-data/excuse-status/excuse-status-store';

export const ExcuseStatusEdit = observer(() => {
  const params = useParams<{ id: string }>();
  const store = useStore(ExcuseStatusStore);
  const isLoading = store.isDataLoading;
  const [redirect, setRedirect] = useState(false);

  useComponentDidMount(() => {
    store.setEditId(params.id);
  });

  useEffect(() => {
    async function openEditForm() {
      if (!isLoading) {
        await store.openEditForm();
        setRedirect(true);
      }
    }

    openEditForm();
  }, [store, isLoading]);

  if (redirect) {
    return <Redirect to="/excuse-status" />;
  }

  return null;
});
