import { action, computed, observable } from 'mobx';
import { FormInstance } from 'antd';
import uniqBy from 'lodash/uniqBy';
import { t } from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

import ModalStore from '@/stores/modal-store';
import { inject } from '@/types/store';
import WuItemPickerDialogStore from '@/components/wu-item-picker-dialog/wu-item-picker-dialog-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import {
  ExamDto,
  ExamFormDto,
  ExamInvigilatorDto,
  ExamSettingsDto,
  ExamStudentDto,
  ExamTypeWithGradingScaleDto,
  GenderEnum,
  MasterDataRefDto,
  RoomLocationDto,
} from '@untis/wu-rest-view-api/api';
import { AssignedTeachersRow } from '@/components/assigned-teachers/assigned-teachers';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { ISelectOptionListItem } from '@/ui-components/select-option-list/select-option-list';
import { IDeprecatedFilter, IFilterItem } from '@/ui-components/filter-bar/filter/deprecatedFilter';
import { formatDateTimeForServerRequest } from '@/utils/date/date-util';
import { DateTimeRange } from '@/ui-components/wu-form/form-date-time-range-picker/form-date-time-range-picker';
import { ExamSettingsViewApi } from '@/stores/api-store';
import { getResponseError, IResponseError, ResponseErrorType } from '@/utils/error-handling/error-handling';
import { IValidationError } from '@/types/validation-error';
import ConfigStore from '@/stores/config-store';
import { IExamForm } from '@ls/exams/exam-form/exam-form-store';
import { IExamWizardForm } from '@ls/exams/wizard/exam-wizard-store';
import { ISelectItem } from '@/ui-components/select/select';

export abstract class AbstractExamFormStore<ElementFormType> {
  protected configStore = inject(ConfigStore);
  protected modalStore: ModalStore = inject(ModalStore);
  protected wuItemPickerDialogStore: WuItemPickerDialogStore = inject(WuItemPickerDialogStore);
  protected notificationStore: NotificationStore = inject(NotificationStore);

  @observable protected _isLoading: boolean = true;
  @observable protected _isSubmitting: boolean = false;
  @observable protected _exam: ExamDto | undefined = undefined;
  @observable protected _formInstance: FormInstance<ElementFormType> | undefined;

  @observable protected _examTypes: ExamTypeWithGradingScaleDto[] = [];
  @observable protected _rooms: RoomLocationDto[] = [];
  @observable protected _students: ExamStudentDto[] = [];
  @observable protected _teachers: MasterDataRefDto[] = [];
  @observable protected _examSettings: ExamSettingsDto | undefined;
  @observable protected _assignedTeachers: AssignedTeachersRow[] = [];
  @observable protected _canSelectRooms: boolean = false;
  @observable protected _canAssignedSelectTeachers: boolean = false;
  @observable protected _hasGrades: boolean = false;
  @observable protected _studentConflicts: Map<string, string> = new Map<string, string>();

  @action
  protected async getExamSettings() {
    const examSettingsResponse = await ExamSettingsViewApi.getExamSettings();
    this._examSettings = examSettingsResponse.data;
  }

  @action
  protected setupFormData(formData: ExamFormDto) {
    this._exam = formData.exam;
    this._examTypes = [...(formData.examTypes || [])];
    this._rooms = [...(formData.rooms || [])];
    this._students = [...(formData.students || [])];
    this._teachers = [...(formData.invigilators || [])];
    this._assignedTeachers =
      this._exam?.invigilators.map((invigilator) => {
        const teachers: MasterDataRefDto[] | undefined = invigilator.teachers?.filter(
          (teacher) => teacher.displayName && teacher.displayName.length > 0,
        );
        return {
          id: dayjs(invigilator.start).unix(),
          selectedTime: dayjs(invigilator.start),
          selectedTeachers: teachers || [],
        };
      }) || [];
    this._canSelectRooms = formData.selectExaminationRoom || false;
    this._canAssignedSelectTeachers = formData.selectExaminationTeacher || false;
    this._hasGrades = formData.hasGrades || false;
  }

  @action.bound
  public updateModalHeader(examTypeId?: string) {
    const selectedExamTypeId: string = examTypeId ?? this._formInstance?.getFieldValue('examType');
    const selectedExamType = this._examTypes.find(
      (examType) => examType.id.toString() === selectedExamTypeId,
    )?.displayName;

    const examType = selectedExamType ?? this.exam?.examType?.displayName ?? '';
    const examName = this._formInstance?.getFieldValue('name') ?? this._exam?.examName ?? '';

    this.modalStore.setTitle(`${examType} ${examName}`);
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  @computed
  get form(): FormInstance<ElementFormType> | undefined {
    return this._formInstance;
  }

  @action
  setForm(formInstance: FormInstance<ElementFormType>) {
    this._formInstance = formInstance;
  }

  @computed
  get exam(): ExamDto | undefined {
    return this._exam;
  }

  @computed
  get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  @computed
  get examTypes(): ISelectItem[] {
    return this._examTypes.map((examType) => {
      return {
        id: examType.id.toString(),
        label: examType.displayName ?? '',
      };
    });
  }

  @computed
  get teachers(): MasterDataRefDto[] {
    return this._teachers;
  }

  @computed
  get rooms(): RoomLocationDto[] {
    return this._rooms;
  }

  @computed
  get students(): ExamStudentDto[] {
    return this._students;
  }

  @computed
  get assignedTeachers(): AssignedTeachersRow[] {
    return this._assignedTeachers;
  }

  @action
  setAssignedTeachers(assignedTeachers: AssignedTeachersRow[]) {
    this._assignedTeachers = assignedTeachers;
  }

  @computed
  get canSelectRooms(): boolean {
    return this._canSelectRooms;
  }

  @computed
  get canAssignedSelectTeachers(): boolean {
    return this._canAssignedSelectTeachers;
  }

  @action
  protected displayError(error: Error, title: string, form: FormInstance<IExamForm | IExamWizardForm> | undefined) {
    const responseError = getResponseError(error);
    const errors = this.getValidationErrors(responseError);
    let message = error.toString();
    if (errors.length <= 0) {
      this.notificationStore.error({ title, message });
      return;
    }
    const globalErrors = errors.filter((error) => error.path === 'exam');
    if (globalErrors.length > 0) {
      message = globalErrors[0].errorMessage;
    }
    const studentSpecificErrors = errors.filter((error) => error.path.includes('student'));
    if (studentSpecificErrors.length <= 0) {
      this.notificationStore.error({ title, message });
      return;
    }
    message = t('lessons.exams.messages.hasStudentConflict');
    const examTypeLimitExceedCollectiveError: IValidationError | undefined = errors.find(
      (error) => error.path === 'exam.students',
    );
    if (examTypeLimitExceedCollectiveError) {
      message = examTypeLimitExceedCollectiveError.errorMessage;
    }
    this.setStudentErrors(studentSpecificErrors, form);
    this.notificationStore.error({ title, message });
  }

  private getValidationErrors(responseError: IResponseError): IValidationError[] {
    let result: IValidationError[] = [];
    if (responseError.type === ResponseErrorType.VALIDATION_ERROR) {
      result = responseError.payload as IValidationError[];
    }
    return result;
  }

  @action
  private setStudentErrors(
    studentValidationErrors: IValidationError[],
    form: FormInstance<IExamForm | IExamWizardForm> | undefined,
  ) {
    const studentConflicts: Map<string, string> = new Map<string, string>();
    studentValidationErrors.forEach((studentValidationError) => {
      const studentIdMatch = studentValidationError.path.match('\\d+');
      if (studentIdMatch) {
        studentConflicts.set(studentIdMatch[0], studentValidationError.errorMessage);
      }
    });
    this._studentConflicts = studentConflicts;
    const students: IMultiTagSelectItem[] | undefined = form?.getFieldValue('students');
    const updatedStudents = students?.map((student) => {
      if (this._studentConflicts.has(student.id)) {
        return {
          ...student,
          hasError: true,
        };
      } else {
        return student;
      }
    });
    form?.setFieldsValue({ students: updatedStudents });
  }

  @action
  onUpdateDateTimeRange(dateTimeRange?: DateTimeRange) {
    if (dateTimeRange) {
      const { startDateTime, endDateTime } = dateTimeRange;
      if (this._assignedTeachers.length > 0) {
        this._assignedTeachers[0].selectedTime = startDateTime;
      }
      this._assignedTeachers = this._assignedTeachers.filter((a) => {
        return a.selectedTime.isSameOrAfter(startDateTime) && a.selectedTime.isSameOrBefore(endDateTime);
      });
    }
  }

  @action.bound
  disabledDate(date: Dayjs): boolean {
    let isBefore: boolean = false;
    let isAfter: boolean = false;

    const examSettingsStartDate = dayjs(this._examSettings?.start);
    const examSettingsEndDate = dayjs(this._examSettings?.end);

    if (examSettingsStartDate.isValid()) {
      isBefore = date.isSameOrBefore(examSettingsStartDate);
    }

    if (examSettingsEndDate.isValid()) {
      isAfter = date.isSameOrAfter(examSettingsEndDate);
    }

    return isBefore || isAfter;
  }

  @computed
  get addRoomsHandler(): () => Promise<IMultiTagSelectItem[]> {
    return () => {
      return new Promise<IMultiTagSelectItem[]>((resolve) => {
        const handleSubmit = (selectedRoomsIds: string[]) => {
          const selectedRoomDtos = this._rooms.filter((s) => selectedRoomsIds.includes(s.id.toString()));
          const selectedRooms = selectedRoomDtos.map(this.mapRoomDtoToMultiTagSelectItem);
          this.modalStore.closeModal();
          resolve(selectedRooms);
        };

        const roomOptions = this._rooms.map((room) => {
          const item: ISelectOptionListItem = {
            id: room.id.toString(),
            value: room.id.toString(),
            name: room.displayName,
            icon: '',
            tags: [],
          };

          if (room.building) {
            item.tags?.push({
              name: room.building.displayName ?? '',
            });
          }

          if (room.department) {
            item.tags?.push({
              name: room.department.displayName ?? '',
            });
          }

          return item;
        });

        const selectedRooms: string[] = this.form?.getFieldValue('rooms').map((s: IMultiTagSelectItem) => s.id);
        const buildings: IFilterItem[] = this._rooms
          .map((room) => room.building?.displayName)
          .filter((building) => building != null)
          .map((building) => {
            return {
              id: building!,
              label: building!,
            };
          });
        const department: IFilterItem[] = this._rooms
          .map((room) => room.department?.displayName)
          .filter((department) => department != null)
          .map((department) => {
            return {
              id: department!,
              label: department!,
            };
          });
        const filterTags: IDeprecatedFilter[] = [];
        const uniqueBuildings = uniqBy(buildings, (tag) => tag.id);
        const uniqueDepartments = uniqBy(department, (tag) => tag.id);
        if (uniqueBuildings.length > 0) {
          filterTags.push({
            id: 'building',
            placeholder: t('general.building'),
            items: uniqueBuildings,
            canUndoSelection: true,
          });
        }
        if (uniqueDepartments.length > 0) {
          filterTags.push({
            id: 'department',
            placeholder: t('general.department'),
            items: uniqueDepartments,
            canUndoSelection: true,
          });
        }

        this.wuItemPickerDialogStore.openItemPickerDialog(
          t('general.rooms'),
          roomOptions,
          selectedRooms,
          filterTags,
          handleSubmit,
        );
      });
    };
  }

  @computed
  get addStudentsHandler(): () => Promise<IMultiTagSelectItem[]> {
    return () => {
      return new Promise<IMultiTagSelectItem[]>((resolve) => {
        const handleSubmit = (selectedStudentIds: string[]) => {
          const selectedStudentDtos = this._students.filter((s) => selectedStudentIds.includes(s.id.toString()));
          const selectedStudents = selectedStudentDtos.map(this.mapStudentDtoToMultiTagSelectItem);
          this.modalStore.closeModal();
          resolve(selectedStudents);
        };

        const studentOptions = this._students.map((student) => {
          const item: ISelectOptionListItem = {
            id: student.id.toString(),
            value: student.id.toString(),
            name: student.displayName,
            avatarSrc: student.imageUrl,
            tags: [],
          };

          if (student.gender) {
            item.tags?.push({
              name: this.mapGender(student.gender),
              value: student.gender.toString(),
            });
          }

          if (student.klasse) {
            item.tags?.push({
              name: student.klasse.displayName ?? '',
            });
          }

          if (this._studentConflicts.has(student.id.toString())) {
            item.tags?.push({
              name: this._studentConflicts.get(student.id.toString()) || '',
              type: 'error',
            });
          }

          return item;
        });

        const selectedStudents: string[] = this.form?.getFieldValue('students').map((s: IMultiTagSelectItem) => s.id);
        const genders: IFilterItem[] = this._students
          .map((student) => student.gender)
          .filter((gender) => gender != null)
          .map((gender) => {
            return {
              id: gender!.toString(),
              label: this.mapGender(gender),
            };
          });
        const klassen: IFilterItem[] = this._students
          .map((student) => student.klasse?.displayName)
          .filter((klasse) => klasse != null)
          .map((klasse) => {
            return {
              id: klasse!,
              label: klasse!,
            };
          });
        const filterTags: IDeprecatedFilter[] = [];
        const uniqueGenders = uniqBy(genders, (tag) => tag.id);
        const uniqueKlassen = uniqBy(klassen, (tag) => tag.id);
        if (uniqueGenders.length > 0) {
          filterTags.push({
            id: 'gender',
            placeholder: t('general.gender'),
            items: uniqueGenders,
            canUndoSelection: true,
          });
        }
        if (uniqueKlassen.length > 0) {
          filterTags.push({
            id: 'klasse',
            placeholder: t('general.class'),
            items: uniqueKlassen,
            canUndoSelection: true,
          });
        }

        this.wuItemPickerDialogStore.openItemPickerDialog(
          t('general.students'),
          studentOptions,
          selectedStudents,
          filterTags,
          handleSubmit,
        );
      });
    };
  }

  @action.bound
  private mapRoomDtoToMultiTagSelectItem(roomDto: ExamStudentDto): IMultiTagSelectItem {
    return {
      id: roomDto.id.toString(),
      label: roomDto.displayName,
    };
  }

  @action.bound
  private mapStudentDtoToMultiTagSelectItem(studentDto: ExamStudentDto): IMultiTagSelectItem {
    return {
      id: studentDto.id.toString(),
      label: studentDto.displayName,
    };
  }

  private mapGender(gender?: GenderEnum): string {
    if (gender === GenderEnum.MALE) {
      return t('general.male');
    } else if (gender === GenderEnum.FEMALE) {
      return t('general.female');
    } else {
      return t('general.unknown');
    }
  }

  @action.bound
  protected mapToMasterDataRefDto(item: IMultiTagSelectItem): MasterDataRefDto {
    return {
      id: parseInt(item.id),
      displayName: item.label,
      longName: item.label,
      shortName: item.label,
    };
  }

  @action.bound
  protected mapToExamStudentDto(item: IMultiTagSelectItem): ExamStudentDto {
    return {
      id: parseInt(item.id),
      displayName: item.label,
      longName: item.label,
      shortName: item.label,
    };
  }

  @action.bound
  protected calculateInvigilators(
    dateTimeRange: DateTimeRange,
    assignedTeachers: AssignedTeachersRow[],
  ): ExamInvigilatorDto[] {
    const result: ExamInvigilatorDto[] = [];
    assignedTeachers.forEach((value, index, array) => {
      let end: string = '';
      if (index === array.length - 1) {
        end = formatDateTimeForServerRequest(dateTimeRange.endDateTime);
      } else {
        end = formatDateTimeForServerRequest(array[index + 1].selectedTime);
      }
      const invigilator: ExamInvigilatorDto = {
        start: formatDateTimeForServerRequest(value.selectedTime),
        end: end,
        teachers: value.selectedTeachers,
      };
      result.push(invigilator);
    });
    return result;
  }
}
