export enum TestIds {
  BUILDING_LIST = 'building-list',
  BUILDING_SAVE_AND_NEW = 'building-save-and-new',
  BUILDING_NEW = 'building-new',
  BUILDING_REPORT = 'building-report',
  BUILDING_LONG_NAME = 'building-long-name',
  BUILDING_SHORT_NAME = 'building-short-name',
  BUILDING_ADD_ROOMS = 'building-add-rooms',
  OPEN_PERIODS_REPORT = 'open-periods-report',
  OPEN_PERIODS_SEND_MESSAGE = 'open-periods-send-message',
  STUDENT_LIST = 'student-list',
  STUDENT_NEW = 'student-new',
  STUDENT_REPORT = 'student-report',
  STUDENT_IMAGES = 'student-images',
  STUDENT_IMPORT = 'student-import',
  STUDENT_LAST_NAME = 'student-last-name',
  STUDENT_FIRST_NAME = 'student-first-name',
  STUDENT_SHORT_NAME = 'student-short-name',
  STUDENT_EXTERN_KEY = 'student-short-name',
  STUDENT_BIRTH_DATE = 'student-birth-date',
  STUDENT_ACTIVE_FLAG = 'student-active-flag',
  STUDENT_ADDRESS_STREET = 'student-address-street',
  STUDENT_ADDRESS_CITY = 'student-address-city',
  STUDENT_ADDRESS_POST_CODE = 'student-address-post-code',
  STUDENT_ADDRESS_MOBILE = 'student-address-mobile',
  STUDENT_ADDRESS_PHONE = 'student-address-phone',
  STUDENT_ADDRESS_EMAIL = 'student-address-email',
  STUDENT_ADDRESS_FAX = 'student-address-fax',
  STUDENT_GENDER = 'student-gender',
  STUDENT_CATALOGUE = 'student-catalogue',
  STUDENT_FRONT_COLOR = 'student-front-color',
  STUDENT_BACK_COLOR = 'student-back-color',
  STUDENT_MEDICAL_CERTIFICATE = 'student-medical-certificate',
  STUDENT_MEDICAL_CERTIFICATE_DATE = 'student-medical-certificate-date',
  STUDENT_DESCRIPTION = 'student-description',
  STUDENT_CLASS = 'student-class',
  STUDENT_NEW_CLASS = 'student-new-class',
  STUDENT_NEW_CLASS_DATE = 'student-new-class-date',
  STUDENT_CHANGE_CLASS = 'student-change-class',
  STUDENT_SHOW_LESSONS = 'student-show-lessons',
  STUDENT_LEGAL_AGE = 'student-legal-age',
  STUDENT_ZIP_FILE_IDENTIFICATION_METHOD = 'student-zip-file-identification-method',
  EXAMS_LIST = 'exams-list',
  CALENDAR_ENTRY_DETAILS_MENU = 'calendar-entry-details-menu',
  CLASS_REGISTER_OVERVIEW = 'class-register-overview',
  MESSAGES_OF_DAY_LIST = 'messages-of-day-list',
  MESSAGES_OF_DAY_NEW = 'messages-of-day-new-button',
  MESSAGES_OF_DAY_EDIT = 'messages-of-day-edit-button',
  MESSAGES_OF_DAY_DELETE = 'messages-of-day-delete-button',
  MESSAGES_OF_DAY_FORM_SAVE_BUTTON = 'messages-of-day-form-save-button',
  MESSAGES_OF_DAY_FORM_CANCEL_BUTTON = 'messages-of-day-form-cancel-button',
  MESSAGES_OF_DAY_FORM_SAVE_AND_NEW_BUTTON = 'messages-of-day-form-save-and-new-button',
  MESSAGES_OF_DAY_FORM_TITLE_INPUT = 'messages-of-day-form-title-input',
  MESSAGES_OF_DAY_ICON_PICKER = 'messages-of-day-form-icon-picker',
  MESSAGES_OF_DAY_FORM_SUBTITLE_INPUT = 'messages-of-day-form-subtitle-input',
  MESSAGES_OF_DAY_FORM_CONTENT_INPUT = 'messages-of-day-form-content-input',
  MESSAGES_OF_DAY_FORM_ATTACHMENTS = 'messages-of-day-form-attachments',
  MESSAGES_OF_DAY_FORM_STARTDATE = 'messages-of-day-form-startdate',
  MESSAGES_OF_DAY_FORM_ENDDATE = 'messages-of-day-form-enddate',
  MESSAGES_OF_DAY_FORM_DAY_OF_WEEK_DROPDOWN = 'messages-of-day-form-day-of-week-dropdown',
  MESSAGES_OF_DAY_FORM_PUBLIC_TOGGLE = 'messages-of-day-form-public-toggle',
  MESSAGES_OF_DAY_FORM_POSITION_INPUT = 'messages-of-day-form-position-input',
  MESSAGES_OF_DAY_FORM_DISPLAY_AREA_MULTI_SELECT_BUTTON = 'messages-of-day-form-display-area-multi-select-button',
  MESSAGES_OF_DAY_FORM_USER_GROUP_MULTI_SELECT_BUTTON = 'messages-of-day-form-user-group-multi-select-button',
  MESSAGES_OF_DAY_FORM_DEPARTMENT_MULTI_SELECT_BUTTON = 'messages-of-day-form-department-multi-select-button',
  MESSAGES_SEARCH_INPUT = 'messages-search-input',
  MESSAGES_NEW_BUTTON = 'messages-new-button',
  MESSAGES_DELETE_ALL_BUTTON = 'messages-delete-all-button',
  MESSAGES_SUBJECT_INPUT = 'messages-subject-input',
  MESSAGES_CONTENT_INPUT = 'messages-content-input',
  MESSAGES_ADD_RECIPIENT_BUTTON = 'messages-add-recipient-button',
  MESSAGES_SAVE_BUTTON = 'messages-save-button',
  MESSAGES_CANCEL_BUTTON = 'messages-cancel-button',
  MESSAGES_SEND_BUTTON = 'messages-send-button',
  MESSAGES_REQUEST_READ_RECEIPT_BUTTON = 'messages-request-read-receipt-button',
  MESSAGES_FORBID_REPLY_BUTTON = 'messages-forbid-reply-button',
  MESSAGES_COPY_TO_STUDENT_BUTTON = 'messages-copy-to-student-button',
  MESSAGES_ATTACHMENT_BUTTON = 'messages-attachment-button',
  MESSAGES_DELETE_BUTTON = 'messages-delete-button',
  MESSAGES_RECIPIENT_PICKER_SEARCH_INPUT = 'messages-recipient-picker-search-input',
  MESSAGES_RECIPIENT_PICKER_CANCEL_BUTTON = 'messages-recipient-picker-cancel-button',
  MESSAGES_RECIPIENT_PICKER_APPLY_BUTTON = 'messages-recipient-picker-apply-button',
  MESSAGES_PRINT_BUTTON = 'messages-print-button',
  MESSAGES_REPLY_BUTTON = 'messages-reply-button',
  MESSAGES_REVOKE_BUTTON = 'messages-revoke-button',
  MESSAGES_LIST_CREATE_BUTTON = 'messages-list-create-button',
  MESSAGES_LIST_NAME_INPUT = 'messages-list-name-input',
  MESSAGES_LIST_STATIC_TOGGLE = 'messages-list-static-input',
  MESSAGES_LIST_DYNAMIC_TOGGLE = 'messages-list-dynamic-input',
  MESSAGES_LIST_DEFAULT_TOGGLE = 'messages-list-default-input',
  MESSAGES_LIST_NEXT_BUTTON = 'messages-list-next-button',
  MESSAGES_LIST_RECIPIENT_PICKER_PUBLIC_BUTTON = 'messages-list-recipient-picker-public-button',
  MESSAGES_LIST_RECIPIENT_PICKER_CANCEL_BUTTON = 'messages-list-recipient-picker-cancel-button',
  MESSAGES_LIST_RECIPIENT_PICKER_CREATE_BUTTON = 'messages-list-recipient-picker-create-button',
  MESSAGES_LIST_RECIPIENT_PICKER_RESTORE_BUTTON = 'messages-list-recipient-picker-restore-button',
  MASTER_DATA_NEW = 'master_data_new_button',
  MASTER_DATA_REPORT = 'master_data_report_button',
  MASTER_DATA_DELETE = 'master_data_delete_button',
  MASTER_DATA_IMPORT = 'master_data_import_button',
  MASTER_DATA_SAVE = 'master_data_save_button',
  MASTER_DATA_OK = 'master_data_ok_button',
  MASTER_DATA_CANCEL = 'master_data_cancel_button',
  MASTER_DATA_SAVE_AND_NEW = 'master_data_save_and_new_button',
  PLATFORM_APPLICATION_CALENDAR_INTEGRATION_SECTION = 'platform-application-calendar-integration-section',
  PLATFORM_APPLICATION_SHORT_DISCLAIMER_SECTION = 'detail-info-dialog-disclaimer-short',
  PLATFORM_APPLICATION_SERVER_TO_SERVER_DISCLAIMER_SECTION = 'detail-info-dialog-disclaimer-s2sEnabledPart',
  PLATFORM_APPLICATION_DETAIL_INFO_DIALOG_SECTION = 'platform-detail-info-section',
  PLATFORM_APPLICATION_DATA_INTEGRATION_SECTION = 'platform-application-data-integration-section',
  PLATFORM_APPLICATION_CONFIGURATION_MENU_TAB = 'platform-application-configuration-menu-tab',
  PLATFORM_APPLICATION_CONFIGURATION_DETAILS_TAB = 'platform-application-configuration-details-tab',
  PLATFORM_APPLICATION_CONFIGURATION_EXAM_TAB = 'platform-application-configuration-exam-tab',
  GLOBAL_SCHOOL_YEAR_SELECTION_SECTION = 'global-school-year-selection-section',
  TIMETABLE_DAY_WEEK_TOGGLE = 'timetable-day-week-toggle',
  TIMETABLE_LEGEND_FILTER = 'timetable-legend-filter',
  TIMETABLE_SETTING_POPOVER = 'timetable-settings-popover',
  TIMETABLE_FEEDBACK_BUTTON = 'timetable-feedback-button',
  TIMETABLE_HELP_CENTER_BUTTON = 'timetable-help-center-button',
  TIMETABLE_ITEM_SELECTOR = 'timetable-item-selector',
  TIMETABLE_FORMAT_SETTINGS_LIST = 'timetable-format-settings-list',
  I_CAL_FORMAT_SETTINGS_LIST = 'i-cal-format-settings-list',
  TIMETABLE_GENERAL_SETTINGS = 'timetable-general-settings',
}
