import React, { FocusEvent, FormEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Icon, OptionPopup, Stack } from '@/ui-components';
import { useOutsideClick } from '@/hooks/useClickOutside';
import { IDeprecatedFilterBarProps, DeprecatedFilterBar } from '@/ui-components/filter-bar/deprecated-filter-bar';
import './deprecated-search-bar.less';

export interface IDeprecatedSearchBarOption {
  id: string;
  label: string;
  category?: string;
  type?: 'all' | 'class' | 'teacher';
}

// There might be more in the future
export enum DeprecatedSearchBarRestriction {
  ONLY_ONE_OPTION_PER_GROUP,
}

export interface IDeprecatedSearchBarProps {
  value: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  className?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onClear?: () => void;
  options?: IDeprecatedSearchBarOption[];
  onChangeSelectOptions?: (options: IDeprecatedSearchBarOption[]) => void;
  selectedOptions?: IDeprecatedSearchBarOption[];
  restriction?: DeprecatedSearchBarRestriction;
  maxResults?: number; // if more results -> display a message
  zIndex?: number;
  color?: 'default' | 'white';
  keepInputValue?: boolean;
  optionAllLabel?: string; // if not provided, then explicit option "all" is not shown
  showCategoryFilter?: boolean;
  // displays a string at the same position as the categoryFilter would be rendered. replaces categoryFilter if set.
  searchDescription?: string;
  // booleanFilter?: {
  //   value: Map<string, boolean>;
  //   onChange: (id: string, value: boolean) => void;
  // };
  filterProps?: IDeprecatedFilterBarProps;
}

export const DeprecatedSearchBar = (props: IDeprecatedSearchBarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>();
  const { t } = useTranslation();

  useEffect(() => {
    setIsOpen(!!props.options && props.options.length > 0 && props.value.length > 0);
  }, [props.value, props.options]);

  const ref = useOutsideClick(() => {
    if (isOpen && props.keepInputValue !== true) {
      handleClear();
    }
    setIsOpen(false);
  });

  const className = clsx('deprecated-search-bar', props.className, {
    'is-open': isOpen,
    white: props.color === 'white',
  });

  const extendedOptions: IDeprecatedSearchBarOption[] = useMemo(() => {
    const topOptions: IDeprecatedSearchBarOption[] = [];
    if (props.optionAllLabel) {
      topOptions.push({ id: '-1', label: props.optionAllLabel, type: 'all' });
    }
    return [...topOptions, ...(props.options ? props.options : [])];
  }, [props.options, props.optionAllLabel]);

  const categories: (string | undefined)[] = useMemo(() => {
    const categoriesSet: Set<string | undefined> = new Set<string | undefined>();
    extendedOptions.forEach((option) => {
      categoriesSet.add(option.category);
    });
    return Array.from(categoriesSet.values());
  }, [extendedOptions]);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    props.onChange && props.onChange(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (
      e.key === 'Backspace' &&
      props.value.length === 0 &&
      props.selectedOptions &&
      props.selectedOptions.length > 0
    ) {
      onUnselectOption(props.selectedOptions[props.selectedOptions.length - 1]);
    }
    if (e.key === 'Escape' && isOpen) {
      if (props.keepInputValue !== true) {
        handleClear();
      }
      if (props.keepInputValue) {
        setIsOpen(false);
      }
    }
  };

  const handleClear = () => {
    props.onChange && props.onChange('');
    props.onClear && props.onClear();
  };

  let searchResult: IDeprecatedSearchBarOption[] = [];

  searchResult = extendedOptions
    .filter((option) => option.label.toLowerCase().includes(props.value.toLowerCase()))
    .sort((a, b) => {
      const indexA = a.label.toLowerCase().indexOf(props.value.toLowerCase());
      const indexB = b.label.toLowerCase().indexOf(props.value.toLowerCase());

      if (indexA === indexB) {
        return 0;
      }
      return indexA - indexB;
    });

  if (selectedCategory) {
    searchResult = searchResult.filter((option) => option.category === selectedCategory);
  }

  const onSelectOption = (option: IDeprecatedSearchBarOption) => {
    const selectedOptions = props.selectedOptions ? props.selectedOptions : [];
    if (!props.selectedOptions?.find((o) => o.id === option.id && o.category === option.category)) {
      const newOptions =
        option.type === 'all'
          ? [option]
          : props.restriction === DeprecatedSearchBarRestriction.ONLY_ONE_OPTION_PER_GROUP
          ? [...selectedOptions.filter((o) => o.category !== option.category && o.type !== 'all'), option]
          : [...selectedOptions.filter((o) => o.type !== 'all'), option];

      props.onChangeSelectOptions && props.onChangeSelectOptions(newOptions);
    }
    handleClear();
    setIsOpen(false);
  };

  const onUnselectOption = (option: IDeprecatedSearchBarOption) => {
    const selectedOptions = props.selectedOptions ? props.selectedOptions : [];
    props.onChangeSelectOptions &&
      props.onChangeSelectOptions(selectedOptions.filter((o) => option.id !== o.id || option.category !== o.category));
  };

  const renderNoResult = () => {
    return <div className="no-result">{t('general.noSearchResults')}</div>;
  };

  const renderSearchResult = () => {
    if (props.maxResults && searchResult.length > props.maxResults) {
      return <div className="no-result">{t('general.pleaseBeMoreSpecific')}</div>;
    }

    const categories = new Set<string | undefined>();
    searchResult.map((result) => categories.add(result.category));

    const groupedOptions = new Map<string | undefined, IDeprecatedSearchBarOption[]>();
    categories.forEach((group) => {
      const optionsOfGroup = searchResult.filter((option) => option.category === group);
      groupedOptions.set(group, optionsOfGroup);
    });

    const renderedOptions: ReactElement[] = [];

    groupedOptions.forEach((options, group) => {
      if (group !== undefined) {
        renderedOptions.push(
          <span key={group} className="option-group">
            {group}
          </span>,
        );
      }

      options.forEach((option) => {
        renderedOptions.push(
          <span onClick={() => onSelectOption(option)} key={`${group}-${option.id}`} className="option">
            {option.label}
          </span>,
        );
      });
    });

    return <div className="results">{renderedOptions}</div>;
  };

  const filteredCategories: string[] = categories.filter((c) => c !== undefined) as string[];

  const onInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (selectedCategory !== undefined || props.filterProps) {
      setIsOpen(true);
    }
    props.onFocus && props.onFocus(e);
  };

  return (
    <div className={className} ref={ref} style={{ zIndex: props.zIndex }}>
      <div className="input-container">
        {(!props.selectedOptions || props.selectedOptions.length === 0) && <Icon type="search" />}
        {props.selectedOptions && props.selectedOptions.length > 0 && (
          <Stack x="sm" className="selected-options">
            {props.selectedOptions.map((option) => {
              return (
                <span className="selected-option" key={`${option.category}-${option.id}`}>
                  {option.label}
                  <Icon onClick={() => onUnselectOption(option)} type="cancel-small" />
                </span>
              );
            })}
          </Stack>
        )}
        <input
          data-testid="search-bar-input"
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleChange}
          onFocus={onInputFocus}
          onBlur={props.onBlur}
          disabled={props.disabled}
          onKeyDown={handleKeyDown}
        />
        {props.value.length > 0 && (
          <button className="button-cancel" type="button" onClick={handleClear} data-testid="clear">
            <Icon type="cancel-circle-filled" />
          </button>
        )}
        {props.searchDescription && (
          <>
            <div className="separator" />
            <div className="search-description">{props.searchDescription}</div>
          </>
        )}
        {props.showCategoryFilter && !props.searchDescription && (
          <>
            <div className="separator" />
            <CategoryFilter
              selectedCategory={selectedCategory}
              categories={filteredCategories}
              setIsOpen={setIsOpen}
              onSelectCategory={(category) => setSelectedCategory(category)}
            />
          </>
        )}
      </div>
      {isOpen && (props.filterProps || selectedCategory !== undefined || props.value.length) && (
        <div className="drop-down-container">
          {props.filterProps?.filter && props.filterProps.filter.length > 0 && (
            <div className="boolean-filter">
              <DeprecatedFilterBar {...props.filterProps} />
            </div>
          )}
          {searchResult.length > 0 ? renderSearchResult() : extendedOptions.length > 0 ? renderNoResult() : null}
        </div>
      )}
    </div>
  );
};

interface ICategoryFilterProps {
  selectedCategory?: string;
  categories: string[];
  onSelectCategory: (category: string | undefined) => void;
  setIsOpen: (isOpen: boolean) => void;
}

const CategoryFilter = (props: ICategoryFilterProps) => {
  const { t } = useTranslation();

  if (props.categories.length === 0) {
    return null;
  }

  const options = [
    {
      label: t('general.all'),
      onClick: () => props.onSelectCategory(undefined),
    },
    ...props.categories.map((category) => {
      return {
        label: category,
        onClick: () => props.onSelectCategory(category),
      };
    }),
  ];

  return (
    <div className="category-filter">
      <OptionPopup options={options} placement="bottomRight">
        <div className="click-container" onClick={() => props.setIsOpen(false)}>
          {props.selectedCategory ? props.selectedCategory : t('general.all')}
          <Icon type="dropdown" />
        </div>
      </OptionPopup>
    </div>
  );
};

export default DeprecatedSearchBar;
