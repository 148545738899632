import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IReasonsOfAbsenceRows, ReasonsOfAbsenceStore } from './reasons-of-absence-store';

import { ListView } from '@/components/list-view/list-view';
import useStore from '@/hooks/useStore';

export const MasterDataReasonsOfAbsenceList = observer(() => {
  const store = useStore(ReasonsOfAbsenceStore);
  const { t } = useTranslation();

  return (
    <ListView<IReasonsOfAbsenceRows>
      title={t('general.reasonsOfAbsence')}
      table={{
        shadow: true,
        columns: store.columns,
        rowData: store.reasonsOfAbsenceRows,
        rowSelection: {
          selectedRowKeys: store.selectedRowKeys,
          type: 'checkbox',
          onChange: (keys) => store.setSelectedRowKeys(keys),
        },
      }}
      selectedRowsActions={store.selectedRowsActions}
      searchBar={{
        selectedFreeTextOptions: store.selectedFreeTextOptions,
        onChangeFreeTextOptions: (values) => store.setSelectedFreeTextSearchOptions(values),
        placeholder: t('general.search'),
        searchDescription: t('general.name'),
      }}
      actions={store.listViewActions}
      customSorting={{
        onSave: (order) => {
          store.saveSorting(order);
        },
        onDelete: () => {
          store.resetSorting();
        },
      }}
      isPageLoading={store.isMetaLoading}
      compact
    />
  );
});
