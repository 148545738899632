export const PlatformApplicationConfigurationTranslation = {
  defaultTabHint: 'platform.details.configurations.defaultTabHint',
  menuName: 'platform.details.configurations.menuName',
  timetableDetailName: 'platform.details.configurations.timetableDetailName',
  namePlaceholder: 'platform.details.configurations.namePlaceholder',
  availableAt: 'platform.details.configurations.availableAt',
  openInNewTab: 'platform.details.configurations.openInNewTab',
  addConfiguration: 'platform.details.configurations.addConfiguration',
  menuConfigurations: 'platform.details.configurations.menuConfigurations',
  menuConfigurationsDescription: 'platform.details.configurations.menuConfigurationsDescription',
  timetableDetailConfigurations: 'platform.details.configurations.timetableDetailConfigurations',
  timetableDetailConfigurationsDescription: 'platform.details.configurations.timetableDetailConfigurationsDescription',
  noConfigurations: 'platform.details.configurations.noConfigurations',
  examConfigurationTabsTitle: 'platform.details.configurations.examConfigurationTabsTitle',
  examConfigurationTabsDescription: 'platform.details.configurations.examConfigurationTabsDescription',
  examName: 'platform.details.configurations.examName',
  userRoles: 'platform.userRoles',
};
