import { Checkbox, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PlatformDetailInfoServerToServerDisclaimer from './platform-detail-info-server-to-server-disclaimer';
import PlatformDetailInfoShortDisclaimer from './platform-detail-info-short-disclaimer';

import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import './platform-detail-info-dialog.less';
import { IFormButton, WUForm } from '@/ui-components/wu-form';
import { TestIds } from '@/testIds';

interface IProps {
  isFirstSave: boolean;
  isResetPassword?: boolean;
  isServerToServerCommunicationEnabled: boolean;
  password?: string;
  onOk?: (headmasterEmail?: string) => void;
}

interface IForm {
  headmasterEmail: string;
}

const PlatformDetailInfoDialog = observer((props: IProps) => {
  const { t } = useTranslation();
  const { closeModal } = useStore(ModalStore);
  const isPasswordAvailable = !!props.password;
  const [form] = Form.useForm<IForm>();
  const [passwordCopyConfirmPending, setPasswordCopyConfirmPending] = useState(isPasswordAvailable);
  const [disclaimerConfirmationPending, setDisclaimerConfirmationPending] = useState(props.isFirstSave);

  const onSubmit = () => {
    if (!isSubmitButtonDisabled) {
      const headmasterEmail: string = form.getFieldValue('headmasterEmail');
      props.onOk?.(headmasterEmail);
      closeModal();
    }
  };

  const renderDisclaimer = () => {
    return (
      <div className="disclaimer" data-testid="detail-info-dialog-disclaimer">
        <h2>{t('platform.accessDetails.dataProtectionTitle')}</h2>
        {props.isServerToServerCommunicationEnabled ? (
          <PlatformDetailInfoServerToServerDisclaimer />
        ) : (
          <PlatformDetailInfoShortDisclaimer />
        )}
        <div className="disclaimer-confirmation-check">
          <label>{t('platform.accessDetails.dataProtectionNoticeConfirmation')}</label>
          <Checkbox
            checked={!disclaimerConfirmationPending}
            onChange={(e) => setDisclaimerConfirmationPending(!e.target.checked)}
            data-testid="disclaimer-checkbox"
          />
        </div>
      </div>
    );
  };

  const renderPassword = () => {
    return (
      <div className="reset-password">
        <h2>{t('general.dataInterfacePassword')}</h2>
        <label className="password-label">{t('general.password')}:</label>
        <span className="info-value">{props.password}</span>
        <div className="password-copy-check" data-testid="detail-info-dialog-password-copy-hint">
          <div className="password-hint">{t('platform.accessDetails.savePasswordHint')}</div>
          <label>{t('platform.accessDetails.passwordSaved')}</label>
          <Checkbox
            checked={!passwordCopyConfirmPending}
            onChange={(e) => setPasswordCopyConfirmPending(!e.target.checked)}
            data-testid="password-copied-checkbox"
          />
        </div>
      </div>
    );
  };

  const isSubmitButtonDisabled = useMemo(() => {
    return passwordCopyConfirmPending || disclaimerConfirmationPending;
  }, [disclaimerConfirmationPending, passwordCopyConfirmPending]);

  const leftButtons: IFormButton[] = [
    {
      label: t('general.cancel'),
      type: 'secondary',
      disabled: false,
      outline: true,
      testId: 'cancel-button',
      onClick: () => {
        closeModal();
      },
    },
  ];

  const isSubmitButtonEnabled = props.isResetPassword ? true : !props.isServerToServerCommunicationEnabled;

  return (
    <div className="platform-detail-info" data-testid={TestIds.PLATFORM_APPLICATION_DETAIL_INFO_DIALOG_SECTION}>
      <WUForm
        form={form}
        enableSubmitButton={isSubmitButtonEnabled}
        onSubmit={onSubmit}
        testIdSaveButton="detail-info-dialog-ok-button"
        submitButtonLabel={t`general.ok`}
        leftButtons={props.isFirstSave ? leftButtons : []}
        className="platform-detail-info-form"
        disableSubmitButton={isSubmitButtonDisabled}
      >
        <div className="platform-detail-info-content">
          {props.isFirstSave && renderDisclaimer()}
          {isPasswordAvailable && renderPassword()}
        </div>
      </WUForm>
    </div>
  );
});

export default PlatformDetailInfoDialog;
