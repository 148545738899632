import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { WUForm, WUTable } from '@/ui-components';
import { ChangeClassStore } from '@/pages/master-data/student/class/change-class-store';
import { FormDatePicker, FormSection, WUFormCol, WUFormRow } from '@/ui-components/wu-form';
import { TestIds } from '@/testIds';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { ClassInfoDto, SchoolYearClassInfoDto } from '@untis/wu-rest-view-api/api';
import { formatDate } from '@/utils/date/date-util';
import { ISelectItem } from '@/ui-components/select/select';

export interface IChangeClassForm {
  classId: string;
  startDate: Dayjs;
}

export interface IClassHistoryRow extends ITableRowKey {
  className: string;
  startDate?: Dayjs;
  endDate?: Dayjs;
}

export interface IStudentGroupRow extends ITableRowKey {
  studentGroupName: string;
  classes: string;
  subject: string;
}

export interface IChangeClassFormProps {
  schoolYearClassInfo: SchoolYearClassInfoDto[];
  studentId: number;
  onStudentClassUpdated?: (studentId: number, classInfo: ClassInfoDto) => void;
}

export const ChangeClassForm = observer((props: IChangeClassFormProps) => {
  const { t } = useTranslation();
  const modalStore = useStore(ModalStore);

  const [selectableClasses, setSelectableClasses] = useState<ClassInfoDto[]>([]);

  const [form] = Form.useForm<IChangeClassForm>();
  const [store] = useState(() => new ChangeClassStore(form, props.studentId));

  const mapToFormSelect = (classInfo: ClassInfoDto[]): ISelectItem[] => {
    const items: ISelectItem[] = [];
    classInfo?.forEach((availableClass) => {
      items.push({
        id: String(availableClass.id),
        label: availableClass.name,
      });
    });

    return items;
  };

  function getSchoolYear(date: Dayjs): SchoolYearClassInfoDto | undefined {
    return props.schoolYearClassInfo.find((schoolYearInfo) => {
      return truncateDate(date).isBetween(
        dayjs(schoolYearInfo.schoolYear.dateRange?.start),
        dayjs(schoolYearInfo.schoolYear.dateRange?.end),
        undefined,
        '[]',
      );
    });
  }

  function truncateDate(dateTime: Dayjs) {
    return dayjs(dateTime.format('YYYYMMDD'), 'YYYYMMDD');
  }

  return (
    <WUForm<IChangeClassForm>
      form={store.form}
      rightButtons={store.buttonsOnRightSide}
      fixedButtonBar={true}
      onSubmit={(form) => {
        store.changeStudentClass(form, () => {
          const foundSchoolYear = getSchoolYear(form.startDate);
          if (foundSchoolYear?.isCurrentSchoolYear) {
            const selectedClass = selectableClasses.find((cl) => cl.id === Number(form.classId));
            props.onStudentClassUpdated &&
              props.onStudentClassUpdated(props.studentId, {
                id: Number(form.classId),
                name: selectedClass ? selectedClass.name : '',
                startDate: formatDate(form.startDate),
              });
          }
        });
        modalStore.closeModal();
      }}
      maxWidth
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={4}>
            <FormSection text={t('general.startDate')} />
          </WUFormCol>
          <WUFormCol lg={8} />
          <WUFormCol lg={4}>
            <FormDatePicker
              name="startDate"
              label={t('general.startDate')}
              initialValue={undefined}
              onChange={(date) => {
                if (date) {
                  const foundSchoolYear = getSchoolYear(date);
                  if (foundSchoolYear) {
                    setSelectableClasses(foundSchoolYear.classes);
                  } else {
                    setSelectableClasses([]);
                  }
                }
              }}
              testId={TestIds.STUDENT_NEW_CLASS_DATE}
              useWrapperWidth
              required
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={4}>
            <FormSection text={t('general.new') + ' ' + t('general.class')} />
          </WUFormCol>
          <WUFormCol lg={8} />
          <WUFormCol lg={4}>
            <FormSingleSelect
              items={mapToFormSelect(selectableClasses)}
              placeholder={t('general.new') + ' ' + t('general.class')}
              name="classId"
              initialValue={undefined}
              testId={TestIds.STUDENT_NEW_CLASS}
              onChange={(value) => {
                store.findAvailableStudentGroups(value);
              }}
              searchable
              required
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={4}>
            <FormSection text={t('general.history')} />
          </WUFormCol>
          <WUFormCol lg={8} />
          <WUFormCol lg={1.5}>
            <WUTable<IClassHistoryRow>
              columns={store.historyColumns}
              rowData={store.getClassHistoryRows}
              shadow
              emptyText={t('general.classChange.classHistoryMissing')}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={4}>
            <FormSection
              text={t('general.studentGroups')}
              description={t('general.classChange.newStudentGroupSelection')}
            />
          </WUFormCol>
          <WUFormCol lg={8} />
          <WUFormCol lg={1.5}>
            <WUTable<IStudentGroupRow>
              columns={store.studentGroupColumns}
              rowData={store.getStudentGroupRows}
              rowSelection={{
                selectedRowKeys: store.selectedStudentGroupRowKeys,
                type: 'checkbox',
                onChange: (keys) => store.setSelectedStudentGroupRowKeys(keys),
              }}
              shadow
              emptyText={t('general.classChange.studentGroupMissing')}
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});
