import { notification } from 'antd';
import { t } from 'i18next';
import { action, computed, observable } from 'mobx';
import React from 'react';
import { AxiosResponse } from 'axios';

import { TtHorizonViewApi } from '@/stores/api-store';
import ModalStore from '@/stores/modal-store';
import { inject, IStore, Store } from '@/types/store';
import { ErrorMessageEnum, showError, showErrorMessage } from '@/utils/error-handling/error-message';
import PublishHorizonDialog from '@tt/components/publish-horizon/publish-horizon-dialog';
import PublishHorizonSuccess from '@tt/components/publish-horizon/publish-horizon-success';
import { fromTTHorizonDto } from '@tt/utils/mappers';
import { byDateRangeAndName } from '@tt/utils/sorting';
import { TTHorizon } from '@tt/types';
import { TTHorizonPublicationDto } from '@untis/wu-rest-tt-api/api';

@Store()
export default class HorizonStore implements IStore {
  private modalStore = inject(ModalStore);

  @observable isLoading: boolean = true;
  @observable fetchedOnce: boolean = false;
  @observable private _horizons: TTHorizon[] = [];
  @observable private _activeHorizon?: TTHorizon;

  @computed
  get horizons(): TTHorizon[] {
    return [...this._horizons].sort(byDateRangeAndName);
  }

  @action
  add(horizon: TTHorizon) {
    // this.horizons.push(horizon) doesn't work for some reason - there's no mobx update in this case
    this._horizons = [...this._horizons, horizon];
  }

  @action
  update(horizon: TTHorizon) {
    this.remove(horizon);
    this.add(horizon);
  }

  @action
  remove(horizon: TTHorizon) {
    const index = this._horizons.findIndex((h) => {
      return h.id === horizon.id;
    });
    if (index > -1) {
      this._horizons.splice(index, 1);
    }
  }

  @action
  setActiveHorizon(horizonId: number) {
    this._activeHorizon = this._horizons.find((hrz) => hrz.id === horizonId);
  }

  @computed
  get activeHorizon(): TTHorizon | undefined {
    return this._activeHorizon;
  }

  @action
  async fetchHorizons() {
    this.isLoading = true;

    try {
      const response = await TtHorizonViewApi.getHorizons();
      this._horizons = response.data.map(fromTTHorizonDto);
      this.fetchedOnce = true;
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
    }

    this.isLoading = false;
  }

  @action.bound
  async deleteHorizon(horizon: TTHorizon) {
    const confirmed = await this.modalStore.deprecatedBooleanUserPrompt({
      content: t('timetabling.deleteTimetableQuestion', { timetableName: horizon.name }),
      okText: t('general.remove'),
    });

    if (!confirmed) {
      return;
    }

    this.isLoading = true;

    try {
      await TtHorizonViewApi.deleteHorizon(horizon.id);
      if (this._activeHorizon?.id === horizon.id) {
        this._activeHorizon = undefined;
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }

    await this.fetchHorizons();
  }

  @action.bound publishHorizon(horizon: TTHorizon) {
    return new Promise<void>((resolve) => {
      this.modalStore
        .deprecatedBooleanUserPrompt({
          content: <PublishHorizonDialog horizon={horizon} />,
          okText: t('general.publish'),
          cancelText: t('general.cancel'),
        })
        .then((confirmed: boolean) => {
          if (confirmed) {
            TtHorizonViewApi.publishHorizon(horizon.id)
              .then((response: AxiosResponse<TTHorizonPublicationDto>) => {
                const data: TTHorizonPublicationDto = response.data;

                notification.success({
                  key: 'HorizonPublishSuccess',
                  message: t('timetabling.publishTimetable.success.message'),
                  description: (
                    <PublishHorizonSuccess
                      numberOfCreatedPeriods={data.numberOfCreatedPeriods}
                      numberOfDeletedPeriods={data.numberOfDeletedPeriods}
                      numberOfUnchangedPeriods={data.numberOfUnchangedPeriods}
                      numberOfUpdatedPeriods={data.numberOfUpdatedPeriods}
                    />
                  ),
                });
              })
              .catch((error) => {
                showErrorMessage(error?.response?.data?.errorMessage);
              })
              .finally(() => resolve());
          } else {
            resolve();
          }
        });
    });
  }
}
