import React from 'react';
import Form, { FormItemProps } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';

export interface IFormItemProps<T> extends FormItemProps {
  name: NamePath;
  label?: string;
  disabled?: boolean;
  testId?: string;
}

export function WUFormItem<T>(props: IFormItemProps<T>) {
  // The child inside of the Form.Item must have a value and an onChange handler in order to work in forms
  return <Form.Item {...props} />;
}
