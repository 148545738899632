import React from 'react';

import { Icon } from '@/ui-components';
import './info-section.less';

interface IProps {
  infoText: string;
  testId?: string;
}

export const InfoSection = (props: IProps) => {
  return (
    <div data-testid={props.testId} className="info-section">
      <div className="info-section-icon">
        <Icon type="info-circle" />
      </div>
      {props.infoText}
    </div>
  );
};
