import dayjs from 'dayjs';

import {
  DateRangeDto,
  MinMaxDto,
  TimeSpanDto,
  TTChangeSetDto,
  TTConstraintKlasseDto,
  TTConstraintSubjectDto,
  TTConstraintTeacherDto,
  TTDiagnosisDto,
  TTDiagnosisPartitionDto,
  TTEvaluatedSlotDto,
  TTHorizonDto,
  TTKlasseDto,
  TTLessonAddedConflictDto,
  TTLessonDeletedConflictDto,
  TTLessonDto,
  TTMasterDataConstraintDto,
  TTOptimizationJobDto,
  TTOptimizationJobIndicatorsDto,
  TTOptimizationJobStatisticsDto,
  TTOptimizationJobStatusDto,
  TTPeriodDto,
  TTPeriodElementsDto,
  TTRoomDto,
  TTRoomRefDto,
  TTRoomRequirementDto,
  TTSchedulingUnitConfigurationDto,
  TTSchedulingUnitDto,
  TTSchoolyearDto,
  TTStudentGroupDto,
  TTSubjectDto,
  TTSubjectRefDto,
  TTTeacherDto,
  TTTeacherRefDto,
  TTTimeGridDto,
  TTTimeGridRowDto,
  TTTimeGridSlotDto,
  TTTimePreferenceDataDto,
  TTTimePreferenceRoomDto,
  TTTimePreferenceSlotDto,
  TTTimePreferenceSubjectDto,
  TTTimePreferenceTeacherDto,
  WULessonDto,
} from '@untis/wu-rest-tt-api';
import { dayjsFormatWithoutTimeDeprecated, dayjsWithoutTimeDeprecated } from '@/utils/date/date-util';
import {
  DateRange,
  Day,
  DayTime,
  PredefinedDateScheme,
  TimeSpan,
  TTChangeSet,
  TTDiagnosis,
  TTDiagnosisKlasse,
  TTDiagnosisPartition,
  TTDiagnosisRoom,
  TTDiagnosisTeacher,
  TTEvaluatedSlot,
  TTHorizon,
  TTKlasse,
  TTLesson,
  TTLessonAddedConflict,
  TTLessonChangedConflict,
  TTLessonDeletedConflict,
  TTMasterDataConstraintData,
  TTMasterDataConstraintKlasse,
  TTMasterDataConstraintSubject,
  TTMasterDataConstraintTeacher,
  TTOptimizationJob,
  TTOptimizationJobIndicators,
  TTOptimizationJobStatistics,
  TTOptimizationJobStatus,
  TTOptimizationState,
  TTOptimizationType,
  TTPeriod,
  TTRoom,
  TTRoomRequirement,
  TTSchedulingUnit,
  TTSchedulingUnitConfiguration,
  TTSchoolyear,
  TTStudentGroup,
  TTSubject,
  TTTeacher,
  TTTimeGrid,
  TTTimeGridRow,
  TTTimeGridSlot,
  TTTimePreferenceData,
  TTTimePreferenceKlasse,
  TTTimePreferenceRoom,
  TTTimePreferenceSlot,
  TTTimePreferenceSubject,
  TTTimePreferenceTeacher,
  WULesson,
} from '@tt/types';
import { TTKlasseRefDto, TTTimePreferenceKlasseDto, TTTimePreferenceSlotDtoDayEnum } from '@untis/wu-rest-tt-api/api';
import { NumberRange } from '@/types/number-range';

// FROM dto

export function fromDateRangeDto(dto: DateRangeDto): DateRange {
  return {
    start: dayjsWithoutTimeDeprecated(dto.start),
    end: dayjsWithoutTimeDeprecated(dto.end),
  };
}

function fromMinMaxDto(dto: MinMaxDto): NumberRange {
  return {
    min: typeof dto.min === 'number' ? dto.min : null,
    max: typeof dto.max === 'number' ? dto.max : null,
  };
}

export function fromTTTimePreferencesDto(dto: TTTimePreferenceDataDto): TTTimePreferenceData {
  return {
    timePreferencesKlasse: dto.timePreferencesKlasse.map(fromTTTimePreferenceKlasseDto),
    timePreferencesRoom: dto.timePreferencesRoom.map(fromTTTimePreferenceRoomDto),
    timePreferencesSubject: dto.timePreferencesSubject.map(fromTTTimePreferenceSubjectDto),
    timePreferencesTeacher: dto.timePreferencesTeacher.map(fromTTTimePreferenceTeacherDto),
  };
}

function fromTTTimePreferenceKlasseDto(dto: TTTimePreferenceKlasseDto): TTTimePreferenceKlasse {
  return {
    klasse: fromTTKlasseRefDto(dto.klasse),
    timePreferences: dto.timePreferences.map(fromTTTimePreferenceSlotDto),
  };
}

function fromTTTimePreferenceRoomDto(dto: TTTimePreferenceRoomDto): TTTimePreferenceRoom {
  return {
    room: fromTTRoomRefDto(dto.room),
    timePreferences: dto.timePreferences.map(fromTTTimePreferenceSlotDto),
  };
}

function fromTTTimePreferenceSubjectDto(dto: TTTimePreferenceSubjectDto): TTTimePreferenceSubject {
  return {
    subject: fromTTSubjectRefDto(dto.subject),
    timePreferences: dto.timePreferences.map(fromTTTimePreferenceSlotDto),
  };
}

function fromTTTimePreferenceTeacherDto(dto: TTTimePreferenceTeacherDto): TTTimePreferenceTeacher {
  return {
    teacher: fromTTTeacherRefDto(dto.teacher),
    timePreferences: dto.timePreferences.map(fromTTTimePreferenceSlotDto),
  };
}

function fromTTTimePreferenceSlotDto(dto: TTTimePreferenceSlotDto): TTTimePreferenceSlot {
  return {
    day: fromDayString(dto.day),
    index: dto.index,
    weight: dto.weight,
  };
}

export function fromTimeSpanDto(dto: TimeSpanDto): TimeSpan {
  const startDayjs = dayjs(dto.start);
  const endDayjs = dayjs(dto.end);

  return {
    start: startDayjs.hour() * 100 + startDayjs.minute(),
    end: endDayjs.hour() * 100 + endDayjs.minute(),
  };
}

export function fromTTChangeSetDto(
  dto: TTChangeSetDto,
  klassenById: Map<number, TTKlasse>,
  roomsById: Map<number, TTRoom>,
  subjectsById: Map<number, TTSubject>,
  teachersById: Map<number, TTTeacher>,
  studentGroupsById: Map<number, TTStudentGroup>,
): TTChangeSet {
  const lessonsChanged: Map<number, TTLessonChangedConflict> = new Map<number, TTLessonChangedConflict>();

  const getConflict = (lessonDto: WULessonDto): TTLessonChangedConflict => {
    let lessonChangedConflict = lessonsChanged.get(lessonDto.id);
    if (!lessonChangedConflict) {
      lessonChangedConflict = {
        lesson: fromWULessonDto(lessonDto, subjectsById, klassenById, teachersById, studentGroupsById),
      };
      lessonsChanged.set(lessonDto.id, lessonChangedConflict);
    }
    return lessonChangedConflict;
  };

  dto.lessonsChangedDateRange.forEach((conflict) => {
    const lessonChangedConflict: TTLessonChangedConflict = getConflict(conflict.lesson);
    lessonChangedConflict.dateRange = {
      old: fromDateRangeDto(conflict.oldDateRange),
      new: fromDateRangeDto(conflict.newDateRange),
    };
  });
  dto.lessonsChangedKlassen.forEach((conflict) => {
    const lessonChangedConflict: TTLessonChangedConflict = getConflict(conflict.lesson);
    lessonChangedConflict.klassen = {
      old: Array.from(conflict.oldKlassenIds).map((id: number) => klassenById.get(id)!),
      new: Array.from(conflict.newKlassenIds).map((id: number) => klassenById.get(id)!),
    };
  });
  dto.lessonsChangedPeriodsPerWeek.forEach((conflict) => {
    const lessonChangedConflict: TTLessonChangedConflict = getConflict(conflict.lesson);
    lessonChangedConflict.periodsPerWeek = {
      old: conflict.oldPeriodsPerWeek,
      new: conflict.newPeriodsPerWeek,
    };
  });
  dto.lessonsChangedPredefinedDateScheme.forEach((conflict) => {
    const lessonChangedConflict: TTLessonChangedConflict = getConflict(conflict.lesson);
    lessonChangedConflict.predefinedDateScheme = {
      old: fromPredefinedDateSchemeString(conflict.oldPredefinedDateScheme),
      new: fromPredefinedDateSchemeString(conflict.newPredefinedDateScheme),
    };
  });
  dto.lessonChangedRoomRequirements.forEach((conflict) => {
    const lessonChangedConflict: TTLessonChangedConflict = getConflict(conflict.lesson);
    lessonChangedConflict.roomRequirements = {
      old: conflict.oldRoomRequirements.map((rr) => fromTTRoomRequirementDto(rr, roomsById)),
      new: conflict.newRoomRequirements.map((rr) => fromTTRoomRequirementDto(rr, roomsById)),
    };
  });
  dto.lessonsChangedSubject.forEach((conflict) => {
    const lessonChangedConflict: TTLessonChangedConflict = getConflict(conflict.lesson);
    lessonChangedConflict.subject = {
      old: subjectsById.get(conflict.oldSubjectId)!,
      new: subjectsById.get(conflict.newSubjectId)!,
    };
  });
  dto.lessonsChangedTeachers.forEach((conflict) => {
    const lessonChangedConflict: TTLessonChangedConflict = getConflict(conflict.lesson);
    lessonChangedConflict.teachers = {
      old: Array.from(conflict.oldTeacherIds).map((id: number) => teachersById.get(id)!),
      new: Array.from(conflict.newTeacherIds).map((id: number) => teachersById.get(id)!),
    };
  });
  dto.lessonsChangedStudentgroup?.forEach((conflict) => {
    const lessonChangedConflict: TTLessonChangedConflict = getConflict(conflict.lesson);
    lessonChangedConflict.studentGroup = {
      old: studentGroupsById.get(conflict.oldStudentGroupId)!,
      new: studentGroupsById.get(conflict.newStudentGroupId)!,
    };
  });

  return {
    lessonsAdded: dto.lessonsAdded.map((lessonAdded) =>
      fromTTLessonAddedConflictDto(lessonAdded, subjectsById, klassenById, teachersById, studentGroupsById),
    ),
    lessonsChanged: Array.from(lessonsChanged.values()),
    lessonsDeleted: dto.lessonsDeleted.map(fromTTLessonDeletedConflictDto),
  };
}

export function fromTTDiagnosisDto(dto: TTDiagnosisDto): TTDiagnosis {
  return {
    partitions: dto.partitions.map(fromTTDiagnosisPartitionDto),
  };
}

export function fromTTDiagnosisPartitionDto(dto: TTDiagnosisPartitionDto): TTDiagnosisPartition {
  const { predefinedDateScheme, klasseItems, roomItems, teacherItems, count } = dto;
  return {
    predefinedDateScheme: fromPredefinedDateSchemeString(predefinedDateScheme),
    klasseItems: klasseItems as unknown as TTDiagnosisKlasse[],
    teacherItems: teacherItems as unknown as TTDiagnosisTeacher[],
    roomItems: roomItems as unknown as TTDiagnosisRoom[],
    count,
  };
}

export function fromTTEvaluatedSlotDto(dto: TTEvaluatedSlotDto): TTEvaluatedSlot {
  return {
    index: dto.index,
    day: fromDayString(dto.day),
    score: dto.score,
    collision: dto.collision,
  };
}

export function fromTTHorizonDto(dto: TTHorizonDto): TTHorizon {
  return {
    id: dto.id,
    name: dto.name,
    description: dto.description ? dto.description : '',
    dateRange: fromDateRangeDto(dto.dateRange),
  };
}

export function fromTTKlasseDto(dto: TTKlasseDto): TTKlasse {
  return {
    id: dto.id,
    name: dto.name,
  };
}

function fromTTKlasseRefDto(dto: TTKlasseRefDto): TTKlasse {
  return {
    id: dto.id,
    name: dto.displayName,
  };
}

export function fromTTLessonDto(
  dto: TTLessonDto,
  subjectsById: Map<number, TTSubject>,
  klassenById: Map<number, TTKlasse>,
  teachersById: Map<number, TTTeacher>,
  studentGroupsById: Map<number, TTStudentGroup>,
): TTLesson {
  return new TTLesson(
    dto.id,
    fromDateRangeDto(dto.dateRange),
    fromPredefinedDateSchemeString(dto.predefinedDateScheme),
    subjectsById.get(dto.subjectId)!,
    Array.from(dto.klassenIds).map((klasseId: number) => klassenById.get(klasseId)!),
    Array.from(dto.teacherIds).map((teacherId: number) => teachersById.get(teacherId)!),
    dto.schedulingUnitId,
    dto.studentGroupId ? studentGroupsById.get(dto.studentGroupId)! : undefined,
  );
}

export function fromTTMasterDataConstraintDto(dto: TTMasterDataConstraintDto): TTMasterDataConstraintData {
  return {
    constraintsKlasse: dto.constraintsKlasse.map(fromTTConstraintKlasseDto),
    constraintsSubject: dto.constraintsSubject.map(fromTTConstraintSubjectDto),
    constraintsTeacher: dto.constraintsTeacher.map(fromTTConstraintTeacherDto),
  };
}

function fromTTConstraintKlasseDto(dto: TTConstraintKlasseDto): TTMasterDataConstraintKlasse {
  return {
    klasse: fromTTKlasseRefDto(dto.klasse),
    periodsPerDay: fromMinMaxDto(dto.periodsPerDay),
  };
}

function fromTTConstraintSubjectDto(dto: TTConstraintSubjectDto): TTMasterDataConstraintSubject {
  return {
    subject: fromTTSubjectRefDto(dto.subject),
    allowLargeBlocks: dto.allowLargeBlocks,
    allowMoreThanOncePerDay: dto.allowMoreThanOncePerDay,
  };
}

function fromTTConstraintTeacherDto(dto: TTConstraintTeacherDto): TTMasterDataConstraintTeacher {
  return {
    teacher: fromTTTeacherRefDto(dto.teacher),
    nonTeachingPeriods: fromMinMaxDto(dto.nonTeachingPeriods),
    periodsPerDay: fromMinMaxDto(dto.periodsPerDay),
  };
}

export function fromTTOptimizationJobDto(dto: TTOptimizationJobDto): TTOptimizationJob {
  return {
    id: dto.id,
    type: fromTTOptimizationTypeString(dto.type),
    createdOn: dayjs(dto.createdOn),
    status: fromTTOptimizationJobStatusDto(dto.status),
    statistics: fromTTOptimizationJobStatisticsDto(dto.statistics),
    indicators: fromTTOptimizationJobIndicators(dto.indicators),
  };
}

export function fromTTPeriodDto(dto: TTPeriodDto): TTPeriod {
  const roomIdsByLessonId: Map<number, number[]> = new Map<number, number[]>();
  dto.elementsPerLesson.forEach((e: TTPeriodElementsDto) => {
    roomIdsByLessonId.set(e.lessonId, Array.from(e.roomIds));
  });

  return new TTPeriod(
    dto.id,
    fromDayString(dto.day),
    dto.slotIndex,
    fromTimeSpanDto(dto.time),
    dto.locked,
    dto.schedulingUnitId,
    roomIdsByLessonId,
  );
}

export function fromTTRoomDto(dto: TTRoomDto): TTRoom {
  return {
    id: dto.id,
    name: dto.name,
  };
}

function fromTTRoomRefDto(dto: TTRoomRefDto): TTRoom {
  return {
    id: dto.id,
    name: dto.displayName,
  };
}

export function fromTTSchedulingUnitDto(dto: TTSchedulingUnitDto, ttLessons?: TTLesson[]): TTSchedulingUnit {
  return new TTSchedulingUnit(
    dto.id,
    dto.periodsPerWeek,
    Array.from(dto.lessonIds),
    Array.from(dto.periodIds),
    ttLessons,
    dto.blockSize,
    dto.blockAmount,
  );
}

export function fromTTSchedulingUnitConfigurationDto(
  dtos: TTSchedulingUnitConfigurationDto[],
): TTSchedulingUnitConfiguration[] {
  return dtos.map((dto) => ({
    actions: dto.actions,
    schedulingUnit: fromTTSchedulingUnitDto(dto.schedulingUnit),
  }));
}

export function fromTTSchoolyearDto(dto: TTSchoolyearDto): TTSchoolyear {
  return {
    id: dto.id,
    name: dto.name,
    dateRange: fromDateRangeDto(dto.dateRange),
  };
}

export function fromTTStudentGroupDto(dto: TTStudentGroupDto): TTStudentGroup {
  return {
    id: dto.id,
    name: dto.name,
  };
}

export function fromTTSubjectDto(dto: TTSubjectDto): TTSubject {
  return {
    id: dto.id,
    name: dto.name,
    backColor: dto.backColor,
  };
}

function fromTTSubjectRefDto(dto: TTSubjectRefDto): TTSubject {
  return {
    id: dto.id,
    name: dto.displayName,
  };
}

export function fromTTTeacherDto(dto: TTTeacherDto): TTTeacher {
  return {
    id: dto.id,
    name: dto.name,
  };
}

function fromTTTeacherRefDto(dto: TTTeacherRefDto): TTTeacher {
  return {
    id: dto.id,
    name: dto.displayName,
  };
}

function fromTTTimeGridRowDto(rows: TTTimeGridRowDto[]): TTTimeGridRow[] {
  return rows.map((row: TTTimeGridRowDto) => ({
    index: row.index,
    label: row.label,
  }));
}

export function fromTTTimeGridDto(dto: TTTimeGridDto): TTTimeGrid {
  return new TTTimeGrid(
    dto.id,
    dto.name,
    fromDayString(dto.firstDayOfWeek),
    dto.slots.map(function (dto: TTTimeGridSlotDto): TTTimeGridSlot {
      return new TTTimeGridSlot(
        fromDayString(dto.day),
        dto.index,
        fromTimeSpanDto(dto.timeSpan),
        fromDayTimeString(dto.dayTime),
      );
    }),
    fromTTTimeGridRowDto(dto.rows),
  );
}

export function fromWULessonDto(
  dto: WULessonDto,
  subjectsById: Map<number, TTSubject>,
  klassenById: Map<number, TTKlasse>,
  teachersById: Map<number, TTTeacher>,
  studentGroupsById: Map<number, TTStudentGroup>,
): WULesson {
  return {
    id: dto.id,
    periodsPerWeek: dto.periodsPerWeek,
    subject: subjectsById.get(dto.subjectId)!,
    klassen: Array.from(dto.klassenIds).map((id: number) => klassenById.get(id)!),
    teachers: Array.from(dto.teacherIds).map((id: number) => teachersById.get(id)!),
    predefinedDateScheme: fromPredefinedDateSchemeString(dto.predefinedDateScheme),
    studentGroup: dto.studentGroupId ? studentGroupsById.get(dto.studentGroupId) : undefined,
  };
}

function fromDayString(day: 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN'): Day {
  return Day[day];
}

function fromDayTimeString(dayTime: 'NONE' | 'MORNING' | 'AFTERNOON'): DayTime {
  return DayTime[dayTime];
}

function fromPredefinedDateSchemeString(pds: 'WEEK_A' | 'WEEK_B' | undefined): PredefinedDateScheme | null {
  if (!pds) {
    return null;
  }
  return PredefinedDateScheme[pds];
}

function fromTTLessonAddedConflictDto(
  dto: TTLessonAddedConflictDto,
  subjectsById: Map<number, TTSubject>,
  klassenById: Map<number, TTKlasse>,
  teachersById: Map<number, TTTeacher>,
  studentGroupsById: Map<number, TTStudentGroup>,
): TTLessonAddedConflict {
  return {
    lesson: fromWULessonDto(dto.lesson, subjectsById, klassenById, teachersById, studentGroupsById),
  };
}

function fromTTLessonDeletedConflictDto(dto: TTLessonDeletedConflictDto): TTLessonDeletedConflict {
  return {
    lessonId: dto.lessonId,
  };
}

function fromTTOptimizationJobIndicators(dto: TTOptimizationJobIndicatorsDto): TTOptimizationJobIndicators {
  return { ...dto };
}

function fromTTOptimizationJobStatisticsDto(dto: TTOptimizationJobStatisticsDto): TTOptimizationJobStatistics {
  return {
    executionStarted: dto.executionStarted ? dayjs(dto.executionStarted) : undefined,
    executionEnded: dto.executionEnded ? dayjs(dto.executionEnded) : undefined,
  };
}

function fromTTOptimizationJobStatusDto(dto: TTOptimizationJobStatusDto): TTOptimizationJobStatus {
  return {
    state: fromTTOptimizationStateString(dto.state),
    progress: dto.progress,
    error: dto.error,
  };
}

function fromTTOptimizationStateString(state: 'QUEUED' | 'RUNNING' | 'FINISHED' | 'ABORTED'): TTOptimizationState {
  return TTOptimizationState[state];
}

function fromTTOptimizationTypeString(type: 'QUICK' | 'INTENSE'): TTOptimizationType {
  return TTOptimizationType[type];
}

function fromTTRoomRequirementDto(dto: TTRoomRequirementDto, roomsById: Map<number, TTRoom>): TTRoomRequirement {
  return {
    homeRoom: dto.homeRoomId ? roomsById.get(dto.homeRoomId)! : null,
    subjectRoom: dto.subjectRoomId ? roomsById.get(dto.subjectRoomId)! : null,
  };
}

// TO dto

export function toDateRangeDto(dateRange: DateRange): DateRangeDto {
  return {
    start: dayjsFormatWithoutTimeDeprecated(dateRange.start),
    end: dayjsFormatWithoutTimeDeprecated(dateRange.end),
  };
}

function toMinMaxDto(minMax: NumberRange): MinMaxDto {
  return {
    min: typeof minMax.min === 'number' ? minMax.min : undefined,
    max: typeof minMax.max === 'number' ? minMax.max : undefined,
  };
}

export function toTTHorizonDto(horizon: TTHorizon): TTHorizonDto {
  return {
    id: horizon.id,
    name: horizon.name,
    description: horizon.description,
    dateRange: toDateRangeDto(horizon.dateRange),
  };
}

function toTTKlasseRefDto(klasse: TTKlasse): TTKlasseRefDto {
  return {
    id: klasse.id,
    displayName: klasse.name,
  };
}

export function toTTMasterDataConstraintDto(data: TTMasterDataConstraintData): TTMasterDataConstraintDto {
  return {
    constraintsKlasse: data.constraintsKlasse.map(toTTConstraintKlasseDto),
    constraintsSubject: data.constraintsSubject.map(toTTConstraintSubjectDto),
    constraintsTeacher: data.constraintsTeacher.map(toTTConstraintTeacherDto),
  };
}

function toTTConstraintKlasseDto(constraint: TTMasterDataConstraintKlasse): TTConstraintKlasseDto {
  return {
    klasse: toTTKlasseRefDto(constraint.klasse),
    periodsPerDay: toMinMaxDto(constraint.periodsPerDay),
  };
}

function toTTConstraintSubjectDto(constraint: TTMasterDataConstraintSubject): TTConstraintSubjectDto {
  return {
    subject: toTTSubjectRefDto(constraint.subject),
    allowLargeBlocks: constraint.allowLargeBlocks,
    allowMoreThanOncePerDay: constraint.allowMoreThanOncePerDay,
  };
}

function toTTConstraintTeacherDto(constraint: TTMasterDataConstraintTeacher): TTConstraintTeacherDto {
  return {
    teacher: toTTTeacherRefDto(constraint.teacher),
    nonTeachingPeriods: toMinMaxDto(constraint.nonTeachingPeriods),
    periodsPerDay: toMinMaxDto(constraint.periodsPerDay),
  };
}

function toTTRoomRefDto(room: TTRoom): TTRoomRefDto {
  return {
    id: room.id,
    displayName: room.name,
  };
}

function toTTSubjectRefDto(subject: TTSubject): TTSubjectRefDto {
  return {
    id: subject.id,
    displayName: subject.name,
  };
}

function toTTTeacherRefDto(teacher: TTTeacher): TTTeacherRefDto {
  return {
    id: teacher.id,
    displayName: teacher.name,
  };
}

export function toTTTimePreferenceDataDto(data: TTTimePreferenceData): TTTimePreferenceDataDto {
  return {
    timePreferencesKlasse: data.timePreferencesKlasse.map(toTTTimePreferenceKlasseDto),
    timePreferencesRoom: data.timePreferencesRoom.map(toTTTimePreferenceRoomDto),
    timePreferencesSubject: data.timePreferencesSubject.map(toTTTimePreferenceSubjectDto),
    timePreferencesTeacher: data.timePreferencesTeacher.map(toTTTimePreferenceTeacherDto),
  };
}

function toTTTimePreferenceSlotDto(slot: TTTimePreferenceSlot): TTTimePreferenceSlotDto {
  return {
    day: toTTTimePreferenceSlotDtoDayEnum(slot.day),
    index: slot.index,
    weight: slot.weight,
  };
}

function toTTTimePreferenceSlotDtoDayEnum(day: Day): TTTimePreferenceSlotDtoDayEnum {
  return TTTimePreferenceSlotDtoDayEnum[day];
}

function toTTTimePreferenceKlasseDto(timePreference: TTTimePreferenceKlasse): TTTimePreferenceKlasseDto {
  return {
    klasse: toTTKlasseRefDto(timePreference.klasse),
    timePreferences: timePreference.timePreferences.map(toTTTimePreferenceSlotDto),
  };
}

function toTTTimePreferenceRoomDto(timePreference: TTTimePreferenceRoom): TTTimePreferenceRoomDto {
  return {
    room: toTTRoomRefDto(timePreference.room),
    timePreferences: timePreference.timePreferences.map(toTTTimePreferenceSlotDto),
  };
}

function toTTTimePreferenceSubjectDto(timePreference: TTTimePreferenceSubject): TTTimePreferenceSubjectDto {
  return {
    subject: toTTSubjectRefDto(timePreference.subject),
    timePreferences: timePreference.timePreferences.map(toTTTimePreferenceSlotDto),
  };
}

function toTTTimePreferenceTeacherDto(timePreference: TTTimePreferenceTeacher): TTTimePreferenceTeacherDto {
  return {
    teacher: toTTTeacherRefDto(timePreference.teacher),
    timePreferences: timePreference.timePreferences.map(toTTTimePreferenceSlotDto),
  };
}
