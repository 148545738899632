// ligten color by a factor
export const lightenColor = (color: string, lightFactor = 0.5) => {
  // Parse the color string into an array of RGB values
  const r = color.substring(1, 3);
  const g = color.substring(3, 5);
  const b = color.substring(5, 7);
  const rgb: number[] = [r, g, b].map((color) => parseInt(color, 16));

  // Calculate the new RGB values after lightening the color
  const newRgb: number[] = rgb.map((val) => Math.min(255, val + Math.round(lightFactor * (255 - val))));

  // Convert the new RGB values back into a hex string
  const hex: string = newRgb.map((val) => val.toString(16).padStart(2, '0')).join('');

  // Prepend the '#' character to the hex string and return the result
  return `#${hex}`;
};
