import dayjs, { Dayjs } from 'dayjs';

/** This date formats are deprecated, should be replaced with new unified formats below */
const DEPRECATED_FORMAT_DATE = 'YYYY-MM-DD';
const DEPRECATED_FORMAT_DATETIME = 'YYYY-MM-DDTHH:mm:ss';

const DATE_FORMAT_PATTERN = 'L';
const TIME_FORMAT_PATTERN = 'LT';
const DATETIME_FORMAT_PATTERN = 'L LT';
const WEEKDAY_PATTERN = 'dddd';

export function formatDateForServerRequest(input: Dayjs | string): string {
  return dayjs(input).format('YYYY-MM-DD');
}

export function formatTimeForServerRequest(input: Dayjs | string): string {
  return dayjs(input).format('HH:mm');
}

export function formatDateTimeForServerRequest(input: Dayjs): string {
  return input.format('YYYY-MM-DDTHH:mm:ss');
}

export function formatDate(input: Dayjs | string | undefined): string {
  if (!input) {
    return '';
  }
  return dayjs(input).format(DATE_FORMAT_PATTERN);
}

// 'Monday', 'Tuesday',...
export function formatWeekDay(input: Dayjs): string {
  return dayjs(input).format(WEEKDAY_PATTERN);
}

export function formatTime(input: Dayjs | string | undefined): string {
  if (!input) {
    return '';
  }
  return dayjs(input).format(TIME_FORMAT_PATTERN);
}

export function formatDateTime(input: Dayjs | string | undefined): string {
  if (!input) {
    return '';
  }
  return dayjs(input).format(DATETIME_FORMAT_PATTERN);
}

export function dateTimeWithFormat(input: Dayjs | string, format: string): string {
  return dayjs(input).format(format);
}

export function formatDateTimeEN(input: Dayjs): string {
  return input.format('YYYY-MM-DDTHH:mm:ss');
}

export function formatDateRange(start: Dayjs, end: Dayjs): string {
  const startDayJs = dayjs(start);
  const endDayJs = dayjs(end);

  if (startDayJs.isSame(endDayJs, 'date')) {
    return startDayJs.format(DATE_FORMAT_PATTERN);
  }

  return startDayJs.format(DATE_FORMAT_PATTERN) + ' - ' + endDayJs.format(DATE_FORMAT_PATTERN);
}

export function formatTimeRange(start: Dayjs, end: Dayjs): string {
  const startDayJs = dayjs(start);
  const endDayJs = dayjs(end);

  if (startDayJs.isSame(endDayJs)) {
    return startDayJs.format(TIME_FORMAT_PATTERN);
  }

  return startDayJs.format(TIME_FORMAT_PATTERN) + ' - ' + endDayJs.format(TIME_FORMAT_PATTERN);
}

export function formatDateTimeRange(start: Dayjs, end: Dayjs): string {
  const startDayJs = dayjs(start);
  const endDayJs = dayjs(end);
  const isSameDay = startDayJs.isSame(endDayJs, 'date');
  const isSameTime = startDayJs.format('hh:mm') === endDayJs.format('hh:mm');

  if (isSameDay) {
    if (isSameTime) {
      return `${startDayJs.format(DATE_FORMAT_PATTERN)} ${startDayJs.format(TIME_FORMAT_PATTERN)}`;
    } else {
      return `${startDayJs.format(DATE_FORMAT_PATTERN)} ${startDayJs.format(TIME_FORMAT_PATTERN)} - ${endDayJs.format(
        TIME_FORMAT_PATTERN,
      )}`;
    }
  }

  return (
    startDayJs.format(DATE_FORMAT_PATTERN) +
    ' ' +
    startDayJs.format(TIME_FORMAT_PATTERN) +
    ' - ' +
    endDayJs.format(DATE_FORMAT_PATTERN) +
    ' ' +
    endDayJs.format(TIME_FORMAT_PATTERN)
  );
}

// !!! All METHODS USING FORMAT_DATE const are deprecated and date format should be replaced with new unified formats

// returns a dayjs object with the given timestamp, ignoring the time, only taking the date
export function dayjsWithoutTimeDeprecated(input: Dayjs | string): Dayjs {
  return dayjs(input, DEPRECATED_FORMAT_DATE);
}

export function dayjsFormatWithoutTimeDeprecated(input: Dayjs): string {
  return input.format(DEPRECATED_FORMAT_DATE);
}

export function dayjsFormatWithTimeDeprecated(input: Dayjs): string {
  return input.format(DEPRECATED_FORMAT_DATETIME);
}

/**
 * PARSING FUNCTIONS
 */

export function uDateFromDayjs(input: Dayjs): number {
  return Number(input.format('YYYY-MM-DD').split('-').join(''));
}

export function dayjsFromUDateWithoutTime(date: number): Dayjs {
  if (date === 0) {
    return dayjs('19700101', 'YYYYMMDD');
  }
  return dayjs(date.toString(), 'YYYYMMDD');
}

export const dayjsFromDateString = (dateTime: string): Dayjs => {
  return dayjs(dateTime, 'YYYY-MM-DD');
};

export const dayjsFromTimeString = (time: string): Dayjs => {
  const now = dayjs();
  const timeDayJs = dayjs(time, 'HH:mm');
  return now.startOf('day').set('hours', timeDayJs.hour()).set('minutes', timeDayJs.minute());
};

export const dayJsFromDateTimeString = (dateTime: string): Dayjs => {
  return dayjs(dateTime, 'YYYY-MM-DDTHH:mm:ss');
};

/**
 * WebUntis BE (= Java Calendar) "first day of week attribute" does not match the definition of
 * DayJs "first day of week"
 * Use this function for conversion.
 * This is WebUntis definition:
 *
 * SUNDAY = 1;
 * MONDAY = 2;
 * TUESDAY = 3;
 * WEDNESDAY = 4;
 * THURSDAY = 5;
 * FRIDAY = 6;
 * SATURDAY = 7;
 *
 * DayJs starts with 0, which is "Monday"
 */

export const toDayJsFirstDayOfWeek = (javaCalendarFirstDayOfWeek: number): number => {
  switch (javaCalendarFirstDayOfWeek) {
    case 1:
      return 6;
    case 2:
      return 0;
    case 3:
      return 1;
    case 4:
      return 2;
    case 5:
      return 3;
    case 6:
      return 4;
    case 7:
      return 5;
    default:
      return 0;
  }
};
