import React, { useState, useCallback } from 'react';
import { Popover } from 'antd';
import clsx from 'clsx';

import { IconButton } from '@/ui-components/icon-button/icon-button';

import './icon-picker.less';

export type IconPickerIcon = {
  icon: string;
  label: string;
};

export type IconPickerProps = {
  testId?: string;
  onChange?: (icon: string) => void;
  defaultIcon?: string;
  icons: IconPickerIcon[];
  translations: {
    triggerTooltip?: string;
    heading: string;
  };
};

export const IconPicker = ({ testId, translations, icons, onChange, defaultIcon }: IconPickerProps) => {
  const [visible, setVisible] = useState(false);
  const [icon, setIcon] = useState(() => defaultIcon ?? icons[0]?.icon ?? '');

  function open() {
    setVisible(true);
  }

  function close() {
    setVisible(false);
  }

  function handleOnVisibleChange(visible: boolean) {
    setVisible(visible);
  }

  function onIconClick(icon: string) {
    setIcon(icon);
    onChange?.(icon);
  }

  return (
    <Popover
      trigger="click"
      content={
        <IconGrid
          title={translations.heading}
          selectedIcon={icon}
          icons={icons}
          onIconClick={onIconClick}
          closePopover={close}
        />
      }
      placement="rightBottom"
      visible={visible}
      onVisibleChange={handleOnVisibleChange}
      overlayClassName="icon-picker-popover-container"
    >
      {icon && (
        <IconButton
          testId={testId}
          tooltip={translations.triggerTooltip ? { title: translations.triggerTooltip, placement: 'right' } : undefined}
          subdir="icon-picker"
          type={icon}
          ariaLabel={icon}
          onClick={open}
        />
      )}
    </Popover>
  );
};

type IconGridProps = {
  title: string;
  selectedIcon: string;
  icons: IconPickerIcon[];
  onIconClick: (icon: string) => void;
  closePopover: () => void;
};

function IconGrid({ title, selectedIcon, icons, onIconClick, closePopover }: IconGridProps) {
  const onClick = useCallback(
    (icon?: string) => {
      return () => {
        closePopover();
        if (icon) {
          onIconClick(icon);
        }
      };
    },
    [onIconClick, closePopover],
  );

  return (
    <div className="icon-picker-popover">
      <div className="icon-picker-popover-header">
        <h1>{title}</h1>
        <IconButton onClick={onClick()} type="cancel-gray" ariaLabel="Close" />
      </div>
      <div className="icon-picker-popover-grid">
        {icons.map((icon) => (
          <IconButton
            testId={`icon-picker-grid-${icon}`}
            subdir="icon-picker"
            tooltip={{ title: icon.label }}
            onClick={onClick(icon.icon)}
            key={icon.icon}
            ariaLabel={icon.label}
            type={icon.icon}
            className={clsx({
              active: selectedIcon === icon.icon,
            })}
          />
        ))}
      </div>
    </div>
  );
}
