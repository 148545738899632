import React, { useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import clsx from 'clsx';

import { IDateCalendarHolidayProps } from '../date/calendar/date-calendar/date-calendar';

import { DateCalendar, IconButton } from '@/ui-components';
import { getPickerLocaleFromDayjs } from '@/ui-components/date/date-picker/date-picker';

import './date-picker-with-arrows.less';

export interface IDatePickerWithArrowsProps {
  mode: 'day' | 'week';
  value: Dayjs;
  onChange: (date: Dayjs) => void;
  holidays?: IDateCalendarHolidayProps[];
  testId?: string;
  /* disables dates in calendar and hides arrow buttons when next-/previous date is disabled*/
  disabledDate?: (date: Dayjs) => boolean;
  /* minimum width of date button (excluding arrow buttons)*/
  minWidth?: number;
  keepArrows?: boolean;
  getNextDay?: (date: Dayjs) => Dayjs;
  getPreviousDay?: (date: Dayjs) => Dayjs;
}

const DatePickerWithArrows = (props: IDatePickerWithArrowsProps) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const onCloseDatePicker = () => {
    setIsDatePickerOpen(false);
  };

  const isToday = props.mode === 'day' && props.value.isSame(dayjs(), 'd');

  const className = clsx('date-picker-with-arrows', {
    'keep-arrows': props.keepArrows,
    'is-open': isDatePickerOpen,
    'is-today': isToday,
  });

  const testId = `date-picker-with-arrows${props.testId ? `-${props.testId}` : ''}`;
  const dateTextTestId = `${testId}-date-text`;
  const leftArrowTestId = `${testId}-previous`;
  const rightArrowTestId = `${testId}-next`;

  const handleDateClick = () => {
    !isDatePickerOpen && setIsDatePickerOpen(true);
  };

  const getNextDate = () => {
    if (props.mode === 'week') {
      return props.value.clone().add(1, 'week');
    } else {
      return props.getNextDay ? props.getNextDay(props.value) : props.value.clone().add(1, 'day');
    }
  };

  const getPreviousDate = () => {
    if (props.mode === 'week') {
      return props.value.clone().subtract(1, 'week');
    } else {
      return props.getPreviousDay ? props.getPreviousDay(props.value) : props.value.clone().subtract(1, 'day');
    }
  };

  const isNextDisabled = useMemo(() => {
    return props.disabledDate ? props.disabledDate(getNextDate()) : false;
  }, [props.disabledDate, props.getNextDay, props.value, props.mode]);

  const isPreviousDisabled = useMemo(() => {
    return props.disabledDate ? props.disabledDate(getPreviousDate()) : false;
  }, [props.disabledDate, props.getPreviousDay, props.value, props.mode]);

  const showArrow = useMemo(() => {
    return props.keepArrows ? true : !isDatePickerOpen;
  }, [isDatePickerOpen, props.keepArrows]);

  const handleOnClickNextDate = () => {
    props.onChange(getNextDate());
  };

  const handleOnClickPreviousDate = () => {
    props.onChange(getPreviousDate());
  };

  const text =
    props.mode === 'day'
      ? `${props.value.clone().format('dddd, DD. MMMM YYYY')}`
      : `${props.value.clone().startOf('week').format('DD. MMMM')} - ${props.value
          .clone()
          .endOf('week')
          .format('DD. MMMM YYYY')}`;

  const locale = getPickerLocaleFromDayjs(props.value);

  return (
    <div className={className} data-testid={testId}>
      {showArrow && (
        <IconButton
          className="icon-button"
          ariaLabel="previous date"
          type="arrow-left"
          viewOnly={true}
          onClick={handleOnClickPreviousDate}
          testId={leftArrowTestId}
          disabled={isPreviousDisabled}
        />
      )}
      <span
        className="date-text"
        data-testid={dateTextTestId}
        onClick={handleDateClick}
        role="span-button"
        style={{ minWidth: props.minWidth }}
      >
        {text}
      </span>
      {showArrow && (
        <IconButton
          className="icon-button"
          ariaLabel="next date"
          type="arrow-right"
          viewOnly={true}
          onClick={handleOnClickNextDate}
          testId={rightArrowTestId}
          disabled={isNextDisabled}
        />
      )}
      {isDatePickerOpen && (
        <div className="calendar-wrapper">
          <DateCalendar
            picker={props.mode === 'week' ? 'week' : undefined}
            value={props.value}
            // it's not possible to select an 'undefined' value with this component
            onChange={(value) => {
              if (value) {
                props.onChange(value);
              }
            }}
            onClose={onCloseDatePicker}
            holidays={props.holidays}
            locale={locale}
            disabledDate={props.disabledDate}
          />
        </div>
      )}
    </div>
  );
};

export default DatePickerWithArrows;
