import React, { useCallback, useMemo, useState } from 'react';
import './redirect-uri-input.less';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  getInitialDomainArray,
  getRedirectUriInputType,
  isDomainPatternAsteriskOnly,
  isDomainPatternTextOnly,
  mapRedirectUriItemsToReactNodes,
  RedirectUriInputType,
  RedirectUriItem,
} from '@pa/components/redirect-uri-input/redirect-uri-input-item-mapper';

interface IProps {
  initialDomain: string;
  domainPattern: string;
  setStoreDomain: (domain: string) => void;
}

/**
 * The RedirectUriInput component is used in the platform application detail view to enable the admin to edit the
 * application domain according to the restrictions given by the domain pattern.
 */
const RedirectUriInput = (props: IProps) => {
  const { t } = useTranslation();
  const { initialDomain, domainPattern, setStoreDomain } = props;
  const [domain, setDomain] = useState<string[]>(getInitialDomainArray(initialDomain, domainPattern));

  const updateDomain = useCallback(
    (index: number, value: string) => {
      const newDomain = domain.slice();
      newDomain[index] = value;
      setDomain(newDomain);
      setStoreDomain(newDomain.join('.'));
    },
    [domain, setStoreDomain],
  );

  const itemsToRender = useMemo((): RedirectUriItem[] => {
    const patternParts = domainPattern.split('.');
    const domainPatternIsAsteriskOnly = isDomainPatternAsteriskOnly(domainPattern);
    const domainPatternIsTextOnly = isDomainPatternTextOnly(domainPattern);

    function getTextInputItem(index: number): RedirectUriItem {
      const textInputClassName = 'redirect-uri-text-input';
      return {
        type: RedirectUriInputType.TextInput,
        props: {
          className: clsx(
            textInputClassName,
            domainPatternIsAsteriskOnly && textInputClassName + '--single-input-field',
          ),
          value: domainPatternIsAsteriskOnly ? initialDomain : domain[index],
          onChange: (e) => updateDomain(index, e.target.value),
        },
      };
    }

    function getDropDownItem(domainPart: string, index: number): RedirectUriItem {
      const values = domainPart.slice(1, -1).split('|');
      const dropDownElements = values.map((value) => {
        return { key: value, value: value };
      });

      return {
        type: RedirectUriInputType.DropDown,
        props: {
          items: dropDownElements,
          selectedKey: domain[index],
          placeholder: t('general.chooseOption'),
          onSelect: (item) => updateDomain(index, item.value),
        },
      };
    }

    function getTextItem(text: string): RedirectUriItem {
      return {
        type: RedirectUriInputType.Text,
        props: {
          text: text,
          className: domainPatternIsTextOnly ? 'single-text-item' : '',
        },
      };
    }

    if (domainPatternIsTextOnly) {
      return [getTextItem(domain.join('.'))];
    }

    const items: RedirectUriItem[] = [];

    patternParts.forEach((part, index) => {
      let itemToAdd: RedirectUriItem;
      const redirectUriType = getRedirectUriInputType(part);

      switch (redirectUriType) {
        case RedirectUriInputType.TextInput:
          itemToAdd = getTextInputItem(index);
          items.push(getTextItem('https://'));
          break;
        case RedirectUriInputType.DropDown:
          itemToAdd = getDropDownItem(part, index);
          break;
        case RedirectUriInputType.Text:
          itemToAdd = getTextItem(domain[index]);
          break;
      }

      if (itemToAdd) {
        const isFirst: boolean = index === 0;
        !isFirst && items.push(getTextItem('.'));
        items.push(itemToAdd);
      }
    });

    return items;
  }, [domain, domainPattern, updateDomain, t, initialDomain]);

  return <div className="redirect-uri-input">{mapRedirectUriItemsToReactNodes(itemsToRender)}</div>;
};

export default RedirectUriInput;
