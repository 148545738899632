import React from 'react';

import useStore from '@/hooks/useStore';
import OneDriveStore from '@/stores/one-drive-store';
import { onDownloadAttachment } from '@/components/attachments/attachments-common';
import { FormAttachments, IFormAttachmentsProps } from '@/ui-components/wu-form/form-attachments/form-attachments';
import { Attachments } from '@/ui-components';
import { FileDescriptorDtoV2 } from '@untis/wu-rest-view-api';
import { inject } from '@/types/store';

interface IOneDriveAttachmentsProps {
  singleSelect?: boolean;
  value: FileDescriptorDtoV2[];
  readOnly?: boolean;
  onChange: (attachments: FileDescriptorDtoV2[]) => void;
  onUpload: () => Promise<FileDescriptorDtoV2[]>;
  onDownload: (attachment: FileDescriptorDtoV2) => void;
}

export const OneDriveAttachments = (
  props: Omit<IOneDriveAttachmentsProps, 'onUpload' | 'onDownload' | 'getId' | 'getName'>,
) => {
  const store = useStore(OneDriveStore);

  return (
    <Attachments
      onUpload={() => store.openOneDriveDialog(props.singleSelect)}
      onDownload={onDownloadAttachment}
      onChange={props.onChange}
      value={props.value}
      readOnly={props.readOnly}
    />
  );
};

interface IFormOneDriveAttachmentsProps extends IFormAttachmentsProps {
  singleSelect?: boolean;
}

export const FormOneDriveAttachments = (
  props: Omit<IFormOneDriveAttachmentsProps, 'onUpload' | 'onDownload' | 'getId' | 'getName'>,
) => {
  const store = useStore(OneDriveStore);

  return (
    <FormAttachments
      {...props}
      onUpload={() => store.openOneDriveDialog(props.singleSelect)}
      onDownload={onDownloadAttachment}
    />
  );
};

/*
  If another base component is rendering Attachments, it needs the correct props as well.
  This function can be used to provide those.
  (value and onChange handler still need to be passed in individually)
 */
export const getOneDriveAttachmentProps = (
  singleSelect?: boolean,
): Omit<IOneDriveAttachmentsProps, 'value' | 'onChange' | 'storageId' | 'name'> => {
  const store = inject(OneDriveStore);

  return {
    onUpload: () => store.openOneDriveDialog(!!singleSelect),
    onDownload: onDownloadAttachment,
  };
};
