import React from 'react';

import './create-calendar-entry-page.less';
import CreateCalendarEntryForm from '@/pages/calendar-entry/create-calendar-entry/create-calendar-entry-form';
/**
 *  Component that displays the CalendarEntryForm in "Create Calendar Entry Mode", so that users can
 *  create new Calendar Entries.
 */
const CreateCalendarEntryPage = () => {
  return (
    <div className="create-calendar-entry">
      <CreateCalendarEntryForm />
    </div>
  );
};

export default CreateCalendarEntryPage;
