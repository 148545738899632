import { observer } from 'mobx-react-lite';
import React, { Ref, RefObject } from 'react';
import clsx from 'clsx';

import useStore from '@/hooks/useStore';
import Keys from '@/types/keys';
import CollapseButton from '@/ui-components/navigation-bar/collapse-button/collapse-button';
import MenuItem, { MenuItemType } from '@/ui-components/navigation-bar/menu-item/menu-item';
import { IMenuUser, ISubMenuItem, NavigationBarStore } from '@/ui-components/navigation-bar/navigation-bar-store';
import SelectionIndicator from '@/ui-components/navigation-bar/selection-indicator/selection-indicator';
import UserItem from '@/ui-components/navigation-bar/user-item/user-item';

import './bottom-view.less';

interface IProps {
  user: IMenuUser;
  bottomViewMenuItems: ISubMenuItem[];
  logOutItem: ISubMenuItem;
  onLogOut: () => void;
  isBottomViewOverlap: boolean;
  selectedSubMenuItem?: ISubMenuItem;
  isUserItemVisible: boolean;
  height: number;
  ref: RefObject<HTMLDivElement> | null;
}

const BottomView: (props: IProps) => JSX.Element | null = observer(
  (props: IProps, ref: Ref<HTMLDivElement>) => {
    const store = useStore(NavigationBarStore);

    return (
      <div
        className={clsx('bottom-view', {
          'bottom-view--intersects': props.isBottomViewOverlap,
          'bottom-view--dense-mode': store.isInDenseSubMenu || store.isInDepartmentSelection,
        })}
        ref={ref}
        style={{
          height: props.height,
          minHeight: props.height,
        }}
      >
        <div className="fixed-section">
          {!(store.isInDenseSubMenu || store.isInDepartmentSelection) && <CollapseButton />}
          {props.isUserItemVisible && (
            <UserItem
              name={props.user.name}
              role={props.user.role}
              image={props.user.image}
              route={props.user.userRoute}
            />
          )}
          {props.bottomViewMenuItems.map((item) => (
            <MenuItem
              key={item.name}
              name={item.name}
              icon={item.icon}
              type={MenuItemType.BOTTOM_VIEW_ITEM}
              selected={false}
              isDense={false}
              isAccessible={true}
              onClick={item.onClick}
              onKeyDown={item.onKeyDown}
              className={clsx({
                highlight: item.highlight,
              })}
            />
          ))}
          <MenuItem
            name={props.logOutItem.name}
            selected={false}
            icon={props.logOutItem.icon}
            onClick={props.onLogOut}
            onKeyDown={(event) => {
              if (event.key !== Keys.Enter) return;
              props.onLogOut();
            }}
            type={MenuItemType.BOTTOM_VIEW_ITEM}
            isDense={false}
            testId={props.logOutItem.testId}
            isAccessible={true}
          />
          <SelectionIndicator isShownInBottomView scrollTop={0} />
        </div>
      </div>
    );
  },
  { forwardRef: true },
);

export default BottomView;
