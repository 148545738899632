import React from 'react';
import clsx from 'clsx';

import { Icon } from '@/ui-components';

import './lesson-card-substitution-tag.less';

interface IProps {
  addedDisplayName: string;
  removedDisplayName?: string;
  showTag?: boolean;
  showChange?: boolean;
  isTopElement?: boolean;
}

const LessonCardSubstitutionTag = (props: IProps) => {
  const { addedDisplayName, removedDisplayName, showChange, showTag, isTopElement } = props;

  return (
    <span
      className={clsx('lesson-card-substitution-tag', {
        'lesson-card-top-element': isTopElement,
        'show-tag': showTag,
      })}
      data-testid="lesson-card-substitution-tag"
      key={addedDisplayName}
    >
      {showChange && showTag && removedDisplayName && (
        <>
          <span data-testid="removed-resource">{removedDisplayName}</span>
          <Icon type="arrow-right-16" />
        </>
      )}
      <span data-testid="added-resource">{addedDisplayName}</span>
    </span>
  );
};

export default LessonCardSubstitutionTag;
