import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import DeprecatedDropDown, { DeprecatedDropDownItem } from '@/ui-components/deprecated-drop-down/drop-down';
import './page-header-date-picker.less';
import { formatDate } from '@/utils/date/date-util';
import { DatePicker } from '@/ui-components';
import { IDateCalendarHolidayProps } from '@/ui-components/date/calendar/date-calendar/date-calendar';

// developers need to decide, if the component should work with dates or date ranges
export enum PageDatePickerMode {
  DATE,
  DATE_RANGE,
}

// possible options in date mode
export enum DateOptionEnum {
  TODAY = 'DATE_TODAY',
  FROM_START_OF_WEEK = 'FROM_START_OF_WEEK',
  USER_SELECTION = 'USER_SELECTION_DATE', // user should pick from calendar
}

// possible options in daterange mode
export enum DateRangeOptionEnum {
  DATE_RANGE_TODAY = 'DATE_RANGE_TODAY',
  DATE_RANGE_WEEK = 'DATE_RANGE_WEEK',
  DATE_RANGE_NEXT_WEEK = 'DATE_RANGE_NEXT_WEEK',
  DATE_RANGE_MONTH = 'DATE_RANGE_MONTH',
  DATE_RANGE_SCHOOLYEAR = 'DATE_RANGE_SCHOOLYEAR',
  USER_SELECTION_DATE_RANGE = 'USER_SELECTION_DATE_RANGE', // user should pick from calendar
  CUSTOM_DATE_RANGE = 'CUSTOM_DATE_RANGE', // custom option, developers can provide (e.g. previous schoolyears)
  START_OF_WEEK = 'START_OF_WEEK',
  START_OF_MONTH = 'START_OF_MONTH',
  START_OF_SCHOOLYEAR = 'START_OF_SCHOOLYEAR',
}

export type DateRange = {
  startDate: Dayjs;
  endDate: Dayjs;
};

// use this as state variable, when working in date mode
export interface IDate {
  option: DateOptionEnum;
  date: Dayjs;
}

// use this...
export interface IDateRange {
  option: DateRangeOptionEnum;
  startDate: Dayjs;
  endDate: Dayjs;
}

// ... or this as state variable, when working in date range mode
export interface ICustomDateRange {
  startDate: Dayjs;
  endDate: Dayjs;
  name: string;
}

interface IDateModeProps {
  mode: PageDatePickerMode.DATE;
  onDateChange: (value: IDate) => void;
  options?: [DateOptionEnum, ...DateOptionEnum[]];
  disabledOptions?: [DateRangeOptionEnum, ...DateRangeOptionEnum[]];
  onCustomClick?: () => void;
  value: IDate | undefined;
  additionalLabel?: string;
  customOptionLabelKey?: string;
  canUndoSelection?: boolean;
  onUndoSelection?: () => void;
  placeholder?: string;
  holidays?: IDateCalendarHolidayProps[];
  testId?: string;
}

interface IDateRangeModeProps {
  mode: PageDatePickerMode.DATE_RANGE;
  onDateRangeChange: (value: IDateRange | ICustomDateRange) => void;
  options?: [DateRangeOptionEnum, ...DateRangeOptionEnum[]];
  disabledOptions?: [DateRangeOptionEnum, ...DateRangeOptionEnum[]];
  onCustomClick?: () => void;
  value: IDateRange | ICustomDateRange;
  currentSchoolyear?: DateRange; // <- set this if you use DateRangeOptionEnum.CURRENT_SCHOOLYEAR
  additionalLabel?: string;
  customDateRanges?: ICustomDateRange[];
  disabledCustomDateRanges?: string[];
  customOptionLabelKey?: string;
  canUndoSelection?: boolean;
  onUndoSelection?: () => void;
  placeholder?: string;
  firstDayOfWeek?: number;
  holidays?: IDateCalendarHolidayProps[];
  testId?: string;
}

export type PageDatePickerProps = IDateModeProps | IDateRangeModeProps;

// type guard work with proper prop type depending ond mode
const isDateModeProps = (props: IDateModeProps | IDateRangeModeProps): props is IDateModeProps => {
  return (props as IDateModeProps).mode === PageDatePickerMode.DATE;
};

// type guard to distinguish between date ranges and custom date ranges
const isCustomDateRange = (value: ICustomDateRange | IDateRange | IDate | undefined): value is ICustomDateRange => {
  if (!value) {
    return false;
  }
  return (value as ICustomDateRange).name !== undefined;
};

export const getDateByQuickDateOption = (option: DateOptionEnum): Dayjs | null => {
  switch (option) {
    case DateOptionEnum.TODAY:
      return dayjs();
    case DateOptionEnum.FROM_START_OF_WEEK:
      return dayjs().startOf('isoWeek');
    case DateOptionEnum.USER_SELECTION:
      return null;
  }
};

export const getDateRangeForDateRangeOptionEnum = (
  value: DateRangeOptionEnum,
  dateRange: DateRange | undefined,
  firstDayOfWeek: number | undefined,
): { startDate: Dayjs; endDate: Dayjs } | null => {
  switch (value) {
    case DateRangeOptionEnum.DATE_RANGE_TODAY:
      return {
        startDate: dayjs(),
        endDate: dayjs(),
      };
    case DateRangeOptionEnum.DATE_RANGE_WEEK: {
      return {
        startDate: dayjs().startOf('week'),
        endDate: dayjs().endOf('week'),
      };
    }
    case DateRangeOptionEnum.DATE_RANGE_NEXT_WEEK: {
      return {
        startDate: dayjs().startOf('week').add(7, 'days'),
        endDate: dayjs().startOf('week').add(13, 'days'),
      };
    }
    case DateRangeOptionEnum.DATE_RANGE_MONTH: {
      return {
        startDate: dayjs().startOf('month'),
        endDate: dayjs().endOf('month'),
      };
    }
    case DateRangeOptionEnum.DATE_RANGE_SCHOOLYEAR: {
      if (dateRange) {
        return {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        };
      }
      console.warn('No schoolyear provided');
      return null;
    }
    case DateRangeOptionEnum.START_OF_WEEK: {
      const weekStart = firstDayOfWeek ? getFirstDayOfWeek(dayjs(), firstDayOfWeek) : dayjs().startOf('week');
      return !dateRange || weekStart.isBetween(dateRange.startDate, dateRange.endDate, 'days', '[]')
        ? {
            startDate: weekStart,
            endDate: dayjs(),
          }
        : null;
    }
    case DateRangeOptionEnum.START_OF_MONTH: {
      const monthStart = dayjs().startOf('month');
      return !dateRange || monthStart.isBetween(dateRange.startDate, dateRange.endDate, 'days', '[]')
        ? {
            startDate: monthStart,
            endDate: dayjs(),
          }
        : null;
    }
    case DateRangeOptionEnum.START_OF_SCHOOLYEAR: {
      if (dateRange) {
        const today = dayjs();
        return {
          startDate: dateRange.startDate,
          endDate: today.isBetween(dateRange.startDate, dateRange.endDate, 'days', '[]') ? today : dateRange.endDate,
        };
      }
      console.warn('No schoolyear provided');
      return null;
    }
    default:
      return null;
  }
};

export const getFirstDayOfWeek = (date: Dayjs, dayOfWeek: number | undefined) => {
  const startOfWeek = date.startOf('week');
  if (dayOfWeek !== undefined) {
    return startOfWeek.day(dayOfWeek);
  }
  return startOfWeek;
};

export const parseDateRangeEnum = (value: string): DateRangeOptionEnum | undefined => {
  const dateRangeOption = DateRangeOptionEnum[value as keyof typeof DateRangeOptionEnum];
  if (dateRangeOption === undefined) {
    return undefined;
  }

  return dateRangeOption;
};

export const getDateRangeByQuickDateRangeOption = (
  option: DateRangeOptionEnum,
  dateRange?: DateRange,
  firstDayOfWeek?: number,
): DateRange | null => {
  switch (option) {
    case DateRangeOptionEnum.DATE_RANGE_TODAY:
    case DateRangeOptionEnum.DATE_RANGE_WEEK:
    case DateRangeOptionEnum.DATE_RANGE_MONTH:
    case DateRangeOptionEnum.DATE_RANGE_SCHOOLYEAR:
    case DateRangeOptionEnum.START_OF_WEEK:
    case DateRangeOptionEnum.DATE_RANGE_NEXT_WEEK:
    case DateRangeOptionEnum.START_OF_MONTH:
    case DateRangeOptionEnum.START_OF_SCHOOLYEAR:
      return getDateRangeForDateRangeOptionEnum(option, dateRange, firstDayOfWeek);
    case DateRangeOptionEnum.USER_SELECTION_DATE_RANGE:
      return null;
    case DateRangeOptionEnum.CUSTOM_DATE_RANGE:
      if (dateRange) {
        return {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        };
      }
      return null;
  }
};

const getKeyForCustomDateRange = (dateRange: ICustomDateRange): string => {
  return `${DateRangeOptionEnum.CUSTOM_DATE_RANGE.toString()}-${dateRange.name}`;
};

const getNameFromCustomDateRangeKey = (key: string): string => {
  return key.replace(`${DateRangeOptionEnum.CUSTOM_DATE_RANGE.toString()}-`, '');
};

// FOR INTERNAL USE OF PAGE HEADER ONLY! DON'T USE IT ELSEWHERE!
export const PageHeaderDatePicker = (props: PageDatePickerProps) => {
  const items: DeprecatedDropDownItem[] = [];
  const { t } = useTranslation();

  const testId: string | undefined = props.testId;
  const datePickerTestId: string | undefined = testId ? `${testId}-date-picker` : undefined;

  // create dropdown items depending on the mode
  if (props.mode === PageDatePickerMode.DATE) {
    // pseudo element, because "Custom" should stay as selectable option
    if (props.value?.option === DateOptionEnum.USER_SELECTION) {
      items.push({
        key: 'PSEUDO',
        value: t(props.customOptionLabelKey ?? 'general.custom'),
      });
    }
    if (props.options?.includes(DateOptionEnum.TODAY)) {
      items.push({
        key: DateOptionEnum.TODAY.toString(),
        value: t('general.today'),
      });
    }
    if (props.options?.includes(DateOptionEnum.FROM_START_OF_WEEK)) {
      items.push({
        key: DateOptionEnum.FROM_START_OF_WEEK.toString(),
        value: t('general.fromTheStartOfTheWeek'),
      });
    }
    if (props.options?.includes(DateOptionEnum.USER_SELECTION)) {
      items.push({
        key: DateOptionEnum.USER_SELECTION.toString(),
        value: t(props.customOptionLabelKey ?? 'general.custom'),
      });
    }
  } else {
    // pseudo element, because "Custom" should stay as selectable option
    if (!isCustomDateRange(props.value) && props.value.option === DateRangeOptionEnum.USER_SELECTION_DATE_RANGE) {
      items.push({
        key: 'PSEUDO',
        value: t('general.custom'),
      });
    }
    if (props.options?.includes(DateRangeOptionEnum.DATE_RANGE_TODAY)) {
      items.push({
        key: DateRangeOptionEnum.DATE_RANGE_TODAY.toString(),
        value: t('general.today'),
        isDisabled: !!props.disabledOptions?.includes(DateRangeOptionEnum.DATE_RANGE_TODAY),
      });
    }
    if (props.options?.includes(DateRangeOptionEnum.DATE_RANGE_WEEK)) {
      items.push({
        key: DateRangeOptionEnum.DATE_RANGE_WEEK.toString(),
        value: t('general.currentWeek'),
        isDisabled: !!props.disabledOptions?.includes(DateRangeOptionEnum.DATE_RANGE_WEEK),
      });
    }
    if (props.options?.includes(DateRangeOptionEnum.DATE_RANGE_NEXT_WEEK)) {
      items.push({
        key: DateRangeOptionEnum.DATE_RANGE_NEXT_WEEK.toString(),
        value: t('general.nextWeek'),
        isDisabled: !!props.disabledOptions?.includes(DateRangeOptionEnum.DATE_RANGE_NEXT_WEEK),
      });
    }
    if (props.options?.includes(DateRangeOptionEnum.DATE_RANGE_MONTH)) {
      items.push({
        key: DateRangeOptionEnum.DATE_RANGE_MONTH.toString(),
        value: t('general.currentMonth'),
        isDisabled: !!props.disabledOptions?.includes(DateRangeOptionEnum.DATE_RANGE_MONTH),
      });
    }
    if (props.options?.includes(DateRangeOptionEnum.DATE_RANGE_SCHOOLYEAR)) {
      items.push({
        key: DateRangeOptionEnum.DATE_RANGE_SCHOOLYEAR.toString(),
        value: t('general.currentSchoolyear'),
      });
    }
    if (props.options?.includes(DateRangeOptionEnum.START_OF_WEEK)) {
      items.push({
        key: DateRangeOptionEnum.START_OF_WEEK.toString(),
        value: t('general.startOfTheWeek'),
      });
    }
    if (props.options?.includes(DateRangeOptionEnum.START_OF_MONTH)) {
      items.push({
        key: DateRangeOptionEnum.START_OF_MONTH.toString(),
        value: t('general.startOfTheMonth'),
      });
    }
    if (props.options?.includes(DateRangeOptionEnum.START_OF_SCHOOLYEAR)) {
      items.push({
        key: DateRangeOptionEnum.START_OF_SCHOOLYEAR.toString(),
        value: t('general.startOfTheSchoolyear'),
      });
    }
    if (props.options?.includes(DateRangeOptionEnum.USER_SELECTION_DATE_RANGE)) {
      items.push({
        key: DateRangeOptionEnum.USER_SELECTION_DATE_RANGE.toString(),
        value: t(props.customOptionLabelKey ?? 'general.custom'),
      });
    }
    if (props.customDateRanges) {
      props.customDateRanges.forEach((customRange) => {
        items.push({
          key: getKeyForCustomDateRange(customRange),
          value: customRange.name,
          isDisabled: props.disabledCustomDateRanges?.includes(customRange.name),
        });
      });
    }
  }

  const selectedKey = isCustomDateRange(props.value)
    ? getKeyForCustomDateRange(props.value)
    : props.value?.option === DateRangeOptionEnum.USER_SELECTION_DATE_RANGE ||
      props.value?.option === DateOptionEnum.USER_SELECTION
    ? 'PSEUDO'
    : props.value?.option.toString();

  const handleSelectDate = (item: DeprecatedDropDownItem) => {
    const option: DateOptionEnum = item.key as DateOptionEnum;
    if (option === DateOptionEnum.USER_SELECTION) {
      props.onCustomClick && props.onCustomClick();
    } else if (props.canUndoSelection && item.key === '-1') {
      props.onUndoSelection && props.onUndoSelection();
    } else {
      const date = getDateByQuickDateOption(option);
      if (date) {
        (props as IDateModeProps).onDateChange({ option, date });
      }
    }
  };

  const handleSelectDateRange = (item: DeprecatedDropDownItem) => {
    const option: DateRangeOptionEnum = item.key as DateRangeOptionEnum;
    let firstDayOfWeek: number | undefined;
    if (!isDateModeProps(props)) {
      firstDayOfWeek = props.firstDayOfWeek;
    }

    if (option === DateRangeOptionEnum.USER_SELECTION_DATE_RANGE) {
      props.onCustomClick && props.onCustomClick();
    }
    if (option.startsWith(DateRangeOptionEnum.CUSTOM_DATE_RANGE)) {
      handleSelectCustomDateRange(item, option);
    } else {
      const dateRange = getDateRangeByQuickDateRangeOption(
        option,
        (props as IDateRangeModeProps).currentSchoolyear,
        firstDayOfWeek,
      );
      if (dateRange) {
        (props as IDateRangeModeProps).onDateRangeChange({
          option: option,
          ...dateRange,
        });
      }
    }
  };

  const handleSelectCustomDateRange = (item: DeprecatedDropDownItem, option: DateRangeOptionEnum) => {
    const dateRange = (props as IDateRangeModeProps)?.customDateRanges?.find((dateRange) => {
      return dateRange.name === getNameFromCustomDateRangeKey(item.key);
    });

    if (dateRange) {
      (props as IDateRangeModeProps).onDateRangeChange({
        option: option,
        ...dateRange,
      });
    }
  };

  const handleChangeDate = (date: Dayjs | undefined) => {
    if (date) {
      const option = DateOptionEnum.USER_SELECTION;
      (props as IDateModeProps).onDateChange({ option, date });
    } else {
      props.onUndoSelection && props.onUndoSelection();
    }
  };

  const valueString = props.value
    ? isDateModeProps(props)
      ? formatDate(props.value?.date)
      : formatDate(props.value?.startDate) +
        (props.value.endDate.isAfter(props.value.startDate, 'date') ? ' - ' + formatDate(props.value.endDate) : '')
    : '';

  const handleDateLabelClick = () => {
    props.onCustomClick && props.onCustomClick();
  };

  const handleDateRangeLabelClick = () => {
    props.onCustomClick && props.onCustomClick();
    handleSelectCustomDateRange(
      {
        key: DateRangeOptionEnum.USER_SELECTION_DATE_RANGE.toString(),
        value: t(props.customOptionLabelKey ?? 'general.custom'),
      },
      DateRangeOptionEnum.USER_SELECTION_DATE_RANGE,
    );
  };
  const useDatePicker = () => {
    return isDateModeProps(props) && !props.options;
  };

  const dropDownDatePicker = (
    <DeprecatedDropDown
      selectedKey={selectedKey}
      items={items}
      canUndoSelection={props.canUndoSelection}
      onSelect={props.mode === PageDatePickerMode.DATE ? handleSelectDate : handleSelectDateRange}
      rightAlign={true}
      searchable={false}
      testId={testId}
    />
  );

  const datePicker = (
    <DatePicker
      value={isDateModeProps(props) ? props.value?.date : undefined}
      label={props.placeholder}
      onChange={handleChangeDate}
      onOpen={() => {
        props.onCustomClick && props.onCustomClick();
      }}
      removable={true}
      holidays={props.holidays}
      testId={testId}
    />
  );

  return (
    <div className="page-header-date-picker" data-testid={datePickerTestId}>
      {useDatePicker() ? (
        ''
      ) : (
        <p
          className="value-label"
          onClick={props.mode === PageDatePickerMode.DATE ? handleDateLabelClick : handleDateRangeLabelClick}
        >
          {props.additionalLabel ? `${valueString}${props.additionalLabel}` : valueString}
        </p>
      )}
      {useDatePicker() ? datePicker : dropDownDatePicker}
    </div>
  );
};
