import ModalStore from '@/stores/modal-store';
import KeyCodes from '@/types/key-codes';
import { inject, Store } from '@/types/store';

type Callback = (e: any) => void;

interface IShortcutOptions {
  withMetaKey?: boolean;
  skipStopPropagation?: boolean;
  skipPreventDefault?: boolean;

  // if set to true, keyboard events will be fired even if a modal is currently opened
  fireOnOpenedModals?: boolean;

  // if set to true, keyboard events will be fired even if a userprompt is currently opened
  fireOnOpenedUserPrompts?: boolean;

  // if set to true, keyboard events will be fired even if the currently focused element is an input element
  fireOnSelectedInputElement?: boolean;
}

interface IShortcutEvent {
  callback: Callback;
  options?: IShortcutOptions;
}

export interface IShortcutEvents {
  [keyCode: number]: IShortcutEvent;
}

@Store()
export default class ShortcutStore {
  private globalCallbacks: Callback[] = [];
  private modalStore = inject(ModalStore);

  /**
   * Registers callback which are being called when any keyboard shortcut is successfully executed.
   */
  addGlobalCallback(callback: Callback) {
    this.globalCallbacks.push(callback);
  }

  removeGlobalCallback(callback: Callback) {
    const index = this.globalCallbacks.indexOf(callback);
    if (index >= 0) {
      this.globalCallbacks.splice(index, 1);
    }
  }

  createShortcutListener(shortcutEvents: IShortcutEvents, baseOptions?: IShortcutOptions): Callback {
    return (e: any) => {
      // either meta key or alt key must be pressed if withMetaKey is set
      if (baseOptions?.withMetaKey && !(e.metaKey || e.altKey)) {
        return;
      }

      let shortcutEvent = shortcutEvents[e.keyCode];
      if (!shortcutEvent) {
        if (shortcutEvents[KeyCodes.Other]) {
          shortcutEvent = shortcutEvents[KeyCodes.Other];
        } else {
          return;
        }
      }

      const {
        skipPreventDefault,
        skipStopPropagation,
        fireOnOpenedModals,
        fireOnOpenedUserPrompts,
        fireOnSelectedInputElement,
      }: IShortcutOptions = Object.assign({}, baseOptions, shortcutEvent.options);

      const userPromptValidation = fireOnOpenedUserPrompts || !this.modalStore.isUserPromptOpen;
      const modalValidation = fireOnOpenedUserPrompts || fireOnOpenedModals || this.modalStore.modalStack.length < 1;
      let inputValidation: boolean;
      if (!document.activeElement || fireOnSelectedInputElement) {
        inputValidation = true;
      } else {
        switch (document.activeElement.tagName.toLowerCase()) {
          case 'input':
          case 'textarea':
            inputValidation = false;
            break;
          default:
            inputValidation = true;
            break;
        }
      }

      if (!(modalValidation && inputValidation && userPromptValidation)) {
        return;
      }

      if (!skipPreventDefault) {
        e.preventDefault();
      }
      if (!skipStopPropagation) {
        e.stopPropagation();
      }

      shortcutEvent.callback(e);
      for (const callback of this.globalCallbacks) {
        callback(e);
      }
    };
  }
}
