import { observable, action } from 'mobx';
import * as React from 'react';
import { t } from 'i18next';

import { Store, inject } from '@/types/store';
import { DashboardViewApi, MessagesOfDayViewApi } from '@/stores/api-store';
import { DashboardCardDto, DashboardCardDetailDto, DashboardCardStatusDto } from '@untis/wu-rest-view-api';
import ModalStore from '@/stores/modal-store';
import MessagesOfTheDayDetail from '@today/components/messages-of-the-day-detail/messages-of-the-day-detail';
import NotificationStore from '@/stores/notification-store/notification-store';
import { showErrorResponse } from '@/utils/error-handling/error-message';
import RouterStore from '@/stores/router-store';
import RefStore from '@/stores/ref-store';
import { ExperimentalStore } from '@/stores/experimental-store';

@Store()
class TodayMessagesOfTheDayStore {
  @observable isLoading = false;
  @observable messagesOfTheDay: DashboardCardDto[] = [];
  @observable messagesOfTheDayDetails: DashboardCardDetailDto[] = [];
  @observable detailsModalOpenInSeconds = 0;

  @observable private unreadCount: number = 0;
  private modalStore = inject(ModalStore);
  private notificationStore = inject(NotificationStore);
  private routerStore = inject(RouterStore);
  private intervalId: number = -1;
  private refStore = inject(RefStore);
  private experimentalStore = inject(ExperimentalStore);

  async getMessagesOfTheDay() {
    try {
      this.isLoading = true;
      const [dashboardCards, dashboardCardsDetails] = await Promise.allSettled([
        DashboardViewApi.getDashboardCards(),
        DashboardViewApi.getDashboardCardsDetails(),
      ]);

      if (dashboardCards.status === 'fulfilled') {
        this.messagesOfTheDay = dashboardCards.value.data.dashboardCards;
      }

      if (dashboardCardsDetails.status === 'fulfilled') {
        this.messagesOfTheDayDetails = dashboardCardsDetails.value.data.dashboardCardsDetails;
      }
    } catch (error) {
      showErrorResponse(error);
    } finally {
      this.isLoading = false;
      this.refStore.archerContainerRef.current?.refreshScreen();
    }
  }

  @action
  async getUnreadMessagesOfTheDayCount() {
    const { data } = await DashboardViewApi.getDashboardCardStatus();
    this.setUnreadMessagesOfTheDayCount(data.unreadCardsCount ?? 0);
  }

  async openMessagesOfTheDayDetail(id: number) {
    this.detailsModalOpenInSeconds = 0;
    try {
      this.intervalId = window.setInterval(() => {
        this.detailsModalOpenInSeconds += 1;
      }, 1000);
      await this.modalStore.openModalDialog({
        size: 'md',
        className: 'messages-of-the-day-detail-modal',
        children: <MessagesOfTheDayDetail activeId={id} />,
      });
      this.detailsModalOpenInSeconds = 0;
    } catch (error) {
      //
    } finally {
      clearInterval(this.intervalId);
    }
  }

  @action
  async deleteMessageOfDay(id: number) {
    try {
      const shouldDelete = await this.modalStore.booleanUserPrompt({
        hasDestructiveAction: true,
        title: t('general.motdDeleteConfirmationTitle', { count: 1 }),
        children: t('general.motdDeleteConfirmationSubtitle', { count: 1 }),
        okButton: {
          label: t('general.delete'),
        },
        cancelButton: {
          label: t('general.cancel'),
        },
      });

      if (!shouldDelete) {
        return;
      }

      await MessagesOfDayViewApi.deleteMessagesOfTheDay([id]);

      this.messagesOfTheDay = this.messagesOfTheDay.filter((motd) => motd.id !== id);
      this.messagesOfTheDayDetails = this.messagesOfTheDayDetails.filter((motd) => motd.id !== id);

      if (this.messagesOfTheDayDetails.length < 1) {
        this.modalStore.closeModal();
      }

      this.messagesOfTheDay = this.messagesOfTheDay.filter((motd) => motd.id !== id);
      this.notificationStore.success({ title: t('general.motdDeleted', { count: 1 }) });
    } catch (error) {
      showErrorResponse(error);
    }
  }

  @action
  async markMessageRead(motd: DashboardCardDetailDto) {
    if (motd.status === DashboardCardStatusDto.READ) {
      return;
    }

    if (this.detailsModalOpenInSeconds < 2) {
      return;
    }

    try {
      await DashboardViewApi.markDashboardCardsAsRead(motd.id);

      this.markMessagesOfTheDayRead(motd.id);
      this.markMessagesOfTheDayDetailRead(motd.id);

      await this.getUnreadMessagesOfTheDayCount();
    } catch (error) {
      showErrorResponse(error);
    }
  }

  @action
  setUnreadMessagesOfTheDayCount = (count: number) => {
    this.unreadCount = count;
  };

  @action
  navigate(path: string) {
    this.modalStore.closeAll();
    clearInterval(this.intervalId);
    this.routerStore.routing.push(path);
  }

  markMessagesOfTheDayRead(id: number) {
    const motdIndex = this.messagesOfTheDay.findIndex((motd) => motd.id === id);
    if (motdIndex < 0) {
      return;
    }

    this.messagesOfTheDay[motdIndex].status = DashboardCardStatusDto.READ;
  }

  markMessagesOfTheDayDetailRead(id: number) {
    const motdIndex = this.messagesOfTheDayDetails.findIndex((motd) => motd.id === id);

    if (motdIndex < 0) {
      return;
    }

    this.messagesOfTheDayDetails[motdIndex].status = DashboardCardStatusDto.READ;
  }

  get badge() {
    return (this.unreadCount ?? 0) > 0 ? this.unreadCount?.toString() ?? '' : '';
  }
}

export default TodayMessagesOfTheDayStore;
