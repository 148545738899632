import { t } from 'i18next';
import { Rule } from 'antd/es/form';

export abstract class TextValidationPatterns {
  static MASTER_DATA_NAME = /^[^<>|\[\]{}]*$/;
  static EMAIL = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  static NUMBERS_ONLY = /^[0-9]*$/;
}

export interface ICommonTextInputRules {
  required?: boolean;
  isEmail?: boolean;
  pattern?: RegExp;
  maxLength?: number;
  errorLabel?: string;
}

function getPatternRule(pattern: RegExp, errorLabel: string | undefined): Rule {
  return {
    pattern: pattern,
    message: errorLabel ?? t('general.inputContainsInvalidCharacters'),
  };
}

export const getCommonTextInputRules = (rules: ICommonTextInputRules, otherRules?: Rule[]): Rule[] => {
  const rulesToReturn: Rule[] = [];

  if (rules.required) {
    rulesToReturn.push({ required: true, message: t('general.inputRequired') });
  }

  if (rules.isEmail) {
    rulesToReturn.push({
      type: 'email',
      message: t('general.form.validation.invalidEmail'),
    });
  }

  if (rules.maxLength) {
    rulesToReturn.push({
      max: rules.maxLength,
      message: t('general.inputMayContainXCharactersAtMax', {
        count: rules.maxLength,
      }),
    });
  }

  if (rules.pattern) {
    rulesToReturn.push(getPatternRule(rules.pattern, rules.errorLabel));
  }

  if (otherRules) {
    rulesToReturn.push(...otherRules);
  }

  return rulesToReturn;
};
