import React, { useState } from 'react';
import Form from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';

import { IconButton } from '../../icon-button/icon-button';

import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import { DatePicker, IDatePickerProps } from '@/ui-components/date/date-picker/date-picker';

import './form-date-range-picker.less';

type DateRangePickerMode = 'start' | 'end';

export type DateRange = {
  start?: Dayjs;
  end?: Dayjs;
};

interface IProps extends IFormItemProps<DateRange> {
  value?: DateRange;
  startLabel: string;
  endLabel: string;
  removable?: boolean;
  onChange?: (dateRange: DateRange) => void;
}

interface IDateRangePickerWrapper extends Omit<IDatePickerProps, 'value' | 'label' | 'onChange'> {
  value?: DateRange;
  startLabel: string;
  endLabel: string;
  onChange?: (dateRange?: DateRange) => void;
  initialValue?: DateRange;
}

const DateRangePickerWrapper = (props: IDateRangePickerWrapper) => {
  const [dateRange, setDateRange] = useState<DateRange>(() => {
    return {
      start: props.initialValue?.start,
      end: props.initialValue?.end,
    };
  });

  const { t } = useTranslation();

  const handleSelect = (dateRange: DateRange, mode?: DateRangePickerMode) => {
    if (mode == 'start' && dateRange.end?.isSameOrBefore(dateRange.start)) {
      dateRange.end = dateRange.start;
    }

    if (mode == 'end' && dateRange.start?.isSameOrAfter(dateRange.end)) {
      dateRange.start = dateRange.end;
    }

    setDateRange(dateRange);
    props.onChange && props.onChange(dateRange);
  };

  return (
    <div className="form-date-range-picker-container">
      <DatePicker
        value={dateRange?.start}
        label={props.startLabel}
        disabled={props.disabled}
        testId="start"
        onChange={(date) =>
          handleSelect(
            {
              start: date,
              end: dateRange?.end,
            },
            'start',
          )
        }
      />
      <DatePicker
        value={dateRange?.end}
        label={props.endLabel}
        disabled={props.disabled}
        testId="end"
        onChange={(date) =>
          handleSelect(
            {
              start: dateRange?.start,
              end: date,
            },
            'end',
          )
        }
      />
      {props.removable && (
        <IconButton
          type="shared_trash"
          ariaLabel={t('general.delete')}
          disabled={props.disabled}
          onClick={() =>
            handleSelect({
              start: undefined,
              end: undefined,
            })
          }
        />
      )}
    </div>
  );
};

export const FormDateRangePicker = (props: IProps) => {
  return (
    <Form.Item
      name={props.name}
      label={props.label}
      className="form-time-range-picker"
      rules={props.rules}
      dependencies={props.dependencies}
    >
      <DateRangePickerWrapper
        initialValue={props.initialValue}
        startLabel={props.startLabel}
        endLabel={props.endLabel}
        removable={props.removable}
        disabled={props.disabled}
      />
    </Form.Item>
  );
};
