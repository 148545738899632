import { t } from 'i18next';
import { action, computed, observable, reaction } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';

import { AbstractListViewStore } from '@/pages/master-data/common/abstract-list-view-store';
import { ElementType, Right } from '@/stores/rights-store';
import { ISearchBarOption } from '@/ui-components/search-bar/search-bar';
import {
  DateRangeOptionEnum,
  ICustomDateRange,
  IDateRange,
} from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import { ExamsReportUrlMapperStore } from '@ls/exams/overview/exams-report-url-mapper-store';
import SchoolYearStore from '@/stores/schoolyear-store';
import { inject } from '@/types/store';
import { ExamsViewApi } from '@/stores/api-store';
import { MasterDataRefDto } from '@untis/wu-rest-view-api/api';

export abstract class AbstractExamListStore<ElementListType> extends AbstractListViewStore<ElementListType> {
  protected readonly _localizedType: string = '';
  protected readonly _localizedTeacher: string = '';
  protected readonly _localizedClass: string = '';
  protected readonly _localizedSubject: string = '';

  protected examsReportUrlMapperStore: ExamsReportUrlMapperStore = inject(ExamsReportUrlMapperStore);
  private _schoolyearStore: SchoolYearStore = inject(SchoolYearStore);

  @observable protected _options: ISearchBarOption[] = [];
  @observable protected _selectedOptions: ISearchBarOption[] = [];
  @observable protected _selectedDateRange: IDateRange | ICustomDateRange = {
    option: DateRangeOptionEnum.DATE_RANGE_WEEK,
    startDate: dayjs().startOf('week'),
    endDate: dayjs().endOf('week'),
  };

  protected constructor(right: Right, rightAll: boolean) {
    super({ right: right, elementType: ElementType.ALL, rightAll: rightAll });
    this._localizedType = t('general.type');
    this._localizedClass = t('general.class');
    this._localizedTeacher = t('general.teacher');
    this._localizedSubject = t('general.subject');
    this.setDateRangeForCurrentSchoolYear();

    reaction(
      () => this._schoolyearStore.currentSchoolYear,
      () => {
        this._modalStore.closeModal();
        this.setDateRangeForCurrentSchoolYear();
      },
    );
  }

  @action
  private setDateRangeForCurrentSchoolYear() {
    if (!this._schoolyearStore.isTodayInCurrentSchoolYear()) {
      const start = dayjs(this._schoolyearStore.currentSchoolYearStart, 'YYYY-MM-DD');
      const end = dayjs(this._schoolyearStore.currentSchoolYearEnd, 'YYYY-MM-DD');
      this.setSelectedDateRange({
        option: DateRangeOptionEnum.DATE_RANGE_SCHOOLYEAR,
        startDate: start,
        endDate: end,
      });
    } else {
      this.setSelectedDateRange({
        option: DateRangeOptionEnum.DATE_RANGE_WEEK,
        startDate: dayjs().startOf('week'),
        endDate: dayjs().endOf('week'),
      });
    }
  }

  @computed
  get options(): ISearchBarOption[] {
    return this._options;
  }

  @computed
  get selectedOptions(): ISearchBarOption[] {
    return this._selectedOptions;
  }

  @action
  setSelectedOptions(options: ISearchBarOption[]) {
    this._selectedOptions = options;
  }

  @computed
  get selectedDateRange(): IDateRange | ICustomDateRange {
    return this._selectedDateRange;
  }

  @action
  setSelectedDateRange(value: IDateRange | ICustomDateRange) {
    this._selectedDateRange = value;
  }

  @action
  async getExamListFilters(start?: string, end?: string) {
    const response = await ExamsViewApi.getExamListFilterData(start, end);
    const filterData = response.data;

    const options: ISearchBarOption[] = [];

    filterData.examTypes.map((item: MasterDataRefDto) => {
      const option: ISearchBarOption = {
        id: item.id.toString(),
        label: item.displayName ?? '',
        category: this._localizedType,
      };
      options.push(option);
    });

    filterData.classes.map((item: MasterDataRefDto) => {
      const option: ISearchBarOption = {
        id: item.id.toString(),
        label: item.displayName ?? '',
        category: this._localizedClass,
      };
      options.push(option);
    });

    filterData.teachers.map((item: MasterDataRefDto) => {
      const option: ISearchBarOption = {
        id: item.id.toString(),
        label: item.displayName ?? '',
        category: this._localizedTeacher,
      };
      options.push(option);
    });

    filterData.subjects.map((item: MasterDataRefDto) => {
      const option: ISearchBarOption = {
        id: item.id.toString(),
        label: item.displayName ?? '',
        category: this._localizedSubject,
      };
      options.push(option);
    });

    this._options = [...options];
  }

  protected getSelectedOptionByCategory(optionCategory: string): number | undefined {
    const selectedFilterValue = this._selectedOptions.find(
      (selectedOption) => selectedOption.category === optionCategory,
    );
    return selectedFilterValue ? Number(selectedFilterValue.id) : undefined;
  }

  public disabledDate = (date: Dayjs): boolean => {
    return !this._schoolyearStore.isDateInCurrentSchoolYear(date);
  };

  public disabledOptions = (date: Dayjs): [DateRangeOptionEnum, ...DateRangeOptionEnum[]] | undefined => {
    return this._schoolyearStore.isDateInCurrentSchoolYear(date)
      ? undefined
      : [
          DateRangeOptionEnum.DATE_RANGE_TODAY,
          DateRangeOptionEnum.DATE_RANGE_WEEK,
          DateRangeOptionEnum.DATE_RANGE_NEXT_WEEK,
          DateRangeOptionEnum.DATE_RANGE_MONTH,
        ];
  };

  public disabledCustomDateRanges = (date: Dayjs): string[] | undefined => {
    return this._schoolyearStore.isDateInCurrentSchoolYear(date) ? undefined : [t('general.toEndOfSchoolyear')];
  };
}
