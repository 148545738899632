import React from 'react';
import { Dayjs } from 'dayjs';

import { IUserPromptComponentProps } from '@/stores/modal-store';
import { DateCalendar } from '@/ui-components';
import { IDateCalendarHolidayProps } from '@/ui-components/date/calendar/date-calendar/date-calendar';

interface IProps<T> extends IUserPromptComponentProps<T> {
  date?: Dayjs;
  disabledDate?: (date: Dayjs) => boolean;
  withSubmitButton?: boolean;
  title?: string;
  holidays?: IDateCalendarHolidayProps[];
}

/**
 *  View for modals user prompts to display a Calendar, so that the user can pick a date (Dayjs)
 *  Based on the Ant Design Date Picker and modified, to hide the input field and adapt some styles.
 */
export const DatePickerPrompt = (props: IProps<Dayjs>) => {
  const onChange = (value: Dayjs | undefined) => {
    if (value && props.promptProps) {
      props.promptProps.setPromptValue(value);

      // if the calendar does not have a submit button, submit it immediately
      if (!props.withSubmitButton) {
        props.promptProps.onSubmit();
      }
    }
  };

  const onSubmit = props.withSubmitButton
    ? () => {
        if (props.promptProps) {
          props.promptProps.onSubmit();
        }
      }
    : undefined;

  return (
    <div className="date-picker-prompt">
      <DateCalendar
        value={props.date}
        title={props.title}
        onChange={onChange}
        onSubmit={onSubmit}
        shadow={false}
        holidays={props.holidays}
        disabledDate={props.disabledDate}
      />
    </div>
  );
};
