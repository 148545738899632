import React from 'react';
import './form-label.less';
import clsx from 'clsx';
import { Form } from 'antd';

interface IProps {
  text?: string;
  centered?: boolean;
  alignRight?: boolean;
  alignLeft?: boolean;
  color?: string;
}

export const FormLabel = (props: IProps) => {
  const className = clsx('wu-form-label', {
    centered: !!props.centered,
    'align-right': !!props.alignRight,
    'align-left': !!props.alignLeft,
  });

  return (
    <Form.Item name="wu-form-label" valuePropName="value" trigger="onChange" className={className}>
      <div style={{ color: props.color }} className={className}>
        {props.text && <label>{props.text}</label>}
      </div>
    </Form.Item>
  );
};
