import { Service } from 'typedi';

@Service()
export default class LogoutBroadcastService {
  private readonly broadcastChannel: BroadcastChannel = new BroadcastChannel('logout_channel');

  private readonly logoutMessage = 'logout';

  public sendLogoutSessionEvent() {
    this.broadcastChannel?.postMessage(this.logoutMessage);
  }

  public registerHandleLogoutFromAllTabs() {
    this.broadcastChannel.onmessage = (event: MessageEvent<any>) => {
      window.setInterval(() => {
        if (event.data === this.logoutMessage) {
          window.location.reload();
        }
      }, 500);
    };
  }
}
