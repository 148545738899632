import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'clsx';
import { useTranslation } from 'react-i18next';

import { DayEntryTypeEnum } from '@untis/wu-rest-view-api/api';
import { LessonCardIndicators } from '@/components/lesson-card/components/indicator/lesson-card-indicators';
import { LessonIndicator } from '@/components/lesson-card/components/indicator/lesson-card-indicator';
import LessonCardColorBar from '@/components/lesson-card/components/color-bar/lesson-card-color-bar';
import { IFullDayEvent } from '@te/standard/stores/data/timetable-data-store';
import useStore from '@/hooks/useStore';
import { TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import { TimetableCardDisplayStore } from '@te/standard/stores/format/timetable-card-display-store';
import { TimetableUrlStore } from '@te/standard/stores/url/timetable-url-store';

import './timetable-full-day-event.less';

interface IProps {
  fullDayEvent: IFullDayEvent;
}

const TimetableFullDayEvent = observer((props: IProps) => {
  const { fullDayEvent } = props;
  const { id, title, location, groupName, type, color, isTentative, calendarName } = fullDayEvent;

  const { t } = useTranslation();
  const timetableMetaStore = useStore(TimetableMetaStore);
  const timetableCardDisplayStore = useStore(TimetableCardDisplayStore);
  const timetableUrlStore = useStore(TimetableUrlStore);

  const indicators: LessonIndicator[] = [];

  if (timetableMetaStore.timetableViewType === 'day') {
    indicators.push({
      type: 'full-day-event',
      displayName: t('timetable.indicators.fullDayEvent'),
    });
  }
  if (type === DayEntryTypeEnum.EXTERNAL_CALENDAR_EVENT) {
    indicators.push({
      type: 'external',
      displayName: calendarName ?? '',
    });
  }

  const timetableEntryDisplayProps = timetableCardDisplayStore.getTimetableEntryDisplayProps(fullDayEvent);

  const isClickable = type === DayEntryTypeEnum.EXTERNAL_CALENDAR_EVENT;

  const handleClick = () => {
    if (isClickable) {
      timetableUrlStore.navigateToPeriodDetails(fullDayEvent);
    }
  };

  const backgroundColorStyle = {
    backgroundColor: timetableEntryDisplayProps.backgroundColor,
  };

  let testId = 'full-day-event';
  if (calendarName) {
    testId = `${testId}-${calendarName}`;
  }
  testId = `${testId}-${id}`;

  return (
    <div
      className={classNames('timetable-full-day-event--container', {
        shadow: !timetableEntryDisplayProps.hideShadow,
        highlighted: timetableEntryDisplayProps.isHighlighted,
        past: timetableEntryDisplayProps.isPast,
        clickable: isClickable,
      })}
      data-testid={testId}
      style={backgroundColorStyle as React.CSSProperties}
      onClick={handleClick}
    >
      <LessonCardColorBar color={color} isStriped={isTentative} />
      <div className="timetable-full-day-event--inner">
        <span className="timetable-full-day-event--title">{title}</span>
        {timetableEntryDisplayProps.displayVariant === 'long' && (
          <>
            <span className="timetable-full-day-event--text">{location}</span>
            <span className="timetable-full-day-event--text">{groupName}</span>
          </>
        )}
      </div>
      {timetableEntryDisplayProps.showIndicators && (
        <LessonCardIndicators indicators={indicators} displayVariant={timetableEntryDisplayProps.displayVariant} />
      )}
    </div>
  );
});

export default TimetableFullDayEvent;
