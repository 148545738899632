import { Row } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import Linkify from 'linkify-react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormRow from '../../form-row/form-row';
import CalendarEntryDetailViewStore from '../calendar-entry-detail-view-store';

import CalendarEntryDetailFormStore from './calendar-entry-detail-form-store';

import EmptyWrapper from '@/components/empty-wrapper/empty-wrapper';
import useStore from '@/hooks/useStore';
import ConfigStore from '@/stores/config-store';
import {
  Button,
  DeprecatedAttachments,
  Icon,
  InlineTextInput,
  MultiTagSelect,
  Stack,
  SwitchComponent,
} from '@/ui-components';
import { isValidUrl } from '@/utils/validation/validation-util';
import { dayjsWithoutTimeDeprecated } from '@/utils/date/date-util';
import '../../calendar-entry-form.less';
import { calendarFileDescriptorDtoToAttachment } from '@/stores/one-drive-store';
import { OneDriveAttachments } from '@/components/attachments/one-drive-attachments';
import WuPageIframe from '@/components/embedded-webuntis/wu-page-iframe/wu-page-iframe';

interface IProps {
  detailViewStore: CalendarEntryDetailViewStore;
}

const CalendarEntryDetailForm = observer((props: IProps) => {
  const { t } = useTranslation();
  const configStore = useStore(ConfigStore);

  const [store, setStore] = useState<CalendarEntryDetailFormStore>(() => {
    return new CalendarEntryDetailFormStore(props.detailViewStore);
  });

  useEffect(() => {
    setStore(new CalendarEntryDetailFormStore(props.detailViewStore));
    // We really only want the store to update when the user selected another period.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.detailViewStore.selectedPeriodId, props.detailViewStore.isBlockSelected]);

  const entry = props.detailViewStore.selectedCalendarEntry;
  if (!entry) {
    return <></>;
  }

  let className = 'calendar-entry-form';
  className = className + (props.detailViewStore.isCancelled ? ' cancelled' : '');

  // 'null' is actually a value that might appear in the teachingContent for technical reasons
  const hasTeachingContentText =
    entry.teachingContent && entry.teachingContent.length > 0 && entry.teachingContent !== 'null';
  const hasTeachingContentFiles = entry.teachingContentFiles && entry.teachingContentFiles.length > 0;
  const hasHomework = entry.homeworks && entry.homeworks.length > 0;
  const canEditVideoCalls = store.canEditVideoCall;
  const hasActiveVideoCall = entry.videoCall && entry.videoCall.isActive;
  const canReadHomework = store.canReadHomework;

  let bookingLabel = t('general.booking');
  if (entry.subType?.displayName) {
    bookingLabel = bookingLabel + ' (' + entry.subType.displayName + ')';
  }

  const onVideoCallSwitchChange: SwitchChangeEventHandler = (checked) => {
    checked ? store.createVideoCall() : store.removeVideoCall();
  };

  const validateUrl = (value: string): string | true => {
    const validationErrorText = t('general.provideValidUrl');
    return isValidUrl(value) ? true : validationErrorText;
  };

  return (
    <div className={className}>
      <Row className="center-row">
        {store.showIframe ? (
          <WuPageIframe page={store.iFramePage} />
        ) : (
          <div className="form">
            <EmptyWrapper text={t('general.noFurtherDetails')} description={t('general.noFurtherDetailsForPeriod')}>
              {canEditVideoCalls && (
                <FormRow className="video-call-row" icon="online_lesson">
                  <div className="video-call-section-container">
                    <div className="video-call-title-container">
                      <label>{t('general.takesPlaceOnline')}</label>
                      <SwitchComponent
                        size="default"
                        checked={entry.videoCall && entry.videoCall.isActive}
                        onChange={onVideoCallSwitchChange}
                      />
                    </div>
                    {hasActiveVideoCall && (
                      <InlineTextInput
                        value={entry.videoCall!.videoCallUrl ? entry.videoCall!.videoCallUrl : ''}
                        onSave={store.patchVideoCall}
                        placeholder={t('general.enterUrlHere')}
                        validate={validateUrl}
                      />
                    )}
                  </div>
                </FormRow>
              )}
              {!canEditVideoCalls && hasActiveVideoCall && (
                <FormRow className="video-call-row" icon="online_lesson">
                  <div className="video-call-section-container">
                    <label>{t('general.takesPlaceOnline')}</label>
                    {entry.videoCall!.videoCallUrl && <Linkify>{entry.videoCall!.videoCallUrl}</Linkify>}
                  </div>
                </FormRow>
              )}
              {entry.booking && (
                <FormRow icon="bookings" className="booking-row">
                  <ol>
                    <label>{bookingLabel}</label>
                    <div>{entry.booking.bookingText}</div>
                    {entry.booking.id && (
                      <label
                        className="link"
                        onClick={() => {
                          store.handleBooking(entry.booking);
                        }}
                      >
                        {t('general.openBooking')}
                      </label>
                    )}
                  </ol>
                </FormRow>
              )}
              {store.canEditRooms && (
                <FormRow icon="shared_roomEntity">
                  <MultiTagSelect
                    placeholder={t('general.addRooms')}
                    value={store.roomItems}
                    onAdd={store.addRoomHandler}
                    disabled={!store.canEditRooms}
                  />
                </FormRow>
              )}
              {entry.exam && (
                <FormRow icon="examinations" className="exam-row">
                  <div className="exam-container">
                    <label>{t('general.exam') + (entry.exam.typeLongName ? ': ' + entry.exam.typeLongName : '')}</label>
                    {entry.exam.name && <span>{entry.exam.name}</span>}
                    {entry.exam.description && <span>{entry.exam.description}</span>}
                  </div>
                </FormRow>
              )}
              {entry.lessonInfo && (
                <FormRow icon="info-circle" className="note-row">
                  <InlineTextInput disabled value={entry.lessonInfo} label={t('general.lessonInfo')} />
                </FormRow>
              )}
              {entry.substText && (
                <FormRow icon="note" className="note-row">
                  <InlineTextInput disabled value={entry.substText} label={t('general.substitutionText')} />
                </FormRow>
              )}
              {hasTeachingContentText && (
                <FormRow icon="note" className="note-row">
                  <InlineTextInput
                    disabled={true}
                    value={entry.teachingContent!}
                    label={t('general.teachingContent')}
                  />
                </FormRow>
              )}
              {
                /* if there are only files for teaching content but no text we need to separately display a label */
                !hasTeachingContentText && hasTeachingContentFiles && (
                  <FormRow icon="note" className="note-row" noMargin>
                    <label>{t('general.teachingContent')}</label>
                  </FormRow>
                )
              }
              {hasTeachingContentFiles && (
                <FormRow className="attachment-row">
                  <DeprecatedAttachments
                    readOnly
                    attachments={entry.teachingContentFiles.map(calendarFileDescriptorDtoToAttachment)}
                  />
                </FormRow>
              )}
              {(entry.notesAll || store.canEditNotesAll) && (
                <FormRow icon="note" className="note-row">
                  <InlineTextInput
                    disabled={!store.canEditNotesAll}
                    value={entry.notesAll ? entry.notesAll : ''}
                    onSave={store.patchNotesAll}
                    label={t('general.infoForStudents')}
                  />
                </FormRow>
              )}
              {((entry.notesAllFiles && entry.notesAllFiles.length > 0) ||
                (store.canEditNotesAll && configStore.hasOneDrive)) && (
                <FormRow className="attachment-row">
                  <OneDriveAttachments
                    value={store.attachmentsAll && store.attachmentsAll.length > 0 ? store.attachmentsAll : []}
                    onChange={(attachments) => store.handleAddAttachmentsAll(attachments)}
                    readOnly={!store.canEditNotesAll || !configStore.hasOneDrive}
                  />
                </FormRow>
              )}
              {canReadHomework && hasHomework && (
                <FormRow icon="homework" className="note-row">
                  <div className="homework-container">
                    <Stack y="md">
                      {entry.homeworks?.map((homework) => {
                        const assignedDate = dayjsWithoutTimeDeprecated(homework.dateTime).format('L');
                        const dueDate = dayjsWithoutTimeDeprecated(homework.dueDateTime).format('L');

                        return (
                          <div key={homework.id} className="homework">
                            <label>{homework.text}</label>
                            <label className="dates">{`${assignedDate} - ${dueDate}`}</label>
                          </div>
                        );
                      })}
                    </Stack>
                  </div>
                </FormRow>
              )}
              {(entry.notesStaff || store.canEditNotesStaff) && (
                <FormRow icon="note" className="note-row">
                  <InlineTextInput
                    disabled={!store.canEditNotesStaff}
                    value={entry.notesStaff ? entry.notesStaff : ''}
                    onSave={store.patchNotesStaff}
                    label={t('general.infoForTeachers')}
                  />
                </FormRow>
              )}

              {((entry.notesStaffFiles && entry.notesStaffFiles.length > 0) ||
                (store.canEditNotesStaff && configStore.hasOneDrive)) && (
                <FormRow className="attachment-row">
                  <OneDriveAttachments
                    value={store.attachmentsStaff && store.attachmentsStaff.length > 0 ? store.attachmentsStaff : []}
                    onChange={(attachments) => store.handleAddAttachmentsStaff(attachments)}
                    readOnly={!store.canEditNotesStaff || !configStore.hasOneDrive}
                  />
                </FormRow>
              )}
            </EmptyWrapper>
          </div>
        )}
      </Row>

      {store.showFooterActionButtons && (
        <Row className="button-bar">
          <div className="button-bar-content">
            <div className="left">
              <Button
                className="previous-period-button"
                size="large"
                outline
                disabled={!store.hasPreviousPeriod}
                onClick={() => store.fetchSinglePeriod(store.singleCalendarEntry?.previousId)}
              >
                <Icon type="arrow-left" />
                {t('general.previousPeriod')}
              </Button>
              <Button
                className="next-period-button"
                size="large"
                outline
                disabled={!store.hasNextPeriod}
                onClick={() => store.fetchSinglePeriod(store.singleCalendarEntry?.nextId)}
              >
                {t('general.nextPeriod')}
                <Icon type="arrow-right" />
              </Button>
              {store.canCancel && (
                <Button size="large" outline onClick={store.handleCancel}>
                  {t('general.cancellation')}
                </Button>
              )}
              {store.canMove && (
                <Button
                  disabled={props.detailViewStore.isBlockSelected}
                  size="large"
                  outline
                  onClick={() => store.handleMove()}
                >
                  {t('general.move')}
                </Button>
              )}
              {store.canCreateExam && (
                <Button size="large" outline onClick={() => store.handleCreateExam()}>
                  {t('general.createExam')}
                </Button>
              )}
              {store.canEditExam && (
                <Button size="large" outline onClick={() => store.handleEditExam()}>
                  {t('general.editExam')}
                </Button>
              )}
            </div>
          </div>
        </Row>
      )}
    </div>
  );
});

export default CalendarEntryDetailForm;
