import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { RowProps } from 'antd';

import { Col, Row } from '@/ui-components/grid/grid';
import { WUForm } from '@/ui-components';
import {
  DepartmentRefDto,
  SubjectDto,
  SubjectGroupRefDto,
  TeacherQualificationRefDto,
} from '@untis/wu-rest-view-api/api';
import { FormColorPicker } from '@/ui-components/wu-form/form-color-picker/form-color-picker';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { FormMultiTagSelect } from '@/ui-components/wu-form/form-multi-tag-select/form-multi-tag-select';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { createMultiSelectAddHandlerWithOptions, validateForm } from '@/utils/form/form-util';
import { FormCheckboxes } from '@/ui-components/wu-form/form-checkboxes/form-checkboxes';
import { SubjectViewApi } from '@/stores/api-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { SubjectStore } from '@/pages/master-data/subject/subject-store';
import { FormSection } from '@/ui-components/wu-form/form-section/form-section';
import { FormLabel } from '@/ui-components/wu-form/form-label/form-label';
import { IFormButton } from '@/ui-components/wu-form/wu-form';
import { getResponseError, IResponseError, ResponseErrorType } from '@/utils/error-handling/error-handling';
import { IValidationError } from '@/types/validation-error';
import { TextValidationPatterns } from '@/utils/form/common-form-rules';
import FormTaggedSwitch from '@/ui-components/wu-form/form-tagged-switch/form-tagged-switch';
import { textTodo } from '@/utils/text-todo/text-todo';
import deprecatedGetFormColProps from '@md/helpers/deprecatedGetFormColProps';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import { ISelectItem } from '@/ui-components/select/select';

export interface ISubjectFormSubject {
  name: string;
  longName?: string;
  alias?: string;
  externKey?: string;
  text?: string;
  statFlags?: string;
  subjectType?: number;
  teachingQualifications?: IMultiTagSelectItem[];
  departments?: IMultiTagSelectItem[];
  subjectGroups?: IMultiTagSelectItem[];
  checkboxes?: string[];
  active?: boolean;
  frontColor?: string;
  backColor?: string;
}

const FORM_ROW_PROPS: RowProps = {
  gutter: [{ xl: 16 }, { xl: 16 }],

  style: { marginTop: '24px' },
};

interface IProps {
  subject?: SubjectDto | undefined;
  store: SubjectStore;
}

const getMultiSelectValues = (
  value: ISubjectFormSubject,
  props: IProps,
): {
  departmentDtos: DepartmentRefDto[];
  subjectGroupDtos: SubjectGroupRefDto[];
  teacherQualificationDtos: TeacherQualificationRefDto[];
} => {
  const departmentDtos: DepartmentRefDto[] = [];
  const subjectGroupDtos: SubjectGroupRefDto[] = [];
  const teacherQualificationDtos: TeacherQualificationRefDto[] = [];

  value.departments?.forEach((item: IMultiTagSelectItem) => {
    const departmentDto = props.store.departments.find((d) => d.id.toString() === item.id);
    if (departmentDto) {
      departmentDtos.push(departmentDto);
    }
  });
  value.subjectGroups?.forEach((item: IMultiTagSelectItem) => {
    const subjectGroupDto = props.store.subjectGroups.find((s) => s.id && s.id.toString() === item.id);
    if (subjectGroupDto) {
      subjectGroupDtos.push(subjectGroupDto);
    }
  });
  value.teachingQualifications?.forEach((item: IMultiTagSelectItem) => {
    const teachingQualificationDto = props.store.teachingQualifications.find((t) => t.id.toString() === item.id);
    if (teachingQualificationDto) {
      teacherQualificationDtos.push(teachingQualificationDto);
    }
  });

  return {
    departmentDtos,
    subjectGroupDtos,
    teacherQualificationDtos,
  };
};

const formSubjectToSubjectDto = (value: ISubjectFormSubject, props: IProps): SubjectDto => {
  const multiSelectValues = getMultiSelectValues(value, props);
  return {
    id: props.subject?.id,
    name: value.name,
    longName: value.longName,
    alternateName: value.alias,
    externKey: value.externKey,
    text: value.text,
    statFlags: value.statFlags,
    active: value.active,
    foreColor: value.frontColor?.replace('#', ''),
    backColor: value.backColor?.replace('#', ''),
    absenceControlNeeded: value.checkboxes?.includes('absenceCheck'),
    lessonTopicNeeded: value.checkboxes?.includes('teachingContentCompulsory'),
    subjectType: props.store.subjectTypes.find((type) => type.id == value.subjectType),
    departments: multiSelectValues.departmentDtos,
    subjectGroups: multiSelectValues.subjectGroupDtos,
    teacherQualifications: multiSelectValues.teacherQualificationDtos,
  };
};

export const SubjectForm = observer((props: IProps) => {
  const store = props.store;
  const modalStore = useStore(ModalStore);
  const notificationStore = useStore(NotificationStore);
  const { t } = useTranslation();
  const history = useHistory();
  const isInEditMode = !!props.subject;
  const disabled = isInEditMode && !store.canEdit;

  let { subject } = props;
  const teachingQualificationItems: IMultiTagSelectItem[] = store.teachingQualifications.map((qualification) => {
    return {
      id: qualification.id.toString(),
      label: qualification.displayName ? qualification.displayName : '',
    };
  });
  const departmentItems: IMultiTagSelectItem[] = store.departments.map((department) => {
    return {
      id: department.id.toString(),
      label: department.displayName ? department.displayName : '',
    };
  });
  const subjectGroupItems: IMultiTagSelectItem[] = store.subjectGroups.map((subjectGroup) => {
    return {
      id: subjectGroup.id ? subjectGroup.id.toString() : '',
      label: subjectGroup.displayName ? subjectGroup.displayName : '',
    };
  });

  const leftButtons: IFormButton[] =
    isInEditMode && store.canDelete
      ? [
          {
            label: t('general.delete'),
            onClick: () => {
              if (props.subject?.id) {
                store.deleteSubject(props.subject.id);
              }
            },
            testId: 'delete-button',
          },
        ]
      : [];

  const rightButtons: IFormButton[] = [];
  if (!isInEditMode) {
    rightButtons.push({
      label: t('general.saveAndNew'),
      type: 'primary',
      disabled: store.submitAllDisabled,
      outline: true,
      onClick: () => {
        validateForm(store.form).then((isValid) => {
          if (isValid) {
            handleSaveNewSubject(store.form.getFieldsValue(), true);
          }
        });
      },
      testId: 'save-and-new-button',
    });
  }
  rightButtons.push({
    label: disabled ? t('general.ok') : t('general.cancel'),
    onClick: () => {
      modalStore.closeModal();
    },
    testId: 'close-form-button',
  });

  const handleSaveNewSubject = (value: ISubjectFormSubject, saveAndNew: boolean) => {
    const subjectDto = formSubjectToSubjectDto(value, props);

    SubjectViewApi.createSubject(subjectDto)
      .then((result) => {
        notificationStore.success({ title: t('general.subjectCreated') });
        store.onSubjectCreated(result.data);
        store.form.resetFields();
        if (!saveAndNew) {
          modalStore.closeModal();
        }
      })
      .catch((error: AxiosError) => {
        const responseError: IResponseError = getResponseError(error);
        if (responseError.type === ResponseErrorType.VALIDATION_ERROR) {
          handleValidationError(responseError);
        } else {
          notificationStore.error({ title: t('general.subjectCouldNotBeCreated'), message: error.toString() });
        }
      });
  };

  const handleChangeSubject = (value: ISubjectFormSubject) => {
    const subjectDto = formSubjectToSubjectDto(value, props);
    SubjectViewApi.updateSubject(subjectDto)
      .then((result) => {
        notificationStore.success({ title: t('general.subjectEdited') });
        store.onSubjectChanged(result.data);
        modalStore.closeModal();
      })
      .catch((error) => {
        const responseError: IResponseError = getResponseError(error);
        if (responseError.type === ResponseErrorType.VALIDATION_ERROR) {
          handleValidationError(responseError);
        } else {
          notificationStore.error({ title: t('general.subjectCouldNotBeEdited'), message: error.toString() });
        }
      });
  };

  const handleValidationError = (error: IResponseError): void => {
    const validationErrors: IValidationError[] = error.payload as IValidationError[];
    for (const validationError of validationErrors) {
      if (validationError.path.includes('.name')) {
        store.form.setFields([{ name: 'name', errors: [t(validationError.errorMessage)] }]);
      }
    }
  };

  const initialFormCheckboxValues = [];
  if (!subject || subject.lessonTopicNeeded) {
    initialFormCheckboxValues.push('teachingContentCompulsory');
  }
  if (!subject || subject.absenceControlNeeded) {
    initialFormCheckboxValues.push('absenceCheck');
  }
  if (!subject || subject.active) {
    initialFormCheckboxValues.push('active');
  }

  const initialTeachingQualifications = subject?.teacherQualifications
    ? teachingQualificationItems.filter((qualification) =>
        subject?.teacherQualifications.map((q) => q.id.toString()).includes(qualification.id),
      )
    : [];
  const initialDepartments = subject?.departments
    ? departmentItems.filter((department) => subject?.departments.map((d) => d.id.toString()).includes(department.id))
    : [];
  const initialSubjectGroups = subject?.subjectGroups
    ? subjectGroupItems.filter((group) => subject?.subjectGroups.map((sg) => sg.id!.toString()).includes(group.id))
    : [];

  if (!subject) {
    subject = { active: true, name: '', departments: [], subjectGroups: [], teacherQualifications: [] };
  }

  const initialSubjectType: ISelectItem | undefined = subject?.subjectType?.id
    ? { id: subject.subjectType.id.toString(), label: subject?.subjectType.displayName! }
    : undefined;

  return (
    <WUForm<ISubjectFormSubject>
      form={store.form}
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      testIdSaveButton="save-button"
      fixedButtonBar={true}
      hideSubmitButton={disabled}
      onDisableChange={(disabled) => store.setSubmitAllDisabled(disabled)}
      onSubmit={isInEditMode ? handleChangeSubject : (value) => handleSaveNewSubject(value, false)}
      maxWidth
    >
      <Col {...deprecatedGetFormColProps({ span: 24 })}>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection centered text={textTodo('Status')} />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormTaggedSwitch
              name="active"
              checked={subject?.active}
              initialValue={subject?.active}
              disabled={!store.canEdit}
              onLabel={t('general.active')}
              offLabel={t('general.inactive')}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 24 })}>
            <FormSection centered text={t('general.studentDetails.personalInformation')} />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel text={'* ' + t('general.studentDetails.requiredField')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormInput
              name="name"
              label={t('general.shortName')}
              variant="floating"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              initialValue={subject?.name ? subject.name : ''}
              rules={[
                { required: true, message: t('general.inputRequired') },
                {
                  max: 20,
                  message: t('general.inputTooLong'),
                },
              ]}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormInput
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              name="longName"
              label={t('general.longName')}
              variant="floating"
              initialValue={subject.longName ?? ''}
              disabled={disabled}
              rules={[
                {
                  max: 255,
                  message: t('general.inputTooLong'),
                },
              ]}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormInput
              name="alias"
              label={t('general.aliasName')}
              variant="floating"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              initialValue={subject?.alternateName ? subject.alternateName : ''}
              disabled={disabled}
              rules={[
                {
                  max: 60,
                  message: t('general.inputTooLong'),
                },
              ]}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormInput
              name="text"
              label={t('general.text')}
              variant="floating"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              initialValue={subject?.text ? subject.text : ''}
              rules={[
                {
                  max: 255,
                  message: t('general.inputTooLong'),
                },
              ]}
              disabled={!store.canEdit}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection text={t('general.subjectType')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSingleSelect
              items={store?.subjectTypes?.map((subjectType) => {
                return {
                  id: subjectType.id ? subjectType.id.toString() : '',
                  label: subjectType.displayName || '',
                };
              })}
              placeholder={t('general.subjectType')}
              name="subjectType"
              initialValue={initialSubjectType ? initialSubjectType.id : undefined}
              disabled={disabled}
              searchable
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection text={t('general.teachingQualifications')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormMultiTagSelect
              name="teachingQualifications"
              removable
              onAdd={createMultiSelectAddHandlerWithOptions({
                form: store.form,
                name: 'teachingQualifications',
                items: teachingQualificationItems,
                title: t('general.addTeachingQualifications'),
                modalStore,
                redirectToCreate: () => history.push('/teaching-qualifications'),
              })}
              initialValue={initialTeachingQualifications}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection text={t('general.departments')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormMultiTagSelect
              name="departments"
              removable
              onAdd={createMultiSelectAddHandlerWithOptions({
                form: store.form,
                name: 'departments',
                items: departmentItems,
                title: t('general.addDepartments'),
                modalStore,
                redirectToCreate: () => history.push('/departments'),
              })}
              initialValue={initialDepartments}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection text={t('general.subjectGroups')} centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormMultiTagSelect
              name="subjectGroups"
              removable
              onAdd={createMultiSelectAddHandlerWithOptions({
                form: store.form,
                name: 'subjectGroups',
                items: subjectGroupItems,
                title: t('general.addSubjectGroups'),
                modalStore,
                redirectToCreate: () => history.push('/subject-groups'),
              })}
              initialValue={initialSubjectGroups}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection text={t('general.colors')} />
          </Col>

          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormColorPicker
              name="frontColor"
              placeholder={t('general.foregroundColor')}
              testId="subject-fore-color"
              initialValue={subject?.foreColor ? '#' + subject.foreColor : '#000000'}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormColorPicker
              placeholder={t('general.backgroundColor')}
              name="backColor"
              testId="subject-back-color"
              initialValue={subject?.backColor ? '#' + subject.backColor : '#000000'}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection text={t('general.settings')} />
          </Col>

          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormCheckboxes
              name="checkboxes"
              options={[
                {
                  label: t('general.absenceCheck'),
                  value: 'absenceCheck',
                },
                {
                  label: t('general.needsLessonTopic'),
                  value: 'teachingContentCompulsory',
                },
              ]}
              initialValue={initialFormCheckboxValues}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormSection text={t('general.externalIdAndCodes')} />
          </Col>

          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormInput
              label={t('general.externalId')}
              variant="floating"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              name="externKey"
              rules={[
                {
                  max: 100,
                  message: t('general.inputTooLong'),
                },
              ]}
              initialValue={subject?.externKey ? subject.externKey : ''}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row {...FORM_ROW_PROPS}>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormLabel centered />
          </Col>
          <Col {...deprecatedGetFormColProps({ span: 12 })}>
            <FormInput
              label={t('general.codes')}
              variant="floating"
              name="statFlags"
              rules={[
                {
                  max: 30,
                  message: t('general.inputTooLong'),
                },
              ]}
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              initialValue={subject?.statFlags ? subject.statFlags : ''}
              disabled={disabled}
            />
          </Col>
        </Row>
      </Col>
    </WUForm>
  );
});
