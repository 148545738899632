import React, { ChangeEvent, FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Popover } from 'antd';

import { DeprecatedAttachment, Button, DeprecatedAttachments, Icon, IconButton, Skeleton } from '@/ui-components';
import SendMessageViewStore from '@mg/stores/send-message-view-store';
import RecipientPickerStore from '@mg/stores/recipient-picker-store';
import useStore from '@/hooks/useStore';
import { HistoryMessages, SkeletonHistoryMessages } from '@mg/components/history-messages/history-messages';
import { MessageRecipients } from '@mg/components/message-recipients/message-recipients';
import { TestIds } from '@/testIds';
import './send-message-editor.less';

const SkeletonView = () => {
  return (
    <div className="send-message-form">
      <h1 className="subject">
        <Skeleton.Input active style={{ width: 300, marginTop: 3 }} />
      </h1>

      <div className="sender-container">
        <b className="fromTo">
          <Skeleton.Input size="small" active style={{ width: 20 }} />
        </b>
        <div className="entity-display-name">
          <Skeleton.Button size="small" shape="round" active style={{ width: 150, height: 35 }} />
        </div>
      </div>
      <div className="sender-subject-container">
        <b className="sender-subject">
          <Skeleton.Input size="small" active style={{ width: 50 }} />
        </b>
        <div className="sender-subject-input-field">
          <Skeleton.Input active style={{ width: 200 }} />
        </div>
      </div>
      <div className="send-message-form-body">
        <div className="content-textarea">
          <Skeleton.Input active size="small" style={{ display: 'block', width: 500 }} />
          <Skeleton.Input active size="small" style={{ display: 'block', width: 400, marginTop: 8 }} />
          <Skeleton.Input active size="small" style={{ display: 'block', width: 200, marginTop: 8 }} />
        </div>
        <div className="content-attachments">
          <Skeleton.Input active size="small" style={{ width: 100, marginTop: 24 }} />
        </div>
        <SkeletonHistoryMessages className="history-messages" />
      </div>
      <div className="message-actions with-border">
        <div className="message-actions__secondary">
          <Skeleton.Button shape="round" size="large" active style={{ width: 100 }} />
        </div>
        <div className="message-actions__main" style={{ display: 'flex' }}>
          <Skeleton.Button shape="round" size="large" active style={{ width: 100 }} />
          <Skeleton.Button shape="round" size="large" active style={{ width: 100, marginLeft: 8 }} />
        </div>
      </div>
    </div>
  );
};

function ErrorHint({ message }: { message: string }) {
  return (
    <Popover content={<span className="input-error">{message}</span>} placement="left">
      <Icon type="not-available" />
    </Popover>
  );
}

const SendMessageEditor = observer(() => {
  const { t } = useTranslation();
  const sendMessageViewStore = useStore(SendMessageViewStore);
  const recipientPickerStore = useStore(RecipientPickerStore);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  function handleSaveClick() {
    sendMessageViewStore.openSaveDraftDialog();
  }

  function handleCancelClick() {
    sendMessageViewStore.close();
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    sendMessageViewStore.setTouchedAll();

    if (!sendMessageViewStore.isFormValid) {
      return;
    }

    sendMessageViewStore.sendMessage();
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    sendMessageViewStore.touched.subject = true;
    sendMessageViewStore.subject = event.target.value;
  }

  function handleContentChange(event: ChangeEvent<HTMLTextAreaElement>) {
    sendMessageViewStore.touched.content = true;
    sendMessageViewStore.content = event.target.value;
  }

  function handlePlusClicked() {
    sendMessageViewStore.touched.recipients = true;
    sendMessageViewStore.showRecipientsView();
  }

  if (sendMessageViewStore.loadingMessage) {
    return <SkeletonView />;
  }

  return (
    <>
      <form className="send-message-form" onSubmit={handleSubmit}>
        <h1 className="subject">{sendMessageViewStore.subject || t('general.sendMessageNewMessage')}</h1>
        <div className="sender-container">
          <b className={clsx('fromTo', { error: sendMessageViewStore.formErrors.recipients })}>{t`general.to`}</b>
          <div className="recipients">
            <MessageRecipients
              isReply={sendMessageViewStore.isReply}
              replyRecipient={sendMessageViewStore.replyRecipient}
              recipients={recipientPickerStore.selectedMessageRecipients}
              deselectGroupById={(groupId) => recipientPickerStore.deselectGroupById(groupId)}
              deselectPersonById={(personId) => recipientPickerStore.deselectPersonById(personId)}
            />
          </div>

          {sendMessageViewStore.formErrors.recipients && (
            <ErrorHint message={sendMessageViewStore.formErrors.recipients} />
          )}
          {!sendMessageViewStore.isReply && (
            <Button
              circle
              size="large"
              outline
              onClick={handlePlusClicked}
              testId={TestIds.MESSAGES_ADD_RECIPIENT_BUTTON}
            >
              <Icon type="plus" />
            </Button>
          )}
        </div>
        <div className="sender-subject-container">
          <b
            className={clsx('sender-subject', { error: sendMessageViewStore.formErrors.subject })}
          >{t`general.messageSubject`}</b>
          <div className="sender-subject-input-field">
            {sendMessageViewStore.isReply && <span className="subjectRe">{t('general.messageRe')}</span>}
            <input
              className="sender-subject-input"
              value={sendMessageViewStore.subject}
              onChange={handleChange}
              autoFocus={!sendMessageViewStore.isReply}
              data-testid={TestIds.MESSAGES_SUBJECT_INPUT}
            />
            {sendMessageViewStore.formErrors.subject && <ErrorHint message={sendMessageViewStore.formErrors.subject} />}
          </div>
        </div>
        <div className="send-message-form-body">
          <textarea
            className="content-textarea"
            value={sendMessageViewStore.content}
            onChange={handleContentChange}
            placeholder={t('general.enterTextHere')}
            autoFocus={sendMessageViewStore.isReply}
            data-testId={TestIds.MESSAGES_CONTENT_INPUT}
          />
          {sendMessageViewStore.formErrors.content && (
            <span className="input-error">{sendMessageViewStore.formErrors.content}</span>
          )}
          {(sendMessageViewStore.blobAttachment || sendMessageViewStore.attachments.length > 0) && (
            <div className="content-attachments">
              {sendMessageViewStore.blobAttachment && <DeprecatedAttachment {...sendMessageViewStore.blobAttachment} />}
              {sendMessageViewStore.attachments.length > 0 && (
                <DeprecatedAttachments
                  singleFileUpload
                  attachments={sendMessageViewStore.attachments}
                  onAdd={() => {
                    fileInputRef.current?.click();
                  }}
                  onRemove={(id) => {
                    sendMessageViewStore.removeAttachment(id);
                  }}
                  disabled={sendMessageViewStore.savingMessage}
                />
              )}
            </div>
          )}
          {sendMessageViewStore.replyMessage && (
            <HistoryMessages
              variant="sent"
              className="history-messages"
              messages={sendMessageViewStore.replyMessage.replyHistory}
            />
          )}
        </div>

        <div className="message-actions with-border">
          <div className="message-actions__secondary">
            {!sendMessageViewStore.isReply ? (
              <Button
                outline
                size="large"
                type="secondary"
                onClick={handleSaveClick}
                loading={sendMessageViewStore.savingMessage}
                disabled={!sendMessageViewStore.isTouched || sendMessageViewStore.savingMessage}
                testId={TestIds.MESSAGES_SAVE_BUTTON}
              >
                {sendMessageViewStore.savingMessage ? t`general.saving` : t`general.save`}
              </Button>
            ) : null}
            <div className="message-actions__icons">
              {!sendMessageViewStore.isReply && sendMessageViewStore.permissions?.allowRequestReadConfirmation && (
                <IconButton
                  tooltip={{
                    title: t('general.requestReadConfirmation'),
                    placement: 'top',
                  }}
                  ariaLabel={t('general.requestReadConfirmation')}
                  type="read_receipt"
                  onClick={() => {
                    sendMessageViewStore.touched.requestConfirmation = true;
                    sendMessageViewStore.requestConfirmation = !sendMessageViewStore.requestConfirmation;
                  }}
                  className={sendMessageViewStore.requestConfirmation ? 'message-actions__icons--active' : undefined}
                  testId={TestIds.MESSAGES_REQUEST_READ_RECEIPT_BUTTON}
                />
              )}
              {!sendMessageViewStore.isReply && sendMessageViewStore.permissions?.canForbidReplies && (
                <IconButton
                  tooltip={{
                    title: t('general.forbidReply'),
                    placement: 'top',
                  }}
                  ariaLabel={t('general.forbidReply')}
                  onClick={() => {
                    sendMessageViewStore.touched.forbidReply = true;
                    sendMessageViewStore.forbidReply = !sendMessageViewStore.forbidReply;
                  }}
                  className={sendMessageViewStore.forbidReply ? 'message-actions__icons--active' : undefined}
                  type="forbid_reply_20"
                  testId={TestIds.MESSAGES_FORBID_REPLY_BUTTON}
                />
              )}
              {!sendMessageViewStore.isReply && sendMessageViewStore.isMessageToParents && (
                <IconButton
                  tooltip={{
                    title: t('general.sendCopyToStudent'),
                    placement: 'top',
                  }}
                  ariaLabel={t('general.sendCopyToStudent')}
                  onClick={() => {
                    sendMessageViewStore.touched.sendCopyToStudent = true;
                    sendMessageViewStore.copyToStudent = !sendMessageViewStore.copyToStudent;
                  }}
                  className={sendMessageViewStore.copyToStudent ? 'message-actions__icons--active' : undefined}
                  type="main-class-register"
                  testId={TestIds.MESSAGES_COPY_TO_STUDENT_BUTTON}
                />
              )}
              <IconButton
                tooltip={{
                  title: sendMessageViewStore.maxFileCountReached
                    ? t('general.fileUploadDisabledMaxFileCountReached', { count: sendMessageViewStore.maxFileCount })
                    : t('general.fileUpload', { count: sendMessageViewStore.canUploadMultipleAttachments ? 2 : 1 }),
                  placement: 'top',
                }}
                ariaLabel={t('general.fileUpload', {
                  count: sendMessageViewStore.canUploadMultipleAttachments ? 2 : 1,
                })}
                onClick={() => {
                  sendMessageViewStore.handleFileUploadClick(fileInputRef);
                }}
                type="attachment"
                testId={TestIds.MESSAGES_ATTACHMENT_BUTTON}
                disabled={sendMessageViewStore.maxFileCountReached}
              />
              <input
                className="message-actions__icons_fileUpload"
                ref={fileInputRef}
                type="file"
                multiple={sendMessageViewStore.canUploadMultipleAttachments}
                onChange={sendMessageViewStore.handleFileChange}
              />
              {sendMessageViewStore.draftMessage?.allowMessageDeletion && (
                <IconButton
                  tooltip={{
                    title: t('general.delete'),
                    placement: 'top',
                  }}
                  ariaLabel={t('general.delete')}
                  onClick={() => {
                    sendMessageViewStore.openDeleteDraftConfirmationModal(sendMessageViewStore.draftMessage?.id);
                  }}
                  className={sendMessageViewStore.copyToStudent ? 'message-actions__icons--active' : undefined}
                  type="shared_trash"
                  testId={TestIds.MESSAGES_DELETE_BUTTON}
                />
              )}
            </div>
          </div>
          <div className="message-actions__main">
            <Button
              outline
              size="large"
              type="secondary"
              onClick={handleCancelClick}
              testId={TestIds.MESSAGES_CANCEL_BUTTON}
            >
              {t`general.cancel`}
            </Button>
            <Button
              triggersSubmit
              size="large"
              type="primary"
              loading={sendMessageViewStore.sendingMessage}
              disabled={sendMessageViewStore.sendingMessage}
              testId={TestIds.MESSAGES_SEND_BUTTON}
            >
              {sendMessageViewStore.sendingMessage ? t`general.sendingMessage` : t`general.send`}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
});

export { SendMessageEditor };
