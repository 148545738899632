import { t } from 'i18next';

import { IDeprecatedFormItemValidationRule } from '@/ui-components/deprecated-form/deprecated-form-item/deprecated-form-item';

interface ICommonRules {
  required?: boolean;
  maxLength?: number;
  minLength?: number;
}

const requiredRule = <T>(fieldName?: string): IDeprecatedFormItemValidationRule<T> => {
  return {
    errorMessage: t('general.valueRequired', { name: fieldName }),
    validationCallback: (value) => !!value,
  };
};

const maximumLengthRule = <T>(max: number, fieldName?: string): IDeprecatedFormItemValidationRule<T> => {
  return {
    errorMessage: t('general.maxLength', { name: fieldName, charCount: max }),
    validationCallback: (value) => (value && typeof value === 'string' ? value.length <= max : true),
  };
};

const minimumLengthRule = <T>(min: number, fieldName?: string): IDeprecatedFormItemValidationRule<T> | undefined => {
  return {
    errorMessage: t('general.minLength', { name: fieldName, charCount: min }),
    validationCallback: (value) => (value && typeof value === 'string' ? value.length >= min : true),
  };
};

export const getCommonRules = <T>(rules: ICommonRules, fieldName?: string): IDeprecatedFormItemValidationRule<T>[] => {
  const rulesToReturn: IDeprecatedFormItemValidationRule<T>[] = [];

  if (rules.required) {
    rulesToReturn.push(requiredRule(fieldName));
  }

  if (rules.maxLength) {
    const maxRule = maximumLengthRule(rules.maxLength, fieldName);
    maxRule && rulesToReturn.push(maxRule);
  }

  if (rules.minLength) {
    const minRule = minimumLengthRule(rules.minLength, fieldName);
    minRule && rulesToReturn.push(minRule);
  }

  return rulesToReturn;
};
