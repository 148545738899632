import React, { useContext, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';

import { Page } from '@/ui-components';
import { IPageHeaderTabs } from '@/ui-components/page/page';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import TimetableGeneralSettings from '@/pages/timetable/settings/timetable-general/timetable-general-settings';
import TimetableFormatSettings from '@te/settings/timetable-format/timetable-format-settings';
import ICalFormatSettings from '@te/settings/i-cal-format/i-cal-format-settings';
import TimetableFormatMappings from '@te/settings/format-mapping/timetable-format-mappings';
import { ResponsivenessContext } from '@/context/responsiveness-context';

import './timetable-settings.less';

type TimetableSettingsTab = 'general' | 'timetable-formats' | 'format-mappings' | 'visibility-restrictions' | 'i-cal';

const WHITE_BACKGROUND: TimetableSettingsTab[] = ['general', 'format-mappings'];

const TimetableSettings = observer(() => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<TimetableSettingsTab>('general');
  const reponsivenessContext = useContext(ResponsivenessContext);

  const tabs: IPageHeaderTabs = {
    selectedTab: selectedTab,
    items: [
      {
        key: 'general',
        label: t('general.general'),
      },
      {
        key: 'timetable-formats',
        label: t('general.timetableFormats'),
      },
      {
        key: 'format-mappings',
        label: t('general.formatMappings'),
      },
      {
        key: 'visibility-restrictions',
        label: t('general.visibilityRestrictions'),
      },
      {
        key: 'i-cal',
        label: t('general.iCal'),
      },
    ],
    onTabSelected: (tab) => setSelectedTab(tab as TimetableSettingsTab),
  };

  const content = useMemo(() => {
    switch (selectedTab) {
      case 'general':
        return <TimetableGeneralSettings />;
      case 'timetable-formats':
        return <TimetableFormatSettings />;
      case 'i-cal':
        return <ICalFormatSettings />;
      case 'format-mappings':
        return <TimetableFormatMappings />;
      default:
        return <EmptyIndicator title={t('general.underConstruction')} />;
    }
  }, [selectedTab]);

  return (
    <Page
      title={t('general.timetableSettings')}
      className={classNames('timetable-settings-page', reponsivenessContext?.responsiveClassName, {
        'no-padding': WHITE_BACKGROUND.includes(selectedTab),
      })}
      tabs={tabs}
      whiteContent={WHITE_BACKGROUND.includes(selectedTab)}
    >
      {content}
    </Page>
  );
});

export default TimetableSettings;
