import React from 'react';
import clsx from 'clsx';

import { Icon } from '@/ui-components';
import { getIconForFile } from '@/ui-components/attachments/attachments';

import './deprecated-attachment.less';

export interface IDeprecatedAttachment {
  id: string;
  name: string;
  src?: string;
  storageId?: string;
  additionalHeaders?: { [key: string]: string[] };
}

export interface IAttachmentProps {
  name: string;
  hoverIcon: {
    type: string;
    onClick?: (event: React.BaseSyntheticEvent) => void;
    testId?: string;
  };
  href?: string;
  onClick?: () => void;
  compact?: boolean;
  disabled?: boolean;
}

/**
 * Can be deleted once the "DeprecatedAttachments" usages are gone
 * @param props
 * @constructor
 */
export const DeprecatedAttachment = (props: IAttachmentProps) => {
  const { name, hoverIcon } = props;

  const attachmentContent = (
    <>
      <Icon className="file-icon" type={getIconForFile(name)} />
      <span className="file-name">{name}</span>
      <Icon className="hover-icon" type={hoverIcon.type} onClick={hoverIcon.onClick} testId={hoverIcon.testId} />
    </>
  );

  const className = clsx('deprecated-attachment', {
    compact: props.compact,
  });

  return (
    <div className={className}>
      {props.href && !props.onClick ? (
        <a
          aria-disabled={props.disabled}
          className="deprecated-attachment-content"
          href={props.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {attachmentContent}
        </a>
      ) : (
        <button disabled={props.disabled} className="deprecated-attachment-content" onClick={props.onClick}>
          {attachmentContent}
        </button>
      )}
    </div>
  );
};
