import React from 'react';
import { action, computed, observable } from 'mobx';
import { t } from 'i18next';

import { inject, IStore } from '@/types/store';
import {
  ExamDto,
  ExamGradeDto,
  ExamGradesDto,
  GradeCountsDto,
  GradeDto,
  MasterDataRefDto,
} from '@untis/wu-rest-view-api/api';
import { ExamsViewApi } from '@/stores/api-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import ModalStore from '@/stores/modal-store';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import { Icon } from '@/ui-components';
import { ExamsStore } from '@ls/exams/overview/exams-store';

export interface IGradeStatisticsRow extends ITableRowKey {
  gradeId: string | undefined;
  count: number;
  displayName: string;
  weight: number;
}

export interface IExamStudentGradeRow extends ITableRowKey {
  gradeProtection: boolean;
  disadvantageCompensation: boolean;
  studentName: string;
  gradeId: string | undefined;
  note: string;
}

export interface IStudentGrade {
  gradeProtection: boolean;
  disadvantageCompensation: boolean;
  studentId: number;
  studentName: string;
  gradeId: string;
  gradeName: string;
  note: string;
}

export class GradingFormStore implements IStore {
  private readonly _examId: number;

  private modalStore: ModalStore = inject(ModalStore);
  private notificationStore: NotificationStore = inject(NotificationStore);
  private rightStore: RightsStore = inject(RightsStore);

  @observable private _exam: ExamDto | undefined = undefined;
  @observable private _examGrades: ExamGradesDto | undefined = undefined;

  @observable private _isLoading: boolean = true;
  @observable private _isEdited: boolean = false;

  @observable private _gradingSchemes: MasterDataRefDto[] = [];

  // List of actual grades, that can be used for grading
  @observable private _availableGrades: GradeDto[] = [];
  @observable private _grading: IStudentGrade[] = [];
  @observable private _totalNumberOfParticipants: number = 0;
  @observable private _examStore: ExamsStore;

  /*
    depending on, if there are grades entered for students, or not, the statistics may be calculated from the
    given grades, or directly entered, by the user.
   */
  @observable private _customGradesStatistics: Map<GradeDto, number> = new Map<GradeDto, number>();

  constructor(examId: number, examStore: ExamsStore) {
    this._examId = examId;
    this._examStore = examStore;
    this.fetchData();
  }

  @computed
  get customGradeStatisticRows(): IGradeStatisticsRow[] {
    const rows: IGradeStatisticsRow[] = [];
    this._customGradesStatistics.forEach((count, grade) => {
      rows.push({
        key: grade.id!,
        gradeId: grade.id ? grade.id.toString() : undefined,
        count: count,
        displayName: grade.displayName || '',
        weight: grade.weight || 0,
      });
    });
    return rows;
  }

  @action
  private async fetchData() {
    this._isLoading = true;
    const examResponse = await ExamsViewApi.getExam(this._examId);
    const formResponse = await ExamsViewApi.getExamForm(this._examId);
    const gradesResponse = await ExamsViewApi.getExamGrades(this._examId);

    const exam = examResponse.data;
    const examGrades = gradesResponse.data;
    this._exam = exam;
    this._examGrades = examGrades;
    this._gradingSchemes = [...(formResponse.data.gradingScales ?? [])];
    this._availableGrades = [...(gradesResponse.data.examStatistics.grades ?? [])];
    this._totalNumberOfParticipants = gradesResponse.data.examStatistics.numParticipants || 0;

    this.resetCustomGradeStatistics();
    gradesResponse.data.examStatistics.countPerGrade?.forEach((g) => {
      const grade = this.availableGrades.find((grade) => grade.id === g.grade.id);
      if (grade) {
        this._customGradesStatistics.set(grade, g.count);
      }
    });

    if (examGrades.studentGrades) {
      const studentGrades = examGrades.studentGrades;
      this._exam.students.forEach((student) => {
        const grade = studentGrades.find((studentGrade) => studentGrade.student.id === student.id);
        this._grading.push({
          studentId: student.id,
          studentName: student.displayName ?? '',
          gradeId: grade?.grade?.id.toString() || '',
          gradeName: grade?.grade?.id.toString() || '',
          note: grade?.note || '',
          gradeProtection: !!student.gradeProtection,
          disadvantageCompensation: !!student.disadvantageCompensation,
        });
      });
    } else {
      this._exam.students.forEach((student) => {
        this._grading.push({
          studentId: student.id,
          studentName: student.displayName ?? '',
          gradeId: '',
          gradeName: '',
          note: '',
          gradeProtection: !!student.gradeProtection,
          disadvantageCompensation: !!student.disadvantageCompensation,
        });
      });
    }
    this._isLoading = false;
  }

  @computed
  get computedGradesStatistics(): Map<GradeDto, number> {
    const gradesMap: Map<GradeDto, number> = new Map();

    this._availableGrades.forEach((grade) => {
      const count = this._grading.filter((g) => g.gradeId === grade.id!.toString()).length;
      gradesMap.set(grade, count);
    });

    return gradesMap;
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @computed
  get isEdited(): boolean {
    return this._isEdited;
  }

  @computed
  get exam(): ExamDto | undefined {
    return this._exam;
  }

  @computed
  get gradingSchemes(): MasterDataRefDto[] {
    return this._gradingSchemes;
  }

  @computed
  get availableGrades(): GradeDto[] {
    return this._availableGrades;
  }

  @computed
  get totalNumberOfParticipants(): number {
    return this._totalNumberOfParticipants;
  }

  @computed
  get totalNumberOfGrades(): number {
    let total = 0;
    this.gradeStatistics.forEach((count) => {
      total = total + count;
    });
    return total;
  }

  @computed
  get averageGrade(): number {
    if (this.totalNumberOfGrades === 0) {
      return 0;
    }
    let sumOfGrades = 0;
    this.gradeStatistics.forEach((count, grade) => {
      const weightedCount = count * grade.weight!;
      sumOfGrades = sumOfGrades + weightedCount;
    });
    const averageGrade = sumOfGrades / this.totalNumberOfGrades;
    return Math.round((averageGrade + Number.EPSILON) * 100) / 100;
  }

  @computed
  get examStatisticsColumns(): Columns<IGradeStatisticsRow> {
    return [
      {
        type: ColumnType.Text,
        key: 'weight',
        header: t('general.grade'),
      },
      {
        type: ColumnType.Text,
        key: 'displayName',
        header: t('general.gradeName'),
      },
      {
        type: ColumnType.Number,
        key: 'count-col',
        header: t('general.amount'),
        getValue: (row) => row.count,
        edit: {
          defaultValue: 0,
          onChange: (row, key, value) => this.updateCountsPerGradeText(row.key.toString(), value),
          onBlur: (row, key, value) => this.handleCountsPerGradeChange(row.key.toString(), value),
          min: 0,
          max: this.totalNumberOfParticipants,
          validate: this.validate,
          disabled: this.useComputedGradeStatistics,
        },
      },
    ];
  }

  @action.bound
  private validate(row: IGradeStatisticsRow, value: number): boolean {
    const statistics = new Map<GradeDto, number>(this._customGradesStatistics);
    const grade: GradeDto = this.availableGrades.find((g) => g.id!.toString() === row.gradeId)!;
    statistics.set(grade, value);
    let total = 0;
    statistics.forEach((count) => {
      total += count;
    });
    return total <= this.totalNumberOfParticipants;
  }

  @action.bound
  private updateCountsPerGradeText(gradeId: string, value: number) {
    const grade = this.availableGrades.find((g) => g.id && g.id.toString() === gradeId);
    const count = Number(value);
    if (grade && !isNaN(count)) {
      this._customGradesStatistics.set(grade, count);
    }
    this._isEdited = true;
  }

  @action.bound
  private handleCountsPerGradeChange(gradeId: string, value: number) {
    this.updateCountsPerGradeText(gradeId, value);
    const grade = this.availableGrades.find((g) => g.id!.toString() === gradeId);
    this._customGradesStatistics.set(grade!, value);
  }

  @computed
  get computedGradeStatisticsRows(): IGradeStatisticsRow[] {
    const rows: IGradeStatisticsRow[] = [];

    this.gradeStatistics.forEach((count, grade) => {
      rows.push({
        key: grade.id!,
        gradeId: grade.id!.toString(),
        count: count,
        displayName: grade.displayName || '',
        weight: grade.weight || 0,
      });
    });
    return rows;
  }

  @computed
  get examStudentGradesColumns(): Columns<IExamStudentGradeRow> {
    return [
      {
        type: ColumnType.Custom,
        key: 'studentName',
        header: t('general.student'),
        render: (row) => (
          <span className="custom__wu__table__cell">
            {row.gradeProtection && row.disadvantageCompensation && (
              <Icon png={true} type="grade_protection_and_disadvantage_compensation" />
            )}
            {row.gradeProtection && !row.disadvantageCompensation && <Icon png={true} type="grade_protection" />}
            {!row.gradeProtection && row.disadvantageCompensation && (
              <Icon png={true} type="disadvantage_compensation" />
            )}
            {row.studentName}
          </span>
        ),
      },

      {
        type: ColumnType.SingleDropDown,
        key: 'grade',
        header: t('general.grade'),
        disabled: !this.canWriteGrades,
        style: 'transparent',
        items: this._availableGrades.map((grade) => {
          return {
            id: grade.id?.toString() || '',
            label: grade.weight !== undefined ? grade.weight.toString() : '',
            alias: grade.displayName || '',
          };
        }),
        getValue: ({ gradeId }) => gradeId,
        placeholder: '...',
        allowClear: true,
        onSelect: (row, key, gradeId) => this.handleStudentGradeChange(row, gradeId),
      },
      {
        type: ColumnType.Text,
        key: 'note',
        header: t('general.remark'),
        edit: {
          defaultValue: 0,
          disabled: !this.canWriteGrades,
          getValue: (row: IExamStudentGradeRow) => row.note,
          onChange: (row, key, value) => this.updateRemark(Number(row.key), value.toString()),
        },
      },
    ];
  }

  @computed
  get examStudentGradesRows(): IExamStudentGradeRow[] {
    return this._grading.map((studentGrade) => {
      return {
        key: studentGrade.studentId,
        studentName: studentGrade.studentName,
        gradeId: studentGrade.gradeId === '-1' || studentGrade.gradeId === '' ? undefined : studentGrade.gradeId,
        note: studentGrade.note,
        disadvantageCompensation: studentGrade.disadvantageCompensation,
        gradeProtection: studentGrade.gradeProtection,
      };
    });
  }

  @action.bound
  private updateRemark(studentId: number, value: string) {
    this._grading.forEach((studentGrade) => {
      if (studentGrade.studentId === studentId) {
        studentGrade.note = value;
        this._isEdited = true;
      }
    });
  }

  @action
  private resetCustomGradeStatistics() {
    this._customGradesStatistics = new Map();
    this._availableGrades.forEach((g) => {
      this._customGradesStatistics.set(g, 0);
    });
  }

  @action
  private handleStudentGradeChange(row: IExamStudentGradeRow, gradeId: string | undefined) {
    const newGrade = gradeId ?? '';
    this.resetCustomGradeStatistics();
    this._grading.forEach((studentGrade) => {
      if (studentGrade.studentId === row.key) {
        studentGrade.gradeId = newGrade.toString();
        this._isEdited = true;
      }
    });
  }

  @action.bound
  async saveGradingScheme(schemeId: string) {
    const scheme = this._gradingSchemes.find((scheme) => scheme.id.toString() === schemeId)!;
    const examDto: ExamDto = {
      ...this._exam!,
      gradingScale: scheme,
    };

    await ExamsViewApi.updateExam(examDto)
      .then(() => {
        this._examStore.updateGradingScheme(examDto.examId, scheme);
        this.notificationStore.success({ title: t('lessons.exams.messages.examEdited') });
      })
      .catch((error) => {
        if (this._exam?.canWriteGrades === false) {
          this.notificationStore.error({
            title: t('lessons.exams.messages.examEditedError'),
            message: t('general.httpForbidden') + ' ' + t('general.contactAdmin'),
          });
        } else {
          this.notificationStore.error({
            title: t('lessons.exams.messages.examEditedError'),
            message: error.toString(),
          });
        }
      });
  }

  @action.bound
  saveExamGrades(onSaveCallback?: () => void) {
    const examGrades = this.getExamGradesDtoToUpdate();
    this._isEdited = false;
    ExamsViewApi.updateExamGrades(this._examId, examGrades)
      .then(() => {
        onSaveCallback && onSaveCallback();
        this.notificationStore.success({ title: t('lessons.exams.messages.examEdited') });
      })
      .catch((error) => {
        this.notificationStore.error({
          title: t('lessons.exams.messages.examEditedError'),
          message: error.toString(),
        });
      });
  }

  @action.bound
  private getExamGradesDtoToUpdate(): ExamGradesDto {
    const emptyMasterDataRef: MasterDataRefDto = {
      id: -1,
      displayName: '',
      shortName: '',
      longName: '',
    };
    const studentGrades: ExamGradeDto[] = this._grading.map((studentGrade) => {
      const grade = this._availableGrades.find((grade) => grade.id?.toString() === studentGrade.gradeId);
      let gradeMasterDataDto = { ...emptyMasterDataRef };
      if (grade) {
        gradeMasterDataDto = {
          id: grade.id || -1,
          displayName: grade.displayName || '',
          shortName: grade.displayName || '',
          longName: grade.displayName || '',
        };
      }
      return {
        student: {
          id: studentGrade.studentId,
          displayName: studentGrade.studentName,
        },
        grade: gradeMasterDataDto,
        note: studentGrade.note,
      };
    });
    const examStatistics = this._examGrades!.examStatistics;
    const countPerGrade: GradeCountsDto[] = [];
    this.gradeStatistics.forEach((count, grade) => {
      countPerGrade.push({
        count: count,
        grade: {
          id: grade.id!,
          displayName: grade.displayName,
          shortName: grade.displayName!,
          longName: grade.displayName!,
        },
      });
    });
    return {
      studentGrades: studentGrades,
      examStatistics: {
        ...examStatistics,
        averageGrade: this.averageGrade,
        numParticipantsWithGrade: this.totalNumberOfGrades,
        countPerGrade: countPerGrade,
      },
    };
  }

  @action.bound
  async checkForUnsaved(): Promise<boolean> {
    if (!this.isEdited) {
      return true;
    }
    return await this.modalStore.booleanUserPrompt({
      title: t('general.discardChanges'),
      children: t('general.allUnsavedChangesAreLost'),
      okButton: {
        label: t('general.discard'),
      },
      cancelButton: {
        label: t('general.cancel'),
      },
    });
  }

  @computed
  get canReadStatistics(): boolean {
    return this.rightStore.canRead(Right.EXAMSTATISTICS, ElementType.ALL, false);
  }

  @computed
  get canReadGrades(): boolean {
    return this.rightStore.canRead(Right.PERFORMANCEASSESSMENT, ElementType.ALL, false);
  }

  @computed
  get useComputedGradeStatistics(): boolean {
    if (!this._exam?.canWriteGrades || !this.rightStore.canWrite(Right.EXAMSTATISTICS, ElementType.ALL, false)) {
      return false;
    }

    return this._grading.filter((g) => g.gradeId !== '').length > 0;
  }

  @computed
  get gradeStatistics(): Map<GradeDto, number> {
    return this.useComputedGradeStatistics ? this.computedGradesStatistics : this._customGradesStatistics;
  }

  @computed
  get canWriteGrades(): boolean {
    return (
      !!this._exam?.canWriteGrades && this.rightStore.canWrite(Right.PERFORMANCEASSESSMENT, ElementType.ALL, false)
    );
  }
}
