import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { DeprecatedDropDownItemElement } from '@/ui-components/deprecated-drop-down/components/deprecated-drop-down-item';
import { useDropDownUIHelper } from '@/ui-components/deprecated-drop-down/hooks/use-dropdown-ui-helper';
import { useDropDown } from '@/ui-components/deprecated-drop-down/hooks/use-dropdown';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import { ITestComponentProps } from '@/types/test-component-props';
import { Icon } from '@/ui-components';

import './drop-down.less';

/** *
 * @deprecated in favor of Single-/MultiDropDown
 */
export type DeprecatedDropDownItem = {
  key: string;
  value: string;
  alias?: string;
  isDisabled?: boolean;
};

type CommonProps = {
  onClose?: () => void;
  disabled?: boolean;
  placeholder?: string;
  canUndoSelection?: boolean;
  selectedDark?: boolean;
  borderLess?: boolean;
  staticWidth?: boolean;
  searchable?: boolean;
  className?: string;
  rightAlign?: boolean;
  open?: boolean;
  items: DeprecatedDropDownItem[];
  style?: 'default' | 'transparent';
};

type ConditionalProps =
  | {
      selectedKey: string | undefined;
      multiple?: never;
      onSelect?: (item: DeprecatedDropDownItem) => void;
      onChange?: (selectedKey: string) => void;
      shorten?: never;
      translation?: never;
    }
  | {
      selectedKey: string[] | undefined;
      multiple: true;
      onSelect?: (item: DeprecatedDropDownItem) => void;
      onChange?: (selectedKey: string[]) => void;
      shorten?: boolean;
      translation?: never;
    }
  | {
      selectedKey: string[] | undefined;
      multiple: true;
      onSelect?: (item: DeprecatedDropDownItem) => void;
      onChange?: (selectedKey: string[]) => void;
      shorten?: boolean; // this will make sure only the first item is shown and all
      // additional items are appended with + X {translation}, will be the default after FP-68 refactoring
      translation: {
        shorten: string; // X more
      };
    };

/** *
 * @deprecated in favor of Single-/MultiDropDown
 */
export type IDeprecatedDropDownProps = ITestComponentProps & CommonProps & ConditionalProps;

/** *
 * @deprecated in favor of Single-/MultiDropDown
 */
const DeprecatedDropDown = (props: IDeprecatedDropDownProps) => {
  const { t } = useTranslation();

  const {
    isOpen,
    filter,
    setHelperStyle,
    getHelperWidth,
    setPreSelectedItem,
    preSelectedItem,
    scrollableRef,
    toggleOpen,
    setFilter,
    showScrollbar,
    inputRef,
    zIndexInitial,
    helperStyle,
    outsideClickRef,
    setIsOpen,
  } = useDropDownUIHelper(props);

  const {
    isFilterResultEmpty,
    isAnItemSelected,
    placeholderItems,
    propItems,
    selectableItems,
    selectedItemsValues,
    selectedKeysString,
    selectedValuesString,
    inputPlaceholder,
  } = useDropDown({
    filter,
    props,
  });

  const [isHovered, setIsHovered] = useState<boolean>(false);

  useComponentDidMount(() => {
    setHelperStyle({ width: `${getHelperWidth()}px` });
  });

  const handleItemSelect = useCallback(
    (item: DeprecatedDropDownItem) => {
      props.onSelect?.(item);
      if (props.multiple) {
        props.onChange?.([item.key]);
      } else {
        props.onChange?.(item.key);
      }
    },
    [props],
  );

  const handleDelete = useCallback(() => {
    if (filter.length === 0 && props.canUndoSelection) {
      handleItemSelect(placeholderItems[0]);
      setPreSelectedItem(undefined);
    }
  }, [filter.length, handleItemSelect, placeholderItems, props.canUndoSelection, setPreSelectedItem]);

  const handleItemPreSelect = useCallback((item: DeprecatedDropDownItem) => {
    setPreSelectedItem(item);
  }, []);

  const preSelectNextItem = useCallback(
    (currentIndex: number) => {
      if (props.canUndoSelection && preSelectedItem?.key === '-1') {
        return;
      }

      if (currentIndex < selectableItems.length - 1) {
        setPreSelectedItem(selectableItems[currentIndex + 1]);
        if (scrollableRef.current) {
          const domItems = scrollableRef.current.getElementsByTagName('*');
          scrollableRef.current.scrollTop = domItems[currentIndex + 1].clientTop;

          const target = domItems[currentIndex + 1] as HTMLElement;
          if (target) {
            scrollableRef.current.scrollTop = target.offsetTop - 32;
          }
        }
      } else if (props.canUndoSelection && preSelectedItem?.key !== '-1') {
        // setPreSelectedItem(placeholderItem);
      }
    },
    [preSelectedItem?.key, props.canUndoSelection, scrollableRef, selectableItems, setPreSelectedItem],
  );

  const preSelectPreviousItem = useCallback(
    (currentIndex: number) => {
      if (props.canUndoSelection && preSelectedItem?.key === '-1') {
        setPreSelectedItem(selectableItems[selectableItems.length - 1]);
      } else if (currentIndex > 0) {
        setPreSelectedItem(selectableItems[currentIndex - 1]);
        if (scrollableRef.current) {
          const domItems = scrollableRef.current.getElementsByTagName('*');
          scrollableRef.current.scrollTop = domItems[currentIndex - 1].clientTop;

          const target = domItems[currentIndex - 1] as HTMLElement;
          if (target) {
            scrollableRef.current.scrollTop = target.offsetTop - 32;
          }
        }
      }
    },
    [preSelectedItem?.key, props.canUndoSelection, scrollableRef, selectableItems, setPreSelectedItem],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Escape') {
        toggleOpen();
        return;
      }

      if (selectableItems.length === 0) {
        return;
      }

      if (!preSelectedItem) {
        setPreSelectedItem(selectableItems[0]);
        return;
      }

      const currentIndex = selectableItems.findIndex((item) => item.key === preSelectedItem.key);

      if (e.key === 'ArrowUp') {
        preSelectPreviousItem(currentIndex);
      } else if (e.key === 'ArrowDown') {
        preSelectNextItem(currentIndex);
      } else if (e.key === 'Enter' || e.key === 'Tab') {
        if (preSelectedItem.isDisabled === undefined || !preSelectedItem.isDisabled) {
          handleItemSelect(preSelectedItem);

          if (!props.multiple) {
            toggleOpen();
          }
        }
      } else if (e.key === 'Backspace' || e.key === 'Delete') {
        handleDelete();
      }
    },
    [
      handleDelete,
      handleItemSelect,
      preSelectNextItem,
      preSelectPreviousItem,
      preSelectedItem,
      props.multiple,
      selectableItems,
      setPreSelectedItem,
      toggleOpen,
    ],
  );

  const handleInputClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter(e.target.value);
      if (selectableItems.length === 0) {
        setPreSelectedItem(undefined);
      } else {
        setPreSelectedItem(selectableItems[0]);
      }
    },
    [selectableItems, setFilter, setPreSelectedItem],
  );

  let selectedClassName = 'dropdown-container-layer-selected-item';
  selectedClassName = selectedClassName + (isAnItemSelected ? '' : ' placeholder');

  return (
    <div className={clsx('dropdown-wrapper', props.className)} data-testid={props.testId}>
      <div
        ref={outsideClickRef}
        className={clsx('dropdown-container-layer', {
          'is-open': isOpen,
          'static-width': props.staticWidth,
          'show-scrollbar': showScrollbar,
          'reset-z-index': zIndexInitial,
          disabled: !!props.disabled,
          'selected-dark': props.multiple
            ? !!props.selectedDark && isAnItemSelected
            : !!props.selectedDark && isAnItemSelected && !isOpen,
          'dropdown--borderless': props.borderLess,
          'right-align': props.rightAlign,
        })}
        role="dropdown-container-layer"
        onClick={() => toggleOpen()}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        {...(isOpen ? {} : { tabIndex: 0 })}
        onKeyDown={(event) => {
          if (
            preSelectedItem === undefined ||
            preSelectedItem.isDisabled === undefined ||
            !preSelectedItem.isDisabled
          ) {
            if (event.key === 'Enter') {
              toggleOpen();
            }
          }
          if (event.key === 'Tab' && isOpen) {
            setIsOpen(false);
          }
        }}
      >
        <div className="dropdown-container-layer-items">
          <div
            className={clsx('first-line-helper', {
              disabled: !!props.disabled,
              'style-transparent': props.style === 'transparent',
            })}
          >
            {isOpen && props.searchable ? (
              <fieldset>
                <input
                  placeholder={inputPlaceholder}
                  ref={inputRef}
                  onClick={handleInputClick}
                  onKeyDown={handleKeyDown}
                  onChange={handleInputChange}
                />
              </fieldset>
            ) : (
              <span
                className={clsx(selectedClassName, 'dropdown-multi-container-layer-selected-item', {
                  placeholder: !isAnItemSelected,
                })}
                key={selectedKeysString}
              >
                {selectedValuesString}
              </span>
            )}
          </div>
          <div className="other-items-helper"></div>
          <div ref={scrollableRef} className="other-items">
            {/* render selected item so we can inherit it's width */}
            <div className="drop-down-static-helper-wrapper">
              <span>{selectedValuesString}</span>
              {propItems.map((item) => (
                <DeprecatedDropDownItemElement
                  key={item.key}
                  item={item}
                  preSelectedItem={preSelectedItem}
                  handleItemPreSelect={handleItemPreSelect}
                  handleItemSelect={handleItemSelect}
                  multiple={props.multiple}
                  isSelected={selectedItemsValues.includes(item.value)}
                  testIdSuffix="static-helper"
                />
              ))}
            </div>
            {isFilterResultEmpty ? (
              // stop event propagation so that the dropdown stays open when the user clicks the "no-results" hint
              <span className="no-results" onClick={(e) => e.stopPropagation()}>
                {t('general.noSearchResults')}
              </span>
            ) : (
              selectableItems.map((item) => (
                <DeprecatedDropDownItemElement
                  key={item.key}
                  item={item}
                  preSelectedItem={preSelectedItem}
                  handleItemPreSelect={handleItemPreSelect}
                  handleItemSelect={handleItemSelect}
                  multiple={props.multiple}
                  isSelected={selectedItemsValues.includes(item.value)}
                  isDisabled={item.isDisabled}
                />
              ))
            )}
          </div>
        </div>
        {isHovered && isAnItemSelected && props.canUndoSelection ? (
          <Icon
            onClick={(e) => {
              e.stopPropagation();
              handleItemSelect(placeholderItems[0]);
            }}
            className="dropdown-close-icon"
            type="cancel-circle-filled"
            testId="drop-down-reset-btn"
          />
        ) : (
          <Icon type="dropdown" />
        )}
      </div>
      <div style={helperStyle} className="drop-down-helper" />
    </div>
  );
};

DeprecatedDropDown.defaultProps = {
  searchable: true,
};

export default DeprecatedDropDown;
