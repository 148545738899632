import React from 'react';

import { IFilter } from '@/ui-components/filter-bar/filter/filter';
import { IFilterItem } from '@/ui-components/filter-bar/filter/deprecatedFilter';
import { MultiDropDown } from '@/ui-components/drop-down/drop-down';
import './filter.less';
import { ToggleFilter } from '@/ui-components/filter-bar/filter/toggle-filter';

export interface IMultiDropdownFilterProps extends IFilter<string[]> {
  items: IFilterItem[];
  placeholder?: string;
  /*
  If a multi select only has one item, a toggle filter can achieve the same. Set this to true if you want the user
  to see a toggle filter instead of a dropdown filter in this case.
 */
  useToggleIfHasOnlyOneItem?: boolean;
  testId?: string;
}

export const MultiDropdownFilter = (props: IMultiDropdownFilterProps) => {
  if (props.useToggleIfHasOnlyOneItem && props.items.length === 1) {
    const firstItem = props.items[0];
    return (
      <ToggleFilter
        label={firstItem.label}
        value={props.value.length === 1 && props.value[0] === firstItem.id}
        onChange={(value) => {
          props.onChange(value ? [firstItem.id] : []);
        }}
        testId={props.testId}
      />
    );
  }

  return (
    <div className="new-filter">
      <MultiDropDown
        items={props.items}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        testId={props.testId}
        maxDisplayedItems={1}
        style="dark"
        searchable
      />
    </div>
  );
};
