import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PlatformDetailViewStore from '../../stores/platform-detail-view-store';

import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { ICellTextStyle } from '@/ui-components/wu-table/wu-table';
import { WUTable } from '@/ui-components';
import useStore from '@/hooks/useStore';
import { TestIds } from '@/testIds';

export interface IAccessDetailsRowData {
  key: string;
  name: string;
  value: string;
}

export interface IApiPermissionRowData {
  key: string;
  name: string;
  description: string;
}

const PlatformDetailDataInterfaceIntegrationSection = observer(() => {
  const { t } = useTranslation();
  const platformDetailViewStore = useStore(PlatformDetailViewStore);

  const columnTextStyle: ICellTextStyle = {
    bold: true,
    color: 'gray',
  };

  const apiPermissionColumns: Columns<IApiPermissionRowData> = [
    {
      type: ColumnType.Text,
      key: 'name',
      header: t('general.name').toUpperCase(),
      textStyle: columnTextStyle,
    },
    {
      type: ColumnType.Text,
      key: 'description',
      header: t('general.description').toUpperCase(),
      textStyle: columnTextStyle,
    },
  ];

  const accessDetailsColumns: Columns<IAccessDetailsRowData> = [
    {
      type: ColumnType.Text,
      key: 'name',
      header: t('general.name').toUpperCase(),
      textStyle: columnTextStyle,
    },
    {
      type: ColumnType.Text,
      key: 'value',
      header: t('general.value').toUpperCase(),
      textStyle: columnTextStyle,
    },
  ];

  return (
    <section data-testid={TestIds.PLATFORM_APPLICATION_DATA_INTEGRATION_SECTION} className="data-interface-section">
      <label className="title">{t('general.dataInterfaceIntegration')}</label>
      <div data-testid="access-details" className="data-interface-section-element access-details">
        <label>{t('platform.accessDetails.title')}</label>
        <WUTable<IAccessDetailsRowData>
          shadow={false}
          columns={accessDetailsColumns}
          rowData={platformDetailViewStore.accessDetailsRows}
          verticalAlign="top"
        />
      </div>
      <div data-testid="api-permissions" className="data-interface-section-element api-permissions">
        <label>{t('general.apiPermissions')}</label>
        <WUTable<IApiPermissionRowData>
          shadow={false}
          columns={apiPermissionColumns}
          rowData={platformDetailViewStore.apiPermissionRows}
          verticalAlign="top"
        />
      </div>
    </section>
  );
});

export default PlatformDetailDataInterfaceIntegrationSection;
