import * as React from 'react';
import { ReactElement } from 'react';
import { Menu } from 'antd';

import './horizontal-menu.less';
import { Icon } from '@/ui-components';

export interface IHorizontalMenuItem<T> {
  label: string;
  key: T;
  icon?: string;
}

export interface IHorizontalMenuProps<T> {
  menuItems: IHorizontalMenuItem<T>[];
  // if true, disable the items and show a loading indicator on hover
  isLoading?: boolean;
  // keys of menuItems
  selectedKeys: (string | number)[];
  onTabSelect?: (key: T) => void;
  testId?: string;
}

/**
 * A horizontal menu that can be used for sub-navigation.
 * Use an Enum for the generic type to stay typesafe while working with menu item keys
 */
export function HorizontalMenu<T extends string | number>(props: IHorizontalMenuProps<T>) {
  const menuItems: (ReactElement | null)[] = props.menuItems
    .map((item) => {
      const onClick = () => {
        props.onTabSelect && props.onTabSelect(item.key);
      };

      const testId = props.testId ? `${props.testId}-${item.key}` : undefined;

      return (
        <Menu.Item data-testid={testId} key={item.key} disabled={props.isLoading} onClick={onClick}>
          {item.label}
          {item.icon && <Icon type={item.icon} />}
        </Menu.Item>
      );
    })
    .filter((item) => item !== null);

  return (
    <div className="horizontal-menu">
      <Menu selectedKeys={props.selectedKeys.map((k) => k.toString())} mode="horizontal">
        {menuItems}
      </Menu>
    </div>
  );
}

export default Menu;
