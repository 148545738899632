import { observable } from 'mobx';

import { IStore, Store } from '@/types/store';

const REPORTS_BASE_URL = 'WebUntis/reports.do';

@Store()
export class ReportUrlMapperStore implements IStore {
  @observable isLoading: boolean = false;

  getReportUrl(reportName: string | undefined, reportFormat: string, reportParameters: string | undefined): string {
    return `${REPORTS_BASE_URL}?name=${reportName}&format=${reportFormat}${reportParameters}`;
  }
}
