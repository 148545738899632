import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isSafari } from 'react-device-detect';

import PlatformStore from '../../stores/platform-store';

import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import CalendarEntryDetailViewStore from '@/pages/calendar-entry/calendar-entry-detail-view/calendar-entry-detail-view-store';
import OpenApplicationInNewTabView from '@pa/components/open-application-in-new-tab-view/open-application-in-new-tab-view';
import './platform-application-iframe.less';
import useStore from '@/hooks/useStore';
import { PlatformApplicationUrlDtoViewTypeEnum } from '@untis/wu-rest-view-api';
import { CalendarEntryDetailsTab } from '@/pages/calendar-entry/calendar-entry-detail-view/calendar-entry-detail-tab-meta';

interface IProps {
  itemId: number;
  detailViewStore: CalendarEntryDetailViewStore;
}

interface ISelectedTab {
  tabType: CalendarEntryDetailsTab;
  itemId: number;
}

const PlatformApplicationIframe = observer((props: IProps) => {
  const platformStore = useStore(PlatformStore);
  const [hasAlreadyBeenSelected, setHasAlreadyBeenSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRender, setShouldRender] = useState(true);
  const { t } = useTranslation();
  const previousTabRef = useRef<ISelectedTab>();
  const item = props.detailViewStore.getPlatformApplicationMenuItem(props.itemId);
  const selectedItemId = props.detailViewStore.selectedPlatformApplicationId;
  const selectedTab = props.detailViewStore.selectedTab;
  const openInNewTab = item?.openInNewTab || isSafari; // in Safari we cannot integrate via IFrame
  const hasPlatformTabChanged =
    previousTabRef.current?.tabType !== selectedTab || previousTabRef.current?.itemId !== selectedItemId;
  const isPlatformTabSelected = selectedTab === CalendarEntryDetailsTab.PLATFORM_APPLICATION;

  function onFadeOutEnd() {
    setShouldRender(false);
  }

  function updatePreviousTabRef() {
    previousTabRef.current = {
      tabType: selectedTab,
      itemId: selectedItemId ?? 0,
    };
  }

  useEffect(() => {
    updatePreviousTabRef();
  });

  if (!openInNewTab && isPlatformTabSelected && hasPlatformTabChanged && selectedItemId === item!.id) {
    platformStore.trackPlatformApplicationUsage(item!.id, PlatformApplicationUrlDtoViewTypeEnum.DETAIL);
    updatePreviousTabRef();
  }

  if (item && item.id !== selectedItemId && !hasAlreadyBeenSelected) {
    return <></>;
  } else if (!hasAlreadyBeenSelected) {
    setHasAlreadyBeenSelected(true);
    setIsLoading(true);
  }

  if (openInNewTab) {
    if (item?.id === selectedItemId && isPlatformTabSelected) {
      return (
        <OpenApplicationInNewTabView
          name={item?.name ?? ''}
          platformApplicationId={item != null ? item?.id : 0}
          url={item?.redirectUrl ?? ''}
        />
      );
    } else {
      return <></>;
    }
  } else {
    let className = 'platform-application-iframe';

    if (!item || item.id !== selectedItemId || !isPlatformTabSelected) {
      className += ' platform-application-iframe--hide';
    }

    return (
      <div className={className}>
        <iframe
          title={item?.name}
          src={item?.redirectUrl}
          allow={`clipboard-read; clipboard-write self ${item?.redirectUrl}`}
          onLoad={() => setIsLoading(false)}
        />
        <EmptyIndicator
          title={t('general.loading')}
          animationArgs={{
            onFadeOutEnd,
            shouldRender,
            fadeOut: !isLoading,
          }}
          fullScreen={true}
          white
        />
      </div>
    );
  }
});

export default PlatformApplicationIframe;
