import React, { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOutsideClick } from '@/hooks/useClickOutside';
import { IconButton } from '@/ui-components';

import './sidebar.less';

export interface ISidebarProps {
  title?: string;
  content?: React.ReactNode;
  isOpen?: boolean;
  overlap?: boolean;
  children?: ReactNode;
  handleClose?: () => void;
  disableOutsideClick?: boolean;
  /* This will be used instead of "handleClose" if the "onClose" attribute of the ISidebarDefinition is set.
   * See SidebarStore */
  onClose?: () => void;
  size?: 'md' | 'lg';
  testId?: string;
  withoutPadding?: boolean;
  // for testing purpose, so that the tests don't have to await the transition - don't use that
  transitionDurationMs?: number;
}

const Sidebar = (props: ISidebarProps) => {
  const transitionDurationMs = props.transitionDurationMs ?? 250; // should be the same as in the LESS file
  const contentRef: RefObject<HTMLDivElement> = useRef(null);
  const [isFullyClosed, setIsFullyClosed] = useState(true);
  const { t } = useTranslation();
  const testId = props.testId ? `${props.testId}-sidebar` : undefined;
  const maskTestId = testId ? `${testId}-mask` : undefined;

  const ref = useOutsideClick((event) => {
    /*
      In case of the Dropdown, even though, the dropdown is clearly inside, an outside click is detected, if the
      clear button is clicked.
      The reason seems to be, that the icon node is removed from the dom, immediately, before the hook is evaluated.

      Using stopPropagation() on the event does not work, because the "use outside click" handler is
      called before the click handler.

      to work around this issue for the dropdown, we check if the classname of the target is "clear-all-icon"
      and ignore the click in this case.
     */
    if (event.target) {
      const node = event.target as any;
      if (node.className === 'clear-all-icon') {
        return;
      }
    }
    if (!props.disableOutsideClick) {
      if (!props.isOpen) {
        return;
      }
      if (props.onClose) {
        props.onClose();
      } else if (props.handleClose) {
        props.handleClose();
      }
    }
  });

  useEffect(() => {
    if (props.isOpen && contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [props.isOpen]);

  // Workaround: When the sidebar is fully closed (= we wait for the close transition duration) we set its content
  // wrapper width to 0 and hide the overflow, to prevent potential scrolling issues caused by embedded
  // 3rd party applications. (see WU-12304)
  useEffect(() => {
    if (props.isOpen) {
      setIsFullyClosed(false);
    } else {
      setTimeout(() => {
        setIsFullyClosed(true);
      }, transitionDurationMs);
    }
  }, [props.isOpen]);

  return (
    <div
      className={`untis-sidebar
      ${props.overlap ? ' untis-sidebar_overlap' : ''}
      ${props.isOpen ? ' untis-sidebar_opened' : ''}
      ${props.size === 'lg' ? ' untis-sidebar_large' : ''}
      ${props.withoutPadding ? ' without-padding' : ''}
      ${isFullyClosed ? ' is-fully-closed' : ''}`}
      data-testid={testId}
    >
      <div className="untis-sidebar_target">{props.children}</div>
      {/* "useOutsideClick" does not work for the iframe that is embedding WU
      -> therefore we render a mask above it so the sidebar can close when the user clicks in
      the iframe area */}
      {props.isOpen && <div data-testid={maskTestId} className="untis-sidebar-mask" />}
      <div className="untis-sidebar_content_wrapper">
        <div className="untis-sidebar_content-container" ref={ref}>
          {props.title && (
            <div className="untis-sidebar_header">
              <div className="untis-sidebar_heading">
                {props.title && <h2>{props.title}</h2>}
                <IconButton ariaLabel={t('general.closeSidebar')} type="cancel-gray" onClick={props.onClose} />
              </div>
            </div>
          )}
          <div className="untis-sidebar_content" ref={contentRef}>
            {props.content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
