import React from 'react';
import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import { AxiosError } from 'axios';

import { ExcuseStatusForm, IExcuseStatusFormData } from './excuse-status-form';

import { IDeprecatedSearchBarOption } from '@/ui-components/deprecated-search-bar/deprecated-search-bar';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import { booleanSorting } from '@/utils/sorting/sorting-util';
import { ElementType, Right } from '@/stores/rights-store';
import { IListViewAction, IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import { createChunks } from '@/utils/array/array-util';
import { CustomOrderDto, ExcuseStatusDto } from '@untis/wu-rest-view-api/api';
import { ExcuseStatusViewApi } from '@/stores/api-store';
import { AbstractListViewStore } from '@/pages/master-data/common/abstract-list-view-store';
import { matchesAllSearches } from '@/utils/filtering/filtering-util';
import { inject, Store } from '@/types/store';
import RouterStore from '@/stores/router-store';

export interface IExcuseStatusRow extends ITableRowKey {
  id: number;
  name: string;
  longName: string;
  active: boolean;
  excused: boolean;
  key: number;
}
@Store()
export class ExcuseStatusStore extends AbstractListViewStore<IExcuseStatusFormData> {
  @observable private _excuseStatusListDto: ExcuseStatusDto[] = [];
  @observable private _selectedOptions: IDeprecatedSearchBarOption[] = [];
  private editId?: number;
  private routerStore = inject(RouterStore);

  constructor() {
    super({ right: Right.MASTERDATA, elementType: ElementType.EXCUSE_STATUS });
    this.fetchData();
  }

  @action
  async fetchData() {
    ExcuseStatusViewApi.readAll().then((response) => {
      this._excuseStatusListDto = response.data.statusList;
      this.setIsDataLoading(false);
    });
  }

  @action
  setEditId(editId: string) {
    this.editId = editId ? Number(editId) : undefined;
  }

  @action
  async openEditForm(editRow?: IExcuseStatusRow) {
    const row = editRow ?? this.excuseStatusRows.find((row) => row.id === this.editId);

    if (!row) {
      return;
    }

    const excuse = this.getExcuseStatusByRowId(row.id.toString());
    this.form?.resetFields();
    if (excuse) {
      try {
        await this._modalStore.openModalDialog({
          title: `${t('general.excuseStatus')} ${excuse.longName} (${excuse.name})`,
          size: 'md',
          children: <ExcuseStatusForm store={this} excuseStatus={excuse} />,
          onAfterClose: () => this.routerStore.redirect('/excuse-status'),
          containsForm: true,
        });
      } catch (error) {
        // noop
      }
    }
  }

  @computed
  get excuseStatusRows(): IExcuseStatusRow[] {
    return this._excuseStatusListDto.map((status) => ({
      id: status.id!,
      name: status.name,
      longName: status.longName,
      active: status.active,
      excused: status.excused,
      key: status.id!,
    }));
  }

  @computed
  get columns(): Columns<IExcuseStatusRow> {
    const cols: Columns<IExcuseStatusRow> = [
      {
        type: ColumnType.Text,
        header: t('general.name'),
        key: 'name',
        sorter: (a, b) => {
          return a.name.localeCompare(b.name);
        },
      },
      {
        type: ColumnType.Text,
        header: t('general.longName'),
        key: 'longName',
        sorter: (a, b) => {
          return a.longName.localeCompare(b.longName);
        },
      },

      {
        key: 'excused',
        type: ColumnType.Boolean,
        header: t('general.excuseCounts'),
        sorter: (a, b) => booleanSorting(a.excused, b.excused),
      },

      {
        type: ColumnType.Tag,
        header: t('general.active'),
        tags: (row) =>
          row.active
            ? [{ text: t('general.active'), color: 'green' }]
            : [{ text: t('general.inactive'), color: 'grey' }],
        key: 'active',
        sorter: (a, b) => booleanSorting(a.active, b.active),
      },
    ];
    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.HoverActions,
        key: 'actions',
        width: this.columnActions.length * 45 + 'px',
        actionIcons: this.columnActions,
      });
    }

    return cols;
  }

  private mapRowToSearchString(row: IExcuseStatusRow): string {
    let res = '';
    res += row.name;
    res += row.longName;
    return res.toLocaleLowerCase();
  }

  @computed
  get filteredRows(): IExcuseStatusRow[] {
    return this.excuseStatusRows.filter((row) =>
      matchesAllSearches(this.mapRowToSearchString(row), this.selectedFreeTextOptions),
    );
  }

  @action
  setSelectedOptions(options: IDeprecatedSearchBarOption[]) {
    this._selectedOptions = options;
  }

  @action
  onExcuseStatusChanged(formValue: IExcuseStatusFormData, rowValue: IExcuseStatusRow) {
    return ExcuseStatusViewApi.update(rowValue.id, this.mapToDto(formValue, rowValue)).then((response) => {
      const cachedDto = this._excuseStatusListDto.find((status) => status.id === rowValue.id);
      if (cachedDto) {
        Object.assign(cachedDto, response.data);
      }
    });
  }

  @action
  async createExcuseStatus(formValue: IExcuseStatusFormData): Promise<any> {
    return ExcuseStatusViewApi.create(this.mapToDto(formValue)).then((response) => {
      this._excuseStatusListDto = [...this._excuseStatusListDto, response.data];
    });
  }

  @action
  onExcuseStatusDeleted(deletedExcuseStatusIds: number[]) {
    this._excuseStatusListDto = this._excuseStatusListDto.filter(
      (s) => s.id && !deletedExcuseStatusIds.some((idToDelete) => s.id === idToDelete),
    );
  }

  deleteExcuseStatus(id: number) {
    this._modalStore
      .openDeletePrompt(t('general.deleteExcuseStatusQuestionmark'), t('general.excuseDeleteConfirmation'))
      .then((result) => {
        if (result) {
          ExcuseStatusViewApi._delete(id)
            .then(() => {
              this._notificationStore.success({ title: t('general.excuseStatusDeleted') });
              this._modalStore.closeModal();
              this.onExcuseStatusDeleted([id]);
            })
            .catch((error) => {
              const axiosError: AxiosError = error as AxiosError;
              if (axiosError.response?.status === 422) {
                this._notificationStore.error({
                  title: t('general.excuseStatusCouldNotBeDeleted'),
                  message: t('general.excuseStatusInUse'),
                });
              } else {
                this._notificationStore.error({
                  title: t('general.excuseStatusCouldNotBeDeleted'),
                  message: error.toString(),
                });
              }
            });
        }
      })
      .catch((error) => {
        this._notificationStore.error({
          title: t('general.excuseStatusCouldNotBeDeleted'),
          message: error.toString(),
        });
      });
  }

  getExcuseStatusByRowId = (id: string): IExcuseStatusRow | undefined => {
    return this.excuseStatusRows.find((excuseStatus) => excuseStatus.id?.toString() === id);
  };

  @action.bound
  openCreateForm() {
    this.form.resetFields();
    this._modalStore.openModalDialog({
      children: <ExcuseStatusForm store={this} />,
      title: t('general.newExcuseStatus'),
      size: 'md',
      onAfterClose: () => this.routerStore.redirect('/excuse-status'),
      containsForm: true,
    });
  }

  @action
  onCheckboxChanged(row: IExcuseStatusRow, key: keyof IExcuseStatusRow | string, value: boolean) {
    const cachedDto = this._excuseStatusListDto.find((status) => status.id === row.id);
    const dtoToUpdate: ExcuseStatusDto = Object.assign({}, cachedDto, { [key]: value });
    ExcuseStatusViewApi.update(dtoToUpdate.id!, dtoToUpdate).then((response) => {
      if (cachedDto) {
        Object.assign(cachedDto, response.data);
      }
    });
  }

  @computed
  get sortSave(): (order: CustomOrderDto[]) => void {
    return (order) => {
      ExcuseStatusViewApi.order(order).then(() => {
        this.fetchData();
      });
    };
  }

  @computed
  get sortReset(): () => void {
    return () => {
      ExcuseStatusViewApi.resetOrder().then(() => {
        this.fetchData();
      });
    };
  }

  @computed
  private get columnActions() {
    const actions = [];
    if (this.canEdit) {
      actions.push({
        tooltip: t('general.edit'),
        onClick: (row: IExcuseStatusRow) => this.openEditForm(row),
        icon: 'edit',
        href: (row: IExcuseStatusRow) => `/excuse-status/edit/${row.id}`,
      });
    }

    if (this.canDelete) {
      actions.push({
        tooltip: t('general.delete'),
        href: undefined,
        onClick: (row: IExcuseStatusRow) => this.deleteExcuseStatus(row.id),
        icon: 'shared_trash',
      });
    }
    return actions;
  }

  @computed
  get selectedOptions(): IDeprecatedSearchBarOption[] {
    return this._selectedOptions;
  }

  @computed
  get listViewActions(): IListViewAction[] {
    const actions: IListViewAction[] = [];
    if (this.canCreate) {
      actions.push({
        label: t('general.new'),
        href: '/excuse-status/new',
        onClick: this.openCreateForm,
      });
    }
    return actions;
  }

  mapToDto(formValue: IExcuseStatusFormData, rowValue?: IExcuseStatusRow): ExcuseStatusDto {
    return {
      id: rowValue?.id || undefined,
      name: formValue.name,
      longName: formValue.longName,
      active: formValue.active,
      excused: formValue.excused,
    };
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<IExcuseStatusRow>[] | undefined {
    return this.canDelete
      ? [
          {
            label: t('general.delete'),
            icon: 'shared_trash',
            onClick: (rows) => {
              this._modalStore
                .openDeletePrompt(
                  t('general.deleteExcuseStatusQuestionmark_plural'),
                  t('general.exuseStatusBulkDeleteConfirmation', {
                    count: rows.length,
                  }),
                )
                .then((result) => {
                  if (result) {
                    const chunks = createChunks<IExcuseStatusRow>(rows, 100);
                    chunks.forEach((chunk) => {
                      ExcuseStatusViewApi.deleteMultiple(chunk.map((row) => row.id))
                        .then(() => {
                          this._notificationStore.success({
                            title: t('general.excuseStatusDeleted'),
                          });
                          this.onExcuseStatusDeleted(chunk.map((row) => row.id));
                        })
                        .catch((error) => {
                          const axiosError: AxiosError = error as AxiosError;
                          if (axiosError.response?.status === 422) {
                            this._notificationStore.error({
                              title: t('general.someExcuseStatusCouldNotBeDeleted'),
                              message: t('general.excuseStatusInUse'),
                            });
                          } else {
                            this._notificationStore.error({
                              title: t('general.someExcuseStatusCouldNotBeDeleted'),
                              message: error.toString(),
                            });
                          }
                        });
                    });
                  }
                });
            },
          },
        ]
      : undefined;
  }
}
