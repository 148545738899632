import { IFrameHandleMessagePayload } from '@/components/embedded-webuntis/embedded-webuntis';
import {
  ClassRegisterOverviewStore,
  IAbsence,
} from '@/pages/class-register/class-register-overview/class-register-overview-store';

interface IHomeworkPayload {
  id: number;
  homework: string;
  date: number;
  dueDate: number;
  lessonId: number;
  deleted: boolean;
}

interface IAbsencesPayload {
  modified: Array<IAbsence>;
  removedIds: Array<number>;
}

export const crOverviewStorePostMsgHandler = (
  store: ClassRegisterOverviewStore,
): ((e: IFrameHandleMessagePayload | undefined) => void) => {
  function updateHomework(hw: IHomeworkPayload) {
    if (hw.deleted) {
      store.removeHomework(hw.id);
    } else {
      store.saveOrUpdateHomework({ id: hw.id, homework: hw.homework, date: hw.date, dueDate: hw.dueDate }, hw.lessonId);
    }
  }

  function updateAbsences(absences: IAbsencesPayload) {
    absences.modified && absences.modified.length > 0 && store.updateAbsencesInStore(absences.modified);
    absences.removedIds && absences.removedIds.length > 0 && store.removeAbsencesFromStore(absences.removedIds);
  }

  return function (msgPayload: IFrameHandleMessagePayload | undefined) {
    if (msgPayload && msgPayload.payload) {
      msgPayload.payload.periodId &&
        msgPayload.payload.topic !== undefined &&
        store.updateLessonTopic(msgPayload.payload.periodId, msgPayload.payload.topic);
      msgPayload.payload.homework && msgPayload.payload.homework.id && updateHomework(msgPayload.payload.homework);
      msgPayload.payload.absences && updateAbsences(msgPayload.payload.absences);
      msgPayload.payload.periodId &&
        msgPayload.payload.isAbsencesChecked !== undefined &&
        store.updateAbsencesChecked(msgPayload.payload.periodId, !!msgPayload.payload.isAbsencesChecked);
    }
  };
};
