import { observer } from 'mobx-react-lite';
import React from 'react';

import useStore from '@/hooks/useStore';
import PlatformDetailViewStore from '@pa/stores/platform-detail-view-store';
import { PlatformApplicationConfigurationTranslation } from '@pa/utils/platform-application-translations';
import PlatformConfigurationTabs from '@pa/components/platform-configuration-tabs/platform-configuration-tabs';
import './platform-configuration-tabs.less';
import { TestIds } from '@/testIds';

const PlatformConfigurationMenuTabs = observer(() => {
  const platformDetailViewStore = useStore(PlatformDetailViewStore);

  return (
    <PlatformConfigurationTabs
      testId={TestIds.PLATFORM_APPLICATION_CONFIGURATION_MENU_TAB}
      urlInformationList={platformDetailViewStore.menuUrlInformations}
      tabsTitle={PlatformApplicationConfigurationTranslation.menuConfigurations}
      tabsDescription={PlatformApplicationConfigurationTranslation.menuConfigurationsDescription}
      additionalContainerClass="main-menu-configurations"
      tabLabelName={PlatformApplicationConfigurationTranslation.menuName}
    />
  );
});

export default PlatformConfigurationMenuTabs;
