import React, { BaseSyntheticEvent } from 'react';

import './amount-indicator.less';
import { Button, Icon } from '@/ui-components';
export interface IAmountIndicatorProps {
  amount?: number;
  amountTotal?: number;
  amountLabel?: string;
  empty?: boolean;
  emptyLabel?: string;
  fulfilled?: boolean;
  fulfilledLabel?: string;
  onClick?: (event: BaseSyntheticEvent) => void;
  testId?: string;
  width?: string | number;
}

export const AmountIndicator = (props: IAmountIndicatorProps) => {
  const success =
    !props.empty && (props.fulfilled || (props.amountTotal !== undefined && props.amountTotal === props.amount));

  const amountTestId = props.testId ? `${props.testId}-amount` : undefined;
  const counterTestId = props.testId ? `${props.testId}-counter` : undefined;
  const totalTestId = props.testId ? `${props.testId}-total` : undefined;
  const labelTestId = props.testId ? `${props.testId}-label` : undefined;

  return (
    <Button
      testId={props.testId}
      outline={!success}
      onClick={props.onClick}
      style={{ width: props.width }}
      className={`amount-indicator${success ? ' amount-fulfilled' : ''}${props.empty ? ' amount-empty' : ''}`}
    >
      {props.empty ? (
        <span data-testid={labelTestId}>{props.emptyLabel}</span>
      ) : props.fulfilled ? (
        <div className="amount-indicator_label_with_icon">
          <Icon type="check" className="untis-icon-check-style" data-testid={labelTestId} /> {props.fulfilledLabel}
        </div>
      ) : (
        <div className="amount-indicator_counter">
          <span className="amount-indicator_counter-amount" data-testid={counterTestId}>
            {props.amount}
          </span>
          {props.amountTotal && (
            <>
              {' '}
              <div className="amount-indicator_counter-divider" />{' '}
              <span className="amount-indicator_counter-amount-total" data-testid={totalTestId}>
                {props.amountTotal}
              </span>
            </>
          )}
          {props.amountLabel && (
            <span className="amount-indicator_counter-amount-label" data-testid={amountTestId}>
              {props.amountLabel}
            </span>
          )}
        </div>
      )}
    </Button>
  );
};
