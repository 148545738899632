import React, { useState } from 'react';
import clsx from 'clsx';
import { t } from 'i18next';

import './tagged-switch.less';

import { SwitchComponent } from '@/ui-components';

export type TaggedSwitchProps = {
  checked?: boolean;
  onChange?: (value: boolean) => void;
  offLabel?: string;
  onLabel?: string;
  testId?: string;
  disabled?: boolean;
  defaultChecked?: boolean;
};
const TaggedSwitch = ({
  checked,
  onChange,
  offLabel = t('general.no'),
  onLabel = t('general.yes'),
  testId,
  disabled,
  defaultChecked,
}: TaggedSwitchProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked ?? false);
  const label = isChecked ? onLabel : offLabel;

  return (
    <div
      data-testid={testId}
      className={clsx('untis__tagged__switch', !isChecked && 'switched--off')}
      defaultChecked={defaultChecked}
    >
      {!!label && <label className="untis__tagged__switch__label">{label}</label>}
      <SwitchComponent
        disabled={disabled}
        onChange={() => {
          const newVal = !isChecked;
          onChange && onChange(newVal);
          setIsChecked(newVal);
        }}
        checked={isChecked}
      />
    </div>
  );
};

export default TaggedSwitch;
