import React, { Fragment } from 'react';

import Seperator from '../mg-seperator/mg-seperator';

import { IncomingMessageRefDto, SentMessageRefDto } from '@untis/wu-rest-view-api';

import './entity-display-name.less';

interface IProps {
  className?: string;
  message: Pick<IncomingMessageRefDto, 'sender'> | Pick<SentMessageRefDto, 'recipientPersons' | 'recipientGroups'>;
}

/**
 * Component for showing the displayname of an entity of a message. Could be the displayName of the sender or the
 * recipient names concatenated together.
 */
const EntityDisplayName = ({ className, message }: IProps) => {
  let entityDisplayName: string | React.ReactElement;
  if ('sender' in message) {
    entityDisplayName = message.sender.displayName;
  } else {
    const recipientGroupNames = message.recipientGroups?.map((g) => g.displayName) || [];
    const recipientNames = message.recipientPersons?.map((r) => r.displayName) || [];
    const entityNames: string[] = [...recipientGroupNames, ...recipientNames];

    entityDisplayName = (
      <>
        {entityNames.map((displayName, index) => (
          <Fragment key={displayName}>
            {index > 0 && <Seperator />}
            {displayName}
          </Fragment>
        ))}
      </>
    );
  }

  return <div className={'entity-display-name' + (className ? ' ' + className : '')}>{entityDisplayName}</div>;
};
export default EntityDisplayName;
