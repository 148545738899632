import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import {
  ITimeRangePickerTimeSlot,
  TimeRangePickerMode,
} from '@/ui-components/time-range-picker/time-range-picker-store';
import TimeRangePicker from '@/ui-components/time-range-picker/time-range-picker';
import { DatePicker } from '@/ui-components';
import { IDateCalendarHolidayProps } from '@/ui-components/date/calendar/date-calendar/date-calendar';

import './date-time-range-picker.less';

export interface IDateTimePickerProps {
  startDateTime: Dayjs;
  endDateTime: Dayjs;
  onDateTimeRangeChange?: (start: Dayjs, end: Dayjs, mode?: TimeRangePickerMode) => void;
  onlySameDay?: boolean;
  disabled?: boolean;
  disabledDate?: (date: Dayjs) => boolean;
  timeSlots?: ITimeRangePickerTimeSlot[];
  holidays?: IDateCalendarHolidayProps[];
}

const DateTimeRangePicker = observer((props: IDateTimePickerProps) => {
  const { t } = useTranslation();

  const [start, setStart] = useState<Dayjs>(props.startDateTime);
  const [end, setEnd] = useState<Dayjs>(props.endDateTime);

  useEffect(() => {
    setStart(props.startDateTime);
    setEnd(props.endDateTime);
  }, [props.startDateTime, props.endDateTime]);

  const handleTimeRangeChange = (startTime: Dayjs, endTime: Dayjs, mode: TimeRangePickerMode) => {
    let validatedStartTime = startTime;
    let validatedEndTime = endTime;
    const newStart = createDateTime(start, startTime);
    const newEnd = createDateTime(end, endTime);

    // depending on which TimeRangePicker initiated the change, we need to validate
    if (mode === TimeRangePickerMode.Start) {
      if (newStart.isAfter(newEnd)) {
        validatedEndTime = startTime;
      }
    } else {
      if (newEnd.isBefore(newStart)) {
        validatedStartTime = endTime;
      }
    }

    const validatedStart = createDateTime(start, validatedStartTime);
    const validatedEnd = createDateTime(end, validatedEndTime);

    setStart(newStart);
    setEnd(newEnd);
    props.onDateTimeRangeChange && props.onDateTimeRangeChange(validatedStart, validatedEnd, mode);
  };

  const createDateTime = (date: Dayjs, time: Dayjs) => {
    let dateTime: Dayjs = dayjs();
    dateTime = dateTime.year(date.year());
    dateTime = dateTime.month(date.month());
    dateTime = dateTime.date(date.date());
    dateTime = dateTime.hour(time.hour());
    dateTime = dateTime.minute(time.minute());
    dateTime = dateTime.second(0);
    dateTime = dateTime.millisecond(0);
    return dateTime;
  };

  const handleStartDateChange = (startDate: Dayjs) => {
    const newStart = createDateTime(startDate, start);
    let newEnd = end;

    if (props.onlySameDay) {
      newEnd = createDateTime(startDate, end);
    }

    if (newStart.isAfter(end)) {
      if (props.onlySameDay) {
        newEnd = createDateTime(startDate, end);
      } else {
        newEnd = newStart;
      }

      setEnd(newEnd);
      props.onDateTimeRangeChange && props.onDateTimeRangeChange(newStart, newEnd, TimeRangePickerMode.Start);
    } else {
      setStart(newStart);
      props.onDateTimeRangeChange && props.onDateTimeRangeChange(newStart, newEnd, TimeRangePickerMode.Start);
    }
  };

  const handleEndDateChange = (endDate: Dayjs) => {
    const newEnd = createDateTime(endDate, end);
    if (newEnd.isBefore(start)) {
      const newStart = newEnd;
      setStart(newStart);
      props.onDateTimeRangeChange && props.onDateTimeRangeChange(newStart, newEnd, TimeRangePickerMode.End);
    } else {
      setEnd(newEnd);
      props.onDateTimeRangeChange && props.onDateTimeRangeChange(start, newEnd, TimeRangePickerMode.End);
    }
  };

  return (
    <div className="date-time-picker">
      <div className="date-time-picker-row">
        <div className="date-time-start-date">
          <DatePicker
            disabled={props.disabled}
            disabledDate={(date) => props.disabledDate?.(date) ?? false}
            label={t('general.start') + ' ' + t('general.date')}
            value={start}
            onChange={(date) => handleStartDateChange(date!)}
            holidays={props.holidays}
          />
        </div>
        <div className="date-time-start-time">
          <TimeRangePicker
            label={t('general.startTime')}
            startTime={start}
            endTime={end}
            timeSlots={props.timeSlots}
            mode={TimeRangePickerMode.Start}
            onTimeRangeChange={handleTimeRangeChange}
            disabled={props.disabled}
            onlyEditOwnTimeSlot
          />
        </div>
      </div>
      <div className="date-time-picker-row">
        <div className="date-time-end-date">
          <DatePicker
            disabled={props.onlySameDay}
            disabledDate={(date) => props.disabledDate?.(date) ?? false}
            label={t('general.end') + ' ' + t('general.date')}
            value={end}
            onChange={(date) => handleEndDateChange(date!)}
            holidays={props.holidays}
          />
        </div>
        <div className="date-time-end-time">
          <TimeRangePicker
            label={t('general.endTime')}
            startTime={start}
            endTime={end}
            timeSlots={props.timeSlots}
            mode={TimeRangePickerMode.End}
            onTimeRangeChange={handleTimeRangeChange}
            disabled={props.disabled}
            onlyEditOwnTimeSlot
          />
        </div>
      </div>
    </div>
  );
});

export default DateTimeRangePicker;
