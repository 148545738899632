import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';

import { DeprecatedTag } from '@/ui-components';
import { CalendarRoomDto, CalendarTeacherDto } from '@untis/wu-rest-view-api';
import {
  renderCalendarEntryHeaderElements,
  renderCalendarEntryHeaderString,
} from '@/ui-components/calendar-entry-details-header/calendar-entry-details-header-util';
import RouterStore from '@/stores/router-store';
import useStore from '@/hooks/useStore';

import './calendar-entry-details-header.less';

export interface ICalendarEntryDetailsData {
  type: string;
  startDateTime: Dayjs;
  endDateTime: Dayjs;
  teachers: CalendarTeacherDto[];
  rooms: CalendarRoomDto[];
  tags: string[];
  lessonNumber?: number;
}

interface IProps {
  entryData: ICalendarEntryDetailsData;
  minimized: boolean;
  idParam: string | undefined;
  dateParam: string | undefined;
}

const CalendarEntryContent = (props: IProps) => {
  const { t } = useTranslation();
  const routerStore: RouterStore = useStore(RouterStore);

  return (
    <>
      <div className="title-row">
        <div className="title-content-container">
          <div className="text-container">
            <div className="pipe-separator">{renderCalendarEntryHeaderString(props.entryData.type)}</div>
            {props.entryData.tags.map((tag) => (
              <DeprecatedTag active key={tag}>
                {tag.toUpperCase()}
              </DeprecatedTag>
            ))}
          </div>
        </div>
      </div>
      {!props.minimized && (
        <div className="subtitle-row">
          <div className="subtitle-elements-flex-container">
            <div className="pipe-separator">
              {renderCalendarEntryHeaderString(props.entryData.startDateTime.format('dddd'))}
            </div>

            <div className="pipe-separator">
              {renderCalendarEntryHeaderString(props.entryData.startDateTime.format('l'))}
            </div>

            <div className="pipe-separator">
              {renderCalendarEntryHeaderString(
                props.entryData.startDateTime.format('LT') + '-' + props.entryData.endDateTime.format('LT'),
              )}
            </div>

            {props.entryData.rooms.length > 0 &&
              renderCalendarEntryHeaderElements(
                'room',
                [],
                props.entryData.rooms,
                props.idParam,
                props.dateParam,
                routerStore,
              )}
            {props.entryData.teachers.length > 0 &&
              renderCalendarEntryHeaderElements(
                'teacher',
                [],
                props.entryData.teachers,
                props.idParam,
                props.dateParam,
                routerStore,
              )}
          </div>
          {props.entryData.lessonNumber && (
            <div>
              {t`general.lesson`} Nr.: {props.entryData.lessonNumber}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CalendarEntryContent;
