import { Row } from 'antd';
import { useObserver } from 'mobx-react-lite';
import React, { forwardRef, ReactElement, Ref } from 'react';

import './main-menu-header.less';

interface IProps {
  title: string;
  subtitle: string;
  collapsed: boolean;
  isInSubMenu: boolean;
  showMenuItemsFadeAnimationWithIcons: boolean;
  isMainMenuHeaderOverlap: boolean;
  logo?: JSX.Element;
  customHeaderComponent?: {
    component: ReactElement;
    height: number;
  };
  headerHeight: number;
}

const MainMenuHeader = forwardRef((props: IProps, ref: Ref<HTMLDivElement>) => {
  const className =
    'untis-menu-header untis-menu-header--main' +
    (props.collapsed ? ' untis-menu-header--collapsed' : '') +
    (props.isInSubMenu ? ' untis-menu-header--main-left' : ' untis-menu-header--main-right') +
    (props.collapsed
      ? ' untis-menu-header--scale-animation'
      : props.showMenuItemsFadeAnimationWithIcons
      ? ' untis-menu-header--swipe-animation'
      : '');

  const titleColumn = props.collapsed ? null : (
    <div className="title-column" style={{ height: props.headerHeight }}>
      <Row className="title-row title-row--subtitle">{props.title}</Row>
      <Row className="subtitle-row">{props.subtitle}</Row>
    </div>
  );

  return useObserver(() => (
    <>
      <div
        style={{ height: props.headerHeight + (props.customHeaderComponent?.height ?? 0) }}
        ref={ref}
        className={props.isMainMenuHeaderOverlap ? 'shadow-helper shadow-helper-active' : 'shadow-helper'}
      />
      <div
        className={className}
        style={{
          height: props.headerHeight,
        }}
      >
        <div className="wrapper">
          <div
            className="icon-column"
            style={{
              height: props.headerHeight,
              lineHeight: props.headerHeight,
            }}
          >
            {props.logo}
          </div>
          {titleColumn}
          {props.customHeaderComponent?.component && (
            <div
              className="costum-component-container"
              style={{ top: props.headerHeight, height: props.customHeaderComponent.height }}
            >
              {props.customHeaderComponent.component}
            </div>
          )}
        </div>
      </div>
    </>
  ));
});

export default MainMenuHeader;
