import { computed, observable } from 'mobx';

import { inject, Store } from '@/types/store';
import {
  IFullDayEvent,
  IGridEntry,
  ISummarisedFullDayEvents,
  IParallelGridEntryGroup,
  ISummarisedGridEntries,
} from '@te/standard/stores/data/timetable-data-store';
import { TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';

interface IVerticalGridEntryGroup {
  entries: IGridEntry[];
  leftPercentage: number;
  widthPercentage: number;
}

const GRID_ENTRY_COLLISION_THRESHOLD = 2;

@Store()
export class TimetableDataPostprocessStore {
  private timetableMetaStore = inject(TimetableMetaStore);

  @observable private _maxFullDayEvents: number = 3;
  @observable private _maxGridEntriesParallel: number = 3;

  summariseFullDayEvents(fullDayEvents: IFullDayEvent[]): ISummarisedFullDayEvents {
    const result: ISummarisedFullDayEvents = {
      events: [],
    };
    const shouldSlice =
      this.timetableMetaStore.timetableViewType === 'week' && fullDayEvents.length > this._maxFullDayEvents;
    if (shouldSlice) {
      result.events = [...fullDayEvents].slice(0, this._maxFullDayEvents);
      result.additionalCount = fullDayEvents.length - this._maxFullDayEvents;
    } else {
      result.events = [...fullDayEvents];
    }

    return result;
  }

  postProcessGridEntries(gridEntries: IGridEntry[]): IParallelGridEntryGroup[] {
    this.setParallelToLeftFlag(gridEntries);
    const gridEntriesByParallelGroup = this.groupGridEntriesByParallelGroup(gridEntries);
    return this.summariseEntriesInParallelGroups(gridEntriesByParallelGroup);
  }

  private setParallelToLeftFlag(gridEntries: IGridEntry[]) {
    // check for parallel periods and set a flag if parallel to left (previous period)
    gridEntries.forEach((entry, index) => {
      if (index < gridEntries.length - 1) {
        // find grid entries that are consecutively next to each other
        const rightPercentage = entry.leftPercentage + entry.widthPercentage;
        if (Math.abs(gridEntries[index + 1].leftPercentage - rightPercentage) <= GRID_ENTRY_COLLISION_THRESHOLD) {
          // check if top and height are identical
          if (entry.top === gridEntries[index + 1].top && entry.height === gridEntries[index + 1].height) {
            gridEntries[index + 1].isParallelToLeft = true;
          }
        }
      }
    });
  }

  private groupGridEntriesByParallelGroup(gridEntries: IGridEntry[]): Map<number, IGridEntry[]> {
    const gridEntriesByParallelGroup: Map<number, IGridEntry[]> = new Map<number, IGridEntry[]>();
    gridEntries.forEach((gridEntry) => {
      const { parallelGroupId } = gridEntry;
      let parallelEntries = gridEntriesByParallelGroup.get(parallelGroupId);
      if (parallelEntries) {
        parallelEntries.push(gridEntry);
      } else {
        parallelEntries = [gridEntry];
      }

      gridEntriesByParallelGroup.set(parallelGroupId, parallelEntries);
    });

    return gridEntriesByParallelGroup;
  }

  private summariseEntriesInParallelGroups(
    gridEntriesByParallelGroup: Map<number, IGridEntry[]>,
  ): IParallelGridEntryGroup[] {
    const result: IParallelGridEntryGroup[] = [];
    gridEntriesByParallelGroup.forEach((parallelEntries) => {
      if (this.shouldSummariseParallelEntries(parallelEntries)) {
        result.push(this.summariseParallelGroup(parallelEntries));
      } else {
        result.push({
          entries: parallelEntries,
        });
      }
    });
    return result;
  }

  private shouldSummariseParallelEntries(parallelEntries: IGridEntry[]): boolean {
    return (
      this.timetableMetaStore.timetableViewType === 'week' && parallelEntries.length > this._maxGridEntriesParallel
    );
  }

  private summariseParallelGroup(parallelEntries: IGridEntry[]): IParallelGridEntryGroup {
    const sortedEntries = this.sortParallelEntries(parallelEntries);
    const verticalGroups = this.splitParallelEntriesToVerticalGroups(sortedEntries);

    if (verticalGroups.length > this._maxGridEntriesParallel) {
      return this.calculateParallelGridEntryGroup(verticalGroups);
    } else {
      return {
        entries: parallelEntries,
      };
    }
  }

  private sortParallelEntries(parallelEntries: IGridEntry[]): IGridEntry[] {
    const entriesToSort = [...parallelEntries];
    return entriesToSort.sort((a, b) => a.leftPercentage - b.leftPercentage || a.widthPercentage - b.widthPercentage);
  }

  private splitParallelEntriesToVerticalGroups(parallelEntries: IGridEntry[]): IVerticalGridEntryGroup[] {
    const verticalGroups: IVerticalGridEntryGroup[] = [];

    parallelEntries.forEach((gridEntry) => {
      const containingGroup = this.findContainingVerticalGroup(gridEntry, verticalGroups);
      this.addGridEntryToVerticalGroup({ ...gridEntry }, containingGroup, verticalGroups);
    });

    return verticalGroups;
  }

  private findContainingVerticalGroup(
    gridEntry: IGridEntry,
    verticalGroups: IVerticalGridEntryGroup[],
  ): IVerticalGridEntryGroup | undefined {
    const { leftPercentage } = gridEntry;
    return verticalGroups.find(
      (verticalGroup) =>
        leftPercentage >= verticalGroup.leftPercentage &&
        leftPercentage < verticalGroup.leftPercentage + verticalGroup.widthPercentage - 1,
    );
  }

  private addGridEntryToVerticalGroup(
    gridEntry: IGridEntry,
    containingGroup: IVerticalGridEntryGroup | undefined,
    verticalGroups: IVerticalGridEntryGroup[],
  ) {
    if (containingGroup) {
      containingGroup.entries.push(gridEntry);
    } else {
      verticalGroups.push({
        entries: [gridEntry],
        widthPercentage: gridEntry.widthPercentage,
        leftPercentage: gridEntry.leftPercentage,
      });
    }
  }

  private calculateParallelGridEntryGroup(verticalGroups: IVerticalGridEntryGroup[]): IParallelGridEntryGroup {
    const result: IParallelGridEntryGroup = {
      entries: [],
      summarisedEntries: {
        top: Number.MAX_VALUE,
        height: 0,
        widthPercentage: 0,
        leftPercentage: 0,
        hiddenEntries: [],
      },
    };
    verticalGroups.forEach((verticalGroup, index) => {
      const shouldBeHidden = index >= this._maxGridEntriesParallel - 1;
      if (shouldBeHidden) {
        result.summarisedEntries?.hiddenEntries.push(...verticalGroup.entries);
      } else {
        this.addAdjustedVisibleEntry(result, verticalGroup, index, verticalGroups.length);
      }
    });

    this.calculatePlaceholderDimensions(result);
    this.expandVisibleEntries(result);

    return result;
  }

  private addAdjustedVisibleEntry(
    result: IParallelGridEntryGroup,
    verticalGroup: IVerticalGridEntryGroup,
    verticalGroupIndex: number,
    numberOfTotalVerticalGroups: number,
  ) {
    const groupStartAfterSummarization = (100 / this._maxGridEntriesParallel) * verticalGroupIndex;
    const groupWidthBeforeSummarization = 100 / numberOfTotalVerticalGroups;

    verticalGroup.entries.forEach((gridEntry) => {
      const numberOfColumnsOfGridEntry = gridEntry.widthPercentage / groupWidthBeforeSummarization;
      let gridEntryUpdatedWidth = Math.round(numberOfColumnsOfGridEntry) * this.summarisedEntriesWidthPercentage;

      const gridEntryOverflow =
        groupStartAfterSummarization + gridEntryUpdatedWidth - this.summarisedEntriesLeftPercentage;
      if (gridEntryOverflow > 0) {
        gridEntryUpdatedWidth = gridEntryUpdatedWidth - gridEntryOverflow;
      }

      gridEntry.leftPercentage = groupStartAfterSummarization;
      gridEntry.widthPercentage = gridEntryUpdatedWidth;

      result.entries.push(gridEntry);
    });
  }

  private calculatePlaceholderDimensions(parallelGridEntryGroup: IParallelGridEntryGroup) {
    let currentTop = parallelGridEntryGroup.summarisedEntries!.top;
    let currentBottom = -1;
    parallelGridEntryGroup.summarisedEntries?.hiddenEntries.forEach((hiddenEntry) => {
      currentTop = Math.min(currentTop, hiddenEntry.top);
      currentBottom = Math.max(currentBottom, hiddenEntry.top + hiddenEntry.height);
    });

    parallelGridEntryGroup.summarisedEntries!.top = currentTop;
    parallelGridEntryGroup.summarisedEntries!.height = currentBottom - currentTop;
    parallelGridEntryGroup.summarisedEntries!.leftPercentage = this.summarisedEntriesLeftPercentage;
    parallelGridEntryGroup.summarisedEntries!.widthPercentage = this.summarisedEntriesWidthPercentage;
  }

  private expandVisibleEntries(parallelGridEntryGroup: IParallelGridEntryGroup) {
    parallelGridEntryGroup.entries.forEach((visibleEntry) => {
      const canExpand = this.visibleEntryCanExpand(
        visibleEntry,
        parallelGridEntryGroup.entries,
        parallelGridEntryGroup.summarisedEntries,
      );
      if (canExpand) {
        visibleEntry.widthPercentage = this.getExpandedEntry(visibleEntry).widthPercentage;
      }
    });
  }

  private visibleEntryCanExpand(
    visibleEntry: IGridEntry,
    visibleEntries: IGridEntry[],
    summarisedEntries: ISummarisedGridEntries | undefined,
  ): boolean {
    const expandedEntry = this.getExpandedEntry(visibleEntry);
    const collisionEntries = visibleEntries.filter((otherEntry) => {
      return visibleEntry.periodIds !== otherEntry.periodIds && this.entriesCollide(expandedEntry, otherEntry);
    });
    return collisionEntries.length === 0 && !this.entriesCollide(expandedEntry, summarisedEntries);
  }

  private getExpandedEntry(gridEntry: IGridEntry): IGridEntry {
    return { ...gridEntry, widthPercentage: 100 - gridEntry.leftPercentage };
  }

  private entriesCollide(gridEntry: IGridEntry, otherEntry: IGridEntry | ISummarisedGridEntries | undefined): boolean {
    if (otherEntry === undefined) {
      return false;
    }

    const gridEntryTop = gridEntry.top;
    const gridEntryBottom = gridEntry.top + gridEntry.height;
    const gridEntryLeft = gridEntry.leftPercentage;
    const gridEntryRight = gridEntry.leftPercentage + gridEntry.widthPercentage;

    const otherEntryTop = otherEntry.top;
    const otherEntryBottom = otherEntry.top + otherEntry.height;
    const otherEntryLeft = otherEntry.leftPercentage;
    const otherEntryRight = otherEntry.leftPercentage + otherEntry.widthPercentage;

    const horizontalOverlap = gridEntryRight > otherEntryLeft && otherEntryRight > gridEntryLeft;
    const verticalOverlap = gridEntryBottom > otherEntryTop && otherEntryBottom > gridEntryTop;

    return horizontalOverlap && verticalOverlap;
  }

  @computed
  private get summarisedEntriesLeftPercentage(): number {
    return (100 / this._maxGridEntriesParallel) * (this._maxGridEntriesParallel - 1);
  }

  @computed
  private get summarisedEntriesWidthPercentage(): number {
    return 100 / this._maxGridEntriesParallel;
  }
}
