import Layout from 'antd/lib/layout';
import { observer } from 'mobx-react-lite';
import React, { RefObject, useEffect, useMemo, useRef } from 'react';
import Div100vh from 'react-div-100vh';
import { Container } from 'typedi';
import { useTranslation } from 'react-i18next';

import DeletionServiceWarning from '@/components/deletion-service-warning/deletion-service-warning';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import { useComponentWillUnmount } from '@/hooks/useComponentWillUnmount';
import useModalDelegate from '@/hooks/useModalDelegate';
import useStore from '@/hooks/useStore';
import AppStore from '@/stores/app-store';
import ConfigStore from '@/stores/config-store';
import EnvironmentStore from '@/stores/environment-store';
import { MenuStore } from '@/stores/menu-store';
import ModalStore from '@/stores/modal-store';
import RefStore from '@/stores/ref-store';
import RouterStore from '@/stores/router-store';
import TokenStore from '@/stores/token-store';
import PostMessageStore, { HandleMessageAction } from '@sp/stores/post-message-store';
import { message } from '@/ui-components';
import SendMessageViewStore from '@/pages/messages/stores/send-message-view-store';
import { MessageRecipientOption, SchoolYearDto } from '@untis/wu-rest-view-api';
import { SidebarStore } from '@/stores/sidebar-store';
import { AbsenceSidebar } from '@/pages/class-register/absence-sidebar/absence-sidebar';
import { SchoolyearGapDialogView } from '@/dialogs/schoolyear-gap-dialog-view/schoolyear-gap-dialog-view';
import { WuCurrentUserDtoRolesEnum } from '@untis/wu-rest-view-api/api';
import { LocalStorageContext, LocalStorageStore } from '@/stores/local-storage-store';
import { SHOW_SCHOOLYEAR_GAP_DIALOG } from '@/types/local-storage-ids';
import SchoolYearStore from '@/stores/schoolyear-store';
import AnalyticsStore from '@/stores/analytics-store/analytics-store';
import './embedded-webuntis.less';
import { TriggerStore } from '@/stores/trigger-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { createAutomaticStudentAbsenceNotificationArgs } from '@/utils/notification/notification-util';

/**
 * parent.postMessage can only send strings, but sometimes we also need payload.
 * This is why we introduce this MessageObject that contains a type and a payload.
 *
 * However, in order for this to work there is the precondition that WebUntis ALWAYS sends JSON parsed string
 * that fit this interface definition.
 */
export interface IFrameHandleMessagePayload {
  messageType: HandleMessageAction;
  payload?: any;
}

export const appendStylesToIframe = (iFrameRef: RefObject<HTMLIFrameElement>, pathToCss: string) => {
  const environment = Container.get(EnvironmentStore);

  const cssLink = document.createElement('link');
  cssLink.href = pathToCss + (environment.version ? '?v=' + environment.version : '');
  cssLink.rel = 'stylesheet';
  cssLink.type = 'text/css';

  if (iFrameRef.current && iFrameRef.current.contentDocument) {
    iFrameRef.current.contentDocument.head.appendChild(cssLink);
  }
};

export const fetchToken = (iFrameRef: RefObject<HTMLIFrameElement>, tokenStore: TokenStore) => {
  if (
    iFrameRef.current &&
    iFrameRef.current.contentWindow &&
    iFrameRef.current.contentWindow.location.pathname === '/WebUntis/index.do'
  ) {
    // if WebUntis redirects to index.do, we try to fetch a new token and indirectly check if we are still logged in
    tokenStore.fetchToken();
  }
};

export const getTypeOfIFrameMessage = (payload: IFrameHandleMessagePayload): string => {
  if (!payload.messageType) {
    throw new Error('MessageEvent data does not contain a type.');
  }

  return payload.messageType;
};

interface IEmbeddedWebuntisProps {
  src: string;
}

// https://stackoverflow.com/questions/17838607/making-an-iframe-responsive
// https://medium.com/@ebakhtarov/handling-of-iframes-in-react-f038be46ac24
const EmbeddedWebuntis = observer((props: IEmbeddedWebuntisProps) => {
  const appStore = useStore(AppStore);
  const tokenStore = useStore(TokenStore);
  const routerStore = useStore(RouterStore);
  const menuStore = useStore(MenuStore);
  const modalStore = useStore(ModalStore);
  const configStore = useStore(ConfigStore);
  const refStore = useStore(RefStore);
  const sendMessageViewStore = useStore(SendMessageViewStore);
  const modalDelegate = useModalDelegate();
  const sidebarStore = useStore(SidebarStore);
  const schoolYearStore = useStore(SchoolYearStore);
  const analyticsStore = useStore(AnalyticsStore);
  const schoolyearStore = useStore(SchoolYearStore);
  const localStorageStore = useStore(LocalStorageStore);
  const triggerStore = useStore(TriggerStore);
  const notificationStore = useStore(NotificationStore);
  const postMessageStore = useStore(PostMessageStore);
  const lastLegacyPage = useRef('');
  const lastShownLegacyPage = useRef('');

  const { t } = useTranslation();

  const className = useMemo(() => {
    let value = 'embedded-webuntis-container';

    if (appStore.showLegacyWebUntis) {
      value += ' embedded-webuntis-container--show';
    } else {
      value += ' embedded-webuntis-container--hide';
    }

    return value;
  }, [appStore.showLegacyWebUntis]);

  useEffect(() => {
    if (routerStore.legacyPage) {
      // if previous legacy page was null (new frontend was opened)
      // and last shown (non-null) legacy page was the same as the legacy page that is about to be shown now,
      // then reload the content of the old page as data changes invoked by the new frontend might affect it
      if (!lastLegacyPage.current && lastShownLegacyPage.current === routerStore.legacyPage) {
        postMessageStore.postReloadContentMessage();
      }
      lastShownLegacyPage.current = routerStore.legacyPage;
    }
    lastLegacyPage.current = routerStore.legacyPage || '';
  }, [routerStore.legacyPage]);

  const firstNavigationEvent = useRef(true);

  /**
   * This function gets called whenever the embedded WebUntis application
   * posts a message to its window.parent (this application).
   * For now we expect navigation from within WebUntis to cause
   * a postMessage that sends the page in a data attribute.
   * We can use this information to update this applications route accordingly.
   */
  const handleFrameTasks = async (e: MessageEvent) => {
    /**
     * We only care for navigation messages we can apply an action for
     * or route to.
     * E.g.: React Dev Tools seems to post a bunch of messages
     * we don't want to react to.
     *
     * So we check if the message data is a JSON Object and has (at least) a type.
     */

    let messageObject: IFrameHandleMessagePayload;
    let type: string;

    try {
      const dataType = typeof e.data;
      if (dataType !== 'object') {
        return;
      }
      messageObject = e.data;
      type = getTypeOfIFrameMessage(messageObject);
    } catch (e) {
      return;
    }

    // We only want to handle events that come from this iframe
    if (e.source && 'frameElement' in e.source && e.source.frameElement?.id !== 'embedded-webuntis') {
      return;
    }

    // The message is OK --> handle it according to its type
    if (type === HandleMessageAction[HandleMessageAction.NAVIGATION]) {
      if (firstNavigationEvent.current) {
        firstNavigationEvent.current = false;
      } else {
        // We just want to navigate to another legacy page
        const legacyRoute = getLegacyRouteFromPayload(messageObject.payload);

        if (!legacyRoute) {
          return;
        }
        routerStore.updateRouteForLegacy(legacyRoute);
      }
    } else if (type === HandleMessageAction[HandleMessageAction.RELOAD]) {
      // We have to reload the page
      window.location.reload();
    } else if (type === HandleMessageAction[HandleMessageAction.OPEN_MODAL_DOJO]) {
      menuStore.isDojoModalOpen = true;
    } else if (type === HandleMessageAction[HandleMessageAction.OPEN_MODAL_REACT]) {
      menuStore.isReactModalOpen = true;
    } else if (type === HandleMessageAction[HandleMessageAction.CLOSE_MODAL_DOJO]) {
      menuStore.isDojoModalOpen = false;
    } else if (type === HandleMessageAction[HandleMessageAction.CLOSE_MODAL_REACT]) {
      menuStore.isReactModalOpen = false;
    } else if (type === HandleMessageAction[HandleMessageAction.NOTIFY]) {
      const notificationType = messageObject.payload.type;
      const notificationMessage = messageObject.payload.message;

      switch (notificationType) {
        case 'success':
          message.success(notificationMessage);
          break;
        case 'error':
          message.error(notificationMessage);
          break;
        case 'warning':
          message.warning(notificationMessage);
          break;
        case 'info':
          message.info(notificationMessage);
          break;
        default:
          console.error('Unkown WebUntis NotificationType: ' + notificationType);
          break;
      }
    } else if (type === HandleMessageAction[HandleMessageAction.SHOW_SINGLE_PERIOD_DETAILS]) {
      modalDelegate.openSinglePeriodDetailsDialog({ periodId: messageObject.payload.ttid }, messageObject.payload.tab);
    } else if (type === HandleMessageAction[HandleMessageAction.SHOW_PERIOD_DETAILS]) {
      modalDelegate.openPeriodDetailsDialog(
        {
          initialSelectedPeriodId: messageObject.payload.ttid,
          initialIsBlockSelected: messageObject.payload.isBlockSelected,
          elementId: messageObject.payload.elementId,
          elementType: messageObject.payload.elementType,
          startDateTime: messageObject.payload.startDateTime,
          endDateTime: messageObject.payload.endDateTime,
        },
        messageObject.payload.tab,
      );
    } else if (type === HandleMessageAction[HandleMessageAction.SELECTED_DEPARTMENT_ID]) {
      configStore.selectedDepartmentId = messageObject.payload;
    } else if (type === HandleMessageAction[HandleMessageAction.SELECT_STUDENT]) {
      configStore.selectedStudentId = messageObject.payload;
    } else if (type === HandleMessageAction[HandleMessageAction.SHOW_STUDENT_DELETE_DIALOG]) {
      const { students, isForm } = messageObject.payload;

      if (students.length <= 0) {
        return;
      }

      modalStore.deprecatedOpenModalDialog({
        content: <DeletionServiceWarning persons={students} isForm={isForm} type="students" />,
        className: 'untis-deletion-warning',
        closable: true,
      });
    } else if (type === HandleMessageAction[HandleMessageAction.SHOW_LEGAL_GUARDIAN_DELETE_DIALOG]) {
      const { legalGuardians, isForm } = messageObject.payload;

      if (legalGuardians.length <= 0) {
        return;
      }

      modalStore.deprecatedOpenModalDialog({
        content: <DeletionServiceWarning persons={legalGuardians} isForm={isForm} type="legalGuardians" />,
        className: 'untis-deletion-warning',
        closable: true,
      });
    } else if (type === HandleMessageAction[HandleMessageAction.SHOW_APPRENTICE_REPRESENTATIVES_DELETE_DIALOG]) {
      const { apprenticeRepresentatives, isForm } = messageObject.payload;

      if (apprenticeRepresentatives.length <= 0) {
        return;
      }

      modalStore.deprecatedOpenModalDialog({
        content: (
          <DeletionServiceWarning
            persons={apprenticeRepresentatives}
            isForm={isForm}
            type="apprenticeRepresentatives"
          />
        ),
        className: 'untis-deletion-warning',
        closable: true,
      });
    } else if (type === HandleMessageAction[HandleMessageAction.LEGACY_MESSAGE_ACTION]) {
      const action = messageObject.payload.action;
      if (action === 'sent') {
        message.success(t('general.messageSent'));
        routerStore.routing.push('/messages/inbox');
      } else if (action === 'saved') {
        message.success(t('general.messageSaved'));
        routerStore.routing.push('/messages/drafts');
      } else {
        routerStore.routing.push('/messages/inbox');
      }
    } else if (type === HandleMessageAction[HandleMessageAction.LEGACY_TT_SEARCH_CHANGED]) {
      routerStore.refreshRouteParamsForLegacy(messageObject.payload.params);
    } else if (type === HandleMessageAction[HandleMessageAction.OPEN_UNREAD_MESSAGES]) {
      routerStore.routing.push('/messages/inbox');
    } else if (type === HandleMessageAction[HandleMessageAction.OPEN_TIMETABLING]) {
      routerStore.routing.push('/timetabling/timetables');
    } else if (type === HandleMessageAction[HandleMessageAction.OPEN_SEND_MESSAGE_DIALOG]) {
      const userIds: number[] | undefined = messageObject.payload?.targetIds;
      sendMessageViewStore.openSendMessageView({
        recipientOption: MessageRecipientOption.CUSTOM,
        initialRecipientsUserIds: userIds,
      });
    } else if (type === HandleMessageAction[HandleMessageAction.OPEN_ABSENCE_SIDEBAR]) {
      sidebarStore.openSidebar({
        title: t('general.absenceTimes'),
        content: <AbsenceSidebar absenceId={messageObject.payload} />,
        overlap: true,
        onClose: () => sidebarStore.closeSidebar(),
      });
    } else if (type == HandleMessageAction.USER_ACTIVITY) {
      tokenStore.fetchToken();
    } else if (type === HandleMessageAction.SELECT_SCHOOLYEAR) {
      const selectedSchoolYearId: number | undefined = messageObject.payload;
      const selectedSchoolYear: SchoolYearDto | undefined = schoolYearStore.schoolYears.find(
        (x) => x.id === selectedSchoolYearId,
      );
      schoolYearStore.handleSchoolyearChanged(selectedSchoolYear);
    } else if (type === HandleMessageAction.ANALYTICS_TRACK_EVENT) {
      const event = messageObject.payload;
      analyticsStore.trackEvent(event.category, event.action, event.name, event.value);
    } else if (type === HandleMessageAction.AUTOMATIC_STUDENT_ABSENCE_NOTIFICATIONS_SENT) {
      if (Array.isArray(messageObject.payload.notifications)) {
        await notificationStore.notifyMultiple(
          messageObject.payload.notifications.map((n: any) => {
            return {
              type: n.notificationSentSuccessfully ? 'success' : 'error',
              args: createAutomaticStudentAbsenceNotificationArgs(n.studentName, n.notificationSentSuccessfully),
            };
          }),
        );
      }
    } else {
      throw Error(`Unknown event received: ${type}`);
    }
  };

  // e.g.: turns "coursetemplatelist.do?request.preventCache=1569482967396" into "#coursetemplatelist"
  const getLegacyRouteFromPayload = (payload: string): string | undefined => {
    if (!payload) {
      return undefined;
    }

    const dotDoPosition: number = payload.search(/.do/);

    if (dotDoPosition > -1) {
      payload = payload.substr(0, dotDoPosition);

      if (payload.charAt(0) !== '#') {
        payload = '#' + payload;
      }
    }

    payload = replaceTablistPageWithCorrectPage(payload);

    return payload;
  };

  /**
   * In some cases, the back buttons in WU cause a navigation to a xxx.do site (e.g.: "sub pages" on
   * tab-list-pages).
   * In this case, we want to redirect to the correct tab-list-page rather than to xxx.do.
   * Otherwise, only the content of the xxx.do page would be displayed, but the tab-list-page itself with its
   * tab navigation would not appear.
   */
  const replaceTablistPageWithCorrectPage = (route: string): string => {
    if (route === '#activityformatlist' || route === '#dayoverviewformatlist' || route === '#substitutionformatlist') {
      return '#monitorformats';
    }

    if (
      route === '#untisintegrationform' ||
      route === '#samlintegrationform' ||
      route === '#ldapintegrationform' ||
      route === '#office365integrationform' ||
      route === '#sokratesintegrationform' ||
      route === '#smartschoolintegrationform'
    ) {
      return '#integrations';
    }

    if (
      route === '#viewsettingsform' ||
      route === '#colorsettings' ||
      route === '#timetableformatlist' ||
      route === '#overviewformatlist' ||
      route === '#icsformatlist' ||
      route === '#officehourform' ||
      route === '#reportviewsettingsform'
    ) {
      return '#formats';
    }

    if (route === '#messagelist' || route === '#distributionlistlist') {
      return '#messagecenter';
    }

    return route;
  };

  useComponentDidMount(() => {
    window.addEventListener('message', handleFrameTasks);
  });

  useComponentWillUnmount(() => {
    window.removeEventListener('message', handleFrameTasks);
  });

  const handleLoad = () => {
    appStore.setEmbeddedWebUntisLoaded(true);

    fetchToken(refStore.embeddedWebUntisIFrameRef, tokenStore);

    // the iframe should include a link tag to a css file to overwrite some styles
    appendStylesToIframe(refStore.embeddedWebUntisIFrameRef, '../overwrites.css');

    routerStore.updateLegacyPage(routerStore.legacyPage, false);

    const isStudentOrParent =
      configStore.userRolesEnum.includes(WuCurrentUserDtoRolesEnum.STUDENT) ||
      configStore.userRolesEnum.includes(WuCurrentUserDtoRolesEnum.PARENT) ||
      configStore.userRolesEnum.includes(WuCurrentUserDtoRolesEnum.LEGAL_GUARDIAN);

    // opening the dialog here in the handleLoad function instead of app.tsx, because we have to wait
    // until initial routing has been done.
    // Otherwise the routing causes the dialog to close immediately after login (which is intended, because all new
    // modals should close if the user navigates)

    if (
      !schoolyearStore.currentSchoolYear &&
      !triggerStore.hasForceAdminDetailsChangeAction &&
      localStorageStore.readBoolean(LocalStorageContext.GLOBAL, SHOW_SCHOOLYEAR_GAP_DIALOG) !== false
    ) {
      modalStore.openModalDialog({
        title: t('general.noSchoolyearActive'),
        size: 'md',
        disableNavigation: true,
        maskClosable: false,
        hideXIcon: true,
        showFooterSeparator: true,
        children: <SchoolyearGapDialogView isStudentOrParent={isStudentOrParent} />,
        keyboard: false,
        onAfterClose: () =>
          localStorageStore.writeBoolean(LocalStorageContext.GLOBAL, SHOW_SCHOOLYEAR_GAP_DIALOG, false),
      });
    }
  };

  return (
    <Layout.Content className={className}>
      <Div100vh className="embedded-webuntis">
        <iframe
          src={props.src}
          ref={refStore.embeddedWebUntisIFrameRef}
          onLoad={handleLoad}
          title="WebUntis"
          id="embedded-webuntis"
        />
      </Div100vh>
    </Layout.Content>
  );
});

export default EmbeddedWebuntis;
