import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';

import { Timespan } from '@untis/wu-rest-view-api';
import { LessonIndicator } from '@/components/lesson-card/components/indicator/lesson-card-indicator';
import {
  ILessonCardDisplayProps,
  ILessonCardKlasseWithStudentGroupContent,
  ILessonCardProps,
  ILessonCardResourceWithChange,
  LessonCardDisplayVariant,
  LessonCardRowContent,
  LessonCardStatus,
  LessonCardType,
} from '@/components/lesson-card/lesson-card';
import { formatDate, formatTimeRange } from '@/utils/date/date-util';
import { ILessonCardDimensions } from '@/components/lesson-card/hooks/use-lesson-card-dimensions';

export const MINIMAL_VARIANT_WIDTH_THRESHOLD = 60;
export const MINIMAL_VARIANT_HEIGHT_THRESHOLD = 30;
export const SHOW_SINGLE_ROW_HEIGHT_THRESHOLD = 46;
export const STANDBY_LESSON_CARD_HEIGHT_THRESHOLD = SHOW_SINGLE_ROW_HEIGHT_THRESHOLD + 12; /* padding */
export const LESSON_CARD_ROW_HEIGHT = 16;

export function useComputedLessonCardProps({
  periodIds,
  rows,
  endDateTime,
  indicators,
  type,
  status,
  colorBar,
  periodMove,
  calendarName,
  displayProps,
  lessonCardDimensions,
  onLessonClick,
}: ILessonCardProps & { lessonCardDimensions?: ILessonCardDimensions }) {
  const { t } = useTranslation();

  const testId = useMemo(() => {
    return `lesson-card-${periodIds.join('-')}`;
  }, [periodIds]);

  const handleButtonClick = useCallback(() => {
    onLessonClick?.();
  }, [onLessonClick]);

  const isInThePast = useMemo(
    () => displayProps?.isPast ?? endDateTime?.isBefore(dayjs()) ?? false,
    [endDateTime, displayProps],
  );

  const showSingleRow = useMemo((): boolean => {
    return (
      (lessonCardDimensions != undefined &&
        displayProps?.showSingleRow &&
        lessonCardDimensions.height > MINIMAL_VARIANT_HEIGHT_THRESHOLD &&
        lessonCardDimensions.height <= SHOW_SINGLE_ROW_HEIGHT_THRESHOLD) ??
      false
    );
  }, [lessonCardDimensions, displayProps?.showSingleRow]);

  const minimaHeightVariant = useMemo((): boolean => {
    return (
      (lessonCardDimensions !== undefined && lessonCardDimensions.height <= MINIMAL_VARIANT_HEIGHT_THRESHOLD) ?? false
    );
  }, [lessonCardDimensions]);

  const minimaWidthVariant = useMemo((): boolean => {
    const minWidthThreshold = displayProps?.showStartEndTime
      ? MINIMAL_VARIANT_WIDTH_THRESHOLD + 26
      : MINIMAL_VARIANT_WIDTH_THRESHOLD;
    return (lessonCardDimensions !== undefined && lessonCardDimensions.width <= minWidthThreshold) ?? false;
  }, [lessonCardDimensions, displayProps?.showStartEndTime]);

  const displayVariant: LessonCardDisplayVariant = useMemo(() => {
    if (minimaHeightVariant) {
      return 'minimal-height';
    } else if (minimaWidthVariant) {
      return 'minimal-width';
    } else {
      return displayProps?.displayVariant ?? 'default';
    }
  }, [minimaHeightVariant, minimaWidthVariant, displayProps]);

  const updatedDisplayProps: ILessonCardDisplayProps = useMemo(() => {
    return {
      ...displayProps,
      isPast: isInThePast,
      displayVariant: displayVariant,
      showSingleRow: showSingleRow,
    };
  }, [displayProps, isInThePast, showSingleRow, displayVariant]);

  const updatedColorBar = useMemo(() => {
    const { color, isStriped } = colorBar;
    return {
      color: color.startsWith('#') ? colorBar.color : `#${colorBar.color}`,
      isStriped,
    };
  }, [colorBar]);

  const rooms: ILessonCardResourceWithChange[] = useMemo(() => {
    const roomsRow = rows.find((row) => row.type === 'rooms');
    const content: ILessonCardResourceWithChange[] | undefined = roomsRow?.content as
      | ILessonCardResourceWithChange[]
      | undefined;
    return (
      content?.filter(
        (roomChange) => !!roomChange.regularResource || !!roomChange.removedResource || !!roomChange.addedResource,
      ) ?? []
    );
  }, [rows]);

  const teachers: ILessonCardResourceWithChange[] = useMemo(() => {
    const teachersRow = rows.find((row) => row.type === 'teachers');
    const content: ILessonCardResourceWithChange[] | undefined = teachersRow?.content as
      | ILessonCardResourceWithChange[]
      | undefined;
    return (
      content?.filter(
        (teacherChange) =>
          !!teacherChange.regularResource || !!teacherChange.removedResource || !!teacherChange.addedResource,
      ) ?? []
    );
  }, [rows]);

  const klassen: ILessonCardResourceWithChange[] = useMemo(() => {
    const klasseRow = rows.find((row) => row.type === 'klasse');
    const content: ILessonCardResourceWithChange[] | undefined = klasseRow?.content as
      | ILessonCardResourceWithChange[]
      | undefined;
    return (
      content?.filter(
        (klasseChange) =>
          !!klasseChange.regularResource || !!klasseChange.removedResource || !!klasseChange.addedResource,
      ) ?? []
    );
  }, [rows]);

  const klasseWithStudentGroup: ILessonCardKlasseWithStudentGroupContent = useMemo(() => {
    const klasseWithStudentGroupRow = rows.find((row) => row.type === 'klasse-student-group');
    const content: ILessonCardKlasseWithStudentGroupContent | undefined = klasseWithStudentGroupRow?.content as
      | ILessonCardKlasseWithStudentGroupContent
      | undefined;
    return {
      klassen:
        content?.klassen.filter(
          (klasseChange) =>
            !!klasseChange.regularResource || !!klasseChange.removedResource || !!klasseChange.addedResource,
        ) ?? [],
      studentGroup: content?.studentGroup,
    };
  }, [rows]);

  const hasRoomChange = useMemo(
    () =>
      rooms.some((roomChange) => roomChange.removedResource !== undefined || roomChange.addedResource !== undefined),
    [rooms],
  );

  const hasTeacherChange = useMemo(
    () =>
      teachers.some(
        (teacherChange) => teacherChange.removedResource !== undefined || teacherChange.addedResource !== undefined,
      ),
    [teachers],
  );

  const hasKlasseChange = useMemo(
    () =>
      klassen.some(
        (klasseChange) => klasseChange.removedResource !== undefined || klasseChange.addedResource !== undefined,
      ) ||
      klasseWithStudentGroup.klassen.some(
        (klasseChange) => klasseChange.removedResource !== undefined || klasseChange.addedResource !== undefined,
      ),
    [klassen, klasseWithStudentGroup],
  );

  const isCancelled = useMemo(
    () => [LessonCardStatus.CANCELLED, LessonCardStatus.MOVED_AWAY].includes(status),
    [status],
  );

  const formatPeriodMove = (translationKey: string, periodMove?: Timespan): string => {
    const periodMoveStart = dayjs(periodMove?.start);
    const periodMoveEnd = dayjs(periodMove?.end);
    return t(translationKey, {
      date: formatDate(periodMoveStart),
      timeRange: formatTimeRange(periodMoveStart, periodMoveEnd),
    });
  };

  const hasChange = useMemo(() => {
    return hasRoomChange || hasTeacherChange || hasKlasseChange;
  }, [hasRoomChange, hasTeacherChange, hasKlasseChange]);

  const updatedIndicators: LessonIndicator[] = useMemo(() => {
    const _indicators: LessonIndicator[] = [];
    if (status === LessonCardStatus.CANCELLED || status === LessonCardStatus.MOVED_AWAY) {
      _indicators.push({
        type: 'cancelled',
        displayName: t('general.cancellation'),
      });
      if (displayProps?.displayVariant === 'long' && status === LessonCardStatus.MOVED_AWAY) {
        _indicators.push({
          type: 'change',
          displayName: formatPeriodMove('timetable.indicators.movedToIndicator', periodMove),
        });
      }
    } else if (type === LessonCardType.EXTERNAL_CALENDAR_EVENT) {
      _indicators.push({
        type: 'external',
        displayName: calendarName ?? '',
      });
    } else {
      if (type === LessonCardType.EXAM) {
        _indicators.push({
          type: 'exam',
          displayName: t('general.exam'),
        });
      }

      if (status === LessonCardStatus.MOVED_HERE) {
        _indicators.push({
          type: 'change',
          displayName: formatPeriodMove('timetable.indicators.movedFromIndicator', periodMove),
        });
      } else if (
        type === LessonCardType.ADDITIONAL_PERIOD ||
        type === LessonCardType.EVENT ||
        status === LessonCardStatus.ADDITIONAL
      ) {
        if (type === LessonCardType.STAND_BY_PERIOD) {
          _indicators.push({
            type: 'change',
            displayName: t('general.additionalStandBy'),
          });
        } else if (type === LessonCardType.OFFICE_HOUR) {
          _indicators.push({
            type: 'change',
            displayName: t('general.additionalOfficeHour'),
          });
        } else if (type === LessonCardType.BREAK_SUPERVISION) {
          _indicators.push({
            type: 'change',
            displayName: t('general.additionalBreakSupervision'),
          });
        } else {
          _indicators.push({
            type: 'change',
            displayName: t('general.additionalPeriod'),
          });
        }
      }

      if (status === LessonCardStatus.CHANGED || hasChange) {
        _indicators.push({
          type: 'change',
          displayName: t('general.modification'),
        });
      }
    }

    if (displayProps?.displayVariant !== 'long') {
      return uniqBy(_indicators, (value) => value.type);
    } else {
      return _indicators;
    }
  }, [type, status, indicators, hasChange, periodMove, calendarName, displayProps]);

  // safely remove any row that's content is empty
  const filteredRows: LessonCardRowContent[] = useMemo(() => {
    return rows.filter((row) => {
      if (row.type === 'teachers' || row.type === 'rooms' || row.type === 'klasse') {
        return row.content.some(
          (resourceChange) =>
            resourceChange.regularResource !== undefined ||
            resourceChange.removedResource !== undefined ||
            resourceChange.addedResource !== undefined,
        );
      } else if (row.type === 'klasse-student-group') {
        return (
          row.content.klassen.some(
            (resourceChange) =>
              resourceChange.regularResource !== undefined ||
              resourceChange.removedResource !== undefined ||
              resourceChange.addedResource !== undefined,
          ) || row.content.studentGroup !== undefined
        );
      } else if (row.type === 'subject') {
        return row.content.join().length > 0;
      } else if (row.type === 'student-group') {
        return row.content !== undefined;
      } else if (row.type === 'text') {
        return row.content.texts.join().length > 0;
      } else {
        return false;
      }
    });
  }, [rows]);

  const updatedRows: LessonCardRowContent[] = useMemo(() => {
    let _updatedRows: LessonCardRowContent[] = [];

    filteredRows?.forEach((row, index) => {
      if (row.type === 'teachers') {
        _updatedRows.push({
          type: 'teachers',
          content: teachers,
          isTopElement: index === 0,
          displayProps: updatedDisplayProps,
          indicator: updatedIndicators[index],
        });
      } else if (row.type === 'rooms') {
        _updatedRows.push({
          type: 'rooms',
          content: rooms,
          isTopElement: index === 0,
          displayProps: updatedDisplayProps,
          indicator: updatedIndicators[index],
        });
      } else if (row.type === 'klasse') {
        _updatedRows.push({
          type: 'klasse',
          content: klassen,
          isTopElement: index === 0,
          displayProps: updatedDisplayProps,
          indicator: updatedIndicators[index],
        });
      } else if (row.type === 'klasse-student-group') {
        _updatedRows.push({
          type: 'klasse-student-group',
          content: klasseWithStudentGroup,
          isTopElement: index === 0,
          displayProps: updatedDisplayProps,
          indicator: updatedIndicators[index],
        });
      } else {
        _updatedRows.push({
          ...row,
          isTopElement: index === 0,
          displayProps: updatedDisplayProps,
          indicator: updatedIndicators[index],
        });
      }
    });

    if (type === LessonCardType.BREAK_SUPERVISION) {
      _updatedRows = [];
      _updatedRows.push({
        type: 'subject',
        content: [t('general.breakSupervision')],
        isTopElement: true,
        indicator: updatedIndicators[0],
        displayProps: updatedDisplayProps,
      });
      _updatedRows.push({
        type: 'rooms',
        content: rooms,
        isTopElement: false,
        indicator: updatedIndicators[1],
        displayProps: updatedDisplayProps,
      });
      _updatedRows.push({
        type: 'teachers',
        content: teachers,
        isTopElement: false,
        indicator: updatedIndicators[2],
        displayProps: updatedDisplayProps,
      });
    }

    if (updatedIndicators.length > _updatedRows.length) {
      updatedIndicators.slice(_updatedRows.length).forEach((indicator) => {
        _updatedRows.push({
          type: 'empty',
          content: undefined,
          indicator: indicator,
          displayProps: updatedDisplayProps,
          isTopElement: false,
        });
      });
    }

    return _updatedRows;
  }, [filteredRows, updatedDisplayProps, updatedIndicators, rooms, teachers, klassen, klasseWithStudentGroup]);

  return {
    handleButtonClick,
    updatedColorBar,
    updatedDisplayProps,
    updatedIndicators,
    updatedRows,
    isCancelled,
    testId,
  };
}
