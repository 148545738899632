import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import ICalSubscriptionStore, { ICalSubscriptionFormData } from '@te/standard/stores/ical/i-cal-subscription-store';
import { WUForm } from '@/ui-components';
import { FormSection, IFormButton, WUFormCol, WUFormRow } from '@/ui-components/wu-form';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import { formatDate } from '@/utils/date/date-util';
import { LoadingIndicator } from '@/ui-components/scroller/loading-indicator/loading-indicator';

import './i-cal-subscription-form.less';

const ICalSubscriptionForm = observer(() => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ICalSubscriptionFormData>();
  const [store] = useState<ICalSubscriptionStore>(() => new ICalSubscriptionStore());

  useComponentDidMount(() => {
    store.setForm(form);
  });

  if (store.isLoading) {
    return <LoadingIndicator />;
  }

  const leftButtons: IFormButton[] = [
    {
      type: 'primary',
      label: t('general.disableLink'),
      disabled: !store.hasICalLink,
      outline: true,
      testId: 'disable-link',
      onClick: store.disableLink,
    },
  ];
  const rightButtons: IFormButton[] = [
    {
      type: 'primary',
      label: t('general.copyLink'),
      disabled: !store.hasICalLink,
      outline: true,
      testId: 'copy-link',
      onClick: store.copyLink,
    },
  ];

  const initialFormat = store.initialFormat.toString();

  return (
    <WUForm<ICalSubscriptionFormData>
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      fixedButtonBar={true}
      form={store.form}
      maxWidth
      hideTopBorder
      enableSubmitButton={true}
      disableSubmitButton={store.hasICalLink}
      submitButtonLabel={t('general.createLink')}
      onSubmit={store.createLink}
      testIdSaveButton="create-link"
      className="i-cal-subscription-form"
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection
              text={t('general.iCalFormat')}
              description={t('general.selectICalFormatForSubscribedCalendar')}
            />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormSingleSelect
              items={store.formatItems}
              name="format"
              initialValue={initialFormat}
              placeholder={t('general.iCalFormat')}
              allowClear={false}
              onChange={store.onFormatChange}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={1}>
            <FormSection text={t('general.iCalLink')} />
          </WUFormCol>
          <WUFormCol lg={1}>
            <ICalSubscriptionLink createdOn={store.linkCreatedOn} link={store.link} />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});

const ICalSubscriptionLink = (props: { createdOn: Dayjs | undefined; link: string | undefined }) => {
  const { t } = useTranslation();
  const { createdOn, link } = props;

  return (
    <div className="i-cal-subscription-link-container">
      <div className="link-created-on">
        <span className="link-created-on--label">{t('general.createdOn')}: </span>
        <span data-testid="link-created-on" className="link-created-on--value">
          {link ? formatDate(createdOn) : t('general.noLinkCreated')}
        </span>
      </div>
      {link && (
        <div className="i-cal-subscription-link">
          <a data-testid="link" href={link} rel="noreferrer">
            {link}
          </a>
        </div>
      )}
    </div>
  );
};

export default ICalSubscriptionForm;
