import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import { ListView } from '@/components/list-view/list-view';
import { ExamTypeStore, IExamTypeRow, IExamTypeForm } from '@/pages/master-data/exam-type/exam-type-store';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';

import './exam-type.less';

export const MasterDataExamType = observer(() => {
  const [form] = Form.useForm<IExamTypeForm>();
  const [examTypesStore] = useState(new ExamTypeStore());
  const { t } = useTranslation();

  useComponentDidMount(() => {
    examTypesStore.setFormInstance(form);
  });

  return (
    <ListView<IExamTypeRow>
      title={t('masterData.examTypes.title')}
      isPageLoading={examTypesStore.isDataLoading}
      searchBar={{
        selectedFreeTextOptions: examTypesStore.selectedFreeTextOptions,
        onChangeFreeTextOptions: (values) => examTypesStore.setSelectedFreeTextSearchOptions(values),
        placeholder: t('general.search'),
      }}
      table={{
        columns: examTypesStore.columns,
        rowData: examTypesStore.filteredRows,
        rowSelection: {
          selectedRowKeys: examTypesStore.selectedRowKeys,
          type: 'checkbox',
          onChange: (keys) => examTypesStore.setSelectedRowKeys(keys),
        },
        shadow: true,
        className: 'exam-type-wu-table',
      }}
      actions={examTypesStore.listViewActions}
      selectedRowsActions={examTypesStore.selectedRowsActions}
      customSorting={{
        onSave: examTypesStore.onSaveCustomOrder,
        onDelete: examTypesStore.onDeleteCustomOrder,
      }}
    />
  );
});
