import { action, computed, observable } from 'mobx';
import { RefObject } from 'react';

import { Store } from '@/types/store';
import { ITestComponentProps } from '@/types/test-component-props';

export interface IMenuItem extends ITestComponentProps {
  name: string;
  badge?: string;
  tag?: string;
  subMenuItems?: ISubMenuItem[];
  selectedSubMenuItem?: ISubMenuItem;
  icon: string | JSX.Element;
  showDenseMenuItems?: boolean;
  route?: string;
}

export interface IPlatformMenuItem extends IMenuItem {
  logoutUrl: string | undefined;
}

export interface ISubMenuItem extends ITestComponentProps {
  name: string;
  route: string;
  alternateRoutes?: string[]; // Alternative routes which should also trigger the menu item as selected item
  badge?: string;
  icon?: string;
  isDense?: boolean;
  onClick?: () => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  highlight?: boolean;
}

export interface IMenuUser {
  name: string;
  role: string;
  image?: string;
  userRoute?: string;
}

export interface IMenuStudent {
  name: string;
  image?: string;
  id: number;
}

export interface IMenuDepartment {
  id: number;
  name: string;
}

@Store()
export class NavigationBarStore {
  @observable private _collapsed = false; // is the menu collapsed to the left?
  @observable isInSubMenu: boolean | undefined = undefined;
  @observable isInDepartmentSelection: boolean = false;
  @observable isInStudentSelection: boolean = false;
  @observable mainMenuItemList: RefObject<HTMLDivElement> | null;
  @observable subMenuItemList: RefObject<HTMLDivElement> | null;
  @observable departmentList: RefObject<HTMLDivElement> | null;
  @observable studentList: RefObject<HTMLDivElement> | null;
  @observable bottomView: RefObject<HTMLDivElement> | null;
  @observable mainMenuHeader: RefObject<HTMLDivElement> | null;
  @observable subMenuHeader: RefObject<HTMLDivElement> | null;
  @observable departmentHeader: RefObject<HTMLDivElement> | null;
  @observable studentHeader: RefObject<HTMLDivElement> | null;
  @observable isMainMenuHeaderOverlap = false;
  @observable isSubMenuHeaderOverlap = false;
  @observable isDepartmentHeaderOverlap = false;
  @observable isStudentHeaderOverlap = false;
  @observable isBottomViewOverlap = false;
  @observable private _currentRoute: string = '';
  @observable private _selectedDepartmentId: number = -1;
  @observable private _selectedStudentId: number = -1;
  @observable menuItems: IMenuItem[] = [];
  @observable bottomMenuItems: ISubMenuItem[] = [];
  @observable showMenuItemsFadeAnimationWithIcons: boolean = true;
  @observable mainMenuListScrollTop: number = 0;
  @observable subMenuListScrollTop: number = 0;
  @observable departmentListScrollTop: number = 0;
  @observable studentListScrollTop: number = 0;
  @observable scrollMode: boolean = false;
  @observable departments: IMenuDepartment[] = [];
  @observable students: IMenuStudent[] = [];
  @observable schoolName: string = '';
  @observable userRoute: string | undefined;

  onCollapseCallback: (() => void) | undefined = undefined;
  onExpandCallback: (() => void) | undefined = undefined;
  onSelectDepartment: ((value: number) => void) | undefined;
  onSelectStudent: ((value: number) => void) | undefined;
  departmentsText: string = '';
  studentsText: string = '';
  allText: string = ''; // Text that is rendered to select "all departments" or "all students"
  allStudentsText: string = '';

  constructor() {
    this.mainMenuItemList = null;
    this.subMenuItemList = null;
    this.departmentList = null;
    this.studentList = null;
    this.bottomView = null;
    this.subMenuHeader = null;
    this.mainMenuHeader = null;
    this.departmentHeader = null;
    this.studentHeader = null;
  }

  @action
  setCurrentRoute(value: string) {
    this._currentRoute = value;
  }

  @action
  setSelectedDepartmentId(value: number) {
    this._selectedDepartmentId = value;
  }

  @action
  setSelectedStudentId(value: number) {
    this._selectedStudentId = value;
  }

  /**
   * Checks if the bottom-view intersects with the menu items.
   * (If the view intersects a shadow should appear to indicate that there is more content hidden by the view)
   */
  isOverlap(rect1: ClientRect | DOMRect, rect2: ClientRect | DOMRect): boolean {
    return !(
      rect1.right <= rect2.left ||
      rect1.left >= rect2.right ||
      rect1.bottom <= rect2.top ||
      rect1.top >= rect2.bottom
    );
  }

  @action.bound
  public checkForIntersections() {
    if (
      !this.mainMenuItemList ||
      !this.mainMenuItemList.current ||
      !this.subMenuItemList ||
      !this.subMenuItemList.current ||
      !this.departmentList ||
      !this.departmentList.current ||
      !this.bottomView ||
      !this.bottomView.current ||
      !this.mainMenuHeader ||
      !this.mainMenuHeader.current ||
      !this.subMenuHeader ||
      !this.subMenuHeader.current ||
      !this.departmentHeader ||
      !this.departmentHeader.current ||
      !this.studentList ||
      !this.studentList.current
    ) {
      return;
    }
    const mainMenuRect = this.mainMenuItemList.current.getBoundingClientRect();
    const subMenuRect = this.subMenuItemList.current.getBoundingClientRect();
    const departmentRect = this.departmentList.current.getBoundingClientRect();
    const bottomViewRect = this.bottomView.current.getBoundingClientRect();
    const mainMenuHeaderRect = this.mainMenuHeader.current.getBoundingClientRect();
    const subMenuHeaderRect = this.subMenuHeader.current.getBoundingClientRect();
    const departmentHeaderRect = this.departmentHeader.current.getBoundingClientRect();
    const studentHeaderRect = this.studentList.current.getBoundingClientRect();

    this.isMainMenuHeaderOverlap = this.isOverlap(mainMenuRect, mainMenuHeaderRect) && this.isInMainMenu;
    this.isSubMenuHeaderOverlap = this.isOverlap(subMenuRect, subMenuHeaderRect) && !!this.isInSubMenu;
    this.isDepartmentHeaderOverlap =
      this.isOverlap(departmentRect, departmentHeaderRect) && this.isInDepartmentSelection;

    if (this.isInMainMenu) {
      this.isBottomViewOverlap = this.isOverlap(mainMenuRect, bottomViewRect);
    } else if (this.isInSubMenu) {
      this.isBottomViewOverlap = this.isOverlap(subMenuRect, bottomViewRect);
    } else if (this.isInDepartmentSelection) {
      this.isBottomViewOverlap = this.isOverlap(departmentRect, bottomViewRect);
    } else if (this.isInStudentSelection) {
      this.isBottomViewOverlap = this.isOverlap(studentHeaderRect, bottomViewRect);
    }

    if (this.studentList && this.studentList.current && this.studentHeader && this.studentHeader.current) {
      const studentRect = this.studentList.current.getBoundingClientRect();
      const studentHeaderRect = this.studentHeader.current.getBoundingClientRect();
      this.isStudentHeaderOverlap = this.isOverlap(studentRect, studentHeaderRect) && this.isInStudentSelection;
    }
  }

  @action
  public setMainScrollTop(scrollTop: number) {
    this.mainMenuListScrollTop = scrollTop;
    this.scrollMode = true;
  }

  @action
  public setSubScrollTop(scrollTop: number) {
    this.subMenuListScrollTop = scrollTop;
    this.scrollMode = true;
  }

  @action
  public setDepartmentScrollTop(scrollTop: number) {
    this.departmentListScrollTop = scrollTop;
    this.scrollMode = true;
  }

  @action
  public setStudentScrollTop(scrollTop: number) {
    this.studentListScrollTop = scrollTop;
    this.scrollMode = true;
  }

  @action.bound
  public handleBack() {
    this.isInSubMenu = false;
    this.isInDepartmentSelection = false;
    this.isInStudentSelection = false;
    this.showMenuItemsFadeAnimationWithIcons = true;
    this.scrollMode = false;

    if (this.selectedMenuItem) {
      const selectedMenuItem = window.document.getElementsByClassName(
        `menu-item-link-${this.selectedMenuItem.name}`,
      )[0] as HTMLElement | undefined;
      selectedMenuItem?.focus();
    }
  }

  @computed
  get isInMainMenu(): boolean {
    return !this.isInSubMenu && !this.isInDenseSubMenu && !this.isInDepartmentSelection && !this.isInStudentSelection;
  }

  public focusFirstItem() {
    const firstMenuItem = window.document.getElementsByClassName('menu-item-link-first')[0] as HTMLElement | undefined;
    firstMenuItem?.focus();
  }

  @computed
  get currentRoute(): string {
    return this._currentRoute;
  }

  @computed
  get selectedDepartment(): IMenuDepartment | undefined {
    return this.departments.find((department) => department.id === this._selectedDepartmentId);
  }

  @computed
  get currentRouteBelongsToBottomViewItem(): boolean {
    return !!this.bottomMenuItems.find((item) => {
      return item.route === this.currentRoute;
    });
  }

  @computed
  get selectedMenuItem(): IMenuItem | undefined {
    // first we search for the current route in all main menu item routes
    const selectedMainMenuItem = this.menuItems.find((item) => this.isRouteMatchingCurrent(item.route));

    if (selectedMainMenuItem) {
      return selectedMainMenuItem;
    } else {
      // if we couldn't find it in the main menu item routes, we check if the current route is from a sub menu item

      return this.menuItems.find((item) => {
        return !!item.subMenuItems?.some((sub) => sub === this.selectedSubMenuItem);
      });
    }
  }

  @computed
  get selectedMenuItemIndex(): number | undefined {
    if (this.selectedMenuItem) {
      const selectedRoute = this.selectedMenuItem.route;
      return this.menuItems.findIndex((i) => i.route === selectedRoute);
    }
    return undefined;
  }

  @computed
  get selectedSubMenuItemIndex(): number | undefined {
    if (this.selectedSubMenuItem && this.selectedMenuItem?.subMenuItems) {
      return this.selectedMenuItem.subMenuItems.findIndex((item) => {
        return item === this.selectedSubMenuItem;
      });
    }
    return undefined;
  }

  @computed
  get selectedDepartmentItemIndex(): number {
    const index = this.departments.findIndex((department) => department.id === this._selectedDepartmentId);
    if (index === -1) {
      return 0; // all departments, first item
    } else {
      return index + 1;
    }
  }

  @computed
  get selectedStudentItemIndex(): number {
    const index = this.students.findIndex((student) => student.id === this._selectedStudentId);
    if (index === -1) {
      return 0; // all students, first item
    } else {
      return index + 1;
    }
  }

  @computed
  get selectedStudentName(): string {
    const index = this.students.findIndex((student) => student.id === this._selectedStudentId);
    if (index === -1) {
      return this.allStudentsText; // all students, first item
    } else {
      return this.students[index].name;
    }
  }

  @computed
  get selectedStudentImage(): string | undefined {
    const index = this.students.findIndex((student) => student.id === this._selectedStudentId);
    if (index === -1) {
      return undefined;
    } else {
      return this.students[index].image;
    }
  }

  @computed
  get isUserItemSelected(): boolean {
    return !!(this.userRoute && this.currentRoute === this.userRoute);
  }

  @computed
  get selectedSubMenuItem(): ISubMenuItem | undefined {
    for (const item of this.menuItems) {
      const subMenuItem = item.subMenuItems?.find(
        (sub) => this.isRouteMatchingCurrent(sub.route) || sub.alternateRoutes?.includes(this.currentRoute),
      );
      if (subMenuItem) {
        return subMenuItem;
      }
    }

    return undefined;
  }

  @computed
  get selectedBottomViewItem(): ISubMenuItem | undefined {
    if (this.currentRouteBelongsToBottomViewItem) {
      return this.bottomMenuItems.find((item) => {
        return item.route === this.currentRoute;
      });
    }

    return undefined;
  }

  @computed
  get selectedMainMenuItemHasSubMenuItems(): boolean {
    return !!(
      this.selectedMenuItem &&
      this.selectedMenuItem.subMenuItems &&
      this.selectedMenuItem.subMenuItems.length > 0
    );
  }

  @computed
  get isInDenseSubMenu(): boolean {
    return !!(this.selectedMenuItem && this.selectedMenuItem.showDenseMenuItems && this.isInSubMenu);
  }

  @computed
  get subMenuHeaderTitle(): string {
    if (this.selectedMenuItem) {
      return this.selectedMenuItem.name;
    }

    return '';
  }

  @computed
  get subMenuHeaderTag(): string | undefined {
    return this.selectedMenuItem?.tag;
  }

  @computed
  get indicatorSelectionIndex(): number | undefined {
    if (this.isInDepartmentSelection) {
      return this.selectedDepartmentItemIndex;
    } else if (this.isInStudentSelection) {
      return this.selectedStudentItemIndex;
    } else if (this.isInSubMenu) {
      return this.selectedSubMenuItemIndex;
    } else {
      return this.selectedMenuItemIndex;
    }
  }

  @computed
  get selectedDepartmentId(): number {
    return this._selectedDepartmentId;
  }

  @computed
  get selectedStudentId(): number {
    return this._selectedStudentId;
  }

  @computed
  get mainMenuHeaderSubTitle(): string {
    if (this.departments.length > 0 && this.selectedDepartmentId > -1) {
      const selectedDepartment = this.departments.find((department) => department.id === this.selectedDepartmentId);
      return selectedDepartment ? selectedDepartment.name : this.schoolName;
    }
    return this.schoolName;
  }

  @computed
  get isMainMenuAccessible(): boolean {
    return !this.isInSubMenu && !this.isInDepartmentSelection && !this.isInStudentSelection;
  }

  @computed
  get collapsed(): boolean {
    return this._collapsed;
  }

  @computed
  get isSomeItemSelected(): boolean {
    return !!this.selectedMenuItem || !!this.selectedBottomViewItem || this.isUserItemSelected;
  }

  @action
  public handleInvalidMenuItemSelected(onInvalidMenuItemSelected: (() => void) | undefined) {
    if (onInvalidMenuItemSelected && this.menuItems.length && !this.isSomeItemSelected) {
      onInvalidMenuItemSelected();
    }
  }

  @action.bound
  public setCollapsed(value: boolean) {
    this._collapsed = value;
    if (value) {
      this.onCollapseCallback && this.onCollapseCallback();
    } else {
      this.onExpandCallback && this.onExpandCallback();
    }
  }

  // match the route even it is the part of dynamic route with params
  private isRouteMatchingCurrent(route?: string) {
    if (this.currentRoute.startsWith('/timetabling/timetables')) {
      return route === this.currentRoute;
    }
    return (
      route === this.currentRoute ||
      (route &&
        this.currentRoute &&
        this.currentRoute.indexOf(route) === 0 &&
        this.currentRoute.charAt(route.length) === '/')
    );
  }
}
