import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { ITeacherForm } from '@/pages/master-data/teacher/teacher-form';
import { TeacherStore, ITeacherRow } from '@/pages/master-data/teacher/teacher-store';
import ConfigStore from '@/stores/config-store';
import useStore from '@/hooks/useStore';
import { ListView } from '@/components/list-view/list-view';
import { DeprecatedSearchBarRestriction } from '@/ui-components/deprecated-search-bar/deprecated-search-bar';
import { TeacherViewApi } from '@/stores/api-store';

const MasterDataTeacher = () => {
  const [form] = Form.useForm<ITeacherForm>();
  const [store] = useState(() => new TeacherStore(form));
  const { t } = useTranslation();
  const configStore = useStore(ConfigStore);

  useEffect(() => {
    store.fetchData();
  }, [configStore.selectedDepartmentId, store]);

  return (
    <ListView<ITeacherRow>
      title={t('general.teachers')}
      isPageLoading={store.isDataLoading}
      deprecatedSearchBar={{
        value: store.searchInputValue,
        onChange: (value) => store.setSearchInputValue(value),
        onChangeSelectOptions: (option) => store.setSelectedOptions(option),
        selectedOptions: store.selectedOptions,
        restriction: DeprecatedSearchBarRestriction.ONLY_ONE_OPTION_PER_GROUP,
        color: 'white',
        placeholder: t('general.search'),
      }}
      table={{
        columns: store.columns,
        rowData: store.filteredRows,
        rowSelection: {
          selectedRowKeys: store.selectedRowKeys,
          type: 'checkbox',
          onChange: (keys) => store.setSelectedRowKeys(keys),
        },
        shadow: true,
      }}
      actions={store.listViewActions}
      selectedRowsActions={store.selectedRowsActions}
      customSorting={
        store.canEdit
          ? {
              onSave: (order) => {
                TeacherViewApi.createCustomOrderForTeachers(order).then(() => {
                  store.fetchTeachers();
                });
              },
              onDelete: () => {
                TeacherViewApi.deleteCustomOrderForTeachers().then(() => {
                  store.fetchTeachers();
                });
              },
            }
          : undefined
      }
    />
  );
};

export default observer(MasterDataTeacher);
