import React, { ReactNode } from 'react';
import { Col } from 'antd';
import './wu-form-col.less';
import clsx from 'clsx';

type ColumnSize = 1 | 1.5 | 1.25 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 21;

interface IProps {
  lg: ColumnSize;
  /**
   * Usually you only need to set the lg prop - however, if you need different responsive behaviour of columns,
   * you can optionally specify the md prop.
   */
  md?: ColumnSize;
  offset?: ColumnSize;
  children?: ReactNode;
  removePadding?: boolean;
  /**
   * If set, it removes the padding left/right and adds padding 24px to top.
   * Use this if you are nesting columns inside of columns to correct the padding of your right most column.
   */
  lastNestedColumn?: boolean;
  /**
   * Use this if you have e.g. 2 columns next to each other, and you want them to prevent to break into separate rows
   */
  preventBreak?: boolean;
  flex?: number;
}

const sizeToMdSpan = (size: ColumnSize): number => {
  switch (size) {
    case 1:
      return 24;
    case 1.5:
      return 16;
    case 1.25:
      return 14;
    case 2:
      return 12;
    case 3:
      return 8;
    case 4:
      return 6;
    case 5:
      return 5;
    case 6:
      return 3;
    case 7:
      return 2;
    case 8:
      return 1;
    case 21:
      return 21;
  }
};

export const WUFormCol = (props: IProps) => {
  const className = clsx('wu-form-col', {
    'remove-padding': props.removePadding,
    'inline-element': props.lastNestedColumn,
  });

  return (
    <Col
      className={className}
      style={{ flex: props.flex ?? undefined }}
      xs={props.preventBreak ? undefined : 24}
      sm={props.preventBreak ? undefined : 24}
      md={props.preventBreak ? undefined : 24}
      lg={{
        span: sizeToMdSpan(props.lg),
        offset: props.offset ? sizeToMdSpan(props.offset) : 0,
      }}
    >
      {props.children}
    </Col>
  );
};
