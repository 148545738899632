import React, { PropsWithChildren, ReactNode } from 'react';

import { Col, Row } from '@/ui-components';

import './deprecated-fullsize-modal-wrapper.less';

const responsiveColProps = {
  xs: { span: 22, offset: 1 },
  sm: { span: 22, offset: 1 },
  md: { span: 22, offset: 1 },
  lg: { span: 20, offset: 2 },
  xl: { span: 18, offset: 3 },
  xxl: { span: 16, offset: 4 },
};

interface IProps {
  className?: string;
  footerChildren?: ReactNode;
}

/**
 * @deprecated in favor of PageHeader (WU-10158)
 */
export const DeprecatedFullsizeModalWrapper = (props: PropsWithChildren<IProps>) => {
  return (
    <div className={'fullsize-modal-wrapper-container' + (props.className ? ' ' + props.className : '')}>
      <Row className="fullsize-modal-wrapper-content">
        <Col {...responsiveColProps}>{props.children}</Col>
      </Row>
      {props.footerChildren && (
        <div className="fullsize-modal-wrapper-footer">
          <Row>
            <Col {...responsiveColProps}>{props.footerChildren}</Col>
          </Row>
        </div>
      )}
    </div>
  );
};
export default DeprecatedFullsizeModalWrapper;
