import { JSONContent } from '@tiptap/react';

export function transformJsonToMarkdown(json: JSONContent) {
  let markdown = '';
  if (!json.content) return markdown;

  json.content.forEach((node) => {
    switch (node.type) {
      case 'heading':
        markdown += parseHeading(node);
        break;

      case 'paragraph': {
        markdown += parseParagraph(node);
        break;
      }
      case 'orderedList': {
        markdown += parseList(node, 1, '1.');
        break;
      }
      case 'bulletList': {
        markdown += parseList(node, 1, '-');
        break;
      }
    }
  });

  return markdown;
}

function parseHeading(heading: JSONContent) {
  let markdown = '';

  if (!heading.content) {
    return markdown;
  }

  // add # infront of heading
  const level = heading.attrs?.level ?? 1;
  markdown =
    Array.from({ length: level })
      .map(() => '#')
      .join('') + ' ';

  heading.content.forEach((contentNode: JSONContent) => {
    switch (contentNode.type) {
      case 'text':
        markdown += `${parseTextNode(contentNode)}`;
        break;
    }
  });

  return markdown + '\n\n';
}

function parseTextNode(textNode: JSONContent) {
  let textWithMarks = '';

  if (!textNode.text) {
    return textWithMarks;
  }

  if (!textNode.marks) {
    return textNode.text; // do not trim whitespaces here
  }

  // this is either empty or a whitespace, depending if the textNode.text has a space at
  // the beginning of the word
  let prefix = textNode.text[0] === ' ' ? ' ' : '';

  // this is either empty or a whitespace, depending if the textNode.text has a space at
  // the end of the word
  let suffix = textNode.text[textNode.text.length - 1] === ' ' ? ' ' : '';

  textNode.marks?.forEach((mark) => {
    switch (mark.type) {
      case 'bold':
        textWithMarks += '**';
        break;
      case 'italic':
        textWithMarks += '_';
        break;
      case 'link':
        prefix = '';
        textWithMarks += `[${textNode.text}](${mark.attrs?.href ?? ''})`;
        suffix = '';
        break;
    }
  });

  const containsLink = !!textNode.marks.find((mark) => mark.type === 'link');

  if (!containsLink) {
    textWithMarks += suffix === ' ' ? textNode.text.slice(0, -1) : textNode.text;
  }

  textNode.marks?.reverse().forEach((mark) => {
    switch (mark.type) {
      case 'bold':
        textWithMarks += '**';
        break;
      case 'italic':
        textWithMarks += '_';
        break;
    }
  });

  return prefix + textWithMarks + suffix;
}

function parseParagraph(paragraph: JSONContent, newLine = '\n\n') {
  let markdown = '';

  if (!paragraph.content) {
    markdown += newLine;

    return markdown;
  }

  paragraph.content.forEach((contentNode: JSONContent) => {
    if (contentNode.type !== 'text') {
      return;
    }

    markdown += parseTextNode(contentNode);
  });

  markdown += newLine;

  return markdown;
}

function parseList(list: JSONContent, level = 1, prefix = '-') {
  let markdown = '';

  if (!list.content) {
    markdown += '\n\n';
    return markdown;
  }

  list.content.forEach((listItem: JSONContent) => {
    if (listItem.type !== 'listItem') {
      return;
    }

    listItem.content?.forEach((node) => {
      switch (node.type) {
        case 'paragraph':
          const indent = level > 1 ? Array.from({ length: level }).join('\t') : '';
          markdown += `${indent}${prefix} ${parseParagraph(node, '\n')}`;
          break;
        case 'orderedList':
          markdown += parseList(node, level + 1, '1.');
          break;
        case 'bulletList':
          markdown += parseList(node, level + 1, '-');
          break;
      }
    });
  });

  if (level === 1) {
    markdown += '\n\n';
  }

  return markdown;
}
