import { observer } from 'mobx-react-lite';
import React, { Ref, useCallback } from 'react';

import useStore from '@/hooks/useStore';
import Keys from '@/types/keys';
import MainMenuItemList from '@/ui-components/navigation-bar/menu-item-list/main-menu-item-list/main-menu-item-list';
import SubMenuItemList from '@/ui-components/navigation-bar/menu-item-list/sub-menu-item-list/sub-menu-item-list';
import MenuItem, { MenuItemType } from '@/ui-components/navigation-bar/menu-item/menu-item';
import { IMenuItem, ISubMenuItem, NavigationBarStore } from '@/ui-components/navigation-bar/navigation-bar-store';
import SelectionIndicator from '@/ui-components/navigation-bar/selection-indicator/selection-indicator';
import nameToInitials from '@/utils/name-to-initials/name-to-initials';
import { Avatar, Icon } from '@/ui-components';

import './menu-item-list.less';

interface IProps {
  mainMenuRef: Ref<HTMLDivElement>;
  subMenuRef: Ref<HTMLDivElement>;
  departmentRef: Ref<HTMLDivElement>;
  studentRef: Ref<HTMLDivElement>;
  onNavigate: (item: IMenuItem, subMenuItem?: ISubMenuItem) => void;
  menuListContainerTop: number;
  menuBottomViewHeight: number;
}

const getDepartmentItems = (store: NavigationBarStore, onClick: (val: number) => void): JSX.Element[] => {
  const allItem = (
    <MenuItem
      key="all_departments"
      name={store.allText}
      selected={store.selectedDepartmentId === 0}
      type={MenuItemType.SUB_MENU_ITEM}
      onClick={() => onClick(0)}
      isDense={true}
      isAccessible={store.isInDepartmentSelection}
    />
  );

  const items = store.departments.map((department, index) => {
    return (
      <MenuItem
        key={department.id}
        name={department.name}
        selected={store.selectedDepartmentId === department.id}
        type={MenuItemType.SUB_MENU_ITEM}
        onClick={() => {
          onClick(department.id);
        }}
        onKeyDown={(event) => {
          if (event.key === Keys.Enter) onClick(department.id);
          else if (event.key === Keys.Escape) store.handleBack();
        }}
        isDense={true}
        isAccessible={store.isInDepartmentSelection}
        isFirst={index === 0}
      />
    );
  });

  return [allItem, ...items];
};

const getStudentItems = (store: NavigationBarStore, onClick: (val: number) => void): JSX.Element[] => {
  const allItem = (
    <MenuItem
      key="all_students"
      name={store.allStudentsText}
      selected={store.selectedStudentId === -1}
      type={MenuItemType.SUB_MENU_ITEM}
      onClick={() => onClick(-1)}
      isDense={false}
      isAccessible={store.isInStudentSelection}
      icon={<Avatar className="all-students-avatar" shape="circle" size={40} icon={<Icon type="students" />} />}
    />
  );

  const items = store.students.map((student, index) => {
    return (
      <MenuItem
        key={student.id}
        name={student.name}
        selected={store.selectedStudentId === student.id}
        type={MenuItemType.SUB_MENU_ITEM}
        onClick={() => {
          onClick(student.id);
        }}
        onKeyDown={(event) => {
          if (event.key === Keys.Enter) onClick(student.id);
          else if (event.key === Keys.Escape) store.handleBack();
        }}
        isDense={false}
        isAccessible={store.isInStudentSelection}
        isFirst={index === 0}
        icon={
          <Avatar shape="circle" size={40} src={student.image}>
            {nameToInitials(student.name)}
          </Avatar>
        }
      />
    );
  });

  return [allItem, ...items];
};

const MenuItemList = observer((props: IProps) => {
  const store = useStore(NavigationBarStore);

  let scrollTop = store.mainMenuListScrollTop;
  if (store.isInSubMenu) {
    scrollTop = store.subMenuListScrollTop;
  } else if (store.isInDepartmentSelection) {
    scrollTop = store.departmentListScrollTop;
  } else if (store.isInStudentSelection) {
    scrollTop = store.studentListScrollTop;
  }

  const { onNavigate } = props;
  const handleSubMenuItemClickFactory = useCallback(
    (item: IMenuItem, subMenuItem?: ISubMenuItem) => () => {
      store.scrollMode = false;
      onNavigate(item, subMenuItem);
    },
    [onNavigate, store.scrollMode],
  );

  const handleDepartmentItemClick = (id: number) => {
    store.scrollMode = false;
    store.onSelectDepartment && store.onSelectDepartment(id);
  };

  const handleStudentItemClick = (id: number) => {
    store.scrollMode = false;
    store.onSelectStudent && store.onSelectStudent(id);
  };

  const departmentItems = getDepartmentItems(store, handleDepartmentItemClick);
  const studentItems = store.students.length > 3 ? getStudentItems(store, handleStudentItemClick) : [];

  let subMenuItems: any[] = [];

  if (store.selectedMenuItem && store.selectedMenuItem.subMenuItems) {
    subMenuItems = store.selectedMenuItem.subMenuItems.map((item, index) => {
      return (
        <MenuItem
          key={item.route}
          name={item.name}
          icon={item.icon}
          selected={item === store.selectedSubMenuItem}
          badge={item.badge}
          type={MenuItemType.SUB_MENU_ITEM}
          route={item.route}
          onClick={handleSubMenuItemClickFactory(store.selectedMenuItem!, item)}
          isDense={!!item.isDense}
          testId={item.testId}
          isAccessible={!!store.isInSubMenu}
          isFirst={index === 0}
        />
      );
    });
  }

  return (
    <div className="menu-item-list-container">
      <MainMenuItemList
        ref={props.mainMenuRef}
        onNavigate={props.onNavigate}
        menuHeaderHeight={props.menuListContainerTop}
        menuBottomViewHeight={props.menuBottomViewHeight}
      />
      <SubMenuItemList
        collapsed={store.collapsed}
        showDense={store.isInDenseSubMenu}
        isInSubMenu={!!store.isInSubMenu}
        showMenuItemsFadeAnimationWithIcons={store.showMenuItemsFadeAnimationWithIcons}
        ref={props.subMenuRef}
        handleScroll={(e: any) => {
          store.setSubScrollTop(e.target.scrollTop);
        }}
        items={subMenuItems}
        menuHeaderHeight={props.menuListContainerTop}
        menuBottomViewHeight={props.menuBottomViewHeight}
      />
      <SubMenuItemList
        collapsed={store.collapsed}
        showDense={true}
        isInSubMenu={store.isInDepartmentSelection}
        showMenuItemsFadeAnimationWithIcons={store.showMenuItemsFadeAnimationWithIcons}
        ref={props.departmentRef}
        handleScroll={(e: any) => {
          store.setDepartmentScrollTop(e.target.scrollTop);
        }}
        items={departmentItems}
        menuHeaderHeight={props.menuListContainerTop}
        menuBottomViewHeight={props.menuBottomViewHeight}
      />
      <SubMenuItemList
        collapsed={store.collapsed}
        showDense={false}
        isInSubMenu={store.isInStudentSelection}
        showMenuItemsFadeAnimationWithIcons={store.showMenuItemsFadeAnimationWithIcons}
        ref={props.studentRef}
        handleScroll={(e: any) => {
          store.setStudentScrollTop(e.target.scrollTop);
        }}
        items={studentItems}
        menuHeaderHeight={props.menuListContainerTop}
        menuBottomViewHeight={props.menuBottomViewHeight}
      />
      <SelectionIndicator
        isShownInBottomView={false}
        scrollTop={scrollTop}
        menuListContainerTop={props.menuListContainerTop}
      />
    </div>
  );
});

export default MenuItemList;
