// must match the visit custom dimension in matomo
export enum VisitCustomDimension {
  ROLE = 1,
}

// must match the action custom dimension in matomo
export enum ActionCustomDimension {}

export type PageView = 'Today';

export type EventCategory = 'Messaging' | 'TimetableNew';

type EventActionMessaging =
  | 'SentMessage'
  | 'SentMessageRecipientOption'
  | 'SentMessageNoReply'
  | 'SentMessageCcOption'
  | 'SentMessageReadReceipt';
type EventActionTimetableNew =
  | 'TimetableClassViewLoaded'
  | 'TimetableTeacherViewLoaded'
  | 'TimetableRoomViewLoaded'
  | 'TimetableStudentViewLoaded'
  | 'TimetableSubjectViewLoaded'
  | 'TimetableResourceViewLoaded'
  | 'TimetableDateViewButtonClick'
  | 'TimetableDayColumnDateClick'
  | 'TimetablePeriodClick'
  | 'TimetableToolbarClick'
  | 'TimetableOnboardingShown';

export type EventAction = EventActionMessaging | EventActionTimetableNew;
