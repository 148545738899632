import { action, computed, observable } from 'mobx';

import { TimetableEntityType } from '@te/standard/stores/timetable-root-store';
import {
  TimetableFormatDto,
  TimetableGridDto,
  TimeGridTypeEnum,
  ResourceTypeEnum,
  TimetableTypeEnum,
} from '@untis/wu-rest-view-api/api';
import { Store } from '@/types/store';

const TYPES_WITH_BREAKS: TimetableEntityType[] = ['teacher', 'room', 'my-teacher'];

@Store()
export class TimetableFormatStore {
  @observable private _timetableEntityType: TimetableEntityType | undefined;
  @observable private _timetableGridDto: TimetableGridDto | undefined;
  @observable private _selectedTimetableFormatId: number | undefined;

  @action
  reset() {
    this._timetableEntityType = undefined;
    this._timetableGridDto = undefined;
    this._selectedTimetableFormatId = undefined;
  }

  @action
  init(timetableEntityType: TimetableEntityType) {
    this._timetableEntityType = timetableEntityType;
  }

  @action
  setFormats(timetableGridDto: TimetableGridDto) {
    this._timetableGridDto = timetableGridDto;
    switch (this._timetableEntityType) {
      case 'student':
      case 'my-student':
        this._selectedTimetableFormatId = timetableGridDto.studentFormat;
        break;
      case 'class':
      case 'my-class':
        this._selectedTimetableFormatId = timetableGridDto.classFormat;
        break;
      case 'teacher':
      case 'my-teacher':
        this._selectedTimetableFormatId = timetableGridDto.teacherFormat;
        break;
      case 'subject':
        this._selectedTimetableFormatId = timetableGridDto.subjectFormat;
        break;
      case 'room':
        this._selectedTimetableFormatId = timetableGridDto.roomFormat;
        break;
      case 'resource':
        this._selectedTimetableFormatId = timetableGridDto.resourceFormat;
        break;
    }

    const selectedFormatIdUndefined =
      this._selectedTimetableFormatId === null || this._selectedTimetableFormatId === undefined;
    const hasFormatDefinitions = timetableGridDto.formatDefinitions.length > 0;
    if (selectedFormatIdUndefined && hasFormatDefinitions) {
      this._selectedTimetableFormatId = timetableGridDto.formatDefinitions[0].id;
    }
  }

  @computed
  get selectedTimetableFormatId(): number | undefined {
    return this._selectedTimetableFormatId;
  }

  @computed
  get timetableFormat(): TimetableFormatDto | undefined {
    return this._timetableGridDto?.formatDefinitions.find(
      (timetableFormat) => timetableFormat.id === this._selectedTimetableFormatId,
    );
  }

  @computed
  get availableTimetableFormats(): TimetableFormatDto[] {
    return this._timetableGridDto?.formatDefinitions ?? [];
  }

  @computed
  get timetableEntityType(): TimetableEntityType | undefined {
    return this._timetableEntityType;
  }

  @computed
  get isUntisGrid(): boolean {
    return this.timetableFormat?.timeGridType === TimeGridTypeEnum.LESSON_GRID;
  }

  @computed
  get showBreaks(): boolean {
    if (this._timetableEntityType) {
      return this.isUntisGrid && TYPES_WITH_BREAKS.includes(this._timetableEntityType);
    } else {
      return false;
    }
  }

  @computed
  get isMyTimetable(): boolean {
    return (
      this._timetableEntityType === 'my-student' ||
      this._timetableEntityType === 'my-class' ||
      this._timetableEntityType === 'my-teacher'
    );
  }

  @computed
  get resourceTypeEnum(): ResourceTypeEnum {
    switch (this._timetableEntityType) {
      case 'student':
      case 'my-student':
        return ResourceTypeEnum.STUDENT;
      case 'class':
      case 'my-class':
        return ResourceTypeEnum.CLASS;
      case 'teacher':
      case 'my-teacher':
        return ResourceTypeEnum.TEACHER;
      case 'subject':
        return ResourceTypeEnum.SUBJECT;
      case 'room':
        return ResourceTypeEnum.ROOM;
      case 'resource':
        return ResourceTypeEnum.RESOURCE;
      default:
        return ResourceTypeEnum.CLASS;
    }
  }

  @computed
  get timetableTypeEnum(): TimetableTypeEnum {
    switch (this._timetableEntityType) {
      case 'student':
      case 'class':
      case 'teacher':
      case 'subject':
      case 'room':
      case 'resource':
        return TimetableTypeEnum.STANDARD;
      case 'my-class':
      case 'my-student':
      case 'my-teacher':
        return TimetableTypeEnum.MY_TIMETABLE;
      default:
        return TimetableTypeEnum.STANDARD;
    }
  }

  @computed
  get showStartEndTimeOfSlots(): boolean {
    return this.timetableFormat?.showStartEndTimeOfSlots ?? false;
  }

  @action
  setSetSelectedTimetableFormatId(selectedTimetableFormatId: number) {
    this._selectedTimetableFormatId = selectedTimetableFormatId;
  }
}
