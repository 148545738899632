import { t } from 'i18next';
import { action, computed, observable } from 'mobx';

import { getSubMenuItem, MenuItemEnum, MenuPermissionEnum, SubMenuItemEnum } from '@/components/menu/menu-items';
import ConfigStore from '@/stores/config-store';
import TokenStore, { TokenPermission } from '@/stores/token-store';
import { inject, Store } from '@/types/store';
import { IMenuItem, IPlatformMenuItem, ISubMenuItem } from '@/ui-components/navigation-bar/navigation-bar-store';
import HorizonStore from '@tt/stores/horizon-store';
import { IPlatformApplicationMenuItem } from '@pa/types/IPlatformApplicationMenuItem';
import { PLATFORM_APPLICATION_DEFAULT_ICON } from '@pa/stores/platform-store';
import TodayMessagesOfTheDayStore from '@/pages/today/stores/today-messages-of-the-day-store';
import IncomingMessagesStore from '@/pages/messages/stores/incoming-messages-store';
import ModalStore from '@/stores/modal-store';

/**
 * This store holds the information about how the main- and sub-menu items in the frontend are structured
 * It also contains state that is important for the menu (for example: information about the badge values for
 * certain menu items)
 *
 * It holds the config store since the config store holds all the information that is needed to decide whether a
 * menu item should be available or not.
 */
@Store()
export class MenuStore {
  // we have to disable the menu when the embedded WU shows a modal dialog (DOJO or React)
  @observable public isDojoModalOpen: boolean = false;
  @observable public isReactModalOpen: boolean = false;
  @observable private _expanded: boolean = true;

  private configStore = inject(ConfigStore);
  private horizonStore = inject(HorizonStore);
  private tokenStore = inject(TokenStore);
  private todayMessagesOfTheDayStore = inject(TodayMessagesOfTheDayStore);
  private incomingMessagesStore = inject(IncomingMessagesStore);
  private modalStore = inject(ModalStore);

  constructor() {
    this.refreshMenuBadgeCounts();
  }

  async refreshMenuBadgeCounts() {
    try {
      const menuBadgeCountPromises = [this.todayMessagesOfTheDayStore.getUnreadMessagesOfTheDayCount()];

      // only check for unread messages if the user has the permission to read messages
      if (this.configStore.checkMenuItemPermission(MenuItemEnum.MESSAGE_CENTER_2021)) {
        menuBadgeCountPromises.push(this.incomingMessagesStore.getUnreadMessagesCount());
      }

      await Promise.all(menuBadgeCountPromises);
    } catch (error) {
      console.error('refreshMenuBadgeCounts', error);
    }
  }

  @action
  setExpanded = (value: boolean) => {
    this._expanded = value;
  };

  @computed
  get disabled(): boolean {
    return this.isDojoModalOpen || this.isReactModalOpen || this.modalStore.isNavigationDisabled;
  }

  @computed
  private get todayMenuItem(): IMenuItem | undefined {
    if (this.configStore.checkMenuItemPermission(MenuItemEnum.TODAY)) {
      return {
        name: t('menu.menuItems.today.today'),
        icon: 'navigation-dashboard',
        route: '/today',
        testId: 'menu-today-item',
        // badge: this.todayMessagesOfTheDayStore.badge,
      };
    }

    return undefined;
  }

  @computed
  private get overviewMenuItem(): IMenuItem | undefined {
    if (this.configStore.checkMenuItemPermission(MenuItemEnum.MY_DATA)) {
      return {
        name: t('menu.menuItems.overview.overview'),
        icon: 'my_data',
        route: '/overview',
        testId: 'overview-item',
      };
    }

    return undefined;
  }

  @computed
  public get platformApplicationMenuItems(): IPlatformMenuItem[] | undefined {
    if (this.configStore.platformApplicationMenuItems) {
      return this.configStore.platformApplicationMenuItems.map((item: IPlatformApplicationMenuItem) => {
        return {
          name: item.name,
          icon: item.icon ? item.icon : PLATFORM_APPLICATION_DEFAULT_ICON,
          route: `/platform-application/${item.name}`,
          logoutUrl: item.logoutUrl,
        };
      });
    }
    return undefined;
  }

  @computed
  private get messagesMenuItem(): IMenuItem | undefined {
    if (this.configStore.checkMenuItemPermission(MenuItemEnum.MESSAGE_CENTER_2021)) {
      const subMenuItems: ISubMenuItem[] = [];
      subMenuItems.push({
        name: t('general.inbox'),
        icon: 'admin-messages',
        route: '/messages/inbox',
        badge: this.incomingMessagesStore.badge,
      });
      this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.MESSAGE_CENTER_2021_SENT_MESSAGES, subMenuItems);
      this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.MESSAGE_CENTER_2021_DRAFT_MESSAGES, subMenuItems);
      this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.MESSAGE_CENTER_2021_LISTS, subMenuItems);

      return {
        name: t('general.myMessages'),
        icon: 'admin-messages',
        route: subMenuItems[0].route,
        subMenuItems,
        badge: this.incomingMessagesStore.badge,
      };
    } else if (this.configStore.checkMenuItemPermission(MenuItemEnum.MESSAGE_CENTER)) {
      return {
        name: t('menu.bottomUpViewMenuItems.messages'),
        icon: 'admin-messages',
        route: '/message-center',
        badge: this.incomingMessagesStore.badge,
      };
    }

    return undefined;
  }

  @computed
  private get timetableMenuItem(): IMenuItem | undefined {
    const subMenuItems: ISubMenuItem[] = [];

    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_CLASSES_MY, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_TEACHERS_MY, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_STUDENTS_MY, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_CLASSES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_STUDENTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_TEACHERS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_ROOMS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_SUBJECTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_RESOURCES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_DAILY_OVERVIEW_CLASSES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_DAILY_OVERVIEW_TEACHERS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_DAILY_OVERVIEW_ROOMS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_OVERVIEW_CLASSES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_OVERVIEW_TEACHERS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_OVERVIEW_ROOMS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_OVERVIEW_RESOURCES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_CONTACT_HOURS, subMenuItems);

    if (subMenuItems.length > 0) {
      return {
        name: t('menu.menuItems.timetable.timetable'),
        icon: 'navigation-timetable',
        route: subMenuItems[0].route,
        subMenuItems,
      };
    }

    return undefined;
  }

  @computed
  private get timetableNewMenuItem(): IMenuItem | undefined {
    const subMenuItems: ISubMenuItem[] = [];

    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_NEW_TEACHERS_MY, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_NEW_CLASSES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_NEW_STUDENTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_NEW_TEACHERS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_NEW_ROOMS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_NEW_SUBJECTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_NEW_RESOURCES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_SETTINGS_NEW, subMenuItems);

    if (subMenuItems.length > 0) {
      return {
        name: t('menu.menuItems.timetable.timetable'),
        icon: 'navigation-timetable',
        route: subMenuItems[0].route,
        tag: 'BETA',
        subMenuItems,
      };
    }

    return undefined;
  }

  @computed
  private get lessonsMenuItem(): IMenuItem | undefined {
    const subMenuItems: ISubMenuItem[] = [];

    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.MY_LESSONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TEACHER_LESSONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.CLASS_LESSONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.STUDENT_LESSONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.EXAMS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.EXAM_CALENDAR, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.EXAM_STATISTICS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.LESSONS_TASKS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.DAILY_TEACHER_LESSONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.DAILY_CLASS_LESSONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.YEARLY_TEACHER_LESSONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.YEARLY_CLASS_LESSONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.STUDENT_GROUPS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.LUX_TEACHER_SALARY, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.LESSONS_SETTINGS, subMenuItems);

    if (subMenuItems.length > 0) {
      return {
        name: t('menu.menuItems.lessons.lessons'),
        icon: 'navigation-lessons',
        route: subMenuItems[0].route,
        subMenuItems,
      };
    }

    return undefined;
  }

  @computed
  private get bookingMenuItem(): IMenuItem | undefined {
    const subMenuItems: ISubMenuItem[] = [];

    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.BOOK_ROOM, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.NEW_ACTIVITY, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.APPOINTMENTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.BOOK_RESOURCE, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.MY_BOOKINGS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.LIST_OF_ROOMS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.LIST_OF_RESOURCES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.BOOKINGS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TODO_LIST, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.ROOM_CONFLICTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.RESOURCE_CONFLICTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.BOOKING_LOCK, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.BOOKING_REPORTS, subMenuItems);

    if (subMenuItems.length > 0) {
      return {
        name: t('menu.menuItems.booking.booking'),
        icon: 'navigation-book',
        route: subMenuItems[0].route,
        subMenuItems,
      };
    }

    return undefined;
  }

  @computed
  private get classRegisterMenuItem(): IMenuItem | undefined {
    const subMenuItems: ISubMenuItem[] = [];

    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.CLASS_REGISTER_OVERVIEW, subMenuItems);
    // remove when open periods are released
    // this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.OPEN_PERIODS_TEACHER, subMenuItems);
    // this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.OPEN_PERIODS_CLASS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.OPEN_PERIODS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.ABSENCES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.ABSENCE_TIMES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.CLASS_REGISTER_ENTRIES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.HOMEWORK, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.CLASS_SERVICES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.EXEMPTIONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.CLASS_REGISTER_REPORTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.CLASS_REGISTER_SETTINGS, subMenuItems);

    if (subMenuItems.length > 0) {
      return {
        name: t('menu.menuItems.classRegister.classRegister'),
        icon: 'navigation-class-register',
        route: subMenuItems[0].route,
        subMenuItems,
      };
    }

    return undefined;
  }

  @computed
  private get timetablingMenuItems(): IMenuItem | undefined {
    const subMenuItems: ISubMenuItem[] = [];
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLING_LESSONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLING_TIMETABLES, subMenuItems);
    const activeHorizon = this.horizonStore.activeHorizon;
    if (activeHorizon) {
      const activeHorizonId = activeHorizon.id;
      this.configStore.addSubMenuItemIfPermitted(
        SubMenuItemEnum.TIMETABLING_TIMETABLING,
        subMenuItems,
        undefined, //  for badge
        activeHorizonId,
      );
      this.configStore.addSubMenuItemIfPermitted(
        SubMenuItemEnum.TIMETABLING_BLOCKS_AND_COUPLINGS,
        subMenuItems,
        undefined, //  for badge
        activeHorizonId,
      );
      this.configStore.addSubMenuItemIfPermitted(
        SubMenuItemEnum.TIMETABLING_TIME_PREFERENCES,
        subMenuItems,
        undefined, //  for badge
        activeHorizonId,
      );
      this.configStore.addSubMenuItemIfPermitted(
        SubMenuItemEnum.TIMETABLING_MASTER_DATA_CONSTRAINTS,
        subMenuItems,
        undefined, //  for badge
        activeHorizonId,
      );
    }

    if (subMenuItems.length > 0) {
      return {
        name: t('menu.menuItems.timetabling.subMenuItems.timetabling'),
        icon: 'scheduling',
        route: '/timetabling/timetables',
        subMenuItems,
      };
    }
    return undefined;
  }

  @computed
  private get coursesMenuItem(): IMenuItem | undefined {
    const subMenuItems: ISubMenuItem[] = [];

    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.COURSES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.COURSE_TEMPLATES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.COURSES_PER_STUDENT, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.REGISTRATION_PERIODS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.COURSES_REPORTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.COURSE_CATEGORIES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.COURSE_CHOICE_CATEGORIES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.COURSE_MODULES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.STAGES_OF_STUDIES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.COURSES_SETTINGS, subMenuItems);

    if (subMenuItems.length > 0) {
      return {
        name: t('menu.menuItems.courses.courses'),
        icon: 'courses',
        route: subMenuItems[0].route,
        subMenuItems,
      };
    }

    return undefined;
  }

  @computed
  private get studentCoursesMenuItem(): IMenuItem | undefined {
    const subMenuItems: ISubMenuItem[] = [];

    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.COURSE_REGISTRATION, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.MY_COURSES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.COURSE_PREVIEW, subMenuItems);

    if (subMenuItems.length > 0) {
      return {
        name: t('menu.menuItems.courses.courses'),
        icon: 'courses',
        route: subMenuItems[0].route,
        subMenuItems,
      };
    }

    return undefined;
  }

  @computed
  private get substitutionPlanningMenuItem(): IMenuItem | undefined {
    const subMenuItems: ISubMenuItem[] = [];

    this.tokenStore.addSubMenuItemIfPermitted(SubMenuItemEnum.SUBSTITUTION_PLANNING_SUBSTITUTIONS, subMenuItems, [
      TokenPermission.SUBSTITUTION_PLANNING,
    ]);
    this.tokenStore.addSubMenuItemIfPermitted(SubMenuItemEnum.SUBSTITUTION_PLANNING_TEACHER_COUNTERS, subMenuItems, [
      TokenPermission.SUBSTITUTION_PLANNING,
    ]);
    this.tokenStore.addSubMenuItemIfPermitted(
      SubMenuItemEnum.SUBSTITUTION_PLANNING_TEACHER_ABSENCE_REASONS,
      subMenuItems,
      [TokenPermission.SUBSTITUTION_PLANNING],
    );
    this.tokenStore.addSubMenuItemIfPermitted(SubMenuItemEnum.SUBSTITUTION_PLANNING_SETTINGS, subMenuItems, [
      TokenPermission.SUBSTITUTION_PLANNING,
    ]);
    this.tokenStore.addSubMenuItemIfPermitted(SubMenuItemEnum.SUBSTITUTION_PLANNING_MY_ABSENCES, subMenuItems, [
      TokenPermission.OWN_TEACHER_ABSENCE_READ,
    ]);
    this.tokenStore.addSubMenuItemIfPermitted(SubMenuItemEnum.SP_SUBSTIUTION_REQUESTS, subMenuItems, [
      TokenPermission.SP_OWN_ASK_TEACHER_REQUESTS,
    ]);

    if (subMenuItems.length > 0) {
      return {
        name: t('menu.menuItems.substitutionPlanning.substitutionPlanning'),
        icon: 'navigation-substitution-planing',
        route: subMenuItems[0].route,
        subMenuItems,
        testId: 'menu-substitutionplanning-item',
      };
    }

    return undefined;
  }

  @computed
  private get masterDataMenuItem(): IMenuItem | undefined {
    const subMenuItems: ISubMenuItem[] = [];

    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.CLASSES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.STUDENTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.LEGAL_GUARDIAN, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.APPRENTICE_REPRESENTATIVES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TEACHERS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TEACHING_QUALIFICATIONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TEACHER_STATUS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.SUBJECTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.SUBJECT_GROUPS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.SUBJECT_TYPES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.ROOMS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.ROOM_GROUPS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.ROOM_TYPES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.BUILDINGS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.RESOURCES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.GROUPS_OF_RESOURCES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TYPES_OF_RESOURCES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.REASONS_OF_ABSENCE, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.EXCUSE_STATUS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.REASONS_OF_EXEMPTIONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.REMARK_CATEGORIES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.GROUPS_FOR_REMARK_CATEGORIES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.SERVICES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.STUDENT_PROPERTIES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.GRADING_SCHEMES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TYPES_OF_EXAMINATIONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TEACHING_METHODS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TYPES_OF_ACTIVITIES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.REDUCTION_REASONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TASKS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.DEPARTMENTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.HOLIDAYS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.SCHOOL_YEARS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIME_GRID, subMenuItems);

    subMenuItems.forEach((item) => (item.isDense = true));

    if (subMenuItems.length > 0) {
      return {
        name: t('menu.menuItems.masterData.masterData'),
        icon: 'navigation-master-data',
        route: subMenuItems[0].route,
        showDenseMenuItems: true,
        subMenuItems,
      };
    }

    return undefined;
  }

  @computed
  private get administrationMenuItem(): IMenuItem | undefined {
    const subMenuItems: ISubMenuItem[] = [];

    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.MESSAGES_OF_THE_DAY, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.NOVASCHEM, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.HAMBURG, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.EXPORT, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.ACTIVE_USERS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.RIGHTS_AND_ROLES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.USERS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.ASSIGNMENT_GROUPS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.LOCK_OUT_TIMES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.BOOKING_RESTRICTIONS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.ROOM_BLOCKINGS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.EXAM_BLOCKINGS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.LAYOUT_SETTINGS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.MONITOR_VIEWS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.BOOKING_FORMATS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_SETTINGS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.SETTINGS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.INTEGRATION, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.PARENT_TEACHER_DAYS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.PLAYGROUND, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.BACKUP, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.SYSTEM_LOCK, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.PLATFORM_APPLICATION, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.PRIVACY, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.ABOUT_WEBUNTIS, subMenuItems);

    subMenuItems.forEach((item) => (item.isDense = true));

    if (subMenuItems.length > 0) {
      return {
        name: t('menu.menuItems.administration.administration'),
        icon: 'navigation-administration',
        showDenseMenuItems: true,
        route: subMenuItems[0].route,
        subMenuItems,
      };
    }

    return undefined;
  }

  @computed
  private get contactDetailsMenuItem(): IMenuItem | undefined {
    if (this.configStore.checkMenuItemPermission(MenuItemEnum.CONTACT_DETAILS)) {
      return {
        name: t('menu.bottomUpViewMenuItems.contactDetails'),
        icon: 'admin-contact-details',
        route: '/contact-details',
      };
    }

    return undefined;
  }

  @computed
  private get driveMenuItem(): IMenuItem | undefined {
    if (this.configStore.checkMenuItemPermission(MenuItemEnum.DRIVE)) {
      return {
        name: t('menu.bottomUpViewMenuItems.drive'),
        icon: 'drive',
        route: '/drive',
      };
    }

    return undefined;
  }

  @computed
  private get playgroundMenuItem(): IMenuItem | undefined {
    if (this.configStore.playgroundUrl) {
      return {
        name: t('general.playground'),
        icon: 'playground',
        route: this.configStore.playgroundUrl,
      };
    }

    return undefined;
  }

  @computed
  private get studentAbsencesMenuItem(): IMenuItem | undefined {
    if (this.configStore.checkMenuItemPermission(MenuItemEnum.STUDENTABSENCES)) {
      return {
        name: t('menu.menuItems.classRegister.subMenuItems.absences'),
        icon: 'absences',
        route: '/student-absences',
      };
    }

    return undefined;
  }

  @computed
  private get studentExemptionsMenuItem(): IMenuItem | undefined {
    if (this.configStore.checkMenuItemPermission(MenuItemEnum.STUDENT_EXEMPTIONS)) {
      return {
        name: t('menu.menuItems.exemptions.exemptions'),
        icon: 'exemptions',
        route: '/student-exemptions',
      };
    }

    return undefined;
  }

  @computed
  private get studentClassServicesMenuItem(): IMenuItem | undefined {
    if (this.configStore.checkMenuItemPermission(MenuItemEnum.STUDENT_CLASS_SERVICES)) {
      return {
        name: t('menu.menuItems.classServices.classServices'),
        icon: 'class_services',
        route: '/student-class-services',
      };
    }

    return undefined;
  }

  @computed
  private get studentHomeworkMenuItem(): IMenuItem | undefined {
    if (this.configStore.checkMenuItemPermission(MenuItemEnum.STUDENT_HOMEWORK)) {
      return {
        name: t('menu.menuItems.homework.homework'),
        icon: 'homework',
        route: '/student-homework',
      };
    }

    return undefined;
  }

  @computed
  private get studentClassRegisterEntriesMenuItem(): IMenuItem | undefined {
    if (this.configStore.checkMenuItemPermission(MenuItemEnum.STUDENT_CLASSREG_ENTRIES)) {
      return {
        name: t('menu.menuItems.classregEntries.classregEntries'),
        icon: 'class_register_entries',
        route: '/student-class-register-entries',
      };
    }

    return undefined;
  }

  @computed
  private get studentExaminationsMenuItem(): IMenuItem | undefined {
    if (this.configStore.checkMenuItemPermission(MenuItemEnum.STUDENT_EXAMS)) {
      return {
        name: t('menu.menuItems.examinations.examinations'),
        icon: 'examinations',
        route: '/student-exams',
      };
    }

    return undefined;
  }

  @computed
  private get studentGradesMenuItem(): IMenuItem | undefined {
    if (this.configStore.checkMenuItemPermission(MenuItemEnum.STUDENT_GRADES)) {
      return {
        name: t('menu.menuItems.grades.grades'),
        icon: 'grades',
        route: '/student-grades',
      };
    }

    return undefined;
  }

  @computed
  private get studentBookingMenuItem(): IMenuItem | undefined {
    const subMenuItems: ISubMenuItem[] = [];
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.STUDENT_BOOKING, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.BOOK_ROOM, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.NEW_ACTIVITY, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.BOOK_RESOURCE, subMenuItems);
    if (subMenuItems.length > 0) {
      return {
        name: t('menu.menuItems.booking.booking'),
        icon: 'navigation-book',
        route: subMenuItems[0].route,
        subMenuItems,
      };
    }
    return undefined;
  }

  @computed
  private get studentContactHoursMenuItem(): IMenuItem | undefined {
    if (this.configStore.checkMenuItemPermission(MenuItemEnum.STUDENT_CONTACT_HOURS)) {
      return {
        name: t('menu.menuItems.timetable.subMenuItems.officehours'),
        icon: 'infoCenter_officeHours',
        route: '/timetable-contact-hours',
      };
    }

    return undefined;
  }

  @computed
  private get studentMyTimetable(): IMenuItem | undefined {
    /* My Timetable for students/parents should be only displayed, if the user has at least the permission
      for the timetable for students and/or classes (does not matter if only own tt or all tt).
      Depending on which tt is permitted (student or classes) the tt should be displayed - if possible
      the tt for students.
    */
    if (
      this.configStore.checkMenuPermission(MenuPermissionEnum.PERMISSION_STUDENT_TT_ALL) ||
      this.configStore.checkMenuPermission(MenuPermissionEnum.PERMISSION_STUDENT_TT_OWN)
    ) {
      return {
        name: t('menu.menuItems.timetable.subMenuItems.my'),
        icon: 'my_timetable',
        route: this.configStore.userStudents.length > 1 ? '/timetable-students-parent' : '/timetable-students-my',
      };
    } else if (
      this.configStore.checkMenuPermission(MenuPermissionEnum.PERMISSION_CLASS_TT_ALL) ||
      this.configStore.checkMenuPermission(MenuPermissionEnum.PERMISSION_CLASS_TT_OWN)
    ) {
      return {
        name: t('menu.menuItems.timetable.subMenuItems.my'),
        icon: 'my_timetable',
        route: this.configStore.userStudents.length > 1 ? '/timetable-classes-parent' : '/timetable-classes-my',
      };
    }

    return undefined;
  }

  @computed
  private get studentMyTimetableNew(): IMenuItem | undefined {
    if (this.configStore.checkMenuPermission(MenuPermissionEnum.TIMETABLE_NEW_STUDENTS_MY)) {
      return {
        name: t('menu.menuItems.timetable.subMenuItems.my'),
        icon: 'my_timetable',
        route: '/timetable-new/my-student',
        tag: 'BETA',
      };
    } else if (this.configStore.checkMenuPermission(MenuPermissionEnum.TIMETABLE_NEW_CLASSES_MY)) {
      return {
        name: t('menu.menuItems.timetable.subMenuItems.my'),
        icon: 'my_timetable',
        route: '/timetable-new/my-class',
        tag: 'BETA',
      };
    }

    return undefined;
  }

  @computed
  private get otherTimetables(): IMenuItem | undefined {
    /* This is shown if the student can see more timetables than just his own (= ONLY PERMISSION_STUDENT_TT_OWN
      or ONLY PERMISSION_CLASS_TT_OWN)
    */

    const subMenuItems: ISubMenuItem[] = [];

    if (this.configStore.checkMenuPermission(MenuPermissionEnum.PERMISSION_STUDENT_TT_ALL)) {
      subMenuItems.push(getSubMenuItem(SubMenuItemEnum.TIMETABLE_STUDENTS)!);
    }
    if (this.configStore.checkMenuPermission(MenuPermissionEnum.PERMISSION_CLASS_TT_ALL)) {
      subMenuItems.push(getSubMenuItem(SubMenuItemEnum.TIMETABLE_CLASSES)!);
    }
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_TEACHERS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_ROOMS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_SUBJECTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_RESOURCES, subMenuItems);

    if (subMenuItems.length > 0) {
      return {
        name: this.studentMyTimetable
          ? t('menu.menuItems.timetable.subMenuItems.other')
          : t('menu.menuItems.timetable.timetable'),
        icon: 'navigation-timetable',
        route: subMenuItems[0].route,
        subMenuItems,
      };
    }

    return undefined;
  }

  @computed
  private get otherNewTimetables(): IMenuItem | undefined {
    const subMenuItems: ISubMenuItem[] = [];

    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_NEW_CLASSES, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_NEW_STUDENTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_NEW_TEACHERS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_NEW_ROOMS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_NEW_SUBJECTS, subMenuItems);
    this.configStore.addSubMenuItemIfPermitted(SubMenuItemEnum.TIMETABLE_NEW_RESOURCES, subMenuItems);

    if (subMenuItems.length > 0) {
      return {
        name: this.studentMyTimetableNew
          ? t('menu.menuItems.timetable.subMenuItems.other')
          : t('menu.menuItems.timetable.timetable'),
        icon: 'navigation-timetable',
        route: subMenuItems[0].route,
        subMenuItems,
        tag: 'BETA',
      };
    }

    return undefined;
  }

  @computed
  public get menuItems(): IMenuItem[] {
    if (!this.configStore) {
      return [];
    }

    // items will be undefined if the permission checks fail for them
    const menuItemsOrUndefined: (IMenuItem | undefined)[] = [];

    if (this.configStore.hasStudentsUI) {
      menuItemsOrUndefined.push(this.todayMenuItem);
      menuItemsOrUndefined.push(this.overviewMenuItem);
      menuItemsOrUndefined.push(this.messagesMenuItem);
      menuItemsOrUndefined.push(this.studentMyTimetable);
      menuItemsOrUndefined.push(this.studentMyTimetableNew);
      menuItemsOrUndefined.push(this.otherTimetables);
      menuItemsOrUndefined.push(this.otherNewTimetables);
      menuItemsOrUndefined.push(this.studentCoursesMenuItem);
      menuItemsOrUndefined.push(this.studentBookingMenuItem);
      menuItemsOrUndefined.push(this.studentAbsencesMenuItem);
      menuItemsOrUndefined.push(this.studentHomeworkMenuItem);
      menuItemsOrUndefined.push(this.studentClassRegisterEntriesMenuItem);
      menuItemsOrUndefined.push(this.studentClassServicesMenuItem);
      menuItemsOrUndefined.push(this.contactDetailsMenuItem);
      menuItemsOrUndefined.push(this.studentExaminationsMenuItem);
      menuItemsOrUndefined.push(this.studentGradesMenuItem);
      menuItemsOrUndefined.push(this.studentExemptionsMenuItem);
      menuItemsOrUndefined.push(this.studentContactHoursMenuItem);
    } else {
      menuItemsOrUndefined.push(this.todayMenuItem);
      menuItemsOrUndefined.push(this.messagesMenuItem);
      menuItemsOrUndefined.push(this.timetableMenuItem);
      menuItemsOrUndefined.push(this.timetableNewMenuItem);
      menuItemsOrUndefined.push(this.lessonsMenuItem);
      menuItemsOrUndefined.push(this.bookingMenuItem);
      menuItemsOrUndefined.push(this.classRegisterMenuItem);
      menuItemsOrUndefined.push(this.coursesMenuItem);
      menuItemsOrUndefined.push(this.timetablingMenuItems);
      menuItemsOrUndefined.push(this.substitutionPlanningMenuItem);
      menuItemsOrUndefined.push(this.playgroundMenuItem);
      menuItemsOrUndefined.push(this.masterDataMenuItem);
      menuItemsOrUndefined.push(this.administrationMenuItem);
      menuItemsOrUndefined.push(this.contactDetailsMenuItem);
      menuItemsOrUndefined.push(this.driveMenuItem);
    }

    // append platform items if there are any
    !!this.platformApplicationMenuItems &&
      this.platformApplicationMenuItems.forEach((item) => {
        menuItemsOrUndefined.push(item);
      });

    const menuItems: IMenuItem[] = [];

    // filter out undefined items
    menuItemsOrUndefined.forEach((item) => {
      if (item) {
        menuItems.push(item);
      }
    });

    return menuItems;
  }

  @computed
  public get expanded(): boolean {
    return this._expanded;
  }
}
