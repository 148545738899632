import React from 'react';
import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

import { ExamFormStore } from '../exam-form/exam-form-store';
import { GradingSchemeDialog } from '../grading-scheme-dialog/grading-scheme-dialog';

import { CheckOutlined, MinusOutlined } from '@ant-design/icons';
import { AppViewApi, ExamsViewApi } from '@/stores/api-store';
import { inject } from '@/types/store';
import { ExamDto, MasterDataRefDto } from '@untis/wu-rest-view-api/api';
import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import { IListViewAction, IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import { formatDateForServerRequest } from '@/utils/date/date-util';
import {
  ButtonType,
  Columns,
  ColumnType,
  DateFormat,
  ISyncButtonDefinition,
} from '@/ui-components/wu-table/wu-table-column-mapper';
import { defaultSorting, sortingByDayjs, sortStringListByFirstElement } from '@/utils/sorting/sorting-util';
import { createChunks } from '@/utils/array/array-util';
import {
  IReportExportingRow,
  IReportExtraOption,
  ReportType,
} from '@/components/reports-exporting-table/reports-exporting-table';
import { IExamReportParameters } from '@ls/exams/overview/exams-report-url-mapper-store';
import ExamForm from '@ls/exams/exam-form/exam-form';
import { AbstractExamListStore } from '@ls/exams/overview/abstract-exam-list-store';
import { PlatformApplicationExamIntegrationDto, PlatformApplicationUrlDtoViewTypeEnum } from '@untis/wu-rest-view-api';
import { matchesAllSearches } from '@/utils/filtering/filtering-util';
import PlatformStore from '@pa/stores/platform-store';
import { GradingForm } from '@ls/exams/grading-form/grading-form';
import { GradingFormStore } from '@ls/exams/grading-form/grading-form-store';
import { IToggleFilterProps } from '@/ui-components/filter-bar/filter/toggle-filter';

export interface IExamRow extends ITableRowKey {
  examId: number;
  examType: string;
  examName: string;
  examDate: Dayjs;
  startTime: Dayjs;
  endTime: Dayjs;
  subject: string;
  classes: string[];
  teachers: string[];
  rooms: string[];
  isExported: boolean;
  isDeleted: boolean;
  isUntisExam: boolean;
  bookedOn: Dayjs;
  returnedOn?: Dayjs;
  examText: string;
  userCanEditExam: boolean;
  userCanDeleteExam: boolean;
  userCanReadGrades: boolean;
}

export class ExamsStore extends AbstractExamListStore<IExamRow> {
  private rightStore: RightsStore = inject(RightsStore);
  private platformStore: PlatformStore = inject(PlatformStore);
  @observable private examListIntegrations: PlatformApplicationExamIntegrationDto[] = [];
  @observable private _exams: ExamDto[] = [];
  @observable private _showDeleted = false;

  constructor() {
    super(Right.EXAMINATION, false);
  }

  @action
  async init() {
    await this.getExamListFilters();
    await this.getExamList();
    await this.getExamListIntegrations();

    this.setIsDataLoading(false);
  }

  @action
  async getExamList() {
    const response = await ExamsViewApi.getExamList(
      formatDateForServerRequest(this._selectedDateRange.startDate),
      formatDateForServerRequest(this._selectedDateRange.endDate),
      this.getSelectedOptionByCategory(this._localizedType),
      this.getSelectedOptionByCategory(this._localizedClass),
      this.getSelectedOptionByCategory(this._localizedSubject),
      this.getSelectedOptionByCategory(this._localizedTeacher),
      this._showDeleted,
    );
    this._exams = response.data.exams ?? [];
  }

  @action
  async getExamListIntegrations() {
    const response = await AppViewApi.getExamIntegrations();
    this.examListIntegrations = response.data;
  }

  private mapDtoToSearchString(dto: ExamDto): string {
    let res = '';
    res += dto.classes.map((c) => c.shortName).join(' ');
    res += dto.teachers.map((t) => t.shortName).join(' ');
    res += dto.rooms.map((r) => r.shortName).join(' ');
    res += dto.subject ? dto.subject.shortName : '';
    res += dto.examType ? dto.examType.shortName : '';
    res += dto.examName ? dto.examName : '';
    return res.toLocaleLowerCase();
  }

  @computed
  get rowData(): IExamRow[] {
    let filteredDtos = this._exams;

    filteredDtos = filteredDtos.filter((dto) => {
      if (!this._showDeleted) {
        return true;
      }

      return this._showDeleted && dto.deleted;
    });

    filteredDtos = filteredDtos.filter((dto) => {
      return matchesAllSearches(this.mapDtoToSearchString(dto), this.selectedFreeTextOptions);
    });

    return filteredDtos?.map((exam): IExamRow => {
      const { examStart, examEnd, examBooked, examReturned } = exam;
      const examStartDayjs = dayjs(examStart);
      const examEndDayjs = dayjs(examEnd);
      const bookedOnDayjs = dayjs(examBooked);
      const returnedOnDayjs = !!examReturned ? dayjs(examReturned) : undefined;
      const classes = exam.classes.map((klasse) => klasse.displayName ?? '');
      const teachers = exam.teachers?.map((teacher) => teacher.displayName ?? '');
      const rooms = exam.rooms?.map((room) => room.displayName ?? '');

      return {
        key: exam.examId,
        examId: exam.examId,
        examType: exam.examType?.displayName ?? '',
        examName: exam.examName ?? '',
        examDate: examEndDayjs,
        startTime: examStartDayjs,
        endTime: examEndDayjs,
        subject: exam.subject?.displayName ?? '',
        classes: classes,
        teachers: teachers ?? [],
        rooms: rooms ?? [],
        isExported: exam.exported,
        isDeleted: exam.deleted,
        isUntisExam: exam.isUntisExam,
        bookedOn: bookedOnDayjs,
        returnedOn: returnedOnDayjs,
        examText: exam.examText ?? '',
        userCanEditExam: exam?.canEdit ?? false,
        userCanDeleteExam: exam?.canDelete ?? false,
        userCanReadGrades: exam?.canReadGrades ?? false,
      };
    });
  }

  @computed
  get columns(): Columns<IExamRow> {
    const cols: Columns<IExamRow> = [
      {
        type: ColumnType.Text,
        key: 'examType',
        header: t('general.type'),
        width: 150,
        sorter: (a, b) => defaultSorting(a.examType, b.examType),
      },
      {
        type: ColumnType.Text,
        key: 'examName',
        header: t('general.name'),
        width: 150,
        sorter: (a, b) => defaultSorting(a.examName, b.examName),
      },
      {
        type: ColumnType.List,
        key: 'class',
        header: t('general.class'),
        getEntries: (row) => row.classes,
        entryThreshold: 3,
        ellipsis: true,
        width: 80,
        sorter: (a, b) => sortStringListByFirstElement(a.classes, b.classes),
      },
      {
        type: ColumnType.Text,
        key: 'subject',
        header: t('general.subject'),
        width: 90,
        sorter: (a, b) => defaultSorting(a.subject, b.subject),
      },
      {
        type: ColumnType.List,
        key: 'teacher',
        header: t('general.teacher'),
        getEntries: (row) => row.teachers,
        entryThreshold: 3,
        ellipsis: true,
        width: 150,
        sorter: (a, b) => sortStringListByFirstElement(a.teachers, b.teachers),
      },
      {
        type: ColumnType.Date,
        key: 'examDate',
        format: DateFormat.Date,
        header: t('general.date'),
        width: 80,
        sorter: (a, b) => sortingByDayjs(a.examDate, b.examDate),
      },
      {
        type: ColumnType.Date,
        key: 'startTime',
        format: DateFormat.Time,
        header: t('general.fromDate'),
      },
      {
        type: ColumnType.Date,
        key: 'endTime',
        format: DateFormat.Time,
        header: t('general.toDate'),
      },
      {
        type: ColumnType.List,
        key: 'room',
        header: t('general.room'),
        getEntries: (row) => row.rooms,
        entryThreshold: 3,
        ellipsis: true,
        width: 80,
        sorter: (a, b) => sortStringListByFirstElement(a.rooms, b.rooms),
      },
      {
        type: ColumnType.Boolean,
        key: 'isExported',
        header: t('general.exported'),
        align: 'center',
        render: (value: boolean) => (
          <div className="boolean-column-wrapper">
            <div className="boolean-column">{value ? <CheckOutlined /> : <MinusOutlined />}</div>
          </div>
        ),
      },
      {
        type: ColumnType.Date,
        key: 'bookedOn',
        format: DateFormat.Date,
        header: t('lessons.exams.tableColumns.bookedOn'),
        width: 80,
        sorter: (a, b) => sortingByDayjs(a.bookedOn, b.bookedOn),
      },
      {
        type: ColumnType.Date,
        key: 'returnedOn',
        format: DateFormat.Date,
        header: t('lessons.exams.tableColumns.returnedOn'),
        width: 80,
        sorter: (a, b) => sortingByDayjs(a.returnedOn, b.returnedOn),
      },
      {
        type: ColumnType.TextPopover,
        key: 'examText',
        header: t('general.text'),
        getValue: (row) => (row.examText.length === 0 ? [] : [row.examText]),
        maxWidth: 250,
      },
    ];

    if (this.columnActions.length > 0) {
      cols.push({
        type: ColumnType.OverlayHoverActions,
        key: 'actions',
        actionIcons: [...this.columnActions, ...this.dropDownActions],
        buttonActions: [...this.buttonActions],
      });
    }

    return cols.filter((column) => {
      const isExportedColumn = column.key === 'isExported';
      return !isExportedColumn || this._configStore.isAdmin;
    });
  }

  @computed
  private get buttonActions(): ISyncButtonDefinition<IExamRow>[] {
    const buttons: ISyncButtonDefinition<IExamRow>[] = [];

    if (
      this.rightStore.canRead(Right.PERFORMANCEASSESSMENT, ElementType.ALL, false) ||
      this.rightStore.canRead(Right.EXAMSTATISTICS, ElementType.ALL, false)
    ) {
      buttons.push({
        type: ButtonType.Sync,
        label: t('general.grades'),
        outline: true,
        isHoverButton: true,
        style: () => 'secondary',
        condition: (row: IExamRow) => !row.isDeleted && row.userCanReadGrades,
        onClick: (row) => this.openGradesModal(row.examId),
      });
    }

    return buttons;
  }

  @computed
  private get columnActions() {
    const actions = [];

    if (this.canRead) {
      actions.push({
        tooltip: t('general.edit'),
        icon: 'edit',
        condition: (row: IExamRow) => row.userCanEditExam,
        onClick: (row: IExamRow) => {
          const examFormStore = new ExamFormStore(row.examId);
          this._modalStore.openModalDialog({
            children: (
              <ExamForm
                examFormStore={examFormStore}
                onSaveCallback={() => this.getExamList()}
                onDeleteCallback={() => this.getExamList()}
              />
            ),
            title: '',
            size: 'full-size',
            containsForm: true,
          });
        },
      });
    }

    if (this.canDelete) {
      actions.push({
        tooltip: t('general.delete'),
        icon: 'shared_trash',
        condition: (row: IExamRow) => !row.isUntisExam && row.userCanDeleteExam,
        onClick: (row: IExamRow) => this.deleteExam(row.examId),
      });
    }

    return actions;
  }

  @computed
  private get dropDownActions() {
    const actions = [];

    if (this.rightStore.canWrite(Right.ADMIN, ElementType.ALL, false)) {
      actions.push({
        icon: 'more',
        label: t('lessons.exams.tableColumns.historyChanges'),
        onClick: (row: IExamRow) => this.openExamHistory(row.examId),
      });
    }

    this.examListIntegrations.forEach((integration) => {
      actions.push({
        icon: 'more',
        label: integration.menuName,
        condition: (row: IExamRow) => !row.isDeleted,
        onClick: (row: IExamRow) => this.openGradeIntegration(integration, row.examId),
      });
    });

    return actions;
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<IExamRow>[] {
    const selectedRowsActions: IListViewSelectedRowsAction<IExamRow>[] = [];

    if (this.rightStore.canDelete(Right.EXAMINATION, ElementType.ALL, false)) {
      selectedRowsActions.push({
        icon: 'shared_trash',
        label: t('general.delete'),
        onClick: (rows) => this.bulkDeleteExams(rows),
      });
    }

    return selectedRowsActions;
  }

  @computed
  get globalActions(): IListViewAction[] {
    return [
      {
        label: t('general.reports'),
        onClick: () => this._reportStore.openReportsModal(this.reportRows, this.getReportUrl, this.extraReportOptions),
      },
    ];
  }

  @computed
  get reportRows(): IReportExportingRow[] {
    return [
      {
        key: 'exams',
        title: t('lessons.exams.reports.examsReportTitle'),
        description: t('lessons.exams.reports.examsReportDescription'),
        reportTypes: [ReportType.PDF],
      },
      {
        key: 'examsByType',
        title: t('lessons.exams.reports.examsByTypeReportTitle'),
        description: t('lessons.exams.reports.examsByTypeReportDescription'),
        reportTypes: [ReportType.PDF],
      },
      {
        key: 'examPlanner',
        title: t('lessons.exams.reports.examsPlannerReportTitle'),
        description: t('lessons.exams.reports.examsPlannerReportDescription'),
        reportTypes: [ReportType.PDF, ReportType.XLS],
      },
      {
        key: 'examsWithGrades',
        title: t('lessons.exams.reports.examsWithGradesReportTitle'),
        description: t('lessons.exams.reports.examsWithGradesReportDescription'),
        reportTypes: [ReportType.PDF, ReportType.XLS, ReportType.CSV],
      },
      {
        key: 'examsByClass',
        title: t('lessons.exams.reports.examsByClassesReportTitle'),
        description: t('lessons.exams.reports.examsByClassesReportDescription'),
        reportTypes: [ReportType.PDF, ReportType.XLS, ReportType.CSV],
      },
      {
        key: 'examsByTeacher',
        title: t('lessons.exams.reports.examsByTeachersReportTitle'),
        description: t('lessons.exams.reports.examsByTeachersReportDescription'),
        reportTypes: [ReportType.PDF, ReportType.XLS, ReportType.CSV],
      },
    ];
  }

  @action.bound
  private getReportUrl(key: string, reportType: ReportType, extraOptions?: IReportExtraOption[]): string {
    const reportParams: IExamReportParameters = {
      startDate: formatDateForServerRequest(this._selectedDateRange.startDate),
      endDate: formatDateForServerRequest(this._selectedDateRange.endDate),
      examTypeId: this.getSelectedOptionByCategory(this._localizedType)?.toString() ?? '-1',
      classId: this.getSelectedOptionByCategory(this._localizedClass)?.toString() ?? '-1',
      subjectId: this.getSelectedOptionByCategory(this._localizedSubject)?.toString() ?? '-1',
      teacherId: this.getSelectedOptionByCategory(this._localizedTeacher)?.toString() ?? '-1',
      gradingSchemeId: '-1',
      showDeleted: this._showDeleted,
      showEmpty: extraOptions?.find((extraOption) => extraOption.key === 'showEmpty')?.value || false,
    };
    return this.examsReportUrlMapperStore.mapToReportUrl(key, reportType, reportParams);
  }

  @action.bound
  setShowDeleted(value: boolean) {
    this._showDeleted = value;
  }

  @computed
  get showDeleted(): boolean {
    return this._showDeleted;
  }

  @computed
  get toggleFilters(): IToggleFilterProps[] | undefined {
    if (this._configStore.isAdmin) {
      return [
        {
          label: t('lessons.exams.filters.showDeleted'),
          value: this._showDeleted,
          onChange: (value) => this.setShowDeleted(value),
        },
      ];
    }

    return undefined;
  }

  @computed
  get extraReportOptions(): IReportExtraOption[] {
    return [
      {
        key: 'showEmpty',
        value: false,
        text: t('lessons.exams.reports.extraToggleTitle'),
      },
    ];
  }

  @action
  async openExamHistory(id: number) {
    const response = await ExamsViewApi.getExamHistory(id);
    const history = response.data.changes ? response.data.changes : [];
    this._modalStore.openHistoryDialog(history);
  }

  @action.bound
  updateGradingScheme(examId: number, scheme: MasterDataRefDto) {
    this._exams.find((e) => e.examId === examId)!.gradingScale = scheme;
  }

  @action
  openGradesModal(examId: number) {
    const exam = this._exams.find((e) => e.examId === examId);

    if (exam) {
      const store = new GradingFormStore(exam.examId, this);

      if (!exam || exam.gradingScale == null || exam.gradingScale.id < 1) {
        this._modalStore.openModalDialog({
          size: 'md',
          children: <GradingSchemeDialog gradingFormStore={store} examStore={this} />,
          title: t('general.assignGradingScheme'),
          containsForm: true,
        });
      } else {
        const examName = exam ? exam.examName : undefined;
        this._modalStore.openModalDialog({
          size: 'full-size',
          children: <GradingForm store={store} />,
          showHeaderSeparator: true,
          title: examName ? [t('general.grades'), examName] : t('general.grades'),
          closeCondition: store.checkForUnsaved,
        });
      }
    }
  }

  @action
  deleteExam(examId: number) {
    this._modalStore
      .openDeletePrompt(t('lessons.exams.messages.deletePrompt'), t('lessons.exams.messages.deletePromptText'))
      .then((result) => {
        if (result) {
          ExamsViewApi.deleteExam(examId)
            .then(() => {
              this._notificationStore.success({ title: t('lessons.exams.messages.examDeleted') });
              this._modalStore.closeModal();
              this.setSelectedRowKeys([]);
              this.getExamList();
            })
            .catch((error) => {
              this._notificationStore.error({
                title: t('lessons.exams.messages.examDeletedError'),
                message: error.toString(),
              });
            });
        }
      });
  }

  @action
  bulkDeleteExams(rows: IExamRow[]) {
    this._modalStore
      .openDeletePrompt(
        t('lessons.exams.messages.bulkDeletePrompt', { count: rows.length }),
        t('lessons.exams.messages.deletePromptText'),
      )
      .then((result) => {
        if (result) {
          const chunks = createChunks<IExamRow>(rows, 100);
          chunks.forEach((chunk) => {
            ExamsViewApi.deleteExams(chunk.map((row) => row.examId))
              .then(() => {
                this._notificationStore.success({
                  title: t('lessons.exams.messages.examsDeleted'),
                });
                this.setSelectedRowKeys([]);
                this.getExamList();
              })
              .catch((error) => {
                this._notificationStore.error({
                  title: t('lessons.exams.messages.examsDeletedError'),
                  message: error.toString(),
                });
              });
          });
        }
      });
  }

  private openGradeIntegration(integration: PlatformApplicationExamIntegrationDto, examId: number) {
    this.platformStore.trackPlatformApplicationUsage(integration.id!, PlatformApplicationUrlDtoViewTypeEnum.EXAMLIST);
    window.open(`${integration.url}&exam_id=${examId}`);
  }
}
