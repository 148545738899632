import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/ui-components';

import './wu-table-controls.less';

export type WuTableControlsProps = {
  selectAll: CheckboxProps & {
    className?: string;
  };
};

export function WuTableControls({ selectAll }: WuTableControlsProps) {
  const { t } = useTranslation();
  return (
    <div className="wu-table-controls">
      {selectAll && (
        <div className={clsx('wu-table-controls--select-all', selectAll.className)}>
          <Tooltip title={t('general.selectAll')} placement="top">
            <Checkbox {...selectAll}></Checkbox>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
