import { message } from 'antd';
import { t } from 'i18next';
import { action, computed, observable } from 'mobx';
import { Dayjs } from 'dayjs';

import {
  CalendarEntryTypesEnum,
  CalendarKlasseDto,
  CalendarSubjectDto,
  CalendarTeacherDto,
  CreateCalendarEntryDto,
} from '@untis/wu-rest-view-api';
import { CalendarViewApi } from '@/stores/api-store';
import { inject } from '@/types/store';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { showErrorMessage } from '@/utils/error-handling/error-message';
import { dayjsFormatWithTimeDeprecated } from '@/utils/date/date-util';
import PostMessageStore from '@sp/stores/post-message-store';
import CalendarEntryBaseFormStore, { FormMode } from '@/pages/calendar-entry/calendar-entry-base-form-store';
import { OverbookingDto } from '@untis/sp-rest-api/api';
import SchoolYearStore from '@/stores/schoolyear-store';

interface IItem {
  key: string;
  value: string;
}

export interface IItemType {
  isTeacher: boolean;
  isKlasse: boolean;
}

class CreateCalendarEntryFormStore extends CalendarEntryBaseFormStore {
  @observable private _classes: CalendarKlasseDto[] = [];
  @observable private _subjects: CalendarSubjectDto[] = [];
  @observable private _teachers: CalendarTeacherDto[] = [];

  @observable private itemType: IItemType = {
    isTeacher: false,
    isKlasse: false,
  };

  @observable private _types: CalendarEntryTypesEnum[] = [];
  @observable private _isFetchingTypes: boolean = false;
  @observable private _isFetchingConfig: boolean = false;
  @observable private _selectedSubject: CalendarSubjectDto | undefined;
  @observable selectedType: string | undefined;
  @observable selectedClasses: CalendarKlasseDto[] = [];

  @observable selectedTeachers: CalendarTeacherDto[] = [];

  private _postMessageStore = inject(PostMessageStore);
  private schoolYearStore = inject(SchoolYearStore);

  constructor(
    initialStart: Dayjs,
    initialEnd: Dayjs,
    preSelectedTeachers: number[] | undefined,
    editMode: boolean | undefined,
    overbooking?: OverbookingDto | undefined,
  ) {
    super(initialStart, initialEnd, editMode ? FormMode.UPDATE : FormMode.CREATE, overbooking);
    this.fetchCalendarEntryTypes();
    this.fetchCalendarEntryConfig(true, preSelectedTeachers);
    this.fetchRooms();
  }

  @computed get selectedSubjectKey(): string {
    return this._selectedSubject ? this._selectedSubject.id.toString() : '-1';
  }

  @computed
  get teacherItems(): IMultiTagSelectItem[] {
    return this.createMultiSelectItems(this.selectedTeachers, this.selectTeachers);
  }

  @computed
  get addTeacherHandler() {
    return this.createAddHandler(
      this._teachers,
      this.selectedTeachers,
      this.selectTeachers,
      t('createCalendarEntry.addTeachers'),
      this._modalStore,
      {
        isTeacher: true,
        isKlasse: false,
      },
    );
  }

  @computed
  get classItems(): IMultiTagSelectItem[] {
    return this.createMultiSelectItems(this.selectedClasses, this.selectClasses);
  }

  @computed
  get addClassHandler() {
    return this.createAddHandler(
      this._classes,
      this.selectedClasses,
      this.selectClasses,
      t('createCalendarEntry.addClasses'),
      this._modalStore,
      {
        isTeacher: false,
        isKlasse: true,
      },
    );
  }

  @computed get subjectItems(): IItem[] {
    return this._subjects.map((subject) => {
      return {
        key: subject.id.toString(),
        value: subject.displayName,
      };
    });
  }

  @computed get typeItems(): { key: string; value: string }[] {
    return this._types.map((type) => {
      return {
        key: type,
        value: this.getTranslationForCalendarEntryType(type),
      };
    });
  }

  @computed
  get dateTimeRangeSelectionError(): boolean {
    const datesOutsideOfCurrentSchoolyear =
      !this.schoolYearStore.isDateInCurrentSchoolYear(this._start) ||
      !this.schoolYearStore.isDateInCurrentSchoolYear(this._end);

    const isSameDay = this._start.isSame(this._end, 'day');
    const isEndBeforeStartOnTheSameDay = isSameDay && this._end.isSameOrBefore(this._start, 'minutes');
    return datesOutsideOfCurrentSchoolyear || isEndBeforeStartOnTheSameDay;
  }

  @computed
  get submitDisabled(): boolean {
    return this.dateTimeRangeSelectionError;
  }

  @action.bound
  disabledDate(current: Dayjs): boolean {
    return !this.schoolYearStore.isDateInCurrentSchoolYear(current);
  }

  @action
  fetchCalendarEntryTypes = async () => {
    this._isFetchingTypes = true;
    CalendarViewApi.getCalendarEntryTypes().then((response) => {
      this._types = response.data;
      this._isFetchingTypes = false;
      if (this._types.length === 1) {
        this.setSelectedType(this._types[0]);
      }
    });
  };

  @action
  fetchCalendarEntryConfig = (isInitialFetch: boolean, preSelectedTeachers?: number[]) => {
    this._isFetchingConfig = true;
    CalendarViewApi.getCalendarEntryNewFormData(
      dayjsFormatWithTimeDeprecated(this._end),
      dayjsFormatWithTimeDeprecated(this._start),
    ).then((response) => {
      this._classes = response.data.klassen;
      this._teachers = response.data.teachers;
      this._subjects = response.data.subjects;
      this._isFetchingConfig = false;

      if (isInitialFetch) {
        if (preSelectedTeachers) {
          this.selectTeachers(preSelectedTeachers);
        }
        this.prepopulateFieldsFromOverbooking();
      } else {
        this.selectedClasses = this.selectedClasses.filter((selectedClass) =>
          this._classes.map((klasse) => klasse.id).includes(selectedClass.id),
        );
        this.selectedTeachers = this.selectedTeachers.filter((selectedTeacher) =>
          this._teachers.map((teacher) => teacher.id).includes(selectedTeacher.id),
        );
        this._selectedSubject = this._subjects.find((subject) => subject.id === this._selectedSubject?.id);
      }
    });
  };

  @action prepopulateFieldsFromOverbooking = () => {
    if (this.overbooking?.period?.klassen) {
      this.selectClasses(this.overbooking?.period?.klassen.map((klasse) => klasse.klasse.id));
    }

    if (this.overbooking?.period?.subject) {
      this.setSelectedSubject({
        key: `${this.overbooking?.period?.subject.id}`,
        value: this.overbooking?.period?.subject.displayName,
      });
    }

    if (this.overbooking?.period?.teachers) {
      const teacherIds = this.overbooking?.period?.teachers?.map((teacher) => teacher.currentTeacher?.id!);
      this.selectTeachers(teacherIds);
    }
  };

  @action selectClasses = (ids: number[]) => {
    this.selectedClasses = this._classes.filter((c) => ids.includes(c.id));
  };

  @action selectTeachers = (ids: number[]) => {
    this.selectedTeachers = this._teachers.filter((t) => ids.includes(t.id));
  };

  @action setSelectedType = (type: string) => {
    this.selectedType = type;
  };

  @action setSelectedSubject = (item: IItem) => {
    this._selectedSubject = this._subjects.find((s) => s.id.toString() === item.key);
  };

  @action handleTimeRangeChanged = () => {
    this.fetchRooms();
    this.fetchCalendarEntryConfig(false);
  };

  @action handleSubmit = async () => {
    try {
      if (this._mode === FormMode.UPDATE) {
        if (this.overbooking?.id) {
          await CalendarViewApi.updateCalendarEntry(this.overbooking?.id, undefined, undefined, {
            rooms: { value: this.selectedRooms.map((r) => r.id) },
          });
          await CalendarViewApi.updateCalendarEntry(this.overbooking?.id, undefined, undefined, {
            teachers: { value: this.selectedTeachers.map((t) => t.id) },
          });
        }
        message.success(t('general.updatedCalendarEntryMessage'));
      } else {
        const dto: CreateCalendarEntryDto = {
          startDateTime: dayjsFormatWithTimeDeprecated(this.start),
          endDateTime: dayjsFormatWithTimeDeprecated(this.end),
          subjectId: this._selectedSubject ? this._selectedSubject.id : undefined,
          klassen: this.selectedClasses.map((k) => k.id),
          teachers: this.selectedTeachers.map((t) => t.id),
          rooms: this.selectedRooms.map((r) => r.id),
        };

        await CalendarViewApi.postViewCalendarEntry(dto);
        message.success(t('createCalendarEntry.entryCreated'));
      }
    } catch (error) {
      showErrorMessage(this.getTranslationForError(error.response.data.errorMessage));
      return false;
    } finally {
      this._postMessageStore.postReloadContentMessage();
    }
    return true;
  };

  @action handleDelete = async () => {
    try {
      if (this.overbooking?.id) {
        await CalendarViewApi.deleteCalendarEntry(this.overbooking?.id);
      }
      message.success(t('general.deletedCalendarEntryMessage'));
    } catch (error) {
      showErrorMessage(this.getTranslationForError(error.response.data.errorMessage));
      return false;
    } finally {
      this._postMessageStore.postReloadContentMessage();
    }
    return true;
  };
}

export default CreateCalendarEntryFormStore;
