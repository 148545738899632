import { useEffect } from 'react';

import useStore from '@/hooks/useStore';
import AppStore from '@/stores/app-store';

interface IEventListenerMap {
  onKeyUp?: (e: any) => void;
  onKeyDown?: (e: any) => void;
}

/**
 * Hook to register global keyboard shortcuts.
 *
 * @param eventListeners Eventlisteners which are created by the ShortcutStore
 * @param useCapture see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener documentation
 */
export default function useGlobalKeyboardShortcuts(eventListeners: IEventListenerMap, useCapture?: boolean) {
  const appStore = useStore(AppStore);

  useEffect(() => {
    const embeddedWebuntis = window.document.getElementById('embedded-webuntis') as HTMLIFrameElement | undefined;
    // here is why we do this iframe Document withdrawal: https://stackoverflow.com/a/1416262/5084489
    const embeddedWebuntisWindow = (embeddedWebuntis?.contentWindow || embeddedWebuntis?.contentDocument) as Window;
    const embeddedWebuntisDocument =
      embeddedWebuntisWindow?.document != null
        ? embeddedWebuntisWindow.document
        : (embeddedWebuntisWindow as unknown as Document);

    if (eventListeners.onKeyUp) {
      window.addEventListener('keyup', eventListeners.onKeyUp, useCapture);

      if (embeddedWebuntisDocument != null) {
        embeddedWebuntisDocument.addEventListener('keyup', eventListeners.onKeyUp, useCapture);
      }
    }

    if (eventListeners.onKeyDown) {
      window.addEventListener('keydown', eventListeners.onKeyDown, useCapture);

      if (embeddedWebuntisDocument != null) {
        embeddedWebuntisDocument.addEventListener('keydown', eventListeners.onKeyDown, useCapture);
      }
    }

    return () => {
      if (eventListeners.onKeyUp) {
        window.removeEventListener('keyup', eventListeners.onKeyUp, useCapture);

        if (embeddedWebuntisDocument != null) {
          embeddedWebuntisDocument.removeEventListener('keyup', eventListeners.onKeyUp, useCapture);
        }
      }

      if (eventListeners.onKeyDown) {
        window.removeEventListener('keydown', eventListeners.onKeyDown, useCapture);

        if (embeddedWebuntisDocument != null) {
          embeddedWebuntisDocument.removeEventListener('keydown', eventListeners.onKeyDown, useCapture);
        }
      }
    };
  }, [eventListeners.onKeyUp, eventListeners.onKeyDown, useCapture, appStore.embeddedWebUntisLoaded]);
}
