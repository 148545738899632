import React, { useState } from 'react';

import { IUserPromptComponentProps } from '@/stores/modal-store';
import { ISelectOptionListItem, SingleSelectOptionList } from '@/ui-components/select-option-list/select-option-list';
import { ISingleTagSelectItem } from '@/ui-components/tag-select/single-tag-select/single-tag-select';

interface IProps<T> extends IUserPromptComponentProps<T> {
  items: ISingleTagSelectItem[];
  selectedItem?: ISingleTagSelectItem;
}

export const SingleItemPickerPrompt = (props: IProps<ISingleTagSelectItem[]>) => {
  const [selectedOption, setSelectedOption] = useState<number | string | undefined>(
    props.selectedItem ? props.selectedItem.id : undefined,
  );

  const options: ISelectOptionListItem[] = props.items.map((item) => {
    return {
      id: item.id,
      name: item.label,
      value: item.id,
    };
  });

  const onChange = (checkedValue: string | number) => {
    const newOption = checkedValue.toString();
    props.promptProps?.setPromptValue(
      props.items.filter((item) => {
        return newOption.includes(item.id);
      }),
    );
    setSelectedOption(newOption);
  };

  return (
    <div className="container">
      <SingleSelectOptionList name="item-picker-prompt" options={options} onChange={onChange} value={selectedOption} />
    </div>
  );
};
