import { observer } from 'mobx-react-lite';
import React from 'react';

import useStore from '@/hooks/useStore';
import PlatformDetailViewStore from '@pa/stores/platform-detail-view-store';
import PlatformStore, { IPlatformListApplication } from '@pa/stores/platform-store';
import { Card, Icon } from '@/ui-components';
import { getPlatformLogo } from '@pa/utils/platform-utils';

import './platform-card.less';

interface IProps {
  platform: IPlatformListApplication;
  isRegisterMode: boolean;
}

const PlatformCard = observer((props: IProps) => {
  const { platform, isRegisterMode } = props;
  const schoolActiveStatusClassName = platform.schoolActive ? 'active-status green' : 'active-status red';
  const platformStore = useStore(PlatformStore);
  const platformDetailStore = useStore(PlatformDetailViewStore);

  const handleApplicationClick = () => {
    const registeredPlatformId = platformStore.isRegisteredGetId(platform.id);
    const id = isRegisterMode && registeredPlatformId ? registeredPlatformId : platform.id;

    if (isRegisterMode && !registeredPlatformId) {
      platformDetailStore.registerApplication(id);
    } else {
      platformDetailStore.openPlatformDetailView(id);
    }
  };

  const deleteButton = (): React.ReactNode => {
    return (
      <button
        className="delete-button"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          platformStore.handleDelete(platform.id, platform.name ?? '');
        }}
      >
        <Icon type="cancel" />
      </button>
    );
  };

  return (
    <Card bordered={isRegisterMode} tabIndex={0} className="platform-card" onClick={handleApplicationClick}>
      <div className="platform-info">
        {getPlatformLogo(platform.icon)}
        <span className="text">
          <>
            <h2 className="title">
              {platform.name}
              {!isRegisterMode && <span className={schoolActiveStatusClassName} />}
            </h2>
            <span className="description">{platform.description}</span>
          </>
        </span>
        {!isRegisterMode && platform.deletable && deleteButton()}
      </div>
    </Card>
  );
});

export default PlatformCard;
