import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { MessagesOfDayForm } from './messages-of-day-form';
import { MessagesOfTheDayStore } from './messages-of-day-store';

import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import ModalStore from '@/stores/modal-store';
import useStore from '@/hooks/useStore';
import RouterStore from '@/stores/router-store';

const AdminMessagesOfTheDayNew = () => {
  const modalStore = useStore(ModalStore);
  const routerStore = useStore(RouterStore);
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  const store = useStore(MessagesOfTheDayStore);

  useComponentDidMount(async () => {
    await modalStore.openModalDialog({
      children: <MessagesOfDayForm store={store} />,
      title: t('general.motdNewTitle'),
      size: 'full-size',
      onAfterClose: () => routerStore.redirect('/messages-of-the-day-new'),
      containsForm: true,
    });
    setRedirect(true);
  });

  if (redirect) {
    return <Redirect to="/messages-of-the-day-new" />;
  }

  return null;
};

export default AdminMessagesOfTheDayNew;
