import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MultiSelectOptionList, SingleSelectOptionList } from '@/ui-components/select-option-list/select-option-list';
import { DeprecatedFilterBarValue, DeprecatedFilterBar } from '@/ui-components/filter-bar/deprecated-filter-bar';
import { DeprecatedSearchBar } from '@/ui-components/deprecated-search-bar/deprecated-search-bar';
import { Button } from '@/ui-components';
import {
  CalendarBuildingDto,
  CalendarDepartmentDto,
  CalendarRoomDetailDto,
  RoomAvailabilityEnum,
  CalendarRoomTypeDto,
} from '@untis/wu-rest-view-api';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import useStore from '@/hooks/useStore';
import { IMasterDataDto } from '@/pages/calendar-entry/types';
import ModalStore from '@/stores/modal-store';
import { DeprecatedFullsizeModalWrapper } from '@/ui-components/deprecated-modal/deprecated-fullsize-modal-wrapper';
import RoomSelectionDialogStore from '@/pages/calendar-entry/room-selection-dialog/room-selection-dialog-store';
import './room-selection-dialog.less';
import { IDeprecatedFilter, IFilterItem } from '@/ui-components/filter-bar/filter/deprecatedFilter';

interface IProps {
  isSingleSelect?: boolean;
  availableRooms: CalendarRoomDetailDto[];
  initialSelected?: IMasterDataDto;
  initialSelectedRooms: IMasterDataDto[];
  onOk: (item: IMasterDataDto | IMasterDataDto[] | undefined) => void;
  title: string;
  buildings: CalendarBuildingDto[];
  departments: CalendarDepartmentDto[];
  roomTypes: CalendarRoomTypeDto[];
  initialBuildingFilter?: number;
  initialDepartmentFilter?: number;
}

const RoomSelectionDialog = observer((props: IProps) => {
  const initialSelectedRoom = props.initialSelected ? props.initialSelected.id : undefined;

  const initialSelectedRooms = React.useMemo(() => {
    return props.initialSelectedRooms ? props.initialSelectedRooms.map((i) => i.id) : [];
  }, [props.initialSelectedRooms]);

  const modalStore = useStore(ModalStore);
  const [store] = useState(
    () =>
      new RoomSelectionDialogStore(
        props.availableRooms,
        props.buildings,
        props.departments,
        props.roomTypes,
        initialSelectedRooms,
        initialSelectedRoom,
        props.initialBuildingFilter,
        props.initialDepartmentFilter,
        props.isSingleSelect,
      ),
  );
  const { t } = useTranslation();

  const handleFilterChange = (
    newFilterItems: DeprecatedFilterBarValue,
    filter: IDeprecatedFilter,
    item: IFilterItem | undefined,
  ) => {
    const newFilterValue = new Map(store.filterBarValue);
    newFilterValue.set(filter.id, item ? item.id : undefined);
    store.setFilterBarValue(newFilterValue);
  };

  const isEmpty =
    props.isSingleSelect &&
    props.availableRooms.filter((room) => {
      return room.availability !== RoomAvailabilityEnum.NONE;
    }).length === 0;

  let noRooms = undefined;

  if (isEmpty) {
    noRooms = (
      <EmptyIndicator title={t('general.noRoomsAvailable')} description={t('general.noRoomsBookableAtTheMoment')} />
    );
  } else if (store.filteredRooms.length === 0) {
    noRooms = <EmptyIndicator title={t('general.noEntriesFound')} description={t('general.pleaseChangeSearch')} />;
  }

  const renderOptionList = () => {
    if (!props.isSingleSelect) {
      return (
        <MultiSelectOptionList
          value={store.selectedRoomIds}
          options={store.roomItems}
          name="room-selection-list"
          onChange={(values) => {
            store.setSelectedRoomIds(values as number[]);
          }}
        />
      );
    }
    return (
      <SingleSelectOptionList
        value={store.selectedRoomId}
        options={store.roomItems}
        name="room-selection-list"
        onChange={(value) => {
          store.setSelectedRoomId(value as number);
        }}
      />
    );
  };

  const handleSingleSelectSubmit = () => {
    props.onOk(props.availableRooms.find((r) => r.id === store.selectedRoomId));
  };

  const handleMultiSelectSubmit = () => {
    props.onOk(props.availableRooms.filter((r) => store.selectedRoomIds?.some((selected) => selected === r.id)));
  };

  return (
    <DeprecatedFullsizeModalWrapper
      className="room-selection-dialog"
      footerChildren={
        <>
          <Button
            outline={true}
            size="large"
            onClick={() => {
              modalStore.closeModal();
            }}
          >
            {t('general.cancel')}
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              props.isSingleSelect ? handleSingleSelectSubmit() : handleMultiSelectSubmit();
            }}
            disabled={props.isSingleSelect ? !store.selectedRoomId : !store.selectedRoomIds}
          >
            {t('general.save')}
          </Button>
        </>
      }
    >
      <h1>{props.title}</h1>
      <div className="content">
        {!isEmpty && (
          <>
            <DeprecatedSearchBar
              value={store.searchBarValue}
              onChange={(value) => store.setSearchBarValue(value)}
              placeholder={t('general.searchARoom')}
            />
            <DeprecatedFilterBar filter={store.filter} value={store.filterBarValue} onChange={handleFilterChange} />
          </>
        )}
        {Boolean(noRooms) ? noRooms : renderOptionList()}
      </div>
    </DeprecatedFullsizeModalWrapper>
  );
});

export default RoomSelectionDialog;
