import { reaction, action } from 'mobx';
import { debounce } from 'lodash';

import { BaseMessagesStore } from '@mg/stores/base-messages-store';
import { MessageViewApi } from '@/stores/api-store';
import { Store } from '@/types/store';
import { SentMessageRefDto } from '@untis/wu-rest-view-api';

@Store()
export default class SentMessagesStore extends BaseMessagesStore<SentMessageRefDto> {
  constructor(typediParams: any) {
    super(typediParams);

    reaction(
      () => this.searchText,
      (searchText) => {
        if (searchText.length === 0) {
          this.getSentMessages();
        } else {
          this.debouncedSearchSentMessages();
        }
      },
    );
  }

  @action
  async getSentMessages() {
    await this.fetchMessages<{ sentMessages: SentMessageRefDto[] }>(
      (searchText, options) => MessageViewApi.getSentMessages(searchText, options),
      'sentMessages',
    );
  }

  @action
  debouncedSearchSentMessages = debounce(this.getSentMessages, 300);
}
