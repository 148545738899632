/**
 * Map defining connections between new and old frontend routes
 * !!! Be careful when changing some (timetable) routes and params here!!!
 * There is ui2020RoutesBuilder.ts in the old frontend too because of the PoDS period details link navigation!
 * New route path param names should be the same as search param names of the legacy page.
 * Params with f__ prefix represents timetable filter parameters.
 * Order (from the most to the least specific) is important if there are multiple entries with the same base legacy path
 * (e.g. #conflictform?elemType=6 and #conflictform).
 */
export default new Map<string, string>([
  ['/today', '/basic/main'],
  ['/timetable-classes-my/:date?', '/basic/timetable?selectedTab=1&my'],
  ['/timetable-teachers-my/:date?', '/basic/timetable?selectedTab=2&my'],
  ['/timetable-students-my/:date?', '/basic/timetable?selectedTab=5&my'],
  ['/timetable-students-parent/:id?/:date?', '/basic/timetableParent?selectedTab=5&myParent'],
  ['/timetable-classes-parent/:id?/:date?', '/basic/timetableParent?selectedTab=1&myParent'],
  ['/timetable-classes/:id?/:date?', '/basic/timetable?selectedTab=1'],
  ['/timetable-teachers/:id?/:date?', '/basic/timetable?selectedTab=2'],
  ['/timetable-resources/:id?/:date?/:f__restypeId?', '/basic/timetable?selectedTab=6'],
  ['/timetable-rooms/:id?/:date?/:f__buildingId?/:f__roomGroupId?', '/basic/timetable?selectedTab=4'],
  ['/timetable-subjects/:id?/:date?', '/basic/timetable?selectedTab=3'],
  ['/timetable-students/:id?/:date?/:f__klasseOrStudentgroupId?', '/basic/timetable?selectedTab=5'],
  ['/timetable-daily-overview-rooms', '#Timetable?type=4&name=*&formatId=0'],
  ['/timetable-daily-overview-teachers', '#Timetable?type=2&name=*&formatId=0'],
  ['/timetable-daily-overview-classes', '#Timetable?type=1&name=*&formatId=0'],
  ['/timetable-overview-classes', '#selectelementform?elemTypeStr=1'],
  ['/timetable-overview-teachers', '#selectelementform?elemTypeStr=2'],
  ['/timetable-overview-resources', '#selectelementform?elemTypeStr=6'],
  ['/timetable-overview-rooms', '#selectelementform'],
  ['/timetable-contact-hours', 'basic/officehours'],
  ['/my-lessons', '#lessonlist?my=true'],
  ['/teacher-lessons', '#lessonlist'],
  ['/class-lessons', '#lessonweeklist'],
  ['/student-lessons', '#lessonstudentweeklist'],
  ['/student-exams', '/basic/studentexams'],
  ['/student-grades', '/classreg/lessongrades'],
  ['/student-booking', '/booking/student'],
  ['/daily-class-lessons', '#lessonklasselist'],
  ['/daily-teacher-lessons', '#lessonteacherlist'],
  ['/yearly-class-lessons', '#yearlylessonklasselist'],
  ['/yearly-teacher-lessons', '#yearlylessonteacherlist'],
  ['/student-groups', '#studentgrouplist'],
  ['/lessons-tasks', '#usertasklist'],
  ['/my-bookings', '#bookinglist?list=user'],
  ['/appointments', '#appointmentsearch'],
  ['/new-activity', '#activity'],
  ['/book-resource', '#resourcebooking?mode=1'],
  ['/book-room', '#booking?mode=1'],
  ['/list-of-rooms', '#roomsearchform'],
  ['/list-of-resources', '#resourcesearchform'],
  ['/booking-reports', '#bookingreportsform'],
  ['/bookings', '#bookinglistadmin?list=admin'],
  ['/todo-list', '#bookingworkitems'],
  ['/resource-conflicts', '#conflictform?elemType=6'],
  ['/room-conflicts', '#conflictform'],
  ['/open-periods-teacher', '#lessontopiclistteacher'],
  ['/open-periods-class', '#lessontopiclistklasse'],
  ['/absences', '#absencelist'],
  ['/absence-times', '#absencetimes'],
  ['/class-register-entries', '#classregeventlist'],
  ['/exemptions', '#exemptionlist'],
  ['/class-services', '#studentduty'],
  ['/class-register-reports', '#classregreportsform'],
  ['/class-register-settings', '#classregsettings'],
  ['/course-templates', '#coursetemplatelist'],
  ['/courses', '#courselist'],
  ['/courses-per-student', '#courseperstudentlist'],
  ['/registration-periods', '#registrationtimelist'],
  ['/courses-reports', '#coursereports'],
  ['/course-categories', '#coursecategorylist'],
  ['/course-choice-categories', '#coursechoicecategorylist'],
  ['/course-modules', '#coursemodulelist'],
  ['/stages-of-studies', '#coursestudyphaselist'],
  ['/courses-settings', '#coursesettingsform'],
  ['/course-registration', '/course/courses?selectedTab=coursechoice'],
  ['/my-courses', '/course/courses?selectedTab=coursearchive'],
  ['/course-preview', '/course/courses?selectedTab=coursepreview'],
  /* Route /scheduling is the route for the "old" (= before 2021) implementation of the timetabling-module */
  /* It is to be replaced by the "new" /timetabling/... routes once the redesign of the module is finished. */
  ['/scheduling', '#timetabling/horizons'],
  ['/classes', '#klasselist'],
  ['/students', '#studentlist'],
  ['/students-import', '#importstudent'],
  ['/legalguardians', '#legalguardianlist'],
  ['/apprenticerepresentatives', '#apprenticerepresentativelist'],
  ['/teachers', '#teacherlist'],
  ['/teaching-qualifications', '#teacherqualificationlist'],
  ['/teacher-status', '#teacherstatuslist'],
  ['/subject-groups', '#subjectgrouplist'],
  ['/subject-types', '#subjecttypelist'],
  ['/rooms', '#roomlist'],
  ['/rooms-import', '#importroom'],
  ['/room-groups', '#roomgrouplist'],
  ['/room-types', '#roomtypelist'],
  ['/resources', '#resourcelist'],
  ['/groups-of-resources', '#resourcegrouplist'],
  ['/types-of-resources', '#resourcetypelist'],
  ['/reasons-of-absence', '#absencereasonlist'],
  ['/excuse-status', '#excusestatuslist'],
  ['/reasons-of-exemptions', '#exemptionreasonlist'],
  ['/remark-categories', '#eventreasonlist'],
  ['/groups-for-remark-categories', '#eventreasongrouplist'],
  ['/services', '#dutylist'],
  ['/student-properties', '#studentattributetypelist'],
  ['/grading-schemes', '#markschemalist'],
  ['/teaching-methods', '#teachingmethodlist'],
  ['/types-of-activities', '#activitytypelist'],
  ['/reduction-reasons', '#reductionreasonlist'],
  ['/tasks', '#usertasktypelist'],
  ['/departments', '#departmentlist'],
  ['/holidays', '#holidaylist'],
  ['/school-years', '#schoolyearlist'],
  ['/time-grid', '#timegrid'],
  ['/messages-of-the-day', '#messagedaylist'],
  ['/export', '#dataexport'],
  ['/active-users', '#activeusers'],
  ['/rights-and-roles', '/basic/userrole'],
  ['/exam-calendar', '#basic/examCalendar'],
  ['/users', '#userlist'],
  ['/lock-out-times', '#bookinglocklist'],
  ['/booking-restrictions', '#bookingrestrictions'],
  ['/room-blockings', '#roomlocklist'],
  ['/layout-settings', '#formats'],
  ['/monitor-views', '#monitorformats'],
  ['/booking-formats', '#bookingformformatlist'],
  ['/timetable-settings', '#ttsettingsform'],
  ['/settings', '#settingsform'],
  ['/integration', '#integrations'],
  ['/parent-teacher-days', '#parentsdaylist'],
  ['/playground', '/basic/playground'],
  ['/privacy', '#privacy'],
  ['/about-webuntis', '#about'],
  ['/assignment-groups', '#assignmentgrouplist'],
  ['/system-lock', '/basic/systemLock'],
  ['/booking-lock', '/basic/bookingLock'],
  ['/backup', '#backup'],
  ['/backup-anonymise', '#backup?anonymise=true'],
  ['/import-novaschem', '#importNovaschem'],
  ['/hamburg', '#hamburg'],
  ['/luxemburg', '#luxcentral/teacher'],
  ['/profile', '#basic/profile'],
  ['/message-center', '#messagecenter'],
  ['/contact-details', '/basic/contact'],
  ['/drive', '/basic/drive'],
  ['/pods-timetable-teacher', '/basic/pods-timetable-teacher'],
  ['/pods-timetable-student', '/basic/pods-timetable-student'],
  ['/student-absences', '/classreg/studentabsences'],
  ['/overview', '/classreg/classreg'],
  ['/student-exemptions', '/classreg/studentexemptions'],
  ['/student-class-services', '/classreg/studentclassservices'],
  ['/student-homework', '/basic/studenthomeworks'],
  ['/student-class-register-entries', '/classreg/studentclassregevents'],
]);
