import { RefObject, useEffect, useRef } from 'react';

/**
 * Hook which can be used to react if the user clicks outside of the element which is used as ref.
 * You have to provide a ref object as third parameter, then this will be used instead of creating a new one.
 *
 * If an outsideclick is detected, the given callback function will be called.
 *
 * With the second param, the excluded elements array, you can specify elements which are outside, but won't trigger
 * the callback either. (also excludes children of these elements)
 */
export function useOutsideClickWithRef(
  callback: (event: MouseEvent) => void,
  ref: RefObject<HTMLElement>,
  excludedElements?: Array<Node | null>,
) {
  function handleClickOutside(event: MouseEvent) {
    // When the user clicks on an item of a dropdown (drop-down.tsx), the items are injected into the dom
    // absolute positioned and therefore an outside click would always be detected (false positive).
    // To prevent this, we exclude all clickable parts of the dropdown from outside checks
    if (
      ((event.target as Element).classList && (event.target as Element).classList.contains('ant-select-item')) ||
      (event.target as Element).classList.contains('ant-select-dropdown') ||
      (event.target as Element).classList.contains('rc-virtual-list-scrollbar') ||
      (event.target as Element).classList.contains('rc-virtual-list-scrollbar-thumb') ||
      (event.target as Element).classList.contains('ant-select-item-option-content')
    ) {
      return false;
    }

    const targetNode = event.target as Node;

    if (excludedElements) {
      for (const excludedElement of excludedElements) {
        if (excludedElement && (excludedElement === event.target || excludedElement.contains(targetNode))) {
          return;
        }
      }
    }

    if (event.target && ref.current && !ref.current.contains(targetNode)) {
      callback(event);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

/**
 * Same as the useOutsideClickWithRef hook, but the ref is being generated and returned by the hook so you can directly
 * use it to set the ref element.
 */
export function useOutsideClick(callback: (event: MouseEvent) => void, excludedElements?: Array<Node | null>) {
  const ref = useRef<HTMLDivElement | any>(null);

  useOutsideClickWithRef(callback, ref, excludedElements);

  return ref;
}
