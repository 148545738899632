import React from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import { Route } from 'react-router-dom';

import AdminMessagesOfTheDayList from './messages-of-day-list';
import AdminMessagesOfTheDayNew from './messages-of-day-new';
import AdminMessagesOfTheDayEdit from './messages-of-day-edit';

import { IMessagesOfDayFormMessage } from '@ad/messages-of-day/messages-of-day-form';
import { MessagesOfTheDayStore } from '@ad/messages-of-day/messages-of-day-store';
import useStore from '@/hooks/useStore';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';

const AdminMessagesOfTheDay = observer(() => {
  const [form] = Form.useForm<IMessagesOfDayFormMessage>();
  const messagesOfTheDayStore = useStore(MessagesOfTheDayStore);

  useComponentDidMount(() => {
    messagesOfTheDayStore.setFormInstance(form);
  });

  return (
    <>
      <AdminMessagesOfTheDayList />
      <Route path="/messages-of-the-day-new/new" render={() => <AdminMessagesOfTheDayNew />} />
      <Route path="/messages-of-the-day-new/edit/:id" render={() => <AdminMessagesOfTheDayEdit />} />
    </>
  );
});

export default AdminMessagesOfTheDay;
