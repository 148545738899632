import { RefObject } from 'react';
import { ArcherContainerHandle } from 'react-archer/lib/ArcherContainer/ArcherContainer.types';

import { Store } from '@/types/store';

@Store()
export default class RefStore {
  embeddedWebUntisIFrameRef: RefObject<HTMLIFrameElement> = { current: null };
  archerContainerRef: RefObject<ArcherContainerHandle> = { current: null };
}
