import React from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import { Route } from 'react-router-dom';

import { StudentDutiesStore } from '@md/student-duties/student-duties-store';
import useStore from '@/hooks/useStore';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import { StudentDutiesNew } from '@md/student-duties/student-duties-new';
import { StudentDutiesEdit } from '@md/student-duties/student-duties-edit';
import { StudentDutiesList } from '@md/student-duties/student-duties-list';

export const MasterDataStudentDuties = observer(() => {
  const store = useStore(StudentDutiesStore);
  const [form] = Form.useForm<StudentDutiesStore>();

  useComponentDidMount(() => {
    store.setFormInstance(form);
  });

  return (
    <>
      <StudentDutiesList />
      <Route path="/services/new" render={() => <StudentDutiesNew />} />
      <Route path="/services/edit/:id" render={() => <StudentDutiesEdit />} />
    </>
  );
});
