import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useStore from '@/hooks/useStore';
import Seperator from '@mg/components/mg-seperator/mg-seperator';
import ReadConfirmationTable from '@mg/components/read-confirmation-table/read-confirmation-table';
import ReadConfirmationStore from '@mg/stores/read-confirmation-store';
import { Skeleton } from '@/ui-components';

import './read-confirmation-view.less';

interface IReadConfirmationViewProps {
  subject: string;
}

const ReadConfirmationView = observer(({ subject }: IReadConfirmationViewProps) => {
  const { t } = useTranslation();
  const readConfirmationStore = useStore(ReadConfirmationStore);

  return (
    <div>
      <div>
        <h1>{t('general.confirmations')}</h1>
        <div className="read-confirmation-state">
          {readConfirmationStore.isLoading && <Skeleton.Input style={{ width: 50, marginTop: 8 }} active={true} />}
          {!readConfirmationStore.isLoading && (
            <>
              <span className={readConfirmationStore.confirmedRequestCount > 0 ? 'read-confirmation-count' : ''}>
                {readConfirmationStore.confirmedRequestCount}
              </span>
              <Seperator />
              {readConfirmationStore.totalRequestCount}
            </>
          )}
        </div>
      </div>
      <h2>
        {t('general.messageSubject')}: {subject}
      </h2>

      <ReadConfirmationTable
        isLoading={readConfirmationStore.isLoading}
        requestConfirmations={readConfirmationStore.requestConfirmations}
      />
    </div>
  );
});
export default ReadConfirmationView;
