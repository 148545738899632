import { TimetableEntityType } from '@te/standard/stores/timetable-root-store';
import AnalyticsStore from '@/stores/analytics-store/analytics-store';
import { inject, Store } from '@/types/store';
import { EventAction } from '@/stores/analytics-store/analytics-store-tracking-config';

@Store()
export default class TimetableAnalyticsStore {
  private analyticsStore: AnalyticsStore = inject(AnalyticsStore);

  trackTimetableVisit(timetableEntityType: TimetableEntityType) {
    switch (timetableEntityType) {
      case 'student':
      case 'my-student':
        this.analyticsStore.trackEvent('TimetableNew', 'TimetableStudentViewLoaded');
        break;
      case 'class':
      case 'my-class':
        this.analyticsStore.trackEvent('TimetableNew', 'TimetableClassViewLoaded');
        break;
      case 'teacher':
      case 'my-teacher':
        this.analyticsStore.trackEvent('TimetableNew', 'TimetableTeacherViewLoaded');
        break;
      case 'subject':
        this.analyticsStore.trackEvent('TimetableNew', 'TimetableSubjectViewLoaded');
        break;
      case 'room':
        this.analyticsStore.trackEvent('TimetableNew', 'TimetableRoomViewLoaded');
        break;
      case 'resource':
        this.analyticsStore.trackEvent('TimetableNew', 'TimetableResourceViewLoaded');
        break;
    }
  }
  trackClick(event: EventAction, timetableEntityType?: TimetableEntityType, value?: any) {
    this.analyticsStore.trackEvent('TimetableNew', event, 'click', 1, {
      timetableType: timetableEntityType,
      value: value,
    });
  }
  trackToggle(event: EventAction, timetableEntityType?: TimetableEntityType, toggleElement?: any, toggleState?: any) {
    this.analyticsStore.trackEvent('TimetableNew', event, 'click', 1, {
      timetableType: timetableEntityType,
      toggleElement: toggleElement,
      value: toggleState,
    });
  }
}
