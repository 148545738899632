import React from 'react';

interface IProps {
  emptyText?: string;
}

const EmptyElement = (props: IProps) => {
  return (
    <>
      {props.emptyText && (
        <div className="empty-element">
          <label>{props.emptyText}</label>
        </div>
      )}
    </>
  );
};

export default EmptyElement;
