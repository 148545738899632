import React, { useEffect, useRef, useState } from 'react';

import { ILessonCardColorBar } from '@/components/lesson-card/lesson-card';
import { lightenColor } from '@/utils/color/color';

import './lesson-card-color-bar.less';

const LessonCardColorBar = ({ color, isStriped }: ILessonCardColorBar) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string | null>(null);

  const lightColor = isStriped ? lightenColor(color) : undefined;

  useEffect(() => {
    if (ref.current) {
      setHeight((ref.current.offsetHeight <= 24 ? 8 : 16) + 'px');
    }
  }, [ref?.current?.offsetHeight]);

  const lessonCardStyle = {
    '--height': height,
    '--color': color,
    '--light-color': lightColor == undefined ? '' : lightColor,
  };

  return (
    <div
      ref={ref}
      style={lessonCardStyle as React.CSSProperties}
      className={lightColor == undefined ? 'lesson-card-color-bar' : 'lesson-card-color-bar-striped'}
      data-testid="lesson-card-color-bar"
    />
  );
};

export default LessonCardColorBar;
