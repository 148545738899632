import { createContext } from 'react';

export enum ResponsivenessThreshold {
  XS_MOBILE_DEVICES,
  S_TABLETS,
  M_LAPTOPS,
  L_DESKTOP,
  XL_TV,
}

interface IResponsivenessContext {
  threshold: ResponsivenessThreshold;
  responsiveClassName: string;
}

export const getResponsivenessThresholdForWidth = (width: number): ResponsivenessThreshold => {
  if (width <= 480) {
    return ResponsivenessThreshold.XS_MOBILE_DEVICES;
  } else if (width >= 481 && width <= 768) {
    return ResponsivenessThreshold.S_TABLETS;
  } else if (width >= 769 && width <= 1024) {
    return ResponsivenessThreshold.M_LAPTOPS;
  } else if (width >= 1025 && width <= 1200) {
    return ResponsivenessThreshold.L_DESKTOP;
  }
  return ResponsivenessThreshold.XL_TV;
};

export const getResponsivenessClassName = (threshold: ResponsivenessThreshold): string => {
  switch (threshold) {
    case ResponsivenessThreshold.XS_MOBILE_DEVICES:
      return 'responsive-size-xs';
    case ResponsivenessThreshold.S_TABLETS:
      return 'responsive-size-s';
    case ResponsivenessThreshold.M_LAPTOPS:
      return 'responsive-size-m';
    case ResponsivenessThreshold.L_DESKTOP:
      return 'responsive-size-l';
    case ResponsivenessThreshold.XL_TV:
      return 'responsive-size-xl';
  }
};

export const ResponsivenessContext = createContext<IResponsivenessContext | null>(null);
