import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Flex, WUForm } from '@/ui-components';
import useStore from '@/hooks/useStore';
import { ExamTypeStore, IExamTypeForm } from '@/pages/master-data/exam-type/exam-type-store';
import { ExamTypeDto } from '@untis/wu-rest-view-api/api';
import { IFormButton } from '@/ui-components/wu-form/wu-form';
import { validateForm } from '@/utils/form/form-util';
import ModalStore from '@/stores/modal-store';
import { FormSection } from '@/ui-components/wu-form/form-section/form-section';
import { FormLabel } from '@/ui-components/wu-form/form-label/form-label';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { FormRadioButtons } from '@/ui-components/wu-form/form-radio-buttons/form-radio-buttons';
import { FormColorPicker } from '@/ui-components/wu-form/form-color-picker/form-color-picker';
import { FormCheckboxes } from '@/ui-components/wu-form/form-checkboxes/form-checkboxes';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import { TextValidationPatterns } from '@/utils/form/common-form-rules';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import { WUFormCol, WUFormRow } from '@/ui-components/wu-form';

interface IProps {
  examType?: ExamTypeDto;
  examTypeStore: ExamTypeStore;
}

export const ExamTypeForm = observer((props: IProps) => {
  const modalStore = useStore(ModalStore);
  const rightsStore = useStore(RightsStore);
  const { t } = useTranslation();

  const { examType, examTypeStore } = props;
  const isInEditMode = !!props.examType;
  const disabled = isInEditMode && !rightsStore.canWrite(Right.MASTERDATA, ElementType.EXAM_TYPE, true);

  const leftButtons: IFormButton[] =
    isInEditMode && rightsStore.canDelete(Right.MASTERDATA, ElementType.EXAM_TYPE, true)
      ? [
          {
            label: t('general.delete'),
            onClick: () => {
              if (props.examType?.id) {
                examTypeStore.deleteExamType(props.examType.id);
              }
            },
          },
        ]
      : [];

  const rightButtons: IFormButton[] = [];
  if (!isInEditMode) {
    rightButtons.push({
      label: t('general.saveAndNew'),
      type: 'primary',
      disabled: examTypeStore.submitAllDisabled,
      outline: false,
      onClick: () => {
        validateForm(examTypeStore.form).then((isValid) => {
          if (isValid) {
            examTypeStore.saveNewExamType(examTypeStore.form.getFieldsValue(), true);
          }
        });
      },
    });
  }
  rightButtons.push({
    label: disabled ? t('general.ok') : t('general.cancel'),
    onClick: () => {
      modalStore.closeModal();
    },
  });

  const initialSettingsCheckboxValues = [];
  if (!examType || examType.heralded) {
    initialSettingsCheckboxValues.push('announced');
  }
  if (!examType || examType.sendToUntis) {
    initialSettingsCheckboxValues.push('transferToUntis');
  }
  if (!examType || examType.active) {
    initialSettingsCheckboxValues.push('active');
  }

  const initialMaxNumberCheckboxValues: string[] = [];
  if (!examType || examType.countingWeekend) {
    initialMaxNumberCheckboxValues.push('includingWeekends');
  }

  return (
    <WUForm<IExamTypeForm>
      form={examTypeStore.form}
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      testIdSaveButton="save-button"
      fixedButtonBar={true}
      hideSubmitButton={disabled}
      onDisableChange={(disabled) => examTypeStore.setSubmitAllDisabled(disabled)}
      onSubmit={
        isInEditMode
          ? (value: IExamTypeForm) => examTypeStore.updateExamType(value, props.examType)
          : (value: IExamTypeForm) => examTypeStore.saveNewExamType(value, false)
      }
      maxWidth
      paddingTop
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={1}>
            <FormSection centered text={t('general.name')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormLabel text={'* ' + t('general.studentDetails.requiredField')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              name="name"
              label={`${t('general.shortName')} *`}
              variant="floating"
              testId="exam-type-name"
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              initialValue={examType?.name ? examType.name : ''}
              rules={[
                { required: true, message: t('general.inputRequired') },
                {
                  max: 20,
                  message: t('general.inputTooLong'),
                },
              ]}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormLabel centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              name="longName"
              label={t('general.longName')}
              initialValue={examType?.longName ?? ''}
              variant="floating"
              testId="exam-type-long-name"
              disabled={disabled}
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              rules={[
                {
                  max: 255,
                  message: t('general.inputTooLong'),
                },
              ]}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection centered text={t('general.examinationType')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormRadioButtons
              name="examinationType"
              options={
                new Map([
                  ['written', t('general.written')],
                  ['oral', t('general.oral')],
                ])
              }
              testId="exam-type-written-or-oral"
              initialValue={examType ? (examType.inWriting ? 'written' : 'oral') : 'written'}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.colors')} />
          </WUFormCol>

          <WUFormCol lg={2}>
            <FormColorPicker
              name="foregroundColor"
              placeholder={t('general.foregroundColor')}
              testId="exam-type-foreground-color"
              initialValue={examType?.foreColor ? '#' + examType.foreColor : '#000000'}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormLabel centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormColorPicker
              placeholder={t('general.backgroundColor')}
              name="backgroundColor"
              testId="exam-type-background-color"
              initialValue={examType?.backColor ? '#' + examType.backColor : '#000000'}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.settings')} />
          </WUFormCol>

          <WUFormCol lg={2}>
            <FormCheckboxes
              name="settings"
              options={[
                {
                  label: t('general.active'),
                  value: 'active',
                },
                {
                  label: t('masterData.examTypes.form.announced'),
                  value: 'announced',
                },
                {
                  label: t('masterData.examTypes.form.transferToUntis'),
                  value: 'transferToUntis',
                },
              ]}
              testId="exam-type-settings"
              initialValue={initialSettingsCheckboxValues}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={1}>
            <FormSection text={t('masterData.examTypes.form.maxStudentsPerExam')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormLabel text={t('masterData.examTypes.form.maxExamsPerDay')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              name="maxExamsPerDay"
              initialValue={examType?.maxExamsPerDay ? examType.maxExamsPerDay.toString() : '0'}
              disabled={disabled}
              testId="exam-type-max-exam-per-day"
              pattern={TextValidationPatterns.NUMBERS_ONLY}
              maxLength={3}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormLabel text={t('masterData.examTypes.form.maxExamsPerWeek')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              name="maxExamsPerWeek"
              initialValue={examType?.maxExamsPerWeek ? examType.maxExamsPerWeek.toString() : '0'}
              disabled={disabled}
              testId="exam-type-max-exam-per-week"
              pattern={TextValidationPatterns.NUMBERS_ONLY}
              maxLength={3}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormLabel text={t('masterData.examTypes.form.maxExamsPerRange')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <Flex justifyContent="space-between">
              <div style={{ width: '60px' }}>
                <FormInput
                  name="maxExamsPerRange"
                  initialValue={examType?.maxExamsPerRange ? examType.maxExamsPerRange.toString() : '0'}
                  disabled={disabled}
                  testId="exam-type-max-exam-per-range"
                  pattern={TextValidationPatterns.NUMBERS_ONLY}
                  maxLength={3}
                />
              </div>
              <div>
                <FormLabel text={t('general.within')} centered />
              </div>
              <div style={{ width: '60px' }}>
                <FormInput
                  name="examsPerRangeNumberDays"
                  initialValue={examType?.examsPerRangeNumberDays ? examType.examsPerRangeNumberDays.toString() : '0'}
                  disabled={disabled}
                  pattern={TextValidationPatterns.NUMBERS_ONLY}
                  maxLength={3}
                />
              </div>
              <div>
                <FormLabel text={t('general.days')} centered />
              </div>
            </Flex>
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormLabel text={t('general.grouping')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              name="typeRestriction"
              initialValue={examType?.typeRestriction ? examType.typeRestriction.toString() : '0'}
              disabled={disabled}
              testId="exam-type-type-restriction"
              pattern={TextValidationPatterns.NUMBERS_ONLY}
              maxLength={3}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormLabel text={t('masterData.examTypes.form.noExamAfter')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <Flex justifyContent="space-between">
              <div style={{ width: '60px' }}>
                <FormInput
                  name="numberFreeDays"
                  initialValue={examType?.numberFreeDays ? examType.numberFreeDays.toString() : '0'}
                  disabled={disabled}
                  testId="exam-type-number-of-free-days"
                  pattern={TextValidationPatterns.NUMBERS_ONLY}
                  maxLength={3}
                />
              </div>
              <div>
                <FormLabel text={t('masterData.examTypes.form.numberFreeDays')} centered />
              </div>
              <div style={{ width: '60px', whiteSpace: 'nowrap' }}>
                <FormCheckboxes
                  name="countingWeekend"
                  options={[
                    {
                      label: t('masterData.examTypes.form.countingWeekend'),
                      value: 'includingWeekends',
                    },
                  ]}
                  initialValue={initialMaxNumberCheckboxValues}
                  disabled={disabled}
                />
              </div>
            </Flex>
          </WUFormCol>
        </WUFormRow>

        {rightsStore.canWrite(Right.MASTERDATA, ElementType.MARK_SCHEMA, true) ? (
          <>
            <WUFormRow>
              <WUFormCol lg={1}>
                <FormSection text={t('general.grades')} />
              </WUFormCol>
              <WUFormCol lg={2}>
                <FormLabel text={t('general.gradingScheme')} centered />
              </WUFormCol>
              <WUFormCol lg={2}>
                <FormSingleSelect
                  name="gradingScheme"
                  placeholder={t('general.gradingScheme')}
                  testId="exam-type-grading-scale"
                  items={examTypeStore.gradingSchemes}
                  initialValue={
                    examType?.gradingScale && examType.gradingScale.id !== 0
                      ? examType?.gradingScale?.id.toString()
                      : undefined
                  }
                  allowClear={true}
                  disabled={disabled}
                  styleVariant="floating"
                />
              </WUFormCol>
            </WUFormRow>

            <WUFormRow>
              <WUFormCol lg={2}>
                <FormLabel text={t('masterData.examTypes.form.weightingFactor')} centered />
              </WUFormCol>
              <WUFormCol lg={2}>
                <FormInput
                  name="weightFactor"
                  initialValue={examType?.weightFactor ? examType.weightFactor.toString() : '0'}
                  disabled={disabled}
                  testId="exam-type-weight-factor"
                  pattern={TextValidationPatterns.NUMBERS_ONLY}
                  maxLength={3}
                />
              </WUFormCol>
            </WUFormRow>
          </>
        ) : null}
      </WUFormCol>
    </WUForm>
  );
});
