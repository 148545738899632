import { Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren, useMemo } from 'react';
import ReactDOM from 'react-dom';

import useStore from '@/hooks/useStore';
import AppStore from '@/stores/app-store';

import './page-header.less';

interface IProps {
  className?: string;
  fixed?: boolean;
  fixedVisible?: boolean;
}

/**
 * @deprecated in favor of PageHeader (WU-8313)
 */
const DeprecatedPageHeader = observer((props: PropsWithChildren<IProps>) => {
  const appStore = useStore(AppStore);

  const className = useMemo(() => {
    let value = 'deprecated-page-header';

    if (props.fixed) {
      value += ' deprecated-page-header--fixed';
    } else {
      value += ' deprecated-page-header--anchored';
    }

    if (props.fixedVisible) {
      value += ' deprecated-page-header--fixed-visible';
    }

    if (props.className) {
      value += ' ' + props.className;
    }

    return value;
  }, [props.className, props.fixed, props.fixedVisible]);

  const renderResult = (
    <Row align="middle" className={className}>
      {props.children}
    </Row>
  );

  if (props.fixed) {
    if (appStore.stickyHeaderContainerRef) {
      return ReactDOM.createPortal(renderResult, appStore.stickyHeaderContainerRef);
    } else {
      return null;
    }
  } else {
    return renderResult;
  }
});

export default DeprecatedPageHeader;
