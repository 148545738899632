import TextArea, { TextAreaProps } from 'antd/lib/input/TextArea';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IInputProps } from '@/ui-components/input/input';
import { Button, Input } from '@/ui-components';

import './inline-text-input.less';

interface IProps {
  value: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  onSave?: (value: string) => void;
  singleLine?: boolean;
  validate?: (value: string) => true | string; // if not valid, a validation error message should be returned
}

const InlineTextInput = (props: IProps) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [editValue, setEditValue] = useState(props.value);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setEditValue(props.value);
  }, [props.value]);

  const { t } = useTranslation();

  const handleClick = () => {
    if (!props.disabled && isValid) {
      setIsInEditMode(true);
    }
  };

  const handleSave = () => {
    setIsInEditMode(false);
    if (props.onSave) {
      props.onSave(editValue);
    }
    setHasUnsavedChanges(false);
  };

  const handleCancel = () => {
    setIsInEditMode(false);
    setEditValue(props.value);
    setHasUnsavedChanges(false);
  };

  const handleBlur = () => {
    if (!hasUnsavedChanges) {
      setIsInEditMode(false);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>) => {
    setEditValue(e.target.value);
    setIsInEditMode(true);
    setHasUnsavedChanges(true);

    if (props.validate) {
      const validationResult = props.validate(e.target.value);
      if (validationResult === true) {
        // careful, you need to check "true", not just "truthy"
        setValidationError('');
        setIsValid(true);
      } else {
        setValidationError(validationResult);
        setIsValid(false);
      }
    }
  };

  let className = 'inline-text-input';
  className = className + (isInEditMode ? ' edit-mode' : '');
  className = className + (props.singleLine ? ' single-line' : '');

  const textAreaProps: TextAreaProps = {
    disabled: props.disabled,
    value: editValue,
    onClick: handleClick,
    onChange: handleChange,
    autoSize: true,
    placeholder: props.placeholder,
    onBlur: handleBlur,
  };

  const inputProps: IInputProps = {
    disabled: props.disabled,
    value: editValue,
    onClick: handleClick,
    onChange: handleChange,
    placeholder: props.placeholder,
    onBlur: handleBlur,
  };

  const inputComponent = props.singleLine ? <Input {...inputProps} /> : <TextArea {...textAreaProps} />;

  const buttons = (
    <>
      <Button outline onClick={handleCancel} size="small">
        {t('general.cancel')}
      </Button>
      <Button type="primary" onClick={handleSave} disabled={!isValid}>
        {t('general.save')}
      </Button>
    </>
  );

  return (
    <div className={className}>
      {props.label && <label>{props.label}</label>}
      {inputComponent}
      {!props.disabled && (
        <div className="inline-text-input-button-bar">
          <div className="helper">{buttons}</div>
        </div>
      )}
      {!isValid && isInEditMode && (
        <div className="validation-error">
          <span>{validationError}</span>
          {/* we render the buttons again but hide them, so that the "real buttons do not
        overlap the validation message if it is too long" */}
          <div className="validation-helper">{buttons}</div>
        </div>
      )}
    </div>
  );
};

export default InlineTextInput;
