import { action } from 'mobx';
import React from 'react';
import { t } from 'i18next';
import { Dayjs } from 'dayjs';

import {
  AbstractTimetableDetailsModalHandler,
  TimetableEntry,
} from '@te/standard/stores/url/abstract-timetable-details-modal-handler';
import { TimetableUrl } from '@te/standard/stores/url/timetable-url-store';
import { formatDateTimeEN } from '@/utils/date/date-util';
import ExternalEventDetails from '@te/standard/components/external-event/external-event-details';
import { IFullDayEvent, IGridEntry, TimetableDataStore } from '@te/standard/stores/data/timetable-data-store';
import { TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import { inject, Store } from '@/types/store';
import { LessonCardRowContent, LessonCardType } from '@/components/lesson-card/lesson-card';
import { DayEntryTypeEnum, Timespan } from '@untis/wu-rest-view-api';

export interface IExternalTimetableEvent {
  id: number;
  title: string;
  location: string;
  groupName: string;
  startDateTime: Dayjs;
  endDateTime: Dayjs;
  durationTotal?: Timespan;
  description: string;
  color: string;
  calendarName: string;
  isTentative?: boolean;
  externalLink?: string;
}

@Store()
export class ExternalEventDetailsModalHandler extends AbstractTimetableDetailsModalHandler {
  private timetableDataStore = inject(TimetableDataStore);
  private timetableMetaStore: TimetableMetaStore = inject(TimetableMetaStore);

  shouldHandleTimetableUrl(timetableUrl: TimetableUrl): boolean {
    return timetableUrl.subPath === 'external';
  }

  handleTimetableUrl(timetableUrl: TimetableUrl, onCloseCallback: () => void): void {
    const timetableEntryId = Number(timetableUrl.entryId);
    const eventToOpen = this.findTimetableEntryToOpenForDay(timetableEntryId);
    if (eventToOpen) {
      this.openDetailsModal(eventToOpen, onCloseCallback);
    }
  }

  shouldHandleTimetableEntry(entry: TimetableEntry): boolean {
    if (this.isGridEntry(entry)) {
      return (entry as IGridEntry).lessonCardProps.type === LessonCardType.EXTERNAL_CALENDAR_EVENT;
    } else if (this.isFullDayEvent(entry)) {
      return (entry as IFullDayEvent).type === DayEntryTypeEnum.EXTERNAL_CALENDAR_EVENT;
    } else {
      return false;
    }
  }

  getTimetableExtraPath(entry: TimetableEntry): string {
    const entryId = this.getId(entry);
    const timetableEntityId = this.timetableFilterStore.selectedFilterValue ?? -1;
    const timetableEntityTypeId = this.timetableEntityTypeId;
    const formattedStartTime = formatDateTimeEN(this.getStartDateTime(entry));
    const formattedEndTime = formatDateTimeEN(this.getEndDateTime(entry));

    // eslint-disable-next-line max-len
    return `/external/${entryId}/${timetableEntityId}/${timetableEntityTypeId}/${formattedStartTime}/${formattedEndTime}/${false}`;
  }

  openDetailsModal(entry: TimetableEntry, onCloseCallback: () => void): void {
    if (this.isGridEntry(entry)) {
      this.openExternalTimetableEventDetails(
        this.mapGridEntryToExternalTimetableEvent(entry as IGridEntry),
        onCloseCallback,
      );
    } else if (this.isFullDayEvent(entry)) {
      this.openExternalTimetableEventDetails(
        this.mapFullDayEventToExternalTimetableEvent(entry as IFullDayEvent),
        onCloseCallback,
      );
    }
  }

  private findTimetableEntryToOpenForDay(timetableEntryId: number): TimetableEntry | undefined {
    let toOpen: TimetableEntry | undefined;
    const { currentTimetableDays } = this.timetableMetaStore;
    currentTimetableDays.forEach((timetableDay) => {
      this.timetableDataStore
        .getTimetableEntries(timetableDay.dayValue)
        .gridEntries.forEach((summarisedGridEntries) => {
          summarisedGridEntries.entries.forEach((gridEntry) => {
            if (toOpen === undefined && gridEntry.isExternal && gridEntry.periodIds.includes(timetableEntryId)) {
              toOpen = gridEntry;
            }
          });
        });

      this.timetableDataStore.getFullDayEvents(timetableDay.dayValue).events.forEach((fullDayEvent) => {
        if (
          toOpen === undefined &&
          fullDayEvent.type === DayEntryTypeEnum.EXTERNAL_CALENDAR_EVENT &&
          fullDayEvent.id === timetableEntryId
        ) {
          toOpen = fullDayEvent;
        }
      });
    });

    return toOpen;
  }

  @action
  private openExternalTimetableEventDetails(event: IExternalTimetableEvent, onCloseCallback: () => void) {
    const leftButtons = [];
    if (event.externalLink) {
      leftButtons.push({
        label: t('general.goToLocation', { location: event.calendarName }),
        onClick: () => window.open(event.externalLink),
      });
    }
    leftButtons.push({
      label: t('general.close'),
      onClick: () => {
        this.modalStore.closeModal().then(() => {
          onCloseCallback();
        });
      },
    });
    this.modalStore
      .openModalDialog({
        children: <ExternalEventDetails {...event} />,
        size: 'md',
        className: 'external-event-details-modal',
        showFooterSeparator: true,
        leftButtons: leftButtons,
      })
      .then(() => {
        onCloseCallback();
      });
  }

  private mapGridEntryToExternalTimetableEvent(gridEntry: IGridEntry): IExternalTimetableEvent {
    const { periodIds, link, durationTotal, lessonCardProps } = gridEntry;
    const { startDateTime, endDateTime, colorBar, calendarName, rows } = lessonCardProps;
    const id = periodIds[0];

    return {
      id: id,
      title: this.mapText(rows[0]),
      location: this.mapText(rows[1]),
      groupName: this.mapText(rows[2]),
      description: this.mapText(rows[3]),
      color: colorBar.color,
      externalLink: link,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      durationTotal: durationTotal,
      calendarName: calendarName ?? '',
    };
  }

  private mapText(row: LessonCardRowContent | undefined): string {
    let result = '';
    if (row && row.type === 'text') {
      result = row.content.texts.join(', ');
    }
    return result;
  }

  private mapFullDayEventToExternalTimetableEvent(fullDayEvent: IFullDayEvent): IExternalTimetableEvent {
    const {
      id,
      title,
      location,
      groupName,
      description,
      externalLink,
      color,
      isTentative,
      startDateTime,
      endDateTime,
      durationTotal,
      calendarName,
    } = fullDayEvent;

    return {
      id: id,
      title: title,
      location: location ?? '',
      groupName: groupName ?? '',
      color: color,
      isTentative: isTentative,
      description: description ?? '',
      calendarName: calendarName ?? '',
      externalLink: externalLink,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      durationTotal: durationTotal,
    };
  }
}
