import React, { useState, useCallback } from 'react';
import { Form } from 'antd';
import { Dayjs } from 'dayjs';

import { ITimeRangePickerTimeSlot } from '@/ui-components/time-range-picker/time-range-picker-store';
import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import AssignedTeachers, {
  AssignedTeachersRow,
  IAssignedTeachersProps,
} from '@/components/assigned-teachers/assigned-teachers';
import { MasterDataRefDto } from '@untis/wu-rest-view-api';

export type DateTimeRange = {
  startDateTime: Dayjs;
  endDateTime: Dayjs;
};

interface IProps extends IFormItemProps<AssignedTeachersRow[]> {
  startTime: Dayjs;
  endTime: Dayjs;
  timeSlots?: ITimeRangePickerTimeSlot[];
  teacherOptions: MasterDataRefDto[];
  value?: AssignedTeachersRow[];
  onChange?: (rows: AssignedTeachersRow[]) => void;
  disabled?: boolean;
}

interface IAssignedTeachersWrapper extends IAssignedTeachersProps {
  initialValue?: AssignedTeachersRow[];
  onChange?: (assignedTeachersRow: AssignedTeachersRow[]) => void;
}

const AssignedTeachersWrapper = (props: IAssignedTeachersWrapper) => {
  const [assignedTeachers, setAssignedTeachers] = useState<AssignedTeachersRow[]>(() => props.initialValue ?? []);
  const handleChange = useCallback((assignedTeachersRow: AssignedTeachersRow[]) => {
    setAssignedTeachers(assignedTeachersRow);
    props.onChange && props.onChange(assignedTeachersRow);
  }, []);

  return (
    <AssignedTeachers
      value={assignedTeachers}
      onAssignedTeachersChange={(rows) => handleChange(rows)}
      startTime={props.startTime}
      endTime={props.endTime}
      timeSlots={props.timeSlots}
      teacherOptions={props.teacherOptions}
      disabled={props.disabled}
    />
  );
};

export const FormAssignedTeachers = (props: IProps) => {
  return (
    <Form.Item
      name={props.name}
      label={props.label}
      rules={props.rules}
      dependencies={props.dependencies}
      initialValue={props.initialValue}
    >
      <AssignedTeachersWrapper
        initialValue={props.initialValue}
        onChange={props.onChange}
        startTime={props.startTime}
        endTime={props.endTime}
        timeSlots={props.timeSlots}
        teacherOptions={props.teacherOptions}
        disabled={props.disabled}
      />
    </Form.Item>
  );
};
