import React, { ReactElement, RefObject, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import ResizeObserver from 'resize-observer-polyfill';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Icon, OptionPopup } from '@/ui-components';
import { useOutsideClick } from '@/hooks/useClickOutside';
import { Option } from '@/ui-components/option-popup/option-popup';
import { useHorizontalScroll } from '@/hooks/useHorizontalMouseWheelScroll';
import { FilterChip } from '@/ui-components/filter-chip/filter-chip';
import { SearchBarStore } from '@/ui-components/search-bar/search-bar-store';
import './search-bar.less';
import { IToggleFilterProps, ToggleFilter } from '@/ui-components/filter-bar/filter/toggle-filter';
import { FilterBar } from '@/ui-components/filter-bar/filter-bar';

export interface ISearchBarOption {
  id: string;
  label: string;
  /* if set, the category string will be displayed in dropdown as separator and as category
    filter (use localised string)
   */
  category?: string;
}

export interface ISearchBarProps {
  // if no value is selected or input is entered, placeholder will be displayed in input
  placeholder?: string;
  disabled?: boolean;
  // displays a string at the same position as the categoryFilter would be rendered. replaces categoryFilter if set.
  searchDescription?: string;
  // the options that should appear in the dropdown
  options?: ISearchBarOption[];
  // the dropdown options, that are selected
  selectedOptions?: ISearchBarOption[];
  onChangeSelectOptions?: (options: ISearchBarOption[]) => void;
  // options that result of users free text inputs
  selectedFreeTextOptions?: string[];
  onChangeFreeTextOptions?: (options: string[]) => void;
  // if set, selecting a second option of the same category will replace the first option
  onlyOneOptionPerCategory?: boolean;
  // should be used for boolean toggle filters
  toggleFilters?: IToggleFilterProps[];
  dataTestId?: string;
}

/**
 * Component that lets the user enter an arbitrary amount of free text values that result as FilterChips, as well
 * as optional drop down options and boolean toggle filters.
 */
export const SearchBar = observer((props: ISearchBarProps) => {
  const { t } = useTranslation();
  const inputRef: RefObject<HTMLInputElement> = useRef(null);
  const separateInputRef: RefObject<HTMLInputElement> = useRef(null);
  const [store] = useState(() => new SearchBarStore(props));
  const ref = useOutsideClick(store.handleOutsideClick);
  const selectedOptionsRef: RefObject<HTMLDivElement> = useHorizontalScroll();
  // we need to keep track of the selected options height, so that we can set the
  // correct "top" attribute to the (absolute positioned) dropdown.
  const [selectedOptionsHeight, setSelectedOptionsHeight] = useState(0);
  // ref so that we can scroll to the latest added option, when added
  const optionsEndRef: RefObject<HTMLInputElement> = useRef(null);
  // depending on the scrollbar, the gradients should be displayed or hidden
  const [isScrollAtStart, setIsScrollAtStart] = useState(false);
  const [isScrollAtEnd, setIsScrollAtEnd] = useState(false);
  const [scrollbarVisible, setScrollbarVisible] = useState(
    selectedOptionsRef.current && selectedOptionsRef.current.scrollWidth > selectedOptionsRef.current.clientWidth,
  );

  // keep props in store up to date
  useEffect(() => {
    store.props = props;
  }, [props]);

  useEffect(() => {
    if (!store.active) {
      inputRef.current?.blur();
      separateInputRef.current?.blur();
      return;
    }
    if (store.showSeparateInputRow) {
      separateInputRef.current?.focus();
    } else {
      inputRef.current?.focus();
    }
  }, [store.active]);

  // initialise internalOptions according to given props
  useEffect(() => {
    let initialInternalOptions: (ISearchBarOption | string)[] = [];
    if (props.selectedOptions) {
      initialInternalOptions = [...initialInternalOptions, ...props.selectedOptions];
    }
    if (props.selectedFreeTextOptions) {
      initialInternalOptions = [...initialInternalOptions, ...props.selectedFreeTextOptions];
    }
    store.internalSelectedOptions = initialInternalOptions;
  }, []);

  // Keeping the internalSelectedOptions in synch with the props works fine, if the user manipulates the values using
  // the searchbar. However, developers can also set new selected options by props, that were not triggered by the
  // searchbar (e.g.: setting some values after an API call)
  // For this cases, we need a sanity check, that makes sure, that the internal values are correct
  useEffect(() => {
    const optionsDifference = [
      ...(props.selectedOptions || []).filter(
        (so) => !store.internalSelectedOptions.find((iso) => typeof iso === 'object' && iso.id === so.id),
      ),
      ...store.internalSelectedOptions.filter(
        (iso) => typeof iso === 'object' && !props.selectedOptions?.find((so) => so.id === iso.id),
      ),
      ...(props.selectedFreeTextOptions || []).filter(
        (sfto) => !store.internalSelectedOptions.find((iso) => iso === sfto),
      ),
      ...store.internalSelectedOptions.filter(
        (iso) => typeof iso === 'string' && !props.selectedFreeTextOptions?.find((sfto) => sfto === iso),
      ),
    ];
    if (optionsDifference.length) {
      store.internalSelectedOptions = [...(props.selectedOptions || []), ...(props.selectedFreeTextOptions || [])];
    }
  }, [props.selectedOptions, props.selectedFreeTextOptions]);

  // keep track of actual height of optionsRef (to calculate the correct top offset for absolute positioned area)
  useEffect(() => {
    if (!selectedOptionsRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setSelectedOptionsHeight(selectedOptionsRef.current ? selectedOptionsRef.current.clientHeight : 0);
    });
    resizeObserver.observe(selectedOptionsRef.current);
    return () => resizeObserver.disconnect();
  }, [selectedOptionsRef]);

  // keep track of the information if the scrollbar is at the start or very end
  // to decide if gradients should be rendered or not.
  useEffect(() => {
    selectedOptionsRef.current?.addEventListener('scroll', updateScrollInformation);
    updateScrollInformation();
    return () => {
      selectedOptionsRef.current?.removeEventListener('scroll', updateScrollInformation);
    };
  }, [selectedOptionsRef, scrollbarVisible, store.inputValue]);

  // if width or options change, we need to recalculate if scrollbar is visible or not
  useEffect(() => {
    const value =
      selectedOptionsRef.current && selectedOptionsRef.current.scrollWidth > selectedOptionsRef.current.clientWidth;
    setScrollbarVisible(value);
  }, [
    selectedOptionsRef.current?.scrollWidth,
    selectedOptionsRef.current?.clientWidth,
    props.selectedOptions,
    props.selectedFreeTextOptions,
    store.active,
  ]);

  // when searchbar gets active or input changes, reset pre-selected option
  useEffect(() => {
    store.resetPreSelection();
  }, [store.active, store.inputValue]);

  const scrollOptionsEndIntoView = () => {
    optionsEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  };

  const updateScrollInformation = () => {
    if (selectedOptionsRef.current) {
      const scrollLeft = selectedOptionsRef.current.scrollLeft;
      const scrollRight = Math.floor(
        selectedOptionsRef.current.scrollWidth - selectedOptionsRef.current.clientWidth - scrollLeft,
      );
      setIsScrollAtStart(scrollLeft === 0);
      setIsScrollAtEnd(scrollRight <= 0);
    }
  };

  const renderSearchResult = () => {
    const renderedOptions: ReactElement[] = [];

    store.filteredOptionsWithoutCategory.forEach((option) => {
      const optionClassName = clsx('option-row', {
        active:
          store.preSelectedOption &&
          store.preSelectedOption.id === option.id &&
          store.preSelectedOption.category === option.category,
      });
      renderedOptions.push(
        <span
          onMouseEnter={() => store.handleMouseEnterOptionRow(option)}
          onClick={() => store.handleSelectOption(option, scrollOptionsEndIntoView)}
          key={store.getIdForOption(option)}
          id={store.getIdForOption(option)}
          className={optionClassName}
        >
          {option.label}
        </span>,
      );
    });

    store.categorisedOptions.forEach((options, group) => {
      if (group !== undefined && options.length > 0) {
        renderedOptions.push(
          <span key={group} className="category-row">
            {group}
          </span>,
        );
      }

      options.forEach((option) => {
        const optionClassName = clsx('option-row', {
          active:
            store.preSelectedOption &&
            store.preSelectedOption.id === option.id &&
            store.preSelectedOption.category === option.category,
        });
        renderedOptions.push(
          <span
            onMouseEnter={() => store.handleMouseEnterOptionRow(option)}
            onClick={() => store.handleSelectOption(option, scrollOptionsEndIntoView)}
            key={store.getIdForOption(option)}
            id={store.getIdForOption(option)}
            className={optionClassName}
          >
            {option.label}
          </span>,
        );
      });
    });

    return <div className="search-results">{renderedOptions}</div>;
  };

  const renderInput = (ref: RefObject<HTMLInputElement>, display: boolean, isSeparate: boolean) => {
    const testId = props.dataTestId
      ? isSeparate
        ? `${props.dataTestId}-searchbar-separate-input`
        : `${props.dataTestId}-searchbar-input`
      : undefined;
    return (
      <input
        disabled={props.disabled}
        value={store.inputValue}
        placeholder={store.showPlaceholder ? props.placeholder : undefined}
        onChange={(e) => store.handleInputValueChange(e.target.value)}
        onFocus={() => {
          store.active = true;
        }}
        onKeyDown={(e) => store.handleKeyDown(e, scrollOptionsEndIntoView)}
        onClick={store.handleClick}
        style={{ display: display ? 'inherit' : 'none' }}
        ref={ref}
        data-testid={testId}
      />
    );
  };

  const shadowHelperHeight =
    // initial offset
    40 +
    // if a free text search option is displayed, add 40
    (store.inputValue.trim().length > 0 ? 40 : 0) +
    // for each rows (option/category), add 32
    Math.min(store.filteredOptions.length + store.filteredCategories.length, 10) * 32 +
    // if the selected options height is bigger than the initial 40px, we need to add them
    (selectedOptionsHeight - 40) +
    // separate input row plus border
    (store.showSeparateInputRow ? 41 : 0);

  const className = clsx('search-bar', {
    active: store.active,
    'has-scrollbar': !!scrollbarVisible,
    'has-separate-input-row': store.showSeparateInputRow,
    'has-category-filter': store.allCategories.length > 0 && !props.searchDescription,
  });

  const searchPhraseRowClassName = clsx('search-phrase-row', {
    active: store.preSelectedOption === undefined,
  });

  const shadowHelperClassName = clsx('shadow-helper', {
    active: store.active,
  });

  const testId = props.dataTestId ? `${props.dataTestId}-searchbar` : undefined;
  const optionsTestId = testId ? `${testId}-options` : undefined;
  const optionTestId = testId ? `${testId}-option` : undefined;
  const freeTextTestId = testId ? `${testId}-free-text` : undefined;
  const clearAllButtonTestId = testId ? `${testId}-clear-all-button` : undefined;

  return (
    <div className={className} ref={ref} data-testid={testId}>
      {/* this div exists do correctly display the shadow and round border div over both,
      the relative and absolute part of the searchbar*/}
      <div className={shadowHelperClassName} style={{ height: shadowHelperHeight }} />

      <div className="top-container">
        <div className="icon-options-input-container">
          {(!store.active || !store.showSeparateInputRow) && (
            <Icon type="search" onClick={store.handleClick} className="search-icon" />
          )}
          {scrollbarVisible && !isScrollAtStart && <div className="left-gradient" />}
          <div ref={selectedOptionsRef} className="selected-options" data-testid={optionsTestId}>
            {props.toggleFilters && props.toggleFilters.length > 0 && (
              <div className="boolean-filter">
                <FilterBar wrap={store.active}>
                  {props.toggleFilters.map((t) => (
                    <ToggleFilter
                      key={`${t.label}-filter`}
                      {...t}
                      onChange={(value) => {
                        t.onChange(value);
                        store.closeSearchbar();
                      }}
                      withCancelIcon
                      hideInactiveBooleanFilter={!store.active}
                      testId={`${t.testId}`}
                    />
                  ))}
                </FilterBar>
              </div>
            )}
            {store.internalSelectedOptions.map((option) => {
              if (typeof option === 'string') {
                return (
                  <FilterChip
                    text={option}
                    onCancel={() => store.handleRemoveFreeTextOption(option)}
                    key={option}
                    className="margin-right-4"
                    dataTestId={freeTextTestId ? `${freeTextTestId}-${option}` : undefined}
                  />
                );
              } else {
                return (
                  <FilterChip
                    text={option.label}
                    onCancel={() => store.handleRemoveOption(option)}
                    key={`${option.id}-${option.category ? option.category : 'no-category'}`}
                    className="margin-right-4"
                    dataTestId={optionTestId ? `${optionTestId}-${option.label}` : undefined}
                  />
                );
              }
            })}
            <div ref={optionsEndRef} />
          </div>
          {scrollbarVisible && !isScrollAtEnd && <div className="right-gradient" />}
          {renderInput(inputRef, !store.active || !store.showSeparateInputRow, false)}
          {store.showSeparateInputRow && <div className="flex-helper" />}
          {store.showClearAllButton && (
            <button className="button-cancel" type="button" onClick={store.clearAll} data-testid={clearAllButtonTestId}>
              <Icon type="cancel-circle-filled" />
            </button>
          )}
          {props.searchDescription && (
            <>
              <div className="separator" />
              <div className="search-description">{props.searchDescription}</div>
            </>
          )}
          {store.allCategories.length > 0 && !props.searchDescription && (
            <>
              <div className="separator" />
              <CategoryFilter
                selectedCategory={store.selectedCategory}
                categories={store.allCategories}
                onSelectCategory={(category) => {
                  store.selectedCategory = category;
                }}
              />
            </>
          )}
        </div>
        <div className="separate-input-row" style={{ display: store.showSeparateInputRow ? 'inherit' : 'none' }}>
          <Icon type="search" onClick={store.handleClick} className="search-icon" />
          {renderInput(separateInputRef, true, true)}
        </div>
      </div>
      <div
        className="dropdown-container"
        style={{ top: selectedOptionsHeight + (store.showSeparateInputRow ? 41 : 0) }}
      >
        {store.inputValue.length > 0 && (
          <div
            onMouseEnter={store.handleMouseEnterSearchPhraseRow}
            className={searchPhraseRowClassName}
            onClick={() => store.handleSelectSearchValue(scrollOptionsEndIntoView)}
            id="search-phrase-row"
          >
            <div className="hover-container">
              <Icon type="search" />
              <span className="value">{store.inputValue}</span>
              <span className="hint">
                {store.searchPhraseAlreadySelected ? t(`(${t('general.alreadySelected')})`) : t('general.searchPhrase')}
              </span>
            </div>
          </div>
        )}
        {store.active && store.filteredOptions.length > 0 && renderSearchResult()}
      </div>
    </div>
  );
});

interface ICategoryFilterProps {
  selectedCategory?: string;
  categories: string[];
  onSelectCategory: (category: string | undefined) => void;
}

/**
 * This filter is for internal usage of the search-bar only, therefore it is not exported
 */
const CategoryFilter = (props: ICategoryFilterProps) => {
  const { t } = useTranslation();

  const options: Option[] = [
    {
      label: t('general.all'),
      onClick: () => props.onSelectCategory(undefined),
    },
    ...props.categories.map((category) => {
      const option: Option = {
        label: category,
        onClick: () => {
          props.onSelectCategory(category);
        },
      };
      return option;
    }),
  ];

  return (
    <div className="category-filter">
      <OptionPopup options={options} placement="bottomRight">
        <div className="click-container">
          {props.selectedCategory ? props.selectedCategory : t('general.all')}
          <Icon className="down-arrow" type="dropdown" />
        </div>
      </OptionPopup>
    </div>
  );
};
