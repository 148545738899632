import React from 'react';
import { TimePicker as AntdTimePicker } from 'antd';
import { Dayjs } from 'dayjs';
import classNames from 'clsx';

import { ITestComponentProps } from '@/types/test-component-props';

import './time-picker.less';

export interface ITimePickerProps extends ITestComponentProps {
  value: Dayjs | undefined;
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
}

/**
 * Displays AntdTimePicker with floating label. For untis usecases we have custom on click action
 * to show specific timeslots, for now we don't need to open antd time picker UI.
 */
export const TimePicker = (props: ITimePickerProps) => {
  const time = props.value;
  const containerHelperClass = 'container-helper-closed';

  const timeLabel = classNames('time-label', {
    float: !!time,
    'with-value': !!time,
  });

  const pickerClassName = classNames('untis-time-picker', {
    disabled: props.disabled,
  });

  return (
    <div onClick={props.onClick} className={pickerClassName} data-testid={props.testId}>
      <div className={timeLabel} data-testid="time-picker-label">
        {!!time ? time.format('LT') : props.label}
      </div>
      {props.label && (
        <div className="floating-label">
          <span className="floating-label-content">{props.label}</span>
        </div>
      )}
      <div className={'container-helper ' + containerHelperClass}>
        <AntdTimePicker value={time} />
      </div>
    </div>
  );
};

export default TimePicker;
