import { useRef, useEffect, RefObject } from 'react';

/**
 * Divs with that ref scroll horizontally onMouseWheel when hovered
 */
export function useHorizontalScroll() {
  const elRef: RefObject<HTMLDivElement> = useRef(null);
  useEffect(() => {
    if (elRef.current) {
      const onWheel = (e: WheelEvent) => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        elRef.current?.scrollTo({
          left: elRef.current.scrollLeft + e.deltaY,
          behavior: 'smooth',
        });
      };
      elRef.current.addEventListener('wheel', onWheel);
      return () => elRef.current?.removeEventListener('wheel', onWheel);
    }
  }, []);
  return elRef;
}
