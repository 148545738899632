import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { HistoryMessages } from '@/pages/messages/components/history-messages/history-messages';
import {
  IncomingMessageViewDto,
  RequestConfirmationSummaryDto,
  SentMessageViewDto,
  DraftMessageViewDto,
} from '@untis/wu-rest-view-api';
import useStore from '@/hooks/useStore';
import EntityDisplayName from '@mg/components/entity-display-name/entity-display-name';
import MessageDateTime from '@mg/components/message-date-time/message-date-time';
import ReadConfirmationStateBadge from '@mg/components/read-confirmation-state-badge/read-confirmation-state-badge';
import MessageDetailViewStore from '@mg/stores/message-detail-view-store';
import { DeprecatedAttachments, Button, Skeleton, IconButton, DeprecatedAttachment } from '@/ui-components';
import { TestIds } from '@/testIds';

import './message-detail-view.less';

const SkeletonView = () => {
  return (
    <div className="skeleton detail-container">
      <div className="subject">
        <Skeleton.Input active style={{ width: 300, marginTop: 3 }} />
      </div>
      <div className="sender-container">
        <b className="fromTo">
          <Skeleton.Input size="small" active style={{ width: 20 }} />
        </b>
        <div className="entity-display-name">
          <Skeleton.Input size="small" active style={{ width: 150 }} />
        </div>
        <div className="message-date-time">
          <Skeleton.Input size="small" active style={{ width: 50 }} />
        </div>
      </div>
      <div className="message-body">
        <div className="content">
          <Skeleton.Input size="small" active style={{ width: 300, marginBottom: 15 }} />
          <Skeleton.Input size="small" active style={{ width: 350, marginBottom: 15 }} />
          <Skeleton.Input size="small" active style={{ width: 200 }} />
        </div>
      </div>
      <div className="message-actions">
        <div className="message-actions-icons" style={{ display: 'flex' }}>
          <Skeleton.Input size="small" active style={{ width: 28, marginRight: 8, display: 'inline-block' }} />
          <Skeleton.Input size="small" active style={{ width: 28, marginRight: 8, display: 'inline-block' }} />
          <Skeleton.Input size="small" active style={{ width: 28, display: 'inline-block' }} />
        </div>
        <Skeleton.Button shape="round" size="large" active style={{ width: 100 }} />
      </div>
    </div>
  );
};

const MessageDetailView = observer(() => {
  const messageDetailViewStore = useStore(MessageDetailViewStore);
  const { isLoading, message, isIncomingMessage, isSentMessage } = messageDetailViewStore;
  const { t } = useTranslation();

  const messageRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => messageRef.current,
  });

  if (isLoading) {
    return <SkeletonView />;
  }

  if (message == null) {
    return null;
  }

  let requestConfirmationStatus: RequestConfirmationSummaryDto | undefined;
  if ('requestConfirmationStatus' in message) {
    requestConfirmationStatus = message.requestConfirmationStatus;
  }

  let allowSendRequestConfirmation = false;
  let allowMessageDeletion = message.allowMessageDeletion;
  let isReplyAllowed = 'isReplyAllowed' in message && message.isReplyAllowed;
  const isReply = 'isReply' in message && message.isReply;
  if (
    'requestConfirmation' in message &&
    message.requestConfirmation &&
    typeof message.requestConfirmation !== 'boolean'
  ) {
    allowSendRequestConfirmation = message.requestConfirmation.allowSendRequestConfirmation;
    allowMessageDeletion = allowMessageDeletion || message.requestConfirmation.allowMessageDeletion;
    isReplyAllowed = isReplyAllowed || message.requestConfirmation.isReplyAllowed;
  }

  function handleRevokeMessageClick() {
    messageDetailViewStore.openRevokeMessageConfirmationModal();
  }

  function handleDeleteMessageClick() {
    messageDetailViewStore.openDeleteMessageConfirmationModal();
  }

  return (
    <div className="message-detail-container" ref={messageRef}>
      <div className="message-header">
        <h1 className="subject">
          {isReply ? t('general.messageRe') : ''} {message.subject}
        </h1>
        <div className="header-actions">
          {requestConfirmationStatus && <ReadConfirmationStateBadge message={message as SentMessageViewDto} />}
        </div>
      </div>
      <InfoBanner message={message} />
      <div className="sender-container">
        <b className="fromTo">{t(isIncomingMessage ? 'general.from' : 'general.to')}:</b>
        <EntityDisplayName message={message} />
        <MessageDateTime dateTime={message.sentDateTime} showBoth />
      </div>
      <div className="message-body">
        <div className="message-content">
          {message.content ? (
            <div dangerouslySetInnerHTML={{ __html: message.content }} />
          ) : (
            <div className="no-content">{t('general.messageNoContent')}</div>
          )}
        </div>
        <div className="message-attachments">
          {message.storageAttachments.length > 0 && (
            <DeprecatedAttachments
              readOnly
              attachments={messageDetailViewStore.getStorageAttachments(message.storageAttachments)}
            />
          )}
          {message.blobAttachment && (
            <DeprecatedAttachment
              name={message.blobAttachment.name}
              hoverIcon={{ type: 'download' }}
              onClick={() => messageDetailViewStore.downloadBlobAttachment(message.id, message.blobAttachment!.name)}
            />
          )}
          {message.attachments.length > 0 && (
            <DeprecatedAttachments
              readOnly
              attachments={messageDetailViewStore.getOneDriveAttachments(message.attachments)}
            />
          )}
        </div>

        {'replyHistory' in message && (
          <HistoryMessages
            variant={isSentMessage ? 'sent' : 'incoming'}
            className="history-messages"
            messages={message.replyHistory}
          />
        )}
      </div>
      <div className="message-actions">
        <div className="message-actions-icons">
          <IconButton
            onClick={handlePrint}
            type="print_24"
            tooltip={{
              title: t('general.print'),
              placement: 'top',
            }}
            ariaLabel={t('general.print')}
            testId={TestIds.MESSAGES_PRINT_BUTTON}
          />
          {isReplyAllowed && (
            <IconButton
              onClick={() => messageDetailViewStore.openReplyMessageDetailView(message.id)}
              type="reply"
              tooltip={{
                title: t('general.reply'),
                placement: 'top',
              }}
              ariaLabel={t('general.reply')}
              testId={TestIds.MESSAGES_REPLY_BUTTON}
            />
          )}
          {allowMessageDeletion && isSentMessage && (
            <IconButton
              onClick={handleRevokeMessageClick}
              type="revoke_24"
              tooltip={{
                title: t('general.revoke'),
                placement: 'top',
              }}
              ariaLabel={t('general.revoke')}
              testId={TestIds.MESSAGES_REVOKE_BUTTON}
            />
          )}
          {allowMessageDeletion && (
            <IconButton
              onClick={handleDeleteMessageClick}
              type="shared_trash"
              tooltip={{
                title: t('general.delete'),
                placement: 'top',
              }}
              ariaLabel={t('general.delete')}
              testId={TestIds.MESSAGES_DELETE_BUTTON}
            />
          )}
        </div>
        {allowSendRequestConfirmation && (
          <Button
            size="large"
            type="primary"
            onClick={() => messageDetailViewStore.confirmReadRequest(message as IncomingMessageViewDto)}
          >{t`general.sendReadConfirmation`}</Button>
        )}
      </div>
    </div>
  );
});

const InfoBanner = observer(
  ({ message }: { message: IncomingMessageViewDto | SentMessageViewDto | DraftMessageViewDto }) => {
    const messageDetailViewStore = useStore(MessageDetailViewStore);
    const requestConfirmationText = messageDetailViewStore.getRequestConfirmationText();
    const isReplyForbiddenText = messageDetailViewStore.getIsReplyForbiddenText();

    const isReplyForbidden = 'isReplyForbidden' in message ? message.isReplyForbidden ?? false : false;

    if (requestConfirmationText && isReplyForbidden) {
      return (
        <div className="info-banner">
          {requestConfirmationText} - {isReplyForbiddenText}
        </div>
      );
    }

    if (isReplyForbidden) {
      return <div className="info-banner">{isReplyForbiddenText}</div>;
    }

    if (requestConfirmationText) {
      return <div className="info-banner">{requestConfirmationText}</div>;
    }

    return null;
  },
);

export default MessageDetailView;
