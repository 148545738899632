import React from 'react';
import { Dayjs } from 'dayjs';

import { Button } from '../../button/button';
import TimeRangePickerStore, { TimeRangePickerMode } from '../time-range-picker-store';

import '../time-range-picker.less';

interface IProps {
  store: TimeRangePickerStore;
  label: string;
  startTime: Dayjs;
  endTime: Dayjs;
}

/**
 * This is the component that renders the button in the TimeRangePickerForm to quick select a new start time and end
 * time based on the stores TimeRangePickerTimeSlots.
 */
const TimeRangePickerTimeSlot = (props: IProps) => {
  const getRangeString = (): string => {
    const startTime = props.startTime.format('LT');
    const endTime = props.endTime.format('LT');
    if (props.store.onlyEditOwnTimeSlot) {
      return props.store.mode === TimeRangePickerMode.Start ? startTime : endTime;
    } else {
      return startTime + ' - ' + endTime;
    }
  };

  const handleClick = () => {
    props.store.onTimeRangeButtonClick(props.startTime, props.endTime);
    /*
      Normally, when the form closes, the time-range-picker-input values are evaluated to create the start/end date
      that are passed to the onTimeRangeChange handler.
      To avoid this we set a flag in the store and check it in the time-range-picker-input.
     */
    props.store.closedByTimeSlotClick = true;
    props.store.toggleEditMode();
  };

  return (
    <div className="time-slot">
      <p>{props.label}</p>
      <Button onClick={handleClick} outline>
        {getRangeString()}
      </Button>
    </div>
  );
};

export default TimeRangePickerTimeSlot;
