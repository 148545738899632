import { ModalProps as ADModalProps } from 'antd/lib/modal';
import { t } from 'i18next';
import { action, computed, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import * as React from 'react';
import { Dayjs } from 'dayjs';

import { Button } from '../ui-components';

import RouterStore from '@/stores/router-store';
import { PartialRequired } from '@/types/partial-required';
import { inject, Store } from '@/types/store';
import { DatePickerPrompt } from '@/components/prompts/date-picker-prompt/date-picker-prompt';
import { IInternalModalProps, IModalProps, IPromptProps } from '@/ui-components/modal/modal';
import { DateRangePickerPrompt } from '@/components/prompts/date-range-picker-prompt/date-range-picker-prompt';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { ItemPickerPrompt } from '@/components/prompts/item-picker-prompt/item-picker-prompt';
import { HistoryElementDto } from '@untis/wu-rest-view-api/api';
import { HistoryView } from '@/components/history-view/history-view';
import { ISingleTagSelectItem } from '@/ui-components/tag-select/single-tag-select/single-tag-select';
import { SingleItemPickerPrompt } from '@/components/prompts/item-picker-prompt/single-item-picker-prompt';
import { MultiSelectFilter } from '@/utils/form/form-util';
import { IDateCalendarHolidayProps } from '@/ui-components/date/calendar/date-calendar/date-calendar';
// size 'calendar' is a temporary solution until the modal refactoring was done (WU-10158)
export type ModalSize =
  | 'calendar'
  | 'sm'
  | 'md'
  | 'lg'
  | 'full-size'
  | 'date-calendar'
  | 'date-calendar-with-header-or-footer'
  | 'date-calendar-with-header-and-footer'
  | 'date-range-calendar'
  | 'date-range-calendar-with-header-or-footer'
  | 'date-range-calendar-with-header-and-footer';

export function isDeprecatedModalDefinition(
  modal: DeprecatedModalDefinition | (IModalProps & IInternalModalProps<any>),
): modal is DeprecatedModalDefinition {
  return (modal as DeprecatedModalDefinition).content !== undefined;
}

type ItemPickerOptions = {
  title: string | string[];
  items: IMultiTagSelectItem[];
  selectedItems?: IMultiTagSelectItem[];
  filters?: MultiSelectFilter[];
  isSearchable?: boolean;
  isSingleSelect?: boolean;
};

/**
 * @deprecated
 * Contains all relevant information to determine, how a modal dialog should look and behave.
 * Serves a Method that creates Props for the AntDesign Modal Component out of this information.
 */
export class DeprecatedModalDefinition {
  isUserPrompt: boolean;
  content: any; // the component that should be displayed in the dialog
  footer: any[] | null; // array of (button) components that should be rendered below user prompts.
  className?: string; // custom css-classname for the modal dialog
  size: ModalSize;
  onCancel: () => void;
  onOk: () => void;
  closeOnEscape: boolean;
  // the element which was focused before the modal was opened, will be focused again when the modal is being closed
  lastFocusedElement?: HTMLElement | null;
  onClose?: () => void;
  maskClosable?: boolean;
  onCancelCloseAll?: boolean;
  // this closeCondition function can be used to do only close the modal if this condition is met
  // can be useful if you want to show a userPrompt and depending on the action of the user to close or not to close
  closeCondition?: () => Promise<boolean>;

  constructor(onCancel: () => void, onOk: () => void) {
    this.size = 'sm';
    this.onCancel = onCancel;
    this.onOk = onOk;
    this.closeOnEscape = true;
    this.footer = null;
    this.isUserPrompt = false;
  }

  private getClassName(expanded: boolean, showFocusedElements: boolean) {
    let name = 'ant-modal deprecated';
    if (this.className) {
      name += ' ' + this.className;
    }
    name += ' ant-modal--' + this.size;
    if (expanded) {
      name += ' ant-modal--expanded';
    } else {
      name += ' ant-modal--collapsed';
    }
    if (showFocusedElements) {
      name += ' show-focused-elements';
    }
    return name;
  }

  private getWrapClassName(expanded: boolean) {
    let name = 'ant-modal-wrap';
    name += ' ant-modal-wrap--' + this.size;
    if (expanded) {
      name += ' ant-modal-wrap--expanded';
    } else {
      name += ' ant-modal-wrap--collapsed';
    }
    return name;
  }

  getADModalProps = (expanded: boolean, showFocusedElements: boolean): ADModalProps => {
    return {
      visible: true,
      footer: this.footer,
      mask: this.size !== 'full-size',
      destroyOnClose: true,
      onCancel: this.onCancel,
      onOk: this.onOk,
      keyboard: this.closeOnEscape,
      maskClosable: this.maskClosable,
      className: this.getClassName(expanded, showFocusedElements),
      wrapClassName: this.getWrapClassName(expanded),
    };
  };
}

export interface IDeprecatedOpenModalProps {
  content: any;
  size?: ModalSize;
  closable: boolean;
  className?: string;
  closeOnEscape?: boolean; // Default is true
  onClose?: () => void;
  maskClosable?: boolean;
  onCancelCloseAll?: boolean;
  disableNavigation?: boolean;
  closeCondition?: () => Promise<boolean>;
  lastFocusedElement?: HTMLElement;
}

export interface IDeprecatedUserPromptComponentProps<T> {
  onPromptValueChange: (result: T) => void;
  // Don't set this, this is set by the modal so that prompts without OK buttons can work. Might change with WU-10158
  injectedUserPromptOnOkHandler: () => void;
}

// these are injected by the modal-store -> You can just use those if your components props you use in the prompt
// extend this interface
export interface IUserPromptComponentProps<T> {
  promptProps?: IPromptProps<T>;
}

type IBasicUserPromptProps = Exclude<
  PartialRequired<IBaseUserPromptProps<never, never>, 'content'>,
  'component' | 'componentProps'
>;

type IUserPromptProps<T, P extends IDeprecatedUserPromptComponentProps<T>> = Exclude<
  PartialRequired<IBaseUserPromptProps<T, P>, 'component' | 'componentProps'>,
  'content'
>;

type UserPromptComponent<T, P> = React.ComponentType<IDeprecatedUserPromptComponentProps<T> & P>;

interface IBaseUserPromptProps<T, P extends IDeprecatedUserPromptComponentProps<T>> {
  content?: string | React.ReactElement;
  component?: UserPromptComponent<T, P>;
  componentProps?: Omit<P, keyof IDeprecatedUserPromptComponentProps<T>>;
  okText?: string;
  size?: ModalSize;
  cancelText?: string;
  closable?: boolean;
  closeOnEscape?: boolean;
  className?: string;
  buttonSize?: 'small' | 'large'; // the size of the user prompt buttons,
  hideCancelButton?: boolean;
  hideOKButton?: boolean;
  hideFooter?: boolean;
}

interface IUserPromptResult<T> {
  value?: T;
  cancelled: boolean;
}

@Store()
export default class ModalStore {
  private routerStore = inject(RouterStore);
  @observable private _stack: (DeprecatedModalDefinition | (IModalProps & IInternalModalProps<any>))[] = new Array<
    DeprecatedModalDefinition | (IModalProps & IInternalModalProps<any>)
  >();
  /**
   * With the basic user prompt, you can build modals where the user simply accepts or cancels.
   * It will return true or false based on the users decision.
   */
  @action.bound
  public async deprecatedBooleanUserPrompt(props: IBasicUserPromptProps): Promise<boolean> {
    const result = await this.deprecatedOpenUserPrompt(props);
    return !result.cancelled;
  }

  /**
   * Example usage:
   *
   * modalStore.booleanUserPrompt({
   *    children: "Are you sure?",
   *    okButton: label: "Yes" },
   *    cancelButton: { label: "No" }
   * });
   * @param props
   */
  @action.bound
  public async booleanUserPrompt(props: IModalProps): Promise<boolean> {
    const result = await this.openUserPrompt(props);
    return !result.cancelled;
  }

  /**
   * "Helper" function to create a boolean prompt that is rendered like it is supposed to be
   * rendered according to designers.
   */
  @action.bound
  public async openDeletePrompt<T>(
    title: string,
    text: string,
    buttonLabels?: Partial<{
      okButton: string;
      cancelButton: string;
    }>,
  ): Promise<boolean> {
    return this.booleanUserPrompt({
      title: title,
      children: text,
      okButton: {
        label: buttonLabels?.okButton ?? t('general.delete'),
      },
      cancelButton: {
        label: buttonLabels?.cancelButton ?? t('general.cancel'),
      },
      hasDestructiveAction: true,
      showFooterSeparator: true,
    });
  }

  /**
   * With this user prompt, you can build modals where the user is able to decide between more extended choices.
   * The choice of the user will be returned as the "result" prop of the return value.
   * You can check if the user cancelled the prompt with the "cancelled" prop of the return value.
   */
  @action.bound
  public async deprecatedUserPrompt<T, P extends IDeprecatedUserPromptComponentProps<T>>(
    props: IUserPromptProps<T, P>,
  ): Promise<IUserPromptResult<T>> {
    return await this.deprecatedOpenUserPrompt(props);
  }

  @action.bound
  private async openUserPrompt<T>(modal: IModalProps): Promise<IUserPromptResult<T>> {
    if (this.isUserPromptOpen) {
      console.warn(
        'Tried to open two userprompts at the same time. Preventing the userprompt with following props:',
        modal,
      );
      return {
        cancelled: true,
      };
    }

    return new Promise((resolve) => {
      const promptValue = observable.box<T>(undefined);

      const promptProps: IPromptProps<T> = {
        setPromptValue: (value: T) => promptValue.set(value),
        getPromptValue: () => promptValue.get(),
        onSubmit: () => {
          this.closeModal();
          resolve({ value: promptValue.get(), cancelled: false });
        },
        onCancel: () => {
          this.closeModal();
          resolve({ cancelled: true });
        },
      };

      const injectedPromptProps: IUserPromptComponentProps<T> = {
        promptProps: promptProps,
      };

      if (React.isValidElement(modal.children)) {
        modal.children = React.cloneElement(modal.children, { ...injectedPromptProps });
      }

      this.pushModal({
        ...modal,
        isUserPrompt: true,
        isOpen: true,
        onClose: this.closeModal,
        promptProps: promptProps,
        lastFocusedElement: document.activeElement as HTMLElement,
      });
    });
  }

  @action.bound
  private async deprecatedOpenUserPrompt<T, P extends IDeprecatedUserPromptComponentProps<T>>(
    props: IBaseUserPromptProps<T, P>,
  ): Promise<IUserPromptResult<T>> {
    if (this.isUserPromptOpen) {
      console.warn(
        'Tried to open two userprompts at the same time. Preventing the userprompt with following props:',
        props,
      );
      return {
        cancelled: true,
      };
    }

    return new Promise((resolve) => {
      const promptValue = observable.box<T>(undefined);

      const onOk = () => {
        this.closeModal();
        resolve({ value: promptValue.get(), cancelled: false });
      };
      const onCancel = () => {
        this.closeModal();
        resolve({ cancelled: true });
      };

      let isBasicUserPrompt = true;
      let content: React.ReactElement | string;
      if (props.component) {
        const ChildComponent = props.component;
        const childProps = {
          ...(props.componentProps as P),
          onPromptValueChange: (result: T) => promptValue.set(result),
          injectedUserPromptOnOkHandler: onOk,
        };
        content = <ChildComponent {...childProps} />;
        isBasicUserPrompt = false;
      } else {
        content = props.content!;
      }

      const modal: DeprecatedModalDefinition = new DeprecatedModalDefinition(onCancel, onOk);
      const buttonSize = props.buttonSize || 'large';

      modal.isUserPrompt = true;
      modal.size = props.size || 'sm';
      modal.content = <div className="user-prompt">{content}</div>;
      modal.className = props.className;
      const modalFooter = [];
      !props.hideCancelButton &&
        modalFooter.push(
          <Button key="cancel" onClick={onCancel} outline autoFocus testId="userprompt-cancel" size={buttonSize}>
            {props.cancelText || t('general.cancel')}
          </Button>,
        );

      !props.hideOKButton &&
        modalFooter.push(
          <Observer key="ok">
            {() => (
              <Button
                onClick={onOk}
                type="primary"
                testId="userprompt-confirm"
                size={buttonSize}
                disabled={!isBasicUserPrompt && typeof promptValue.get() === 'undefined'}
              >
                {props.okText || t('general.ok')}
              </Button>
            )}
          </Observer>,
        );

      if (!props.hideFooter) {
        modal.footer = modalFooter;
      }

      modal.closeOnEscape = typeof props.closeOnEscape === 'undefined' ? true : props.closeOnEscape;
      modal.lastFocusedElement = document.activeElement as HTMLElement;

      this.pushModal(modal);
    });
  }

  @action
  public async openModalDatePicker(
    date?: Dayjs,
    title?: string,
    disabledDate?: (date: Dayjs) => boolean,
    withSubmitButton?: boolean,
    holidays?: IDateCalendarHolidayProps[],
  ): Promise<IUserPromptResult<Dayjs>> {
    const hasHeader = !!title;
    const hasFooter = !!withSubmitButton;

    let size: ModalSize = 'date-calendar';
    if (hasFooter && hasHeader) {
      size = 'date-calendar-with-header-and-footer';
    } else if ((hasFooter && !hasHeader) || (!hasFooter && hasHeader)) {
      size = 'date-calendar-with-header-or-footer';
    }

    return await this.openUserPrompt({
      children: (
        <DatePickerPrompt
          date={date}
          disabledDate={disabledDate}
          withSubmitButton={withSubmitButton}
          title={title}
          holidays={holidays}
        />
      ),
      size: size,
      hideXIcon: true,
      maskClosable: true,
    });
  }

  @action
  public async openModalDateRangePicker(
    startDate: Dayjs,
    endDate: Dayjs,
    title?: string,
    disabledDate?: (date: Dayjs) => boolean,
    holidays?: IDateCalendarHolidayProps[],
  ): Promise<
    IUserPromptResult<{
      startDate: Dayjs;
      endDate: Dayjs;
    }>
  > {
    const hasHeader = !!title;

    let size: ModalSize = 'date-range-calendar-with-header-or-footer';
    if (hasHeader) {
      size = 'date-range-calendar-with-header-and-footer';
    }

    return await this.openUserPrompt({
      children: (
        <DateRangePickerPrompt
          startDate={startDate}
          endDate={endDate}
          disabledDate={disabledDate}
          title={title}
          holidays={holidays}
        />
      ),
      size: size,
      hideXIcon: true,
    });
  }

  @action
  public async openModalItemPicker(
    title: string,
    items: IMultiTagSelectItem[],
    selectedItems?: IMultiTagSelectItem[],
    filters?: MultiSelectFilter[],
    isSearchable?: boolean,
    isSingleSelect?: boolean,
  ): Promise<IUserPromptResult<IMultiTagSelectItem[]>> {
    return await this.openUserPrompt({
      title: title,
      children: (
        <ItemPickerPrompt
          items={items}
          filters={filters}
          isSearchable={isSearchable}
          isSingleSelect={isSingleSelect}
          selectedItems={selectedItems}
        />
      ),
      size: 'sm',
      okButton: {
        label: t('general.add'),
      },
      cancelButton: {
        label: t('general.cancel'),
      },
      showHeaderSeparator: true,
      showFooterSeparator: items.length > 0,
    });
  }
  @action
  public async openModalItemPickerWithOptions(
    options: ItemPickerOptions,
  ): Promise<IUserPromptResult<IMultiTagSelectItem[]>> {
    const { title, items, selectedItems, filters, isSearchable, isSingleSelect } = options;
    const hasItem = items.length > 0;
    return await this.openUserPrompt({
      title,
      children: hasItem ? (
        <ItemPickerPrompt
          items={items}
          filters={filters}
          isSearchable={isSearchable}
          isSingleSelect={isSingleSelect}
          selectedItems={selectedItems}
        />
      ) : (
        t(`general.addFirstElementEmptyState`)
      ),

      size: 'sm',
      okButton: {
        label: hasItem ? t('general.add') : t('general.create'),
      },
      cancelButton: {
        label: t('general.cancel'),
      },
      showFooterSeparator: true,
    });
  }

  @action
  public async openModalSingleItemPicker(
    title: string,
    items: ISingleTagSelectItem[],
    selectedItem?: ISingleTagSelectItem,
  ): Promise<IUserPromptResult<ISingleTagSelectItem[]>> {
    return await this.openUserPrompt({
      title: title,
      children: <SingleItemPickerPrompt items={items} selectedItem={selectedItem} />,
      size: 'sm',
      hideXIcon: true,
      okButton: {
        label: t('general.ok'),
      },
      showFooterSeparator: items.length > 0,
      showHeaderSeparator: items.length > 0,
    });
  }

  @action
  public openHistoryDialog(history: HistoryElementDto[]) {
    this.openModalDialog({
      title: t('general.historyChanges'),
      children: <HistoryView history={history} />,
      size: 'md',
    });
  }

  @action
  public openModalDialog(props: IModalProps): Promise<void> {
    return new Promise((resolve) => {
      this.pushModal({
        ...props,
        isUserPrompt: false,
        isOpen: true,
        onClose: async () => {
          if (props.onCancelCloseAll) {
            this.closeAll();
          } else {
            await this.closeModal();
          }
          resolve();
        },
        className: props.className,
        lastFocusedElement: props.lastFocusedElement ?? (document.activeElement as HTMLElement),
      });
    });
  }

  /**
   * @deprecated in favor of openModalDialog(props: IModalProps)
   */
  @action
  public deprecatedOpenModalDialog(props: IDeprecatedOpenModalProps) {
    const modal: DeprecatedModalDefinition = new DeprecatedModalDefinition(
      () => (props.onCancelCloseAll ? this.closeAll() : this.closeModal()),
      () => this.closeModal(),
    );

    modal.size = props.size || 'sm';
    modal.content = props.content;
    modal.className = props.className;
    modal.closeOnEscape = typeof props.closeOnEscape === 'undefined' ? true : props.closeOnEscape;
    modal.lastFocusedElement = props.lastFocusedElement ?? (document.activeElement as HTMLElement);
    modal.onClose = props.onClose;
    modal.closeCondition = props.closeCondition;
    modal.maskClosable = props.maskClosable;
    modal.onCancelCloseAll = typeof props.onCancelCloseAll === 'undefined' ? false : props.onCancelCloseAll;

    if (props.disableNavigation) {
      this.disableNavigation();
    }

    this.pushModal(modal);
  }

  @action.bound
  async closeModal() {
    if (this._stack.length > 0) {
      const closedModal: DeprecatedModalDefinition | (IModalProps & IInternalModalProps<any>) =
        this._stack[this._stack.length - 1];
      if (!closedModal) {
        return;
      }

      if (closedModal.closeCondition) {
        const shouldClose = await closedModal.closeCondition();
        if (shouldClose) {
          const popped = this._stack.pop();
          this.onAfterClose(popped);
        }
      } else {
        const popped = this._stack.pop(); // must be before the onClose call, otherwise a recursive loop happens
        this.onAfterClose(popped);
      }

      if (this.isLastModalOpen) {
        this.enableNavigation();
      }

      setTimeout(() => {
        closedModal.lastFocusedElement && closedModal.lastFocusedElement.focus();
      }, 150); // we wait for 150ms so we don't accidently fire events on the newly focused element
    }
  }

  private onAfterClose(modal: DeprecatedModalDefinition | undefined | (IModalProps & IInternalModalProps<any>)) {
    if (!modal || isDeprecatedModalDefinition(modal)) {
      return;
    }

    if (modal.onAfterClose) {
      modal.onAfterClose();
    }
  }

  @action
  closeAll() {
    this._stack.length = 0;
    this.enableNavigation();
  }

  @action
  closeAllAndRemoveModalRoutes() {
    this.closeAll();
    this.routerStore.removeAllModalRoutes();
  }

  enableNavigation() {
    this.setNavigationEnabled(true);
  }

  disableNavigation() {
    this.setNavigationEnabled(false);
  }

  /**
   * sets the title of the last modal in the stack
   *
   * @param title the new title you want to set
   */
  setTitle(title: string) {
    this.setTitleAtIndex(this._stack.length - 1, title);
  }

  /**
   * sets the title of an existing modal in the stack
   *
   * @param index the index of the modal in the _stack which you want to update
   * @param title the new title you want to set
   */
  @action
  setTitleAtIndex(index: number, title: string) {
    const modal = this._stack[index];
    if (!modal) {
      return;
    }

    if ('title' in modal) {
      modal.title = title;
      this._stack[index] = modal;
    }
  }

  private setNavigationEnabled(shouldEnable: boolean) {
    const $navigationBar = document.getElementsByClassName('untis-navigation-bar')[0];

    if (!$navigationBar) {
      return;
    }

    if (shouldEnable) {
      $navigationBar.classList.remove('untis-navigation-bar--disabled');
    } else {
      $navigationBar.classList.add('untis-navigation-bar--disabled');
    }
  }

  @computed
  private get notDeprecatedModals(): IModalProps[] {
    const modals: IModalProps[] = [];
    this._stack.forEach((modal) => {
      if (!isDeprecatedModalDefinition(modal)) {
        modals.push(modal);
      }
    });
    return modals;
  }

  @computed
  get isNavigationDisabled() {
    return this.notDeprecatedModals.filter((modal) => modal.disableNavigation).length > 0;
  }

  @computed
  get isAnyModalOpen() {
    return this._stack.length > 0;
  }

  @computed
  get isLastModalOpen() {
    return this._stack.length === 0;
  }

  @computed
  get modalStack(): (DeprecatedModalDefinition | (IModalProps & IInternalModalProps<any>))[] {
    return this._stack;
  }

  @computed
  get isUserPromptOpen() {
    return this._stack.some((modal) => modal.isUserPrompt);
  }

  /**
   * Pushes a new modal object onto the stack
   */
  @action
  private pushModal(modal: DeprecatedModalDefinition | (IModalProps & IInternalModalProps<any>)) {
    this._stack.push(modal);
  }
}
