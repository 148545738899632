import React, { Key } from 'react';
import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

import { ITableRowKey } from '@/ui-components/wu-table/wu-table';
import { ButtonType, Columns, ColumnType } from '@/ui-components/wu-table/wu-table-column-mapper';
import {
  DateRange,
  DateRangeOptionEnum,
  getDateRangeForDateRangeOptionEnum,
  ICustomDateRange,
  IDateRange,
  parseDateRangeEnum,
} from '@/ui-components/page/page-header/page-header-date-picker/page-header-date-picker';
import { inject } from '@/types/store';
import { SidebarStore } from '@/stores/sidebar-store';
import {
  ITeachingContentPreview,
  TeachingContentSidebar,
} from '@/pages/class-register/teaching-content-sidebar/teaching-content-sidebar';
import {
  ClassRegKlasseDto,
  ClassRegOpenPeriodDto,
  ClassRegOpenPeriodsFilterEnum,
  ClassRegOpenPeriodsMetaResponseDto,
  ClassRegPeriodTypeEnum,
  ClassRegUserDto,
} from '@untis/wu-rest-view-api/api';
import { ClassRegLessonTopicViewApi, ClassRegOpenPeriodsViewApi } from '@/stores/api-store';
import {
  dayJsFromDateTimeString,
  formatDate,
  formatDateForServerRequest,
  formatTime,
  toDayJsFirstDayOfWeek,
  uDateFromDayjs,
} from '@/utils/date/date-util';
import ConfigStore from '@/stores/config-store';
import { IListViewSelectedRowsAction } from '@/components/list-view/list-view';
import { MessageRecipientOption } from '@untis/wu-rest-view-api/index';
import SendMessageViewStore from '@mg/stores/send-message-view-store';
import ModalStore from '@/stores/modal-store';
import CalendarEntryDetailView from '@/pages/calendar-entry/calendar-entry-detail-view/calendar-entry-detail-view';
import IframeMessageCallbackStore from '@/stores/iframe-message-callback-store';
import { IFrameHandleMessagePayload } from '@/components/embedded-webuntis/embedded-webuntis';
import { HandleMessageAction } from '@sp/stores/post-message-store';
import { TeachingContentSidebarStore } from '@/pages/class-register/teaching-content-sidebar/teaching-content-sidebar-store';
import { sortingByDayjs } from '@/utils/sorting/sorting-util';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import { LocalStorageContext, LocalStorageStore } from '@/stores/local-storage-store';
import SettingsStore, { NumberSetting } from '@/stores/settings-store';
import { matchesAllSearches } from '@/utils/filtering/filtering-util';
import { ISearchBarOption } from '@/ui-components/search-bar/search-bar';
import SchoolYearStore from '@/stores/schoolyear-store';
import { IToggleFilterProps } from '@/ui-components/filter-bar/filter/toggle-filter';

export interface IOpenPeriodsRow extends ITableRowKey {
  id: number;
  teacher: string;
  teacherIds: number[];
  class: string;
  subject: string;
  date: string;
  startDayjs: Dayjs;
  startTime: string;
  endTime: string;
  room: string;
  teachingContent: string;
  absencesChecked?: boolean;
  type: string | undefined;
  canAccessTopic: boolean;
  topicHistoryId: number | undefined;
}

const localStorageDatePickerValue = 'date-range-picker-value';

export class OpenPeriodsStore {
  private readonly ABS_CHECK_MISSING_FILTER_ID = 'missing-absence-check';
  private readonly TOPIC_MISSING_FILTER_ID = 'missing-teaching-content';
  private readonly _iframeCallbackId: string | undefined;

  private _fetchedDateRange: IDateRange | null = null;
  @observable private _meta: ClassRegOpenPeriodsMetaResponseDto | undefined;
  @observable private _periods: ClassRegOpenPeriodDto[] = [];
  @observable private _totalPeriods: number = 0;
  @observable private _isPageLoading: boolean = true;
  @observable private _isPageContentLoading: boolean = false;
  @observable private _selectedFreeTextOptions: string[] = [];
  @observable private _selectedOptions: ISearchBarOption[] = [];
  @observable private _absenceCheckMissingFilter: boolean = false;
  @observable private _teachingContentMissingFilter: boolean = false;
  @observable private _selectedDateRange: IDateRange = {
    // default date is about to be set after initial meta response is processed
    startDate: dayjs(0),
    endDate: dayjs(0),
    option: DateRangeOptionEnum.CUSTOM_DATE_RANGE,
  };
  @observable private _selectedRowKeys: Key[] = [];
  @observable private _periodTeacherUsers: ClassRegUserDto[] = [];
  @observable private _sidebarStore: SidebarStore = inject(SidebarStore);
  @observable private _configStore: ConfigStore = inject(ConfigStore);
  @observable private _sendMessageViewStore: SendMessageViewStore = inject(SendMessageViewStore);
  @observable private _modalStore: ModalStore = inject(ModalStore);
  @observable private _iframeCbStore: IframeMessageCallbackStore = inject(IframeMessageCallbackStore);
  @observable private _rightsStore: RightsStore = inject(RightsStore);
  @observable private _localStorageStore: LocalStorageStore = inject(LocalStorageStore);
  @observable protected _settingsStore: SettingsStore = inject(SettingsStore);
  @observable private _schoolYearStore: SchoolYearStore = inject(SchoolYearStore);

  @observable private _classesCategory = t('general.classes');
  @observable private _myClassesCategory = t('general.myClasses');
  @observable private _otherClassesCategory = t('general.otherClasses');
  @observable private _teachersCategory = t('general.teachers');

  @computed
  get classesCategory() {
    return this._classesCategory;
  }

  @computed
  get teachersCategory() {
    return this._teachersCategory;
  }

  @computed
  get myClassesCategory() {
    return this._myClassesCategory;
  }

  @computed
  get otherClassesCategory() {
    return this._otherClassesCategory;
  }

  @computed
  get totalPeriods() {
    return this._totalPeriods;
  }

  constructor() {
    // temporary solution until the new absences sidebar is implemented
    this._iframeCallbackId = this._iframeCbStore.registerCallback(
      HandleMessageAction.CR_OVERVIEW_PERIOD_DETAILS_UPDATE,
      this.crOldPageIframeCallback,
    );
  }

  onDestroy() {
    this._iframeCallbackId && this._iframeCbStore.clearCallback(this._iframeCallbackId);
  }

  @action.bound
  preselectSearchOptions(options: ISearchBarOption[]) {
    const personId = this._configStore.personId;
    const teacherOptions =
      this._configStore.isTeacher && personId
        ? options.filter((o) => o.category === this._teachersCategory && o.id === personId.toString())
        : [];
    if (teacherOptions.length > 0) {
      this.setSelectedOptions([teacherOptions[0]]);
    }
  }

  @action
  async loadMetaData() {
    this._isPageLoading = true;
    await ClassRegOpenPeriodsViewApi.loadOpenPeriodsMetaData().then((response) => {
      this.processMetaResponse(response.data);
    });
  }

  @action.bound
  async refreshOnFilterChange() {
    await this.fetchIfReady();
  }

  @action
  private async fetchIfReady() {
    if (!this._meta?.canReadAll && this.hasNoClassOrTeacherFilterSet()) {
      return;
    }

    if (!this._isPageLoading && !this._isPageContentLoading && this._selectedDateRange.startDate.unix() > 0) {
      this._isPageContentLoading = true;
      await ClassRegOpenPeriodsViewApi.loadOpenPeriodsData({
        ...this.resolveFetchFilterData(),
        dateRange: {
          start: formatDateForServerRequest(this._selectedDateRange.startDate),
          end: formatDateForServerRequest(this._selectedDateRange.endDate),
        },
      }).then((response) => {
        this._isPageContentLoading = false;
        this._periods = response.data.periods;
        this._totalPeriods = response.data.total;
        this._periodTeacherUsers = response.data.teachers;
        this._fetchedDateRange = this._selectedDateRange;
      });
    }
  }

  private resolveFetchFilterData(): {
    classId: number | undefined;
    teacherId: number | undefined;
    filter: ClassRegOpenPeriodsFilterEnum;
  } {
    let classId, teacherId;
    // one filter option for a request only, as one can be handled on DB level anyway
    // (multiple would be filtered afterwards within business service)
    if (this._selectedOptions.length > 0) {
      const option = this._selectedOptions[0];
      if (
        option.category === this._classesCategory ||
        option.category === this._myClassesCategory ||
        option.category === this._otherClassesCategory
      ) {
        classId = +option.id;
      } else if (option.category === this._teachersCategory) {
        teacherId = +option.id;
      }
    }
    return { classId, teacherId, filter: this.resolveFetchFilterEnum() };
  }

  @action.bound
  setSelectedDateRange(dateRange: IDateRange) {
    this._selectedDateRange = dateRange;
  }

  private isIDateRange(value: IDateRange | ICustomDateRange): value is IDateRange {
    return (value as IDateRange).option !== undefined;
  }

  @action.bound
  updateLocaleStorage(value: IDateRange | ICustomDateRange) {
    if (
      !this._meta?.canReadAll &&
      this.isIDateRange(value) &&
      value.option !== DateRangeOptionEnum.USER_SELECTION_DATE_RANGE
    ) {
      this._localStorageStore.writeString(
        LocalStorageContext.OPEN_PERIODS,
        localStorageDatePickerValue,
        value.option.toString(),
      );
    }
  }

  @action
  setSelectedFreeTextSearchOptions(value: string[]) {
    this._selectedFreeTextOptions = value;
  }

  @action
  setSelectedOptions(value: ISearchBarOption[]) {
    this._selectedOptions = value;
  }

  @computed
  get selectedDateRange(): IDateRange {
    return this._selectedDateRange;
  }

  @computed
  get isPageLoading(): boolean {
    return this._isPageLoading;
  }

  @computed
  get isPageContentLoading(): boolean {
    return this._isPageContentLoading;
  }

  @computed
  get schoolyearDateRange(): DateRange | undefined {
    return this._schoolYearStore.currentSchoolYearStart && this._schoolYearStore.currentSchoolYearEnd
      ? {
          startDate: this._schoolYearStore.currentSchoolYearStart,
          endDate: this._schoolYearStore.isTodayInCurrentSchoolYear()
            ? dayjs()
            : this._schoolYearStore.currentSchoolYearEnd,
        }
      : undefined;
  }

  @computed
  get filter(): IToggleFilterProps[] {
    // if allowedFilters contain 1 filter only then this filter is used by default
    // and no filtering option is available for the user

    return !this._meta || this._meta.allowedFilters.length > 1
      ? [
          {
            label: t('general.absenceCheckMissing'),
            value: this._absenceCheckMissingFilter,
            onChange: (value) => this.onAbsenceCheckMissingFilterChange(value),
          },
          {
            label: t('general.teachingContentMissing'),
            value: this._teachingContentMissingFilter,
            onChange: (value) => this.onTeachingContentMissingFilterChange(value),
          },
        ]
      : [];
  }

  @computed
  get absenceCheckMissingFilter(): boolean {
    return this._absenceCheckMissingFilter;
  }

  @computed
  get teachingContentMissingFilter(): boolean {
    return this._teachingContentMissingFilter;
  }

  @computed
  get myClasses(): ClassRegKlasseDto[] {
    return !this._meta || !this._meta.myClassIds.length
      ? []
      : this._meta.classes.filter((clazz) => this._meta?.myClassIds.includes(clazz.el.id));
  }

  @computed
  get otherClasses(): ClassRegKlasseDto[] {
    return !this._meta
      ? []
      : !this._meta.myClassIds.length
      ? this._meta.classes
      : this._meta.classes.filter((clazz) => !this._meta?.myClassIds.includes(clazz.el.id));
  }

  @computed
  get searchBarOptions(): ISearchBarOption[] {
    const options: ISearchBarOption[] = [];
    this.myClasses
      ?.sort((c1, c2) => c1.el?.name?.localeCompare(c2.el?.name))
      .forEach((clazz) =>
        options.push({
          id: clazz.el.id.toString(),
          label: clazz.el.name,
          category: this._myClassesCategory,
        }),
      );
    const classCategoryMsg = this.myClasses.length ? this._otherClassesCategory : this._classesCategory;
    this.otherClasses
      ?.sort((c1, c2) => c1.el?.name?.localeCompare(c2.el?.name))
      .forEach((clazz) =>
        options.push({
          id: clazz.el.id.toString(),
          label: clazz.el.name,
          category: classCategoryMsg,
        }),
      );
    this._meta?.teachers
      ?.sort((t1, t2) => t1.el.name.localeCompare(t2.el.name))
      .forEach((teacher) =>
        options.push({
          id: teacher.el.id.toString(),
          label: teacher.el.name,
          category: this._teachersCategory,
        }),
      );
    return options;
  }

  @computed
  get firstDayOfWeek(): number | undefined {
    const webUntisFirstDayOfWeek = this._settingsStore.getNumberSetting(NumberSetting.FIRST_DAY_OF_WEEK);
    if (webUntisFirstDayOfWeek) {
      return toDayJsFirstDayOfWeek(webUntisFirstDayOfWeek);
    }
    return undefined;
  }

  @computed
  get canReadAll(): boolean {
    return !!this._meta?.canReadAll;
  }

  @computed
  get canReadHistory(): boolean {
    return !!this._meta?.canSeeHistory;
  }

  @action.bound
  onTeachingContentMissingFilterChange(value: boolean) {
    if (value) {
      this._absenceCheckMissingFilter = false;
    }
    this._teachingContentMissingFilter = value;
  }

  @action.bound
  onAbsenceCheckMissingFilterChange(value: boolean) {
    if (value) {
      this._teachingContentMissingFilter = false;
    }
    this._absenceCheckMissingFilter = value;
  }

  @action
  setSelectedRowKeys(keys: Key[]) {
    this._selectedRowKeys = keys;
  }

  @computed
  get selectedOptions(): ISearchBarOption[] {
    return this._selectedOptions;
  }

  @computed
  get canSendMessage() {
    // class teachers, admins and subject teachers with Open Periods Right can read classes related open periods only
    // and so they have at least one allowed class in the list
    // and so they are allowed to send messages if having CREATE MESSAGES right
    return this.hasAtLeastOneClassOption && this._rightsStore.canCreate(Right.MESSAGES, ElementType.ALL, true);
  }

  @computed
  get selectedRowsActions(): IListViewSelectedRowsAction<IOpenPeriodsRow>[] | undefined {
    return this.canSendMessage
      ? [
          {
            label: t('general.sendMessage'),
            icon: 'admin-messages',
            onClick: this.sendMessage,
          },
        ]
      : undefined;
  }

  @computed
  get rows(): IOpenPeriodsRow[] {
    return this.mapDtosToRows(this.filteredPeriods);
  }

  @computed
  get selectedFreeTextOptions(): string[] {
    return this._selectedFreeTextOptions;
  }

  @computed
  get columns(): Columns<IOpenPeriodsRow> {
    const cols: Columns<IOpenPeriodsRow> = [
      {
        type: ColumnType.Text,
        key: 'class',
        header: t('general.class'),
        sorter: (a, b) => a.class.localeCompare(b.class),
      },
      {
        type: ColumnType.Text,
        key: 'subject',
        header: t('general.subject'),
        sorter: (a, b) => a.subject.localeCompare(b.subject),
      },
      {
        type: ColumnType.DateRange,
        key: 'date',
        getStart: (row) => {
          const yyyymmdd: string = row.startDayjs.format('YYYY-MM-DD');
          const HHmm: string = row.startDayjs.format('HH:mm');
          return dayjs(`${yyyymmdd}-${HHmm}`, 'YYYY-MM-DD-HH:mm');
        },
        getEnd: (row) => {
          const yyyymmdd: string = row.startDayjs.format('YYYY-MM-DD');
          const HHmm: string = row.endTime;
          return dayjs(`${yyyymmdd}-${HHmm}`, 'YYYY-MM-DD-HH:mm');
        },
        header: t('general.date'),
        sorter: (a, b) => sortingByDayjs(a.startDayjs, b.startDayjs),
        width: 210,
      },
      {
        type: ColumnType.Tag,
        key: 'type',
        header: t('general.type'),
        tags: (row) => (row.type ? [{ text: row.type }] : []),
      },
      {
        type: ColumnType.Text,
        key: 'room',
        header: t('general.room'),
        sorter: (a, b) => a.room.localeCompare(b.room),
      },
    ];
    if (this.hasMoreThanOneTeacherOption) {
      cols.unshift({
        type: ColumnType.Text,
        key: 'teacher',
        header: t('general.teacher'),
        sorter: (a, b) => a.teacher.localeCompare(b.teacher),
      });
    }
    if (
      this._meta?.allowedFilters.some((f) =>
        [ClassRegOpenPeriodsFilterEnum.ABSENCE_OPEN, ClassRegOpenPeriodsFilterEnum.TOPIC_OR_ABSENCE_OPEN].includes(f),
      )
    ) {
      cols.push({
        type: ColumnType.Button,
        header: t('general.absenceCheck'),
        key: 'absencesChecked',
        buttons: [
          {
            type: ButtonType.Sync,
            label: (row) => (row.absencesChecked ? t('general.checked') : t('general.check')),
            onClick: (row) => {
              // temporary, until the new absence sidebar is implemented
              this._modalStore.deprecatedOpenModalDialog({
                content: <CalendarEntryDetailView data={{ periodId: row.id }} tab="class-register" />,
                closable: true,
                size: 'full-size',
              });
            },
            disabled: (row) => row.absencesChecked == null,
            style: (row) => (row.absencesChecked ? 'success' : 'secondary'),
            outline: true,
          },
        ],
      });
    }
    if (
      this._meta?.allowedFilters.some((f) =>
        [ClassRegOpenPeriodsFilterEnum.TOPIC_OPEN, ClassRegOpenPeriodsFilterEnum.TOPIC_OR_ABSENCE_OPEN].includes(f),
      )
    ) {
      cols.push({
        type: ColumnType.TextOrButton,
        key: 'teachingContent',
        header: t('general.teachingContent'),
        getText: (row) => row.teachingContent,
        buttonLabel: t('general.enter'),
        disabled: (row) => !row.canAccessTopic,
        onClick: (row) => {
          const store = new TeachingContentSidebarStore(row.id);
          this._sidebarStore.openSidebar({
            withoutPadding: true,
            title: t('general.teachingContent'),
            overlap: true,
            content: <TeachingContentSidebar onSave={this.updatePeriodTopic} store={store} />,
            onClose: () => store.onCloseTeachingContentSidebar(),
          });
        },
        ellipsis: true,
        width: 200,
      });
    }
    if (this.canReadHistory) {
      cols.push({
        type: ColumnType.DropDownActions,
        key: 'actions',
        width: 70,
        actions: [
          {
            label: t('general.historyChanges'),
            condition: (row) => !!row.topicHistoryId,
            onClick: async (row) => {
              if (row.topicHistoryId) {
                const response = await ClassRegLessonTopicViewApi.getLessonTopicHistory(row.topicHistoryId);
                this._modalStore.openHistoryDialog(response.data.changes);
              }
            },
          },
        ],
      });
    }
    return cols;
  }

  @computed
  get hasMoreThanOneTeacherOption(): boolean {
    return !!this._meta && !!this._meta.teachers && this._meta.teachers.length > 1;
  }

  @computed
  get hasAtLeastOneClassOption(): boolean {
    return !!this._meta && !!this._meta.classes && this._meta.classes.length > 0;
  }

  @computed
  get selectedRowKeys(): Key[] {
    return this._selectedRowKeys;
  }

  /* The report accepts a parameter to determine, if only periods with (missing absences | missing topic | both)
   * should be included in the report. The are determined by the BE implementation:
   *
   *      byte TOPIC_NEEDED = 0x01;
   *      byte ABSENCE_CONTROL_NEEDED = 0x02;
   *      byte CONTROL_ALL = TOPIC_NEEDED | ABSENCE_CONTROL_NEEDED; // <-- 3 (0x01 OR 0x02),
   * */
  @computed
  get reportPeriodType(): number {
    const currentFilterEnum = this.resolveCurrentFilterEnum();
    if (currentFilterEnum === ClassRegOpenPeriodsFilterEnum.ABSENCE_OPEN) {
      return 2;
    } else if (currentFilterEnum === ClassRegOpenPeriodsFilterEnum.TOPIC_OPEN) {
      return 1;
    } else {
      return 3;
    }
  }

  @action.bound
  sendMessage(rows: IOpenPeriodsRow[]) {
    this._sendMessageViewStore.openSendMessageView({
      recipientOption: MessageRecipientOption.CUSTOM,
      initialRecipientsUserIds: this.resolvePeriodTeacherUserIds(rows),
    });
  }

  @action
  private processMetaResponse(responseDto: ClassRegOpenPeriodsMetaResponseDto) {
    this._isPageLoading = false;
    this._meta = responseDto;
    let selectedDateRange;

    const startOfWeekDateRange = getDateRangeForDateRangeOptionEnum(
      DateRangeOptionEnum.START_OF_WEEK,
      this.schoolyearDateRange,
      this.firstDayOfWeek,
    );
    if (startOfWeekDateRange) {
      selectedDateRange = {
        startDate: startOfWeekDateRange.startDate,
        endDate: startOfWeekDateRange.endDate,
        option: DateRangeOptionEnum.START_OF_WEEK,
      };
    }

    if (!this._meta?.canReadAll) {
      const storedDateRangePickerValue: string | undefined = this._localStorageStore.readString(
        LocalStorageContext.OPEN_PERIODS,
        localStorageDatePickerValue,
      );

      if (storedDateRangePickerValue) {
        const dateRangePickerValue = parseDateRangeEnum(storedDateRangePickerValue);
        if (dateRangePickerValue) {
          const dateRange = getDateRangeForDateRangeOptionEnum(
            dateRangePickerValue,
            this.schoolyearDateRange,
            this.firstDayOfWeek,
          );
          if (dateRange) {
            selectedDateRange = {
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
              option: dateRangePickerValue,
            };
          }
        }
      }
    }

    this._selectedDateRange = selectedDateRange || {
      ...this.getDefaultDateRangeForSchoolYear(this.schoolyearDateRange, 7),
      option: DateRangeOptionEnum.USER_SELECTION_DATE_RANGE,
    };

    this.setDefaultFilterValue(responseDto.defaultFilter);
  }

  /**
   * @return list of the user IDs related to the teachers teaching the periods currently rendered
   */
  @action.bound
  private resolvePeriodTeacherUserIds(rows: IOpenPeriodsRow[]): number[] {
    const rowsTeacherIds: number[] = rows.flatMap((row) => row.teacherIds);
    return this._periodTeacherUsers
      .filter((user) => user.personId && rowsTeacherIds.indexOf(user.personId) >= 0)
      .map((tu) => tu.id);
  }

  private mapDtoToSearchString(dto: ClassRegOpenPeriodDto): string {
    let res = '';
    res += dto.period.classes.map((c) => c.el.nameShort).join(' ');
    res += dto.period.teachers.map((t) => t.el.nameShort).join(' ');
    res += dto.period.rooms.map((r) => r.el.nameShort).join(' ');
    res += dto.period.subject ? dto.period.subject.el.nameShort : '';
    return res.toLocaleLowerCase();
  }

  @computed
  get filteredPeriods(): ClassRegOpenPeriodDto[] {
    return this._periods.filter(
      (dto) =>
        this.isMissingAnyChecks(dto) &&
        (this.hasNoClassOrTeacherFilterSet() || this.matchesSelectedClassOrTeacher(dto)) &&
        this.isInSelectedDateRange(dto) &&
        this.isOpenForSelectedReason(dto) &&
        matchesAllSearches(this.mapDtoToSearchString(dto), this._selectedFreeTextOptions),
    );
  }

  private isMissingAnyChecks(period: ClassRegOpenPeriodDto) {
    const absenceCheckMissing = period.absCheckNeeded && !period.absChecked;
    const teachingContentMissing = !!period.topicNeeded && !period.topicShort;
    return absenceCheckMissing || teachingContentMissing;
  }

  private isOpenForSelectedReason(period: ClassRegOpenPeriodDto) {
    const notFilteredByReason = !this._absenceCheckMissingFilter && !this._teachingContentMissingFilter;
    const absenceCheckMissing = this._absenceCheckMissingFilter && period.absCheckNeeded && !period.absChecked;
    const lessonTopicMissing = this._teachingContentMissingFilter && !!period.topicNeeded && !period.topicShort;

    return notFilteredByReason || lessonTopicMissing || absenceCheckMissing;
  }

  private hasNoClassOrTeacherFilterSet() {
    return this.selectedOptions.length === 0;
  }

  private matchesSelectedClassOrTeacher(dto: ClassRegOpenPeriodDto) {
    return this.selectedOptions.every(
      (o) =>
        ((o.category === this.myClassesCategory ||
          o.category === this.otherClassesCategory ||
          o.category === this.classesCategory) &&
          dto.period.classes.some((c) => c.el.id === parseInt(o.id))) ||
        (o.category === this.teachersCategory && dto.period.teachers.some((c) => c.el.id === parseInt(o.id))),
    );
  }

  private isInSelectedDateRange(dto: ClassRegOpenPeriodDto) {
    return (
      dayJsFromDateTimeString(dto.period.dtRange.start).isSameOrAfter(
        this._selectedDateRange.startDate.startOf('day'),
      ) && dayJsFromDateTimeString(dto.period.dtRange.end).isSameOrBefore(this._selectedDateRange.endDate.endOf('day'))
    );
  }

  private mapDtosToRows(dtos: ClassRegOpenPeriodDto[]): IOpenPeriodsRow[] {
    return dtos.map((dto) => {
      const startDayjs = dayJsFromDateTimeString(dto.period.dtRange.start);
      return {
        key: dto.period.id,
        id: dto.period.id,
        teacher: dto.period.teachers.map((t) => t.el.nameShort).join(', '),
        teacherIds: dto.period.teachers.map((t) => t.el.id),
        class: dto.period.classes.map((c) => c.el.nameShort).join(', '),
        subject: dto.period.subject ? dto.period.subject.el.nameShort : '',
        startDayjs: startDayjs,
        date: formatDate(startDayjs),
        startTime: formatTime(dto.period.dtRange.start),
        endTime: formatTime(dto.period.dtRange.end),
        room: dto.period.rooms.map((t) => t.el.name).join(', '),
        teachingContent: dto.topicShort || '',
        type: dto.type ? ClassRegPeriodTypeEnum[dto.type] : undefined,
        canAccessTopic: dto.canAccessTopic,
        absencesChecked: dto.absChecked,
        topicHistoryId: dto.topicId,
      };
    });
  }

  @action.bound
  private updatePeriodTopic(savedTeachingContents: ITeachingContentPreview[]) {
    this._periods.forEach((p) => {
      const saved = savedTeachingContents.find((tc) => tc.periodId === p.period.id);
      if (saved) {
        p.topicShort = saved.text;
        p.topicId = saved.topicId;
      }
    });
  }

  @action.bound
  private crOldPageIframeCallback(msgPayload: IFrameHandleMessagePayload | undefined) {
    if (msgPayload && msgPayload.payload && msgPayload.payload.periodId) {
      this._periods.forEach((p) => {
        if (p.period.id === msgPayload.payload.periodId) {
          if (msgPayload.payload.topic !== undefined) {
            p.topicShort = msgPayload.payload.topic;
          }
          if (msgPayload.payload.isAbsencesChecked !== undefined) {
            p.absChecked = msgPayload.payload.isAbsencesChecked;
          }
        }
      });
    }
  }

  @action
  private setDefaultFilterValue(defaultFilter?: ClassRegOpenPeriodsFilterEnum) {
    if (defaultFilter === ClassRegOpenPeriodsFilterEnum.TOPIC_OPEN) {
      this._teachingContentMissingFilter = true;
    } else if (defaultFilter === ClassRegOpenPeriodsFilterEnum.ABSENCE_OPEN) {
      this._absenceCheckMissingFilter = true;
    }
  }

  @computed
  get noToggleFilterSet(): boolean {
    return !this._absenceCheckMissingFilter && !this._teachingContentMissingFilter;
  }

  @computed
  get reportUrl(): string {
    const startDate = uDateFromDayjs(this.selectedDateRange.startDate);
    const endDate = uDateFromDayjs(this.selectedDateRange.endDate);
    let additionalParams = `&openPeriodType=${this.reportPeriodType}`;
    const selectedClass = this.selectedOptions.find(
      (o) =>
        o.category === this.myClassesCategory ||
        o.category === this.otherClassesCategory ||
        o.category === this.classesCategory,
    );
    const selectedTeacher = this.selectedOptions.find((o) => o.category === this.teachersCategory);
    if (selectedClass) {
      additionalParams += `&klasseId=${selectedClass.id}`;
    }
    if (selectedTeacher) {
      additionalParams += `&teacherId=${selectedTeacher.id}`;
    }
    if (this.selectedFreeTextOptions.length > 0) {
      additionalParams += `&filter=${encodeURIComponent(this.selectedFreeTextOptions.join(','))}`;
    }

    return `/WebUntis/reports.do?name=OpenPeriod&format=pdf&rpt_sd=${startDate}&rpt_ed=${endDate}${additionalParams}`;
  }

  private resolveCurrentFilterEnum(): ClassRegOpenPeriodsFilterEnum {
    // relying on that max one filter can be selected only at the same time
    if (
      this._absenceCheckMissingFilter ||
      (this._meta?.defaultFilter === ClassRegOpenPeriodsFilterEnum.ABSENCE_OPEN && this.noToggleFilterSet)
    ) {
      return ClassRegOpenPeriodsFilterEnum.ABSENCE_OPEN;
    } else if (
      this._teachingContentMissingFilter ||
      (this._meta?.defaultFilter === ClassRegOpenPeriodsFilterEnum.TOPIC_OPEN && this.noToggleFilterSet)
    ) {
      return ClassRegOpenPeriodsFilterEnum.TOPIC_OPEN;
    } else {
      return ClassRegOpenPeriodsFilterEnum.TOPIC_OR_ABSENCE_OPEN;
    }
  }

  private resolveFetchFilterEnum(): ClassRegOpenPeriodsFilterEnum {
    // fetching all allowed open periods, additional filtering applied client side
    return this._meta?.allowedFilters.includes(ClassRegOpenPeriodsFilterEnum.TOPIC_OR_ABSENCE_OPEN)
      ? ClassRegOpenPeriodsFilterEnum.TOPIC_OR_ABSENCE_OPEN
      : this.resolveCurrentFilterEnum();
  }

  private getDefaultDateRangeForSchoolYear(
    schoolYearRange: DateRange | undefined,
    days: number,
  ): { startDate: Dayjs; endDate: Dayjs } {
    const today = dayjs();
    if (schoolYearRange && schoolYearRange.startDate.isAfter(today)) {
      return {
        startDate: schoolYearRange.startDate.clone(),
        endDate: schoolYearRange.startDate.clone().add(days, 'days'),
      };
    } else {
      const isBefore = !!schoolYearRange && schoolYearRange.endDate.isBefore(today);
      // move to previous end of a day (by subtracting 1 minute) if end date is set to midnight (start of a day)
      return {
        startDate: isBefore
          ? schoolYearRange.endDate.clone().subtract(1, 'minute').subtract(days, 'days')
          : today.clone().startOf('week'),
        endDate: isBefore ? schoolYearRange.endDate.clone().subtract(1, 'minute') : today,
      };
    }
  }
}
