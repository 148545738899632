import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';
import axios from 'axios';
import clsx from 'clsx';
import { message } from 'antd';

import { Button, DeprecatedAttachment, Icon } from '@/ui-components';
import { IDeprecatedAttachment } from '@/ui-components/deprecated-attachment/deprecated-attachment';
import { HeaderDtoItem } from '@untis/wu-rest-view-api';
import { MessageViewApi } from '@/stores/api-store';

import './deprecated-attachments.less';

interface IProps {
  attachments: IDeprecatedAttachment[];
  onAdd?: () => void;
  onRemove?: (id: string) => void;
  readOnly?: boolean;
  singleFileUpload?: boolean;
  compact?: boolean;
  disabled?: boolean;
}

/**
 * @deprecated in favor of Attachments
 * Deprecated because:
 * - The Attachments have a dependency to the MessageViewApi
 * - Attachments should have a change handler that can be called with the actual correct values when attachments
 *   are added or removed. With this implementation this is not possible, which leads to cluncy usage and makes it
 *   impossible to use within a Form.Item
 * - Base components should not need to know that there is "drive", "s3" or similar WebUntis specific implementation
 *   details
 * @param props
 * @constructor
 */
export const DeprecatedAttachments = (props: IProps) => {
  const [isDownloading, setIsDownloading] = useState<string[]>([]);
  const { t } = useTranslation();
  const handleAdd = () => {
    props.onAdd && props.onAdd();
  };

  const handleRemove = (e: React.BaseSyntheticEvent, id: string) => {
    e.preventDefault();

    props?.onRemove?.(id);
  };

  const isS3Attachment = (attachment: IDeprecatedAttachment) => {
    return (
      !attachment.src ||
      (attachment.src && !!attachment.additionalHeaders && Object.keys(attachment.additionalHeaders).length)
    );
  };

  const onClick = useCallback(
    (attachment: IDeprecatedAttachment) => {
      if (isS3Attachment(attachment) && !isDownloading.includes(attachment.id)) {
        return async () => {
          try {
            let downloadUrl = attachment.src;
            let additionalHeaders = attachment.additionalHeaders ?? {};
            setIsDownloading((previous) => [...previous, attachment.id]);
            if (!downloadUrl) {
              // if the presigned Request was not already generated, do it now
              const fileInfo = await MessageViewApi.getAttachmentStorageUrl(attachment.id);
              downloadUrl = fileInfo.data.downloadUrl;
              additionalHeaders =
                fileInfo.data.additionalHeaders?.reduce(
                  (headers: { [key: string]: string[] }, headerItem: HeaderDtoItem) => {
                    if (headerItem.key === 'host') return headers;
                    headers[headerItem.key] = [headerItem.value];
                    return headers;
                  },
                  {},
                ) ?? {};
            }
            additionalHeaders['X-Amz-Date'] = [new URL(downloadUrl).searchParams.get('X-Amz-Date') ?? ''];
            const { data: blob } = await axios.get(downloadUrl, {
              responseType: 'blob',
              headers: additionalHeaders,
            });
            fileDownload(blob, attachment.name);
          } catch (error) {
            message.error(error.message);
          } finally {
            setIsDownloading((previous) => previous.filter((id) => attachment.id !== id));
          }
        };
      }
      // otherwise this is a onedrive attachment and can just be opened in a new tab
      return;
    },
    [isDownloading],
  );

  if (props.readOnly && props.attachments.length === 0) {
    return null;
  }

  return (
    <div
      className={clsx('deprecated-attachments', {
        compact: props.compact,
      })}
    >
      {props.attachments.length === 0 && (
        <Button testId="general.attachFile" onClick={handleAdd} size={props.compact ? 'small' : undefined} outline>
          <Icon type="attachment" />
          {t('general.attachFile')}
        </Button>
      )}
      {props.attachments.map((attachment) => {
        const hoverIcon = props.readOnly
          ? { type: 'download', onClick: undefined, testId: `download-attachment-${attachment.id}` }
          : {
              type: 'cancel-circle-small',
              onClick: (e: React.BaseSyntheticEvent) => {
                e.stopPropagation();
                if (!props.disabled) {
                  handleRemove(e, attachment.id);
                }
              },
              testId: `remove-attachment-${attachment.id}`,
            };

        return (
          <DeprecatedAttachment
            compact={props.compact}
            key={attachment.id}
            name={attachment.name}
            href={attachment.src}
            onClick={isS3Attachment(attachment) ? onClick(attachment) : undefined}
            hoverIcon={hoverIcon}
            disabled={props.disabled}
          />
        );
      })}
      {props.attachments.length > 0 && !props.readOnly && !props.singleFileUpload && (
        <Button size="large" className="add-button" onClick={handleAdd} testId="add-attachment" circle outline>
          <Icon type="plus" />
        </Button>
      )}
    </div>
  );
};
