import { message as antdMessage } from 'antd';
import { t } from 'i18next';
import fileDownload from 'js-file-download';
import { observable } from 'mobx';
import React from 'react';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

import {
  IncomingMessageRefDto,
  SentMessageRefDto,
  IncomingMessageViewDto,
  SentMessageViewDto,
  DraftMessageViewDto,
  MessageAttachmentDto,
  StorageMessageAttachmentDto,
} from '@untis/wu-rest-view-api';
import { MessageViewApi } from '@/stores/api-store';
import ModalStore from '@/stores/modal-store';
import TokenStore from '@/stores/token-store';
import { inject, IStore, Store } from '@/types/store';
import { ErrorMessageEnum, showError } from '@/utils/error-handling/error-message';
import { IErrorResponseData } from '@/utils/error-handling/error-handling';
import MessageDetailView from '@mg/components/message-detail-view/message-detail-view';
import DraftMessagesStore from '@mg/stores/draft-messages-store';
import IncomingMessagesStore from '@mg/stores/incoming-messages-store';
import SentMessagesStore from '@mg/stores/sent-messages-store';
import SendMessageViewStore from '@mg/stores/send-message-view-store';
import { calendarFileDescriptorDtoToAttachment } from '@/stores/one-drive-store';
import { IDeprecatedAttachment } from '@/ui-components/deprecated-attachment/deprecated-attachment';

export type MessageType = 'incoming' | 'incomingReadConfirmation' | 'sent' | 'draft' | 'unknown';

@Store()
export default class MessageDetailViewStore implements IStore {
  @observable isLoading = true;
  @observable message: IncomingMessageViewDto | SentMessageViewDto | DraftMessageViewDto | null = null;

  private messageType: MessageType = 'unknown';
  private modalStore = inject(ModalStore);
  private tokenStore = inject(TokenStore);
  private incomingMessageStore = inject(IncomingMessagesStore);
  private sentMessageStore = inject(SentMessagesStore);
  private draftMessageStore = inject(DraftMessagesStore);
  private sendMessageViewStore = inject(SendMessageViewStore);

  private async getIncomingMessage(messageRef: IncomingMessageRefDto) {
    this.isLoading = true;

    try {
      await this.loadMessage(messageRef.id);
      if (!messageRef.isMessageRead) {
        messageRef.isMessageRead = true;
        this.incomingMessageStore.updateMessageUnreadCount();
      }
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
      this.message = null;
      this.modalStore.closeModal();
    }

    this.isLoading = false;
  }

  private async loadMessage(messageId: number) {
    const response = await MessageViewApi.getIncomingMessage(messageId, true);
    this.message = response.data;
    this.stripEmptyMessageHtmlContent();
  }

  openIncomingMessageDetailView(messageRef: IncomingMessageRefDto, clickedElement?: HTMLElement) {
    this.messageType = 'incoming';
    this.getIncomingMessage(messageRef);
    this.modalStore.openModalDialog({
      className: 'message-detail-modal',
      children: <MessageDetailView />,
      size: 'lg',
      noPadding: true,
      lastFocusedElement: clickedElement,
    });
  }

  async openSentMessageDetailView(messageRef: SentMessageRefDto, clickedElement?: HTMLElement) {
    this.messageType = 'sent';
    this.getSentMessage(messageRef);
    this.modalStore.openModalDialog({
      className: 'message-detail-modal',
      children: <MessageDetailView />,
      size: 'lg',
      noPadding: true,
      lastFocusedElement: clickedElement,
    });
  }

  async openDeleteMessageConfirmationModal(messageId?: number, messageType?: MessageType) {
    const id = messageId ?? this.message?.id;

    if (!id) {
      // no ID specified
      return;
    }

    const title =
      messageType === 'draft' ? t('general.messageDraftDeleteConfirmation') : t('general.messageDeleteConfirmation');
    const subtitle =
      messageType === 'draft'
        ? t('general.messageDraftDeleteConfirmationSubtitle')
        : t('general.messageDeleteConfirmationSubtitle');

    const shouldDelete = await this.modalStore.openDeletePrompt(title, subtitle);

    if (!shouldDelete) {
      return;
    }

    try {
      await MessageViewApi.deleteMessage(id);
      switch (messageType ?? this.messageType) {
        case 'incoming':
          this.incomingMessageStore.deleteMessage(id);
          break;
        case 'sent':
          this.sentMessageStore.deleteMessage(id);
          break;
        case 'draft':
          this.draftMessageStore.deleteMessage(id);
          break;
      }
      antdMessage.success(t('general.messageDeleteSuccess'));
      this.modalStore.closeAll();
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
    }
  }

  openReplyMessageDetailView(messageId: number, clickedElement?: HTMLElement) {
    this.sendMessageViewStore.openReplySendMessageView(messageId, clickedElement);
  }

  async openRevokeMessageConfirmationModal(messageRef?: SentMessageRefDto | undefined) {
    const message = messageRef || this.message; // use the given message or the message shown in the detail view
    const shouldRevoke = await this.modalStore.openDeletePrompt(
      t('general.messageRevokeConfirmation'),
      t('general.messageRevokeConfirmationSubtitle'),
      {
        okButton: t('general.revoke'),
      },
    );

    if (!shouldRevoke || !message) {
      return;
    }

    try {
      await MessageViewApi.revokeMessage(message.id);
      this.sentMessageStore.deleteMessage(message.id);
      this.modalStore.closeModal();
      this.message = null;
      antdMessage.success(t('general.messageRevokeSuccess'));
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
    }
  }

  async confirmReadRequest(message: IncomingMessageViewDto) {
    const moveMessageToInbox = () => {
      const messageIndex = this.incomingMessageStore.readConfirmationMessages.findIndex((m) => m.id === message.id);
      const removedMessages = this.incomingMessageStore.readConfirmationMessages.splice(messageIndex, 1);
      if (removedMessages.length > 0) {
        this.incomingMessageStore.messages.unshift(removedMessages[0]);
      }
    };

    if (message.requestConfirmation?.allowSendRequestConfirmation) {
      try {
        const response = await MessageViewApi.confirmReadReceipt(message.id);
        message.requestConfirmation = response.data;
        message.allowMessageDeletion = response.data.allowMessageDeletion;
        message.isReplyAllowed = response.data.isReplyAllowed;

        // update the message in the message list and not just in the detail view
        const messageRef = this.incomingMessageStore.readConfirmationMessages.find(({ id }) => id === message.id);
        if (messageRef) {
          messageRef.allowMessageDeletion = response.data.allowMessageDeletion;
        }

        moveMessageToInbox();
      } catch (error) {
        const axiosError = error as AxiosError;
        if ('response' in axiosError) {
          if (
            (axiosError.response?.data as IErrorResponseData)?.errorCode ===
            'MESSAGING_READ_CONFIRMATION_ALREADY_CONFIRMED'
          ) {
            moveMessageToInbox();
            this.loadMessage(this.message!.id);
          }
        } else {
          console.error(error);
          showError(ErrorMessageEnum.ERROR);
        }
      }
    }
  }

  getRequestConfirmationText() {
    if (
      this.message &&
      'requestConfirmation' in this.message &&
      this.message.requestConfirmation &&
      typeof this.message.requestConfirmation !== 'boolean'
    ) {
      if (this.message.requestConfirmation.confirmationDate) {
        const confirmedByCurrentUser = this.tokenStore.getUserId() === this.message.requestConfirmation.confirmerUserId;
        const formattedDate = dayjs(this.message.requestConfirmation.confirmationDate).format('L');
        if (confirmedByCurrentUser) {
          return t('general.confirmedAt', { date: formattedDate });
        } else {
          return t('general.confirmedByAt', {
            userName: this.message.requestConfirmation.confirmerUserDisplayName,
            date: formattedDate,
          });
        }
      } else {
        if (!this.message.requestConfirmation.allowSendRequestConfirmation) {
          return t('general.parentReadConfirmationNecessary');
        }
      }
    }
  }

  getIsReplyForbiddenText() {
    if (this.isSentMessage) {
      return t('general.messageSentReplyIsForbidden');
    }

    return t('general.messageReplyIsForbidden');
  }

  async downloadBlobAttachment(messageId: number, fileName: string) {
    // we need to pass 'blob' as responseType so that Axios downloads the file correctly
    const result = await MessageViewApi.downloadBlobAttachment(messageId, { responseType: 'blob' });
    /* we need to cast the data of the result to 'any' because there is no correct mapping
     * for blob files in swagger 2 */
    fileDownload(result.data as any, fileName);
  }

  getOneDriveAttachments(attachments: MessageAttachmentDto[]): IDeprecatedAttachment[] {
    return attachments.map(calendarFileDescriptorDtoToAttachment);
  }

  getStorageAttachments(storageAttachments: StorageMessageAttachmentDto[]): IDeprecatedAttachment[] {
    return storageAttachments.map((storageAttachment) => ({
      id: storageAttachment.id,
      name: storageAttachment.name,
      src: undefined,
    }));
  }

  get isIncomingMessage() {
    let isIncomingMessage = false;
    if (this.message != null && 'sender' in this.message) {
      isIncomingMessage = true;
    }
    return isIncomingMessage;
  }

  get isSentMessage() {
    return this.message && !('sender' in this.message);
  }

  private async getSentMessage(messageRef: SentMessageRefDto) {
    this.isLoading = true;

    try {
      const response = await MessageViewApi.getSentMessage(messageRef.id, true);
      this.message = response.data;

      this.stripEmptyMessageHtmlContent();
    } catch (error) {
      console.error(error);
      showError(ErrorMessageEnum.ERROR);
      this.message = null;
      this.modalStore.closeModal();
    }

    this.isLoading = false;
  }

  private stripEmptyMessageHtmlContent() {
    // we are checking if the message would be empty if we strip all the html from it, and if it is empty, we just
    // also show the empty text
    const div = document.createElement('div');
    if (this.message?.content) {
      div.innerHTML = this.message.content;
      if (!div.innerText) {
        this.message.content = '';
      }
    }
  }
}
