import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@/ui-components';
import './attachments.less';
import { FileDescriptorDtoV2 } from '@untis/wu-rest-view-api';

// to avoid introducing a dependency to the wu api, we use this interface instead of FileDescriptorDtoV2

export interface IAttachmentProps {
  value: FileDescriptorDtoV2[];
  onUpload: () => Promise<FileDescriptorDtoV2[]>;
  onChange: (attachments: FileDescriptorDtoV2[]) => void;
  onDownload: (attachment: FileDescriptorDtoV2) => void;
  /* Use this method if you have to do something when the user clicks (x) on an attachment (e.g. server request,...).
   * When an attachment is removed, the onChange handler is also called with the new array of attachments. */
  onRemove?: (attachment: FileDescriptorDtoV2) => void;
  readOnly?: boolean;
  onlyOneAttachment?: boolean;
  disabled?: boolean;
  testId?: string;
  localSource?: boolean;
}

export const Attachments = (props: IAttachmentProps) => {
  const { t } = useTranslation();

  const handleRemove = (id: string) => {
    props.onRemove && props.onRemove(props.value.find((a) => a.storageId === id)!);
    props.onChange && props.onChange(props.value.filter((a) => a.storageId !== id));
  };

  const getHoverIcon = (id: string) => {
    const testId = props.testId ? `${props.testId}-attachments-remove-${id}` : undefined;
    return props.readOnly
      ? { type: 'download', onClick: undefined, testId: `download-attachment-${id}` }
      : {
          type: 'cancel-circle-small',
          onClick: (e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            if (!props.disabled) {
              handleRemove(id);
            }
          },
          testId: testId,
        };
  };

  const onUpload = () => {
    const oldValues = props.value;
    const oldValuesIds = oldValues.map((value) => value.storageId);
    props.onUpload().then((addedValues) => {
      const newValues = [...oldValues];
      addedValues.forEach((value) => {
        if (!oldValuesIds.includes(value.storageId)) {
          newValues.push(value);
        }
      });
      props.onChange && props.onChange(newValues);
    });
  };

  if (props.readOnly && props.value && props.value.length === 0) {
    return null;
  }

  const handleDownload = (attachment: FileDescriptorDtoV2) => {
    props.onDownload(attachment);
  };

  const testId = props.testId ? `${props.testId}-attachments` : undefined;
  const addButtonTestId = testId ? `${testId}-add` : undefined;

  return (
    <div className="attachments">
      {(!props.value || props.value.length === 0) && (
        <Button triggersSubmit={false} testId={addButtonTestId} onClick={onUpload} disabled={props.disabled} outline>
          <Icon type="attachment" />
          {t('general.attachFile')}
        </Button>
      )}
      {props.value &&
        props.value.map((attachment, index) => {
          return (
            <Attachment
              name={attachment.name}
              id={attachment.storageId}
              hoverIcon={getHoverIcon(attachment.storageId)}
              onClick={() => handleDownload(attachment)}
              disabled={props.disabled}
              testId={props.testId}
              key={index}
            />
          );
        })}
      {props.value && props.value.length > 0 && !props.readOnly && !props.onlyOneAttachment && !props.disabled && (
        <Button
          triggersSubmit={false}
          size="large"
          className="add-button"
          onClick={onUpload}
          testId={addButtonTestId}
          circle
          outline
        >
          <Icon type="plus" />
        </Button>
      )}
    </div>
  );
};

interface IAttachmentComponentProps {
  name: string;
  id: string;
  hoverIcon: {
    type: string;
    onClick?: (event: React.BaseSyntheticEvent) => void;
    testId?: string;
  };
  onClick?: () => void;
  disabled?: boolean;
  testId?: string;
}

/**
 * For internal use of "Attachments" component only - should not be exported
 */
const Attachment = (props: IAttachmentComponentProps) => {
  const attachmentContent = (
    <>
      <Icon className="file-icon" type={getIconForFile(props.name)} />
      <span className="file-name">{props.name}</span>
      {!props.disabled && (
        <Icon
          className="hover-icon"
          type={props.hoverIcon.type}
          onClick={props.hoverIcon.onClick}
          testId={props.hoverIcon.testId}
        />
      )}
    </>
  );

  const className = clsx('attachment');

  const testId = props.testId ? `${props.testId}-attachments` : undefined;
  const attachmentTestId = testId ? `${testId}-${props.id}` : undefined;

  return (
    <div className={className}>
      <button
        data-testid={attachmentTestId}
        type="button"
        disabled={props.disabled}
        className="attachment-content"
        onClick={props.onClick}
      >
        {attachmentContent}
      </button>
    </div>
  );
};

export const getIconForFile = (fileName: string) => {
  fileName = fileName.toLowerCase();
  const parts = fileName.split('.');

  if (parts.length < 2) {
    return 'unknown';
  }

  const extension = parts[parts.length - 1];
  switch (extension) {
    case 'avi':
      return 'avi';
    case 'bmp':
      return 'bmp';
    case 'eps':
      return 'eps';
    case 'jpg':
    case 'jpeg':
      return 'jpg';
    case 'mov':
      return 'mov';
    case 'mp4':
      return 'mp4';
    case 'pdf':
      return 'pdf';
    case 'png':
      return 'png';
    case 'ppt':
      return 'ppt';
    case 'psd':
      return 'psd';
    case 'rar':
      return 'rar';
    case 'rtf':
      return 'rtf';
    case 'txt':
      return 'txt';
    case 'xls':
    case 'xlsx':
      return 'xls';
    case 'zip':
      return 'zip';
    case 'doc':
    case 'docx':
      return 'doc';
  }

  return 'unknown';
};
