import { action, computed, observable, toJS } from 'mobx';
import { Placement } from 'react-joyride';

import { Store } from '@/types/store';
import { OnboardingApi } from '@/stores/api-store';
import { OnboardingTypeEnum } from '@untis/wu-rest-view-api';
import { FeatureOnboardingModalSize } from '@/components/feature-onboarding/feature-onboarding-modal';

export type FeatureOnboardingGraphicType = 'video' | 'image';

interface IFeatureOnboardingGraphicCommon {
  type: FeatureOnboardingGraphicType;
}

interface IIFeatureOnboardingVideoGraphic {
  name: string;
  loop?: boolean;
}

export type FeatureOnboardingGraphic = (
  | {
      type: 'video';
      content: IIFeatureOnboardingVideoGraphic;
    }
  | {
      type: 'image';
      name: string;
    }
) &
  IFeatureOnboardingGraphicCommon;

export interface IFeatureOnboardingStepConfiguration {
  id: string;
  target: string;
  title: string;
  textContent: string;
  textContainsHtml?: boolean;
  graphic: FeatureOnboardingGraphic;
  placement: Placement | 'auto' | 'center';
  beforeStep?: () => void;
  afterStep?: () => void;
}

type FeatureOnboardingWelcomeStep = Omit<IFeatureOnboardingStepConfiguration, 'target' | 'placement'>;

export interface IFeatureOnboardingStep extends IFeatureOnboardingStepConfiguration {
  stepNumber?: number;
  isWelcomeStep: boolean;
  isExitStep: boolean;
  hasNextStep: boolean;
  hasPreviousStep: boolean;
  modalSize?: FeatureOnboardingModalSize;
}

export interface IFeatureOnboardingConfig {
  type: OnboardingTypeEnum;
  maxReachedStep?: string;
  steps: IFeatureOnboardingStepConfiguration[];
  welcomeStep?: FeatureOnboardingWelcomeStep;
  withExitStep?: boolean;
}

enum FeatureOnboardingStatus {
  NOT_STARTED = 'NOT_STARTED',
  INCOMPLETE = 'INCOMPLETE',
  COMPLETE = 'COMPLETE',
}

@Store()
export class FeatureOnboardingStore {
  @observable private _isRunning = false;
  @observable private _config: IFeatureOnboardingConfig | undefined;
  @observable private _currentStepId: string | undefined;
  @observable private _lastSavedStepId: string | undefined;
  @observable private _exitStepVisible = false;

  @action
  async start(config: IFeatureOnboardingConfig) {
    const status = this.getStatusFromConfig(config);
    const currentStepId = this.getStepIdToOpen(config, status);

    this._config = config;
    this._currentStepId = currentStepId;
    this._lastSavedStepId = config.maxReachedStep;
    this._exitStepVisible = false;

    await this.runHooks(
      undefined,
      config.steps.find((step) => step.id === currentStepId),
    );
    await this.updateOnboardingIfNeeded();
    this._isRunning = true;
  }

  private getStatusFromConfig(config: IFeatureOnboardingConfig): FeatureOnboardingStatus {
    const lastStepId = config.steps[config.steps.length - 1].id;
    if (config.maxReachedStep === lastStepId) {
      return FeatureOnboardingStatus.COMPLETE;
    } else if ((config.maxReachedStep?.length ?? 0) > 0) {
      return FeatureOnboardingStatus.INCOMPLETE;
    } else {
      return FeatureOnboardingStatus.NOT_STARTED;
    }
  }

  private getStepIdToOpen(config: IFeatureOnboardingConfig, status: FeatureOnboardingStatus): string | undefined {
    const firstStepId = config.welcomeStep ? config.welcomeStep.id : config.steps[0].id;
    if (status === FeatureOnboardingStatus.NOT_STARTED) {
      return firstStepId;
    } else {
      return config.steps[0].id;
    }
  }

  @action
  private async finishOnboarding() {
    await this.runHooks(this.currentStep, undefined);
    this._isRunning = false;
    this._config = undefined;
    this._currentStepId = undefined;
    this._lastSavedStepId = undefined;
    this._exitStepVisible = false;
  }

  @action
  private async processNewStep(joyrideStepIndex: number, isExitStep?: boolean) {
    const nextStepId = this.joyRideSteps[joyrideStepIndex].id;
    const nextStep = this.joyRideSteps.find((step) => step.id === nextStepId);

    await this.runHooks(this.currentStep, nextStep);

    this._exitStepVisible = isExitStep ?? false;
    this._currentStepId = nextStepId;

    await this.updateOnboardingIfNeeded();
  }

  private async runHooks(
    currentStep?: IFeatureOnboardingStepConfiguration,
    nextStep?: IFeatureOnboardingStepConfiguration,
  ) {
    if (currentStep?.afterStep) {
      currentStep.afterStep();
    }

    if (nextStep?.beforeStep) {
      nextStep?.beforeStep();
      await this.delayForMs(200);
    }
  }

  private async updateOnboardingIfNeeded() {
    const lastSavedStepIndex = this.joyRideSteps.findIndex((step) => step.id === this._lastSavedStepId);
    if (this.currentJoyrideStepIndex > lastSavedStepIndex) {
      await OnboardingApi.updateOnboarding({ type: this._config?.type, step: this._currentStepId });
      this._lastSavedStepId = this._currentStepId;
    }
  }

  private delayForMs(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  @computed
  get isRunning(): boolean {
    return toJS(this._isRunning);
  }

  @computed
  get joyRideSteps(): IFeatureOnboardingStep[] {
    if (this._config) {
      const lastStepIndex = this._config.steps.length - 1;
      const steps: IFeatureOnboardingStep[] = this._config.steps.map((step, index) => {
        return {
          ...step,
          stepNumber: index + 1,
          isWelcomeStep: false,
          isExitStep: false,
          hasNextStep: index != lastStepIndex,
          hasPreviousStep: index > 0,
        };
      });

      if (this._config.welcomeStep) {
        steps.unshift({
          ...this._config.welcomeStep,
          stepNumber: undefined,
          target: 'body',
          placement: 'center',
          modalSize: 'lg',
          isWelcomeStep: true,
          isExitStep: false,
          hasNextStep: false,
          hasPreviousStep: false,
        });
      }

      if (this._config.withExitStep) {
        const lastStep = this._config.steps[this._config.steps.length - 1];
        steps.push({
          ...lastStep,
          stepNumber: undefined,
          isWelcomeStep: false,
          isExitStep: true,
          hasNextStep: false,
          hasPreviousStep: false,
        });
      }

      return toJS(steps);
    } else {
      return [];
    }
  }

  @computed
  get actualSteps(): IFeatureOnboardingStep[] {
    return this.joyRideSteps.filter((step) => !step.isWelcomeStep && !step.isExitStep);
  }

  @computed
  get currentStep(): IFeatureOnboardingStep | undefined {
    if (this._exitStepVisible) {
      return this.joyRideSteps[this.joyRideSteps.length - 1];
    }
    return this.joyRideSteps.find((step) => step.id === this._currentStepId);
  }

  @computed
  get currentJoyrideStepIndex(): number {
    return toJS(this.joyRideSteps.findIndex((step) => step.id === this._currentStepId));
  }

  @computed
  private get hasNextJoyrideStep(): boolean {
    return this.currentJoyrideStepIndex < this.joyRideSteps.length - 1;
  }

  @computed
  private get hasPreviousJoyrideStep(): boolean {
    return this.currentJoyrideStepIndex > 0;
  }

  @computed
  get hasExitStep(): boolean | undefined {
    return this._config?.withExitStep;
  }

  @action.bound
  async toNextStep() {
    if (this._isRunning && this.hasNextJoyrideStep) {
      await this.processNewStep(this.currentJoyrideStepIndex + 1);
    }
  }

  @action.bound
  async toPreviousStep() {
    if (this._isRunning && this.hasPreviousJoyrideStep) {
      await this.processNewStep(this.currentJoyrideStepIndex - 1);
    }
  }

  @action.bound
  async toExitStep() {
    if (this._isRunning && this._config?.withExitStep) {
      await this.processNewStep(this.joyRideSteps.length - 1, true);
    }
  }

  @action.bound
  async close() {
    if (this._isRunning) {
      await this.finishOnboarding();
    }
  }

  @action.bound
  async toStep(stepId: string) {
    if (this._isRunning) {
      const joyrideStepIndex = this.joyRideSteps.findIndex((step) => step.id === stepId);
      joyrideStepIndex > 0 &&
        joyrideStepIndex <= this.joyRideSteps.length - 1 &&
        (await this.processNewStep(joyrideStepIndex));
    }
  }
}
