import { action, observable } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';

import {
  BackEntryDto,
  DayDataDto,
  DayEntryDto,
  DayEntryStatusDetailEnum,
  DayEntryStatusEnum,
  DayEntryTypeEnum,
  GridEntryDto,
  GridEntryStatusDetailEnum,
  Timespan,
  TimetableEntriesDto,
} from '@untis/wu-rest-view-api';
import { ILessonCardProps, LessonCardStatus, LessonCardType } from '@/components/lesson-card/lesson-card';
import { TimetableGridDimensionsStore } from '@te/standard/stores/grid/timetable-grid-dimensions-store';
import { TimetableTimeStore } from '@te/standard/stores/time/timetable-time-store';
import { inject, Store } from '@/types/store';
import { TimetableExternalEventEnricher } from '@te/standard/stores/data/timetable-external-event-enricher';
import { TimetableUntisPeriodEnricher } from '@te/standard/stores/data/timetable-untis-period-enricher';
import { CommonTimetableDataMapper } from '@te/standard/stores/data/common-timetable-data-mapper';
import { AbstractTimetableDataEnricher } from '@te/standard/stores/data/abstract-timetable-data-enricher';
import { TimetableGridSlotsStore } from '@te/standard/stores/grid/timetable-grid-slots-store';
import { TimetableDataPostprocessStore } from '@te/standard/stores/data/timetable-data-postprocess-store';
import { BackEntryStatusDetailEnum, BackEntryTypeEnum } from '@untis/wu-rest-view-api/api';
import { formatDateRange } from '@/utils/date/date-util';

export interface ITimetableEntries {
  gridEntries: IParallelGridEntryGroup[];
  layers: ITimetableLayer[];
}

export interface IGridEntry {
  periodIds: number[];
  parallelGroupId: number;
  top: number;
  height: number;
  widthPercentage: number;
  leftPercentage: number;
  lessonCardProps: ILessonCardProps;
  link?: string;
  durationTotal?: Timespan;
  isCancelled?: boolean;
  isExam?: boolean;
  hasChange?: boolean;
  isExternal?: boolean;
  isParallelToLeft?: boolean;
}

export interface ISummarisedGridEntries {
  top: number;
  height: number;
  widthPercentage: number;
  leftPercentage: number;
  hiddenEntries: IGridEntry[];
}

export interface IParallelGridEntryGroup {
  entries: IGridEntry[];
  summarisedEntries?: ISummarisedGridEntries;
}

export interface IFullDayEvent {
  id: number;
  type: DayEntryTypeEnum;
  status: DayEntryStatusEnum;
  title: string;
  location?: string;
  groupName?: string;
  description?: string;
  externalLink?: string;
  color: string;
  calendarName?: string;
  isTentative: boolean;
  startDateTime: Dayjs;
  endDateTime: Dayjs;
  durationTotal?: Timespan;
}

export interface ISummarisedFullDayEvents {
  events: IFullDayEvent[];
  additionalCount?: number;
}

export interface ITimetableLayerProps {
  id?: number;
  type: BackEntryTypeEnum;
  top: number;
  height: number;
  widthPercentage: number;
  leftPercentage: number;
  text?: string;
  layerText?: string;
  parallelLeftOffset?: number;
  parallelWidthOffset?: number;
}

export interface ITimetableLayerHolidayProps extends ITimetableLayerProps {
  textDate?: string;
  layerTextDate?: string;
  bookable?: boolean;
}

export type ITimetableLayer = ITimetableLayerProps | ITimetableLayerHolidayProps;

interface IGridEntryDuration {
  startDateTime: Dayjs;
  endDateTime: Dayjs;
}

@Store()
export class TimetableDataStore {
  private timetableGridSlotsStore = inject(TimetableGridSlotsStore);
  private commonTimetableDataMapper = inject(CommonTimetableDataMapper);
  private timetableUntisPeriodEnricher = inject(TimetableUntisPeriodEnricher);
  private timetableExternalEventEnricher = inject(TimetableExternalEventEnricher);
  private timetableDataPostprocessStore = inject(TimetableDataPostprocessStore);
  private timetableGridDimensionsStore = inject(TimetableGridDimensionsStore);
  private timetableTimeStore = inject(TimetableTimeStore);

  private timetablePeriodEnrichers: Array<AbstractTimetableDataEnricher> = [
    this.timetableUntisPeriodEnricher,
    this.timetableExternalEventEnricher,
  ];

  @observable private _timeTableEntries: TimetableEntriesDto | undefined;

  @action
  reset() {
    this._timeTableEntries = undefined;
  }

  getTimetableEntries(date: Dayjs): ITimetableEntries {
    const dayData = this.getDayData(date);
    const gridEntries = this.mapGridEntries(dayData);
    const layers = this.mapBackEntries(dayData);
    return {
      gridEntries: this.timetableDataPostprocessStore.postProcessGridEntries(gridEntries),
      layers: layers,
    };
  }

  getFullDayEvents(date: Dayjs): ISummarisedFullDayEvents {
    const fullDayEvents: IFullDayEvent[] = this.mapDayEntries(this.getDayData(date));
    return this.timetableDataPostprocessStore.summariseFullDayEvents(fullDayEvents);
  }

  private getDayData(date: Dayjs): DayDataDto | undefined {
    return this._timeTableEntries?.days.find((day) => {
      const currentDayDate = dayjs(day.date);
      return date.isSame(currentDayDate, 'date');
    });
  }
  private mapGridEntries(dayData: DayDataDto | undefined): IGridEntry[] {
    const gridEntries: IGridEntry[] = [];
    if (!dayData) {
      return gridEntries;
    }
    (dayData.gridEntries ?? []).forEach((gridEntryDto) => {
      const {
        type,
        ids,
        color,
        layoutStartPosition,
        layoutWidth,
        layoutGroup,
        statusDetail,
        link,
        durationTotal,
        moved,
        name,
      } = gridEntryDto;

      const adjustedGridEntryDuration = this.getAdjustedEntryDuration(gridEntryDto);
      const periodStartTime = adjustedGridEntryDuration.startDateTime;
      const periodEndTime = adjustedGridEntryDuration.endDateTime;

      if (
        this.timetableTimeStore.isTimeRangeBetweenTimeGridRange(periodStartTime, periodEndTime) &&
        this.shouldHandleGridEntry(gridEntryDto)
      ) {
        const parallelGroupId = layoutGroup ?? 0;
        const top = this.timetableGridDimensionsStore.offsetFromStartTime(periodStartTime);
        const height = this.timetableGridDimensionsStore.durationHeight(periodStartTime, periodEndTime);
        const widthPercentage = (layoutWidth ?? 100) / 10;
        const leftPercentage = (layoutStartPosition ?? 0) / 10;

        const lessonStatus = this.commonTimetableDataMapper.mapPeriodStatus(gridEntryDto);
        const isTentative = statusDetail === GridEntryStatusDetailEnum.TENTATIVE;
        const lessonType = this.commonTimetableDataMapper.mapGridEntryTypeEnum(type);
        const isCancelled = lessonStatus === LessonCardStatus.CANCELLED || lessonStatus === LessonCardStatus.MOVED_AWAY;
        const isExam = lessonType === LessonCardType.EXAM;
        const hasPeriodChange =
          lessonStatus === LessonCardStatus.MOVED_HERE || lessonStatus === LessonCardStatus.CHANGED;
        const isAdditional = lessonStatus === LessonCardStatus.ADDITIONAL;
        const hasChange = hasPeriodChange || isAdditional;
        const isExternal = lessonType === LessonCardType.EXTERNAL_CALENDAR_EVENT;

        const lessonCardProps: ILessonCardProps = {
          periodIds: ids,
          status: lessonStatus,
          colorBar: {
            color: color?.startsWith('#') ? color : `#${color ?? ''}`,
            isStriped: isTentative,
          },
          type: lessonType,
          rows: [],
          startDateTime: periodStartTime,
          endDateTime: periodEndTime,
          periodMove: moved,
          calendarName: name,
        };

        const gridEntry: IGridEntry = {
          periodIds: ids,
          parallelGroupId: parallelGroupId,
          top: top,
          height: height,
          widthPercentage: widthPercentage,
          leftPercentage: leftPercentage,
          lessonCardProps: lessonCardProps,
          durationTotal: durationTotal,
          link: link,
          isExam: isExam,
          isCancelled: isCancelled,
          hasChange: hasChange,
          isExternal: isExternal,
        };

        this.timetablePeriodEnrichers.forEach((enricher) => {
          if (enricher.supportsGridEntry(gridEntryDto)) {
            enricher.enrichPeriod(gridEntry, gridEntryDto);
          }
        });

        gridEntries.push(gridEntry);
      }
    });

    return gridEntries;
  }

  private mapDayEntries(dayData: DayDataDto | undefined): IFullDayEvent[] {
    const fullDayEvents: IFullDayEvent[] = [];
    if (!dayData) {
      return fullDayEvents;
    }
    (dayData.dayEntries ?? []).forEach((dayEntryDto) => {
      const { id, type, color, duration, status, statusDetail, notesAll, link, durationTotal, name } = dayEntryDto;

      const entryStartTime = dayjs(duration?.start);
      const entryEndTime = dayjs(duration?.end);
      const isTentative = statusDetail === DayEntryStatusDetailEnum.TENTATIVE;

      if (this.shouldHandleDayEntry(dayEntryDto)) {
        const fullDayEvent: IFullDayEvent = {
          id: id ?? 0,
          type: type,
          status: status,
          isTentative: isTentative,
          title: '',
          color: color?.startsWith('#') ? color : `#${color ?? ''}`,
          description: notesAll,
          externalLink: link,
          startDateTime: entryStartTime,
          endDateTime: entryEndTime,
          durationTotal: durationTotal,
          calendarName: name,
        };

        this.timetablePeriodEnrichers.forEach((enricher) => {
          if (enricher.supportsDayEntry(dayEntryDto)) {
            enricher.enrichFullDayEvent(fullDayEvent, dayEntryDto);
          }
        });

        fullDayEvents.push(fullDayEvent);
      }
    });

    return fullDayEvents;
  }

  private mapBackEntries(dayData: DayDataDto | undefined): ITimetableLayer[] {
    const layers: ITimetableLayer[] = [];
    if (dayData) {
      dayData.backEntries?.forEach((backEntryDto) => {
        const adjustedGridEntryDuration = this.getAdjustedEntryDuration(backEntryDto);
        const backEntryStartTime = adjustedGridEntryDuration.startDateTime;
        const backEntryEndTime = adjustedGridEntryDuration.endDateTime;

        if (
          this.timetableTimeStore.isTimeRangeBetweenTimeGridRange(backEntryStartTime, backEntryEndTime) &&
          this.shouldHandleBackEntry(backEntryDto)
        ) {
          const { id, type, layoutStartPosition, layoutWidth } = backEntryDto;
          const top = this.timetableGridDimensionsStore.offsetFromStartTime(backEntryStartTime);
          const height = this.timetableGridDimensionsStore.durationHeight(backEntryStartTime, backEntryEndTime);
          const widthPercentage = (layoutWidth ?? 100) / 10;
          const leftPercentage = (layoutStartPosition ?? 0) / 10;
          let textDate: string | undefined = undefined;

          if (type === BackEntryTypeEnum.HOLIDAY) {
            const holidayStart = dayjs(backEntryDto.durationTotal?.start);
            const holidayEnd = dayjs(backEntryDto.durationTotal?.end);
            textDate =
              holidayEnd.diff(holidayStart, 'day') !== 0 ? formatDateRange(holidayStart, holidayEnd) : undefined;
          }

          layers.push({
            id: id ?? 0,
            type,
            top,
            height,
            leftPercentage,
            widthPercentage,
            text: backEntryDto.shortName,
            textDate,
            bookable: backEntryDto.statusDetail === BackEntryStatusDetailEnum.BOOKABLE,
          });
        }
      });
    }

    return layers;
  }

  private getAdjustedEntryDuration(gridEntry: GridEntryDto | BackEntryDto): IGridEntryDuration {
    const { duration } = gridEntry;
    const startDateTime = dayjs(duration.start);
    const endDateTime = dayjs(duration.end);
    const { timeGridStartTime, timeGridEndTime } = this.timetableGridSlotsStore;
    const currentTimeGridStartTime = startDateTime
      .clone()
      .set('hour', timeGridStartTime.hour())
      .set('minute', timeGridStartTime.minute());
    const currentTimeGridEndTime = startDateTime
      .clone()
      .set('hour', timeGridEndTime.hour())
      .set('minute', timeGridEndTime.minute());

    const result: IGridEntryDuration = {
      startDateTime,
      endDateTime,
    };

    const fullyOverlaps =
      startDateTime.isBefore(currentTimeGridStartTime, 'minutes') &&
      endDateTime.isAfter(currentTimeGridEndTime, 'minutes');
    const startsBefore =
      startDateTime.isBefore(currentTimeGridStartTime, 'minutes') &&
      endDateTime.isAfter(currentTimeGridStartTime, 'minutes');
    const endsAfter =
      startDateTime.isBefore(currentTimeGridEndTime, 'minutes') &&
      endDateTime.isAfter(currentTimeGridEndTime, 'minutes');

    if (fullyOverlaps) {
      result.startDateTime = currentTimeGridStartTime;
      result.endDateTime = currentTimeGridEndTime;
    } else if (startsBefore) {
      result.startDateTime = currentTimeGridStartTime;
    } else if (endsAfter) {
      result.endDateTime = currentTimeGridEndTime;
    }

    return result;
  }

  private shouldHandleGridEntry(gridEntryDto: GridEntryDto): boolean {
    return this.timetablePeriodEnrichers.some((enricher) => enricher.supportsGridEntry(gridEntryDto));
  }

  private shouldHandleDayEntry(dayEntryDto: DayEntryDto): boolean {
    return this.timetablePeriodEnrichers.some((enricher) => enricher.supportsDayEntry(dayEntryDto));
  }

  private shouldHandleBackEntry(backEntryDto: BackEntryDto): boolean {
    return this.timetablePeriodEnrichers.some((enricher) => enricher.supportsBackEntry(backEntryDto));
  }

  @action
  setTimetableEntries(timetableEntries: TimetableEntriesDto | undefined) {
    this._timeTableEntries = timetableEntries;
  }
}
