import { action, computed, observable } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';

import { TimegridViewApi } from '@/stores/api-store';
import LocaleStore from '@/stores/locale-store';
import { inject, Store } from '@/types/store';
import { ITimeRangePickerTimeSlot } from '@/ui-components/time-range-picker/time-range-picker-store';
import { TimegridUnitViewDto, TimegridViewDto } from '@untis/wu-rest-view-api';

@Store()
export class TimegridStore {
  localeStore: LocaleStore = inject(LocaleStore);

  @observable private timeGridData: TimegridViewDto | undefined;

  @action async fetchTimegridForCurrentSchoolyear() {
    const timeGridData = await TimegridViewApi.getTimegrid();
    this.timeGridData = timeGridData.data;
  }

  /**
   * Returns the max number of units that are defined for a day within the timegrid
   */
  @computed get timegridUnits(): Array<TimegridUnitViewDto> | undefined {
    return this.timeGridData?.units;
  }

  // e.g. turns '800' to '0800'
  private prefixZero = (value: string): string => {
    if (value.length === 3) {
      value = '0' + value;
    }
    return value;
  };

  private createDayjsFromTime = (hours: number, minutes: number): Dayjs => {
    let mom = dayjs();
    mom = mom.set('hour', hours);
    mom = mom.set('minute', minutes);
    mom = mom.set('second', 0);
    mom = mom.set('milliseconds', 0);
    return mom;
  };

  @computed
  get timeRangePickerTimeSlots(): ITimeRangePickerTimeSlot[] {
    if (this.timeGridData && this.timeGridData.units) {
      return this.timeGridData.units.map((unit) => {
        const prefixedStart: string = this.prefixZero(unit.startTime!.toString());
        const prefixedEnd: string = this.prefixZero(unit.endTime!.toString());

        const startHours: number = Number(prefixedStart.substr(0, 2));
        const startMinutes: number = Number(prefixedStart.substr(2, 2));
        const endHours: number = Number(prefixedEnd.substr(0, 2));
        const endMinutes: number = Number(prefixedEnd.substr(2, 2));

        const start = this.createDayjsFromTime(startHours, startMinutes);
        const end = this.createDayjsFromTime(endHours, endMinutes);

        const slot: ITimeRangePickerTimeSlot = {
          startTime: start,
          endTime: end,
          label: this.localeStore.toOrdinalNumber(unit.unitOfDay!),
        };
        return slot;
      });
    }
    return [];
  }

  getCurrentTimeSlot = (): ITimeRangePickerTimeSlot | undefined => {
    if (this.timeRangePickerTimeSlots.length > 0) {
      const now = dayjs();
      const firstSlot = this.timeRangePickerTimeSlots[0];
      const lastSlot = this.timeRangePickerTimeSlots[this.timeRangePickerTimeSlots.length - 1];

      let currentSlot = undefined;

      if (now.isBefore(firstSlot.startTime)) {
        currentSlot = firstSlot;
      } else if (now.isAfter(lastSlot.endTime)) {
        currentSlot = lastSlot;
      } else {
        this.timeRangePickerTimeSlots.forEach((slot) => {
          if (now.isAfter(slot.startTime) && now.isBefore(slot.endTime)) {
            currentSlot = slot;
          }
        });
      }

      return currentSlot;
    }
  };

  @action.bound
  getTimegridSlotForDateTime(dateTime: Dayjs): ITimeRangePickerTimeSlot | undefined {
    const time = this.createDayjsFromTime(dateTime.hour(), dateTime.minute());
    return this.timeRangePickerTimeSlots.find((timeSlot) => {
      const { startTime, endTime } = timeSlot;
      const timeGridStartTime = this.createDayjsFromTime(startTime.hour(), startTime.minute());
      const timeGridEndTime = this.createDayjsFromTime(endTime.hour(), endTime.minute());
      return timeGridStartTime.isSameOrBefore(time) && timeGridEndTime.isSameOrAfter(time);
    });
  }
}
