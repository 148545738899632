import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { AutoGrowTextInput } from '@mg/components/auto-grow-text-input/auto-grow-text-input';
import { Button, IconButton, SearchField, Tag } from '@/ui-components';
import SendMessageViewStore from '@mg/stores/send-message-view-store';
import RecipientPickerStore from '@mg/stores/recipient-picker-store';
import useStore from '@/hooks/useStore';
import {
  MultiSelectOptionList,
  MultiSelectOptionListSkeleton,
  SingleSelectOptionList,
} from '@/ui-components/select-option-list/select-option-list';
import {
  MessageGroupDto,
  MessagePersonDto,
  MessagePersonSectionDtoType,
  SectionType,
  WuCurrentUserDtoRolesEnum,
} from '@untis/wu-rest-view-api';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import { IButtonProps } from '@/ui-components/button/button';
import { IconButtonProps } from '@/ui-components/icon-button/icon-button';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import './recipient-picker.less';
import ConfigStore from '@/stores/config-store';
import { FilterSkeleton } from '@/ui-components/filter-bar/deprecated-filter-bar';
import { TestIds } from '@/testIds';
import { FilterBar } from '@/ui-components/filter-bar/filter-bar';
import { ToggleFilter } from '@/ui-components/filter-bar/filter/toggle-filter';
import { MultiDropdownFilter } from '@/ui-components/filter-bar/filter/multi-dropdown-filter';

function getSectionTranslationKey(sectionType: SectionType): string {
  switch (sectionType) {
    case SectionType.CLASSTEACHER:
      return 'general.sendMessageMyClassesSection';
    case SectionType.NORMAL_LESSONS:
      return 'general.sendMessageNormalLessonsSection';
    case SectionType.SUBSTITUTIONS:
      return 'general.sendMessageSubstitutionSection';
    default:
      return '';
  }
}

function getTeacherSectionTranslationKey(
  roles: WuCurrentUserDtoRolesEnum[],
  sectionType?: MessagePersonSectionDtoType,
): string {
  switch (sectionType) {
    case MessagePersonSectionDtoType.CLASS_TEACHERS:
      return 'general.sendMessageMyClassesSection';
    case MessagePersonSectionDtoType.TEACHERS:
      return roles.includes(WuCurrentUserDtoRolesEnum.TEACHER)
        ? 'general.sendMessageNormalLessonsSection'
        : 'general.sendMessageMyTeachersSection';
    case MessagePersonSectionDtoType.OTHERS:
      return 'general.other';
    default:
      return '';
  }
}

interface IClassStudentCountProps {
  count: number;
}

function ClassStudentCount({ count }: IClassStudentCountProps) {
  return <div className="class-count">{count}</div>;
}

const ClassStudentRecipientView = observer(() => {
  const { t } = useTranslation();
  const recipientPickerStore = useStore(RecipientPickerStore);
  const { classStudentViewStore } = recipientPickerStore;

  return (
    <>
      <div className="recipient-search-container">
        <div className="recipient-search no-filters">
          <SearchField
            placeholder={recipientPickerStore.searchBarPlaceholder}
            value={recipientPickerStore.searchQuery}
            onChange={(query) => {
              recipientPickerStore.search(query);
            }}
            disabled={recipientPickerStore.isLoading}
            testId={TestIds.MESSAGES_RECIPIENT_PICKER_SEARCH_INPUT}
          />
        </div>
      </div>
      <div className="recipient-list-body">
        {classStudentViewStore.isLoading && <MultiSelectOptionListSkeleton amount={8} />}
        {classStudentViewStore.showPersonsList ? (
          <>
            {classStudentViewStore.isSearchLoading && <div className="search-loading">{t('general.loading')}</div>}
            {classStudentViewStore.isSearchEmpty && (
              <EmptyIndicator
                className="empty-indicator"
                title={t('general.sendCustomMessageNoResultsTitle')}
                description={t('general.sendCustomMessageNoResultsSubtitle')}
              />
            )}
            {classStudentViewStore.isSearchSuccess && (
              <MultiSelectOptionList
                name="persons"
                value={classStudentViewStore.selectedPersonsIds}
                options={classStudentViewStore.personsOptions}
              />
            )}
          </>
        ) : (
          classStudentViewStore.sections.map((section, index) => {
            return (
              <div className="class-section" key={`${section.sectionType}-${index}`}>
                <h2>{t(getSectionTranslationKey(section.sectionType))}</h2>
                <MultiSelectOptionList
                  name="class_teachers"
                  value={classStudentViewStore.selectedGroupIds}
                  options={classStudentViewStore.getOptionsByGroup(section.groups)}
                />
              </div>
            );
          })
        )}
      </div>
    </>
  );
});

const PersonRecipientView = observer(() => {
  const { t } = useTranslation();
  const recipientPickerStore = useStore(RecipientPickerStore);
  const configStore = useStore(ConfigStore);
  const { personViewStore } = recipientPickerStore;

  return (
    <>
      <div className="recipient-search-container">
        <div className="recipient-search no-filters">
          <SearchField
            placeholder={recipientPickerStore.searchBarPlaceholder}
            value={recipientPickerStore.searchQuery}
            onChange={(query) => {
              recipientPickerStore.search(query);
            }}
            disabled={recipientPickerStore.isLoading}
            testId={TestIds.MESSAGES_RECIPIENT_PICKER_SEARCH_INPUT}
          />
        </div>
      </div>
      <div className="recipient-list-body">
        {personViewStore.isLoading && <MultiSelectOptionListSkeleton amount={8} />}
        {personViewStore.isSuccess &&
          personViewStore.filteredPersonSections.map((section, index) => {
            return (
              <div className="class-section" key={`${section.type}-${index}`}>
                <h2>
                  {t(getTeacherSectionTranslationKey(configStore.userRolesEnum, section.type), {
                    count: section.type === MessagePersonSectionDtoType.OTHERS ? 2 : 1,
                  })}
                </h2>
                <SingleSelectOptionList
                  name="persons"
                  value={personViewStore._selectedPersonId}
                  options={personViewStore.getOptionsByPersons(section.persons)}
                  onChange={(value) => personViewStore.selectOrDeselectPersonById(Number(value))}
                />
              </div>
            );
          })}
        {personViewStore.isSuccess && personViewStore.filteredPersonSections.length <= 0 && (
          <EmptyIndicator
            className="empty-indicator"
            title={t('general.sendCustomMessageNoResultsTitle')}
            description={t('general.sendCustomMessageNoResultsSubtitle')}
          />
        )}
      </div>
    </>
  );
});

interface ICustomRecipientViewProps {
  variant: 'CUSTOM' | 'STAFF';
}

const CustomRecipientView = observer(({ variant }: ICustomRecipientViewProps) => {
  const { t } = useTranslation();
  const recipientPickerStore = useStore(RecipientPickerStore);
  const { customViewStore } = recipientPickerStore;

  const translations = React.useMemo(() => {
    switch (variant) {
      case 'STAFF':
        return {
          entry: {
            title: t('general.sendStaffMessageEntryTitle'),
            subtitle: t('general.sendStaffMessageEntrySubtitle'),
          },
          selectedEmpty: {
            title: t('general.sendStaffMessageNothingSelectedTitle'),
            subtitle: t('general.sendStaffMessageNothingSelectedSubtitle'),
          },
          resultsEmpty: {
            title: t('general.sendStaffMessageNoResultsTitle'),
            subtitle: t('general.sendCustomMessageNoResultsSubtitle'),
          },
        };
      case 'CUSTOM':
      default:
        return {
          entry: {
            title: t('general.sendCustomMessageEntryTitle'),
            subtitle: t('general.sendCustomMessageEntrySubtitle'),
          },
          selectedEmpty: {
            title: t('general.sendCustomMessageNothingSelectedTitle'),
            subtitle: t('general.sendCustomMessageNothingSelectedSubtitle'),
          },
          resultsEmpty: {
            title: t('general.sendCustomMessageNoResultsTitle'),
            subtitle: t('general.sendCustomMessageNoResultsSubtitle'),
          },
        };
    }
  }, [t, variant]);

  return (
    <>
      <div className="recipient-search-container">
        <div className="recipient-search">
          <SearchField
            placeholder={recipientPickerStore.searchBarPlaceholder}
            value={recipientPickerStore.searchQuery}
            onChange={(query) => {
              recipientPickerStore.search(query);
            }}
            disabled={recipientPickerStore.isLoading}
            testId={TestIds.MESSAGES_RECIPIENT_PICKER_SEARCH_INPUT}
          />
        </div>
        {customViewStore.showFilterBar && (
          <div className="recipient-filter">
            {customViewStore.isLoadingFilters ? (
              <FilterSkeleton />
            ) : (
              <FilterBar wrap>
                <ToggleFilter
                  label={t('general.selected')}
                  value={customViewStore.selectedFilter}
                  onChange={(value) => customViewStore.handleSelectedToggleChange(value)}
                />
                {customViewStore.showCustomListFilter && (
                  <MultiDropdownFilter
                    items={customViewStore.customListFilterItems}
                    value={customViewStore.customListFilter}
                    onChange={(values) => customViewStore.setCustomListFilter(values)}
                    placeholder={t('general.staticQuickFilter', { count: 1 })}
                    useToggleIfHasOnlyOneItem
                  />
                )}
                {customViewStore.showDynamicListFilter && (
                  <MultiDropdownFilter
                    items={customViewStore.dynamicListFilterItems}
                    value={customViewStore.dynamicListFilter}
                    onChange={(values) => customViewStore.setDynamicListFilter(values)}
                    placeholder={t('general.dynamicQuickFilter', { count: 1 })}
                    useToggleIfHasOnlyOneItem
                  />
                )}
                {customViewStore.showClassesFilter && (
                  <MultiDropdownFilter
                    items={customViewStore.classesFilterItems}
                    value={customViewStore.classesFilter}
                    onChange={(values) => customViewStore.setClassesFilter(values)}
                    placeholder={t('general.class')}
                    useToggleIfHasOnlyOneItem
                    testId="class"
                  />
                )}
                {customViewStore.showDepartmentsFilter && (
                  <MultiDropdownFilter
                    items={customViewStore.departmentsFilterItems}
                    value={customViewStore.departmentsFilter}
                    onChange={(values) => customViewStore.setDepartmentsFilter(values)}
                    placeholder={t('general.department')}
                    useToggleIfHasOnlyOneItem
                  />
                )}
                {customViewStore.showRolesFilter && (
                  <MultiDropdownFilter
                    items={customViewStore.rolesFilterItems}
                    value={customViewStore.rolesFilter}
                    onChange={(values) => customViewStore.setRolesFilter(values)}
                    placeholder={t('general.role')}
                    useToggleIfHasOnlyOneItem
                    testId="role"
                  />
                )}
                {customViewStore.showGroupsFilter && (
                  <MultiDropdownFilter
                    items={customViewStore.groupsFilterItems}
                    value={customViewStore.groupsFilter}
                    onChange={(values) => customViewStore.setGroupsFilter(values)}
                    placeholder={t('general.group')}
                    useToggleIfHasOnlyOneItem
                  />
                )}
              </FilterBar>
            )}
          </div>
        )}
      </div>
      <div
        className={clsx('recipient-list-body', {
          center: customViewStore.isFirstVisit || customViewStore.hasEmptyResults || customViewStore.isSelectedEmpty,
        })}
      >
        {customViewStore.isLoadingResults && <MultiSelectOptionListSkeleton amount={8} />}
        {!customViewStore.isLoadingFilters && customViewStore.isFirstVisit && (
          <EmptyIndicator
            className="empty-indicator"
            title={translations.entry.title}
            description={translations.entry.subtitle}
          />
        )}
        {customViewStore.isSelectedEmpty && (
          <EmptyIndicator
            className="empty-indicator"
            title={translations.selectedEmpty.title}
            description={translations.selectedEmpty.subtitle}
          />
        )}
        {customViewStore.hasEmptyResults && (
          <EmptyIndicator
            className="empty-indicator"
            title={translations.resultsEmpty.title}
            description={translations.resultsEmpty.subtitle}
          />
        )}
        {customViewStore.hasResults && (
          <MultiSelectOptionList
            name="persons"
            value={customViewStore.selectedPersonsIds}
            options={customViewStore.personsOptions}
            onSelectAll={customViewStore.handleSelectAll}
          />
        )}
      </div>
    </>
  );
});

type IRecipientPickerViewProps = {
  tag?: string;
  mainActions: IButtonProps[];
  secondaryIconActions?: IconButtonProps[];
};

type IRecipientPickerEditProps = {
  tag?: string;
  mainActions: IButtonProps[];
  secondaryIconActions?: IconButtonProps[];
};

export type MessageRecipient =
  | {
      type: 'group';
      group: MessageGroupDto;
    }
  | {
      type: 'person';
      person: MessagePersonDto;
    };

export type IRecipientPickerProps = IRecipientPickerViewProps | IRecipientPickerEditProps;

const RecipientPicker = observer((props: IRecipientPickerProps) => {
  const { t } = useTranslation();
  const sendMessageViewStore = useStore(SendMessageViewStore);
  const recipientPickerStore = useStore(RecipientPickerStore);

  useComponentDidMount(() => {
    if (sendMessageViewStore.canSendMessages && !recipientPickerStore.isEditMode) {
      recipientPickerStore.getRecipients();
    }
  });

  return (
    <div className="recipient-picker">
      <div className="modal-title-container">
        {!recipientPickerStore.isEditMode && (
          <h1 className="modal-title">
            {recipientPickerStore.name !== '' ? recipientPickerStore.name : t('general.sendMessageRecipients')}
          </h1>
        )}
        {recipientPickerStore.isEditMode && (
          <div className="modal-title">
            <AutoGrowTextInput
              maxLength={50}
              autoFocus
              value={recipientPickerStore.name}
              onChange={recipientPickerStore.onNameChange}
              placeholder={recipientPickerStore.isLoading ? `${t('general.loading')}` : ''}
            />
          </div>
        )}
        {props.tag && (
          <Tag className="modal-title-tag" color="grey">
            {props.tag}
          </Tag>
        )}
      </div>
      <div className="recipient-list-container">
        {recipientPickerStore.showClassAndStudentsList && <ClassStudentRecipientView />}
        {recipientPickerStore.showTeachersList && <PersonRecipientView />}
        {recipientPickerStore.showCustomList && <CustomRecipientView variant="CUSTOM" />}
        {recipientPickerStore.showStaffList && <CustomRecipientView variant="STAFF" />}
        {recipientPickerStore.hasError && (
          <div className="recipient-list-body error">{t('general.sendMessageRecipientsLoadingError')}</div>
        )}
      </div>
      <div className="actions with-border">
        <div className="actions__secondary">
          {props.secondaryIconActions?.map((action, i) => (
            <IconButton key={i} {...action} />
          ))}

          {recipientPickerStore.selectedCount > 0 && (
            <span className="selected-count">
              {t('general.sendMessagePersonSelected', { count: recipientPickerStore.selectedCount })}
            </span>
          )}
        </div>
        <div className="actions__main">
          {props.mainActions?.map((action, i) => {
            return <Button key={i} {...action}></Button>;
          })}
        </div>
      </div>
    </div>
  );
});

export { RecipientPicker, ClassStudentCount };
