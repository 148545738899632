import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { WUForm, WUTable } from '@/ui-components';
import { FormSection, IFormButton, WUFormCol, WUFormRow } from '@/ui-components/wu-form';
import ModalStore from '@/stores/modal-store';
import useStore from '@/hooks/useStore';
import { GradingFormStore, IExamStudentGradeRow, IGradeStatisticsRow } from '@ls/exams/grading-form/grading-form-store';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';

import './grading-form.less';

interface IProps {
  store: GradingFormStore;
}

export const GradingForm = observer((props: IProps) => {
  const modalStore = useStore(ModalStore);
  const { t } = useTranslation();

  const handleSubmit = () => {
    props.store.saveExamGrades();
    modalStore.closeModal();
  };

  const rightButtons: IFormButton[] = [
    {
      label: t('general.cancel'),
      onClick: () => {
        modalStore.closeModal();
      },
    },
  ];

  if (props.store.isLoading) {
    return <EmptyIndicator title={t('general.loading')} fullScreen />;
  }

  return (
    <WUForm
      onSubmit={handleSubmit}
      rightButtons={rightButtons}
      maxWidth
      fixedButtonBar
      className="grading-form"
      enableSubmitButton={props.store.isEdited}
      hideTopBorder
    >
      {!props.store.isLoading && (
        <WUFormCol lg={1} removePadding>
          {props.store.canReadGrades && (
            <WUFormRow>
              <WUFormCol lg={3}>
                <FormSection
                  centered
                  text={t('lessons.exams.grades.enterGrades')}
                  description={t('general.enterGradesPerStudent')}
                />
              </WUFormCol>
              <WUFormCol lg={1.5}>
                <WUTable<IExamStudentGradeRow>
                  columns={props.store.examStudentGradesColumns}
                  rowData={props.store.examStudentGradesRows}
                />
              </WUFormCol>
            </WUFormRow>
          )}

          {props.store.canReadStatistics && (
            <WUFormRow>
              <WUFormCol lg={3}>
                <FormSection
                  centered
                  text={t('general.gradesStatistics')}
                  description={t('general.gradesStatisticsDescription')}
                />
              </WUFormCol>
              <WUFormCol lg={1.5}>
                <WUTable<IGradeStatisticsRow>
                  columns={props.store.examStatisticsColumns}
                  rowData={
                    props.store.useComputedGradeStatistics
                      ? props.store.computedGradeStatisticsRows
                      : props.store.customGradeStatisticRows
                  }
                />
                <div className="exam-grade-statistics-derived-values">
                  <div className="exam-grade-statistics-average-grade">
                    <label>{t('lessons.exams.grades.averageGrade')}: </label>
                    <span>{props.store.averageGrade}</span>
                  </div>
                  <div className="exam-grade-statistics-sum">
                    <label>{t('general.sum')}: </label>
                    <span>
                      {props.store.totalNumberOfGrades} ({props.store.totalNumberOfParticipants})
                    </span>
                  </div>
                </div>
              </WUFormCol>
            </WUFormRow>
          )}
        </WUFormCol>
      )}
    </WUForm>
  );
});
