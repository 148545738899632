import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './platform-detail-info-dialog.less';
import { TestIds } from '@/testIds';

const PlatformDetailInfoShortDisclaimer = observer(() => {
  const { t } = useTranslation();

  return (
    <div data-testid={TestIds.PLATFORM_APPLICATION_SHORT_DISCLAIMER_SECTION}>
      {t('general.platformDataProtectionNoticeShortIntro')}
      {t('general.platformDataProtectionNoticeRiskForSchool')}
    </div>
  );
});

export default PlatformDetailInfoShortDisclaimer;
