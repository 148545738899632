import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

import { useRenderLegacyPage } from '@/hooks/useRenderLegacyPage';
import ConfigStore from '@/stores/config-store';
import useStore from '@/hooks/useStore';

const ParentsTeacherDay = () => {
  const configStore = useStore(ConfigStore);
  const params = useParams<{ id?: string }>();

  const parentTeacherDayUrl = useMemo(
    () => (configStore.isTeacher ? `parentsdayteacher?selId=${params.id}` : `parentsday/parentview?selId=${params.id}`),
    [configStore.isTeacher, params.id],
  );

  useRenderLegacyPage({ url: parentTeacherDayUrl });

  return null;
};

export default ParentsTeacherDay;
