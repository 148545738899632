import { observable, action, computed } from 'mobx';
import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';

import { Store } from '@/types/store';
import { ErrorMessageEnum, showError } from '@/utils/error-handling/error-message';
import { IncomingMessageRefDto, SentMessageRefDto, DraftMessageRefDtoV2 } from '@untis/wu-rest-view-api';

type IMessageRefDto = IncomingMessageRefDto | SentMessageRefDto | DraftMessageRefDtoV2;

type LoadingState = 'LOADING' | 'LOADING_SUCCESS';
type State =
  | LoadingState
  | 'SEARCHING'
  | 'SEARCHING_SUCCESS'
  | 'SEARCHING_NOT_ENOUGH_CHARACTERS'
  | 'ERROR'
  | 'NO_RESULTS'
  | 'NO_SEARCH_RESULTS';

@Store()
export class BaseMessagesStore<T extends IMessageRefDto> {
  @observable state: State = 'LOADING';
  @observable loadingState: LoadingState = 'LOADING_SUCCESS';
  @observable searchText = '';
  @observable initialMessages: T[] = [];
  @observable searchedMessages: T[] = [];
  initialSource: CancelTokenSource = axios.CancelToken.source();

  @observable
  private _minSearchCharacterLength: number;

  constructor(_typediParams: any, minSearchCharacterLength = 3) {
    this._minSearchCharacterLength = minSearchCharacterLength;
  }

  @action
  async fetchMessages<R extends { [key: string]: T[] }>(
    apiCall: (searchText?: string, options?: AxiosRequestConfig) => Promise<AxiosResponse<R>>,
    key: keyof R,
  ) {
    this.initialSource.cancel();
    this.initialSource = axios.CancelToken.source();

    if (this.searchText.length > 0 && this.searchText.length < this.minSearchCharacterLength) {
      this.setState('SEARCHING_NOT_ENOUGH_CHARACTERS');
      this.resetSearchedMessages();
      return;
    }

    this.setLoadingStateDelayed(this.hasSearchText ? 'SEARCHING' : 'LOADING');

    try {
      const response = await apiCall(this.hasSearchText ? this.searchText : undefined, {
        cancelToken: this.initialSource.token,
      });
      const messages = response.data[key];

      if (this.hasSearchText) {
        this.searchedMessages = messages;
      } else {
        this.initialMessages = messages;
      }

      const externalDataLength = this.handleFetchResponse(response);

      if (messages.length === 0 && externalDataLength === 0) {
        this.setState(this.hasSearchText ? 'NO_SEARCH_RESULTS' : 'NO_RESULTS');
      } else {
        this.setState(this.hasSearchText ? 'SEARCHING_SUCCESS' : 'LOADING_SUCCESS');
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        //
      } else {
        console.error(error);
        showError(ErrorMessageEnum.ERROR);

        if (this.hasSearchText) {
          this.searchedMessages = [];
        } else {
          this.initialMessages = [];
        }

        this.setState('ERROR');

        throw error;
      }
    }
  }

  /**
   * Handles additional actions upon receiving the response from the fetchMessages method.
   * This method is intended to be empty by default and can be overridden in subclasses if necessary.
   * @param _response - The response object from the fetchMessages method.
   */
  @action
  protected handleFetchResponse(_response: AxiosResponse<any>): number {
    return 0;
  }

  @action
  protected resetSearchedMessages() {
    this.searchedMessages = [];
  }

  @action
  setLoadingStateDelayed(state: State) {
    this.loadingState = 'LOADING';
    setTimeout(() => {
      if (this.loadingState === 'LOADING') {
        this.setState(state);
      }
    }, 250);
  }

  @action
  setState(state: State) {
    this.state = state;

    if (['NO_SEARCH_RESULTS', 'SEARCHING_SUCCESS', 'NO_RESULTS', 'LOADING_SUCCESS'].includes(this.state)) {
      this.loadingState = 'LOADING_SUCCESS';
    }
  }

  @action.bound
  handleSearchTextChange(searchText: string) {
    this.searchText = searchText.trimStart();
  }

  @action
  addMessage(message: T) {
    this.initialMessages.unshift(message);
  }

  @action
  updateMessage(messageId: number, data: Partial<T>) {
    const messageIndex = this.initialMessages.findIndex((message) => message.id === messageId);
    if (messageIndex > -1) {
      const message = this.initialMessages[messageIndex];
      this.initialMessages[messageIndex] = {
        ...message,
        ...data,
      };
    }
  }

  @action
  deleteMessage(messageId: number) {
    this.deleteMessages([messageId]);
  }

  @action
  deleteMessages(messageIds: number[]) {
    if (this.hasActiveSearch) {
      this.searchedMessages = this.searchedMessages.filter((message) => !messageIds.includes(message.id));

      if (this.searchedMessages.length === 0) {
        this.setState('NO_SEARCH_RESULTS');
      }

      return;
    }

    this.initialMessages = this.initialMessages.filter((message) => !messageIds.includes(message.id));
    if (this.initialMessages.length === 0) {
      this.setState('NO_RESULTS');
    }
  }

  get minSearchCharacterLength(): number {
    return this._minSearchCharacterLength;
  }

  @computed
  get hasSearchText() {
    return this.searchText.length > 0;
  }

  @computed
  get messages() {
    if (this.hasActiveSearch) {
      return this.searchedMessages;
    }

    return this.initialMessages;
  }

  @computed
  get hasMessages() {
    return this.state === 'LOADING_SUCCESS';
  }

  @computed
  get hasResults() {
    return this.state === 'LOADING_SUCCESS' || this.state === 'SEARCHING_SUCCESS';
  }

  @computed
  get showHasNotEnoughCharacters() {
    return this.state === 'SEARCHING_NOT_ENOUGH_CHARACTERS';
  }

  @computed
  get noResults() {
    return this.state === 'NO_RESULTS';
  }

  @computed
  get noSearchResults() {
    return this.state === 'NO_SEARCH_RESULTS';
  }

  @computed
  get isLoading() {
    return this.state === 'LOADING' || this.state === 'SEARCHING';
  }

  @computed
  get isInitalLoading() {
    return this.state === 'LOADING';
  }

  @computed
  get isSearching() {
    return this.state === 'SEARCHING';
  }

  @computed
  get hasActiveSearch() {
    return (
      ['NO_SEARCH_RESULTS', 'SEARCHING_SUCCESS', 'SEARCHING'].includes(this.state) ||
      this.searchText.length >= this.minSearchCharacterLength
    );
  }
}
