enum KeyCodes {
  // if you specify "Other" KeyCode as Shortcut Trigger, it will act like a "switch default" branch in your listener
  Other = -1,

  Tab = 9,
  Enter = 13,
  Escape = 27,
  ARROW_UP = 38,
  ARROW_DOWN = 40,
  E = 69,
  K = 75,
  N = 78,
  P = 80,
  U = 85,
}

export default KeyCodes;
