export default [
  '#FFA94D',
  '#FFD43B',
  '#9CCB61',
  '#36C98C',
  '#36BAC9',
  '#4B9FD8',
  '#4E9ABF',
  '#837BE4',
  '#D47AEB',
  '#F781AA',
  '#FF8787',
  '#B27F61',
  '#67747E',
  '#FFC17E',
  '#FFE071',
  '#B7D98D',
  '#6ED8AC',
  '#6ECDD8',
  '#6EADD8',
  '#7FB6D1',
  '#A5A0EB',
  '#E09FF0',
  '#FFA8A8',
  '#FFABAB',
  '#C7A38D',
  '#919BA2',
  '#FFD6AA',
  '#FFEBA1',
  '#D0E7B4',
  '#9FE5C8',
  '#9FDEE5',
  '#9FC9E5',
  '#ABCFE1',
  '#C4C0F2',
  '#EBC0F6',
  '#FCC3D7',
  '#FFC6C6',
  '#DBC2B3',
  '#B6BDC1',
  '#FFE3C6',
  '#FFF1C0',
  '#DFEECC',
  '#BEEDDA',
  '#BEE9ED',
  '#BEDAED',
  '#C6DEEA',
  '#D7D4F6',
  '#F1D4F8',
  '#FCD6E3',
  '#FFD8D8',
  '#E6D6CC',
  '#CED2D5',
];
