import { matchPath } from 'react-router-dom';

import {
  IPeriodIdData,
  ITimeSlotArguments,
} from '@/pages/calendar-entry/calendar-entry-detail-view/calendar-entry-detail-view';

const MODAL_SUB_PATH = '/modal';

export class ModalRoute<T> {
  private readonly parentPath: string;
  private readonly subPath: string;
  private readonly params: Array<keyof T>;

  constructor(args: { parentPath: string; subPath: string; params: Array<keyof T> }) {
    this.parentPath = args.parentPath;
    this.subPath = args.subPath;
    this.params = args.params;
  }

  /**
   * Returns the path (template) for the particular modal route (with the all unsubstituted parameter names)
   */
  getRoutePath(): string {
    return this.parentPath + this.getModalSubRoutePath();
  }

  /**
   * Returns full path with all parameter keys substituted by the values retrieved from the payload.
   * If modal route pathname cannot be built (parent path doesn't match or param is missing) then null is returned back.
   * @param currentLocationPath
   * @param paramValues key-value parameters object
   */
  getPathname(currentLocationPath: string, paramValues: { [param in keyof T]: string }): string | null {
    if (
      !matchPath(currentLocationPath, { path: this.parentPath, exact: false }) ||
      this.params.find((p) => paramValues[p] === undefined || paramValues[p] === null || paramValues[p] === '')
    ) {
      return null;
    }
    return this.params.reduce(
      (result: string, param: keyof T) => result + '/' + encodeURIComponent(paramValues[param]),
      this.removeModalSubPath(currentLocationPath) + MODAL_SUB_PATH + this.subPath,
    );
  }

  removeModalSubPath(currentRoute: string): string {
    const endIndex = currentRoute.indexOf(MODAL_SUB_PATH + this.subPath);
    return endIndex > -1 ? currentRoute.substring(0, endIndex) : currentRoute;
  }

  private getModalSubRoutePath(): string {
    return this.params.reduce((result, param) => result + '/:' + param.toString(), MODAL_SUB_PATH + this.subPath);
  }
}

interface ICommonPeriodDetailArgs {
  tab: string;
}

// Remove this parent path restriction (replace with *) once the particular modal dialogs can be open as routes only
// @see useModalDelegate.tsx
const PERIOD_DETAILS_PARENT_PATH =
  '/timetable-:v(classes|teachers|rooms|subjects|students|resources|classes-my|teachers-my|students-my' +
  '|students-parent|classes-parent)/*';

/**
 * Returns currentRoute without all modal sub-paths children.
 * @param currentRoute
 */
export const removeAllModalSubPaths = (currentRoute: string): string => {
  const modalIndex = currentRoute.indexOf(MODAL_SUB_PATH + '/');
  return modalIndex > -1 ? currentRoute.substring(0, modalIndex) : currentRoute;
};

/**
 * Definition of the modal dialog routes.
 * !!! Be careful when doing some route/params changes here!!!
 * There is ui2020RoutesBuilder.ts in the old frontend too because of the PoDS period details link navigation!
 */
export const modalRoutes = {
  periodDetailsRoute: new ModalRoute<ITimeSlotArguments & ICommonPeriodDetailArgs>({
    parentPath: PERIOD_DETAILS_PARENT_PATH,
    subPath: '/details',
    params: [
      'initialSelectedPeriodId',
      'initialIsBlockSelected',
      'elementId',
      'elementType',
      'startDateTime',
      'endDateTime',
      // tab param can be handled by nested route within calendar-entry-detail-view.tsx once this component
      // is used within Route boundaries only
      'tab',
    ],
  }),
  singlePeriodDetailsRoute: new ModalRoute<IPeriodIdData & ICommonPeriodDetailArgs>({
    parentPath: PERIOD_DETAILS_PARENT_PATH,
    subPath: '/spdetails',
    params: ['periodId', 'tab'],
  }),
};
