import { Popover } from 'antd';
import React, { ReactNode, useState } from 'react';

import './text-popover.less';
import { IconButton } from '@/ui-components';
import { textTodo } from '@/utils/text-todo/text-todo';

interface IProps {
  value: string[];
  maxWidth?: number;
  marginLeft?: number;
}

const TextPopover = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (value: boolean) => {
    setIsOpen(value);
  };

  const handleClick = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    handleOpenChange(false);
  };

  const content: ReactNode[] = [];

  props.value.forEach((str, i) => {
    content.push(<p>{str}</p>);
    if (i < props.value.length - 1) {
      content.push(<hr />);
    }
  });

  return (
    <Popover
      trigger="click"
      placement="bottom"
      className="text-popover"
      overlayClassName="text-popover-overlay"
      onVisibleChange={handleOpenChange}
      visible={isOpen}
      content={
        <div className="text-popover-content">
          {content}
          <div className="close-popover">
            <IconButton onClick={handleClick} ariaLabel={textTodo('close popover')} type="cancel-gray" />
          </div>
        </div>
      }
    >
      <span style={{ maxWidth: props.maxWidth, marginLeft: props.marginLeft }} className="child">
        {props.value.join(' ')}
      </span>
    </Popover>
  );
};

export default TextPopover;
