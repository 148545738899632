import React from 'react';
import clsx from 'clsx';

import './lesson-card-text-content.less';

interface IProps {
  texts: string[];
  previewOnly?: boolean;
  marginTop?: boolean;
  isTopElement?: boolean;
}

const LessonCardTextContent = ({ texts, previewOnly, marginTop, isTopElement }: IProps) => {
  const renderText = (text: string) => {
    if (previewOnly) {
      let previewText = text
        ?.trim()
        ?.replaceAll('\n', ' ')
        ?.replace(/ +(?= )/g, '');
      if (previewText && previewText.length > 50) {
        previewText = `${previewText.substring(0, 50)}...`;
      }
      return previewText;
    } else {
      return text;
    }
  };

  return (
    <div
      className={clsx(`lesson-card-text-content-container`, {
        'lesson-card-top-element': isTopElement,
        'margin-top': marginTop,
      })}
      data-testid="lesson-card-text-content-container"
    >
      <span
        className={clsx('lesson-card-text-content', { 'lesson-card-top-element': isTopElement })}
        data-testid="lesson-card-text-content"
      >
        {renderText(texts.join(', '))}
      </span>
    </div>
  );
};

export default LessonCardTextContent;
