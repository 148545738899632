import React, { HTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';
import { ITestComponentProps } from '@/types/test-component-props';

import './button.less';

declare const ButtonTypes: [
  'primary',
  'primary-light',
  'success',
  'secondary',
  'success-light',
  'yellow-light',
  'blue-light',
  'red-light',
  'destructive',
];
export declare type ButtonType = (typeof ButtonTypes)[number];

export interface IButtonProps extends HTMLAttributes<HTMLButtonElement>, ITestComponentProps {
  disabled?: boolean;
  circle?: boolean;
  outline?: boolean;
  className?: string;
  size?: 'xSmall' | 'small' | 'large';
  type?: ButtonType;
  dropshadow?: boolean;
  block?: boolean;
  uppercase?: boolean;
  children?: any;
  tag?: string | number;
  loading?: boolean;
  onClick?: (e: React.BaseSyntheticEvent) => void;
  triggersSubmit?: boolean;
  autoFocus?: boolean;
  unstyled?: boolean;
  href?: string;
}

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, IButtonProps>((props, ref) => {
  const {
    dropshadow,
    unstyled,
    circle,
    outline,
    block,
    uppercase,
    size,
    type,
    className,
    tag,
    loading,
    children,
    triggersSubmit,
    testId,
    href,
    ...other
  } = props;

  const buttonClassName = unstyled
    ? 'untis-button--unstyled'
    : clsx(
        'untis-button',
        {
          'untis-button--secondary': !Boolean(type),
          'untis-button--shadow': dropshadow,
          'untis-button--circle': circle,
          'untis-button--outline': outline,
          'untis-button--block': block,
          'untis-button--uppercase': uppercase,
          [`untis-button--${size}`]: Boolean(size),
          [`untis-button--${type}`]: Boolean(type),
          'untis-button--tag': tag,
          'untis-button--disabled': props.disabled,
        },
        className,
      );

  if (href) {
    return (
      <Link
        to={href}
        className={buttonClassName}
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        data-testid={testId}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      {...other}
      className={buttonClassName}
      ref={ref as React.ForwardedRef<HTMLButtonElement>}
      type={triggersSubmit ? 'submit' : 'button'}
      data-testid={testId}
    >
      {children}
      {tag !== undefined && !loading && <div className="tag">{tag}</div>}
      {loading && <LoadingOutlined className="loading" translate="no" />}
    </button>
  );
});

export default Button;
