import { action, computed, observable } from 'mobx';
import { FormInstance } from 'antd';
import { isNil } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';

import { ISelectItem } from '@/ui-components/select/select';
import { ICalSettingsViewApi } from '@/stores/api-store';
import { ICalFormatRefDto, ICalSubscriptionDto } from '@untis/wu-rest-view-api/api';
import { inject } from '@/types/store';
import ModalStore from '@/stores/modal-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { copyTextToClipboard } from '@/utils/clipboard/clipboard';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import { TimetableFilterStore } from '@te/standard/stores/filter/timetable-filter-store';

export interface ICalSubscriptionFormData {
  format: string;
}

export interface ICalSubscription {
  url: string | undefined;
  createdAt: string | undefined;
}

const STANDARD_FORMAT_ID = 0;

export default class ICalSubscriptionStore {
  @observable private _isLoading = true;
  @observable private _formInstance: FormInstance<ICalSubscriptionFormData> | undefined;
  @observable private _iCalSubscription: ICalSubscription | undefined;
  @observable private _iCalFormats: ICalFormatRefDto[] = [];
  @observable private _initialFormat: number = 0;

  private modalStore = inject(ModalStore);
  private notificationStore = inject(NotificationStore);
  private timetableFormatStore = inject(TimetableFormatStore);
  private timetableFilterStore = inject(TimetableFilterStore);

  constructor() {
    this.init();
  }

  @action
  private async init() {
    try {
      const { resourceTypeEnum } = this.timetableFormatStore;
      const { selectedFilterValue } = this.timetableFilterStore;
      const subscription = await ICalSettingsViewApi.getSubscription(resourceTypeEnum, selectedFilterValue ?? 0);
      this.setICalSubscription(subscription.data);
      this.initFormats(subscription.data);
    } catch (error) {
      this.modalStore.closeModal();
      this.notificationStore.error({
        title: t('general.error'),
        message: t('general.errors.unexpectedError'),
      });
    } finally {
      this._isLoading = false;
    }
  }

  @action
  private setICalSubscription(data: ICalSubscriptionDto) {
    this._iCalSubscription = {
      url: data.url,
      createdAt: data.created,
    };
  }

  @action
  private initFormats(data: ICalSubscriptionDto) {
    this._iCalFormats = data.formats ?? [];
    this._iCalFormats.push({ id: STANDARD_FORMAT_ID, name: t('general.standard') });
    this._initialFormat = data.selectedFormat ?? STANDARD_FORMAT_ID;
  }

  @action
  setForm(formInstance: FormInstance<ICalSubscriptionFormData>) {
    this._formInstance = formInstance;
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @computed
  get form(): FormInstance<ICalSubscriptionFormData> | undefined {
    return this._formInstance;
  }

  @computed
  get formatItems(): ISelectItem[] {
    return this._iCalFormats.map((format) => ({
      id: format.id.toString(),
      label: format.name,
    }));
  }

  @computed
  get initialFormat(): number {
    return this._initialFormat;
  }

  @computed
  get linkCreatedOn(): Dayjs | undefined {
    return this._iCalSubscription?.createdAt ? dayjs(this._iCalSubscription.createdAt) : undefined;
  }

  @computed
  get link(): string | undefined {
    return this._iCalSubscription?.url;
  }

  @computed
  get hasICalLink(): boolean {
    return !isNil(this._iCalSubscription?.url);
  }

  @action.bound
  async onFormatChange(formatId: string | undefined) {
    const id = Number(formatId);
    if (this.hasICalLink && !isNaN(id)) {
      try {
        const { resourceTypeEnum } = this.timetableFormatStore;
        const { selectedFilterValue } = this.timetableFilterStore;
        await ICalSettingsViewApi.changeSubscriptionFormat(resourceTypeEnum, selectedFilterValue ?? 0, id);
        this.notificationStore.success({ title: t('general.iCalFormatChanged'), placement: 'topRight' });
      } catch (error) {
        this.notificationStore.error({
          title: t('general.error'),
          message: t('general.errors.unexpectedError'),
        });
      }
    }
  }

  @action.bound
  async disableLink() {
    try {
      const { resourceTypeEnum } = this.timetableFormatStore;
      const { selectedFilterValue } = this.timetableFilterStore;
      await ICalSettingsViewApi.disableSubscription(resourceTypeEnum, selectedFilterValue ?? 0);
      this._iCalSubscription = undefined;
      this.notificationStore.success({ title: t('general.iCalLinkDisabled'), placement: 'topRight' });
    } catch (error) {
      this.notificationStore.error({
        title: t('general.error'),
        message: t('general.errors.unexpectedError'),
      });
    }
  }

  @action.bound
  async copyLink() {
    if (this.hasICalLink) {
      await copyTextToClipboard(this._iCalSubscription?.url ?? '');
      this.notificationStore.success({ title: t('general.iCalLinkCopiedToClipboard'), placement: 'topRight' });
    }
  }

  @action.bound
  async createLink(formData: ICalSubscriptionFormData) {
    try {
      const { resourceTypeEnum } = this.timetableFormatStore;
      const { selectedFilterValue } = this.timetableFilterStore;
      const id = Number(formData.format);
      if (!this.hasICalLink && !isNaN(id)) {
        await ICalSettingsViewApi.enableSubscription(resourceTypeEnum, selectedFilterValue ?? 0, id);
        const subscription = await ICalSettingsViewApi.getSubscription(resourceTypeEnum, selectedFilterValue ?? 0);
        this.setICalSubscription(subscription.data);
        this.notificationStore.success({ title: t('general.iCalLinkCreated'), placement: 'topRight' });
      }
    } catch (error) {
      this.notificationStore.error({
        title: t('general.error'),
        message: t('general.errors.unexpectedError'),
      });
    }
  }
}
