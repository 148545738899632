import React from 'react';
import { useTranslation } from 'react-i18next';

interface IPublishHorizonResultProps {
  numberOfCreatedPeriods: number;
  numberOfDeletedPeriods: number;
  numberOfUnchangedPeriods: number;
  numberOfUpdatedPeriods: number;
}

const PublishHorizonSuccess = (props: IPublishHorizonResultProps) => {
  const { t } = useTranslation();

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>{t('timetabling.publishTimetable.success.numberOfCreatedPeriods')}</td>
            <td>{props.numberOfCreatedPeriods}</td>
          </tr>
          <tr>
            <td>{t('timetabling.publishTimetable.success.numberOfDeletedPeriods')}</td>
            <td>{props.numberOfDeletedPeriods}</td>
          </tr>
          <tr>
            <td>{t('timetabling.publishTimetable.success.numberOfUnchangedPeriods')}</td>
            <td>{props.numberOfUnchangedPeriods}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default PublishHorizonSuccess;
