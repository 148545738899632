import React from 'react';
import { observer } from 'mobx-react-lite';

import { TimetableEntityType } from '@te/standard/stores/timetable-root-store';
import useStore from '@/hooks/useStore';
import { TimetableFilterStore } from '@te/standard/stores/filter/timetable-filter-store';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import { TimetableUserActionsStore } from '@te/standard/stores/user-actions/timetable-user-actions-store';
import TimetableEntityFilterSelector from '@te/standard/components/header/entity-selector/timetable-entity-filter-selector';
import {
  RoomFilterDto,
  ResourceFilterDto,
  StudentFilterDto,
  SubjectFilterDto,
  TeacherFilterDto,
  ClassFilterDto,
} from '@untis/wu-rest-view-api/api';
import TimetableUserOwnSelector from '@te/standard/components/header/entity-selector/timetable-user-own-selector';

export interface ITimetableSelectorProps {
  selectedValue?: number;
  onValueSelected: (value: number) => void;
}

export const DROPDOWN_ABSOLUTE_MAX_WIDTH = 300;
export const DEFAULT_DROPDOWN_ITEM_WIDTH = 80;
export const WIDTH_CALCULATION_MARGIN_OF_ERROR = 5;
export const WIDTH_CALCULATION_PADDING = 16;
export const WIDTH_CALCULATION_TOOLTIP_ICON = 24;

const TimetableEntitySelector = observer(() => {
  const timetableFilterStore = useStore(TimetableFilterStore);
  const timetableUserActionsStore = useStore(TimetableUserActionsStore);
  const timetableFormatStore = useStore(TimetableFormatStore);
  const { timetableEntityType } = timetableFormatStore;
  const {
    selectedFilterValue,
    myTimetableFilterOptions,
    filterOptions,
    roomFilterSelectorFilters,
    studentFilterSelectorFilters,
    resourceFilterSelectorFilters,
    subjectFilterSelectorFilters,
    classFilterSelectorFilters,
    teacherFilterSelectorFilters,
    getClassTooltipText,
  } = timetableFilterStore;

  const onValueSelected = (value: number) => {
    timetableUserActionsStore.setSelectedFilterValue(value);
  };

  switch (timetableEntityType) {
    case 'my-class':
    case 'my-teacher':
    case 'my-student':
      return (
        <TimetableUserOwnSelector
          filterItems={myTimetableFilterOptions}
          selectedValue={selectedFilterValue?.toString()}
          onValueSelected={onValueSelected}
        />
      );
    case 'teacher':
      return (
        <TimetableEntityFilterSelector
          selectedValue={selectedFilterValue}
          onValueSelected={onValueSelected}
          options={filterOptions as TeacherFilterDto[]}
          toMasterData={(teacher) => (teacher as TeacherFilterDto).teacher}
          filters={teacherFilterSelectorFilters}
        />
      );
    case 'class':
      return (
        <TimetableEntityFilterSelector
          selectedValue={selectedFilterValue}
          onValueSelected={onValueSelected}
          options={filterOptions as ClassFilterDto[]}
          toMasterData={(clazz) => (clazz as ClassFilterDto).class}
          getTooltip={getClassTooltipText}
          filters={classFilterSelectorFilters}
        />
      );
    case 'room':
      return (
        <TimetableEntityFilterSelector
          selectedValue={selectedFilterValue}
          onValueSelected={onValueSelected}
          options={filterOptions as RoomFilterDto[]}
          toMasterData={(room) => (room as RoomFilterDto).room}
          filters={roomFilterSelectorFilters}
        />
      );
    case 'student':
      return (
        <TimetableEntityFilterSelector
          selectedValue={selectedFilterValue}
          onValueSelected={onValueSelected}
          options={filterOptions as StudentFilterDto[]}
          toMasterData={(student) => (student as StudentFilterDto).student}
          filters={studentFilterSelectorFilters}
        />
      );
    case 'subject':
      return (
        <TimetableEntityFilterSelector
          selectedValue={selectedFilterValue}
          onValueSelected={onValueSelected}
          options={filterOptions as SubjectFilterDto[]}
          toMasterData={(subject) => (subject as SubjectFilterDto).subject}
          filters={subjectFilterSelectorFilters}
        />
      );
    case 'resource':
      return (
        <TimetableEntityFilterSelector
          selectedValue={selectedFilterValue}
          onValueSelected={onValueSelected}
          options={filterOptions as ResourceFilterDto[]}
          toMasterData={(resource) => (resource as ResourceFilterDto).resource}
          filters={resourceFilterSelectorFilters}
        />
      );
    default:
      return <TimetableEntityTypeNotSupported type={timetableEntityType} />;
  }
});

function TimetableEntityTypeNotSupported({ type }: { type: TimetableEntityType | undefined }) {
  return (
    <div className="timetable-entity-selector--type-not-supported">
      <strong>{type}</strong> type not supported
    </div>
  );
}

export default TimetableEntitySelector;
