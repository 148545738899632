import React, { useMemo } from 'react';
import clsx from 'clsx';

import { Icon, DeprecatedTag, Skeleton } from '@/ui-components';

import './motd-card.less';

export type MotdCardProps = {
  title: string;
  subtitle?: string;
  headerIcon?: string;
  hasAttachment?: boolean;
  testId?: string;
  newTag?: string;
  headerColor?:
    | string
    | 'orange'
    | 'yellow'
    | 'green'
    | 'turquoise'
    | 'lightblue'
    | 'blue'
    | 'grayblue'
    | 'purple'
    | 'violet'
    | 'pink'
    | 'red'
    | 'brown'
    | 'gray';
  onClick: () => void;
};

export const MotdCard = ({
  newTag,
  hasAttachment,
  onClick,
  testId,
  headerColor,
  title,
  subtitle,
  headerIcon,
}: MotdCardProps) => {
  const isUnknownColor = useMemo(
    () =>
      ![
        'orange',
        'yellow',
        'green',
        'turquoise',
        'lightblue',
        'blue',
        'grayblue',
        'purple',
        'violet',
        'pink',
        'red',
        'brown',
        'gray',
        undefined,
      ].includes(headerColor),
    [headerColor],
  );

  const headerColorWithoutHash = useMemo(() => `#${headerColor?.replaceAll('#', '')}`, [headerColor]);

  return (
    <div data-testid={testId} className="motd-card-container">
      <button className="motd-card" onClick={onClick}>
        <div
          style={isUnknownColor ? { backgroundColor: headerColorWithoutHash } : undefined}
          className={clsx('motd-card-header', {
            'motd-card-header-orange': headerColor === undefined || headerColor === 'orange',
            'motd-card-header-yellow': headerColor === 'yellow',
            'motd-card-header-green': headerColor === 'green',
            'motd-card-header-turquoise': headerColor === 'turquoise',
            'motd-card-header-lightblue': headerColor === 'lightblue',
            'motd-card-header-blue': headerColor === 'blue',
            'motd-card-header-grayblue': headerColor === 'grayblue',
            'motd-card-header-purple': headerColor === 'purple',
            'motd-card-header-violet': headerColor === 'violet',
            'motd-card-header-pink': headerColor === 'pink',
            'motd-card-header-red': headerColor === 'red',
            'motd-card-header-brown': headerColor === 'brown',
            'motd-card-header-gray': headerColor === 'gray',
          })}
        >
          {headerIcon && <Icon type={headerIcon} subdir="icon-picker" className="header-icon" />}
          {newTag && (
            <DeprecatedTag outline rounded type="white" className="new-tag">
              {newTag}
            </DeprecatedTag>
          )}
        </div>

        <div className="motd-card-body">
          <div className="title" title={title}>
            <span>{title}</span>
          </div>
          {subtitle && (
            <div className="subtitle" title={subtitle}>
              {subtitle}
            </div>
          )}
        </div>

        <div className="motd-card-footer">{hasAttachment && <Icon type="attachment" className="footer-icon" />}</div>
      </button>
    </div>
  );
};

MotdCard.Skeleton = () => (
  <div className="motd-card-container">
    <Skeleton.Input className="motd-skeleton" active />
  </div>
);
