import React from 'react';
import { Form } from 'antd';

import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import { getCommonTextInputRules, ICommonTextInputRules } from '@/utils/form/common-form-rules';
import { ISelectItem, SelectStyle, SingleSelect } from '@/ui-components/select/select';

export interface IFormSingleSelectProps extends IFormItemProps<string | undefined> {
  items: ISelectItem[];
  value?: string;
  searchable?: boolean;
  allowClear?: boolean;
  placeholder?: string;
  onChange?: (value: string | undefined) => void;
  customSorting?: (a: ISelectItem, b: ISelectItem) => number;
  initialValue?: string;
  styleVariant?: SelectStyle;
}

export const FormSingleSelect = (props: IFormSingleSelectProps) => {
  return (
    <Form.Item
      name={props.name}
      label={props.label}
      rules={getCommonTextInputRules(props as ICommonTextInputRules, props.rules)}
      dependencies={props.dependencies}
      initialValue={props.initialValue}
    >
      <SingleSelect
        items={props.items}
        testId={props.testId ?? props.name}
        searchable={props.searchable}
        placeholder={props.placeholder}
        disabled={props.disabled}
        allowClear={props.allowClear ?? true}
        customSorting={props.customSorting}
        styleVariant={props.styleVariant}
        onChange={props.onChange}
      />
    </Form.Item>
  );
};
