import { action, computed, observable } from 'mobx';
import { t } from 'i18next';
import { Dayjs } from 'dayjs';

import { ExamDto, ExamTypeWithGradingScaleDto, GradingScaleDto, MasterDataRefDto } from '@untis/wu-rest-view-api/api';
import { ExamsViewApi } from '@/stores/api-store';
import { formatDateTimeForServerRequest } from '@/utils/date/date-util';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { AbstractExamFormStore } from '@ls/exams/exam-form/abstract-exam-form-store';
import { AssignedTeachersRow } from '@/components/assigned-teachers/assigned-teachers';
import { ISelectItem } from '@/ui-components/select/select';

export interface IExamForm {
  name: string;
  text: string;
  examType: string | undefined;
  gradingScheme: string | undefined;
  dateTimeRange: {
    startDateTime: Dayjs;
    endDateTime: Dayjs;
  };
  assignedTeachers: AssignedTeachersRow[];
  students: IMultiTagSelectItem[];
  rooms: IMultiTagSelectItem[];
  personInCharge: number | undefined;
  returnedOn?: Dayjs;
  returnedBy: string | undefined;
  settings: string[];
}

enum GradeDeletionResult {
  NOT_NEEDED,
  ACCEPTED,
  REJECTED,
}

export class ExamFormStore extends AbstractExamFormStore<IExamForm> {
  @observable private readonly _examId: number = -1;

  @observable private _users: MasterDataRefDto[] = [];
  @observable private _gradingSchemes: MasterDataRefDto[] = [];
  @observable private _selectedExamType: number | undefined;

  constructor(examId: number) {
    super();
    this._examId = examId;
  }

  @action
  async fetchData() {
    await this.getExamSettings();
    await this.getExamFormData();
    this.updateModalHeader();

    this._isLoading = false;
  }
  @action
  private async getExamFormData() {
    const formResponse = await ExamsViewApi.getExamForm(this._examId);
    const formData = formResponse.data;
    this.setupFormData(formData);

    this._users = [...(formData.users || [])];
    this._gradingSchemes = [...(formData.gradingScales || [])];
    this._selectedExamType = this.exam?.examType?.id;
  }

  @computed
  get users(): ISelectItem[] {
    return this._users.map((user) => {
      return {
        id: user.id.toString(),
        label: user.displayName ?? '',
      };
    });
  }

  @computed
  get gradingSchemes(): ISelectItem[] {
    return this._gradingSchemes.map((gradingScheme) => {
      return {
        id: gradingScheme.id.toString(),
        label: gradingScheme.displayName ?? '',
      };
    });
  }

  @computed
  get selectedExamTypeHasGradingScale(): boolean {
    const selectedExamType = this._examTypes.find((e) => e.id.toString() === this._selectedExamType?.toString());
    return this._gradingSchemes.find((gs) => gs.id === selectedExamType?.gradingScaleId) !== undefined;
  }

  @action
  updateExam(value: IExamForm, onSaveCallback?: () => void) {
    this._isSubmitting = true;
    const examDto = this.formExamToExamDto(value, this._exam!);

    ExamsViewApi.updateExam(examDto)
      .then(() => {
        onSaveCallback && onSaveCallback();
        this.notificationStore.success({ title: t('lessons.exams.messages.examEdited') });
        this.modalStore.closeModal();
      })
      .catch((error) => {
        this._isSubmitting = false;
        this.displayError(error, t('lessons.exams.messages.examEditedError'), this.form);
      });
  }

  @action
  deleteExam(onDeleteCallback?: () => void) {
    this.modalStore.openDeletePrompt(t('lessons.exams.messages.deletePrompt'), '').then((result) => {
      if (result) {
        ExamsViewApi.deleteExam(this._examId)
          .then(async () => {
            onDeleteCallback && onDeleteCallback();
            this.notificationStore.success({ title: t('lessons.exams.messages.examDeleted') });
            this.modalStore.closeModal();
          })
          .catch((error) => {
            this.notificationStore.error({
              title: t('lessons.exams.messages.examDeletedError'),
              message: error.toString(),
            });
          });
      }
    });
  }

  @action.bound
  public async onExamTypeSelected(selectedExamTypeId: string | undefined) {
    const examTypeOfExam: ExamTypeWithGradingScaleDto | undefined = this._examTypes.find(
      (et) => et.id === this._exam?.examType?.id,
    );
    const gradingScaleOfExamTypeOfExam: GradingScaleDto | undefined = this._gradingSchemes.find(
      (gs) => gs.id === examTypeOfExam?.gradingScaleId,
    );
    const selectedExamType: ExamTypeWithGradingScaleDto | undefined = this._examTypes.find(
      (e) => e.id.toString() === selectedExamTypeId,
    );
    const gradingScaleOfSelectedExamType: GradingScaleDto | undefined = this._gradingSchemes.find(
      (gs) => gs.id === selectedExamType?.gradingScaleId,
    );

    const gradeDeletionResult = await this.askToDeleteGrades(gradingScaleOfSelectedExamType?.id);

    if (
      gradeDeletionResult === GradeDeletionResult.ACCEPTED ||
      gradeDeletionResult === GradeDeletionResult.NOT_NEEDED
    ) {
      this._formInstance?.setFieldValue('examType', selectedExamTypeId);
      this._selectedExamType = Number(selectedExamTypeId);
      if (gradingScaleOfSelectedExamType?.id) {
        this._formInstance?.setFieldValue('gradingScheme', gradingScaleOfSelectedExamType.id.toString());
      }
      this.updateModalHeader(selectedExamTypeId);
    } else if (gradeDeletionResult === GradeDeletionResult.REJECTED) {
      this._formInstance?.setFieldsValue({
        examType: examTypeOfExam?.id.toString(),
        gradingScheme: gradingScaleOfExamTypeOfExam?.id?.toString(),
      });
      this._selectedExamType = examTypeOfExam?.id;
      this.updateModalHeader(examTypeOfExam?.toString());
    }
  }

  @action.bound
  public async onGradingSchemeSelected(gradingSchemeId: string | undefined) {
    const gradeDeletionResult = await this.askToDeleteGrades(Number(gradingSchemeId));
    const currentGradingSchemeId = this._exam?.gradingScale?.id;

    if (gradeDeletionResult === GradeDeletionResult.ACCEPTED) {
      this._formInstance?.setFieldsValue({ gradingScheme: gradingSchemeId });
    } else if (gradeDeletionResult === GradeDeletionResult.REJECTED) {
      this._formInstance?.setFieldsValue({ gradingScheme: currentGradingSchemeId?.toString() });
    }
  }

  private async askToDeleteGrades(gradingSchemeId: number | undefined): Promise<GradeDeletionResult> {
    const examGradingSchemeId = this._exam?.gradingScale?.id;

    if (this._hasGrades && gradingSchemeId != examGradingSchemeId) {
      // If exam already has grades and gradingScheme changed,
      // we want to ask the user if really all existing grades shall be deleted
      return (await this.modalStore.openDeletePrompt('', t('lessons.exams.messages.deleteGradesPrompt')))
        ? GradeDeletionResult.ACCEPTED
        : GradeDeletionResult.REJECTED;
    }
    return GradeDeletionResult.NOT_NEEDED;
  }

  private formExamToExamDto(value: IExamForm, exam: ExamDto): ExamDto {
    const emptyMasterDataRef = {
      id: -1,
      displayName: '',
      shortName: '',
      longName: '',
    };

    return {
      ...exam,
      examName: value.name,
      examText: value.text,
      examStart: formatDateTimeForServerRequest(value.dateTimeRange.startDateTime),
      examEnd: formatDateTimeForServerRequest(value.dateTimeRange.endDateTime),
      examType: this._examTypes.find((e) => e.id.toString() === value.examType) || emptyMasterDataRef,
      rooms: value.rooms.map(this.mapToMasterDataRefDto),
      students: value.students.map(this.mapToExamStudentDto),
      invigilators: this.calculateInvigilators(value.dateTimeRange, value.assignedTeachers),
      exported: !!value.settings ? value.settings.includes('exported') : exam.exported,
      examReturned: value.returnedOn ? formatDateTimeForServerRequest(value.returnedOn) : undefined,
      examReturnedUser: this._users.find((u) => u.id === Number(value.returnedBy)) || emptyMasterDataRef,
      examBookedUser: this._users.find((u) => u.id === value.personInCharge) || exam.examBookedUser,
      examModifiedUser: {
        id: this.configStore.userId || -1,
        displayName: this.configStore.userName,
        shortName: this.configStore.userName,
        longName: this.configStore.userName,
      },
      gradingScale: this._gradingSchemes.find((g) => g.id.toString() === value.gradingScheme) || emptyMasterDataRef,
    };
  }
}
