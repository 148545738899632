import { Dayjs } from 'dayjs';

const DEFAULT_LOCALE = 'de';

export function defaultSorting(s1: string, s2: string): number {
  return s1.localeCompare(s2, DEFAULT_LOCALE, { numeric: true });
}

export function booleanSorting(b1: boolean, b2: boolean): number {
  return Number(b1) - Number(b2);
}

export function numberSorting(n1: number, n2: number): number {
  return n1 - n2;
}

export function sortingByBoolean(b1: boolean, b2: boolean): number {
  return b1 === b2 ? 0 : b1 ? -1 : 1;
}

export function sortingByName(s1: any, s2: any): number {
  const s1Name: string = s1.name;
  const s2Name: string = s2.name;
  return defaultSorting(s1Name, s2Name);
}

export function sortingByDisplayName(s1: any, s2: any): number {
  const lastLetter = 'z';
  const s1Name: string = s1?.displayName || lastLetter;
  const s2Name: string = s2?.displayName || lastLetter;
  return defaultSorting(s1Name, s2Name);
}

export function sortingById(s1: any, s2: any): number {
  const s1Name: string = `${s1.id}`;
  const s2Name: string = `${s2.id}`;
  return defaultSorting(s1Name, s2Name);
}

export function sortingByDate(date1: Date, date2: Date): number {
  return date1.getTime() - date2.getTime();
}

export function sortingByDayjs(a: Dayjs | undefined, b: Dayjs | undefined): number {
  // if both or one is undefined
  if (!a && !b) {
    return 0;
  } else if (!a && b) {
    return 1;
  } else if (a && !b) {
    return -1;
  }

  // if both are defined
  if (a!.isSame(b!, 'date')) {
    return 0;
  }
  return a!.isBefore(b!) ? 1 : -1;
}

export function sortByDateOrString(
  date1: Dayjs | undefined,
  date2: Dayjs | undefined,
  name1: string,
  name2: string,
): number {
  if (!date1 && !date2) {
    return defaultSorting(name1, name2);
  }

  if (!date1 && date2) {
    return -1;
  }

  if (date1 && !date2) {
    return 1;
  }

  return date1!.toDate().getTime() - date2!.toDate().getTime();
}

export function sortStringListByFirstElement(list1: string[], list2: string[]): number {
  return defaultSorting(list1[0] ?? '', list2[0] ?? '');
}
