import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Converter } from 'showdown';

import { transformJsonToMarkdown } from './transformJsonToMarkdown';

import { Editor, useEditor, EditorContent, BubbleMenu, EditorOptions, JSONContent } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { Icon } from '@/ui-components';
import { Link } from '@tiptap/extension-link';

import './wysiwyg.less';

type WysiwygOutput = 'html' | 'json' | 'markdown';

export type WysiwygProps = {
  options?: Partial<EditorOptions>;
  value?: string | JSONContent;
  onChange?: (value: string | JSONContent) => void;
  variant?: 'inline-editing' | 'default';
  output?: WysiwygOutput;
  markdownConverter?: Converter;
  testId?: string;
};

const defaultMarkdownConverter = new Converter({
  underline: false,
  omitExtraWLInCodeBlocks: true,
  noHeaderId: true,
  simplifiedAutoLink: true,
  excludeTrailingPunctuationFromURLs: true,
  strikethrough: true,
  tables: false,
  openLinksInNewWindow: true,
  emoji: true,
  simpleLineBreaks: true,
  encodeEmails: false,
  requireSpaceBeforeHeadingText: false,
});

export const Wysiwyg = ({
  markdownConverter: propsMarkdownConverter,
  options,
  value,
  onChange,
  variant,
  output,
  testId,
}: WysiwygProps) => {
  const markdownConverter = React.useMemo(
    () => propsMarkdownConverter || defaultMarkdownConverter,
    [propsMarkdownConverter],
  );

  const content = React.useMemo(
    () => (output === 'markdown' ? markdownConverter?.makeHtml((value as string) ?? '') : value ?? ''),
    [output, value, markdownConverter],
  );

  const editor = useEditor({
    extensions: [
      // if you want to include one of those, you need to adapt transformJsonToMarkdown
      // to be able to parse that feature
      StarterKit.configure({
        code: false,
        strike: false,
        blockquote: false,
        codeBlock: false,
        hardBreak: false,
        horizontalRule: false,
      }),
      Link.configure({ openOnClick: false }),
      ...(options?.extensions ?? []),
    ],
    content,
    onUpdate(props) {
      const { editor } = props;
      switch (output) {
        case 'markdown':
          const markdown = transformJsonToMarkdown(editor.getJSON());
          onChange?.(markdown.trim());
          break;
        case 'json':
          onChange?.(editor.getJSON());
          break;
        case 'html':
        default:
          onChange?.(editor.getHTML());
          break;
      }
      options?.onUpdate?.(props);
    },
    ...options,
  });

  return (
    <div data-testid={testId}>
      <MenuBar variant={variant} editor={editor} output={output} />
      <EditorContent editor={editor} />
    </div>
  );
};

type MenuBarButton = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isActive?: boolean;
  icon: string;
  testId?: string;
};

const MenuBarButton = ({ isActive, icon, testId, ...props }: MenuBarButton) => {
  return (
    <button
      type="button"
      className={clsx('menubar-button', {
        active: isActive,
      })}
      data-testid={testId}
      {...props}
    >
      <Icon type={icon} />
    </button>
  );
};

type MenuBarProps = {
  variant?: 'inline-editing' | 'default';
  editor: Editor | null;
  output?: WysiwygOutput;
};

const MenuBar = ({ variant, editor }: MenuBarProps) => {
  const setLink = useCallback(() => {
    if (!editor) {
      return;
    }

    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  const buttons = (
    <>
      <MenuBarButton
        icon="undo"
        onClick={() => editor.chain().focus().undo().run()}
        testId="wysiwyg-menu-button-undu"
      />
      <MenuBarButton
        icon="redo"
        onClick={() => editor.chain().focus().redo().run()}
        testId="wysiwyg-menu-button-redo"
      />
      <MenuBarButton
        icon="text-bold"
        onClick={() => editor.chain().focus().toggleBold().run()}
        isActive={editor.isActive('bold')}
        testId="wysiwyg-menu-button-text-bold"
      />
      <MenuBarButton
        icon="text-italic"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        isActive={editor.isActive('italic')}
        testId="wysiwyg-menu-button-text-italic"
      />
      {/* <MenuBarButton
        icon="text-underline"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        isActive={editor.isActive('underline')}
      /> */}
      <MenuBarButton
        isActive={editor.isActive('link')}
        icon="link"
        onClick={editor.isActive('link') ? () => editor.chain().focus().unsetLink().run() : setLink}
        testId="wysiwyg-menu-button-text-link"
      />
      <MenuBarButton
        isActive={editor.isActive('orderedList')}
        icon="list-numbers"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        testId="wysiwyg-menu-button-ordered-list"
      />
      <MenuBarButton
        isActive={editor.isActive('bulletList')}
        icon="list-bullets"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        testId="wysiwyg-menu-button-bullet-list"
      />
    </>
  );

  if (variant === 'inline-editing') {
    return (
      <BubbleMenu className="bubble-menu" editor={editor} tippyOptions={{ duration: 100 }}>
        {buttons}
      </BubbleMenu>
    );
  }

  return (
    <>
      <div className="richeditor-menubar">
        <div className="static-menu">{buttons}</div>
      </div>
    </>
  );
};
