import { useEffect } from 'react';

import useStore from '@/hooks/useStore';
import IframeMessageCallbackStore, { CallBack } from '@/stores/iframe-message-callback-store';
import { HandleMessageAction } from '@sp/stores/post-message-store';

export default function useIframeMessageCallback(action: HandleMessageAction, callBack: CallBack) {
  const iframeMessageCallbackStore = useStore(IframeMessageCallbackStore);

  useEffect(() => {
    const callbackId = iframeMessageCallbackStore.registerCallback(action, callBack);
    return () => iframeMessageCallbackStore.clearCallback(callbackId);
    // Disable, because we only want the effect to be called once. Be careful with disabling on other places.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
