import React from 'react';
import { Dayjs } from 'dayjs';

import { IUserPromptComponentProps } from '@/stores/modal-store';
import { DateRangeCalendar } from '@/ui-components';
import { IDateCalendarHolidayProps } from '@/ui-components/date/calendar/date-calendar/date-calendar';

interface IProps<T> extends IUserPromptComponentProps<T> {
  startDate: Dayjs | undefined;
  endDate: Dayjs | undefined;
  disabledDate?: (date: Dayjs) => boolean;
  title?: string;
  holidays?: IDateCalendarHolidayProps[];
}

interface IDateRange {
  startDate: Dayjs;
  endDate: Dayjs;
}

export const DateRangePickerPrompt = (props: IProps<IDateRange>) => {
  const onSubmit = (start: Dayjs, end: Dayjs) => {
    if (props.promptProps) {
      props.promptProps.setPromptValue({
        startDate: start,
        endDate: end,
      });
      props.promptProps.onSubmit();
    }
  };

  return (
    <div className="date-range-picker-prompt">
      <DateRangeCalendar
        start={props.startDate}
        end={props.endDate}
        title={props.title}
        onSubmit={onSubmit}
        shadow={false}
        disabledDate={props.disabledDate}
        holidays={props.holidays}
      />
    </div>
  );
};
