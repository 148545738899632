import { t } from 'i18next';

import {
  FileDescriptorDto,
  FileDescriptorDtoV2,
  FileDescriptorDtoV2StorageProviderEnum,
} from '@untis/wu-rest-view-api';
import ConfigStore from '@/stores/config-store';
import TokenStore from '@/stores/token-store';
import { inject, Store } from '@/types/store';
import { showErrorMessage } from '@/utils/error-handling/error-message';
import { IDeprecatedAttachment } from '@/ui-components/deprecated-attachment/deprecated-attachment';

interface IOneDriveError {
  errorCode: string;
  message: string;
}

interface IOneDriveResponse {
  value: IOneDriveFile[];
}

interface IOneDriveFile {
  permissions: IOneDrivePermission[];
  id: string;
  name: string;
}

interface IOneDrivePermission {
  roles: 'read'[];
  link: {
    webUrl: string;
  };
}

export interface IDriveFileDescriptor {
  id: number;
  driveId?: string;
  name: string;
  downloadUrl: string;
}

interface IDialogOptions {
  multiSelect?: boolean; // Default is true
  onSuccess: (selectedFiles: IDriveFileDescriptor[]) => void;
  onClose?: (error: IOneDriveError | undefined, canceled: boolean) => void;
}

interface IOneDriveOptions {
  clientId: string;
  action: 'share';
  multiSelect: boolean;
  advanced: {
    createLinkParameters: {
      type: 'view';
      scope: 'anonymous';
    };
    redirectUri: string;
  };
  success: (response: IOneDriveResponse) => void;
  cancel: () => void;
  error: (error: IOneDriveError) => void;
}

/**
 * Store that can be used to open the OneDrive File Uploader.
 */
@Store()
class OneDriveStore {
  private _tokenStore = inject(TokenStore);
  private _configStore = inject(ConfigStore);

  private isReadPermission = (permission: IOneDrivePermission): boolean => {
    return permission.roles && permission.roles.indexOf('read') > -1;
  };

  // https://docs.microsoft.com/en-us/onedrive/developer/controls/file-pickers/js-v72/open-file?view=odsp-graph-online
  public openOneDriveDialog = (singleSelect?: boolean): Promise<FileDescriptorDtoV2[]> => {
    return new Promise<FileDescriptorDtoV2[]>((resolve, reject) => {
      const odOptions: IOneDriveOptions = {
        clientId: this._configStore.oneDriveClientId,
        action: 'share',
        multiSelect: !singleSelect,
        advanced: {
          createLinkParameters: { type: 'view', scope: 'anonymous' },
          // we need to pass embedded.do as redirectUri because with this it work both in the old and new frontend
          // (as no redirect is done internally in webuntis)
          redirectUri: window.location.origin + '/WebUntis/embedded.do',
        },
        success: (response: IOneDriveResponse) => {
          if (response.value) {
            const selectedFiles: FileDescriptorDtoV2[] = response.value
              .filter((file) => {
                return file.permissions && file.permissions.some(this.isReadPermission);
              })
              .map((file) => {
                return {
                  storageProvider: FileDescriptorDtoV2StorageProviderEnum.ONEDRIVE,
                  storageId: file.id,
                  name: file.name,
                  downloadUrl: file.permissions.find(this.isReadPermission)!.link.webUrl,
                };
              });
            resolve(selectedFiles);
          }
          resolve([]);
        },
        cancel: () => {
          reject();
        },
        error: (error: IOneDriveError) => {
          this.onError(error);
          reject();
        },
      };

      // ensure that the user is still logged in (OneDrive should not open if the user ran into a timeout)
      this._tokenStore.fetchToken().then(() => {
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.OneDrive.open(odOptions);
        } catch (e) {
          this.onError(e as IOneDriveError);
        }
      });
    });
  };

  public deprecatedOpenOneDriveDialog = (options: IDialogOptions) => {
    // https://docs.microsoft.com/en-us/onedrive/developer/controls/file-pickers/js-v72/open-file?view=odsp-graph-online
    const odOptions: IOneDriveOptions = {
      clientId: this._configStore.oneDriveClientId,
      action: 'share',
      multiSelect: options.multiSelect ? options.multiSelect : true,
      advanced: {
        createLinkParameters: { type: 'view', scope: 'anonymous' },
        // we need to pass embedded.do as redirectUri because with this it work both in the old and new frontend
        // (as no redirect is done internally in webuntis)
        redirectUri: window.location.origin + '/WebUntis/embedded.do',
      },

      success: (response: IOneDriveResponse) => {
        if (response.value) {
          const selectedFiles: IDriveFileDescriptor[] = response.value
            .filter((file) => {
              return file.permissions && file.permissions.some(this.isReadPermission);
            })
            .map((file) => {
              return {
                id: -1, // the backend will calculate the ids by itself when saving the attachments
                driveId: file.id,
                name: file.name,
                downloadUrl: file.permissions.find(this.isReadPermission)!.link.webUrl,
              };
            });

          options.onSuccess(selectedFiles);
        }
      },
      cancel: () => {
        options.onClose && options.onClose(undefined, true);
      },
      error: (error: IOneDriveError) => {
        this.onError(error, options);
      },
    };

    // ensure that the user is still logged in (OneDrive should not open if the user ran into a timeout)
    this._tokenStore.fetchToken().then(() => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.OneDrive.open(odOptions);
      } catch (e) {
        this.onError(e as IOneDriveError, options);
      }
    });
  };

  private onError = (error: IOneDriveError, options?: IDialogOptions) => {
    let errorMessage;
    if (error.errorCode === 'popupOpen') {
      errorMessage = t('general.popupsNeeded');
    } else if (error.message.indexOf('Tenant does not have a SPO license') > -1) {
      errorMessage = t('general.oneDriveInvalidAccount');
    } else {
      errorMessage = t('general.errors.error');
    }

    console.error(error);
    showErrorMessage(errorMessage);
    options && options.onClose && options.onClose(error, false);
  };
}

export const calendarFileDescriptorDtoToAttachment = (descriptor: FileDescriptorDto): IDeprecatedAttachment => {
  return {
    id: descriptor.id,
    storageId: descriptor.id,
    name: descriptor.name,
    src: descriptor.downloadUrl,
  };
};

export default OneDriveStore;
