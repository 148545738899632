import { observer } from 'mobx-react-lite';
import React from 'react';

import useStore from '@/hooks/useStore';
import Keys from '@/types/keys';
import { NavigationBarStore } from '@/ui-components/navigation-bar/navigation-bar-store';

import './collapse-button.less';

const CollapseButton = observer(() => {
  const store = useStore(NavigationBarStore);
  const className = 'collapse-button' + (store.collapsed ? ' collapse-button--collapsed' : '');

  const handleCollapseClick = () => {
    const value = !store.collapsed;
    store.setCollapsed(value);
    store.showMenuItemsFadeAnimationWithIcons = false;
  };

  return (
    <div className={className}>
      <div
        className="container"
        onClick={handleCollapseClick}
        onKeyDown={(event) => {
          if (event.key !== Keys.Enter) return;
          handleCollapseClick();
        }}
        tabIndex={0}
      >
        <div className="line" />
        <div className="spacer" />
        <div className="line" />
      </div>
    </div>
  );
});

export default CollapseButton;
