import * as React from 'react';

import './auto-grow-text-input.less';

export const AutoGrowTextInput = (props: React.InputHTMLAttributes<HTMLInputElement>) => (
  <span className="input-wrap">
    <span className="width-machine" aria-hidden="true">
      {props.value}
    </span>
    <input {...props} />
  </span>
);
