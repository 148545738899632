import { t } from 'i18next';

import { INotificationArgs } from '@/stores/notification-store/notification-store';

export const createAutomaticStudentAbsenceNotificationArgs = (name: string, success: boolean): INotificationArgs => {
  return {
    title: success ? t('general.automaticNotificationSuccessful') : t('general.automaticNotificationFailed'),
    message: success
      ? t('general.legalGuardianNotifiedAboutAbsence', {
          student: name,
        })
      : t('general.pleaseInformLegalGuardianAboutAbsence', {
          student: name,
        }),
  };
};
