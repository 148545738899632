import { TableProps } from 'antd/lib/table';
import React from 'react';

import { Card, Icon, Table, Tooltip } from '@/ui-components';

import './table-card.less';

interface IActionProps<RecordType> {
  icon: string;
  title: string;
  record: RecordType;
  onClick: (row: RecordType) => void;
}

export const Action = (props: IActionProps<any>) => {
  return (
    <div
      className="action-container"
      onClick={(event) => {
        event.stopPropagation();
        props.onClick(props.record);
      }}
    >
      <Tooltip key={props.title} className="icon-container" title={props.title} placement="top">
        <Icon type={props.icon} />
      </Tooltip>
    </div>
  );
};

export enum TableCardCellStyle {
  NoBorder = 'cell-style cell-style--borderless',
  GrayText = 'cell-style cell-style--gray',
}

type TableCardProps<RecordType> = TableProps<RecordType>;

const TableCard = (props: TableCardProps<any>) => {
  const { className, rowClassName, ...otherProps } = props;

  return (
    <Card className="table-card">
      <Table
        className={'table ' + className}
        rowClassName={'table-row ' + rowClassName}
        pagination={false}
        showHeader={false}
        {...otherProps}
      />
    </Card>
  );
};

export default TableCard;
