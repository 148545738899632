import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/ui-components';
import { Checkbox } from '@/ui-components/checkbox/checkbox';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';

import './schoolyear-gap-dialog-view.less';

interface IProps {
  isStudentOrParent: boolean;
}

export const SchoolyearGapDialogView = (props: IProps) => {
  const [checked, setChecked] = useState(false);
  const modalStore = useStore(ModalStore);
  const { t } = useTranslation();

  const handleClick = () => {
    modalStore.closeModal();
  };

  return (
    <div className="schoolyear-gap-dialog-view">
      <p>{t('general.currentlyNoSchoolyearActive')}</p>
      {!props.isStudentOrParent && <p>{t('general.pleaseSelectSchoolyear')}</p>}
      <Checkbox
        checked={checked}
        onChange={(checked) => setChecked(checked)}
        label={t('general.iHaveReadTheInformation')}
      />
      <div className="footer">
        <Button onClick={handleClick} disabled={!checked} type="primary" size="large">
          {t('general.continue')}
        </Button>
      </div>
    </div>
  );
};
