import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Dayjs } from 'dayjs';

import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { useComponentWillUnmount } from '../../../hooks/useComponentWillUnmount';
import TimeRangePickerStore, { TimeRangePickerMode } from '../time-range-picker-store';

import '../time-range-picker.less';

interface IProps {
  startTime: Dayjs;
  endTime: Dayjs;
  mode: TimeRangePickerMode;
  store: TimeRangePickerStore;
}

/**
 *  Component that renders two inputs (hour, minutes) and is optimized for
 *  entering times in a 24h format.
 */
const TimeRangePickerInput12h = (props: IProps) => {
  const { store } = props;

  const time = props.mode === TimeRangePickerMode.Start ? props.startTime : props.endTime;
  const [initialTime] = useState(time);
  const hoursInputRef: RefObject<HTMLInputElement> = useRef(null);
  const minutesInputRef: RefObject<HTMLInputElement> = useRef(null);
  const meridiemInputRef: RefObject<HTMLInputElement> = useRef(null);
  const [hoursInputValue, setHoursInputValue] = useState(() => store.format24hTo12h(time.hour()).toString());
  const [minutesInputValue, setMinutesInputValue] = useState(() => store.prefixZero(time.minute().toString()));
  const [meridiemInputValue, setMeridiemInputValue] = useState(time.format('A'));
  const hoursInputValueRef = useRef(hoursInputValue);
  const minutesInputValueRef = useRef(minutesInputValue);
  const meridiemInputValueRef = useRef(meridiemInputValue);
  const [closeAfterRerender, setCloseAfterRerender] = useState(false);

  // effect so that the form can close itself
  useEffect(() => {
    if (closeAfterRerender) {
      props.store.toggleEditMode();
    }
  }, [closeAfterRerender, props.store]);

  // effect to update reference values (needed in useComponentWillUnmount)
  useEffect(() => {
    hoursInputValueRef.current = hoursInputValue;
    minutesInputValueRef.current = minutesInputValue;
    meridiemInputValueRef.current = meridiemInputValue;
  }, [hoursInputValue, minutesInputValue, meridiemInputValue]);

  useComponentWillUnmount(() => {
    if (!props.store.closedByTimeSlotClick) {
      const newValue: Dayjs = store.createNewDayjs12h(
        hoursInputValueRef.current,
        minutesInputValueRef.current,
        meridiemInputValueRef.current,
      );
      store.onTimeChange(newValue, props.mode, props.startTime, props.endTime);
    }
  });

  useComponentDidMount(() => {
    store.selectRef(hoursInputRef);
  });

  const resetHours = () => {
    setHoursInputValue(initialTime.hour().toString());
  };

  const resetMinutes = () => {
    setMinutesInputValue(initialTime.minute().toString());
  };

  const handleHoursKeyDown = (e: any) => {
    const hours = Number(hoursInputValue);
    if (!isNaN(hours)) {
      switch (e.key) {
        case 'Enter':
        case 'Tab':
          e.preventDefault();
          setHoursInputValue(store.prefixZero(hoursInputValue));
          store.selectRef(minutesInputRef);
          break;
        case 'ArrowUp':
          e.preventDefault();
          setHoursInputValue(hours < 12 ? store.prefixZero((hours + 1).toString()) : '01');
          break;
        case 'ArrowDown':
          e.preventDefault();
          setHoursInputValue(hours > 1 ? store.prefixZero((hours - 1).toString()) : '12');
          break;
      }
    }
  };

  const handleMinutesKeyDown = (e: any) => {
    const minutes = Number(minutesInputValue);
    if (!isNaN(minutes)) {
      switch (e.key) {
        case 'Enter':
        case 'Tab':
          e.preventDefault();
          setMinutesInputValue(store.prefixZero(minutesInputValue));
          store.selectRef(meridiemInputRef);
          break;
        case 'ArrowUp':
          e.preventDefault();
          setMinutesInputValue(minutes < 59 ? store.prefixZero((minutes + 1).toString()) : '00');
          break;
        case 'ArrowDown':
          e.preventDefault();
          setMinutesInputValue(minutes > 0 ? store.prefixZero((minutes - 1).toString()) : '59');
          break;
      }
    }
  };

  const handleMeridiemKeyDown = (e: any) => {
    e.preventDefault();
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        props.store.toggleEditMode();
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        setMeridiemInputValue(meridiemInputValue === 'AM' ? 'PM' : 'AM');
        break;
      case 'a':
      case 'A':
        setMeridiemInputValue('AM');
        setCloseAfterRerender(true);
        break;
      case 'p':
      case 'P':
        setMeridiemInputValue('PM');
        setCloseAfterRerender(true);
        break;
      default:
        setMeridiemInputValue(initialTime.format('A'));
    }
  };

  const handleHoursChange = (e: any) => {
    const value: string = e.target.value;
    const numericValue: number = Number(value);
    if (isNaN(numericValue) || numericValue > 23 || numericValue < 0) {
      resetHours();
      store.selectRef(hoursInputRef);
      return;
    }

    if (numericValue >= 3 && numericValue <= 9) {
      setHoursInputValue(store.prefixZero(e.target.value));
      store.selectRef(minutesInputRef);
      return;
    }

    if (numericValue > 11) {
      setMeridiemInputValue('PM');
    }
    if (numericValue > 12) {
      setHoursInputValue(store.prefixZero((numericValue - 12).toString()));
      store.selectRef(minutesInputRef);
    } else {
      setHoursInputValue(e.target.value);
      if (value.length === 2) {
        if (numericValue === 0) {
          setMeridiemInputValue('AM');
          setHoursInputValue(String(12));
        }
        store.selectRef(minutesInputRef);
      }
    }
  };

  const handleMinutesChange = (e: any) => {
    const value: string = e.target.value;
    const numericValue: number = Number(value);
    if (isNaN(numericValue) || numericValue > 59 || numericValue < 0) {
      resetMinutes();
      store.selectRef(minutesInputRef);
      return;
    }

    if (numericValue >= 6 && numericValue <= 9) {
      setMinutesInputValue(store.prefixZero(e.target.value));
      store.selectRef(meridiemInputRef);
      return;
    }

    setMinutesInputValue(e.target.value);
    if (value.length === 2) {
      store.selectRef(meridiemInputRef);
    }
  };

  return (
    <div className="time-range-picker-input">
      <input
        value={hoursInputValue}
        onClick={() => store.selectRef(hoursInputRef)}
        onKeyDown={handleHoursKeyDown}
        onChange={handleHoursChange}
        ref={hoursInputRef}
        data-testid="hours-input"
      />
      <p>:</p>
      <input
        value={minutesInputValue}
        onClick={() => store.selectRef(minutesInputRef)}
        onKeyDown={handleMinutesKeyDown}
        onChange={handleMinutesChange}
        ref={minutesInputRef}
        data-testid="minutes-input"
      />
      <p>&nbsp;</p>
      <input
        readOnly
        value={meridiemInputValue}
        onClick={() => store.selectRef(meridiemInputRef)}
        onKeyDown={handleMeridiemKeyDown}
        ref={meridiemInputRef}
        className="meridiem"
        data-testid="meridiem-input"
      />
    </div>
  );
};

export default TimeRangePickerInput12h;
