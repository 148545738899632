import { Collapse } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DateRange as DateRangeType, TTHorizon } from '@tt/types';

import './publish-horizon-dialog.less';

const { Panel } = Collapse;

interface IPublishHorizonDialogProps {
  horizon: TTHorizon;
}

function formatDateRange(dateRange: DateRangeType): string {
  return dateRange.start.format('L') + ' - ' + dateRange.end.format('L');
}

const PublishHorizonDialog = (props: IPublishHorizonDialogProps) => {
  const { t } = useTranslation();

  return (
    <div className="untis__publish-horizon-dialog">
      <h2 className="untis__publish-horizon-dialog__title">{t('timetabling.publishTimetable.title')}</h2>
      <p>{t('timetabling.publishTimetable.userPrompt', { timetableName: props.horizon.name })}</p>

      <Collapse className="untis__publish-horizon-dialog__collapse">
        <Panel key="1" header={t('general.moreInfo')}>
          <p>{t('timetabling.publishTimetable.explanation01')}</p>
          <p>
            {t('timetabling.publishTimetable.explanation02', {
              timetableDateRange: formatDateRange(props.horizon.dateRange),
            })}
          </p>
        </Panel>
      </Collapse>
    </div>
  );
};

export default PublishHorizonDialog;
