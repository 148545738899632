import { t } from 'i18next';

import { ISubMenuItem } from '@/ui-components/navigation-bar/navigation-bar-store';

/**
 * MenuPermissions are not mapped to menu items directly but used to calculate the menu structure
 */
export enum MenuPermissionEnum {
  PERMISSION_STUDENT_TT_ALL, //
  PERMISSION_STUDENT_TT_OWN,
  PERMISSION_CLASS_TT_ALL,
  PERMISSION_CLASS_TT_OWN,
  TIMETABLE_NEW_CLASSES_MY,
  TIMETABLE_NEW_STUDENTS_MY,
}

export enum MenuItemEnum {
  TODAY,
  MESSAGE_CENTER,
  MESSAGE_CENTER_2021,
  CONTACT_DETAILS,
  DRIVE,
  STUDENTABSENCES,
  STUDENT_EXAMS,
  STUDENT_GRADES,
  STUDENT_CONTACT_HOURS,
  STUDENT_EXEMPTIONS,
  STUDENT_CLASS_SERVICES,
  STUDENT_HOMEWORK,
  STUDENT_CLASSREG_ENTRIES,
  MY_DATA,
}

export enum SubMenuItemEnum {
  TIMETABLE_CLASSES_MY,
  TIMETABLE_TEACHERS_MY,
  TIMETABLE_STUDENTS_MY,
  TIMETABLE_NEW_TEACHERS_MY,
  TIMETABLE_CLASSES,
  TIMETABLE_TEACHERS,
  TIMETABLE_RESOURCES,
  TIMETABLE_ROOMS,
  TIMETABLE_SUBJECTS,
  TIMETABLE_STUDENTS,
  TIMETABLE_NEW_CLASSES,
  TIMETABLE_NEW_STUDENTS,
  TIMETABLE_NEW_TEACHERS,
  TIMETABLE_NEW_ROOMS,
  TIMETABLE_NEW_SUBJECTS,
  TIMETABLE_NEW_RESOURCES,
  TIMETABLE_SETTINGS_NEW,
  TIMETABLE_DAILY_OVERVIEW_ROOMS,
  TIMETABLE_DAILY_OVERVIEW_TEACHERS,
  TIMETABLE_DAILY_OVERVIEW_CLASSES,
  TIMETABLE_OVERVIEW_CLASSES,
  TIMETABLE_OVERVIEW_TEACHERS,
  TIMETABLE_OVERVIEW_ROOMS,
  TIMETABLE_OVERVIEW_RESOURCES,
  TIMETABLE_CONTACT_HOURS,
  MY_LESSONS,
  TEACHER_LESSONS,
  CLASS_LESSONS,
  STUDENT_LESSONS,
  EXAMS,
  EXAM_STATISTICS,
  DAILY_TEACHER_LESSONS,
  DAILY_CLASS_LESSONS,
  YEARLY_CLASS_LESSONS,
  YEARLY_TEACHER_LESSONS,
  STUDENT_GROUPS,
  LESSONS_TASKS,
  LESSONS_SETTINGS,
  MY_BOOKINGS,
  APPOINTMENTS,
  NEW_ACTIVITY,
  BOOK_RESOURCE,
  BOOK_ROOM,
  LIST_OF_ROOMS,
  LIST_OF_RESOURCES,
  BOOKING_REPORTS,
  BOOKINGS,
  TODO_LIST,
  ROOM_CONFLICTS,
  RESOURCE_CONFLICTS,
  // OPEN_PERIODS_TEACHER, // <-- remove when open periods are released
  // OPEN_PERIODS_CLASS, // <-- remove when open periods are released
  OPEN_PERIODS,
  ABSENCES,
  ABSENCE_TIMES,
  CLASS_REGISTER_ENTRIES,
  HOMEWORK,
  EXEMPTIONS,
  CLASS_SERVICES,
  CLASS_REGISTER_REPORTS,
  CLASS_REGISTER_SETTINGS,
  COURSE_TEMPLATES,
  COURSES,
  COURSES_PER_STUDENT,
  REGISTRATION_PERIODS,
  COURSES_REPORTS,
  COURSE_CATEGORIES,
  COURSE_CHOICE_CATEGORIES,
  COURSE_MODULES,
  STAGES_OF_STUDIES,
  COURSES_SETTINGS,
  SUBSTITUTION_PLANNING_SUBSTITUTIONS,
  SUBSTITUTION_PLANNING_TEACHER_COUNTERS,
  SUBSTITUTION_PLANNING_TEACHER_ABSENCE_REASONS,
  SUBSTITUTION_PLANNING_SETTINGS,
  SUBSTITUTION_PLANNING_MY_ABSENCES,
  SP_SUBSTIUTION_REQUESTS,
  TIMETABLING,
  TIMETABLING_LESSONS,
  TIMETABLING_TIMETABLES,
  TIMETABLING_TIMETABLING,
  TIMETABLING_BLOCKS_AND_COUPLINGS,
  TIMETABLING_MASTER_DATA_CONSTRAINTS,
  TIMETABLING_TIME_PREFERENCES,
  CLASSES,
  STUDENTS,
  LEGAL_GUARDIAN,
  APPRENTICE_REPRESENTATIVES,
  TEACHERS,
  TEACHING_QUALIFICATIONS,
  TEACHER_STATUS,
  SUBJECTS,
  SUBJECT_GROUPS,
  SUBJECT_TYPES,
  ROOMS,
  ROOM_GROUPS,
  ROOM_TYPES,
  BUILDINGS,
  RESOURCES,
  GROUPS_OF_RESOURCES,
  TYPES_OF_RESOURCES,
  REASONS_OF_ABSENCE,
  EXCUSE_STATUS,
  REASONS_OF_EXEMPTIONS,
  REMARK_CATEGORIES,
  GROUPS_FOR_REMARK_CATEGORIES,
  SERVICES,
  STUDENT_PROPERTIES,
  GRADING_SCHEMES,
  TYPES_OF_EXAMINATIONS,
  TEACHING_METHODS,
  TYPES_OF_ACTIVITIES,
  REDUCTION_REASONS,
  TASKS,
  DEPARTMENTS,
  HOLIDAYS,
  SCHOOL_YEARS,
  TIME_GRID,
  MESSAGES_OF_THE_DAY,
  EXPORT,
  ACTIVE_USERS,
  RIGHTS_AND_ROLES,
  EXAM_CALENDAR,
  USERS,
  LOCK_OUT_TIMES,
  BOOKING_RESTRICTIONS,
  ROOM_BLOCKINGS,
  EXAM_BLOCKINGS,
  LAYOUT_SETTINGS,
  MONITOR_VIEWS,
  BOOKING_FORMATS,
  TIMETABLE_SETTINGS,
  SETTINGS,
  INTEGRATION,
  PARENT_TEACHER_DAYS,
  PLAYGROUND,
  BACKUP,
  PRIVACY,
  ABOUT_WEBUNTIS,
  ASSIGNMENT_GROUPS,
  SYSTEM_LOCK,
  BOOKING_LOCK,
  NOVASCHEM,
  HAMBURG,
  LUX_TEACHER_SALARY,
  PROFILE,
  MESSAGE_CENTER_2021_LISTS,
  MESSAGE_CENTER_2021_SENT_MESSAGES,
  MESSAGE_CENTER_2021_DRAFT_MESSAGES,
  PLATFORM_APPLICATION,
  MY_COURSES,
  COURSE_PREVIEW,
  COURSE_REGISTRATION,
  STUDENT_BOOKING,
  CLASS_REGISTER_OVERVIEW,
}

const getSubMenuItemWithKey = (key: SubMenuItemEnum, activeHorizonId?: number): ISubMenuItem | undefined => {
  switch (key) {
    case SubMenuItemEnum.TIMETABLE_CLASSES_MY:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.my'),
        icon: 'my_timetable',
        route: '/timetable-classes-my',
      };
    case SubMenuItemEnum.TIMETABLE_TEACHERS_MY:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.my'),
        icon: 'my_timetable',
        route: '/timetable-teachers-my',
      };
    case SubMenuItemEnum.TIMETABLE_STUDENTS_MY:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.my'),
        icon: 'my_timetable',
        route: '/timetable-students-my',
      };
    case SubMenuItemEnum.TIMETABLE_CLASSES:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.classes'),
        icon: 'shared_classEntity',
        route: '/timetable-classes',
      };
    case SubMenuItemEnum.TIMETABLE_TEACHERS:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.teachers'),
        icon: 'shared_teacherEntity',
        route: '/timetable-teachers',
      };
    case SubMenuItemEnum.TIMETABLE_RESOURCES:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.resources'),
        icon: 'resources',
        route: '/timetable-resources',
      };
    case SubMenuItemEnum.TIMETABLE_SUBJECTS:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.subjects'),
        icon: 'shared_subjectEntity',
        route: '/timetable-subjects',
      };
    case SubMenuItemEnum.TIMETABLE_ROOMS:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.rooms'),
        icon: 'shared_roomEntity',
        route: '/timetable-rooms',
      };
    case SubMenuItemEnum.TIMETABLE_STUDENTS:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.students'),
        icon: 'shared_student',
        route: '/timetable-students',
      };
    case SubMenuItemEnum.TIMETABLE_DAILY_OVERVIEW_ROOMS:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.dailyViewRooms'),
        icon: 'rooms_daily',
        route: '/timetable-daily-overview-rooms',
      };
    case SubMenuItemEnum.TIMETABLE_DAILY_OVERVIEW_TEACHERS:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.dailyViewTeachers'),
        icon: 'teachers_daily',
        route: '/timetable-daily-overview-teachers',
      };
    case SubMenuItemEnum.TIMETABLE_DAILY_OVERVIEW_CLASSES:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.dailyViewClasses'),
        icon: 'classes_daily',
        route: '/timetable-daily-overview-classes',
      };
    case SubMenuItemEnum.TIMETABLE_OVERVIEW_CLASSES:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.overviewClasses'),
        icon: 'classes_weekly',
        route: '/timetable-overview-classes',
      };
    case SubMenuItemEnum.TIMETABLE_OVERVIEW_TEACHERS:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.overviewTeachers'),
        icon: 'teachers_weekly',
        route: '/timetable-overview-teachers',
      };
    case SubMenuItemEnum.TIMETABLE_OVERVIEW_ROOMS:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.overviewRooms'),
        icon: 'rooms_weekly',
        route: '/timetable-overview-rooms',
      };
    case SubMenuItemEnum.TIMETABLE_OVERVIEW_RESOURCES:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.overviewResources'),
        icon: 'resources_weekly',
        route: '/timetable-overview-resources',
      };
    case SubMenuItemEnum.TIMETABLE_CONTACT_HOURS:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.officehours'),
        icon: 'infoCenter_officeHours',
        route: '/timetable-contact-hours',
      };
    case SubMenuItemEnum.TIMETABLE_NEW_TEACHERS_MY:
      return {
        name: t('menu.menuItems.timetable.subMenuItems.my'),
        icon: 'my_timetable',
        route: '/timetable-new/my-teacher',
      };
    case SubMenuItemEnum.TIMETABLE_NEW_CLASSES:
      return {
        name: t('general.class'),
        icon: 'shared_classEntity',
        route: '/timetable-new/class',
      };
    case SubMenuItemEnum.TIMETABLE_NEW_STUDENTS:
      return {
        name: t('general.student'),
        icon: 'shared_student',
        route: '/timetable-new/student',
      };
    case SubMenuItemEnum.TIMETABLE_NEW_TEACHERS:
      return {
        name: t('general.teacher'),
        icon: 'shared_teacherEntity',
        route: '/timetable-new/teacher',
      };
    case SubMenuItemEnum.TIMETABLE_NEW_ROOMS:
      return {
        name: t('general.room'),
        icon: 'shared_roomEntity',
        route: '/timetable-new/room',
      };
    case SubMenuItemEnum.TIMETABLE_NEW_SUBJECTS:
      return {
        name: t('general.subject'),
        icon: 'shared_subjectEntity',
        route: '/timetable-new/subject',
      };
    case SubMenuItemEnum.TIMETABLE_NEW_RESOURCES:
      return {
        name: t('general.resource'),
        icon: 'resources',
        route: '/timetable-new/resource',
      };
    case SubMenuItemEnum.TIMETABLE_SETTINGS_NEW:
      return {
        name: t('general.settings'),
        icon: 'settings',
        route: '/timetable-new/settings',
      };
    case SubMenuItemEnum.MY_LESSONS:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.my'),
        icon: 'my_lessons',
        route: '/my-lessons',
      };
    case SubMenuItemEnum.TEACHER_LESSONS:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.teacher'),
        icon: 'lessons_teachers',
        route: '/teacher-lessons',
      };
    case SubMenuItemEnum.CLASS_LESSONS:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.class'),
        icon: 'lessons_classes',
        route: '/class-lessons',
      };
    case SubMenuItemEnum.STUDENT_LESSONS:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.student'),
        icon: 'lessons_students',
        route: '/student-lessons',
      };
    case SubMenuItemEnum.EXAMS:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.exams'),
        icon: 'examinations',
        route: '/exams',
      };
    case SubMenuItemEnum.EXAM_STATISTICS:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.examStatistics'),
        icon: 'reports',
        route: '/exam-statistics',
      };
    case SubMenuItemEnum.DAILY_CLASS_LESSONS:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.dailyClass'),
        icon: 'lessons_classes',
        route: '/daily-class-lessons',
      };
    case SubMenuItemEnum.DAILY_TEACHER_LESSONS:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.dailyTeacher'),
        icon: 'shared_teacherEntity',
        route: '/daily-teacher-lessons',
      };
    case SubMenuItemEnum.YEARLY_CLASS_LESSONS:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.yearlyClasses'),
        icon: 'lessons_list_classes',
        route: '/yearly-class-lessons',
      };
    case SubMenuItemEnum.YEARLY_TEACHER_LESSONS:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.yearlyTeachers'),
        icon: 'lessons_list_teacher',
        route: '/yearly-teacher-lessons',
      };
    case SubMenuItemEnum.STUDENT_GROUPS:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.studentGroups'),
        icon: 'student_groups',
        route: '/student-groups',
      };
    case SubMenuItemEnum.LESSONS_TASKS:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.tasks'),
        icon: 'tasks',
        route: '/lessons-tasks',
      };
    case SubMenuItemEnum.LESSONS_SETTINGS:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.settings'),
        icon: 'settings',
        route: '/lessons-settings',
      };

    case SubMenuItemEnum.MY_BOOKINGS:
      return {
        name: t('menu.menuItems.booking.subMenuItems.my'),
        icon: 'my_bookings',
        route: '/my-bookings',
      };
    case SubMenuItemEnum.APPOINTMENTS:
      return {
        name: t('menu.menuItems.booking.subMenuItems.appointments'),
        icon: 'appointments',
        route: '/appointments',
      };
    case SubMenuItemEnum.NEW_ACTIVITY:
      return {
        name: t('menu.menuItems.booking.subMenuItems.newActivity'),
        icon: 'new_activity',
        route: '/new-activity',
      };
    case SubMenuItemEnum.BOOK_RESOURCE:
      return {
        name: t('menu.menuItems.booking.subMenuItems.resource'),
        icon: 'book_resource',
        route: '/book-resource',
      };
    case SubMenuItemEnum.BOOK_ROOM:
      return {
        name: t('menu.menuItems.booking.subMenuItems.room'),
        icon: 'book_room',
        route: '/book-room',
      };
    case SubMenuItemEnum.LIST_OF_ROOMS:
      return {
        name: t('menu.menuItems.booking.subMenuItems.roomsList'),
        icon: 'list_of_rooms',
        route: '/list-of-rooms',
      };
    case SubMenuItemEnum.LIST_OF_RESOURCES:
      return {
        name: t('menu.menuItems.booking.subMenuItems.resourcesList'),
        icon: 'list_of_resources',
        route: '/list-of-resources',
      };
    case SubMenuItemEnum.BOOKING_LOCK:
      return {
        name: t('menu.menuItems.booking.subMenuItems.bookingsLock'),
        icon: 'lock_unlock_bookings',
        route: '/booking-lock',
      };
    case SubMenuItemEnum.BOOKING_REPORTS:
      return {
        name: t('menu.menuItems.booking.subMenuItems.reports'),
        icon: 'reports',
        route: '/booking-reports',
      };
    case SubMenuItemEnum.BOOKINGS:
      return {
        name: t('menu.menuItems.booking.subMenuItems.bookings'),
        icon: 'bookings',
        route: '/bookings',
      };
    case SubMenuItemEnum.TODO_LIST:
      return {
        name: t('menu.menuItems.booking.subMenuItems.todoList'),
        icon: 'to-do_list',
        route: '/todo-list',
      };
    case SubMenuItemEnum.ROOM_CONFLICTS:
      return {
        name: t('menu.menuItems.booking.subMenuItems.roomConflicts'),
        icon: 'room_conflicts',
        route: '/room-conflicts',
      };
    case SubMenuItemEnum.RESOURCE_CONFLICTS:
      return {
        name: t('menu.menuItems.booking.subMenuItems.resourceConflicts'),
        icon: 'resource_conflicts',
        route: '/resource-conflicts',
      };

    // case SubMenuItemEnum.OPEN_PERIODS_TEACHER:
    //   return {
    //     name: t('menu.menuItems.classRegister.subMenuItems.openPeriodsTeacher'),
    //     icon: 'open_periods_teacher',
    //     route: '/open-periods-teacher',
    //   };
    // case SubMenuItemEnum.OPEN_PERIODS_CLASS:
    //   return {
    //     name: t('menu.menuItems.classRegister.subMenuItems.openPeriodsClass'),
    //     icon: 'open_periods_class',
    //     route: '/open-periods-class',
    //   };
    case SubMenuItemEnum.OPEN_PERIODS:
      return {
        name: t('menu.menuItems.classRegister.subMenuItems.openPeriods'),
        icon: 'open_periods_teacher',
        route: '/open-periods',
      };
    case SubMenuItemEnum.ABSENCES:
      return {
        name: t('menu.menuItems.classRegister.subMenuItems.absences'),
        icon: 'absences',
        route: '/absences',
      };
    case SubMenuItemEnum.ABSENCE_TIMES:
      return {
        name: t('menu.menuItems.classRegister.subMenuItems.absenceTimes'),
        icon: 'absences_times',
        route: '/absence-times',
      };
    case SubMenuItemEnum.CLASS_REGISTER_ENTRIES:
      return {
        name: t('menu.menuItems.classRegister.subMenuItems.entries'),
        icon: 'class_register_entries',
        route: '/class-register-entries',
      };
    case SubMenuItemEnum.HOMEWORK:
      return {
        name: t('menu.menuItems.classRegister.subMenuItems.homework'),
        icon: 'homework',
        route: '/homework',
      };
    case SubMenuItemEnum.EXEMPTIONS:
      return {
        name: t('menu.menuItems.classRegister.subMenuItems.exemptions'),
        icon: 'exemptions',
        route: '/exemptions',
      };
    case SubMenuItemEnum.CLASS_SERVICES:
      return {
        name: t('menu.menuItems.classRegister.subMenuItems.classServices'),
        icon: 'class_services',
        route: '/class-services',
      };
    case SubMenuItemEnum.CLASS_REGISTER_REPORTS:
      return {
        name: t('menu.menuItems.classRegister.subMenuItems.reports'),
        icon: 'reports',
        route: '/class-register-reports',
      };
    case SubMenuItemEnum.CLASS_REGISTER_SETTINGS:
      return {
        name: t('menu.menuItems.classRegister.subMenuItems.settings'),
        icon: 'settings',
        route: '/class-register-settings',
      };

    case SubMenuItemEnum.COURSE_TEMPLATES:
      return {
        name: t('menu.menuItems.courses.subMenuItems.templates'),
        icon: 'course_templates',
        route: '/course-templates',
      };
    case SubMenuItemEnum.COURSES:
      return {
        name: t('menu.menuItems.courses.subMenuItems.courses'),
        icon: 'courses',
        route: '/courses',
      };
    case SubMenuItemEnum.COURSES_PER_STUDENT:
      return {
        name: t('menu.menuItems.courses.subMenuItems.perStudent'),
        icon: 'courses_student',
        route: '/courses-per-student',
      };
    case SubMenuItemEnum.REGISTRATION_PERIODS:
      return {
        name: t('menu.menuItems.courses.subMenuItems.registrationPeriods'),
        icon: 'registration_periods',
        route: '/registration-periods',
      };
    case SubMenuItemEnum.COURSES_REPORTS:
      return {
        name: t('menu.menuItems.courses.subMenuItems.reports'),
        icon: 'reports',
        route: '/courses-reports',
      };
    case SubMenuItemEnum.COURSE_CATEGORIES:
      return {
        name: t('menu.menuItems.courses.subMenuItems.categories'),
        icon: 'course_categories',
        route: '/course-categories',
      };
    case SubMenuItemEnum.COURSE_CHOICE_CATEGORIES:
      return {
        name: t('menu.menuItems.courses.subMenuItems.choiceCategories'),
        icon: 'course_choice_categories',
        route: '/course-choice-categories',
      };
    case SubMenuItemEnum.COURSE_MODULES:
      return {
        name: t('menu.menuItems.courses.subMenuItems.modules'),
        icon: 'course_modules',
        route: '/course-modules',
      };
    case SubMenuItemEnum.STAGES_OF_STUDIES:
      return {
        name: t('menu.menuItems.courses.subMenuItems.stagesOfStudies'),
        icon: 'study_sections',
        route: '/stages-of-studies',
      };
    case SubMenuItemEnum.COURSES_SETTINGS:
      return {
        name: t('menu.menuItems.courses.subMenuItems.settings'),
        icon: 'settings',
        route: '/courses-settings',
      };

    case SubMenuItemEnum.SUBSTITUTION_PLANNING_SUBSTITUTIONS:
      return {
        name: t('menu.menuItems.substitutionPlanning.subMenuItems.substitutions'),
        icon: 'substitution_planing',
        route: '/substitution-planning/substitutions',
      };

    case SubMenuItemEnum.SUBSTITUTION_PLANNING_TEACHER_COUNTERS:
      return {
        name: t('menu.menuItems.substitutionPlanning.subMenuItems.counterOverview'),
        route: '/substitution-planning/counter-overview',
        icon: 'sp-teacher-counter',
      };

    case SubMenuItemEnum.SUBSTITUTION_PLANNING_TEACHER_ABSENCE_REASONS:
      return {
        name: t('menu.menuItems.substitutionPlanning.subMenuItems.absenceReasons'),
        route: '/substitution-planning/teacher-absence-reasons',
        icon: 'sp-teacher-absence-reasons',
      };

    case SubMenuItemEnum.SUBSTITUTION_PLANNING_SETTINGS:
      return {
        name: t('menu.menuItems.substitutionPlanning.subMenuItems.settings'),
        route: '/substitution-planning/settings',
        icon: 'settings',
      };

    case SubMenuItemEnum.SUBSTITUTION_PLANNING_MY_ABSENCES:
      return {
        name: t('menu.menuItems.substitutionPlanning.subMenuItems.myAbsences'),
        route: '/substitution-planning/my-absences',
        icon: 'my_absences',
      };

    case SubMenuItemEnum.SP_SUBSTIUTION_REQUESTS:
      return {
        name: t('menu.menuItems.substitutionPlanning.subMenuItems.substitutionRequests'),
        route: '/substitution-planning/substitution-requests',
        icon: 'substitution_requests',
      };
    case SubMenuItemEnum.TIMETABLING_LESSONS:
      return {
        name: t('menu.menuItems.timetabling.subMenuItems.lessons'),
        icon: 'list',
        route: '/timetabling/lectures',
      };
    case SubMenuItemEnum.TIMETABLING_TIMETABLES:
      return {
        name: t('menu.menuItems.timetabling.subMenuItems.timetables'),
        icon: 'list',
        route: '/timetabling/timetables',
      };
    case SubMenuItemEnum.TIMETABLING_TIMETABLING:
      return {
        name: t('menu.menuItems.timetabling.subMenuItems.timetabling'),
        icon: 'scheduling',
        route: `/timetabling/timetables/${activeHorizonId}/timetabling`,
      };
    case SubMenuItemEnum.TIMETABLING_BLOCKS_AND_COUPLINGS:
      return {
        name: t('menu.menuItems.timetabling.subMenuItems.lessonConstraints'),
        icon: 'lessons',
        route: `/timetabling/timetables/${activeHorizonId}/lesson-constraints`,
      };
    case SubMenuItemEnum.TIMETABLING_TIME_PREFERENCES:
      return {
        name: t('menu.menuItems.timetabling.subMenuItems.timePreferences'),
        icon: 'time_preferences',
        route: `/timetabling/timetables/${activeHorizonId}/time-requests`,
      };
    case SubMenuItemEnum.TIMETABLING_MASTER_DATA_CONSTRAINTS:
      return {
        name: t('menu.menuItems.timetabling.subMenuItems.masterDataConstraints'),
        icon: 'constraints',
        route: `/timetabling/timetables/${activeHorizonId}/master-data-constraints`,
      };
    case SubMenuItemEnum.CLASSES:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.classes'),
        icon: 'placeholder-20',
        route: '/classes',
      };
    case SubMenuItemEnum.STUDENTS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.students'),
        icon: 'placeholder-20',
        route: '/students',
      };
    case SubMenuItemEnum.LEGAL_GUARDIAN:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.legalguardians'),
        icon: 'placeholder-20',
        route: '/legalguardians',
      };
    case SubMenuItemEnum.APPRENTICE_REPRESENTATIVES:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.apprenticerepresentatives'),
        icon: 'placeholder-20',
        route: '/apprenticerepresentatives',
      };
    case SubMenuItemEnum.TEACHERS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.teachers'),
        icon: 'placeholder-20',
        route: '/teachers',
      };
    case SubMenuItemEnum.TEACHING_QUALIFICATIONS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.teachingQualifications'),
        icon: 'placeholder-20',
        route: '/teaching-qualifications',
      };
    case SubMenuItemEnum.TEACHER_STATUS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.teacherStatus'),
        icon: 'placeholder-20',
        route: '/teacher-status',
      };
    case SubMenuItemEnum.SUBJECTS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.subjects'),
        icon: 'placeholder-20',
        route: '/subjects',
      };
    case SubMenuItemEnum.SUBJECT_GROUPS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.subjectGroups'),
        icon: 'placeholder-20',
        route: '/subject-groups',
      };
    case SubMenuItemEnum.SUBJECT_TYPES:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.subjectTypes'),
        icon: 'placeholder-20',
        route: '/subject-types',
      };
    case SubMenuItemEnum.ROOMS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.rooms'),
        icon: 'placeholder-20',
        route: '/rooms',
      };
    case SubMenuItemEnum.ROOM_GROUPS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.roomGroups'),
        icon: 'placeholder-20',
        route: '/room-groups',
      };
    case SubMenuItemEnum.ROOM_TYPES:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.roomTypes'),
        icon: 'placeholder-20',
        route: '/room-types',
      };
    case SubMenuItemEnum.BUILDINGS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.buildings'),
        icon: 'placeholder-20',
        route: '/buildings',
      };
    case SubMenuItemEnum.RESOURCES:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.resources'),
        icon: 'placeholder-20',
        route: '/resources',
      };
    case SubMenuItemEnum.GROUPS_OF_RESOURCES:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.resourceGroups'),
        icon: 'placeholder-20',
        route: '/groups-of-resources',
      };
    case SubMenuItemEnum.TYPES_OF_RESOURCES:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.resourceTypes'),
        icon: 'placeholder-20',
        route: '/types-of-resources',
      };
    case SubMenuItemEnum.REASONS_OF_ABSENCE:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.absenceReasons'),
        icon: 'placeholder-20',
        route: '/reasons-of-absence',
      };
    case SubMenuItemEnum.EXCUSE_STATUS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.excuseStatus'),
        icon: 'placeholder-20',
        route: '/excuse-status',
      };
    case SubMenuItemEnum.REASONS_OF_EXEMPTIONS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.exemptionReasons'),
        icon: 'placeholder-20',
        route: '/reasons-of-exemptions',
      };
    case SubMenuItemEnum.REMARK_CATEGORIES:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.remarkCategories'),
        icon: 'placeholder-20',
        route: '/remark-categories',
      };
    case SubMenuItemEnum.GROUPS_FOR_REMARK_CATEGORIES:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.remarkCategoryGroups'),
        icon: 'placeholder-20',
        route: '/groups-for-remark-categories',
      };
    case SubMenuItemEnum.SERVICES:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.services'),
        icon: 'placeholder-20',
        route: '/services',
      };
    case SubMenuItemEnum.STUDENT_PROPERTIES:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.studentProperties'),
        icon: 'placeholder-20',
        route: '/student-properties',
      };
    case SubMenuItemEnum.GRADING_SCHEMES:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.gradingSchemes'),
        icon: 'placeholder-20',
        route: '/grading-schemes',
      };
    case SubMenuItemEnum.TYPES_OF_EXAMINATIONS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.examinationTypes'),
        icon: 'placeholder-20',
        route: '/types-of-examinations',
      };
    case SubMenuItemEnum.TEACHING_METHODS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.teachingMethods'),
        icon: 'placeholder-20',
        route: '/teaching-methods',
      };
    case SubMenuItemEnum.TYPES_OF_ACTIVITIES:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.activityTypes'),
        icon: 'placeholder-20',
        route: '/types-of-activities',
      };
    case SubMenuItemEnum.REDUCTION_REASONS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.reductionReasons'),
        icon: 'placeholder-20',
        route: '/reduction-reasons',
      };
    case SubMenuItemEnum.TASKS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.tasksTypes'),
        icon: 'placeholder-20',
        route: '/tasks',
      };
    case SubMenuItemEnum.DEPARTMENTS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.departments'),
        icon: 'navigation-department',
        route: '/departments',
      };
    case SubMenuItemEnum.HOLIDAYS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.holidays'),
        icon: 'placeholder-20',
        route: '/holidays',
      };
    case SubMenuItemEnum.SCHOOL_YEARS:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.schoolYears'),
        icon: 'placeholder-20',
        route: '/school-years',
      };
    case SubMenuItemEnum.TIME_GRID:
      return {
        name: t('menu.menuItems.masterData.subMenuItems.timeGrid'),
        icon: 'placeholder-20',
        route: '/time-grid',
      };

    case SubMenuItemEnum.MESSAGES_OF_THE_DAY:
      return {
        name: t('menu.menuItems.administration.subMenuItems.messagesOfTheDay'),
        icon: 'placeholder-20',
        route: '/messages-of-the-day',
      };
    case SubMenuItemEnum.EXPORT:
      return {
        name: t('menu.menuItems.administration.subMenuItems.export'),
        icon: 'placeholder-20',
        route: '/export',
      };
    case SubMenuItemEnum.ACTIVE_USERS:
      return {
        name: t('menu.menuItems.administration.subMenuItems.activeUsers'),
        icon: 'placeholder-20',
        route: '/active-users',
      };
    case SubMenuItemEnum.RIGHTS_AND_ROLES:
      return {
        name: t('menu.menuItems.administration.subMenuItems.rightsAndRoles'),
        icon: 'placeholder-20',
        route: '/rights-and-roles',
      };
    case SubMenuItemEnum.EXAM_CALENDAR:
      return {
        name: t('menu.menuItems.lessons.subMenuItems.examCalendar'),
        icon: 'exam_planner',
        route: '/exam-calendar',
      };
    case SubMenuItemEnum.USERS:
      return {
        name: t('menu.menuItems.administration.subMenuItems.users'),
        icon: 'placeholder-20',
        route: '/users',
      };
    case SubMenuItemEnum.ASSIGNMENT_GROUPS:
      return {
        name: t('menu.menuItems.administration.subMenuItems.assignmentGroups'),
        icon: 'placeholder-20',
        route: '/assignment-groups',
      };
    case SubMenuItemEnum.LOCK_OUT_TIMES:
      return {
        name: t('menu.menuItems.administration.subMenuItems.lockOutTimes'),
        icon: 'placeholder-20',
        route: '/lock-out-times',
      };
    case SubMenuItemEnum.BOOKING_RESTRICTIONS:
      return {
        name: t('menu.menuItems.administration.subMenuItems.bookingRestrictions'),
        icon: 'placeholder-20',
        route: '/booking-restrictions',
      };
    case SubMenuItemEnum.ROOM_BLOCKINGS:
      return {
        name: t('menu.menuItems.administration.subMenuItems.roomBlockings'),
        icon: 'placeholder-20',
        route: '/room-blockings',
      };
    case SubMenuItemEnum.EXAM_BLOCKINGS:
      return {
        name: t('menu.menuItems.administration.subMenuItems.examBlockings'),
        icon: 'placeholder-20',
        route: '/exam-blockings',
      };
    case SubMenuItemEnum.LAYOUT_SETTINGS:
      return {
        name: t('menu.menuItems.administration.subMenuItems.layoutSettings'),
        icon: 'placeholder-20',
        route: '/layout-settings',
      };
    case SubMenuItemEnum.MONITOR_VIEWS:
      return {
        name: t('menu.menuItems.administration.subMenuItems.monitorViews'),
        icon: 'placeholder-20',
        route: '/monitor-views',
      };
    case SubMenuItemEnum.BOOKING_FORMATS:
      return {
        name: t('menu.menuItems.administration.subMenuItems.bookingFormats'),
        icon: 'placeholder-20',
        route: '/booking-formats',
      };
    case SubMenuItemEnum.TIMETABLE_SETTINGS:
      return {
        name: t('menu.menuItems.administration.subMenuItems.timetableSettings'),
        icon: 'placeholder-20',
        route: '/timetable-settings',
      };
    case SubMenuItemEnum.SETTINGS:
      return {
        name: t('menu.menuItems.administration.subMenuItems.settings'),
        icon: 'placeholder-20',
        route: '/settings',
      };
    case SubMenuItemEnum.INTEGRATION:
      return {
        name: t('menu.menuItems.administration.subMenuItems.integration'),
        icon: 'placeholder-20',
        route: '/integration',
      };
    case SubMenuItemEnum.PARENT_TEACHER_DAYS:
      return {
        name: t('menu.menuItems.administration.subMenuItems.parentTeacherDays'),
        icon: 'placeholder-20',
        route: '/parent-teacher-days',
      };
    case SubMenuItemEnum.PLAYGROUND:
      return {
        name: t('menu.menuItems.administration.subMenuItems.playground'),
        icon: 'placeholder-20',
        route: '/playground',
      };
    case SubMenuItemEnum.BACKUP:
      return {
        name: t('menu.menuItems.administration.subMenuItems.backup'),
        icon: 'placeholder-20',
        route: '/backup',
      };
    case SubMenuItemEnum.SYSTEM_LOCK:
      return {
        name: t('menu.menuItems.administration.subMenuItems.systemLock'),
        icon: 'placeholder-20',
        route: '/system-lock',
      };
    case SubMenuItemEnum.PRIVACY:
      return {
        name: t('menu.menuItems.administration.subMenuItems.privacy'),
        icon: 'placeholder-20',
        route: '/privacy',
      };
    case SubMenuItemEnum.ABOUT_WEBUNTIS:
      return {
        name: t('menu.menuItems.administration.subMenuItems.aboutWebuntis'),
        icon: 'placeholder-20',
        route: '/about-webuntis',
      };

    case SubMenuItemEnum.NOVASCHEM:
      return {
        name: t('menu.menuItems.administration.subMenuItems.novaschem'),
        icon: 'placeholder-20',
        route: '/import-novaschem',
      };
    case SubMenuItemEnum.HAMBURG:
      return {
        name: t('menu.menuItems.administration.subMenuItems.hamburg'),
        icon: 'placeholder-20',
        route: '/hamburg',
      };
    case SubMenuItemEnum.LUX_TEACHER_SALARY:
      return {
        name: t('menu.menuItems.administration.subMenuItems.luxTeacherSalary'),
        icon: 'placeholder-20',
        route: '/luxemburg',
      };

    case SubMenuItemEnum.PROFILE:
      return {
        name: t('menu.bottomUpViewMenuItems.profile'),
        icon: 'admin-profile-settings',
        route: '/profile',
      };
    case SubMenuItemEnum.MESSAGE_CENTER_2021_LISTS:
      return {
        name: t('general.quickFilter', { count: 2 }),
        icon: 'recipient_lists',
        route: '/messages/lists',
      };
    case SubMenuItemEnum.MESSAGE_CENTER_2021_SENT_MESSAGES:
      return {
        name: t('general.sentMessagesShort'),
        icon: 'sent',
        route: '/messages/sent',
      };
    case SubMenuItemEnum.MESSAGE_CENTER_2021_DRAFT_MESSAGES:
      return {
        name: t('general.draftMessagesShort'),
        icon: 'draft',
        route: '/messages/drafts',
      };
    case SubMenuItemEnum.PLATFORM_APPLICATION:
      return {
        name: t('menu.menuItems.administration.subMenuItems.platform'),
        icon: 'placeholder-20',
        route: '/platform-overview',
      };

    case SubMenuItemEnum.MY_COURSES:
      return {
        name: t('menu.menuItems.courses.subMenuItems.myCourses'),
        icon: 'my_courses',
        route: '/my-courses',
      };

    case SubMenuItemEnum.COURSE_PREVIEW:
      return {
        name: t('menu.menuItems.courses.subMenuItems.coursePreview'),
        icon: 'course_preview',
        route: '/course-preview',
      };

    case SubMenuItemEnum.COURSE_REGISTRATION:
      return {
        name: t('menu.menuItems.courses.subMenuItems.registration'),
        icon: 'course_registration',
        route: '/course-registration',
      };

    case SubMenuItemEnum.STUDENT_BOOKING:
      return {
        name: t('menu.menuItems.booking.subMenuItems.my'),
        icon: 'my_bookings',
        route: '/student-booking',
      };

    case SubMenuItemEnum.CLASS_REGISTER_OVERVIEW:
      return {
        name: t('general.overview'),
        icon: 'overview',
        route: '/class-register-overview',
      };

    default:
      console.warn('ERROR: No sub menu item defined for this key.');
  }
};

/**
 * Gets the submenu item related to the given item enum and applies a badge if necessary
 * @param item The item identifier that relates to the item that should be returned
 * @param badge Optional string that will be applied for the items badge property
 */
export const getSubMenuItem = (
  item: SubMenuItemEnum,
  badge?: string,
  activeHorizonId?: number,
): ISubMenuItem | undefined => {
  const subMenuItem = getSubMenuItemWithKey(item, activeHorizonId);
  if (subMenuItem) {
    subMenuItem.badge = badge;

    return subMenuItem;
  } else {
    return undefined;
  }
};
