import { computed } from 'mobx';

import { Store, inject } from '@/types/store';
import ConfigStore from '@/stores/config-store';
import { MenuItemEnum, SubMenuItemEnum } from '@/components/menu/menu-items';
import RightsStore, { Right, ElementType } from '@/stores/rights-store';

@Store()
class TodayPermissionsStore {
  private configStore = inject(ConfigStore);
  private rightStore = inject(RightsStore);

  @computed
  get canSeeBookingLockingNotice() {
    return this.configStore.isAdmin || this.configStore.isTeacher;
  }

  @computed
  get canWriteBookingLockingNotice() {
    return this.configStore.isAdmin || this.rightStore.canWrite(Right.BOOKING_LOCK, ElementType.ALL, false);
  }

  @computed
  get canSeeSystemLockingNotice() {
    return this.configStore.isAdmin;
  }

  @computed
  get canSeeExpireLicense() {
    return this.configStore.isAdmin;
  }

  @computed
  get canSeeSupportAccessToggle() {
    return this.configStore.isAdmin;
  }

  @computed
  get canSeeNewMotdButton() {
    return this.rightStore.canCreate(Right.MSG_OF_DAY, ElementType.ALL, false);
  }

  // quicklinks

  @computed
  get canSeeInboxQuickLink() {
    return this.rightStore.canRead(Right.MESSAGES, ElementType.ALL, false);
  }

  @computed
  get canSeeStudentAbsencesQuickLink() {
    return this.configStore.checkMenuItemPermission(MenuItemEnum.STUDENTABSENCES);
  }

  @computed
  get canSeeAbsencesQuickLink() {
    return this.configStore.checkSubMenuItemPermission(SubMenuItemEnum.ABSENCES);
  }

  @computed
  get canSeeOpenPeriods() {
    return this.configStore.checkSubMenuItemPermission(SubMenuItemEnum.OPEN_PERIODS);
  }

  @computed
  get canSeeStudentHomeworkQuickLink() {
    return this.configStore.checkMenuItemPermission(MenuItemEnum.STUDENT_HOMEWORK);
  }

  @computed
  get canSeeStudentGradesQuickLink() {
    return this.configStore.checkMenuItemPermission(MenuItemEnum.STUDENT_GRADES);
  }

  @computed
  get canSeeClassServicesQuickLink() {
    return this.configStore.checkSubMenuItemPermission(SubMenuItemEnum.CLASS_SERVICES);
  }

  @computed
  get canSeeStudentClassServicesQuickLink() {
    return this.configStore.checkMenuItemPermission(MenuItemEnum.STUDENT_CLASS_SERVICES);
  }

  @computed
  get canSeeTasksQuickLink() {
    return this.rightStore.canRead(Right.USERTASK, ElementType.ALL, false);
  }

  @computed
  get canSeeContactHoursQuickLink() {
    return this.configStore.isTeacher && this.rightStore.canRead(Right.OFFICEHOURS, ElementType.ALL, false);
  }

  @computed
  get canSeeParentTeacherDays() {
    return this.rightStore.canRead(Right.PARENTSDAY, ElementType.ALL, false);
  }
}

export default TodayPermissionsStore;
