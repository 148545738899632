import React, { useMemo } from 'react';

import { IDropDownItem, SingleDropDown } from '@/ui-components/drop-down/drop-down';
import { TestIds } from '@/testIds';
import { measureTextWidth } from '@te/standard/utils/measure-text-width';
import {
  DEFAULT_DROPDOWN_ITEM_WIDTH,
  DROPDOWN_ABSOLUTE_MAX_WIDTH,
  WIDTH_CALCULATION_MARGIN_OF_ERROR,
  WIDTH_CALCULATION_PADDING,
} from '@te/standard/components/header/entity-selector/timetable-entity-selector';

interface IProps {
  filterItems: IDropDownItem[];
  selectedValue: string | undefined;
  onValueSelected: (value: number) => void;
}

const TimetableUserOwnSelector = (props: IProps) => {
  const { filterItems, selectedValue, onValueSelected } = props;

  const dropdownWidth = useMemo(() => {
    const labelWidthsDesc = filterItems
      .map(
        (filterItem) =>
          measureTextWidth(filterItem.label, {
            font: 'AvenirNextLTPro',
            fontWeight: 600,
            fontSize: 14,
          }) ?? DEFAULT_DROPDOWN_ITEM_WIDTH,
      )
      .sort((a, b) => b - a);
    const maxLabelWidth = labelWidthsDesc[0] ?? DEFAULT_DROPDOWN_ITEM_WIDTH;
    const calculatedDropdownWidth = maxLabelWidth + WIDTH_CALCULATION_MARGIN_OF_ERROR + 2 * WIDTH_CALCULATION_PADDING;
    return Math.min(calculatedDropdownWidth, DROPDOWN_ABSOLUTE_MAX_WIDTH);
  }, [filterItems]);

  return (
    <>
      {filterItems.length > 1 ? (
        <SingleDropDown
          showArrowControls
          value={selectedValue}
          onChange={(value) => onValueSelected(Number(value))}
          hideIcons
          searchable
          minWidth={dropdownWidth}
          maxWidth={dropdownWidth}
          items={filterItems}
          testId={TestIds.TIMETABLE_ITEM_SELECTOR}
        />
      ) : (
        <div style={{ width: 200 }} />
      )}
    </>
  );
};

export default TimetableUserOwnSelector;
