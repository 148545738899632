import React, { ReactNode } from 'react';
import { Form, Radio } from 'antd';
import clsx from 'clsx';

import { IFormItemProps } from '@/ui-components/wu-form/wu-form-item';
import './form-radio-buttons.less';

interface IProps extends IFormItemProps<number | string> {
  options: Map<number | string, string>;
  display?: 'horizontal' | 'vertical';
  onChange?: (value: number | string | undefined) => void;
}

export const FormRadioButtons = (props: IProps) => {
  const options: ReactNode[] = [];
  props.options.forEach((label, key) => {
    options.push(
      <Radio value={key} key={key}>
        {label}
      </Radio>,
    );
  });

  return (
    <Form.Item
      name={props.name}
      label={props.label}
      initialValue={props.initialValue}
      className="form-radio-buttons"
      rules={props.rules}
      dependencies={props.dependencies}
    >
      <Radio.Group
        className={clsx({
          'horizontal-align': props.display === 'horizontal',
          'vertical-align': props.display === 'vertical',
        })}
        onChange={(e) => props.onChange && props.onChange(e.target.value)}
        disabled={props.disabled}
        data-testid={props.testId}
      >
        {options}
      </Radio.Group>
    </Form.Item>
  );
};
