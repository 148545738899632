import { Input as AntdInput, InputProps } from 'antd';
import * as React from 'react';
import clsx from 'clsx';
import { v4 } from 'uuid';

import { ITestComponentProps } from '@/types/test-component-props';

import './input.less';

export interface IInputDefaultProps extends InputProps, ITestComponentProps {
  variant?: undefined;
  label?: never;
}

export interface IInputLargeProps extends React.HTMLProps<HTMLInputElement>, ITestComponentProps {
  variant: 'large';
  label: string;
}

export interface IFloatingLabelInputProps extends React.HTMLProps<HTMLInputElement>, ITestComponentProps {
  variant: 'floating';
  label: string;
}

function LargeInput({ placeholder, label, className, testId, ...props }: IInputLargeProps) {
  const id = React.useMemo(() => v4(), []);

  return (
    <div className={clsx('untis-input untis-input--large', className)}>
      <input {...props} id={id} placeholder={placeholder ?? ' '} data-testid={testId} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

function FloatingLabelInput({ label, className, testId, ...props }: IFloatingLabelInputProps) {
  const id = React.useMemo(() => v4(), []);

  return (
    <div className={clsx('floating-label-input-container', className)}>
      <input {...props} id={id} className="floating-label-input" placeholder=" " data-testid={testId} />
      <label htmlFor={id} className="floating-label-input-label">
        {label}
      </label>
    </div>
  );
}

export type IInputProps = IInputDefaultProps | IInputLargeProps | IFloatingLabelInputProps;

const Input = (props: IInputProps) => {
  if (props.variant === 'large') {
    return <LargeInput {...props} />;
  }

  if (props.variant === 'floating') {
    return <FloatingLabelInput {...props} />;
  }

  const { testId, ...rest } = props;

  return <AntdInput {...rest} data-testid={testId} />;
};

export default Input;
