import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IReasonsOfExemptionRow, ReasonsOfExemptionStore } from './reasons-of-exemption-store';

import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { FormSection } from '@/ui-components/wu-form/form-section/form-section';
import { IFormButton, WUForm } from '@/ui-components/wu-form/wu-form';
import { WUFormCol } from '@/ui-components/wu-form/wu-form-col/wu-form-col';
import { WUFormRow } from '@/ui-components/wu-form/wu-form-row/wu-form-row';
import { validateForm } from '@/utils/form/form-util';
import { IErrorResponseData } from '@/utils/error-handling/error-handling';
import NotificationStore from '@/stores/notification-store/notification-store';
import FormTaggedSwitch from '@/ui-components/wu-form/form-tagged-switch/form-tagged-switch';

export interface IReasonsOfExemptionFormData {
  id: number;
  name: string;
  longName: string;
  active: boolean;
  key: number;
}

interface IProps {
  store: ReasonsOfExemptionStore;
  reasonOfExemption?: IReasonsOfExemptionRow;
}

export const ReasonsOfExemptionForm = observer((props: IProps) => {
  const store = props.store;
  const { t } = useTranslation();
  const modalStore = useStore(ModalStore);
  const isInEditMode = !!props.reasonOfExemption;
  const notificationStore = useStore(NotificationStore);
  const disabled = isInEditMode && !store.canEdit;

  const leftButtons: IFormButton[] =
    isInEditMode && store.canDelete
      ? [
          {
            label: t('general.delete'),
            type: 'destructive',
            onClick: () => {
              if (props.reasonOfExemption?.id) {
                store.deleteReasonOfExemption(props.reasonOfExemption.id);
              }
            },
          },
        ]
      : [];

  const rightButtons: IFormButton[] = [];
  if (!isInEditMode) {
    rightButtons.push({
      label: t('general.saveAndNew'),
      type: 'primary',
      outline: true,
      disabled: store.submitAllDisabled,
      onClick: () => {
        validateForm(store.form).then((isValid) => {
          if (isValid) {
            handleSaveNewReasonOfExemption(store.form.getFieldsValue(), true);
          }
        });
      },
    });
  }
  rightButtons.push({
    label: disabled ? t('general.ok') : t('general.cancel'),
    onClick: () => {
      modalStore.closeModal();
    },
  });

  const handleSaveNewReasonOfExemption = (value: IReasonsOfExemptionFormData, saveAndNew: boolean) => {
    store
      .createReasonOfExemption(value)
      .then(() => {
        notificationStore.success({ title: t('general.reasonOfExemptionCreated') });
        store.form.resetFields();
        if (!saveAndNew) {
          modalStore.closeModal();
        }
      })
      .catch((error) => {
        if ((error.response?.data as IErrorResponseData)?.errorCode === 'VALIDATION_ERROR') {
          notificationStore.error({
            title: t('general.reasonOfExemptionCouldNotBeCreated'),
            message: t('menu.menuItems.' + error.response.data.validationErrors[0].errorMessage),
          });
        } else {
          notificationStore.error({ title: t('general.reasonOfExemptionCouldNotBeCreated') });
        }
      });
  };

  const handleChangeReasonOfExemption = (value: IReasonsOfExemptionFormData) => {
    store
      .updateReasonOfExemption(value, props.reasonOfExemption!)
      .then(() => {
        notificationStore.success({ title: t('general.reasonOfExemptionUpdated') });
        store.form.resetFields();
        modalStore.closeModal();
      })
      .catch(() => {
        notificationStore.error({ title: t('general.reasonOfExemptionCouldNotBeEdited') });
      });
  };
  return (
    <WUForm<IReasonsOfExemptionFormData>
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      fixedButtonBar={true}
      form={store.form}
      hideSubmitButton={disabled}
      onSubmit={
        isInEditMode && store.canCreate
          ? (value) => handleChangeReasonOfExemption(value)
          : (value) => handleSaveNewReasonOfExemption(value, false)
      }
      onDisableChange={(disabled) => store.setSubmitAllDisabled(disabled)}
      hideTopBorder
      maxWidth
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={`${t('general.status')}`} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormTaggedSwitch
              name="active"
              initialValue={!!props.reasonOfExemption ? props.reasonOfExemption.isActive : true}
              onLabel={t('general.active')}
              offLabel={t('general.inactive')}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={`${t('general.name')}`} description={t('general.asteriskRequired')} />
          </WUFormCol>
          <WUFormCol lg={4}>
            <FormInput
              variant="floating"
              label={`${t('general.name')}*`}
              name="name"
              initialValue={!!props.reasonOfExemption?.name ? props.reasonOfExemption.name : ''}
              rules={[
                { required: true, message: t('general.inputRequired') },
                { max: 20, message: t('general.valueMustBeWithinRange', { min: 1, max: 20 }) },
              ]}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormInput
              variant="floating"
              label={t('general.longName')}
              name="longName"
              initialValue={props.reasonOfExemption?.longName ? props.reasonOfExemption.longName : ''}
              rules={[{ max: 100, message: t('general.valueMustBeWithinRange', { min: 0, max: 100 }) }]}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});
