import { computed } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';

import { TimetableUrl } from '@te/standard/stores/url/timetable-url-store';
import ModalStore from '@/stores/modal-store';
import { inject } from '@/types/store';
import { IFullDayEvent, IGridEntry } from '@te/standard/stores/data/timetable-data-store';
import { TimetableFilterStore } from '@te/standard/stores/filter/timetable-filter-store';
import { ElementType } from '@/stores/rights-store';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';

export type TimetableEntry = IGridEntry | IFullDayEvent;

export abstract class AbstractTimetableDetailsModalHandler {
  protected modalStore: ModalStore = inject(ModalStore);
  protected timetableFilterStore: TimetableFilterStore = inject(TimetableFilterStore);
  protected timetableFormatStore: TimetableFormatStore = inject(TimetableFormatStore);

  abstract shouldHandleTimetableUrl(timetableUrl: TimetableUrl): boolean;
  abstract handleTimetableUrl(timetableUrl: TimetableUrl, onCloseCallback: () => void): void;
  abstract shouldHandleTimetableEntry(entry: TimetableEntry): boolean;
  abstract openDetailsModal(entry: TimetableEntry, onCloseCallback: () => void): void;
  abstract getTimetableExtraPath(entry: TimetableEntry): string;

  @computed
  protected get timetableEntityTypeId(): number {
    switch (this.timetableFormatStore.timetableEntityType) {
      case 'class':
      case 'my-class':
        return Number(ElementType.CLASS);
      case 'student':
      case 'my-student':
        return Number(ElementType.STUDENT);
      case 'teacher':
      case 'my-teacher':
        return Number(ElementType.TEACHER);
      case 'room':
        return Number(ElementType.ROOM);
      case 'resource':
        return Number(ElementType.RESOURCE);
      case 'subject':
        return Number(ElementType.SUBJECT);
      default:
        return Number(ElementType.CLASS);
    }
  }

  protected getId(entry: TimetableEntry): number {
    let id = -1;
    if ('periodIds' in entry) {
      id = entry.periodIds[0];
    } else if ('id' in entry) {
      id = entry.id;
    }
    return id;
  }

  protected getStartDateTime(entry: TimetableEntry): Dayjs {
    let result = dayjs();
    if (this.isGridEntry(entry)) {
      result = (entry as IGridEntry).lessonCardProps.startDateTime;
    } else if (this.isFullDayEvent(entry)) {
      result = (entry as IFullDayEvent).startDateTime;
    }
    return result;
  }

  protected getEndDateTime(entry: TimetableEntry): Dayjs {
    let result = dayjs();
    if (this.isGridEntry(entry)) {
      result = (entry as IGridEntry).lessonCardProps.endDateTime;
    } else if (this.isFullDayEvent(entry)) {
      result = (entry as IFullDayEvent).endDateTime;
    }
    return result;
  }

  protected isGridEntry(entry: TimetableEntry): boolean {
    return 'periodIds' in entry;
  }

  protected isFullDayEvent(entry: TimetableEntry): boolean {
    return 'id' in entry;
  }
}
