import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Trans } from 'react-i18next';

import { Button, IconButton } from '@/ui-components';
import './context-actions.less';

export interface IContextAction<T> {
  label: string;
  onClick?: (rows: T[]) => void;
  icon?: string;
  testId?: string;
}

interface IProps<T> {
  actions: IContextAction<T>[];
  rows: T[];
  rowCountLabel?: string;
}

export function ContextActions<T>(props: IProps<T>) {
  const [rowCount, setRowCount] = useState<number | undefined>();
  const prevRowCountRef = useRef<number | undefined>();
  const [rowCountLabel, setRowCountLabel] = useState<string | undefined>();
  const prevRowCountLabelRef = useRef<string | undefined>();
  const isOpen = props.rows.length > 0;

  useEffect(() => {
    setRowCount(props.rows.length);
    setRowCountLabel(props.rowCountLabel);
  }, [props.rows, props.rowCountLabel]);

  useEffect(() => {
    prevRowCountRef.current = rowCount;
    prevRowCountLabelRef.current = rowCountLabel;
  }, [rowCount, rowCountLabel]);

  const className = clsx('context-actions', {
    open: isOpen,
  });

  const renderRowCounter = (count: number | undefined, label: string | undefined) => {
    return (
      <div className="row-counter">
        <span className="count">
          {/* The <Trans/> component is needed here, because the crowdin text contains a <strong/> tag,
          that lets the counter appear bold.*/}
          {label ? label : <Trans i18nKey="general.numberOfRowsSelected" values={{ count: rowCount }} />}
        </span>
      </div>
    );
  };

  return (
    <div className={className}>
      <div className="content-container">
        <div className="helper" />
        <div className="content">
          {isOpen
            ? renderRowCounter(rowCount, rowCountLabel)
            : renderRowCounter(prevRowCountRef.current, prevRowCountLabelRef.current)}
          {props.actions.map((action, index) => {
            if (action.icon) {
              return (
                <IconButton
                  onClick={() => {
                    action.onClick && action.onClick(props.rows);
                  }}
                  type={action.icon}
                  tooltip={{
                    title: action.label,
                    placement: 'top',
                  }}
                  ariaLabel={action.label}
                  key={index}
                  testId={action.testId}
                />
              );
            }
            return (
              <Button
                outline={true}
                key={index}
                disabled={!isOpen}
                onClick={() => {
                  action.onClick && action.onClick(props.rows);
                }}
                testId={action.testId}
              >
                {action.label}
              </Button>
            );
          })}
        </div>
        <div className="helper" />
      </div>
    </div>
  );
}
