import React, { ReactNode, HTMLAttributes } from 'react';
import clsx from 'clsx';

import { Box, IBoxProps } from '@/ui-components/box/box';
import { Stack, IStackProps } from '@/ui-components/stack/stack';
import { ITestComponentProps } from '@/types/test-component-props';

import './flex.less';

type AllAttrs = {
  Box: IBoxProps;
  Stack: IStackProps;
  div: HTMLAttributes<HTMLDivElement>;
};

export interface IFlexProps extends ITestComponentProps {
  as?: typeof Box | typeof Stack | 'div';
  children: ReactNode;
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  gap?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
  className?: string;
}

export function Flex<T extends keyof AllAttrs>({
  as,
  children,
  flexDirection,
  justifyContent,
  alignItems,
  gap,
  className,
  testId,
  ...props
}: IFlexProps & AllAttrs[T]) {
  const Element: React.ElementType = as ? as : 'div';

  return (
    <Element
      className={clsx(
        'flex-container',
        `flex-direction-${flexDirection ?? 'row'}`,
        `justify-content-${justifyContent ?? 'flex-start'}`,
        `align-items-${alignItems ?? 'stretch'}`,
        `gap-${gap ?? 'none'}`,
        className,
      )}
      data-testid={testId}
      {...props}
    >
      {children}
    </Element>
  );
}
