import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { WUForm } from '@/ui-components';
import { FormCheckbox, FormInput, FormSection, IFormButton, WUFormCol, WUFormRow } from '@/ui-components/wu-form';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import ICalFormatSettingsStore, {
  ICalFormatSettingsFormData,
  ICalFormatSettingsRow,
} from '@te/settings/i-cal-format/i-cal-format-settings-store';
import FormTaggedSwitch from '@/ui-components/wu-form/form-tagged-switch/form-tagged-switch';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import { ICalPropertyEnum } from '@untis/wu-rest-view-api/api';

import './i-cal-format-settings-form.less';

interface IProps {
  store: ICalFormatSettingsStore;
  format?: ICalFormatSettingsRow;
}

const ICalFormatSettingsForm = observer((props: IProps) => {
  const { t } = useTranslation();
  const { store } = props;

  const modalStore = useStore(ModalStore);

  const isInEditMode = !!props.format;
  const leftButtons: IFormButton[] = isInEditMode
    ? [
        {
          label: t('general.delete'),
          type: 'destructive',
          onClick: () => {
            if (props.format) {
              store.deleteICalFormat(props.format.key);
            }
          },
        },
      ]
    : [];

  const rightButtons: IFormButton[] = [
    {
      label: t('general.cancel'),
      onClick: () => {
        modalStore.closeModal();
      },
    },
  ];

  return (
    <WUForm<ICalFormatSettingsFormData>
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      fixedButtonBar
      onSubmit={
        isInEditMode
          ? (value) => store.updateICalFormat(value, props.format?.key ?? -1)
          : (value) => store.createICalFormat(value)
      }
      maxWidth
      paddingTop
      className="i-cal-format-form"
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={3}>
            <FormSection text={t('general.general')} description={t('general.status')} />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormTaggedSwitch
              name="active"
              testId="active"
              initialValue={props.format ? props.format.active : true}
              onLabel={t('general.active')}
              offLabel={t('general.inactive')}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3}>
            <FormSection description={`${t('general.name')}\n${t('general.asteriskRequired')}`} />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormInput
              name="name"
              testId="name"
              initialValue={props?.format?.name ? props.format.name : ''}
              rules={[
                { required: true, message: t('general.inputRequired') },
                { max: 20, message: t('general.valueMustBeWithinRange', { min: 1, max: 20 }) },
              ]}
              variant="floating"
              label={`${t('general.name')}*`}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3}>
            <FormSection text={t('general.element')} description={t('general.class')} />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormSingleSelect
              name="classTimetableProperty"
              testId="class-property"
              initialValue={
                props.format && props.format.classTimetableProperty
                  ? props.format.classTimetableProperty
                  : store.classTimetableProperties[0]?.id
              }
              items={store.classTimetableProperties}
              placeholder={t('general.elementProperty')}
              styleVariant="floating"
              allowClear={false}
            />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormSingleSelect
              name="classICalProperty"
              testId="class-i-cal-property"
              initialValue={props.format ? props.format.classICalProperty : ICalPropertyEnum.NONE.toString()}
              items={store.iCalProperties}
              placeholder={t('general.iCalProperty')}
              styleVariant="floating"
              allowClear={false}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3}>
            <FormSection description={t('general.teacher')} />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormSingleSelect
              name="teacherTimetableProperty"
              testId="teacher-property"
              initialValue={
                props.format && props.format.teacherTimetableProperty
                  ? props.format.teacherTimetableProperty
                  : store.teacherTimetableProperties[0]?.id
              }
              items={store.teacherTimetableProperties}
              placeholder={t('general.elementProperty')}
              styleVariant="floating"
              allowClear={false}
            />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormSingleSelect
              name="teacherICalProperty"
              testId="teacher-i-cal-property"
              initialValue={props.format ? props.format.teacherICalProperty : ICalPropertyEnum.NONE.toString()}
              items={store.iCalProperties}
              placeholder={t('general.iCalProperty')}
              styleVariant="floating"
              allowClear={false}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3}>
            <FormSection description={t('general.subject')} />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormSingleSelect
              name="subjectTimetableProperty"
              testId="subject-property"
              initialValue={
                props.format && props.format.subjectTimetableProperty
                  ? props.format.subjectTimetableProperty
                  : store.subjectTimetableProperties[0]?.id
              }
              items={store.subjectTimetableProperties}
              placeholder={t('general.elementProperty')}
              styleVariant="floating"
              allowClear={false}
            />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormSingleSelect
              name="subjectICalProperty"
              testId="subject-i-cal-property"
              initialValue={props.format ? props.format.subjectICalProperty : ICalPropertyEnum.NONE.toString()}
              items={store.iCalProperties}
              placeholder={t('general.iCalProperty')}
              styleVariant="floating"
              allowClear={false}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3}>
            <FormSection description={t('general.room')} />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormSingleSelect
              name="roomTimetableProperty"
              testId="room-property"
              initialValue={
                props.format && props.format.roomTimetableProperty
                  ? props.format.roomTimetableProperty
                  : store.roomTimetableProperties[0]?.id
              }
              items={store.roomTimetableProperties}
              placeholder={t('general.elementProperty')}
              styleVariant="floating"
              allowClear={false}
            />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormSingleSelect
              name="roomICalProperty"
              testId="room-i-cal-property"
              initialValue={props.format ? props.format.roomICalProperty : ICalPropertyEnum.NONE.toString()}
              items={store.iCalProperties}
              placeholder={t('general.iCalProperty')}
              styleVariant="floating"
              allowClear={false}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3}>
            <FormSection description={t('general.resouce')} />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormSingleSelect
              name="resourceTimetableProperty"
              testId="resource-property"
              initialValue={
                props.format && props.format.resourceTimetableProperty
                  ? props.format.resourceTimetableProperty
                  : store.resourceTimetableProperties[0]?.id
              }
              items={store.resourceTimetableProperties}
              placeholder={t('general.elementProperty')}
              styleVariant="floating"
              allowClear={false}
            />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormSingleSelect
              name="resourceICalProperty"
              testId="resource-i-cal-property"
              initialValue={props.format ? props.format.resourceICalProperty : ICalPropertyEnum.NONE.toString()}
              items={store.iCalProperties}
              placeholder={t('general.iCalProperty')}
              styleVariant="floating"
              allowClear={false}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3}>
            <FormSection description={t('general.booking')} />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormSingleSelect
              name="bookingTimetableProperty"
              testId="booking-property"
              initialValue={
                props.format && props.format.bookingTimetableProperty
                  ? props.format.bookingTimetableProperty
                  : store.bookingTimetableProperties[0]?.id
              }
              items={store.bookingTimetableProperties}
              placeholder={t('general.elementProperty')}
              styleVariant="floating"
              allowClear={false}
            />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormSingleSelect
              name="bookingICalProperty"
              testId="booking-i-cal-property"
              initialValue={props.format ? props.format.bookingICalProperty : ICalPropertyEnum.NONE.toString()}
              items={store.iCalProperties}
              placeholder={t('general.iCalProperty')}
              styleVariant="floating"
              allowClear={false}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={3}>
            <FormSection description={t('general.exams')} />
          </WUFormCol>
          <WUFormCol lg={3}>
            <FormCheckbox
              name="highlightExams"
              testId="highlight-exams"
              initialValue={props.format ? props.format.highlightExams : false}
              text={t('general.highlightExams')}
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});

export default ICalFormatSettingsForm;
