import { observer } from 'mobx-react-lite';
import React from 'react';
import Div100vh from 'react-div-100vh';
import { useTranslation } from 'react-i18next';

import PlatformList from '../platform-list/platform-list';

import useStore from '@/hooks/useStore';
import PlatformStore from '@pa/stores/platform-store';

import './register-new-platform-view.less';

const RegisterNewPlatformView = observer(() => {
  const { t } = useTranslation();
  const { isLoadingAvailablePlatforms, availablePlatforms } = useStore(PlatformStore);

  return (
    <Div100vh className="register-new-platform-view">
      <h1>{t('platform.availableApplications')}</h1>
      <div className="page-content">
        <PlatformList isLoading={isLoadingAvailablePlatforms} platforms={availablePlatforms} isRegisterMode={true} />
      </div>
    </Div100vh>
  );
});
export default RegisterNewPlatformView;
