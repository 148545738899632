import { action } from 'mobx';
import React from 'react';

import {
  AbstractTimetableDetailsModalHandler,
  TimetableEntry,
} from '@te/standard/stores/url/abstract-timetable-details-modal-handler';
import { TimetableUrl } from '@te/standard/stores/url/timetable-url-store';
import CalendarEntryDetailView, {
  ITimeSlotArguments,
} from '@/pages/calendar-entry/calendar-entry-detail-view/calendar-entry-detail-view';
import { dayJsFromDateTimeString, formatDateTimeEN } from '@/utils/date/date-util';
import { LessonCardType } from '@/components/lesson-card/lesson-card';
import { IGridEntry } from '@te/standard/stores/data/timetable-data-store';
import { Store } from '@/types/store';

export const UNTIS_PERIODS: LessonCardType[] = [
  LessonCardType.NORMAL_TEACHING_PERIOD,
  LessonCardType.STAND_BY_PERIOD,
  LessonCardType.OFFICE_HOUR,
  LessonCardType.BREAK_SUPERVISION,
  LessonCardType.EXAM,
  LessonCardType.ADDITIONAL_PERIOD,
  LessonCardType.EVENT,
];

@Store()
export class UntisLessonDetailsModalHandler extends AbstractTimetableDetailsModalHandler {
  shouldHandleTimetableUrl(timetableUrl: TimetableUrl): boolean {
    return timetableUrl.subPath === 'lessonDetails';
  }

  @action
  handleTimetableUrl(timetableUrl: TimetableUrl, onCloseCallback: () => void) {
    this.openPeriodDetails(
      {
        elementId: Number(timetableUrl.timetableEntityId),
        elementType: Number(timetableUrl.timetableEntityTypeId),
        startDateTime: dayJsFromDateTimeString(timetableUrl.startDateTime ?? ''),
        endDateTime: dayJsFromDateTimeString(timetableUrl.endDateTime ?? ''),
        initialIsBlockSelected: Boolean(timetableUrl.isBlock),
        initialSelectedPeriodId: Number(timetableUrl.entryId),
      },
      onCloseCallback,
    );
  }

  shouldHandleTimetableEntry(entry: TimetableEntry): boolean {
    return this.isGridEntry(entry) && UNTIS_PERIODS.includes((entry as IGridEntry).lessonCardProps.type);
  }

  @action
  openDetailsModal(entry: TimetableEntry, onCloseCallback: () => void) {
    if (this.isGridEntry(entry)) {
      const elementId = this.timetableFilterStore.selectedFilterValue ?? -1;
      const elementType = this.timetableEntityTypeId;
      const startDateTime = this.getStartDateTime(entry);
      const endDateTime = this.getEndDateTime(entry);
      const initialIsBlockSelected = (entry as IGridEntry).periodIds.length > 1;
      const initialSelectedPeriodId = this.getId(entry);

      this.openPeriodDetails(
        {
          elementId,
          elementType,
          startDateTime,
          endDateTime,
          initialIsBlockSelected,
          initialSelectedPeriodId,
        },
        onCloseCallback,
      );
    }
  }

  @action.bound
  getTimetableExtraPath(entry: TimetableEntry): string {
    const entryId = this.getId(entry);
    const timetableEntityId = this.timetableFilterStore.selectedFilterValue ?? -1;
    const timetableEntityTypeId = this.timetableEntityTypeId;
    const formattedStartTime = formatDateTimeEN(this.getStartDateTime(entry));
    const formattedEndTime = formatDateTimeEN(this.getEndDateTime(entry));
    const isBlock = (entry as IGridEntry).periodIds.length > 1;

    // eslint-disable-next-line max-len
    return `/lessonDetails/${entryId}/${timetableEntityId}/${timetableEntityTypeId}/${formattedStartTime}/${formattedEndTime}/${isBlock}`;
  }

  @action
  private openPeriodDetails(period: ITimeSlotArguments, onCloseCallback: () => void) {
    const { elementId, elementType, startDateTime, endDateTime, initialIsBlockSelected, initialSelectedPeriodId } =
      period;
    this.modalStore.openModalDialog({
      children: (
        <CalendarEntryDetailView
          data={{
            elementId,
            elementType,
            startDateTime,
            endDateTime,
            initialIsBlockSelected,
            initialSelectedPeriodId,
          }}
          tab="class-register"
          embedDetailsIframe={true}
        />
      ),
      size: 'full-size-content',
      onAfterClose: () => onCloseCallback(),
    });
  }
}
