import React, { useMemo } from 'react';
import clsx from 'clsx';

import LessonCardIndicatorShape, { LessonCardIndicatorShapeType } from './lesson-card-indicator-shape';

import { LessonCardDisplayVariant } from '@/components/lesson-card/lesson-card';
import { LESSON_CARD_ROW_HEIGHT } from '@/components/lesson-card/hooks/use-computed-lesson-card-props';

import './lesson-card-indicator.less';

export type LessonIndicatorType = 'cancelled' | 'exam' | 'change' | 'external' | 'full-day-event';

const LessonIndicatorTypeDefs: {
  [key in LessonIndicatorType]: { color: string; shape: LessonCardIndicatorShapeType };
} = {
  cancelled: { color: '#ff4040', shape: 'triangle' },
  exam: { color: '#ffd615', shape: 'circle' },
  change: { color: '#199c5f', shape: 'square' },
  external: { color: '#5180F9', shape: 'rhombus' },
  'full-day-event': { color: '#ffffff', shape: 'circle' },
};

export type LessonIndicator = {
  type: LessonIndicatorType;
  displayName: string;
};

export const getIndicatorTypeColor = (indicatorType: LessonIndicatorType) => {
  return LessonIndicatorTypeDefs[indicatorType]?.color ?? '#ffffff';
};

export const getIndicatorTypeShape = (indicatorType: LessonIndicatorType): LessonCardIndicatorShapeType => {
  return LessonIndicatorTypeDefs[indicatorType]?.shape ?? 'circle';
};

export function LessonCardIndicator({
  indicator,
  displayVariant,
}: {
  indicator: LessonIndicator;
  displayVariant?: LessonCardDisplayVariant;
}) {
  const type = useMemo(() => {
    if (displayVariant === 'minimal-height') {
      return 'horizontal-line';
    } else if (displayVariant === 'minimal-width') {
      return 'vertical-line';
    } else {
      return getIndicatorTypeShape(indicator.type);
    }
  }, [displayVariant, indicator]);

  return (
    <div
      className={clsx('lesson-card-indicator', indicator.type + '-indicator', {
        'display-variant-long': displayVariant === 'long',
        'no-background': displayVariant === 'minimal-height' || displayVariant === 'minimal-width',
      })}
      data-testid={`lesson-card-indicator-${indicator.type}`}
      style={{ height: LESSON_CARD_ROW_HEIGHT }}
    >
      {displayVariant === 'long' ? (
        <span className="lesson-card-indicator-text">{indicator.displayName}</span>
      ) : (
        <LessonCardIndicatorShape type={type} color={getIndicatorTypeColor(indicator.type)} width={12} height={12} />
      )}
    </div>
  );
}
