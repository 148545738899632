import React, { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import './scroller.less';
import { Icon } from '@/ui-components';
import { LoadingIndicator } from '@/ui-components/scroller/loading-indicator/loading-indicator';
import { ScrollerStore } from '@/ui-components/scroller/scroller-store';
import useScrollerIntersector from '@/ui-components/scroller/useScrollerIntersector';

export interface IScrollerProps {
  disabled?: boolean; // disables scroller header/footer target (so it is not visible e.g. in case of initial loading)
  scrollableParentRef: RefObject<HTMLElement>;
  children: ReactNode;
  hintDown?: string;
  onScrollToEnd?: () => Promise<any>;
  disableScrollDown?: boolean;
  disableScrollDownLabel?: string;
  hintUp?: string;
  onScrollToStart?: () => Promise<any>;
  disableScrollUp?: boolean;
  disableScrollUpLabel?: string;
  testId?: string;
}

/**
 * Component that can be used to wrap some content and to display hints for the user
 * at the top and bottom of the content.
 * Particular async callback is executed when the user scrolls above the upper hint or below the lower hint
 * and loading indicator is displayed until callback is done.
 * Uses the Intersection Observer API
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 *
 * Usage: This component should be between a scrollable container and content that can overflow.
 *
 * e.g.:
 *
 <div id="parent-container">
 <Scroller hint="Scroll down to load more" onScrollToEnd={...} ...>
 <div>
 // your content goes here
 </div>
 </Scroller>
 </div>
 */
export const Scroller = observer((props: IScrollerProps) => {
  const downTargetRef = useRef<HTMLDivElement>(null);
  const upperTargetRef = useRef<HTMLDivElement>(null);
  const [scrollDownStore] = useState<ScrollerStore>(() => new ScrollerStore('bottom'));
  const [scrollUpStore] = useState<ScrollerStore>(() => new ScrollerStore('top'));

  useScrollerIntersector({
    intersectedRef: downTargetRef,
    store: scrollDownStore,
    container: props.scrollableParentRef,
    onScrolled: props.onScrollToEnd,
    disableScroll: props.disableScrollDown,
  });

  useScrollerIntersector({
    intersectedRef: upperTargetRef,
    store: scrollUpStore,
    container: props.scrollableParentRef,
    onScrolled: props.onScrollToStart,
    disableScroll: props.disableScrollUp,
  });

  useEffect(() => {
    // scroll a little bit up so the icon is not visible in case of upper scroller
    if (!props.disabled && !props.disableScrollUp && props.scrollableParentRef.current) {
      props.scrollableParentRef.current.scrollTo({ top: 100 });
    }
  }, [props.disabled, props.disableScrollUp, props.scrollableParentRef]);

  const growClassName = (store: ScrollerStore) =>
    clsx(
      'dynamic-grow',
      store.level === 1 && 'dynamic-grow--1',
      store.level === 2 && 'dynamic-grow--2',
      store.level === 3 && 'dynamic-grow--3',
      store.level === 4 && 'dynamic-grow--4',
      store.level === 5 && 'dynamic-grow--5',
      store.level === 6 && 'dynamic-grow--6',
      store.level === 7 && 'dynamic-grow--7',
      store.level === 8 && 'dynamic-grow--8',
      store.level === 9 && 'dynamic-grow--9',
      store.level > 9 && 'dynamic-grow--10',
    );

  const renderScrollerHintDown = () => {
    const testId = props.testId ? `${props.testId}-bottom-label` : undefined;
    return (
      <>
        {!scrollDownStore.inProgress && (
          <div className="hint" data-testid={testId}>
            {props.hintDown}
          </div>
        )}
        {scrollDownStore.inProgress && <LoadingIndicator />}
        <div className="target" ref={downTargetRef}>
          {!scrollDownStore.inProgress && !scrollDownStore.hideIcon && (
            <Icon className={growClassName(scrollDownStore)} type="download" />
          )}
        </div>
      </>
    );
  };

  const renderScrollerHintUp = () => {
    const testId = props.testId ? `${props.testId}-top-label` : undefined;
    return (
      <>
        <div className="target target--up" ref={upperTargetRef}>
          {!scrollUpStore.inProgress && !scrollUpStore.hideIcon && (
            <Icon className={growClassName(scrollUpStore)} type="download" />
          )}
        </div>
        {scrollUpStore.inProgress && <LoadingIndicator />}
        {!scrollUpStore.inProgress && (
          <div className="hint" data-testid={testId}>
            {props.hintUp}
          </div>
        )}
      </>
    );
  };

  const renderScrollDownDisabled = () => {
    const testId = props.testId ? `${props.testId}-bottom-label` : undefined;
    return props.disableScrollDownLabel ? (
      <div className="hint hint--margin-bottom" data-testid={testId}>
        {props.disableScrollDownLabel}
      </div>
    ) : null;
  };

  const renderScrollUpDisabled = () => {
    const testId = props.testId ? `${props.testId}-top-label` : undefined;
    return props.disableScrollUpLabel ? (
      <div className="hint hint--margin-top" data-testid={testId}>
        {props.disableScrollUpLabel}
      </div>
    ) : null;
  };

  let scrollerHeightClass = 'scroller--height';
  if (!props.disabled && props.disableScrollUp !== props.disableScrollDown) {
    scrollerHeightClass += '-1';
  } else if (!props.disabled && !props.disableScrollUp && !props.disableScrollDown) {
    scrollerHeightClass += '-2';
  }

  return (
    <div className={'scroller ' + scrollerHeightClass}>
      {!props.disabled && !!props.onScrollToStart && (
        <div className="scroller__header">
          {props.disableScrollUp ? renderScrollUpDisabled() : renderScrollerHintUp()}
        </div>
      )}
      <div className="child-content">{props.children}</div>
      {!props.disabled && !!props.onScrollToEnd && (
        <div>{props.disableScrollDown ? renderScrollDownDisabled() : renderScrollerHintDown()}</div>
      )}
    </div>
  );
});
