import { action } from 'mobx';
import { t } from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

import { inject, Store } from '@/types/store';
import {
  TimetableUrlStore,
  URL_SEARCH_PARAMETER_ENTITY_ID,
  URL_SEARCH_PARAMETER_SELECTED_DATE,
  URL_SEARCH_PARAMETER_VIEW_TYPE,
} from '@te/standard/stores/url/timetable-url-store';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import {
  ITimetableViewType,
  TIMETABLE_VIEW_TYPES,
  TimetableMetaStore,
} from '@te/standard/stores/meta/timetable-meta-store';
import { TimetableFilterStore } from '@te/standard/stores/filter/timetable-filter-store';
import { TimetableLegendFilterStore } from '@te/standard/stores/filter/timetable-legend-filter-store';
import { TimetableDataStore } from '@te/standard/stores/data/timetable-data-store';
import { TimetableRestrictionLayerStore } from '@te/standard/stores/restriction/timetable-restriction-layer-store';
import { TimetableTimeStore } from '@te/standard/stores/time/timetable-time-store';
import { TimetableGridDaysStore } from '@te/standard/stores/grid/timetable-grid-days-store';
import { TimetableGridDimensionsStore } from '@te/standard/stores/grid/timetable-grid-dimensions-store';
import { TimetableIntegrationStore } from '@te/standard/stores/integration/timetable-integration-store';
import { TimetableUserActionsStore } from '@te/standard/stores/user-actions/timetable-user-actions-store';
import TimetableFeatureOnboardingStore from '@te/standard/stores/onboarding/timetable-feature-onboarding-store';
import { TimetableViewApi } from '@/stores/api-store';
import { TimetableEntityType } from '@te/standard/stores/timetable-root-store';
import { formatDateForServerRequest } from '@/utils/date/date-util';
import { GridEntryTypeEnum, TimetableEntriesDto } from '@untis/wu-rest-view-api';
import SchoolYearStore from '@/stores/schoolyear-store';
import TimetableLocalStorageStore from '@te/standard/stores/local-storage/timetable-local-storage-store';
import NotificationStore from '@/stores/notification-store/notification-store';
import { ResourceTypeEnum, TimetableTypeEnum } from '@untis/wu-rest-view-api/api';

export interface ITimetableFilterSelection {
  viewMode: ITimetableViewType;
  selectedDate: Dayjs;
  entityId: number;
}

@Store()
export default class TimetableFacadeStore {
  private schoolYearStore: SchoolYearStore = inject(SchoolYearStore);
  private notificationStore: NotificationStore = inject(NotificationStore);

  private timetableUrlStore: TimetableUrlStore = inject(TimetableUrlStore);
  private timetableFormatStore: TimetableFormatStore = inject(TimetableFormatStore);
  private timetableMetaStore: TimetableMetaStore = inject(TimetableMetaStore);
  private timetableFilterStore: TimetableFilterStore = inject(TimetableFilterStore);
  private timetableLegendFilterStore: TimetableLegendFilterStore = inject(TimetableLegendFilterStore);
  private timetableDataStore: TimetableDataStore = inject(TimetableDataStore);
  private timetableRestrictionLayerStore: TimetableRestrictionLayerStore = inject(TimetableRestrictionLayerStore);
  private timetableTimeStore: TimetableTimeStore = inject(TimetableTimeStore);
  private timetableGridDaysStore: TimetableGridDaysStore = inject(TimetableGridDaysStore);
  private timetableGridDimensionsStore: TimetableGridDimensionsStore = inject(TimetableGridDimensionsStore);
  private timetableIntegrationStore: TimetableIntegrationStore = inject(TimetableIntegrationStore);
  private timetableUserActionsStore: TimetableUserActionsStore = inject(TimetableUserActionsStore);
  private timetableFeatureOnboardingStore: TimetableFeatureOnboardingStore = inject(TimetableFeatureOnboardingStore);
  private timetableLocalStorageStore: TimetableLocalStorageStore = inject(TimetableLocalStorageStore);

  @action
  async init(timetableEntityType: TimetableEntityType, urlSearchParameters: URLSearchParams) {
    const initialFilterSelection = this.getInitialSelection(timetableEntityType, urlSearchParameters);

    await this.initWithValues(
      timetableEntityType,
      initialFilterSelection.selectedDate,
      initialFilterSelection.viewMode,
      initialFilterSelection.entityId,
    );
  }

  @action
  private async initWithValues(
    timetableEntityType: TimetableEntityType,
    initialDate: Dayjs,
    initialViewType: ITimetableViewType,
    initialEntityId: number,
  ) {
    this.timetableFormatStore.init(timetableEntityType);
    if (!this.timetableMetaStore.isDateInCurrentSchoolYear(initialDate)) {
      this.timetableMetaStore.setIsInitialDateInCurrentSchoolYear(false);
    } else {
      this.timetableMetaStore.setIsInitialDateInCurrentSchoolYear(true);
    }

    const { currentSchoolYear } = this.schoolYearStore;
    const { isInitialDateInCurrentSchoolYear } = this.timetableMetaStore;
    if (currentSchoolYear && isInitialDateInCurrentSchoolYear) {
      const result = await TimetableViewApi.getTimetableGrid();

      this.timetableTimeStore.watchCurrentTime(dayjs());
      this.timetableFormatStore.setFormats(result.data);
      this.timetableMetaStore.init(initialDate, initialViewType);
      this.timetableUrlStore.init(this.timetableMetaStore.currentTimetableStartDate, initialViewType, initialEntityId);
      this.timetableLocalStorageStore.writeUrlSelectionToLocalStorage(
        timetableEntityType,
        this.timetableUrlStore.urlSearchParameters,
      );
      await this.timetableFeatureOnboardingStore.getOnboarding();
      if (initialEntityId) {
        this.timetableFilterStore.setSelectedFilterValue(initialEntityId);
      }
    }
  }

  @action
  async fetchFilterData() {
    const { currentSchoolYear } = this.schoolYearStore;
    const { isInitialDateInCurrentSchoolYear } = this.timetableMetaStore;
    const { resourceTypeEnum, timetableTypeEnum } = this.timetableFormatStore;

    if (currentSchoolYear && isInitialDateInCurrentSchoolYear) {
      await this.fetchTimetableFilters(resourceTypeEnum, timetableTypeEnum);
      await this.fetchTimetableIntegrations();
      await this.fetchTimetableEntriesSetting();
    }
  }

  @action
  private async fetchTimetableFilters(resourceTypeEnum: ResourceTypeEnum, timetableTypeEnum: TimetableTypeEnum) {
    const filters = await TimetableViewApi.getTimetableFilter(resourceTypeEnum, timetableTypeEnum);
    this.timetableFilterStore.init(filters.data);
  }

  @action
  async fetchTimetableEntriesSetting() {
    const timetableEntriesSettings = await TimetableViewApi.getTimetableEntriesSettings(
      this.timetableFormatStore.selectedTimetableFormatId,
    );
    this.timetableLegendFilterStore.init(timetableEntriesSettings.data);
  }

  @action
  private async fetchTimetableIntegrations() {
    const externalCalendarIntegrations = await TimetableViewApi.getTimetableIntegrations(
      this.timetableFormatStore.isMyTimetable,
    );
    this.timetableIntegrationStore.setExternalCalendarIntegrations(externalCalendarIntegrations.data);
  }

  @action
  async fetchTimetableEntries(resetEntries: boolean = true) {
    if (resetEntries) {
      this.timetableDataStore.setTimetableEntries(undefined);
      this.timetableRestrictionLayerStore.setTimetableEntries(undefined);
    }
    const { currentTimetableStartDate, currentTimetableEndDate, isInitialDateInCurrentSchoolYear } =
      this.timetableMetaStore;
    const { selectedFilterValue } = this.timetableFilterStore;
    const { currentSchoolYear } = this.schoolYearStore;
    const { resourceTypeEnum } = this.timetableFormatStore;

    if (currentSchoolYear && isInitialDateInCurrentSchoolYear && selectedFilterValue !== undefined) {
      const result = await TimetableViewApi.getTimetableEntries(
        formatDateForServerRequest(currentTimetableStartDate),
        formatDateForServerRequest(currentTimetableEndDate),
        resourceTypeEnum,
        this.timetableFormatStore.selectedTimetableFormatId!,
        [selectedFilterValue],
        [
          GridEntryTypeEnum.NORMAL_TEACHING_PERIOD,
          GridEntryTypeEnum.ADDITIONAL_PERIOD,
          GridEntryTypeEnum.EVENT,
          GridEntryTypeEnum.STAND_BY_PERIOD,
          GridEntryTypeEnum.OFFICE_HOUR,
          GridEntryTypeEnum.EXAM,
          GridEntryTypeEnum.BREAK_SUPERVISION,
        ],
      );

      this.showExternalCalendarErrors(result.data);
      this.timetableDataStore.setTimetableEntries(result.data);
      this.timetableRestrictionLayerStore.setTimetableEntries(result.data);
    }
  }

  @action
  async updateTimetableIntegrations() {
    const { externalCalendarIntegrations } = this.timetableIntegrationStore;
    await TimetableViewApi.updateTimetableIntegrations({ integrations: externalCalendarIntegrations?.integrations });
    await this.fetchTimetableEntries(false);
  }

  @action
  async updateTimetableEntriesSettings() {
    const { timetableEntriesSettings, previousTimetableEntriesSettings } = this.timetableLegendFilterStore;
    if (timetableEntriesSettings !== previousTimetableEntriesSettings) {
      await TimetableViewApi.updateTimetableEntriesSettings(timetableEntriesSettings);
    }
    if (
      timetableEntriesSettings.showTeacherAbsences !== previousTimetableEntriesSettings?.showTeacherAbsences ||
      timetableEntriesSettings.showStudentAbsences !== previousTimetableEntriesSettings?.showStudentAbsences ||
      timetableEntriesSettings.showRoomLocks !== previousTimetableEntriesSettings.showRoomLocks ||
      timetableEntriesSettings.showResourceLocks !== previousTimetableEntriesSettings.showResourceLocks
    ) {
      await this.fetchTimetableEntries(false);
    }
  }

  @action
  async handleActiveTimetableFormatChange() {
    const { selectedTimetableFormatId, resourceTypeEnum } = this.timetableFormatStore;
    const { selectedDate, currentTimetableStartDate, timetableViewType } = this.timetableMetaStore;
    const hasSelectedTimetableFormatId = selectedTimetableFormatId !== undefined;
    const shouldSelectWeekView =
      timetableViewType === 'day' && !this.timetableGridDaysStore.hasTimeGridDay(selectedDate);
    const shouldSelectDate = !selectedDate.isSame(currentTimetableStartDate, this.timetableMetaStore.timetableViewType);
    if (hasSelectedTimetableFormatId) {
      await TimetableViewApi.updateTimetableFormatSelection(resourceTypeEnum, selectedTimetableFormatId);
      await this.fetchTimetableEntriesSetting();

      if (shouldSelectWeekView) {
        this.timetableUserActionsStore.toggleTimetableViewType();
      } else if (shouldSelectDate) {
        this.timetableUserActionsStore.selectDate(currentTimetableStartDate);
      } else {
        await this.fetchTimetableEntries(false);
      }
    }
  }

  @action
  async handleCurrentSchoolYearChange(timetableEntityType?: TimetableEntityType) {
    const { currentSchoolYear } = this.schoolYearStore;
    if (timetableEntityType) {
      let initialDate = dayjs(currentSchoolYear?.dateRange?.start);
      if (this.timetableMetaStore.isDateInCurrentSchoolYear(dayjs())) {
        initialDate = dayjs();
      }
      await this.initWithValues(timetableEntityType, initialDate, 'week', Number.NaN);
    }
  }

  @action
  resetStores() {
    this.timetableMetaStore.reset();
    this.timetableFormatStore.reset();
    this.timetableFilterStore.reset();
    this.timetableLegendFilterStore.reset();
    this.timetableUrlStore.reset();
    this.timetableDataStore.reset();
    this.timetableRestrictionLayerStore.reset();
    this.timetableTimeStore.reset();
    this.timetableGridDimensionsStore.reset();
    this.timetableIntegrationStore.reset();
    this.timetableFeatureOnboardingStore.reset();
  }

  @action
  public resetUrl(timetableEntityType: TimetableEntityType) {
    this.timetableLocalStorageStore.deleteUrlSelectionFromLocalStorage(timetableEntityType);
  }

  @action
  public async resetEntitySelection() {
    this.timetableFilterStore.resetSelectedFilterValue();
    this.timetableUrlStore.removeUrlParameter(URL_SEARCH_PARAMETER_ENTITY_ID);
  }

  private getInitialSelection(
    timetableEntityType: TimetableEntityType,
    urlSearchParameters: URLSearchParams,
  ): ITimetableFilterSelection {
    const urlSearchParameterSelectedDate = dayjs(urlSearchParameters.get(URL_SEARCH_PARAMETER_SELECTED_DATE));
    if (urlSearchParameterSelectedDate.isValid()) {
      return this.getInitialSelectionFromUrl(urlSearchParameters);
    } else {
      return this.getInitialSelectionFromLocalStorage(timetableEntityType);
    }
  }

  private getInitialSelectionFromUrl(urlSearchParameters: URLSearchParams): ITimetableFilterSelection {
    const result: ITimetableFilterSelection = {
      selectedDate: dayjs(),
      viewMode: 'week',
      entityId: Number.NaN,
    };

    const urlSearchParameterSelectedDate = dayjs(urlSearchParameters.get(URL_SEARCH_PARAMETER_SELECTED_DATE));
    const urlSearchParameterViewType = urlSearchParameters.get(URL_SEARCH_PARAMETER_VIEW_TYPE);
    const urlSearchParameterEntityId = Number(urlSearchParameters.get(URL_SEARCH_PARAMETER_ENTITY_ID));

    if (urlSearchParameterSelectedDate.isValid()) {
      result.selectedDate = urlSearchParameterSelectedDate;
    }

    if ((TIMETABLE_VIEW_TYPES as string[]).includes(urlSearchParameterViewType ?? '')) {
      result.viewMode = urlSearchParameterViewType as ITimetableViewType;
    }

    if (urlSearchParameterEntityId) {
      result.entityId = urlSearchParameterEntityId;
    }

    return result;
  }

  private getInitialSelectionFromLocalStorage(timetableEntityType: TimetableEntityType): ITimetableFilterSelection {
    const selectionFromLocalStorage = this.timetableLocalStorageStore.readTimetableFilterSelection(timetableEntityType);
    const { selectedDate } = selectionFromLocalStorage;
    if (this.timetableMetaStore.isDateInCurrentSchoolYear(selectedDate)) {
      return selectionFromLocalStorage;
    } else {
      return this.getDefaultInitialValues();
    }
  }

  private getDefaultInitialValues(): ITimetableFilterSelection {
    return {
      selectedDate: this.timetableMetaStore.isDateInCurrentSchoolYear(dayjs())
        ? dayjs()
        : dayjs(this.schoolYearStore.currentSchoolYear?.dateRange?.start),
      viewMode: 'week',
      entityId: Number.NaN,
    };
  }

  private showExternalCalendarErrors(data: TimetableEntriesDto) {
    data.errors?.forEach((error) => {
      if (error.source) {
        this.notificationStore.warning({
          title: t('general.warning'),
          message: t('timetable.messages.externalCalendarError', {
            calendar: error.source,
          }),
        });
      }
    });
  }
}
