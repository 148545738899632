import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { formatTime } from '@/utils/date/date-util';
import { TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import useStore from '@/hooks/useStore';
import { TimetableTimeStore } from '@te/standard/stores/time/timetable-time-store';
import { TimetableGridDimensionsStore } from '@te/standard/stores/grid/timetable-grid-dimensions-store';
import { TimetableGridDaysStore } from '@te/standard/stores/grid/timetable-grid-days-store';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';

import './timetable-grid-current-time.less';

interface IProps {
  timetableGridRef: React.RefObject<HTMLDivElement>;
}

const TimetableGridCurrentTime = observer((props: IProps) => {
  const { timetableGridRef } = props;

  const currentTimeTodayRef = useRef<HTMLDivElement>(null);

  const timetableTimeStore = useStore(TimetableTimeStore);
  const timetableGridDimensionsStore = useStore(TimetableGridDimensionsStore);
  const timetableGridDaysStore = useStore(TimetableGridDaysStore);
  const timetableMetaStore = useStore(TimetableMetaStore);

  const { timetableViewType } = timetableMetaStore;
  const { currentTime, isCurrentTimeVisible } = timetableTimeStore;
  const { gridDayWidth } = timetableGridDimensionsStore;
  const top = timetableGridDimensionsStore.offsetFromStartTime(currentTime);
  const left = timetableGridDimensionsStore.offsetFromStartDate(currentTime);
  const showToday = timetableGridDaysStore.hasTimeGridDay(currentTime);

  useComponentDidMount(() => {
    if (currentTimeTodayRef.current) {
      setTimeout(() => {
        currentTimeTodayRef?.current?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });

        if (timetableViewType === 'day') {
          if (timetableGridRef && timetableGridRef.current) {
            timetableGridRef.current.scrollLeft = 0;
          }
        }
      }, 0);
    }
  });

  if (!isCurrentTimeVisible) {
    return null;
  }

  return (
    <div className="timetable-grid-current-time" style={{ top: top }} data-testid="timetable-grid-current-time">
      <span className="timetable-grid-current-time--time-value">{formatTime(currentTime)}</span>
      <div className="timetable-grid-current-time--line" />
      <div className="timetable-grid-current-time--circle">
        <div className="timetable-grid-current-time--circle-inner" />
      </div>
      {showToday && (
        <div
          ref={currentTimeTodayRef}
          className="timetable-grid-current-time--today"
          data-testid="timetable-grid-current-time--today"
          style={{ left: left, width: gridDayWidth }}
        />
      )}
    </div>
  );
});

export default TimetableGridCurrentTime;
