import { t } from 'i18next';
import { observable, action, computed } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';

import { inject, Store } from '@/types/store';
import { ParentTeacherDayDashboardDto, ParentTeacherDayStateDto } from '@untis/wu-rest-view-api';
import { formatDateTime, formatDate, formatTime } from '@/utils/date/date-util';
import { ILinkCardProps } from '@/ui-components/link-card/link-card';
import { ParentTeacherDayDashboardViewApi } from '@/stores/api-store';
import TodayPermissionsStore from '@today/stores/today-permissions-store';

@Store()
class TodayParentTeacherDayStore {
  private todayPermissionsStore = inject(TodayPermissionsStore);

  @observable
  parentTeacherDayDashboardDtos: ParentTeacherDayDashboardDto[] = [];

  @action
  async getParentTeacherDays() {
    if (!this.todayPermissionsStore.canSeeParentTeacherDays) {
      return;
    }
    try {
      const { data } = await ParentTeacherDayDashboardViewApi.getParentTeacherDays();
      this.parentTeacherDayDashboardDtos = data.parentTeacherDayDashboardDtos;
      return data;
    } catch (error) {}
  }

  getParentTeacherDayStatusTranslation(ptd: ParentTeacherDayDashboardDto): string | undefined {
    switch (ptd.status) {
      case ParentTeacherDayStateDto.TEACHER_FINISHED:
      case ParentTeacherDayStateDto.PARENT_STUDENT_FINISHED:
      case ParentTeacherDayStateDto.PARENT_STUDENT_OPTIMIZATION:
        return t('general.parentsTeacherDayStatusFinished');

      case ParentTeacherDayStateDto.PARENT_STUDENT_REWORK_PHASE:
      case ParentTeacherDayStateDto.PARENT_STUDENT_TEACHERSELECTION_OR_APPOINTMENTSELECTION:
        return t('general.parentsTeacherDayStatusRegistration');

      case ParentTeacherDayStateDto.TEACHER_PROGRESS:
      case ParentTeacherDayStateDto.TEACHER_BEFORE:
      default:
        return t('general.parentsTeacherDayStatusInvite');
    }
  }

  getParentTeacherDayStatusDescriptionTranslation(ptd: ParentTeacherDayDashboardDto): string | undefined {
    switch (ptd.status) {
      case ParentTeacherDayStateDto.PARENT_STUDENT_TEACHERSELECTION_OR_APPOINTMENTSELECTION:
        return t('general.parentsTeacherDayRegisterUntil', {
          datetime: formatDateTime(ptd.endOfRegistrationDateTime),
        });

      case ParentTeacherDayStateDto.PARENT_STUDENT_REWORK_PHASE:
        return t('general.parentsTeacherDayAutomaticDelegationFinished', {
          datetime: formatDateTime(ptd.startDateTime),
        });

      case ParentTeacherDayStateDto.PARENT_STUDENT_OPTIMIZATION:
        return t('general.parentsTeacherDayRegistrationPhaseOver');

      case ParentTeacherDayStateDto.TEACHER_BEFORE:
        return t('general.parentsTeacherDayInviteLegalGuardiansUntil', {
          datetime: formatDateTime(ptd.endOfRegistrationDateTime),
        });

      case ParentTeacherDayStateDto.TEACHER_PROGRESS:
        return t('general.parentsTeacherDayInviteLegalGuardiansFinished', {
          date: formatDate(ptd.endOfRegistrationDateTime),
          time: formatTime(ptd.endOfRegistrationDateTime),
        });

      case ParentTeacherDayStateDto.PARENT_STUDENT_FINISHED:
      case ParentTeacherDayStateDto.TEACHER_FINISHED:
        return t('general.parentsTeacherDayLookAtDetails');

      default:
        return undefined;
    }
  }

  formatParentTeacherDayDateRange(startdate: Dayjs, enddate: Dayjs) {
    return `${startdate.format('DD. MMM')} - ${enddate.format('LL')}`;
  }

  @computed
  get quickLinks(): ILinkCardProps[] {
    return this.parentTeacherDayDashboardDtos.map((ptd) => {
      return {
        title: ptd.title || '',
        icon: 'parent-day',
        href: `/parent-teacher-day/${ptd.id}`,
        tag: this.getParentTeacherDayStatusTranslation(ptd),
        tooltip: {
          title: this.getParentTeacherDayStatusDescriptionTranslation(ptd),
        },
        badge: ptd.enrolled,
        subtitle: this.formatParentTeacherDayDateRange(dayjs(ptd.startDateTime), dayjs(ptd.endDateTime)),
      };
    });
  }
}

export default TodayParentTeacherDayStore;
