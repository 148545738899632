import { observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';

import useStore from '@/hooks/useStore';
import { NavigationBarStore } from '@/ui-components/navigation-bar/navigation-bar-store';

import './selection-indicator.less';

interface IProps {
  isShownInBottomView: boolean;
  scrollTop: number;
  menuListContainerTop?: number;
}

const SelectionIndicator = observer((props: IProps) => {
  const store = useStore(NavigationBarStore);

  if (props.isShownInBottomView && !store.isUserItemSelected) {
    return null;
  }

  const getIndicatorClass = (): string => {
    return (
      'selection-indicator' +
      (store.isInDenseSubMenu || store.isInDepartmentSelection
        ? ' selection-indicator--dense'
        : ' selection-indicator--normal-height') +
      (store.scrollMode ? ' selection-indicator--scroll' : ' selection-indicator--no-scroll')
    );
  };

  const getStudentItemsOffset = () => {
    // range should match range in student-selection
    const studentsCount = store.students.length;
    let studentItemsCount = 0;
    if (studentsCount > 1 && studentsCount < 4) {
      studentItemsCount = studentsCount + 1; // + 1 for "all" item
    } else if (studentsCount >= 4) {
      studentItemsCount = 1; // only the "all" item
    }

    if (!store.isInMainMenu) {
      return 0;
    }

    const spacing = 16;
    const menuItemHeight = 50;

    if (studentItemsCount >= 1 && studentItemsCount <= 4) {
      return spacing + studentItemsCount * menuItemHeight;
    } else {
      return 0;
    }
  };

  const calculateTopForMenu = (): number => {
    const menuItemHeight = store.isInDenseSubMenu || store.isInDepartmentSelection ? 32 : 50;
    const index = props.isShownInBottomView ? 0 : store.indicatorSelectionIndex;
    return getStudentItemsOffset() + ((index && index * menuItemHeight) || 0) - props.scrollTop;
  };

  const indicatorTop: CSSProperties = {
    top: (props.isShownInBottomView ? 10 : calculateTopForMenu()) + 'px',
  };

  const index = props.isShownInBottomView ? 0 : store.indicatorSelectionIndex;
  const containerClass = props.isShownInBottomView
    ? 'selection-indicator-container selection-indicator-container--bottom-view'
    : 'selection-indicator-container selection-indicator-container--menu-list';

  return (
    <div className={containerClass} style={{ top: props.isShownInBottomView ? undefined : props.menuListContainerTop }}>
      {index !== undefined && <div className={getIndicatorClass()} style={indicatorTop} />}
    </div>
  );
});

export default SelectionIndicator;
