import React from 'react';
import { useTranslation } from 'react-i18next';

import EmptyIndicator, { IEmptyIndicatorProps } from '@/components/empty-indicator/empty-indicator';

type Props = Partial<IEmptyIndicatorProps>;

const LazyLoadingFallbackView = (props: Props) => {
  const { t } = useTranslation();
  const lazyLoadingText = !!props.title ? props.title : t('general.loading');

  return <EmptyIndicator {...props} title={lazyLoadingText} />;
};

export default LazyLoadingFallbackView;
