import React, { useState } from 'react';
import { Store as AntdStore } from 'antd/lib/form/interface';
import { useTranslation } from 'react-i18next';

import { DeprecatedForm, Input, Button } from '@/ui-components';
import useStore from '@/hooks/useStore';
import { TriggerStore } from '@/stores/trigger-store';
import { ProfileDto } from '@untis/wu-rest-view-api';
import { ProfileViewApi } from '@/stores/api-store';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';

import './check-data-modal-view.less';

function CheckDataModalView() {
  const triggerStore = useStore(TriggerStore);
  const [form] = DeprecatedForm.useForm();
  const [initialValues, setInitialValues] = useState<ProfileDto>();
  const { t } = useTranslation();

  const fields = React.useMemo(
    () => [
      {
        label: t('general.firstname'),
        name: 'firstName',
        hasFeedback: true,
        rules: [{ required: true, message: t('general.formRequiredMessage', { field: t('general.firstname') }) }],
        placeholder: '',
      },
      {
        label: t('general.lastname'),
        name: 'lastName',
        hasFeedback: true,
        rules: [{ required: true, message: t('general.formRequiredMessage', { field: t('general.lastname') }) }],
        placeholder: '',
      },
      {
        label: t('general.mobilePhoneNumber'),
        name: 'mobileNumber',
        hasFeedback: true,
        rules: [
          { required: true, message: t('general.formRequiredMessage', { field: t('general.mobilePhoneNumber') }) },
        ],
        placeholder: '+43 123 4567891',
      },
      {
        label: t('general.streetAdress'),
        name: 'street',
        hasFeedback: false,
        rules: [],
        placeholder: 'My Street 1',
      },
      {
        label: t('general.city'),
        name: 'city',
        hasFeedback: false,
        rules: [],
        placeholder: 'Vienna',
      },
      {
        label: t('general.postCode'),
        name: 'postCode',
        hasFeedback: false,
        rules: [],
        placeholder: '1010',
      },
    ],
    [t],
  );

  useComponentDidMount(async () => {
    try {
      const profileResponse = await ProfileViewApi.getProfile();
      setInitialValues(profileResponse.data);
      form.resetFields(); // needed to rerender the form
    } catch (error) {
      //
    }
  });

  async function handleSubmit(values: AntdStore) {
    try {
      await ProfileViewApi.updateProfile(values);
      triggerStore.next();
    } catch (error) {
      console.error(error);
      triggerStore.next();
    }
  }

  async function handleRemindMeLaterClick() {
    try {
      await ProfileViewApi.delayProfileVerification();
      triggerStore.next();
    } catch (error) {
      triggerStore.next();
    }
  }

  return (
    <div>
      <h1>{t('general.profileDataCheckTitle')}</h1>
      <p>{t('general.profileDataCheckDescription')}</p>
      <DeprecatedForm
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 9 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 15 },
        }}
      >
        {fields.map(({ label, rules, hasFeedback, placeholder, name }) => (
          <DeprecatedForm.Item key={name} label={label} rules={rules} hasFeedback={hasFeedback} name={name}>
            <Input placeholder={placeholder} />
          </DeprecatedForm.Item>
        ))}
        <div className="actions">
          <Button size="large" type="secondary" outline onClick={handleRemindMeLaterClick}>
            {t('general.remindMeLater')}
          </Button>
          <Button triggersSubmit size="large" type="primary">
            {t('general.save')}
          </Button>
        </div>
      </DeprecatedForm>
    </div>
  );
}

export { CheckDataModalView };
