import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IFormItemProps, WUFormItem } from '@/ui-components/wu-form/wu-form-item';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { MultiTagSelect } from '@/ui-components';

interface IProps extends IFormItemProps<IMultiTagSelectItem[]> {
  onAdd: () => Promise<IMultiTagSelectItem[]>;
  placeholder?: string;
  removable?: boolean;
  addButtonTestId?: string;
  value?: IMultiTagSelectItem[];
  isSingleSelect?: boolean;
}

interface IMultiSelectWrapperProps extends IProps {
  onChange?: (value: IMultiTagSelectItem[]) => void;
  value?: IMultiTagSelectItem[];
  addButtonTestId?: string;
}

const MultiTagSelectWrapper = (props: IMultiSelectWrapperProps) => {
  const [value, setValue] = useState<IMultiTagSelectItem[]>(props.initialValue || []);
  const { t } = useTranslation();

  // maybe refactor in future, syncing props and state is most of the time an anti pattern
  // but if you update the form value outside of this component eq with an validator
  // the value in this component will not be set to the props.value
  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const handleRemove = (id: string) => {
    const newValue = value.filter((item) => {
      return item.id !== id;
    });
    setValue(newValue);
    props.onChange && props.onChange(newValue);
  };

  const handleAdd = (): Promise<IMultiTagSelectItem[]> => {
    return new Promise((resolve) => {
      props.onAdd().then((result) => {
        props.onChange && props.onChange(result);
        setValue(result);
        resolve(result);
      });
    });
  };

  const isSingleSelect = props.isSingleSelect;

  return (
    <MultiTagSelect
      {...props}
      onAdd={handleAdd}
      isSingleSelect={isSingleSelect}
      placeholder={props.placeholder ?? ''}
      value={value.map((val) => {
        return {
          ...val,
          options: props.removable
            ? [
                ...(val.options ? val.options : []),
                ...(isSingleSelect
                  ? [
                      {
                        label: t('general.edit'),
                        onClick: handleAdd,
                      },
                      {
                        label: t('general.remove'),
                        onClick: () => {
                          handleRemove(val.id);
                        },
                      },
                    ]
                  : [
                      {
                        label: t('general.remove'),
                        onClick: () => {
                          handleRemove(val.id);
                        },
                      },
                    ]),
              ]
            : val.options,
        };
      })}
      disabled={props.disabled}
      addButtonTestId={props.addButtonTestId}
    />
  );
};

export const FormMultiTagSelect = (props: IProps) => {
  return (
    <WUFormItem {...props}>
      <MultiTagSelectWrapper {...props} />
    </WUFormItem>
  );
};
