import React, { ChangeEvent } from 'react';
import { v4 } from 'uuid';
import { Input as AntdInput } from 'antd';

import { ITestComponentProps } from '@/types/test-component-props';

import './textarea.less';

export interface ITextAreaProps extends ITestComponentProps {
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  className?: string;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onClear?: () => void;
  maxLength?: number;
  name?: string;
  floatingLabel?: string;
}

const TextArea = (props: ITextAreaProps) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    props.onChange && props.onChange(value);
  };

  const randomId = React.useMemo(() => v4(), []);
  const id = props.name ? props.name : randomId;

  const testId = props.testId ? `${props.testId}-text-area` : undefined;

  const textArea = (
    <AntdInput.TextArea
      className="wu-text-area"
      id={id}
      data-testid={testId}
      placeholder={props.floatingLabel ? '' : props.placeholder}
      value={props.value}
      onChange={handleChange}
      disabled={props.disabled}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      maxLength={props.maxLength}
      name={props.name}
      showCount={!!props?.maxLength}
    />
  );

  if (props.floatingLabel) {
    return (
      <div className="floating-label-container">
        {textArea}
        <label htmlFor={id} className="floating-label">
          {props.floatingLabel}
        </label>
      </div>
    );
  }

  return textArea;
};

export default TextArea;
