import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { ExamFormStore, IExamForm } from '@ls/exams/exam-form/exam-form-store';
import { IconButton, WUForm } from '@/ui-components';
import { FormSection } from '@/ui-components/wu-form/form-section/form-section';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { IFormButton } from '@/ui-components/wu-form/wu-form';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { FormDatePicker } from '@/ui-components/wu-form/form-date-picker/form-date-picker';
import { FormCheckboxes } from '@/ui-components/wu-form/form-checkboxes/form-checkboxes';
import { FormLabel } from '@/ui-components/wu-form/form-label/form-label';
import RightsStore, { ElementType, Right } from '@/stores/rights-store';
import ConfigStore from '@/stores/config-store';
import ReportStore from '@/stores/report-store';
import { getCommonTextInputRules, TextValidationPatterns } from '@/utils/form/common-form-rules';
import EmptyIndicator from '@/components/empty-indicator/empty-indicator';
import {
  DateTimeRange,
  FormDateTimeRangePickerSchoolYear,
} from '@/ui-components/wu-form/form-date-time-range-picker-school-year/form-date-time-range-picker-school-year';
import { TimegridStore } from '@/stores/timegrid-store';
import { FormMultiTagSelect, WUFormCol, WUFormRow } from '@/ui-components/wu-form';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import { AssignedTeachersRow } from '@/components/assigned-teachers/assigned-teachers';
import { FormAssignedTeachers } from '@/ui-components/wu-form/form-assigned-teachers/form-assigned-teachers';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';

import './exam-form.less';

interface IProps {
  examFormStore: ExamFormStore;
  onSaveCallback?: () => void;
  onDeleteCallback?: () => void;
}

const ExamForm = observer((props: IProps) => {
  const { t } = useTranslation();
  const configStore = useStore(ConfigStore);
  const rightsStore = useStore(RightsStore);
  const modalStore = useStore(ModalStore);
  const reportStore = useStore(ReportStore);
  const timegridStore = useStore(TimegridStore);
  const [form] = Form.useForm<IExamForm>();

  const { examFormStore, onSaveCallback, onDeleteCallback } = props;
  const { exam, isLoading, isSubmitting } = examFormStore;
  const canEdit = exam?.canEdit ?? false;
  const canDelete = exam?.canDelete ?? false;
  const disabled = exam?.deleted || !canEdit || isSubmitting;

  useComponentDidMount(() => {
    examFormStore.setForm(form);
    examFormStore.fetchData();
  });

  if (isLoading) {
    return <EmptyIndicator title={t('general.loading')} />;
  }

  const leftButtons: IFormButton[] = [];
  if (rightsStore.canDelete(Right.EXAMINATION, ElementType.ALL, false) && !exam?.isUntisExam) {
    leftButtons.push({
      label: t('general.delete'),
      onClick: () => examFormStore.deleteExam(onDeleteCallback),
      disabled: !canDelete || isSubmitting,
    });
  }

  const rightButtons: IFormButton[] = [
    {
      label: t('general.cancel'),
      onClick: () => modalStore.closeModal(),
    },
  ];

  const initialFormCheckboxValues = [];
  if (exam?.exported) {
    initialFormCheckboxValues.push('exported');
  }

  const initialDateTimeRangePickerValue: DateTimeRange = {
    startDateTime: exam?.examStart ? dayjs(exam?.examStart) : timegridStore.timeRangePickerTimeSlots[0].startTime,
    endDateTime: exam?.examEnd
      ? dayjs(exam.examEnd)
      : timegridStore.timeRangePickerTimeSlots[timegridStore.timeRangePickerTimeSlots.length - 1].endTime,
  };

  const initialAssignedTeachersValue: AssignedTeachersRow[] = examFormStore.assignedTeachers;

  const initialRooms: IMultiTagSelectItem[] = examFormStore.rooms
    .filter((room) => exam?.rooms.map((r) => r.id).includes(room.id))
    .map((room) => {
      return {
        id: room.id.toString(),
        label: room.displayName,
      };
    });

  const initialStudents: IMultiTagSelectItem[] = examFormStore.students
    .filter((student) => exam?.students.map((s) => s.id).includes(student.id))
    .map((student) => {
      return {
        id: student.id.toString(),
        label: student.displayName,
      };
    });

  const examStartDayjs = dayjs(exam?.examStart);
  const examInTheFuture = examStartDayjs.isAfter(dayjs());

  const formDateTime = examFormStore.form?.getFieldValue('dateTimeRange');
  let startTime = dayjs(exam?.examStart);
  let endTime = dayjs(exam?.examEnd);
  if (formDateTime) {
    startTime = dayjs(formDateTime.startDateTime);
    endTime = dayjs(formDateTime.endDateTime);
  }

  const emptyLabelPlaceHolder = (id: number, extraCondition?: boolean): string | undefined => {
    return id <= 0 && (disabled || exam?.isUntisExam || extraCondition) ? '-' : undefined;
  };

  return (
    <WUForm<IExamForm>
      form={examFormStore.form}
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      fixedButtonBar={true}
      hideSubmitButton={disabled}
      onSubmit={disabled ? undefined : (value: IExamForm) => examFormStore.updateExam(value, onSaveCallback)}
      maxWidth
      paddingTop
    >
      <WUFormCol lg={1}>
        {exam?.isUntisExam && (
          <WUFormRow>
            <WUFormCol lg={1}>
              <div className="exam-form-untis-exam-info-text">{t('lessons.exams.form.untisExamInfo')}</div>
            </WUFormCol>
          </WUFormRow>
        )}

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.name')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              name="name"
              initialValue={exam?.examName || ''}
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              rules={getCommonTextInputRules(
                {
                  required: true,
                },
                [{ max: 100, message: t('general.inputTooLong') }],
              )}
              disabled={disabled || exam?.isUntisExam}
              onChange={() => examFormStore.updateModalHeader()}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.text')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormInput
              name="text"
              initialValue={exam?.examText || ''}
              pattern={TextValidationPatterns.MASTER_DATA_NAME}
              rules={getCommonTextInputRules({}, [{ max: 255, message: t('general.inputTooLong') }])}
              disabled={disabled || exam?.isUntisExam}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.examinationType')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormSingleSelect
              name="examType"
              initialValue={exam?.examType?.id.toString() || undefined}
              items={examFormStore.examTypes}
              placeholder={emptyLabelPlaceHolder(exam?.examType?.id || -1)}
              disabled={disabled}
              onChange={examFormStore.onExamTypeSelected}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.gradingScheme')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormSingleSelect
              name="gradingScheme"
              initialValue={exam?.gradingScale?.id.toString() || undefined}
              items={examFormStore.gradingSchemes}
              placeholder={emptyLabelPlaceHolder(
                exam?.gradingScale?.id || -1,
                examFormStore.gradingSchemes.length === 1,
              )}
              // shall be disabled if gradingScale is set via examType
              disabled={disabled || examFormStore.selectedExamTypeHasGradingScale}
              onChange={examFormStore.onGradingSchemeSelected}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.date')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormDateTimeRangePickerSchoolYear
              name="dateTimeRange"
              initialValue={initialDateTimeRangePickerValue}
              rules={[
                { required: true, message: t('general.inputRequired') },
                {
                  message: t('lessons.exams.messages.dateTimeValidationMessage'),
                  validator(rule, dateTimeRange) {
                    const { startDateTime, endDateTime } = dateTimeRange;
                    if (endDateTime.isSameOrBefore(startDateTime)) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
              disabled={disabled || exam?.isUntisExam}
              onlySameDay={true}
              timeSlots={timegridStore.timeRangePickerTimeSlots}
              onChange={(dateTimeRange) => examFormStore.onUpdateDateTimeRange(dateTimeRange)}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('lessons.exams.form.assignedTeachers')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormAssignedTeachers
              name="assignedTeachers"
              initialValue={initialAssignedTeachersValue}
              startTime={startTime}
              endTime={endTime}
              timeSlots={timegridStore.timeRangePickerTimeSlots}
              teacherOptions={examFormStore.teachers}
              disabled={disabled || exam?.isUntisExam || !examFormStore.canAssignedSelectTeachers}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.students')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormMultiTagSelect
              name="students"
              removable
              onAdd={examFormStore.addStudentsHandler}
              initialValue={initialStudents}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.rooms')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormMultiTagSelect
              name="rooms"
              initialValue={initialRooms}
              removable
              onAdd={examFormStore.addRoomsHandler}
              disabled={disabled || exam?.isUntisExam || !examFormStore.canSelectRooms}
            />
          </WUFormCol>
        </WUFormRow>

        {configStore.isAdmin && (
          <WUFormRow>
            <WUFormCol lg={2}>
              <FormSection text={t('lessons.exams.form.personInCharge')} centered />
            </WUFormCol>
            <WUFormCol lg={2}>
              <FormSingleSelect
                name="personInCharge"
                initialValue={exam?.examBookedUser?.id.toString()}
                items={examFormStore.users}
                placeholder={emptyLabelPlaceHolder(exam?.examBookedUser?.id || -1)}
                disabled={disabled || exam?.isUntisExam}
              />
            </WUFormCol>
          </WUFormRow>
        )}

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('lessons.exams.form.returnedOn')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormDatePicker
              name="returnedOn"
              label={t('lessons.exams.form.returnedOn')}
              initialValue={exam?.examReturned ? dayjs(exam?.examReturned) : undefined}
              disabled={disabled || examInTheFuture || exam?.isUntisExam}
              disabledDate={(date: Dayjs) => {
                const startDateTime = dayjs(exam?.examStart);
                return date.isBefore(startDateTime.startOf('day'));
              }}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('lessons.exams.form.returnedBy')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormSingleSelect
              name="returnedBy"
              initialValue={exam?.examReturnedUser?.id.toString() || undefined}
              items={examFormStore.users}
              allowClear={true}
              placeholder={emptyLabelPlaceHolder(exam?.examReturnedUser?.id || -1)}
              disabled={disabled || examInTheFuture || exam?.isUntisExam}
            />
          </WUFormCol>
        </WUFormRow>

        {configStore.isAdmin && (
          <WUFormRow>
            <WUFormCol lg={2}>
              <FormSection text={t('general.settings')} centered />
            </WUFormCol>
            <WUFormCol lg={2}>
              <FormCheckboxes
                name="settings"
                initialValue={initialFormCheckboxValues}
                options={[
                  {
                    label: t('lessons.exams.form.hasBeenExportedToUntis'),
                    value: 'exported',
                  },
                ]}
                disabled={disabled || exam?.isUntisExam}
              />
            </WUFormCol>
          </WUFormRow>
        )}

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.report')} />
          </WUFormCol>
          <WUFormCol lg={2}>
            <div>
              <IconButton
                type="pdf"
                ariaLabel={t('lessons.exams.form.pdfOutput')}
                tooltip={{
                  title: t('lessons.exams.form.pdfOutput'),
                  placement: 'top',
                }}
                onClick={() =>
                  reportStore.getReport(`/WebUntis/reports.do?name=ExamDetails&format=pdf&examId=${exam?.examId}`)
                }
              />
              <IconButton
                type="csv"
                ariaLabel={t('lessons.exams.form.csvOutput')}
                tooltip={{
                  title: t('lessons.exams.form.csvOutput'),
                  placement: 'top',
                }}
                onClick={() =>
                  reportStore.getReport(`/WebUntis/reports.do?name=ExamDetails&format=csv&examId=${exam?.examId}`)
                }
              />
              <IconButton
                type="xls"
                ariaLabel={t('lessons.exams.form.excelOutput')}
                tooltip={{
                  title: t('lessons.exams.form.excelOutput'),
                  placement: 'top',
                }}
                onClick={() =>
                  reportStore.getReport(`/WebUntis/reports.do?name=ExamDetails&format=xls&examId=${exam?.examId}`)
                }
              />
            </div>
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={1}>
            <FormLabel
              text={t('general.bookedOnByUser', {
                date: dayjs(exam?.examBooked).format('MMM Do, YYYY h:mm A'),
                user: exam?.examBookedUser?.displayName,
              })}
            />
          </WUFormCol>
          <WUFormCol lg={1}>
            <FormLabel
              text={t('general.changedOnByUser', {
                date: dayjs(exam?.examModified).format('MMM Do, YYYY h:mm A'),
                user: exam?.examModifiedUser?.displayName,
              })}
            />
          </WUFormCol>
        </WUFormRow>
      </WUFormCol>
    </WUForm>
  );
});

export default ExamForm;
