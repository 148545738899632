import React, { useEffect, useState } from 'react';
import { Space } from 'antd';

import './loading-indicator.less';

/**
 *  Just three "dots" that indicate that something is loading/calculated/...
 *  Currently only used in the Scroller Component, but we might want to use it somewhere else in the future.
 *  However, this component was only styled and designed by the developer,
 *  so different design requirements and adjustments might follow.
 */
export const LoadingIndicator = () => {
  const [phase, setPhase] = useState<1 | 2 | 3>(1);
  useEffect(() => {
    const interval = setInterval(() => {
      if (phase === 1) {
        setPhase(2);
      } else if (phase === 2) {
        setPhase(3);
      } else {
        setPhase(1);
      }
    }, 250);

    return () => {
      clearInterval(interval);
    };
  }, [setPhase, phase]);

  return (
    <div className="loading-indicator">
      <Space>
        <div className={'dot-1 dot-1--phase-' + phase} />
        <div className={'dot-2 dot-2--phase-' + phase} />
        <div className={'dot-3 dot-3--phase-' + phase} />
      </Space>
    </div>
  );
};
