import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { inject } from '@/types/store';
import { FeatureOnboardingStore, IFeatureOnboardingStep } from '@/stores/feature-onboarding-store';
import { Button, Tag } from '@/ui-components';

const FeatureOnboardingModalFooter = observer(() => {
  const featureOnboardingStore = inject(FeatureOnboardingStore);
  const { currentStep, actualSteps, hasExitStep, toNextStep, toPreviousStep, toStep, toExitStep, close } =
    featureOnboardingStore;

  return (
    <div className="feature-onboarding-modal--footer">
      {!currentStep?.isWelcomeStep && !currentStep?.isExitStep && (
        <OnboardingStepsNavigationTags steps={actualSteps} currentStep={currentStep} toStep={toStep} />
      )}
      <FeatureOnboardingFooterButtons
        currentStep={currentStep}
        hasExitStep={hasExitStep}
        toNextStep={toNextStep}
        toPreviousStep={toPreviousStep}
        toExitStep={toExitStep}
        close={close}
      />
    </div>
  );
});

const OnboardingStepsNavigationTags = ({
  currentStep,
  steps,
  toStep,
}: {
  currentStep?: IFeatureOnboardingStep;
  steps: IFeatureOnboardingStep[];
  toStep: (id: string) => void;
}) => {
  const handleTagClicked = (stepId: string) => {
    const isDifferentStep = stepId !== currentStep?.id;
    isDifferentStep && toStep(stepId);
  };

  return (
    <div className="feature-onboarding-modal--footer-progress">
      {steps.map((step) => (
        <Tag
          key={step.id}
          color={step.id === currentStep?.id ? 'dark-grey' : 'light-grey'}
          size="md"
          onClick={() => handleTagClicked(step.id)}
          testId={`feature-onboarding-modal-footer-tag-${step.id}`}
        >
          &nbsp;
        </Tag>
      ))}
    </div>
  );
};

const FeatureOnboardingFooterButtons = ({
  currentStep,
  hasExitStep,
  toPreviousStep,
  toNextStep,
  toExitStep,
  close,
}: {
  currentStep?: IFeatureOnboardingStep;
  hasExitStep?: boolean;
  toPreviousStep: () => void;
  toNextStep: () => void;
  toExitStep: () => void;
  close: () => void;
}) => {
  const { t } = useTranslation();

  const welcomeStepsButtons = (
    <>
      {hasExitStep && (
        <Button size="large" outline={true} onClick={toExitStep}>
          {t('general.notNow')}
        </Button>
      )}
      <Button size="large" onClick={toNextStep} type="primary">
        {t('general.letsBegin')}
      </Button>
    </>
  );

  const exitStepButtons = (
    <Button size="large" onClick={close} type="primary">
      {t('general.close')}
    </Button>
  );

  const regularStepButtons = (
    <>
      {currentStep?.hasPreviousStep && (
        <Button size="large" outline={true} onClick={toPreviousStep}>
          {t('general.back')}
        </Button>
      )}
      {currentStep?.hasNextStep && (
        <Button size="large" onClick={toNextStep} type="primary">
          {t('general.next')}
        </Button>
      )}
      {!currentStep?.hasNextStep && (
        <Button size="large" onClick={close} type="primary">
          {t('general.end')}
        </Button>
      )}
    </>
  );

  return (
    <div className="feature-onboarding-modal--footer-buttons">
      {currentStep?.isWelcomeStep && welcomeStepsButtons}
      {!currentStep?.isExitStep && !currentStep?.isWelcomeStep && regularStepButtons}
      {currentStep?.isExitStep && exitStepButtons}
    </div>
  );
};

export default FeatureOnboardingModalFooter;
