import { CheckboxChangeEvent, CheckboxProps } from 'antd/lib/checkbox';
import React, { MutableRefObject, Ref, useRef } from 'react';

import { Checkbox } from '../index';

import { ITestComponentProps } from '@/types/test-component-props';

import './complex-checkbox.less';

export interface IComplexCheckboxProps extends CheckboxProps, ITestComponentProps {
  value: any;
  onKeyUp?: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  tabIndex?: number;
  focusableRef?: Ref<HTMLLIElement>;
  checked?: boolean;
  [key: string]: any;
}

type CheckboxRefObject = MutableRefObject<HTMLLIElement | null>;

/**
 * Complex Checkboxes should be used in combination with ComplexCheckboxGroup and are useful for richer contents
 * (e.g. custom components) beside the checkbox. See the ask-teacher-view for example.
 */
export const ComplexCheckbox = (props: IComplexCheckboxProps) => {
  const { className, checked, value, onKeyUp, onKeyDown, tabIndex, focusableRef } = props;
  const liElementRef = useRef<HTMLLIElement>(null) as CheckboxRefObject;

  const onChange = (e: CheckboxChangeEvent) => {
    liElementRef.current && liElementRef.current.focus();
    props.onChange && props.onChange(e);
  };

  return (
    <li
      className={`untis-complex-checkbox${className ? ` ${className}` : ''}`}
      tabIndex={typeof tabIndex === 'undefined' ? -1 : tabIndex}
      ref={(ref) => {
        liElementRef.current = ref;

        if (focusableRef) {
          if (typeof focusableRef === 'function') {
            focusableRef(ref);
          } else {
            (focusableRef as CheckboxRefObject).current = ref;
          }
        }
      }}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
    >
      <Checkbox
        data-testid={props.testId}
        checked={checked}
        value={value}
        onChange={onChange}
        tabIndex={-1}
        disabled={props.disabled}
      >
        {props.children}
      </Checkbox>
    </li>
  );
};

export default ComplexCheckbox;
