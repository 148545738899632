import React, { CSSProperties, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useOutsideClick } from '@/hooks/useClickOutside';
import { useComponentDidMount } from '@/hooks/useComponentDidMount';
import { ITestComponentProps } from '@/types/test-component-props';
import { Icon } from '@/ui-components';

import './combobox.less';

export type DropDownItem = {
  key: string;
  value: string;
};

export interface IComboboxProps extends ITestComponentProps {
  value: string | undefined;
  items: DropDownItem[];
  onSelect?: (value: string) => void;
  disabled?: boolean;
  selectedDark?: boolean;
  borderLess?: boolean;
  staticWidth?: boolean;
  className?: string;
  rightAlign?: boolean;
}

const DELAY_IN_MS = 300;

const Combobox = (props: IComboboxProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [showScrollbar, setShowScrollbar] = useState(false);

  // states if the component overlaps other components. (the z-index must be increased when
  // the dropdow is about to open, fully opened or about to close. Only when the component is
  // fully closed, the z-Index is reset to zero):
  const [zIndexInitial, setZIndexInitial] = useState(true);

  const zIndexTimoutRef = useRef<any | null>(null);
  const scrollBarTimeoutRef = useRef<any | null>(null);
  const inputRef = useRef<null | HTMLInputElement>(null);

  const [preSelectedItem, setPreSelectedItem] = useState<DropDownItem | undefined>(undefined);
  const [filter, setFilter] = useState('');
  const [inputValue, setInputValue] = useState(props.value);

  useEffect(() => {
    if (props.value !== undefined) {
      handleItemSelect({ key: '-1', value: props.value });
    }
  }, [props.value]);

  const { t } = useTranslation();

  const ref = useOutsideClick(() => {
    if (isOpen) {
      toggleOpen();
    }
    handleItemSelect({ key: '-1', value: filter });
  });

  const getHelperWidth = useCallback(() => {
    // + 2px because of the dropdowns 1px border that is always set but visible/invisible depending on its color.
    return (ref.current && ref.current.clientWidth + 2) || 0;
  }, [ref]);

  useEffect(() => {
    const interval = setInterval(() => {
      !isOpen && setHelperStyle({ width: `${getHelperWidth()}px` });
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [isOpen, getHelperWidth]);

  const isAnItemSelected = false;

  const scrollableRef: RefObject<HTMLDivElement> = useRef(null);

  const [helperStyle, setHelperStyle] = useState<CSSProperties>({});

  const determineSelectableItems = (filter: string) => {
    const filterValue = filter.toLowerCase().trim();

    // if the filtervalue matches the current value it would be weird if the option
    // would not appear in the dropdown. Therefore the selected value should only be excluded from the
    // available options if no filter is set.
    // const selectableItems =
    //   filterValue.length > 0
    //     ? props.items.filter((i) => i.value.toLowerCase().includes(filterValue))
    //     : props.items.filter((i) => i.key !== selectedItem.key && i.value.toLowerCase().includes(filterValue));
    const selectableItems = props.items.filter((i) => i.value.toLowerCase().includes(filterValue));

    return selectableItems.sort((a, b) => {
      const indexA = a.value.toLowerCase().indexOf(filterValue);
      const indexB = b.value.toLowerCase().indexOf(filterValue);
      if (indexA === indexB) {
        return 0;
      }
      return indexA - indexB;
    });
  };

  const selectableItems = determineSelectableItems(filter);

  useComponentDidMount(() => {
    setHelperStyle({ width: `${getHelperWidth()}px` });
  });

  useEffect(() => {
    if (isOpen) {
      scrollBarTimeoutRef.current = setTimeout(() => {
        setShowScrollbar(true);
      }, DELAY_IN_MS);
    } else {
      if (scrollBarTimeoutRef.current != null) {
        clearTimeout(scrollBarTimeoutRef.current);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      zIndexTimoutRef.current = setTimeout(() => {
        setZIndexInitial(true);
      }, DELAY_IN_MS);
    } else {
      if (zIndexTimoutRef.current != null) {
        clearTimeout(zIndexTimoutRef.current);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const toggleOpen = () => {
    setFilter('');

    if (!isOpen && selectableItems.length === 0) {
      return;
    }

    setIsOpen(!isOpen);
    if (isOpen) {
      setShowScrollbar(false);
    } else {
      if (scrollableRef && scrollableRef.current != null) {
        scrollableRef.current!.scrollTop = 0;
        setShowScrollbar(false);
        setZIndexInitial(false);
      }
    }
  };

  const handleItemSelect = (item: DropDownItem) => {
    setInputValue(props.value);
    props.onSelect && props.onSelect(item.value);
  };

  const handleItemPreSelect = (item: DropDownItem) => {
    setPreSelectedItem(item);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      toggleOpen();
      return;
    }

    if (selectableItems.length === 0) {
      if (e.key === 'Enter' || e.key === 'Tab') {
        handleItemSelect({ key: '-1', value: filter });
        toggleOpen();
        inputRef.current?.blur();
      }
      return;
    }

    if (!preSelectedItem) {
      setPreSelectedItem(selectableItems[0]);
      return;
    }

    const currentIndex = selectableItems.findIndex((item) => item.key === preSelectedItem.key);

    if (e.key === 'ArrowUp') {
      preSelectPreviousItem(currentIndex);
    } else if (e.key === 'ArrowDown') {
      preSelectNextItem(currentIndex);
    } else if (e.key === 'Enter' || e.key === 'Tab') {
      handleItemSelect(preSelectedItem);
      toggleOpen();
    }
  };

  const preSelectNextItem = (currentIndex: number) => {
    if (currentIndex < selectableItems.length - 1) {
      setPreSelectedItem(selectableItems[currentIndex + 1]);
      if (scrollableRef.current) {
        const domItems = scrollableRef.current.getElementsByTagName('*');
        scrollableRef.current.scrollTop = domItems[currentIndex + 1].clientTop;

        const target = domItems[currentIndex + 1] as HTMLElement;
        if (target) {
          scrollableRef.current.scrollTop = target.offsetTop - 32;
        }
      }
    }
  };

  const preSelectPreviousItem = (currentIndex: number) => {
    if (currentIndex > 0) {
      setPreSelectedItem(selectableItems[currentIndex - 1]);
      if (scrollableRef.current) {
        const domItems = scrollableRef.current.getElementsByTagName('*');
        scrollableRef.current.scrollTop = domItems[currentIndex - 1].clientTop;

        const target = domItems[currentIndex - 1] as HTMLElement;
        if (target) {
          scrollableRef.current.scrollTop = target.offsetTop - 32;
        }
      }
    }
  };

  const renderItem = (item: DropDownItem) => {
    const className = clsx({
      'regular-item': true,
      preselected: preSelectedItem && preSelectedItem.key === item.key,
    });

    return (
      <span
        className={className}
        onClick={() => handleItemSelect(item)}
        key={item.key}
        onMouseEnter={() => handleItemPreSelect(item)}
      >
        {item.value}
      </span>
    );
  };

  const isFiltered = filter.length > 0;
  const isFilterResultEmpty = isFiltered && selectableItems.length === 0;

  const renderSelectableItems = () => {
    return selectableItems.map((i) => renderItem(i));
  };

  const handleInputClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
    setInputValue(e.target.value);
    const selectableItems = determineSelectableItems(e.target.value);
    if (selectableItems.length === 0) {
      setPreSelectedItem(undefined);
    } else {
      setPreSelectedItem(selectableItems[0]);
    }

    if (isOpen) {
      toggleOpen();
    }
  };

  return (
    <div className={clsx('combobox-wrapper', props.className)} data-testid={props.testId}>
      <div
        ref={ref}
        className={clsx('combobox-container-layer', {
          'is-open': isOpen,
          'static-width': props.staticWidth,
          'show-scrollbar': showScrollbar,
          'reset-z-index': zIndexInitial,
          disabled: !!props.disabled,
          'selected-dark': !!props.selectedDark && isAnItemSelected,
          'dropdown--borderless': props.borderLess,
          'right-align': props.rightAlign,
        })}
        onClick={toggleOpen}
      >
        <div className="combobox-container-layer-items">
          <div className="first-line-helper">
            <fieldset>
              <input
                value={inputValue}
                ref={inputRef}
                onClick={handleInputClick}
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
              />
            </fieldset>
          </div>
          <div ref={scrollableRef} className="other-items">
            <div className="drop-down-static-helper-wrapper">{props.items.map((item) => renderItem(item))}</div>
            {isFilterResultEmpty ? (
              // stop event propagation so that the dropdown stays open when the user clicks the "no-results" hint
              <span className="no-results" onClick={(e) => e.stopPropagation()}>
                {t('general.noSearchResults')}
              </span>
            ) : (
              renderSelectableItems()
            )}
          </div>
        </div>
        {!props.disabled && <Icon type="dropdown" />}
      </div>
      <div style={helperStyle} className="combobox-helper" />
    </div>
  );
};

export default Combobox;
