import { Store } from '@/types/store';
import { AbstractTimetableDataEnricher } from '@te/standard/stores/data/abstract-timetable-data-enricher';
import { BackEntryDto, BackEntryTypeEnum, GridEntryDto, GridEntryTypeEnum } from '@untis/wu-rest-view-api';
import { IGridEntry } from '@te/standard/stores/data/timetable-data-store';
import { LessonCardRowContent } from '@/components/lesson-card/lesson-card';

const GRID_ENTRY_TYPE_SUPPORTED_LIST: GridEntryTypeEnum[] = [
  GridEntryTypeEnum.NORMAL_TEACHING_PERIOD,
  GridEntryTypeEnum.ADDITIONAL_PERIOD,
  GridEntryTypeEnum.EVENT,
  GridEntryTypeEnum.STAND_BY_PERIOD,
  GridEntryTypeEnum.OFFICE_HOUR,
  GridEntryTypeEnum.EXAM,
  GridEntryTypeEnum.BREAK_SUPERVISION,
];

const BACK_ENTRY_TYPE_SUPPORTED_LIST: BackEntryTypeEnum[] = [
  BackEntryTypeEnum.ABSENCE,
  BackEntryTypeEnum.BLOCKING,
  BackEntryTypeEnum.HOLIDAY,
];

@Store()
export class TimetableUntisPeriodEnricher extends AbstractTimetableDataEnricher {
  supportsDayEntry(): boolean {
    return false;
  }

  supportsGridEntry(gridEntryDto: GridEntryDto): boolean {
    return GRID_ENTRY_TYPE_SUPPORTED_LIST.includes(gridEntryDto.type);
  }

  supportsBackEntry(backEntryDto: BackEntryDto): boolean {
    return BACK_ENTRY_TYPE_SUPPORTED_LIST.includes(backEntryDto.type);
  }

  enrichFullDayEvent(): void {
    throw new Error('not supported');
  }

  enrichPeriod(toEnrich: IGridEntry, gridEntryDto: GridEntryDto): void {
    const row1 = this.mapEntryResourcePairs(gridEntryDto.position1 ?? []);
    const row2 = this.mapEntryResourcePairs(gridEntryDto.position2 ?? []);
    const row3 = this.mapEntryResourcePairs(gridEntryDto.position3 ?? []);
    const row4 = this.mapEntryResourcePairs(gridEntryDto.position4 ?? []);

    row1 && this.addRow(toEnrich, row1);
    row2 && this.addRow(toEnrich, row2);
    row3 && this.addRow(toEnrich, row3);
    row4 && this.addRow(toEnrich, row4);
  }

  private addRow(toEnrich: IGridEntry, row: LessonCardRowContent) {
    toEnrich.lessonCardProps.rows.push(row);
  }
}
