import React, { ReactNode } from 'react';
import { Modal as AntDesignModal } from 'antd';
import clsx from 'clsx';

import { Button, Flex, Icon, IconButton, Stack } from '@/ui-components';
import './modal.less';
import { ITestComponentProps } from '@/types/test-component-props';

type ButtonProps = {
  label: string;
  onClick?: () => void;
};

export type IconButtonProps = {
  title: string;
  icon: string;
  onClick?: () => void;
  testId?: string;
};

export type ModalSize =
  | 'sm'
  | 'md'
  | 'lg'
  | 'square-md'
  | 'full-size'
  | 'full-size-content'
  | 'lesson-details'
  | 'date-calendar'
  | 'date-calendar-with-header-or-footer'
  | 'date-calendar-with-header-and-footer'
  | 'date-range-calendar'
  | 'date-range-calendar-with-header-or-footer'
  | 'date-range-calendar-with-header-and-footer';

export interface IModalProps extends ITestComponentProps {
  title?: string | string[];
  children?: ReactNode;
  okButton?: ButtonProps;
  cancelButton?: ButtonProps;
  leftButtons?: ButtonProps[];
  iconButtons?: IconButtonProps[];
  showHeaderSeparator?: boolean;
  showFooterSeparator?: boolean;
  hideXIcon?: boolean; // if true, dialog can still be closed by clicking in the mask. Just hides the X icon.
  size?: ModalSize; // full-size-content also removes the dialogs padding
  mask?: boolean;
  getContainer?: string | HTMLElement | false;
  // Can be useful if you want to show a userPrompt and depending on the action of the user to close or not to close.
  // The modal does not use this but holds the information, so the system that uses the modal can take care of it.
  closeCondition?: () => Promise<boolean>;
  className?: string;
  hasDestructiveAction?: boolean; // if true, position of footer buttons and type of primary button will change
  lastFocusedElement?: HTMLElement;
  noPadding?: boolean;
  disableNavigation?: boolean;
  maskClosable?: boolean;
  keyboard?: boolean;
  onAfterClose?: () => void;
  onCancelCloseAll?: boolean;
  // when forms are used within modals, the buttons at the bottom belong to the form, not to the dialog
  // (otherwise things like automatically disabling submit button when the form is invalid would not work)
  // In this case, the Form is responsible for making the Content Area scrollable, not the dialog.
  containsForm?: boolean;
}

export interface IPromptProps<T> {
  getPromptValue: () => T;
  setPromptValue: (value: T) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

/**
 * Separated from IModalProps because this does not really concern the Modal but how it is integrated.
 * Developers should not need to deal with this when they are just adding a new Dialog.
 */
export interface IInternalModalProps<T> {
  isOpen?: boolean;
  onClose?: () => void; // What happens when the dialog closes? E.g. WU modal-store should pop an item from the stack.
  marginLeft?: 76 | 280; // for full size dialogs, to fit with expanded/collapsed navigation bar
  isUserPrompt: boolean; // just holds the information if the dialog is used as a prompt or not
  promptProps?: IPromptProps<T>;
  lastFocusedElement?: HTMLElement | null;
}

/**
 * Modal Dialog based on AntDesign Dialog
 * Default Footer and Title of the AntDesignModal are disabled since we probably will put custom content there instead.
 */
export function Modal<T>(props: IModalProps & IInternalModalProps<T>) {
  const renderHeader: boolean = props.title !== undefined;
  const renderFooter: boolean =
    !!props.okButton ||
    !!props.cancelButton ||
    (!!props.iconButtons && props.iconButtons.length > 0) ||
    (!!props.leftButtons && props.leftButtons.length > 0);

  const className = clsx('wu-modal', props.className, {
    'size-sm': !props.size || props.size === 'sm',
    'size-md': props.size === 'md',
    'size-lg': props.size === 'lg',
    'size-date-calendar': props.size === 'date-calendar',
    'size-date-calendar-with-header-or-footer': props.size === 'date-calendar-with-header-or-footer',
    'size-date-calendar-with-header-and-footer': props.size === 'date-calendar-with-header-and-footer',
    'size-date-range-calendar': props.size === 'date-range-calendar',
    'size-date-range-calendar-with-header-or-footer': props.size === 'date-range-calendar-with-header-or-footer',
    'size-date-range-calendar-with-header-and-footer': props.size === 'date-range-calendar-with-header-and-footer',
    'size-full-size': props.size === 'full-size',
    'size-full-size-content': props.size === 'full-size-content',
    'size-square-md': props.size === 'square-md',
    'size-lesson-details': props.size === 'lesson-details',
    'wu-modal--destructive-action': props.hasDestructiveAction,
    'wu-modal--has-header-separator': props.showHeaderSeparator,
    'hide-x-icon': props.hideXIcon,
    'contains-form': props.containsForm,
  });

  const onOk = () => {
    if (props.promptProps?.onSubmit) {
      props.promptProps.onSubmit();
    } else if (props.okButton?.onClick) {
      props.okButton?.onClick();
    }
  };

  const onCancel = () => {
    if (props.promptProps?.onCancel) {
      props.promptProps.onCancel();
    } else if (props.cancelButton?.onClick) {
      props.cancelButton?.onClick();
    }
  };

  const onClose = () => {
    if (props.promptProps?.onCancel) {
      props.promptProps.onCancel();
    } else if (props.onClose) {
      props.onClose();
    }
  };

  const renderTitle = (title: string | string[]) => {
    if (typeof title === 'string') {
      return title;
    } else {
      return (
        <div className="title-container">
          {title.map((value, index) => {
            return (
              <span className="title-part" key={index}>
                {value}
              </span>
            );
          })}
        </div>
      );
    }
  };

  const isContentAreaScrollable = props.size === 'sm' || props.size === 'md' || props.size === 'lg';

  return (
    <AntDesignModal
      visible={props.isOpen}
      className={className}
      wrapProps={{
        'data-testid': props.testId,
      }}
      onCancel={onClose}
      closeIcon={
        <div className="close-icon">
          <Icon type="cancel-gray" />
        </div>
      }
      keyboard={props.keyboard !== false}
      maskClosable={!!props.maskClosable}
      footer={null} // We will probably put custom content into the footer -> hide the default one.
      title={null} // We will probably put custom content into the title -> hide the default one.
      wrapClassName={
        props.marginLeft === 76 ? 'margin-left-76' : props.marginLeft === 280 ? 'margin-left-280' : undefined
      }
      mask={props.mask !== false}
      getContainer={props.getContainer}
      bodyStyle={
        isContentAreaScrollable
          ? {
              overflowY: props.containsForm ? 'hidden' : 'auto',
              maxHeight: 'calc(100vh - 64px)',
            }
          : undefined
      }
      style={
        isContentAreaScrollable
          ? {
              top: 32,
            }
          : undefined
      }
    >
      {renderHeader && (
        <div className="header">
          <h1 className="title">{renderTitle(props.title ? props?.title : '')}</h1>
          {props.showHeaderSeparator && <div className="separator" />}
        </div>
      )}

      {props.noPadding ? props.children : <div className="content-container">{props.children}</div>}
      {renderFooter && props.showFooterSeparator && <div className="separator" />}
      {renderFooter && (
        <div className="footer">
          <div className="left-buttons">
            <Stack as={Flex} x="md" className="button-container">
              {props.leftButtons &&
                props.leftButtons.map((button) => (
                  <Button size="large" key={button.label} outline onClick={button.onClick}>
                    {button.label}
                  </Button>
                ))}
            </Stack>
            <Stack as={Flex} x="xs" className="icon-button-container">
              {props?.iconButtons?.map((iconButton) => (
                <IconButton
                  ariaLabel={iconButton.title}
                  key={iconButton.title}
                  onClick={iconButton.onClick}
                  type={iconButton.icon}
                  tooltip={{
                    placement: 'top',
                    title: iconButton.title,
                  }}
                  testId={iconButton.testId}
                />
              ))}
            </Stack>
          </div>
          <div>
            <Stack as={Flex} x="md">
              {props.cancelButton && (
                <Button size="large" outline onClick={onCancel} testId="userprompt-cancel">
                  {props.cancelButton.label}
                </Button>
              )}
              {props.okButton && (
                <Button
                  {...(props.hasDestructiveAction ? { type: 'destructive', outline: true } : { type: 'primary' })}
                  size="large"
                  onClick={onOk}
                  testId="userprompt-confirm"
                >
                  {props.okButton.label}
                </Button>
              )}
            </Stack>
          </div>
        </div>
      )}
    </AntDesignModal>
  );
}
