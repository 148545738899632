import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'clsx';

import useStore from '@/hooks/useStore';
import { LessonCard } from '@/components/lesson-card';
import { ILessonCardProps } from '@/components/lesson-card/lesson-card';
import { IGridEntry } from '@te/standard/stores/data/timetable-data-store';
import { TimetableCardDisplayStore } from '@te/standard/stores/format/timetable-card-display-store';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import { TimetableUserActionsStore } from '@/pages/timetable/standard/stores/user-actions/timetable-user-actions-store';

import './timetable-grid-card.less';

interface IProps {
  gridEntry: IGridEntry;
}

const TimetableGridCard = observer((props: IProps) => {
  const timetableCardDisplayStore = useStore(TimetableCardDisplayStore);
  const timetableUserActionsStore = useStore(TimetableUserActionsStore);
  const timetableFormatStore = useStore(TimetableFormatStore);

  const { gridEntry } = props;
  const { top, height, widthPercentage, leftPercentage, lessonCardProps } = gridEntry;

  const parallelLeftOffset = leftPercentage !== 0 ? 1 : 0;
  const parallelWidthOffset = widthPercentage !== 100 ? 1 : 0;

  const timetableEntryDisplayProps = timetableCardDisplayStore.getTimetableEntryDisplayProps(gridEntry);
  const isClickable = !timetableFormatStore.timetableFormat?.hideDetails || gridEntry.isExternal;

  const handleClick = () => {
    if (isClickable) {
      timetableUserActionsStore.handlePeriodClick(gridEntry);
    }
  };

  const updatedLessonCardProps: ILessonCardProps = {
    ...lessonCardProps,
    displayProps: {
      ...timetableEntryDisplayProps,
      hideShadow: true,
    },
    onLessonClick: isClickable ? handleClick : undefined,
  };

  const zIndex = 10 + props.gridEntry.lessonCardProps.startDateTime.hour();

  return (
    <div
      className={classNames('timetable-grid-card', {
        shadow: !timetableEntryDisplayProps.hideShadow,
      })}
      data-testid={`timetable-grid-card-${gridEntry.periodIds.join('-')}`}
      style={{
        top: top + 1,
        height: `${height === 0 ? 0 : height - 1}px`,
        left: `calc(${leftPercentage}% + ${parallelLeftOffset}px)`,
        width: `calc(${widthPercentage}% - ${parallelWidthOffset}px)`,
        zIndex: zIndex,
      }}
    >
      <LessonCard {...updatedLessonCardProps} />
    </div>
  );
});

export default TimetableGridCard;
