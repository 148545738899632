import React from 'react';
import classNames from 'clsx';
import { Dayjs } from 'dayjs';

import { formatTime } from '@/utils/date/date-util';

import './timetable-grid-slot-time.less';

export type TimeGridSlotTextPosition = 'above' | 'below' | 'normal';
export const TIMEGRID_SLOT_TEXT_POSITION_ABOVE = -7;
export const TIMEGRID_SLOT_TEXT_POSITION_BELOW = 7;

interface IProps {
  value: Dayjs;
  top: number;
  hideText: boolean;
  textPosition: TimeGridSlotTextPosition;
}

const TimetableGridSlotTime = (props: IProps) => {
  const { value, top, textPosition, hideText } = props;

  return (
    <div className={classNames('timetable-grid-slot-time')} style={{ top: top }}>
      <span
        className={classNames('timetable-grid-slot-time--time-value', {
          invisible: hideText,
          above: textPosition === 'above',
          below: textPosition === 'below',
        })}
        data-testid="timetable-grid-slot-time--time-value"
      >
        {formatTime(value)}
      </span>
      <div className="timetable-grid-slot-time--line" />
    </div>
  );
};

export default TimetableGridSlotTime;
