import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { TooltipProps } from 'antd/es/tooltip';

import { IconButton } from '@/ui-components/icon-button/icon-button';
import { Icon } from '@/ui-components/icon/icon';
import { Badge } from '@/ui-components/badge/badge';
import Tooltip from '@/ui-components/tooltip/tooltip';
import { Tag } from '@/ui-components';

import './link-card.less';

export interface ILinkCardProps {
  icon: string;
  href?: string;
  title: string;
  subtitle?: string;
  extra?: string;
  description?: string;
  badge?: number;
  tag?: string;
  iconOnlyLink?: boolean;
  iconColor?: 'red' | 'orange';
  highlight?: boolean;
  tooltip?: TooltipProps;
}

export const LinkCard = ({
  title,
  subtitle,
  icon,
  extra,
  tooltip,
  badge,
  tag,
  href,
  iconOnlyLink: useIconOnlyLink,
  highlight,
  iconColor,
}: ILinkCardProps) => {
  const linkCardBody = (
    <div className="link-card-body">
      <div className="link-card-body-container">
        <Icon className={clsx('link-card-icon', iconColor)} type={icon} />
        <div className="link-card-body-container-main">
          <div className="link-card-body-container-main-heading">
            <div className="link-card-body-container-main-heading-title">
              <span>{title}</span>
              {tag && tooltip ? (
                <Tooltip {...tooltip} zIndex={10} trigger="hover">
                  <Tag uppercase>{tag}</Tag>
                </Tooltip>
              ) : null}
            </div>
            {subtitle && <span className="link-card-body-container-main-heading-subtitle">{subtitle}</span>}
          </div>
          {extra && (
            <div className="link-card-body-container-main-extra">
              <span className="link-card-divider" />
              <span>{extra}</span>
            </div>
          )}
        </div>
        {badge && <Badge count={badge} />}
      </div>
    </div>
  );

  if (useIconOnlyLink) {
    return (
      <div
        className={clsx('link-card', {
          'link-card--highlight': highlight,
        })}
      >
        {linkCardBody}
        <IconButton href={href} type="point-arrow-right" ariaLabel={`Link to ${title}`} />
      </div>
    );
  }

  if (href?.startsWith('/')) {
    return (
      <Link
        to={href}
        className={clsx('link-card', {
          'link-card--highlight': highlight,
        })}
      >
        {linkCardBody}
        <Icon className="link-card-icon link-card-link-icon" type="point-arrow-right" />
      </Link>
    );
  }

  if (href) {
    return (
      <a
        href={href}
        className={clsx('link-card', {
          'link-card--highlight': highlight,
        })}
      >
        {linkCardBody}
        <Icon className="link-card-icon link-card-link-icon" type="point-arrow-right" />
      </a>
    );
  }

  return (
    <div
      className={clsx('link-card', {
        'link-card--highlight': highlight,
      })}
    >
      {linkCardBody}
    </div>
  );
};
