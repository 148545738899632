import { Dayjs } from 'dayjs';

import { byKlasseName, byTeacherName } from '@tt/utils/sorting';
import { NumberRange } from '@/types/number-range';
import { TimePreferenceWeight } from '@untis/wu-rest-tt-api';

// general types

export enum Day {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export type TTTimeGridRow = {
  index: number;
  label: string | null;
};

export enum DayTime {
  /**
   * Non-existing or non-schedulable range (= "hole") in the time-grid.
   */
  NONE = 'NONE',
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
}

export enum PredefinedDateScheme {
  WEEK_A = 'WEEK_A',
  WEEK_B = 'WEEK_B',
}

export type DateRange = {
  /**
   * The beginning of this date-range, inclusive.
   */
  start: Dayjs;

  /**
   * The end of this date-range, inclusive (so a date-range consisting of a single day is possible.)
   */
  end: Dayjs;
};

export type TimeSpan = {
  /**
   * The begin of this time-span, inclusive. Format "hhmm", e.g. 800 for 08:00.
   */
  start: number;

  /**
   * The end of this time-span, *exclusive*. Format "hhmm", e.g. 2359 for 23:59.
   */
  end: number;
};

// timetabling: master-data

/**
 * A (planning-) horizon is the outermost container for all other data belonging to a timetable from a
 * timetabling-domain point-of-view.
 * <p>
 * A horizon must always be fully contained within a single {@link TTSchoolyear}. Using horizons, a user can create
 * several timetables, either for an entire schoolyear or just a part of it.
 */
export type TTHorizon = {
  /**
   * The ID of this horizon.
   */
  id: number;

  /**
   * The name of this horizon.
   */
  name: string;

  /**
   * Optional: A description of this horizon.
   */
  description: string;

  /**
   * The date-range of this horizon. Defines the period of validity for the timetable that this horizon is describing.
   */
  dateRange: DateRange;
};

/**
 * A "klasse" (German) is a special group of students which typically stays together over the years, e.g.
 * "1a of 2020" becoming "2a of 2021" and so on.
 */
export type TTKlasse = {
  /**
   * The WebUntis ID of this klasse.
   */
  id: number;

  /**
   * The short name of this klasse.
   */
  name: string;
};

/**
 * A room is a place within some school's building where lessons or events can take place, e.g. the class-room "R1a",
 * the music room or the gym.
 */
export type TTRoom = {
  /**
   * The WebUntis ID of this room.
   */
  id: number;

  /**
   * The short name of this room.
   */
  name: string;
};

export type TTRoomRequirement = {
  homeRoom: TTRoom | null;
  subjectRoom: TTRoom | null;
};

/**
 * A schoolyear can be seen as the outermost container for a lot of the school's data, such as klassen, lessons,
 * events, grading, etc. It can be further divided into semesters.
 */
export type TTSchoolyear = {
  /**
   * The ID of the schoolyear.
   */
  id: number;

  /**
   * The name of the schoolyear.
   */
  name: string;

  /**
   * The duration of the schoolyear.
   */
  dateRange: DateRange;
};

export type TTStudentGroup = {
  /**
   * The ID of the student-group.
   */
  id: number;

  /**
   * The name of the student-group.
   */
  name: string;
};

/**
 * A subject is something being taught at school, e.g. Mathematics, History, etc.
 */
export type TTSubject = {
  /**
   * The WebUntis ID of this subject.
   */
  id: number;

  /**
   * The short name of this subject.
   */
  name: string;

  /**
   * The background-color of this subject, e.g.: 3366FF
   */
  backColor?: string | null;
};

/**
 * A teacher is a person who teaches students by giving lessons.
 */
export type TTTeacher = {
  /**
   * The WebUntis ID of this teacher.
   */
  id: number;

  /**
   * The short name of this teacher.
   */
  name: string;
};

export type TTTimePreferenceData = {
  timePreferencesKlasse: Array<TTTimePreferenceKlasse>;
  timePreferencesRoom: Array<TTTimePreferenceRoom>;
  timePreferencesSubject: Array<TTTimePreferenceSubject>;
  timePreferencesTeacher: Array<TTTimePreferenceTeacher>;
};
export type TTTimePreferenceKlasse = {
  klasse: TTKlasse;
  timePreferences: Array<TTTimePreferenceSlot>;
};
export type TTTimePreferenceSubject = {
  subject: TTSubject;
  timePreferences: Array<TTTimePreferenceSlot>;
};

export type TTTimePreferenceRoom = {
  room: TTRoom;
  timePreferences: Array<TTTimePreferenceSlot>;
};

export type TTTimePreferenceTeacher = {
  teacher: TTTeacher;
  timePreferences: Array<TTTimePreferenceSlot>;
};

export type TTTimePreferenceSlot = {
  day: Day;
  index: number;
  weight: TimePreferenceWeight;
};

/**
 * A time-grid specifies the size of the timetable, being the number of days (e.g. MON - FRI) and hours per
 * day (e.g. 8). It consists of {@link TTTimeGridSlot}s.
 */
export class TTTimeGrid {
  constructor(
    private readonly _id: number,
    private readonly _name: string,
    private readonly _firstDayOfWeek: Day,
    private readonly _slots: TTTimeGridSlot[],
    private readonly _rows: TTTimeGridRow[],
  ) {}

  /**
   * The ID of this time-grid.
   */
  get rows(): TTTimeGridRow[] {
    return this._rows;
  }

  /**
   * The ID of this time-grid.
   */
  get id(): number {
    return this._id;
  }

  /**
   * The name of this time-grid.
   */
  get name(): string {
    return this._name;
  }

  /**
   * The first week-day of the time-grid (typically Monday, but every week-day is valid).
   */
  get firstDayOfWeek(): Day {
    return this._firstDayOfWeek;
  }

  /**
   * The actual slots of this time-grid.
   */
  get slots(): TTTimeGridSlot[] {
    return this._slots;
  }
}

/**
 * One slot (= entry) within a {@link TTTimeGrid}, defined by its week-day and index, e.g. "MON-0".
 */

export class TTEvaluatedSlot {
  constructor(public day: Day, public index: number, public score: number, public collision: boolean) {}
}

export class TTTimeGridSlot {
  constructor(
    private readonly _day: Day,
    private readonly _index: number,
    private readonly _timeSpan: TimeSpan,
    private readonly _dayTime: DayTime,
  ) {}

  /**
   * The week-day of this time-grid-slot.
   */
  get day(): Day {
    return this._day;
  }

  /**
   * The index of this slot on its week-day, starting with 0 for the first slot of the day.
   */
  get index(): number {
    return this._index;
  }

  /**
   * The duration of this slot, e.g. "14:00 - 14:45".
   */
  get timeSpan(): TimeSpan {
    return this._timeSpan;
  }

  /**
   * The logical time-of-day this slot falls into, e.g. AFTERNOON.
   */
  get dayTime(): DayTime {
    return this._dayTime;
  }
}

// timetabling: lessons, scheduling-units, constraints, ...

export type HighlightedSlot = {
  day: Day | undefined;
  slotIndex: number | undefined;
  periodId: number | undefined;
};

export class TTLesson {
  constructor(
    private readonly _id: number,
    private readonly _dateRange: DateRange,
    private readonly _predefinedDateScheme: PredefinedDateScheme | null,
    private readonly _subject: TTSubject,
    private readonly _klassen: TTKlasse[],
    private readonly _teachers: TTTeacher[],
    private readonly _schedulingUnitId: number,
    private readonly _studentGroup: TTStudentGroup | undefined,
  ) {}

  /**
   * @returns The ID of this lesson.
   */
  get id(): number {
    return this._id;
  }

  /**
   * @returns The date-range of this lesson.
   */
  get dateRange(): DateRange {
    return this._dateRange;
  }

  /**
   * @returns Optional: A {@link PredefinedDateScheme} for this lesson, e.g. "Week A".
   */
  get predefinedDateScheme(): PredefinedDateScheme | null {
    return this._predefinedDateScheme;
  }

  /**
   * @returns The ID of the {@link TTSchedulingUnit} of this lesson.
   */
  get schedulingUnitId(): number {
    return this._schedulingUnitId;
  }

  /**
   * @returns The {@link TTSubject} of this lesson.
   */
  get subject(): TTSubject {
    return this._subject;
  }

  /**
   * @returns The {@link TTKlasse}n of this lesson.
   */
  get klassen(): TTKlasse[] {
    return this._klassen;
  }

  /**
   * @returns The {@link TTTeacher}s of this lesson.
   */
  get teachers(): TTTeacher[] {
    return this._teachers;
  }

  /**
   * @returns Optional: The {@link TTStudentGroup} of this lesson.
   */
  get studentGroup(): TTStudentGroup | undefined {
    return this._studentGroup;
  }
}

/**
 * A period specifies when some {@link TTSchedulingUnit}'s {@link TTLesson}(s) are taking place,
 * e.g. on Monday from 8:00 - 8:50.
 */
export class TTPeriod {
  constructor(
    private readonly _id: number,
    private readonly _day: Day,
    private readonly _slotIndex: number,
    private readonly _timeSpan: TimeSpan,
    private readonly _locked: boolean,
    private readonly _schedulingUnitId: number,
    private readonly _roomIdsByLessonId: Map<number, number[]>,
  ) {}

  /**
   * @returns The ID of this period.
   */
  get id(): number {
    return this._id;
  }

  /**
   * @returns The ID of the {@link TTSchedulingUnit} this period belongs to.
   */
  get schedulingUnitId(): number {
    return this._schedulingUnitId;
  }

  /**
   * @returns This period's week-day.
   */
  get day(): Day {
    return this._day;
  }

  /**
   * @returns Index of the {@link TTTimeGridSlot} in the {@link TTTimeGrid}. This is 0-based, i.e. MON-0 for
   * the first slot.
   */
  get slotIndex(): number {
    return this._slotIndex;
  }

  /**
   * @returns This period's duration.
   */
  get timeSpan(): TimeSpan {
    return this._timeSpan;
  }

  /**
   * @returns If the period is locked, the optimization is not allowed to move it to a different position
   * in the timetable.
   */
  get locked(): boolean {
    return this._locked;
  }

  /**
   * @returns The IDs of the {@link TTRoom}s of this period, separated by its {@link TTLesson}-IDs.
   */
  get roomIdsByLessonId(): Map<number, number[]> {
    return this._roomIdsByLessonId;
  }
}

/**
 * A scheduling-unit defines how many {@link TTPeriod}s are to be scheduled for some {@link TTLesson}.
 * <p>
 * If the scheduling-unit has several {@link TTLesson}s (e.g. "Sports in klasse 1a" and "Sports in klasse 1b"),
 * it's called a "coupling".
 * <p>
 * A single scheduled {@link TTPeriod} of a coupling results in one lesson-period for each
 * lesson, which will take place simultaneously, without collision.
 */
export class TTSchedulingUnit {
  constructor(
    public id: number,
    public periodsPerWeek: number,
    public lessonIds: number[],
    public periodIds: number[],
    public lessons?: TTLesson[],
    public blockSize?: number,
    public blockAmount?: number,
  ) {}

  get nonScheduledPeriodsCount() {
    return this.periodsPerWeek - this.periodIds.length;
  }

  /**
   * @returns All related {@link TTKlasse}n of this scheduling-unit, sorted and without duplicates.
   */
  getKlassen(): TTKlasse[] {
    const result: TTKlasse[] = [];
    this.lessons?.forEach((lesson: TTLesson) => {
      lesson.klassen.forEach((klasse: TTKlasse) => {
        if (!result.find((resultKlasse: TTKlasse) => resultKlasse.id === klasse.id)) {
          result.push(klasse);
        }
      });
    });
    return result.sort(byKlasseName);
  }

  /**
   * @returns All related {@link TTTeacher}s of this scheduling-unit, sorted and without duplicates.
   */
  getTeachers(): TTTeacher[] {
    const result: TTTeacher[] = [];
    this.lessons?.forEach((lesson: TTLesson) => {
      lesson.teachers.forEach((teacher: TTTeacher) => {
        if (!result.find((resultTeacher: TTTeacher) => resultTeacher.id === teacher.id)) {
          result.push(teacher);
        }
      });
    });
    return result.sort(byTeacherName);
  }
}

export type TTMasterDataConstraintData = {
  constraintsKlasse: Array<TTMasterDataConstraintKlasse>;
  constraintsSubject: Array<TTMasterDataConstraintSubject>;
  constraintsTeacher: Array<TTMasterDataConstraintTeacher>;
};

export type TTMasterDataConstraintKlasse = {
  klasse: TTKlasse;
  periodsPerDay: NumberRange;
};

export type TTMasterDataConstraintSubject = {
  subject: TTSubject;
  allowLargeBlocks: boolean;
  allowMoreThanOncePerDay: boolean;
};

export type TTMasterDataConstraintTeacher = {
  teacher: TTTeacher;
  nonTeachingPeriods: NumberRange;
  periodsPerDay: NumberRange;
};

export enum TTOptimizationState {
  INITIAL = 'INITIAL',
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  ABORTED = 'ABORTED',
}

export enum TTOptimizationType {
  QUICK = 'QUICK',
  INTENSE = 'INTENSE',
}

export type TTOptimizationJob = {
  id: string;
  type: TTOptimizationType;
  createdOn: Dayjs;
  status: TTOptimizationJobStatus;
  statistics: TTOptimizationJobStatistics;
  indicators: TTOptimizationJobIndicators;
};

export type TTOptimizationJobIndicators = {
  totalNmbKlasseNonTeachingPeriods: number;
  totalNmbBlockConstraintViolations: number;
  totalNmbSubjectMoreThanOncePerDayViolations: number;
  totalNmbUnscheduledPeriods: number;
  totalNmbKlasseCollisions: number;
  totalNmbTeacherCollisions: number;
  totalNmbRoomCollisions: number;
};

export type TTOptimizationJobStatus = {
  state: TTOptimizationState;
  progress: number;
  error?: string;
};

export type TTOptimizationJobStatistics = {
  executionStarted?: Dayjs;
  executionEnded?: Dayjs;
};

export type TTChangeSet = {
  lessonsAdded: TTLessonAddedConflict[];
  lessonsChanged: TTLessonChangedConflict[];
  lessonsDeleted: TTLessonDeletedConflict[];
};

export type TTLessonAddedConflict = {
  lesson: WULesson;
};

export type TTLessonDeletedConflict = {
  lessonId: number;
};

export type TTLessonChangedConflict = {
  lesson: WULesson;
  dateRange?: {
    old: DateRange;
    new: DateRange;
  };
  klassen?: {
    old: TTKlasse[];
    new: TTKlasse[];
  };
  periodsPerWeek?: {
    old: number;
    new: number;
  };
  predefinedDateScheme?: {
    old: PredefinedDateScheme | null;
    new: PredefinedDateScheme | null;
  };
  roomRequirements?: {
    old: TTRoomRequirement[];
    new: TTRoomRequirement[];
  };
  subject?: {
    old: TTSubject;
    new: TTSubject;
  };
  teachers?: {
    old: TTTeacher[];
    new: TTTeacher[];
  };
  studentGroup?: {
    old: TTStudentGroup;
    new: TTStudentGroup;
  };
};

export type LessonCardActions = {
  amountDecreasable: boolean;
  amountIncreasable: boolean;
  blockSizeDecreasable: boolean;
  blockSizeIncreasable: boolean;
  splittable: boolean;
  removable: boolean;
  canBeCoupled: boolean;
  canBeUncoupled: boolean;
};

export type TTSchedulingUnitConfiguration = {
  actions: LessonCardActions;
  schedulingUnit: TTSchedulingUnit;
};

export type WULesson = {
  id: number;
  periodsPerWeek: number;
  subject: TTSubject;
  klassen: TTKlasse[];
  teachers: TTTeacher[];
  predefinedDateScheme?: PredefinedDateScheme | null;
  studentGroup?: TTStudentGroup | undefined;
};

export const DNDItemTypes = {
  SLOT: 'SLOT',
  SCHEDULING_UNIT: 'SCHEDULING_UNIT',
  SINGLE_PERIOD_STACK: 'SINGLE_PERIOD_STACK',
  /* SINGLE_PERIOD_STACK is the first/pure version of scheduling unit without couplings and blocks */
};

/** TIMETABLING DIAGNOSIS TYPES */

export type TTBlockPeriodSurplus = {
  schedulingUnitIds: number[];
  periodIds: number[];
  amount: number;
  blockSize: number;
  subjectId: number;
};
export type TTBlockPeriodMissing = {
  schedulingUnitIds: number[];
  periodIds: number[];
  amount: number;
  blockSize: number;
  subjectId: number;
};
export type TTCollision = { periodIds: number[] };
export type TTNmbPeriodsPerDayViolationKlasse = {
  actual: number;
  max: number;
  min: number;
  day: Day;
};
export type TTNmbPeriodsPerDayViolationTeacher = {
  actual: number;
  max: number;
  min: number;
  day: Day;
};
export type TTNonTeachingPeriodViolationKlasse = { actual: number; slots: TTTimeGridSlot[] };
export type TTNonTeachingPeriodViolationTeacher = { actual: number; slots: TTTimeGridSlot[] };
export type TTSubjectMoreThanOncePerDayUnavoidableViolation = { schedulingUnitIds: number[]; subjectId: number };
export type TTSubjectMoreThanOncePerDayViolation = {
  schedulingUnitIds: number[];
  subjectId: number;
  periodIds: number[];
};

export type ElementFilter = {
  value: string;
  key: string;
};

export type TimePreferenceViolation = {
  weight: TimePreferenceWeight;
  slots: TTTimeGridSlot[];
  periodIds: number[];
};

export type TTDiagnosisKlasse = {
  blockPeriodsSurplus: TTBlockPeriodSurplus[];
  blockPeriodsMissing: TTBlockPeriodMissing[];
  collisions: TTCollision[];
  nmbPeriodsPerDayViolations: TTNmbPeriodsPerDayViolationKlasse[];
  nonTeachingPeriodViolations: TTNonTeachingPeriodViolationKlasse[];
  subjectMoreThanOncePerDayUnavoidableViolations: TTSubjectMoreThanOncePerDayUnavoidableViolation[];
  subjectMoreThanOncePerDayViolations: TTSubjectMoreThanOncePerDayViolation[];
  timePreferenceViolations: TimePreferenceViolation[];
  klasseId: number;
  count: number;
};

export type TTDiagnosisTeacher = {
  collisions: TTCollision[];
  nmbPeriodsPerDayViolations: TTNmbPeriodsPerDayViolationTeacher[];
  nonTeachingPeriodViolations: TTNonTeachingPeriodViolationTeacher[];
  timePreferenceViolations: TimePreferenceViolation[];
  teacherId: number;
  count: number;
};

export type TTDiagnosis = {
  partitions: TTDiagnosisPartition[];
};

export type TTDiagnosisPartition = {
  predefinedDateScheme: PredefinedDateScheme | null;
  count: number;
  klasseItems: TTDiagnosisKlasse[];
  roomItems: TTDiagnosisRoom[];
  teacherItems: TTDiagnosisTeacher[];
};

export type TTDiagnosisRoom = { collisions: TTCollision[]; roomId: number; count: number };

export enum DiagnosisCategory {
  BlockPeriodsSurplus = 'BlockPeriodsSurplus',
  BlockPeriodsMissing = 'BlockPeriodsMissing',
  Collision = 'Collision',
  NmbPeriodsPerDayViolation = 'NmbPeriodsPerDayViolation',
  NonTeachingPeriodViolations = 'NonTeachingPeriodViolations',
  SubjectMoreThanOncePerDayUnavoidableViolations = 'SubjectMoreThanOncePerDayUnavoidableViolations',
  SubjectMoreThanOncePerDayViolations = 'SubjectMoreThanOncePerDayViolations',
  TimePreferenceViolations = 'TimePreferenceViolations',
}

export type DiagnosisItemClickParams = {
  affectedPeriods?: (TTPeriod | undefined)[];
  affectedSchedulingUnits?: (TTSchedulingUnit | undefined)[];
  klasse?: TTKlasse;
  room?: TTRoom;
  teacher?: TTTeacher;
  actual?: number;
  max?: number;
  min?: number;
  day?: Day;
  type: DiagnosisCategory;
  subject?: TTSubject;
  slots?: TTTimeGridSlot[];
};

export enum MinOrMax {
  min = 'min',
  max = 'max',
}

export type SubjectStudentGroupToggle = 'subject' | 'student-group';
