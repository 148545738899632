import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import classNames from 'clsx';
import { observer } from 'mobx-react-lite';
import ResizeObserver from 'resize-observer-polyfill';

import { useComponentWillUnmount } from '@/hooks/useComponentWillUnmount';
import { ITimeGridSlot } from '@te/standard/stores/grid/timetable-grid-slots-store';
import useStore from '@/hooks/useStore';
import { TimetableGridDimensionsStore } from '@te/standard/stores/grid/timetable-grid-dimensions-store';
import { TimetableTimeStore } from '@te/standard/stores/time/timetable-time-store';
import {
  TIMEGRID_SLOT_TEXT_POSITION_ABOVE,
  TimeGridSlotTextPosition,
} from '@te/standard/components/grid/slot/timetable-grid-slot-time';

import './timetable-grid-slot-name-number.less';

interface IProps {
  timeGridSlot: ITimeGridSlot;
  slotStartTextPosition: TimeGridSlotTextPosition;
  slotEndTextPosition: TimeGridSlotTextPosition;
}

interface IContentDimensions {
  offsetHeight: number;
  offsetTop: number;
}

const LargeDisplayVariantLimit = 110;
const MediumDisplayVariantLimit = 80;

export const GRID_SLOT_TEXT_DEFAULT_OFFSET = 6;
const HIDE_OFFSET = 2;

const TimetableGridSlotNameNumber = observer((props: IProps) => {
  const [contentDimensions, setContentDimensions] = useState<IContentDimensions>({ offsetHeight: 0, offsetTop: 0 });
  const ref = useRef<HTMLDivElement>(null);
  const { timeGridSlot, slotStartTextPosition, slotEndTextPosition } = props;

  const timetableGridDimensionsStore = useStore(TimetableGridDimensionsStore);
  const timetableTimeStore = useStore(TimetableTimeStore);
  const timegridSlotsStartOffset = timetableGridDimensionsStore.offsetFromStartTime(timeGridSlot.startTime);
  const timegridSlotHeight = timetableGridDimensionsStore.durationHeight(timeGridSlot.startTime, timeGridSlot.endTime);
  const { isCurrentTimeVisible } = timetableTimeStore;
  const currentTimeTop = timetableGridDimensionsStore.offsetFromStartTime(timetableTimeStore.currentTime);

  const containerTopOffset = useMemo(
    () =>
      slotStartTextPosition === 'normal'
        ? GRID_SLOT_TEXT_DEFAULT_OFFSET
        : Math.abs(TIMEGRID_SLOT_TEXT_POSITION_ABOVE) + GRID_SLOT_TEXT_DEFAULT_OFFSET,
    [slotStartTextPosition],
  );

  const containerHeightOffset = useMemo(
    () =>
      slotEndTextPosition === 'normal'
        ? containerTopOffset + GRID_SLOT_TEXT_DEFAULT_OFFSET
        : Math.abs(TIMEGRID_SLOT_TEXT_POSITION_ABOVE) + containerTopOffset + GRID_SLOT_TEXT_DEFAULT_OFFSET,
    [slotEndTextPosition, containerTopOffset],
  );

  const top = useMemo(
    () => timegridSlotsStartOffset + containerTopOffset,
    [timegridSlotsStartOffset, containerTopOffset],
  );

  const containerHeight = useMemo(
    () => timegridSlotHeight - containerHeightOffset,
    [timegridSlotHeight, containerHeightOffset],
  );

  const invisible = useMemo(() => {
    const contentTop = top + contentDimensions.offsetTop;
    const contentBottom = contentTop + contentDimensions.offsetHeight;
    return (
      isCurrentTimeVisible &&
      currentTimeTop >= contentTop - HIDE_OFFSET &&
      currentTimeTop <= contentBottom + HIDE_OFFSET
    );
  }, [top, contentDimensions, currentTimeTop, isCurrentTimeVisible]);

  const numberNameDisplayVariant = useMemo(() => {
    if (timegridSlotHeight >= LargeDisplayVariantLimit) {
      return 'l';
    } else if (timegridSlotHeight < LargeDisplayVariantLimit && timegridSlotHeight >= MediumDisplayVariantLimit) {
      return 'm';
    } else {
      return 's';
    }
    // timegridSlotHeight is used to avoid uneven display variants (due to some text is elevated while others are not)
  }, [timegridSlotHeight]);

  const [resizeObserver] = useState<ResizeObserver>(
    () =>
      new ResizeObserver(() => {
        setContentDimensions({ offsetHeight: ref.current?.offsetHeight ?? 0, offsetTop: ref.current?.offsetTop ?? 0 });
      }),
  );

  useLayoutEffect(() => {
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }
  });

  useComponentWillUnmount(() => {
    resizeObserver.disconnect();
  });

  return (
    <div className="timetable-grid-slot-number-name" style={{ top: top, height: containerHeight }}>
      <div
        ref={ref}
        className={classNames('timetable-grid-slot-number-name--content', { hide: invisible })}
        data-testid="timetable-grid-slot-number-name--content"
      >
        {numberNameDisplayVariant !== 'l' ? (
          (timeGridSlot.value || timeGridSlot.name) && (
            <TimetableGridSlotNumberNameRow
              number={timeGridSlot.value}
              name={timeGridSlot.name}
              singleRow={numberNameDisplayVariant === 's'}
            />
          )
        ) : (
          <>
            {timeGridSlot.value && <TimetableGridSlotNumber value={timeGridSlot.value} />}
            {timeGridSlot.name && <TimetableGridSlotName value={timeGridSlot.name} />}
          </>
        )}
      </div>
    </div>
  );
});

const TimetableGridSlotName = ({ value }: { value: string }) => {
  return (
    <div className="timetable-grid-slot-name" data-testid="timetable-grid-slot-name">
      {value}
    </div>
  );
};

const TimetableGridSlotNumber = ({ value }: { value: number }) => {
  return (
    <div className="timetable-grid-slot-number" data-testid="timetable-grid-slot-number">
      {value}.
    </div>
  );
};

const TimetableGridSlotNumberNameRow = ({
  number,
  name,
  singleRow,
}: {
  number: number | undefined;
  name: string | undefined;
  singleRow: boolean;
}) => {
  return (
    <div
      className={classNames('timetable-grid-slot-number-name-row', { 'single-row': singleRow })}
      data-testid="timetable-grid-slot-number-name-row"
    >
      {number && `${number}. `}
      {name}
    </div>
  );
};

export default TimetableGridSlotNameNumber;
