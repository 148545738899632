import { AbstractTimetableDataEnricher } from '@te/standard/stores/data/abstract-timetable-data-enricher';
import { Store } from '@/types/store';
import { DayEntryDto, DayEntryTypeEnum, GridEntryDto, GridEntryTypeEnum } from '@untis/wu-rest-view-api';
import { LessonCardRowContent } from '@/components/lesson-card/lesson-card';
import { IFullDayEvent, IGridEntry } from '@te/standard/stores/data/timetable-data-store';

@Store()
export class TimetableExternalEventEnricher extends AbstractTimetableDataEnricher {
  supportsDayEntry(dayEntryDto: DayEntryDto): boolean {
    return dayEntryDto.type === DayEntryTypeEnum.EXTERNAL_CALENDAR_EVENT;
  }

  supportsGridEntry(gridEntryDto: GridEntryDto): boolean {
    return gridEntryDto.type === GridEntryTypeEnum.EXTERNAL_CALENDAR_EVENT;
  }

  supportsBackEntry(): boolean {
    return false;
  }

  enrichPeriod(toEnrich: IGridEntry, gridEntryDto: GridEntryDto): void {
    const row1 = this.mapEntryResourcePairs(gridEntryDto.position1 ?? []);
    const row2 = this.mapEntryResourcePairs(gridEntryDto.position2 ?? []);
    const row3 = this.mapEntryResourcePairs(gridEntryDto.position3 ?? []);

    row1 && toEnrich.lessonCardProps.rows.push(row1);
    row2 && toEnrich.lessonCardProps.rows.push(row2);
    row3 && toEnrich.lessonCardProps.rows.push(row3);
    toEnrich.lessonCardProps.rows.push(this.mapDescription(gridEntryDto));
  }

  enrichFullDayEvent(toEnrich: IFullDayEvent, dayEntryDto: DayEntryDto): void {
    toEnrich.title = this.mapEntryResource(dayEntryDto.position1);
    toEnrich.location = this.mapEntryResource(dayEntryDto.position2);
    toEnrich.groupName = this.mapEntryResource(dayEntryDto.position3);
  }

  private mapDescription(gridEntry: GridEntryDto): LessonCardRowContent {
    return {
      type: 'text',
      hidden: this.timetableMetaStore.timetableViewType === 'week',
      content: {
        texts: [gridEntry.notesAll ?? ''],
        marginTop: true,
        previewOnly: true,
      },
    };
  }
}
