import React, { useState } from 'react';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { IUserPromptComponentProps } from '@/stores/modal-store';
import { IMultiTagSelectItem } from '@/ui-components/tag-select/multi-tag-select/multi-tag-select';
import {
  ISelectOptionListItem,
  MultiSelectOptionList,
  SingleSelectOptionList,
} from '@/ui-components/select-option-list/select-option-list';
import DeprecatedDropDown from '@/ui-components/deprecated-drop-down/drop-down';
import { DeprecatedSearchBar } from '@/ui-components';
import { MultiSelectFilter } from '@/utils/form/form-util';

interface IProps<T> extends IUserPromptComponentProps<T> {
  items: IMultiTagSelectItem[];
  selectedItems?: IMultiTagSelectItem[];
  filters?: MultiSelectFilter[];
  isSearchable?: boolean;
  isSingleSelect?: boolean;
}

export const ItemPickerPrompt = (props: IProps<IMultiTagSelectItem[]>) => {
  const [selectedOptions, setSelectedOptions] = useState<(number | string)[]>(
    props.selectedItems ? props.selectedItems.map((item) => item.id) : [],
  );
  const [searchBarValue, setSearchBarValue] = useState<string>('');
  const { t } = useTranslation();
  const [filterIdsObj, setFilterId] = useState<any>({});
  const propOptions = props.items.map((item) => {
    return {
      id: item.id,
      name: item.label,
      value: item.id,
      filterObject: item?.filterObject,
    };
  });

  const searchedOptions = (items: ISelectOptionListItem[]) => {
    if (props.isSearchable && searchBarValue.trim().length > 0) {
      return items.filter((item) => {
        return item.name.toLowerCase().includes(searchBarValue.toLowerCase());
      });
    }

    return items;
  };

  const hasNoFilterApplied = isEmpty(filterIdsObj);
  const options: ISelectOptionListItem[] = hasNoFilterApplied
    ? searchedOptions(propOptions)
    : searchedOptions(propOptions).filter((opt) => {
        let isInFilter = true;
        Object.keys(filterIdsObj).map((key) => {
          if (filterIdsObj[key] !== '-1' && filterIdsObj[key] !== opt.filterObject[key]) {
            isInFilter = false;
          }
        });
        return isInFilter;
      });

  const onChange = (checkedValue: CheckboxValueType[]) => {
    const newOptions = checkedValue.map((val) => {
      return val.toString();
    });
    props.promptProps?.setPromptValue(
      props.items.filter((item) => {
        return newOptions.includes(item.id);
      }),
    );
    setSelectedOptions(newOptions);
  };
  const onChangeSingle = (checkedValue: CheckboxValueType) => {
    const newOption = checkedValue.toString();
    props.promptProps?.setPromptValue(
      props.items.filter((item) => {
        return newOption === item.id;
      }),
    );
    setSelectedOptions([newOption]);
  };

  const filters = props.filters;
  return (
    <div className="container">
      {props.isSearchable && (
        <DeprecatedSearchBar
          value={searchBarValue}
          onChange={(value) => setSearchBarValue(value)}
          placeholder={t('general.search')}
        />
      )}
      {filters && filters.length > 0 && (
        <div className="item-picker-prompt-filters">
          {filters.map((filter) => (
            <DeprecatedDropDown
              selectedDark
              key={filter.id}
              className="item-picker-prompt-filters-item"
              placeholder={filter.name}
              canUndoSelection
              selectedKey={filterIdsObj[filter.id] !== '' ? filterIdsObj[filter.id] : undefined}
              items={filter.options}
              onSelect={(selectedVal) => setFilterId({ ...filterIdsObj, [filter.id]: selectedVal.key })}
            />
          ))}
        </div>
      )}
      {!hasNoFilterApplied && options.length === 0 && (
        <div className="item-picker-prompt-filters-no-data">{t('general.noResultsBecauseOfFilterSettings')}</div>
      )}
      {props.isSingleSelect ? (
        <SingleSelectOptionList
          name="item-picker-prompt"
          options={options}
          onChange={onChangeSingle}
          value={selectedOptions[0]}
        />
      ) : (
        <MultiSelectOptionList
          name="item-picker-prompt"
          options={options}
          onChange={onChange}
          value={selectedOptions}
        />
      )}
    </div>
  );
};

ItemPickerPrompt.defaultProps = {
  isSearchable: true,
};
