import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'clsx';
import { Dayjs } from 'dayjs';

import { ISummarisedGridEntries } from '@te/standard/stores/data/timetable-data-store';
import useStore from '@/hooks/useStore';
import { LessonIndicator } from '@/components/lesson-card/components/indicator/lesson-card-indicator';
import { TimetableUserActionsStore } from '@te/standard/stores/user-actions/timetable-user-actions-store';
import { TimetableCardDisplayStore } from '@te/standard/stores/format/timetable-card-display-store';
import {
  MINIMAL_VARIANT_WIDTH_THRESHOLD,
  useComputedLessonCardProps,
} from '@/components/lesson-card/hooks/use-computed-lesson-card-props';
import { LessonCardIndicators } from '@/components/lesson-card/components/indicator/lesson-card-indicators';
import {
  TimetableLegendFilterStore,
  TimetableLegendFilterType,
} from '@te/standard/stores/filter/timetable-legend-filter-store';
import { useLessonCardDimensions } from '@/components/lesson-card/hooks/use-lesson-card-dimensions';

import './timetable-summarised-entries.less';

type IProps = ISummarisedGridEntries & { day: Dayjs };

const TimetableSummarisedEntries = observer((props: IProps) => {
  const { top, height, leftPercentage, widthPercentage, hiddenEntries, day } = props;
  const parallelLeftOffset = leftPercentage !== 0 ? 2 : 0;

  const timetableUserActionsStore = useStore(TimetableUserActionsStore);
  const timetableCardDisplayStore = useStore(TimetableCardDisplayStore);
  const timetableLegendFilterStore = useStore(TimetableLegendFilterStore);

  const { lessonCardRef, lessonCardDimensions } = useLessonCardDimensions();

  const isMinimalWidthVariant = useMemo((): boolean => {
    return (
      (lessonCardDimensions !== undefined && lessonCardDimensions.width <= MINIMAL_VARIANT_WIDTH_THRESHOLD) ?? false
    );
  }, [lessonCardDimensions]);

  const getOrder = (types: TimetableLegendFilterType[] | undefined) => {
    // sort according to the order defined in TimetableLegendFilterOrder...
    // types can be more than one at a time (e.g. [exam, change]) so we take the most important (Math.min(...indexOf))
    return Math.min(...(types || []).map((t) => timetableLegendFilterStore.getTimetableLegendFilterOrder(t)));
  };

  const lessonCardsProps = hiddenEntries
    .map((entry) => ({
      ...entry.lessonCardProps,
      displayProps: timetableCardDisplayStore.getTimetableEntryDisplayProps(entry),
    }))
    .sort((a, b) => getOrder(a.displayProps.types) - getOrder(b.displayProps.types))
    .map((lessonCardProps) => useComputedLessonCardProps(lessonCardProps));

  const isHighlighted = lessonCardsProps.some((props) => props.updatedDisplayProps.isHighlighted);
  const hasShadow = lessonCardsProps.some((props) => !props.updatedDisplayProps.hideShadow);
  const isInPast = lessonCardsProps.filter((props) => !props.updatedDisplayProps.isPast).length === 0;

  const firstBackGround = lessonCardsProps.find((props) => !!props.updatedDisplayProps.backgroundColor);
  const backgroundColor = firstBackGround?.updatedDisplayProps.backgroundColor || 'transparent';

  const showIndicators = lessonCardsProps.some((props) => props.updatedDisplayProps.showIndicators);
  // create an array with unique indicators out of displayProps.indicators
  const indicators = lessonCardsProps.reduce((result, props) => {
    (props.updatedIndicators || []).forEach((indicator) => {
      !result.some((i) => i.type == indicator.type) && result.push(indicator);
    });
    return result;
  }, [] as LessonIndicator[]);

  let zIndex = 10;
  const startHours = props.hiddenEntries
    .map((hiddenEntry) => hiddenEntry.lessonCardProps.startDateTime.hour())
    .sort((a, b) => a - b);
  if (startHours.length > 0) {
    zIndex = zIndex + startHours[0];
  }

  return (
    <div
      ref={lessonCardRef}
      className={classNames('timetable-summarised-entries', {
        shadow: hasShadow,
        'minimal-width-variant': isMinimalWidthVariant,
      })}
      style={{
        top: top + 1,
        height: height - 1,
        left: `calc(${leftPercentage}% + ${parallelLeftOffset}px)`,
        width: `calc(${widthPercentage}% - ${parallelLeftOffset}px)`,
        backgroundColor: isHighlighted ? 'white' : undefined,
        zIndex: zIndex,
      }}
      onClick={() => timetableUserActionsStore.toggleTimetableViewType(day)}
      data-testid="timetable-summarised-entries"
    >
      <div
        className={classNames('timetable-summarised-entries--inner', {
          highlighted: isHighlighted,
          past: isInPast,
        })}
        style={{ backgroundColor }}
      >
        <span className="timetable-summarised-entries--text">+ {hiddenEntries.length}</span>
        {showIndicators && (
          <div
            className="timetable-summarised-entries--indicators"
            style={{ height: height - 13 /* 2x padding + border */ }}
          >
            <LessonCardIndicators
              indicators={indicators}
              displayVariant={isMinimalWidthVariant ? 'minimal-width' : 'default'}
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default TimetableSummarisedEntries;
