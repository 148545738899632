import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DeprecatedDropDownItem, IDeprecatedDropDownProps } from '@/ui-components/deprecated-drop-down/drop-down';

type UseDropDownParams = {
  filter: string;
  props: IDeprecatedDropDownProps;
};

function determineSelectableItems(items: DeprecatedDropDownItem[], filter: string) {
  const filterValue = filter.toLowerCase().trim();

  // if the filtervalue matches the current value it would be weird if the option
  // would not appear in the dropdown. Therefore the selected value should only be excluded from the
  // available options if no filter is set.
  const selectableItems =
    filterValue.length > 0 ? items.filter((i) => i.value.toLowerCase().includes(filterValue)) : items;

  return selectableItems.sort((a, b) => {
    const indexA: number = a.value.toLowerCase().indexOf(filterValue);
    const indexB: number = b.value.toLowerCase().indexOf(filterValue);
    if (indexA === indexB) {
      return 0;
    }
    return indexA - indexB;
  });
}

export function useDropDown({
  filter,
  props: { multiple, placeholder, items, selectedKey, shorten, translation },
}: UseDropDownParams) {
  const { t } = useTranslation();

  const placeholderItems: DeprecatedDropDownItem[] = useMemo(
    () => [
      {
        key: '-1',
        value: placeholder ? placeholder : t('general.pleaseSelect'),
      },
    ],
    [placeholder, t],
  );
  const propItems = useMemo(() => items, [items]);
  const selectableItems = useMemo(() => {
    if (!multiple) {
      return [...determineSelectableItems(items, filter)];
    }

    return determineSelectableItems(items, filter);
  }, [multiple, items, filter]);

  const isFiltered = filter.length > 0;
  const isFilterResultEmpty = isFiltered && selectableItems.length === 0;

  const isAnItemSelected = useMemo(() => {
    if (multiple) {
      return !!items.find((i) => selectedKey?.includes(i.key));
    }

    return !!propItems.find((i) => i.key === selectedKey && selectedKey !== placeholderItems[0].key);
  }, [items, multiple, placeholderItems, propItems, selectedKey]);

  const selectedItems = useMemo(() => {
    if (isAnItemSelected) {
      return items.filter((i) => (multiple ? selectedKey?.includes(i.key) : selectedKey === i.key));
    }
    return [...placeholderItems];
  }, [isAnItemSelected, placeholderItems, items, selectedKey, multiple]);

  const selectedItemsValues = useMemo(() => selectedItems.map((i) => (i.alias ? i.alias : i.value)), [selectedItems]);
  const selectedItemsKeys = useMemo(() => selectedItems.map((i) => i.key), [selectedItems]);
  const selectedKeysString = useMemo(() => selectedItemsKeys.join(' | '), [selectedItemsKeys]);
  const selectedValuesString = useMemo(() => {
    if (!multiple) {
      return selectedItemsValues[0];
    }

    if (multiple && translation && shorten && selectedItemsValues.length > 1) {
      return (
        <>
          {selectedItemsValues[0]} {`+ ${selectedItemsValues.length - 1} ${translation?.shorten}`}
        </>
      );
    }

    return selectedItemsValues.reduce((endValue, value, index) => {
      if (index === selectedItemsValues.length - 1) {
        return (
          <>
            {endValue}
            {value}
          </>
        );
      }

      return (
        <>
          {value}
          <span className="separator" />
          {endValue}
        </>
      );
    }, <></>);
  }, [multiple, selectedItemsValues, shorten, translation]);

  const inputPlaceholder = useMemo(() => {
    if (!multiple) {
      return selectedItemsValues[0];
    }

    if (multiple && shorten && translation) {
      if (selectedItemsValues.length > 1) {
        return `${selectedItemsValues[0]} + ${selectedItemsValues.length - 1} ${translation.shorten}`;
      }

      return selectedItemsValues[0];
    } else {
      return selectedItemsValues.join(' | ');
    }
  }, [multiple, selectedItemsValues, shorten, translation]);

  return {
    selectableItems,
    isFilterResultEmpty,
    placeholderItems,
    propItems,
    isAnItemSelected,
    selectedItemsValues,
    selectedKeysString,
    selectedValuesString,
    inputPlaceholder,
  };
}
