import React, { useMemo, useState } from 'react';
import classNames from 'clsx';

import {
  DEFAULT_DROPDOWN_ITEM_WIDTH,
  DROPDOWN_ABSOLUTE_MAX_WIDTH,
  ITimetableSelectorProps,
  WIDTH_CALCULATION_MARGIN_OF_ERROR,
  WIDTH_CALCULATION_PADDING,
  WIDTH_CALCULATION_TOOLTIP_ICON,
} from '@te/standard/components/header/entity-selector/timetable-entity-selector';
import { MasterDataRefDto } from '@untis/wu-rest-view-api/api';
import { IDropDownItem, SingleDropDown } from '@/ui-components/drop-down/drop-down';
import { TestIds } from '@/testIds';
import { FilterChip } from '@/ui-components/filter-chip/filter-chip';
import { measureTextWidth } from '@te/standard/utils/measure-text-width';

import './timetable-entity-filter-selector.less';

const className = 'timetable-entity-filter-selector';

/**
 * Unique names for the filter dropdowns
 */
type FilterType = 'ROOM_GROUP' | 'BUILDING' | 'DEPARTMENT' | 'CLASS' | 'RESOURCE_TYPE' | 'ASSIGNMENT_GROUP';

/**
 * Used to make the re-population of the dropdown async bc
 * otherwise the onClick eventHandlers are not triggered
 */
const REPOPULATION_TIMEOUT = 300;

interface IProps<T> extends ITimetableSelectorProps {
  /**
   * Selectable options for the dropdown
   */
  options: T[];

  /**
   * Filter definition, @see below
   */
  filters: IFilter<T>[];

  /**
   * utility to map a select option of type T to a MasterDataRefDto (id, shortName are needed)
   * @param option of type T
   */
  toMasterData: (option: T) => MasterDataRefDto;

  /**
   * optional tooltip mapper to display per item
   * @param option of type T
   */
  getTooltip?: (option: T) => string;
}

export interface IFilter<T> {
  /**
   * unique name of the filter in this context (= dropdown)
   */
  name: FilterType;

  /**
   * string to use as placeholder for the dropdown select
   */
  placeholder: string;

  /**
   * options to populate the filter dropdown with
   */
  filterOptions: MasterDataRefDto[];

  /**
   * function that filters the options based on the selected filterOption
   */
  filterBy: (option: T, filterId?: number) => boolean;
}

interface IState {
  open: boolean;
  activeFilter?: FilterType;
  selectedFilters: { [key in FilterType]?: number };
}

interface IFilterProps {
  name: FilterType;
  text?: string;
  placeholder?: string;
  selectedId?: number;
  onCancel: () => void;
  onDropdownVisibleChange: (open: boolean) => void;
}

const Filter = ({ name, text, placeholder, selectedId, onCancel, onDropdownVisibleChange }: IFilterProps) => {
  const testId = `${TestIds.TIMETABLE_ITEM_SELECTOR}__filter-${name}`;

  return selectedId !== undefined ? (
    <FilterChip dataTestId={testId} text={text ?? ''} onCancel={onCancel} />
  ) : (
    <SingleDropDown
      testId={testId}
      placeholder={placeholder}
      items={[]}
      onDropdownVisibleChange={onDropdownVisibleChange}
      dropdownClassName={className + '__filter-drop-down'}
    />
  );
};

const TimetableEntityFilterSelector = <T,>(props: IProps<T>) => {
  const { options, filters, toMasterData, getTooltip, selectedValue, onValueSelected } = props;

  const [state, setState] = useState<IState>({
    open: false,
    selectedFilters: {},
  });

  const shownFilters = props.filters.filter((filter) => filter.filterOptions.length > 0);

  let hideCurrentlySelected;

  /**
   * Calculates the options to populate the dropdown with that are:
   *
   * - any filter is active (dropdown open) -> filterOptions of this filter
   * - no filter is active -> options
   */
  const getOptions = (): IDropDownItem[] => {
    if (state.activeFilter !== undefined) {
      // filter options
      const filter = filters.find((filter) => filter.name == state.activeFilter);
      return filter?.filterOptions.map((filterOption) => toDropDownItem(filterOption)) ?? [];
    }
    // select options
    let selectOptions = [...options];
    const selectedFilters = Object.keys(state.selectedFilters) as FilterType[];

    for (const filterType of selectedFilters) {
      const filter = filters.find((filter) => filter.name == filterType);
      const filterId = state.selectedFilters[filterType];

      selectOptions = selectOptions.filter((option) => !filterId || !filter || filter.filterBy(option, filterId));
    }
    // add currently selected option if it is filtered out to show the right value in the input
    // (if the option is not available, selects optionLabelProp will just return the id of the selected item),
    // but hide it in the dropdown (this can be done better in ant v5.x using optionRender)
    if (!selectOptions.find((option) => toMasterData(option).id == selectedValue)) {
      hideCurrentlySelected = true;

      const selectedOption = options.find((option) => toMasterData(option).id == selectedValue);

      !!selectedOption && selectOptions.push(selectedOption);
    }
    return selectOptions.map((option) =>
      toDropDownItem(toMasterData(option), getTooltip ? getTooltip(option) : undefined),
    );
  };

  const toDropDownItem = (masterData: MasterDataRefDto, tooltipText?: string): IDropDownItem => ({
    id: masterData.id?.toString(),
    label: masterData.displayName ?? '',
    tooltip: tooltipText,
  });

  const dropdownWidth = useMemo(() => {
    const labelWidthsDesc = options
      .map(
        (option) =>
          measureTextWidth(toMasterData(option).displayName ?? '', {
            font: 'AvenirNextLTPro',
            fontWeight: 600,
            fontSize: 14,
          }) ?? DEFAULT_DROPDOWN_ITEM_WIDTH,
      )
      .sort((a, b) => b - a);
    const maxLabelWidth = labelWidthsDesc[0] ?? DEFAULT_DROPDOWN_ITEM_WIDTH;
    const calculatedDropdownWidth =
      maxLabelWidth +
      WIDTH_CALCULATION_MARGIN_OF_ERROR +
      2 * WIDTH_CALCULATION_PADDING +
      (getTooltip !== undefined ? WIDTH_CALCULATION_TOOLTIP_ICON : 0);
    return Math.min(calculatedDropdownWidth, DROPDOWN_ABSOLUTE_MAX_WIDTH);
  }, [options, getTooltip]);

  const findItem = (masterData: MasterDataRefDto[], id: number | undefined): MasterDataRefDto | undefined =>
    masterData.find((item) => item.id == id);

  /**
   * Currently selected value is
   *
   * - any filter is active (dropdown open) -> selected filter value of that filterType
   * - no filter is active -> selected option
   */
  const value =
    state.activeFilter !== undefined
      ? state.selectedFilters[state.activeFilter]?.toString()
      : selectedValue?.toString();

  /**
   * Manually set placeholder in case a filter is active bc
   * then no value is selected and the default "Please select..." is shown
   */
  const placeholder = options.map(toMasterData).find((option) => option.id == selectedValue)?.shortName ?? '';

  /**
   * Sets the selected object value where
   *
   * - any filter is active (dropdown open) -> selectedFilters is updated
   * - no filter is active -> onValueSelected callback is called with selected option value
   *
   * @param value either id of the selected filterOption or option
   */
  const handleOnChange = (value: string | undefined) => {
    if (state.activeFilter !== undefined) {
      // filter
      setState((state) => ({
        ...state,
        selectedFilters: { ...state.selectedFilters, [state.activeFilter as FilterType]: Number(value) },
        activeFilter: undefined,
      }));
      return;
    }
    // select
    setState((state) => ({ ...state, open: false }));
    onValueSelected(Number(value));
  };

  /**
   * Toggles the main dropdown by setting state open
   */
  const toggleDropDown = (open: boolean) => !state.activeFilter && setState((state) => ({ ...state, open: open }));

  /**
   * (Open Filter Dropdown): Sets the active filter in state depending on the dropdown with the given
   * @param filterType that is toggled
   */
  const toggleFilter = (filterType: FilterType) => (open: boolean) => {
    const activeFilter = open ? filterType : undefined;
    // async bc otherwise the onClick eventHandlers are not triggered bc menu is re-populated too quickly
    setTimeout(
      () =>
        setState((state) => {
          if (!open && state.activeFilter !== filterType) {
            return state; // prevent other filter from closing the currently active one
          }
          return { ...state, activeFilter: activeFilter };
        }),
      REPOPULATION_TIMEOUT,
    );
  };

  /**
   * (Click Cancel on FilterChip): Deletes the active filter with the given
   * @param filterType from state
   */
  const onCancel = (filterType: FilterType) => () => {
    setState((state) => ({
      ...state,
      selectedFilters: { ...state.selectedFilters, [filterType]: undefined },
      activeFilter: undefined,
    }));
  };

  return (
    <SingleDropDown
      className={className}
      testId={TestIds.TIMETABLE_ITEM_SELECTOR}
      items={getOptions()}
      value={value}
      placeholder={placeholder}
      onChange={handleOnChange}
      onDropdownVisibleChange={toggleDropDown}
      open={state.open}
      showArrowControls
      searchable
      hideIcons
      minWidth={dropdownWidth}
      maxWidth={dropdownWidth}
      listHeight={345}
      dropdownClassName={classNames(className + '__drop-down', { 'hide-selected': hideCurrentlySelected })}
      dropdownRender={(menu) => (
        <>
          {shownFilters.length > 0 && (
            <div className={className + '__filter-bar'}>
              {shownFilters.map(({ name, placeholder, filterOptions }) => (
                <Filter
                  key={name}
                  name={name}
                  placeholder={placeholder}
                  text={findItem(filterOptions, state.selectedFilters[name])?.shortName ?? ''}
                  selectedId={state.selectedFilters[name]}
                  onCancel={onCancel(name)}
                  onDropdownVisibleChange={toggleFilter(name)}
                />
              ))}
            </div>
          )}
          {menu}
        </>
      )}
    />
  );
};

export default TimetableEntityFilterSelector;
