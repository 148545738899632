import { inject, Store } from '@/types/store';
import { ILessonCardDisplayProps, LessonCardType } from '@/components/lesson-card/lesson-card';
import { TimetableEntry } from '@te/standard/stores/url/abstract-timetable-details-modal-handler';
import { IFullDayEvent, IGridEntry } from '@te/standard/stores/data/timetable-data-store';
import { TimetableTimeStore } from '@te/standard/stores/time/timetable-time-store';
import { TimetableMetaStore } from '@te/standard/stores/meta/timetable-meta-store';
import { TimetableFormatStore } from '@te/standard/stores/format/timetable-format-store';
import { DayEntryTypeEnum } from '@untis/wu-rest-view-api/api';
import {
  TimetableLegendFilterStore,
  TimetableLegendFilterType,
  TimetableSettingEnum,
} from '@te/standard/stores/filter/timetable-legend-filter-store';

export type TimetableEntryDisplayProps = ILessonCardDisplayProps & { types?: TimetableLegendFilterType[] };

const CANCELLATION_COLOR = '#ff404023';
const CHANGE_COLOR = '#dff6eb';
const EXAM_COLOR = '#ffd61528';
const EXTERNAL_COLOR = '#5180F928';

@Store()
export class TimetableCardDisplayStore {
  private timetableMetaStore = inject(TimetableMetaStore);
  private timetableFormatStore = inject(TimetableFormatStore);
  private timetableLegendFilterStore = inject(TimetableLegendFilterStore);
  private timetableTimeStore = inject(TimetableTimeStore);

  getTimetableEntryDisplayProps(timetableEntry: TimetableEntry): TimetableEntryDisplayProps {
    const timetableEntryLegendFilterTypes = this.getTimetableEntryLegendFilterTypes(timetableEntry);
    const isHighlighted =
      this.timetableLegendFilterStore.hasSelectedLegendFilters &&
      this.isCardHighlighted(timetableEntryLegendFilterTypes);
    const isPast = this.isTimetableEntryInThePast(timetableEntry);

    return {
      types: timetableEntryLegendFilterTypes,
      isHighlighted: isHighlighted,
      backgroundColor: isHighlighted ? this.getHighlightedColor(timetableEntryLegendFilterTypes) : undefined,
      isPast: this.isTimetableEntryInThePast(timetableEntry),
      hideShadow: !this.timetableEntryHasShadow(timetableEntry, isHighlighted, isPast),
      displayVariant: this.timetableMetaStore.timetableViewType === 'week' ? 'default' : 'long',
      showStartEndTime: this.timetableEntryShowStartEndTime(timetableEntry),
      showIndicators: this.timetableLegendFilterStore.enabledTimetableSettings.includes(
        TimetableSettingEnum.SHOW_SYMBOLS,
      ),
      showSubstitutions: isHighlighted && this.timetableLegendFilterStore.selectedLegendFilters.includes('change'),
      showSingleRow:
        this.isGridEntry(timetableEntry) &&
        [LessonCardType.BREAK_SUPERVISION, LessonCardType.STAND_BY_PERIOD].includes(
          (timetableEntry as IGridEntry).lessonCardProps.type,
        ),
    };
  }

  private getTimetableEntryLegendFilterTypes(timetableEntry: TimetableEntry): TimetableLegendFilterType[] {
    if (this.isGridEntry(timetableEntry)) {
      return this.getGridEntryLegendFilterTypes(timetableEntry as IGridEntry);
    } else {
      return this.getFullDayEventLegendFilterTypes(timetableEntry as IFullDayEvent);
    }
  }

  private isTimetableEntryInThePast(timetableEntry: TimetableEntry): boolean {
    if (this.isGridEntry(timetableEntry)) {
      const gridEntry = timetableEntry as IGridEntry;
      return gridEntry.lessonCardProps.endDateTime.isBefore(this.timetableTimeStore.currentTime, 'minutes');
    } else {
      const fullDayEvent = timetableEntry as IFullDayEvent;
      return fullDayEvent.startDateTime.clone().endOf('day').isBefore(this.timetableTimeStore.currentTime, 'minutes');
    }
  }

  private getGridEntryLegendFilterTypes(gridEntry: IGridEntry): TimetableLegendFilterType[] {
    if (gridEntry.isCancelled) {
      return ['cancellation'];
    } else {
      const cardLegendFilterTypes: TimetableLegendFilterType[] = [];
      gridEntry.hasChange && cardLegendFilterTypes.push('change');
      gridEntry.isExam && cardLegendFilterTypes.push('exam');
      gridEntry.isExternal && cardLegendFilterTypes.push('external');
      return cardLegendFilterTypes;
    }
  }

  private getFullDayEventLegendFilterTypes(fullDayEvent: IFullDayEvent): TimetableLegendFilterType[] {
    const cardLegendFilterTypes: TimetableLegendFilterType[] = [];
    fullDayEvent.type === DayEntryTypeEnum.EXTERNAL_CALENDAR_EVENT && cardLegendFilterTypes.push('external');
    return cardLegendFilterTypes;
  }

  private isCardHighlighted(cardLegendFilterTypes: TimetableLegendFilterType[]): boolean {
    return (
      this.timetableLegendFilterStore.selectedLegendFilters.filter((legendFilter) =>
        cardLegendFilterTypes.includes(legendFilter),
      ).length > 0
    );
  }

  private getHighlightedColor(cardLegendFilterTypes: TimetableLegendFilterType[]): string | undefined {
    if (cardLegendFilterTypes.includes('cancellation')) {
      return CANCELLATION_COLOR;
    }

    if (cardLegendFilterTypes.includes('exam') && cardLegendFilterTypes.includes('change')) {
      if (this.timetableLegendFilterStore.selectedLegendFilters.includes('exam')) {
        return EXAM_COLOR;
      } else {
        return CHANGE_COLOR;
      }
    }

    if (cardLegendFilterTypes.includes('exam')) {
      return EXAM_COLOR;
    }

    if (cardLegendFilterTypes.includes('change')) {
      return CHANGE_COLOR;
    }

    if (cardLegendFilterTypes.includes('external')) {
      return EXTERNAL_COLOR;
    }

    return undefined;
  }

  private timetableEntryHasShadow(timetableEntry: TimetableEntry, isHighlighted: boolean, isPast: boolean): boolean {
    if (this.isGridEntry(timetableEntry)) {
      const gridEntry = timetableEntry as IGridEntry;
      if (gridEntry.lessonCardProps.type === LessonCardType.STAND_BY_PERIOD) {
        return false;
      } else {
        return this.timetableLegendFilterStore.hasSelectedLegendFilters
          ? isHighlighted
          : !isPast && !gridEntry.isCancelled;
      }
    } else {
      return this.timetableLegendFilterStore.hasSelectedLegendFilters ? isHighlighted : !isPast;
    }
  }

  private timetableEntryShowStartEndTime(timetableEntry: TimetableEntry): boolean {
    const showStartEndTime = this.timetableFormatStore.timetableFormat?.showStartEndTime ?? false;
    if (this.isGridEntry(timetableEntry)) {
      return (timetableEntry as IGridEntry).isParallelToLeft ? false : showStartEndTime;
    } else {
      return showStartEndTime;
    }
  }

  private isGridEntry(entry: TimetableEntry): boolean {
    return 'periodIds' in entry;
  }
}
