import React, { ReactNode } from 'react';
import { Row } from 'antd';
import './wu-form-row.less';
import clsx from 'clsx';

interface IProps {
  children?: ReactNode;
  noMarginBottom?: boolean;
}

export const WUFormRow = (props: IProps) => {
  const className = clsx('wu-form-row', {
    'no-margin-bottom': props.noMarginBottom,
  });
  return (
    <Row className={className} gutter={24}>
      {props.children}
    </Row>
  );
};
