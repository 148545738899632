import { useLayoutEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { useComponentWillUnmount } from '@/hooks/useComponentWillUnmount';

export interface ILessonCardDimensions {
  width: number;
  height: number;
}

export function useLessonCardDimensions() {
  const lessonCardRef = useRef<HTMLDivElement>(null);
  const [lessonCardDimensions, setLessonCardDimensions] = useState<ILessonCardDimensions | undefined>(undefined);

  const [resizeObserver] = useState<ResizeObserver>(
    () =>
      new ResizeObserver(() => {
        if (lessonCardRef.current) {
          setLessonCardDimensions({
            width: lessonCardRef.current.getBoundingClientRect().width,
            height: lessonCardRef.current.getBoundingClientRect().height,
          });
        }
      }),
  );

  useLayoutEffect(() => {
    if (lessonCardRef.current) {
      resizeObserver.observe(lessonCardRef.current);
    }
  });

  useComponentWillUnmount(() => {
    resizeObserver.disconnect();
  });

  return {
    lessonCardRef,
    lessonCardDimensions,
  };
}
