import { action, computed, observable } from 'mobx';

import { Store } from '@/types/store';
import { TimetableIntegrationsDto } from '@untis/wu-rest-view-api/api';

@Store()
export class TimetableIntegrationStore {
  @observable private _externalCalendarIntegrations: TimetableIntegrationsDto | undefined;

  @action
  reset() {
    this._externalCalendarIntegrations = undefined;
  }

  @action
  setExternalCalendarIntegrations(externalCalendarIntegrations: TimetableIntegrationsDto) {
    this._externalCalendarIntegrations = externalCalendarIntegrations;
  }

  @computed
  get availableExternalCalendarIntegrations(): string[] {
    return this._externalCalendarIntegrations?.integrations?.map((integration) => integration.name) ?? [];
  }

  @computed
  get externalCalendarIntegrations(): TimetableIntegrationsDto | undefined {
    return this._externalCalendarIntegrations;
  }

  @action
  toggleExternalIntegration(externalIntegration: string) {
    this._externalCalendarIntegrations?.integrations?.forEach((integration) => {
      if (integration.name === externalIntegration) {
        integration.active = !integration.active;
      }
    });
  }

  @computed
  get enabledExternalIntegrations(): string[] {
    return (
      this._externalCalendarIntegrations?.integrations
        ?.filter((integration) => integration.active)
        .map((integration) => integration.name) ?? []
    );
  }
}
