import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IReasonsOfAbsenceRows, ReasonsOfAbsenceStore } from './reasons-of-absence-store';

import { WUForm } from '@/ui-components';
import { WUFormCol } from '@/ui-components/wu-form/wu-form-col/wu-form-col';
import { FormInput } from '@/ui-components/wu-form/form-input/form-input';
import { FormCheckbox } from '@/ui-components/wu-form/form-checkbox/form-checkbox';
import { IFormButton } from '@/ui-components/wu-form/wu-form';
import { WUFormRow } from '@/ui-components/wu-form/wu-form-row/wu-form-row';
import useStore from '@/hooks/useStore';
import ModalStore from '@/stores/modal-store';
import { validateForm } from '@/utils/form/form-util';
import NotificationStore from '@/stores/notification-store/notification-store';
import { FormSection } from '@/ui-components/wu-form/form-section/form-section';
import { AbsenceReasonPrivilegeEnum } from '@untis/wu-rest-view-api/api';
import FormTaggedSwitch from '@/ui-components/wu-form/form-tagged-switch/form-tagged-switch';
import { FormSingleSelect } from '@/ui-components/wu-form/form-single-select/form-single-select';
import { FormTextarea } from '@/ui-components/wu-form';
import { defaultSorting } from '@/utils/sorting/sorting-util';
export interface IReasonsOfAbsenceFormData {
  name: string;
  longName: string;
  active: boolean;
  absenceCounts: boolean;
  autoExcuseStatusId: string | undefined;
  notify: boolean;
  privilege: AbsenceReasonPrivilegeEnum;
  key: number;
  notificationEnabled: boolean;
  notificationConfirmRequired: boolean;
  notificationReplyForbidden: boolean;
  notificationMessage: string;
  notificationSenderId: number;
}

interface IProps {
  store: ReasonsOfAbsenceStore;
  reason?: IReasonsOfAbsenceRows;
}

export const ReasonsOfAbsenceForm = observer((props: IProps) => {
  const store = props.store;
  const { t } = useTranslation();
  const modalStore = useStore(ModalStore);
  const isInEditMode = !!props.reason;
  const notificationStore = useStore(NotificationStore);
  const disabled = isInEditMode && !store.canEdit;
  const [showAbsenceHint, setShowAbsenceHint] = useState(
    store.isSubjectTeacherExcuseActive && props?.reason?.autoExcuseStatusId !== -1,
  );
  const [showNotificationSection, setShowNotificationSection] = useState(!!props.reason?.notificationEnabled);

  const leftButtons: IFormButton[] =
    isInEditMode && store.canDelete
      ? [
          {
            label: t('general.delete'),
            type: 'destructive',
            onClick: () => {
              if (props.reason && props.reason?.id) {
                store.deleteReasonOfAbsence(props.reason.id);
              }
            },
          },
        ]
      : [];

  const rightButtons: IFormButton[] = [];
  if (!isInEditMode) {
    rightButtons.push({
      label: t('general.saveAndNew'),
      type: 'primary',
      outline: true,
      disabled: store.submitAllDisabled,
      onClick: () => {
        validateForm(store.form).then((isValid) => {
          if (isValid) {
            handleSaveNewReasonOfAbsence(store.form.getFieldsValue(), true);
          }
        });
      },
    });
  }
  rightButtons.push({
    label: disabled ? t('general.ok') : t('general.cancel'),
    onClick: () => {
      modalStore.closeModal();
    },
  });

  const handleSaveNewReasonOfAbsence = (value: IReasonsOfAbsenceFormData, saveAndNew: boolean) => {
    store
      .createReasonOfAbsence(value)
      .then(() => {
        notificationStore.success({ title: t('general.reasonOfAbsenceCreated') });
        resetForm();
        if (!saveAndNew) {
          modalStore.closeModal();
        }
      })
      .catch((error) => {
        notificationStore.error({
          title: t('general.reasonOfAbsenceCouldNotBeCreated'),
          message: t('menu.menuItems.' + error.response.data.validationErrors[0].errorMessage),
        });
      });
  };

  const handleChangeReasonOfAbsence = (value: IReasonsOfAbsenceFormData) => {
    store
      .updateReasonOfAbsence(value, props.reason!)
      .then(() => {
        notificationStore.success({ title: t('general.absenceReasonUpdated') });
        resetForm();
        modalStore.closeModal();
      })
      .catch(() => {
        notificationStore.error({ title: t('general.reasonOfAbsenceCouldNotBeEdited') });
      });
  };

  const initialExcuseStatusId: string | undefined =
    props.reason && props.reason.autoExcuseStatusId !== -1 ? props.reason.autoExcuseStatusId.toString() : undefined;

  const resetForm = () => {
    setShowNotificationSection(false);
    store.form.resetFields();
  };

  return (
    <WUForm<IReasonsOfAbsenceFormData>
      leftButtons={leftButtons}
      rightButtons={rightButtons}
      fixedButtonBar={true}
      form={store.form}
      onSubmit={
        isInEditMode
          ? (value) => handleChangeReasonOfAbsence(value)
          : (value) => handleSaveNewReasonOfAbsence(value, false)
      }
      maxWidth
      hideSubmitButton={disabled}
      onDisableChange={(disabled) => store.setSubmitAllDisabled(disabled)}
      onValuesChange={(changedValues, values) => {
        setShowAbsenceHint(store.isSubjectTeacherExcuseActive && values.autoExcuseStatusId !== undefined);
      }}
      paddingTop
    >
      <WUFormCol lg={1}>
        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={t('general.status')} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormTaggedSwitch
              name="active"
              initialValue={!!props.reason ? props.reason.active : true}
              onLabel={t('general.active')}
              offLabel={t('general.inactive')}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection text={`${t('general.name')}`} description={t('general.asteriskRequired')} />
          </WUFormCol>
          <WUFormCol lg={4}>
            <FormInput
              name="name"
              initialValue={props?.reason?.name ? props.reason.name : ''}
              rules={[
                { required: true, message: t('general.inputRequired') },
                { max: 20, message: t('general.valueMustBeWithinRange', { min: 1, max: 20 }) },
              ]}
              disabled={disabled}
              variant="floating"
              label={`${t('general.name')}*`}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormInput
              name="longName"
              initialValue={!!props.reason?.longName ? props.reason.longName : ''}
              rules={[{ max: 100, message: t('general.valueMustBeWithinRange', { min: 0, max: 100 }) }]}
              disabled={disabled}
              variant="floating"
              label={`${t('general.longName')}`}
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection
              text={`${t('general.absence')}`}
              description={`${t('general.absenceAutoExcused')}${
                showAbsenceHint ? `\n${t('general.automaticExcuseStatusAffectsSubjectTeacher')}` : ''
              }`}
              centered
            />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormSingleSelect
              items={store?.excuseStatuses?.map((excuseStatus) => {
                return {
                  id: excuseStatus.key,
                  label: excuseStatus.value || '',
                };
              })}
              name="autoExcuseStatusId"
              initialValue={initialExcuseStatusId}
              disabled={disabled}
              placeholder={`${t('general.status')}`}
            />
          </WUFormCol>
        </WUFormRow>

        {store.showNotifyCheckbox && (
          <WUFormRow>
            <WUFormCol lg={2}>
              <FormSection text={t('general.notification')} centered />
            </WUFormCol>
            <WUFormCol lg={2}>
              <FormCheckbox
                name="notify"
                initialValue={props.reason ? props.reason.notify : false}
                text={t('general.notification')}
                disabled={disabled}
              />
            </WUFormCol>
          </WUFormRow>
        )}
        <WUFormRow>
          <WUFormCol lg={2} />
          <WUFormCol lg={2}>
            <FormSingleSelect
              items={[
                { id: AbsenceReasonPrivilegeEnum.LOW.toString(), label: t('general.low') },
                { id: AbsenceReasonPrivilegeEnum.NORMAL.toString(), label: t('general.normal') },
                { id: AbsenceReasonPrivilegeEnum.HIGH.toString(), label: t('general.high') },
              ]}
              name="privilege"
              initialValue={
                props.reason && props?.reason.privilege
                  ? AbsenceReasonPrivilegeEnum[props.reason.privilege]
                  : AbsenceReasonPrivilegeEnum.NORMAL
              }
              disabled={disabled}
              placeholder={`${t('general.privilege')}`}
              allowClear={false}
              styleVariant="floating"
            />
          </WUFormCol>
        </WUFormRow>

        <WUFormRow>
          <WUFormCol lg={2}>
            <FormSection description={`${t('general.absenceCountsDescription')}`} centered />
          </WUFormCol>
          <WUFormCol lg={2}>
            <FormCheckbox
              name="absenceCounts"
              initialValue={props.reason?.absenceCounts ? props.reason.absenceCounts : false}
              text={`${t('general.absenceCounts')}`}
              disabled={disabled}
            />
          </WUFormCol>
        </WUFormRow>
        {store.autoNotificationFeatureActive && (
          <>
            <WUFormRow>
              <WUFormCol lg={2}>
                <FormSection
                  text={`${t('general.automatedNotification')}`}
                  description={`${t('general.automatedNotificationInfo')}`}
                  centered
                />
              </WUFormCol>
              <WUFormCol lg={2}>
                <FormCheckbox
                  name="notificationEnabled"
                  initialValue={props.reason ? props.reason.notificationEnabled : false}
                  text={t('general.automatedNotification')}
                  disabled={disabled}
                  onChange={(checked) => setShowNotificationSection(checked)}
                />
              </WUFormCol>
            </WUFormRow>
            {showNotificationSection && (
              <>
                <WUFormRow>
                  <WUFormCol lg={2}>
                    <FormSection
                      description={`${t('general.senderOfMessage')}\n${t('general.asteriskRequired')}`}
                      centered
                    />
                  </WUFormCol>
                  <WUFormCol lg={2}>
                    <FormSingleSelect
                      items={store.notificationSenderOptions}
                      name="notificationSenderId"
                      initialValue={props.reason ? '' + props.reason.notificationSenderId : '-1'}
                      disabled={disabled}
                      placeholder={`${t('general.sender')}*`}
                      allowClear={false}
                      required={true}
                      searchable
                      customSorting={(a, b) =>
                        a.id === '-1' ? -1 : b.id === '-1' ? 1 : defaultSorting(`${a.label}`, `${b.label}`)
                      }
                    />
                  </WUFormCol>
                </WUFormRow>
                <WUFormRow>
                  <WUFormCol lg={2}>
                    <FormSection
                      description={`${t('general.messageText')}\n${t('general.asteriskRequired')}`}
                      centered
                    />
                  </WUFormCol>
                  <WUFormCol lg={2}>
                    <FormTextarea
                      name="notificationMessage"
                      disabled={disabled}
                      floatingLabel={`${t('general.message')}*`}
                      maxLength={500}
                      rules={[
                        { required: true, message: t('general.inputRequired') },
                        { max: 500, message: t('general.valueMustBeWithinRange', { min: 1, max: 500 }) },
                      ]}
                      initialValue={props.reason?.notificationMessage || store.notificationDefaultText}
                    />
                  </WUFormCol>
                </WUFormRow>
                <WUFormRow>
                  <WUFormCol lg={2}></WUFormCol>
                  <WUFormCol lg={2}>
                    <FormCheckbox
                      name="notificationConfirmRequired"
                      initialValue={props.reason ? props.reason.notificationConfirmRequired : false}
                      text={t('general.requestReadConfirmation')}
                      disabled={disabled}
                    />
                  </WUFormCol>
                </WUFormRow>
                <WUFormRow>
                  <WUFormCol lg={2}></WUFormCol>
                  <WUFormCol lg={2}>
                    <FormCheckbox
                      name="notificationReplyForbidden"
                      initialValue={props.reason ? props.reason.notificationReplyForbidden : false}
                      text={t('general.forbidReply')}
                      disabled={disabled}
                    />
                  </WUFormCol>
                </WUFormRow>
              </>
            )}
          </>
        )}
      </WUFormCol>
    </WUForm>
  );
});
