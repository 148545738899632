import {
  GridEntryDto,
  GridEntryStatusDetailEnum,
  GridEntryStatusEnum,
  GridEntryTypeEnum,
} from '@untis/wu-rest-view-api';
import { Store } from '@/types/store';
import { LessonCardStatus, LessonCardType } from '@/components/lesson-card/lesson-card';
import { CalendarDtoStatusEnum, CalendarDtoTypeEnum } from '@untis/wu-rest-view-api/api';

@Store()
export class CommonTimetableDataMapper {
  mapPeriodStatus(periodDto: GridEntryDto): LessonCardStatus {
    let result = LessonCardStatus.REGULAR;

    const { type, status, statusDetail } = periodDto;
    const isMovedAway = status === GridEntryStatusEnum.CANCELLED && statusDetail === GridEntryStatusDetailEnum.MOVED;
    const isMovedHere = status === GridEntryStatusEnum.ADDITIONAL && statusDetail === GridEntryStatusDetailEnum.MOVED;
    const isAdditional =
      status === GridEntryStatusEnum.ADDITIONAL ||
      type === GridEntryTypeEnum.EVENT ||
      type === GridEntryTypeEnum.ADDITIONAL_PERIOD;

    if (isMovedAway) {
      result = LessonCardStatus.MOVED_AWAY;
    } else if (status === GridEntryStatusEnum.CANCELLED) {
      result = LessonCardStatus.CANCELLED;
    } else if (isMovedHere) {
      result = LessonCardStatus.MOVED_HERE;
    } else if (status === GridEntryStatusEnum.CHANGED) {
      result = LessonCardStatus.CHANGED;
    } else if (isAdditional) {
      result = LessonCardStatus.ADDITIONAL;
    }
    return result;
  }

  mapCalendarDtoStatusEnum(calendarStatus: CalendarDtoStatusEnum): LessonCardStatus {
    switch (calendarStatus) {
      case CalendarDtoStatusEnum.TAKING_PLACE:
        return LessonCardStatus.REGULAR;
      case CalendarDtoStatusEnum.CANCELLED:
        return LessonCardStatus.CANCELLED;
      case CalendarDtoStatusEnum.SUBSTITUTION:
        return LessonCardStatus.CHANGED;
      case CalendarDtoStatusEnum.MOVED_HERE:
        return LessonCardStatus.MOVED_HERE;
      case CalendarDtoStatusEnum.MOVED_AWAY:
        return LessonCardStatus.MOVED_AWAY;
      default:
        return LessonCardStatus.REGULAR;
    }
  }

  mapGridEntryTypeEnum(gridType: GridEntryTypeEnum): LessonCardType {
    switch (gridType) {
      case GridEntryTypeEnum.NORMAL_TEACHING_PERIOD:
        return LessonCardType.NORMAL_TEACHING_PERIOD;
      case GridEntryTypeEnum.ADDITIONAL_PERIOD:
        return LessonCardType.ADDITIONAL_PERIOD;
      case GridEntryTypeEnum.EVENT:
        return LessonCardType.EVENT;
      case GridEntryTypeEnum.STAND_BY_PERIOD:
        return LessonCardType.STAND_BY_PERIOD;
      case GridEntryTypeEnum.OFFICE_HOUR:
        return LessonCardType.OFFICE_HOUR;
      case GridEntryTypeEnum.EXAM:
        return LessonCardType.EXAM;
      case GridEntryTypeEnum.BREAK_SUPERVISION:
        return LessonCardType.BREAK_SUPERVISION;
      case GridEntryTypeEnum.EXTERNAL_CALENDAR_EVENT:
        return LessonCardType.EXTERNAL_CALENDAR_EVENT;
      default:
        return LessonCardType.NORMAL_TEACHING_PERIOD;
    }
  }

  mapCalendarDtoTypeEnum(calendarType: CalendarDtoTypeEnum): LessonCardType {
    switch (calendarType) {
      case CalendarDtoTypeEnum.NORMAL_TEACHING_PERIOD:
        return LessonCardType.NORMAL_TEACHING_PERIOD;
      case CalendarDtoTypeEnum.ADDITIONAL_PERIOD:
        return LessonCardType.ADDITIONAL_PERIOD;
      case CalendarDtoTypeEnum.EVENT:
        return LessonCardType.EVENT;
      case CalendarDtoTypeEnum.STAND_BY_PERIOD:
        return LessonCardType.STAND_BY_PERIOD;
      case CalendarDtoTypeEnum.OFFICE_HOUR:
        return LessonCardType.OFFICE_HOUR;
      case CalendarDtoTypeEnum.EXAM:
        return LessonCardType.EXAM;
      case CalendarDtoTypeEnum.BREAK_SUPERVISION:
        return LessonCardType.BREAK_SUPERVISION;
      default:
        return LessonCardType.NORMAL_TEACHING_PERIOD;
    }
  }
}
