import React, { useEffect, useRef, useState } from 'react';
import classNames from 'clsx';
import LocaleDe from 'antd/lib/date-picker/locale/de_DE';
import LocaleEn from 'antd/lib/date-picker/locale/en_GB';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';

import { DateCalendar, IconButton } from '@/ui-components';
import './date-picker.less';
import { IDateCalendarHolidayProps } from '@/ui-components/date/calendar/date-calendar/date-calendar';

// e.g.: "Fr. 6. März 2020" (english: "Fri 6 Mar 2020")
export const format = 'ddd ll';

export function getPickerLocaleFromDayjs(value?: Dayjs) {
  if (value && value.locale().startsWith('de')) {
    return LocaleDe;
  } else {
    return LocaleEn;
  }
}

export interface IDatePickerProps {
  value: Dayjs | undefined;
  label?: string;
  disabled?: boolean;
  disabledDeleteButton?: boolean;
  removable?: boolean;
  onChange?: (date: Dayjs | undefined) => void;
  onOpen?: () => void; // Custom onOpen action, could be used to show calendar selection in modal
  testId?: string;
  useWrapperWidth?: boolean;
  holidays?: IDateCalendarHolidayProps[];
  disabledDate?: (date: Dayjs) => boolean;
}

export const DatePicker = (props: IDatePickerProps) => {
  const [dateValue, setDateValue] = useState<Dayjs | undefined>(props.value);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const [timeOutCallback, setTimeOutCallback] = useState<any | undefined>(undefined);
  const popupRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  // Update the label string when a new value comes in
  useEffect(() => {
    setDateValue(props.value);
  }, [props.value]);

  const onChange = (date: Dayjs | undefined) => {
    setDateValue(date);
    props.onChange && props.onChange(date);
  };

  const close = () => {
    setIsInEditMode(false);
    const callback = setTimeout(() => setIsClosed(true), 250);
    setTimeOutCallback(callback);
    if (timeOutCallback) {
      clearTimeout(timeOutCallback);
      setTimeOutCallback(undefined);
    }
  };

  const open = () => {
    if (props.onOpen) {
      props.onOpen();
    } else if (!props.disabled) {
      setIsClosed(false);
      setIsInEditMode(true);
    }
  };

  const datePickerClass = classNames('date-picker-wrapper', {
    disabled: props.disabled,
  });

  const dateLabel = classNames('date-label', {
    float: !!dateValue,
    'with-value': !!dateValue,
    'with-full-width': props.useWrapperWidth,
  });
  const containerHelperClass = isClosed ? 'container-helper-closed' : 'container-helper-open';

  const locale = getPickerLocaleFromDayjs(dateValue);

  const datePicker = (
    <div className={datePickerClass}>
      <div className={dateLabel} onClick={open} data-testid="date-picker-label">
        {!!dateValue ? dateValue.format('DD/MM/YYYY') : props.label}
      </div>
      {props.value && (
        <div className="floating-label">
          <span className="floating-label-content">{props.label}</span>
        </div>
      )}
      <div className={'container-helper ' + containerHelperClass}>
        {isInEditMode && (
          <DateCalendar
            value={dateValue}
            onChange={(val) => onChange(val || undefined)}
            onClose={close}
            allowClear={false}
            disabledDate={(date) => props.disabledDate?.(date) ?? false}
            locale={locale}
            format={locale?.lang.locale.startsWith('de') ? 'DD.MM.YYYY' : undefined}
            holidays={props.holidays}
          />
        )}
        <div ref={popupRef} className="untis-date-picker-popup" />
      </div>
    </div>
  );

  if (props.removable) {
    return (
      <div className="untis-date-picker-wrapper" data-testid={props.testId}>
        {datePicker}
        <div>
          <IconButton
            type="shared_trash"
            ariaLabel={t('general.delete')}
            disabled={props.disabledDeleteButton}
            onClick={() => onChange(undefined)}
          />
        </div>
      </div>
    );
  }

  return <div data-testid={props.testId}>{datePicker}</div>;
};

export default DatePicker;
